import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule as CustomFormsModule } from 'src/app/shared/components/forms/forms.module';
import { TrackingModule } from 'src/app/shared/components/tracking/tracking.module';
import { AttentionsRoutingModule } from './attentions-routing.module';
import { DialogAuditStatesAttentionComponent } from './components/dialog-audit-states-attention/dialog-audit-states-attention.component';
import { DialogEvolutionComponent } from './components/dialog-evolution/dialog-evolution.component';
import { AttentionsComponent } from './pages/attentions/attentions.component';
import { AttentionsDeleteWizardComponent } from './components/attentions-delete-wizard/attentions-delete-wizard.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AttentionEditComponent } from './components/attention-edit/attention-edit.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FinancierSelectorComponent } from '../schedule/components/financier-selector/financier-selector.component';
import { FilePondModule } from 'ngx-filepond';
import { UtilsModule } from 'src/app/shared/components/utils/utils.module';

@NgModule({
  declarations: [
    AttentionsComponent,
    DialogEvolutionComponent,
    DialogAuditStatesAttentionComponent,
    AttentionEditComponent,
    FinancierSelectorComponent,
    AttentionsDeleteWizardComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AttentionsRoutingModule,
    FormsModule,
    CustomFormsModule,
    TrackingModule,
    PipesModule,
    OverlayModule,
    FilePondModule,
    UtilsModule
  ],
  exports: [ AttentionsDeleteWizardComponent, AttentionEditComponent, ]
})
export class AttentionsModule { }
