import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Provider } from 'src/app/shared/models/provider';
import { Observable, timer, of } from 'rxjs';
import { Patient } from 'src/app/shared/models/patient';
import { Agreement } from 'src/app/shared/models/agreement';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { startWith, map, filter, debounce, tap, switchAll } from 'rxjs/operators';
import * as moment from 'moment';
import { ProvidersService } from 'src/app/core/services/providers.service';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';

/**
 * allAgreements: Agreement[]: son los agreements obtenidos de la request a partir de un prestador. Posee la misma
 * estructura que Agreement del model pero compuesto por paciente
 *
 * Listado de agreements (paso 3):
 *  allAgreementsList: es la lista de agreements mostrada en pantalla con los selects
 * (esta lista debe diferenciarse de allAgreements para poder eliminar un item de allAgreementList
 * sin afectar a los agreements cargados en memoria a partir de la request)
 *  agreements:  son los agreements seleccionados
 */

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    @Input() getAgreementsByProvider;
    // @Input() allProviders: Provider[];
    @Output() onLoadSchedule = new EventEmitter<Agreement[]>();
    // ! Borrar, lo hice momentaneameente, solo para obtener los datos del filtro cuando se presiona buscar
    @Output() onLoadScheduleWithDataFilter = new EventEmitter<any>();
    @ViewChild('providerInput', { static: true }) providerInput: ElementRef<HTMLInputElement>;
    @ViewChild('providerAuto', { static: true }) providerAuto: MatAutocomplete;
    @ViewChild('patientInput', { static: false }) patientInput: ElementRef<HTMLInputElement>;
    @ViewChild('patientAuto', { static: true }) patientAuto: MatAutocomplete;
    moment = moment;
    loadingGetProviders = false;
    patientCtrl = new FormControl();
    providerCtrl = new FormControl();
    filteredProviders: Provider[];
    filteredPatients: Observable<Patient[]>;
    patients: Patient[] = [];
    providers: Provider[] = [];
    agreements: Agreement[] = [];
    allPatients: Patient[] = [];
    allAgreements: Agreement[] = [];
    allAgreementsList: Agreement[] = [];
    storedValue;

    constructor(private cd: ChangeDetectorRef, private providersService: ProvidersService, public entitiesFacade: EntitiesFacade) {
        this.filteredPatients = this.patientCtrl.valueChanges.pipe(
            startWith(null),
            map((patient: string | null) => patient ? this._filterPatient(patient) : this.allPatients.slice()));

        let temporalValue;

        this.providerCtrl.valueChanges
            .pipe(
                startWith(''),
                filter((value: string) => !!value && value.length > 0),
                debounce(value => (value.length > 1) ? timer(500) : timer(2500)),
                // tap(_ => this.searchingSpinner = true),
                map(value => { temporalValue = value; return value == this.storedValue ? of(this.filteredProviders) : this.getProvidersByFullName(value) }),
                tap(_ => { this.storedValue = temporalValue }),
                switchAll()
            ).subscribe(values => {
                this.filteredProviders = values
            });
    }

    ngOnInit() {
    }

    removePatient(patient: Patient): void {
        const index = this.patients.indexOf(patient);

        if (index >= 0) {
            this.patients.splice(index, 1);
            // Eliminar las prestaciones de ese paciente
            this.removeAgreementsListByPatient(patient.id);
        }
    }

    removeProvider(provider: Provider): void {
        const index = this.providers.indexOf(provider);

        if (index >= 0) {
            this.providers.splice(index, 1);
            this.removeAllAgreementsByProvider(provider.id);
            this.updateDataByProvider(provider, false);
            // this.onChangeAllAgreements();
        }
    }

    selectedProvider(event: MatAutocompleteSelectedEvent): void {
        const provider: Provider = event.option.value;
        if (!this.providers.find(_provider => _provider.id === provider.id)) {
            this.providers.push(event.option.value);

            const providerId = this.providers[this.providers.length - 1].id;
            this.loadingGetProviders = true;
            this.getAgreementsByProvider(providerId).then(agreementsRequest => {
                // concateno el agreement en caso de que sea nuevo, sino lo ignoro
                agreementsRequest.forEach(agreementRequest => {
                    if (!this.allAgreements.find(agreement => agreement.id == agreementRequest.id)) {
                        this.allAgreements.push(agreementRequest)
                    }
                })
            })
                .then(_ => this.updateDataByProvider(provider, true)).finally(_ => this.loadingGetProviders = false)

        }
        this.providerInput.nativeElement.value = '';
        this.providerCtrl.setValue(null);
    }

    selectedPatient(event: MatAutocompleteSelectedEvent): void {
        const patient: Patient = event.option.value;
        if (!this.patients.find(_patient => _patient.id === patient.id)) {
            this.patients.push(patient);
            this.addAgreementListByPatient(patient)
        }
        this.patientInput.nativeElement.value = '';
        this.patientCtrl.setValue(null);
    }

    onClickReloadAgreementByProvider(event, provider: Provider) {
        this.loadingGetProviders = true;
        this.getAgreementsByProvider(provider.id).then(agreementsRequest => {
            // concateno el agreement en caso de que sea nuevo, sino lo ignoro
            agreementsRequest.forEach(agreementRequest => {
                if (!this.allAgreements.find(agreement => agreement.id == agreementRequest.id)) {
                    this.allAgreements.push(agreementRequest)
                }
            })
        }).then(_ => this.fillAllDataByProvider(provider))
            .finally(() => this.loadingGetProviders = false)
    }
    private _filterPatient(value): Patient[] {
        const filterValue = value.name ? '' : value.toLowerCase();
        return this.allPatients.filter(patient => {
            const nameAndsurname = patient.name.toLowerCase() + ' ' + patient.surname.toLowerCase();
            const surnameAndName = patient.surname.toLowerCase() + ' ' + patient.name.toLowerCase();

            // return nameAndsurname.toLowerCase().indexOf(filterValue) === 0
            return patient.name.toLowerCase().includes(filterValue) || patient.surname.toLowerCase().includes(filterValue) || nameAndsurname.includes(filterValue) || surnameAndName.includes(filterValue)

        });
        // return this.allPatients.filter(patient => patient.name.toLowerCase().includes(filterValue) || patient.surname.toLowerCase().includes(filterValue));
    }

    removeAllAgreementsByProvider(providerId: number) {
        // El provider puede estar en mas de un agreement
        this.allAgreements = this.agreements.filter(
            agreement => agreement.provider.id !== providerId
        )
    }

    removeAgreementsListByPatient(patientId: number) {
        // El paciente puede estar en mas de un agreement
        this.agreements = this.agreements.filter(
            agreement => agreement.provision.casee.patient.id !== patientId
        )
        this.allAgreementsList = this.allAgreementsList.filter(
            agreement => agreement.provision.casee.patient.id !== patientId
        )
    }

    addAgreementListByPatient(patient: Patient) {
        const agreementsByPatient = this.allAgreements.filter(
            agreement => agreement && agreement.provision && agreement.provision.casee && agreement.provision.casee.patient && agreement.provision.casee.patient.id === patient.id
        );
        agreementsByPatient.forEach(
            agreementRequest => {
                if (!this.allAgreementsList.find(_agreement => _agreement.id === agreementRequest.id)) {
                    this.allAgreementsList.push(agreementRequest);
                }
                if (!this.agreements.find(_agreement => _agreement.id === agreementRequest.id)) {
                    this.agreements.push(agreementRequest);
                }
            }
        )
    }

    /**
     * Llena la totalidad del input de patients y agreements según el provider clickeado
     * Este metodo es llamado cuando se clickea el reload del chip del provider
     * @param provider
     */
    fillAllDataByProvider(provider: Provider) {
        this.allPatients = [];
        //Relleno (si hace falta) los pacientes asociados al prestador:
        this.allAgreements.forEach(
            agreement => {
                // Si el paciente no se encuentra en la lista y pertenence a un agreement del provider
                // pasado como parámetro, lo agrego
                if (!this.patients.find(patient => patient.id === agreement.provision.casee.patient.id) && agreement.provider.id === provider.id) {
                    this.patients.push(agreement.provision.casee.patient);
                }
                if (!this.allPatients.find(patient => agreement.provision.casee.patient.id == patient.id)) {
                    this.allPatients.push(agreement.provision.casee.patient);
                }
                //Relleno (si hace falta) la lista de agreements:
                if (!this.allAgreementsList.find(_agreement => _agreement.id === agreement.id) && agreement.provider.id === provider.id) {
                    this.allAgreementsList.push(agreement);
                    this.agreements.push(agreement);
                }
            }
        )
    }

    /**
     * Actualiza los pacientes y prestaciones asociados a un prestador a partir del cambio en allAgreements
     * fill // delete
     */
    updateDataByProvider(provider: Provider, fill: boolean) {
        // this.allAgreementsList = [];
        // this.allAgreementsList = this.allAgreementsList.concat(this.allAgreements);
        if (fill) {
            this.allPatients = [];
            //Relleno (si hace falta) los pacientes asociados al prestador:
            this.allAgreements.forEach(
                agreement => {
                    // Si el paciente no se encuentra en la lista y pertenence a un agreement del provider
                    // pasado como parámetro, lo agrego
                    // if (!this.patients.find(patient => patient.id === agreement.patient.id) && agreement.provider.id === provider.id) {
                    //   this.patients.push(agreement.patient);
                    // }
                    if (!this.allPatients.find(patient => agreement.provision.casee.patient.id == patient.id)) {
                        this.allPatients.push(agreement.provision.casee.patient);
                    }
                    //Relleno (si hace falta) la lista de agreements:
                    // if (!this.allAgreementsList.find(_agreement => _agreement.id === agreement.id) && agreement.provider.id === provider.id) {
                    //   this.allAgreementsList.push(agreement);
                    //   // this.agreements.push(agreement);
                    // }
                }
            )
        } else {

            if (this.allAgreements.length == 0) {
                this.patients = [];
                this.allPatients = [];
                this.agreements = [];
                this.allAgreementsList = [];
            } else {
                this.patients = this.patients.filter(patient => this.allAgreements.find(agreement => agreement.provision.casee.patient.id === patient.id))
                this.allPatients = this.allPatients.filter(patient => this.allAgreements.find(agreement => agreement.provision.casee.patient.id === patient.id))
                this.allAgreementsList = this.allAgreementsList.filter(_agreement => this.allAgreements.find(agreement => agreement.id === _agreement.id))
                this.agreements = this.agreements.filter(_agreement => this.allAgreements.find(agreement => agreement.id === _agreement.id))
            }
            setTimeout(() => {
                this.cd.markForCheck();
            }, 3000);
        }
    }

    thereIsSamePractices(agreement: Agreement) {
        const sameData = this.allAgreementsList.find((agreementItem: Agreement) => (agreement.provision.casee.patient.id == agreementItem.provision.casee.patient.id && agreement.id != agreementItem.id && agreement.provider.id == agreementItem.provider.id && agreement.provision.practice.id == agreementItem.provision.practice.id));
        return sameData;
    }

    onClickChipProvider(event, provider: Provider) {
        event.preventDefault();
        event.stopPropagation()
    }

    onChangeCheckAgreement(_event: MatCheckboxChange, _agreement: Agreement) {
        const indexAgreement = this.agreements.findIndex(agreement => agreement.id === _agreement.id)
        if (_event.checked) {
            if (indexAgreement === -1) {
                this.agreements.push(_agreement);
            }
        } else {
            if (indexAgreement !== -1) {
                this.agreements.splice(indexAgreement, 1);
            }
        }
    }

    onClickLoadSchedule() {
        this.onLoadSchedule.emit(this.agreements);
        // // los seleccionados
        // console.log(this.patients);
        // // Los seleccionados
        // console.log(this.providers);
        // // Los seleccionados
        // console.log(this.agreements);
        // // Todos los pacientes de ese prestador
        // console.log(this.allPatients);
        // // Todos los agreements de ese prestador
        // console.log(this.allAgreements);
        // // Los agreements de ese prestador con ese paciente
        // console.log(this.allAgreementsList);

        this.onLoadScheduleWithDataFilter.emit({
            providersSelected: this.providers,
            patientsSelected: this.patients,
            agreementsSelected: this.agreements,
            allPatients: this.allPatients,
            allAgreements: this.allAgreements,
            allAgreementsList: this.allAgreementsList
        });
    }

    isCheckedAgreement(agreement: Agreement): boolean {
        return this.agreements.find(_agreement => _agreement.id === agreement.id) ? true : false;
    }

    getProvidersByFullName = (value: string) => this.providersService.getProviders({ fullName_like: value, active: true }).pipe(map(dataApi => dataApi.data))


}
/**
   * Lo único que me interesa cuando hago el get de la agenda es el filtro por agreementId, siempre se llega a eso
   */

/**
 * En realidad no me interesa el caso, me interesan los agreements, y manejar esa lista
 */
