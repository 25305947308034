import { Component, OnInit, Input } from '@angular/core';
import { Case } from 'src/app/shared/models/case';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit {
  @Input() case: Case;
  constructor() { }

  ngOnInit() {

  }


  getLinkMap(): string {
    let link = 'https://www.google.com/maps/place/';
    link += this.case.patient.address.street ? this.case.patient.address.street : '';
    link += ' ' + (this.case.patient.address.streetNumber ? this.case.patient.address.streetNumber : '');
    link += ',' + (this.case.patient.address.location && this.case.patient.address.location.name ? this.case.patient.address.location.name : '');
    link += ',' + (this.case.patient.address.location && this.case.patient.address.location.province && this.case.patient.address.location.province.name ? this.case.patient.address.location.province.name : '');
    return link;
  }

}
