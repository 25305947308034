import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormsModule as CustomFormsModule } from 'src/app/shared/components/forms/forms.module';
import { AvatarModule } from 'src/app/shared/components/avatar/avatar.module';
import { MaterialModule } from 'src/app/material/material.module';
import { CustomCalendarModule } from 'src/app/shared/components/calendar/calendar.module';
import { DialogModule } from 'src/app/shared/components/dialog/dialog.module';
import { TrackingModule } from 'src/app/shared/components/tracking/tracking.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CasesRoutingModule } from './cases-routing.module';
import { CasesFilterComponent } from './components/cases-filter/cases-filter.component';
import { DialogChangingStateCaseComponent } from './components/dialog-changing-state-case/dialog-changing-state-case.component';
import { EvolutionsDialogComponent } from './components/evolutions-dialog/evolutions-dialog.component';
import { EvolutionsComponent } from './components/evolutions/evolutions.component';
import { PatientDetailComponent } from './components/patient-detail/patient-detail.component';
import { ProvisionsDetailComponent } from './components/provisions-detail/provisions-detail.component';
import { CaseCreationComponent } from './pages/case-creation/case-creation.component';
import { CaseComponent } from './pages/case/case.component';
import { CasesComponent } from './pages/cases/cases.component';
import { SupplyDetailComponent } from './components/supply-detail/supply-detail.component';
import { SupplyListComponent } from './components/supply-list/supply-list.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { ProvidersSelectorComponent } from './components/providers-selector/providers-selector.component';
import { OperatorsSelectorComponent } from './components/operators-selector/operators-selector.component';
import { VatCategorySelectorComponent } from './components/vat-category-selector/vat-category-selector.component';
import { EvolutionsSideBarComponent } from './components/evolutions-side-bar/evolutions-side-bar.component';
import { ProvisionSchemeComponent } from './components/provision-scheme/provision-scheme.component';
import { AngularSplitModule } from 'angular-split';
import { ScheduleCaseComponent } from './components/schedule-case/schedule-case.component';
import { CardTableModule } from '../../shared/components/card-table/card-table.module';
import { AttentionsListCaseComponent } from './components/attentions-list-case/attentions-list-case.component';
import { CalendarCaseComponent } from './components/calendar-case/calendar-case.component';
import { CalendarModule } from 'angular-calendar';
import { DateAdapter } from '@angular/material/core';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogImageDetailModule } from 'src/app/shared/components/dialog-image-detail/dialog-image-detail.module';
import { CaseIndicatorsComponent } from './components/case-indicators/case-indicators.component';
import { CaseMainDataComponent } from './components/case-main-data/case-main-data.component';
import { CommonModule } from '@angular/common';
// FilePonds
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { UtilsModule } from 'src/app/shared/components/utils/utils.module';
import { AttentionsModule } from '../attentions/attentions.module';
import { AdmissionsModule } from '../admissions/admissions.module';
import { MapComponent } from '../providers/components/map/map.component';
import { ProvidersModule } from '../providers/providers.module';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);

@NgModule({
    declarations: [
        PatientDetailComponent,
        ProvisionsDetailComponent,
        EvolutionsComponent,
        CasesComponent,
        CasesFilterComponent,
        DialogChangingStateCaseComponent,
        CaseCreationComponent,
        CaseMainDataComponent,
        CaseIndicatorsComponent,
        EvolutionsDialogComponent,
        SupplyDetailComponent,
        SupplyListComponent,
        OrderListComponent,
        ProvidersSelectorComponent,
        OperatorsSelectorComponent,
        VatCategorySelectorComponent,
        EvolutionsSideBarComponent,
        ProvisionSchemeComponent,
        ScheduleCaseComponent,
        CalendarCaseComponent,
        AttentionsListCaseComponent,
        CaseComponent,
    ],
    imports: [
    CasesRoutingModule,
    MaterialModule,
    CustomFormsModule,
    CustomCalendarModule,
    AvatarModule,
    TrackingModule,
    PipesModule,
    DirectivesModule,
    DialogModule,
    FormsModule,
    FilePondModule,
    AngularSplitModule,
    CardTableModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    OverlayModule,
    DialogImageDetailModule,
    CommonModule,
    UtilsModule,
    AttentionsModule,
    AdmissionsModule,
    MapComponent,
    ProvidersModule
],
    exports: [ProvisionsDetailComponent, ProvisionSchemeComponent, ScheduleCaseComponent]
})
export class CasesModule { }
