<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon && !spinner">{{ leftIcon }}</mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass"
        *ngIf="leftIconSvg && !spinner" [svgIcon]="leftIconSvg"></mat-icon>
    <mat-spinner *ngIf="spinner" class="mrs-3" color="primary" diameter="20">
    </mat-spinner>
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            <b>{{ label }}</b>
        </mat-label>
        <mat-hint>
            {{ this.hint }}
        </mat-hint>
        <mat-select disableOptionCentering [formControlName]="name" id="{{ name }}" placeholder="{{ placeholder }}"
            [disabled]="disabled ? disabled : false" (selectionChange)="change($event)">
            <ngx-mat-select-search [placeholderLabel]="placeholderSearching" [searching]="searchingSpinner"
                [noEntriesFoundLabel]="placeholderNoValue" [formControlName]="nameSearching">
            </ngx-mat-select-search>
            <mat-select-trigger>

                <div class="flex-row flex-100 p-1">
                    <div class="flex-50" style="min-width: 500px; padding-top: 10px;">
                        <span>
                            {{ proxyCalculateName()(this.operatorSelected) }}
                        </span>
                    </div>
                    <div class="flex-5">
                        <button mat-icon-button class="ml-2">
                            <mat-icon [svgIcon]="'userCircle'"></mat-icon>
                        </button>
                    </div>
                    <div style="display: flex; flex: 45; flex-wrap: nowrap; flex-flow: nowrap !important">

                        <mat-chip-listbox>
                            <div style="display: flex;; flex-direction: row; flex-flow: nowrap; ">
                                <mat-chip [color]="'primary'"
                                    [ngStyle]="{ 'min-width': '100px',   'background-color':'lavender'}"
                                    [color]="'primary'" *ngFor="let tag of this.operatorSelected?.tags.slice(0,3) "
                                    matTooltip="{{proxyCalculateTag(tag)}}">
                                    <span [ngStyle]="{
                                        'font-size': '8px',
                                        'white-space': 'normal',
                                        'word-wrap': 'break-word',
                                        'display': 'block',
                                        'text-align': 'center',
                                        'line-height': '10px',
                                        'color':'darkblue',
                                    }">
                                        {{ proxyCalculateTag(tag) }}
                                    </span>
                                </mat-chip>
                                <mat-chip [ngStyle]="{ 'min-width': '30px',  'background-color':'lavender' }"
                                    [color]="'primary'" *ngIf="this.operatorSelected?.tags?.length > 3">
                                    <span [ngStyle]="{
                                        'font-size': '8px',
                                        'white-space': 'normal',
                                        'word-wrap': 'break-word',
                                        'display': 'block',
                                        'text-align': 'center',
                                        'line-height': '10px',
                                        'color':'darkblue',
                                    }">
                                        {{ '+' + (this.operatorSelected?.tags?.length - 3) }}
                                    </span>
                                </mat-chip>
                            </div>
                        </mat-chip-listbox>
                    </div>
                </div>
            </mat-select-trigger>
            <mat-option value="{{ proxyCalculateId()(element) }}" *ngFor="let element of filteredValues | async">

                <div class="flex-row flex-100p-1">
                    <div class="flex-50" style="min-width: 500px">
                        <span>
                            {{ proxyCalculateName()(element) }}
                        </span>
                    </div>
                    <div class="flex-10">
                        <button mat-icon-button>
                            <mat-icon [svgIcon]="'userCircle'"></mat-icon>
                        </button>
                    </div>
                    <div style="display: flex; flex: 45; flex-wrap: nowrap; flex-flow: nowrap !important">
                        <mat-chip-listbox>
                            <div style="display: flex;; flex-direction: row; flex-flow: nowrap; ">

                                <mat-chip [ngStyle]="{ 'min-width': '120px', 'background-color':'lavender'}"
                                    [color]="'primary'" *ngFor="let tag of element.tags.slice(0,3)"
                                    matTooltip="{{proxyCalculateTag(tag)}}">
                                    <span [ngStyle]="{
                                        'font-size': '8px',
                                        'white-space': 'normal',
                                        'word-wrap': 'break-word',
                                        'display': 'block',
                                        'text-align': 'center',
                                        'line-height': '10px',
                                        'color':'darkblue',
                                    }">
                                        {{ proxyCalculateTag(tag) }}
                                    </span>
                                </mat-chip>

                                <mat-chip [ngStyle]="{ 'min-width': '30px', 'background-color':'lavender' }"
                                    [color]="'primary'" *ngIf="this.element?.tags?.length > 3">
                                    <span [ngStyle]="{
                                        'font-size': '8px',
                                        'white-space': 'normal',
                                        'word-wrap': 'break-word',
                                        'display': 'block',
                                        'text-align': 'center',
                                        'line-height': '10px',
                                        'color':'darkblue',
                                    }">
                                        {{ '+' + (this.element?.tags?.length - 3) }}
                                    </span>
                                </mat-chip>

                            </div>
                        </mat-chip-listbox>
                    </div>
                </div>
            </mat-option>
        </mat-select>

        <mat-error style="display: unset;"  *ngIf="
                this.form.get(this.name).errors &&
                (this.form.get(this.name).dirty || this.form.get(this.name).touched)
            ">
            <b>
                {{ objectValues(this.form.get(this.name).errors)[0] }}
            </b>
        </mat-error>

    </mat-form-field>
</div>

<ng-template #operatorInfo>
    <div class="flex-column flex-100">
        <div class="flex-column bg-primary-lighter p-5">
            <div class="flex-row align-end">
                <button mat-icon-button (click)="this.closeDialog()">
                    <mat-icon>highlight_off</mat-icon>
                </button>
            </div>
            <div class="flex-row align-center text-primary-h2">
                {{ this.operatorForWindow?.name + " " + this.operatorForWindow?.surname }}
            </div>
            <div class="flex-row align-center text-primary-darker-h4 mt-0">
                {{ "Operador/a" }}
            </div>
            <div class="flex-row align-center stretch">
                <mat-chip-listbox>
                    <mat-chip
                        [ngStyle]="{ 'background-color': ADMISSIONS_STATES_COLORS[1].background_color, 'color': ADMISSIONS_STATES_COLORS[1].color }">
                        {{ '3 c' | titlecase | uppercase }}
                    </mat-chip>
                    <mat-chip
                        [ngStyle]="{ 'background-color': ADMISSIONS_STATES_COLORS[0].background_color, 'color': ADMISSIONS_STATES_COLORS[0].color }">
                        {{ '6 d' | titlecase | uppercase }}
                    </mat-chip>
                    <mat-chip
                        [ngStyle]="{ 'background-color': ADMISSIONS_STATES_COLORS[1].background_color, 'color': ADMISSIONS_STATES_COLORS[1].color }">
                        {{ '25 activos' | titlecase | uppercase }}
                    </mat-chip>
                    <mat-chip
                        [ngStyle]="{ 'background-color': ADMISSIONS_STATES_COLORS[2].background_color, 'color': ADMISSIONS_STATES_COLORS[2].color }">
                        {{ '6 asignados' | titlecase | uppercase }}
                    </mat-chip>
                </mat-chip-listbox>
            </div>
        </div>
        <div class="flex-column align-center stretch m-5 border-data">
            <div class="flex-row my-3 mx-6 flex-100 space-between">
                <span class="text-primary-darker subtitle-3 align-start">
                    Región
                </span>
                <span class="text-primary subtitle-4 align-end">
                    {{ this.operatorForWindow?.region }}
                </span>
            </div>
            <div class="flex-row my-3 mx-6 flex-100 space-between">
                <span class="text-primary-darker subtitle-3 align-start">
                    Empresa
                </span>
                <span class="text-primary subtitle-4 align-end">
                    {{ this.operatorForWindow?.empresa }}
                </span>
            </div>
            <div class="flex-row my-3 mx-6 flex-100 space-between">
                <span class="text-primary-darker subtitle-3 align-start">
                    Max. Categoría
                </span>
                <span class="text-primary subtitle-4 align-end">
                    {{ this.operatorForWindow?.categoria }}
                </span>
            </div>
            <div class="flex-row my-3 mx-6 flex-100 space-between">
                <span class="text-primary-darker subtitle-3 align-start">
                    Estado
                </span>
                <span class="text-primary subtitle-4 align-end">
                    {{ this.operatorForWindow?.estado | uppercase }}
                </span>
            </div>
            <div class="flex-row my-3 mx-6 flex-100 space-between">
                <span class="text-primary-darker subtitle-3">
                    Lorem ipsum
                </span>
                <span class="text-primary subtitle-4">
                    Lorem ipsum
                </span>
            </div>
        </div>
        <div class="flex-row align-space-evenly">
            <button mat-stroked-button [color]="'primary'" [disabled]="false" matTooltip="Volver" class="no-border">
                {{ "No, volver" | uppercase }}
            </button>
            <button mat-raised-button [color]="'primary'" [disabled]="false" matTooltip="Seleccionar">
                {{ "Si, seleccionar" | uppercase }}
            </button>
        </div>
    </div>
</ng-template>
