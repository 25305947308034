import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogImageDetailComponent } from './dialog-image-detail.component';
import { MaterialModule } from 'src/app/material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [DialogImageDetailComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class DialogImageDetailModule { }
