<div class="flex-column ms-1">
    <!-- Exportar -->
    <ng-container>
        <div class="flex-column">
            <mat-card>
                <mat-card-content>
                    <!-- Multi-select -->
                    <form [formGroup]="filterForm">
                        <div class="flex-column">
                            <!-- # ROW 1 -->
                            <div class="flex-row align-space-between-start">
                                <div class="flex-row align-space-between-start gap-0-5rem flex-70">
                                    <!-- [(value)]="this.categoriesSelected" -->
                                    <mat-select class="flex-35 subtitle-2 mts-2" [(value)]="this.categoriesSelected"
                                        placeholder="Tipo de evolución" multiple
                                        (selectionChange)="this.selectOption($event)">
                                        <mat-option *ngFor="let category of this.listEvolutionTypes"
                                            value="{{category.id}}">
                                            {{ category.description }}
                                        </mat-option>
                                    </mat-select>
                                    <span class="text-primary-darker subtitle-1 mts-2">
                                        Período: {{ getLabelDate() }}
                                    </span>
                                </div>
                                <!-- Export and Add evolution -->
                                <div class="flex-row align-end-start gap-0-5rem flex-20">
                                    <button mat-icon-button matTooltip="Exportar a..." [color]="'primary'"
                                        [matMenuTriggerFor]="menuExport" [disabled]="this.attentions?.length==0">
                                        <mat-icon>save_alt</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Agregar evolución al caso"
                                        (click)="openCreateEvolution()" [color]="'primary'">
                                        <mat-icon>add_circle_outlined</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <!-- # ROW 2 -->
                            <div class="flex-row align-start-center gap-1rem">
                                <button mat-button [color]="'primary'" (click)="this.onClickFilter()">
                                    <mat-icon matTooltip="Filtrar evoluciones" [svgIcon]="'Filter-enabled-primary'">
                                    </mat-icon>
                                    FILTRAR
                                </button>
                                <span class="text-secondary txt" (click)="removeFilter()"
                                    style="white-space: nowrap; cursor: pointer; position: relative;">Limpiar
                                    filtros</span>
                            </div>
                        </div>
                    </form>
                    <!-- Div to export with transition -->
                    <div *ngIf="showOptions" [@enterAnimation] class="flex-column">
                        <mat-divider></mat-divider>
                        <form *ngIf="exportForm" [formGroup]="exportForm" class="flex-column">
                            <div class="headline-3 mys-3 text-primary-bolder">
                                Configuración de exportación
                            </div>
                            <div class="flex-row align-space-evenly-center">
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker">Encabezado</span>
                                    <app-select-input [name]="this.LOGO" [form]="this.exportForm"
                                        [placeholder]="'Seleccionar...'" [elements]="this.elementsLogo"
                                        [calculateId]="this.calculateIdlogo" [calculateName]="this.calculateNameLogo">
                                    </app-select-input>
                                </div>
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker">Salida</span>
                                    <app-select-input [name]="this.OUTPUT_TYPE" [form]="this.exportForm"
                                        [placeholder]="'Seleccionar...'" [elements]="this.elementsOutput"
                                        [calculateId]="this.calculateIdOutput"
                                        [calculateName]="this.calculateNameOutput">
                                    </app-select-input>
                                </div>
                            </div>
                            <div *ngIf="this.logoForm == LOGO_OPTIONS.CUSTOM_LOGO">
                                <app-file-input class="flex-100" [name]="this.LOAD_FILE" [form]="this.exportForm">
                                </app-file-input>
                            </div>
                            <div *ngIf=" this.exportForm.errors && (this.exportForm.dirty || this.exportForm.touched)">
                                <mat-error *ngIf=" this.exportForm.errors &&
                            (this.exportForm.dirty || this.exportForm.touched)" class="flex-row align-start-center">
                                    <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
                                    {{ objectValues(this.exportForm.errors)[0] }}
                                </mat-error>
                            </div>
                            <div class="flex-row align-space-evenly-center mbs-5" *ngIf="!!this.showVisibilityCheckbox">
                                <app-checkbox-input class="mt-3 subtitle-3 text-primary-darker" [form]="this.exportForm"
                                    [label]="'Incluir ocultas al financiador'" [name]="this.VISIBLE_FINANCIER">
                                </app-checkbox-input>
                                <app-checkbox-input class="mt-3 subtitle-3 text-primary-darker" [form]="this.exportForm"
                                    [label]="'Incluir ocultas al prestador'" [name]="this.VISIBLE_PROVIDER">
                                </app-checkbox-input>
                            </div>
                            <div class="flex-row align-space-around-start wrap pl">
                                <button mat-stroked-button [color]="'primary'"
                                    (click)="this.showOptions = false">CANCELAR</button>
                                <!-- <mat-spinner *ngIf="pdfSpinner$ | async" diameter="30"></mat-spinner> -->
                                <div class="flex align-center-center">
                                    <button mat-flat-button [color]="'primary'" (click)="this.onSubmitExportFunction()"
                                            [disabled]="(pdfSpinner$ | async)">
                                      <span *ngIf="!(pdfSpinner$ | async)">
                                        GENERAR
                                      </span>
                                      <mat-spinner *ngIf="(pdfSpinner$ | async)" color="accent" diameter="20"></mat-spinner>
                                    </button>
                                  </div>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-menu #menuExport="matMenu">
                <button mat-menu-item (click)="this.onClickOptionToExport(OPTIONS_TO_EXPORT.PDF)">
                    <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30" [svgIcon]="'pdf'">
                    </mat-icon>PDF
                </button>
                <button mat-menu-item (click)="this.onClickOptionToExport(OPTIONS_TO_EXPORT.ZIP)">
                    <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30" [svgIcon]="'zip'">
                    </mat-icon>ZIP
                </button>
                <!-- TODO: not used yet -->
                <!-- <button mat-menu-item (click)="this.onClickOptionToExport(OPTIONS_TO_EXPORT.CSV)">
                    <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30" [svgIcon]="'csv'">
                    </mat-icon>CSV
                </button> -->
            </mat-menu>
        </div>
    </ng-container>

    <ng-container *ngIf="!(this.isLoadingGettingEvolutions$ | async); else spinner">
        <!-- Evolutions -->
        <mat-card class="mts-2">
            <ng-container *ngIf="this.attentions?.length > 0; else noEvolutions">
                <mat-card-content class="flex-row align-space-between-start flex">
                    <!-- Select dates -->
                    <div class="flex-column full-width">
                        <!-- Attentions list -->
                        <div id="{{(attention.id) ? attention.id : attention.evolutions[0].id}}"
                            class="flex-column autoAttention mb-4 anchor"
                            *ngFor="let attention of this.attentions; let i = index">
                            <div class="flex-row full-width">
                                <!-- Attention date & separator -->
                                <b class="text-primary-darker mb-2 autoAttentionDate">
                                    {{ (attention.id) ? 'Atención' : (attention.evolutions[0].category) ?
                                    attention.evolutions[0].category.description : 'Evolución' }} del día
                                    {{ attention?.fromDate
                                    ? (attention.fromDate | date: 'dd/MM/yyyy' )
                                    : 'Sin datos - '
                                    }}
                                </b>
                                <!-- Practice -->
                                <span class="text-secondary ml-3 autoAttentionPractice" *ngIf="attention?.practice">
                                    {{attention?.practice?.name}}
                                </span>
                                <!-- Divider -->
                                <mat-divider class="mb-2" [inset]="true"></mat-divider>
                            </div>
                            <!-- Evolutions list -->
                            <div class="autoEvolution parentShowOnHover"
                                *ngFor="let evolution of attention.evolutions; let j = index">
                                <div class="flex-row     align-space-between-center full-width">
                                    <!-- Icon -->
                                    <div class="flex-7 align-end-start">
                                        <app-avatar
                                            [username]="!!evolution?.operator?.name ? evolution?.operator?.name : this.entitiesFacade.getProvidersFullName(evolution?.provider)"
                                            [tooltip]="!!evolution?.operator?.name ? evolution?.operator?.name : this.entitiesFacade.getProvidersFullName(evolution?.provider)"
                                            [size]="37" [textSizeRatio]="2" [initialsSize]="4">
                                        </app-avatar>
                                    </div>
                                    <!-- Data -->
                                    <div class="flex-column flex-90">
                                        <div class="flex-row align-space-between-start">
                                            <div class="flex-69">
                                                <h3 class="text-secondary-darker mb-0 pb-0 autoEvolutionName">
                                                    <ng-container
                                                        *ngIf="evolution.category.id==EVOLUTIONS_TYPE.ADMINISTRATIVE; else elseTemplate">
                                                        <span class="text-secondary">
                                                            {{!!evolution.operator && !!evolution.operator.name
                                                            ?
                                                            evolution.operator.name : (!!evolution.provider &&
                                                            !!this.entitiesFacade.getProvidersFullName(evolution.provider)
                                                            ?
                                                            this.entitiesFacade.getProvidersFullName(evolution.provider)
                                                            : 'Sin nombre')}}
                                                        </span>
                                                    </ng-container>
                                                    <ng-template #elseTemplate>
                                                        {{
                                                        (attention.id) ?
                                                        (!!evolution.provider &&
                                                        !!this.entitiesFacade.getProvidersFullName(evolution.provider)
                                                        ?
                                                        this.entitiesFacade.getProvidersFullName(evolution.provider)
                                                        : 'Sin nombre' ) : '' }}
                                                    </ng-template>
                                                </h3>

                                                <div *ngIf="evolution.hasMedia" class="flex-column align-center-center">
                                                    <ng-container *ngIf="evolution?.mediaUrls;else loadingPhoto">
                                                        <ng-container *ngFor="let mediaUrl of evolution?.mediaUrls">
                                                            <img *ngIf="fileUtilitiesService.isPhoto(mediaUrl)"
                                                                class="mts-2 mb-3 cursor-pointer mat-elevation-z4"
                                                                align-center alt="Foto de la evolución"
                                                                style="max-width: 100%; max-height: 200px;"
                                                                [src]="this.sanitizeUrl(mediaUrl)"
                                                                (click)="openDialogImage(mediaUrl)" />

                                                            <button mat-icon-button
                                                                *ngIf="fileUtilitiesService.isPdf(mediaUrl)"
                                                                (click)="fileUtilitiesService.downloadFile(mediaUrl)"
                                                                matTooltip="Descargar">
                                                                <mat-icon mat-list-icon [style.width.px]="30"
                                                                    [style.height.px]="30" [svgIcon]="'pdf'">
                                                                </mat-icon>
                                                            </button>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #loadingPhoto>
                                                        <mat-spinner class="mts-4" color="primary" diameter="40">
                                                        </mat-spinner>
                                                    </ng-template>
                                                </div>

                                                <ng-container class="text-secondary-darker"
                                                    *ngIf="attention.id; else noAttentionIdType">
                                                    <span [ngSwitch]="evolution.category.id">
                                                        <span class="text-warn autoEvolutionType"
                                                            *ngSwitchCase="EVOLUTIONS_TYPE.ABSENT_PATIENT">
                                                            &nbsp;Paciente ausente
                                                        </span>
                                                        <span *ngSwitchCase="EVOLUTIONS_TYPE.NORMAL"
                                                            class="autoEvolutionType">
                                                            &nbsp;Evolución médica
                                                        </span>
                                                        <span class="text-warn autoEvolutionType"
                                                            *ngSwitchCase="EVOLUTIONS_TYPE.NOT_GOING">
                                                            &nbsp;Prestador ausente
                                                        </span>
                                                        <span class="text-warn autoEvolutionType"
                                                            *ngSwitchCase="EVOLUTIONS_TYPE.CRITICAL">
                                                            &nbsp;Evolución médica crítica
                                                        </span>
                                                        <span class="text-primary-darker autoEvolutionType"
                                                            *ngSwitchCase="EVOLUTIONS_TYPE.DISCHARGE">
                                                            &nbsp;Alta médica
                                                        </span>
                                                        <span class="autoEvolutionType"
                                                            *ngSwitchDefault>{{evolution?.category?.description}}
                                                        </span>
                                                    </span> —
                                                    <span
                                                        class="text-secondary mat-small multi_lines_text autoEvolutionText evText"
                                                        style="max-width: 200px!important;"
                                                        [innerHTML]="evolution.text | safeHtml">
                                                        <!-- {{ evolution.text }} -->
                                                    </span>
                                                </ng-container>
                                                <ng-template class="text-secondary-darker" #noAttentionIdType>
                                                    <span class="autoEvolutionType">
                                                        {{ evolution.category.description }}
                                                    </span>
                                                    <span
                                                        class="text-secondary mat-small multi_lines_text autoEvolutionText"
                                                        style="max-width: 200px!important;">
                                                        {{ evolution.text }}
                                                    </span>
                                                </ng-template>
                                            </div>
                                            <!-- Evolution number, menuButton and date -->
                                            <div class="flex-column text-secondary flex-30">
                                                <div class="flex-row align-space-between-none gap-gappx">
                                                    <span matTooltip="Código de evolución"
                                                        class="text-primary-darker">E{{evolution.id}}
                                                    </span>
                                                    <mat-icon class="cursor-pointer text-secondary"
                                                        [matMenuTriggerFor]="menuEvolutions"
                                                        [matMenuTriggerData]="{ attention:attention, evolution:evolution }">more_vert
                                                    </mat-icon>
                                                </div>
                                                <span>
                                                    {{ evolution.date | date: 'dd/MM/yyyy HH:mm' }}
                                                </span>
                                            </div>
                                            <div class="mts-2 align-center-center" *ngIf="evolution.hasMedia">
                                                <ng-container *ngFor="let mediaUrl of evolution?.media?.mediaUrls">
                                                    <button mat-icon-button *ngIf="fileUtilitiesService.isPdf(mediaUrl)"
                                                        (click)="fileUtilitiesService.downloadFile(mediaUrl)"
                                                        matTooltip="Descargar">
                                                        <mat-icon mat-list-icon [style.width.px]="30"
                                                            [style.height.px]="30" [svgIcon]="'pdf'">
                                                        </mat-icon>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- Visibility buttons -->
                                        <div class="flex-row align-start-center gap-1em">
                                            <button mat-button [disabled]="true"
                                                class="align-center-center autoVisibleFinancier" style="padding: 0px;">
                                                <mat-icon class="text-primary mat-icon-medium"
                                                    *ngIf="evolution.visibleFinancier">
                                                    visibility
                                                </mat-icon>
                                                <mat-icon class="text-secondary mat-icon-medium"
                                                    *ngIf="!evolution.visibleFinancier">
                                                    visibility_off
                                                </mat-icon>
                                                Financiador
                                            </button>
                                            <button mat-button [disabled]="true"
                                                class="align-center-center autoVisibleProvider" style="padding: 0px">
                                                <mat-icon class="text-primary mat-icon-medium"
                                                    *ngIf="evolution.visibleProvider">
                                                    visibility
                                                </mat-icon>
                                                <mat-icon class="text-secondary mat-icon-medium"
                                                    *ngIf="!evolution.visibleProvider">
                                                    visibility_off
                                                </mat-icon>
                                                Prestadores
                                            </button>
                                        </div>
                                        <mat-divider class="mys-2" *ngIf="j+1 < attention.evolutions.length"
                                            [inset]="true">
                                        </mat-divider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Right dates nav -->
                    <ng-container *ngIf="this.attentions?.length > 0;">
                        <div class="tableOfContentContainer flex-row align-center-start"
                            style="height: 80vh; overflow-y: auto; overflow-x: hidden;">
                            <div class="tableOfContent docs-toc-container">
                                <div class="docs-toc-heading">Fechas</div>
                                <nav *ngFor="let attention of this.attentionsWithoutDayRepeat; let i = index">
                                    <a [routerLink]="[]"
                                        fragment="{{(attention.id) ? attention.id : attention.evolutions[0].id}}"
                                        [ngClass]="{'active': this.fragment == (attention.id || attention.evolutions[0].id).toString()}"
                                        class="docs-level-h3 docs-link ng-star-inserted">
                                        {{ attention?.fromDate ? (attention.fromDate | date: 'dd/MM/yyyy' ) : 'Sin datos
                                        - '}}
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </ng-container>
        </mat-card>
        <!-- Message doesn't exist evolutions -->
        <ng-template #noEvolutions>
            <div *ngIf="emptyAttentions" class="noEvolutions flex-row wrap align-center-center">
                <span class="text-secondary-darker" *ngIf="this.attentionsPreviousFilter?.length == 0">
                    Aún no se han cargado evoluciones
                </span>
                <span class="text-secondary-darker" *ngIf="this.attentionsPreviousFilter?.length > 0">
                    Aún no se han cargado evoluciones para los <span class="subtitle-2 text-primary-darker">items
                        seleccionados</span>
                </span>
            </div>
            <div *ngIf="!emptyAttentions" class="noEvolutions flex-row wrap align-center-center">
                <span class="text-secondary-darker">
                    El filtro ingresado no encontró evoluciones
                </span>
            </div>
        </ng-template>

        <ng-template #noEvolutionsFiltered>
            <div class="noEvolutions flex-row wrap align-center-center">
                <span class="text-secondary-darker">
                    El filtro ingresado no encontró evoluciones
                </span>
            </div>
        </ng-template>
    </ng-container>
</div>

<mat-menu #menuEvolutions="matMenu">
    <ng-template matMenuContent let-attention="attention" let-evolution="evolution">
        <button mat-menu-item (click)="this.openDialogModifyEvolution(evolution,attention)">
            <mat-icon>policy</mat-icon>
            <span>Gestionar</span>
        </button>
    </ng-template>
</mat-menu>


<ng-template #spinner>
    <div class="spinner-hover">
        <mat-spinner diameter="60"></mat-spinner>
    </div>
</ng-template>

<!-- Create/Add evolution -->
<ng-template #addEvolutionForCase>
    <div class="flex-column">
        <div class="flex-row flex-100 align-start-center">
            <div class="flex-row flex-30 align-start-center gap-20p">
                <div class="flex-20 mls-2">
                    <mat-icon [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                        class="mat-icon-extraLarge">assignment</mat-icon>
                </div>
                <div class="flex-column">
                    <span class="mat-h2 mb-0 text-secondary-darker">
                        {{ this.case.id }}
                    </span>
                    <span class="text-secondary">
                        {{this.case.active ? 'Caso abierto' : 'Caso cerrado'}}
                    </span>
                </div>
            </div>
            <div class="flex-row flex-30 align-start-center">
                <div class="flex-20 mls-2">
                    <mat-icon [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                        class="mat-icon-extraLarge">person_pin</mat-icon>
                </div>
                <div class="flex-column">
                    <span class="mat-h2 mb-0 text-secondary-darker">
                        {{ this.case.patient.surname }}, {{ this.case.patient.name }}
                    </span>
                    <span class="text-secondary">{{ this.case.patient.typeDocument ?
                        this.case.patient.typeDocument : 'DNI '}}
                        {{ this.case.patient.documentNumber ? this.case.patient.documentNumber : 'Sin datos'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="separator separator-center mts-1">
            <span class="mx-2 text-primary-darker">
                Datos de la evolución
            </span>
        </div>
        <div class="flex-row align-start-center mts-2">
            <form class="flex-100 flex-row wrap align-space-between-start gap-5p" [formGroup]="evolutionForm">
                <app-text-area-input class="flex-60" [clearable]="true" [form]="this.evolutionForm"
                    [name]="this.EVOLUTION_TEXT" [placeholder]="'Ingrese el texto de la evolución'"
                    id="EvolutionCreateDialogComponent_txtArea" (change)="onChangeDescription($event)">
                </app-text-area-input>
                <div class="flex-35 flex-column wrap">
                    <ng-container *ngIf="!this.updatingEvolution; else updateEvolution">
                        <app-select-input [name]="this.EVOLUTION_TYPE" [form]="this.evolutionForm"
                            [placeholder]="'Tipo de evolución:'" [elements]="this.listEvolutionTypes"
                            [calculateId]="this.calculateId" [calculateName]="this.calculateName"
                            (selected)="onSelectCategory($event)" id="EvolutionCreateDialogComponent_cmbCategories">
                        </app-select-input>
                    </ng-container>
                    <ng-template #updateEvolution>
                        <span class="text-secondary-darker">
                            Tipo de evolución:
                        </span>
                        <span class="text-secondary">
                            {{ this.evolutionToUpdate.category.description }}
                        </span>
                    </ng-template>
                    <file-pond #myPond [options]="pondOptions" (oninit)="pondHandleInit()"
                        (onaddfile)="pondHandleAddFile($event)" (onremovefile)="pondHandleRemoveFile($event)"
                        (onaddfilestart)="pondHandleProcessFile($event)"
                        id="EvolutionCreateDialogComponent_fileSelector">
                    </file-pond>
                </div>
            </form>
        </div>
        <div class="flex-row wrap align-center-center gap-2em">
            <button mat-button (click)="financierVisibility = !financierVisibility" class="align-center-center"
                id="EvolutionCreateDialogComponent_btnFinancierVisibility">
                <mat-icon class="text-primary mat-icon-medium" *ngIf="this.financierVisibility">visibility</mat-icon>
                <mat-icon class="text-secondary mat-icon-medium" *ngIf="!this.financierVisibility">visibility_off
                </mat-icon>
                {{this.case.financier.name}}
            </button>
            <button mat-button (click)="providersVisibility = !providersVisibility"
                id="EvolutionCreateDialogComponent_btnProviderVisibility">
                <mat-icon class="text-primary mat-icon-medium" *ngIf="this.providersVisibility">visibility</mat-icon>
                <mat-icon class="text-secondary mat-icon-medium" *ngIf="!this.providersVisibility">visibility_off
                </mat-icon>
                Prestadores
            </button>
        </div>
        <div class="flex-row align-space-around-stretch mts-2">
            <button mat-stroked-button [color]="'primary'" [disabled]="this.loadingConfirm" (click)="onCancelClick()"
                id="EvolutionCreateDialogComponent_btnCancel">CANCELAR</button>
            <button mat-flat-button [color]="'primary'"
                [disabled]="!this.evolutionForm.valid || this.loadingConfirm || this.loadingAddFile"
                (click)="onConfirmClick()" id="EvolutionCreateDialogComponent_btnConfirm">
                <span *ngIf="!this.loadingConfirm; else loadingConfirmIcon">
                    CONFIRMAR
                </span>
                <ng-template #loadingConfirmIcon>
                    <mat-icon>
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </ng-template>
            </button>
        </div>
    </div>
</ng-template>
