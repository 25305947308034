import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

@Component({
    selector: 'app-spinner-error',
    templateUrl: './spinner-error.component.html',
    styleUrls: ['./spinner-error.component.scss'],
    standalone: true,
    providers: [
        provideLottieOptions({
            player: () => import('lottie-web'),
          }),
          AnimationLoader
    ],
    imports: [LottieComponent, CommonModule]
})

export class SpinnerErrorComponent {

    width = input<number>(50);
    msgError = input<string>('');

    options: AnimationOptions = {
        path: 'assets/animations/error.json',
    };

    animationCreated(animationItem: AnimationItem): void {
        console.log(animationItem);
    }
}
