import { Component, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Agreement } from 'src/app/shared/models/agreement';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { AttentionEdit } from 'src/app/shared/models/submodels/attentionEdit';
import * as momentRange from 'moment-range';
import { ProvisionFeeWithPractice } from 'src/app/shared/models/submodels/provisionFeeWithPractice';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { MatRadioButton } from '@angular/material/radio';
import { Attention } from 'src/app/shared/models/attention';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import Swal from 'sweetalert2';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { CalendarCaseService } from 'src/app/core/services/calendar-case.service';

@Component({
	selector: 'app-attention-edit',
	templateUrl: './attention-edit.component.html',
	styleUrls: ['./attention-edit.component.scss']
})
export class AttentionEditComponent implements OnInit {

	@Input() attention: AttentionEdit;
    @Input() lastDate: Date; // Last attention
    @Input() mode:string;

    @Output() closeDialog = new EventEmitter(); // Emitter to create attention
    @Output() refreshDataFiltered = new EventEmitter(); // Retrocompatibility
    @Output() onEventDelete = new EventEmitter<Attention>();

	allProvisionsCase: Provision[];
	provisionFees: ProvisionFee[];
	_provisionsDisplayedOnScreen: Subscription;
	provisionsDispayed: Provision[];
    agreementSelected: Agreement;
    provisionFeeSelected: ProvisionFeeWithPractice;
    minDate: Date; // For mat-calendar filter
    maxDate: Date; // For mat-calendar filter
    agreementAttentionDefault: number;
    showRadiobuttons: boolean = false;
    dateSelectedFromOtherComponent: Date;
    radiobuttonsDisabled: boolean = false;
    selectionToUpdateValue: string;
    editForm: FormGroup;
    durationAttention;
    loadingEditAttention = false;
    swalWithCustomizeButtons: any;
    isButtonReplaceAgreementEnabled: boolean = true;
    isButtonEditEnabled: boolean = true;
    isButtonDeleteEnabled: boolean = true;

    inputMode: string = ''

    // FORM
    public readonly AGREEMENT = 'agreement';
    public readonly DATE_FROM = 'fromDate';
    public readonly DATE_TO = 'toDate';
    public readonly DATE_FROM_UPDATE = 'dateFromUpdate';
    public readonly DATE_TO_UPDATE = 'dateToUpdate';
    // public readonly HOUR_DURATION = 'hourDuration';
    // public readonly MINUTE_DURATION = 'minuteDuration';
    public readonly STATE_ATTENTION = 'stateAttention';
    public readonly QUANTITY = 'quantity';	

    public readonly TIME_FROM = 'timeFrom';
    public readonly TIME_TO = 'timeTo'

    isNextDay: boolean = false;

    get quantityForm() {
        return this.editForm.get(this.QUANTITY).value;
    }
    get agreementForm() {
        return this.editForm.get(this.AGREEMENT).value;
    }
    get dateFromForm() {
        return this.editForm.get(this.DATE_FROM).value;
    }
    get dateToForm() {
        return this.editForm.get(this.DATE_TO).value;
    }
    get dateFromUpdateForm() {
        return this.editForm.get(this.DATE_FROM_UPDATE).value;
    }
    get dateToUpdateForm() {
        return this.editForm.get(this.DATE_TO_UPDATE).value;
    }
    get stateAttentionForm() {
        return this.editForm.get(this.STATE_ATTENTION).value;
    }
    set agreementForm(agreementId: number) {
        agreementId != null ? this.editForm?.controls[this.AGREEMENT].setValue(agreementId.toString())
            : this.editForm?.controls[this.AGREEMENT].reset();
    }
    set dateFromForm(date: Date) {
        date != null ? this.editForm?.controls[this.DATE_FROM].setValue(date)
            : this.editForm?.controls[this.DATE_FROM].reset();
    }
    set dateToForm(date: Date) {
        date != null ? this.editForm?.controls[this.DATE_TO].setValue(date)
            : this.editForm?.controls[this.DATE_TO].reset();
    }
    set dateFromUpdateForm(date: Date) {
        date != null ? this.editForm?.controls[this.DATE_FROM_UPDATE].setValue(date)
            : this.editForm?.controls[this.DATE_FROM_UPDATE].reset();
    }
    set dateToUpdateForm(date: Date) {
        date != null ? this.editForm?.controls[this.DATE_TO_UPDATE].setValue(date)
            : this.editForm?.controls[this.DATE_TO_UPDATE].reset();
    }    
    set stateAttentionForm(stateName: string) {
        stateName != null ? this.editForm?.controls[this.STATE_ATTENTION].setValue(stateName)
            : this.editForm?.controls[this.STATE_ATTENTION].reset();
    }
    set quantityForm(hours: number) {
        hours != null ? this.editForm?.controls[this.QUANTITY].setValue(hours)
            : this.editForm?.controls[this.QUANTITY].reset();
    }
    get timeFromForm() {
        return this.editForm.get(this.TIME_FROM).value;
    }
    get timeToForm() {
        return this.editForm.get(this.TIME_TO).value;
    }

    set timeFromForm(hourAndMinute: string) {
        hourAndMinute != null ? this.editForm?.controls[this.TIME_FROM].setValue(hourAndMinute)
            : this.editForm?.controls[this.TIME_FROM].reset();
    }
    set timeToForm(hourAndMinute: string) {
        hourAndMinute != null ? this.editForm?.controls[this.TIME_TO].setValue(hourAndMinute)
            : this.editForm?.controls[this.TIME_TO].reset();
    }

    showReplaceAgreement: boolean = false;
    showEditAttention: boolean = false;
    showOnlyView: boolean = false;
    default: boolean = false;
    attentionFromDateCast: string = 'Error de fecha'
    attentionHourFromToCast: string = '';
    attentionDurationCast: number = 0;

	constructor(private formBuilder: FormBuilder,
                private ren: Renderer2,
                private attentionsFacade: AttentionsFacade,
                public attentionsService: AttentionsService,
                private calendarService: CalendarCaseService
	) { }

	ngOnInit(): void {

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })     

        // NEW FORM
        this.editForm = this.createEditForm();
        this.inputMode = this.mode; // First mode saved
        switch (this.mode) {
            case 'UPD': // Edit
                this.setEditMode();
                break;

            case 'RPA': // Replace agreement
                this.setReplaceAgreementMode();
                break;
            
            case 'DSP': // Display
                this.setDisplayMode();
                break;
        }        
	}

    initData() {

        this.stateAttentionForm = this.attention.state.name;
        this.dateFromForm = !!this.attention ? this.attention.fromDate : null; // attention.fromDate
        this.timeFromForm = moment(this.attention.fromDate).format('HH:mm'); // Hour in
        this.timeToForm = moment(this.attention.toDate).format('HH:mm'); // Hour out
        this.dateToForm = null; // attention.toDate
        this.dateFromUpdateForm = null;
        this.dateToUpdateForm = null;
        this.quantityForm = this.calculateAttentionDuration();
        this.showRadiobuttons = false;
        this.editForm.controls['radioButton'].reset();

        switch (this.mode) {
            case 'UPD': // Edit
                this.editForm.get(this.DATE_FROM).setValidators( [CustomValidators.required('Requerido')] );
                // Visit
                if (this.attention.provisionFeeSelected.practice?.restriction?.requiredTime) {
                    this.editForm.get(this.TIME_FROM).setValidators( [CustomValidators.required('Requerido')] ); // Hour in
                    this.editForm.get(this.TIME_TO).setValidators( [CustomValidators.required('Requerida')] ); // Hour out
                    this.editForm.get(this.QUANTITY).setValidators( [CustomValidators.required('Requerida')] ); // Cant
                }
                break;

            case 'RPA': // Replace agreement
                this.attentionFromDateCast = `${moment(this.attention.fromDate).format('DD-MM-YYYY')}`;
                this.attentionHourFromToCast = `${moment(this.attention.fromDate).format('HH:mm')} - ${moment(this.attention.toDate).format('HH:mm')}`;
                this.attentionDurationCast = this.calendarService.practiceCountedByHours(this.attention.practice) ? this.attentionsService.getHourQuantity(this.attention) : 1
                break;
            
            case 'DSP': // Display
                this.attentionFromDateCast = `${moment(this.attention.fromDate).format('DD-MM-YYYY')}`;
                this.attentionHourFromToCast = `${moment(this.attention.fromDate).format('HH:mm')} - ${!!this.attention.toDate ? moment(this.attention.toDate).format('HH:mm') : ''}`;
                this.attentionDurationCast = this.calendarService.practiceCountedByHours(this.attention.practice) ? this.attentionsService.getHourQuantity(this.attention) : 1            
                this.editForm.get(this.TIME_FROM).clearValidators(); // Hour in
                this.editForm.get(this.TIME_TO).clearValidators(); // Hour out
                this.editForm.get(this.QUANTITY).clearValidators(); // Cant
                break;
        }
        
        this.radiobuttonsDisabled = false;
        this.selectionToUpdateValue = '';

        this.agreementAttentionDefault = (this.attention.agreements.find(a => a.id == this.attention.agreementId)).id;

        let hours, minutes;

        // Get diff to - from
        if (!!this.attention.toDate) {
            this.durationAttention = moment.duration(moment(this.attention.toDate).diff(this.attention.fromDate));
            hours = moment.duration(moment(this.attention.toDate).diff(this.attention.fromDate)).hours();
            minutes = moment.duration(moment(this.attention.toDate).diff(this.attention.fromDate)).minutes();
        }

        // Calc "Cantidad de horas"
        this.changeHourFinish();
        // Set agreements
        this.setAgreementsAllowed();
        // Set agreement for this attention
        this.onSelectedAgreement(this.attention.agreementId) // Init select agreement default        
    }

	createEditForm(): FormGroup {
        return this.formBuilder.group({
            [this.AGREEMENT]: ['', [CustomValidators.required('Acuerdo prestacional requerido')]],
            [this.STATE_ATTENTION]: [{value:'', disabled:true}],

            [this.DATE_FROM]: ['', [CustomValidators.required('Requerido')]], // attention.fromDate
            [this.TIME_FROM]: ['', [CustomValidators.required('Requerido')]], // Hour in
            [this.TIME_TO]: ['', [CustomValidators.required('Requerida')]], // Hour out

            [this.DATE_TO]: [], // attention.toDate

            [this.DATE_FROM_UPDATE]: [''],
            [this.DATE_TO_UPDATE]: [''],

            [this.QUANTITY]: [ this.calculateAttentionDuration(), [CustomValidators.required('Requerida')] ],
            radioButton: new FormControl({ value: '', disabled: false },),
        });
    }

    onSelectedAgreement(agreementId: number) {

        this.agreementSelected = this.attention.agreements.find(agreement => agreement.id == agreementId);
        this.agreementSelected.provider = { ...this.agreementSelected.provider, fullName: `${this.agreementSelected.provider.surname} ${this.agreementSelected.provider.name} - $${this.agreementSelected.providerFee} - ${moment(this.agreementSelected.fromDate).format('DD-MM-YYYY')} ${!!this.agreementSelected.toDate ? `a ${moment(this.agreementSelected.toDate).format('DD-MM-YYYY')}` : ' - en adelante'}` }
        this.agreementForm = this.agreementSelected.id

        const rangeProvision = new momentRange.DateRange(moment(this.attention.provisionFeeSelected?.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!this.attention.provisionFeeSelected?.toDate ? moment(this.attention.provisionFeeSelected.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);
        const rangeAgreement = new momentRange.DateRange(moment(this.agreementSelected?.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!this.agreementSelected?.toDate ? moment(this.agreementSelected?.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);

        this.minDate = rangeProvision.intersect(rangeAgreement)?.start.toDate();
        this.maxDate = rangeProvision.intersect(rangeAgreement)?.end.toDate();

        if (this.agreementAttentionDefault == agreementId) {
            this.showRadiobuttons = false;
        } else {
            this.showRadiobuttons = true;
            this.editForm.patchValue({
                [this.DATE_FROM_UPDATE]: this.minDate,
                [this.DATE_TO_UPDATE]:  (!this.agreementSelected.toDate && !this.attention.provisionFeeSelected.toDate) ? this.lastDate : this.maxDate,
            })
        }
    }    

    calculateAgreementId(agreement: Agreement) {
        return agreement.id;
    }

    calculateAgreementName(agreement: Agreement) {
        let result = (!!agreement.provider?.name || !!agreement.provider?.surname)
            ? agreement.provider.name + ' ' + agreement.provider.surname
            : ' - ';

        result += !!agreement.providerFee ? ' - $' + agreement.providerFee : ' - ';

        return result + ' - ' + (!!agreement.fromDate && !!agreement.toDate ? `${moment(agreement.fromDate).format('DD/MM/YYYY')} a ${moment(agreement.toDate).format('DD/MM/YYYY')}` : `${moment(agreement.fromDate).format('DD/MM/YYYY')}`);
    }    

    validateCheckTypeToUpdateDate() {
        this.editForm.get(this.DATE_FROM_UPDATE).setValidators( [CustomValidators.required('Requerida')] );
        this.editForm.get(this.DATE_TO_UPDATE).setValidators( [CustomValidators.required('Requerida')] );
        if (this.radiobuttonsDisabled) {
            return false
        }
        return (this.selectionToUpdateValue == 'date') ? true : false;
    }

    validateCheckTypeToUpdateForward() {
        this.editForm.get(this.DATE_FROM_UPDATE).setValidators(null);
        this.editForm.get(this.DATE_TO_UPDATE).setValidators(null);
        if (this.radiobuttonsDisabled) {
            return false
        }
        return (this.selectionToUpdateValue == 'forward') ? true : false;
    }

    addCustomValidatorsfromDate(date:Date) {
        this.editForm.get('fromDate').clearValidators();
        this.editForm.get('fromDate').setValidators(Validators.compose([CustomValidators.required('Fecha requerida'), CustomValidators.dateLowerThan(date)]));
    }

    clickRadiobutton(event) {
        switch (event.value) {
            case 'forward':
                if (this.selectionToUpdateValue == 'forward') {
                    this.selectionToUpdateValue = '';
                } else {
                    this.selectionToUpdateValue = event.value;
                }
                break;

            case 'date':
                if (this.selectionToUpdateValue == 'date') {
                    this.selectionToUpdateValue = '';
                } else {
                    this.selectionToUpdateValue = event.value;
                }
                break;
        }
    }    

    checkState(element?: MatRadioButton) {
        setTimeout(() => {
            if (!!this.selectionToUpdateValue && this.selectionToUpdateValue === element?.value) {
                element.checked = false;
                this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-program-focused');
                this.selectionToUpdateValue = null;
            } else {
                this.selectionToUpdateValue = element.value;
            }
        })
    }

    attentionQuantityEdited() {
        this.dateToForm = moment(this.dateFromForm).add(this.durationAttention, 'hours').toDate();
    }

    calculateAttentionDuration() {
        let fromDate = this.attention?.fromDate ? this.attention.fromDate : null;
        let toDate = this.attention?.toDate ? this.attention.toDate : null;
        return !!toDate ? moment(toDate).diff(moment(fromDate), 'hours') : null;
    }    

    attentionDateEdited(event) {
        if (event) {
            this.radiobuttonsDisabled = true;
            this.selectionToUpdateValue = '';
            this.dateToForm = moment(this.dateFromForm).add(this.durationAttention, 'hours').toDate();
        }
    }    

    onClickCloseDialog() {
        if (this.inputMode === 'DSP') {
            this.mode = 'DSP';
            this.setDisplayMode();
        } else {
            this.closeDialog.emit();
        }
    }    

    onEditAttention() {
        this.loadingEditAttention = true;

        let attention: Attention = {
            id: this.attention.id,
            fromDate: moment(this.dateFromForm).toDate(),
            agreementId: this.agreementSelected.id,
            practiceId: this.attention.practiceId,
            providerFee: this.agreementSelected.providerFee,
        };

        // Set time
        attention.fromDate = moment(attention.fromDate).set({ hour: moment(this.timeFromForm, 'HH:mm').hour(), minute: moment(this.timeFromForm, 'HH:mm').minute() }).toDate();
        this.attentionQuantityEdited();

        if (!!this.editForm.get(this.AGREEMENT).value && this.editForm.get(this.AGREEMENT).dirty) {
            attention = { ...attention, providerId: this.agreementSelected.provider.id }
        }
        if (!!this.dateToForm && !!this.editForm.get(this.QUANTITY).value) {
            this.durationAttention = this.editForm.get(this.QUANTITY).value
            if ([AttentionStates.REALIZADA, AttentionStates.FACTURABLE].includes(this.attention.state.id)) {
                attention = { ...attention, quantity: this.durationAttention };
            }
        }
        if (!!this.dateToForm && !!this.durationAttention) {
            this.dateToForm = moment(attention.fromDate).add(this.durationAttention, 'hours').toDate();
            attention = { ...attention, toDate: this.dateToForm }; // Add toDate
        } else {
            delete attention.toDate;
        }

        if (!this.validateCheckTypeToUpdateDate() && !this.validateCheckTypeToUpdateForward()) {
            this.attentionsFacade.patchAttention(attention).toPromise()
                .then(_ => {
                    // Flag to close pop-up
                    this.inputMode = '___'
                    this.onClickCloseDialog()})
                .then(_ => {
                    // Reload attentions on view
                    this.refreshDataFiltered.emit();

                    this.swalWithCustomizeButtons.fire({
                        title: 'Atención modificada con éxito',
                        icon: 'success',
                    })
                })
                .finally(() => this.loadingEditAttention = false);
        } else {
            let attentionToSend = { ...attention, fromDateAttention: attention.fromDate, toDateAttention: attention.toDate, agreementsIds: [attention.agreementId] }
            delete attention.id;
            delete attentionToSend.agreementId;
            delete attentionToSend.practiceId;
            if (!!this.validateCheckTypeToUpdateDate()) {
                attentionToSend.fromDate = moment(this.editForm.get(this.DATE_FROM_UPDATE).value).toDate();
                attentionToSend.toDate = moment(this.editForm.get(this.DATE_TO_UPDATE).value).toDate();
            } else {
                delete attentionToSend.fromDate;
                delete attentionToSend.toDate;
            }
            this.attentionsFacade.patchAttentionByAgreement(attentionToSend).toPromise()
                .then(_ => {
                    // Flag to close pop-up
                    this.inputMode = '___'
                    this.onClickCloseDialog()})
                .then(_ => {
                    // Reload attentions on view
                    this.refreshDataFiltered.emit();

                    this.swalWithCustomizeButtons.fire({
                        title: 'Atenciones modificadas con éxito',
                        icon: 'success',
                    })
                })
                .finally(() => this.loadingEditAttention = false);
        }
    }

    setAgreementsAllowed() {
        // Filter agreement and controls case attention state
        switch (this.attention.state.id) {
            case AttentionStates.AGENDADA:
            case AttentionStates.NO_REALIZADA:
                // Nothing
                // All agreements
                break;

            case AttentionStates.EN_CURSO:
            case AttentionStates.DEBITADA:
            case AttentionStates.REALIZADA:
            case AttentionStates.FACTURABLE:
                if ((AttentionStates.REALIZADA || AttentionStates.FACTURABLE) && !this.attention.hasEvolutions) {
                    // ALL agreements
                } else {
                    // Agreements with same provider
                    this.attention.agreements = [ ...this.attention.agreements.filter(agree => agree.provider.id == this.attention.provider.id) ] 
                }
                break;

            case AttentionStates.INFORMADA:
            case AttentionStates.NO_INFORMADA:
                // Combo agreements read-only
                this.editForm.get(this.AGREEMENT).disable();
                break;

            default:
                break;
        }        
    }

    onClickDeleteAttention() {
        this.onEventDelete.emit(this.attention)
    }

    // fx to validates hours
    durationChanged() {
        let hours = this.quantityForm;
        // let minutes: any = hours != 24 ? this.minuteDurationForm : 0;
        let hourFinish = moment(this.editForm.get(this.TIME_FROM).value, 'HH:mm').add({ hours: hours })
        // if (hours != null && (minutes != null && minutes != "")) {
        if ( hours != null ) {            
            this.editForm.get(this.TIME_TO).setValue(hourFinish.format('HH:mm'));
            this.editForm.get(this.TIME_TO).markAsPristine();
            this.editForm.get(this.QUANTITY).markAsPristine();
        }
        this.validateIfNextDay();
    }

    validateIfNextDay() {
        this.isNextDay = moment(this.timeToForm, 'HH:mm').diff(moment(this.timeFromForm, 'HH:mm')) <= 0;
    }
    
    changeHourFinish() {
        let timeString = this.editForm.get(this.TIME_TO).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        let timeTo;
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.editForm.get(this.TIME_TO).setValue(formattedTimeString);

                let timeFrom = moment(this.editForm.get(this.TIME_FROM).value, 'HH:mm')
                // Same day
                if (parseInt(timeString) > parseInt(this.timeFromForm.replace(':', ''))) {
                    timeTo = moment(this.editForm.get(this.TIME_TO).value, 'HH:mm')
                    // Next day
                } else {
                    timeTo = moment(timeFrom).add(1, 'day')
                    timeTo = moment(timeTo).set({ hour: timeString.substring(0, 2), minute: timeString.substring(2,) })
                }

                let timeDifference = moment.duration(timeTo.diff(timeFrom))
                this.editForm.get(this.QUANTITY).setValue(Math.ceil(timeDifference.asHours()));
            }
        }
        this.editForm.get(this.QUANTITY).markAsPristine();
        this.validateIfNextDay();
    }    

    validateTimeValid() {
        let timeString = this.editForm.get(this.TIME_FROM).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.editForm.get(this.TIME_FROM).setValue(formattedTimeString)

                if (this.editForm.get(this.TIME_TO).dirty) {
                    this.changeHourFinish();
                } else if (this.editForm.get(this.QUANTITY).dirty ) {
                    this.durationChanged();
                } else {
                    this.changeHourFinish();
                }
            }
        }
    }

    setDisplayMode() {
        this.mode = 'DSP'
        this.initData();
        this.showOnlyView = true;
        this.showEditAttention = false;
        this.showReplaceAgreement = false;
        // Buttons
        this.isButtonEditEnabled = true;
        this.isButtonReplaceAgreementEnabled = true;
        this.isButtonDeleteEnabled = true;
    }

    setEditMode() {
        this.mode = 'UPD'
        this.initData();
        this.showOnlyView = false;
        this.showEditAttention = true;
        this.showReplaceAgreement = false;
        // Buttons
        this.isButtonEditEnabled = true;
        this.isButtonReplaceAgreementEnabled = false;
        this.isButtonDeleteEnabled = false;
    }

    setDeleteMode() {
        this.mode = 'DEL'
        this.initData();
        this.showOnlyView = true;
        this.showEditAttention = false;
        this.showReplaceAgreement = false;
        this.onClickDeleteAttention();
        // Buttons
        this.isButtonEditEnabled = true;
        this.isButtonReplaceAgreementEnabled = true;
        this.isButtonDeleteEnabled = true;
    }

    setReplaceAgreementMode() {
        this.mode = 'RPA'
        this.initData();
        this.showOnlyView = false;
        this.showEditAttention = false;
            this.showReplaceAgreement = true;
        // Buttons
        this.isButtonEditEnabled = false;
        this.isButtonReplaceAgreementEnabled = true;
        this.isButtonDeleteEnabled = false;
    }
}
