import { Injectable } from '@angular/core';
import { BaseState } from './base.state';
import { BehaviorSubject } from 'rxjs';
import { Pagination } from '../../shared/models/pagination';
import { Observable } from 'rxjs';
import { Tag } from 'src/app/shared/models/tags';
import { MetaDataTags } from 'src/app/abstraction/tags.facade';

@Injectable({
    providedIn: 'root',
})
export class TagsState extends BaseState {
    override store = {

        // Categories of Solutions
        tags$: new BehaviorSubject<Tag[]>(null),
        loadingGetTags$: new BehaviorSubject<boolean>(null),
        loadingCreatingTags$: new BehaviorSubject<boolean>(null),
        loadingUpdatingTags$: new BehaviorSubject<boolean>(null),

        // Pagination
        pagination$: new BehaviorSubject<Pagination>(null),
        loadingGettingPagination$: new BehaviorSubject<boolean>(false),

        // Metadata
        metaDataTags$: new BehaviorSubject<MetaDataTags>(null),

        // Count
        tagsCount$: new BehaviorSubject<number>(null),
    };

    // Categories of Evolutions
    getTags$() {
        return this.store.tags$.asObservable();
    }

    setTags(tags: Tag[]) {
        this.store.tags$.next(tags);
    }

    isLoadingGetTags$() {
        return this.store.loadingGetTags$.asObservable();
    }

    setLoadingGetTags(isLoadingGetTags: boolean) {
        this.store.loadingGetTags$.next(isLoadingGetTags);
    }

    // ADD
    isLoadingCreatingTag$() {
        return this.store.loadingCreatingTags$.asObservable();
    }

    setLoadingCreatingTag(isLoadingCreatingTags: boolean) {
        this.store.loadingCreatingTags$.next(isLoadingCreatingTags);
    }

    addTag(tag: Tag) {
        this.add<Tag>({ data: tag, storeRefAttribute: this.store.tags$ })
    }

    // UPDATE
    setLoadingUpdatingTag(isLoadingUpdatingTags: boolean) {
        this.store.loadingUpdatingTags$.next(isLoadingUpdatingTags);
    }

    updateTag(tag: Tag, id: number) {
        this.update<Partial<Tag>>({ data: tag, dataId: id, storeRefAttribute: this.store.tags$ })
    }

    isLoadingUpdatingTag$() {
        return this.store.loadingUpdatingTags$.asObservable();
    }

    // Pagination
    getPagination$(): Observable<Pagination> {
        return this.store.pagination$.asObservable();
    }

    setPagination(pagination: Pagination) {
        this.store.pagination$.next(pagination);
    }


    isLoadingGettingPagination$() {
        return this.store.loadingGettingPagination$.asObservable();
    }

    setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
        this.store.loadingGettingPagination$.next(isLoadingGettingPagination);
    }

    // Metadata
    getMetaDataTags$(): Observable<MetaDataTags> {
        return this.store.metaDataTags$.asObservable();
    }

    setMetaDataTags(md: MetaDataTags) {
        this.store.metaDataTags$.next(md);
    }

    // Count
    getTagsCount$(): Observable<number> {
        return this.store.tagsCount$.asObservable();
    }

    setTagsCount(count: number) {
        this.store.tagsCount$.next(count);
    }

}
