import { Component, Input, OnInit, TemplateRef, ViewChild, SimpleChanges, ElementRef, ChangeDetectorRef, TestabilityRegistry } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Requisition, RequisitionReason, RequisitionState, RequisitionType } from '../../../../shared/models/requisition';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PatchRequisitionBody, RequisitionService, RequisitionsQPS } from '../../../../core/services/requisition.service';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/enums/routes';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { GeneralService } from '../../../../core/services/general.service';
import { RequisitionsFacade } from '../../../../abstraction/requisitions.facade';
import { RequisitionStates } from 'src/app/core/enums/requisitionsStates';
import { RequisitionReasons } from 'src/app/core/enums/requisitionsReasons';
import { MatRadioChange } from '@angular/material/radio';
import { StorageService } from '../../../../core/services/storage.service';
import { fromEvent, Subscription, timer } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { RequisitionTypes } from 'src/app/core/enums/requisitionTypes';
import { debounce, filter, map, skip, startWith } from 'rxjs/operators';
import { Operator } from 'src/app/shared/models/operator';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import * as moment from 'moment';


@Component({
  selector: 'app-admissions-list',
  templateUrl: './admissions-list.component.html',
  styleUrls: ['./admissions-list.component.scss'],
})

export class AdmissionsListComponent implements OnInit {

  @Input("requisitions") requisitions: Requisition[];
  @Input("loading") loading: boolean;
  @Input("columns") columns: string; // D: default, A: Archived
  @Input("operator") operatorSelected: number

  @ViewChild('updateState', { static: true }) updateState: TemplateRef<any>;
  @ViewChild('viewRejectCase', { static: true }) viewRejectCase: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;

  _dialogRef;
  formUpdateState: FormGroup;
  formRejectCase: FormGroup;
  filterForm: FormGroup;
  stateSelected: RequisitionState;
  @Input('indicatorSelected') indicatorSelected : string;
  typeSelected: RequisitionType;
  _operators: Subscription;
  operators: Operator[];

  public readonly OBSERVATIONS = 'observations';
  public readonly REQUISITION_STATE = 'requisitionState'
  public readonly REQUISITION_TYPE = 'requisitionType'
  public readonly OPERATOR = 'operatorId';
  public readonly OPERATOR_SEARCHING = 'operatorSearching';
  public readonly ACTIVE_STATE = 'validityState'
  public readonly REQUISITION_REASON = 'requisitionReason'

  activeRequisitionStates: RequisitionState[] = [
    {
      id: 0,
      description: 'Todos'
    },
    {
      id: 1,
      description: RequisitionStates.DRAFT
    },
    {
      id: 2,
      description: RequisitionStates.WITHOUT_WARNING
    },
    {
      id: 3,
      description: RequisitionStates.WAITING_FOR_INFO
    },
    {
      id: 4,
      description: RequisitionStates.SEARCHING_RESOURCES
    },
    {
      id: 5,
      description: RequisitionStates.CONFIRMED
    },
    {
      id: 6,
      description: RequisitionStates.BUDGETED
    },
    {
      id: 7,
      description: RequisitionStates.PENDING
    },
  ]

  requisitionTypes: RequisitionType[] = [
    {
      id: 0,
      description: 'Todos'
    },
    {
      id: 1,
      description: RequisitionTypes.REQUEST
    },
    {
      id: 2,
      description: RequisitionTypes.PROVISIONAL_INCREMENT
    },
    {
      id: 3,
      description: RequisitionTypes.AUTHORIZATION
    },
    {
      id: 4,
      description: RequisitionTypes.PARTIAL_DISCHARGE
    },
    {
      id: 5,
      description: RequisitionTypes.ENDING
    }
  ]

  requisitionValidityStates = [
    {
      id: 1,
      description: "Activas"
    },
    {
      id: 2,
      description: "Archivadas"
    }
  ]

  requisitionReasons: RequisitionReason[] = [
    {
      id: 0,
      description: 'Todos'
    },
    {
      id: 1,
      description: RequisitionReasons.ACCION_REALIZADA
    },
    {
      id: 2,
      description: RequisitionReasons.RECHAZADO_AD
    },
    {
      id: 3,
      description: RequisitionReasons.DESESTIMADO_F
    },
    {
      id: 4,
      description: RequisitionReasons.NO_AUTORIZADO
    },
    {
      id: 5,
      description: RequisitionReasons.SIN_RESPUESTA_F
    },
    {
      id: 6,
      description: RequisitionReasons.AUTORIZADO
    },

  ]

  displayedColumns: string[];
  displayedColumnsDefault: string[] = ['patient', 'type', 'state', 'caseType', 'complexity', 'operator', 'financier', 'date', 'actions'];
  displayedColumnsArchived: string[] = ['patient', 'motive', 'type', 'caseType', 'complexity', 'operator', 'financier', 'date'];

  sortedData: Requisition[];

  requisitionToChange: Requisition;

  optionsToReject = RequisitionReasons;
  optionsListReject: string[];
  reasonRejectSelected: number;
  changeStateSelected: number;

  metaDataRequisitions: RequisitionsQPS;
  _mdr: Subscription;
  _pr: Subscription;
  _requisitionsLoaded: Subscription;
  _input: Subscription;


  numberOfRows: number;
  dataSource;

  get operator() {
    return this.filterForm.get(this.OPERATOR).value;
  }

  set operator(operator: number){
    this.filterForm.get(this.OPERATOR).setValue(operator);
  }

  get activeStateSelected(){
    return this.filterForm.get(this.ACTIVE_STATE).value;
  }

  set activeStateSelected(state){
    this.filterForm.get(this.ACTIVE_STATE).setValue(state);
  }

  get reasonSelected(){
    return this.filterForm.get(this.REQUISITION_REASON).value;
  }

  set reasonSelected(reason){
    this.filterForm.get(this.REQUISITION_REASON).setValue(reason);
  }




  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public router: Router,
    public requisitionService: RequisitionService,
    public generalService: GeneralService,
    public requisitionsFacade : RequisitionsFacade,
    private storageService: StorageService,
    private entitiesFacade: EntitiesFacade,
    ) { }

  swalWithCustomizeButtons;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.data = [];

    this.swalWithCustomizeButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btnSwalConfirm',
        cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    this.filterForm = this.createFilterForm()
    this.operator = this.operatorSelected;

    // Metadata - get qps
    this.suscribeToData();
  }

  suscribeToData(){
    this._mdr = this.requisitionsFacade.getMetaDataRequisitions$().subscribe(mdr => {
      if ( this.storageService.getObjectfromSessionStorage ( 'filterListCases' )) {
        mdr = this.storageService.getObjectfromSessionStorage ( 'filterListCases' );
      }

        this.metaDataRequisitions = mdr;
        if(!!this.metaDataRequisitions?.requisitionsStateId && this.metaDataRequisitions?.requisitionsStateId.length == 1){
          let state = this.activeRequisitionStates.find(rs => rs.id == this.metaDataRequisitions.requisitionsStateId[0]);
          this.stateSelected = state
        }
    })

    this._operators = this.entitiesFacade.loadOperators({active: true}).subscribe((operators : Operator[]) => {
      this.operators = operators;
    })

    setTimeout(() => {

      // Quickfilter is saved too in session
      if ( this.storageService.getObjectfromSessionStorage ( 'filterListRequisitions' ) ) {
        const filter = this.storageService.getObjectfromSessionStorage ( 'filterListRequisitions' );
        if (filter.searchPatient || filter.searchCif || filter.searchDocument) {
          this.inputSearch.nativeElement.value = filter.searchPatient || filter.searchCif || filter.searchDocument;
        }
      }

      this._input = fromEvent(this.inputSearch.nativeElement, 'keyup').
        pipe(
          map((event: any) => event.target.value),
          startWith(''),
          filter((value: string) => (value.length != 1 && value.length != 2)),
          skip(1),
          debounce((string: string) => (string.length > 1) ? timer(500) : timer(1250)),
        ).subscribe((st) => {

          let rfp: RequisitionsQPS = this.metaDataRequisitions;

          console.log("Quickfilter cases");

          delete rfp.searchPatient;
          delete rfp.searchCif;
          delete rfp.searchDocument;

          if (st.length != 0){
            if (this.hasNumbers(st) && this.hasLetters(st)) {
              // By Cif
              rfp.searchCif = st;
            } else if (this.hasNumbers && !this.hasLetters(st)) {
              // By Document
              rfp.searchCif = st;
              rfp.searchDocument = st;
            } else {
              // By Patient
              rfp.searchPatient = st;
            }
          }
          rfp.page = 0;
          this.storageService.setObjectToSessionStorage ( 'filterListRequisitions', rfp );
          this._requisitionsLoaded = this.requisitionsFacade.loadRequisitions(rfp).subscribe();
        });
    }, 500);
  }

  hasNumbers(st: string): boolean {
    const numbers = '0123456789';
    for (let i = 0; i < st.length; i++) {
      if (numbers.indexOf(st.charAt(i), 0) != -1) {
        return true;
      }
    }
    return false;
  }


  hasLetters(st: string): boolean{
    const numbers = '0123456789';
    for (let i = 0; i < st.length; i++) {
      if (numbers.indexOf(st.charAt(i), 0) == -1) {
        return true;
      }
    }
    return false;
  }

  sortData(sort: Sort) {
    let sortActive:string = '';
    switch (sort.active) {
      case 'patient':
        sortActive = 'surname' // Column patient ordering for surname
         break;
      case 'date':
        sortActive = 'fromDate' // column date ordering for firstAuditDate
        break;
      default:
        sortActive = ''
        break;
    }
    const qps: RequisitionsQPS = { ...this.metaDataRequisitions, order:sort.direction.toUpperCase(), sort:sortActive }
    this.requisitionsFacade.loadRequisitions( qps );
  }

  createformUpdateState(): FormGroup {
    return this.formBuilder.group({
      radioButton: new FormControl("", [Validators.required])
    })
  }

  changeState( requisition: Requisition ) {
    this.formUpdateState = this.createformUpdateState();
    this.requisitionToChange = requisition;

    this.openDialog(
      'Modificar estado',
      this.updateState,
      { maxHeight: '95vh', minWidth: '25%' },
      () => {}
   );
  }

  openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
    this._dialogRef = this.dialog.open(DialogComponent, {
       disableClose: true,
       minWidth: style.minWidth,
       maxHeight: style.maxHeight,
       data: { template, title },
    });
    this._dialogRef.afterClosed().subscribe(() => afterClosed());
 }

  onClickCloseDialog() {
    this._dialogRef.close();
  }

  onClickConfirmUpdateState() {
    const req: PatchRequisitionBody = {
      id: this.requisitionToChange.requisitionResponseDto.id,
      requisitionState: { id: this.changeStateSelected },
    }
    this.patchRequisition( req, 'Cambio de estado de solicitud realizado con éxito' );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes['requisitions'] && changes['requisitions'].currentValue && this.dataSource ) {
      this.dataSource.data = changes['requisitions'].currentValue.slice();
    }
    if(changes['operatorSelected']){
      this.operator = this.operatorSelected;
      this.onFilter();
    }

    if(changes['indicatorSelected']){
      this.stateSelected = this.activeRequisitionStates.find(rs => rs.description == this.indicatorSelected);
      this.activeStateSelected = this.requisitionValidityStates[0];
      this.columns = '';
      this.onFilter();
    }

    switch (this.columns) {
      case 'A':
          this.displayedColumns = this.displayedColumnsArchived;
        break;

      default:
        this.displayedColumns = this.displayedColumnsDefault;
        break;
    }

  }

  switchToCase( requisition: Requisition ) {

    this.requisitionToChange = requisition;
    const newState = Object.keys(RequisitionStates).indexOf('ARCHIVED');
    const req: PatchRequisitionBody = {
      id: this.requisitionToChange.requisitionResponseDto.id,
      requisitionState: { id: newState },
      requisitionReason: { id: Object.values(RequisitionReasons).indexOf(RequisitionReasons.AUTORIZADO) }
    }
    if (this.isTaskRequisition(requisition) && !this.isProvisionalIncrementType(requisition)){
      req.requisitionReason = { id: Object.values(RequisitionReasons).indexOf(RequisitionReasons.ACCION_REALIZADA) }
    }

    this.swalWithCustomizeButtons.fire({
      title: 'Esta acción no se puede revertir',
      icon: 'question',
      html: this.getConfirmationMessage(this.requisitionToChange),
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      reverseButtons: true,
      preConfirm: () => {
        return this.requisitionsFacade.updateRequisition( req ).toPromise()
          .then((_) => {
            this.swalWithCustomizeButtons.fire({
              html: this.getSuccessMessage(this.requisitionToChange),
              icon: 'success',
              timer: 2500,
              showConfirmButton: false
            });
          })
          .then((_) => this.navigateToCase( this.requisitionToChange.caseRequisitionResponseDto.id ) )
          .catch((err) => {
            console.log('error', err);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }

  getConfirmationMessage(requisitionToChange : Requisition){
    let message = '¿Está seguro de que desea '
    message += (this.isTaskRequisition(requisitionToChange) ? 'marcar' : 'convertir');
    message +=  ` la solicitud <b>#${ this.requisitionToChange.requisitionResponseDto.id }</b> `;
    message += (this.isTaskRequisition(requisitionToChange) ? 'como realizada?' : 'a caso?');
    return message;
  }

  getSuccessMessage(requisitionToChange : Requisition){
    return `La solicitud <b>#${ this.requisitionToChange.requisitionResponseDto.id }</b> se ${ this.isTaskRequisition(requisitionToChange) ? 'marcó como realizada' : 'convirtió en caso' } exitosamente`;
  }

  calculateNameRequisitionState(indicator : RequisitionState) {
    return indicator ? indicator.description : null;
  }

  calculateIdRequisitionState(indicator : RequisitionState) {
    return indicator ? indicator.id : null;
  }

  calculateNameRequisitionReason(indicator : RequisitionReason) {
    return indicator ? indicator.description : null;
  }

  calculateIdRequisitionReason(indicator : RequisitionReason) {
    return indicator ? indicator.id : null;
  }

  calculateNameRequisitionType(indicator : RequisitionType) {
    return indicator ? indicator.description : null;
  }

  calculateIdRequisitionType(indicator : RequisitionType) {
    return indicator ? indicator.id : null;
  }

  onSelectStateFilter(event){
    this.stateSelected = this.activeRequisitionStates.find(ind => ind.id == event || ind.description == event);
    this.onFilter();
  }

  onSelectTypeFilter(event){
    this.typeSelected = this.requisitionTypes.find(ind => ind.id == event || ind.description == event);
    this.onFilter();
  }

  onFilter(){
    let rfp: RequisitionsQPS = this.metaDataRequisitions;

    if(!!this.stateSelected && !!this.stateSelected.id && this.stateSelected.description != 'Todos'){
      delete rfp.requisitionsStateId
      rfp.requisitionsStateId = [this.stateSelected.id]
    } else if(this.stateSelected == null || this.stateSelected == undefined || this.stateSelected.description == 'Todos'){
      rfp.requisitionsStateId = [];
      this.activeRequisitionStates.forEach(st => {
        rfp.requisitionsStateId = [...rfp.requisitionsStateId, st.id]
        rfp.requisitionsStateId = rfp.requisitionsStateId.filter(id => id != 0 && id != 8)
      })
    }
    if(!!this.typeSelected && !!this.typeSelected.id && this.typeSelected.description != 'Todos'){
      delete rfp.requisitionsTypeId
      rfp.requisitionsTypeId = [this.typeSelected.id]
    } else if(this.typeSelected == null || this.typeSelected == undefined || this.typeSelected){
        rfp.requisitionsTypeId = [];
        this.requisitionTypes.forEach(type => {
          rfp.requisitionsTypeId = [...rfp.requisitionsTypeId, type.id];
          rfp.requisitionsTypeId = rfp.requisitionsTypeId.filter(t => t != 0);
        })
    }

    if(this.activeStateSelected == 2){
      rfp.requisitionsStateId = [8]
      if(this.reasonSelected == 0){
        rfp.requisitionReasons = this.requisitionReasons.filter(r => r.description != 'Todos').map(r => r.id);
      } else {
        rfp.requisitionReasons = [this.reasonSelected];
      }
    } else {
      delete rfp.requisitionReasons;
    }

    if(!!this.operator){
      rfp.operatorId = this.operator;
      this.requisitionsFacade.loadRequisitionsIndicators({operatorId: this.operator, fromDate: moment().subtract(60, 'days').toDate()})
    } else {
      delete rfp.operatorId;
      this.requisitionsFacade.loadRequisitionsIndicators({fromDate: moment().subtract(60, 'days').toDate()})
    }
    this.storageService.setObjectToSessionStorage( 'filterListRequisitions', rfp );
    this._requisitionsLoaded = this.requisitionsFacade.loadRequisitions(rfp).subscribe();
  }

  createFilterForm():FormGroup{
    return this.formBuilder.group({
      [this.REQUISITION_STATE]: [''],
      [this.REQUISITION_TYPE]: [''],
      [this.OPERATOR]: ['', []],
      [this.OPERATOR_SEARCHING]: [''],
      [this.ACTIVE_STATE]: [this.requisitionValidityStates[0]?.id?.toString()],
      [this.REQUISITION_REASON]: ['']
    })
  }

  navigateToCase( caseId: number ) {
    this.generalService.setGoBack({ route: this.generalService.checkRouteGoToAdmissions( this.router.url ), title: 'Admisiones' });
    const requisition : Requisition = this.requisitions.find(req => req.requisitionResponseDto.caseId == caseId);
    if (!!requisition){
      let requisitionStorage = {
        caseId: requisition.caseRequisitionResponseDto.id,
        requisitionId: requisition?.requisitionResponseDto?.id,
        requisitionTransitionStates: requisition?.requisitionResponseDto.requisitionTransitionStates,
        requisitionType: requisition.requisitionResponseDto.requisitionType,
        requisitionState: requisition.requisitionResponseDto.requisitionState,
      };
      this.storageService.setObjectToSessionStorage("requisition", requisitionStorage);
    }
    this.router.navigate([ ROUTES.CASES, caseId ]);
  }

  createformRejectCase(): FormGroup {
    return this.formBuilder.group({
      [this.OBSERVATIONS]: ['', [CustomValidators.required('Ingrese la descripción del motivo')]],
      radioButton: new FormControl("", [Validators.required])
    })
  }

  clickRadiobutton(event: MatRadioChange) {
    this.reasonRejectSelected =  Object.values(RequisitionReasons).indexOf(event.value); // Save option number
  }

  clickRadiobuttonChangeState(event: MatRadioChange) {
    this.changeStateSelected =  event.value; // Save option number
  }

  rejectCase(requisition: Requisition) {

    this.requisitionToChange = requisition;
    this.formRejectCase = this.createformRejectCase();
    this.optionsListReject = [ RequisitionReasons.RECHAZADO_AD, RequisitionReasons.DESESTIMADO_F, RequisitionReasons.NO_AUTORIZADO, RequisitionReasons.SIN_RESPUESTA_F ];

    this.openDialog(
      'Desestimar',
      this.viewRejectCase,
      { maxHeight: '95vh', minWidth: '25%' },
      () => { }
    );
  }

  onClickConfirmRejectCase() {

    const newState = Object.keys(RequisitionStates).indexOf('ARCHIVED');

    const req: PatchRequisitionBody = {
      id: this.requisitionToChange.requisitionResponseDto.id,
      requisitionState: { id: newState },
      requisitionReason: { id: this.reasonRejectSelected },
      observation: this.formRejectCase.get(this.OBSERVATIONS).value
    }

    this.requisitionsFacade.updateRequisition( req ).subscribe(
      req => {
        this.swalWithCustomizeButtons.fire({
          title: 'Caso desestimado con éxito',
          icon: 'success',
          timer: 2500,
          showConfirmButton: false
        }).then(() => {
          this.filterRequisitionsOnChange();
          this._dialogRef.close();
        })
      })
  }

  clickedRow( row: Requisition ) {
    this.navigateToCase( row.requisitionResponseDto.caseId );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isChangeStateEnabled( requisition: Requisition ): Boolean {
    let requisitionTransitionStates = requisition.requisitionResponseDto.requisitionTransitionStates;
    if (requisitionTransitionStates?.length != 1 ||
      (requisitionTransitionStates?.length == 1 && requisitionTransitionStates[0].description != RequisitionStates.ARCHIVED)){
      return true;
    } else {
      return false;
    }
  }

  transitionStatesPermitted(transitions : RequisitionState[]){
    return transitions.filter(t => t.description != RequisitionStates.ARCHIVED);
  }

  isRejectCaseEnabled( requisition: Requisition ): Boolean {
    const idsEnabled = [
                          Object.keys(RequisitionStates).indexOf('WITHOUT_WARNING'),
                          Object.keys(RequisitionStates).indexOf('WAITING_FOR_INFO'),
                          Object.keys(RequisitionStates).indexOf('SEARCHING_RESOURCES'),
                          Object.keys(RequisitionStates).indexOf('CONFIRMED'),
                          Object.keys(RequisitionStates).indexOf('BUDGETED'),
                      ]
    return idsEnabled.includes( requisition.requisitionResponseDto.requisitionState.id );
  }

  isSwitchToCaseEnabled( requisition: Requisition ): Boolean {
    const idsEnabled = [
                          Object.keys(RequisitionStates).indexOf('CONFIRMED'),
                          Object.keys(RequisitionStates).indexOf('BUDGETED'),
                          Object.keys(RequisitionStates).indexOf('PENDING'),
                      ]
    return idsEnabled.includes( requisition.requisitionResponseDto.requisitionState.id );
  }

  patchRequisition( requisition: PatchRequisitionBody, succesMsg: string ) {
    this.requisitionsFacade.updateRequisition( requisition ).subscribe(
      req => {
        this.swalWithCustomizeButtons.fire({
          title: succesMsg,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false
        }).then(() => {
          this.filterRequisitionsOnChange();
          this._dialogRef.close();
        })
      }
    )
  }

  onPaginatorEvent( event: PageEvent ) {
      let rfp: RequisitionsQPS = this.metaDataRequisitions;
      rfp.size = event.pageSize;
      rfp.page = event.pageIndex;
      this.requisitionsFacade.loadRequisitions( rfp );
  }

  ngOnDestroy(): void {
    this._mdr ? this._mdr.unsubscribe() : null;
    this._pr ? this._pr.unsubscribe() : null;
    this._requisitionsLoaded ? this._requisitionsLoaded.unsubscribe() : null;
  }

  caseOrPerformed( requisition : Requisition ) : string {
    if (this.isTaskRequisition(requisition)){
      return 'Pasar a realizada';
    } else {
      return 'Pasar a caso';
    }
  }

  isProvisionalIncrementType( requisition : Requisition ) : boolean {
    return (requisition?.requisitionResponseDto?.requisitionType?.description == RequisitionTypes.PROVISIONAL_INCREMENT);
  }

  isTaskRequisition( requisition : Requisition ) {
    return (requisition?.requisitionResponseDto?.requisitionType?.description != RequisitionTypes.REQUEST);
  }

  getCaseTypesList(arr, separator: string){
    let caseTypes = []
    if(!!arr && arr.length > 0){
      caseTypes = arr.map(item => item.name)
      return caseTypes.join(`${separator}`)
    }
    return "-"
  }

  calculateIdOperator(operator: Operator) {
    return operator ? operator.id : null;
  }

  calculateNameOperator(operator: Operator) {
    return operator ? operator.name : null;
  }

  calculateIdActiveState(state) {
    return state != null ? state.id : null
  }

  calculateNameActiveState(state) {
    return state != null ? state.description : null
  }

  onSelectActiveState(){
    // ACTIVE REQUISITIONS
    if(this.activeStateSelected == 1){
      this.columns = '';
      this.stateSelected = this.activeRequisitionStates.find(ind => ind.id == 0)
    } else {
      // ARCHIVE REQUISITIONS
      this.columns = 'A'
      this.reasonSelected = 0;
    }
    this.onFilter();
  }

  filterByActiveState(){
    this.dataSource.data = this.dataSource.data.filter(r => this.activeStateSelected == 1 ? r.requisitionResponseDto.requisitionState.id != 8 : r.requisitionResponseDto.requisitionState.id == 8)
  }

  filterByState(){
    if(this.stateSelected != null && this.stateSelected.id != 0){
      this.dataSource.data = this.dataSource.data.filter(r => r.requisitionResponseDto.requisitionState.id == this.stateSelected.id)
    }
  }

  filterByType(){
    if(this.typeSelected != null && this.typeSelected.id != 0){
      this.dataSource.data = this.dataSource.data.filter(r => r.requisitionResponseDto.requisitionType.id == this.typeSelected.id)
    }
  }

  filterRequisitionsOnChange(){
    this.filterByActiveState();
    this.filterByState();
    this.filterByType();
  }

}
