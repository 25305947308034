import { Component, input } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
    diameter = input<number>(50); // Signal
    minHeight = input<number>(0); // Signal
}
