import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../core/services/general.service';
import { Router } from '@angular/router';
import { ROUTES } from '../../../../core/enums/routes';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MetaDataRequisitions, RequisitionsFacade } from 'src/app/abstraction/requisitions.facade';
import { Requisition, RequisitionIndicator } from '../../../../shared/models/requisition';
import { AuthenticationService } from '../../../../core/authentication.service';
import { RequisitionsQPS, RequisitionsIndicatorsQPS } from '../../../../core/services/requisition.service';
import { User } from 'src/app/shared/models/user';
import { RequisitionStates } from 'src/app/core/enums/requisitionsStates';
import * as moment from 'moment';
import { RequisitionTypes } from 'src/app/core/enums/requisitionTypes';
import { RequisitionReasons } from 'src/app/core/enums/requisitionsReasons';
import { Operator } from 'src/app/shared/models/operator';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';

enum filterDate {
  TODAY = '1',
  LAST_7_DAYS = '2',
  LAST_30_DAYS = '3',
  LAST_60_DAYS = '4'
}

@Component({
  selector: 'app-admission-dashboard',
  templateUrl: './admission-dashboard.component.html',
  styleUrls: ['./admission-dashboard.component.scss']
})
export class AdmissionDashboardComponent implements OnInit {

  public readonly TITLE_DASHBOARD_ADMISSIONS = 'Admisiones';

  filterFormCardRequisitionsAuthorized: FormGroup;
  public readonly FRECUENCY_AUTHORIZED = 'frecuencyAuthorized';
  frecuencyPeriod_default;

  indicatorsOptions = [];
  selectedIndicator; // Select default indicator value

  frecuencyPeriod = [
    {
      id: filterDate.TODAY,
      name: 'Hoy'
    },
    {
      id: filterDate.LAST_7_DAYS,
      name: 'Últimos 7 días'
    },
    {
      id: filterDate.LAST_30_DAYS,
      name: 'Últimos 30 días'
    },
    {
      id: filterDate.LAST_60_DAYS,
      name: 'Últimos 60 días'
    },
  ]

  _userSubscription: Subscription;
  _requisitions: Subscription;
  _metadataRequisitions: Subscription;
  _isLoadingGetRequisitions: Subscription;
  _requisitionsIndicators: Subscription;
  _isLoadingGetRequisitionsIndicators: Subscription;

  requisitions: Requisition[];
  routedFrom: string;
  user: User;
  metadataRequisitions: MetaDataRequisitions;

  loading: boolean = false;
  loadingIndicators: boolean = false;
  columnsToShow: string = 'D'; // Default

  _routing: Subscription;

  _operators: Subscription;
  operators: Operator[];
  operatorSelected: Operator;
  filterForm: FormGroup;
  stateSelected: string;
  requisitionStates = RequisitionStates


  reqIndicators: RequisitionIndicator[];

  // Indicators
  public requisitionsNews: number;
  public requisitionsUpdate: number;
  public requisitionsWithoutWarning: number;
  public requisitionsWaitingForInfo: number;
  public requisitionsSearchingResources: number;
  public requisitionsConfirmed: number;
  public requisitionsBudgeted: number;
  public requisitionsComplexityA: number;
  public requisitionsComplexityB: number;
  public requisitionsComplexityC: number;
  public requisitionsAuthorized: number;
  public requisitionsAuthorizedComplexityA: number;
  public requisitionsAuthorizedComplexityB: number;
  public requisitionsAuthorizedComplexityC: number;
  public totals: number;
  public readonly OPERATOR = 'operatorId';
  public readonly OPERATOR_SEARCHING = 'operatorSearching';
  public readonly _ROUTES = ROUTES

  get operator() {
    return this.filterForm.get(this.OPERATOR).value;
  }

  set operator(operator){
    this.filterForm.get(this.OPERATOR).setValue(operator);
  }

  constructor(
    private generalService: GeneralService,
    public router: Router,
    private formBuilder: FormBuilder,
    private requisitionsFacade: RequisitionsFacade,
    public  authenticationService: AuthenticationService,
    private entitiesFacade: EntitiesFacade,
  ) { }

  createFilterFormCardRequisitionsAuthorized(): FormGroup {
    return this.formBuilder.group({
      [this.FRECUENCY_AUTHORIZED]: [],
    });
  }

  ngOnInit(): void {

    this.routedFrom = history.state.routedFrom ? history.state.routedFrom : ''; // Get routed from
    this._userSubscription = this.authenticationService.getUser$().subscribe((user) => (this.user = user)); // Get operator

    this.generalService.sendMessage(this.TITLE_DASHBOARD_ADMISSIONS);
    this.filterFormCardRequisitionsAuthorized = this.createFilterFormCardRequisitionsAuthorized();

    this.filterForm = this.createFilterForm()

    this.suscribeToData();
  }

  openCreateAdmission(): void {
    this.generalService.setGoBack({ route: this.generalService.checkRouteGoToAdmissions( this.router.url ), title: 'Dashboard' });
    this.router.navigate([this._ROUTES.ADMISSIONS_CREATE]);
  }


  openAdmissionsDrafts() {
  }

  openAdmissionsArchived() {
    console.log('emi log',this.generalService.checkRouteGoToAdmissions( this.router.url ));

    // this.generalService.setGoBack({ route: this.generalService.checkRouteGoToAdmissions( this.router.url ), title: 'Dashboard' });
    // this.router.navigate([this._ROUTES.ADMISSIONS_ARCHIVED]);
  }

  openAdmissionsOperators() {
  }

  suscribeToData() {
    this._isLoadingGetRequisitions = this.requisitionsFacade.isLoadingGetRequisitions$().subscribe( loading => this.loading=loading );

    // Detect changes - refresh list
    this._requisitions = this.requisitionsFacade.getRequisitions$().subscribe( requisitions => {
      if ( !!requisitions ) {
        this.requisitions = requisitions.map( req => {return {...req}} )
      }
    })

    this._metadataRequisitions = this.requisitionsFacade.getMetaDataRequisitions$().subscribe(mdr => {
      if(!!mdr){
        this.metadataRequisitions = mdr
        if(mdr.fromIndicator){
          this.operator = mdr.operatorId;
        }
      }
    })

    this._operators = this.entitiesFacade.loadOperators({active: true}).subscribe((operators : Operator[]) => {
      this.operators = operators;
    })

    this.loadRequisitions();

    // Indicators
    this._isLoadingGetRequisitionsIndicators = this.requisitionsFacade.isLoadingGetRequisitionsIndicators$().subscribe( loading => this.loadingIndicators = loading );

    this._requisitionsIndicators = this.requisitionsFacade.getRequisitionsToIndicators$().subscribe( reqInd => {
      if ( reqInd ) {
        this.reqIndicators = reqInd
        this.getIndicators();
      }
    })
  }

  loadRequisitions() {

    let qps: RequisitionsQPS;
    switch (this.router.url) {
      case this._ROUTES.ADMISSIONS_LIST:
      case this._ROUTES.ADMISSIONS:
        qps = {
          requisitionsStateId: [
            Object.keys(RequisitionStates).indexOf('DRAFT'),
            Object.keys(RequisitionStates).indexOf('WITHOUT_WARNING'),
            Object.keys(RequisitionStates).indexOf('WAITING_FOR_INFO'),
            Object.keys(RequisitionStates).indexOf('SEARCHING_RESOURCES'),
            Object.keys(RequisitionStates).indexOf('CONFIRMED'),
            Object.keys(RequisitionStates).indexOf('BUDGETED'),
            Object.keys(RequisitionStates).indexOf('PENDING'),
          ],
          page: 0,
          size: 10,
          order: 'DESC',
          sort: 'fromDate'
        }
        this.columnsToShow = 'D'
        break;

      case this._ROUTES.ADMISSIONS_ARCHIVED:
        qps = {
          requisitionsStateId: [
            Object.keys(RequisitionStates).indexOf('ARCHIVED'),
          ],
          page: 0,
          size: 10,
          order: 'DESC',
          sort: 'fromDate'
        }
        this.columnsToShow = 'A';
        break;

      default:
        this.columnsToShow = 'D'
        break;
    }

    if(!!this.metadataRequisitions){
      if(this.router.url != this._ROUTES.ADMISSIONS){
        if(!!this.metadataRequisitions.requisitionsStateId){
          qps.requisitionsStateId = this.metadataRequisitions?.requisitionsStateId;
        }
        if(!!this.metadataRequisitions.operatorId){
          qps.operatorId = this.metadataRequisitions.operatorId;
        } else {
          delete qps.operatorId;
        }
      } else if(this.router.url == this._ROUTES.ADMISSIONS) {
        this.requisitionsFacade.setMetaDataRequisitions({})
      }
    }

    if(!!this.operator && this.router.url != this._ROUTES.ADMISSIONS){
      qps.operatorId = this.operator;
    } else {
      this.operator = null;
      delete qps.operatorId;
    }

    /* qps =    this.routedFrom === 'SOLICITUDES'
          || this.router.url === this._ROUTES.ADMISSIONS_LIST
          || ( this.router.url === this._ROUTES.ADMISSIONS_ARCHIVED && this.generalService.getGoBack()?.route === this._ROUTES.ADMISSIONS_LIST )
          ? { ...qps, operatorId: this.user.id } : qps; */
    this.requisitionsFacade.loadRequisitions( qps );

    // Load indicators
    const qpsInd: RequisitionsIndicatorsQPS = {
      fromDate: moment().subtract(60, 'days').toDate(),
      operatorId: qps.operatorId
    }
    if (qps.operatorId == null) {
      delete qpsInd.operatorId
    }
    this.requisitionsFacade.loadRequisitionsIndicators( qpsInd );
  }

  ngOnDestroy(): void {
    this._requisitions ? this._requisitions.unsubscribe() : null;
    this._userSubscription ? this._userSubscription.unsubscribe() : null;
    this._isLoadingGetRequisitions ? this._isLoadingGetRequisitions.unsubscribe() : null;
    this._requisitionsIndicators ? this._requisitionsIndicators.unsubscribe() : null;
    this._isLoadingGetRequisitionsIndicators ? this._isLoadingGetRequisitionsIndicators.unsubscribe() : null;
    this._routing ? this._routing.unsubscribe() : null;
    !!this._metadataRequisitions ? this._metadataRequisitions.unsubscribe() : null;
  }

  getIndicators() {
    // Left card
    // SOLICITUDES - NUEVAS
    this.requisitionsNews = this.reqIndicators.filter( req => req.requisitionState.id != Object.keys(RequisitionStates).indexOf("ARCHIVED") && req.requisitionType.id === Object.keys(RequisitionTypes).indexOf("REQUEST") ).length;
    // SOLICITUDES - ACTUALIZACIONES
    this.requisitionsUpdate = this.reqIndicators.filter( req => req.requisitionState.id != Object.keys(RequisitionStates).indexOf("ARCHIVED") && req.requisitionType.id != Object.keys(RequisitionTypes).indexOf("REQUEST") ).length;
    // SOLICITUDES - SIN AVISO AL F
    this.requisitionsWithoutWarning = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("WITHOUT_WARNING")).length;
    // SOLICITUDES - ESPERANDO INFO
    this.requisitionsWaitingForInfo = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("WAITING_FOR_INFO")).length;
    // SOLICITUDES - BUSCANDO REC
    this.requisitionsSearchingResources = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("SEARCHING_RESOURCES")).length;
    // SOLICITUDES - CONFIRMADO
    this.requisitionsConfirmed = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("CONFIRMED")).length;
    // SOLICITUDES - PRESUPUESTADO
    this.requisitionsBudgeted = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("BUDGETED")).length;

    // Center card
    // COMPLEJIDAD A
    this.requisitionsComplexityA = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("WITHOUT_WARNING") && req.complexities?.name === "A" ).length;
    // COMPLEJIDAD B
    this.requisitionsComplexityB = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("WITHOUT_WARNING") && req.complexities?.name === "B" ).length;
    // COMPLEJIDAD C
    this.requisitionsComplexityC = this.reqIndicators.filter( req => req.requisitionState.id === Object.keys(RequisitionStates).indexOf("WITHOUT_WARNING") && req.complexities?.name === "C" ).length;

    // Right card
    this.frecuencyPeriod_default = filterDate.TODAY;
    this.frecuencySelected( this.frecuencyPeriod_default );
  }

  frecuencySelected( event:string )  {

    // Archived and Autorizada
    const reqAux = this.reqIndicators.filter( req =>     req.requisitionState.id === Object.keys(RequisitionStates).indexOf("ARCHIVED")
                                                      && req?.requisitionReason?.id === Object.keys(RequisitionReasons).indexOf("AUTORIZADO")
                                                      && req.requisitionType.id ===  Object.keys(RequisitionTypes).indexOf("REQUEST"))
    let xDate:Date;

    switch (event) {
      case "1": // TODAY
        xDate = moment().toDate();
        break;
      case "2": // LAST 7 WEEK
        xDate = moment().subtract(7, 'days').toDate();
        break;
      case "3": // LAST 30 DAYS
        xDate = moment().subtract(30, 'days').toDate();
        break;
      case "4": // LAST 60 DAYS
        xDate = moment().subtract(60, 'days').toDate();
        break;
      default:
        break;
    }

    if (xDate) {
      this.requisitionsAuthorized = this.filterRequisitonsByDate(reqAux, xDate).length;
      this.requisitionsAuthorizedComplexityA = this.filterRequisitonsByDate(reqAux.filter( req => req.complexities?.name === "A" ), xDate).length;
      this.requisitionsAuthorizedComplexityB = this.filterRequisitonsByDate(reqAux.filter( req => req.complexities?.name === "B" ), xDate).length;
      this.requisitionsAuthorizedComplexityC = this.filterRequisitonsByDate(reqAux.filter( req => req.complexities?.name === "C" ), xDate).length;
      this.totals = this.filterRequisitonsByDate(this.reqIndicators, xDate).length;
    }
  }

  filterRequisitonsByDate( requisitions: RequisitionIndicator[], from: Date ): RequisitionIndicator[] {
    return requisitions.filter( req => moment(moment(req.fromDate).format('YYYY-MM-DD')).isBetween(moment(from).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), undefined, "[]" ) );
  }

  calculateIdOperator(operator: Operator) {
    return operator ? operator.id : null;
  }

  calculateNameOperator(operator: Operator) {
    return operator ? operator.name : null;
  }


  createFilterForm():FormGroup{
    return this.formBuilder.group({
      [this.OPERATOR]: [this.router.url == this._ROUTES.ADMISSIONS ? '' : this.user.id, []],
      [this.OPERATOR_SEARCHING]: [''],
    })
  }
}
