/**
 * * Enum de las APIs de la aplicación para comunicación con el BackEnd
 */
export enum EvolutionsType {
   NORMAL = 1,
   ABSENT_PATIENT = 2,
   NOT_GOING = 3,
   CRITICAL = 4,
  
   ABSENT_WITHOUT_NOTICE=6,
   ATTENTION_IN_PROGRESS_WITHOUT_EVOLUTION=7,
   ATTENTION_IN_PROGRESS_WITHOUT_EXIT=8,
   VIDEOCALL=9,
   DISCHARGE=10,
   ADMINISTRATIVE = 11,
}
