import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, DatePipe, TitleCasePipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { CalendarModule, DateAdapter as CalendarDateAdapter  } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { MaterialModule } from './material/material.module';
import { UtilsModule } from './shared/components/utils/utils.module';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { ManageHttpInterceptor } from './core/interceptors/manage-http.interceptor';
import { MoreInfoComponent } from './shared/components/more-info/more-info.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AvatarModule } from './shared/components/avatar/avatar.module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MyDateAdapter } from 'MyDateAdapter';
import { CasesModule } from './modules/cases/cases.module';

export const routes: Routes = [];

// FilePonds
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { ScheduleModule } from './modules/schedule/schedule.module';
import { GoogleMapsModule } from '@angular/google-maps';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);

registerLocaleData(localeEsAr, 'es-Ar');

const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@NgModule({
    declarations: [ AppComponent, MessageDialogComponent, MoreInfoComponent ], // ScheduleComponen
    imports: [
        BrowserModule,
        CommonModule,
        MatNativeDateModule,
        CalendarModule.forRoot({
            provide: CalendarDateAdapter ,
            useFactory: adapterFactory
        }),
        RouterModule.forRoot( routes ),
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialModule,
        UtilsModule,
        AvatarModule,
        MatMomentDateModule,
        FilePondModule,
        CasesModule,
        ScheduleModule,
        GoogleMapsModule
    ],
    providers: [
        DatePipe,
        TitleCasePipe,
        provideHttpClient(withInterceptorsFromDi()),
        // Time - old
        { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: LOCALE_ID, useValue: "es-AR" },
        // Time - news
        { provide: DateAdapter, useClass: MyDateAdapter },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        // Http
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true },

        provideAnimationsAsync()
    ],
    bootstrap: [ AppComponent ]
})

export class AppModule { }
