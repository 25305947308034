<div class="flex-row align-center-center full-width">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon && !spinner">{{ leftIcon }}</mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass"
        *ngIf="leftIconSvg && !spinner" [svgIcon]="leftIconSvg"></mat-icon>
    <mat-spinner *ngIf="spinner" class="mrs-3" color="primary" diameter="20">
    </mat-spinner>
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <!-- <mat-label fxLayoutAlign="start center" *ngIf="this.label || this.icon || this.iconSvg">
          <mat-icon class="mrs-2" *ngIf="this.icon">{{ icon }}</mat-icon>
          <mat-icon class="mrs-2" *ngIf="this.iconSvg" [svgIcon]="iconSvg"></mat-icon>
          {{ label }}
       </mat-label> -->
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <mat-hint>
            {{ this.hint }}
        </mat-hint>
        <mat-select [formControlName]="name" id="{{ name }}" placeholder="{{ placeholder }}"
            [disabled]="disabled ? disabled : false" (selectionChange)="change($event)">
            <mat-option *ngIf="this.noOptionText">{{noOptionText}}</mat-option>
            <mat-option value="{{ proxyCalculateId()(element) }}" [matTooltip]="proxyCalculateName()(element)" *ngFor="let element of elements">
                {{ proxyCalculateName()(element) }}
            </mat-option>

        </mat-select>
        <mat-error *ngIf="
             this.form.get(this.name)?.errors &&
             (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
          ">
            <b>
                {{ getError() }}
            </b>
        </mat-error>
    </mat-form-field>
</div>
