import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTableComponent } from './card-table.component';
import { MaterialModule } from 'src/app/material/material.module';



@NgModule({
  declarations: [CardTableComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    CardTableComponent
  ]
})
export class CardTableModule { }
