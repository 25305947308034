import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRemoveFocused]'
})
export class RemoveFocusedDirective {
  constructor(private elRef: ElementRef) {
    console.log('este es el ',this.elRef);
    
  }

  @HostListener('click') onClick() {   
    this.elRef.nativeElement.blur();
  }
}
