import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Task } from 'src/app/shared/models/task';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { DialogImageDetailComponent } from '../../../../shared/components/dialog-image-detail/dialog-image-detail.component';

@Component({
   selector: 'app-dialog-end-task',
   templateUrl: './dialog-end-task.component.html',
   styleUrls: ['./dialog-end-task.component.scss'],
})
export class DialogEndTaskComponent implements OnInit {
   PRACTICE_QUALITY_CONTROL_ID = '10000047';
   // PRACTICE_QUALITY_CONTROL_ID = 10006036;
   loadingConfirm = false;
   financierVisibility;
   providersVisibility;
   providersVisibilityEvolution;
   financierVisibilityEvolution;
   evolutionTextHint;
   evolutionTextPlaceholder;
   ATTENTION_STATES = AttentionStates;
   EVOLUTIONS_TYPE = EvolutionsType;
   public readonly NO_MODIFICAR_ESTADO_ID = 0;
   attentionStates: StateAttention[] = [
      {
         id: this.NO_MODIFICAR_ESTADO_ID,
         name: 'No modificar estado',
      },
      {
         id: AttentionStates.AGENDADA,
         name: 'Agendada',
      },
      {
         id: AttentionStates.EN_CURSO,
         name: 'En curso',
      },
      {
         id: AttentionStates.NO_REALIZADA,
         name: 'No realizada',
      },
      {
         id: AttentionStates.REALIZADA,
         name: 'Realizada',
      },
   ];
   /**
    * FORM
    */
   public readonly STATE_ATTENTION = 'state_attention';
   attentionStateForm: FormGroup;
   /**
    * EVOLUTION FORM
    */
   public readonly EVOLUTION_TEXT = 'evolution_text';
   public readonly EVOLUTION_TYPE = 'evolution_type';
   evolutionForm: FormGroup;
   get evolutionText() {
      return this.evolutionForm.get(this.EVOLUTION_TEXT);
   }
   get evolutionType() {
      return this.evolutionForm.get(this.EVOLUTION_TYPE);
   }

   constructor(
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<DialogEndTaskComponent>,
      public entitiesFacade: EntitiesFacade,
      @Inject(MAT_DIALOG_DATA) public data: {
         task: Task,
         modifyEvolution: boolean,
      },
   ) {
      console.log(data);
   }

   ngOnInit() {
      this.setFormVariables();
      this.financierVisibility = true;
      this.providersVisibility = true;
      this.data.modifyEvolution ? this.financierVisibilityEvolution = false : this.financierVisibilityEvolution = true;
      this.providersVisibilityEvolution = true;

      this.attentionStateForm = this.createAttentionStateForm();
      this.evolutionForm = this.createEvolutionForm();
      if (this.data.modifyEvolution) {
         this.evolutionText.setValidators([CustomValidators.required('Texto requerido'), CustomValidators.minLength(10, 'El texto debe tener al menos 10 caracteres')])
         this.evolutionText.updateValueAndValidity();
      }
      this.attentionStates = this.attentionStates.filter(
         (state) => state.id !== this.data.task.evolution.attention.state.id,
      );
   }

   get stateAttention() {
      return this.attentionStateForm.get(this.STATE_ATTENTION);
   }

   setFormVariables() {
      if (this.data.modifyEvolution) {
         this.evolutionTextHint = '';
         this.evolutionTextPlaceholder = 'Texto de la nueva evolución'
      } else {
         this.evolutionTextHint = 'Opcional';
         this.evolutionTextPlaceholder = '[Opcional] Observaciones'
      }
   }

   createAttentionStateForm(): FormGroup {
      return this.formBuilder.group({
         [this.STATE_ATTENTION]: ['', [CustomValidators.required('Estado requerido')]],
      });
   }

   createEvolutionForm(): FormGroup {
      return this.formBuilder.group({
         [this.EVOLUTION_TEXT]: [
            '',
            [CustomValidators.minLength(10, 'El texto debe tener al menos 10 caracteres')],
         ],
         [this.EVOLUTION_TYPE]: [
            EvolutionsType.ADMINISTRATIVE,
            [CustomValidators.required('Tipo requerido')],
         ],
      });
   }

   calculateId(element: StateAttention) {
      return element ? element.id : null;
   }

   calculateName(element: StateAttention) {
      return element ? element.name : null;
   }

   openDialogImage(_image): void {
      const dialogRef = this.dialog.open(DialogImageDetailComponent, {
         width: '350vw',
         data: { image: _image },
      });

      dialogRef.afterClosed().subscribe((result) => { });
   }

   onCancelClick(): void {
      this.dialogRef.close();
   }

   onConfirmClick(): void {
      this.loadingConfirm = true;
      let data: {
         task: Task;
         visibility: {
            financier: boolean;
            providers: boolean;
         };
         attentionState?: number;
         evolutionOperator?: {
            attentionId: number;
            text: string;
            type: number;
            date: Date;
            mediaUrls: string[];
            visibleFinancier: boolean;
            visibleProvider: boolean;
         };
      };
      data = {
         task: this.data.task,
         visibility: {
            financier: this.financierVisibility,
            providers: this.providersVisibility,
         },
         attentionState: null,
      };
      // Si la evolución es una alerta y el estado es distinto a no_modificar_estado -> indicar visibilidad de la evolución y estado final de la atención
      if (
         this.data.task.evolution.category.id !== this.EVOLUTIONS_TYPE.NORMAL &&
         this.stateAttention.value != this.NO_MODIFICAR_ESTADO_ID
      ) {
         data.attentionState = this.stateAttention.value;
      }

      if (
         (this.data.task.evolution.category.id !== this.EVOLUTIONS_TYPE.NORMAL || this.data.modifyEvolution) &&
         !!this.evolutionText.value
      ) {
         const postEvolutionObject = {
            attentionId: this.data.task.evolution.attentionId,
            caseId: this.data.task.evolution.caseId,
            text: this.evolutionText.value,
            type: this.evolutionType.value,
            date: new Date(),
            mediaUrls: null,
            visibleFinancier: this.financierVisibilityEvolution,
            visibleProvider: this.providersVisibilityEvolution,
            referencedEvolution: this.data.task.evolution.id,
         };
         data.evolutionOperator = postEvolutionObject;
      }
      this.dialogRef.close(data);
   }
}
