<div class="flex-column max-height-550">
    <div class="flex-column bg-primary-lighter p-1">
        <span class="text-primary-darker-h3 ml-1 mb-0">Seguimiento de estados de la atención</span>
    </div>
    <div class="flex-column p-24">
        <div class="mxs-3">
            <app-tracking class="mxs-4 mls-5" [elements]="this.trackingElements">
            </app-tracking>
        </div>
        <div class="flex-row align-space-around-stretch pys-3">
                <button id="btnClose" mat-stroked-button [color]="'primary'" (click)="onCancelClick()">CERRAR</button>
                <!-- Button only show if user is admin -->
                <button id="btnRevertState" *ngIf="revertState" mat-flat-button [color]="'primary'" (click)="revertStateBillableToPerformed()">REVERTIR ESTADO</button>
        </div>
    </div>
</div>
