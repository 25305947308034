import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CasesComponent } from './pages/cases/cases.component';
import { CaseComponent } from './pages/case/case.component';
import { CaseCreationComponent } from './pages/case-creation/case-creation.component';
import { ProvisionalControlComponent } from '../schedule/pages/provisional-control/provisional-control.component';

const routes: Routes = [
   {
      path: '',
      redirectTo: 'listado',
      pathMatch: 'full',
      data: { breadcrumb: 'Listado' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'listado',
      //component: CasesComponent
      component: ProvisionalControlComponent,
      data: { breadcrumb: 'Listado' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'listado/:id',
      component: CaseComponent,
      data: { breadcrumb: 'Listado >:id' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'creacion',
      component: CaseCreationComponent,
      data: { breadcrumb: 'Creación' }
   },
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule],
})
export class CasesRoutingModule { }
