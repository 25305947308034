import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, model, OnInit, Output, signal, Signal, SimpleChanges, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { ProvisionsFrequencyUnit } from 'src/app/core/enums/provisionsFrequencyUnit';
import { ProvisionsUnit } from 'src/app/core/enums/provisionsUnit';
import { ROUTES } from 'src/app/core/enums/routes';
import { SUPPLY_SPECIALTY_ID } from 'src/app/core/enums/supplySpecialtyId';
import { ProvidersService } from 'src/app/core/services/providers.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Agreement } from 'src/app/shared/models/agreement';
import { Case } from 'src/app/shared/models/case';
import { Practice } from 'src/app/shared/models/practice';
import { Provider } from 'src/app/shared/models/provider';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import Swal from 'sweetalert2';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { ArrangementsFacade } from '../../../../abstraction/arrangements.facade';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { ProvisionAgreementFilters } from '../../../../shared/models/ProvisionAgreementFilters';
import mime from 'mime';
import { GeneralService } from '../../../../core/services/general.service';
import { Evolution } from '../../../../shared/models/evolution';
import { EvolutionsFacade, AttentionComposedWithEvolutions } from '../../../../abstraction/evolutions.facade';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EvolutionsDialogComponent } from '../evolutions-dialog/evolutions-dialog.component';
import { Authorization } from 'src/app/shared/models/authorization';
import { CasesService } from '../../../../core/services/cases.service';
import { AlertService } from '../../../../core/services/alert.service';
import { ProvidersQPS } from '../../../../core/services/providers.service';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { Attention } from '../../../../shared/models/attention';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { Arrangement } from 'src/app/shared/models/arrangement';
import { ArrangementItem } from '../../../../shared/models/arrangementItem';
import { CaseArrangement } from '../../../../shared/models/caseArrangement';
import { DialogImageDetailComponent } from '../../../../shared/components/dialog-image-detail/dialog-image-detail.component';
import { ProvisionsFacade, AUTHORIZATION_COLORS, AUTHORIZATION_BACKGROUND_COLORS } from '../../../../abstraction/provisions.facade';
import { BudgetBody, PostProvisionBody } from 'src/app/core/services/provisions.service';
import { HttpBaseResponseErrorTeaPot } from '../../../../shared/models/httpBaseResponseErrorTeaPot';
import { ProvisionsState } from 'src/app/core/states/provisions.state';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { PracticeArrangement } from 'src/app/core/services/arrangements.service';
import { MapsService } from 'src/app/shared/services/maps.service';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { Patient } from 'src/app/shared/models/patient';
import { Specialty } from 'src/app/shared/models/specialty';
import { Logger } from 'sass';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';
import { FilePondComponent } from 'ngx-filepond';

@Component({
    selector: 'app-provisions-detail',
    templateUrl: './provisions-detail.component.html',
    styleUrls: ['./provisions-detail.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ProvisionsDetailComponent implements OnInit {

    // sPatient: WritableSignal<Patient> = signal(null);
    supplySpecialtyId = SUPPLY_SPECIALTY_ID;
    routes = ROUTES;
    isOpen: boolean;
    showAlertMsg: boolean = false;
    checkboxAuthorizationText: string = '';
    alertMsg: string = '';
    showPracticeAndAgreementExistent: boolean = false;
    providers: Provider[];
    provisionAgreementsInput: ProvisionAgreementFilters;
    AUTHORIZATION_COLORS = AUTHORIZATION_COLORS;
    AUTHORIZATION_BACKGROUND_COLORS = AUTHORIZATION_BACKGROUND_COLORS;

    @Input() case: Case;
    @Input() isExpanded: boolean = false;
    @Input() supply: boolean = false;
    @Input() isBilling;
    @Input() allProvisionsWithoutFilter: Provision[];

    @Output('onChangeExpand') onChangeExpandEvent = new EventEmitter<boolean>();

    @ViewChild('provisionTemplate', { static: true }) provisionTemplate: TemplateRef<any>;
    @ViewChild('agreementTemplate', { static: true }) agreementTemplate: TemplateRef<any>;
    @ViewChild('updateAuthorizationTemplate', { static: true }) updateAuthorizationTemplate: TemplateRef<any>;
    @ViewChild('showAuthorizations', { static: true }) showAuthorizations: TemplateRef<any>;
    @ViewChild('suspendTemplate', { static: true }) suspendTemplate: TemplateRef<any>;
    @ViewChild('specialtiesMap', { static: true }) specialtiesMap: TemplateRef<any>;

    @ViewChild('practicesTable') matTable: ElementRef;

    public readonly NO_ARRANGEMENT_ID = -1;
    public readonly PRACTICE = 'practiceId';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly AUTHORIZATION = 'authorization';
    public readonly AUTHORIZATION_NUMBER = 'authorizationNumber';
    public readonly AUTHORIZATION_FROM_DATE = 'fromDate';
    public readonly AUTHORIZATION_TO_DATE = 'toDate';
    public readonly AUTHORIZATION_OBSERVATIONS = 'observations';
    public readonly ARRANGEMENT = 'arrangementId';
    public readonly ARRANGEMENT_SEARCHING = 'arrangementSearching';
    public readonly ARRANGEMENT_MARGIN = 'arrangementMargin';
    public readonly ARRANGEMENT_FEE = 'arrangementFee';
    public readonly FEE = 'fee';
    public readonly FEE_TXT = 'fee_txt';
    public readonly MARGIN = 'profitMargin';
    public readonly FRECUENCY = 'frequency';
    public readonly AMOUNT = 'amount';
    public readonly UNIT = 'unit';
    public readonly PROVISION_FREQUENCY_UNIT_DEFAULT = ProvisionsFrequencyUnit.DIA;
    public readonly UPDATE_FEE = 'fee';
    public readonly AGREEMENT_PROVISION_FEE = 'providerFee';
    public readonly AGREEMENT_MARGIN = 'marginFee';
    public readonly MARGIN_ADDED = 'profitMarginAdded';
    public readonly IS_MANUAL_VALUE = 'is_manual_value';
    public readonly PROVIDER = 'providerId';
    public readonly PROVIDER_NAME = 'providerName';
    public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly PROVIDER_FEE = 'providerFee';
    public readonly IS_ADDING_AUTHORIZATION = 'is_adding_authorization';
    public readonly AGREEMENT_CHECK = 'agreementCheck';
    public readonly TOTAL_AMOUNT = 'totalAmount';
    public readonly FILTERED_BY_REGION = 'isFilteredByRegion';
    public readonly AGREEMENT_FROM_DATE = 'agreementFromDate';
    public readonly AGREEMENT_TO_DATE = 'agreementToDate';

    // Update form
    public readonly VALIDITY_FROM_DATE = 'fromDate';
    public readonly VALIDITY_TO_DATE = 'toDate';

    formProvision: FormGroup;
    formAgreement: FormGroup;
    formFrequency: FormGroup;
    formAuthorization: FormGroup;
    formSuspend: FormGroup;

    provisionToWork: Provision;
    agreementToWork: Agreement;

    provisionFeeToWork: ProvisionFee;
    practices: Practice[];
    provisionsToUpdate: Provision[];

    isTooltipOpen: boolean = false;
    practicesCodes: any[];
    practiceForTooltip: string;
    arrangementNameForTooltip: CaseArrangement[];
    practiceArrangementForTooltip: PracticeArrangement[];

    // Default
    providerQPS_Default: ProvidersQPS = {
        active: true,
        isSupply: this.supply,
        isFilteredByRegion: false
    };

    public dataSource;
    loadingSignature: boolean = false;
    _screenToJumpSubscription: Subscription
    isLoadingCreatingAgreement$: Observable<boolean>;
    isLoadingUpdatingAgreement$: Observable<boolean>;
    isLoadingCreatingProvision$: Observable<boolean>;
    isLoadingUpdatingProvision$: Observable<boolean>;
    isLoadingGetArrangementItems$: Observable<boolean>;
    isLoadingGetArrangements$: Observable<boolean>;
    case$: Observable<Case>;
    _case: Subscription;
    _allProvisions: Subscription;
    _practices: Subscription;
    _formProvision: Subscription;
    _provisionsDisplayedOnScreen: Subscription;
    _isLoadingUpdatingProvision: Subscription;
    isLoadingUpdatingProvision: boolean;

    // showFormAgreement;
    @Input() expandedElement: any;
    arrangements: { id: number, name: string }[];
    selectionProvisions = new SelectionModel<Provision>(true, []);
    selectionAgreements = new SelectionModel<Agreement>(true, []);
    selectionProvisionFees = new SelectionModel<ProvisionFee>(true, []);
    columnsToDisplayExpanded: string[] = [
        'arrow',
        'provision',
        'checkProvisionFee',
        'frequency',
        'fee',
        'totalAmount',
        'totalRealized',
        'billed',
        'debited',
        'fullyReported',
        'validity',
        'vigency',
        'actions'
    ];
    headerColumns: string[] = [
        'arrow',
        'provision',
        'checkProvisionFee',
        'frequency',
        'fee',
        'totalAmount',
        'states',
        'validity',
        'vigency',
        'actions'
    ]

    statesColumns: string[] = [
        'totalRealized',
        'billed',
        'debited',
        'fullyReported',
    ]

    columnsToDisplayCollapsed: string[] = [
        'arrow',
        'provision',
        'checkProvisionFee',
        'frequency',
        'fee',
    ];
    _dialogRef;
    _dialogRefAgreementTemplate: MatDialogRef<any>;

    objectValues = Object.values;
    provisionsFrequencyUnit = [
        {
            id: ProvisionsFrequencyUnit.DIA,
            name: 'Dia',
        },
        {
            id: ProvisionsFrequencyUnit.SEMANA,
            name: 'Semana',
        },
        {
            id: ProvisionsFrequencyUnit.MES,
            name: 'Mes',
        },
    ];
    provisionsUnit = [
        {
            id: ProvisionsUnit.UNIDAD,
            name: 'Unidad',
        },
        {
            id: ProvisionsUnit.BOLSA_BOLSON,
            name: 'Bolsa/Bolsón',
        },
        {
            id: ProvisionsUnit.PAQUETE,
            name: 'Paquete',
        },
        {
            id: ProvisionsUnit.CAJA,
            name: 'Caja',
        },
    ];
    provisionIdforHover;
    provisionFeeIdforHover;
    historyModeActivated: boolean;
    _historyModeActivated: Subscription;
    _historyModeDate: Subscription;
    historyModeDate: Date;
    previousHistoryModeActivated: boolean;

    disabledButtonSubmitUpdateProvision: boolean;

    provisionFeeSelectedToAutorize: ProvisionFee = null;

    // File authorization loader
    @ViewChild('myPond') myPond: FilePondComponent;

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Adjunte <u>aquí</u> o arrastre la autorización',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 150
    };

    photoEvolutionB64: string = null;
    loadingAddFile: boolean = false;
    enumStates = AttentionStates;
    _attentions: Subscription;
    isAddingAuthorization: any;
    provisionSelectedToUpdate: any;
    provisionFeeSelectedToUpdate: any;

    _caseArrangements: Subscription;
    caseArrangements: CaseArrangement[];
    arrangementItemsByPractice: Partial<ArrangementItem>[];
    isOpenTooltip: boolean = false;
    processSuspendCompleteSuccesfully: any;
    enableMarginField: boolean = false;
    specialtySelected: Specialty;

    get providerForm() {
        return this.formAgreement.get(this.PROVIDER).value;
    }

    get providerFeeForm() {
        return this.formAgreement.get(this.PROVIDER_FEE).value;
    }

    get practiceId() {
        return this.formProvision.get(this.PRACTICE).value;
    }

    set frequencyUnitForm(value: string) {
        !!value ?
            this.formFrequency.controls[this.UNIT].setValue(value) :
            this.formFrequency.controls[this.UNIT].reset();
    }

    get provisionFromDate() {
        return this.formProvision.get(this.VALIDITY_FROM_DATE).value;
    }

    get provisionToDate() {
        return this.formProvision.get(this.VALIDITY_TO_DATE).value;
    }

    get isAddingAuthorizationUpdatingProvision() {
        return this.formProvision.get(this.IS_ADDING_AUTHORIZATION).value;
    }

    get providerName() {
        return this.formAgreement.get(this.PROVIDER_NAME).value;
    }

    get suspendFromDate() {
        return this.formSuspend.get(this.VALIDITY_FROM_DATE).value;
    }

    get suspendToDate() {
        return this.formSuspend.get(this.VALIDITY_TO_DATE).value;
    }

    set suspendFromDate(value: Date) {
        this.formSuspend.get(this.VALIDITY_FROM_DATE).setValue(value);
    }

    set suspendToDate(value: Date) {
        this.formSuspend.get(this.VALIDITY_TO_DATE).setValue(value);
    }

    swalWithCustomizeButtons: any;
    toDay: Date = moment(new Date(), 'YYYY-MM-DD').toDate();

    authorizations: Authorization[];
    isLoadingGetAuthorizations$: Observable<boolean>;

    evolutions$: Observable<AttentionComposedWithEvolutions[]>; _evolutions: Subscription;
    evolutionsSubscribed: Evolution[];
    provisionsLoaded: boolean = false;

    _filtersOnProvisions: Subscription;
    _loadingGetProvisions: Subscription; loadingGetProvisions: boolean = true;
    _loadingGetAttentions: Subscription; loadingGetAttentions: boolean = true;

    isAddModeProvision: boolean;
    isRetroactive: boolean;
    isShowRetroactive: boolean;
    isShowAuth: boolean;
    isAddModeAgreement: boolean;
    isUpdateModeAgreement: boolean;
    actionProvision: string;
    actionAgreement: string;
    title: string = '';
    showTxtWithOutArrangement: boolean = false;
    isLoadingGettingProviders$: Observable<boolean>;

    provisionsFiltered: Provision[]; // Provisions after filters

    arrangementSelected: Arrangement;
    practicesToShow: Practice[];
    existPriceList: boolean;

    historyFromDate: Date;
    historyToDate: Date;

    // Footer TOTALS
    totalInvoicedFooter: number = 0;
    totalDebitedFooter: number = 0;
    totalDeliveredFooter: number = 0;
    totalValueFooter: number = 0;

    attentionsINRange: Attention[];

    btnsDisabled: boolean;
    private _btnsDiasabled: Subscription;

    // Googgle maps
    private _markers: Subscription;
    markers = model<MarkerGoogleMaps[]>;

    constructor(
        private formBuilder: FormBuilder,
        public entitiesFacade: EntitiesFacade,
        private casesFacade: CasesFacade,
        public dialog: MatDialog,
        private nomenclatorFacade: NomenclatorFacade,
        private providersService: ProvidersService,
        private arrangementsFacade: ArrangementsFacade,
        public generalService: GeneralService,
        private evolutionsFacade: EvolutionsFacade,
        private _bottomSheet: MatBottomSheet,
        private alertService: AlertService,
        private attentionsService: AttentionsService,
        private casesService: CasesService,
        private provisionsFacade: ProvisionsFacade,
        private provisionState: ProvisionsState,
        private attentionsFacade: AttentionsFacade,
        private fileUtilitiesService: FileUtilitiesService,
        public mapsService: MapsService,
        private breadcrumbService: BreadcrumbService
    ) {
        this.isLoadingCreatingAgreement$ = this.provisionsFacade.isLoadingCreatingAgreement$();
        this.isLoadingUpdatingAgreement$ = this.provisionsFacade.isLoadingUpdatingAgreement$();

        this.isLoadingCreatingProvision$ = this.provisionsFacade.isLoadingCreatingProvision$();
        this.isLoadingUpdatingProvision$ = this.provisionsFacade.isLoadingUpdatingProvision$();

        this.isLoadingGetArrangementItems$ = this.arrangementsFacade.isLoadingGetArrangementItems$();
        this.isLoadingGetArrangements$ = this.arrangementsFacade.isLoadingGetArrangements$();

        this.isLoadingGettingProviders$ = this.entitiesFacade.isLoadingGettingProviders$();
    }

    ngOnInit() {
        this._screenToJumpSubscription = this.breadcrumbService.getScreenToJump$().subscribe(event => {


            if (event == ViewManagementEntities.PRACTICES_WITHOUT_SCHEDULE || event == ViewManagementEntities.SUPPLIES_WITHOUT_ORDER) {
                                    this.setPracticeTableFocus()
                            }
        })

        this._isLoadingUpdatingProvision = this.provisionsFacade.isLoadingUpdatingProvision$().subscribe(
            isLoading => {
                this.isLoadingUpdatingProvision = isLoading;
            },
            error => console.error('Error:', error)
        );


        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this.calculateNameProvider = this.calculateNameProvider.bind(this);
        this.calculateNamePractice = this.calculateNamePractice.bind(this)
        this.dataSource = new MatTableDataSource();

        this._practices = this.nomenclatorFacade.getPractices$().subscribe((practices) => {
            if (!!practices) {
                this.practices = practices.filter((practice) => !practice.specialty?.isSupply);
            }
        });

        this._loadingGetProvisions = this.provisionsFacade.isLoadingGetProvisions$().subscribe(loading => {
            this.loadingGetProvisions = loading;
        })

        this._loadingGetAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loading => {
            this.loadingGetAttentions = loading;
        })

        this._filtersOnProvisions = this.provisionsFacade.getFiltersOnProvisions$().subscribe(filters => {

            this.provisionAgreementsInput = filters;
            this.selectionProvisions.clear();
            this.selectionAgreements.clear();
            this.selectionProvisionFees.clear();

            if (this.provisionAgreementsInput?.provisions?.length > 0) {
                this.provisionAgreementsInput?.provisions?.forEach(filter => {
                    this.selectionProvisions.select(filter);
                    filter.agreements?.forEach(agr => {
                        if (this.provisionAgreementsInput.agreements.includes(agr)) {
                            this.selectionAgreements.select(agr);
                        }
                    })
                    filter.provisionFees?.forEach(provFee => {
                        if (this.provisionAgreementsInput.provisionFees.includes(provFee)) {
                            this.selectionProvisionFees.select(provFee);
                        }
                    })
                })
            }
        })

        if (!!this.isBilling) {
            this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
        }

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                if (this.historyFromDate !== rangeDate.historyFromDate || this.historyToDate !== rangeDate.historyToDate) {
                    this.clearCheckboxesSelection();
                    this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
                }

                // Actualiza las fechas anteriores
                this.historyFromDate = rangeDate.historyFromDate;
                this.historyToDate = rangeDate.historyToDate;
            }
        })

        // Attentions filtered
        this._attentions = this.attentionsFacade.getAttentions$().subscribe(attentions => {
            if (!!attentions) {
                this.attentionsINRange = attentions.filter(att => moment(att.fromDate).isBetween(this.historyFromDate, this.historyToDate, undefined, '[]') && !att.isSupply);
                this.getTotalsFooter();
                this.dataSource.data = this.markProvisions();
            }
        })

        this.isLoadingGetAuthorizations$ = this.provisionsFacade.isLoadingGetAuthorizations$();

        this._provisionsDisplayedOnScreen = this.provisionsFacade.getProvisionsDisplayedOnScreen$().subscribe(provisions => {
            if (!!provisions) {
                // Always is filtered with provisions displayed on screen
                this.provisionsFiltered = provisions?.filter((aprov) => { return (this.practices?.some(practice => practice?.id == aprov?.practice?.id)) });
                this.dataSource.data = this.markProvisions();
            }
            else {
                this.dataSource.data = [];
            }
        })

        this._caseArrangements = this.casesFacade.getCaseArrangements$().subscribe(arrangements => {
            if (arrangements) {
                this.caseArrangements = arrangements;
            }
        })

        this._btnsDiasabled = this.generalService.getDisabledBtnsPeriodSelector$().subscribe(btnsDiasabled => this.btnsDisabled = btnsDiasabled);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['allProvisionsWithoutFilter']) {
            this.allProvisionsWithoutFilter = changes['allProvisionsWithoutFilter'].currentValue;
        }

        if (!!this.dataSource && !!this.dataSource.data) {
            // Creo un array para guardar los agreements seleccionados que tienen vigencia en un periodo de tiempo diferente
            let persistedAgreementsIds = []

            // Busco en los datos que me muestra la tabla para ver si el agreement seleccionado existe
            this.dataSource.data.forEach(prov => {
                this.selectionAgreements.selected.forEach(selAg => {
                    // Si existen, se pushean al array inicial
                    persistedAgreementsIds.push(prov.agreements.filter(ag => ag.id === selAg.id))
                })
            })

            // Se filtran los resultados vacios, se eliminan arrays adicionales y se guardan los ids de los agreements
            persistedAgreementsIds = persistedAgreementsIds.filter(i => i.length != 0).flat().map(ag => ag.id)

            this.selectionAgreements.selected.forEach(selAg => {
                // Compara los ids del array con los agreements seleccionados, si no existen, los deselecciona
                if (!persistedAgreementsIds.includes(selAg.id)) {
                    this.selectionAgreements.deselect(selAg)
                }
            })
        }
    }

    setPracticeTableFocus() {

        if (this.matTable) {
            this.matTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            this.matTable.nativeElement.focus();
        } else {
            console.log('Elemento no encontrado');
        }


    }


    getTotalsFooter() {
        // TOTAL AGENDADO
        this.totalValueFooter = parseFloat(this.attentionsINRange?.
            reduce((acum, attention) => acum + (!!this.practiceCountedByHours(attention?.practice)
                ? (attention.toDate && attention.fromDate) ? Math.round(moment(attention.toDate).diff(moment(attention.fromDate)) / (1000 * 60 * 60)) : 1 // Attention by hours
                : 1) // Attention by unit
                * attention.provisionFee, 0
            ).toFixed(2))

        // TOTAL FACTURABLE
        this.totalInvoicedFooter = parseFloat(this.attentionsINRange?.
            filter(att => att.state.id === AttentionStates.FACTURABLE).
            reduce((accum, attention) => accum + (!!this.practiceCountedByHours(attention.practice)
                ? attention.quantity ? attention.quantity : 1
                : 1)
                * attention.provisionFee, 0
            ).toFixed(2))

        // TOTAL DEBITABLE
        this.totalDebitedFooter = parseFloat(this.attentionsINRange?.
            filter(att => att.state.id === AttentionStates.DEBITADA).
            reduce((accum, attention) => accum + (!!this.practiceCountedByHours(attention.practice)
                ? attention.quantity ? attention.quantity : 1
                : 1)
                * attention.provisionFee, 0
            ).toFixed(2))
        // TOTAL INFORMABLE
        this.totalDeliveredFooter = parseFloat(this.attentionsINRange?.
            filter(att => att.state.id === AttentionStates.INFORMADA).
            reduce((accum, attention) => accum + (!!this.practiceCountedByHours(attention.practice)
                ? attention.quantity ? attention.quantity : 1
                : 1)
                * attention.provisionFee, 0
            ).toFixed(2))
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    createAgreementForm(): FormGroup {
        return this.formBuilder.group({
            [this.PROVIDER]: ['', [CustomValidators.required('Prestador requerido')]],
            [this.PROVIDER_SEARCHING]: [''],
            [this.PROVIDER_NAME]: [{ value: '', disabled: true }], // Readonly if edit mode
            [this.AGREEMENT_FROM_DATE]: ['', []],
            [this.AGREEMENT_TO_DATE]: [''],
            [this.FILTERED_BY_REGION]: [''],
            [this.AGREEMENT_PROVISION_FEE]: [{ value: '', disabled: (!!this.enableMarginField && !this.isAddModeAgreement) }, [CustomValidators.required('Honorarios del prestador requeridos'), CustomValidators.minValue(0, 'Debe ser un valor positivo')]],
            [this.AGREEMENT_MARGIN]: [{ value: '0', disabled: !this.enableMarginField }, []],
            radioButton: new FormControl({ value: 'cost', disabled: false },),
        });
    }

    onChangeExpand(expanded) {
        this.isExpanded = expanded;
        this.onChangeExpandEvent.emit(this.isExpanded);
    }

    openAddProvisionDialog() {
        this.isAddModeProvision = true;
        this.isShowAuth = true;
        this.isRetroactive = false;
        this.isShowRetroactive = false; // Hide element
        this.actionProvision = 'AGREGAR';
        this.provisionToWork = null;
        this.provisionFeeToWork = null;
        this.title = 'Agregar esquema prestacional';
        this.checkboxAuthorizationText = 'Agregar autorización'
        this.provisionForm()
    }

    openUpdateProvisionDialog(provision: Provision, provisionFee: ProvisionFee) {
        this._dialogRef.close();
        this.isAddModeProvision = false;
        this.isRetroactive = false;
        this.isShowRetroactive = false; // Hide element
        this.actionProvision = 'GUARDAR';
        this.provisionToWork = provision;
        this.provisionFeeToWork = provisionFee
        this.title = 'Corregir esquema prestacional';
        this.provisionForm(this.provisionToWork, this.provisionFeeToWork);
    }

    openUpdateProvisionDialogWithRetroactiveMode(provision: Provision, provisionFee: ProvisionFee) {
        console.log("PROVISION: ", provision);
        console.log("PROVISION-FEE: ", provisionFee);
        this.checkboxAuthorizationText = 'Agregar autorización'

        if (!!provisionFee.arrangementId) {
            this.arrangementSelected = this.casesFacade.getCaseArrangement(provisionFee.arrangementId);
        } else {
            this.arrangementSelected = null;
        }

        this.isAddModeProvision = false;
        this.isRetroactive = false;
        this.isShowRetroactive = true;
        this.isShowAuth = true;
        this.actionProvision = 'GUARDAR';
        this.provisionToWork = provision;
        this.provisionFeeToWork = provisionFee
        this.title = 'Renovar esquema prestacional';

        this.provisionForm(this.provisionToWork, this.provisionFeeToWork);
    }

    createProvisionForm(provision?: Provision, provisionFee?: ProvisionFee) {

        this.formFrequency = this.createFrequencyGroup(!!provisionFee ? provisionFee : provision); // Accept Provision or ProvisionFee

        // Form auth, it has other controls if add/edit provision
        this.formAuthorization = this.createAuthorizationGroup();
        let radioButton = '';
        if (!this.caseArrangements || this.caseArrangements.length == 0) {
            radioButton = 'S/C';
            this.practicesToShow = this.practices;
        }

        // If exist provisionFee.arrangement
        this.showTxtWithOutArrangement = !!provisionFee?.arrangementId ? true : false;

        return this.formBuilder.group({
            arrangement: new FormControl({ value: radioButton, disabled: false }),
            [this.PRACTICE]: ['', CustomValidators.required('Práctica requerida')],
            [this.PRACTICE_SEARCHING]: [''],
            [this.FRECUENCY]: this.formFrequency,
            [this.VALIDITY_FROM_DATE]: [''],
            [this.VALIDITY_TO_DATE]: [''],
            [this.FEE]: [!!provisionFee ? provisionFee.fee : !!provision?.fee ? provision.fee : null,],
            [this.FEE_TXT]: ['Por convenio'],
            [this.IS_ADDING_AUTHORIZATION]: [{ value: false, disabled: this.isShowAuth ? false : true }],
            [this.AUTHORIZATION]: this.formAuthorization,
            [this.IS_MANUAL_VALUE]: [true],
            [this.TOTAL_AMOUNT]: [!!provisionFee ? provisionFee.totalAmount : '',],
        });
    }

    provisionForm(provision?: Provision, provisionFee?: ProvisionFee) {
        this.arrangementItemsByPractice = null;
        this.showAlertMsg = false;
        this.showPracticeAndAgreementExistent = false;
        this.arrangementSelected = (!!provisionFee?.arrangementId) ? this.caseArrangements.find(arr => arr.id == provisionFee.arrangementId) : undefined;

        this.practicesToShow = this.practices;
        this.formProvision = this.createProvisionForm(provision, provisionFee);

        if (!this.showTxtWithOutArrangement) {
            this.formProvision.get('fee').setValidators([CustomValidators.required('Honorarios requeridos'), CustomValidators.minValue(0, 'Debe ser un valor positivo')]);
        }

        if (this.isAddModeProvision) {

            setTimeout(() => {
                this.formProvision.get(this.FEE).valueChanges.subscribe(value => {
                    if (!!value) {
                        this.formProvision.get(this.IS_MANUAL_VALUE).setValue(true);
                    }
                });
            }, 1000);
        } else {
            if (!!provisionFee) {
                this.formProvision.get('fromDate').setValue(provisionFee.fromDate)
            } else {
                this.formProvision.get('fromDate').setValue(this.toDay)
            }

            this.formProvision.get('fromDate').setValidators(CustomValidators.required('Vigencia desde requerida'));

            // VALIDITY_TO_DATE
            if (!!provisionFee) {
                this.formProvision.get('toDate').setValue(provisionFee.toDate)
            }

            if (!!this.isAddingAuthorization || (!this.isAddModeProvision && !!this.provisionFeeToWork.toDate)) {
                this.formProvision.get(this.VALIDITY_TO_DATE).setValidators(CustomValidators.required('Vigencia hasta requerida'));
                this.formAuthorization.get(this.AUTHORIZATION_FROM_DATE).setValue(provisionFee.fromDate);
                this.formAuthorization.get(this.AUTHORIZATION_TO_DATE).setValue(provisionFee.toDate);
            } else {
                this.formProvision.get('toDate').clearValidators();
            }
        }

        if (this.isShowRetroactive) {
            this.toogleRetroactive(); // Set rules to retroactive mode
        }

        this.openDialog(this.title, this.provisionTemplate, { maxHeight: '95vh', minWidth: '40%' },
            () => { }
        );

        this._formProvision = this.formProvision.valueChanges.subscribe(form => {

            if (!!this.provisionFromDate && !!this.provisionToDate &&
                (this.formProvision.controls['fromDate'].touched || this.formProvision.controls['toDate'].touched) &&
                (this.formProvision.controls['fromDate'].dirty || this.formProvision.controls['toDate'].dirty)) {

                if (this.isAddModeProvision) {
                    if (this.isControlsDateOkUCreatingProvision(this.provisionFromDate, this.provisionToDate)) {
                        this.formProvision.controls['fromDate'].markAsUntouched();
                        this.formProvision.controls['toDate'].markAsUntouched();
                    }
                } else {
                    if (!this.isShowRetroactive) {
                        if (this.isControlsDateOkUpdatingProvision(this.provisionFromDate, this.provisionToDate)) {
                            this.formProvision.controls['fromDate'].markAsUntouched();
                            this.formProvision.controls['toDate'].markAsUntouched();
                        }
                    }
                }
            }
            // Fees's list / Price list
            if (!!this.arrangementSelected) {
                if (!!form.practiceId) {
                    this.arrangementItemsByPractice = this.getArrangementItemsByPracticeValids(this.arrangementSelected.id, form.practiceId, this.formProvision.get('fromDate').value, this.formProvision.get('toDate').value);
                } else {
                    this.existPriceList = true;
                }
            } else {
                // Nothing to do
            }
        })
    }

    openAuthorizations(provision: Provision, provisionFee: ProvisionFee) {

        this.authorizations = [];
        // this.provisionsFacade.loadAuthorizations(this.case.id, provision.id).subscribe(auths => this.authorizations = auths);
        this.authorizations.push(provisionFee.authorization);
        this.generalService.showMoreInfoSideBarRight(`Autorizaciones para ${provision.practice.name}`, this.showAuthorizations);
    }

    openUpdateAuthorizationDialog(provisionFee: ProvisionFee) {

        this.provisionsToUpdate = this.selectionProvisions.selected;
        this.provisionFeeSelectedToAutorize = provisionFee;
        this.formAuthorization = this.createAuthorizationGroup(provisionFee);

        // Only auth, then set custom validators
        this.formAuthorization.get('authorizationNumber').setValidators([CustomValidators.required('Número de autorización requerido')]);
        this.formAuthorization.get('toDate').setValidators([CustomValidators.required('Fecha de fin de autorización requerida'), CustomValidators.dateGreaterEqualThan(provisionFee.fromDate)]);
        this.formAuthorization.updateValueAndValidity();

        this.openDialog(
            'Agregar autorización',
            this.updateAuthorizationTemplate,
            { maxHeight: '95vh', minWidth: '40%' },
            () => { }
        );
    }

    onClickCreateAuthorization() {
        if (this.formAuthorization.get(this.AUTHORIZATION_OBSERVATIONS).value == '') {
            this.formAuthorization.get(this.AUTHORIZATION_OBSERVATIONS).setValue('Sin observaciones.')
        }
        this.provisionsFacade.addAuthorizationToProvisions(
            this.formAuthorization.value,    // Form authorization
            [this.provisionFeeSelectedToAutorize],   // Provisions selected
            [this.photoEvolutionB64]
        ).subscribe(() => {
            this.swalWithCustomizeButtons.fire({
                text: 'Autorización cargada correctamente',
                icon: 'success'
            }).then(() => {
                this._dialogRef.close();
            });
        });
    }

    openAddAgreementDialog(provision: Provision) {
        this.isAddModeAgreement = true;
        this.actionAgreement = 'CREAR';
        this.provisionToWork = provision;
        this.agreementToWork = null;
        this.title = 'Crear acuerdo prestacional';
        this.agreementForm();
        //
        let practice = this.practices.filter(practice => practice.specialty.id == this.provisionToWork.specialtyId);
        this.specialtySelected = practice[0].specialty;
    }

    openUpdateAgreementDialog(provision: Provision, agreement: Agreement) {
        this.isAddModeAgreement = false;
        this.isUpdateModeAgreement = true;
        this.actionAgreement = 'ACTUALIZAR';
        this.provisionToWork = provision;
        this.agreementToWork = agreement;
        this.title = 'Actualizar acuerdo prestacional';
        this.openUpdateAgreementFeeDialog();
    }

    openFixAgreementDialog(provision: Provision, agreement: Agreement) {
        this.isAddModeAgreement = false;
        this.isUpdateModeAgreement = false;
        this.actionAgreement = 'CORREGIR';
        this.provisionToWork = provision;
        this.agreementToWork = agreement;
        this.title = 'Corregir acuerdo prestacional';
        this.openUpdateAgreementFeeDialog();
    }

    agreementForm = () => {

        let providerQPS: ProvidersQPS;
        providerQPS = { ...this.providerQPS_Default, specialtyId: this.provisionToWork.specialtyId }; // Add specialtyId

        if (this.isAddModeAgreement) {
            this.loadProviders(providerQPS); // Load providers default
        }

        this.formAgreement = this.createAgreementForm();

        if (!this.isAddModeAgreement) {
            this.formAgreement.get('providerFee').setValue(Number(this.agreementToWork.providerFee));
            this.formAgreement.get('providerName').setValue(this.entitiesFacade.getProvidersFullName(this.agreementToWork.provider));
            if (!!this.isUpdateModeAgreement) {
                if (!!this.agreementToWork) {
                    if (!!this.agreementToWork.toDate) {
                        let newFromDate = moment(this.agreementToWork.toDate, "YYYY-MM-DD").add(1, "days").toDate();
                        this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValue(newFromDate);
                    } else {
                        let actualDate = moment(new Date(), 'YYYY-MM-DD').hours(0).minutes(0).seconds(0);
                        let agreementFromDate = moment(this.agreementToWork.fromDate, 'YYYY-MM-DD').hours(0).minutes(0).seconds(0);
                        if (moment(actualDate.format('YYYY-MM-DD')).isSameOrBefore(moment(agreementFromDate.format('YYYY-MM-DD')))) {
                            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValue(moment(this.agreementToWork.fromDate).add(1, "days").toDate());
                        } else {
                            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValue(moment(moment().format('YYYY-MM-DD')).toDate());
                        }
                    }
                }
            } else {
                if (!!this.agreementToWork) {
                    this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValue(this.agreementToWork.fromDate);
                    if (!!this.agreementToWork.toDate) {
                        this.formAgreement.get(this.AGREEMENT_TO_DATE).setValue(this.agreementToWork.toDate);
                    }
                }
            }

            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValidators(CustomValidators.required('Fecha requerida'));

            if (this.isUpdateModeAgreement) {
                this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValidators(CustomValidators.dateGreaterThan(this.agreementToWork.fromDate));
            }

            if (!this.isUpdateModeAgreement) {
                // this.formAgreement.get(this.AGREEMENT_TO_DATE).setValidators(Validators.compose([CustomValidators.dateGreaterThan( this.formAgreement.get(this.AGREEMENT_FROM_DATE).value )]))
            }
        } else {
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValue(moment(this.historyFromDate).toDate());
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValidators(Validators.compose([CustomValidators.required('Fecha requerida'), CustomValidators.dateLowerThan(this.formAgreement.get(this.AGREEMENT_TO_DATE).value), CustomValidators.required('Fecha de inicio de acuerdo requerida')]));
            this.formAgreement.get(this.AGREEMENT_TO_DATE).setValidators(Validators.compose([CustomValidators.dateGreaterThan(this.formAgreement.get(this.AGREEMENT_FROM_DATE).value)]))
        }

        this.openDialogAgreementTemplate(this.title, this.agreementTemplate, { maxHeight: '95vh', minWidth: '30%' }, () => { });
    }

    setAgreementDateCustomValidators(fromDateSelected, toDateSelected) {
        this.addCustomValidatorsfromDate(toDateSelected);
        this.addCustomValidatorsToDate(fromDateSelected);
    }

    addCustomValidatorsfromDate(date: Date) {
        if (moment(date).isValid()) {
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).clearValidators();
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValidators(CustomValidators.required('Fecha requerida'));
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setValidators(CustomValidators.dateLowerEqualThan(date));
        }
    }

    addCustomValidatorsToDate(date: Date) {
        if (moment(date).isValid()) {
            this.formAgreement.get(this.AGREEMENT_TO_DATE).clearValidators();
            this.formAgreement.get(this.AGREEMENT_TO_DATE).setValidators(Validators.compose([CustomValidators.dateGreaterEqualThan(date)]));
        }
    }

    onClickDateFrom(event) {
        if (moment(event.value).isValid()) {
            let fromDateSelected = moment(event.value).toDate();
            let toDateSelected = null;
            if (moment(this.formAgreement.get(this.AGREEMENT_TO_DATE).value).isValid()) {
                toDateSelected = moment(this.formAgreement.get(this.AGREEMENT_TO_DATE).value).hours(23).minutes(59).seconds(59).toDate();
            }
            this.formAgreement.get(this.AGREEMENT_TO_DATE).setErrors(null);
            this.setAgreementDateCustomValidators(fromDateSelected, toDateSelected);
        }
    }

    onClickDateTo(event) {
        if (!!event.value) {
            if (moment(event.value).isValid()) {
                let toDateSelected = moment(event.value).hours(23).minutes(59).seconds(59).toDate();
                let fromDateSelected = null;
                if (!!this.formAgreement.get(this.AGREEMENT_FROM_DATE).valid) {
                    fromDateSelected = moment(this.formAgreement.get(this.AGREEMENT_FROM_DATE).value).toDate();
                }
                this.formAgreement.get(this.AGREEMENT_FROM_DATE).setErrors(null);
                this.setAgreementDateCustomValidators(fromDateSelected, toDateSelected);
            }
        } else {
            this.formAgreement.get(this.AGREEMENT_FROM_DATE).setErrors(null);
            this.formAgreement.get(this.AGREEMENT_TO_DATE).clearValidators();
            if (this.formAgreement.get(this.AGREEMENT_TO_DATE).touched) {
                this.formAgreement.get(this.AGREEMENT_TO_DATE).setValue(null);
            }

        }
    }

    clickRadiobuttonAgreementCost(event) {
        if (!!event && !!event.value) {
            if (event.value == 'margin') {
                this.enableMarginField = true;
                this.formAgreement.get(this.AGREEMENT_PROVISION_FEE).disable();
                this.formAgreement.get(this.AGREEMENT_MARGIN).enable();
            } else {
                this.enableMarginField = false;
                this.formAgreement.get(this.AGREEMENT_MARGIN).disable();
                this.formAgreement.get(this.AGREEMENT_PROVISION_FEE).enable();
            }
        }
    }

    updateMargin() {
        let newMargin = this.formAgreement.get(this.AGREEMENT_PROVISION_FEE).value / this.agreementToWork.providerFee;
        newMargin *= 100;
        newMargin -= 100;
        this.formAgreement.get(this.AGREEMENT_MARGIN).setValue(newMargin.toFixed(2));
    }

    updateCost() {
        if (!!this.formAgreement.get(this.AGREEMENT_MARGIN).value && this.formAgreement.get(this.AGREEMENT_MARGIN).value != '') {
            let newCost = this.agreementToWork.providerFee + (this.agreementToWork.providerFee * (this.formAgreement.get(this.AGREEMENT_MARGIN).value / 100));
            this.formAgreement.get(this.AGREEMENT_PROVISION_FEE).setValue(Number(newCost.toFixed(2)));
        } else {
            this.formAgreement.get(this.AGREEMENT_MARGIN).setValue(Number(Number(0).toFixed(2)));
        }
    }

    onClickConfirmFormAgreement() {

        const formValues = { ...this.formAgreement.value };
        delete formValues.providerSearching;
        delete formValues.isFilteredByRegion;
        formValues.fromDate = this.formAgreement.get(this.AGREEMENT_FROM_DATE).value;
        if (!!this.isAddModeAgreement || (!this.isAddModeAgreement && !this.isUpdateModeAgreement)) {
            formValues.toDate = this.formAgreement.get(this.AGREEMENT_TO_DATE).value;
        }
        delete formValues.agreementFromDate;
        delete formValues.agreementToDate;
        if (!formValues.providerFee) {
            let newCost = this.agreementToWork.providerFee + (this.agreementToWork.providerFee * (this.formAgreement.get(this.AGREEMENT_MARGIN).value / 100));
            formValues.providerFee = newCost;
        }
        delete formValues.marginFee;
        delete formValues.radioButton;

        if (this.isAddModeAgreement) {

            // Add agreement
            (this.provisionsFacade.addAgreement(formValues, this.provisionToWork.id, this.case.id) as Observable<Agreement>).subscribe(() => {
                this.swalWithCustomizeButtons.fire({
                    text: 'El acuerdo prestacional se creó correctamente',
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false
                }).then(() => this._dialogRefAgreementTemplate.close());
            });
        } else {

            // Edit agreement
            delete formValues.providerId;

            let successMsg = () => {

                this.swalWithCustomizeButtons.fire({
                    text: 'El acuerdo prestacional se actualizó correctamente',
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false
                }).then(() => {
                    //  this.casesFacade.setCaseDateFilters( this.casesFacade.getCaseDateFilter() ); // It isn't used anymore
                    this._dialogRefAgreementTemplate.close();
                })
            }

            formValues.force = false;
            if (!this.isUpdateModeAgreement) {

                formValues.edit = true;

                if (formValues.providerFee == this.agreementToWork.providerFee || !this.formAgreement.get(this.PROVIDER_FEE).dirty) {
                    delete formValues.providerFee
                }
            }

            this.provisionsFacade.updateAgreement(
                formValues,
                this.case.id,
                this.provisionToWork.id,
                this.agreementToWork.id
            ).subscribe({
                next: () => { successMsg(); },
                error: error => {
                    if (error.status === 418) {
                        let errorContent: HttpBaseResponseErrorTeaPot = error.error;

                        // WARNING! It's a promise, waiting the user
                        this.attentionsService.processErrorCountAttentions(errorContent, 'error').then(res => {
                            if (!res) {
                                // Nothing to do
                                this._dialogRef.close();
                            } else {
                                // User confirm force
                                formValues.force = true;

                                this.provisionsFacade.updateAgreement(formValues, this.case.id, this.provisionToWork.id, this.agreementToWork.id).subscribe(
                                    () => successMsg()
                                )
                            }
                        })
                    }
                }
            })
        }
    }

    onClickCloseDialog() {
        this.photoEvolutionB64 = null;
        this.isAddingAuthorization = false;
        this._dialogRef.close();
    }

    onClickCloseDialogAgreementTemplate() {
        this._dialogRefAgreementTemplate.close();
    }

    openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    openDialogAgreementTemplate(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRefAgreementTemplate = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRefAgreementTemplate.afterClosed().subscribe(() => afterClosed());
    }

    createFrequencyGroup(provision?: Provision | ProvisionFee) {
        setTimeout(() => {
            this.frequencyUnitForm = provision
                && provision.frequency
                && provision.frequency.unit
                ? provision.frequency.unit
                : this.PROVISION_FREQUENCY_UNIT_DEFAULT;
        }, 0);
        return this.formBuilder.group({
            [this.AMOUNT]: [provision && provision.frequency && provision.frequency.amount ? provision.frequency.amount : '', [
                CustomValidators.required('Cantidad requerida'),
                CustomValidators.minValue(0, 'Debe ser un valor positivo')
            ]
            ],
            [this.UNIT]: [, [CustomValidators.required('Período requerido')]]
        });
    }

    createAuthorizationGroup(provisionFee?: ProvisionFee) {
        if (!!provisionFee) {
            return this.formBuilder.group({
                [this.AUTHORIZATION_NUMBER]: ['',],
                [this.AUTHORIZATION_FROM_DATE]: [(!!provisionFee.fromDate) ? provisionFee.fromDate : '',],
                [this.AUTHORIZATION_TO_DATE]: [(!!provisionFee.toDate) ? provisionFee.toDate : '',],
                [this.AUTHORIZATION_OBSERVATIONS]: ['']
            });
        } else {
            return this.formBuilder.group({
                [this.AUTHORIZATION_NUMBER]: ['',],
                [this.AUTHORIZATION_FROM_DATE]: ['',],
                [this.AUTHORIZATION_TO_DATE]: ['',],
                [this.AUTHORIZATION_OBSERVATIONS]: ['']
            });
        }
    }

    calculateNameUnit(element) {
        return element ? element.name : null;
    }

    calculateIdUnit(element) {
        return element ? element.id : null;
    }
    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }
    calculateNamePractice(element: Practice) {
        if (!!this.arrangementSelected) {
            let practices = this.arrangementSelected?.practiceArrangements?.filter(pr => pr.practiceId === element.id);
            let practice = practices[practices?.length - 1]

            if (!!practice?.code) {
                return element ? element.name + ' #' + practice.code : null;
            }
            return element ? element.name + ' #' + element.code : null;
        }
        return element ? element.name + ' #' + element.code : null;
    }

    onClickDeleteProvision() {
        this.swalWithCustomizeButtons.fire({
            title: `¿Seguro desea eliminar las prestaciones seleccionadas?`,
            icon: 'question',
            confirmButtonText: 'ELIMINAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: () => {
                return this.provisionsFacade.deleteProvisions(this.selectionProvisions.selected.map(prov => prov.id)).toPromise().then(() => {
                    this.swalWithCustomizeButtons.fire({
                        text: 'Prestaciones borradas correctamente',
                        icon: 'success'
                    }).then(() => {
                        // Refresh view
                        this.selectionProvisions.clear();
                        this.selectionAgreements.clear();
                        this.selectionProvisionFees.clear();
                        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());

                        this._dialogRef.close();
                    });
                }).catch(e => console.log('error', e));
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
    }

    onClickDeleteAgreement(provision: Provision, agreement: Agreement) {
        this.swalWithCustomizeButtons.fire({
            title: `¿Seguro desea eliminar el acuerdo prestacional?`,
            icon: 'question',
            confirmButtonText: 'ELIMINAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: () => {
                return this.provisionsFacade.deleteAgreement(this.case.id, provision.id, agreement.id).toPromise()
                    .then(() => {
                        this.swalWithCustomizeButtons.fire({
                            text: 'Acuerdo prestacional eliminado correctamente',
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false,
                        }).then(() => this._dialogRef.close());
                    })
                    .catch(error => {
                        if (error.status === 418) {
                            let errorContent: HttpBaseResponseErrorTeaPot = error.error;
                            // WARNING! It's a promise, waiting the user
                            this.attentionsService.processErrorCountAttentions(errorContent, 'error').then(res => {
                                if (!res) {
                                    // Nothing to do
                                } else {
                                    // User confirm force
                                    // Nothing to do, it doesn't fire force action
                                }
                            })
                        }
                    }
                    )
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
    }

    onClickConfirmFormProvision() {

        let formValues = { ...this.formProvision.value };

        delete formValues[this.PRACTICE_SEARCHING];
        delete formValues[this.FEE_TXT];
        delete formValues['arrangement'];

        console.log(formValues)

        // Auth
        if (!formValues[this.IS_ADDING_AUTHORIZATION]) {
            delete formValues[this.AUTHORIZATION];
        } else {
            if (!!this.photoEvolutionB64) {
                formValues.authorization.mediaUrls = [this.photoEvolutionB64];
            }
            if (!!formValues.fromDate) {
                formValues.authorization.fromDate = moment(formValues.fromDate)
            }
            if (!!formValues.toDate) {
                formValues.authorization.toDate = moment(formValues.toDate)
            }
        }


        delete formValues[this.IS_ADDING_AUTHORIZATION];

        if (!!formValues.fromDate)
            formValues.fromDate = moment(formValues.fromDate);

        if (!!formValues.toDate)
            formValues.toDate = moment(formValues.toDate);


        this.isAddingAuthorization = false;

        switch (true) {

            case this.isAddModeProvision || this.isShowRetroactive: // Add ProvisionFee or Retroactive mode=ON y mode=OFF

                if (formValues[this.IS_MANUAL_VALUE]) {
                    if (formValues[this.FEE] != null && formValues[this.FEE] != undefined) {
                        delete formValues[this.MARGIN];
                    } else {
                        delete formValues[this.FEE];
                    }
                } else {
                    if (!!formValues[this.FEE] && !(!!formValues[this.MARGIN])) {
                        delete formValues[this.MARGIN];
                    }
                    if (!!formValues[this.MARGIN] && !(!!formValues[this.FEE])) {
                        delete formValues[this.FEE];
                    }
                }

                delete formValues[this.IS_MANUAL_VALUE];

                // Add idToRefresh
                if (this.isShowRetroactive) {

                    formValues = { ...formValues, idToRefresh: this.provisionFeeToWork.id };

                    if (!!this.provisionFeeToWork.arrangementId) {
                        formValues = { ...formValues, arrangementId: this.provisionFeeToWork.arrangementId } // Add arrangementId if exists
                        delete formValues[this.FEE];
                    }

                    delete formValues[this.PRACTICE];
                }

                let endPoint: Observable<any>; // Save endpoint to execute
                let subtractOneDayPreviousProvisionFee: boolean = false;

                // New Provision
                if (this.isAddModeProvision) {
                    let postProvisionBody: PostProvisionBody = formValues;
                    if (!!this.arrangementSelected) {
                        postProvisionBody.arrangementId = this.arrangementSelected.id;
                    }
                    endPoint = this.provisionsFacade.addProvision(postProvisionBody, this.case.id)
                    // New ProvisionFee
                } else {
                    endPoint = this.provisionsFacade.addProvisionFee(formValues, this.case.id, this.provisionToWork.id)
                    subtractOneDayPreviousProvisionFee = true;
                }

                endPoint.subscribe(() => {
                    if (this.isRetroactive) {
                        subtractOneDayPreviousProvisionFee ? this.provisionState.updateToDateProvisionFee(moment(formValues.fromDate).subtract(1, 'day').toDate(), this.provisionToWork.id, this.provisionToWork.provisionFeeId) : null; // Subtract one day for previous provisionFee if retroactive mode = ON
                    }
                    this.actionMessageSuccesAddUpdateProvisionFee('El esquema prestacional se creó correctamente');
                })

                break;

            case !this.isAddModeProvision: // Edit ProvisionFee

                delete formValues.practiceId;
                delete formValues.is_manual_value;

                if (!formValues.profitMargin)
                    delete formValues.profitMargin;

                if (!this.disabledButtonSubmitUpdateProvision) {

                    // Frecuency
                    if (!this.formFrequency.get(this.UNIT).dirty && !this.formFrequency.get(this.AMOUNT).dirty) {
                        delete formValues.frequency;
                    }

                    // Validity
                    /* if (!this.formProvision.get(this.VALIDITY_FROM_DATE).dirty && !this.formProvision.get(this.VALIDITY_TO_DATE).dirty) {
                       delete formValues.fromDate;
                       delete formValues.toDate;
                    } */

                    // Fee
                    if (!this.formProvision.get(this.FEE).dirty) {
                        delete formValues.fee; // To delete fee then patch always else back-end analyze other rules
                    }

                    // Total amount
                    if (!this.formProvision.get(this.TOTAL_AMOUNT).value) {
                        delete formValues.totalAmount;
                    }

                    this.provisionsFacade.updateProvisionFeePatch(formValues, this.case.id, this.provisionToWork.id, this.provisionFeeToWork.id).subscribe(
                        () => {     // Success
                            this.actionMessageSuccesAddUpdateProvisionFee('El esquema prestacional se actualizó correctamente');
                        },
                        error => {  // Error
                            if (error.status === 418) {
                                let errorContent: HttpBaseResponseErrorTeaPot = error.error;

                                // User wants force the process
                                if (errorContent.data.force) { // Edit text
                                    errorContent = { ...errorContent, message: 'La prestación que se quiere corregir tiene atenciones en estado facturable ¿Desea continuar?' }
                                }

                                // WARNING! It's a promise, waiting the user
                                this.attentionsService.processErrorCountAttentions(errorContent).then(res => {
                                    if (!res) {
                                        this._dialogRef.close()
                                    } else {
                                        // User confirm force
                                        formValues = { ...formValues, force: true };
                                        this.provisionsFacade.updateProvisionFeePatch(formValues, this.case.id, this.provisionToWork.id, this.provisionFeeToWork.id).subscribe(
                                            () => {
                                                // Success
                                                this.actionMessageSuccesAddUpdateProvisionFee('El esquema prestacional se actualizó correctamente')
                                            })
                                    }
                                })
                            }
                        }
                    );
                }
                break;

            default:
                break;
        }
    }

    actionMessageSuccesAddUpdateProvisionFee(msg: string) {
        this.swalWithCustomizeButtons.fire({
            text: msg,
            icon: 'success'
        }).then(() => {
            this._dialogRef.close()
            this.provisionsFacade.setFiltersOnProvisions(this.updateFilter()); // It needs reload attentions
        });
    }

    isControlsDateOkUCreatingProvision(from: Date, to: Date): boolean {

        if (!!this.practiceId && this.isPracticePresent(this.practiceId)) {
            this.provisionToWork = this.allProvisionsWithoutFilter.find(prov => prov.practiceId == this.practiceId); // Select
            this.isControlsDateOkUpdatingProvision(from, to); // Same control to update
        } else {
            this.provisionToWork = null;

            if (this.isValidityDate(from, to)) { // It can true or false
                this.disabledButtonSubmitUpdateProvision = false;
                return true
            } else {
                this.disabledButtonSubmitUpdateProvision = true;
                return false
            }
        }
    }

    isControlsDateOkUpdatingProvision(from: Date, to: Date): boolean {
        if (this.isValidityDate(from, to)) { // It can true or false
            if (this.isValidityDateRangeWithProvisionsFees(from, to)) { // Always true, only info message
                if (this.isValidityDateWithoutRangeBlanks(from, to)) { // Always true, only info message
                }
            }
        } else {
            this.disabledButtonSubmitUpdateProvision = true;
            return false
        }
        this.disabledButtonSubmitUpdateProvision = false;
        return true
    }

    isValidityDate(provFromDate: Date, provToDate: Date): boolean {

        const provFrom = moment(provFromDate).format('YYYY-MM-DD')
        const provTo = moment(provToDate).format('YYYY-MM-DD')

        if (provFrom > provTo) {
            this.alertService.openError('El rango de fechas desde-hasta es incorrecto')
            return false
        }
        return true;
    }

    isValidityDateRangeWithProvisionsFees(provFromDate: Date, provToDate: Date): boolean {

        const provFrom = moment(provFromDate).format('YYYY-MM-DD')
        const provTo = moment(provToDate).format('YYYY-MM-DD')

        // Input range inside some provision_fee
        // Looking for all provisions-fee
        if (this.allProvisionsWithoutFilter.find(provision => provision.id === this.provisionToWork?.id).provisionFees
            .filter(prov => prov.id != this.provisionFeeToWork?.id)
            .find(prov => (provFrom >= moment(prov.fromDate).format('YYYY-MM-DD') && provFrom <= moment(prov.toDate).format('YYYY-MM-DD'))
                ||
                (provTo >= moment(prov.fromDate).format('YYYY-MM-DD') && provTo <= moment(prov.toDate).format('YYYY-MM-DD')))) {

            return false;
        }
        return true;
    }

    isValidityDateWithoutRangeBlanks(provFromDate: Date, provToDate: Date): boolean {

        const provFrom = moment(provFromDate).format('YYYY-MM-DD')
        const provTo = moment(provToDate).format('YYYY-MM-DD')

        // Input range out range with blanks
        if (moment(provFromDate).diff(this.provisionToWork.provisionFees[this.provisionToWork.provisionFees.length - 1].toDate, 'days') > 1) {
            return false;
        }
        return true;
    }

    onClickSignature(provider) {
        this.loadingSignature = true;
        this.providersService.getSignaturePhoto(provider.id).subscribe
            ((data) => {
                this.loadingSignature = false;
                this.openDialogImage(data.mediaUrl);
            },
                (er) => {
                    this.loadingSignature = false;
                });
    }

    openDialogImage(_image): void {
        const dialogRef = this.dialog.open(DialogImageDetailComponent, {
            width: '250vw',
            data: { image: _image },
        });
        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    onSelectPractice(practiceId: number): void {
        this.formProvision.get(this.FEE).reset();
        if (!!this.provisionFromDate) {
            this.validateShowMessages();
        }
    }

    isPracticePresent(practiceId: number): boolean {
        return this.allProvisionsWithoutFilter.filter(prov => prov.practiceId == practiceId).length > 0;
    }

    isAllSelected(): boolean {
        const numSelected = this.selectionProvisionFees.selected?.filter(selected => this.dataSource?.data.some(prov => prov.provisionFees.some(pF => pF.id == selected.id))).length;
        const totalProvisionFees = this.dataSource.data?.flatMap(prov => prov.provisionFees).length;
        let allAgreementsSelected: boolean = true;
        let allProvisionFeesSelected: boolean = true;
        this.selectionProvisions.selected?.forEach(selected => {
            if (this.isAgreementsIndeterminated(selected)) {
                allAgreementsSelected = false;
            }
            /* if (this.isProvisionFeeIndeterminated(selected)){
               allProvisionFeesSelected = false;
            } */
        })
        return (numSelected === totalProvisionFees) && allAgreementsSelected && allProvisionFeesSelected;
    }

    masterToggle() {

        if (this.isAllSelected()) {
            this.clearCheckboxesSelection();
        } else {
            if (!!this.dataSource.data && this.dataSource.data.length > 0) {
                this.dataSource.data.forEach(row => this.selectPractice(row, true));
            }
        }
        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    clearCheckboxesSelection() {
        this.selectionProvisions.clear();
        this.selectionAgreements.clear();
        this.selectionProvisionFees.clear();
    }

    // When click on checkbox Agreement
    onChangeChild(provision: Provision, agreement: Agreement) {
        this.selectionAgreements.toggle(agreement)
        this.verifySelectionPractice(provision);
        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }


    // When click on checkbox on Provision/ProvisionFee
    onChangeChildProvisionFee(provision: Provision, provisionFee: ProvisionFee) {
        this.selectionProvisionFees.toggle(provisionFee)
        this.verifySelectionPractice(provision);
        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    verifySelectionPractice(data: Provision) {
        //TODO: Borrar si no fallan los checks
        //let agreementsNotSelected = data.agreements?.filter(agr => !this.isAgreementSelected(agr));
        /*  let provisionFeesNotSelected = data.provisionFees?.filter(provFee => !this.isProvisionFeeSelected(provFee));
         let pfns = false;
         //let ans = false;
         if (data.provisionFees){
            pfns = (provisionFeesNotSelected.length > 0 && provisionFeesNotSelected.length == data.provisionFees.length);
         } */
        /* if (data.agreements){
           ans = (agreementsNotSelected.length > 0 && agreementsNotSelected.length == data.agreements.length);
        } */
        /* if (pfns ){ //&& ans
           if (!this.selection.selected.find(provision => provision.id == data.id)){
              this.selection.select(data);
           } else {
              this.selection.deselect(this.selection.selected.find(sel => sel.id == data.id));
           }
        } else {
           if (!this.selection.selected.find(provision => provision.id == data.id)){
              this.selection.select(data);
           } else {
              if (this.isEmptySelectionProvisionFeesForPractice(data)){
                 this.selection.deselect(data);
                 data.agreements.forEach(ag => this.selectionAgreements.deselect(ag))
              }
           }
        } */

        if (this.isPracticeSelected(data)) {
            if (this.isEmptySelectionProvisionFeesForPractice(data)) {
                this.selectionProvisions.deselect(this.selectionProvisions?.selected?.find(prov => prov.id == data.id));
                data.agreements.forEach(ag => {
                    if (this.selectionAgreements.isSelected(ag)) {
                        this.selectionAgreements.deselect(ag);
                    }
                })
            }
        } else {
            if (!this.isEmptySelectionProvisionFeesForPractice(data)) {
                this.selectionProvisions.select(data);
            }
        }
    }

    selectPractice(row, masterToggle: boolean = false) {

        //let isPracticeIndeterminated = this.isPracticeIndeterminated(row);
        //let isProvisionFeeIndeterminated = this.isProvisionFeeIndeterminated(row);

        if (row.agreements) {
            if (!masterToggle && !this.isAgreementsIndeterminated(row) && !this.isEmptySelectionAgreementsForPractice(row)) { //&& !isPracticeIndeterminated
                row.agreements.forEach(agr => {
                    this.selectionAgreements.deselect(this.selectionAgreements.selected.find(sel => sel.id == agr.id));
                })
            } else {
                let agreementsNotSelected = row.agreements.filter(agr => !this.isAgreementSelected(agr));
                agreementsNotSelected.forEach(agr => {
                    this.selectionAgreements.select(agr);
                })
            }
        }

        if (row.provisionFees) {
            if (!masterToggle && !this.isEmptySelectionProvisionFeesForPractice(row)) { //&& !isPracticeIndeterminated && !isProvisionFeeIndeterminated
                row.provisionFees.forEach(provFee => {
                    this.selectionProvisionFees.deselect(this.selectionProvisionFees.selected.find(sel => sel.id == provFee.id));
                })
            } else {
                let provisionFeesNotSelected = row.provisionFees.filter(provFee => !this.isProvisionFeeSelected(provFee));
                provisionFeesNotSelected.forEach(provFee => {
                    this.selectionProvisionFees.select(provFee);
                })
            }
        }

        if (!masterToggle || (masterToggle && !this.selectionProvisions.selected.map(sel => sel.id).includes(row.id))) {
            if (this.selectionProvisions.selected.map(sel => sel.id).includes(row.id) && !this.isAgreementsIndeterminated(row)) { //&& !isPracticeIndeterminated
                this.selectionProvisions.deselect(this.selectionProvisions.selected.find(sel => sel.id == row.id));
            } else {
                if (!this.selectionProvisions.selected.map(sel => sel.id).includes(row.id)) {
                    this.selectionProvisions.select(row);
                }
            }
        }

        if (!masterToggle) {
            this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
        }
    }

    dateDiff(day: Date): number {
        if (!!day)
            return moment(day).diff(this.toDay, 'days');
        else
            return -1; // Vencido / hay un error en la fecha
    }

    isPracticeSelected(row) {
        return this.selectionProvisions.selected.map(sel => sel.id).includes(row.id);
    }

    isAgreementsIndeterminated(row: Provision) {
        let agreementsSelected = row.agreements?.filter(agr => this.selectionAgreements.selected.map(sel => sel.id).includes(agr.id));
        return this.isPracticeSelected(row) && row.agreements?.length != agreementsSelected?.length && agreementsSelected?.length != 0;
    }

    isEmptySelectionAgreementsForPractice(row) {
        let isEmptyAgreements: boolean = true;
        if (!!row.agreements && row.agreements.length > 0) {
            row.agreements?.forEach(agr => {
                if (this.selectionAgreements.selected.map(sel => sel.id).includes(agr.id)) {
                    isEmptyAgreements = false
                }
            })
        } else {
            isEmptyAgreements = false;
        }
        return isEmptyAgreements;
    }

    isEmptySelectionProvisionFeesForPractice(row) {
        let isEmptyProvisionFees: boolean = true;
        row.provisionFees?.forEach(provFee => {
            if (this.selectionProvisionFees.selected.map(sel => sel.id).includes(provFee.id)) {
                isEmptyProvisionFees = false
            }
        })
        return isEmptyProvisionFees;
    }

    isAgreementSelected(agreement: Agreement) {
        return this.selectionAgreements.selected.map(sel => sel.id).includes(agreement.id);
    }

    isProvisionFeeSelected(provisionFee: ProvisionFee) {
        return this.selectionProvisionFees.selected.map(sel => sel.id).includes(provisionFee.id);
    }

    /* isProvisionFeeIndeterminated(row : Provision){
        let provisionFeeSelected = row.provisionFees?.filter(provFee => this.selectionProvisionFee.selected.map(sel => sel.id).includes(provFee.id));

        return this.isAgreementsIndeterminated(row) //(this.isPracticeSelected(row) && row.provisionFees?.length != provisionFeeSelected?.length  && (provisionFeeSelected?.length != 0 || (provisionFeeSelected?.length == 0 && !!row.agreements && !this.isEmptySelectionAgreementsForPractice(row))))
                //    ||
                //(this.isPracticeSelected(row) && row.provisionFees?.length == provisionFeeSelected?.length) && (this.isAgreementsIndeterminated(row) || this.isEmptySelectionAgreementsForPractice(row));
    } */

    /* isPracticeIndeterminated(provision : Provision){
       let isIndeterminate = this.isAgreementsIndeterminated(provision);
       if (!isIndeterminate) {
          isIndeterminate = this.isProvisionFeeIndeterminated(provision);
       }
       return isIndeterminate;
    } */

    isEmptySelectionForPractice(row) {
        return (this.isEmptySelectionProvisionFeesForPractice(row));
    }

    verifyProvisionFeeSelection(provisionFee): boolean {
        return this.selectionProvisionFees.selected.map(provFee => provFee.id).includes(provisionFee.id);
    }

    getFullInvoiced() {
        if (this.dataSource.data.length > 0) {
            let invoiced: number = 0;
            this.dataSource.data.forEach(data => {
                data.provisionFees?.forEach(provFee => {
                    invoiced += provFee.invoiced;
                })
            })
            return invoiced;
        } else {
            return 0;
        }
    }

    getFullDebited() {
        if (this.dataSource.data.length > 0) {
            let debited: number = 0;
            this.dataSource.data.forEach(data => {
                data.provisionFees?.forEach(provFee => {
                    debited += provFee.debited;
                })
            })
            return debited;
        } else {
            return 0;
        }
    }

    getTotalValue() {
        if (this.dataSource.data.length > 0) {
            let total: number = 0;
            this.dataSource.data.forEach(data => {
                data.provisionFees?.forEach(provFee => {
                    if (!!provFee.totalValue) {
                        total += provFee.totalValue;
                    }
                })
            })
            return total;
        } else {
            return 0;
        }
    }

    pondHandleInit() {
        console.log('Inicio la carga');
    }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);
            this.photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
        } else {
            this.photoEvolutionB64 = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoEvolutionB64 = null;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    showAuthEvolution(auth) {
        // Find evolution
        this.evolutionsFacade.loadEvolutionsById(auth.evolutionId).subscribe(evol => {
            this._bottomSheet.open(EvolutionsDialogComponent, {
                panelClass: 'bottomSheetPanelClass',
                data: {
                    evolutions: [evol],
                    attentions: [],
                    name: this.case.patient.name,
                    surname: this.case.patient.surname,
                    expanded: true
                },
            });
        })
    }

    enableButtonEditForProvisionHover(id) {
        this.provisionIdforHover = id
    }

    enableButtonEditForProvisionFeeHover(id) {
        this.provisionFeeIdforHover = id
    }

    provisionFeeFromDate(provisionFee?: ProvisionFee) {
        if (!!provisionFee && !!provisionFee.fromDate) {
            return provisionFee.fromDate;
        }
        return null;
    }

    provisionFeeToDate(provisionFee?: ProvisionFee) {
        if (!!provisionFee && !!provisionFee.toDate) {
            return provisionFee.toDate;
        }
        return null;
    }

    frequencyOfProvision(provisionFee?: ProvisionFee) {
        if (!!provisionFee && !!provisionFee.frequency) {
            if (!!provisionFee.frequency.amount) {
                return provisionFee.frequency.amount + ' por ' + provisionFee.frequency.unit;
            } else {
                return provisionFee.frequency.unit;
            }
        } else {
            return '-'
        }
    }

    getAttentionsProvisionFee(provisionFee: ProvisionFee): Attention[] {
        return !!this.attentionsINRange?.filter(att => att.provisionFeeId == provisionFee?.id)
            ? this.attentionsINRange.filter(att => att.provisionFeeId == provisionFee?.id)
            : [];
    }

    getAttentionsAgreement(agreement: Agreement): Attention[] {
        return this.attentionsINRange?.filter(att => att.agreementId == agreement.id)
            ? this.attentionsINRange.filter(att => att.agreementId == agreement.id)
            : [];
    }

    totalOfProvision(provision: Provision, provisionFee: ProvisionFee) {
        return this.getAttentionsProvisionFee(provisionFee)?.reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? Math.round(moment(attention.toDate).diff(moment(attention.fromScheduled)) / (1000 * 60 * 60)) ? Math.round(moment(attention.toDate).diff(moment(attention.fromDate)) / (1000 * 60 * 60)) : 1 // Attention by hous
                : 1), 0                                                                      // Attention by unit
        )
    }

    totalRealizedOfProvision(provision: Provision, provisionFee: ProvisionFee) {
        return this.getAttentionsProvisionFee(provisionFee)?.filter(att => att.state.id === AttentionStates.REALIZADA).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision.practice)
                ? attention.quantity ? attention.quantity : 1
                : 1), 0
        )
    }

    totalUnitOfProvision(provision?: Provision, provisionFee?: ProvisionFee): number {

        if (!!provisionFee && !!provision) {
            if (!!provisionFee.arrangementId) {
                if (moment(provisionFee.toDate) < moment(this.historyToDate)) {
                    const practicesArrangement = this.getArrangementItemsByPracticeValids(provisionFee.arrangementId, provision.practiceId, this.historyFromDate, this.historyToDate) // Validate with price list
                    return practicesArrangement?.find(pa => this.generalService.validateDateBetween(pa.fromDate, pa.toDate, provisionFee.toDate))?.fee;
                } else {
                    const practicesArrangement = this.getArrangementItemsByPracticeValids(provisionFee.arrangementId, provision.practiceId, this.historyFromDate, this.historyToDate) // Validate with price list
                    return practicesArrangement?.find(pa => this.generalService.validateDateBetween(pa.fromDate, pa.toDate, this.historyToDate))?.fee;
                }
            } else if (!!provisionFee.fee) {
                return provisionFee.fee;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    verifyVariationOnPrice(provision?: Provision, provisionFee?: ProvisionFee) {
        if (!!provisionFee && !!provision) {
            if (!!provisionFee.arrangementId) {
                const arrangement: CaseArrangement = this.caseArrangements?.find(arr => arr.id == provisionFee.arrangementId);
                if (!!arrangement && !!arrangement.practiceArrangements) {
                    let practiceArrangementForProvision: ArrangementItem[] = this.casesService.getArrangementItemsByPracticeBetweenDates(arrangement, provision.practice, provisionFee.fromDate, provisionFee.toDate);
                    if (!!practiceArrangementForProvision && practiceArrangementForProvision.length > 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getVariationPriceTooltip(provision?: Provision, provisionFee?: ProvisionFee) {
        if (moment(provisionFee.toDate) < moment(this.historyToDate)) {
            return this.getArrangementItemsByPracticeValids(provisionFee.arrangementId, provision.practiceId, provisionFee.fromDate, provisionFee.toDate);
        } else {
            return this.getArrangementItemsByPracticeValids(provisionFee.arrangementId, provision.practiceId, provisionFee.fromDate, this.historyToDate);
        }
    }

    showTooltip() {
        this.isOpenTooltip = this.isOpenTooltip ? false : true;
    }

    validateDateOfProvisionFee(provisionFee: ProvisionFee, practiceArrangement: ArrangementItem) {
        const startOfMonth = moment().startOf('month');
        return (provisionFee.fromDate <= startOfMonth.toDate() && provisionFee.toDate >= startOfMonth.toDate());
    }

    totalValueOfProvision(provisionFee?: ProvisionFee) {
        if (!!provisionFee && !!provisionFee.fee && !!provisionFee.totalValue) {
            return provisionFee.totalValue;
        } else {
            return null;
        }
    }

    invoicedOfProvision(provision: Provision, provisionFee: ProvisionFee) {
        return this.getAttentionsProvisionFee(provisionFee).filter(att => att.state.id === AttentionStates.FACTURABLE).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision.practice)
                ? attention.quantity ? attention.quantity : 1 : 1
            ), 0
        )
    }

    debitedOfProvision(provision: Provision, provisionFee?: ProvisionFee) {
        return this.getAttentionsProvisionFee(provisionFee).filter(att => att.state.id === AttentionStates.DEBITADA).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision.practice)
                ? attention.quantity ? attention.quantity : 1 : 1
            ), 0
        )
    }

    fullyDeliveredOfProvision(provision: Provision, provisionFee?: ProvisionFee) {
        return this.getAttentionsProvisionFee(provisionFee).filter(att => att.state.id === AttentionStates.INFORMADA).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision.practice)
                ? attention.quantity ? attention.quantity : 1 : 1
            ), 0
        )
    }


    getColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_COLORS[state]
    }

    getBackgroundColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_BACKGROUND_COLORS[state]
    }

    // Add or remove validators to form "formAuthorization"
    onChangeCheckAut(event, form) {
        this.isAddingAuthorization = event;
        this.formAuthorization.get('authorizationNumber').clearValidators();
        this.formProvision.get('toDate').clearValidators();

        switch (true) {
            case !this.isAddModeProvision:

                if (event) {
                    // Add validator
                    this.formAuthorization.get('authorizationNumber').setValidators([CustomValidators.required('Número de autorización requerido')]);
                    this.formProvision.get('toDate').setValidators([CustomValidators.required('Fecha de fin de autorización requerida')]);
                }
                break;

            case this.isAddModeProvision:
                if (event) {
                    // Add validators
                    this.formAuthorization.get('authorizationNumber').setValidators([CustomValidators.required('Número de autorización requerido')])
                    this.formProvision.get('toDate').setValidators([CustomValidators.required('Fecha de fin de autorización requerida')]); // To update provision-fee this date isn't required
                }
                break;

            default:
                break;
        }

        this.formAuthorization.get('authorizationNumber').updateValueAndValidity();
        this.formProvision.get('toDate').updateValueAndValidity();
    }

    practiceExistHelp() {
        this.isOpen = this.isOpen ? false : true;
    }

    loadProviders = (providerQPS) => {
        this.entitiesFacade.loadProviders(providerQPS).subscribe(providers => {
            this.providers = providers;
        })
    }


    // onChangeCheckFilterByRegion(event) {

    //     this.formAgreement.get('providerId').reset();

    //     let providerQPS: ProvidersQPS;
    //     providerQPS = { ...this.providerQPS_Default, specialtyId: this.provisionToWork.specialtyId }

    //     if (event) { // Filter by region

    //         delete providerQPS.isFilteredByRegion
    //         providerQPS = { ...providerQPS, isFilteredByRegion: true, caseId: this.case.id }
    //     }

    //     this.loadProviders(providerQPS);
    // }

    openSuspendProvisionFee(provision, provisionFee) {

        this.formSuspend = this.createSuspendForm(provision, provisionFee);

        this.provisionFeeToWork = provisionFee;

        this.openDialog(
            'Finalizar práctica',
            this.suspendTemplate,
            { maxHeight: '95vh', minWidth: '60%' },
            () => {
                if (!!this._attentions) {
                    this._attentions.unsubscribe();
                }
                if (!!this.processSuspendCompleteSuccesfully) {
                    this.provisionsFacade.setFiltersOnProvisions(this.updateFilter()); // It needs reload attentions
                    this.processSuspendCompleteSuccesfully = false;
                }
            }
        );
    }

    createSuspendForm(provision: Provision, provisionFee: ProvisionFee) {
        return this.formBuilder.group({
            [this.PRACTICE]: [{ value: provision.practice.name, disabled: true }],
            [this.VALIDITY_FROM_DATE]: [!!provisionFee.fromDate ? provisionFee.fromDate : '', Validators.compose([CustomValidators.required('Fecha requerida'), CustomValidators.dateLowerThan(provisionFee.toDate), CustomValidators.dateGreaterThan(provisionFee.fromDate)])],
            [this.VALIDITY_TO_DATE]: [{ value: provisionFee.toDate, disabled: true }]
        })
    }

    onClickSuspendProvisionFee() {
        // Config swal
        const swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm'
            },
            buttonsStyling: true
        })

        const newRange = {
            fromDate: this.provisionFeeToWork.fromDate,
            toDate: moment(this.suspendFromDate).subtract(1, 'days').toDate(),
        }

        this.provisionsFacade.updateProvisionFeePatch(newRange, this.case.id, this.provisionFeeToWork.provisionId, this.provisionFeeToWork.id).subscribe(
            () => {
                swalWithCustomizeButtons.fire({
                    title: 'La operación fue realizada con éxito',
                    icon: 'success',
                }).then(() => {
                    this.processSuspendCompleteSuccesfully = true;
                    this._dialogRef.close()
                });
            },
            error => {
                if (error.status === 418) {
                    let errorContent: HttpBaseResponseErrorTeaPot = error.error;

                    // WARNING! It's a promise, waiting the user
                    this.attentionsService.processErrorCountAttentions(errorContent, 'error').then(res => {
                        if (!res) {
                            // Nothing to do
                        } else {
                            // User confirm force
                            // Nothing to do, it doesn't fire force action
                        }
                    })
                }
            }
        )
    }

    getTooltipUnitDescription(provision: Provision) {
        let toolTipText: string = '';
        if (!!this.practiceCountedByHours(provision?.practice)) {
            toolTipText = 'Horas';
        } else {
            toolTipText = 'Atenciones';
        }
        return toolTipText;
    }

    practiceCountedByHours(practice: Practice) {
        return (!!practice?.restriction?.requiredTime || !!practice?.restriction?.requiredToDate);
    }

    amendProvisionFee(provision: Provision, provisionFee: ProvisionFee) {
        this.provisionFeeSelectedToUpdate = provisionFee;
        this.provisionSelectedToUpdate = provision;
        this.checkboxAuthorizationText = !!provisionFee?.authorization ? 'Reemplazar autorización' : 'Agregar autorización';
        // Auth
        this.isShowAuth = true;
        this.photoEvolutionB64 = null; // File is mandatory

        this._dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            maxHeight: '20%',
            maxWidth: '30%',
            data: {
                message: 'Corregir la práctica modifica los datos que corresponden a la autorización actual.',
                icon: 'WarningCircle'
            }
        });

        this._dialogRef.componentInstance.ok.subscribe(result => {
            if (result) {
                this.openUpdateProvisionDialog(this.provisionSelectedToUpdate, this.provisionFeeSelectedToUpdate);
            }
        });
    }

    openMessageDialogComponent(message) {
        this._dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            maxHeight: '20%',
            maxWidth: '50vh',
            data: {
                message: message,
                icon: 'WarningCircle'
            }
        });
    }

    deleteProvisionFee(provision: Provision, provisionFee: ProvisionFee) {
        this.provisionFeeSelectedToUpdate = provisionFee;
        this.provisionSelectedToUpdate = provision;

        const textAux = !!provisionFee.toDate ? `al ${moment(provisionFee.toDate).format('DD/MM/YYYY')}` : `en adelante`;

        if (!!provision && !!provision.provisionFees && provision.provisionFees.length == 1) {
            this.openMessageDialogComponent("Se eliminará la prestación en su totalidad, ¿Desea continuar?");
        } else {
            this._dialogRef = this.dialog.open(MessageDialogComponent, {
                disableClose: false,
                maxHeight: '20%',
                maxWidth: '50vh',
                data: {
                    message: `Se eliminará la prestación para el período del ${moment(provisionFee.fromDate).format('DD/MM/YYYY')} ${textAux} y su agenda asociada ¿Desea continuar?`,
                    icon: 'WarningCircle',
                    isWorking: this.isLoadingUpdatingProvision
                }
            });
        }

        this._dialogRef.componentInstance.ok.subscribe(result => {
            if (result) {
                this.confirmDeleteProvisionFee(provision, provisionFee);
            }
        });
    }

    confirmDeleteProvisionFee(provision: Provision, provisionFee: ProvisionFee) {

        let successMsg = () => {
            this.swalWithCustomizeButtons.fire({
                text: 'La sub-prestación se eliminó correctamente',
                icon: 'success'
            }).then(() => {
                this._dialogRef.close();
            })
        }

        this.provisionsFacade.deleteProvisionFee(this.case.id, provision.id, provisionFee.id).subscribe(
            res => {
                successMsg()
            },
            error => {
                if (error.status === 418) {
                    let errorContent: HttpBaseResponseErrorTeaPot = error.error;

                    // WARNING! It's a promise, waiting the user
                    this.attentionsService.processErrorCountAttentions(errorContent, 'error').then(res => {
                        if (!res) {
                            this._dialogRef.close();
                        } else {
                            // User confirm force
                            // Nothing to do, it doesn't fire force action
                        }
                    })
                }
            }
        )
    }

    toogleRetroactive(event: boolean = false) {
        this.isRetroactive = event;
        this.formProvision.get(this.VALIDITY_FROM_DATE).clearValidators();
        this.formProvision.get(this.VALIDITY_TO_DATE).clearValidators();

        // fromdate <= todate
        this.formProvision.setValidators(CustomValidators.fromToDate('fromDate', 'toDate'));

        if (!this.isRetroactive) {
            // Retroactive OFF
            const startOfMonth = moment(this.formProvision.get(this.VALIDITY_FROM_DATE).value).startOf('month').format('YYYY-MM-DD');
            const endOfMonth = moment(this.formProvision.get(this.VALIDITY_FROM_DATE).value).endOf('month').format('YYYY-MM-DD');

            if (startOfMonth == moment(this.formProvision.get(this.VALIDITY_FROM_DATE).value).format('YYYY-MM-DD') && endOfMonth == moment(this.formProvision.get(this.VALIDITY_TO_DATE).value).format('YYYY-MM-DD')) {

                const startOfNextMonth = moment(startOfMonth).add(1, 'M');
                const endOfNextMonth = moment(startOfNextMonth).endOf('month').format('YYYY-MM-DD');

                this.formProvision.get(this.VALIDITY_FROM_DATE).setValue(startOfNextMonth);
                this.formProvision.get(this.VALIDITY_TO_DATE).setValue(endOfNextMonth);
            } else {

                let diff = moment.duration(moment(this.provisionFeeToWork.toDate).diff(this.provisionFeeToWork.fromDate))
                diff = diff.asDays() > 0 ? diff : diff.add(1, 'day');
                this.formProvision.get(this.VALIDITY_FROM_DATE).setValue(moment(this.provisionFeeToWork.toDate).add(1, 'day'));
                this.formProvision.get(this.VALIDITY_TO_DATE).setValue(moment(this.provisionFeeToWork.toDate).add(diff));
            }

            this.formProvision.get(this.VALIDITY_FROM_DATE).setValidators(Validators.compose([CustomValidators.required('Fecha requerida'), CustomValidators.dateGreaterThan(this.provisionFeeToWork.toDate)]))
            this.formProvision.get(this.VALIDITY_TO_DATE).setValidators(Validators.compose([CustomValidators.required('Fecha requerida'), CustomValidators.dateGreaterThan(this.formProvision.controls['fromDate'].value)]))

        } else {
            // Retroactive ON
            this.formProvision.get(this.VALIDITY_FROM_DATE).setValidators(Validators.compose([CustomValidators.required('Fecha requerida'),
            CustomValidators.dateGreaterThan(moment(this.provisionFeeToWork.fromDate).toDate()),
            CustomValidators.dateLowerThan(moment(this.provisionFeeToWork.toDate).toDate())]));

            this.formProvision.get(this.VALIDITY_FROM_DATE).setValue(moment(this.provisionFeeToWork.fromDate).add(1, 'day'));
            this.formProvision.get(this.VALIDITY_TO_DATE).setValue(this.provisionFeeToWork.toDate);
            this.formProvision.get(this.VALIDITY_TO_DATE).setValidators(Validators.compose([CustomValidators.required('Fecha requerida'),
            CustomValidators.dateGreaterThan(moment(this.provisionFeeToWork.toDate).subtract(1, 'day').toDate())]))
        }
    }

    updateFilter(): ProvisionAgreementFilters {

        // Provisions's filter
        delete this.provisionAgreementsInput?.provisions;
        delete this.provisionAgreementsInput?.agreements;
        delete this.provisionAgreementsInput?.provisionFees;
        // Supplies's filter
        delete this.provisionAgreementsInput?.provisionsSupplies;
        delete this.provisionAgreementsInput?.provisionAgreementsSupplies;
        delete this.provisionAgreementsInput?.orders;

        let provisionsToDeselect = this.selectionProvisions.selected.flatMap(prov => this.selectionProvisions.selected.filter(prov2 => prov.id === prov2.id && this.selectionProvisions.selected.indexOf(prov) != this.selectionProvisions.selected.indexOf(prov2)));
        provisionsToDeselect.forEach(provToDeselect => this.selectionProvisions.deselect(provToDeselect))

        return this.provisionAgreementsInput = { ...this.provisionAgreementsInput, provisions: [...this.selectionProvisions.selected], agreements: [...this.selectionAgreements.selected], provisionFees: [...this.selectionProvisionFees.selected] };
    }

    openUpdateAgreementFeeDialog() {
        this.agreementForm();
    }

    radioButtonArrangementSelected(id) {

        if (!!id && id.value != 'S/C') {
            this.arrangementSelected = this.caseArrangements.find(arr => arr.id == id.value);
            if (!!this.arrangementSelected) {
                const practiceIds = this.arrangementSelected?.practiceArrangements?.map(practice => practice.practiceId);
                this.practicesToShow = this.practices.filter(practice => practiceIds?.includes(practice.id));
            } else {
                this.practicesToShow = this.practices;
            }
            this.showTxtWithOutArrangement = true;
            this.formProvision.get(this.FEE).clearValidators();
            this.formProvision.get(this.FEE).setValue(0);
        } else {
            this.arrangementSelected = undefined;
            this.practicesToShow = this.practices;
            this.showTxtWithOutArrangement = false;
            this.formProvision.get(this.FEE).setValidators([CustomValidators.required('Honorarios requeridos'), CustomValidators.minValue(0, 'Debe ser un valor positivo')]);
        }
    }

    getArrangementItemsByPracticeValids(arrangementId: number, practiceId: number, provisionFeeFromDate: Date, provisionFeeToDate: Date): Partial<ArrangementItem>[] {
        provisionFeeFromDate = moment(provisionFeeFromDate).toDate()
        provisionFeeToDate = moment(provisionFeeToDate).toDate()

        const arrangement: CaseArrangement = this.caseArrangements?.find(arr => arr.id == arrangementId); // Get arrangement
        const practice: Practice = this.practices.find(practice => practice.id == practiceId); // Get practice

        let allArrangementPracticesByPractice: Partial<ArrangementItem>[] = this.casesService.getArrangementItemsByPracticeBetweenDates(arrangement, practice, provisionFeeFromDate, provisionFeeToDate);
        this.existPriceList = false;

        if (allArrangementPracticesByPractice?.length > 0) {

            this.existPriceList = true;

            // First item where initial date is provisionFeeFromDate
            let firstItem = allArrangementPracticesByPractice.find(item => moment(moment(provisionFeeFromDate).utc().format('YYYY-MM-DD')).isSameOrAfter(item.fromDate))
            let index = 0
            if (!!firstItem) {
                index = allArrangementPracticesByPractice.findIndex(item => item.id == firstItem.id);
            } else {
                allArrangementPracticesByPractice.splice(0, 0, {
                    fee: -1, // Show "No definido"
                    fromDate: this.formProvision ? this.formProvision.get('fromDate').value : null,
                    toDate: moment(allArrangementPracticesByPractice[0].fromDate).subtract(1, 'day').toDate(),
                }
                )
            }
            // Replace fromDate
            allArrangementPracticesByPractice[index] = !!firstItem ? { ...allArrangementPracticesByPractice[index], fromDate: moment(moment(provisionFeeFromDate).utc().format('YYYY-MM-DD')).toDate() } : { ...allArrangementPracticesByPractice[index] };
            // Last item where final date is provisionFeeToDate or null (==null is messagge "en adelante")
            let lastItem = allArrangementPracticesByPractice[allArrangementPracticesByPractice.length - 1];
            // Replace toDate
            if (moment(provisionFeeToDate).isValid()) {
                lastItem = { ...lastItem, toDate: provisionFeeToDate }
            }
            allArrangementPracticesByPractice[allArrangementPracticesByPractice.length - 1] = { ...lastItem };

            return allArrangementPracticesByPractice;
        } else {
            return null;
        }
    }

    agreementPerformed(provision: Provision, agreement: Agreement) { // Realizadas
        return this.getAttentionsAgreement(agreement).filter(att => att.state.id === AttentionStates.REALIZADA).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? attention.quantity ? attention.quantity : 1 : 1), 0
        )
    }

    agreementNotPerformed(provision: Provision, agreement: Agreement) { // No realizadas
        return this.getAttentionsAgreement(agreement).filter(att => [AttentionStates.AGENDADA, AttentionStates.EN_CURSO, AttentionStates.NO_REALIZADA, AttentionStates.CREACION].includes(att.state.id)).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? attention.quantity ? attention.quantity : 1 : 1 // Attention by hous
            ), 0                                                                      // Attention by unit
        )
    }

    agreementInvoiced(provision: Provision, agreement: Agreement) { // Facturables
        return this.getAttentionsAgreement(agreement).filter(att => [AttentionStates.FACTURABLE].includes(att.state.id)).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? attention.quantity ? attention.quantity : 1 : 1
            ), 0
        )
    }

    agreementDebited(provision: Provision, agreement: Agreement) { // Debitadles
        return this.getAttentionsAgreement(agreement).filter(att => [AttentionStates.DEBITADA].includes(att.state.id)).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? attention.quantity ? attention.quantity : 1 : 1
            ), 0
        )
    }

    agreementTotal(provision: Provision, agreement: Agreement) {
        return this.getAttentionsAgreement(agreement).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? Math.round(moment(attention.toDate).diff(moment(attention.fromDate)) / (1000 * 60 * 60)) ? Math.round(moment(attention.toDate).diff(moment(attention.fromDate)) / (1000 * 60 * 60)) : 1 : 1 // Attention by hours
            ), 0                                                                      // Attention by unit
        )
    }

    agreementInformed(provision: Provision, agreement: Agreement) {
        return this.getAttentionsAgreement(agreement).filter(att => [AttentionStates.INFORMADA].includes(att.state.id)).reduce((accum, attention) =>
            accum + (!!this.practiceCountedByHours(provision?.practice)
                ? attention.quantity ? attention.quantity : 1 : 1 // Attention by hous
            ), 0                                                                      // Attention by unit
        )
    }

    practiceTooltip() {
        this.isTooltipOpen = this.isTooltipOpen ? false : true
    }

    getPracticeCode(provision: Provision) {
        let practicesCodes = [];
        let practiceArrangements = [];

        provision?.provisionFees?.forEach(provFee => {
            if (!!provFee.arrangementId) {
                const arrangementItems = this.getArrangementItemsByPracticeValids(
                    provFee?.arrangementId,
                    provision.practiceId,
                    provFee?.fromDate,
                    provFee?.toDate
                ).map(item => ({
                    ...item,
                    arrangementId: provFee.arrangementId
                }));
                practiceArrangements.push(...arrangementItems);
            } else {
                this.practiceForTooltip = provision.practice.code;
            }
        });

        practiceArrangements = practiceArrangements.flat();

        practiceArrangements = practiceArrangements.reduce((accumulator, current) => {
            const existingItem = accumulator.find(item => item.id === current.id && item.arrangementId === current.arrangementId);
            if (!existingItem) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        if (practiceArrangements.length > 0) {
            practiceArrangements.forEach(prArr => {
                practicesCodes.push({ code: prArr.code, arrangementId: prArr.arrangementId });
            });
        }

        let formattedCodes = practicesCodes.map(practice => ({
            name: `Conv. ${practice.code}`,
            arrId: practice.arrangementId
        }));

        if (formattedCodes.length > 0) {
            this.practicesCodes = formattedCodes;
            this.practiceForTooltip = provision.practice?.name;
        } else {
            let practice: any = this.practices.filter(pr => pr?.id === provision?.practice?.id);
            this.practicesCodes = practice.map(pr => ({
                name: `Cod. ${pr?.code}`,
                arrId: pr?.arrangementId
            }));
            this.practiceForTooltip = provision.practice?.name;
        }
    }

    getPracticeArrangementName(provision) {
        //Cargamos en un array los ids de los convenios del case
        let arrangementIds = [];
        if (!!provision.provisionFees) {
            provision.provisionFees.forEach(element => {
                arrangementIds.push(element.arrangementId);
            });
        }
        //Traemos los nombres de los convenios usando los ids de arrangementsIds y los insertamos en un array
        let caseArrangements = arrangementIds.reduce((acum, id) => {
            const caseMatcher = this.caseArrangements.filter(element => element.id === id);
            return [...acum, ...caseMatcher.map(element => ({ id: element.id, name: element.name }))];
        }, []);
        this.arrangementNameForTooltip = caseArrangements;

        //Traemos al array que posse id de acuerdos junto a su codigo
        //Armamos un array nuevo que unifica por id del convenio los nombres y los codigos para mostrar en la vista
        const matchingArrangementNames = this.arrangementNameForTooltip.filter(arrangement =>
            this.practicesCodes.some(practice => practice.arrId === arrangement.id)
        );
        this.practiceArrangementForTooltip = this.practicesCodes.map(practice => {
            const matchingArrangementName = matchingArrangementNames.find(arrangement => arrangement.id === practice.arrId);
            return {
                id: practice.arrId,
                arrangementName: matchingArrangementName ? matchingArrangementName.name : '',
                code: practice.name
            };
        });
    }

    onClickAuthorization(provisionFee: ProvisionFee) {
        if (this.generalService.getAuthorizationState(provisionFee) != "NO AUTORIZADO") {
            this.showAuthEvolution(provisionFee.authorization);
        } else {
            this.openUpdateAuthorizationDialog(provisionFee);
        }
    }

    ngOnDestroy(): void {

        if (!!this._screenToJumpSubscription) {
            this._screenToJumpSubscription.unsubscribe();
            this.breadcrumbService.setScreenToJump(null)
        }


        this._practices.unsubscribe();
        // this._provisionsDisplayedOnScreen.unsubscribe();

        if (!!this._historyModeDate) {
            this._historyModeDate.unsubscribe();
        }

        if (!!this._filtersOnProvisions) {
            this._filtersOnProvisions.unsubscribe();
        }

        !!this._allProvisions ? this._allProvisions.unsubscribe() : null;

        if (!!this._loadingGetProvisions) {
            this._loadingGetProvisions.unsubscribe();
        }

        if (!!this._loadingGetAttentions) {
            this._loadingGetAttentions.unsubscribe();
        }

        if (!!this._attentions) {
            this._attentions.unsubscribe();
        }

        if (!!this._caseArrangements) {
            this._caseArrangements.unsubscribe();
        }

        !!this._btnsDiasabled ? this._btnsDiasabled.unsubscribe() : null;
        !!this._markers ? this._markers.unsubscribe() : null;
    }

    validateShowMessages() {
        let existentProvision = this.allProvisionsWithoutFilter.find(prov => prov.practiceId == this.practiceId);
        if (this.provisionFeeToWork == null) {
            this.showAlertMsg = !!existentProvision ? existentProvision.provisionFees.some(provFee => provFee.toDate == null || moment(provFee.toDate).isAfter(this.provisionFromDate)) : false;
        } else {
            this.showAlertMsg = !!existentProvision ? existentProvision.provisionFees.some(provFee => provFee.id != this.provisionFeeToWork.id && (provFee.toDate == null || moment(provFee.toDate).isAfter(this.provisionFromDate))) : false;
        }
        let existentPracticeWithAgreement = this.allProvisionsWithoutFilter.find(prov => prov.practiceId == this.practiceId && (prov?.agreements?.some(agr => agr?.toDate == null || moment(agr?.toDate).isAfter(moment(this.provisionFromDate)))));
        this.showPracticeAndAgreementExistent = !!existentPracticeWithAgreement && this.provisionFeeToWork == null;
        if (this.isAddModeProvision) {
            this.alertMsg = "Ya existe esta práctica. Se duplicará.";
        } else {
            this.alertMsg = "El periodo de vigencia seleccionado se superpone con otro periodo de la misma práctica"
        }
    }

    exportProvisionFees(isPdf: boolean) {
        let provisionFees = this.selectionProvisionFees.selected.map(pf => {
            let provision = this.provisionsFiltered.find(prov => prov.id == pf.provisionId)
            let provFee = { ...pf, practice: provision.practice }
            if (provFee.arrangementId != null) {
                provFee.fee = parseFloat(this.totalUnitOfProvision(provision, provFee).toFixed(2));
            }
            delete provFee.orders
            delete provFee.agreement
            delete provFee.invoiced
            delete provFee.debited
            delete provFee.delivered
            delete provFee.total
            delete provFee.totalAmount
            delete provFee.totalDebited
            delete provFee.totalDelivered
            delete provFee.totalInvoiced
            delete provFee.totalRealized
            delete provFee.totalValue
            delete provFee.userId
            delete provFee.provider
            delete provFee.authorization
            delete provFee.paintedType
            return provFee
        })
        let pdfBody: BudgetBody = {
            provisionFees: provisionFees,
            patientId: this.case?.patient?.id,
            financierId: this.case?.financier?.id,
            caseId: this.case?.id,
            isPdf: isPdf,
            isPractice: true,
        }
        this.provisionsFacade.generateBudgetPDF(pdfBody).subscribe(pdf => this.fileUtilitiesService.downloadFile(pdf))
    }

    openSpecialtiesMap() {
        this.openDialog('Localización', this.specialtiesMap, { maxHeight: '100vh', minWidth: '90%' }, () => { });
    }

    markProvisions(): Provision[] {
        const attentions = this.attentionsINRange;
        let provisions = this.provisionsFiltered;
        this.dataSource.data = [];

        if (provisions?.length > 0) {
            const range = this.casesFacade.getCaseDateFilter();
            if (!!attentions) {
                const rangeAttentions = attentions.filter(att => moment(att.fromDate).isSameOrAfter(range.historyFromDate) && moment(att.fromDate).isSameOrBefore(range.historyToDate))
                const filteredProvisions = provisions.filter(pr => pr.practice.name !== 'Entrega insumos' && !pr.isSupply);
                const attentionsById = new Map(rangeAttentions.map(att => [att.provisionFeeId, att]));
                // Acumulamos cantidad de fees sin atención y los fees
                const { accumulatedCount, accumulatedFeesWithoutAttention } = filteredProvisions.reduce(
                    (acc, provision) => {
                        const feesWithoutAttention = provision.provisionFees.filter(fee => !attentionsById.has(fee.id));
                        acc.accumulatedCount += feesWithoutAttention.length;
                        acc.accumulatedFeesWithoutAttention.push(...feesWithoutAttention);
                        return acc;
                    },
                    { accumulatedCount: 0, accumulatedFeesWithoutAttention: [] }
                );
                // Mapear IDs de los fees sin atención
                const provisionsFeesIdsToPaint = new Set(accumulatedFeesWithoutAttention.map(pf => pf.id));
                // Agregar `paintedType` solo a los provisionFees que cumplan con la condición
                // No olvidar que cualquier cambio en la logica debe coincidir con el filtro aplicado en caseIndicators.
                provisions.forEach(provision => {
                    provision.provisionFees.forEach(fee => {
                        if (provisionsFeesIdsToPaint.has(fee.id)) {
                            fee.paintedType = 'PRACTICES_WITHOUT_SCHEDULE';
                        } else {
                            fee.paintedType = null;
                        }
                    });
                });
            } else {
                provisions.forEach(provision => {
                    provision.provisionFees.forEach(fee => {
                        if (!!fee.paintedType) {
                            fee.paintedType = null;
                        }
                    });
                })
            }
        }
        return provisions;
    }
}
