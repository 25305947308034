import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { UpdatingAgreement } from 'src/app/abstraction/provisions.facade';
import { Agreement } from 'src/app/shared/models/agreement';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionAgreementFilters } from 'src/app/shared/models/ProvisionAgreementFilters';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { BaseState, IUpdate } from './base.state';
import * as moment from 'moment';
import { CaseDateFilters, CasesFacade } from 'src/app/abstraction/cases.facade';
import * as momentRange from 'moment-range';
import { ColorsToAgreements } from 'src/app/shared/models/submodels/agreementsColors';

@Injectable({
  providedIn: 'root',
})

export class ProvisionsState extends BaseState {
  override store = {
    //PROVISIONS
    loadingCreatingProvision$: new BehaviorSubject<boolean>(false),
    errorCreatingProvision$: new BehaviorSubject<boolean>(false),
    loadingUpdatingProvision$: new BehaviorSubject<boolean>(false),
    errorUpdatingProvision$: new BehaviorSubject<boolean>(false),
    loadingGetProvisions$: new BehaviorSubject<boolean>(false),
    filtersOnProvisions$: new BehaviorSubject<ProvisionAgreementFilters>(null),
    allProvisionsCase$: new BehaviorSubject<Provision[]>(null),
    provisionsDisplayedOnScreen$: new BehaviorSubject<Provision[]>(null),

    //AUTHORIZATIONS
    isLoadingGetAuthorizations$: new BehaviorSubject<boolean>(null),

    //AGREEMENTS
    loadingCreatingAgreement$: new BehaviorSubject<boolean>(false),
    loadingUpdatingAgreement$: new BehaviorSubject<boolean>(false),
    loadingGetAgreementsBySpecialtyId$: new BehaviorSubject<boolean>(false),
    agreementsBySpecialtyId$: new BehaviorSubject<Agreement[]>(null),
    colorsToAgreements$: new BehaviorSubject<ColorsToAgreements>({})
  };

  constructor(private casesFacade: CasesFacade) {
    super();
  }

  //  PROVISIONS
  deleteProvisions(provisionsIN: Provision[]) {
    // All Case Provisions
    let allProvisions: Provision[] = this.store.allProvisionsCase$.getValue();
    // Provisions displayed
    let provisionsDisplayedOnScreen: Provision[] = this.store.provisionsDisplayedOnScreen$.getValue();
    // Remove elements
    for (let prov of provisionsIN) {
        allProvisions = allProvisions.filter((provision) => provision.id !== prov.id);

        provisionsDisplayedOnScreen = provisionsDisplayedOnScreen.filter(
          (provision) => provision.id !== prov.id,
        );
    }

    // Delete in ALLProvisions
    this.store.allProvisionsCase$.next(allProvisions);
    // TODO: after release supplies - delete if all ok
    // Delete in provisionsDisplayedOnScreen
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
    // this.store.provisionsDisplayedOnScreen$.next(provisionsDisplayedOnScreen)
  }

  addProvision(provision: Provision, caseId: number) {
    let provisions = this.store.allProvisionsCase$.value;
    if (!!provisions?.find((prov) => prov.id == provision.id)) {
        this.updateProvision(provision);
    } else {
        this.add({ data: provision, storeRefAttribute: this.store.allProvisionsCase$ });
    }
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  deleteProvision(provisionId: number) {
    this.delete({ dataId: provisionId, storeRefAttribute: this.store.allProvisionsCase$ });
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  setProvision(provision: Provision) {
    this.updateProvision(provision);
  }

  setProvisions(provisions: Provision[], caseId: number) {
    this.updateProvisions(provisions);
  }

  getAllProvisionsCase$(): Observable<Provision[]> {
    return this.store.allProvisionsCase$.asObservable();
  }

  setAllProvisionsCase(provisions: Provision[]) {
    this.store.allProvisionsCase$.next(provisions);
  }

  filterProvisionsToDisplayedOnScreen(provisionsToFilter: Provision[]) {
  console.log("filterProvisionsToDisplayedOnScreen")
  // Deep copy of provisions
  let provisions = provisionsToFilter.map(p => {return {...p}});
    if (!!provisions) {
      this.setLoadingGetProvisions(true);
      let dateFilters: CaseDateFilters = this.casesFacade.getCaseDateFilter();
      let range = new momentRange.DateRange(
        dateFilters.historyFromDate,
        dateFilters.historyToDate,
      );
      provisions.map(prov => {
        // Filter provisionFees
        let provisionFees = [];
        prov.provisionFees?.forEach( provFee => {
          let provisionFeeRange = new momentRange.DateRange();
          if (!!provFee.fromDate) {
            provisionFeeRange.start = moment(provFee.fromDate, 'YYYY-MM-DD');
          }
          if (!!provFee.toDate) {
            provisionFeeRange.end = moment(provFee.toDate, 'YYYY-MM-DD');
          }
          if (provisionFeeRange.overlaps(range, { adjacent: true })) {
            provisionFees.push(provFee);
          }
        });
        // Fiter agreements
        let agreements = [];
        prov.agreements?.forEach( agr => {
          let agreementRange = new momentRange.DateRange();
          if (!!agr.fromDate) {
            agreementRange.start = moment(agr.fromDate, 'YYYY-MM-DD');
          }
          if (!!agr.toDate) {
            agreementRange.end = moment(agr.toDate, 'YYYY-MM-DD');
          }
          if (agreementRange.overlaps(range, { adjacent: true })) {
            agreements.push(agr);
          }
        });
        prov.provisionFees = provisionFees;
        prov.agreements = agreements;
        // provisionsToDisplay.push(prov);
        return { ...prov };
      });
      this.setProvisionsDisplayedOnScreen( provisions.filter(prov => !!prov.provisionFees && prov.provisionFees.length > 0) );
      this.setLoadingGetProvisions(false);
    } else {
      this.setProvisionsDisplayedOnScreen([]);
    }
  }

  updateProvision(provision: Partial<Provision>) {
    this.update({ data: provision, dataId: provision.id, storeRefAttribute: this.store.allProvisionsCase$ });
  }

  updateProvisions(provisions: Partial<Provision[]>) {
    this.updateBatch({data: provisions, storeRefAttribute: this.store.allProvisionsCase$})
  }

  isLoadingGetProvisions$(): Observable<boolean> {
    return this.store.loadingGetProvisions$.asObservable();
  }

  setLoadingGetProvisions(isLoadingGetProvisions: boolean) {
    this.store.loadingGetProvisions$.next(isLoadingGetProvisions);
  }

  isLoadingGetAgreementsBySpecialtyId(): Observable<boolean> {
    return this.store.loadingGetAgreementsBySpecialtyId$.asObservable();
  }

  setLoadingGetAgreementsBySpecialtyId(value:boolean){
    this.store.loadingGetAgreementsBySpecialtyId$.next(value);
  }

  isErrorUpdatingProvision$() {
    return this.store.errorUpdatingProvision$.asObservable();
  }

  setErrorUpdatingProvision(isErrorUpdatingProvision: boolean) {
    this.store.errorUpdatingProvision$.next(isErrorUpdatingProvision);
  }

  getProvisionsDisplayedOnScreen$(): Observable<Provision[]> {
    return this.store.provisionsDisplayedOnScreen$.asObservable();
  }

  setProvisionsDisplayedOnScreen(provisions: Provision[]) {
    this.store.provisionsDisplayedOnScreen$.next(provisions);
  }

  isLoadingCreatingProvision$() {
    return this.store.loadingCreatingProvision$.asObservable();
  }

  setLoadingCreatingProvision(isLoadingCreatingProvision: boolean) {
    this.store.loadingCreatingProvision$.next(isLoadingCreatingProvision);
  }

  isErrorCreatingProvision$() {
    return this.store.errorCreatingProvision$.asObservable();
  }

  setErrorCreatingProvision(isErrorCreatingProvision: boolean) {
    this.store.errorCreatingProvision$.next(isErrorCreatingProvision);
  }

  isLoadingUpdatingProvision$() {
    return this.store.loadingUpdatingProvision$.asObservable();
  }

  setLoadingUpdatingProvision(isLoadingUpdatingProvision: boolean) {
    this.store.loadingUpdatingProvision$.next(isLoadingUpdatingProvision);
  }

  // FILTERS

  setFiltersOnProvisions(filters: ProvisionAgreementFilters) {
    this.store.filtersOnProvisions$.next(filters);
  }

  getFiltersOnProvisions$(): Observable<ProvisionAgreementFilters> {
    return this.store.filtersOnProvisions$.asObservable();
  }

  getFiltersOnProvisions(): ProvisionAgreementFilters {
    return this.store.filtersOnProvisions$.value;
  }

  // PROVISION FEES
  addProvisionFee(provisionFee: ProvisionFee, provisionId: number) {
    let provisions: Provision[] = this.store.allProvisionsCase$.getValue();
    let provision: Provision = provisions.find((prov) => prov.id == provisionId);
    if (!!provision) {
        if (!!provision.provisionFees) {
          provision.provisionFees.push(provisionFee);
        } else {
          provision.provisionFees = [provisionFee];
        }
        this.updateProvision(provision);
    }
  }

  updateProvisionFee(provisionFee: ProvisionFee, provisionId: number, provisionFeeId: number) {
    let provisions: Provision[] = this.store.allProvisionsCase$.getValue();
    let provision: Provision = provisions.find((prov) => prov.id == provisionId);
    if (!!provision) {
        if (!!provision.provisionFees) {
          const provisionFeeIndex = provision.provisionFees.findIndex(
              (prov) => prov.id == provisionFeeId,
          );
          if (provisionFeeIndex != -1) {
              provision.provisionFees[provisionFeeIndex] = {...provisionFee};
          }
        } else {
          provision.provisionFees = [provisionFee];
        }
        this.updateProvision(provision);
    }
  }

  addAuthorizationToProvisionFees(provisionFees: ProvisionFee[], provisionId: number) {
    let provisions: Provision[] = this.store.allProvisionsCase$.getValue();
    let provision: Provision = provisions.find((prov) => prov.id == provisionId);
    provisionFees?.forEach((provisionFee) => {
        if (!!provision) {
          if (!!provision.provisionFees) {
              const provisionFeeIndex = provision.provisionFees.findIndex(
                (prov) => prov.id == provisionFee.id,
              );
              if (provisionFeeIndex != -1) {
                provision.provisionFees[provisionFeeIndex] = provisionFee;
              }
          } else {
              provision.provisionFees = [provisionFee];
          }
          this.updateProvision(provision);
        }
    });
  }

  deleteProvisionFee(provisionId: number, provisionFeeId: number) {
    // All Case Provisions
    let allProvisions = this.store.allProvisionsCase$.getValue();
    let provisionIndex = allProvisions.findIndex((provision) => provision.id === provisionId);
    if (provisionIndex != -1) {
        allProvisions[provisionIndex].provisionFees = [
          ...allProvisions[provisionIndex].provisionFees.filter((pf) => pf.id != provisionFeeId),
        ];
        this.store.allProvisionsCase$.next(allProvisions);
    }
    // Provisions displayed
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
    // let provisionsDisplayedOnScreen = this.store.provisionsDisplayedOnScreen$.getValue();
    // provisionIndex = provisionsDisplayedOnScreen.findIndex(provision => provision.id === provisionId);
    // if (provisionIndex != -1) {
    // 	provisionsDisplayedOnScreen[provisionIndex].provisionFees = [...provisionsDisplayedOnScreen[provisionIndex].provisionFees.filter(pf => pf.id != provisionFeeId)];
    // 	this.store.provisionsDisplayedOnScreen$.next(provisionsDisplayedOnScreen)
    // }
  }

  // AGREEMENTS
  setLoadingCreatingAgreement(isLoadingCreatingAgreement: boolean) {
    this.store.loadingCreatingAgreement$.next(isLoadingCreatingAgreement);
  }

  isLoadingCreatingAgreement$() {
    return this.store.loadingCreatingAgreement$.asObservable();
  }

  updateAgreement(agreement: UpdatingAgreement, provisionId: number, agreementId: number) {
    const provisions = this.store.allProvisionsCase$.getValue();
    if (!!provisions) {
        const provisionIndex = provisions.findIndex((provision) => provision.id == provisionId);
        if (provisionIndex != -1) {
          const agreementIndex = provisions[provisionIndex].agreements.findIndex(
              (agreement) => agreement.id == agreementId,
          );
          if (agreementIndex != -1) {
              provisions[provisionIndex].agreements[agreementIndex] = {
                ...provisions[provisionIndex].agreements[agreementIndex],
                ...agreement,
              };
              this.updateProvision(provisions[provisionIndex]);
          }
        }
    }
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  addAgreementAtPatch(agreement: Agreement, provisionId: number, agreementId: number) {
    let provisionIndex = -1;

    // Update all provisions to case
    let allProvisions = this.store.allProvisionsCase$.getValue();
    provisionIndex = allProvisions.findIndex((aprovision) => aprovision.id === provisionId);

    if (provisionIndex != -1) {
        const agreements = allProvisions[provisionIndex].agreements || [];
        const agreementIndex = allProvisions[provisionIndex].agreements.findIndex(
          (agreement) => agreement.id == agreementId,
        );
        if (agreementIndex != -1) {
          allProvisions[provisionIndex].agreements[agreementIndex].toDate = moment(
              agreement.fromDate,
          )
              .subtract(1, 'days')
              .toDate();
          agreement.provider = allProvisions[provisionIndex].agreements[agreementIndex].provider;
        }
        agreements.push(agreement);
        allProvisions[provisionIndex].agreements = [...agreements];
        this.store.allProvisionsCase$.next(allProvisions);
    }
    // Update provisions displayed
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  addAgreement(agreement: Agreement, provisionId: number) {
    let provisionIndex = -1;

    // Update all provisions to case
    let allProvisions = this.store.allProvisionsCase$.getValue();
    provisionIndex = allProvisions.findIndex((aprovision) => aprovision.id === provisionId);

    if (provisionIndex != -1) {
        const agreements = allProvisions[provisionIndex].agreements || [];
        agreements.push(agreement);
        allProvisions[provisionIndex].agreements = [...agreements];
        this.store.allProvisionsCase$.next(allProvisions);
    }
    // Update all provisions to case
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  deleteAgreement(provisionId: number, agreementId: number) {
    let provisionIndex: number;
    let agreements: Agreement[];

    // Update all provisions to case
    let allProvisions = this.store.allProvisionsCase$.getValue();
    provisionIndex = allProvisions.findIndex((aprovision) => aprovision.id === provisionId);
    if (provisionIndex != -1) {
        agreements = [];
        agreements = allProvisions[provisionIndex].agreements.filter(
          (agreement) => agreement.id !== agreementId,
        );
        allProvisions[provisionIndex].agreements = [...agreements];
        this.store.allProvisionsCase$.next(allProvisions);
    }
    // Update all provisions to case
    // TODO: after release supplies - delete if all ok
    // this.filterProvisionsToDisplayedOnScreen(this.store.allProvisionsCase$.value); TODO: - did it in provision-scheme
  }

  isLoadingUpdatingAgreement$() {
    return this.store.loadingUpdatingAgreement$.asObservable();
  }

  setLoadingUpdatingAgreement(isLoading: boolean) {
    this.store.loadingUpdatingAgreement$.next(isLoading);
  }

  putAgreements(agreements: Agreement[]) {
    for (let agreement of agreements) {
        agreement.newAgreement
          ? this.addAgreement(agreement, agreement.currentProvisionId)
          : this.updateAgreement(agreement, agreement.currentProvisionId, agreement.id);
    }
  }

  //AGREEMENTS GOOGLE MAPS
  setAgreementsBySpecialtyId(agreements:Agreement[]) {
    this.store.agreementsBySpecialtyId$.next(agreements);
  }

  getAgreementsBySpecialtyId$() : Observable<Agreement[]> {
    return this.store.agreementsBySpecialtyId$.asObservable();
  }

//   isLoading

  // AUTHORIZATIONS

  isLoadingGetAuthorizations$(): Observable<boolean> {
    return this.store.isLoadingGetAuthorizations$.asObservable();
  }

  setLoadingGetAuthorizations(isLoadingGetAuthorizations: boolean) {
    this.store.isLoadingGetAuthorizations$.next(isLoadingGetAuthorizations);
  }

  // Update provisionFees
  updateProvisionFees(provisionFees: Partial<ProvisionFee[]>, provisions: Partial<Provision[]>, agreements?:Agreement[]) {
    //Deep copy of provisions
    let provisionsToUpdate = provisions.map(p => {return{...p}});
    let provsAux = [];
    // Finds the provisions for the provisionFees to update
    provisionsToUpdate.filter(prov => provisionFees.some(pF => pF.provisionId == prov.id)).forEach(prov => {
      provisionFees.forEach(pF => {
        let provAux = {...prov};
        // If there is agreements to update or to add, it updates them on the provision
        if(!!agreements){
          let agreementsForProvision = agreements.filter(ag => ag.currentProvisionId == provAux.id)
          if(!!agreementsForProvision){
            agreementsForProvision.forEach(ag => {
              if(!ag.newAgreement){
                let index = provAux.agreements.findIndex(agr => agr.id == ag.id);
                provAux.agreements[index] = ag;
              } else {
                if(provAux.agreements == null){
                  provAux.agreements = [ag];
                } else {
                  provAux?.agreements?.push(ag);
                }
              }
            })
          }
        }
        // Finds the provisionFee on the provision to update, replaces it and pushes the provision to the auxiliar.
        let indexData = prov.provisionFees.findIndex(provFee => provFee.id == pF.id);
        if(indexData != -1){
          provAux.provisionFees[indexData] = pF;
          provsAux?.push(provAux);
        };
      })
    })
    this.updateProvisions(provsAux);
  }

  // Only used when retroactive-mode is ON for previous provisionFee
  updateToDateProvisionFee(toDate: Date, provisionId: number, provisionFeeId: number) {
    let provisions: Provision[] = this.store.allProvisionsCase$.getValue();
    let provision: Provision = provisions.find((prov) => prov.id == provisionId);
    if (!!provision) {
        if (!!provision.provisionFees) {
          const provisionFeeIndex = provision.provisionFees.findIndex(
              (prov) => prov.id == provisionFeeId,
          );
          if (provisionFeeIndex != -1) {
              provision.provisionFees[provisionFeeIndex] = {...provision.provisionFees[provisionFeeIndex], toDate};
          }
        } else {
          // Nothing todo
        }
        this.updateProvision(provision);
    }
  }

  // Colors agreements
  setcolorsToAgreements(colorsMap: ColorsToAgreements) {
    this.store.colorsToAgreements$.next(colorsMap);
  }

  getColorsToAgreements$() {
    return this.store.loadingCreatingProvision$.asObservable();
  }
}
