import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetaDataTasks } from 'src/app/abstraction/tasks.facade';
import { Task } from 'src/app/shared/models/task';
import { BaseState } from './base.state';
@Injectable({
   providedIn: 'root',
})
export class TasksState extends BaseState {

   override store = {
      tasks$: new BehaviorSubject<Task[]>(null),
      loadingGetTasks$: new BehaviorSubject<boolean>(false),
      pendingTasks$: new BehaviorSubject<number>(null),
      loadingGetPendingTasks$: new BehaviorSubject<boolean>(false),
      metaDataTasks$: new BehaviorSubject<MetaDataTasks>(null),
   };
   constructor() {
      super();
   }


   isLoadingGetTasks$() {
      return this.store.loadingGetTasks$.asObservable();
   }

   setLoadingGetTasks(isLoadingGetTasks: boolean) {
      this.store.loadingGetTasks$.next(isLoadingGetTasks);
   }

   getTasks$(): Observable<Task[]> {
      return this.store.tasks$.asObservable();
   }

   setTasks(tasks: Task[]) {
      this.store.tasks$.next(tasks);
   }

   getMetaDataTasks$(): Observable<MetaDataTasks> {
      return this.store.metaDataTasks$.asObservable();
   }

   setMetaDataTasks(metaDataTasks: MetaDataTasks) {
      this.store.metaDataTasks$.next(metaDataTasks);
   }

   updateMetaDataTasks(metaDataTasks: Partial<MetaDataTasks>) {
      const md = this.store.metaDataTasks$.getValue();
      this.store.metaDataTasks$.next({ ...md, ...metaDataTasks });
   }

   // INDICATORS

   isLoadingGetPendingTasks$() {
      return this.store.loadingGetPendingTasks$.asObservable();
   }

   setLoadingGetPendingTasks(boolean: boolean) {
      this.store.loadingGetPendingTasks$.next(boolean);
   }

   getPendingTasks$(): Observable<number> {
      return this.store.pendingTasks$.asObservable();
   }

   setPendingTasks(number: number) {
      this.store.pendingTasks$.next(number);
   }
}
