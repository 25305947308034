import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { DateAdapter } from '@angular/material/core';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarModule } from 'angular-calendar';
import { MaterialModule } from 'src/app/material/material.module';


@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CommonModule,
    MaterialModule,
  ],
  exports: [CalendarComponent],

})
export class CustomCalendarModule { }
