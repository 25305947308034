import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { SnapshotAttentions } from 'src/app/modules/attentions/pages/attentions/attentions.component';
import { DateService } from 'src/app/shared/components/utils/date.service';
import { Attention } from 'src/app/shared/models/attention';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { Liquidation } from 'src/app/shared/models/liquidation';
import { StateLiquidation } from 'src/app/shared/models/stateLiquidation';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { RestUtilitiesService } from './rest-utilities.service';
import { Consent } from '../../shared/models/consent';
import { CaseDateFilters } from 'src/app/abstraction/cases.facade';
import { GeneralService } from './general.service';
import { SweetAlertIcon } from 'sweetalert2';
import { ProvisionAgreementFilters } from '../../shared/models/ProvisionAgreementFilters';
import { Pagination } from 'src/app/shared/models/pagination';
import { ExtraMetaDataAttentions } from 'src/app/abstraction/attentions.facade';
import { EvolutionsService } from './evolutions.service';
import { AttentionOverlap } from 'src/app/shared/models/attentionOverlap';
import * as momentRange from 'moment-range';
import { ViewManagementEntities } from '../enums/ViewManagementEntities';

export interface AttentionsDeleteBatchQPS {
   caseId: number;
   allAttentions?: boolean; // En caso de seleccionar la opción “Todas las atenciones” enviar true
   fromDate?: any; // En caso de seleccionar algunas atenciones)
   toDate?: Date; // En caso de seleccionar algunas atenciones)
   allDays?: boolean; // En caso de seleccionar la opción “Todos los dias”)
   daysOfTheWeek?: number[]; // En caso de filtrar por día:
   // Para los días de la semana se debe enviar una lista con los id de cada día seleccionado yendo desde el 1 (Lunes) al 7 (Domingo)
   agreementsId?: number[];
   provisionFeesId: number[];
   force?: boolean;
}
export interface AttentionsQPS extends Pagination, ExtraMetaDataAttentions {
   fromDate?: Date;
   toDate?: Date;
   casesIds?: number[];
   agreementsIds?: number[];
   patientsIds?: number[];
   providersIds?: number[];
   financiersIds?: number[];
   ordersIds?: number[];
   operatorId?: number;
   liquidationId?: number;
   activeCase?: boolean;
   practicesIds?: number[];
   provisionFeeIds?: number[];
   statesIds?: number[];
   all?: boolean;
   allScheduledIndicator?: boolean;
   deleted?: boolean;
   sort?: string[];
   order?: string;
   page?: number;
   size?: number;
   select?: string[];
   expand?: string;
   notManageable?: boolean;
   searchPatient?: string;
   searchProvider?: string;
   searchPractice?: string;
   searchState?: string;
   searchCIF?: string;
   searchDocument?: string;
}
export interface PendingAttentionsQPS {
   casesIds?: number[];
}
export interface LiquidationsQPS {
   page?: number;
   size?: number;
   financierId?: number;
   name_like?: string;
   stateId?: number;
   active?: boolean;
   sortAttributePairsStr?: string;
}

export interface PatchAttentionBody {
   id?: number;
   fromDate?: Date;
   toDate?: Date;
   checkIn?: Date;
   checkOut?: Date;
   state?: {
      id?: number;
   };
   liquidationId?: number;
   caseId?: number;
   agreementId?: number;
   practiceId?: number;
   revertStateReason?: string | any;
}

export interface PostLiquidationBody {
   name: string;
   financierId: number;
   state: {
      id: number;
   };
}

export interface PatchLiquidationBody {
   active?: boolean;
   name?: string;
   financierId?: number;
}

@Injectable({
   providedIn: 'root',
})
export class AttentionsService {
   public snapshotData: SnapshotAttentions;

   constructor(
      private http: HttpClient,
      private dateService: DateService,
      private restUtilitiesService: RestUtilitiesService,
      private generalService: GeneralService,
      private evolutionsService: EvolutionsService,
   ) {}

   getAttentions(qps?: AttentionsQPS): Observable<HttpBaseResponse<Attention[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps
         ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps))
         : null;
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getLiquidations(qps?: LiquidationsQPS): Observable<HttpBaseResponse<Liquidation[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps
         ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps))
         : null;
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.LIQUIDATIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getAuditStatesAttention(attentionId: number): Observable<AuditStatesAttention> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}/${attentionId}/states`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getStatesLiquidation(): Observable<StateLiquidation[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.LIQUIDATIONS}/states`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getAttentionsLiquidationReport(liquidationId: number): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.LIQUIDATIONS}/${liquidationId}/report`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getScheduledAttentions(qps: AttentionsQPS): Observable<number> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps
         ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps))
         : null;
      return this.http
         .get<any>(
            `${environment.apiAttentions}${APIS.ATTENTIONS}/indicators/scheduled-attentions`,
            {
               headers: queryHeaders,
               observe: 'response',
               params: queryParams,
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getPendingAttentions(qps: PendingAttentionsQPS): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps
         ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps))
         : null;
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}/indicators/pending-attentions`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getAttentionsGeneric(qps: AttentionsQPS): Observable<Attention[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string; value: string }[] = _.map(qps, (value, key): {
         param: string;
         value: string;
      } => {
         if (value instanceof Date) value = this.dateService.formatDate(value);
         if (Array.isArray(value)) value = value.join(',');
         return {
            param: key,
            value: value.toString(),
         };
      });
      let queryParams = new HttpParams()
         .append('expand', 'financier,operator,patient,practice,provider')
         .append('size', '2000')
         .append('all', '1');
      qpsProcessed.forEach((qp) => {
         queryParams = queryParams.append(qp.param, qp.value);
      });
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getAttentionsByAgreements(
      agreementsId: number[],
      fromDate?: Date,
      toDate?: Date,
      notManageable?: boolean,
   ): Observable<Attention[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');

      let queryParams = new HttpParams()
         .append('agreementsIds', agreementsId.join(','))
         .append('all', '1')
         .append('expand', 'financier,operator,practice')
         .append('size', '2000');

      if (notManageable != null && notManageable != undefined) {
         queryParams = queryParams.append('notManageable', notManageable.toString());
      }
      if (fromDate && toDate) {
         const fromDateFormatted = this.dateService.formatDate(fromDate);
         const toDateFormatted = this.dateService.formatDate(toDate);
         queryParams = queryParams
            .append('fromDate', fromDateFormatted)
            .append('toDate', toDateFormatted);
      }
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchAttention(attention: Attention) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const { id, ...restWithoutId } = attention;
      console.log(JSON.stringify(restWithoutId));
      return this.http
         .patch<any>(
            `${environment.apiAttentions}${APIS.ATTENTIONS}/${id}`,
            JSON.stringify(restWithoutId),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchAttentionByAgreement(attention) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      if (!!attention.fromDate) {
         attention.fromDate = this.dateService.formatDate(attention.fromDate);
      }
      if (!!attention.toDate) {
         attention.toDate = this.dateService.formatDate(attention.toDate);
      }
      const { id, ...restWithoutId } = attention;
      return this.http
         .patch<any>(
            `${environment.apiAttentions}${APIS.ATTENTIONS}/${attention.id}/byAgreement`,
            JSON.stringify(restWithoutId),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchLiquidation(liquidation: PatchLiquidationBody, liquidationId: number) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiAttentions}${APIS.LIQUIDATIONS}/${liquidationId}`,
            JSON.stringify(liquidation),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchAttentions(attentions: PatchAttentionBody[]): Observable<Attention[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      // console.log(JSON.stringify(attentions));
      return this.http
         .patch<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, JSON.stringify(attentions), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   deleteAttention(attention: Attention) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .delete<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}/${attention.id}`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   deleteAttentionsBatch(qps: AttentionsDeleteBatchQPS) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps
         ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps))
         : null;
      return this.http
         .delete<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   deleteLiquidation(liquidationId: number) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .delete<any>(`${environment.apiAttentions}${APIS.LIQUIDATIONS}/${liquidationId}`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   deleteAttentions(attentions: Attention[]) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const arrayRequests: Observable<any>[] = [];
      attentions.forEach((attention) => {
         const { id, ...restWithoutId } = attention;
         arrayRequests.push(
            this.http.delete<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}/${id}`, {
               headers: queryHeaders,
               observe: 'response',
            }),
         );
      });
      return forkJoin(arrayRequests).pipe<any>(
         map<HttpResponse<any>[], any>((responses) => {
            return responses.map((response) => response.body);
         }),
      );
   }

   postAttention(
      attentions: {
         fromDate: Date;
         toDate: Date;
         stateId: number;
         agreementId: number;
         provisionFeeId?: number;
      }[],
   ) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const body = attentions.map((attention) => {
         const { stateId, ...noStateId } = attention;
         return { ...noStateId, state: { id: stateId } };
      });
      return this.http
         .post<any>(`${environment.apiAttentions}${APIS.ATTENTIONS}`, JSON.stringify(body), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postAttentionV2(
      attentions: {
         fromDate: Date;
         toDate: Date;
         stateId: number;
         agreementId: number;
         provisionFeeId?: number;
      }[],
   ) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const body = attentions.map((attention) => {
         const { stateId, ...noStateId } = attention;
         return { ...noStateId, state: { id: stateId } };
      });
      return this.http
         .post<any>(`${environment.apiAttentions}${APIS.ATTENTIONS_V2}`, JSON.stringify(body), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postLiquidation(liquidation: PostLiquidationBody) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(
            `${environment.apiAttentions}${APIS.LIQUIDATIONS}`,
            JSON.stringify(liquidation),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getLiquidationsCount(): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiAttentions}/user/liquidations/count`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   isFacturable(attention: Attention) {
      // return (attention.state.id == AttentionStates.REALIZADA || attention.state.id == AttentionStates.NO_REALIZADA)
      return (
         attention.state.id == AttentionStates.REALIZADA ||
         attention.state.id == AttentionStates.NO_REALIZADA
      );
   }

   isBillableOrDebited(attention: Attention) {
      return (
         attention.state.id == AttentionStates.REALIZADA ||
         attention.state.id == AttentionStates.NO_REALIZADA ||
         attention.state.id == AttentionStates.FACTURABLE ||
         attention.state.id == AttentionStates.DEBITADA
      );
   }

   isNotInformed(attention: Attention) {
      return attention.state.id == AttentionStates.NO_INFORMADA;
   }

   isDebitable(attention: Attention) {
      return attention.state.id == AttentionStates.REALIZADA;
   }

   isDeletable(attention: Attention) {
      const permittedStatesToDeleteWithOutEvolutions = [
         AttentionStates.REALIZADA,
         AttentionStates.FACTURABLE,
      ];

      if (
         (attention.state.id == AttentionStates.AGENDADA && !attention.isSupply) ||
         (permittedStatesToDeleteWithOutEvolutions.includes(attention.state.id) &&
            !attention.isSupply &&
            !attention.hasEvolutions)
      ) {
         return true;
      } else {
         return false;
      }
   }

   isInformable(attention: Attention): boolean {
      return (
         attention.state.id == AttentionStates.FACTURABLE ||
         attention.state.id == AttentionStates.NO_INFORMADA
      );
   }

   isEvolvable(attention: Attention): boolean {
      return (
         attention.state.id == AttentionStates.AGENDADA ||
         attention.state.id == AttentionStates.REALIZADA ||
         attention.state.id == AttentionStates.EN_CURSO ||
         attention.state.id == AttentionStates.NO_REALIZADA ||
         attention.state.id == AttentionStates.INFORMADA
      );
   }

   isAgreementReplaceable(attention: Attention): boolean {
      const notPermittedStatesToReplace = [
          AttentionStates.INFORMADA,
          AttentionStates.NO_INFORMADA
      ];
      return !(notPermittedStatesToReplace.includes(attention.state.id) && !attention.isSupply);
  }

   isOldAndOmmited(attention: Attention): boolean {
      const now = moment(new Date());
      now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return (
         moment(attention.fromScheduled).isBefore(now) &&
         (attention.state.id == AttentionStates.AGENDADA ||
            attention.state.id == AttentionStates.EN_CURSO)
      );
   }

   isLastStateidBillable(lastStateId: number): boolean {
      return lastStateId == AttentionStates.FACTURABLE;
   }

   isEditable(attention: Attention): boolean {
      const permittedStatesToDeleteWithOutEvolutions = [
         AttentionStates.REALIZADA,
         AttentionStates.FACTURABLE,
      ];

      if (
         (attention.state.id == AttentionStates.AGENDADA && !attention?.isSupply) ||
         (permittedStatesToDeleteWithOutEvolutions.includes(attention.state.id) &&
            !attention?.isSupply &&
            !attention.hasEvolutions)
      ) {
         return true;
      } else {
         return false;
      }
   }

   isCopiable(attention: Attention): boolean {
      const permittedStatesToCopy = [
         AttentionStates.AGENDADA,
         AttentionStates.REALIZADA,
         AttentionStates.FACTURABLE,
      ];
      return (
         !!attention.practice?.restriction?.requiredTime &&
         permittedStatesToCopy.includes(attention.state.id) && !attention.isSupply
      );
   }

   isStatePending(value) {
      if (!!value.id) {
        return (
          value.id == AttentionStates.CARGADA ||
          value.id == AttentionStates.AGENDADA ||
          value.id == AttentionStates.REALIZADA ||
          value.id == AttentionStates.EN_CURSO ||
          value.id == AttentionStates.NO_REALIZADA ||
          value.id == AttentionStates.FACTURABLE
        );
      } else {
        return false;
      }
    }

   // Consent - Consentimiento - GET
   getConsent(caseId: number): Observable<HttpBaseResponse<Consent>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiAttentions}${APIS.CONSENT}/${caseId}/state`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   // Consent - Consentimiento - POST
   postConsent(consent: Consent): Observable<Consent> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiAttentions}${APIS.CONSENT}`, JSON.stringify(consent), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   countAttentionsByState(attentions: Attention[]): Array<number> {
      let totalsByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0);
      attentions.map((att) => {
         totalsByAttentionsState[att.state.id] += 1;
      });
      return totalsByAttentionsState;
   }

   getPeriodToLoadAttentions(from: Date, to: Date): CaseDateFilters {
      // const start:Date = moment(from).subtract(1, 'M').startOf('month').startOf('isoWeek').toDate();
      const start: Date = moment(from)
         .subtract(1, 'M')
         .startOf('month')
         .toDate();
      // const end:Date = moment(to).add(1,'M').endOf('month').endOf('isoWeek').toDate()
      const end: Date = moment(to)
         .add(1, 'M')
         .endOf('month')
         .toDate();
      console.log(
         `Get period to load attentions from ${moment(start).format('YYYY-MM-DD')} to ${moment(
            end,
         ).format('YYYY-MM-DD')}`,
      );

      return { historyFromDate: start, historyToDate: end };
   }

   /**
    *
    * @param msg: Template message
    * @returns: Template message transformed to html to be used into Swal
    */

   getAttentionsMessageError = (msg: string): string => {
      let countAttentions: Array<string> = msg.split(',');
      let message: string = '';

      for (let line in countAttentions) {
         message += `<span>${countAttentions[line]}</span><br>`;
      }
      return message;
   };

   /**
    *
    * @param errorContent Endpoint error object
    * @param msgType SwalAlertIcon to show
    * @returns
    */
   processErrorCountAttentions(errorContent, msgType?: SweetAlertIcon): Promise<boolean> {
      return this.generalService.processErrorTeaPot(
         errorContent,
         this.getAttentionsMessageError,
         msgType,
      );
   }

   mamushkaCheckBoxes(
      filters: ProvisionAgreementFilters,
      attentionsToFilter: Attention[],
   ): Attention[] {
      if (filters?.provisions?.length > 0) {
         const practicesIds: number[] = filters?.provisions?.map(
            (provision) => provision.practice.id,
         );
         attentionsToFilter = attentionsToFilter.filter(
            (att) => practicesIds.filter((pr) => pr === att.practiceId).length > 0,
         );
      }

      if (filters?.provisionFees?.length > 0) {
         const provisionFeeIds: number[] = filters?.provisionFees?.map((provFee) => provFee.id);
         if (filters.agreements?.length > 0) {
            // Gets all the practices with one agreement selected, so it can filter by agreement only if there is some agreement selected of that provision.
            const practiceIdsWithAgreementSelected: number[] = filters.provisions
               .filter((p) => p.agreements.some((ag) => filters.agreements.includes(ag)))
               .map((p) => p.practiceId);
            const agreementsIds: number[] = filters?.agreements?.map((agreement) => agreement.id);
            attentionsToFilter = attentionsToFilter.filter((att) =>
               practiceIdsWithAgreementSelected.includes(att.practiceId)
                  ? agreementsIds.includes(att.agreementId) &&
                    provisionFeeIds.includes(att.provisionFeeId)
                  : provisionFeeIds.includes(att.provisionFeeId),
            );
         } else {
            attentionsToFilter = attentionsToFilter.filter(
               (att) => provisionFeeIds.filter((pf) => pf === att.provisionFeeId).length > 0,
            );
         }
      }

      return attentionsToFilter;
   }

   filterGuardiaAttentions(attentions: Attention[]): Attention[] {
      return attentions?.length > 0
         ? attentions.filter((att) => att.practice?.restriction?.id == 2)
         : [];
   }

   composeAttentionHasOverlap(guardiaAttentions: Attention[]): AttentionOverlap[] {
      let guardiaAttentionsComposed: AttentionOverlap[] = [];
      // Group attentions by practiceId
      const groupedAttentions = guardiaAttentions?.reduce((acc, att) => {
         if (!acc[att.practiceId]) {
            acc[att.practiceId] = [];
         }
         acc[att.practiceId].push(att);
         return acc;
      }, {});

      Object.values(groupedAttentions).forEach((attentions) => {
         // Primero, creamos un mapa para rastrear las atenciones que tienen solapamiento
         const overlapMap = new Map();

         attentions.forEach((att, index) => {
            // Crea un rango para la atención actual
            const range1 = new momentRange.DateRange(moment(att.fromDate), moment(att.toDate));

            // Compara solo con las atenciones posteriores en el mismo grupo
            for (let i = index + 1; i < attentions.length; i++) {
               const range2 = new momentRange.DateRange(
                  moment(attentions[i].fromDate),
                  moment(attentions[i].toDate),
               );
               const intersection = this.calculateRangeIntersection(range1, range2);
               if (intersection) {
                  // Si hay superposición, agrega ambas atenciones al mapa de solapamiento
                  overlapMap.set(att.id, {
                     attentionId: attentions[i].id,
                     providerFullName: `${attentions[i].provider.name} ${attentions[i].provider.surname}`, // Asumiendo que esta propiedad existe en Attention
                     fromDate: attentions[i].fromDate,
                     toDate: attentions[i].toDate,
                     hourDiff: intersection.end.diff(intersection.start, 'hours'),
                  });
                  overlapMap.set(attentions[i].id, {
                     attentionId: att.id,
                     providerFullName: `${att.provider.name} ${att.provider.surname}`, // Asumiendo que esta propiedad existe en Attention
                     fromDate: att.fromDate,
                     toDate: att.toDate,
                     hourDiff: intersection.end.diff(intersection.start, 'hours'),
                  });
               }
            }
         });

         // Después de todas las comparaciones, marcamos las atenciones como hasOverlap: true y asignamos los datos de solapamiento
         attentions.forEach((att) => {
            att.hasOverlap = overlapMap.has(att.id);
            if (overlapMap.has(att.id)) {
               att.dataFromOverlaping = overlapMap.get(att.id);
            }
         });
      });

      guardiaAttentionsComposed = Object.values(groupedAttentions).flat();

      return guardiaAttentionsComposed;
   }

   calculateRangeIntersection(
      range1: momentRange.DateRange,
      range2: momentRange.DateRange,
   ): momentRange.DateRange | null {
      const intersection: momentRange.DateRange = range1.intersect(range2);
      return intersection ? intersection : null;
   }

   overlapText(overlapedAttention: AttentionOverlap) {
      let overlapInfo = overlapedAttention ? overlapedAttention.dataFromOverlaping : null;
      // Prestador - Dia  Dom 30 Mar - 18:00hs a 09:00hs (+1)
      const text = overlapInfo
         ? `Solapamiento - ${overlapInfo.providerFullName} - ${moment(overlapInfo.fromDate).format(
              'ddd DD MMM',
           )} - ${moment(overlapInfo.fromDate).format('HH:mm')}hs a ${moment(
              overlapInfo.toDate,
           ).format('HH:mm')}hs (+${overlapInfo.hourDiff})`
         : null;
      return text;
   }

   getHourQuantity(att: Attention): number {
      return Math.ceil(moment(att.toDate).diff(att.fromDate) / (1000 * 60 * 60));
   }
}
