import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Params } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';
import { event } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private ViewManagementEntities$ = new BehaviorSubject<ViewManagementEntities>(null);
  private _breadcrumbsSubject = new BehaviorSubject<Array<{ label: string, url: string }>>([]);
  breadcrumbs$ = this._breadcrumbsSubject.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      this._breadcrumbsSubject.next(breadcrumbs);
    });
  }

  getScreenToJump$(): Observable<ViewManagementEntities> {
    return this.ViewManagementEntities$.asObservable();
 }


  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): Array<{ label: string, url: string }> {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      // Extraemos los parámetros de la ruta actual
      const params: Params = child.snapshot.params;

      // Solo agregamos el breadcrumb si tiene un label definido y es diferente al último añadido
      if (child.snapshot.data && child.snapshot.data['breadcrumb']) {
        const label = this.replaceParams(child.snapshot.data['breadcrumb'], params);
        if (!breadcrumbs.length || breadcrumbs[breadcrumbs.length - 1].label !== label) {
          breadcrumbs.push({ label, url: url });
        }
      }

      // Continuamos con los hijos si existen
      if (child.children.length > 0) {
        return this.createBreadcrumbs(child, url, breadcrumbs);
      }

      // Si no hay hijos, retornamos los breadcrumbs actuales
      return breadcrumbs;
    }

    return breadcrumbs;
  }

  private replaceParams(label: string, params: Params): string {
    return Object.keys(params).reduce((acc, key) => {
      return acc.replace(`:${key}`, params[key].toString());
    }, label);
  }

  // Método nuevo para agregar entradas manualmente
  addManualBreadcrumb(label: string, url?: string) {
    const currentBreadcrumbs = this._breadcrumbsSubject.value;
    currentBreadcrumbs.push({ label, url: url || '' });
    this._breadcrumbsSubject.next([...currentBreadcrumbs]);
  }

  // Método nuevo para eliminar la última entrada
  removeLastBreadcrumb() {
    const currentBreadcrumbs = this._breadcrumbsSubject.value;
    if (currentBreadcrumbs.length > 0) {
      currentBreadcrumbs.pop();
      this._breadcrumbsSubject.next([...currentBreadcrumbs]);
    }
  }

  // Método nuevo para limpiar todos los breadcrumbs
  clearBreadcrumbs() {
    this._breadcrumbsSubject.next([]);
  }

  setScreenToJump(event:ViewManagementEntities) {
    this.ViewManagementEntities$.next(event)
  }
}
