import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { finalize, map, shareReplay, tap, concatMap, take, takeUntil } from 'rxjs/operators';
import { CasesService, OrdersQPS, PatchOrderBody, PostCaseBody, PostOrderBody, PostDerivedProviderBody, PostAddCaseArrangementBody, CompaniesTypeQPS, TaxZone, TaxZonesQPS, PatchPatientBody, DeliveryNotePDFBody } from '../core/services/cases.service';
import { GeneralService } from '../core/services/general.service';
import { CasesState } from '../core/states/cases.state';
import { Case, CASE_AUTHORIZATIONS_STATES } from '../shared/models/case';
import { Diagnosis } from '../shared/models/diagnosis';
import { Order } from '../shared/models/order';
import { Pagination } from '../shared/models/pagination';
import { AttentionsService } from '../core/services/attentions.service';
import { VatCategory } from '../shared/models/vatCategory';
import { PatientsQPS } from '../core/services/patients.service';
import { Patient } from '../shared/models/patient';
import { CaseArrangement } from '../shared/models/caseArrangement';
import { Arrangement } from '../shared/models/arrangement';
import { ProvisionalControlIndicatorsQPS, CasesIndicatorsValues, ProvisionFeesIndicatorsQPS } from '../shared/models/provisionalControlIndicators';
import { ArrangementItem } from '../shared/models/arrangementItem';
import { Item } from '../shared/models/item';
import { AttentionsState } from '../core/states/attentions.state';
import { HttpCancelService } from '../core/services/http-cancel.service';
import { UpdatingCase } from '../shared/models/submodels/updatingCase';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { RestUtilitiesService } from '../core/services/rest-utilities.service';
import { APIS } from '../core/enums/apis';

export interface CasesFilterParameters {
    byArrangementDate?: Date;
    patientsIds?: number[];
    providersIds?: number[];
    financierId?: number;
    operatorId?: number;
    page?: number;
    size?: number;
    order?: string;
    sort?: string;
    searchCif?: string;
    searchPatient?: string;
    searchDocument?: string;
    active?: boolean;
    allActiveIndicator?: boolean;
    allHasScheduleIndicator?: boolean;
    allHasPendingScheduleIndicator?: boolean;
    hasSchedule?: boolean;
    hasPendingSchedule?: boolean;
    // Attentions filters
    attentionsToDate?: Date;
    attentionsStatesIds?: number[];
    derived?: boolean;
    all?: boolean;
    companyTypeId?: number;
    toDateProvisionFee?: Date;
    indicator?: string;
    // byIndicator?: true;
    byArrangement?: boolean;
    closeLastTrimester?: boolean;
    requested?: number;
    rejected?: number;
    tableOption?: string;
    casesState?: string;
    compose?: boolean
}

export interface CaseDateFilters {
    historyFromDate?: Date | null,
    historyToDate?: Date | null,
    touched?: boolean
}

export interface MetaDataOrders extends OrdersQPS, Pagination {

}

export interface EvolutionPdfQPS {
    caseId: number;
    financierId: number;
    fromDate?: string;
    toDate?: string;
    image?: string;
    isHideFromFinancier?: boolean;
    isHideFromProvider?: boolean;
    isWithTenantLogo?: boolean;
    groupByPractice?: boolean;
    groupByPracticeAndProvider?: boolean;
    providerIds?: number[];
    financierIds?: number[];
    categoriesIds?: number[];
    provisionFeeIds?: number[];
    agreementsIds?: number[];
    onlyCover?: boolean;
}

export interface CaseQPS {
    withProvisions?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class CasesFacade {

    private diagnoses$: Observable<Diagnosis[]>;
    private loadingGettingDiagnoses$ = new BehaviorSubject<boolean>(false);
    private cancelPendingRequests$ = new Subject<void>();

    constructor(
        private casesService: CasesService,
        private casesState: CasesState,
        private generalService: GeneralService,
        private attentionService: AttentionsService,
        private attentionState: AttentionsState,
        private httpCancelService: HttpCancelService,
        private http: HttpClient,
        private restUtilitiesService: RestUtilitiesService,
    ) {
        this.setLoadingGettingDiagnoses(true);
        this.diagnoses$ = this.casesService
            .getDiagnoses()
            .pipe(tap(() => this.setLoadingGettingDiagnoses(false)), shareReplay(1)); // cache the data
    }

    getDiagnoses$(): Observable<Diagnosis[]> {
        return this.diagnoses$;
    }

    getDiagnosesFiltered$(value: string): Observable<Diagnosis[]> {
        return this.diagnoses$
            .pipe(
                map(diagnoses => diagnoses.filter(diagnosis => {
                    const name = this.generalService.normalizeString(diagnosis.name.toLowerCase().trim());
                    value = this.generalService.normalizeString(value.toLowerCase().trim());
                    return name.indexOf(value) != -1;
                }))
            );
    }

    private setLoadingGettingDiagnoses(isLoadingGettingDiagnoses: boolean) {
        this.loadingGettingDiagnoses$.next(isLoadingGettingDiagnoses);
    }

    isLoadingGettingDiagnoses$() {
        return this.loadingGettingDiagnoses$.asObservable();
    }

    isLoadingCreatingCase$(): Observable<boolean> {
        return this.casesState.isLoadingCreatingCase$();
    }

    isLoadingGettingCases$(): Observable<boolean> {
        return this.casesState.isLoadingGettingCases$();
    }

    isLoadingGettingCase$(): Observable<boolean> {
        return this.casesState.isLoadingGettingCase$();
    }

    isLoadingGettingActiveCases$(): Observable<boolean> {
        return this.casesState.isLoadingGettingActiveCases$();
    }
    isLoadingGettingPagination$(): Observable<boolean> {
        return this.casesState.isLoadingGettingPagination$();
    }

    isLoadingGettingProvisionalControlProvisionFees$(): Observable<boolean> {
        return this.casesState.isLoadingGettingProvisionalControlProvisionFees();
    }

    setIsLoadingGettingProvisionalControlProvisionFees(isLoading: boolean) {
        this.casesState.setLoadingGettingProvisionalControlProvisionFees(isLoading);
    }

    isLoadingGettingProvisionalControlOrders$(): Observable<boolean> {
        return this.casesState.isLoadingGettingProvisionalControlOrders();
    }

    setIsLoadingGettingProvisionalControlOrders(isLoading: boolean) {
        this.casesState.setLoadingGettingProvisionalControlOrders(isLoading);
    }

    getPagination$(): Observable<Pagination> {
        return this.casesState.getPagination$();
    }

    getMetaDataCases$(): Observable<CasesFilterParameters> {
        return this.casesState.getMetaDataCases$();
    }

    setMetaDataCases$(mdc: CasesFilterParameters): void {
        this.casesState.setMetaDataCases(mdc);
    }

    // @FacadeAdd<CreationalCase, Case>({
    //    state: 'casesState',
    //    service: 'casesService',
    //    loadingMethod: 'setLoadingCreatingCase',
    //    addMethod: 'addCase',
    //    errorMethod: 'setErrorCreatingCase',
    //    postMethod: 'postCase'
    // })
    // pesimistic strategy
    addCase(acase: PostCaseBody): void | Observable<Case> {
        this.casesState.setLoadingCreatingCase(true);
        // Mapper from CreationalCase to PostCaseBody must be here
        const promise: Promise<Case> = new Promise((res, rej) => {
            this.casesService.postCase(acase).pipe(
                finalize(() => this.casesState.setLoadingCreatingCase(false))
            ).subscribe({
                next: newCase => { this.casesState.addCase(newCase); res(newCase); },
                error: e => { this.casesState.setErrorCreatingCase(true); rej(e); },
            })
        });
        return from(promise);
    }

    getCases$(): Observable<Case[]> {
        return this.casesState.getCases$().pipe(
            map((cases: Case[]) => {
                return cases?.map(acase => ({ ...acase, attentionsWithoutScheduleByCase: this.getAttentionsWithoutScheduleByCase(acase) }));
            })
        );
    }

    private getAttentionsWithoutScheduleByCase(acase: Case) {
        if (acase.hasSchedule == null || acase.hasSchedule == undefined || acase.hasSchedule == true) {
            return 0;
        } else {
            if (!!acase.fromDate) {
                const _now = new Date();
                const _caseCreation = new Date(acase.fromDate.toString());
                return this.generalService.dateDiffInDays(_caseCreation, _now);
            } else {
                return 0;
            }
        }
    }

    private setAuthorizationsStateToCase(acase: Case) {
        const _acase = { ...acase };
        const now = new Date().getTime();
        if (!_acase.closestAuthorization) {
            _acase.closestAuthorizationState = CASE_AUTHORIZATIONS_STATES.UNAUTHORIZED;
        } else {
            const closestAuthorization = new Date(_acase.closestAuthorization.toDate).getTime();
            if (closestAuthorization < now) {
                _acase.closestAuthorizationState = CASE_AUTHORIZATIONS_STATES.EXPIRED;
            } else {
                if (this.generalService.dateDiffInDays(new Date(), new Date(_acase.closestAuthorization.toDate)) <= 5) {
                    console.log('POR VENCEEER dias:', acase);
                    console.log('POR VENCEEER dias:', this.generalService.dateDiffInDays(new Date(), new Date(_acase.closestAuthorization.toDate)));
                    _acase.closestAuthorizationState = CASE_AUTHORIZATIONS_STATES.CLOSE_TO_EXPIRED;
                } else {
                    _acase.closestAuthorizationState = CASE_AUTHORIZATIONS_STATES.VALID;
                }
            }
        }
        return _acase;
    }

    // This method make de request and update the state
    // Default -> all: false
    loadCases(cfp?: CasesFilterParameters, expandedPendingAttentions: boolean = false, version?) {
        this.casesState.setLoadingGettingCases(true);
        //console.log('Loading request GET cases ', cfp);
        let cases: Case[] = [];
        let responseCases;
        return this.casesService.getCases(cfp, version)
            .pipe(
                tap(body => this.casesState.setPagination(body.pagination)),
                map(body => {
                    let cases = body.data;
                    if (!!cases) {
                        cases = cases.map(acase => this.setAuthorizationsStateToCase(acase));
                    }
                    body.data = cases;
                    return body;
                }),
                concatMap(body => {
                    responseCases = body;
                    cases = body.data;
                    if (!!cases && cases.length > 0 && expandedPendingAttentions) {
                        const casesIds = cases.map(acase => acase.id);
                        return this.attentionService.getPendingAttentions({ casesIds }).toPromise().then(
                            (pendingAttentions) => {
                                return pendingAttentions;
                            }
                        ).catch((error) => {
                            this.casesState.setLoadingGettingCases(false);
                        });
                    } else {
                        return of(null);
                    }
                }),
                tap(bodyPendingAttentions => {
                    if (!!bodyPendingAttentions) {
                        const pendingAttentions: any[] = bodyPendingAttentions;
                        let caseIndex: number;
                        pendingAttentions.forEach(pendingAttention => {
                            caseIndex = cases.findIndex(acase => acase.id == pendingAttention.caseId);
                            if (caseIndex != -1) {
                                cases[caseIndex].pendingAttentions = pendingAttention.attentionCount;
                            }
                        });
                    }
                }),
                tap(response => {
                    this.casesState.setMetaDataCases(cfp);
                }),
                tap(_ => this.casesState.setCases(cases)),
                finalize(() => this.casesState.setLoadingGettingCases(false)),
                finalize(() => this.casesState.setLoadingGettingPagination(false)),
                tap((_) => console.log('request finished. Cases: ', responseCases.data, 'Pagination :', responseCases.pagination))
            );
    }

    loadCasesFromProvisionalControlIndicators(provisionalControlIndicatorsQPS?: ProvisionalControlIndicatorsQPS, version?: string) {

        // this.httpCancelService.cancelPendingRequests();
        this.casesState.setLoadingGettingCases(true);
        //this.casesState.setLoadingProvisionalControlIndicators(true);

        const promise: Promise<CasesIndicatorsValues> = new Promise((res, rej) => {

            this.casesService.getProvisionalControlIndicatorValues(provisionalControlIndicatorsQPS, version).pipe(
                //takeUntil(this.httpCancelService.onCancelPendingRequests()),
                tap(body => this.casesState.setPagination(body.pagination)),
                //finalize(() => this.casesState.setLoadingProvisionalControlIndicators(false))
                finalize(() => this.casesState.setLoadingGettingCases(false)),
                finalize(() => this.casesState.setLoadingGettingPagination(false)),
            ).subscribe({
                next: response => {
                    this.casesState.setMetaDataCases(provisionalControlIndicatorsQPS);
                    // this.casesState.setProvisionalControlIndicatorValues(response.data);
                    this.casesState.setCases(response.data.cases);
                    res(response.data.cases);
                },
                error: e => { rej(e) }
            })
        });
        return from(promise);
    }

    getActiveCases$(): Observable<number> {
        return this.casesState.getActiveCases$();
    }

    // this method make de request and update the state
    loadActiveCases(all: boolean = false) {
        this.casesState.setLoadingGettingActiveCases(true);
        // directly mapped from CasesFilterParameters to CasesQPS, because they are the same properties (could differ)
        const promise: Promise<number> = new Promise((res, rej) => {
            this.casesService.getActiveCases(all).pipe(
                tap(() => {
                    this.casesState.updateMetaDataCases({ allActiveIndicator: all });
                }),
                finalize(() => this.casesState.setLoadingGettingActiveCases(false))
            ).subscribe({
                next: countCases => { this.casesState.setActiveCases(countCases); res(countCases); },
                error: e => { rej(e); }
            })
        });
        return from(promise);
    }

    getCasesWithoutAttentions$(): Observable<number> {
        return this.casesState.getCasesWithoutAttentions$();
    }

    // This method make de request and update the state
    loadCasesWihtoutAttentions(all: boolean = false) {
        this.casesState.setLoadingGettingCasesWithoutAttentions(true);
        // directly mapped from CasesFilterParameters to CasesQPS, because they are the same properties (could differ)
        const promise: Promise<number> = new Promise((res, rej) => {
            this.casesService.getCasesWithoutAttentions(all).pipe(
                tap(() => {
                    this.casesState.updateMetaDataCases({ allHasScheduleIndicator: all });
                }),
                finalize(() => this.casesState.setLoadingGettingCasesWithoutAttentions(false))
            ).subscribe({
                next: countCases => { this.casesState.setCasesWithoutAttentions(countCases); res(countCases); },
                error: e => { rej(e); },
            })
        });
        return from(promise);
    }

    // INDICATORS
    getCasesWithoutPendingAttentions$(): Observable<number> {
        return this.casesState.getCasesWithoutPendingAttentions$();
    }

    // this method make de request and update the state
    loadCasesWihtoutPendingAttentions(all: boolean = false): Observable<number> {
        this.casesState.setLoadingGettingCasesWithoutPendingAttentions(true);
        // directly mapped from CasesFilterParameters to CasesQPS, because they are the same properties (could differ)
        const promise: Promise<number> = new Promise((res, rej) => {
            this.casesService.getCasesWithoutPendingAttentions(all).pipe(
                tap(() => {
                    this.casesState.updateMetaDataCases({ allHasPendingScheduleIndicator: all });
                }),
                finalize(() => this.casesState.setLoadingGettingCasesWithoutPendingAttentions(false))
            ).subscribe({
                next: countCases => { this.casesState.setCasesWithoutPendingAttentions(countCases); res(countCases); },
                error: e => { rej(e); },
            });
        });
        return from(promise);
    }

    isLoadingGettingCasesWithoutPendingAttentions$(): Observable<boolean> {
        return this.casesState.isLoadingGettingCasesWithoutPendingAttentions$();
    }

    // **CASE**
    getCase$(): Observable<Case> {
        return this.casesState.getCase$();
    }

    updateCase$(casee: UpdatingCase, caseId: number) {
        this.casesState.updateCase(casee, caseId);
    }

    // this method make de request and update the state
    loadCase(caseId: number, qps?: CaseQPS) {
        this.casesState.setLoadingGettingCase(true);
        // Directly mapped from CasesFilterParameters to CasesQPS, because they are the same properties (could differ)
        const promise: Promise<Case> = new Promise((res, rej) => {
            this.casesService.getCase(caseId, qps).pipe(
                finalize(() => this.casesState.setLoadingGettingCase(false)),
                tap(casee => console.log("request GET case by Id: ", casee))
            ).subscribe({
                next: casee => { this.casesState.setCase(casee); res(casee) },
                error: e => rej(e)
            });
        });
        return from(promise);
    }

    updateCase(acase: UpdatingCase, caseId: number): Observable<Case> {
        this.casesState.setLoadingUpdatingCase(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Case> = new Promise((res, rej) => {
            this.casesService.patchCase(acase, caseId).pipe(
                finalize(() => this.casesState.setLoadingUpdatingCase(false))
            ).subscribe({
                next: newCase => { this.casesState.updateCase(newCase, caseId); res(newCase); },
                error: e => { this.casesState.setErrorUpdatingCase(true); rej(e); },
            })
        });
        return from(promise);
    }

    // **ORDERS** //
    isLoadingGettingOrders$(): Observable<boolean> {
        return this.casesState.isLoadingGettingOrders$();
    }
    isLoadingUpdatingOrder$(): Observable<boolean> {
        return this.casesState.isLoadingUpdatingOrder$();
    }
    isLoadingCreatingOrder$(): Observable<boolean> {
        return this.casesState.isLoadingCreatingOrder$();
    }

    getMetaDataOrders$(): Observable<MetaDataOrders> {
        return this.casesState.getMetaDataOrders$();
    }

    getOrders$(): Observable<Order[]> {
        return this.casesState.getOrders$();
    }

    loadOrders(qps: OrdersQPS, caseId: number) {
        this.casesState.setLoadingGettingOrders(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getOrders(caseId, qps).pipe(
                tap(body => this.casesState.setOrders(body.data)),
                tap(() => this.casesState.setLoadingGettingOrders(false)),
                tap(() => this.casesState.setLoadingGettingPagination(false)),
                tap((body) => {
                    this.casesState.setMetaDataOrders({ ...qps, ...body.pagination });
                }),
            ).subscribe({
                next: response => res(response.data),
                error: e => {
                    this.casesState.setLoadingGettingOrders(false);
                    this.casesState.setLoadingGettingPagination(false);
                    rej(e);
                }
            })
        });
        return from(promise);
    }

    addOrder(postBody: PostOrderBody, caseId: number): Observable<Order> {
        this.casesState.setLoadingCreatingOrder(true);
        const promise: Promise<Order> = new Promise((res, rej) => {
            this.casesService.postOrder(postBody, caseId).pipe(
                finalize(() => this.casesState.setLoadingCreatingOrder(false))
            ).subscribe({
                next: newOrder => {
                    this.casesState.addOrder(newOrder);
                    if (!!newOrder.orderAttention) {
                        if (!!newOrder?.orderAttention?.attentionOfSupplyRequest) {
                            this.attentionState.putAttention(newOrder?.orderAttention?.attentionOfSupplyRequest);
                            this.attentionState.putAttentionCaseActive(newOrder?.orderAttention?.attentionOfSupplyRequest);
                        }
                    }
                    res(newOrder)
                },
                error: (e) => { this.casesState.setErrorCreatingOrder(true); rej(e); },
            });
        });
        return from(promise);
    }

    updateOrder(patchBody: PatchOrderBody, entityId: number, caseId: number): Observable<Order> {
        this.casesState.setLoadingUpdatingOrder(true);
        const promise: Promise<Order> = new Promise((res, rej) => {
            this.casesService.patchOrder(patchBody, entityId, caseId).pipe(
                finalize(() => this.casesState.setLoadingUpdatingOrder(false))
            ).subscribe({
                next: newOrder => {
                    this.casesState.updateOrder(newOrder, entityId);
                    if (!!newOrder.orderAttention) {
                        // Delete attentions because is reverting
                        if (patchBody.stateReversed) {
                            newOrder?.orderAttention?.attentionsAffected?.forEach(att => {
                                this.attentionState.deleteAttentionCaseActive(att)
                                this.attentionState.deleteAttention(att)
                            })
                        } else {
                            // Billeable attentions by supply/item
                            if (newOrder?.orderAttention?.attentionsAffected) {
                                this.attentionState.putAttentions(newOrder?.orderAttention?.attentionsAffected) // This update attentionsCaseActive too
                                this.attentionState.putAttentionsCaseActive(newOrder?.orderAttention?.attentionsAffected) // Force caseActive attentions to refresh
                            }
                        }
                        // Attention 'Entrega insumos'
                        if (newOrder?.orderAttention?.attentionOfSupplyRequest) {
                            this.attentionState.putAttention(newOrder?.orderAttention?.attentionOfSupplyRequest);
                            this.attentionState.putAttentionCaseActive(newOrder?.orderAttention?.attentionOfSupplyRequest);
                        }
                    }
                    res(newOrder);
                },
                error: e => {
                    this.casesState.setErrorUpdatingOrder(true);
                    rej(e);
                }
            });
        });
        return from(promise);
    }

    deleteOrderItems(items: Item[], orders: Order[]) {
        this.casesState.deleteOrderItems(items, orders);
    }

    /** Derivacion de caso */
    deriveCase(body: PostDerivedProviderBody, caseId: number): Observable<Case> {
        this.casesState.setLoadingCreatingCase(true);
        // Mapper from CreationalCase to PostCaseBody must be here
        const promise: Promise<Case> = new Promise((res, rej) => {
            this.casesService.postDerivation(body, caseId).pipe(
                finalize(() => this.casesState.setLoadingCreatingCase(false))
            ).subscribe({
                next: newCase => { this.casesState.addCase(newCase); res(newCase); },
                error: e => { this.casesState.setErrorCreatingCase(true); rej(e); }
            });
        });
        return from(promise);
    }

    /*VAT Category*/
    isLoadingGettingVatCategories$(): Observable<boolean> {
        return this.casesState.isLoadingGettingVatCategories$();
    }

    loadVatCategories(): Observable<VatCategory[]> {
        this.casesState.setLoadingGettingVatCategories(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getVatCategories().pipe(
                tap(body => this.casesState.setVatCategories(body)),
                tap(() => this.casesState.setLoadingGettingVatCategories(false)),
                tap((body) => console.log('request finished. Vat Categories:', body)),
            ).subscribe({
                next: response => { res(response); },
                error: e => {
                    this.casesState.setLoadingGettingVatCategories(false);
                    rej(e);
                },
            });
        });
        return from(promise);
    }

    getVatCategories$(): Observable<VatCategory[]> {
        return this.casesState.getVatCategories$();
    }

    /* PATIENTS */

    isLoadingGettingPatients$(): Observable<boolean> {
        return this.casesState.isLoadingGettingPatients$();
    }

    getPatients$(): Observable<Patient[]> {
        return this.casesState.getPatients$();
    }

    loadPatients(pfp?: PatientsQPS): Observable<Patient[]> {
        this.casesState.setLoadingGettingPatients(true);
        console.log('Loading request GET patients ', pfp);
        const promise: Promise<Patient[]> = new Promise((res, rej) => {
            this.casesService.getPatients(pfp).subscribe({
                next: patients => { this.casesState.setPatients(patients); res(patients); },
                error: e => { rej(e); },
                complete: () => this.casesState.setLoadingGettingPatients(false)
            })
        })
        return from(promise);
    }

    /* CASE ARRANGEMENTS */
    isLoadingGetCaseArrangements$(): Observable<boolean> {
        return this.casesState.isLoadingGetCaseArrangements$();
    }

    loadCaseArrangements(caseId: number): Observable<CaseArrangement[]> {
        this.casesState.setLoadingGetCaseArrangements(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getCaseArrangements(caseId).pipe(
                finalize(() => this.casesState.setLoadingGetCaseArrangements(false)),
            ).subscribe({
                next: arrangements => {
                    // Order by fromDate
                    for (let i in arrangements) {
                        arrangements[i].practiceArrangements = [...this.casesService.sortArrangementitem(arrangements[i].practiceArrangements)]
                    }
                    console.log("itemsOrdered: ", arrangements)
                    this.casesState.setCaseArrangements(arrangements)
                    res(arrangements)
                },
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }

    getCaseArrangements$(): Observable<CaseArrangement[]> {
        return this.casesState.getCaseArrangements$();
    }

    isLoadingAddCaseArrangement$(): Observable<boolean> {
        return this.casesState.isLoadingGettingVatCategories$();
    }

    addCaseArrangement(arrangement: PostAddCaseArrangementBody, caseId: number): Observable<CaseArrangement> {
        this.casesState.setLoadingAddCaseArrangement(true);

        const promise: Promise<CaseArrangement> = new Promise((res, rej) => {
            this.casesService.postCaseArrangement(caseId, arrangement).pipe(
                finalize(() => this.casesState.setLoadingAddCaseArrangement(false))
            ).subscribe({
                next: newArrangement => { this.casesState.addCaseArrangement(newArrangement); res(newArrangement); },
                error: e => { this.casesState.setErrorAddingCaseArrangement(true); rej(e); },
            });
        });
        return from(promise);
    }

    deleteCaseArrangement(caseArrangement: CaseArrangement): Observable<CaseArrangement> {
        this.casesState.setLoadingAddCaseArrangement(true);

        const promise: Promise<CaseArrangement> = new Promise((response, reject) => {
            this.casesService.deleteCaseArrangement(caseArrangement.caseArrangementId).pipe(
                finalize(() => this.casesState.setLoadingAddCaseArrangement(false))
            ).subscribe({
                next: delCaseArrangement => { this.casesState.deleteCaseArrangement(caseArrangement.id); response(delCaseArrangement); },
                error: e => reject(e)
            });
        });
        return from(promise);
    }

    // COMPANIES TYPE
    loadCompaniesType(qps?: CompaniesTypeQPS) {

        const promise: Promise<any> = new Promise((resolve, reject) => {

            this.casesState.setLoadingGetCompaniesType(true);

            this.casesService.getCompaniesType(qps).pipe(
                tap(body => this.casesState.setCompaniesType(body.data)),
                tap(() => this.casesState.setLoadingGetCompaniesType(false)),
                tap((body) => console.log('Request finished. CompaniesType: ', body)),
            ).subscribe({
                next: response => resolve(response.data),
                error: e => {
                    this.casesState.setLoadingGetCompaniesType(false);
                    reject(e)
                }
            })
        })
        return from(promise);
    }

    loadTaxZones(qps: TaxZonesQPS): Observable<TaxZone[]> {
        this.casesState.setLoadingGettingTaxZones(true);
        const promise: Promise<TaxZone[]> = new Promise((res, rej) => {
            this.casesService.getTaxZones(qps).subscribe({
                next: taxZones => { this.casesState.setTaxZones(taxZones); res(taxZones) },
                error: e => { rej(e) },
                complete: () => this.casesState.setLoadingGettingTaxZones(false)
            })
        });
        return from(promise);
    }

    getTaxZones$(): Observable<TaxZone[]> {
        return this.casesState.getTaxZones$();
    }

    setCase(acase: Case) {
        this.casesState.setCase(acase);
    }

    isShowCase$(): Observable<boolean> {
        return this.casesState.isShowCase$();
    }

    setShowCase(showCase: boolean) {
        this.casesState.setShowCase(showCase);
    }

    updatePatientCaseAndEntities(patientIdCase: number, patient: PatchPatientBody): Observable<PatchPatientBody> {

        this.casesState.setLoadingUpdatingPatientCaseAndEntities(true);

        const promise: Promise<Case> = new Promise((res, rej) => {
            this.casesService.patchPatientCaseAndEntities(patientIdCase, patient).pipe(
                finalize(() => this.casesState.setLoadingUpdatingPatientCaseAndEntities(false))
            ).subscribe({
                next: patientResponse => {
                    // Map entities patient TO case patient
                    this.casesState.setPatient(patientResponse);
                    res(patientResponse)
                },
                error: e => { rej(e) },
            });
        });
        return from(promise);
    }

    isLoadingUpdatingPatientCaseAndEntities$(): Observable<boolean> {
        return this.casesState.isLoadingUpdatingPatientCaseAndEntities$();
    }

    getProvisionalControlIndicatorValues$(): Observable<CasesIndicatorsValues> {
        return this.casesState.getProvisionalControlIndicatorValues$();
    }

    getProvisionalControlProvisionFees$() {
        return this.casesState.getProvisionalControlProvisionFees$();
    }

    getProvisionFeesIndicators$() {
        return this.casesState.getProvisionFeesIndicators$();
    }

    setProvisionFeesIndicators(indicators) {
        this.casesState.setProvisionFeesIndicators(indicators);
    }

    getOrderIndicators$() {
        return this.casesState.getOrderIndicators$();
    }

    setOrderIndicators(indicators) {
        this.casesState.setOrderIndicators$(indicators);
    }

    getProvisionalControlOrders$() {
        return this.casesState.getProvisionalControlOrders$();
    }

    isLoadingProvisionalControlIndicators$(): Observable<boolean> {
        return this.casesState.getLoadingProvisionalControlIndicators$();
    }

    // FIXME: delete
    loadProvisionalControlIndicators(provisionalControlIndicatorsQPS?: ProvisionalControlIndicatorsQPS, version?: string) {

        // this.httpCancelService.cancelPendingRequests();
        this.casesState.setLoadingProvisionalControlIndicators(true);

        const promise: Promise<CasesIndicatorsValues> = new Promise((res, rej) => {

            this.casesService.getProvisionalControlIndicatorValues(provisionalControlIndicatorsQPS, version).pipe(
                //takeUntil(this.httpCancelService.onCancelPendingRequests()),
                // tap(body => this.casesState.setPagination(body.pagination)),
                finalize(() => this.casesState.setLoadingProvisionalControlIndicators(false))
            ).subscribe({
                next: response => {
                    this.casesState.setMetaDataCases(provisionalControlIndicatorsQPS);
                    this.casesState.setProvisionalControlIndicatorValues(response.data);
                    res(response.data);
                    // this.casesState.setCases(indicators.cases);
                },
                error: e => { rej(e) }
            })
        });
        return from(promise);
    }

    loadProvisionFeesIndicators(provisionFeesIndicatorsQPS?: ProvisionFeesIndicatorsQPS, version?: string) {
        this.casesState.setLoadingProvisionFeesIndicators(true);

        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getProvisionFeesIndicators(provisionFeesIndicatorsQPS, version).pipe(
                finalize(() => this.casesState.setLoadingProvisionFeesIndicators(false))
            ).subscribe((indicators) => {
                this.casesState.setProvisionFeesIndicators(indicators);
            })
        })
        return from(promise);
    }

    loadProvisionalControlProvisionFees(provisionFeesQps?: ProvisionFeesIndicatorsQPS) {
        const promise: Promise<any> = new Promise((res, rej) => {
            this.setIsLoadingGettingProvisionalControlProvisionFees(true);
            this.casesService.getProvisionalControlProvisionFees(provisionFeesQps).subscribe((body) => {
                this.casesState.setPagination(body.pagination);
                this.casesState.setProvisionalControlProvisionFees$(body.data);
                this.setIsLoadingGettingProvisionalControlProvisionFees(false);
            })
        })
        return from(promise);
    }

    loadOrdersIndicators(qps, version?) {

        this.casesState.setLoadingOrdersIndicators(true);

        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getOrdersIndicators(qps, version).pipe(
                finalize(() => this.casesState.setLoadingOrdersIndicators(false))
            ).subscribe((indicators) => {
                this.casesState.setOrderIndicators$(indicators);
            })
        })
        return from(promise);
    }

    loadProvisionalControlOrders(qps) {
        const promise: Promise<any> = new Promise((res, rej) => {
            this.setIsLoadingGettingProvisionalControlOrders(true);
            this.casesService.getProvisionalControlOrders(qps).subscribe((body) => {
                this.casesState.setPagination(body.pagination);
                this.casesState.setProvisionalControlOrders$(body.data);
                this.setIsLoadingGettingProvisionalControlOrders(false);
            })
        })
        return from(promise);
    }

    setCases$(cases: Case[]) {
        this.casesState.setCases(cases);
    }

    setProvisionalControlIndicatorValues(provisionalControlIndicatorValues: CasesIndicatorsValues) {
        this.casesState.setProvisionalControlIndicatorValues(provisionalControlIndicatorValues)
    }

    getCaseArrangement(arrangementId: number): Arrangement {
        return this.casesState.getCaseArrangement(arrangementId);
    }

    getCaseArrangementPracticesByPractice(arrangementId: number, practiceId: number): ArrangementItem[] {
        return this.casesState.getCaseArrangementPracticesByPractice(arrangementId, practiceId)
    }

    getCaseDateFilters$(): Observable<CaseDateFilters> {
        return this.casesState.getCaseDateFilters$();
    }

    getCaseDateFilter(): CaseDateFilters {
        return this.casesState.getCaseDateFilters();
    }

    setCaseDateFilters(caseDateFilters: CaseDateFilters) {
        this.casesState.setCaseDateFilters(caseDateFilters);
    }

    isLoadingUpdatingCase$(): Observable<boolean> {
        return this.casesState.isLoadingUpdatingCase$();
    }

    setCaseArrangements(arrangements: CaseArrangement[]) {
        this.casesState.setCaseArrangements(arrangements);
    }


    /**
    * Endpoint to test
    * @returns users
    */
    loadServiceToTest() {
        const promise = new Promise((res, rej) => this.casesService.getServiceToTest().subscribe({
            next: res,
            error: rej
        }));
        return from(promise);
    }

    filterOrdersOnDateFilters(orders: Order[]) {
        this.casesState.filterOrdersToDisplayOnScreen(orders);
    }

    getOrdersDisplayedOnScreen$() {
        return this.casesState.getOrdersDisplayedOnScreen$()
    }

    generateOrderDeliveryNotePDF(deliveryNoteBody: DeliveryNotePDFBody) {
        const promise = new Promise((res, rej) => {
            this.casesService.generateOrderDeliveryNotePDF(deliveryNoteBody).subscribe(response => {
                res(response)
            })
        })
        return from(promise)
    }

    isLoadingProvisionFeesIndicators$(): Observable<boolean> {
        return this.casesState.isLoadingProvisionFeesIndicators$();
    }

    isLoadingOrdersIndicators$(): Observable<boolean> {
        return this.casesState.isLoadingOrdersIndicators$();
    }

    isLoadingGetEvolutionsPdf$():Observable<boolean>{
        return this.casesState.getLoadingEvolutionsPdf$();
    }

    setLoadingGetEvolutionsPdf(value:boolean){
        return  this.casesState.setLoadingEvolutionsPdf(value);
    }


    // this method make de request and update the state  TODO:
    loadgetEvolutionsPDF(evolutionsPdfQPS: EvolutionPdfQPS) {
        this.setLoadingGetEvolutionsPdf(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.casesService.getEvolutionsPDF(evolutionsPdfQPS).pipe(
                finalize(() =>   this.setLoadingGetEvolutionsPdf(false)),
            ).subscribe({
                next: response =>  res(response),
                error: e => rej(e)
            });
        });
        return from(promise);
    }
}
