import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { PostCaseBody } from 'src/app/core/services/cases.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { HealthInsuranceQPS, LocationsQPS, PatientsQPS, PatientsService } from 'src/app/core/services/patients.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Address } from 'src/app/shared/models/address';
import { Case } from 'src/app/shared/models/case';
import { Diagnosis } from 'src/app/shared/models/diagnosis';
import { Financier } from 'src/app/shared/models/financier';
import { VatCategory } from 'src/app/shared/models/vatCategory';
import { Location } from 'src/app/shared/models/location';
import { Operator } from 'src/app/shared/models/operator';
import { Patient } from 'src/app/shared/models/patient';
import { TypeDocument } from 'src/app/shared/models/typeDocument';
import Swal from 'sweetalert2';
import { ROUTES } from './../../../../core/enums/routes';
import { NomenclatorFacade } from 'src/app/abstraction/nomenclator.facade';
import { NomenclatorService } from 'src/app/core/services/nomenclator.service';

@Component({
    selector: 'app-case-creation',
    templateUrl: './case-creation.component.html',
    styleUrls: ['./case-creation.component.scss'],
})

export class CaseCreationComponent implements OnInit {
    caseForm: FormGroup;
    // // Default location to load when a patient is selected
    // locationsOnSelect: Location[];
    // public readonly TITLE_CASES_LIST = 'Creación de caso';
    // public readonly PATIENT = 'patient';
    // public readonly PATIENT_SEARCHING = 'patientSearching';
    // public readonly FINANCIER_INTERNAL_CODE = 'financierInternalCode';
    // public readonly FINANCIER_OPERATOR = 'financierOperator';
    // public readonly FINANCIER = 'financierId';
    // public readonly FINANCIER_SEARCHING = 'financierSearching';
    // public readonly OPERATOR = 'operatorId';
    // public readonly OPERATOR_SEARCHING = 'operatorSearching';
    // public readonly PATIENT_HEALTH_INSURANCE_SEARCHING = 'healthInsuranceSearching';
    // public readonly ENABLED_FINANCIER = 'enabledFinancier';
    // public readonly ENABLED_PROVIDER = 'enabledProvider';
    // public readonly DIAGNOSES = 'diagnoses';
    // public readonly DIAGNOSIS = 'id';
    // public readonly DIAGNOSIS_SEARCHING = 'diagnosisSearching';
    // public readonly OBSERVATIONS = 'observation';
    // public readonly VAT_CATEGORY = 'vatCategory';
    // public readonly VAT_CATEGORY_SEARCHING = 'vatCategorySearching';
    // hintSelectPatient = '';
    // enabledProviderDefault = true;
    // enabledFinancierDefault = true;
    // // public readonly PATIENT_ID = 'id';
    // public readonly PATIENT_SEARCH = 'patientSearch';
    // public readonly PATIENT_TYPEDOCUMENT = 'typeDocument';
    // public readonly PATIENT_DOCUMENTNUMBER = 'documentNumber';
    // public readonly PATIENT_EMAIL = 'email';
    // public readonly PATIENT_NAME = 'name';
    // public readonly PATIENT_SURNAME = 'surname';
    // public readonly PATIENT_PHONENUMBER = 'phoneNumber';
    // public readonly PATIENT_ADDRESS = 'address';
    // public readonly PATIENT_ADDRESS_FLAT = 'flat';
    // public readonly PATIENT_ADDRESS_FLATEXTRA = 'flatExtra';
    // public readonly PATIENT_ADDRESS_FLOOR = 'floor';
    // public readonly PATIENT_ADDRESS_STREET = 'street';
    // public readonly PATIENT_ADDRESS_STREETNUMBER = 'streetNumber';
    // public readonly PATIENT_ADDRESS_LOCATION = 'location';
    // public readonly PATIENT_ADDRESS_LOCATION_SEARCHING = 'locationSearching';
    // public readonly PATIENT_ALLERGIES = 'allergies'; // Today impact in the Case and not in the Patient - WATCH!!!

    // public readonly PATIENT_BIRTHDATE = 'birthDate';
    // public readonly PATIENT_GENDER = 'gender';
    // public readonly PATIENT_AFFILIATIONNUMBER = 'affiliationNumber';
    // public readonly PATIENT_HEALTHINSURANCE = 'healthInsuranceId';

    // isLoadingCreatingCase$: Observable<boolean>;
    // isLoadingGettingOperators$: Observable<boolean>;
    // isLoadingGettingFinanciers$: Observable<boolean>;
    // isLoadingGettingPatients$: Observable<boolean>;
    // isLoadingGettingDiagnoses$: Observable<boolean>;
    // isLoadingGettingTypeDocuments$: Observable<boolean>;
    // isLoadingGettingHealthInsurance$: Observable<boolean>;
    // isLoadingGettingVatCategories$: Observable<boolean>;
    // _operators: Subscription;
    // _financiers: Subscription;
    // _typeDocuments: Subscription;
    // _diagnoses: Subscription;
    // _vatCategories: Subscription;
    // genders$: Observable<any>;
    // operators: Operator[];
    // financiers: Financier[];
    // diagnoses: Diagnosis[];
    // typeDocuments: TypeDocument[];
    // vatCategories: VatCategory[];

    // get financier() {
    //     return this.caseForm.get(this.FINANCIER).value;
    // }
    // get patient() {
    //     return this.caseForm.get(this.PATIENT).value;
    // }
    // get patientTypeDocument() {
    //     return this.patientFormGroup.get(this.PATIENT_TYPEDOCUMENT).value;
    // }
    // get patientDocumentNumber() {
    //     return this.patientFormGroup.get(this.PATIENT_DOCUMENTNUMBER).value;
    // }
    // get patientName() {
    //     return this.patientFormGroup.get(this.PATIENT_NAME).value;
    // }
    // get patientGender() {
    //     return this.patientFormGroup.get(this.PATIENT_GENDER).value;
    // }
    // get patientBirthdate() {
    //     return this.patientFormGroup.get(this.PATIENT_BIRTHDATE).value;
    // }
    // get patientEmail() {
    //     return this.patientFormGroup.get(this.PATIENT_EMAIL).value;
    // }
    // get patientSurname() {
    //     return this.patientFormGroup.get(this.PATIENT_SURNAME).value;
    // }
    // get patientPhonenumber() {
    //     return this.patientFormGroup.get(this.PATIENT_PHONENUMBER).value;
    // }
    // get patientStreet() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_STREET).value;
    // }
    // get patientStreetNumber() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_STREETNUMBER).value;
    // }
    // get patientFlat() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_FLAT).value;
    // }
    // get patientFlatExtra() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_FLATEXTRA).value;
    // }
    // get patientFloor() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_FLOOR).value;
    // }
    // get affiliationNumber() {
    //     return this.patientFormGroup.get(this.PATIENT_AFFILIATIONNUMBER).value;
    // }
    // get healthInsurance() {
    //     return this.patientFormGroup.get(this.PATIENT_HEALTHINSURANCE).value;
    // }
    // get patientLocation() {
    //     return this.addressFormGroup.get(this.PATIENT_ADDRESS_LOCATION).value;
    // }

    // get patientAllergies() {
    //     return this.caseForm.get(this.PATIENT_ALLERGIES).value;
    // }

    // get financierInternalCode() {
    //     return this.caseForm.get(this.FINANCIER_INTERNAL_CODE).value;
    // }
    // get financierOperator() {
    //     return this.caseForm.get(this.FINANCIER_OPERATOR).value;
    // }
    // get operator() {
    //     return this.caseForm.get(this.OPERATOR).value;
    // }

    // get enabledFinancier() {
    //     return this.caseForm.get(this.ENABLED_FINANCIER).value;
    // }

    // set setEnabledFinancier(value: boolean) {
    //     // return this.evolutionForm.get(this.EVOLUTION_TYPE);
    //     this.caseForm.controls[this.ENABLED_FINANCIER].setValue(value);
    // }

    // get enabledProvider() {
    //     return this.caseForm.get(this.ENABLED_PROVIDER).value;
    // }

    // set setEnabledProvider(value: boolean) {
    //     // return this.evolutionForm.get(this.EVOLUTION_TYPE);
    //     this.caseForm.controls[this.ENABLED_PROVIDER].setValue(value);
    // }

    // get diagnosesFormArray(): FormArray {
    //     return this.caseForm.get(this.DIAGNOSES) as FormArray;
    // }

    // // Address form
    // get addressFormGroup() {
    //     return this.caseForm.get(this.PATIENT).get(this.PATIENT_ADDRESS);
    // }

    // // Patient form
    // get patientFormGroup() {
    //     return this.caseForm.get(this.PATIENT);
    // }


    // createDiagnosis(): FormGroup {
    //     return this.formBuilder.group({
    //         [this.DIAGNOSIS]: ['', CustomValidators.required('Diagnóstico requerido')],
    //         [this.OBSERVATIONS]: [''],
    //         [this.DIAGNOSIS_SEARCHING]: [''],
    //     });
    // }

    // addDiagnosis(): void {
    //     this.diagnosesFormArray.push(this.createDiagnosis());
    // }

    // removeDiagnosis(index: number): void {
    //     this.diagnosesFormArray.removeAt(index);
    // }

    constructor() {}
    //     private formBuilder: FormBuilder,
    //     private casesFacade: CasesFacade,
    //     private entitiesFacade: EntitiesFacade,
    //     public router: Router,
    //     private generalService: GeneralService,
    //     private patientsService: PatientsService,
    //     private nomenclatorFacade: NomenclatorFacade,
    //     private nomenclatorService: NomenclatorService
    // ) {
    //     this.isLoadingCreatingCase$ = this.casesFacade.isLoadingCreatingCase$();
    //     this.isLoadingGettingOperators$ = this.entitiesFacade.isLoadingGettingOperators$();
    //     this.isLoadingGettingFinanciers$ = this.entitiesFacade.isLoadingGettingFinanciers$();
    //     this.isLoadingGettingPatients$ = this.entitiesFacade.isLoadingGettingPatients$();
    //     this.isLoadingGettingDiagnoses$ = this.casesFacade.isLoadingGettingDiagnoses$();
    //     this.isLoadingGettingTypeDocuments$ = this.entitiesFacade.isLoadingGettingTypeDocuments$();
    //     this.isLoadingGettingHealthInsurance$ = this.entitiesFacade.isLoadingGettingHealthInsurance$();
        // this.isLoadingGettingVatCategories$ = this.casesFacade.isLoadingGettingVatCategories$();

    ngOnInit(): void {
        // this.generalService.sendMessage(this.TITLE_CASES_LIST);
        // this.entitiesFacade.loadOperators();
        // this.entitiesFacade.loadTypeDocuments();
        // this.entitiesFacade.loadFinanciers();
        // // this.casesFacade.loadVatCategories();
        // this.genders$ = this.entitiesFacade.getGenders$();
        // this._operators = this.entitiesFacade.getOperators$().subscribe(operators => this.operators = operators);
        // this._financiers = this.entitiesFacade.getFinanciers$().subscribe(financiers => this.financiers = financiers);
        // this._diagnoses = this.casesFacade.getDiagnoses$().subscribe(diagnoses => this.diagnoses = diagnoses);
        // this._typeDocuments = this.entitiesFacade.getTypeDocuments$().subscribe(typeDocuments => this.typeDocuments = typeDocuments);
        // // this._vatCategories = this.casesFacade.getVatCategories$()
        // //   .subscribe(vatCategories => {
        // //     this.vatCategories = vatCategories;
        // //     console.log("Vat Categories: !", this.vatCategories);
        // //   });

        // this.caseForm = this.createCaseForm();
    }

    // ngOnDestroy(): void {
    //     this._operators.unsubscribe();
    //     this._financiers.unsubscribe();
    //     this._diagnoses.unsubscribe();
    //     this._typeDocuments.unsubscribe();
    //     //this._vatCategories.unsubscribe();
    // }

    // calculateIdPatient(element: Patient) {
    //     return element ? element.id : null;
    // }

    // selected(patient: Patient | string) {
    //     // New Patient option selected
    //     this.patientFormGroup.reset();
    //     console.log('Paciente: ', patient);
    //     if (this.patientFormGroup.disabled) {
    //         this.patientFormGroup.enable();
    //     }
    //     if (typeof (patient) == 'string') {
    //         this.patientFormGroup.get(this.PATIENT_TYPEDOCUMENT).enable();
    //         this.patientFormGroup.get(this.PATIENT_DOCUMENTNUMBER).enable();
    //         this.hintSelectPatient = 'Complete el siguiente formulario para registrar un nuevo paciente';
    //     } else {
    //         this.hintSelectPatient = 'Confirme los datos del paciente seleccionado y modifíquelos en caso de ser necesario';
    //         this.locationsOnSelect = [patient.address.location];
    //         // Patient selected
    //         patient = patient as Patient;
    //         this.patientFormGroup.get(this.PATIENT_TYPEDOCUMENT).disable();
    //         this.patientFormGroup.get(this.PATIENT_DOCUMENTNUMBER).disable();
    //         this.patientFormGroup.patchValue({
    //             [this.PATIENT_TYPEDOCUMENT]: patient?.typeDocument?.id.toString(),
    //             [this.PATIENT_DOCUMENTNUMBER]: patient?.documentNumber,
    //             [this.PATIENT_NAME]: patient?.name,
    //             [this.PATIENT_GENDER]: patient?.gender,
    //             [this.PATIENT_BIRTHDATE]: patient?.birthDate,
    //             [this.PATIENT_EMAIL]: patient?.email,
    //             [this.PATIENT_SURNAME]: patient?.surname,
    //             [this.PATIENT_PHONENUMBER]: patient?.phoneNumber,
    //             [this.PATIENT_ADDRESS]: {
    //                 [this.PATIENT_ADDRESS_FLAT]: patient?.address?.flat,
    //                 [this.PATIENT_ADDRESS_STREET]: patient?.address?.street,
    //                 [this.PATIENT_ADDRESS_STREETNUMBER]: patient?.address?.streetNumber,
    //                 [this.PATIENT_ADDRESS_FLOOR]: patient?.address?.floor,
    //                 [this.PATIENT_ADDRESS_FLATEXTRA]: patient?.address?.flatExtra,
    //                 // TODO: Adapt location dynamic
    //                 [this.PATIENT_ADDRESS_LOCATION]: patient?.address?.location?.id.toString(),
    //             }
    //         });
    //     }
    //     this.caseForm.controls[this.PATIENT_ALLERGIES].enable();
    //     this.caseForm.controls[this.PATIENT_ALLERGIES].setValue(''); // Today impact in the Case and not in the Patient - WATCH!!!
    // }

    // calculateNamePatient(element: Patient) {
    //     return element ? element.surname + ' ' + element.name : null;
    //     // const dni = element && !!element.documentNumber ? element.documentNumber : null;
    //     // return name + (!!dni ? ` \xa0\xa0\xa0 • \xa0\xa0\xa0 DNI: ${dni}` : '');
    // }

    // // calculateNameOperator(operator: Operator) {
    // //   return operator ? operator.name : null;
    // // }

    // // calculateIdOperator(operator: Operator) {
    // //   return operator ? operator.id : null;
    // // }

    // calculateNameLocation(location: Location) {
    //     let string = '';
    //     string += location ? location.name : '';
    //     string += location && location.province && location.province.name ? ', ' + location.province.name : '';
    //     return string;
    // }

    // calculateIdTypeDocument(typeDocument: TypeDocument) {
    //     return typeDocument ? typeDocument.id : null;
    // }

    // // calculateNameFinancier(financier: Financier) {
    // //   return financier ? financier.name : null;
    // // }

    // // calculateIdFinancier(financier: Financier) {
    // //   return financier ? financier.id : null;
    // // }

    // // calculateNameDiagnosis(diagnosis: Diagnosis) {
    // //   return diagnosis ? diagnosis.name : null;
    // // }

    // // calculateIdDiagnosis(diagnosis: Diagnosis) {
    // //   return diagnosis ? diagnosis.id : null;
    // // }

    // createCaseForm(casee?: Partial<Case>): FormGroup {
    //     return this.formBuilder.group({
    //         [this.PATIENT_SEARCH]: [''],
    //         [this.PATIENT_SEARCHING]: [''],
    //         [this.FINANCIER_INTERNAL_CODE]: [''],
    //         [this.FINANCIER_OPERATOR]: [''],
    //         [this.OPERATOR]: ['', [CustomValidators.required('Operador requerido')]],
    //         [this.OPERATOR_SEARCHING]: [''],
    //         [this.FINANCIER]: ['', [CustomValidators.required('Financiador requerido')]],
    //         [this.FINANCIER_SEARCHING]: [''],
    //         [this.ENABLED_FINANCIER]: [this.enabledFinancierDefault],
    //         [this.ENABLED_PROVIDER]: [this.enabledProviderDefault],
    //         [this.PATIENT]: this.createPatientForm(casee && casee.patient ? casee.patient : null),
    //         [this.DIAGNOSES]: this.formBuilder.array([this.createDiagnosis()]),
    //         [this.PATIENT_ALLERGIES]: [{ value: '', disabled: true }, [CustomValidators.maxLength(1024, 'El máximo de carácteres son 1024')]]
    //     });
    // }

    // createPatientForm(patient?: Patient): FormGroup {
    //     const form = this.formBuilder.group({
    //         // [this.PATIENT_ID]: [patient && patient.id ? patient.id : ''],
    //         [this.PATIENT_AFFILIATIONNUMBER]: [''],
    //         [this.PATIENT_HEALTHINSURANCE]: [''],
    //         [this.PATIENT_HEALTH_INSURANCE_SEARCHING]: [''],
    //         [this.PATIENT_TYPEDOCUMENT]: [
    //             patient && patient.typeDocument
    //                 ? patient.typeDocument.id
    //                 : '',
    //             [CustomValidators.required('Tipo de documento requerido requerido')]
    //         ],
    //         [this.PATIENT_DOCUMENTNUMBER]: [
    //             patient && patient.documentNumber
    //                 ? patient.documentNumber
    //                 : '',
    //             [CustomValidators.required('Nro de documento requerido')]
    //         ],
    //         [this.PATIENT_EMAIL]: [patient && patient.email ? patient.email : '',],
    //         [this.PATIENT_GENDER]: [patient && patient.gender ? patient.gender : ''],
    //         [this.PATIENT_BIRTHDATE]: [patient && patient.birthDate ? patient.birthDate : ''],
    //         [this.PATIENT_NAME]: [patient && patient.name ? patient.name : '', [CustomValidators.required('Nombre requerido')]],
    //         [this.PATIENT_SURNAME]: [patient && patient.surname ? patient.surname : '', [CustomValidators.required('Apellido requerido')]],
    //         [this.PATIENT_PHONENUMBER]: [patient && patient.phoneNumber ? patient.phoneNumber : ''],
    //         [this.PATIENT_ADDRESS]: this.createAddressForm(patient && patient.address ? patient.address : null)
    //     });
    //     form.disable();
    //     return form;
    // }

    // createAddressForm(address?: Address): FormGroup {
    //     return this.formBuilder.group({
    //         [this.PATIENT_ADDRESS_STREET]: [
    //             address && address.street ? address.street : '',
    //             [CustomValidators.required('Campo obligatorio')]
    //         ],
    //         [this.PATIENT_ADDRESS_STREETNUMBER]: [
    //             address && address.streetNumber ? address.streetNumber : '',
    //             [CustomValidators.required('Campo obligatorio')]
    //         ],
    //         [this.PATIENT_ADDRESS_FLOOR]: [
    //             address && address.floor ? address.floor : '',
    //         ],
    //         [this.PATIENT_ADDRESS_FLAT]: [
    //             address && address.flat ? address.flat : '',
    //         ],
    //         [this.PATIENT_ADDRESS_FLATEXTRA]: [
    //             address && address.flatExtra ? address.flatExtra : ''
    //         ],
    //         [this.PATIENT_ADDRESS_LOCATION]: [
    //             address && address.location ? address.location : '',
    //             [CustomValidators.required('Campo obligatorio')]
    //         ],
    //         [this.PATIENT_ADDRESS_LOCATION_SEARCHING]: [''],
    //     });
    // }

    // onSubmitCaseForm() {
    //     const body: PostCaseBody = JSON.parse(JSON.stringify(this.caseForm.getRawValue()));
    //     delete body[this.PATIENT_SEARCHING];
    //     delete body.patient.address[this.PATIENT_ADDRESS_LOCATION_SEARCHING];
    //     delete body[this.FINANCIER_SEARCHING];
    //     delete body[this.OPERATOR_SEARCHING];
    //     delete body[this.PATIENT_SEARCH];
    //     delete body.patient[this.PATIENT_HEALTH_INSURANCE_SEARCHING];

    //     for (const diagnosis of body.diagnoses) {
    //         delete diagnosis[this.DIAGNOSIS_SEARCHING];
    //     }
    //     body.patient.typeDocument = {
    //         id: this.caseForm['controls']['patient']['controls'].typeDocument.value
    //     };
    //     body.patient.address.location = {
    //         id: this.caseForm['controls']['patient']['controls'].address['controls'].location.value
    //     };
    //     console.log('body: ', body);
    //     console.log('caseForm: ', this.caseForm);

    //     (this.casesFacade.addCase(body) as Observable<Case>).subscribe(acase => {
    //         this.router.navigate([ROUTES.CASES, acase.id]);

    //         const swalWithCustomizeButtons = Swal.mixin({
    //             customClass: {
    //                 confirmButton: 'btnSwalConfirm',
    //                 cancelButton: 'btnSwalCancel'
    //             },
    //             buttonsStyling: true
    //         })

    //         swalWithCustomizeButtons.fire({
    //             title: 'Caso creado correctamente',
    //             icon: 'success',
    //         });
    //     });
    // }

    // getDiagnosesByName = (value: string) => this.casesFacade.getDiagnosesFiltered$(value);

    // loadPatientsByFullName = (value: string) => {
    //     const pfp: PatientsQPS = {
    //         active: true
    //     };
    //     if (this.generalService.stringIsNumber(value)) {
    //         pfp['document_number_like'] = value;
    //     } else {
    //         pfp['fullName_like'] = value;
    //     }
    //     return this.entitiesFacade.loadPatients(pfp);
    // }

    // loadLocationsByName = (value: string) => {
    //     const lfp: LocationsQPS = {
    //         name_like: value
    //     };
    //     return this.entitiesFacade.loadLocations(lfp);
    // }

    // loadHealthInsuranceByName = (value: string) => {
    //     const hqps: HealthInsuranceQPS = {
    //         name_like: value
    //     };
    //     return this.entitiesFacade.loadHealthInsurance(hqps);
    // }


    // calculateName(element: VatCategory) {
    //     return element ? element.description : null;
    // }

    // calculateId(element: VatCategory) {
    //     return element ? element.id : null;
    // }


    // getDiagnosesFormGroup(index: number): FormGroup {
    //     return this.diagnosesFormArray.at(index) as FormGroup;
    // }

    // getAddressFormGroup(): FormGroup {
    //     return this.addressFormGroup as FormGroup;
    // }

    // getPatientFormGroup(): FormGroup {
    //     return this.patientFormGroup as FormGroup;
    // }
}
