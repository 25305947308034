import { Component, Output, EventEmitter, Input, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MyDateAdapter } from 'MyDateAdapter';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MyDateAdapter }, // It's mandatory, NO delete -> set first day MONDAY
    ]
})
export class DatePickerComponent extends FormElementComponent {

    @Input() clearable = false;
    @Input() min!: Date;
    @Input() max!: Date;
    @Input() openCalendar!: boolean; // Undefined by default
    @Input() togglePicker = false;
    @Input() startDate!: Date;
    @Input() override disabled!: boolean;
    @Input() override readonly!: boolean; // Undefined by default

    @Output() onDateChange = new EventEmitter;
    @Output() isCalendarOpen = new EventEmitter<boolean>();

    @ViewChild('picker', { static: false }) picker!: MatDatepicker<MyDateAdapter>;

    date = moment();
    selDate: string = '';

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['openCalendar']?.currentValue) {
            this.picker.open();
            this.isCalendarOpen.emit(true);
        }
    }

    onClickDateChange(event: any) {
        this.onDateChange.emit(event);
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        this.selDate = '';
        this.date = moment(event.value);

        if (!moment(this.date).isValid()) {
            this.selDate = this.date.format('DD'); // Automatic message from moment if date is invalid
        }
    }

    onClose(args: any): void {
        args.preventDefault();
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }

    hasError(errorType: string): boolean {
        const control = this.form.get(this.name);
        return control && control.hasError(errorType) && (control.dirty || control.touched);
    }
}
