<div class="flex-row align-center-none">
    <div class="flex-row align-start-none flex ms-3">
        <!-- View only - Display -->
        <ng-container *ngIf="showOnlyView;">
            <!-- Body -->
            <div class="flex-column gap-1-5em">
                <!-- Agreement -->
                <div class="flex-row">
                    <!-- Agreement -->
                    <div class="flex-column">
                        <span class="subtitle-2 text-secondary-darker mbs-1">Acuerdo prestacional</span>
                        <span class="subtitle-2 text-secondary">{{ this.agreementSelected.provider.fullName }}</span>
                    </div>
                </div>
                <!-- Body -->
                <div class="flex-row align-center-center gap-1-5em">
                    <div class="flex-column align-center-start gap-1-5em">
                        <div class="flex-column">
                            <span class="subtitle-2 text-secondary-darker mbs-1">Fecha</span>
                            <span class="subtitle-2 text-secondary">{{ this.attentionFromDateCast }}</span>
                        </div>
                        <div class="flex-column align-center-start">
                            <span class="subtitle-2 text-secondary-darker mbs-1">Horario</span>
                            <span class="subtitle-2 text-secondary">{{ this.attentionHourFromToCast }}</span>
                        </div>
                    </div>
                    <div class="flex-column align-center-start gap-1-5em">
                        <div class="flex-column">
                            <span class="subtitle-2 text-secondary-darker mbs-1">Estado de la atención</span>
                            <span class="subtitle-2 text-secondary">{{ this.attention.state.name }}</span>
                        </div>
                        <div class="flex-column align-center-start">
                            <span class="subtitle-2 text-secondary-darker mbs-1">Cantidad de horas</span>
                            <span class="subtitle-2 text-secondary">{{ this.attentionDurationCast }}hs</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Edit dates -->
        <ng-container *ngIf="showEditAttention;">
            <form [formGroup]="editForm" class="flex-column align-start-none pls-3 prs-3 gap-1em">
                <!-- Provider name -->
                <span class="subtitle-2 text-primary-darker">{{ agreementSelected.provider.fullName }}</span>
                <div class="flex-row align-start-center" style="gap: 0.6% !important;">
                    <!-- Left column -->
                    <div class="flex-column align-start-none">
                        <!-- Date -->
                        <div class="flex-column">
                            <span class="subtitle-3 text-primary-darker">Fecha</span>
                            <app-date-picker [name]="this.DATE_FROM" [form]="this.editForm" [min]="this.minDate"
                                [max]="this.maxDate"
                                style="max-width: 150px;">
                            </app-date-picker>
                        </div>
                        @if (!!this.attention.provisionFeeSelected && this.attention.provisionFeeSelected.practice?.restriction?.requiredTime) {
                            <div class="flex-row"> <!-- style="max-width: 200px;" -->
                                <div class="flex-column"> <!-- flex-20 -->
                                    <span class="subtitle-3 text-primary-darker">Ingreso</span>
                                    <app-text-input [form]="this.editForm" [selectAllOnClick]="true"
                                        (changes)="validateTimeValid()" [name]="this.TIME_FROM"
                                        (keydown.enter)="$event.preventDefault()"
                                        style="max-width: 110px">
                                    </app-text-input>
                                </div>
                                <div class="flex-row align-start-end gap-0-5 mls-1">
                                    <div class="flex-column">
                                        <span class="subtitle-3 text-primary-darker">Salida</span>
                                        <app-text-input [form]="this.editForm" [selectAllOnClick]="true"
                                            (changes)="changeHourFinish()" [name]="this.TIME_TO"
                                            (keydown.enter)="$event.preventDefault()"
                                            style="max-width: 110px">
                                        </app-text-input>
                                    </div>
                                    @if(isNextDay) { <span class="subtitle-5 text-primary-darker mbs-4">+1 día</span> }
                                </div>
                            </div>
                        }
                    </div>
                    <!-- Right column -->
                    <div class="flex-column align-center-none">
                        <!-- Attention state -->
                        <div class="flex-row">
                            <div class="flex-column"
                                *ngIf="!!this.attention.provisionFeeSelected && this.attention.provisionFeeSelected.practice?.restriction?.requiredTime">
                                <span class="subtitle-3 text-primary-darker">Estado</span>
                                <app-text-input [name]="this.STATE_ATTENTION" [form]="this.editForm">
                                </app-text-input>
                            </div>
                        </div>
                        <!-- Cantidad de horas -->
                        <div class="flex-row">
                            <div class="flex-40 flex-column"
                                *ngIf="!!this.attention.provisionFeeSelected && this.attention.provisionFeeSelected.practice?.restriction?.requiredTime">
                                <span class="subtitle-3 text-primary-darker">Cant. horas*</span>
                                <app-number-input (change)="this.durationChanged()" [name]="this.QUANTITY"
                                    [form]="this.editForm"
                                    [money]='false' [placeholder]="'Horas'" [minValue]="0">
                                </app-number-input>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Check-in / check-out  -->
                <div *ngIf="!!this.attention?.checkIn && !!this.attention?.checkOut"
                    class="flex-row align-start-center gap-1em">
                    <span class="text-secondary-darker">• Check In : {{this.attention.checkIn | date: 'HH:mm dd/MM/yyyy' }}</span>
                    <span class="text-secondary-darker">• Check Out : {{this.attention.checkOut | date: 'HH:mm dd/MM/yyyy' }}</span>
                </div>
                <!-- BOTTOM BUTTONS -->
                <div class="mts-5 flex-row align-space-around-center full-width">
                    <button mat-stroked-button [color]="'primary'" (click)="onClickCloseDialog()"
                        [disabled]="loadingEditAttention">
                        <span *ngIf="!loadingEditAttention; else loadingConfirm">CANCELAR</span>
                    </button>
                    <button mat-flat-button [color]="'primary'"
                        [disabled]="!this.editForm.valid || loadingEditAttention" (click)="onEditAttention()">
                        <span *ngIf="!loadingEditAttention; else loadingConfirm">CONFIRMAR</span>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- Replace agreement -->
        <ng-container *ngIf="showReplaceAgreement;">
            <form [formGroup]="editForm" class="flex-column align-start-none pls-3 prs-3">
                <div class="flex-column align-start-none gap-2%">
                    <!-- FromDate Hour IN/OUT Time Attention state -->
                    <div class="flex-row align-start-center gap-3em">
                        <span class="subtitle-2 text-primary-darker">{{ this.attentionFromDateCast }}</span>
                        <span class="subtitle-2 text-primary-darker">{{ this.attentionHourFromToCast }}</span>
                        <span class="subtitle-2 text-primary-darker">{{ this.attentionDurationCast }}hs</span>
                        <span class="subtitle-2 text-primary-darker">{{ this.attention.state.name }}</span>
                    </div>
                    <!-- Select agreements -->
                    <div class="flex-column mts-2">
                        <span class="subtitle-3 text-primary-darker">Reemplazar acuerdo prestacional</span>
                        <app-select-input [form]="this.editForm" [name]="this.AGREEMENT"
                            [elements]="attention.agreements" [calculateId]="this.calculateAgreementId"
                            [calculateName]="this.calculateAgreementName"
                            [defaultValue]="this.agreementAttentionDefault.toString()"
                            (selected)="this.onSelectedAgreement($event)">
                        </app-select-input>
                    </div>
                    <!-- Repeat logic -->
                    <ng-container *ngIf="this.showRadiobuttons">
                        <div class="flex-column align-start-none gap-2%">
                            <!-- Radiobuttons -->
                            <div class="flex-column"
                                [ngStyle]="{'border-right': this.radiobuttonsDisabled ? '1px solid #1b0c3d' : '', 'padding-right': this.radiobuttonsDisabled ? '0.5em' : ''}">
                                <mat-radio-group class="flex-column" formControlName="radioButton"
                                    [disabled]="this.radiobuttonsDisabled" (click)="$event.stopPropagation()"
                                    name="radioButton">
                                    <mat-radio-button #buttonForward class="subtitle-3 text-primary-darker"
                                        [checked]="validateCheckTypeToUpdateForward()" [value]="'forward'"
                                        (click)="checkState(buttonForward)">Este y los que siguen</mat-radio-button>
                                    <mat-radio-button #buttonDate class="subtitle-3 text-primary-darker mts-1"
                                        [checked]="validateCheckTypeToUpdateDate()" [value]="'date'"
                                        (click)="checkState(buttonDate)">Seleccionar desde y hasta</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <!-- DATES -->
                            <div class="flex-row align-space-evenly-center"
                                [hidden]="!(selectionToUpdateValue=='date') || this.radiobuttonsDisabled">
                                <!-- DATE_FROM_UPDATE -->
                                <div class="flex-column">
                                    <span class="subtitle-3 text-primary-darker">Desde</span>
                                    <app-date-picker [name]="this.DATE_FROM_UPDATE" [form]="this.editForm"
                                        [min]="this.minDate" [max]="this.maxDate">
                                    </app-date-picker>
                                </div>
                                <!-- DATE_TO_UPDATE -->
                                <div class="flex-column">
                                    <span class="subtitle-3 text-primary-darker">Hasta</span>
                                    <app-date-picker [name]="this.DATE_TO_UPDATE" [form]="this.editForm"
                                        [min]="this.minDate" [max]="this.maxDate">
                                    </app-date-picker>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- BOTTOM BUTTONS -->
                <div class="mts-5 flex-row align-space-around-start full-width">
                    <button mat-stroked-button [color]="'primary'" (click)="onClickCloseDialog()"
                        [disabled]="loadingEditAttention">
                        <span *ngIf="!loadingEditAttention; else loadingConfirm">
                            CANCELAR
                        </span>
                    </button>
                    <button mat-flat-button [color]="'primary'"
                        [disabled]="!this.editForm.valid || loadingEditAttention" (click)="onEditAttention()">
                        <span *ngIf="!loadingEditAttention; else loadingConfirm">
                            CONFIRMAR
                        </span>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
    <!-- Actions -->
    <ng-container *ngTemplateOutlet="sidebarActions"></ng-container>
</div>

    <ng-template #loadingConfirm>
        <mat-icon>
            <mat-spinner color="accent" diameter="20">
            </mat-spinner>
        </mat-icon>
    </ng-template>

    <!-- Vertical actions -->
    <ng-template #sidebarActions>
        <div class="flex-column align-start-start div-actions flex-1-1-auto">
            <!-- Edit button -->
            <button *ngIf="this.attentionsService.isEditable(attention) && isButtonEditEnabled"
                class="mat-icon-button-22x22" id="btn_edit_attention_enabled" mat-icon-button [matTooltip]="'Editar'"
                (click)="this.setEditMode();">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'Edit-enabled-primary-22x22'"></mat-icon>
            </button>
            <button *ngIf="!this.attentionsService.isEditable(attention) || !isButtonEditEnabled"
                class="mat-icon-button-22x22" id="btn_edit_attention_disabled" mat-icon-button [matTooltip]="'Editar'">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'Edit-disabled-22x22'"></mat-icon>
            </button>
            <!-- Delete button -->
            <button *ngIf="this.attentionsService.isDeletable(attention) && isButtonDeleteEnabled" class=""
                id="btn_delete_attention_enabled" mat-icon-button [matTooltip]="'Eliminar'"
                (click)="this.setDeleteMode()">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'Delete-enabled-primary-22x22'"></mat-icon>
            </button>
            <button *ngIf="!this.attentionsService.isDeletable(attention) || !isButtonDeleteEnabled" class=""
                id="btn_delete_attention_disabled" mat-icon-button [matTooltip]="'Eliminar'">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'Delete-disabled-22x22'"></mat-icon>
            </button>
            <!-- Replace agreement button -->
            <button
                *ngIf="this.attentionsService.isAgreementReplaceable(attention) && this.attention.agreements.length >= 2 && isButtonReplaceAgreementEnabled"
                class="" id="btn_changeagreement_attention_enabled" mat-icon-button [matTooltip]="'Reemplazar acuerdo'"
                (click)="this.setReplaceAgreementMode()">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'ReplaceAgreement-enabled-primary-22x22'"></mat-icon>
            </button>
            <button
                *ngIf="!this.attentionsService.isAgreementReplaceable(attention) || this.attention.agreements.length <= 1 || !isButtonReplaceAgreementEnabled"
                class="" id="btn_changeagreement_attention_disabled" mat-icon-button
                [matTooltip]="'Reemplazar acuerdo'">
                <mat-icon class="mat-icon-22x22" [svgIcon]="'ReplaceAgreement-disabled-22x22'"></mat-icon>
            </button>
        </div>
    </ng-template>
