<div class="flex-100 flex-column">
    <div class="flex-100 separator separator-center mys-3">
        <span class="mx-2 text-primary">
            Filtros
        </span>
    </div>
    <mat-form-field>
        <mat-chip-grid #providerChipList>
            <mat-chip-row *ngFor="let provider of providers" (removed)="removeProvider(provider)" class="bg-primary-lighter text-primary">
                {{this.entitiesFacade.getProvidersFullName(provider)}}
                <mat-icon matChipTrailingIcon matTooltip="Cargar todos los pacientes y acuerdos del prestador"
                    class="text-primary" (click)="onClickReloadAgreementByProvider($event,provider)">refresh
                </mat-icon>
                <mat-icon matChipRemove class="text-primary">cancel</mat-icon>
            </mat-chip-row>
            <input [matChipInputFor]="providerChipList" placeholder="Buscar prestadores" #providerInput [formControl]="providerCtrl"
                [matAutocomplete]="providersAuto">
        </mat-chip-grid>
        <mat-autocomplete #providersAuto="matAutocomplete" (optionSelected)="selectedProvider($event)">
            <mat-option *ngFor="let provider of filteredProviders" [value]="provider">
                {{this.entitiesFacade.getProvidersFullName(provider)}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <ng-container *ngIf="this.providers.length > 0 && !loadingGetProviders;else loadingResponse ">
        <mat-form-field>
            <mat-chip-grid #patientChipList>
                <mat-chip-row *ngFor="let patient of patients" (removed)="removePatient(patient)">
                    {{patient.surname +' '+patient.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input placeholder="Buscar pacientes asociados" #patientInput [disabled]="this.providers.length < 1"
                 [formControl]="patientCtrl" [matAutocomplete]="patientsAuto"
                 [matChipInputFor]="patientChipList">
            </mat-chip-grid>
            <mat-autocomplete #patientsAuto="matAutocomplete" (optionSelected)="selectedPatient($event)">
                <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient">
                    {{patient.surname +' '+patient.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-list dense>
            <mat-list-item class="px-0 mys-3" *ngFor="let agreement of allAgreementsList">
                <mat-checkbox (change)="onChangeCheckAgreement($event, agreement)"
                    [checked]="isCheckedAgreement(agreement)">
                    <div>
                        <span>
                            {{agreement.provision.practice.name}}
                        </span>
                        <span class="text-secondary-darker"
                            *ngIf="this.providers.length > 1 || this.patients.length > 1 ">
                            ・
                            <span *ngIf="this.patients.length > 1">
                                {{agreement.provision.casee.patient.surname}}
                            </span>
                            <span *ngIf="this.patients.length > 1 && this.providers.length > 1">
                                |
                            </span>
                            <span *ngIf="this.providers.length > 1">
                                {{this.entitiesFacade.getProvidersFullName(agreement.provider)}}
                            </span>
                        </span>
                        <span *ngIf="this.thereIsSamePractices(agreement)" class="text-secondary-darker mls-1">
                            ({{ (!!agreement && !!agreement.provision && !!agreement.provision.casee && !!agreement.provision.casee.fromDate) ? (moment(agreement.provision.casee.fromDate).utc().format('MMMM')  | titlecase) : '' }})
                        </span>
                    </div>
                </mat-checkbox>
            </mat-list-item>
        </mat-list>
    </ng-container>

    <button mat-flat-button [disabled]="this.agreements.length < 1" (click)="onClickLoadSchedule()"
        [color]="'primary'">VER AGENDA</button>
</div>

<ng-template #loadingResponse>
    <div class="flex-100 align-center-center">
        <mat-spinner *ngIf="this.loadingGetProviders" class="mys-3" color="primary" diameter="40">
        </mat-spinner>
    </div>
</ng-template>
