import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { ProvidersQPS } from 'src/app/core/services/providers.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Case } from 'src/app/shared/models/case';
import { Provider } from 'src/app/shared/models/provider';
import Swal from 'sweetalert2';
import { CaseDerivation } from 'src/app/shared/models/caseDerivation';

@Component({
  selector: 'app-providers-selector',
  templateUrl: './providers-selector.component.html',
  styleUrls: ['./providers-selector.component.scss']
})
export class ProvidersSelectorComponent implements OnInit {

  public readonly PROVIDERS = "providers";

  corporateProvidersForm: FormGroup;

  @Input() case: Case;

  corporates: Provider[];

  constructor(private formBuilder: FormBuilder, private entitiesFacade: EntitiesFacade, private casesFacade: CasesFacade) { }

  ngOnInit(): void {
    this.corporateProvidersForm = this.createCorporateProvidersForm();
    let providerQPS: ProvidersQPS = {
      isCorporate: true
    }
    this.entitiesFacade.loadProviders(providerQPS).subscribe()
    this.entitiesFacade.getProviders$().subscribe((providers: Provider[]) => {
      this.corporates = providers;
      const derivated: CaseDerivation[] = this.case['caseDerivations'];
      this.corporateProvidersForm.get(this.PROVIDERS).setValue(derivated[0]?.derivedTo?.id.toString())
      // TODO: Cambiar línea
      // this.corporateProvidersForm.get(this.PROVIDERS).setValue('100000100')
    });
  }

  createCorporateProvidersForm(): FormGroup {
    return this.formBuilder.group({
      [this.PROVIDERS]: ['', [CustomValidators.required('Estado requerido')]],
    });
  }

  calculateName(element: Provider) {
    return element ? element.name : null;
  }

  calculateId(element: Provider) {
    return element ? element.id : null;
  }

  onClickDelegate() {

    const swalWithCustomizeButtons = Swal.mixin({
      customClass: {
      confirmButton: 'btnSwalConfirm',
      cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    swalWithCustomizeButtons.fire({
      title: `Confirma la delegacion del caso`,
      icon: 'warning',
      confirmButtonText: 'CONFIRMAR',
      cancelButtonText: 'CANCELAR',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      reverseButtons: true,
      preConfirm: () => {
        const body = { providerId: this.corporateProvidersForm.get(this.PROVIDERS)?.value };
        return this.casesFacade.deriveCase(body, this.case.id).toPromise().then(() => {
          return swalWithCustomizeButtons.fire({
            text: 'Caso delegado correctamente',
            icon: 'success'
          });
        }).catch(e => {
          return swalWithCustomizeButtons.fire({
            title: 'Ha ocurrido un error',
            text: 'El caso no ha sido derivado',
            icon: 'error'
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
}