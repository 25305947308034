import { Component, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent<T> {

  /**
   * Initializes the component.
   *
   * @param dialogRef - A reference to the dialog opened.
   */

  constructor(
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: { template: TemplateRef<any>, title: string, headerClass: string, titleClass:string, templateClass: string },
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
   this.cd.detectChanges();
  }

  close() {
    this.dialogRef.close()
  }
}