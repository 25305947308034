import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {

  @Input() elements!: {
    key: string,
    value: string,
    details?: string
  }[];

  constructor() { }

  ngOnInit(): void { }
}
