import { Component, OnInit, Input, ViewChild, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { Attention } from 'src/app/shared/models/attention';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ROUTES } from 'src/app/core/enums/routes';
import { Router } from '@angular/router';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EvolutionsDialogComponent } from 'src/app/modules/cases/components/evolutions-dialog/evolutions-dialog.component';
import { EvolutionsQPS } from 'src/app/core/services/evolutions.service';
import { DialogEvolutionComponent } from 'src/app/modules/attentions/components/dialog-evolution/dialog-evolution.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AttentionsFacade, ATTENTIONS_BACKGROUND_COLORS, ATTENTIONS_COLORS } from 'src/app/abstraction/attentions.facade';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import {
  DialogAuditStatesAttentionComponent
} from 'src/app/modules/attentions/components/dialog-audit-states-attention/dialog-audit-states-attention.component';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Provider } from 'src/app/shared/models/provider';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { Evolution } from 'src/app/shared/models/evolution';
import { AttentionToProcess } from '../../../../shared/models/attentionToProcess';
import { MatRadioButton } from '@angular/material/radio';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';

export interface AttentionAndLoadingEvolutions extends Attention {
  loadingEvolutions?: boolean
}


@Component({
  selector: 'app-attentions-list',
  templateUrl: './attentions-list.component.html',
  styleUrls: ['./attentions-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AttentionsListComponent implements OnInit {

  @Input() attentions: Attention[];
  @Input() clearSelection: boolean;
  @Input() title: string;
  @Input() loading: boolean = false;
  @Input() showMonth: boolean = true;
  @Input() showTotalQuantity: boolean = false;
  @Input() ruleClickable: (attention: Attention) => boolean;
  @Input() ruleEnabledButton3: (attention: Attention) => boolean;
  @Input() columnsToShow: string[];
  @Input() showGoToCase: boolean = true;
  @Input() textButton1: string;
  @Input() iconButton1: string;
  @Input() colorButton1: string = 'primary';
  @Input() colorIconButton1: string;
  @Input() infoTextButton1: string;
  @Input() colorButton2: string = 'primary';
  @Input() colorIconButton2: string;
  @Input() iconButton2: string;
  @Input() textButton2: string;
  @Input() textButton3: string;
  @Input() iconButton3: string;
  @Input() colorButton3: string = 'primary';
  @Input() colorIconButton3: string;
  @Input() textButton4: string;
  @Input() iconButton4: string;
  @Input() colorButton4: string = 'primary';
  @Input() colorIconButton4: string;
  @Input() textNoAttentions: string;
  @Input() scrollOn: boolean = true;
  @Input() paginatorOn: boolean = false;
  @Input() pageSize = 25;
  @Input() providers: Provider[];
  @Input() showProviderPracticeFilter: boolean = false;
  @Input() quickSearchActive: boolean = false;
  @Input() paginatorServerSide: boolean = false;
  @Input() keepSelection: boolean = false;
  @Input() evolutionList: boolean = false;
  @Input() noSort: boolean = false;
  @Input() showReload: boolean = false;
  @Input() supplyList: Attention[];

  @Input() descriptionStateShort: boolean = false;
  @Input() bottomTotals: boolean = false;

  @Output() onClickButton1 = new EventEmitter<Attention[]>();
  @Output() onClickButton2 = new EventEmitter<Attention[]>();
  @Output() onClickButton3 = new EventEmitter<Attention[]>();
  @Output() onClickButton4 = new EventEmitter<Attention[]>();
  @Output() onChangeMonth = new EventEmitter<Date>();
  @Output() onClickReload = new EventEmitter();


  @ViewChild('consolidationFocus') matTable: ElementRef;

  attentionsToBillDebit: AttentionToProcess[] = [];
  array = []
  removed = []

  currentCheckedValue = null;

  // BOTTOM TOTALS
  totalsByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
  public enumStates = AttentionStates;
  totalAttentions: number = 0;
  totalHours: number = 0;

  checkBoxActive: boolean = true;
  routes = ROUTES;
  ATTENTIONS_STATES = AttentionStates;
  ATTENTIONS_COLORS = ATTENTIONS_COLORS;
  ATTENTIONS_BACKGROUND_COLORS = ATTENTIONS_BACKGROUND_COLORS;
  ROUTER = this.router;
  expandedElement: any;
  public actualDate = moment();
  public actualDateAttentions: Attention[];
  displayedColumns: string[] = [
    'arrow',
    'checkbox',
    'fromDate',
    'provider',
    'practice',
    'financier',
    'patient',
    'documentNumber',
    'state',
    'signature',
    'evolutions',
  ];

  displayedColumnsSupply: string[] = [
    // 'arrow',
    'checkbox',
    'fromDate',
    'supply',
    'quantity',
    'unit',
    'price',
    'provider',
    'state',
  ];

  // Collections
  selectionToBill = new SelectionModel<Attention>(true, []);
  selectionToDebit = new SelectionModel<Attention>(true, []);

  dataSourceAttentions: MatTableDataSource<Attention>;
  dataSourceSupplies: MatTableDataSource<Attention>;
  selection = new SelectionModel<Attention>(true, []);
  @ViewChild('attentionsPaginator') paginator: MatPaginator;
  @ViewChild('suppliesPaginator') paginatorSupply: MatPaginator;
  supplySelection = new SelectionModel<Attention>(true, []);
  pageIndex = 0;
  EVOLUTIONS_TYPE = EvolutionsType;
  loadingEvolutions = false;
  filterForm: FormGroup;
  public readonly PROVIDER = 'provider';
  public readonly PROVIDER_SEARCHING = 'providerSearching';

  attetions$: Observable<Attention[]>;
  _attentions: Subscription;
  _evolutions: Subscription; evolutions$: Observable<Evolution[]>

  showButtonBill: boolean = false;
  _screenToJump:Subscription;

  constructor(
    public dialog: MatDialog,
    public entitiesFacade: EntitiesFacade,
    private attentionsFacade: AttentionsFacade,
    public cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private router: Router,
    public attentionService: AttentionsService,
    private _bottomSheet: MatBottomSheet,
    public fileUtilitiesService: FileUtilitiesService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private evolutionsFacade: EvolutionsFacade,
    private ren: Renderer2,
    private breadcrumbService: BreadcrumbService
  ) { }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
  }

  isCheckedSupply(row: any): boolean {
    const found = this.supplySelection.selected.find(el => el.id === row.id);
    if (found) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {

    if (this.showProviderPracticeFilter) {
      this.calculateNameProvider = this.calculateNameProvider.bind(this);
      this.filterForm = this.createFilterForm();
    }
    this.selection.isSelected = this.isChecked.bind(this);
    this.supplySelection.isSelected = this.isCheckedSupply.bind(this);
    this.setMonthOfLastAttention();
    this.setActualDateAttentions();
    // this.dataSourceSupplies = new MatTableDataSource<Attention>(this.supplyList);
    setTimeout(() => {
      this.dataSourceSupplies.paginator = this.paginatorSupply;
    });
    this.onChangeMonth.emit(moment(this.actualDate).toDate());
    this.displayedColumns = !!this.columnsToShow ? this.displayedColumns = this.columnsToShow : this.displayedColumns;
    this.setFilterPredicate();

    // Subscription to store.attentions$ because patch make change to state
    // this.attetions$ = this.attentionsFacade.getAttentions$();
    // this._attentions = this.attetions$.subscribe(att => {

    //   console.log("ATTENTIONS from attentions-list-component: ", att)

    //   // Compatibility because updateAttention() receive one attention
    //   if (!!att) {
    //     att.forEach(e => {
    //       this.updateAttention(e);
    //     })
    //   }
    // })
  }

  ngAfterViewInit(){
    this._screenToJump = this.breadcrumbService.getScreenToJump$().subscribe(event =>{
        if (event == ViewManagementEntities.BILLING_ATTENTIONS || event == ViewManagementEntities.SUPPLIES_NOT_INFORMED) {
                          this.setConsolidationTableFocus()
                   }
    })
  }

  setConsolidationTableFocus() {
        if(this.matTable){
            try{
                this.matTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.matTable.nativeElement.focus();
            }catch(err){
                console.log(err);

            }
        }
}


  calculateTotalsByAttentionState() {
    this.totalsByAttentionsState.fill(0);
    this.totalHours = 0;
    this.actualDateAttentions.map( att => {
        this.totalsByAttentionsState[att.state.id]+=1;
        this.totalHours += !!att.quantity ? att.quantity : 0;
      });
    this.totalAttentions = this.totalsByAttentionsState.reduce((a, b) => a + b, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    this.dataSourceSupplies = new MatTableDataSource<Attention>(this.supplyList);
    if (!!changes['attentions'] && !!changes['attentions'].currentValue) {
      this.dataSourceAttentions = new MatTableDataSource([]);
      this.setActualDateAttentions();
    }

    if (!!changes['clearSelection'] && changes['clearSelection'].currentValue) {
      this.selection.clear();
    }

    this.displayedColumns = !!this.columnsToShow ? this.displayedColumns = this.columnsToShow : this.displayedColumns;

    if (!!changes['columnsToShow']) {
      this.showButtonBill = changes['columnsToShow'].currentValue.findIndex(col => col == 'bill-debit') != -1; // Control, if exist columnsToShow -> validate if column 'bill' is included
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (!!this._evolutions) {
      this._evolutions.unsubscribe();
    }
  }

  createFilterForm(): FormGroup {
    return this.formBuilder.group({
      [this.PROVIDER]: [''],
      [this.PROVIDER_SEARCHING]: [''],
    });
  }

  setMonthOfLastAttention() {
    if (this.attentions != null && this.attentions != undefined && this.attentions.length > 0) {
      let auxDate = this.attentions[0].fromDate;
      this.attentions.forEach((at) => {
        if (at.fromDate > auxDate) {
          auxDate = at.fromDate;
        }
      });
      this.actualDate = moment(auxDate);
    }
  }
  // THIS PREDICATE FILTER WITH PATIENT NAME AND SURNAME/PROVIDER NAME AND SURNAME/ FINANCIER NAME
  setFilterPredicate() {
    if (!!this.dataSourceAttentions) {
      this.dataSourceAttentions.filterPredicate = (data: Attention, filter) => {
        const patientNameAndSurname =
          data.patient.name.toLowerCase() + ' ' + data.patient.surname.toLowerCase();
        const providerNameAndSurname =
          data.provider.name.toLowerCase() + ' ' + data.provider.surname.toLowerCase();
        const financierName =
          data.financier.name.toLowerCase();
        return (
          patientNameAndSurname.indexOf(filter) != -1 ||
          providerNameAndSurname.indexOf(filter) != -1 ||
          financierName.indexOf(filter) != -1
        );
      };
    }
  }

  onClickEvolutions(attention: AttentionAndLoadingEvolutions) {
    attention.loadingEvolutions = true;
    this.getEvolutionsByAttention(attention.id)
      .then((evolutions) => { this.openBottomSheet(evolutions, [attention]); })
      .then(_ => attention.loadingEvolutions = false)
      .catch(err => attention.loadingEvolutions = false);
  }

  onClickStateAttention(attention) {
    console.log("onClickStateAttention:", attention);
    attention.isLoadingStates = true;
    this.attentionsFacade.loadAuditStatesAttention(attention.id).pipe(

      map(a => ({ ...a, lastStateId: attention.state.id })), // Add id lastState
      map(a => ({ ...a, caseId: attention.caseId })), // Add id caseId
      tap(a => console.log("Este es el tracking de la attention", a)))

      .subscribe(a => { this.openAuditStatesAttentionDialog(a); },
        () => attention.isLoadingStates = false,
        () => attention.isLoadingStates = false);
  }

  openAuditStatesAttentionDialog(auditStatesAttention: AuditStatesAttention): void {
    const dialogRef = this.dialog.open(DialogAuditStatesAttentionComponent, {
      width: '65%',
      disableClose: true, // Don't close with outside click
      data: { auditStatesAttention },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("Callback Dialog: ", res);
      this.onClickReload.emit(true);
    });
  }

  getEvolutionsByAttention(attentionId): Promise<any> {
    return new Promise((resolve, reject) => {
      let qps: EvolutionsQPS = { attentionId };
      this.evolutions$ = this.evolutionsFacade.loadEvolutionsByQPS(qps, false)
      this._evolutions = this.evolutions$.subscribe(
        (evolutions) => {
          resolve(evolutions);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  getTotalQuantity() {
    return this.attentions.filter(
      attention => [
        this.ATTENTIONS_STATES.REALIZADA,
        this.ATTENTIONS_STATES.NO_INFORMADA,
        this.ATTENTIONS_STATES.FACTURABLE,
        this.ATTENTIONS_STATES.EN_PROCESO,
        this.ATTENTIONS_STATES.INFORMADA
      ]
        .includes(attention.state.id))
      .map(attention => attention.quantity)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalQuantityDebited() {
    return this.attentions
      .filter(attention => attention.state.id == this.ATTENTIONS_STATES.DEBITADA)
      .map(attention => attention.quantity)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalPrice() {
    return this.supplyList.filter(
      supply => [
        this.ATTENTIONS_STATES.REALIZADA,
        this.ATTENTIONS_STATES.NO_INFORMADA,
        this.ATTENTIONS_STATES.FACTURABLE,
        this.ATTENTIONS_STATES.EN_PROCESO,
        this.ATTENTIONS_STATES.INFORMADA,
      ]
        .includes(supply.state.id))
      .map(supply => supply.providerFee) // TIENE QUE SER PROVISION FEE!!!
      // .map(supply => supply.provisionFee)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalPriceDebited() {
    return this.supplyList
      .filter(supply => this.ATTENTIONS_STATES.DEBITADA == supply.state.id)
      .map(supply => supply.provisionFee)
      .reduce((acc, value) => acc + value, 0);
  }

  openBottomSheet(evolutions, attentions) {

    this._bottomSheet.open(EvolutionsDialogComponent, {
      panelClass: 'bottomSheetPanelClass',
      data: {
        evolutions,
        name: attentions[0].patient.name,
        surname: attentions[0].patient.surname,
        attentions: attentions
      },
    });
  }

  setActualDateAttentions() {
    if (!this.paginatorServerSide) {
      this.selection.clear();
    }
    if (this.showMonth) {
      this.actualDateAttentions = this.attentions?.filter(attention =>
        moment(attention.fromDate).month() == moment(this.actualDate).month()
        && moment(attention.fromDate).year() == moment(this.actualDate).year()
      );
    } else {
      this.actualDateAttentions = this.attentions;
    }
    if (!this.noSort) {
      if (!!this.actualDateAttentions) {
        this.actualDateAttentions.sort((attA, attB) => {
          if (!!attA.provider && !!attB.provider) {
            const dateA = attA.fromDate;
            const dateB = attB.fromDate;
            const providerA = this.entitiesFacade.getProvidersFullName(attA.provider);
            const providerB = this.entitiesFacade.getProvidersFullName(attB.provider);
            return (dateA < dateB ? -1 : dateA > dateB ? 1 : 0) || (providerA < providerB ? -1 : providerA > providerB ? 1 : 0);
          }
        });
      }
    }

    if (!!this.dataSourceAttentions && !!this.actualDateAttentions) {
      this.dataSourceAttentions.data = this.actualDateAttentions;
      this.calculateTotalsByAttentionState();
    }

    setTimeout(() => {
      if (this.paginatorOn) {
        this.dataSourceAttentions.paginator = this.paginator;
      }
      this.setFilterPredicate();
    });
  }

  getNameDate(): string {
    return this.actualDate.format('MMMM YYYY');
  }

  onClickPreviousMonth() {
    this.actualDate = this.actualDate.subtract(1, 'month').startOf('month');
    this.setActualDateAttentions();
    this.onChangeMonth.emit(moment(this.actualDate).toDate());
    this.attentionsToBillDebit = []; // Used for mode bill or debit
  }

  onClickNextMonth() {
    this.actualDate = this.actualDate.add(1, 'month').startOf('month');
    this.setActualDateAttentions();
    this.onChangeMonth.emit(moment(this.actualDate).toDate());
    this.attentionsToBillDebit = []; // Used for mode bill or debit
  }

  onLocalClickButton1() {
    console.log('Selecciones para facturar: ', [
      ...this.selection.selected,
      ...this.supplySelection.selected
    ]);
    this.onClickButton1.emit([
      ...this.selection.selected,
      ...this.supplySelection.selected
    ]);
  }

  onLocalClickButton2() {
    console.log('Selecciones para facturar: ', [
      ...this.selection.selected,
      ...this.supplySelection.selected
    ]);
    this.onClickButton2.emit([
      ...this.selection.selected,
      ...this.supplySelection.selected
    ]);
  }

  onLocalClickButton3() {
    this.onClickButton3.emit([
      ...this.selection.selected,
      ...this.supplySelection.selected
    ]);
  }

  onLocalClickButton4() {
    this.onClickButton4.emit(this.attentionsToBillDebit)
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const actualPageAttentions = this.dataSourceAttentions._pageData(this.dataSourceAttentions.filteredData);
    const numSelectedOnActualPage = this.selection.selected
      .filter(selected => actualPageAttentions.some(attention => attention.id == selected.id)).length;
    const numSelectablesOnActualPage = this.dataSourceAttentions
      ._pageData(this.dataSourceAttentions.filteredData)
      .filter(attention => this.ruleClickable(attention)).length;
    return numSelectedOnActualPage === numSelectablesOnActualPage;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      if (!!this.dataSourceAttentions.filteredData && this.dataSourceAttentions.filteredData.length > 0) {
        this.dataSourceAttentions
          ._pageData(this.dataSourceAttentions.filteredData)
          .filter(attention => this.ruleClickable(attention))
          .forEach(row => this.selection.select(row));
      }
    }
  }

  isAllSupplySelected() {
    return this.supplySelection.selected.length == this.supplyList.length;
  }

  masterToggleSupply() {
    if (this.isAllSupplySelected()) {
      this.supplySelection.clear();
    } else {
      this.supplyList
        .filter(supply => this.ruleClickable(supply))
        .forEach(row => this.supplySelection.select(row));
    }
  }

  isEnabledButton3() {
    let response = false;
    this.dataSourceAttentions.data.forEach(attention => {
      if (this.ruleEnabledButton3(attention)) {
        response = true;
        return false;
      }
    });
    return response;
  }

  matTooltipText(attention: Attention): string {
    let string = '';
    !!attention.fromDate
      ? (string +=
        'Ingreso agendado: ' +
        this.datePipe.transform(attention.fromDate, 'yyyy-MM-dd HH:mm') +
        '\n')
      : null;
    !!attention.toDate
      ? (string +=
        'Egreso agendado: ' +
        this.datePipe.transform(attention.toDate, 'yyyy-MM-dd HH:mm') +
        '\n')
      : null;
    !!attention.checkIn
      ? (string +=
        'Check in: ' + this.datePipe.transform(attention.checkIn, 'yyyy-MM-dd HH:mm') + '\n')
      : null;
    !!attention.checkOut
      ? (string +=
        'Check out: ' + this.datePipe.transform(attention.checkOut, 'yyyy-MM-dd HH:mm') + '\n')
      : null;
    return string;
  }

  openDialog(attention: Attention): void {
    console.log('Entro en abrir evolución');

    const dialogRef = this.dialog.open(DialogEvolutionComponent, { maxHeight: '95vh', minWidth: '60%', data: { attention }});

    dialogRef.afterClosed().subscribe((attention: Attention) => {
      // this.onFinishTask(result);
      if (!!attention && !!attention.state.id) {
        // this.updateAttention(attention);
        this.setActualDateAttentions();
      }
    });
  }

  // updateAttention(updatedAttention: Attention) {
  //   const attentionIndex = this.attentions?.findIndex(
  //     (attention) => attention.id === updatedAttention.id,
  //   );
  //   if (attentionIndex != -1) {
  //     this.attentions[attentionIndex].state = updatedAttention.state;
  //     this.attentions[attentionIndex].state = updatedAttention.state;
  //     this.setActualDateAttentions();
  //   }
  // }

  stopPropagation(e: Event) {
    e.cancelBubble = true;
    if (e.stopPropagation) { e.stopPropagation(); }
    e.stopPropagation();
  }

  applyFilter(filterValue: any) {
    this.selection.clear();
    // filterValue.length > 0 ? this.checkBoxActive = false : this.checkBoxActive = true;
    this.dataSourceAttentions.filter = filterValue.trim().toLowerCase();
  }

  sanitizeUrl(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  calculateIdProvider(element: Provider) {
    return element ? element.id : null;
  }

  calculateNameProvider(element: Provider) {
    return element ? this.entitiesFacade.getProvidersFullName(element) : null;
  }

  onSelectedProvider(providerId: number) {
    console.log('PROVIDER: ', providerId);
    this.dataSourceAttentions.data = this.attentions.filter(attention => !!attention.provider && attention.provider.id == providerId);
  }

  OnDestroy() {
    if (!!this._attentions) {
      this._attentions.unsubscribe;
    }
  }

  checkState( el: MatRadioButton, attention: AttentionToProcess ) {

    let attentionDeleted:boolean = false;

    // Uncheck radiobutton if exist in array and it's the same radiobutton fired
    setTimeout(() => {

      const atte = this.attentionsToBillDebit.find( att => att.id === attention.id);

      if (!!atte) {

        this.currentCheckedValue = atte.bill ? 'toBill' : atte.debit ? 'toDebit' : '';

        if ( this.currentCheckedValue && this.currentCheckedValue === el.value ) {
            el.checked = false;
            this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
            this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
            this.currentCheckedValue = null;
            this.attentionsToBillDebit.splice(this.attentionsToBillDebit.findIndex( a => a.id === atte.id), 1);
            attentionDeleted = true;
        }
      }
    })

    // It's needed because the radiobutton can't be unchecked before
    setTimeout(() => {

      if (!attentionDeleted) {

        let att;

        if (el.value == 'toBill') {
          att = { ...attention, bill: true, debit: false };
        }

        if (el.value == 'toDebit') {
          att = { ...attention, bill: false, debit: true };
        }

        let index = this.attentionsToBillDebit.findIndex(f => f.id === attention.id);
        if (index != -1) {
            this.attentionsToBillDebit.splice(index, 1)
        }
        this.attentionsToBillDebit.push(att)
        console.log("Attentions selected to bill or debit: ", this.attentionsToBillDebit);
      }
      }, 200)
      }
}
