import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material/material.module';
import { TrackingComponent } from './tracking.component';


@NgModule({
  declarations: [TrackingComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [TrackingComponent],

})
export class TrackingModule { }
