import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AttentionsFacade } from '../../../../abstraction/attentions.facade';
import { AttentionsDeleteBatchQPS, AttentionsService } from '../../../../core/services/attentions.service';
import * as moment from 'moment';
import { Attention } from '../../../../shared/models/attention';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import Swal from 'sweetalert2';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { Observable, Subscription } from 'rxjs';
import { Agreement } from 'src/app/shared/models/agreement';
import { HttpBaseResponseErrorTeaPot } from 'src/app/shared/models/httpBaseResponseErrorTeaPot';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { AlertService } from 'src/app/core/services/alert.service';
import { first } from 'rxjs/operators';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';

@Component({
	selector: 'app-attentions-delete-wizard',
	templateUrl: './attentions-delete-wizard.component.html',
	styleUrls: ['./attentions-delete-wizard.component.scss']
})
export class AttentionsDeleteWizardComponent implements OnInit {

	@Input() caseId: number;
	@Input() provisionAgreements: Agreement[];
    @Input() provisionFees: ProvisionFee[];
	@Output() onClickCancelWizard = new EventEmitter();

	auxAttentions: Attention[];
	previousAttentions: Attention[];
	daysOfWeekSelected = []; // 1: Monday/Lunes ... 7: Sunday/Domingo
	countDown: number;
	qps: AttentionsDeleteBatchQPS;

	controlListDays = new FormControl([]);
	labelButton: string;

	auxToDate: Date;
	dateRangeSubscription: Subscription;

	datesToPostSelected: moment.Moment[] = [];
	enumStates = AttentionStates;
	countAttentionsToDelete: Array<number>;

	btn_next_disabled: boolean = false;
	formOptionToSelect: FormGroup;
	formSelectCustomized: FormGroup;
	customizeAttenttionsSelectedToDelete: boolean = false;
	isEnabledConfirm: boolean = true;
	totalHours: number;

	public readonly FROM_DATE = 'fromDate';
	public readonly TO_DATE = 'toDate';
	public readonly ALLDAYS = 'allDays';
	public readonly LIST_DAYS = 'listDays';

	get optionSelected() {
		return this.formOptionToSelect.controls['radioButton'].value;
	}

	get fromDate() {
		return this.formSelectCustomized.get(this.FROM_DATE).value;
	}

	get toDate() {
		return this.formSelectCustomized.get(this.TO_DATE).value;
	}

	get allDays() {
		return this.formSelectCustomized.get(this.ALLDAYS).value;
	}

	set setDateFrom(date: Date) {
		this.formSelectCustomized.controls[this.FROM_DATE].setValue(date);
	}

	set setDateTo(date: Date) {
		this.formSelectCustomized.controls[this.TO_DATE].setValue(date);
	}

	public dayChipsSelected: Map<string, boolean> = new Map<string, boolean>();

	loadingDeleteBatchAttentions$: Observable<boolean>;

	constructor(
		private formBuilder: FormBuilder,
		private attentionsFacade: AttentionsFacade,
		private attentionsService: AttentionsService,
		private casesFacade: CasesFacade,
		private alertService: AlertService,
	) { }

	ngOnInit(): void {

		this.formOptionToSelect = this.createFormOptionToSelect();
		this.formSelectCustomized = this.createFormSelectCustomized();
		this.setDayChipsSelected();
		// Preselect option custom
		const event = { value: 'customizeAttenttionsSelected' }
		this.radioButtonSelected(event);
		// Set actual month
		this.dateRangeSubscription = this.casesFacade.getCaseDateFilters$()
		.pipe(first())
		.subscribe( range => {
			if (!!range) {
				this.setDateFrom = range.historyFromDate;
			} else {
				this.setDateFrom = moment().toDate();
			}
		})
		//
		this.loadingDeleteBatchAttentions$ = this.attentionsFacade.isLoadingDeleteBatchAttentions$();
	}

	setDayChipsSelected(isSelected?: boolean) {
		this.dayChipsSelected.set('L', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('M', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('X', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('J', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('V', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('S', !!isSelected ? isSelected : false);
		this.dayChipsSelected.set('D', !!isSelected ? isSelected : false);
	}

	isDayChipSelected(day) {
		return this.dayChipsSelected.get(day);
	}

	asIsOrder(a, b) {
		return 1;
	}

	// Metodo que se llama cuando se selecciona o se deselecciona un day chip
	selectDayChip(day: { key, value }) {
		// Actualiza la vista de los chips
		this.dayChipsSelected.set(day.key, !this.dayChipsSelected.get(day.key));
		// Actualiza el value del dia que me vino (por la accion anterior)
		day.value = this.dayChipsSelected.get(day.key);
	}

	createFormOptionToSelect(): FormGroup {
		return this.formBuilder.group({
			radioButton: new FormControl({ value: 'customizeAttenttionsSelected', disabled: false },)
		})
	}

	createFormSelectCustomized() {
		return this.formBuilder.group({
			[this.FROM_DATE]: ['', [CustomValidators.required('Fecha Desde requerida')]],
			[this.TO_DATE]: [''],
			[this.ALLDAYS]: [false],
		})
	}

	closeWizard() {
		this.onClickCancelWizard.emit();
	}

	radioButtonSelected(event) {
		this.setDayChipsSelected(); // Reset array days
		this.customizeAttenttionsSelectedToDelete = event.value === 'customizeAttenttionsSelected' ? true : false;
		this.isEnabledConfirm = event.value === 'allAttentionsSelected' ? true : false;
	}

	onChangeCheck(event) {
		this.setDayChipsSelected(event);
	}

	confirmDeleteAttentionsBatch() {
		// caseId: number,
		// allAttentions?: boolean,    // En caso de seleccionar la opción “Todas las atenciones” enviar true
		// fromDate?: Date,            // En caso de seleccionar algunas atenciones
		// toDate?: Date,              // En caso de seleccionar algunas atenciones
		// allDays?: boolean           // En caso de seleccionar la opción “Todos los dias”
		// daysOfTheWeek?: number[]    // En caso de filtrar por día:
		// agreementsId: number[]

		switch (this.optionSelected) {
			case 'allAttentionsSelected':
				break;

			case 'customizeAttenttionsSelected':

				this.auxToDate = !!this.toDate ? this.toDate : moment().add(10, 'years');

				// 1: Monday/Lunes ... 7: Sunday/Domingo
				this.daysOfWeekSelected = [];
				let i = 0
				this.dayChipsSelected.forEach(x => {
					i++;
					if (x)
						this.daysOfWeekSelected.push(i);
				})
				break;

			default:
				// NOTHING
				break;
		}

		this.qps = {
			caseId: this.caseId,
			allAttentions: this.optionSelected === 'allAttentionsSelected' ? true : false,
			allDays: this.allDays,
			daysOfTheWeek: this.daysOfWeekSelected?.length > 0 ? this.daysOfWeekSelected : [1, 2, 3, 4, 5, 6, 7],
			agreementsId: this.provisionAgreements?.map(pro => pro.id),
            provisionFeesId: this.provisionFees?.map(pro => pro.id)
		}

		if (!!this.fromDate) {
			this.qps = { ...this.qps, fromDate: moment(this.fromDate).toDate() }
		}

		if (!!this.auxToDate) {
			this.qps = { ...this.qps, toDate: moment(this.auxToDate).toDate() };
		}

		this.qps = { ...this.qps, force: false }; // 1st called to endpoint

		this.confirmProcces();
	}

	isAtLeastOneDaySelected(): boolean {
		let res = false;
		this.dayChipsSelected.forEach(item => {
			if (item)
				res = true;
		})
		return res;
	}

	confirmProcces() {
		this.attentionsFacade.deleteAttentionsBatch(this.qps).subscribe(
			// Success
			res => {
				this.actionMessageSuccesDeleteAttentions(res);
			},
			error => {
				if (error.status === 404) {
					this.alertService.openError("No se han encontrado atenciones para ser eliminadas.");
				}
				if (error.status === 418) {
					let errorContent: HttpBaseResponseErrorTeaPot = error.error;

					// WARNING! It's a promise, waiting the user
					this.attentionsService.processErrorCountAttentions(errorContent).then(res => {
						if (!res) {
						} else {
							// User confirm force
							this.qps = { ...this.qps, force: true };
							this.attentionsFacade.deleteAttentionsBatch(this.qps).subscribe(
								res => {
									// Success
									this.actionMessageSuccesDeleteAttentions('Se han eliminado las atenciones correctamente');
									// Force refresh attentions through filters
									this.casesFacade.setCaseDateFilters(this.casesFacade.getCaseDateFilter());
								})
						}
					})
				}
			})
	}

	actionMessageSuccesDeleteAttentions(msg: string) {
		Swal.fire({
			position: 'center',
			icon: 'success',
			title: msg,
			showConfirmButton: false,
			timer: 2500
		}).then(_ => this.closeWizard()) // Emit
	}
}
