<form *ngIf="vatCategorySelectorForm" [formGroup]="vatCategorySelectorForm" class="flex-row">
    <app-select-searching-dynamic-input class="m-0 p-0" [leftIcon]='"sell"'
        [defaultValue]="this.actualVatCategory?.id.toString()" [elements]="this.vatCategoriesOnSelect"
        [leftIconClass]="'text-secondary-darker'" (selected)="this.onUpdateVatCategory()" [name]="this.VAT_CATEGORIES"
        [nameSearching]="this.VAT_CATEGORIES_SEARCHING" [form]="this.vatCategorySelectorForm"
        [placeholder]="'Categoria de IVA'" [calculateId]="this.calculateId" [calculateName]="this.calculateName"
        [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar categoria de IVA...'"
        [httpGet]="this.getVatCategoriesByName">
    </app-select-searching-dynamic-input>
</form>
