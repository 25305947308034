import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setObjectToSessionStorage ( key: string, object: Object ) {

    sessionStorage.setItem( key, JSON.stringify( object ));
    
  }

  getObjectfromSessionStorage ( key: string ) {
    
    if ( key ) {

      return JSON.parse(sessionStorage.getItem( key ));
      
    }
  }

  removeValuesByKeyOnSessionStorage ( key : string ) : void {
    if ( key ){
      sessionStorage.removeItem( key );
    }
  }

  setObjectToLocalStorage ( key: string, object: Object ) {
    localStorage.setItem( key, JSON.stringify( object ));
  }

  getObjectfromLocalStorage ( key: string ) {
    if ( key ) {
      return JSON.parse(localStorage.getItem( key ));
    }
  }

  removeValuesByKeyOnLocalStorage ( key : string ) : void {
    if ( key ){
      localStorage.removeItem( key );
    }
  }

  moveFromSessionToLocalStorage ( key : string ) : void {
    if ( key && sessionStorage.getItem(key) ){
      localStorage.setItem(key, sessionStorage.getItem(key));
      sessionStorage.removeItem(key);
    }
  }

  moveFromLocalToSessionStorage ( key : string ) : void {
    if ( key && localStorage.getItem(key) ){
      sessionStorage.setItem(key, localStorage.getItem(key));
      localStorage.removeItem(key);
    }
  }

}
