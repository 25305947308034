import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { DateService } from 'src/app/shared/components/utils/date.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIS } from 'src/app/core/enums/apis';
import { Evolution } from 'src/app/shared/models/evolution';
import { RestUtilitiesService } from './rest-utilities.service';
import { Media } from "src/app/shared/models/media";
import { AttentionsQPS } from './attentions.service';
import { AttentionComposedWithEvolutions } from '../../abstraction/evolutions.facade';

export interface EvolutionsQPS {
	caseId?: number,
	attentionId?: number,
	category?: string,
	fromDate?: Date,
	toDate?: Date,
	attentionsIds?: number[]
}

export interface PostEvolutionBody {
	date: Date | string,
	attentionId?: number,
	caseId?: number,
	text: string,
	type: number,
	visibleFinancier?: boolean,
	visibleProvider?: boolean,
	mediaUrls: string[],
	referencedEvolution?: number
}

@Injectable({
	providedIn: 'root',
})
export class EvolutionsService {
	constructor(private http: HttpClient, private dateService: DateService, private restUtilitiesService: RestUtilitiesService) { }

	patchEvolution(body: Evolution) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiRecordService}${APIS.EVOLUTIONS}/${body.id}`,
				JSON.stringify(body),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postEvolution(body: PostEvolutionBody) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		if (!!body.mediaUrls) {
			const mediaUrls = body.mediaUrls;
			for (var i = 0; i < mediaUrls.length; i++) { if (!mediaUrls[i]) { mediaUrls.splice(i, 1); } };
			if (mediaUrls.length < 1) delete body.mediaUrls;
		}
		body.date = (body.date as Date).toISOString();
		return this.http
			.post<any>(`${environment.apiRecordService}${APIS.EVOLUTIONS}`, JSON.stringify(body), {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	getEvolutions(qps?: EvolutionsQPS): Observable<Evolution[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
		return this.http
			.get<any>(`${environment.apiRecordService}${APIS.EVOLUTIONS}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					console.log("response recibido: ", response.body.data);
					return response.body.data;
				}),
			);
	}

	getEvolutionById(id: number): Observable<Evolution> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.get<any>(`${environment.apiRecordService}${APIS.EVOLUTIONS}/${id}`, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					console.log("response recibido: ", response.body.data);
					return response.body.data;
				}),
			);
	}

	getAttentionsWithEvolutions(qps?: AttentionsQPS, categoriesIds?: number[]): Observable<AttentionComposedWithEvolutions[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		let finalQPS: any = { ...qps };
		if (!!categoriesIds) {
			finalQPS = { ...finalQPS, 'categories': categoriesIds.toString() };
		}
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(finalQPS)) : null;
		return this.http
			.get<any>(`${environment.apiAttentions}/user/attentions/evolutions`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					console.log("response recibido: ", response.body.data);
					return response.body.data;
				}),
			);
	}

	getMediaByEvolutionId(evolutionId: number): Observable<Media> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.get<any>(`${environment.apiRecordService}${APIS.EVOLUTIONS}/${evolutionId}/media`, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<Media>(
				map<HttpResponse<any>, Media>((response) => {
					return response.body.data;
				}),
			);
	}

	getCountEvolutions( qps?: EvolutionsQPS ): Observable<number> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
		return this.http
			.get<any>(`${environment.apiRecordService}${APIS.EVOLUTIONS_COUNT}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					console.log("response recibido: ", response.body);
					return response.body;
				}),
			);
	}
}
