import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { RestUtilitiesService } from './rest-utilities.service';
import { map } from 'rxjs/operators';
import { CategoryOfEvolution } from 'src/app/shared/models/categoryOfEvolution';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';


export interface CategoriesOfEvolutionsCountQPS {
   active?: number;
}

export interface CategoriesOfEvolutionsQPS {
   description?: string;
   active?: number;
   page?: number;
   size?: number;
   all?: boolean
}

export interface PostCategoryOfEvolution {
   description: string,
   defaultText?: string,

   isRequiredForClosure?: boolean,
   isRequiredForLiquidation?: boolean,
   
   createFinancier?: number,
   createOperator?: number,
   createProvider?: number,
   createPatient?: number,

   visibleFinancier?: number,
   visibleProvider?: number,
   visiblePatient?: number,
   
   priority: number
}

export interface PatchCategoryOfEvolution {
   id: number;
   description?: string,
   defaultText?: string,

   isRequiredForClosure?: boolean,
   isRequiredForLiquidation?: boolean,
   
   createFinancier?: number,
   createOperator?: number,
   createProvider?: number,
   createPatient?: number,

   visibleFinancier?: number,
   visibleProvider?: number,
   visiblePatient?: number,
   
   priority?: number
   active: number
}

@Injectable({
   providedIn: 'root'
})


export class CategoriesOfEvolutionsService {

   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getCategoriesOfEvolutions( qps?: CategoriesOfEvolutionsQPS ): Observable<HttpBaseResponse<CategoryOfEvolution[]>> {

      console.log( "getCategoriesOfEvolutions" );

      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs( qps )) : null;
      return this.http
         .get<any>(`${environment.apiRecordService}${APIS.CATEGORIES_OF_EVOLUTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }


   postCategoryOfEvolution( category: PostCategoryOfEvolution ): Observable<CategoryOfEvolution> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiRecordService}${APIS.CATEGORIES_OF_EVOLUTIONS}`, JSON.stringify( category ), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }


   patchCategoryOfEvolution(category: PatchCategoryOfEvolution ) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiRecordService}${APIS.CATEGORIES_OF_EVOLUTIONS}`,
            JSON.stringify( category ),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }   


   getCategoriesOfEvolutionsCount( qps?: CategoriesOfEvolutionsCountQPS ): Observable<HttpBaseResponse<any>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiRecordService}${APIS.CATEGORIES_OF_EVOLUTIONS}/count`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }
}
