import { Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { Practice } from 'src/app/shared/models/practice';
import { ATTENTIONS_COLORS } from 'src/app/abstraction/attentions.facade';

@Injectable({
  providedIn: 'root'
})
export class CalendarCaseService {
  constructor() { }

  getHoursTooltip(event : CalendarEvent):string{
    return  moment(event.start).format('HH:mm') + ' - ' +  moment(event.end).format('HH:mm')
   }
  
   eventStartsBeforeDaySelected(event: CalendarEvent, date : Date | moment.Moment){
    return moment(date).startOf('day').isAfter(moment(event.start, 'dd/mm/yyyy').startOf('day'))
   }
  
   eventEndsAfterDaySelected(event: CalendarEvent, date : Date | moment.Moment){
    return moment(date).startOf('day').isBefore(moment(event.end, 'dd/mm/yyyy').startOf('day'))
   }

   practiceCountedByHours(practice : Practice){
    return (!!practice?.restriction?.requiredTime || !!practice?.restriction?.requiredToDate);
 }

 getColor(element) {
    return ATTENTIONS_COLORS[element];
  }

}
