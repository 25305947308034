import { Component, OnInit } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-time-picker-input',
  templateUrl: './time-picker-input.component.html',
  styleUrls: ['./time-picker-input.component.scss']
})
export class TimePickerInputComponent extends FormElementComponent implements OnInit {

  constructor() {
    super();
  }
}
