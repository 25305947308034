export enum AttentionStates {
   CARGADA = 0,
   AGENDADA = 1,
   EN_CURSO = 2,
   REALIZADA = 3,
   NO_REALIZADA = 4,
   FACTURABLE = 5,
   DEBITADA = 6,
   EN_PROCESO = 7,
   INFORMADA = 8,
   NO_INFORMADA = 9,
   CREACION = 10
}