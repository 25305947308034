import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { Patient } from 'src/app/shared/models/patient';
import { FormGroup } from '@angular/forms';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';

@Component({
  selector: 'app-patient-selector',
  templateUrl: './patient-selector.component.html',
  styleUrls: ['./patient-selector.component.scss']
})
export class PatientSelectorComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() name: string;
  @Input() nameSearching: string;
  @Input() calculatePatientId = (patient: Patient) => patient.id;

  patients$: Observable<Patient[]>;
  _patients: Subscription;
  patients: Patient[];

  constructor(
    private casesFacade: CasesFacade,
    private entitiesFacade: EntitiesFacade,
  ) { }

  ngOnInit(): void {
    this.entitiesFacade.loadPatients();
    this.patients$ = this.entitiesFacade.getPatients$();

    this.subscribeToData();
  }

  subscribeToData() {
    this._patients = this.patients$
      .subscribe(patients => this.patients = patients);
  }

  ngOnDestroy(): void {
    this._patients.unsubscribe();
  }

  calculatePatientDNI(patient: Patient) {
    return patient.documentNumber;
  }

}
