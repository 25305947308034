<div class="flex-column flex mts-4">
    <ng-container *ngIf="loading">
        <div class="flex-row align-center-center full-height spinner-hover">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
    </ng-container>
    <div><span class="headline-2 text-primary-darker-title" *ngIf="columns!='A'">Solicitudes</span></div>
    <div><span class="headline-2 text-primary-darker-title" *ngIf="columns==='A'">Solicitudes archivadas</span></div>
    <div class="flex-row space-between-center mts-2 full-width">
        <mat-form-field appearance="outline" class="flex-40">
            <!-- <mat-icon matPrefix class="text-primary-lighter">search</mat-icon> -->
            <mat-label>Ingrese el nombre, apellido, DNI del paciente o CIF</mat-label>
            <mat-hint>Al buscar por CIF o DNI el mismo debe ser exacto</mat-hint>
            <input matInput placeholder="Búsqueda rápida" #inputSearch />
        </mat-form-field>
        <app-select-input
            [elements]="this.requisitionValidityStates" [name]="this.ACTIVE_STATE"
            [form]="this.filterForm" class="flex-18" [placeholder]="'Filtrar por estado'"
            [calculateId]="calculateIdActiveState" [calculateName]="calculateNameActiveState"
            [defaultValue]="this.activeStateSelected?.id?.toString()"
            (selected)="onSelectActiveState()">
        </app-select-input>
        <app-select-input
            *ngIf="this.activeStateSelected == 1"
            [elements]="this.activeRequisitionStates" [name]="this.REQUISITION_STATE"
            [form]="this.filterForm" class="flex-18" [placeholder]="'Filtrar por estado'"
            [calculateId]="calculateIdRequisitionState" [calculateName]="calculateNameRequisitionState"
            [defaultValue]="this.stateSelected?.id.toString()"
            (selected)="onSelectStateFilter($event)">
        </app-select-input>
        <app-select-input
            *ngIf="this.activeStateSelected != 1"
            [elements]="this.requisitionReasons" [name]="this.REQUISITION_REASON"
            [form]="this.filterForm" class="flex-18" [placeholder]="'Filtrar por motivo'"
            [calculateId]="calculateIdRequisitionReason" [calculateName]="calculateNameRequisitionReason"
            [defaultValue]="this.reasonSelected?.id?.toString()"
            (selected)="onSelectStateFilter($event)">
        </app-select-input>
        <app-select-input
            [elements]="this.requisitionTypes" [name]="this.REQUISITION_TYPE"
            [form]="this.filterForm" class="flex-18" [placeholder]="'Filtrar por tipo'"
            [calculateId]="calculateIdRequisitionType" [calculateName]="calculateNameRequisitionType"
            [defaultValue]="this.typeSelected?.id.toString()"
            (selected)="onSelectTypeFilter($event)">
        </app-select-input>
    </div>
    <app-card-table class="mt-1" (page)="onPaginatorEvent($event)" [showPaginator]="true" [metaData]="this.metaDataRequisitions">
        <div table class="flex-row align-center-center pointer">
            <div class="flex-100 flex-column mts-3">
                <table *ngIf="this.dataSource?.data?.length > 0; else noRequisitionsTemplate" mat-table class="flex-100" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                    <!-- Patient Column -->
                    <ng-container matColumnDef="patient">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="patient" class="pl-0" sortActionDescription="Paciente">Paciente</th>
                        <td mat-cell *matCellDef="let admission" class="pl-0">
                            <div class="inner-cell" [matTooltip]="this.generalService.getFullName(admission.caseRequisitionResponseDto.patient.name, admission.caseRequisitionResponseDto.patient.surname)">
                                <span class="text-ellipsis" style="width: 100px;">
                                    {{ this.generalService.getFullName(admission.caseRequisitionResponseDto.patient.name, admission.caseRequisitionResponseDto.patient.surname)}}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Tipo">Tipo</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <span class="mat-small">
                                    {{ admission.requisitionResponseDto.requisitionType.description | titlecase | uppercase }}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Estado">Estado</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <mat-chip-listbox>
                                    <mat-chip class="nowrap-pointer" matTooltip="{{admission.requisitionResponseDto.requisitionState.description}}" [ngClass]="this.requisitionService.getColorRequisitionState(admission.requisitionResponseDto.requisitionState.id)">
                                        <span class="mat-small">
                                            {{ admission.requisitionResponseDto.requisitionState.description | firstLetterWordsIntoString}}
                                        </span>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Motive Column -->
                    <ng-container matColumnDef="motive">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Estado">Motivo</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell inner-cell-auth">
                                <mat-chip-listbox>
                                    <mat-chip class="nowrap-pointer" matTooltip="{{admission?.requisitionResponseDto?.requisitionReason?.description}}" [ngClass]="this.requisitionService.getColorRequisitionReason(admission?.requisitionResponseDto?.requisitionReason?.id)">
                                        <span class="mat-small">
                                            {{ admission?.requisitionResponseDto?.requisitionReason ? (admission?.requisitionResponseDto?.requisitionReason?.description | firstLetterWordsIntoString) : '-' }}
                                        </span>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Case type Column -->
                    <ng-container matColumnDef="caseType">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Tipo de caso">Tipo de caso</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell case-type-cell align-center-center" matTooltip="{{getCaseTypesList(admission.caseRequisitionResponseDto.tags, '  •  ')}}">
                                <div>
                                    <span class="text-ellipsis">{{getCaseTypesList(admission.caseRequisitionResponseDto.tags, ', ')}}</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Complexity -->
                    <ng-container matColumnDef="complexity">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Complejidad">Complejidad</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <span class="mat-small">
                                    {{ !!admission.caseRequisitionResponseDto.complexities ? admission.caseRequisitionResponseDto.complexities.name : '-' | titlecase | uppercase }}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Operator Column -->
                    <ng-container matColumnDef="operator">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Operador">Operador</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <span class="text-ellipsis" style="width:100px;" [matTooltip]="admission.caseRequisitionResponseDto?.operator?.name + ', ' + admission?.caseRequisitionResponseDto?.operator?.surname">
                                    {{ admission.caseRequisitionResponseDto?.operator?.name }}, {{ admission?.caseRequisitionResponseDto?.operator?.surname }}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Financier Column -->
                    <ng-container matColumnDef="financier">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Financiador">Financiador</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                {{ admission?.caseRequisitionResponseDto?.financier?.name }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="date" sortActionDescription="Fecha">Fecha</th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <div class="flex-row space-between-center">
                                    {{ admission.requisitionResponseDto.firstAuditDate ? (admission.requisitionResponseDto.firstAuditDate | date: 'dd/MM/yyyy') : '-' }}
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let admission">
                            <div class="inner-cell">
                                <button mat-icon-button (click)="$event.stopPropagation();" matTooltip="Acciones">
                                    <mat-icon class="cursor-pointer text-secondary" [matMenuTriggerFor]="menuActions"
                                              [matMenuTriggerData]="{ requisition: admission }"
                                              [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"></mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row (click)="clickedRow(row)" class="bg-on-hover parentShowOnHover" *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <ng-template #noRequisitionsTemplate>
                    <span class="text-secondary mts-4" *ngIf="this.dataSource?.data?.length == 0">No se encontraron solicitudes</span>
                </ng-template>
            </div>
        </div>
    </app-card-table>
</div>

<mat-menu #menuActions="matMenu">
    <ng-template matMenuContent let-requisition="requisition">
        <!-- Enabled states: 5, 6 -->
        <button mat-menu-item (click)="this.switchToCase(requisition)" id="switchToCase" *ngIf="isSwitchToCaseEnabled(requisition)">{{ caseOrPerformed(requisition) }}</button>
        <!-- Enabled states: 2, 3, 4, 5, 6 -->
        <button mat-menu-item (click)="this.rejectCase(requisition)" id="rejectCase" *ngIf="isRejectCaseEnabled(requisition)">Desestimar</button>
        <!-- Enabled states: 2, 3, 4, 5, 6 -->
        <button mat-menu-item (click)="this.changeState(requisition)" id="changeState" *ngIf="isChangeStateEnabled(requisition)">Cambiar de estado</button>
    </ng-template>
</mat-menu>

<ng-template #updateState>
    <form (submit)="onClickConfirmUpdateState()" [formGroup]="this.formUpdateState">
        <div class="flex-column">
            <mat-radio-group class="flex-column py-3 px-2" (change)="clickRadiobuttonChangeState($event)" name="radioButton" formControlName="radioButton">
                <mat-radio-button *ngFor="let option of transitionStatesPermitted(requisitionToChange.requisitionResponseDto.requisitionTransitionStates)" [value]="option.id" color="primary" class="subtitle-3 text-primary-darker">
                    {{ option.description }}
                </mat-radio-button>
            </mat-radio-group>
            <div class="mts-3 flex-row flex-space-evenly-start">
                <button mat-stroked-button color="primary" type="button" (click)="onClickCloseDialog()">CANCELAR</button>
                <button mat-flat-button color="primary" type="submit" [disabled]="!this.formUpdateState.valid">GUARDAR</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #viewRejectCase>
    <form (submit)="onClickConfirmRejectCase()" [formGroup]="this.formRejectCase">
        <ng-container *ngIf="(this.requisitionsFacade.isLoadingUpdatingRequisition$() | async)">
            <div class="flex-row align-center-center full-height spinner-hover">
                <mat-spinner diameter="60"></mat-spinner>
            </div>
        </ng-container>
        <div class="flex-column flex-gap-1rem">
            <div class="flex-row gap-2rem">
                <mat-label>Motivo</mat-label>
                <mat-radio-group class="flex-column" (change)="clickRadiobutton($event)" name="radioButton" formControlName="radioButton">
                    <mat-radio-button *ngFor="let option of optionsListReject" color="primary" class="subtitle-3 text-primary-darker" [value]="option">
                        {{ option }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <app-text-area-input class="flex-100" [name]="this.OBSERVATIONS" [form]="this.formRejectCase" [placeholder]="'Descripción'" [maxLength]="140"></app-text-area-input>
            <div class="mts-3 flex-row flex-space-evenly-start">
                <button mat-stroked-button color="primary" type="button" (click)="onClickCloseDialog()">CANCELAR</button>
                <button mat-flat-button color="primary" type="submit" [disabled]="!this.formRejectCase.valid">GUARDAR</button>
            </div>
        </div>
    </form>
</ng-template>
