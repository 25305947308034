<div class="full-width flex-column" style="max-height: 80vh; overflow: auto;">
    <div *ngIf="!!dataSourceAttentions && this.quickSearchActive"
        class="flex-row align-center-center flex-100 mxs-5 mys-2">
        <mat-form-field class="flex-80">
            <input matInput (keyup)="applyFilter($event)" placeholder="Búsqueda rápida" />
        </mat-form-field>
    </div>

    <!-- Buttons - Title -->
    <div *ngIf="!!dataSourceAttentions && showMonth" class="flex-row mts-5">
        <div class="flex-25">
            <mat-button-toggle-group *ngIf="!this.loading">
                <mat-button-toggle mwlCalendarPreviousView (click)="onClickPreviousMonth()">
                    <mat-icon class="text-secondary-darker">keyboard_arrow_left</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle mwlCalendarNextView (click)="onClickNextMonth()">
                    <mat-icon class="text-secondary-darker">keyboard_arrow_right</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="flex-50 align-center-start">
            <span class="mat-title text-secondary-darker">
                {{ getNameDate() | titlecase }}
            </span>
        </div>
        <div class="flex-25">
        </div>
    </div>

    <div class="flex-column full-width" *ngIf="(dataSourceAttentions && dataSourceAttentions.data
            && dataSourceAttentions.filteredData.length > 0
            && dataSourceAttentions.data.length > 0)
            || (dataSourceSupplies && dataSourceSupplies.data && dataSourceSupplies.data.length > 0);
            else noAttentions">

        <!-- Attentions Table -->
        <div [ngClass]="{'scrollOff': this.scrollOn==false}" style="border-radius: 10px;">
            <!-- style="max-height: 85vh; overflow:auto;" -->
            <table multiTemplateDataRows class="full-width mat-elevation-z0 mt-0" mat-table
                [dataSource]="dataSourceAttentions">
                <!-- Master Head 1 -->
                <ng-container matColumnDef="title">
                    <th #consolidationFocus mat-header-cell *matHeaderCellDef colspan="25"
                        style="text-align: center; border-bottom: 1px solid white !important;">
                        <span>{{ this.showButtonBill ? "Atenciones Médicas e Insumos" : "Atenciones Médicas" }}</span>
                    </th>
                </ng-container>

                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *ngIf="this.checkBoxActive" style="margin-right: 5px;"
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox *ngIf="this.ruleClickable(row); else noClickeable"
                            (click)="$event.stopPropagation()" (change)="$event ?  selection.toggle(row) : null"
                            [checked]="this.selection.isSelected(row)">
                        </mat-checkbox>
                        <ng-template #noClickeable>
                        </ng-template>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>

                </ng-container>

                <!-- arrow Column -->
                <ng-container matColumnDef="arrow">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let data" class="pr-0 arrow-column"
                        style="align-content: center !important;">
                        <mat-icon class="text-secondary">{{ data == expandedElement ? 'keyboard_arrow_up' :
                            'keyboard_arrow_down' }}</mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- dateFrom Column -->
                <ng-container matColumnDef="fromDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            <a>{{ data.fromDate | date: "dd/MM/yyyy" }}</a>
                            <span class="text-secondary" *ngIf="data?.liquidation?.id"
                                [matTooltip]="data?.liquidation?.name">#{{data?.liquidation?.id}}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column mys-1">
                            <b>
                                Total
                            </b>
                            <span class="mat-caption">
                                Total debitada
                            </span>
                        </div>
                    </td>
                </ng-container>

                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Cant.</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.quantity ? data.quantity : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column">
                            <b>
                                {{this.getTotalQuantity()}}
                            </b>
                            <span>
                                {{this.getTotalQuantityDebited()}}
                            </span>
                        </div>
                    </td>

                </ng-container>

                <!-- financier Column -->
                <ng-container matColumnDef="financier">
                    <th mat-header-cell *matHeaderCellDef>Financiador</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="inner-cell">
                            <a>{{ !!data.financier?.name ? data.financier.name : 'Sin datos'}}</a>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- patient Column -->
                <ng-container matColumnDef="patient">
                    <th mat-header-cell *matHeaderCellDef>Paciente</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="inner-cell">
                            <div
                                matTooltip="{{!!data.patient ? data.patient.surname + ' ' + data.patient.name : 'Sin datos'}}">

                                {{
                                !!data.patient ? ((data.patient.surname + ' ' + data.patient.name
                                ).toString().length > 15
                                ? ((data.patient.surname + ' ' + data.patient.name) | slice: 0:13) + '...'
                                : data.patient.surname + ' ' + data.patient.name)
                                : 'Sin datos'
                                }}
                            </div>
                        </div>
                        <!-- <div *ngIf="isExpanded">
                            {{ data.patient.surname + ' ' + data.patient.name }}
                        </!-->
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- patientDocument Column -->
                <ng-container matColumnDef="documentNumber">
                    <th mat-header-cell *matHeaderCellDef>Documento</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="inner-cell">
                            <div class="flex-column">
                                {{!!data.patient.documentNumber ? data.patient.documentNumber : '-'}}
                            </div>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- provider Column -->
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>Prestador</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="inner-cell">
                            <span *ngIf="!!this.entitiesFacade.getProvidersFullName(data?.provider)"
                                matTooltip="{{this.entitiesFacade.getProvidersFullName(data?.provider)}}">

                                {{
                                (this.entitiesFacade.getProvidersFullName(data?.provider)
                                ).toString().length > 15
                                ? ((this.entitiesFacade.getProvidersFullName(data?.provider)) | slice: 0:13) + '...'
                                : this.entitiesFacade.getProvidersFullName(data?.provider)
                                }}
                            </span>
                        </div>
                        <!-- <div *ngIf="isExpanded && this.entitiesFacade.getProvidersFullName(data?.provider)">
                            {{this.entitiesFacade.getProvidersFullName(data?.provider)}}
                        </div> -->
                        <div *ngIf="!this.entitiesFacade.getProvidersFullName(data?.provider)">
                            Sin datos
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- state Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let attention">
                        <div class="inner-cell inner-cell-auth">
                            <mat-chip-listbox *ngIf="!attention?.isLoadingStates">
                                <mat-chip [matTooltip]="this.matTooltipText(attention)"
                                    matTooltipClass="multiline-tooltip" style="white-space: nowrap; cursor: pointer;"
                                    [ngClass]="{ 'mat-chip-min-width-50px': this.descriptionStateShort }"
                                    [ngStyle]="{'color':this.ATTENTIONS_COLORS[attention.state.id], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[attention.state.id]}"
                                    selected [disabled]="attention?.isLoadingStates"
                                    (click)="this.stopPropagation($event); !attention?.isLoadingStates ? this.onClickStateAttention(attention):null">
                                    <span class="mat-caption" matTooltip="{{ attention.state.name | uppercase }}">
                                        {{ attention.state.name | firstLetterWordsIntoString }}
                                    </span>
                                </mat-chip>
                            </mat-chip-listbox>
                            <mat-spinner diameter="20" *ngIf="attention?.isLoadingStates" class="flex align-center-center"></mat-spinner>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let attention">
                        {{ attention.id }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- practice Column -->
                <ng-container matColumnDef="practice">
                    <th mat-header-cell *matHeaderCellDef>Especialidad</th>
                    <td mat-cell *matCellDef="let attention">
                        <div class="inner-cell">
                            {{ !!attention.practice?.name ? attention.practice.name : 'Sin datos'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- evolutions Column -->
                <ng-container matColumnDef="evolutions">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="center">
                            <mat-icon *ngIf="this.showReload" matRipple (click)='this.onClickReload.emit()'
                                class="text-primary cursor-pointer" matTooltip="Refrescar">refresh</mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let attention">
                        <div class="inner-cell">
                            <button mat-icon-button [color]="'primary'"
                                (click)="stopPropagation($event); onClickEvolutions(attention)"
                                matTooltip="Ver evoluciones" [disabled]="attention.loadingEvolutions">
                                <mat-icon *ngIf="!attention.loadingEvolutions; else loadingEvolutionsIcon"
                                    [svgIcon]="'ListPlus-enabled-primary'"></mat-icon>
                                <ng-template #loadingEvolutionsIcon>
                                    <mat-icon>
                                        <mat-spinner color="primary" diameter="20">
                                        </mat-spinner>
                                    </mat-icon>
                                </ng-template>
                            </button>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- evolve Column -->
                <ng-container matColumnDef="evolve">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let attention">
                        <div class="flex-row align-space-around stretch">
                            <button mat-icon-button *ngIf="this.attentionService.isEvolvable(attention)"
                                (click)="stopPropagation($event); openDialog(attention)" matTooltip="Evolucionar">
                                <mat-icon class="text-secondary m-0"
                                    [svgIcon]="'ListPlus-enabled-primary-lighter'"></mat-icon>
                            </button>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- signature Column -->
                <ng-container matColumnDef="signature">
                    <th mat-header-cell *matHeaderCellDef>Firmada</th>
                    <td mat-cell *matCellDef="let attention">
                        <div class="inner-cell">
                            <div class="flex-row align-space-around stretch">
                                <mat-icon
                                    *ngIf="attention.signed!=null && attention.signed!=undefined && attention.signed"
                                    class="text-primary">verified</mat-icon>
                                <mat-icon
                                    *ngIf="attention.signed!=null && attention.signed!=undefined && !attention.signed"
                                    class="text-warn">highlight_off</mat-icon>
                            </div>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Actions Bill/Facturar o Debit/Debitar -->
                <ng-container matColumnDef="bill-debit">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="flex-row align-space-around none" style="gap: 0.4em;">
                            <span>Facturar</span>
                            <span>Debitar</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let attention">
                        <mat-radio-group class="align-space-around end" (click)="$event.stopPropagation()"
                            [disabled]="attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA">
                            <mat-radio-button #buttonB class="subtitle-3 text-primary-darker" [value]="'toBill'"
                                (click)="!(attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA) && checkState(buttonB, attention)"></mat-radio-button>
                            <mat-radio-button #buttonD class="subtitle-3 text-primary-darker" [value]="'toDebit'"
                                (click)="!(attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA) && checkState(buttonD, attention)"></mat-radio-button>
                        </mat-radio-group>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let data" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                            [@detailExpand]="data == expandedElement ? 'expanded' : 'collapsed'">
                            <!-- Attention -->
                            <div class="mxs-3 mbs-3 mts-2 flex-100 flex-column" *ngIf="data == expandedElement">
                                <h3 class="text-primary-darker mys-2">Datos adicionales de la atención
                                    <span class="text-primary">#{{data.id}}</span>
                                </h3>
                                <div class="flex-95 text-secondary mls-2 align-center-start">
                                    <span class="flex-auto">
                                        <span class="text-secondary-darker">
                                            Agenda:
                                        </span>
                                        {{
                                        data && data.fromScheduled
                                        ? (data.fromScheduled | date: 'dd/MM/yyyy HH:mm')
                                        : ''
                                        }}
                                        {{
                                        data && data.toScheduled
                                        ? ' a ' +
                                        (data.toScheduled | date: 'dd/MM/yyyy HH:mm')
                                        : ''
                                        }}
                                    </span>
                                    <span class="flex-auto" *ngIf="data.state.id >= 3">
                                        <span class="text-secondary-darker">
                                            Ingreso:
                                        </span>
                                        {{
                                        data && data.fromDate
                                        ? (data.fromDate | date: 'dd/MM/yyyy HH:mm')
                                        : '-'
                                        }}
                                    </span>
                                    <span class="flex-auto" *ngIf="data.state.id >= 3">
                                        <span class="text-secondary-darker">
                                            Egreso:
                                        </span>
                                        {{
                                        data && data.toDate
                                        ? (data.toDate | date: 'dd/MM/yyyy HH:mm')
                                        : '-'
                                        }}
                                    </span>
                                    <span class="flex-auto" *ngIf="data.state.id >= 3">
                                        <span class="text-secondary-darker">
                                            Cantidad declarada:
                                        </span>
                                        {{ !!data?.quantity ? (data?.quantity + ' ' + data?.unit) : '-' }}
                                    </span>
                                </div>
                                <div class="mys-2" *ngIf="data.length > 0">
                                    <h3 class="mb-0 text-primary-darker">Evoluciones</h3>
                                    <div *ngFor="let evolution of data; let j = index" class="mls-2">
                                        <div class="flex-100 flex-column">
                                            <div class="flex-row space-between start">
                                                <div class="flex-68">
                                                    <h4 class="text-secondary-darker mb-0 pb-0">
                                                        <!-- {{ !!evolution.operator && !!evolution.operator.name ?
                                                        evolution.operator.name :'' }}
                                                        <span class="text-secondary"
                                                            *ngIf="!!evolution.operator && !!evolution.operator.name">
                                                            en nombre de
                                                        </span> -->
                                                        {{
                                                        !!evolution.provider &&
                                                        !!this.entitiesFacade.getProvidersFullName(evolution?.provider)
                                                        ? this.entitiesFacade.getProvidersFullName(evolution?.provider)
                                                        : 'Sin nombre' }}
                                                        —
                                                        <span [ngSwitch]="evolution.category.id">
                                                            <span class="text-warn"
                                                                *ngSwitchCase="EVOLUTIONS_TYPE.ABSENT_PATIENT">
                                                                &nbsp;Paciente ausente
                                                            </span>
                                                            <span *ngSwitchCase="EVOLUTIONS_TYPE.NORMAL">
                                                                &nbsp;
                                                                Evolución médica
                                                            </span>
                                                            <span class="text-warn"
                                                                *ngSwitchCase="EVOLUTIONS_TYPE.NOT_GOING">
                                                                &nbsp;Prestador ausente
                                                            </span>
                                                            <span class="text-warn"
                                                                *ngSwitchCase="EVOLUTIONS_TYPE.CRITICAL">
                                                                &nbsp;Evolución médica crítica
                                                            </span>
                                                            <span class="text-primary-darker"
                                                                *ngSwitchCase="EVOLUTIONS_TYPE.DISCHARGE">
                                                                &nbsp;Alta médica
                                                            </span>
                                                            <span *ngSwitchDefault>{{evolution?.category?.description}}
                                                            </span>
                                                        </span>
                                                    </h4>


                                                </div>
                                                <div class="flex-30 fex-column text-secondary">
                                                    <div class="end-start">
                                                        <span
                                                            class="text-primary-darker">E{{evolution.id}}</span>&nbsp;-&nbsp;
                                                        <span>
                                                            {{ evolution.date | date: 'dd/MM/yyyy HH:mm' }}
                                                        </span>
                                                        <!-- <div>
                                                        <mat-icon class="cursor-pointer text-secondary" [matMenuTriggerFor]="menuEvolutions"
                                                            [matMenuTriggerData]="{ attention:attention, evolution:evolution }">more_vert
                                                        </mat-icon>
                                                    </div> -->
                                                    </div>
                                                    <!-- <div class="mts-2 center-center" *ngIf="evolution.hasMedia">
                                                    <button mat-icon-button *ngIf="fileUtilitiesService.isPdf(evolution.photoDataUrl)"
                                                        (click)="fileUtilitiesService.downloadFile(evolution.photoDataUrl)"
                                                        matTooltip="Descargar">
                                                        <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30" [svgIcon]="'pdf'">
                                                        </mat-icon>
                                                    </button>
                                                    </div> -->

                                                </div>
                                            </div>
                                            <div class="flex-row text-secondary-darker">

                                                <span class="text-secondary mat-small multi_lines_text" [innerHTML]="evolution.text | safeHtml">
                                                </span>
                                            </div>
                                            <mat-divider class="mys-2 " *ngIf="j+1 < data.evolutions.length"
                                                [inset]="true"></mat-divider>
                                        </div>
                                    </div>
                                </div>
                                <div class="align-center-start mts-3" *ngIf="showGoToCase">
                                    <button mat-stroked-button [color]="'primary'" *ngIf="data && data.caseId"
                                        [routerLink]="[routes.CASES, data.caseId ]">
                                        IR AL CASO
                                    </button>
                                </div>
                                <div class="flex-row align-center-center mts-2" *ngIf="!!data.errorMessage"
                                    style="gap: 0.5em;">
                                    <mat-icon class="text-warn">report</mat-icon>
                                    <span>{{data.errorMessage }}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container>
                    <tr mat-header-row *matHeaderRowDef="['title']"></tr>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef=" let row; columns: displayedColumns" class="bg-on-hover example-element-row"
                    [class.example-expanded-row]="expandedElement === row"
                    (click)="expandedElement = expandedElement === row ? null : row"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    class="bg-on-hover parentShowOnHover example-detail-row"></tr>
                <ng-container *ngIf="this.showTotalQuantity">
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </ng-container>
            </table>
        </div>

        <mat-paginator #attentionsPaginator *ngIf="this.dataSourceAttentions?.data?.length > 0 && this.paginatorOn"
            class="flex-100" [pageSize]="this.pageSize" [pageIndex]="this.pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="!this.keepSelection ? this.selection.clear() : null"
            showFirstLastButtons>
        </mat-paginator>

        <!-- Supply Table -->
        <div *ngIf="this.supplyList" style="max-height: 85vh; overflow:auto;"
            [ngClass]="{'scrollOff': this.scrollOn==false}">

            <table multiTemplateDataRows class="flex-100 mat-elevation-z0 mt-0" mat-table
                [dataSource]="this.dataSourceSupplies.data">

                <!-- Master Head 1 -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef colspan="25" style="text-align: center;">
                        <span>Insumos</span>
                    </th>
                </ng-container>

                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *ngIf="this.checkBoxActive" style="margin-right: 5px"
                            (change)="$event ? masterToggleSupply() : null"
                            [checked]="supplySelection.hasValue() && isAllSupplySelected()"
                            [indeterminate]="supplySelection.hasValue() && !isAllSupplySelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div>
                            <mat-checkbox *ngIf="this.ruleClickable(row); else noClickeable"
                                (click)="$event.stopPropagation()"
                                (change)="$event ?  supplySelection.toggle(row) : null"
                                [checked]="this.supplySelection.isSelected(row)">
                            </mat-checkbox>
                            <ng-template #noClickeable>
                                <span>

                                </span>
                            </ng-template>

                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- dateFrom Column -->
                <ng-container matColumnDef="fromDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            <a>{{ data.fromDate | date: "dd/MM/yyyy" }}</a>
                            <span class="text-secondary" *ngIf="data?.liquidation?.id"
                                [matTooltip]="data?.liquidation?.name">#{{data?.liquidation?.id}}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Cant.</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.quantity ? data.quantity : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- unit Column -->
                <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef>Unidad</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.unit ? data.unit : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column mys-1">
                            <b>
                                Total
                            </b>
                            <span class="mat-caption">
                                Total debitada
                            </span>
                        </div>
                    </td>
                </ng-container>

                <!-- price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Precio.</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            <!-- TODO: Revisar la interfaz -->
                            {{!!data.providerFee ? (data.providerFee | currency: 'ARS':'symbol':'1.2') : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column">
                            <b>
                                {{(this.getTotalPrice() | currency: 'ARS':'symbol':'1.2')}}
                            </b>
                            <span>
                                {{(this.getTotalPriceDebited() | currency: 'ARS':'symbol':'1.2')}}
                            </span>
                        </div>
                    </td>

                </ng-container>

                <!-- provider Column -->
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>Proveedor</th>
                    <td mat-cell *matCellDef="let data">
                        <span *ngIf="!!this.entitiesFacade.getProvidersFullName(data?.provider)"
                            matTooltip="{{this.entitiesFacade.getProvidersFullName(data?.provider)}}">

                            {{
                            (this.entitiesFacade.getProvidersFullName(data?.provider)
                            ).toString().length > 15
                            ? ((this.entitiesFacade.getProvidersFullName(data?.provider)) | slice: 0:13) + '...'
                            : this.entitiesFacade.getProvidersFullName(data?.provider)
                            }}
                        </span>

                        <!-- <div *ngIf="isExpanded && this.entitiesFacade.getProvidersFullName(data?.provider)">
                            {{this.entitiesFacade.getProvidersFullName(data?.provider)}}
                        </div> -->
                        <div *ngIf="!this.entitiesFacade.getProvidersFullName(data?.provider)">
                            Sin datos
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- state Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let attention" [matTooltip]="this.matTooltipText(attention)"
                        matTooltipClass="multiline-tooltip">
                        <mat-chip-listbox *ngIf="!attention?.isLoadingStates">
                            <mat-chip style="white-space: nowrap; cursor: pointer;"
                                [ngStyle]="{'color':this.ATTENTIONS_COLORS[attention.state.id], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[attention.state.id]}"
                                selected [disabled]="attention?.isLoadingStates"
                                (click)="this.stopPropagation($event); !attention?.isLoadingStates ? this.onClickStateAttention(attention):null">
                                <span class="mat-small">
                                    {{ attention.state.name | titlecase | uppercase }}
                                </span>
                            </mat-chip>
                        </mat-chip-listbox>
                        <div class="align-center-center">
                            <mat-spinner diameter="20" *ngIf="attention?.isLoadingStates"></mat-spinner>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let attention">
                        {{ attention.id }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <!-- practice Column -->
                <ng-container matColumnDef="supply">
                    <th mat-header-cell *matHeaderCellDef>Insumo</th>
                    <td mat-cell *matCellDef="let attention">
                        {{ !!attention.practice?.name ? attention.practice.name : 'Sin datos'}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>

                </ng-container>

                <ng-container>
                    <tr mat-header-row *matHeaderRowDef="['title']"></tr>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsSupply"></tr>
                <tr mat-row *matRowDef=" let row; columns: displayedColumnsSupply"
                    class="bg-on-hover example-element-row"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
                <tr mat-footer-row *matFooterRowDef="displayedColumnsSupply"></tr>
                <!-- <ng-container *ngIf="this.showTotalQuantity">
                </ng-container> -->
            </table>
        </div>

        <mat-paginator #suppliesPaginator *ngIf="this.supplyList?.length > 0 && this.paginatorOn" class="flex-100"
            [pageSize]="this.pageSize" [pageIndex]="this.pageIndex" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="!this.keepSelection ? this.selection.clear() : null" showFirstLastButtons>
        </mat-paginator>

        <!-- Bottom buttons -->
        <div class="flex-row align-space-evenly-center mts-2">
            <!-- TOTALS -->
            <div *ngIf="this.bottomTotals" class="flex-60 flex-column start-start">
                <div class="row wrap start-none mts-2">
                    <div *ngFor="let totalByState of totalsByAttentionsState; let i=index">
                        <div *ngIf="totalByState!=0" class="flex-row wrap start-center" style="gap: 0.1em;">
                            <mat-chip-listbox>
                                <mat-chip style="white-space: nowrap; cursor: pointer;"
                                    [ngClass]="{ 'mat-chip-min-width-50px': this.descriptionStateShort }"
                                    [ngStyle]="{'color':this.ATTENTIONS_COLORS[i], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[i]}">
                                    <span class="mat-caption" matTooltip="{{ enumStates[i].split('_').join(' ') }}">
                                        {{ this.descriptionStateShort ? ( enumStates[i].split('_').join(' ') |
                                        firstLetterWordsIntoString ) : ( enumStates[i] | titlecase | uppercase ) }}
                                    </span>
                                </mat-chip>
                            </mat-chip-listbox>
                            <span class="mls-1 subtitle-3 text-secondary-darker mrs-5">{{ totalByState }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex-row start-start" style="gap: 1em;">
                    <span class="subtitle-3 text-secondary">TOTAL:</span>
                    <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.totalAttentions }}</span>
                    <span class="subtitle-3 text-secondary">TOTAL HS:</span>
                    <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.totalHours }}</span>
                </div>
            </div>

            <!-- BUTTONS -->
            <div class="flex-row space-evenly center" style="gap: 2em;">
                <button mat-flat-button [color]="this.colorButton1"
                    [disabled]="this.selection.selected.length == 0 && this.supplySelection.selected.length == 0"
                    (click)="onLocalClickButton1()">
                    <mat-icon *ngIf="this.iconButton1" [color]="this.colorIconButton1 ?this.colorIconButton1: ''">
                        {{this.iconButton1}}</mat-icon>
                    {{this.textButton1}}
                </button>

                <button *ngIf="this.textButton2" mat-flat-button [color]="this.colorButton2"
                    [disabled]="this.selection.selected.length == 0 && this.supplySelection.selected.length == 0"
                    (click)="onLocalClickButton2()">
                    <mat-icon *ngIf="this.iconButton2" [color]="this.colorIconButton2">{{this.iconButton2}}</mat-icon>
                    {{this.textButton2}}
                </button>

                <button *ngIf="this.textButton3" mat-button [color]="this.colorButton3"
                    [disabled]="!this.dataSourceAttentions.data || this.dataSourceAttentions.data.length < 1 || !isEnabledButton3()"
                    (click)="onLocalClickButton3()">
                    <mat-icon *ngIf="this.iconButton3" [color]="this.colorIconButton3">{{this.iconButton3}}</mat-icon>
                    {{this.textButton3}}
                </button>

                <!-- Process, bill or debit the attention -->
                <button *ngIf="this.textButton4" mat-flat-button [color]="this.colorButton4"
                    [disabled]="this.attentionsToBillDebit.length == 0" (click)="onLocalClickButton4()">
                    <mat-icon *ngIf="this.iconButton4" [color]="this.colorIconButton4">{{this.iconButton4}}</mat-icon>
                    {{this.textButton4}}
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #noAttentions>
    <div *ngIf="!loading; else loadingSpinner" class="flex-100 text-secondary-darker mts-4 center">
        <div *ngIf="!!this.dataSourceAttentions && !!this.dataSourceSupplies
                || (!!this.dataSourceAttentions?.data && this.dataSourceAttentions?.data?.length == 0)
                && (!!this.dataSourceSupplies?.data && this.dataSourceSupplies?.data?.length == 0)">
            <!-- <app-spinner-error></app-spinner-error> -->
            <span>
                {{this.textNoAttentions}}
            </span>
        </div>
        <div *ngIf="!this.dataSourceAttentions">
            <app-spinner-error></app-spinner-error>
            <span>
                Para ver la lista de atenciones, se debe efectuar un filtro de acuerdos previamente
            </span>
        </div>
    </div>
</ng-template>

<ng-template #loadingSpinner>
    <div *ngIf="this.loading" class="flex-100 pys-2 align-center-start">
        <mat-spinner class="mys-4" color="primary" diameter="40">
        </mat-spinner>
    </div>
</ng-template>
