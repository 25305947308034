/**
 * * Enum de las URLs de la aplicación
 */
export enum ROUTES {
   LOGIN = '/login',
   HOME = '/app/principal',
   PROFILE = '/app/perfil',
   CASES = '/app/casos/listado',
   ATTENTIONS = '/app/atenciones/listado',
   TASKS = '/app/tareas/listado',
   CREATE_CASE = '/app/casos/creacion',
   SCHEDULE = '/app/gestion/agenda',
   CONSOLIDATION = '/app/gestion/consolidacion',
   PROVISIONS_CONTROL = '/app/gestion/control-prestacional',
   DATA_MANAGEMENT = '/app/gestion-de-datos',
   DATA_MANAGEMENT_HEALTH_INSURANCE = '/app/gestion-de-datos/obras-sociales',
   DATA_MANAGEMENT_FINANCIERS = '/app/gestion-de-datos/clientes',
   DATA_MANAGEMENT_PROVIDERS = '/app/gestion-de-datos/prestadores',
   DATA_MANAGEMENT_OPERATORS = '/app/gestion-de-datos/operadores',
   DATA_MANAGEMENT_PRACTICES = '/app/gestion-de-datos/practicas',
   DATA_MANAGEMENT_SUPPLIES = '/app/gestion-de-datos/insumos',
   DATA_MANAGEMENT_LIQUIDATIONS = '/app/gestion-de-datos/liquidaciones',
   DATA_MANAGEMENT_CATEGORIES_OF_EVOLUTIONS = '/app/gestion-de-datos/tipos-de-evoluciones',
   DATA_MANAGEMENT_ARRANGEMENTS = 'app/gestion-de-datos/convenios',
   DATA_MANAGEMENT_FORMS = '/app/gestion-de-datos/forms',
   DATA_MANAGEMENT_TAGS = '/app/gestion-de-datos/etiquetas',
   ADMISSIONS = '/app/admisiones/dashboard',
   ADMISSIONS_LIST = '/app/admisiones/listado',
   ADMISSIONS_ARCHIVED = '/app/admisiones/archivadas',
   ADMISSIONS_CREATE = '/app/admisiones/crear',
   ADMISSIONS_ORDERS = '/app/admisiones/pedidos',
   ADMISSIONS_CASE_MANAGEMENT = '/app/admisiones/gestion',
   FIND_PROVIDERS = '/app/prestadores/buscar-prestadores',
}
