import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RootFacade } from '../abstraction/root.facade';
import { HttpBaseResponse } from '../shared/models/httpBaseResponse';
import { Tenant, TenantData } from '../shared/models/tenant';
import { User } from '../shared/models/user';
import { APIS } from './enums/apis';
import { StorageService } from './services/storage.service';
import { USERADMIN } from './enums/userAdmin';
import { USERFRONTDESK } from './enums/userFrontDesk';
export interface PostUserBody {
    id: string;
}
export interface PatchUserBody {
    active: boolean;
}
interface PostBodyPasswordReset {
    email: string;
    applicationId: string;
    tenantId: number;
}
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private user$: BehaviorSubject<User>;
    private tenant$: BehaviorSubject<Tenant>;
    private _redirectUrl: string;
    private applicationId = '2';

    constructor(
        private rootFacade: RootFacade,
        public dialogRef: MatDialog,
        private http: HttpClient,
        private storageService: StorageService
    ) {
        this.user$ = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.tenant$ = new BehaviorSubject<Tenant>(JSON.parse(localStorage.getItem('tenant')));
    }

    get redirectUrl(): string {
        return this._redirectUrl;
    }

    set redirectUrl(url: string) {
        this._redirectUrl = url;
    }

    // Get user value without subscription
    get userValue(): User {
        return this.user$.value;
    }

    get tenantValue(): Tenant {
        return this.tenant$.value;
    }

    // Get user value by subscription
    getUser$(): Observable<User> {
        return this.user$.asObservable();
    }

    // Get user value by subscription
    getTenant$(): Observable<Tenant> {
        return this.tenant$.asObservable();
    }

    isAuthenticated() {
        return !!this.userValue;
    }

    updateCurrentUser(user: User) {
        if (!!this.userValue) {
            // User exists, we must update its value
            const u = { ...this.userValue, ...user };
            // Save the updated object in local storage
            localStorage.setItem('user', JSON.stringify(u));
            // Update the BehaviourSubject with the new user
            this.user$.next(u);
        } else {
            // There's no user yet
            localStorage.setItem('user', JSON.stringify(user));
            // Update the BehaviourSubject with the new user
            this.user$.next(user);
        }
    }

    updateCurrentTenant(tenant: any) {
        if (!!this.tenantValue) {
            // user exists, we must update its value
            const t = { ...this.tenantValue, ...tenant };
            // Save the updated object in local storage
            localStorage.setItem('tenant', JSON.stringify(t));
            // Update the BehaviourSubject with the new user
            this.tenant$.next(t);
        } else {
            // There's no user yet
            localStorage.setItem('tenant', JSON.stringify(tenant));
            // Update the BehaviourSubject with the new user
            this.tenant$.next(tenant);
        }
    }

    removeCurrentUser() {
        // Remove user from local storage to log out
        localStorage.removeItem('user');
        // Set the currentUserSubject to null, indicating that the user is no longer logged in the app
        this.user$.next(null);
        this._redirectUrl = null;
    }

    removeCurrentTenant() {
        // Remove user from local storage to log out
        localStorage.removeItem('tenant');
        // Set the currentUserSubject to null, indicating that the user is no longer logged in the app
        this.tenant$.next(null);
    }

    //  login(username: string, password: string): Observable<any> {
    //     const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
    //     const body = {
    //        username: username,
    //        password: password,
    //        applicationId: this.applicationId,
    //        tenantCode: this.tenantValue?.id // TODO: descomentar cuando se vuelve a DEV
    //        //tenantCode: 8 // TODO: descomentar cuando se desea ejecutar QA
    //     };

    //     return this.http
    //        .post<any>(`${environment.apiAuthService}${APIS.LOGIN}`, body, {
    //           headers: queryHeaders,
    //           observe: 'response',
    //        })
    //        .pipe(
    //           map<HttpResponse<any>, any>((response) => {
    //              //  this.router.navigate([ROUTES.LOGIN]);
    //              return response.body.data;
    //           }),
    //        );
    //  }

    loginV2(username: string, password: string, tenantId?: number): Observable<HttpBaseResponse<TenantData>> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const body = {
            username: username,
            password: password,
            applicationId: this.applicationId,
            tenantId
        };

        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.LOGIN_V2}`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    //TODO: Resolve tenantId for reset password
    postResetPassword(email: string): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const body: PostBodyPasswordReset = {
            email,
            applicationId: this.applicationId,
            tenantId: 1
        };
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.USERS}/password-reset`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postUserFinancier(body: PostUserBody): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.USER}${APIS.USERS}/financiers`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postUserOperator(body: PostUserBody): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.USER}${APIS.USERS}/operators`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postUserProvider(body: PostUserBody): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.USER}${APIS.USERS}/providers`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postUserPatient(body: PostUserBody): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.USER}${APIS.USERS}/patients`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    patchUser(body: PatchUserBody, userId: string): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .patch<any>(`${environment.apiAuthService}${APIS.USER}${APIS.USERS}/${userId}`, body, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    logout(): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiAuthService}${APIS.LOGOUT}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
                tap(() => {
                    this.dialogRef.closeAll();
                    this.rootFacade.cleanStore();
                }),
                finalize(() => {
                    this.removeCurrentTenant();
                    this.removeCurrentUser();
                    this.storageService.removeValuesByKeyOnLocalStorage("consolidationsFilters");
                    this.clearSessionStorage();
                })
            );
    }

    clearSessionStorage() {
        sessionStorage.clear();
    }

    getUserData(): Observable<User> {
        const queryHeaders = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http
            .get<HttpBaseResponse<any>>(`${environment.apiAuthService}${APIS.USER}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<HttpBaseResponse<any>>, User>((response) => {
                    return response.body.data;
                }),
            );
    }

    isReachable(): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');

        return this.http
            .get<HttpBaseResponse<any>>(`https://api.adomicilio-sa.com.ar/`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<HttpBaseResponse<any>>, User>((response) => {
                    return response.body.data;
                }),
            );
    }

    postUser(entityId) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const body = {
            entityId
        };
        return this.http
            .post<HttpBaseResponse<any>>(`${environment.apiAuthService}${APIS.USERS}`,
                JSON.stringify(body),
                {
                    headers: queryHeaders,
                    observe: 'response',
                })
            .pipe(
                map<HttpResponse<any>, Tenant>((response) => {
                    return response.body.data;
                }),
            );
    }

    postAttention(attentions: { fromDate: Date, toDate: Date, stateId: number, agreementId: number }[]) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const body = attentions.map(attention => {
            const { stateId, ...noStateId } = attention;
            return { ...noStateId, state: { id: stateId } };
        });
        return this.http
            .post<any>(
                `${environment.apiAttentions}${APIS.ATTENTIONS}`,
                JSON.stringify(body),
                {
                    headers: queryHeaders,
                    observe: 'response',
                },
            )
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getTenant(tenantId: string): Observable<Tenant> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .get<HttpBaseResponse<any>>(`${environment.apiAuthService}${APIS.TENANTS}/${tenantId}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe(
                map<HttpResponse<any>, Tenant>((response) => {
                    return response.body.data;
                }),
            );
    }

    // TODO: MOCK -> Borrar y dejar el patchTenant2
    patchTenant(tenant: Partial<Tenant>): Observable<any> {
        return of(tenant).pipe(
            delay(3000),
            tap(tenant => this.updateCurrentTenant(tenant))
        );
    }

    patchTenant2(tenant: Partial<Tenant>) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(
                `${environment.apiAttentions}${APIS.ATTENTIONS}`,
                JSON.stringify(tenant),
                {
                    headers: queryHeaders,
                    observe: 'response',
                },
            )
            .pipe(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
                tap((tenant) => { this.updateCurrentTenant(tenant); }),
            );
    }

    // Validate if user is admin from localStorage
    isAdmin(): boolean {

        const user: Array<string> = JSON.parse(localStorage.getItem('user'));

        if (!!user) {
            const userRoles = user['roles'];
            for (let role in userRoles) {

                if (userRoles[role].toUpperCase().includes(USERADMIN.ROLE)) {
                    return true;
                }
            }
        }
        return false;
    }

    // Validate if user is frontdesk from localStorage
    isFrontDesk(): boolean {
        const userRoles: Array<string> = JSON.parse(localStorage.getItem('user')).roles;

        for (let role in userRoles) {

            if (userRoles[role].toUpperCase().includes(USERFRONTDESK.ROLE)) {
                return true;
            }
        }
        return false;
    }

    getValueTenant(): Tenant {
        return this.tenant$.getValue();
    }
}
