import { Injectable } from '@angular/core';
import { CasesState } from '../core/states/cases.state';
import { EntitiesState } from '../core/states/entities.state';
import { NomenclatorState } from '../core/states/nomenclator.state';
import { AttentionsState } from '../core/states/attentions.state';
import { TasksState } from '../core/states/tasks.state';
import { CategoriesofEvolutionsState } from '../core/states/categories-of-evolutions.state';
import { ArrangementsState } from '../core/states/arrangements.state';
import { ProvisionsState } from '../core/states/provisions.state';
import { MapsSate } from '../core/states/maps.state';

@Injectable({
    providedIn: 'root',
})
export class RootFacade {

    constructor(
        // ! Define all states here
        private casesState: CasesState,
        private entitiesState: EntitiesState,
        private nomenclatorState: NomenclatorState,
        private attentionState: AttentionsState,
        private tasksState: TasksState,
        private categoriesofEvolutionsState: CategoriesofEvolutionsState,
        private arrangementsState: ArrangementsState,
        private provisionsState: ProvisionsState,
        private mapsState: MapsSate
    ) { }

    cleanStore() {
        const properties = Object.getOwnPropertyNames(this);
        properties.forEach(p => {
            this[p].cleanStore();
        })
    }
}
