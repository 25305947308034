import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ConsolidationComponent } from './pages/consolidation/consolidation.component';
import { ProvisionalControlComponent } from './pages/provisional-control/provisional-control.component';
import { CaseComponent } from '../cases/pages/case/case.component';

const routes: Routes = [
   {
      path: '',
      redirectTo: 'agenda',
      pathMatch: 'full'
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'agenda',
      component: ScheduleComponent,
      data: { breadcrumb: 'Agenda' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'consolidacion',
      component: ConsolidationComponent,
      data: { breadcrumb: 'Consolidación' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'control-prestacional',
      component: ProvisionalControlComponent,
      data: { breadcrumb: 'Control Prestacional' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'control-prestacional/:id',
      component: CaseComponent,
      data: { breadcrumb: 'Control Prestacional >:id' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule],
})
export class ScheduleRoutingModule { }
