import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { RestUtilitiesService } from './rest-utilities.service';
import { Operator, OperatorTag } from 'src/app/shared/models/operator';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { Tag } from 'src/app/shared/models/tags';

export interface OperatorsQPS {
   fullName_like?: string;
   active?: boolean;
   page?: number,
   size?: number,
   order?: string,
   sort?: string,
   id?: number,
   expand?: string[]
}

export interface PostOperatorBody {
   name: string;
   surname: string;
   email?: string;
   phoneNumber?: string;
   photo?: string;
   tags?: Tag[];
}

export interface PatchOperatorBody {
   name?: string;
   surname?: string;
   email?: string;
   phoneNumber?: string;
   photo?: string;
   active?: boolean;
   tags?: OperatorTag[];
}

@Injectable({
   providedIn: 'root',
})
export class OperatorsService {
   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getOperators(qps?: OperatorsQPS): Observable<HttpBaseResponse<Operator[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
      return this.http
         .get<any>(`${environment.apiOperatorServer}${APIS.OPERATORS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   postOperator(entidad: PostOperatorBody): Observable<Operator> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiOperatorServer}${APIS.OPERATORS}`, JSON.stringify(entidad), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchOperator(entidad: PatchOperatorBody, entidadId) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiOperatorServer}${APIS.OPERATORS}/${entidadId}`,
            JSON.stringify(entidad),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getOperatorsCount(): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiOperatorServer}${APIS.OPERATORS}/count`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getMediaByOperatorId(operatorId: number): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiOperatorServer}${APIS.OPERATORS}/${operatorId}/media`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

}
