import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseState } from './base.state';
import { CategoryOfEvolution } from '../../shared/models/categoryOfEvolution';
import { Pagination } from '../../shared/models/pagination';
import { MetaDataCategoriesOfEvolutions } from '../../abstraction/categories-of-evolutions.facade';
import { PatchCategoryOfEvolution } from '../services/categories-of-evolutions.service';

@Injectable({
   providedIn: 'root',
})
export class CategoriesofEvolutionsState extends BaseState {

    override store = {

        // Categories of Solutions
        categoriesOfEvolutions$: new BehaviorSubject<CategoryOfEvolution[]>(null),
        loadingGetCategoriesofEvolutions$: new BehaviorSubject<boolean>(null),
        loadingCreatingCategorieofEvolution$: new BehaviorSubject<boolean>(null),
        loadingUpdatingCategorieofEvolution$: new BehaviorSubject<boolean>(null),

        // Pagination
        pagination$: new BehaviorSubject<Pagination>(null),
        loadingGettingPagination$: new BehaviorSubject<boolean>(false),

        // Metadata
        metaDataCategoriesOfEvolutions$: new BehaviorSubject<MetaDataCategoriesOfEvolutions>(null),

        // Count
        categoriesOfEvolutionsCount$: new BehaviorSubject<number>(null),
   };

   constructor() {
      super();
   }

   // Categories of Evolutions
   getCategoriesOfEvolutions$() {
      return this.store.categoriesOfEvolutions$.asObservable();
   }

   setCategoriesOfEvolutions( categoriesOfEvolutions: CategoryOfEvolution[]) {
      this.store.categoriesOfEvolutions$.next( categoriesOfEvolutions );
   }

   isLoadingGetCategoriesofEvolutions$() {
    return this.store.loadingGetCategoriesofEvolutions$.asObservable();
   }

   setLoadingGetCategoriesofEvolutions( isLoadingGetCategoriesofEvolutions: boolean ) {
      this.store.loadingGetCategoriesofEvolutions$.next( isLoadingGetCategoriesofEvolutions );
   }

   // ADD
   isLoadingCreatingCategorieofEvolution$() {
    return this.store.loadingCreatingCategorieofEvolution$.asObservable();
   }

   setLoadingCreatingCategoryOfEvolution( isLoadingCreatingCategoryOfEvolution: boolean ) {
      this.store.loadingCreatingCategorieofEvolution$.next( isLoadingCreatingCategoryOfEvolution );
   }

   addCaegoryOfEvolution( category: CategoryOfEvolution ) {
      this.add<CategoryOfEvolution>({ data: category, storeRefAttribute: this.store.categoriesOfEvolutions$ })
   }
   //

   // UPDATE
   setLoadingUpdatingCategoryofEvolution( isLoadingUpdatingCategoryOfEvolution: boolean ) {
      this.store.loadingUpdatingCategorieofEvolution$.next( isLoadingUpdatingCategoryOfEvolution );
   }

   updateCategoryOfEvolution(category: PatchCategoryOfEvolution, categoryId: number) {
      this.update<Partial<CategoryOfEvolution>>({ data: category, dataId: categoryId, storeRefAttribute: this.store.categoriesOfEvolutions$ })
   }

   isLoadingUpdatingCategorieofEvolution$() {
      return this.store.loadingUpdatingCategorieofEvolution$.asObservable();
   }

   // Pagination
   getPagination$(): Observable<Pagination> {
      return this.store.pagination$.asObservable();
   }

   setPagination(pagination: Pagination) {
      this.store.pagination$.next(pagination);
   }


   isLoadingGettingPagination$() {
      return this.store.loadingGettingPagination$.asObservable();
   }

   setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
      this.store.loadingGettingPagination$.next( isLoadingGettingPagination );
   }

   // Metadata
   getMetaDataCategoriesOfEvolutions$(): Observable<MetaDataCategoriesOfEvolutions> {
      return this.store.metaDataCategoriesOfEvolutions$.asObservable();
   }

   setMetaDataCategoriesOfEvolutions(md: MetaDataCategoriesOfEvolutions) {
      this.store.metaDataCategoriesOfEvolutions$.next( md );
   }

   // Count
   getCategoriesOfEvolutionsCount$(): Observable<number> {
      return this.store.categoriesOfEvolutionsCount$.asObservable();
   }

   setCategoriesOfEvolutionsCount( count: number) {
      this.store.categoriesOfEvolutionsCount$.next( count );
   }
}
