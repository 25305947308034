<div class="flex-column flex-100 mbs-2" *ngIf="!!this.case">
    @if(this.loadingSignature) {
    <app-spinner [minHeight]="200"></app-spinner>
    }
    <div class="flex-100 flex-row align-end-center header-buttons" style="height: 36px;">
        <!-- Add provision -->
        <button mat-icon-button [matTooltip]="'Agregar esquema prestacional'" [color]="'primary'"
            (click)="this.openAddProvisionDialog()" id="addArrangementButton" [disabled]="!this.case.active">
            <mat-icon>add_circle_outlined</mat-icon>
        </button>

        <button mat-icon-button [disabled]="this.selectionProvisionFees?.selected?.length == 0"
            [matMenuTriggerFor]="menuGlobalActions" (click)="$event.stopPropagation()" matTooltip="Acciones">
            <mat-icon class="cursor-pointer text-secondary" [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"
                [ngClass]="{ 'line-icon-grey': this.selectionProvisionFees?.selected?.length == 0 }"></mat-icon>
        </button>
    </div>
    <ng-container *ngIf="!this.loadingGetProvisions; else loadingProvisions">
        <table  tabindex="0"  mat-table style="width: 100%; border-radius: 10px;" [dataSource]="this.dataSource" multiTemplateDataRows>
            <!-- arrow Column -->
            <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"></th>
                <td mat-cell *matCellDef="let data" class="arrow-column">
                    <div *ngIf="!!data.provisionFees && data.provisionFees.length > 0" style="border:none !important">
                        <mat-icon [color]="'primary'" style="margin-top: 0.8rem;">
                            {{ data == expandedElement ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                        </mat-icon>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="provision">
                <th mat-header-cell [attr.rowspan]="2" *matHeaderCellDef>
                    {{ this.supply ? 'Insumo' : 'Práctica' }}
                </th>
                <td mat-cell *matCellDef="let data"
                    class="flex align-start-none margin-top-14 border-none padding-top-1">
                    <div #practicesTable *ngIf="!!data.provisionFees && data.provisionFees.length > 0" class="border-none width-100"
                        [ngStyle]="{ 'max-width': !this.isExpanded ? '5rem' : '' }">
                        <span class="subtitle-3 text-primary-darker text-ellipsis"
                            (mouseover)="practiceTooltip(); getPracticeCode(data); getPracticeArrangementName(data)"
                            (mouseout)="practiceTooltip()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
                            {{ !!data.practice?.name ? data.practice.name : 'Sin datos' }}
                        </span>
                        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="0"
                            [cdkConnectedOverlayOffsetY]="10" [cdkConnectedOverlayOrigin]="originOverlay"
                            [cdkConnectedOverlayOpen]="isTooltipOpen" [cdkConnectedOverlayWidth]="200">
                            <div class="flex-column" *ngIf="practiceForTooltip === data.practice.name">
                                <div class="bg-secondary-darker mat-font-verysmall-px pxs-2 pys-1">
                                    <span class="font-weight-500 text-primary-lighter">
                                        {{ practiceForTooltip }}
                                    </span>
                                </div>
                                <div class="bg-secondary-darker mat-font-verysmall-px pxs-2 pys-1" *ngFor="let code of practiceArrangementForTooltip">
                                    <div class="flex-row flex-none gap-0-1em">
                                        <span class="font-weight-500 text-primary-lighter">{{!!code.id? code.arrangementName : 'Sin convenio'}} - {{code.code}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef
                    [attr.colspan]="isExpanded ? columnsToDisplayExpanded.length : columnsToDisplayCollapsed.length"
                    class="border-radius-0-0-10-0">
                    <div *ngIf="this.isExpanded" class="flex-row align-space-around-center gap-2rem border-none">
                        <div>
                            <span class="subtitle-5 text-primary-darker font-weight-500">PRÁCTICAS:</span><span
                                class="mls-1 subtitle-5 text-primary font-weight-600">{{ !!this.dataSource.data ?
                                this.dataSource.data.length : 0 }}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker font-weight-500">TOTAL AGENDADO:</span><span
                                class="mls-1 subtitle-5 text-primary font-weight-600">{{ !btnsDisabled ?
                                (totalValueFooter | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker font-weight-500">TOTAL FACTURABLE:</span><span
                                class="mls-1 subtitle-5 text-primary font-weight-600">{{ !btnsDisabled ?
                                (totalInvoicedFooter | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker font-weight-500">TOTAL DEBITABLE:</span><span
                                class="mls-1 subtitle-5 text-primary font-weight-600">{{ !btnsDisabled ?
                                (totalDebitedFooter | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker font-weight-500">TOTAL INFORMADO:</span><span
                                class="mls-1 subtitle-5 text-primary font-weight-600">{{ !btnsDisabled ?
                                (totalDeliveredFooter | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- checkBox Column -->
            <ng-container matColumnDef="checkProvisionFee">
                <th mat-header-cell [attr.rowspan]="2" *matHeaderCellDef>
                    <div class="align-center-center">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selectionProvisionFees.hasValue() && isAllSelected()"
                            [indeterminate]="selectionProvisionFees.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row" style="min-width: 0px !important;">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell pxs-3">
                        <mat-checkbox class="align-center" (click)="$event.stopPropagation()" [indeterminate]="
                    this.isAgreementsIndeterminated(row) &&
                    this.selectionProvisionFees.isSelected(data)
                 " (change)="onChangeChildProvisionFee(row, data)" [checked]="this.verifyProvisionFeeSelection(data)">
                        </mat-checkbox>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- frequency Column -->
            <ng-container matColumnDef="frequency">
                <th mat-header-cell [attr.rowspan]="2" *matHeaderCellDef>
                    {{ this.supply ? 'Cantidad' : 'Frecuencia' }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        <div [matTooltip]="data?.authorization?.number ? data?.authorization?.number : 'Sin autorización'"
                            [matTooltipDisabled]="isExpanded"
                            [ngClass]="{ 'no-frequency': data?.frequency?.unit == 'Sin especificar' || data?.frequency?.amount == null }">
                            {{ this.frequencyOfProvision(data) }}
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Valor Unitario -->
            <!-- total Column -->
            <ng-container matColumnDef="fee">
                <th mat-header-cell [attr.rowspan]="2" *matHeaderCellDef>
                    <div class="flex-column align-center-center">
                        <span>Venta</span>
                        <span>Unitario</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row" style="width: 5rem;">
                    <div *ngFor="let data of row.provisionFees; let last = last; let idRecord = index"
                        class="inner-cell" (mouseover)="enableButtonEditForProvisionFeeHover(data.id)"
                        (mouseleave)="enableButtonEditForProvisionFeeHover(0)">
                        <div *ngIf="!!this.verifyVariationOnPrice(row, data)">
                            <div cdkOverlayOrigin #tooltipOverlay="cdkOverlayOrigin" (mouseover)="showTooltip()"
                                (mouseout)="showTooltip()">
                                <div class="flex-row" style="cursor: pointer;">
                                    <span>{{ !!totalUnitOfProvision(row, data) ? (totalUnitOfProvision(row,
                                        data).toFixed(2) | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                                    <mat-icon [style.width.px]="8" [style.height.px]="8" [svgIcon]="'ellipseRed'"
                                        class="pb-2"></mat-icon>
                                </div>
                            </div>
                            <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="-50"
                                [cdkConnectedOverlayOffsetY]="5" [cdkConnectedOverlayOrigin]="tooltipOverlay"
                                [cdkConnectedOverlayOpen]="isOpenTooltip" [cdkConnectedOverlayWidth]="250"
                                *ngIf="data.id === this.provisionFeeIdforHover">
                                <div class="flex-column flecha-lado-izquierdo-arriba">
                                    <div class="bg-secondary-lighter mat-font-verysmall-px pxs-2 pys-1"
                                        *ngFor="let item of this.getVariationPriceTooltip(row, data)">
                                        <div class="body-3 flex-row flex-none gap-0-1em">
                                            <span class="font-weight-500">
                                                {{ item.fee != -1 ? (item.fee.toFixed(2) | currency:
                                                'ARS':'symbol':'1.2') : 'No definido' }}: {{ item.fromDate | date:
                                                'dd/MM/yyyy' }} - {{ !!item.toDate ? (item.toDate | date: 'dd/MM/yyyy')
                                                : 'en adelante' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="!this.verifyVariationOnPrice(row, data)">
                            <span style="min-width: 50px;">{{ !!totalUnitOfProvision(row, data) ?
                                (totalUnitOfProvision(row, data) | currency: 'ARS':'symbol':'1.2') : '-' }}</span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Total Amount - Total Agendado -->
            <ng-container matColumnDef="totalAmount">
                <th mat-header-cell [attr.rowspan]="2" *matHeaderCellDef>
                    <div class="flex-column align-center-center">
                        <span>Total</span>
                        <span>Agendado</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell"
                    [ngClass]="{'marked-top': !!data?.paintedType}"
                    >
                        <span
                          matTooltip="{{ this.getTooltipUnitDescription(row) }}">
                            {{ !btnsDisabled ? totalOfProvision(row, data) : '-' }}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Total Realizado -->
            <ng-container matColumnDef="totalRealized">
                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">
                    <div class="state-header flex-column align-center-center"
                        style="border-radius: 10px 0px 0px 0px; border-right: none !important; width: 100%; height: 34px;">
                        Realizado
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        <span matTooltip="{{ this.getTooltipUnitDescription(row) }}">
                            {{ !btnsDisabled ? totalRealizedOfProvision(row, data) : '-' }}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Valor Total -->
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="flex-column align-start-none">
                        <span>Valor</span>
                        <span>total</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        {{ !!totalValueOfProvision(data) ? (totalValueOfProvision(data) | currency:
                        'ARS':'symbol':'1.2') : '-' }}
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Facturable -->
            <ng-container matColumnDef="billed">
                <th mat-header-cell *matHeaderCellDef class="state-header">Facturable</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        <span matTooltip="{{ this.getTooltipUnitDescription(row) }}">
                            {{ !btnsDisabled ? invoicedOfProvision(row, data) : '-' }}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Debitable -->
            <ng-container matColumnDef="debited">
                <th mat-header-cell *matHeaderCellDef class="state-header">Debitable</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        <span matTooltip="{{ this.getTooltipUnitDescription(row) }}">
                            {{ !btnsDisabled ? debitedOfProvision(row, data) : '-' }}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Informado -->
            <ng-container matColumnDef="fullyReported">
                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter" style="border-right: 1px solid #FFF;">
                    <div class="state-header flex-column align-center-center"
                        style="border-radius: 0px 10px 0px 0px; border-left: none !important; height: 34px;">
                        <!-- <span>Total</span> -->
                        <span>Informado</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last" class="inner-cell">
                        <span matTooltip="{{ this.getTooltipUnitDescription(row) }}">
                            {{ !btnsDisabled ? fullyDeliveredOfProvision(row, data) : '-' }}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- PROVISION STATE -->
            <!-- validity Column -->
            <ng-container matColumnDef="validity">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Estado</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngFor="let data of row.provisionFees; let last = last; let idRecord = index" class="inner-cell-auth inner-cell">
                        <mat-chip-listbox>
                            <mat-chip
                                    [ngStyle]="{
                                        'background-color': this.getBackgroundColorAuthorizationState(data),
                                        color: this.getColorAuthorizationState(data)
                                    }"
                                    matTooltip="{{
                                            data.authorization
                                            ? '#' + data.authorization.authorizationNumber + ' - ' + this.generalService.getAuthorizationState(data)
                                            : this.generalService.getAuthorizationState(data)
                                    }}"
                                    (click)="this.onClickAuthorization(data); $event.stopPropagation()"
                                    matBadge="P"
                                    [matBadgeHidden]="data.arrangementId"
                                    matBadgeColor="accent"
                                    matBadgeSize="small">
                                <span class="mat-small cursor-pointer">
                                    {{ this.generalService.getAuthorizationState(data) | firstLetterWordsIntoString }}
                                </span>
                            </mat-chip>
                        </mat-chip-listbox>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="vigency">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Vigencia</th>
                <td mat-cell *matCellDef="let provision" style="width:6rem;">
                    <div *ngFor="let data of provision.provisionFees; let last = last; let idRecord = index"
                        class="inner-cell">
                        <div class="flex-row align-center-center">
                            <div>
                                {{ !!provisionFeeFromDate(data) ? (provisionFeeFromDate(data) | date: 'dd/MM/yyyy') : ''
                                }}
                                {{ provisionFeeToDate(data) | date: 'dd/MM/yyyy' }}
                            </div>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!--ACTIONS COLUMN-->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"></th>
                <td mat-cell *matCellDef="let prov" style="min-width: 1rem !important;">
                    <div *ngFor="let data of prov.provisionFees; let last = last; let idRecord = index"
                        class="inner-cell">
                        <button mat-icon-button (click)="$event.stopPropagation()" matTooltip="Acciones"
                            [matMenuTriggerFor]="menuActions"
                            [matMenuTriggerData]="{ provision: prov, provisionFee: data }">
                            <mat-icon class="cursor-pointer text-secondary"
                                [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"></mat-icon>
                        </button>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns 1-->
            <!-- Agreements -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let data"
                    [attr.colspan]="isExpanded ? columnsToDisplayExpanded.length : columnsToDisplayCollapsed.length"
                    style="padding-left:0 !important;">
                    <div class="example-element-detail"
                        [@detailExpand]="data.id == expandedElement ? 'expanded' : 'collapsed'">
                        <!-- Agreements -->
                        <div class="flex-column">
                            <div class="shadow"></div>
                            <!-- GRID / agreements-grid -->
                            <!-- GRID WITH 4 COLUMNS TO NOT EXPANDED  -->
                            <!-- GRID WITH 9 COLUMNS TO EXPANDED     -->
                            <div class="agreements-grid" style="width: 100%"
                                [ngClass]="{ 'agreements-grid-cant-columns-13': this.isExpanded, 'agreements-grid-cant-columns-5': !this.isExpanded}">
                                <!-- HEADER -->
                                <div class="bg-primary-lighter agreement-header"></div>
                                <!-- Checkbox -->
                                <div class="bg-primary-lighter agreement-header">
                                    <span class="subtitle-3 text-primary">Acuerdo prestacional</span>
                                </div>
                                <div *ngIf="this.isExpanded" class="bg-primary-lighter agreement-header">
                                    <span class="subtitle-3 text-primary">Total agendado</span>
                                </div>
                                <div *ngIf="!this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Especialidad</span>
                                </div>
                                <div style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Costo</span>
                                </div>
                                <div style="text-align: center;" *ngIf="this.isExpanded"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Realizadas</span><!--  -->
                                </div>
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">No realizadas</span>
                                </div>
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Facturables</span>
                                </div>
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Debitables</span>
                                </div>
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Informadas</span>
                                </div>
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="text-primary bg-primary-lighter agreement-header">
                                    <span class="subtitle-3">Vigencia</span>
                                </div>
                                <!-- Button edit and delete together-->
                                <div *ngIf="this.isExpanded" style="text-align: center;"
                                    class="bg-primary-lighter agreement-header">
                                    <button [style.grid-column-start]="this.isExpanded ? 11 : 4" [disabled]="(
                                        isLoadingCreatingAgreement$ | async)
                                        || !this.case.active
                                        || (!!this.case['caseDerivations'] && this.case['caseDerivations'].length > 0)"
                                        mat-icon-button (click)="this.openAddAgreementDialog(data)"
                                        [matTooltip]="'Crear acuerdo prestacional'">
                                        <mat-icon [svgIcon]="'PlusCircle-enabled-primary-lighter'"></mat-icon>
                                    </button>
                                </div>
                                <!-- END HEADER -->
                                <!-- CONTENT GRID -->
                                <ng-container *ngFor="let agreement of data.agreements" style="height: 52px;">
                                    <!-- Checkbox -->
                                    <!-- Expanded/Not expanded #1 -->
                                    <mat-checkbox class="align-center-center grid-text-center"
                                        (click)="$event.stopPropagation()" (change)="onChangeChild(data, agreement)"
                                        [disabled]="!isPracticeSelected(data)"
                                        [checked]="this.selectionAgreements?.isSelected(agreement) && isPracticeSelected(data)">
                                    </mat-checkbox>
                                    <!-- View expanded -->
                                    <ng-container *ngIf="this.isExpanded; else collapseAgreements">
                                        <!-- Expanded #3 - Name provider -->
                                        <div class="align-start-center grid-text-center pls-3" style="display: flex;">
                                            <span>{{!!agreement?.provider
                                                ? this.entitiesFacade?.getProvidersFullName(agreement?.provider)
                                                : ''}}
                                            </span>
                                            <button mat-icon-button *ngIf="agreement?.provider?.hasSignature"
                                                color="accent" aria-label="Firma del prestador"
                                                [matTooltip]="'Firma del prestador'"
                                                (click)="this.onClickSignature(agreement?.provider)">
                                                <mat-icon>verified</mat-icon>
                                            </button>
                                        </div>
                                        <!-- Expanded #4 - Total agendado -->
                                        <div class="align-center-center grid-text-center">
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}"
                                                [ngClass]="{ autoProviderFee: true }">
                                                {{ agreementTotal(data, agreement) }}
                                            </span>
                                        </div>
                                        <!-- Expanded #5 - Costo - fee -->
                                        <div class="align-center-center grid-text-center">
                                            <span [ngClass]="{ autoProviderFee: true }">
                                                {{ agreement?.providerFee | currency: 'ARS':'symbol':'1.2' }}
                                            </span>
                                        </div>
                                        <!-- Expanded #6 - Realizadas -->
                                        <div class="align-center-center grid-text-center">
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}">{{
                                                agreementPerformed(data, agreement)
                                                }}</span>
                                        </div>
                                        <!-- Expanded #7 - No realizadas -->
                                        <div class="align-center-center grid-text-center">
                                            <!-- Expanded #6 -->
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}">{{
                                                agreementNotPerformed(data, agreement)
                                                }}</span>
                                        </div>
                                        <!-- Expanded #8 - Facturables -->
                                        <div class="align-center-center grid-text-center">
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}">{{
                                                agreementInvoiced(data, agreement)
                                                }}</span>
                                        </div>
                                        <!-- Expanded #9 - Debitables -->
                                        <div class="align-center-center grid-text-center">
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}">{{
                                                agreementDebited(data, agreement)
                                                }}</span>
                                        </div>
                                        <!-- Expanded #10 - Informadas -->
                                        <div class="align-center-center grid-text-center">
                                            <span matTooltip="{{ this.getTooltipUnitDescription(data) }}">
                                                {{ agreementInformed(data, agreement) }}
                                            </span>
                                        </div>
                                        <!-- Expanded #11 - Fecha -->
                                        <div class="align-center-center grid-text-center" *ngIf="this.isExpanded">
                                            <span>
                                                {{ agreement?.fromDate | date: 'dd/MM/yyyy' }}
                                                {{ agreement?.toDate | date: 'dd/MM/yyyy' }}
                                            </span>
                                        </div>
                                        <!-- Expanded #12 - Button Edit -->
                                        <div class="align-center grid-text-center">
                                            <button mat-icon-button (click)="$event.stopPropagation()"
                                                matTooltip="Acciones" [matMenuTriggerFor]="menuAgreement"
                                                [matMenuTriggerData]="{
                                                provision: data,
                                                agreement: agreement}">
                                                <mat-icon class="cursor-pointer text-secondary"
                                                    [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"
                                                    *ngIf="this.isExpanded"></mat-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <!-- View NOT expanded -->
                                    <ng-template #collapseAgreements>
                                        <!-- Name provider -->
                                        <span class="align-center my-3">
                                            {{
                                            !!agreement?.provider
                                            ? this.entitiesFacade?.getProvidersFullName(
                                            agreement?.provider
                                            )
                                            : ''
                                            }}</span>
                                        <!-- Specialties provider -->
                                        <span class="align-center my-3" [matTooltip]="
                                    this.entitiesFacade.getProviderSpecialties(
                                       agreement?.provider?.specialties
                                    )
                                 ">
                                            {{
                                            this.entitiesFacade.getProviderSpecialties(
                                            agreement?.provider?.specialties | slice: 0:2
                                            )
                                            }}...
                                        </span>
                                        <!-- Provider fee -->
                                        <div class="grid-text-center align-center my-3">
                                            <span>{{
                                                agreement?.providerFee | currency: 'ARS':'symbol':'1.2'
                                                }}</span>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <!-- END CONTENT GRID -->
                                <!-- Button Crear acuerdo -->
                                <div
                                    [ngClass]="{ '.agreements-grid-start-1-end-10': this.isExpanded, '.agreements-grid-start-1-end-4': !this.isExpanded }">
                                </div>
                                <div [style.grid-column-start]="this.isExpanded ? 11 : 4">
                                    <button *ngIf="!this.isExpanded" [disabled]="
                                        (isLoadingCreatingAgreement$ | async)
                                        || !this.case.active
                                        || (!!this.case['caseDerivations'] && this.case['caseDerivations'].length > 0)"
                                        mat-icon-button (click)="this.openAddAgreementDialog(data)"
                                        [matTooltip]="'Crear acuerdo prestacional'">
                                        <mat-icon [svgIcon]="'PlusCircle-enabled-primary-lighter'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <!-- End GRID -->
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!--Containers used to generate a row under the table header for attentions spinner-->
            <ng-container matColumnDef="progressBarProvisions">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="6">
                    <mat-progress-bar [mode]="'determinate'" [value]="100"
                        progressBarId="attentionsLoading"></mat-progress-bar>
                </th>
            </ng-container>

            <ng-container matColumnDef="progressBarAttentions">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="border-none">
                    <mat-progress-bar [mode]="'indeterminate'" progressBarId="attentionsLoading"
                        *ngIf="this.loadingGetAttentions"></mat-progress-bar>
                    <mat-progress-bar [mode]="'determinate'" [value]="100" progressBarId="attentionsLoading"
                        *ngIf="!this.loadingGetAttentions"></mat-progress-bar>
                </th>
            </ng-container>

            <ng-container matColumnDef="progressBarState">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
                    <mat-progress-bar [mode]="'determinate'" [value]="100"
                        progressBarId="attentionsLoading"></mat-progress-bar>
                </th>
            </ng-container>

            <ng-container matColumnDef="states">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">
                    Estados
                </th>
                <td mat-cell class="display-none" *matCellDef></td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Rows definitions -->
            <tr mat-header-row *matHeaderRowDef="isExpanded ? headerColumns : columnsToDisplayCollapsed"></tr>
            <tr mat-header-row *matHeaderRowDef="this.isExpanded ? statesColumns : null" style="height: fit-content;">
            </tr>
            <tr mat-header-row *matHeaderRowDef="['progressBarProvisions', 'progressBarAttentions', 'progressBarState']"
                class="height-4px"></tr>
            <tr mat-row
                *matRowDef="let element; columns: isExpanded ? columnsToDisplayExpanded : columnsToDisplayCollapsed"
                class="bg-on-hover parentShowOnHover cursor-pointer"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element.id ? null : element.id"
                (mouseover)="enableButtonEditForProvisionHover(element.id)"
                (mouseleave)="enableButtonEditForProvisionHover(0)">
            </tr>
            <!-- [ngClass]="{ 'autoArrangement': true }" -->
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                class=" bg-on-hover parentShowOnHover example-detail-row"></tr>
            <tr mat-footer-row *matFooterRowDef="isExpanded ? columnsToDisplayExpanded : columnsToDisplayCollapsed"
                [ngClass]="{ 'bg-primary-lighter': this.isExpanded }">
            </tr>
        </table>
    </ng-container>

    <ng-template #loadingProvisions>
        <div class="align-center-center height-100">
            <!-- <mat-spinner diameter="60"></mat-spinner> -->
        </div>
    </ng-template>
</div>

<!-- ADD / UPDATE PROVISION -->
<ng-template #provisionTemplate>
    <form class="flex-column flex-100" [formGroup]="this.formProvision" (submit)="onClickConfirmFormProvision()">
        <div class="flex-column">
            <div class="flex-row space-between-start" [ngClass]="{ 'mbs-3': !this.isAddModeProvision }">
                <div class="flex-column align-start-start gap-gappx">
                    <!-- Practice -->
                    <div class="flex-row align-start-center gap-0-5em" [hidden]="this.isAddModeProvision">
                        <span class="text-primary">Práctica: </span>
                        <span class="subtitle-2 text-primary-darker">{{
                            !!this.provisionToWork?.practice?.name
                            ? this.provisionToWork.practice.name
                            : ''
                            }}</span>
                    </div>
                    <!-- Arrangement -->
                    <div class="flex-row align-start-center gap-0-5em" [hidden]="this.isAddModeProvision">
                        <span class="text-primary">Convenio: </span>
                        <span class="subtitle-2 text-primary-darker">{{
                            !!this.arrangementSelected ? this.arrangementSelected.name : 'Sin convenio'
                            }}</span>
                    </div>
                </div>
                <!-- Retroactive -->
                <div class="flex-row flex-100 align-end-center"
                    *ngIf="isShowRetroactive && this.provisionFeeToWork?.arrangementId == null">
                    <mat-slide-toggle [color]="'primary'" (change)="toogleRetroactive($event.checked)">
                        <span class="text-primary subtitle-2">Retroactivo</span>
                    </mat-slide-toggle>
                </div>
            </div>

            <!-- Radiobutton arrangements - hidden if isShowRetroactive = false -->
            <div class="flex-column" *ngIf="!isShowRetroactive && !!isAddModeProvision">
                <span class="subtitle-3 text-primary-darker">Convenio: </span>
                <mat-radio-group class="flex-column subtitle-3 text-primary-darker mbs-3" formControlName="arrangement"
                    [name]="this.ARRANGEMENT" (change)="this.radioButtonArrangementSelected($event)">
                    <mat-radio-button *ngFor="let element of this.caseArrangements" [value]="element.id" class=""
                        style="width: max-content;">
                        {{ element.name }}
                    </mat-radio-button>
                    <mat-radio-button [value]="'S/C'" class="">Sin convenio</mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- Practice -->
            <div class="flex-column" [hidden]="!this.isAddModeProvision">
                <span class="subtitle-3 text-primary-darker">Seleccione práctica *</span>
                <app-select-searching-input class="" [name]="this.PRACTICE" [nameSearching]="this.PRACTICE_SEARCHING"
                    [form]="this.formProvision" (selected)="this.onSelectPractice($event)"
                    [elements]="this.practicesToShow" [calculateId]="this.calculateIdPractice"
                    [calculateName]="this.calculateNamePractice"
                    [defaultValue]="!!this.provisionToWork ? this.provisionToWork.practiceId : null"
                    [placeholderSearching]="'Buscar...'"
                    [disabled]="this.isAddModeProvision ? false : true">
                </app-select-searching-input>
            </div>

            <!-- Frequency   -->
            <div [formGroupName]="this.FRECUENCY" class="flex-row space-between-center flex-100" *ngIf="!isRetroactive">
                <!-- UNIT input -->
                <div class="flex-column flex-45">
                    <span class="subtitle-3 text-primary-darker">Frecuencia *</span>
                    <app-select-input [name]="this.UNIT" [form]="this.formFrequency"
                        [placeholder]="this.supply ? 'Unidad' : 'Período'"
                        [elements]="this.supply ? this.provisionsUnit : this.provisionsFrequencyUnit"
                        [calculateId]="this.calculateIdUnit" [calculateName]="this.calculateNameUnit">
                    </app-select-input>
                </div>
                <!-- AMOUNT input -->
                <div class="flex-column flex-45">
                    <span class="subtitle-3 text-primary-darker">Cantidad *</span>
                    <app-number-input [name]="this.AMOUNT" [form]="this.formFrequency" [minValue]="0" [money]="false">
                    </app-number-input>
                </div>
            </div>

            <!-- Validity / Vigencia -->
            <div class="flex-column">
                <div class="flex-row align-space-between-center flex-100">
                    <!-- From -->
                    <!-- style="height: 65px;" -->
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Vigencia desde *</span>
                        <app-date-picker [name]="this.VALIDITY_FROM_DATE" [form]="formProvision"
                            (onDateChange)="validateShowMessages()"></app-date-picker>
                    </div>
                    <!-- style="height: 65px;" -->
                    <div class="flex-column flex-45">
                        <!-- To -->
                        <ng-container *ngIf="!!this.isAddingAuthorization ||
                            (!this.isAddModeProvision && !!this.provisionFeeToWork.toDate); else vigencyNotRequired">
                            <span class="subtitle-3 text-primary-darker">Vigencia hasta *</span>
                        </ng-container>
                        <ng-template #vigencyNotRequired>
                            <span class="subtitle-3 text-primary-darker">Vigencia hasta</span>
                        </ng-template>
                        <app-date-picker [name]="this.VALIDITY_TO_DATE" [form]="formProvision"></app-date-picker>
                    </div>
                </div>
                <div class="flex-column align-start-start">
                    <div *ngIf="showAlertMsg" class="flex-row align-start-start gap-0-5em">
                        <span class="subtitle-4">{{ this.alertMsg }}</span>
                        <mat-icon style="cursor: pointer; z-index: 10; font-size: 20px;" [color]="'accent'"
                            (mouseover)="practiceExistHelp()" (mouseout)="practiceExistHelp()" cdkOverlayOrigin
                            #originOverlay="cdkOverlayOrigin">help_outline
                        </mat-icon>

                        <!-- Alert msg if practice exist -->
                        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="30"
                            [cdkConnectedOverlayOffsetY]="-30" [cdkConnectedOverlayOrigin]="originOverlay"
                            [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayWidth]="200">
                            <div class="flex-column flecha-lado-izquierdo-arriba">
                                <div class="bg-secondary-lighter mat-font-verysmall-px pxs-2 pys-1">
                                    <div class="flex-column">
                                        <span>La duplicación de prácticas permite tener la misma práctica con
                                            dos valores distintos en el mismo periodo de tiempo.</span>
                                        <span>Si desea editar o actualizar la especialidad, utilice el icono
                                            "Actualizar" que esta sobre la práctica.</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div *ngIf="showPracticeAndAgreementExistent" style="max-width: 500px;">
                        <span class="subtitle-4">
                            Esta práctica fue parte del esquema prestacional y existen acuerdos
                            prestacionales vigentes que se incluirán dentro de esta.
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex-row">
                <!-- Fee  input -->
                <div class="flex-column flex-30" *ngIf="!this.showTxtWithOutArrangement">
                    <span class="subtitle-3 text-primary-darker">Valor venta *</span>
                    <app-number-input class="" [name]="this.FEE" [form]="this.formProvision" [money]="true"
                        [minValue]="0">
                    </app-number-input>
                </div>

                <!-- Grid fees list / Price list -->
                <div *ngIf="this.showTxtWithOutArrangement" class="border-filters grid-fees-list px-2 mbs-4" [ngClass]="{'grid-fees-list-columns-2': !!this.arrangementItemsByPractice,
                                'grid-fees-list-columns-1': !this.arrangementItemsByPractice}">
                    <ng-container *ngIf="!!this.arrangementItemsByPractice; else emptyMessage">
                        <div class="subtitle-3 text-primary-darker">Valor venta</div>
                        <div class="subtitle-3 text-primary-darker">Período</div>
                        <ng-container *ngFor="let item of this.arrangementItemsByPractice">
                            <div class="body-3">
                                {{item.fee != -1
                                ? (item.fee.toFixed(2) | currency: 'ARS':'symbol':'1.2')
                                : 'No definido'
                                }}
                            </div>
                            <div class="body-3">
                                {{ item.fromDate | date: 'dd/MM/yyyy' }} -
                                {{ !!item.toDate ? (item.toDate | date: 'dd/MM/yyyy') : 'en adelante' }}
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #emptyMessage class="grid-columns-1">
                        <span *ngIf="existPriceList" class="body-3 text-primary" style="text-align: center;">Debe
                            seleccionar una práctica para ver la lista de valores de venta e ingresar
                            la vigencia</span>
                        <span *ngIf="!existPriceList" class="body-3 text-primary" style="text-align: center;">No hay
                            valores de venta definidos para el convenio en el rango de fechas
                            especificado</span>
                    </ng-template>
                </div>
            </div>

            <!-- Authorization -->
            <div *ngIf="isShowAuth" class="separator separator-center mts-1 flex-100">
                <b class="mx-2 text-primary-darker">
                    Autorización
                </b>
            </div>

            <app-checkbox-input *ngIf="isShowAuth" class="flex-1-1-auto mbs-2" [labelPosition]="'before'"
                [form]="this.formProvision" [label]="checkboxAuthorizationText" [name]="this.IS_ADDING_AUTHORIZATION"
                (change)="onChangeCheckAut($event, 'this.formProvision')">
            </app-checkbox-input>
            <div *ngIf="this.formProvision.get(this.IS_ADDING_AUTHORIZATION).value" [formGroupName]="this.AUTHORIZATION" class="flex-column flex-100">
                <div class="flex-row space-between">
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Número de autorización *</span>
                        <app-text-input [name]="this.AUTHORIZATION_NUMBER" [form]="this.formAuthorization">
                        </app-text-input>
                    </div>
                </div>
                <div class="flex-row align-space-between-center">
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Observaciones de la autorización</span>
                        <app-text-area-input class="flex-100" [hint]="'Opcional'"
                            [name]="this.AUTHORIZATION_OBSERVATIONS" [form]="this.formAuthorization" [maxLength]="120">
                        </app-text-area-input>
                    </div>
                    <div class="flex-column flex-45">
                        <div style="height: 25px;">
                            <span class="subtitle-3" style="color:red" *ngIf="this.photoEvolutionB64 == null">
                                Archivo requerido
                            </span>
                        </div>
                        <file-pond #myPond [options]="pondOptions" (oninit)="pondHandleInit()"
                            (onaddfile)="pondHandleAddFile($event)" (onremovefile)="pondHandleRemoveFile($event)"
                            (onaddfilestart)="pondHandleProcessFile($event)" id="Case_fileSelector"
                            class="filepond--panel-root" fxFlex="48">
                        </file-pond>
                    </div>
                </div>
            </div>

            <mat-error class="mls-3" *ngIf="
               this.formAuthorization.errors &&
               (this.formAuthorization.dirty || this.formAuthorization.touched)" class="align-start-center">
                <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
                {{ objectValues(this.formAuthorization.errors)[0] }}
            </mat-error>

            <!-- Bottom -->
            <div class="flex-column">
                <!-- <mat-error *ngIf="this.formProvision?.errors['fromToDate']" class="subtitle-3"
                    style="text-align: center">Rango de fechas de vigencia incorrecto</mat-error> -->
                <div class="flex-row mts-3 flex-100 align-space-evenly-center">
                    <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                        [disabled]="
                     (isLoadingUpdatingProvision$ | async) || (isLoadingCreatingProvision$ | async)">
                        CANCELAR
                    </button>

                    <button mat-flat-button [color]="'primary'" type="submit" [disabled]="
                        (isLoadingUpdatingProvision$ | async) ||
                        (isLoadingCreatingProvision$ | async) ||
                        !this.formProvision.valid ||
                        this.disabledButtonSubmitUpdateProvision ||
                        (this.photoEvolutionB64 == null &&
                            this.formProvision.get(this.IS_ADDING_AUTHORIZATION).value)">
                        <span
                            *ngIf=" !(isLoadingUpdatingProvision$ | async) && !(isLoadingCreatingProvision$ | async); else loadingIcon">{{
                            this.actionProvision }}</span>
                        <ng-template #loadingIcon>
                            <mat-icon>
                                <mat-spinner color="accent" diameter="20"> </mat-spinner>
                            </mat-icon>
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- ADD & UPDATE AGREEMENT -->
<ng-template #agreementTemplate>
    <div class="flex-row align-space-between-start flex-gap-1rem full-width">
        <form *ngIf="this.formAgreement" [formGroup]="this.formAgreement" class="flex-column"
            (submit)="onClickConfirmFormAgreement()">
            <!-- Spinner -->
            <div class="spinner-hover" *ngIf="(isLoadingGettingProviders$ | async) && this.isAddModeAgreement">
                <mat-spinner color="primary" diameter="40"></mat-spinner>
            </div>
            <div class="flex-column align-center-none full-width">
                <!-- Select providers -->
                <div class="flex-row flex-100 align-space-between-center full-width gap-3-5em">
                    <div class="flex-column flex-100">
                        <span class="subtitle-3 text-primary-darker">Prestador *</span>
                        <app-select-searching-input [hidden]="!this.isAddModeAgreement" [name]="this.PROVIDER"
                            [elements]="this.providers"
                            [defaultValue]="this.isAddModeAgreement ? null : this.agreementToWork.provider.id"
                            [nameSearching]="this.PROVIDER_SEARCHING" [form]="this.formAgreement"
                            [placeholder]="'Seleccione el prestador'" [calculateId]="this.calculateIdProvider"
                            [calculateName]="this.calculateNameProvider" [placeholderNoValue]="'Sin resultados'"
                            [placeholderSearching]="'Buscar prestador...'" [disabled]="!this.isAddModeAgreement">
                        </app-select-searching-input>
                        <app-text-input [form]="this.formAgreement" [hidden]="this.isAddModeAgreement"
                            [name]="this.PROVIDER_NAME">
                        </app-text-input>
                    </div>
                    <!-- Filter by region -->
                        <div  *ngIf="actionAgreement === 'CREAR'" class="flex-row flex-50 cursor-pointer gap-0-3em" (click)="this.openSpecialtiesMap()">
                            <mat-icon class="text-secondary" [svgIcon]="'FindProvider-enabled-primary-27x27'"></mat-icon>
                            <span class="subtitle-3 text-primary-darker" style="align-self:center;">Buscar prestador</span>
                        </div>


                </div>

                <div class="flex-row align-space-between-center flex-100">
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Vigencia desde *</span>
                        <app-date-picker [name]="this.AGREEMENT_FROM_DATE" [form]="formAgreement"
                            (onDateChange)="onClickDateFrom($event)"></app-date-picker>
                    </div>
                    <div class="flex-column flex-45"
                        *ngIf="this.isAddModeAgreement || (!this.isAddModeAgreement && !this.isUpdateModeAgreement)">
                        <span class="subtitle-3 text-primary-darker">Vigencia hasta</span>
                        <app-date-picker [name]="this.AGREEMENT_TO_DATE" [form]="formAgreement"
                            (onDateChange)="onClickDateTo($event)"></app-date-picker>
                    </div>
                </div>

                <ng-container *ngIf="!!this.isAddModeAgreement; else updateAgreement">
                    <div class="flex-column align-center-start flex-10">
                        <span class="subtitle-3 text-primary-darker">Costo *</span>
                        <app-number-input [name]="this.AGREEMENT_PROVISION_FEE" [minValue]="0"
                            [form]="this.formAgreement" [money]="true">
                        </app-number-input>
                    </div>
                </ng-container>
                <ng-template #updateAgreement>
                    <div class="flex-row align-space-between-center flex-100">
                        <mat-radio-group class="align-space-between-end" formControlName="radioButton"
                            (change)="clickRadiobuttonAgreementCost($event)" name="radioButton">
                            <div class="flex-column flex-45">
                                <mat-radio-button class="subtitle-3 text-primary-darker"
                                    [value]="'cost'">Costo</mat-radio-button>
                                <app-number-input [name]="this.AGREEMENT_PROVISION_FEE" [minValue]="0"
                                    [form]="this.formAgreement" [money]="true" (change)="this.updateMargin()">
                                </app-number-input>
                            </div>
                            <div class="flex-column flex-45"
                                *ngIf="!this.isAddModeAgreement && this.isUpdateModeAgreement">
                                <mat-radio-button class="subtitle-3 text-primary-darker" [value]="'margin'">Margen
                                    aplicado
                                    sobre el valor</mat-radio-button>
                                <app-number-input [name]="this.AGREEMENT_MARGIN" [form]="this.formAgreement"
                                    (change)="this.updateCost()" [suffix]="'%'">
                                </app-number-input>
                            </div>
                        </mat-radio-group>
                    </div>
                </ng-template>
                <!-- ProviderFee input -->

                <div class="flex-row align-space-around-center full-width mts-3">
                    <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialogAgreementTemplate()"
                        [disabled]="isLoadingUpdatingAgreement$ | async">
                        CANCELAR
                    </button>
                    <!-- BUTTON create or update -->
                    <button mat-flat-button [color]="'primary'" type="submit"
                        [disabled]="(isLoadingUpdatingAgreement$ | async) || !this.formAgreement.valid">
                        <span *ngIf="
                         !(isLoadingUpdatingAgreement$ | async) &&
                            !(isLoadingCreatingAgreement$ | async);
                         else loadingIcon
                      ">{{ this.actionAgreement }}</span>
                        <ng-template #loadingIcon>
                            <mat-icon>
                                <mat-spinner color="accent" diameter="20"> </mat-spinner>
                            </mat-icon>
                        </ng-template>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- UPDATE AUTHORIZATION -->
<ng-template #updateAuthorizationTemplate>
    <div class="flex-column">
        <form *ngIf="this.formAuthorization" class="flex-column flex-100" [formGroup]="this.formAuthorization" (submit)="onClickCreateAuthorization()">
            <div class="flex-row align-space-between-center gap-2em">
                <div class="flex-row">
                    <div class="flex-column">
                        <span class="subtitle-3 text-primary-darker bs-0">
                            Número de autorización *
                        </span>
                        <app-text-input [name]="this.AUTHORIZATION_NUMBER" [form]="this.formAuthorization"
                            [placeholder]="'Número de autorización'">
                        </app-text-input>
                    </div>
                </div>
                <div class="flex-row align-end-center flex-gap-small">
                    <div class="flex-column">
                        <span class="subtitle-3 text-primary-darker bs-0">
                            Desde *
                        </span>
                        <app-date-picker [name]="this.AUTHORIZATION_FROM_DATE" [form]="formAuthorization" [disabled]="true"
                            [readonly]="true">
                        </app-date-picker>
                    </div>
                    <div class="flex-column">
                        <span class="subtitle-3 text-primary-darker bs-0">
                            Hasta *
                        </span>
                        <app-date-picker [name]="this.AUTHORIZATION_TO_DATE" [form]="formAuthorization"
                            [disabled]="!!this.provisionFeeSelectedToAutorize.toDate"
                            [readonly]="!!this.provisionFeeSelectedToAutorize.toDate">
                        </app-date-picker>
                    </div>
                </div>
            </div>
            <div class="flex-row align-space-between-center gap-2em">
                <div class="flex-column flex-52">
                    <span class="subtitle-3 text-primary-darker bs-0">
                        Observaciones de la autorización
                    </span>
                    <app-text-area-input class="flex-100" [hint]="'Opcional'" [name]="this.AUTHORIZATION_OBSERVATIONS"
                        [form]="this.formAuthorization" [placeholder]="'Ingrese el texto aquí'" [maxLength]="120">
                    </app-text-area-input>
                </div>
                <div class="flex-column flex-46">
                    <div style="height: 25px;">
                        <span class="subtitle-3" style="color:red" *ngIf="this.photoEvolutionB64 == null">
                            Archivo requerido
                        </span>
                    </div>
                    <file-pond #myPond [options]="pondOptions" (oninit)="pondHandleInit()" required="true"
                        (onaddfile)="pondHandleAddFile($event)" (onremovefile)="pondHandleRemoveFile($event)"
                        (onaddfilestart)="pondHandleProcessFile($event)" id="Case_fileSelector"
                        class="filepond--panel-root">
                    </file-pond>
                </div>
            </div>
            <div class="flex-100 mts-3 gap-15px flex-row align-space-evenly-start flex-column-xs">
                <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                    [disabled]="isLoadingUpdatingProvision$ | async">
                    CANCELAR
                </button>
                <button mat-flat-button [color]="'primary'" type="submit" [disabled]="
                  (isLoadingUpdatingProvision$ | async) ||
                  !this.formAuthorization.valid ||
                  this.photoEvolutionB64 == null
               ">
                    <span *ngIf="!(isLoadingUpdatingProvision$ | async); else loadingIcon">GUARDAR</span>
                    <ng-template #loadingIcon>
                        <mat-icon>
                            <mat-spinner color="accent" diameter="20"> </mat-spinner>
                        </mat-icon>
                    </ng-template>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #showAuthorizations>
    <!-- GRID -->
    <ng-container *ngIf="!(this.isLoadingGetAuthorizations$ | async); else loadingAuthorizations">
        <div *ngIf="this.authorizations.length > 0" class="grid-to-auths grid-to-auths-cant-columns-4">
            <!-- TITLES -->
            <div></div>
            <div style="text-align: left;"><span class="subtitle-3">Número</span></div>
            <div style="text-align: left;"><span class="subtitle-3">Fecha</span></div>
            <div></div>
            <!-- CONTENT -->
            <ng-container *ngFor="let authorization of authorizations">
                <div>
                    <mat-icon [svgIcon]="'Lines-gray'"></mat-icon>
                </div>
                <div style="align-self: center;">
                    <span class="subtitle-3 text-primary-darker"># {{ authorization.authorizationNumber }}</span>
                </div>
                <div style="align-self: center;">
                    <span class="body-3">{{ authorization.fromDate | date: 'dd/MM/yyyy' }} -
                        {{ authorization.toDate | date: 'dd/MM/yyyy' }}</span>
                </div>
                <div>
                    <a mat-button [disabled]="!authorization.evolutionId" [ngClass]="{
                     'text-primary': !!authorization.evolutionId,
                     'text-secondary': !authorization.evolutionId
                  }" style="font-weight: bolder; text-decoration: underline !important;"
                        (click)="authorization.evolutionId && showAuthEvolution(authorization)">VER AUTORIZACIÓN</a>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- Message no auths -->
    <ng-template #noAuthorizations>
        <div class="flex-row-wrap align-center-center">
            <span class="text-secondary-darker">
                Aún no se han cargado autorizaciones
            </span>
        </div>
    </ng-template>

    <!-- Spinner -->
    <ng-template #loadingAuthorizations>
        <div class="flex-row align-center-center full-height">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
    </ng-template>
</ng-template>

<!-- Suspend provision-fee -->
<ng-template #suspendTemplate>
    <form (submit)="onClickSuspendProvisionFee()">
        <!-- Spinner -->
        <div class="spinner-hover" *ngIf="false">
            <mat-spinner color="primary" diameter="40"></mat-spinner>
        </div>
        <div class="flex-column">
            <div class="flex-row-wrap align-space-around-center">
                <!-- Practice -->
                <div class="flex-column flex-50">
                    <span class="subtitle-3 text-primary-darker">
                        Práctica
                    </span>
                    <app-text-input [name]="this.PRACTICE" [form]="this.formSuspend" [placeholder]="'Práctica'">
                    </app-text-input>
                </div>
                <!-- Validity / Vigencia -->
                <div class="flex-row align-center-center gap-0-5 flex-40">
                    <div class="flex-column flex-50">
                        <span class="subtitle-3 text-primary-darker">Desde</span>
                        <app-date-picker [name]="this.VALIDITY_FROM_DATE" [form]="formSuspend"></app-date-picker>
                    </div>
                    <div class="flex-column flex-50">
                        <span class="subtitle-3 text-primary-darker">Hasta</span>
                        <app-date-picker [name]="this.VALIDITY_TO_DATE" [form]="formSuspend"></app-date-picker>
                    </div>
                </div>
            </div>
            <div class="flex-100 mts-3 gap-15px flex-row align-space-evenly-start flex-column-xs">
                <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                    [disabled]="isLoadingUpdatingProvision$ | async">
                    CANCELAR
                </button>
                <button mat-flat-button [color]="'primary'" type="submit"
                    [disabled]="!this.formSuspend.valid || (isLoadingUpdatingProvision$ | async)">
                    <span *ngIf="!(isLoadingUpdatingProvision$ | async); else loadingIcon">GUARDAR</span>
                    <ng-template #loadingIcon>
                        <mat-icon>
                            <mat-spinner color="accent" diameter="20"> </mat-spinner>
                        </mat-icon>
                    </ng-template>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<!-- Patient and providers in google-maps -->
<ng-template #specialtiesMap>
    <app-specialties-providers-pois
        [specialtyId]="this.specialtySelected.id"
        [screenCalling]="'app-provisions-detail'"
        [patient]="this.case.patient"
        [provision] = "this.provisionToWork"
        [specialty] = "this.specialtySelected"
        >
    </app-specialties-providers-pois>
</ng-template>

<mat-menu #menuActions="matMenu">
    <ng-template matMenuContent let-provisionFee="provisionFee" let-provision="provision">
        <button mat-menu-item (click)="this.openUpdateAuthorizationDialog(provisionFee)" id="addAuthorizationButton"
            *ngIf="!provisionFee.authorization">
            Autorizar
        </button>
        <button mat-menu-item (click)="this.openUpdateProvisionDialogWithRetroactiveMode(provision, provisionFee)"
            id="updProvisionFee">
            Renovar
        </button>
        <button mat-menu-item (click)="this.openSuspendProvisionFee(provision, provisionFee)" id="suspendProvisionFee">
            Finalizar
        </button>
        <button mat-menu-item (click)="this.amendProvisionFee(provision, provisionFee)" id="amendProvisionFee">
            Corregir
        </button>
        <button mat-menu-item (click)="this.deleteProvisionFee(provision, provisionFee)" id="deletedProvisionFee">
            Eliminar
        </button>
        <!-- <button mat-menu-item (click)="this.openAuthorizations(provision, provisionFee)" id="openAuths">
            Más info
        </button> -->
    </ng-template>
</mat-menu>

<mat-menu #menuAgreement="matMenu">
    <ng-template matMenuContent let-agreement="agreement" let-provision="provision">
        <button mat-menu-item (click)="this.openUpdateAgreementDialog(provision, agreement)" id="updateAgreement">
            Actualizar
        </button>
        <button mat-menu-item (click)="this.openFixAgreementDialog(provision, agreement)" id="fixAgreement">
            Corregir
        </button>
        <button mat-menu-item (click)="onClickDeleteAgreement(provision, agreement)" id="deleteAgreement" [disabled]="
            !this.case.active ||
            (!!this.case['caseDerivations'] && this.case['caseDerivations'].length > 0)
        ">
            Eliminar
        </button>
    </ng-template>
</mat-menu>

<mat-menu #menuGlobalActions="matMenu">
    <ng-template matMenuContent let-provisionFee="provisionFee" let-provision="provision">
        <button mat-menu-item (click)="exportProvisionFees(true)">Exportar como .pdf</button>
        <button mat-menu-item (click)="exportProvisionFees(false)">Exportar como .doc</button>
    </ng-template>
</mat-menu>
