<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon && !spinner">{{ leftIcon }}</mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass"
        *ngIf="leftIconSvg && !spinner" [svgIcon]="leftIconSvg"></mat-icon>
    <mat-spinner *ngIf="spinner" class="mrs-3" color="primary" diameter="20">
    </mat-spinner>
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            <b>{{ label }}</b>
        </mat-label>
        <mat-select multiple [formControlName]="name" id="{{name}}" placeholder="{{placeholder}}"
            [disabled]="disabled ? disabled : false" (selectionChange)="change($event)">
            <!-- FIXME: chips were disabled -->
            <!-- <mat-select-trigger>
                <mat-chip-listbox>
                    <mat-chip class="flex-row align-center-stretch bg-primary-lighter text-primary chips-select" [color]="'primary'"
                        *ngFor="let element of valuesSelected" matTooltip="{{ element.name }}">
                        <span class="ellipsis">{{ element.name }}</span>
                        <button mat-icon-button (mousedown)="removeElement(element)">
                            <mat-icon class="mat-icon-16x16">highlight_off</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-listbox>
            </mat-select-trigger> -->

            <mat-option [disabled]="element?.disabled" [value]="element.id" *ngFor="let element of elements">
                {{ element.name }}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="this.form.get(this.name)?.errors &&
             (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)">
            {{ getError() }}
        </mat-error>
    </mat-form-field>
</div>
