import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Case } from '../../../../shared/models/case';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { AttentionsService } from '../../../../core/services/attentions.service';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { ProvisionAgreementFilters } from '../../../../shared/models/ProvisionAgreementFilters';
import { Subscription } from 'rxjs';
import { CaseDateFilters, CasesFacade } from 'src/app/abstraction/cases.facade';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { Attention } from '../../../../shared/models/attention';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/enums/routes';
import * as moment from 'moment';
import { Provision } from 'src/app/shared/models/provision';
import { OrdersQPS } from 'src/app/core/services/cases.service';
import { Order } from 'src/app/shared/models/order';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { event } from 'jquery';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';

export enum OPTIONS {
    SUPPLY = "SUPPLY",
    PROVISION = "PROVISION",
    ORDER = "ORDER"
}

@Component({
    selector: 'app-provision-scheme',
    templateUrl: './provision-scheme.component.html',
    styleUrls: ['./provision-scheme.component.scss']
})
export class ProvisionSchemeComponent implements OnInit {
    _screenToJumpSubscription: Subscription
    public readonly options: OPTIONS;
    selectedOption: OPTIONS = OPTIONS.PROVISION;
    selected1: OPTIONS = OPTIONS.ORDER

    visibleEvolution: boolean = false;

    swalWithCustomizeButtons: any;
    @Input() data: { name: string; surname: string; typeDocument: string, document: string; cif: number; };
    @Input("case") case: Case;
    @Input("attentionsCaseActive") attentionsCaseActive: Attention[];
    showScheduleList: boolean = false;
    expandedProvision = null;
    refresh = false;
    visibleCalendarPanel: boolean = false;
    isCollapsedView: boolean = false;
    viewColumnsBillAndDebit: boolean = false;
    _filtersOnProvision: Subscription;
    provisionAgreementsFilters: ProvisionAgreementFilters;
    provisionsFilteredCount: number = 0;
    provisionsSuppliesFilteredCount: number = 0;
    ordersFilteredCount: number = 0;
    _isLoadingGetAttentions: Subscription;
    _isLoadingGettingEvolutions: Subscription;
    isLoadingGetAttentions: boolean;
    isLoadingGettingEvolutions: boolean;
    showCalendarList: boolean = false;
    _historyModeDate: Subscription;
    dateRange: CaseDateFilters;
    jumpToScreen:boolean = false;
    historyFromDate: Date;
    historyToDate: Date;

    isAdmin: boolean = false;

    provisionsFeeIdsToModifySupplies: number[]; // provisionFee Ids
    billModifiedSupplies: number[];

    _allProvisions: Subscription;
    allProvisionsWithoutFilter: Provision[]; // ALL Provisions without filetrs to case
    _allOrders: Subscription;
    allOrders: Order[]

    execute: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        private attentionsFacade: AttentionsFacade,
        private evolutionsFacade: EvolutionsFacade,
        private casesFacade: CasesFacade,
        private provisionsFacade: ProvisionsFacade,
        private attentionsService: AttentionsService,
        private authenticationService: AuthenticationService,
        private breadcrumbService: BreadcrumbService,
        private router: Router
    ) { }

    ngOnInit(): void {

        this._screenToJumpSubscription = this.breadcrumbService.getScreenToJump$().subscribe(event => {
                            this.handleEvent(event)
                    })

        // Load orders
        const ordersQPS: OrdersQPS = {
            page: 0,
            size: 1000,
        }
        this.casesFacade.loadOrders(ordersQPS, this.case.id) //FIXME: load all or by date?

        this.isAdmin = this.authenticationService.isAdmin();

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this.subscribeToData();
    }

    ngOnDestroy() {
        this._screenToJumpSubscription.unsubscribe();
        this.breadcrumbService.setScreenToJump(null)

        console.log('provision-scheme ngOnDestroy');

        if (!!this._filtersOnProvision) {
            this._filtersOnProvision.unsubscribe();
        }
        this.provisionsFacade.setFiltersOnProvisions(null);
        if (this._isLoadingGetAttentions) {
            this._isLoadingGetAttentions.unsubscribe();
        }
        if (this._isLoadingGettingEvolutions) {
            this._isLoadingGettingEvolutions.unsubscribe();
        }
        !!this._historyModeDate ? this._historyModeDate.unsubscribe() : null;
        !!this._allProvisions ? this._allProvisions.unsubscribe() : null;
    }

    subscribeToData() {

        // Spinner control
        this._isLoadingGetAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loadingAttentions => this.isLoadingGetAttentions = loadingAttentions);
        this._isLoadingGettingEvolutions = this.evolutionsFacade.isLoadingGetEvolutions$().subscribe(loadingEvolutions => this.isLoadingGettingEvolutions = loadingEvolutions);
        this.suscribeToFilters();

        this._allOrders = this.casesFacade.getOrders$().subscribe(orders => {
            if (!!orders) {
                this.allOrders = orders;
                this.casesFacade.filterOrdersOnDateFilters(this.allOrders);
            }
        })

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(dateRange => {
            if (!!dateRange) {
                this.dateRange = dateRange;
                if (this.historyFromDate !== dateRange.historyFromDate || this.historyToDate !== dateRange.historyToDate) {
                    this.removeFilters();
                }

                this.historyFromDate = dateRange.historyFromDate;
                this.historyToDate = dateRange.historyToDate;

                if (this.execute) {
                    this.provisionsFacade.filterProvisionsOnDateFilters(this.allProvisionsWithoutFilter); // Set provisionsToDisplayedOnScreen
                    this.casesFacade.filterOrdersOnDateFilters(this.allOrders);
                }
            }
        })

        // Provisions
        this._allProvisions = this.provisionsFacade.getAllProvisionsCase$().subscribe(allProvisions => {
            if (!!allProvisions) {
                this.allProvisionsWithoutFilter = allProvisions;
                this.execute = true;
                this.casesFacade.setCaseDateFilters(this.casesFacade.getCaseDateFilter()); // Force load this._historyModeDate
            } else {
                this.provisionsFacade.loadAllProvisionsForCase(this.case.id); // First load ALL provisions, update provisionsToDisplayedOnScreen too
            }
        })
    }

    onTabChanged(e) {
        console.log(e);
        setTimeout(() => {
            this.refresh = !this.refresh;
            this.cd.detectChanges();
        }, 100);
    }

    onSelectOption(option: string) {
        this.selectedOption = OPTIONS[option];

        delete this.provisionAgreementsFilters?.provisions;
        delete this.provisionAgreementsFilters?.agreements;
        delete this.provisionAgreementsFilters?.provisionFees;

        delete this.provisionAgreementsFilters?.provisionsSupplies;
        delete this.provisionAgreementsFilters?.provisionAgreementsSupplies;
        delete this.provisionAgreementsFilters?.orders;

        this.provisionsFacade.setFiltersOnProvisions(this.provisionAgreementsFilters);
    }

    onFlowSelectOption(option: string) {
        this.selectedOption = OPTIONS[option];
        delete this.provisionAgreementsFilters?.provisions;
        delete this.provisionAgreementsFilters?.agreements;
        delete this.provisionAgreementsFilters?.provisionFees;
        delete this.provisionAgreementsFilters?.provisionsSupplies;
        delete this.provisionAgreementsFilters?.provisionAgreementsSupplies;
        delete this.provisionAgreementsFilters?.orders;
    }

    isProvision(): boolean {
        return this.selectedOption === OPTIONS.PROVISION;
    }

    isSupply(): boolean {
        return this.selectedOption === OPTIONS.SUPPLY;
    }

    isOrder(): boolean {
        return this.selectedOption === OPTIONS.ORDER;
    }

    goToScreen(event: ViewManagementEntities) {
        this.breadcrumbService.setScreenToJump(event)
    }

    onClickCalendar() {
        this.jumpToScreen= null
        this.visibleCalendarPanel = true;
        this.visibleEvolution = false;
        this.isCollapsedView = true;
        this.viewColumnsBillAndDebit = false;
        this.selectedOption
        if (this.isCollapsedView && !this.viewColumnsBillAndDebit && this.selectedOption == OPTIONS.SUPPLY) {
            this.onSelectOption('ORDER');
        }
    }

    closePanel() {
        this.visibleCalendarPanel = false;
        this.visibleEvolution = false;
        this.isCollapsedView = false;
        this.viewColumnsBillAndDebit = false;
        if (this.selectedOption === OPTIONS.ORDER) {
            this.onSelectOption('SUPPLY')
        }
    }

    onClickEvolutions() {
        this.visibleCalendarPanel = false;
        this.visibleEvolution = true;
        this.isCollapsedView = true;
        this.viewColumnsBillAndDebit = false;
        if (this.selectedOption == OPTIONS.SUPPLY) {
            this.onSelectOption('ORDER')
        }
    }

    onClickBilling() {
        this.visibleCalendarPanel = false;
        this.visibleEvolution = false;
        this.isCollapsedView = true;
        this.viewColumnsBillAndDebit = true;
        if (this.selectedOption == OPTIONS.ORDER) {
            this.onSelectOption('SUPPLY')
        }
    }

    receiveAgreementOfNewAttention(agrObject) {
        if (!this.provisionAgreementsFilters?.provisions?.includes(agrObject.agreement.provision)) {
            !!this.provisionAgreementsFilters.provisions
                ?
                this.provisionAgreementsFilters.provisions.push(agrObject.agreement.provision)
                :
                this.provisionAgreementsFilters.provisions = [agrObject.agreement.provision];
        }
        if (!this.provisionAgreementsFilters?.agreements?.includes(agrObject.agreement)) {
            !!this.provisionAgreementsFilters.agreements
                ?
                this.provisionAgreementsFilters.agreements.push(agrObject.agreement)
                :
                this.provisionAgreementsFilters.agreements = [agrObject.agreement];
        }
        if (!this.provisionAgreementsFilters?.provisionFees?.some(pF => pF.id == agrObject?.provisionFee)) {
            let provFee = this.allProvisionsWithoutFilter.flatMap(prov => prov.provisionFees).find(pF => pF.id == agrObject?.provisionFee);
            !!this.provisionAgreementsFilters.provisionFees
                ?
                this.provisionAgreementsFilters.provisionFees.push(provFee)
                :
                this.provisionAgreementsFilters.provisionFees = [provFee]
        }
        this.expandedProvision = agrObject?.agreement?.provision;
        this.refreshViewFiltered();
    }

    suscribeToFilters() {

        this._filtersOnProvision = this.provisionsFacade.getFiltersOnProvisions$().subscribe((filters) => {
            if (!!filters) {
                this.provisionAgreementsFilters = filters;
                this.provisionsCount(filters);
                this.provisionsSuppliesCount(filters);
                this.ordersCount(filters);
                this.onFilterAttentions(filters);
            } else {
                this.provisionAgreementsFilters = {};
            }
        })
    }

    onFilterAttentions(filters: ProvisionAgreementFilters) {
        this.attentionsFacade.setAttentionsCaseActive(this.attentionsService.mamushkaCheckBoxes(filters, this.attentionsFacade.getAttentions()));
    }

    provisionsCount(filters: ProvisionAgreementFilters) {
        this.provisionsFilteredCount = filters?.provisionFees?.filter(pF => !this.allProvisionsWithoutFilter.find(prov => prov.id == pF.provisionId).isSupply).length;
    }

    provisionsSuppliesCount(filters: ProvisionAgreementFilters) {
        this.provisionsSuppliesFilteredCount = filters?.provisionsSupplies?.length;
    }

    ordersCount(filters: ProvisionAgreementFilters) {
        this.ordersFilteredCount = filters?.orders?.length;
    }

    removeFilters() {
        let provisionAgreementsFilters = {
            provisions: [],
            provisionFees: [],
            agreements: [],
            provisionsSupplies: [],
            provisionAgreementsSupplies: [],
            orders: [],
        };
        this.provisionsCount(provisionAgreementsFilters);
        this.provisionsSuppliesCount(provisionAgreementsFilters);
        this.ordersCount(provisionAgreementsFilters);
        this.provisionsFacade.setFiltersOnProvisions(provisionAgreementsFilters);
    }

    calculateView() {
        if(this.jumpToScreen){
            return 'listado'
        }else{
            return (!!this.viewColumnsBillAndDebit) ? "listado" : "calendario";
        }
    }

    mustShowSpinner() {
        if (this.isCollapsedView) {
            if (this.isLoadingGetAttentions || this.isLoadingGettingEvolutions) {
                return true;
            }
        }
        return false;
    }

    linkToConsolidation() {
        let patient = { ...this.case.patient };
        patient.id = this.case.patientId; // Replace case.patient.id with case.patientId else Consolidation doesn't work
        this.router.navigate([ROUTES.CONSOLIDATION], { state: { patient: patient, financier: this.case.financier, dateSelectedFrom: moment(this.dateRange.historyFromDate).format('YYYY-MM-DD'), dateSelectedTo: moment(this.dateRange.historyToDate).format('YYYY-MM-DD') } });
    }

    refreshViewFiltered() {
        this.provisionsFacade.setFiltersOnProvisions(this.provisionAgreementsFilters);
    }

    // Open component app-supply-list.this.openAddSupplyDialog( 'FPR', __ ) for update provisionFees if have Kairos checked
    openModifyProvisionFeeSupplies(provisionFeeIds: number[]) {
        this.provisionsFeeIdsToModifySupplies = provisionFeeIds; // Input in supply-list
    }

    //Opens component app-schedule-case.onClickFacturableDebitada() to bill items attentions
    billModifiedAttentions(suppliesToModifyAttentions: number[]) {
        this.billModifiedSupplies = suppliesToModifyAttentions; //Input on schedule-case. Executes ngOnChanges
    }


    handleEvent(event: ViewManagementEntities) {
        switch (event) {
            case ViewManagementEntities.PRACTICES_WITHOUT_SCHEDULE:
                this.closePanel();
                this.onFlowSelectOption('PROVISION');
                break;
            case ViewManagementEntities.ATTENTIONS_OLD_AND_OMITTED:
                this.jumpToScreen = true;
                this.onFlowSelectOption('PROVISION');
                this.viewColumnsBillAndDebit = false;
                this.visibleCalendarPanel = true;
                this.visibleEvolution = false;
                this.isCollapsedView = true;
                this.showCalendarList = true;
                break;
            case ViewManagementEntities.ATTENTIONS_WITHOUT_BILLING:
                this.jumpToScreen = true;
                this.onFlowSelectOption('PROVISION');
                this.visibleCalendarPanel = true;
                this.visibleEvolution = false;
                this.isCollapsedView = true;
                this.showCalendarList = true;
                this.viewColumnsBillAndDebit = true;
                break;
            case ViewManagementEntities.BILLING_ATTENTIONS:
                this.onFlowSelectOption('PROVISION');
                this.linkToConsolidation();

                break;
            case ViewManagementEntities.SUPPLIES_WITHOUT_ORDER:
                this.closePanel();
                this.onFlowSelectOption('SUPPLY');
                break;
            case ViewManagementEntities.SUPPLIES_NOT_DELIVERED:
                this.closePanel();
                this.onFlowSelectOption('SUPPLY');
                break;
            case ViewManagementEntities.SUPPLIES_NOT_BILLING:
                this.onFlowSelectOption('SUPPLY');
                this.onClickBilling();
                break;
            case ViewManagementEntities.SUPPLIES_NOT_INFORMED:
                this.onFlowSelectOption('SUPPLY');
                this.linkToConsolidation();
                break;
            default: null
        }
    }
}
