import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
// import { ExportAsService } from 'ngx-export-as';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttentionsFacade, MetaDataAttentions } from 'src/app/abstraction/attentions.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';
import { AttentionsQPS, AttentionsService, PatchAttentionBody } from 'src/app/core/services/attentions.service';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { FinanciersService } from 'src/app/core/services/financiers.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { PatientsService } from 'src/app/core/services/patients.service';
import { ProvidersService } from 'src/app/core/services/providers.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Attention } from 'src/app/shared/models/attention';
import { Financier } from 'src/app/shared/models/financier';
import { Liquidation } from 'src/app/shared/models/liquidation';
import { Patient } from 'src/app/shared/models/patient';
import { Provider } from 'src/app/shared/models/provider';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import Swal from 'sweetalert2';

interface AttentionsQPSextended extends AttentionsQPS {
   patient?: Patient,
   financier?: Financier,
}

@Component({
   selector: 'app-consolidation',
   templateUrl: './consolidation.component.html',
   styleUrls: ['./consolidation.component.scss'],
   encapsulation: ViewEncapsulation.None,
})

export class ConsolidationComponent implements OnInit {
   @ViewChild('selectLiquidations', { static: false }) selectLiquidations;
   @ViewChild('reportTemplate', { static: true }) reportTemplate;

   // To select default combo patient
   patientOnSelect: [Patient[]];
   actualPatient = [];
   // To select default combo Financier
   financierOnSelect: [Financier[]];
   actualFinancier = [];


   both: boolean = false;

   TITLE = 'Consolidación';
   liquidations: Liquidation[];
   clearSelection: boolean;
   ATTENTION_STATES = AttentionStates;
   actualMonth: Date;
   filterForm: FormGroup;
   filterLiquidationForm: FormGroup;
   formReport: FormGroup;
   errorNotInformed = false;
   _dialogRef
   error = false;
   attentionsNotInformed: Attention[];
   monthAttentions: Attention[];
   FilterEventNotInformed: any;
   lastQps: AttentionsQPS;
   hasMorePages: boolean;
   initialAFP: AttentionsQPSextended
   loadingPdf: boolean = false;
   informingAttentions = false;
   // FORM //
   public readonly PROVIDER = 'providersIds';
   public readonly PROVIDER_SEARCHING = 'providerSearching';
   public readonly PATIENT_SEARCHING = 'patientSearching';
   public readonly PATIENT = 'patientsIds';
   public readonly ATTENTIONS = 'attentions';
   public readonly FINANCIER = 'financiersIds';
   public readonly FINANCIER_SEARCHING = 'financierSearching';
   public readonly LIQUIDATION = 'liquidationId';
   public readonly LIQUIDATION_SEARCHING = 'liquidationSearching';
   public readonly TO_DATE = 'toDate';
   public readonly FROM_DATE = 'fromDate';
   public readonly INFORMED_STATE_ATTENTION = this.ATTENTION_STATES.INFORMADA.toString();
   public readonly NOT_INFORMED_STATE_ATTENTION = this.ATTENTION_STATES.NO_INFORMADA.toString();
   public readonly BILLABLE_STATE_ATTENTION = this.ATTENTION_STATES.FACTURABLE.toString();
   public readonly DEBITED_STATE_ATTENTION = this.ATTENTION_STATES.DEBITADA.toString();
   public readonly IN_PROCESS_STATE_ATTENTION = this.ATTENTION_STATES.EN_PROCESO.toString();


   //FORM GETTERS/SETTERS
   get providerForm() {
      return this.filterForm.get(this.PROVIDER).value;
   }
   get patientForm() {
      return this.filterForm.get(this.PATIENT).value;
   }
   get financierForm() {
      return this.filterForm.get(this.FINANCIER).value;
   }
   get toDateForm() {
      return this.filterForm.get(this.TO_DATE).value;
   }
   get fromDateForm() {
      return this.filterForm.get(this.FROM_DATE).value;
   }
   get informedAttentionForm() {
      return this.filterForm.get(this.INFORMED_STATE_ATTENTION).value;
   }
   get notInformedAttentionForm() {
      return this.filterForm.get(this.NOT_INFORMED_STATE_ATTENTION).value;
   }
   get billableAttentionForm() {
      return this.filterForm.get(this.BILLABLE_STATE_ATTENTION).value;
   }
   get debitedAttentionForm() {
      return this.filterForm.get(this.DEBITED_STATE_ATTENTION).value;
   }
   get inProcessAttentionForm() {
      return this.filterForm.get(this.IN_PROCESS_STATE_ATTENTION).value;
   }
   set providerForm(providerId: number) {
      providerId != null
         ? this.filterForm.controls[this.PROVIDER].setValue(providerId.toString())
         : this.filterForm.controls[this.PROVIDER].reset();
   }
   set patientForm(patientId: number) {
      patientId != null
         ? this.filterForm.controls[this.PATIENT].setValue(patientId.toString())
         : this.filterForm.controls[this.PATIENT].reset();
   }
   set financierForm(financierId: number) {
      financierId != null
         ? this.filterForm.controls[this.FINANCIER].setValue(financierId.toString())
         : this.filterForm.controls[this.FINANCIER].reset();
   }
   set toDateForm(date) {
      date != null
         ? this.filterForm.controls[this.TO_DATE].setValue(date)
         : this.filterForm.controls[this.TO_DATE].reset();
   }
   set fromDateForm(date) {
      date != null
         ? this.filterForm.controls[this.FROM_DATE].setValue(date)
         : this.filterForm.controls[this.FROM_DATE].reset();
   }
   set informedAttentionForm(checked: boolean) {
      this.filterForm.controls[this.INFORMED_STATE_ATTENTION].setValue(checked);
   }
   set notInformedAttentionForm(checked: boolean) {
      this.filterForm.controls[this.NOT_INFORMED_STATE_ATTENTION].setValue(checked);
   }
   set billableAttentionForm(checked: boolean) {
      this.filterForm.controls[this.BILLABLE_STATE_ATTENTION].setValue(checked);
   }
   set debitedAttentionForm(checked: boolean) {
      this.filterForm.controls[this.DEBITED_STATE_ATTENTION].setValue(checked);
   }
   set inProcessAttentionForm(checked: boolean) {
      this.filterForm.controls[this.IN_PROCESS_STATE_ATTENTION].setValue(checked);
   }

   //SUSCRIPTIONS
   _isLoadingGetAttentions: Subscription;
   _attentions: Subscription;
   _mda: Subscription;

   //VARIABLES
   isLoadingGetAttentions: boolean;
   attentions: Attention[];
   metaDataAttentions: MetaDataAttentions;

   swalWithCustomizeButtons:any;

   // Traffic-lights (semáforo) for execute
   canExecute$ = new BehaviorSubject<string>('');
   _canExecute: Subscription;
   attentionsToProcess: Attention[];

   constructor(
      private formBuilder: FormBuilder,
      private providersService: ProvidersService,
      private patientsService: PatientsService,
      private financiersService: FinanciersService,
      public attentionsService: AttentionsService,
      private generalService: GeneralService,
      private entitiesFacade: EntitiesFacade,
      private attentionsFacade: AttentionsFacade,
      public dialog: MatDialog,
      private fileUtilitiesService: FileUtilitiesService,
    //   private exportAsService: ExportAsService,
      public scheduleService: AttentionsService,
      private storageService : StorageService,
   ) { }

   ngOnInit(): void {
      this.proccesAttentionsWith_WithOUTLegaId(); // Subscription to traffic lighter - semáforo

      this.swalWithCustomizeButtons = Swal.mixin({
         customClass: {
          confirmButton: 'btnSwalConfirm',
          cancelButton: 'btnSwalCancel'
         },
         buttonsStyling: true
       })

      this.calculateNameProvider = this.calculateNameProvider.bind(this);
      this.generalService.sendMessage(this.TITLE);

      let filters: AttentionsQPSextended = {};

      if(!!history.state.patient) {
         this.storageService.removeValuesByKeyOnLocalStorage( "consolidationsFilters" );
         filters.patient = history.state.patient;
         filters.patientsIds = [];
         filters.patientsIds[0] = history.state.patient.id;
         filters.financier = history.state.financier;
         filters.financiersIds = [];
         filters.financiersIds[0] = filters.financier.id;
         filters.fromDate = history.state.dateSelectedFrom;
         filters.toDate = history.state.dateSelectedTo;
      } else {
         filters = this.storageService.getObjectfromLocalStorage('consolidationsFilters');
      }

      this.initilizeFilters(); // initialAFP

      if (filters) {
         this.recoveryFilter(filters); // From history or LocalStorage
      }

      this.metaDataAttentions = this.initialAFP;

      this.clearSelection = false;
      this.attentionsFacade.loadAttentions(this.initialAFP).subscribe();
      this.filterForm = this.createFilterForm();
      this.setValuesToCheckbox();
      this.setFiltersView();
      this.filterLiquidationForm = this.createFilterLiquidationForm();
      this.suscribeToData();
   }

   initilizeFilters(){
      this.initialAFP = {
         page: 0,
         size: 100,
         expand: "financier,operator,patient,practice,provider",
         all: true,
         statesIds: !history.state.patient ? [5, 9] : [5, 6], // From case changes states to filter
         sort: ['fromDate'],
         order: 'DESC'
      }
   }

   ngOnDestroy(): void {
      this._attentions?.unsubscribe();
      this._isLoadingGetAttentions?.unsubscribe();
      this._mda?.unsubscribe();
      this._canExecute.unsubscribe();
   }

   suscribeToData() {
      this._isLoadingGetAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loading => this.isLoadingGetAttentions = loading);
      this._mda = this.attentionsFacade.getMetaDataAttentions$().subscribe((mda) => {
         this.metaDataAttentions = mda;
         console.log('metadata attentions : ', mda);
      })
      this._attentions = this.attentionsFacade.getAttentions$().subscribe((attentions: Attention[]) => {
         console.log( "attentions: ", attentions );
         this.attentions = attentions;
      });
   }

   setValuesToCheckbox() : void {
      if (this.metaDataAttentions && this.metaDataAttentions.statesIds) {
         this.metaDataAttentions.statesIds.forEach( ( state ) => {
            if (parseInt(this.INFORMED_STATE_ATTENTION) == state){
               this.filterForm.get(this.INFORMED_STATE_ATTENTION).setValue(true);
            }
            if (parseInt(this.NOT_INFORMED_STATE_ATTENTION) == state){
               this.filterForm.get(this.NOT_INFORMED_STATE_ATTENTION).setValue(true);
            }
            if (parseInt(this.DEBITED_STATE_ATTENTION) == state){
               this.filterForm.get(this.DEBITED_STATE_ATTENTION).setValue(true);
            }
            if (parseInt(this.BILLABLE_STATE_ATTENTION) == state){
               this.filterForm.get(this.BILLABLE_STATE_ATTENTION).setValue(true);
            }
            if (parseInt(this.IN_PROCESS_STATE_ATTENTION) == state){
               this.filterForm.get(this.IN_PROCESS_STATE_ATTENTION).setValue(true);
            }
         });
      } else {
         this.setValuesDefaultToCheckbox();
      }
   }

   setValuesDefaultToCheckbox() {
      this.filterForm.get(this.BILLABLE_STATE_ATTENTION).setValue(true);
      this.filterForm.get(this.NOT_INFORMED_STATE_ATTENTION).setValue(true);
   }

   setFiltersView() : void {

      if(this.metaDataAttentions) {

         if ( this.metaDataAttentions?.fromDate ) {
            this.metaDataAttentions.fromDate = moment(this.metaDataAttentions.fromDate, 'YYYY-MM-DD').toDate();
            this.filterForm.get(this.FROM_DATE).setValue(this.metaDataAttentions.fromDate);
         }

         if ( this.metaDataAttentions?.toDate ) {
            this.metaDataAttentions.toDate = moment(this.metaDataAttentions.toDate, 'YYYY-MM-DD').toDate();
            this.filterForm.get(this.TO_DATE).setValue(this.metaDataAttentions.toDate);
         }

         if ( this.metaDataAttentions?.providersIds ){
            this.filterForm.get(this.PROVIDER).setValue(this.metaDataAttentions.providersIds[0].toString());
         }

         if ( this.metaDataAttentions?.patientsIds ){
            this.patientOnSelect = [[]];
            this.patientOnSelect[0] = !!this.initialAFP.patient ? [this.initialAFP.patient] : null;
            this.actualPatient[0] = !!this.initialAFP.patient ? this.initialAFP.patient.id.toString() : null;
         }

         if (this.metaDataAttentions?.financiersIds){
            this.financierOnSelect = [[]];
            this.financierOnSelect[0] = !!this.initialAFP.financier ? [this.initialAFP.financier] : null;
            this.actualFinancier[0] = !!this.initialAFP.financier ? this.initialAFP.financier.id.toString() : null;
         }
      }
   }

   createFilterForm(): FormGroup {
      return this.formBuilder.group({
         [this.PROVIDER]: [''],
         [this.PROVIDER_SEARCHING]: [''],
         [this.PATIENT]: [''],
         [this.PATIENT_SEARCHING]: [''],
         [this.FINANCIER]: [''],
         [this.FINANCIER_SEARCHING]: [''],
         [this.TO_DATE]: [''],
         [this.FROM_DATE]: [''],
         [this.INFORMED_STATE_ATTENTION]: [false],
         [this.NOT_INFORMED_STATE_ATTENTION]: [false],
         [this.BILLABLE_STATE_ATTENTION]: [false],
         [this.DEBITED_STATE_ATTENTION]: [false],
         [this.IN_PROCESS_STATE_ATTENTION]: [false],
      });
   }

   createFilterLiquidationForm(): FormGroup {
      return this.formBuilder.group({
         [this.FINANCIER]: ['', [CustomValidators.required('Campo requerido')]],
         [this.FINANCIER_SEARCHING]: [''],
         [this.LIQUIDATION]: ['', [CustomValidators.required('Campo requerido')]],
         [this.LIQUIDATION_SEARCHING]: [''],
      });
   }

   createReportForm(financierId: number, attentions: Attention[]): FormGroup {
      return this.formBuilder.group({
         [this.ATTENTIONS]: [attentions, [CustomValidators.required('Campo requerido')]],
         [this.FINANCIER]: [financierId.toString(), [CustomValidators.required('Campo requerido')]],
         [this.LIQUIDATION]: ['', [CustomValidators.required('Campo requerido')]],
         [this.LIQUIDATION_SEARCHING]: [''],
      });
   }

   calculateIdProvider(element: Provider) {
      return element ? element.id : null;
   }

   calculateNameProvider(element: Provider) {
      return element ? this.entitiesFacade.getProvidersFullName(element) : null;
   }

   calculateIdPatient(element: Patient) {
      return element ? element.id : null;
   }

   calculateNamePatient(element: Patient) {
      return element ? element?.surname + ' ' + element?.name + ' - ' + element.documentNumber : null;
   }

   calculateIdFinancier(element: Financier) {
      return element ? element.id : null;
   }

   calculateNameFinancier(element: Financier) {
      return element ? element.name : null;
   }

   resetValuesDialog() {
      this.clearSelectionOfAttentionList();
      let afp: AttentionsQPS = this.metaDataAttentions;
      delete afp.patientsIds;
      delete afp.providersIds;
      delete afp.financiersIds;
      delete afp.toDate;
      delete afp.fromDate;
      delete afp.statesIds;
      this.storageService.removeValuesByKeyOnLocalStorage( "consolidationsFilters" );
      this.initilizeFilters();
      this.filterForm.reset();
      this.setCheckboxsToFalse()
   }

   setCheckboxsToFalse() {
      this.filterForm.get(this.INFORMED_STATE_ATTENTION).setValue(false);
      this.filterForm.get(this.NOT_INFORMED_STATE_ATTENTION).setValue(false);
      this.filterForm.get(this.BILLABLE_STATE_ATTENTION).setValue(false);
      this.filterForm.get(this.DEBITED_STATE_ATTENTION).setValue(false);
      this.filterForm.get(this.IN_PROCESS_STATE_ATTENTION).setValue(false);
   }

   proccesAttentionsWith_WithOUTLegaId() {

      this._canExecute = this.canExecute$.subscribe( control => {

         // Attentions without legacyid
         if ( control === 'attWithOUTLegacyId' ) {
            const attentionsWithoutLegacyId = this.attentionsToProcess.filter( att => att.caseLegacyId === null );
            if ( attentionsWithoutLegacyId.length > 0 ) this.informAttentions2( attentionsWithoutLegacyId, control );
         }

         // Attentions with legacyid
         if ( control === 'attWithLegacyId' ) {
            const attentionsWithLegacyId = this.attentionsToProcess.filter( att => att.caseLegacyId != null );
            if ( attentionsWithLegacyId.length > 0 ) this.informAttentions2( attentionsWithLegacyId, control );
         }
      })
   }

   informAttentions() {

      this.informingAttentions = true;
      const attentions: Attention[] = this.formReport.get(this.ATTENTIONS).value;

      this.attentionsToProcess = [...attentions];
      this.both = this.attentionsToProcess.filter( att => att.caseLegacyId === null ).length > 0 && this.attentionsToProcess.filter( att => att.caseLegacyId != null ).length > 0;
      this.canExecute$.next(this.attentionsToProcess.filter( att => att.caseLegacyId === null ).length > 0 ? 'attWithOUTLegacyId' : 'attWithLegacyId') // If can execute at least one type is checked
   }

   informAttentions2( attentions : Attention[], newState: string ) {

      const attentionsToPatch: PatchAttentionBody[] = attentions.map((attention) => {
         return {
            id: attention.id,
            state: {
               id: newState === 'attWithOUTLegacyId' ? this.ATTENTION_STATES.INFORMADA : this.ATTENTION_STATES.EN_PROCESO
            },
            liquidationId: this.formReport.get(this.LIQUIDATION).value
         };
      });
      this.patchAttentions( attentionsToPatch )
         .then(( attentionsResponse ) => {
            // this.refresh( true );
         })
         .then((_) => {

            let title = 'Atenciones informadas correctamente';

            if (this.both) {
               title = this.canExecute$.value === 'attWithOUTLegacyId' ? 'Atenciones del caso no legado informadas correctamente' : this.canExecute$.value === 'attWithLegacyId' ? 'Atenciones del caso legado informadas correctamente' : '';
            }

            this.swalWithCustomizeButtons.fire({
               title: title,
               icon: 'success',
            }).then(() => {
               this.clearSelectionOfAttentionList();
            });
         })
         .catch((err) => {
            this.swalWithCustomizeButtons.fire({
               title: 'Atención',
               icon: 'warning',
               text: err.error.message,
            })
            console.log('error', err);
         })
         .finally(() => {
            const executionType = this.canExecute$.value === 'attWithOUTLegacyId' ? 'attWithLegacyId' : '';
            this.canExecute$.next( executionType );
            this.informingAttentions = false;
            this.generalService.closeAllDialogs();
         });
   }

   openReportDialog(financier: Financier, attentions: Attention[]) {
      this.attentionsFacade.loadLiquidations({
            financierId: financier.id,
            sortAttributePairsStr: "id,DESC"
            }).subscribe(liquidations => {
         this.liquidations = liquidations;
         this.formReport = this.createReportForm(financier.id, attentions);
         this.openDialog(
            'Informar atenciones del cliente '
               + financier.name.toLocaleUpperCase(),
            this.reportTemplate,
            { maxHeight: '95vh', minWidth: '40%' },
            () => { }
         )
      });
   }

   private openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
      this._dialogRef = this.dialog.open(DialogComponent, {
         disableClose: true,
         minWidth: style.minWidth,
         maxHeight: style.maxHeight,
         data: { template, title },
      })
      this._dialogRef.afterClosed().subscribe(() => afterClosed());
   }

   /***********
    * HANDLERS *
    ***********/

   onFilterAttentions() {
      const formValues = this.filterForm.value;
      let {patient, financier, ...afp} = { ...this.initialAFP };

      //Checks
      const states = [this.INFORMED_STATE_ATTENTION, this.BILLABLE_STATE_ATTENTION, this.DEBITED_STATE_ATTENTION,
      this.IN_PROCESS_STATE_ATTENTION, this.NOT_INFORMED_STATE_ATTENTION]

      states.forEach((stateName: string) => {
         if (formValues[stateName]) {
            if (afp?.statesIds.length > 0 && !afp?.statesIds.find(stateId => stateId == parseInt(stateName)))
               afp.statesIds ? afp.statesIds.push(parseInt(stateName)) : afp.statesIds = [parseInt(stateName)];
         } else {
            afp.statesIds = afp.statesIds.filter(id => id != parseInt(stateName));
            if (!(afp.statesIds.length > 0)) {
               delete afp.statesIds;
            }
         }
      })
      // Rest inputs
      formValues[this.PROVIDER] ? afp.providersIds = [formValues[this.PROVIDER]] : null;
      if (!!afp.providersIds) {
         // TODO: save provider object to after set combo with default
      }
      formValues[this.PATIENT] ? afp.patientsIds = [formValues[this.PATIENT]] : null;
      if (!!afp.patientsIds) {
         // TODO: save patient object provider to after set combo with default
      }
      formValues[this.FINANCIER] ? afp.financiersIds = [formValues[this.FINANCIER]] : null;
      if (!!afp.financiersIds) {
         // TODO: save financier object to after set combo with default
      }
      formValues[this.FROM_DATE] ? afp.fromDate = moment(formValues[this.FROM_DATE]).toDate() : null;
      formValues[this.TO_DATE] ? afp.toDate = moment(formValues[this.TO_DATE]).toDate() : null;

      this.storageService.setObjectToLocalStorage( "consolidationsFilters" , afp);
      this.attentionsFacade.loadAttentions(afp).subscribe();
   }

   onSelectFinancierLiquidation(financierId: number) {
      this.filterLiquidationForm.get(this.LIQUIDATION).reset();
      this.attentionsFacade.loadLiquidations({
        financierId,
        sortAttributePairsStr: "id,DESC"
      }).subscribe(liquidations => this.liquidations = liquidations)
   }

   onPaginatorEvent(event: PageEvent) {
      let afp: AttentionsQPS = this.metaDataAttentions;
      afp.size = event.pageSize;
      afp.page = event.pageIndex;
      this.storageService.setObjectToLocalStorage( "consolidationsFilters" , afp );
      this.attentionsFacade.loadAttentions(afp).subscribe();
   }

   clearSelectionOfAttentionList() {
      this.clearSelection = true;
      setTimeout(() => {
         this.clearSelection = false;
      }, 500)
   }

   onClickInformAttentions(attentions: Attention[]) {

      if (!!attentions && attentions.length > 0) {
         let isSameFinancier: boolean = true;
         const financier: Financier = attentions[0].financier;
         if (attentions.length > 1) {
            isSameFinancier = attentions.every((currentValue, index, array) => {
               return currentValue.financier.id == financier.id;
            })
         }
         if (isSameFinancier) {
            this.swalWithCustomizeButtons.fire({
               icon: 'warning',
               title: 'Atención',
               text: "Este proceso informará al Sistema de Facturación las atenciones previamente seleccionadas",
            }).then(() => {
               this.openReportDialog(financier, attentions);
            })
         } else {
            this.swalWithCustomizeButtons.fire({
               icon: 'error',
               title: 'Atención',
               text: "Las atenciones a informar deben pertenecer al mismo financiador",
            })
         }
      }
   }

   onGeneratePdf() {
      //DESCARGAR BASE64
      this.loadingPdf = true;
      let fileName;
      const liquidationId = this.filterLiquidationForm.get(this.LIQUIDATION).value
      fileName = "Reporte de liquidación - " + liquidationId;
      this.scheduleService.getAttentionsLiquidationReport(liquidationId).toPromise().then((data) => {
         let blobb: Blob = this.fileUtilitiesService.getFileBlob2(data.mediaUrl);
        //  this.exportAsService.downloadFromBlob(blobb, fileName); FIXME: FER
         this._dialogRef.close();
      }).finally(() => this.loadingPdf = false)
   }

   onSearchAttentionsByLiquidation() {
      const liquidationId: number = this.filterLiquidationForm.get(this.LIQUIDATION).value;
      let afp: AttentionsQPS = {
         page: 0,
         size: 10,
         expand: "financier,operator,patient,practice,provider",
         all: true,
         statesIds: [this.ATTENTION_STATES.INFORMADA, this.ATTENTION_STATES.NO_INFORMADA],
         sort: ['fromDate'],
         order: 'DESC',
         liquidationId
      };
      this.attentionsFacade.loadAttentions(afp).subscribe();
   }

   // Para pasarlas a facturable / informada
   patchAttentions(attentions: PatchAttentionBody[]): Promise<Attention[]> {
      return new Promise((resolve, reject) => {
         this.attentionsFacade.patchAttentions( attentions, false ).subscribe(
            (attention) => resolve(attention),
            (error) => reject(error),
         );
      });
   }

   getProvidersByFullName = (value: string) =>
      this.providersService.getProviders({ fullName_like: value, active: true }).pipe(map(dataApi => dataApi.data));;
   getPatientsByNameAndSurname = (value: string) =>
      this.patientsService.getPatients({ fullName_like: value, active: true });
   getFinanciersByName = (value: string) =>
      this.financiersService.getFinanciers({ name_like: value }).pipe(map(dataApi => dataApi.data));
   getLiquidationsByNameAndFinancierId = (financierId) => (value: string) =>
      this.attentionsService.getLiquidations({ name_like: value, financierId }).pipe(map(dataApi => dataApi.data));

   filterDeliveryPractice() {
      return this.attentions.filter(attention => !!attention && !!attention.practice && attention.practice.name != 'Entrega insumos');
   }

   recoveryFilter( filters : AttentionsQPSextended ) : void {
      if (filters.patientsIds){
         this.initialAFP.patientsIds = filters.patientsIds;
      }
      if (filters.providersIds){
         this.initialAFP.providersIds = filters.providersIds;
      }
      if (filters.statesIds){
         this.initialAFP.statesIds = filters.statesIds;
      }
      if (filters.financiersIds){
         this.initialAFP.financiersIds = filters.financiersIds;
      }
      if (filters.fromDate){
         this.initialAFP.fromDate = moment(filters.fromDate, 'YYYY-MM-DD').toDate();
      }
      if (filters.toDate){
         this.initialAFP.toDate = moment(filters.toDate, 'YYYY-MM-DD').toDate();
      }
      if (filters.patient) {
         this.initialAFP.patient = filters.patient;
      }
      if (filters.financier) {
         this.initialAFP.financier = filters.financier;
      }
   }
}
