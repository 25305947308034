import { Component, Input } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import { } from '@angular/forms';

@Component({
    selector: 'app-email-input',
    templateUrl: './email-input.component.html',
    styleUrls: ['./email-input.component.scss'],
})
export class EmailInputComponent extends FormElementComponent {
    @Input() clearable = false;

    constructor() {
        super();
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
