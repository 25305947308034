import { CommonModule } from '@angular/common';
import { Component, computed, input, OnInit, Signal } from '@angular/core';
import { FormsModule } from 'src/app/shared/components/forms/forms.module';
import { Patient } from 'src/app/shared/models/patient';
import { Practice } from 'src/app/shared/models/practice';
import { Specialty } from 'src/app/shared/models/specialty';

@Component({
    selector: 'app-patient-data',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './patient-data.component.html',
    styleUrl: './patient-data.component.scss'
})
export class PatientDataComponent implements OnInit {

    patient = input<Patient>();
    pratice = input<Practice>();
    specialty = input<string>();

    fullName: Signal<string> = computed(() => !!this.patient()?.name && !!this.patient()?.surname
        ? `${this.patient().surname}, ${this.patient().name}`
        : 'Sin especificar'
    );

    ngOnInit(): void {
    }
}
