import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent<T> implements OnInit {

  /**
   * Initializes the component.
   *
   * @param dialogRef - A reference to the dialog opened.
   */

  constructor(
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: { template: TemplateRef<any>, title: string },
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.cd.detectChanges();
  }
}
