export enum ProvisionalControlIndicatorsStates {
    OPERABLES = 'Operables',
    INFORMABLES = 'Informables',
    CON_INSUMOS_NO_PEDIDOS = 'Con insumos no pedidos',
    INFORMADOS = 'Informados',
    TODOS = 'Todos'
}

// Back enum
// OPEN("Abiertos"),
// OPERABLE("Operables"),
// TO_INFORM("Informables"),
// NOT_SCHEDULED("Sin Agenda"),
// WITH_NOT_ORDERED_SUPPLIES("Con insumos no pedidos"),
// INFORMED("Informados");

export enum ProvisionalControlTableOptions {
    CASES,
    ORDERS,
    PROVISIONFEES
}

export enum ProvisionalControlOrderStates {
    ALL = 'ALL',
    WITH_NOT_AUTHORIZED_SUPPLIES = 'WITH_NOT_AUTHORIZED_SUPPLIES',
    NOT_DELIVERED = 'NOT_DELIVERED'
}

export enum ProvisionalControlProvisionFeeStates {
    ALL = 'ALL',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    WITHOUT_SCHEDULE = 'WITHOUT_SCHEDULE',
    BUDGETED = 'BUDGETED'
}

export enum CasesStates {
    ALL = 'Todos',
    OPEN = 'Abiertos',
    CLOSED = 'Cerrados',
    REJECTED = 'Rechazados'
}
