import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvidersRoutingModule } from './providers-routing.module';
import { MapComponent } from './components/map/map.component';
import { MaterialModule } from 'src/app/material/material.module';
import { LocationMapComponent } from "./components/location-map/finder-location-map.component";
import { PatientDataComponent } from './components/patient-data/patient-data.component';
import { SpecialtiesProvidersPoisComponent } from './pages/specialties-providers-pois/specialties-providers-pois.component';
import { FormsModule } from 'src/app/shared/components/forms/forms.module';
import { FilterByAgreementsComponent } from './components/filter-by-agreements/filter-by-agreements.component';


@NgModule({
    declarations: [
        SpecialtiesProvidersPoisComponent,
    ],
    imports: [
        CommonModule,
        ProvidersRoutingModule,
        MaterialModule,
        FormsModule,
        LocationMapComponent,
        PatientDataComponent,
        MapComponent,
        FilterByAgreementsComponent
    ],
    exports: [SpecialtiesProvidersPoisComponent]
})
export class ProvidersModule { }
