<div class="flex-column">

    <div [hidden]="this.step!=1">
        <!-- Patient data -->
        <app-patient-data (patientData)="patientData($event)" (formPatientDataValid)="formPatientDataValid($event)" [parentComponent]="'admission-creation'"></app-patient-data>

        <!-- Space -->
        <div class="mt-4"></div>

        <!-- Case data -->
        <app-case-data (caseData)="caseData($event)" (formCaseDataValid)="formCaseDataValid($event)"></app-case-data>
    </div>
    <!-- Orders -->
    <div [hidden]="step!=2">
        <!-- Order provisions -->
        <app-admissions-provisions (provisionsData)="provisionsData($event)" (formProvisionsDataValid)="formProvisionsDataValid($event)" (arrangementsData)="arrangementsData($event)"></app-admissions-provisions>

        <div class="mt-5"></div>

        <!-- Order supplies -->
        <app-admissions-supplies (suppliesData)="suppliesData($event)" (formSuppliesDataValid)="formSuppliesDataValid($event)"></app-admissions-supplies>
    </div>
    <div [hidden]="step!=3">
        <app-case-management-data (caseManagementData)="caseManagementData($event)" (formCaseManagementDataValid)="formCaseManagementCaseValid($event)"></app-case-management-data>
    </div>

    <div class="mt-3"></div>

    <!-- Button -->
    <div class="flex-row align-space-between-center">
        <button mat-stroked-button [color]="'primary'" (click)="assistantPrevious()" [hidden]="step==1">
            <div class="flex-row align-start-center gap-5px">
                <mat-icon [svgIcon]="'ArrowCircleLeft'"></mat-icon>
                <span>ATRAS</span>
            </div>
        </button>
        <div [hidden]="step!=1"></div>

        <button mat-flat-button [color]="'primary'" (click)="assistantNext()" [disabled]="btn_next_disabled">
            <div class="flex-row align-start-center gap-5px">
                <mat-icon *ngIf="!(isLoadingCreatingRequisition$ | async); else loadingIcon" [svgIcon]="'ArrowCircleRight'"></mat-icon>
                <ng-template #loadingIcon>
                    <mat-icon>
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </ng-template>
                <span>{{ this.labelButtonNext }}</span>
            </div>
        </button>
    </div>
</div>
