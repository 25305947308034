<div class="flex-column">
    <ng-container *ngIf="this.loadingPatient && this.isPatientInputParam">
        <div class="flex-row align-center-center">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
    </ng-container>
    <span *ngIf="!this.isPatientInputParam" class="headline-1 pls-4 mbs-3 border-left text-primary-darker-title">
        {{ 'Datos del paciente' | uppercase }}
    </span>
    <div [ngClass]="{ 'ml-1': !this.isPatientInputParam }" *ngIf="!this.loadingPatient">
        <mat-card class="card-shadow mts-4 mx-1 data-management-container mat-elevation-z2 flex-100 p-1">
            <div class="flex-column ml-4">
                <form [formGroup]="this.formPatient">
                    <div class="flex-column">
                        <div *ngIf="!this.isPatientInputParam" class="flex-row">
                            <span class="text-primary-darker-h3 flex-88 mbs-3">
                                Datos personales
                            </span>
                        </div>
                        <!--patient selector-->
                        <span *ngIf="!this.isPatientInputParam" class="subtitle-3 text-primary-darker mbs-1 ">
                            Crear o seleccionar un paciente
                        </span>
                        <div *ngIf="!this.isPatientInputParam" class="flex-row flex-100">
                            <app-select-searching-dynamic-input
                                class="mbs-1"
                                [placeholder]="'Ingrese nombre y apellido o DNI'"
                                [name]="this.PATIENT_SEARCH"
                                [nameSearching]="this.PATIENT_SEARCHING"
                                [form]="this.admissionPatientForm"
                                class="flex-45" [httpGet]="this.loadPatientsByFullName"
                                (selectedWithEntity)="this.selected($event)"
                                 [calculateId]="this.calculateIdPatient"
                                [calculateName]="this.calculateNamePatient"
                                [defaultOptions]="[{id:'new',name:'Nuevo paciente'}]"
                                [placeholderNoValue]="'Sin resultados'"
                                [placeholderSearching]="'Ingrese nombre y apellido o DNI'"
                                [defaultValue]="'!!this.patientSelected ? this.calculateIdPatient(this.patientSelected)'">
                            </app-select-searching-dynamic-input>
                        </div>
                        <div class="flex-row flex-100 mt-1">
                            <div class="flex-column flex-2">
                                <span class="subtitle-3 text-primary-darker mbs-1" style="width: max-content;">
                                    Tipo de documento
                                </span>
                                <app-select-input [name]="this.PATIENT_TYPEDOCUMENT"
                                    [placeholder]="'Seleccionar'" [elements]="this.typeDocuments"
                                    [defaultValue]="'1'" [form]="this.formPatient">
                                </app-select-input>
                            </div>
                            <div class="flex-column flex-2 ml-1">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    N° de documento
                                </span>
                                <app-text-input [name]="this.PATIENT_DOCUMENTNUMBER" [form]="this.formPatient"
                                    (changes)="this.validateDocumentNumber()"
                                    formControlName="patientDocumentNumber">
                                </app-text-input>
                            </div>
                            <span class="text-secondary subtitle-5 mbs-3" *ngIf="!!this.closedCase">El dni coincide al caso {{this.closedCase?.patient?.surname}}, {{this.closedCase?.patient?.name}}
                                cerrado el {{this.closedCaseDate}} <a href="{{routes.CASES + '/' + closedCase?.id}}"
                                    target="_blank" class="text-primary-darker">Ver caso</a>
                            </span>
                            <div class="flex-column flex-2 ml-3">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Fecha de nacimiento
                                </span>
                                <app-date-picker [name]="this.PATIENT_BIRTHDATE" (onDateChange)="calculateAge()"
                                    [form]="this.formPatient">
                                </app-date-picker>
                            </div>
                            <div class="flex-column flex-2 ml-1 ">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Edad
                                </span>
                                <app-text-input [name]="this.PATIENT_AGE" [readonly]="true" [form]="this.formPatient">
                                </app-text-input>
                            </div>
                        </div>
                        <div class="flex-row flex-100">
                            <div class="flex-column flex-45">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Apellido
                                </span>
                                <app-text-input [name]="this.PATIENT_SURNAME" [form]="this.formPatient">
                                </app-text-input>
                            </div>
                            <div class="flex-column flex-45 ml-5">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Nombre
                                </span>
                                <app-text-input [name]="this.PATIENT_NAME" [form]="this.formPatient">
                                </app-text-input>
                            </div>
                        </div>
                        <div class="flex-row flex-100">
                            <div class="flex-column flex-45">
                                <span class="subtitle-3 text-primary-darker mbs-0">
                                    Sexo
                                </span>
                                <div class="flex-row flex-100 ">
                                    <!-- formControlName="gender" -->
                                    <mat-radio-group class="subtitle-3 text-primary-darker flex-row flex-50 ml-0 align-start"
                                        [name]="this.PATIENT_GENDER">
                                        <mat-radio-button [value]="'M'" >Hombre</mat-radio-button>
                                        <mat-radio-button [value]="'F'" class="ml-3" >Mujer</mat-radio-button>
                                        <mat-radio-button [value]="'S/E'" class="flex-100 ml-3" style="width: max-content !important;">Sin especifar</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form [formGroup]="this.formAddress">
                    <div class="flex-column" [formGroup]="this.formAddress">
                        <span class="text-primary-darker-h3 mt-1" class="flex-100 mbs-3 mt-1">
                            Dirección
                        </span>
                        <div class="flex-row flex-100 mb-0">
                            <div class="flex-30">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Provincia *
                                </span>
                                <app-select-searching-input
                                [name]="this.PATIENT_ADDRESS_STATE"
                                [nameSearching]="this.PATIENT_ADDRESS_STATE_SEARCHING"
                                [form]="this.formAddress"
                                [placeholderSearching]="'Ingrese provincia...'"
                                [calculateName]="this.calculateNameProvince"
                                [calculateId]="this.calculateIdProvince"
                                (selected)="this.selectProvince($event); resetAddressFields()"
                                [elements]="this.provincesOnSelect"
                                [defaultValue]="this.actualPatientProvince?.id.toString()">
                              </app-select-searching-input>
                            </div>
                            <div class="flex-40 ml-3">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Localidad *
                                </span>
                                <app-select-searching-input [name]="this.PATIENT_ADDRESS_LOCATION"
                                    [form]="this.formAddress" [nameSearching]="this.PATIENT_ADDRESS_LOCATION_SEARCHING"
                                    [calculateName]="this.calculateNameLocation"
                                    [calculateId]="this.calculateIdLocation"
                                    [defaultValue]="this.actualPatientLocation?.id.toString()"
                                    [elements]="this.locations" (selected)="this.selectLocation($event); this.resetAddressFields(this.PATIENT_ADDRESS_LOCATION);
                                    ">
                                </app-select-searching-input>
                            </div>
                            <div class="flex-19 ml-3">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Código postal
                                </span>
                                <app-text-input [name]="this.PATIENT_ADDRESS_ZIPCODE" [readonly]="true"
                                    [form]="this.formAddress">
                                </app-text-input>
                            </div>
                        </div>
                        <div class="flex-row flex-100 mb-0">
                            <div class="flex-50">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Calle y Nº
                                </span>
                                <app-address-input [name]="this.PATIENT_ADDRESS_STREET" [city]="this.selectedCity" [province]="this.selectedProvince" [form]="this.formAddress" (selected) = "onSelectAddress($event)" >
                                </app-address-input>
                            </div>
                            <div class="flex-20 ml-3">

                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Piso
                                    </span>
                                    <app-text-input [name]="this.PATIENT_ADDRESS_FLOOR" [form]="this.formAddress">
                                    </app-text-input>

                            </div>
                            <div class="flex-20 ml-3">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Departamento
                                </span>
                                <app-text-input [name]="this.PATIENT_ADDRESS_FLAT" [form]="this.formAddress">
                                </app-text-input>
                            </div>
                        </div>
                        <div class="flex-row flex-100 mt-0">
                            <div class="flex-52">
                                <span class="subtitle-3 text-primary-darker mbs-1">
                                    Observaciones de la dirección
                                </span>
                                <app-text-input [name]="this.PATIENT_ADDRESS_FLATEXTRA" [form]="this.formAddress">
                                </app-text-input>
                            </div>
                        </div>
                    </div>
                </form>
                <form [formGroup]="this.formContacts">
                    <div formArrayName="contacts">
                        <div *ngFor="let contact of contacts.controls; let i = index">
                            <div [formGroupName]="i" class="flex-column">
                                <span class="text-primary-darker-h3 mt-1 flex-100 mbs-3">
                                    Datos de contacto <span *ngIf="i !== 0">{{ i + 1 }}</span>
                                    <button mat-icon-button matTooltip="Eliminar" color="primary" class="mt-2"
                                        (click)="removeContact(i)" *ngIf="contacts.length > 1 || i !== 0">
                                        <mat-icon>delete_outlined</mat-icon>
                                    </button>
                                </span>

                                <div class="flex-row flex-wrap flex-100">
                                    <div class="flex-column flex-45 mr-3" >
                                        <span class="subtitle-3 text-primary-darker mbs-1">Teléfono</span>
                                        <app-text-input [name]="this.PATIENT_NEW_CONTACT_PHONE"
                                            [form]="getContactFormGroup(i)">
                                        </app-text-input>
                                    </div>
                                    <div class="flex-column flex-45">
                                        <span class="subtitle-3 text-primary-darker mbs-1">Email</span>
                                        <app-email-input [name]="this.PATIENT_NEW_CONTACT_EMAIL"
                                            [form]="getContactFormGroup(i)" >
                                        </app-email-input>
                                    </div>
                                </div>
                                    <div class="flex-row flex-100 align-start-center mt-0 mbs-0">
                                        <div class="flex-column">  <!-- flex-65 -->
                                            <span class="subtitle-3 text-primary-darker mbs-1">Referencia
                                            </span>
                                            <app-text-area-input [name]="this.PATIENT_NEW_CONTACT_REFERENCE" [form]="getContactFormGroup(i)"> </app-text-area-input>
                                        </div>
                                        <mat-radio-group formControlName="isMainContact" name="isMainContact" (change)="changeMainContact()" class="align-space-between"
                                            *ngIf="contacts.length > 1" [disabled]="contacts.length <= 1">
                                            <mat-radio-button class="subtitle-3 text-primary-darker"
                                                [value]="true">Contacto principal</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>

                </form>
                <div class="flex-row flex-100 mt-2 mr-3 align-space-between-center full-width">
                    <button mat-stroked-button [color]="'accent'" btn
                        [disabled]="btn_addContact_disabled || this.isloadingUpdate" id="Admission_editPatient"
                        class="btnStyle" matTooltip="Agregar nuevo contacto" (click)="this.addContact()">
                        AGREGAR NUEVO CONTACTO
                    </button>
                    <div *ngIf="this.isPatientInputParam" class="flex-row align-space-around-center gap-1-5em">
                        <button mat-stroked-button [color]="'primary'" (click)="clickCancelEditPatient()"
                            [disabled]="this.isloadingUpdate">
                            <span>CANCELAR</span>
                        </button>
                        <button mat-flat-button [color]="'primary'"
                            [disabled]="!this.formPatienValid || this.caseActive != null"
                            (click)="clickConfirmEditPatient()">
                            <span *ngIf="!this.isloadingUpdate; else loadingConfirmIcon">
                                CONFIRMAR
                            </span>
                            <ng-template #loadingConfirmIcon>
                                <mat-icon>
                                    <mat-spinner color="accent" diameter="20">
                                    </mat-spinner>
                                </mat-icon>
                            </ng-template>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<ng-template #patientCaseActive>
    <div class="flex-column flex-100">
        <div class="flex-column bg-primary-lighter p-5">
            <div class="flex-row align-center">
                <mat-icon svgIcon="WarningCircle" style="height: 55px; width: 55px;"></mat-icon>
            </div>
            <span class="subtitle-3 active-case-text-margin" style="text-align: center;">
                El paciente {{ this.patientSelected?.surname + ", " + this.patientSelected?.name }} ya tiene
                {{this.isRequisition(this.caseActive != null ? this.caseActive : this.closedCase) ? 'una' : 'un'}} <b
                    class="text-primary">{{this.isRequisition(this.caseActive != null ? this.caseActive :
                    this.closedCase) ? 'solicitud' : 'caso'}} {{this.closedCase != null ? 'cerrado' : 'en gestión'}}.</b>
            </span>
        </div>
        <div class="flex-column m-5 border-data">
            <span class="content-margin">ID #</span>
            <div class="flex-row content-margin">
                <app-text-input [form]="this.formPatientCaseActive" [name]="this.PATIENT_NAME_CASE_ACTIVE"
                    class="flex-100">
                </app-text-input>
            </div>
            <button mat-stroked-button class="text-primary content-margin-right subtitle-3 btnVerCaso"
                [color]="'primary'" (click)="redirectToCase()">
                {{this.isRequisition(this.caseActive != null ? this.caseActive : this.closedCase) ? 'Ver solicitud' :
                'Ver caso' | uppercase}}
            </button>
            <span class="content-margin mt-4">Operador</span>
            <div class="content-margin align-start">
                <app-text-input [form]="this.formPatientCaseActive" [name]="this.PATIENT_OPERATOR_CASE_ACTIVE"
                    class="flex-100"></app-text-input>
            </div>
            <div class="flex-row align-space-evenly mt-3">
                <button mat-raised-button [color]="'primary'"
                    (click)="this.parentComponent == 'admission-creation' ? this.returnToDashboard() : this.closeDialog()">
                    {{this.closedCase != null ? 'CANCELAR' : 'OK'}}
                </button>
                <button mat-raised-button *ngIf="this.parentComponent == 'admission-creation'" [color]="'primary'"
                    (click)="this.addTask()">
                    GENERAR TAREA
                </button>
                <button mat-raised-button *ngIf="this.closedCase != null" [color]="'primary'"
                    (click)="this.confirmChanges()">CONFIRMAR CAMBIOS</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #selectTask>
    <div class="flex-column align-center-center">

        <form [formGroup]="formSelectTask">
            <mat-radio-group class="flex-column align-start none gap-1em " formControlName="radioButton"
                (change)="this.radioButtonSelected( $event )">
                <mat-radio-button [value]="'Aumento Prestacional'"><span style="font-weight: bolder;"
                        class="text-primary-darker">Aumento prestacional</span></mat-radio-button>
                <mat-radio-button [value]="'Autorización'"><span style="font-weight: bolder;"
                        class="text-primary-darker">Autorización</span></mat-radio-button>
                <mat-radio-button [value]="'Baja Parcial'"><span style="font-weight: bolder;"
                        class="text-primary-darker">Baja parcial</span></mat-radio-button>
                <mat-radio-button [value]="'Finalización'"><span style="font-weight: bolder;"
                        class="text-primary-darker">Finalización</span></mat-radio-button>
            </mat-radio-group>
        </form>

        <div class="mt-6"></div>

        <div class="flex-row align-space-around center gap-2em">
            <button mat-stroked-button [color]="'primary'" (click)="returnToDashboard()" [disabled]="">
                <span>CANCELAR</span>
            </button>
            <button mat-flat-button [color]="'primary'" [disabled]="!formSelectTask.valid"
                (click)="clickConfirmSelectTask()">
                <span>
                    GUARDAR
                </span>
            </button>
        </div>
    </div>
</ng-template>
