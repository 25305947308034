<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg">
    </mat-icon>
    <mat-form-field class="full-width" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <mat-hint>{{ this.hint }}</mat-hint>
        <input matInput [formControlName]="name" id="{{ name }}" name="{{ name }}" type="text"
            placeholder="{{ placeholder }}" [readonly]="readonly" (keyup.enter)="this.enter.emit()"
            (change)="this.changes.emit()" [attr.disabled]="disabled ? '' : null" maxlength="{{ maxLength }}"
            (click)="selectAll()" />
        <button tabIndex="-1" [disabled]="!this.form.get(this.name)?.value || this.form.get(this.name)?.disabled"
            type="button" mat-button *ngIf="this.clearable" matSuffix mat-icon-button aria-label="Clear"
            (click)="this.form.get(this.name)?.reset()">
            <mat-icon>close</mat-icon>
        </button>
        <!-- @if (this.form.get(this.name)?.errors && (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)) {
            <mat-error> {{ getError() }} </mat-error>
        } -->
        <mat-error *ngIf="hasError('pattern')">
            Solo se permiten números
        </mat-error>
        <mat-error *ngIf="hasError('required')">
            Este campo es obligatorio
        </mat-error>
        <mat-error *ngIf="hasError('maxlength')">
            Se excedió el número máximo de caracteres
        </mat-error>
    </mat-form-field>
</div>
