<!-- Evolutions SideBar -->
<ng-container *ngIf="this.evolutionsSideBarShow$ | async">
    <app-evolutions-side-bar></app-evolutions-side-bar>
</ng-container>

<!-- Case -->
<div class="flex-column px-1 py-1">
    <div class="flex-column flex-wrap gap-15px" *ngIf="!(this.loadingCase$ | async) && !!this.case; else loading">

        <div class="flex-row space-between-center">

            <div class="flex-column align-start-start flex-25">
                <!-- {{ this.case.patient.surname | uppercase }}, {{ this.case.patient.name | uppercase }} -->
                <span class="headline-1 pls-4 mbs-2 border-left text-primary-darker-title" style="line-height: 1.0em">
                    {{ this.generalService.getFullName( this.case.patient.name, this.case.patient.surname ) }}
                </span>
                <div class="flex-row align-start-center gap-1rem">
                    <span class="mls-5 subtitle-3 text-secondary">
                        {{ this.case.patient.typeDocument ? this.case.patient.typeDocument : 'DNI '}}
                        {{ this.case.patient.documentNumber && this.case.patient.documentNumber != '0' ?
                        this.case.patient.documentNumber : 'Sin datos' }}
                    </span>
                    <mat-chip-listbox *ngIf="this.case.requested">
                        <mat-chip style="white-space: nowrap; cursor: pointer;" class="chips-select ellipsis"
                            [ngClass]="this.requisitionService.getColorRequisitionState(this.requisitionType.id)"
                            *ngIf="!!this.requisitionType" matTooltip="{{this.requisitionType.description}}">
                            <span class="subtitle-3">
                                {{ this.requisitionType.description | titlecase | uppercase }}
                            </span>
                        </mat-chip>
                        <mat-chip style="white-space: nowrap; cursor: pointer;" class="chips-select ellipsis"
                            [ngClass]="this.requisitionService.getColorRequisitionState(this.requisitionState.id)"
                            *ngIf="!!this.requisitionState" matTooltip="{{this.requisitionState.description}}">
                            <span class="subtitle-3">
                                {{ this.requisitionState.description | titlecase | uppercase }}
                            </span>
                        </mat-chip>
                    </mat-chip-listbox>
                </div>
            </div>

            <app-case-indicators *ngIf="!this.case.requested" class="flex-70" [attentions]="this.attentions">
            </app-case-indicators>
        </div>
        <mat-card class="flex-100 pt-0 pb-0 mat-elevation-z4">
            <!-- Button Evolutions SideBar -->
            <div style="position: absolute; top: 0; right: 0; z-index: 10;" class="">
                <div class="flex-row space-around-center">
                    <button mat-icon-button [color]="'primary'" [matMenuTriggerFor]="menuExport">
                        <mat-icon matTooltip="Exportar carátula del caso">save_alt</mat-icon>
                    </button>
                    <button id="caseComponent_btnshowSideBarEvolutions" mat-icon-button [color]="'primary'"
                        (click)="this.showSideBarEvolutions()" matTooltip="Notas">
                        <mat-icon>assignment</mat-icon>
                    </button>
                    <mat-menu #menuExport="matMenu">
                        <button mat-menu-item (click)="this.onClickOptionToExport(OPTIONS_TO_EXPORT.PDF)">
                            <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30" [svgIcon]="'pdf'">
                            </mat-icon>PDF
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div *ngIf="showOptions" [@enterAnimation] class="flex-column">
                <form *ngIf="filterCoverForm" [formGroup]="filterCoverForm" class="mxs-5 mbs-3 mt-3 flex-column">
                    <div class="text-secondary-darker mys-3 flex-align-center">
                        Configuración de exportación:
                    </div>
                    <!-- Select logo -->
                    <app-select-input class="flex-100" [name]="this.LOGO_COVER" [form]="this.filterCoverForm"
                        [placeholder]="'Encabezado:'" [elements]="this.elementsLogo"
                        [calculateId]="this.calculateIdlogo" [calculateName]="this.calculateNameLogo">
                    </app-select-input>
                    <div *ngIf="this.logoCoverForm == LOGO_OPTIONS.CUSTOM_LOGO">
                        <app-file-input class="flex-100" [name]="this.LOAD_FILE_COVER" [form]="this.filterCoverForm">
                        </app-file-input>
                    </div>
                    <div>
                        <mat-error class="mbs-5" *ngIf=" this.filterCoverForm.errors &&
                         (this.filterCoverForm.dirty || this.filterCoverForm.touched)">
                            <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
                            {{ objectValues(this.filterCoverForm.errors)[0] }}
                        </mat-error>
                    </div>
                    <div class="flex-row align-space-around-center">
                        <button mat-stroked-button [color]="'primary'" (click)="this.showOptions = false">CANCELAR</button>
                        <button mat-flat-button [color]="'primary'" (click)="this.onSubmitExportFunction()" [disabled]="!this.filterCoverForm.valid || (pdfSpinner$ | async)">
                            <span *ngIf="!(pdfSpinner$ | async); else loadingIcon">EXPORTAR</span>
                        </button>
                        <ng-template #loadingIcon>
                            <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
                        </ng-template>
                    </div>
                </form>
            </div>
            <div style='min-height:400px !important'>
                <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" class="flex tab-custom-overflow" (selectedTabChange)="onTabChanged($event);" [disableRipple]="false" animationDuration="0ms" [selectedIndex]="1">
                    <mat-tab label="Datos principales">
                        <app-case-main-data class="mts-2" [case]="this.case"
                            [disabledSwitchToCase]="!this.isSwitchToCaseEnabled()" (onCloseCase)="this.onCloseCase()"
                            (onReopenCase)="this.onReopenCase()"
                            (onChangeEnabledFinancier)="this.onChangeEnabledFinancier()"
                            (onGenerateOperationReport)="this.onClickOperationReport()"
                            (onChangeState)="this.changeState()" (onSwitchToCase)="this.switchToCase()">
                        </app-case-main-data>
                    </mat-tab>

                    <mat-tab [ngClass]="{'classe': this.refresh}" label="Operaciones">
                        <!-- [attentions]="this.attentions" -->
                        <app-provision-scheme [case]="this.case" [data]="this.dataEvolutions"
                            [attentionsCaseActive]="this.attentionsCaseActive"></app-provision-scheme>
                    </mat-tab>

                </mat-tab-group>
            </div>
            <mat-card-content class="mys-3">
                <div class="{{ isProvisionsDetailExpanded ? 'flex-column': 'flex-row'}}"
                    [ngClass]="isProvisionsDetailExpanded ? 'start-stretch' : 'space-between-start'"
                    class="gap-{{isProvisionsDetailExpanded ? '25px':'5%'}}">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Templates -->
<ng-template #loading>
    <!-- <app-spinner class="flex-100" fxLayoutAlign="center start"></app-spinner> -->
    <div class="example-loading-shade">
        <app-spinner></app-spinner>
    </div>
</ng-template>

<ng-template #generateOperationReportTemplate>
    <div class="flex-column">
        <form *ngIf="this.formOperationReport" class="flex-100" [formGroup]="this.formOperationReport"
            class="flex-column" (submit)="onClickExportOperationReport()">
            <div class="flex-row space-between">
                <app-date-picker class="mxs-5" [name]="this.DATE_FROM" [form]="formOperationReport"
                    [placeholder]="'Atenciones desde la fecha '" style="min-width: 240px;">
                </app-date-picker>
                <app-date-picker [name]="this.DATE_TO" [leftIconClass]="'text-primary'" [form]="formOperationReport"
                    [placeholder]="'Atenciones hasta la fecha'" style="min-width: 240px;">
                </app-date-picker>
            </div>
            <div>
                <mat-error class="mbs-5" *ngIf=" this.formOperationReport.errors &&
                         (this.formOperationReport.dirty || this.formOperationReport.touched)">
                    <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
                    {{ objectValues(this.formOperationReport.errors)[0] }}
                </mat-error>
            </div>

            <div class="mts-3 gap-15px flex-row space-around-start">
                <button mat-stroked-button [color]="'primary'" type="button"
                    (click)="this.onClickCloseDialogGenerateOperationReport()" [disabled]="isLoadingReport">
                    <span>
                        CANCELAR
                    </span>
                </button>
                <button mat-flat-button [color]="'primary'" type="submit"
                    [disabled]="!this.formOperationReport.valid || isLoadingReport">
                    <span *ngIf="!this.isLoadingReport else loadingReport">
                        CREAR
                    </span>
                    <ng-template #loadingReport>
                        <mat-icon>
                            <mat-spinner color="accent" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </ng-template>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<div [hidden]="true">
    <div id="coverToPrint" *ngIf="this.filterCoverForm?.valid"
        style="padding: 20px 30px 20px 30px ; position: relative;">
        <div ng-clock class="flex-column align-start-stretch pt-1"
            style="position: sticky; top: 0; background-color: white; z-index: 2; border: 1px solid black;">
            <div class="flex-row space-between p-2">
                <p class="text-secondary-darker" style="font-size: 24px; font-weight: bold;">
                    Financiador:
                    <span class="mls-2">{{ this.case.financier.name }}</span>
                </p>

                <p class="text-secondary-darker" style="font-size: 18px; font-weight: 400;">
                    Nro Siniestro:
                    <span class="mls-2">
                        <b>{{this.case.financierInternalCode ? this.case.financierInternalCode : ''}}</b>
                    </span>
                </p>
            </div>
            <div class="flex-row space-between p-2">
                <p class="text-secondary-darker" style="font-size: 24px; font-weight: bold;">
                    Paciente:
                    <span class="mls-2">{{this.case.patient.surname + " " + this.case.patient.name}}</span>
                </p>
                <p class="text-secondary-darker" style="font-size: 18px; font-weight: 400;">
                    Tipo y Nro de documento:
                    <span class="mls-2">
                        {{this.case.patient.typeDocument ? this.case.patient.typeDocument : ''}}
                        <b>{{this.case.patient.documentNumber ? this.case.patient.documentNumber : 'Sin datos'}}</b>
                    </span>
                </p>
            </div>
            <div class="flex-row space-evenly p-2">
                <div class="flex-column">
                    <p class="text-secondary-darker" style="font-size: 24px; font-weight: bold;">
                        Prácticas:
                    </p>
                    <p *ngFor="let practice of practices;" class="text-secondary-darker"
                        style="font-size: 18px; font-weight: 400;">
                        <span class="mls-2">
                            {{practice.name + ':'}}
                        </span>
                        <span class="mls-2">
                            <b>{{practice.count }}</b>
                        </span>
                    </p>
                </div>
                <div class="flex-column">
                    <p class="text-secondary-darker" style="font-size: 16px; font-weight: 400;">
                        Id-Web:
                        <span class="mls-2">
                            <b>{{ this.case.id }}</b>
                        </span>
                    </p>
                    <p class="text-secondary-darker" style="font-size: 16px; font-weight: 400;">
                        Id-Sist Externo:
                        <span class="mls-2">
                            <b>{{ this.case.legacyId }}</b>
                        </span>
                    </p>
                    <p class="text-secondary-darker" style="font-size: 16px; font-weight: 400;">
                        Rango :
                        <span class="mls-2">
                            <b>{{ dateFromCoverForm | date: 'dd/MM/yyyy' }}</b> a <b>{{ dateToCoverForm | date:
                                'dd/MM/yyyy' }}</b>
                        </span>
                    </p>

                    <div *ngIf="this.logoCoverForm != LOGO_OPTIONS.NO_LOGO" class="align-end-start">
                        <img *ngIf="!!this.user && !!this.user.photoDataUrl && this.logoCoverForm == LOGO_OPTIONS.COMPANY_LOGO"
                            alt="Logo" style="display: block;max-width:200px;
                      max-height:180px;
                      width: auto;
                      height: auto;
                      object-fit: contain;" [src]="this.sanitizeUrl(this.user.photoDataUrl)" />
                        <img *ngIf="this.logoCoverForm == LOGO_OPTIONS.ADOMICILIO_LOGO" alt="Logo" style="display: block;max-width:250px;
                      max-height:220px;
                      width: auto;
                      height: auto;
                      object-fit: contain;" src="assets/logos/ADomicilio horizontal.png" />
                        <img *ngIf="this.logoCoverForm == LOGO_OPTIONS.CUSTOM_LOGO" alt="Logo" style="display: block;max-width:200px;
                      max-height:180px;
                      width: auto;
                      height: auto;
                      object-fit: contain;" [src]="this.sanitizeUrl(this.loadFileCoverForm)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #updateState>
    <form (submit)="onClickConfirmUpdateState()" [formGroup]="this.formUpdateState">
        <div class="flex-column">
            <mat-radio-group class="flex-column py-3 px-2" (change)="clickRadiobuttonChangeState($event)"
                name="radioButton" formControlName="radioButton">
                <mat-radio-button *ngFor="let option of transitionStatesPermitted(this.requisitionTransitionStates)"
                    [value]="option.id" color="primary" class="subtitle-3 text-primary-darker">
                    {{ option.description }}
                </mat-radio-button>
            </mat-radio-group>
            <div class="mts-3 gap-15px flex-row space-evenly-start">
                <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                    [disabled]="">
                    CANCELAR
                </button>
                <button mat-flat-button [color]="'primary'" type="submit" [disabled]="!this.formUpdateState.valid">
                    GUARDAR
                </button>
            </div>
        </div>
    </form>
</ng-template>
