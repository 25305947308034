<div class="flex-100 flex-column gap-1-5em mts-2 p-2" *ngIf="!!this.case">
    <div class="flex -100 flex-column gap-1-5em">
        <div class="flex-100 flex-row gap-1-5em">

            <!-- Financier CARD -->
            <mat-card class="flex-column flex-50 p-1 ">
                <span class="subtitle-card-1">Datos del caso</span>
                <div class="flex-row align-space-between-center p-1-5">
                    <!-- style="height: 30%; min-height: 100px;" -->
                    <div class="flex-row align-start-center my-2 gap-1-5em mx-1">
                        <!-- Icon -->
                        <mat-icon
                            [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                            [svgIcon]="'hospital'" style="transform: scale(3.2) !important;">

                        </mat-icon>

                        <!-- Financier name -->
                        <div class="flex-column pls-1 flex-100">
                            <span id="financierName" class="mb-0 subtitle-card-2 p-1">
                                {{ (this.case.financier.name ? this.case.financier.name : 'Sin nombre de cliente') |
                                uppercase
                                }}
                            </span>
                            <span class="p-1 text-secondary">
                                {{this.case?.companyType?.description ? this.case?.companyType?.description : 'Sin fines de lucro'}}
                            </span>
                        </div>
                    </div>
                    <!-- Edit case -->
                    <button matTooltip="Modificar datos del caso" (click)="onClickEditCase()" mat-icon-button
                        [disabled]="!this.case.active">
                        <!-- !(this.isLoadingGettingPatient$ | async) -->
                        <mat-icon
                            [svgIcon]="this.case.active ? 'PencilSimple-enabled-primary-lighter' : 'PencilSimple-disabled'"></mat-icon>
                    </button>
                </div>

                <!-- Case data -->
                <mat-card-content class="flex-column flex-1-1-auto p-3  gap-2em">
                    <div class="align-start-center my-2">
                        <a (click)="onClickChangeEnabledFinancier()" style="text-decoration: none;"
                            class="text-primary cursor-pointer align-start-center">
                            <mat-icon class="mat-icon-medium mrs-1">
                                {{
                                this.case?.caseParametrization?.enabledFinancier ?
                                'visibility' :
                                'visibility_off'
                                }}
                            </mat-icon>
                            <span matTooltip="Modificar a no visible para cliente" class="my-4"
                                *ngIf="this.case?.caseParametrization?.enabledFinancier">
                                Caso visible para el cliente
                            </span>
                            <span matTooltip="Modificar a visible para el cliente"
                                *ngIf="!(this.case?.caseParametrization?.enabledFinancier)">
                                Caso no visible para el cliente
                            </span>
                        </a>
                    </div>
                    <div class="align-start-center my-2">
                        <mat-icon class="mat-icon-medium mrs-1" [svgIcon]='"hash"'></mat-icon>
                        <span matTooltip="Codigo interno del financiador">
                            CIF&nbsp;{{ this.case.financierInternalCode ? this.case.financierInternalCode : 'Sin CIF' }}
                        </span>
                    </div>
                    <div class="align-start-center mbs-4">
                        <mat-icon class="mat-icon-medium mrs-1" [svgIcon]="'user-tie'"></mat-icon>
                        <span id="financierOperator">
                            {{ this.case.financierOperator ? this.case.financierOperator : 'Gestor del caso no asignado'
                            }}
                        </span>
                    </div>
                    <!--VAT section-->
                    <div class="flex-row align-start-center mts-2 gap-0-7em my-2">
                        <mat-icon class="mat-icon-medium ">sell</mat-icon>
                        <span>{{!!this.case?.vatCategory ? this.case?.vatCategory?.description : 'Sin datos'}}</span>
                    </div>
                    <!-- Diagnoses -->
                    <div class="flex-row align-start-center flex-gap-small">
                        <mat-icon class="mat-icon-medium mrs-1 flex-4 btnicn">local_hospital</mat-icon>
                        <span id="diagnosis">
                            {{
                            !!this.case.diagnoses
                            ? !!this.case.diagnoses[0]
                            ? this.case.diagnoses[0].name
                            : 'Sin diagnóstico'
                            : 'Sin diagnóstico'
                            }}
                        </span>
                        <button class="btnMenu" [matTooltip]="'Diagnósticos'" (click)="this.showDiagnosesList()" mat-icon-button>
                            <mat-icon class="text-primary">list</mat-icon>
                        </button>
                    </div>
                    <!-- Arrangement Section -->
                    <div class="flex-row align-start-center flex-gap-small">
                        <mat-icon class="mat-icon-medium mrs-1 btnicn">handshake</mat-icon>
                        <span [matTooltip]="listCaseArrangementsString">{{
                            listCaseArrangementsString != '' ? listCaseArrangementsString : 'Convenio del caso no asignado'
                            }}
                        </span>
                        <button class="btnMenu"  [matTooltip]="'Lista de convenios'" mat-icon-button (click)="this.openArrangementsListTemplate()">
                            <mat-icon class="text-primary">list</mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
                <!-- Patient detail -->
                <app-patient-detail [case]="this.case" class="flex-column flex-50"></app-patient-detail>
        </div>
        <!-- Case CARD -->
        <mat-card class="flex-column flex-100" style="padding: 1%;">
            <div class="flex-row align-start-center pls-1">
                <!-- Icon -->
                <div class="align-start-center flex-5">
                    <mat-icon [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                        class="mat-icon-extraLarge">assignment</mat-icon>
                </div>
                <!-- Case number & label -->
                <div class="flex-45 align-center-start flex-column">
                    <span class="mat-h2 mb-0 text-secondary-darker">
                        {{ this.case.id }}
                    </span>

                    <span class="text-secondary">
                        {{this.case.active ? 'Caso abierto' : 'Caso cerrado'}}
                    </span>
                </div>
                <!-- ACTIONS -->
                <div class="flex-50 flex-row align-end-center">
                    <button mat-stroked-button [color]="'primary'" (click)="onClickOperationReport()"
                        *ngIf="!this.case.requested">
                        REPORTE DE ACTUACIÓN
                    </button>
                    <button mat-stroked-button [color]="'accent'" class="ml-2"
                        *ngIf="this.case.active && !this.case.requested" (click)="onClickCloseCase()">
                        CERRAR CASO
                    </button>
                    <button mat-stroked-button [color]="'accent'" class="ml-2" *ngIf="!this.case.active"
                        (click)="onClickReopenCase()">
                        REABRIR CASO
                    </button>
                    <button mat-stroked-button [color]="'primary'" (click)="onClickChangeState()"
                        *ngIf="this.case.requested">
                        CAMBIO DE ESTADO
                    </button>
                    <button mat-stroked-button [disabled]="disabledSwitchToCase" [color]="'accent'" class="ml-2"
                        *ngIf="this.case.active && this.case.requested" (click)="onClickSwitchToCase()">
                        PASAR A CASO
                    </button>
                    <!-- <span class="flex-100" *ngIf="this.case.requested">Para realizar un cambio de estado hacerlo desde el panel <a href="{{routes.ADMISSIONS_LIST}}" class="text-primary-darker">Solicitudes</a></span> -->
                </div>
            </div>

            <mat-card-content class="flex-column text-secondary-darker mts-4 mxs-3">
                <div class="flex-row mbs-4 align-space-between-start">
                    <div class="mts-2 align-start-center wrap flex-row">

                        <mat-icon class="mat-icon-medium mrs-1" [svgIcon]="'hash'">
                        </mat-icon>
                        <span>
                            {{this.case?.legacyId ? 'Cod. ME ' + this.case?.legacyId : 'Sin código en el sistema externo'}}
                        </span>
                    </div>

                    <app-operators-selector [case]="this.case"></app-operators-selector>

                    <div class="mts-2 align-end-center" style="display: flex;">
                        <mat-icon class="mat-icon-medium mrs-1">calendar_today</mat-icon>
                        <span> Última agendada: {{ this.case.validity ? (this.case.validity | date: 'dd/MM/yyyy') : 'Sin agenda' }}
                        </span>
                    </div>
                </div>
                <div class="separator separator-center-left mts-2 align-center-center">
                    <div class="mx-2 align-center-center">
                        <mat-icon class="text-secondary mrs-1">beenhere</mat-icon>
                        <span class="text-secondary">Seguimiento de estados</span>
                    </div>
                </div>
                <app-tracking class="mxs-4 mls-5" [elements]="this.trackingElements"></app-tracking>

                <!-- Consentimiento -->
                <div class="separator separator-center-left mts-4 align-center-center">
                    <div class="mx-2 align-center-center">
                        <mat-icon class="text-secondary mrs-1">fact_check</mat-icon>
                        <span class="text-secondary">Consentimiento</span>
                    </div>
                </div>
                <div class="flex-column mts-4 mls-5">
                    <div class="flex-row wrap align-start-center flex-gap-small">
                        <span>Estado</span>

                        <div [ngSwitch]="this.consentStateId"
                            [hidden]="this.isLoadingGettingConsent || this.isLoadingUpdatingConsent">
                            <div *ngSwitchCase="consentsStates.NOT_REQUESTED">
                                <mat-chip-listbox>
                                    <mat-chip class=""
                                        style="white-space: nowrap;cursor: pointer; color:#4E4D4D; background-color: #ECEBEB; padding-left:5vh">
                                        <span class="mat-caption"
                                            style="text-align: center !important;">{{this.CONSENTSTATES_SPANISH[this.consentStateId]
                                            |
                                            uppercase
                                            }}</span>
                                        <ng-template #loadingLoginIcon>
                                            <mat-icon>
                                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                                            </mat-icon>
                                        </ng-template>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </div>
                            <div *ngSwitchCase="consentsStates.SIGNED">
                                <mat-chip-listbox>
                                    <mat-chip
                                        style="white-space: nowrap;cursor: pointer; color: #21A35C; background-color: #DFF7EA;">
                                        <span class="mat-caption">{{this.CONSENTSTATES_SPANISH[this.consentStateId] |
                                            uppercase
                                            }}</span>
                                        <ng-template #loadingLoginIcon>
                                            <mat-icon>
                                                <mat-spinner color="accent" diameter="20">
                                                </mat-spinner>
                                            </mat-icon>
                                        </ng-template>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </div>
                            <div *ngSwitchCase="consentsStates.PENDING">
                                <mat-chip-listbox>
                                    <mat-chip
                                        style="white-space: nowrap;cursor: pointer; color: #FFB800; background-color: #FFF2DF;">
                                        <span class="mat-caption">{{this.CONSENTSTATES_SPANISH[this.consentStateId] |
                                            uppercase
                                            }}</span>
                                        <ng-template #loadingLoginIcon>
                                            <mat-icon>
                                                <mat-spinner color="accent" diameter="20">
                                                </mat-spinner>
                                            </mat-icon>
                                        </ng-template>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </div>
                        </div>
                        <!-- SPINNER CONSENT -->
                        <ng-container *ngIf="this.isLoadingGettingConsent || this.isLoadingUpdatingConsent">
                            <mat-spinner class="mls-4" color="primary" diameter="20"></mat-spinner>
                        </ng-container>
                        <!-- BUTTON CONSENT -->
                        <a mat-button [hidden]="this.isLoadingGettingConsent || this.isLoadingUpdatingConsent"
                            class="text-primary mls-4"
                            style="font-weight: bolder; text-decoration: underline !important;"
                            (click)="clickConsentState()">{{this.CONSENTSTATES_BTN_ACTIVE[this.consentStateId]}}</a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Case arrangements -->
<ng-template #arrangementsListTemplate>
    <div class="flex-column">
        <ng-container>
            <form *ngIf="this.formAddArrangement " class="flex-100" [formGroup]="this.formAddArrangement"
                class="flex-column" (submit)="onClickAddArrangement()">
                <div class="flex-column m-5 border-data">
                    <app-select-searching-input [name]="this.ARRANGEMENT" [nameSearching]="this.ARRANGEMENT_SEARCHING"
                        [form]="this.formAddArrangement" [placeholder]="'Convenio'" [elements]="this.arrangements"
                        [placeholderNoValue]="'Sin resultados'"
                        [placeholderSearching]="'Buscar convenios con ' + this.case.financier.name + '...'"
                        (selected)="onSelectArrangement($event, this.formAddArrangement)">
                    </app-select-searching-input>

                    <div class="flex-row align-space-evenly-center mt-3 gap-1em">
                        <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()">
                            CERRAR
                        </button>
                        <button id="addArrangementToCase" mat-flat-button [color]="'primary'" type="submit"
                            [disabled]="this.formAddArrangement.invalid">
                            <span *ngIf="!(this.isLoadingAddCaseArrangement$ | async); else loadingIcon">
                                AGREGAR
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            <ng-container *ngIf="!!this.caseArrangements && this.caseArrangements.length > 0">
                <table mat-table [dataSource]="this.caseArrangements">
                    <!-- Arrangement Column -->
                    <!-- Arrangement name -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Convenio </th>
                        <td mat-cell *matCellDef="let arrangement"> {{arrangement.name}} </td>
                    </ng-container>
                    <!-- Button delete -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let arrangement">
                            <button (click)="onClickDeleteArrangement(arrangement)" [matTooltip]="'Eliminar convenio'"
                                mat-icon-button>
                                <mat-icon [svgIcon]="'Trash-enabled-primary'"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="bg-on-hover" *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #loadingIcon>
    <mat-icon>
        <mat-spinner color="accent" diameter="10"></mat-spinner>
    </mat-icon>
</ng-template>

<!-- Edit case -->
<ng-template #editCase>
    <app-case-data [caseIN]="this.patchCase" [isloadingUpdate]="( this.isLoadingUpdatingCase$ | async )"
        (caseData)="caseData($event)" (formCaseDataValid)="formCaseDataValid($event)"
        (onClickCancelEditCase)="clickCancelEditCase()" (onClickConfirmEditCase)="clickConfirmEditCase()">
    </app-case-data>
</ng-template>

<ng-template #diagnosesTemplate>
    <div class="flex-column">

        <table mat-table [dataSource]="this.case.diagnoses" class="flex-100">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> id. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Diagnóstico </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Observations Column -->
            <ng-container matColumnDef="observation">
                <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDiagnoses"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDiagnoses"></tr>

        </table>
        <div class="mts-3 flex-100 gap-15px wrap flex-row align-center">
            <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()">
                <span>
                    CERRAR
                </span>
            </button>
        </div>
    </div>
</ng-template>
