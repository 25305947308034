<!-- Filtros de Atenciones -->
<div class="flex-row flex-100">
    <div class="flex-column gap-10px" style="width: 100%;">
        <app-calendar-case class="flex-100" (onEventClicked)="this.onEventClickedHandler($event)"
            (onEventDelete)="onClickDeleteAttention($event)" [loading]="this.loadingInnerCalendar"
            (onDayClicked)="this.onClickCreateAttention($event)" [colorsForAgreements]="colorsForAgreements"
            [attentions]="this.filteredAttentions" *ngIf="this.optionSelected == 'calendario'"
            [optionSelected]="this.optionSelected" (option)="this.receiveOptionSelected($event)"
            (onClickCreateAttention)="createAttentionFromOUT($event)"
            (viewSelected)="this.setViewSelectedCalendarCase($event)">
        </app-calendar-case>
        <app-attentions-list-case class="flex-100" [textNoAttentions]="'No existen atenciones para el mes indicado'"
            [showGoToCase]="false" [showProviderPracticeFilter]="true" [providers]="this.filterCaseProviders()"
            [ruleClickable]="this.attentionsService.isDeletable"
            (onClickButton4)="this.viewColumnsBillAndDebit ? this.onClicProcessBillAndDebit($event) : ''"
            [colorButton4]="'primary'" [textButton4]="this.viewColumnsBillAndDebit ? 'PROCESAR' : ''"
            [descriptionStateShort]="true" [bottomTotals]="true"
            [columnsToShow]="this.viewColumnsBillAndDebit ? this.displayColumnsForAttentions : ['fromDate', 'isAuthorized', 'timeRange', 'hours', 'practice', 'provider', 'state', 'actionsMenu']"
            [attentions]="this.filteredAttentions" (onUpdateClicked)="this.receiveEventUpdate($event)"
            (onDeleteClicked)="this.receiveEventDelete($event)" [attentionsWithOverlap]="this.attentionsWithOverlap"
            *ngIf="this.optionSelected == 'listado'" (option)="this.receiveOptionSelected($event)"
            [optionSelected]="this.optionSelected" (onClickCreateAttention)="createAttentionFromOUT($event)"
            [processSuccessOnAttentions]="this.processSuccessOnAttentions"
            (statesAttentionSelectedEmitter)="receiveStateAttentionsSelected($event)"
            [statesAttentionsSelectedInput]="stateAttentionSelected"
            [loading]="this.isLoadingGetAttentions || this.isLoadingGettingEvolutions || this.isLoadingFilteredAttentions"
            [provisionsFilteredCount]="this.provisionsFilteredCount"
            [provisionsSuppliesFilteredCount]="this.provisionsSuppliesFilteredCount"
            [ordersFilteredCount]="this.ordersFilteredCount" [provisionAgreements]="this.provisionAgreements"

            [provisionAgreements]="this.provisionAgreementsInput.agreements"
            [provisionFees]="this.provisionAgreementsInput.provisionFees"

            [showMonth]="false" [selectedOption]="this.selectedOption" [focusToElement]="focusEmitter">
        </app-attentions-list-case>
    </div>
</div>

<div class="spinner-hover" *ngIf="mustShowSpinner()">
    <mat-spinner diameter="60"></mat-spinner>
</div>

<!-- FORM attention Add -->
<ng-template #scheduleDialogTemplate>
    <!-- Adding attention -->
    <form class="flex-column flex-100" *ngIf="scheduleForm" [formGroup]="scheduleForm">
        <div class="flex-row flex-100 schedule-case-container">
            <div class="flex-45 align-start-none practice-agreement-container">
                <!-- Select provision_fee -->
                <div class="align-center-none" style="padding: 10px 10px 0px 10px;">
                    <app-select-input [name]="this.PROVISION_FEE" [form]="this.scheduleForm"
                        [placeholder]="'Seleccione la prestación'" [elements]="this.provisionFees"
                        [forceNumber]="this.calculateProvisionFeeId" [calculateId]="this.calculateProvisionFeeId"
                        [calculateName]="this.calculateProvisionFeeName" [disabled]="loadingCreateAttention"
                        (entitySelected)="this.onSelectedProvisionFee( $event )">
                    </app-select-input>
                    <span style="color: #f44336;"
                        *ngIf=" !!this.provisionFeeSelected && this.provisionFeeSelected?.frequency?.amount == null || this.provisionFeeSelected?.frequency?.unit == 'Sin especificar'">La
                        práctica seleccionada no tiene frecuencia, Corrija desde corregir práctica y vuelva a intentar.
                    </span>
                </div>

                <!-- Agreements -->
                <!-- class="agreement-list" -->
                <div class="flex-column agreement-list"
                    *ngIf="this.agreements?.length > 0 && !!this.provisionFeeSelected">
                    <!-- Agreemets header -->
                    <div class="bg-primary-lighter text-primary agreement-header">
                        <div class="flex-35 pl-1 align-start-center">
                            <span>Acuerdo prestacional</span>
                        </div>
                        <div class="flex-20 align-start-center">
                            <span>Especialidad</span>
                        </div>
                        <div class="flex-17 align-start-center">
                            <span>Costo</span>
                        </div>
                        <div class="flex-17 align-start-center">
                            <span>Vigencia</span>
                        </div>
                        <!-- Actions -->
                        <div class="flex-11 align-start-center">
                        </div>
                    </div>
                    <!-- Agreements details -->
                    <div class="agreement-information-container" *ngFor="let agreement of this.agreements;">
                        <!-- Agreement information row -->
                        <div class="flex-row align-start-center agreement-row"
                            [ngClass]="{'bg-primary-lighter': (this.agreementSelected == agreement)}">
                            <!-- Column #1 - Arrow -->
                            <div class="flex-row flex-5 text-primary align-self-stretch"
                                (click)="this.agreementsExpanded.toggle(agreement.id)">
                                <div class="flex-row">
                                    <!-- {{this.colorsForAgreements.get(agreement.id).primary.toString()}} -->
                                    <div [ngStyle]="{'background-color': this.colorsForAgreements?.get(agreement.id)?.primary?.toString()}"
                                        class="flex-15"></div>
                                    <div class="flex-85 flex align-center-center">
                                        <mat-icon
                                            *ngIf="!this.agreementsExpanded.selected.includes(agreement.id)">keyboard_arrow_right</mat-icon>
                                        <mat-icon
                                            *ngIf="this.agreementsExpanded.selected.includes(agreement.id)">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <!-- Column #2 - Provider's name -->
                            <div class="flex-30 align-start-center"
                                [matTooltip]="this.entitiesFacade.getProvidersFullName(agreement?.provider)"
                                (click)="this.agreementsExpanded.toggle(agreement.id)">
                                <span
                                    class="text-ellipsis">{{this.entitiesFacade.getProvidersFullName(agreement?.provider)}}</span>
                            </div>
                            <!-- Column #3 - Specialty -->
                            <div class="flex-20 align-start-center"
                                (click)="this.agreementsExpanded.toggle(agreement.id)"
                                [matTooltip]="this.entitiesFacade.getProviderSpecialties(agreement?.provider?.specialties)">
                                <span
                                    class="text-ellipsis">{{this.entitiesFacade.getProviderSpecialties(agreement?.provider?.specialties
                                    | slice: 0:2 )}}</span>
                            </div>
                            <!-- Column #4 - Provider's fee -->
                            <div class="flex-17" (click)="this.agreementsExpanded.toggle(agreement.id)"
                                class="align-start-center">
                                <span>{{agreement.providerFee | currency: 'ARS':'symbol':'1.2'}}</span>
                            </div>
                            <!-- Column #5 - Vigency -->
                            <div class="flex-17 flex-column align-start-center"
                                (click)="this.agreementsExpanded.toggle(agreement.id)">
                                <span>{{moment(agreement.fromDate).format('DD/MM/yyyy')}}</span>
                                <span>{{!!agreement.toDate ? ' - ' + moment(agreement.toDate).format('DD/MM/yyyy') : '
                                    en adelante'}}</span>
                            </div>
                            <!-- Column #6 - Actions -->
                            <div class="flex-row align-start-center flex-11">
                                <!-- Action delete -->
                                <!-- mat-icon-button-18x18 -->
                                <button class="" id="btn_delete_attentions" mat-icon-button
                                    [matTooltip]="'Eliminación de atenciones'"
                                    (click)="deleteAttentionsBatch(agreement)">
                                    <!-- mat-icon-18x18 -->
                                    <mat-icon class="" [svgIcon]="'Delete-enabled-primary-22x22'"></mat-icon>
                                </button>
                                <!-- Action add -->
                                <!-- mat-icon-button-18x18 -->
                                <!-- mat-icon-button-18x18 -->
                                <button class="" id="btn_add_attention" mat-icon-button
                                    [disabled]="this.loadingCreateAttention" [matTooltip]="'Agendar'"
                                    [color]="'primary'" (click)="onSelectedAgreement(agreement.id)">
                                    <!-- mat-icon-18x18 -->
                                    <mat-icon class="" [svgIcon]="'Add-attention-enabled-primary-22x22'"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <!-- Attentions information-->
                        <div class="flex-column attentions-list"
                            [@detailExpand]="agreementsExpanded.selected.includes(agreement.id) ? 'expanded' : 'collapsed'"
                            [ngStyle]="{'pointer-events': !agreementsExpanded.selected.includes(agreement.id) ? 'none' : 'auto'}">
                            <!-- Attention row -->
                            <div class="attention-row" *ngFor="let attention of agreement.attentions;"
                                [@detailExpand]="agreementsExpanded.selected.includes(agreement.id) ? 'expanded' : 'collapsed'">

                                <!-- Column #1 - Attention date -->
                                <div class="flex-row flex-30 align-space-evenly-none">
                                    <span style="min-width: 16px; min-height: 1em;"><mat-icon style="cursor: pointer;"
                                            svgIcon="WarningCircle" class="mat-icon-small"
                                            matTooltip="{{this.overlapAdviceText(attention)}}"
                                            *ngIf="(!!this.overlapAdviceText(attention))"></mat-icon></span>
                                    <span style="min-width: 80px;"
                                        class="attention-information">{{this.getAttentionDayText(attention)}}</span>
                                </div>
                                <!-- Column #2 - Attention hour start/end -->
                                <div class="flex-30 align-center-center">
                                    <span class="attention-information">{{ this.practiceCountedByHours ?
                                        this.getAttentionHourSpan(attention) : 'Visita'}}</span>
                                    <span *ngIf="moment(attention.toDate).isAfter(attention.fromDate, 'day')">
                                        (+{{moment(attention.toDate).startOf('day').diff(moment(attention.fromDate).startOf('day'),
                                        'day')}})</span>
                                </div>
                                <!-- Column #3 - Attention duration -->
                                <div class="flex-10 align-center-center">
                                    <span class="attention-information">{{this.practiceCountedByHours ?
                                        this.attentionsService.getHourQuantity(attention) : '1'}}</span>
                                </div>
                                <!-- Actions -->
                                <!-- fxLayoutGap="1em" -->
                                <div class="flex-row align-center-center">
                                    <!-- Edit button -->
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="this.attentionsService.isEditable(attention)" class="mat-icon-small"
                                        id="btn_edit_attention_enabled" mat-icon-button [matTooltip]="'Editar'"
                                        (click)="this.editAttentionFromAgreementsGrid(attention);">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'Edit-enabled-primary-16x16'"></mat-icon>
                                    </button>
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="!this.attentionsService.isEditable(attention)" class="mat-icon-small"
                                        id="btn_edit_attention_disabled" mat-icon-button [matTooltip]="'Editar'">
                                        <!-- class="mat-icon-16x16"  -->
                                        <mat-icon [svgIcon]="'Edit-disabled-16x16'"></mat-icon>
                                    </button>
                                    <!-- Copy button -->
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="this.attentionsService.isCopiable(attention)" class="mat-icon-small"
                                        id="btn_copy_attention_enabled" mat-icon-button [matTooltip]="'Copiar'"
                                        (click)="this.copyAttentionTo(attention)">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'Copy-attention-enabled-primary-16x16'"></mat-icon>
                                    </button>
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="!this.attentionsService.isCopiable(attention)" class="mat-icon-small"
                                        id="btn_copy_attention_disabled" mat-icon-button [matTooltip]="'Copiar'">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'Copy-attention-disabled-16x16'"></mat-icon>
                                    </button>
                                    <!-- Delete button -->
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="this.attentionsService.isDeletable(attention)" class="mat-icon-small"
                                        id="btn_delete_attention_enabled" mat-icon-button [matTooltip]="'Eliminar'"
                                        (click)="this.deleteAttentionFromAgreementsGrid(attention);">
                                        <!--  class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'Delete-enabled-primary-16x16'"></mat-icon>
                                    </button>
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button *ngIf="!this.attentionsService.isDeletable(attention)"
                                        class="mat-icon-small" id="btn_delete_attention_disabled" mat-icon-button
                                        [matTooltip]="'Eliminar'">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'Delete-disabled-16x16'"></mat-icon>
                                    </button>
                                    <!-- Replace agreement button -->
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button
                                        *ngIf="this.attentionsService.isAgreementReplaceable(attention) && this.agreements.length >= 2"
                                        class="mat-icon-small" id="btn_changeagreement_attention_enabled"
                                        mat-icon-button [matTooltip]="'Reemplazar acuerdo'"
                                        (click)="this.replaceAgreementAttentionFromAgreementsGrid(attention);">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'ReplaceAgreement-enabled-primary-16x16'"></mat-icon>
                                    </button>
                                    <!-- class="mat-icon-button-16x16" -->
                                    <button
                                        *ngIf="!this.attentionsService.isAgreementReplaceable(attention) || this.agreements.length <= 1"
                                        class="mat-icon-small" id="btn_changeagreement_attention_disabled"
                                        mat-icon-button [matTooltip]="'Reemplazar acuerdo'">
                                        <!-- class="mat-icon-16x16" -->
                                        <mat-icon [svgIcon]="'ReplaceAgreement-disabled-16x16'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Msg or calendar -->
            <div class="flex-57 flex-column p-2 mat-elevation-z2 calendar-container">
                <div *ngIf="!this.provisionFeeSelected; else thereIsProvisionFeeSelected"
                    class="align-center-center text-secondary-darker">
                    Aún no seleccionó prestación ni acuerdo prestacional para cargar agenda
                </div>
                <ng-template #thereIsProvisionFeeSelected>
                    <div class="flex-row flex-100" style="width:100%;" class="align-center-start">
                        <div class="flex-column">
                            <div class="flex-column schedule-form"
                                *ngIf="this.agreementSelected; else noAgreementSelected">
                                <div class="flex-row align-start-start">
                                    @if (!!this.provisionFeeSelected &&
                                    this.provisionFeeSelected.practice?.restriction?.requiredTime) {
                                    <div class="flex-row flex-50 align-space-between-center" style="gap: 0 10px">
                                        <!-- Hora de ingreso -->
                                        <div class="flex-column flex-20 align-center-none">
                                            <span class="subtitle-3 text-primary-darker">Ingreso</span>
                                            <div class="flex-row flex-100 align-start-start">
                                                <app-text-input
                                                    [disabled]="!this.agreementSelected || loadingCreateAttention || !this.agreementSelected"
                                                    class="flex-100 text-input" (changes)="validateTimeValid()"
                                                    type="text" [name]="this.TIME_FROM"
                                                    (keydown.enter)="$event.preventDefault()"
                                                    [form]="this.scheduleForm">
                                                </app-text-input>
                                            </div>
                                        </div>


                                        <!--Horario de salida-->
                                        <div class="flex-column flex-20 align-center-none"
                                            *ngIf="!!this.provisionFeeSelected && this.provisionFeeSelected.practice?.restriction?.requiredTime">
                                            <span class="subtitle-3 text-primary-darker">Salida</span>
                                            <div class="flex-row flex-100 align-start-center">
                                                <app-text-input
                                                    [disabled]="!this.agreementSelected || loadingCreateAttention || !this.agreementSelected"
                                                    class="flex-100 text-input" [selectAllOnClick]="true"
                                                    (changes)="changeHourFinish()" type="text" [name]="this.TIME_TO"
                                                    (keydown.enter)="$event.preventDefault()"
                                                    [form]="this.scheduleForm">
                                                </app-text-input>
                                                <span *ngIf="isNextDay" class="subtitle-5 text-primary-darker mbs-3">+1
                                                    día</span>
                                            </div>
                                        </div>

                                        <!-- Cantidad de horas -->
                                        <div class="flex-column flex-54 align-center-none"
                                            *ngIf="!!this.provisionFeeSelected && this.provisionFeeSelected.practice?.restriction?.requiredTime">
                                            <span class="subtitle-3 text-primary-darker">Cantidad de horas</span>
                                            <div class="flex-100 flex-row align-start-center gap-1em">
                                                <div class="flex-row flex-40 align-start-center">
                                                    <app-number-input class="flex-80 text-input"
                                                        (keydown.enter)="$event.preventDefault()"
                                                        [disabled]="loadingCreateAttention || !this.agreementSelected"
                                                        [minValue]="0" [name]="this.HOUR_DURATION"
                                                        (change)="durationChanged()" [form]="this.scheduleForm">
                                                    </app-number-input>
                                                    <span class="pl-2 text-input">HH</span>
                                                </div>
                                                <div class="flex-row flex-40 align-start-center">
                                                    <app-number-input class="flex-80 text-input"
                                                        (keydown.enter)="$event.preventDefault()"
                                                        [disabled]="loadingCreateAttention || !this.agreementSelected"
                                                        [minValue]="0" [maxValue]="60" (change)="durationChanged()"
                                                        [name]="this.MINUTE_DURATION" [form]="this.scheduleForm">
                                                    </app-number-input>
                                                    <span class="pl-2 text-input">MM</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    @if (!!this.provisionFeeSelected &&
                                    !this.provisionFeeSelected.practice?.restriction?.requiredTime) {
                                    <div class="flex-row flex-45 align-space-between-center" style="gap: 0 10px">
                                        <div class="flex-column align-center-none flex-80">
                                            <span class="subtitle-3 text-primary-darker">Visitas por día</span>
                                            <app-select-input [name]="this.REPEAT_ATTENTION" [form]="this.scheduleForm"
                                                [disabled]="!this.datesToPostSelected[0] || loadingCreateAttention || !this.agreementSelected"
                                                [defaultValue]="this.defaultValueRepeatAttention.toString()"
                                                class="text-input" [elements]="this.repeatTimesAttention"
                                                [calculateId]="this.calculateIdAttentionRepeat"
                                                [calculateName]="this.calculateNameAttentionRepeat"
                                                (selected)="this.onSelectedAttentionRepeat($event)">
                                            </app-select-input>
                                        </div>
                                    </div>
                                    }

                                    <!-- Se repite -->
                                    <div class="flex-row flex-53 align-start-start">
                                        <div class="flex-column flex-55">
                                            <!-- <mat-checkbox [disabled]="datesToPostSelected.length == 0" (change)="onChangeCheckRepeatSchedule($event)" [checked]="this.isRepeatSchedule">
                                             </mat-checkbox> -->
                                            <span class="subtitle-3 text-primary-darker">Se repite</span>
                                            <div class="flex-row align-space-around-start">
                                                <mat-chip-listbox multiple
                                                    [ngStyle]="{'pointer-events':(datesToPostSelected.length == 0  || loadingCreateAttention || !this.agreementSelected ? 'none' : 'auto')}">
                                                    <mat-chip-option
                                                        *ngFor="let day of dayChipsSelected | keyvalue: asIsOrder; let i = index"
                                                        [disabled]="loadingCreateAttention || !this.agreementSelected"
                                                        [ngClass]="{'bg-primary' : isDayChipSelected(day.key), 'text-primary bg-white' : !isDayChipSelected(day.key)}"
                                                        class="repeat-day-chip no-avatar-size border-primary"
                                                        [selectable]="datesToPostSelected.length > 0"
                                                        [selected]="isDayChipSelected(day.key)"
                                                        (click)="selectDayChip(day)">
                                                        <span class="mat-small">{{day.key}}</span>
                                                    </mat-chip-option>
                                                </mat-chip-listbox>
                                            </div>
                                        </div>
                                        <div class="flex-column flex-45 align-start-start">
                                            <span class="subtitle-3 text-primary-darker">Hasta</span>
                                            <div class="align-start-start" style="width: 100%;">
                                                <app-date-picker [name]="this.DATE_END_REPEAT"
                                                    [form]="this.scheduleForm" class="flex-100 text-input"
                                                    [disabled]="loadingCreateAttention || !this.agreementSelected"
                                                    [placeholder]="'Fecha de finalización de la repetición'"
                                                    (onDateChange)="setDatePicker($event)" [min]="this.minDate"
                                                    [max]="this.maxDate">
                                                </app-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-row flex-100 align-space-between-center">
                                    <div class="flex-column align-center-none flex-30">
                                        <span class="subtitle-3 text-primary-darker">Estado de la atención</span>
                                        <app-select-input [name]="this.STATE_ATTENTION" class="text-input"
                                            [disabled]="loadingCreateAttention || !this.agreementSelected"
                                            [form]="this.scheduleForm" [elements]="this.attentionStatesPost"
                                            (selected)="this.onSelectedStateAttention($event)"
                                            [defaultValue]="this.defaultValueStateAttention.toString()"
                                            [calculateId]="this.calculateIdState"
                                            [calculateName]="this.calculateNameState">
                                        </app-select-input>
                                    </div>
                                    <div class="flex-row align-center-center">
                                        <button
                                            [disabled]="this.datesToPostSelected.length < 1  || loadingCreateAttention || !this.agreementSelected"
                                            mat-button (click)="onCleanAttentions()">
                                            <mat-icon class="text-secondary">close</mat-icon>
                                            <span class="text-secondary-darker">
                                                Borrar días seleccionados ({{this.datesToPostSelected.length}})
                                            </span>
                                        </button>
                                    </div>
                                    <div class="align-end-center">
                                        <button mat-flat-button [color]="'primary'" class="medium"
                                            [disabled]="!this.scheduleForm.valid || loadingCreateAttention || !(this.datesToPostSelected.length > 0)"
                                            (click)="onCreateAttentionV2(true)">
                                            <span *ngIf="!loadingCreateAttention; else loadingConfirm">
                                                GUARDAR
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-column gap-1em">
                                <!-- Top bar calendar -->
                                <div class="flex-row align-space-between-center">
                                    <div class="flex-20">
                                        <mat-form-field style="height: 40px !important;" class="flex-100"
                                            appearance="outline">
                                            <mat-select id="cmb_period_view" [(value)]="this.view"
                                                (selectionChange)="this.selectView($event)" class="subtitle-2">
                                                <mat-option value="{{CalendarView.Month}}">
                                                    Mes
                                                </mat-option>
                                                <mat-option value="{{CalendarView.Week}}">
                                                    Semana
                                                </mat-option>
                                                <mat-option value="{{CalendarView.Day}}">
                                                    Día
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-row align-center-center flex-80">
                                        <button id="btn_previous" mat-icon-button id="arrowLeftButton"
                                            mwlCalendarPreviousView [view]="view"
                                            (click)="clickonCalendarPreviousNextView()" [(viewDate)]="viewDate"
                                            [color]="'primary'"
                                            [disabled]="buttonsNewDateScheduleNavigator?.buttonLeftDisabled">
                                            <mat-icon
                                                [ngClass]="{'text-secondary-darker':!buttonsNewDateScheduleNavigator?.buttonLeftDisabled, 'text-secondary-lighter':buttonsNewDateScheduleNavigator?.buttonLeftDisabled }">keyboard_arrow_left</mat-icon>
                                        </button>
                                        <div class="align-center-center">
                                            <span class="subtitle-2 text-secondary-darker">
                                                <!-- First day is Monday -> 1 -->
                                                {{ (viewDate | calendarDate:(view + 'ViewTitle'):'es-AR': 1) | titlecase
                                                }}
                                            </span>
                                        </div>
                                        <button id="btn_next" mat-icon-button id="arrowRightButton" mwlCalendarNextView
                                            [view]="view" (click)="clickonCalendarPreviousNextView()"
                                            [(viewDate)]="viewDate" [color]="'primary'"
                                            [disabled]="buttonsNewDateScheduleNavigator?.buttonRightDisabled">
                                            <mat-icon
                                                [ngClass]="{'text-secondary-darker':!buttonsNewDateScheduleNavigator?.buttonRightDisabled, 'text-secondary-lighter':buttonsNewDateScheduleNavigator?.buttonRightDisabled }">keyboard_arrow_right</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <!-- Calendar -->
                                <div [ngSwitch]="view">
                                    <!-- (eventTimesChanged)="eventTimesChanged($event)" -->
                                    <mwl-calendar-month-view [viewDate]="viewDate" [refresh]="refresh"
                                        [events]="monthEvents" [locale]="'es-AR'" [cellTemplate]="customTemplate"
                                        *ngSwitchCase="CalendarView.Month"
                                        (dayClicked)="onSelectDate(moment($event.day.date))" [weekStartsOn]="1">
                                    </mwl-calendar-month-view>
                                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDateX"
                                        [events]="events" [refresh]="refresh"
                                        (beforeViewRender)="applyDateSelectionPolicy($event)"
                                        (eventClicked)="eventClicked($event.event)" [weekStartsOn]="1">
                                    </mwl-calendar-week-view>
                                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDateX"
                                        [events]="events" [refresh]="refresh"
                                        (beforeViewRender)="applyDateSelectionPolicy($event)"
                                        (eventClicked)="eventClicked($event.event)">
                                    </mwl-calendar-day-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <mat-error class="mls-3"
            *ngIf=" this.scheduleForm.errors && (this.scheduleForm.dirty || this.scheduleForm.touched)"
            class="align-center-center">
            <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
            {{ objectValues(this.scheduleForm.errors)[0] }}
        </mat-error>
        <!-- Buttons -->
        <div class="mts-5 gap-15px flex-row align-center-center">
            <button mat-stroked-button [color]="'primary'" (click)="onClickCloseDialog()"
                [disabled]="loadingCreateAttention">
                <span *ngIf="!loadingCreateAttention; else loadingConfirm">
                    CERRAR
                </span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #loadingConfirm>
    <mat-icon>
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
    </mat-icon>
</ng-template>

<ng-template #loading>
    <app-spinner class="flex-100 align-start"></app-spinner>
</ng-template>

<ng-template #customTemplate let-day="day" let-locale="locale">
    <div *ngIf="day.events" class="flex-100 align-space-between-center flex-colum cal-cell-day"
        [ngClass]="{'bg-primary-lighter': this.isSelectedDate(moment(day.date)), 'disabled-day': this.disableDay(day.date)}">
        <div class="cal-cell-top flex-row align-center-center">
            <span class="flex flex-52 align-end-center cal-day-number">{{ day.date |
                calendarDate:'monthViewDayNumber':locale }}</span>
            <div class="flex flex-48 align-end-center">
                <span class="cal-day-badge bg-primary-lighter text-primary" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal
                    }}</span>

            </div>
        </div>
        <div class="flex-column flex-100 align-start-center gap-3px">
            <ng-container *ngFor="let event of day.events; let i = index">
                <div *ngIf="i <  3 || (i ===  3 && day.events.length == 4 )" (click)="eventClicked($event, event)"
                    class="flex-row align-center-center event-label border-radius"
                    [style.background-color]="event?.color?.primary">
                    <div class="flex flex-80 pr-2 align-start-center">
                        <mat-icon
                            *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventStartsBeforeDaySelected(event, day.date)"
                            class="flex flex-10 align-center-center text-white xxs mat-icon-16x16">arrow_back</mat-icon>
                        <span [matTooltip]="event?.title"
                            class="flex-60 text-white mls-1 text-ellipsis title-label">{{event?.title}}</span>
                        <mat-icon class="flex-20 align-center-center text-white mat-icon-small mat-icon-16x16" svgIcon=Watch-16x16
                            [matTooltip]="calendarCaseService.getHoursTooltip(event)"
                            *ngIf="calendarCaseService.practiceCountedByHours(event?.meta?.attention?.practice)"></mat-icon>
                        <mat-icon
                            *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventEndsAfterDaySelected(event, day.date)"
                            class="flex flex-10 align-center-center text-white xxs mat-icon-16x16">arrow_forward</mat-icon>
                    </div>
                    <span class="flex-20 state-initial text-white border-radius"
                        [matTooltip]="event?.meta?.attention?.state?.name"
                        [ngStyle]="{'background-color': calendarCaseService.getColor(event?.meta?.attention?.state?.id)}">
                        {{event?.meta?.attention?.state?.name | firstLetterWordsIntoString}}
                    </span>
                </div>
            </ng-container>
            <!-- (click)="calendarCaseService.onClickSeeMoreAttentions($event, day)" -->
            <div *ngIf="day.events.length >  4" (click)="this.onClickSeeMoreAttentions($event, day)"
                class="event-label flex-row align-start-center">
                <span class="more-attentions-label event-label pls-1 text-secondary subtitle-2">{{ day.events.length -
                    3}} más</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #extraAttentionsTemplate>
    <ng-container>
        <div class="flex-column align-center-center mb-5">
            <span class="text-secondary-darker headline-2">{{this.dayOpened.format('dddd') | uppercase}}</span>
            <span class="text-secondary-darker headline-2">{{this.dayOpened.format('DD')}}</span>
        </div>
    </ng-container>
    <ng-container >
        <div *ngFor="let event of this.extraDayAttentions" class="align-center-center extra-attentions-event-label border-radius pls-1"
             [style.background-color]="event?.color?.primary"
             (click)="handleEvent('Clicked', event)">
             <div class="flex-row align-space-between-center" style="cursor: pointer;" (click)="eventClicked($event, event)">
                <div class="flex-row align-start-center">
                    <mat-icon class="text-white extra-small mr-2" *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventStartsBeforeDaySelected(event, this.dayOpened)">arrow_back</mat-icon>
                    <span class="text-white text-ellipsis"  [matTooltip]="event?.title" >{{event?.title}}</span>
                    <mat-icon class="text-white extra-small mr-2" *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventEndsAfterDaySelected(event, this.dayOpened)">arrow_forward</mat-icon>
                    <mat-icon class="text-white extra-small mr-2" [matTooltip]="calendarCaseService.getHoursTooltip(event)" *ngIf="calendarCaseService.practiceCountedByHours(event?.meta?.attention?.practice)"> query_builder </mat-icon>
                </div>
                <!-- Attention state -->
                <span class="state-initial text-white border-radius align-center-center" style="width: 16px !important; height:16px !important; display: flex;" [ngStyle]="{'background-color': calendarCaseService.getColor(event?.meta?.attention?.state?.id)}" [matTooltip]="event?.meta?.attention?.state?.name">{{event?.meta?.attention?.state?.name | firstLetterWordsIntoString}}</span>
            </div>
        </div>
  </ng-container>
</ng-template>

<ng-template #noAgreementSelected>
    <div class="flex-100 flex-row align-center-center gap-0-5em" style="min-height: 50px;" class="mbs-3">
        <span class="text-secondary-darker">Para la carga de agenda, seleccione un acuerdo prestacional con el
            símbolo</span>
        <mat-icon class="mat-icon-22x22" [svgIcon]="'Add-attention-default-22x22'"></mat-icon>
    </div>
</ng-template>

<ng-template #deleteWizardAttentions>
    <app-attentions-delete-wizard
        [caseId]="this.case.id"
        [provisionAgreements]="this.provisionAgreements"
        [provisionFees]="[this.provisionFeeSelected]"
        (onClickCancelWizard)="clickCancelDeleteWizard()">
    </app-attentions-delete-wizard>
</ng-template>

<ng-template #editAttentionDialogTemplate>
    <app-attention-edit [attention]=attentionToEdit (closeDialog)="closeDialogAttentionEdit()"
        [lastDate]="selectLastDate()" (refreshDataFiltered)="this.refreshDataFiltered.emit()"
        (onEventDelete)="onClickDeleteAttention($event)" [mode]="editMode">
    </app-attention-edit>
</ng-template>
