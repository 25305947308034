import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, effect, input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AttentionsService, PatchAttentionBody } from 'src/app/core/services/attentions.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Agreement } from 'src/app/shared/models/agreement';
import { Attention } from 'src/app/shared/models/attention';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Provider } from 'src/app/shared/models/provider';
import { Patient } from 'src/app/shared/models/patient';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { Case } from 'src/app/shared/models/case';
import { ATTENTIONS_BACKGROUND_COLORS, ATTENTIONS_COLORS, AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { DateService } from 'src/app/core/services/date.service';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { Practice } from 'src/app/shared/models/practice';
import { AttentionToProcess } from 'src/app/shared/models/attentionToProcess';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { CasesFacade, CaseDateFilters } from '../../../../abstraction/cases.facade';
import { OPTIONS } from '../provision-scheme/provision-scheme.component';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { AlertService } from '../../../../core/services/alert.service';
import { DateAdapter } from '@angular/material/core';
import { ProvisionFee } from '../../../../shared/models/provisionFee';
import { Provision } from '../../../../shared/models/provision';
import { ProvisionAgreementFilters } from '../../../../shared/models/ProvisionAgreementFilters';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { ProvisionQPS } from 'src/app/core/services/provisions.service';
import { ButtonsNewDateScheduleNavigator } from '../../../../core/services/general.service';

import * as moment from 'moment';
import * as momentRange from 'moment-range';
import { MatRadioButton } from '@angular/material/radio';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { GeneralService } from 'src/app/core/services/general.service';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CalendarCaseService } from 'src/app/core/services/calendar-case.service';
import { AttentionOverlap } from 'src/app/shared/models/attentionOverlap';
import { AttentionEdit } from 'src/app/shared/models/submodels/attentionEdit';
import { ProvisionFeeWithPractice } from 'src/app/shared/models/submodels/provisionFeeWithPractice';
import { MyDateAdapter } from 'MyDateAdapter';
import { LoginComponent } from 'src/app/modules/login/pages/login/login.component';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';

const colors: { primary: string, secondary: string }[] = [
    {
        primary: '#609E50',
        secondary: '#609E50',
    },
    {
        primary: '#FF7A00',
        secondary: '#FF7A00',
    },
    {
        primary: '#2D00F7',
        secondary: '#2D00F7',
    },
    {
        primary: ' #9B5DE5',
        secondary: '#9B5DE5',
    },
    {
        primary: '#D100D1',
        secondary: '#D100D1',
    },
    {
        primary: '#D02700',
        secondary: '#D02700',
    },
    {
        primary: '#1994B1',
        secondary: '#1994B1',
    },
    {
        primary: '#F2AD13',
        secondary: '#F2AD13',
    },
    {
        primary: '#F2545B',
        secondary: '#F2545B',
    },
    {
        primary: '#297045',
        secondary: '#297045',
    },
    {
        primary: '#F78E69',
        secondary: '#F78E69',
    },
    {
        primary: '#41309E',
        secondary: '#41309E',
    },
    {
        primary: '#334151',
        secondary: '#334151',
    },
    {
        primary: '#944394',
        secondary: '#944394',
    },
    {
        primary: '#00BB94',
        secondary: '#00BB94',
    },
    {
        primary: '#8DCB00',
        secondary: '#8DCB00',
    },
    {
        primary: '#8A6552',
        secondary: '#8A6552',
    },
    {
        primary: '#A93F55',
        secondary: '#A93F55',
    },
]

interface FilterAttentions {
    date: Date,
    range: momentRange.DateRange
}

@Component({
    selector: 'app-schedule-case',
    templateUrl: './schedule-case.component.html',
    styleUrls: ['./schedule-case.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MyDateAdapter }, // It's mandatory, NO delete -> set first day MONDAY
    ],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', opacity: '0', fontSize: '0px' })),
            state('expanded', style({ height: '*', fontSize: '10px' })),
            transition('expanded <=> collapsed', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})

export class ScheduleCaseComponent implements OnInit {

    view: CalendarView = CalendarView.Month;
    attentionsToProcess: AttentionToProcess[] = [];
    processSuccessOnAttentions: boolean = false;
    _attentionsCaseActive: Subscription;
    // Atenciones separadas para mostrar solapamiento
    guardiaAttentions: Attention[] = [];
    attentionsWithOverlap: AttentionOverlap[] = [];
    attentionToEdit: AttentionEdit;

    @Input("case") case: Case;
    @Input("attentions") attentions: Attention[];
    @Input("selectedOption") selectedOption: OPTIONS; // PROVISION OR SUPPLY
    @Input("viewColumnsBillAndDebit") viewColumnsBillAndDebit: boolean;
    optionViewSelected = input<string>()
    @Input() optionSelected: string= 'Calendario';
    @Input() billSuppliesExternally: number[];

    // Input to controller delete attentions batch button
    @Input("provisionsFilteredCount") provisionsFilteredCount: number;
    @Input("provisionsSuppliesFilteredCount") provisionsSuppliesFilteredCount: number;
    @Input("ordersFilteredCount") ordersFilteredCount: number;
    @Input("provisionAgreements") provisionAgreements: Agreement[];
    //

    @Input() allProvisionsWithoutFilter: Provision[];

    @Output() agreementNewAttention = new EventEmitter<any>(null);
    @Output() refreshDataFiltered = new EventEmitter();
    @Output() openModifySuppliesProvisionFee = new EventEmitter<number[]>();

    focusEmitter:string;
    defaultValueRepeatAttention: number;
    defaultValueStateAttention: number;
    canExecute$ = new BehaviorSubject<string>('_');
    _canExecute: Subscription;
    provisionFeesWithoutCostOrFee: ProvisionFee[] = [];
    provisionFeesWithKairos: ProvisionFee[] = [];
    ATTENTIONS_COLORS = ATTENTIONS_COLORS;
    rangeToNavigate: CaseDateFilters;
    filterAttentions: FilterAttentions;
    viewSelectedCalendarCase: CalendarView;
    editMode: string;

    // FORM
    // public readonly PROVIDER = 'provider';
    // public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly PRACTICE = 'practice';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly PATIENT = 'patient';
    public readonly AGREEMENT = 'agreement';
    public readonly DATE_FROM = 'fromDate';
    public readonly DATE_TO = 'toDate';
    public readonly DATE_FROM_UPDATE = 'dateFromUpdate';
    public readonly DATE_TO_UPDATE = 'dateToUpdate';
    public readonly TIME_FROM = 'timeFrom';
    public readonly TIME_TO = 'timeTo'
    // public readonly TIME_DURATION = 'timeDuration';
    public readonly HOUR_DURATION = 'hourDuration';
    public readonly MINUTE_DURATION = 'minuteDuration';
    public readonly DATE_END_REPEAT = 'dateEndRepeat';
    public readonly STATE_ATTENTION = 'stateAttention';
    public readonly REPEAT_ATTENTION = 'repeatAttention';
    public readonly PROVISION_FEE = 'provisionFee';
    public readonly QUANTITY = 'quantity';
    public moment = moment;
    public CalendarView = CalendarView

    ATTENTION_STATES = AttentionStates;

    @ViewChild('scheduleDialogTemplate', { static: true }) scheduleDialogTemplate: TemplateRef<any>;
    @ViewChild("extraAttentionsTemplate", { static: true }) extraAttentionsTemplate: TemplateRef<any>;
    @ViewChild('deleteWizardAttentions', { static: true }) deleteWizardAttentions: TemplateRef<any>;
    @ViewChild('editAttentionDialogTemplate', { static: true }) editAttentionDialogTemplate: TemplateRef<any>;

    scheduleForm: FormGroup;
    isRepeatSchedule = false;
    swalWithCustomizeButtons: any;

    _dialogRefSupply: MatDialogRef<any>;
    _dialogRefAttByDay: MatDialogRef<any>;
    _dialogRefPopUpAddAttentions: MatDialogRef<any>;
    _dialogRefWizardDelete: MatDialogRef<any>;
    _dialogRefEditAttention: MatDialogRef<any>;
    public dayChipsSelected: Map<string, boolean> = new Map<string, boolean>();
    // Agreement que se muestra en el dialog
    activeAttention: Attention;
    agreementAttentionDefault: number;
    datesToPostSelected: moment.Moment[] = [];
    elementsProvidersDialog: Provider[] = [];
    allPatientsDialog: Patient[] = [];
    allAgreementsDialog: Agreement[] = [];
    allAgreementsDialogList: Agreement[] = [];
    agreements: Agreement[];
    agreementSelected: Agreement;
    loadingCreateAttention = false;
    objectValues = Object.values;
    loadingCalendar: boolean = true;
    loadingInnerCalendar: boolean = false;
    // attentions: Attention[];
    supplyList: Attention[];
    _attentions: Subscription;
    filteredAttentions: Attention[];
    attentionsToShow: Attention[];
    totalsByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
    initialAgreementId: number = undefined;
    loadingEditAttention = false;
    agreementsFromPatient: Agreement[];
    stateAttentionSelected: number[];
    allProvisionsCase: Provision[];
    durationAttention;
    createAttentionFromOutside: boolean = false;
    radiobuttonsDisabled: boolean = false;
    public enumStates = AttentionStates;
    statesSelected = new SelectionModel<number>(true, []);
    agreementsExpanded = new SelectionModel<number>(true, [])

    attentionStates: StateAttention[] = [
        {
            id: AttentionStates.AGENDADA,
            name: 'Agendada',
        },
        {
            id: AttentionStates.EN_CURSO,
            name: 'En curso',
        },
        {
            id: AttentionStates.NO_REALIZADA,
            name: 'No realizada',
        },
        {
            id: AttentionStates.REALIZADA,
            name: 'Realizada',
        },
        {
            id: AttentionStates.FACTURABLE,
            name: 'Facturable',
        },
        {
            id: AttentionStates.DEBITADA,
            name: 'Debitada',
        },
        {
            id: AttentionStates.EN_PROCESO,
            name: 'En proceso',
        },
        {
            id: AttentionStates.INFORMADA,
            name: 'Informada',
        },
        {
            id: AttentionStates.NO_INFORMADA,
            name: 'No informada',
        },
    ];
    attentionStatesPost: StateAttention[] = [
        {
            id: AttentionStates.AGENDADA,
            name: 'Agendada',
        },
        {
            id: AttentionStates.FACTURABLE,
            name: 'Facturable',
        },
        {
            id: AttentionStates.REALIZADA,
            name: 'Realizada',
        }
    ];

    permittedStates: AttentionStates[] = [
        this.ATTENTION_STATES.AGENDADA,
        this.ATTENTION_STATES.REALIZADA,
        this.ATTENTION_STATES.NO_REALIZADA,
        this.ATTENTION_STATES.FACTURABLE,
    ]

    // Repeat attention - only Visitas
    repeatTimesAttention: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    repeatAttention: number = 1;

    selectionToUpdateValue: string;
    showRadiobuttons: boolean = false;
    ATTENTIONS_BACKGROUND_COLORS = ATTENTIONS_BACKGROUND_COLORS;
    colorsForAgreements: Map<number, { primary: string, secondary: string }> = new Map<number, { primary: string, secondary: string }>();
    events: CalendarEvent<any>[];
    monthEvents: CalendarEvent<any>[];
    viewDate: Date = new Date();
    previousViewDate = this.viewDate;
    viewDateX: Date = new Date();
    buttonsNewDateScheduleNavigator: ButtonsNewDateScheduleNavigator
    extraDayAttentions: any;
    dayOpened: moment.Moment;

    // get providerForm() {
    //     return this.scheduleForm.get(this.PROVIDER).value;
    // }
    get patientForm() {
        return this.scheduleForm.get(this.PATIENT).value;
    }
    get agreementForm() {
        return this.scheduleForm.get(this.AGREEMENT).value;
    }
    get provisionFeeForm() {
        return this.scheduleForm.get(this.PROVISION_FEE).value;
    }
    get dateFromForm() {
        return this.scheduleForm.get(this.DATE_FROM).value;
    }
    get dateToForm() {
        return this.scheduleForm.get(this.DATE_TO).value;
    }
    get timeFromForm() {
        return this.scheduleForm.get(this.TIME_FROM).value;
    }
    get timeToForm() {
        return this.scheduleForm.get(this.TIME_TO).value;
    }
    get hourDurationForm() {
        return this.scheduleForm.get(this.HOUR_DURATION).value;
    }
    get minuteDurationForm() {
        return this.scheduleForm.get(this.MINUTE_DURATION).value;
    }
    get dateEndRepeatForm() {
        return this.scheduleForm.get(this.DATE_END_REPEAT).value;
    }
    get stateAttentionForm() {
        return this.scheduleForm.get(this.STATE_ATTENTION).value;
    }
    get repeatAttentionForm() {
        return this.scheduleForm.get(this.REPEAT_ATTENTION).value;
    }
    // set providerForm(providerId: number) {
    //     providerId != null ? this.scheduleForm?.controls[this.PROVIDER].setValue(providerId.toString())
    //         : this.scheduleForm?.controls[this.PROVIDER].reset();
    // }
    set patientForm(patientId: number) {
        patientId != null ? this.scheduleForm?.controls[this.PATIENT].setValue(patientId.toString())
            : this.scheduleForm?.controls[this.PATIENT].reset();
    }
    set agreementForm(agreementId: number) {
        agreementId != null ? this.scheduleForm?.controls[this.AGREEMENT].setValue(agreementId.toString())
            : this.scheduleForm?.controls[this.AGREEMENT].reset();
    }
    set dateFromForm(date: Date) {
        date != null ? this.scheduleForm?.controls[this.DATE_FROM].setValue(date)
            : this.scheduleForm?.controls[this.DATE_FROM].reset();
    }
    set dateToForm(date: Date) {
        date != null ? this.scheduleForm?.controls[this.DATE_TO].setValue(date)
            : this.scheduleForm?.controls[this.DATE_TO].reset();
    }
    set timeFromForm(hourAndMinute: string) {
        hourAndMinute != null ? this.scheduleForm?.controls[this.TIME_FROM].setValue(hourAndMinute)
            : this.scheduleForm?.controls[this.TIME_FROM].reset();
    }
    set timeToForm(hourAndMinute: string) {
        hourAndMinute != null ? this.scheduleForm?.controls[this.TIME_TO].setValue(hourAndMinute)
            : this.scheduleForm?.controls[this.TIME_TO].reset();
    }
    set hourDurationForm(hours: number) {
        hours != null ? this.scheduleForm?.controls[this.HOUR_DURATION].setValue(hours)
            : this.scheduleForm?.controls[this.HOUR_DURATION].reset();
    }
    set minuteDurationForm(minutes: number) {
        minutes != null ? this.scheduleForm?.controls[this.MINUTE_DURATION].setValue(minutes)
            : this.scheduleForm?.controls[this.MINUTE_DURATION].reset();
    }
    set dateEndRepeatForm(date: Date) {
        date != null ? this.scheduleForm?.controls[this.DATE_END_REPEAT].setValue(date)
            : this.scheduleForm?.controls[this.DATE_END_REPEAT].reset();
    }
    set stateAttentionForm(stateId: number) {
        stateId != null ? this.scheduleForm?.controls[this.STATE_ATTENTION].setValue(stateId.toString())
            : this.scheduleForm?.controls[this.STATE_ATTENTION].reset();
    }
    set repeatAttentionForm(repeatTimes: number) {
        repeatTimes != null ? this.scheduleForm?.controls[this.REPEAT_ATTENTION].setValue(repeatTimes)
            : this.scheduleForm?.controls[this.REPEAT_ATTENTION].reset();
    }

    displayColumnsForAttentions: string[] = [];

    // For spinner
    isLoadingGetAttentions: boolean; _isLoadingGetAttentions: Subscription;
    isLoadingGettingEvolutions: boolean; _isLoadingGettingEvolutions: Subscription;
    isLoadingFilteredAttentions: boolean;

    provisionFees: ProvisionFeeWithPractice[];
    provisionFeesSupplies: ProvisionFee[];
    _allProvisionsCase: Subscription;
    provisionFeeSelected: ProvisionFeeWithPractice;

    minDate: Date; // For mat-calendar filter
    maxDate: Date; // For mat-calendar filter

    // HISTORY MODE
    historyModeActivated: boolean;
    _historyModeActivated: Subscription;
    _historyModeDate: Subscription;
    historyModeDate: Date;
    rangePeriodSelector: momentRange.DateRange;
    provisionQPS: ProvisionQPS;

    _filtersOnProvisions: Subscription;
    provisionAgreementsInput: ProvisionAgreementFilters;
    _provisionsDisplayedOnScreen: Subscription;
    provisionsDispayed: Provision[];

    _provisionsForCase: Subscription; provisions: Provision[];
    weekStartsOn = DAYS_OF_WEEK.MONDAY;
    dateRange: CaseDateFilters;
    dateSelectedFromOtherComponent: Date;

    _loadAttentions: Subscription;
    isNextDay: boolean = false;
    refresh: Subject<any> = new Subject();

    isDeleteModeAttentionFromGridAgreements: boolean = false;
    _screenToJump: Subscription

    constructor(
        public attentionsService: AttentionsService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private attentionsFacade: AttentionsFacade,
        private dateService: DateService,
        private nomenclatorFacade: NomenclatorFacade,
        private casesFacade: CasesFacade,
        private evolutionsFacade: EvolutionsFacade,
        private alertService: AlertService,
        private provisionsFacade: ProvisionsFacade,
        private ren: Renderer2,
        private generalService: GeneralService,
        public entitiesFacade: EntitiesFacade,
        public calendarCaseService: CalendarCaseService,
        private breadcrumbService: BreadcrumbService
    ) {
        effect(() => {
           if(!!this.optionViewSelected()){
            this.optionSelected = this.optionViewSelected()
           }

            })

    }

    ngOnInit(): void {

        this.proccesToBillOrDebit(); // Subscribe to traffic-lights (semáforo) for execute

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this._provisionsForCase = this.provisionsFacade.getAllProvisionsCase$().subscribe(prov => {
            if (!!prov) {
                this.provisions = prov;
                this.agreementsFromPatient = this.provisions.flatMap(provision => provision.agreements).filter(agreement => !!agreement);
                this.loadAttentionsByCase();
            } else {
                this.provisionsFacade.loadAllProvisionsForCase(this.case.id);
            }
        })

        // Spinner control
        this._isLoadingGetAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loadingAttentions => this.isLoadingGetAttentions = loadingAttentions);
        this._isLoadingGettingEvolutions = this.evolutionsFacade.isLoadingGetEvolutions$().subscribe(loadingEvolutions => this.isLoadingGettingEvolutions = loadingEvolutions);

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.dateRange = { ...rangeDate, historyToDate: moment(moment(rangeDate.historyToDate).format('YYYY-MM-DD')).toDate() }; // Selector
                this.rangeToNavigate = this.attentionsService.getPeriodToLoadAttentions(this.dateRange.historyFromDate, this.dateRange.historyToDate); // Attentions
                // this.viewDate = this.dateRange.historyFromDate;
                // this.updateScheduleView();
            }
        })

        // For shows sub-provisions filtered or displayeds on screen inside combo when create attention
        this._filtersOnProvisions = this.provisionsFacade.getFiltersOnProvisions$().subscribe(filters => {

            this.provisionAgreementsInput = filters;

            if (this.provisionAgreementsInput?.provisionFees?.length > 0) {

                let auxProvisions: Provision[] = [];

                if (this.provisionAgreementsInput && this.provisionAgreementsInput.provisions) { // .provisions can be undefined
                    for (let provision of this.provisionAgreementsInput.provisions) {
                        let provisionFeesfiltered = this.provisionAgreementsInput.provisionFees.filter(pFee => pFee.provisionId == provision.id)
                        provision = { ...provision, provisionFees: [...provisionFeesfiltered] }
                        auxProvisions.push(provision);
                    }
                    this.provisionAgreementsInput.provisions = [...auxProvisions]
                }
            }
            this.provisionsAndCompose(this.provisionsDispayed);
        })

        // For shows sub-provisions filtered or displayeds on screen inside combo when create attention
        // If doesn't exist filters then provisions
        this._provisionsDisplayedOnScreen = this.provisionsFacade.getProvisionsDisplayedOnScreen$().subscribe(provisions => {

            this.provisionsDispayed = provisions?.filter(aprov => !aprov.isSupply); // Always is calculated

            if (!!provisions) {
                // Always is filtered with provisions displayed on screen
                this.provisionsAndCompose(this.provisionsDispayed);
            }
        });

    }

    ngAfterViewInit(){
        this._screenToJump = this.breadcrumbService.getScreenToJump$().subscribe(event => {
            if (event == ViewManagementEntities.ATTENTIONS_OLD_AND_OMITTED || event == ViewManagementEntities.ATTENTIONS_WITHOUT_BILLING || event == ViewManagementEntities.SUPPLIES_NOT_BILLING) {
                                    this.focusEmitter = event;
                            }

        })
    }

    provisionsAndCompose(provisions: Provision[]) {
        if (provisions) {
            this.allProvisionsCase = provisions
            this.provisionFees = provisions.filter(prov => !prov.isSupply).flatMap(provision =>
                provision.provisionFees.map(provFee => {
                    return { ...provFee, practice: provision.practice }
                })
            )
            this.assignColorsToAgreements();
        }
    }

    /**
         *
         * @param attentions attentions selected to bill
         * @returns provisionFee ids
         */
    getProvisionFeeIdsSupplies(attentions: Attention[]): number[] {
        console.log('getProvisionFeeIdsSupplies');
        let provFees: number[] = [];
        this.provisionFeesWithoutCostOrFee = [];
        this.provisionFeesWithKairos = [];
        if (this.provisions) {
            this.provisionFeesSupplies = this.provisions.filter(prov => prov.isSupply).flatMap(provision => provision.provisionFees)
            // Get provisionFeeId
            attentions.forEach(att => {
                const auxprovFee = this.provisionFeesSupplies.find(pF => pF.id == att.provisionFeeId && (pF.fixedPrice || pF?.agreement?.providerFee == 0 || pF?.fee == 0)) // ProvisionFee with Kairos or cost 0 or fee 0
                if (auxprovFee) {
                    provFees.push(auxprovFee.id);
                    if (!auxprovFee.fixedPrice && (auxprovFee?.agreement?.providerFee == 0 || auxprovFee?.fee == 0)) {
                        this.provisionFeesWithoutCostOrFee = [...this.provisionFeesWithoutCostOrFee, auxprovFee]
                    } else {
                        this.provisionFeesWithKairos = [...this.provisionFeesWithKairos, auxprovFee]
                    }
                }
            })
            return provFees;
        }
        return []
    }

    loadAttentionsByCase(loadingCalendar = false, loadingInnerCalendar = false) {
        console.log('loadAttentionsByCase')
        if (!!this.attentions) {
            this.attentions = this.composeAttentions(this.attentions, this.agreementsFromPatient)
            this.setAttentionsOldAndOmitted();
            this.controlShowsAttentionsIfModeBillOrDebit();
            let attentions = this.attentions;
            if (this.selectedOption == OPTIONS.PROVISION) {
                attentions = attentions.filter(att => att.practice.name != 'Entrega insumos');
            } else {
                if (this.selectedOption == OPTIONS.SUPPLY) {
                    attentions = attentions.filter(att => att.practice.name != 'Entrega insumos');
                } else if (this.selectedOption == OPTIONS.ORDER) {
                    attentions = attentions.filter(att => att.practice.name === 'Entrega insumos')
                }
            }
            // TODO: Necesario para ver el solapamiento de las atenciones
            this.guardiaAttentions = this.attentionsService.filterGuardiaAttentions(this.attentions);
            this.attentionsWithOverlap = this.attentionsService.composeAttentionHasOverlap(this.guardiaAttentions);
            // No se actualizaba la vista porque se esperaba un cambio en filteredAttentions

            if (this.provisionAgreementsInput != null) {
            /* if(!!this.provisionAgreementsInput.agreements && this.provisionAgreementsInput.agreements.length != 0){
                this.filteredAttentions = this.provisionAgreementsInput.agreements.flatMap(agr => attentions.filter(att => att.agreementId === agr.id))
            }
            else */ if ((!!this.provisionAgreementsInput.provisionFees && this.provisionAgreementsInput.provisionFees.length != 0) || (!!this.provisionAgreementsInput.orders && this.provisionAgreementsInput.orders.length != 0)) {

                    if (this.selectedOption == OPTIONS.PROVISION) {
                        this.filteredAttentions = this.attentionsService.mamushkaCheckBoxes(this.provisionAgreementsInput, attentions)
                    }
                    else if (!!this.provisionAgreementsInput.orders && this.provisionAgreementsInput.orders.length != 0) {
                        this.filteredAttentions = this.provisionAgreementsInput.orders?.flatMap(order => attentions.filter(att => att?.agreementId === order?.agreement?.id));
                    } else {
                        this.filteredAttentions = attentions
                    }
                }
                else if (!!this.provisionAgreementsInput.provisionsSupplies && this.provisionAgreementsInput.provisionsSupplies.length != 0) {
                    this.filteredAttentions = this.provisionAgreementsInput.provisionsSupplies.flatMap(sup => attentions.filter(att => att.practiceId === sup.practice.id))
                }
                else {
                    this.filteredAttentions = attentions
                }
            } else {
                this.filteredAttentions = attentions
            }
        }

        if (!!this.stateAttentionSelected) {
            this.stateAttentionSelected = [];
        }
        this.getRangeToFilterAttentions();
        this.isLoadingFilteredAttentions = false;
        this.composeAttentionsToAgreements();
        this.assignColorsToAgreements();
        this.events = this.attentionsToEvents(this.attentions)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['viewColumnsBillAndDebit'] || !!changes['selectedOption']) {
            this.controlShowsAttentionsIfModeBillOrDebit()
        }
        this.loadAttentionsByCase();

        //Bill supplies attentions automatically after values are loaded
        if (changes['billSuppliesExternally'] && this.billSuppliesExternally?.length > 0) {
            let atte = this.attentionsToProcess.filter(att => att.bill);
            this.onClickFacturableDebitada(atte, true);
        }

        if (changes['allProvisionsWithoutFilter']) {
            this.provisions = changes['allProvisionsWithoutFilter'].currentValue;
            this.agreementsFromPatient = this.provisions.flatMap(provision => provision.agreements).filter(agreement => !!agreement);
            this.loadAttentionsByCase();
        }

        if (!!changes['attentions'] && !!changes['attentions'].currentValue) {
            this.composeAttentionsToAgreements();
            this.assignColorsToAgreements();
            this.events = this.attentionsToEvents(this.attentions)
            this.mapMonthEvents();
        }
    }

    controlShowsAttentionsIfModeBillOrDebit() {
        console.log('controlShowsAttentionsIfModeBillOrDebit')
        if (!this.viewColumnsBillAndDebit) {
            this.attentions = this.attentions?.filter(att => att.notManageable == false);
        } else {

            switch (this.selectedOption) {
                case OPTIONS.PROVISION:
                    this.attentions = this.attentions?.filter(att => !!att && !!att.practice && att.practice.name != 'Entrega insumos' && !att.notManageable);
                    this.displayColumnsForAttentions = ['fromDate', 'timeRange', 'hours', 'practice', 'state', 'actionsMenu', 'bill-debit'];
                    break;

                case OPTIONS.SUPPLY:
                    this.attentions = this.attentions?.filter(att => !!att && !!att.practice && att.practice.name != 'Entrega insumos' && att.notManageable);
                    this.displayColumnsForAttentions = ['fromDate', 'timeRange', 'quantity', 'practice', 'state', 'bill-debit'];
                    break;

                default:
                    break;
            }
        }
    }

    // onClickDeleteAttentions(attentions: Attention[]) {
    //     this.swalWithCustomizeButtons.fire({
    //         title: '¿Seguro desea eliminar las atenciones seleccionadas?',
    //         icon: 'warning',
    //         confirmButtonText: 'ELIMINAR',
    //         cancelButtonText: 'CANCELAR',
    //         showLoaderOnConfirm: true,
    //         showCancelButton: true,
    //         reverseButtons: true,
    //         preConfirm: () => {
    //             return this.deleteAttentions(attentions)
    //                 .then(_ => {
    //                     this.swalWithCustomizeButtons.fire({
    //                         title: 'Agenda actualizada correctamente',
    //                         icon: 'success',
    //                     });
    //                 })
    //                 .catch(err => {
    //                     console.log(err);
    //                 })
    //         },
    //         allowOutsideClick: () => !Swal.isLoading()
    //     })
    // }

    onClicProcessBillAndDebit(attentions, processBill: boolean = true) {

        if (attentions[0].isSupply) { // All attentions are supply
            const pFIds: number[] = this.getProvisionFeeIdsSupplies(attentions);
            if (pFIds?.length > 0 && this.provisionFeesWithKairos.length > 0) {
                // There are attentions with Kairos, cost 0 or fee 0
                this.attentionsToProcess = [...attentions];
                processBill = false;
                this.openModifySuppliesProvisionFee.emit(pFIds); // Emit to provision-scheme -> execute openModifyProvisionFeeSupplies()
            }
        }
        if (processBill && !!attentions && attentions.length > 0) {
            this.attentionsToProcess = [...attentions];
            this.canExecute$.next(this.attentionsToProcess.filter(att => att.bill === true).length > 0 ? 'bill' : 'debit') // Start-up before subscription
            this.processSuccessOnAttentions = false; // For refresh TOTALS on app-attentions-list-case
        }
    }

    proccesToBillOrDebit() {

        this._canExecute = this.canExecute$.subscribe(control => {

            if (control === 'bill') {
                const atte = this.attentionsToProcess.filter(att => att.bill === true) // TO BILL
                if (atte.length > 0) {
                    this.onClickFacturableDebitada(atte, true);
                }
            }
            if (control === 'debit') {
                const atte = this.attentionsToProcess.filter(att => att.debit === true) // TO DEBIT
                if (atte.length > 0) {
                    this.onClickFacturableDebitada(atte, false);
                } else {
                    this.canExecute$.next('') // Only bill, not debit
                }
            }
            if (control === '') {
                this.processSuccessOnAttentions = true;
            }
        })
    }

    deleteAttentions(attentions: Attention[]): Promise<Attention> {
        return new Promise((resolve, reject) => {
            this.attentionsService.deleteAttentions(attentions).subscribe(
                (attention) => resolve(attention),
                (error) => reject(error),
            );
        });
    }

    onClickCreateAttention(date?: Date) {

        // Reset all filters, TODO: change if it's necesary keeps the filters
        const filtersReset: ProvisionAgreementFilters = { provisions: [], agreements: [], provisionFees: [], provisionsSupplies: [], provisionAgreementsSupplies: [], orders: [] }
        this.provisionsFacade.setFiltersOnProvisions(filtersReset);

        this.activeAttention = null;
        this.provisionFeeSelected = null;
        this.agreementSelected = null;
        this.isRepeatSchedule = false;
        this.provisionsAndCompose(this.provisionsDispayed);
        this.scheduleForm = this.createScheduleForm();

        // Set to calendar
        this.view = !!this.viewSelectedCalendarCase ? this.viewSelectedCalendarCase : CalendarView.Month;
        console.log('viewDate: ', this.generalService.getDateToSetCalendar());
        // this.viewDate = this.dateRange.historyFromDate; // Set always before opendialog
        // this.getRangeToFilterAttentions();

        this.openDialogCalendar('Agendar', DialogComponent, { maxHeight: '95vh', minWidth: '95%' }, (agreement: Agreement) => {
            this.onCleanAttentions();
            this.scheduleForm.reset();
            this.isRepeatSchedule = false;
        });
        setTimeout(() => {
            if (date) {
                this.dateSelectedFromOtherComponent = date;
            }
        }, 2000);

        // if (date) {
        //     this.provisionFees = this.provisionFees.filter(provFee => {
        //         const rangeProvision = new momentRange.DateRange(moment(provFee.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!provFee.toDate ? moment(provFee.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);
        //         if (this.createAttentionFromOutside) {

        //             let isBetween = rangeProvision.contains(moment(date, "DD-MM-YYYY"));
        //             let isSameMonthFrom = moment(provFee.fromDate).isSame(moment(date), 'month')
        //             let isSameMonthTo = moment(provFee.toDate).isSame(moment(date), 'month')
        //             return isBetween || isSameMonthFrom || isSameMonthTo
        //         }
        //         return rangeProvision.contains(moment(date))
        //     })
        //     this.createAttentionFromOutside = false;
        // }
    }

    onEventClickedHandler(event) {

        let attention: Attention = event.calendarEvent;
        this.editMode = event.editMode;

        console.log("onEventClickedHandler(), attention to edit: ", attention);

        switch (true) {
            case attention.isSupply:
                this.alertService.openInfo('Las atenciones de entrega de insumos se gestionan desde los pedidos');
                break;

            case !attention.isSupply:
                const provisionFee = this.provisionFees.find(p => p.id == attention.provisionFeeId);
                this.mapCaseAgreements(provisionFee); // Find provisionFee and get agreements
                this.attentionToEdit = { ...attention, practiceDescription: this.calculateProvisionFeeName(provisionFee), agreements: this.agreements, provisionFeeSelected: provisionFee };

                let title: string = '';
                switch (this.editMode) {
                    case 'UPD':
                        title = `Editar atención - ${attention.practice?.name}`;
                        break;
                    case 'RPA':
                        title = `Reemplazar acuerdo prestacional - ${attention.practice?.name}`
                        break;
                    case 'DSP':
                        title = `${attention.practice?.name}`;
                        break;
                    default:
                        title = 'Error';
                        break;
                }

                // minWidth: '20%',
                // maxHeight: '90vh',
                this._dialogRefEditAttention = this.dialog.open(DialogComponent, {
                    data: { template: this.editAttentionDialogTemplate, title, templateClass: 'margin: 0' },
                });
                break;
            default:
                break;
        }
    }

    createScheduleForm(): FormGroup {

        console.log('createScheduleForm')

        return this.formBuilder.group({
            [this.PATIENT]: [''],
            [this.PROVISION_FEE]: ['', [CustomValidators.required('Prestación requerida')]],
            [this.AGREEMENT]: ['', [CustomValidators.required('Acuerdo prestacional requerido')]], //CustomValidators.required('Acuerdo prestacional requerido')
            [this.DATE_FROM]: [(!!this.activeAttention ? this.activeAttention.fromDate : ''),],
            [this.DATE_TO]: ['',],
            [this.QUANTITY]: [this.calculateAttentionDuration()],
            [this.DATE_FROM_UPDATE]: [(!!this.agreementSelected ? this.agreementSelected.fromDate : ''),],
            [this.DATE_TO_UPDATE]: [(!!this.agreementSelected ? this.agreementSelected.toDate : ''),],
            [this.TIME_FROM]: ['',],
            [this.TIME_TO]: ['',],
            [this.HOUR_DURATION]: ['',],
            [this.MINUTE_DURATION]: ['',],
            [this.DATE_END_REPEAT]: [''],
            [this.STATE_ATTENTION]: ['', [CustomValidators.required('Estado requerido')]],
            [this.REPEAT_ATTENTION]: [''],
            radioButton: new FormControl({ value: '', disabled: false },),
        }, {
            validators: [
                /* CustomValidators.CVFromToDate(
                    'La fecha de finalización debe ser superior a la de inicio', 'errCV',
                    this.DATE_FROM, this.DATE_TO
                ), */
                CustomValidators.CVFromToDate(
                    'La fecha de finalización de la repetición debe ser mayor o igual a la fecha de finalización de la atención',
                    'errCVEnd',
                    this.DATE_TO, this.DATE_END_REPEAT
                ),
                CustomValidators.CVFromToDate(
                    'La fecha de finalización de la repetición debe ser mayor o igual a la fecha de inicio de la atención',
                    'errCVEnd',
                    this.DATE_FROM, this.DATE_END_REPEAT
                )
            ]
        });
    }

    calculateAttentionDuration() {
        let fromDate = this.activeAttention?.fromDate ? this.activeAttention.fromDate : null;
        let toDate = this.activeAttention?.toDate ? this.activeAttention.toDate : null;

        return !!toDate ? moment(toDate).diff(moment(fromDate), 'hours') : null;
    }

    validateCheckTypeToUpdateDate() {
        if (this.radiobuttonsDisabled) {
            return false
        }
        return (this.selectionToUpdateValue == 'date') ? true : false;
    }

    validateCheckTypeToUpdateForward() {
        if (this.radiobuttonsDisabled) {
            return false
        }
        return (this.selectionToUpdateValue == 'forward') ? true : false;
    }

    clickRadiobutton(event) {
        switch (event.value) {
            case 'forward':
                if (this.selectionToUpdateValue == 'forward') {
                    this.selectionToUpdateValue = '';
                } else {
                    this.selectionToUpdateValue = event.value;
                }
                break;

            case 'date':
                if (this.selectionToUpdateValue == 'date') {
                    this.selectionToUpdateValue = '';
                } else {
                    this.selectionToUpdateValue = event.value;
                }
                break;
        }
    }

    checkState(element?: MatRadioButton) {
        setTimeout(() => {
            if (!!this.selectionToUpdateValue && this.selectionToUpdateValue === element?.value) {
                element.checked = false;
                this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-program-focused');
                this.selectionToUpdateValue = null;
            } else {
                this.selectionToUpdateValue = element.value;
            }
        })
    }

    mapCaseAgreements(provisonFeeSelected?: ProvisionFeeWithPractice) {

        this.rangePeriodSelector = new momentRange.DateRange(moment(this.dateRange.historyFromDate, 'YYYY-MM-DD'), moment(this.dateRange.historyToDate, 'YYYY-MM-DD'));
        const rangeProvision = new momentRange.DateRange(moment(this.provisionFeeSelected?.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!this.provisionFeeSelected?.toDate ? moment(this.provisionFeeSelected?.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);
        this.agreements = this.allProvisionsCase
            .flatMap(provision => {
                if (
                    provision.active && !provision.isDeliveryPractice
                    && !!provision.practice && !!provision.agreements
                    && !this.nomenclatorFacade.isSupplyPractice(provision.practice.id)
                ) {
                    provision.agreements.forEach(agreement => {
                        agreement.practice = provision.practice;
                        agreement.provision = provision;
                    });
                    return provision.agreements;
                }
            })
            .filter(agre => !!agre)
            .filter((ag: Agreement) => {

                const rangeAgreement = new momentRange.DateRange(moment(ag.fromDate, 'YYYY-MM-DD'), !!ag.toDate ? moment(ag.toDate, 'YYYY-MM-DD') : null);
                if (ag.active && !!ag.provider && ag.practice?.id === provisonFeeSelected.practice.id
                    // Agreements between period (selector date range)
                    && (this.rangePeriodSelector.overlaps(rangeAgreement) || this.rangePeriodSelector.adjacent(rangeAgreement))) {
                    if ((!!this.provisionFeeSelected)) {
                        if (rangeProvision.overlaps(rangeAgreement) || rangeProvision.adjacent(rangeAgreement)) {
                            return ag;
                        } else {
                            return null;
                        }
                    }
                    return ag;
                }
            });
        this.composeAttentionsToAgreements();
        this.events = this.attentionsToEvents(this.attentions)
    }

    openDialogCalendar(title: string, component, style: { minWidth: string, maxHeight: string }, afterClosed?): void {

        this._dialogRefPopUpAddAttentions = this.dialog.open(component, {
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template: this.scheduleDialogTemplate, title },
        });
        this._dialogRefPopUpAddAttentions.afterClosed().subscribe(afterClosed);
    }

    onSelectedAgreement(agreementId: number) {
        console.log('onSelectedAgreement')

        this.onCleanAttentions(); // Always after select agreement
        this.resetForm();

        this.agreementSelected = this.agreements.find(agreement => agreement.id == agreementId);
        this.agreementForm = this.agreementSelected.id

        const rangeProvision = new momentRange.DateRange(moment(this.provisionFeeSelected?.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!this.provisionFeeSelected?.toDate ? moment(this.provisionFeeSelected.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);
        const rangeAgreement = new momentRange.DateRange(moment(this.agreementSelected.fromDate, 'YYYY-MM-DD').hours(0).minutes(0), !!this.agreementSelected?.toDate ? moment(this.agreementSelected.toDate, 'YYYY-MM-DD').hours(23).minutes(59) : null);

        this.minDate = rangeProvision.intersect(rangeAgreement)?.start.toDate();
        console.log(rangeProvision.intersect(rangeAgreement));

        this.maxDate = rangeProvision.intersect(rangeAgreement)?.end.toDate();
        console.log(rangeProvision.intersect(rangeAgreement));


        const newDate: Date = moment(this.maxDate).isAfter(moment().add(6, 'months')) ? moment(moment().add(6, 'months')).toDate() : this.maxDate;
        this.dateEndRepeatForm = newDate;

        // TODO: delete after prod new edit component
        // if (this.agreementAttentionDefault == agreementId) {
        //     this.showRadiobuttons = false;
        // } else {
        //     this.showRadiobuttons = true;
        //     this.scheduleForm.patchValue({
        //         [this.DATE_FROM_UPDATE]: this.minDate,
        //         [this.DATE_TO_UPDATE]: this.maxDate,
        //     })
        // }

        if (!this.activeAttention) {
            this.stateAttentionForm = this.ATTENTION_STATES.AGENDADA;
            if (this.agreementSelected.practice.restriction.requiredToDate) {
                // Setting all validators to time pickers
                this.scheduleForm.get(this.TIME_FROM).setValidators(Validators.compose([CustomValidators.required('Requerido'), CustomValidators.hourFormat('Inválido')]));
                this.scheduleForm.get(this.TIME_TO).setValidators(Validators.compose([CustomValidators.required('Requerido'), CustomValidators.hourFormat('Inválida')]));
                this.scheduleForm.get(this.HOUR_DURATION).setValidators(Validators.compose([CustomValidators.minValue(0, 'Duración inválida'), CustomValidators.maxValue(24, 'Superó las 24 hs.')]));
            } else {
                this.scheduleForm.get(this.TIME_FROM).setValidators([]);
                this.scheduleForm.get(this.TIME_TO).setValidators([]);
                this.scheduleForm.get(this.HOUR_DURATION).setValidators([]);
            }
        }

        if (!!this.dateSelectedFromOtherComponent && moment(this.dateSelectedFromOtherComponent).isBetween(this.minDate, this.maxDate, undefined, '[]')) {
            this.onSelectDate(moment(this.dateSelectedFromOtherComponent));
        }
        console.log("Agreement selected: ", this.agreementSelected);
    }

    setAgreementToForm() {
        console.log('setAgreementToForm')
        this.allPatientsDialog = [this.activeAttention.patient];
        this.allAgreementsDialog = this.provisions?.filter(prov => !prov.isSupply).flatMap(provision => provision.agreements).filter((ag) => !!ag); // Filtered supplies and attention "Entrega insumos"
        this.allAgreementsDialog = this.allAgreementsDialog.filter(agreement => (agreement.provider.id == this.activeAttention.provider.id));
        // this.providerForm = this.activeAttention.provider.id;
        this.patientForm = this.activeAttention.patient.id;
        this.agreementForm = this.activeAttention.agreementId;
        this.dateFromForm = this.activeAttention.fromDate;
        this.dateToForm = this.activeAttention.toDate;
        this.stateAttentionForm = this.activeAttention.state.id;
    }

    onCleanAttentions() {
        console.log('onCleanAttentions')
        this.datesToPostSelected = [];
        this.isRepeatSchedule = false;
        this.setDayChipsSelected();
        this.sortAscArrayDates(this.datesToPostSelected); // Ordeno el array
        // this.minDate = null;
        // this.maxDate = null;
    }

    setDayChipsSelected() {
        this.dayChipsSelected.set('L', false);
        this.dayChipsSelected.set('M', false);
        this.dayChipsSelected.set('X', false);
        this.dayChipsSelected.set('J', false);
        this.dayChipsSelected.set('V', false);
        this.dayChipsSelected.set('S', false);
        this.dayChipsSelected.set('D', false);
    }

    // !Mutable
    // Ordena un array de moments, de manera ascendente (mas vieja primero)
    sortAscArrayDates(array: moment.Moment[]) {
        array.sort(function (a: moment.Moment, b: moment.Moment) {
            const a2 = a.toDate();
            const b2 = b.toDate();
            return a < b ? -1 : a > b ? 1 : 0;
        });
    }

    // Actualiza el valor del formulario "DateFromForm" en caso de que corresponda
    updateDateFromForm() {
        console.log('updateDateFromForm')
        // Si no hay fechas en el array o la fecha mas antigua del array es distinta a la del form modifico el form
        if (!this.activeAttention && (!this.datesToPostSelected[0]
            || new Date(this.datesToPostSelected[0].toDate()).getTime() != new Date(this.dateFromForm).getTime())) {
            this.dateFromForm = !!this.datesToPostSelected[0] ? this.datesToPostSelected[0].toDate() : null;
        }
    }

    // Handler llamado cuando se selecciona manualmente un dia del mat-calendar
    onSelectDate(date: moment.Moment) {
        console.log('onSelectDate')
        // Now, execute if agreement is selected
        if (!!this.agreementSelected && !this.disableDay(date)) {

            date = moment(date);
            date = date.set({ hour: 0, minute: 0, second: 0 });

            // Del / Add item to array
            const index = this.datesToPostSelected.findIndex(x => x.isSame(date, 'day'));

            if (index < 0) {
                this.datesToPostSelected.push(date);
            } else {
                this.datesToPostSelected.splice(index, 1);
            }
            // Sort array
            this.sortAscArrayDates(this.datesToPostSelected);
            // Update dateFromForm if necessary
            this.updateDateFromForm();
        }

        if (this.datesToPostSelected.length === 0) {
            this.resetForm();
        }
    }

    deleteDatesUntil(dateUntil) {
        dateUntil = moment(dateUntil);
        this.datesToPostSelected = this.datesToPostSelected.filter(adate => adate.isSameOrBefore(dateUntil));
        console.log(dateUntil);
    }

    /**
     *
     * @param continueCreating
     * @param mode '', 'CPY'
     * @param hourFromCopy
     * @param hourToCopy
     */
    onCreateAttentionV2(continueCreating: boolean = false, mode?: string, fromDateCopy?: Date, hoursCopy?: number, minutesCopy?: number, stateIdCopy?: number) {

        let attentionsToPost: { agreementId: number, fromDate: Date, toDate: Date, stateId: number, quantity?: number }[];
        this.loadingCreateAttention = true;

        if (this.agreementSelected.practice.restriction.requiredToDate && this.agreementSelected.practice.restriction.requiredTime) {
            // GUARDIA - GUARD
            const hourFrom = !!mode && mode === 'CPY' ? moment(fromDateCopy).hours() : Number(this.timeFromForm[0] + this.timeFromForm[1]);
            const minuteFrom = !!mode && mode === 'CPY' ? moment(fromDateCopy).minutes() : Number(this.timeFromForm[3] + this.timeFromForm[4]);

            attentionsToPost = this.datesToPostSelected.map(dateToPost => {

                const fromDate = moment(dateToPost);
                fromDate.set({ hour: hourFrom, minute: minuteFrom });
                const toDate = moment(fromDate);

                let hourDurationForm;
                let minuteDurationForm;

                if (mode != 'CPY') {
                    hourDurationForm = this.hourDurationForm
                    minuteDurationForm = this.minuteDurationForm
                } else {
                    hourDurationForm = hoursCopy
                    minuteDurationForm = minutesCopy
                }

                toDate.add(hourDurationForm, 'hour');
                toDate.add(minuteDurationForm, 'minute');

                const stateIdAux = mode != 'CPY' ? this.stateAttentionForm : stateIdCopy;
                return {
                    agreementId: this.agreementSelected.id,
                    stateId: stateIdAux,
                    fromDate: fromDate.toDate(),
                    toDate: toDate.toDate(),
                    provisionFeeId: this.provisionFeeForm,
                    quantity: [this.ATTENTION_STATES.REALIZADA, this.ATTENTION_STATES.FACTURABLE].includes(parseFloat(stateIdAux.toString())) ? this.calcHours(hourDurationForm, minuteDurationForm) : null
                };
            });
        } else {
            // VISITA - VISIT
            attentionsToPost = this.datesToPostSelected.map(dateToPost => {
                return {
                    agreementId: this.agreementSelected.id,
                    stateId: this.stateAttentionForm,
                    fromDate: dateToPost.toDate(),
                    toDate: null,
                    provisionFeeId: this.provisionFeeForm
                };
            });

            if (this.repeatAttention > 1) {
                attentionsToPost = this.repeatAttentions(attentionsToPost, this.repeatAttention)
            }
        }

        this.postAttentionV2(attentionsToPost)
            .then(attention => {
                if (mode != 'CPY') {
                    Swal.fire({
                        title: 'Agenda actualizada correctamente',
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false
                    });
                }
            })
            .then(_ => {
                if (continueCreating) {
                    this.onCleanAttentions();
                    this.resetValuesDialog(false, false);
                    this.dateSelectedFromOtherComponent = null;
                    this.agreementsExpanded.select(attentionsToPost[0].agreementId)
                } else {
                    this._dialogRefPopUpAddAttentions.close();
                }
            })
            .catch(_ => {
                console.log("Method error: onCreateAttentionV2()");
            })
            .finally(() => this.loadingCreateAttention = false);
    }

    resetValuesDialog(resetAgreementForm, resetAgreementSelected) {
        console.log('resetValuesDialog')

        this.allAgreementsDialog = [];
        this.allPatientsDialog = [];
        this.patientForm = null;

        this.agreementForm = resetAgreementForm ? null : this.agreementForm;
        this.agreementSelected = resetAgreementSelected ? null : this.agreementSelected;
        this.dateEndRepeatForm = resetAgreementSelected ? null : this.dateEndRepeatForm;
        this.minDate = resetAgreementSelected ? null : this.minDate;
        this.maxDate = resetAgreementSelected ? null : this.maxDate;

        this.timeFromForm = null;
        this.timeToForm = null;
        this.hourDurationForm = null;
        this.minuteDurationForm = null;

        this.repeatAttentionForm = null;
        this.isNextDay = false;
        // TODO: delete after production
        // I'm sotb, I know it
        // setTimeout(() => {
        //     this.agreementSelected = null; // If it's not exist this line then -> bug to app-date-picker called DATE_END_REPEAT (properties min & max)
        // }, 100);
    }

    resetForm() {
        console.log('resetForm');
        this.datesToPostSelected = this.datesToPostSelected.filter(date => this.datesToPostSelected.indexOf(date) == 0);
        this.setDayChipsSelected();
        this.isRepeatSchedule = false;
        this.defaultValueRepeatAttention = 1;
        this.repeatAttention = 1;
        this.defaultValueStateAttention = AttentionStates.AGENDADA;
        // this.timeToForm = null; // TODO: se hacen en resetValuesDialog()
        // this.timeFromForm = null;
        // this.hourDurationForm = null;
        // this.minuteDurationForm = null;
    }

    postAttention(attentions: { fromDate: Date, toDate: Date, stateId: number, agreementId: number, provisionFeeId?: number }[]): Promise<Attention> {
        return this.attentionsFacade.postAttentions(attentions).toPromise();
    }

    postAttentionV2(attentions: { fromDate: Date, toDate: Date, stateId: number, agreementId: number, provisionFeeId?: number }[]): Promise<Attention> {
        return this.attentionsFacade.postAttentionsV2(attentions).toPromise();
    }

    onClickCloseDialog() {
        this._dialogRefPopUpAddAttentions?.close();
        this.scheduleForm?.reset();
        this.agreementsExpanded.clear();
        this.dateSelectedFromOtherComponent = null;
    }

    // Metodo que se llama cuando se selecciona o se deselecciona un day chip
    selectDayChip(day: { key, value }) {
        // Actualizo la vista de los chips
        this.dayChipsSelected.set(day.key, !this.dayChipsSelected.get(day.key));
        // Actualizo el value del dia que me vino (por la accion anterior)
        day.value = this.dayChipsSelected.get(day.key);
        // Obtengo el dia en numero (antes estaba como L,M,X,J...)
        let numberDay = this.getDayChipNumber(day);
        // Obtengo el array de dias de ese día (chip) específico que se selecciono/deselecciono
        const daysOfDayChip: moment.Moment[] = this.dateService.recurDaysOfWeek(this.dateFromForm, this.dateEndRepeatForm, [numberDay]);
        // Inserto los días en el array de dias base (si el day chip fue seleccionado, es decir day.value == true)
        // o elimino los días en el array de dias base (si el day chip fue deseleccionado, es decir day.value == false)
        this.updateDatesToPostSelected(daysOfDayChip, day.value);
        // Si ese dia fue seleccionado
        // Ordeno el array
        this.sortAscArrayDates(this.datesToPostSelected);
        // Actualizo el dateFormFrom si es necesario
        this.updateDateFromForm();
        // this.calendarToPost ? this.calendarToPost.updateTodaysDate() : null;
    }

    // Metodo llamado para actualizar el array de dias (agregar/eliminar dias segun los que ya están y los nuevos)
    updateDatesToPostSelected(newDays: moment.Moment[], insert: boolean) {
        // Si se deben agregar los dias
        if (insert) {
            // Los dias que no estén los agrego
            newDays.forEach((newDay: moment.Moment) => {
                const indexNewDayOnOriginalArray = this.datesToPostSelected.findIndex(adate => adate.isSame(newDay, 'day'));
                if (indexNewDayOnOriginalArray == -1) {
                    this.datesToPostSelected.push(newDay);
                }
            });
        }
        // Si se deben quitar los dias
        else {
            // Recorro y borro los días que sean iguales
            newDays.forEach((dayToDelete: moment.Moment) => {
                const indexDayToDeleteOnOriginalArray = this.datesToPostSelected.findIndex(adate => adate.isSame(dayToDelete, 'day'));
                if (indexDayToDeleteOnOriginalArray != -1) {
                    this.datesToPostSelected.splice(indexDayToDeleteOnOriginalArray, 1);
                }
            });
        }
    }

    // Retorna en número el dia pasado como argumento (key: "L", "M"... value: true/false)
    getDayChipNumber(day: { key, value }): number {
        let numberDay;
        [...this.dayChipsSelected.entries()].forEach((element, i) => {
            const key = element[0];
            if (key == day.key) {
                numberDay = i;
                return false;
            }
        });
        return numberDay + 1; // "+ 1" because chips begins monday and not sunday
    }

    isDayChipSelected(day) {
        return this.dayChipsSelected.get(day);
    }

    asIsOrder(a, b) {
        return 1;
    }

    onChangeCheckRepeatSchedule(event) {
        console.log('onChangeCheckRepeatSchedule')
        this.isRepeatSchedule = event.checked;

        if (event.checked) {
            this.dateEndRepeatForm = moment(this.dateFromForm).add(1, 'months').date(0).toDate();
            this.setDayChipsSelected();

            if (!!this.maxDate) {
                const newDate: Date = moment(this.maxDate).isAfter(moment().add(6, 'months')) ? moment(moment().add(6, 'months')).toDate() : this.maxDate;
                this.scheduleForm.get(this.DATE_END_REPEAT).setValue(newDate);
            }
        } else {
            //this.dateEndRepeatForm = null;
            this.datesToPostSelected = this.datesToPostSelected.filter(date => this.datesToPostSelected.indexOf(date) == 0);
            // this.calendarToPost ? this.calendarToPost.updateTodaysDate() : null;
        }
    }

    // Retorna si el día del mat-calendar está en el array de los dates seleccionados (datesToPostSelected)
    // Es llamado desde el html, en el mat-calendar
    isSelectedDate = (date: moment.Moment) => {
        return this.datesToPostSelected.find((x: moment.Moment) => x.isSame(date, 'day')) ? true : false;
    }

    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }

    calculateNamePractice(element: Practice) {
        return element ? element.name : null;
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? `${element?.surname + ' ' + element?.name}` : null;
    }

    calculateNamePatient(element: Patient) {
        return element ? element.surname + ' ' + element.name : null;
    }

    calculateNameAgreement(element: Agreement): string {
        return element
            && element.practice
            && element.practice.name
            ? element.practice.name + ' - ' + (element.provision?.fee ? element.provision.fee : '')
            : 'Sin datos';
    }

    calculateNameState(element: StateAttention) {
        return element ? element.name : null;
    }

    calculateIdState = (element: StateAttention) => {
        return element ? element.id : null;
    }

    calculateNameAttentionRepeat(element: number) {
        return element
            ? element == 1 ? `${element} vez al día` : `${element} veces al día`
            : null;
    }

    calculateIdAttentionRepeat = (element: number) => {
        return element ? element : null;
    }

    calculateAgreementId(agreement: Agreement) {
        return agreement.id;
    }

    calculateAgreementName(agreement: Agreement) {
        let result = (!!agreement.provider?.name || !!agreement.provider?.surname)
            ? agreement.provider.name + ' ' + agreement.provider.surname
            : ' - ';

        result += !!agreement.providerFee ? ' - $' + agreement.providerFee : ' - ';

        return result + ' - ' + (!!agreement.fromDate && !!agreement.toDate ? `${moment(agreement.fromDate).format('DD/MM/YYYY')} a ${moment(agreement.toDate).format('DD/MM/YYYY')}` : `${moment(agreement.fromDate).format('DD/MM/YYYY')}`);
    }

    onClickDeleteAttention(attention) {
        if (!this.isDeleteModeAttentionFromGridAgreements) {
            if (attention) {
                this.activeAttention = attention;
            } else {
                attention = this.activeAttention;
            }
        }

        this.swalWithCustomizeButtons.fire({
            title: '¿Seguro desea eliminar la atención?',
            icon: 'warning',
            confirmButtonText: 'ELIMINAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: () => {
                return this.deleteAttention(attention)
                    .then(_ => {
                        // !this.isDeleteModeAttentionFromGridAgreements ? this.onClickCloseDialog() : null; // Retrocompatible
                        this.swalWithCustomizeButtons.fire({
                            title: `Atención eliminada correctamente`,
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false
                        })

                        if (!!this._dialogRefEditAttention.componentInstance) { // Action fired from another component
                            this._dialogRefEditAttention.close();
                        }
                    })
                    .catch(err => {
                        // !this.isDeleteModeAttentionFromGridAgreements ? this.onClickCloseDialog() : null; // Retrocompatible
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                // Not close previous window
            }
        })
    }

    deleteAttention(attention: Attention) {
        return this.attentionsFacade.deleteAttention(attention).toPromise();
    }

    filterCaseProviders(): Provider[] {
        const aux = this.provisions?.flatMap(provision => {
            if (!!provision.agreements) {
                return provision.agreements;
            }
        });
        const providers = aux?.map(agreement => {
            if (!!agreement && !!agreement.provider) {
                return agreement.provider;
            }
        }).filter(provider => !!provider) as Provider[];

        const providersSet: Provider[] = [];
        providers.forEach(provider => {
            providersSet.find(prov => provider.id == prov.id) ? null : providersSet.push(provider);
        });

        return providersSet;
    }

    onEditAgreement(agreementId: number): void {

        this.agreementSelected = this.allAgreementsDialog.find(agreement => agreement.id == agreementId);
        if (!this.initialAgreementId && this.activeAttention.agreementId != agreementId) {
            this.initialAgreementId = this.activeAttention.agreementId;
        }
        this.activeAttention.agreementId = agreementId;
        this.activeAttention.practiceId = this.agreementSelected.practice.id;
        this.activeAttention.practice = this.agreementSelected.practice;
    }

    attentionDateEdited(event) {
        if (event) {
            this.radiobuttonsDisabled = true;
            this.selectionToUpdateValue = '';
            this.dateToForm = moment(this.dateFromForm).add(this.durationAttention, 'hours').toDate();
        }
    }

    attentionQuantityEdited() {
        this.dateToForm = moment(this.dateFromForm).add(this.durationAttention, 'hours').toDate();
    }

    composeAttentions(attentions: Attention[], agreements: Agreement[]): Attention[] {
        const attentionsComposed = attentions.map(attention => {
            let agreement = agreements?.find(agreement => agreement.id === attention.agreementId);
            if (agreement != null) {
                agreement['practice'] = attention.practice;
                attention.patient = this.case.patient;
                attention.provider = agreement.provider;
            }
            return attention;
        });
        return attentionsComposed;
    }

    setAttentionsOldAndOmitted() {
        let countAttentionOldAndOmmited: number = 0;
        this.attentions.forEach(at => {
            if (this.attentionsService.isOldAndOmmited(at)) {
                countAttentionOldAndOmmited += 1;
            }
        });
        this.case.attentionsOldAndOmitted = countAttentionOldAndOmmited;
    }

    // Process Bill or Debit
    onClickFacturableDebitada(attentions: Attention[], facturable: boolean) {
        let warningText: string;
        let warningTitle: string = 'Advertencia de cambio de estado fuera del flujo normal';
        let countAttentionsInStateDone: number = 0;
        let countAttentionsInStateNotDone: number = 0;
        let countAttentionsInStateBillable: number = 0;
        let countAttentionsInStateDebited: number = 0;
        let warningPopUp: Boolean = false;
        // Eliminamos las seleccionadas para pasarlas al mismo estado
        if (facturable) {
            attentions = attentions.filter(at => at.state.id != AttentionStates.FACTURABLE);
        } else {
            attentions = attentions.filter(at => at.state.id != AttentionStates.DEBITADA);
        }
        // Seteamos contadores de estado
        attentions.forEach((attention) => {
            if (attention.state.id == AttentionStates.REALIZADA) {
                countAttentionsInStateDone += 1;
            }
            if (attention.state.id == AttentionStates.NO_REALIZADA) {
                countAttentionsInStateNotDone += 1;
            }
            if (attention.state.id == AttentionStates.FACTURABLE) {
                countAttentionsInStateBillable += 1;
            }
            if (attention.state.id == AttentionStates.DEBITADA) {
                countAttentionsInStateDebited += 1;
            }

        });
        // Identificamos cambios de estado fuera del curso normal (debitada -> facturable | no realizada -> facturable | facturable -> debitada | realizada -> debitada)
        if (facturable && (countAttentionsInStateNotDone > 0 || countAttentionsInStateDebited > 0)) {
            warningPopUp = true;
            countAttentionsInStateNotDone > 0
                ? warningText = 'Tiene '
                + countAttentionsInStateNotDone
                + ' atención/es seleccionada/s en estado "No realizada". '
                : warningText = '';
            countAttentionsInStateDebited > 0
                ? warningText = warningText
                + 'Tiene ' + countAttentionsInStateDebited
                + ' atención/es seleccionada/s en estado "Debitada". '
                : warningText = '';
            warningText = warningText + 'Debe escribir un motivo para poder realizar el/los cambios de estado a "facturable".';
        } else {
            if (!facturable && (countAttentionsInStateDone > 0 || countAttentionsInStateBillable > 0)) {
                warningPopUp = true;
                countAttentionsInStateDone > 0
                    ? warningText = 'Tiene '
                    + countAttentionsInStateDone
                    + ' atención/es seleccionada/s en estado "Realizada". '
                    : warningText = '';
                countAttentionsInStateBillable > 0
                    ? warningText = warningText
                    + 'Tiene '
                    + countAttentionsInStateBillable
                    + ' atención/es seleccionada/s en estado "Facturable".'
                    : warningText = '';
                warningText = warningText + 'Debe escribir un motivo para poder realizar el/los cambios de estado a "debitada".';
            }
        }

        let pathFunction = (val) => {

            let st: string;
            const attentionsToPatch: Attention[] = attentions.map((at) => {
                let attention: Attention = {
                    id: at.id,
                    caseId: at.caseId,
                    state: {
                        id: facturable ? this.ATTENTION_STATES.FACTURABLE : this.ATTENTION_STATES.DEBITADA
                    }
                };
                if (this.isCrossState(at, facturable)) {
                    st = facturable ? 'Facturable' : 'Debitada';
                    val = '(Cambio de "' + at.state.name + '" a "' + st + '") ' + val;
                    attention.crossStateDescription = val;
                }
                return attention;
            });
            return this.patchAttentions(attentionsToPatch)
                .then((attentionsResponse) => {
                    this.attentions = this.attentions?.map((attention) => {
                        const updatedAttention = attentionsResponse.find(
                            (att) => attention.id == att.id,
                        );
                        if (updatedAttention) {
                            attention = { ...attention, state: updatedAttention.state };
                        }
                        return attention;
                    });

                    // Re-load provisions
                    let provisionsFiltered = attentionsResponse.map(att => att.provisionId)

                    provisionsFiltered.filter((item, index) => {
                        return provisionsFiltered.indexOf(item) === index;
                    })

                    this.supplyList = this.supplyList?.map((supply) => {
                        const updatedSupply = attentionsResponse.find(
                            (att) => supply.id == att.id,
                        );
                        if (updatedSupply) {
                            supply = { ...supply, state: updatedSupply.state };
                        }
                        return supply;
                    });
                })
                .then((_) => {
                    this.swalWithCustomizeButtons.fire({
                        title: 'Atenciones modificadas correctamente',
                        icon: 'success',
                    }).then(_ => {
                        const executionType = this.canExecute$.value === 'bill' ? 'debit' : '';
                        this.canExecute$.next(executionType)
                        this.dialog.closeAll();
                    });
                })
                .catch((err) => {
                    this.swalWithCustomizeButtons.fire({
                        title: 'Atención',
                        icon: 'warning',
                        text: err.error.message,
                    })
                });
        };

        let title;
        let text;

        if (this.billSuppliesExternally?.length > 0) {
            title = 'Valor/es cargado/s con éxito.'
            text = '¿Desea completar el cambio de estado a “Facturable” de los insumos seleccionados?'
        } else {
            title = 'Atención'
            text = `¿Está seguro que desea modificar las atenciones seleccionadas al estado "${facturable ? 'Facturable' : 'Debitada'}" ?`
        }

        const sao: SweetAlertOptions = {
            icon: 'question',
            title: title,
            text: text,
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: pathFunction,
            allowOutsideClick: () => !Swal.isLoading(),
        };
        if (warningPopUp) {
            this.swalWithCustomizeButtons.fire({
                icon: 'warning',
                title: warningTitle,
                text: warningText,
                input: 'textarea',
                inputPlaceholder: 'Escriba un motivo... ',
                inputValidator: (value) => {
                    if (value.length < 10) {
                        return 'El motivo debe tener más de 10 caracteres.';
                    }
                    return ''
                },
                confirmButtonText: 'CONFIRMAR',
                cancelButtonText: 'CANCELAR',
                showLoaderOnConfirm: true,
                showCancelButton: true,
                reverseButtons: true,
                preConfirm: pathFunction,
                allowOutsideClick: () => !Swal.isLoading(),
            });
        } else if (this.provisionFeesWithoutCostOrFee.length != 0 && this.provisionFeesWithKairos.length == 0 && (!this.billSuppliesExternally || this.billSuppliesExternally?.length == 0)) {
            let arrayTable = this.provisionFeesWithoutCostOrFee.map(sup => {
                return {
                    supply: sup.practice.name,
                    provider: `${sup?.agreement?.provider?.name} ${sup?.agreement?.provider?.surname}`,
                    Quantity: `${sup.frequency.amount} ${sup.frequency.unit}`
                }
            })
            this._dialogRefSupply = this.dialog.open(MessageDialogComponent, {
                disableClose: false,
                maxHeight: '70vh',
                minWidth: '30%',
                data: {
                    arrayTable: arrayTable,
                    title: 'Atención',
                    icon: 'WarningCircle',
                    closeText: 'MODIFICAR',
                    confirmText: 'CONTINUAR',
                    message: 'Los siguientes insumos no poseen costo o valor venta, ¿Desea continuar?',
                }
            })
            this._dialogRefSupply.componentInstance.ok.subscribe(result => {
                if (result) {
                    pathFunction('').then(() => {
                        this._dialogRefSupply.close()
                        this.provisionFeesWithKairos = [];
                        this.provisionFeesWithoutCostOrFee = [];
                    })
                } else {
                    this.openModifySuppliesProvisionFee.emit(this.provisionFeesWithoutCostOrFee.map(pF => pF.id))
                    this._dialogRefSupply.close()
                }
            })
        } else {
            this.swalWithCustomizeButtons?.fire(sao).then(() => this.dialog.closeAll());
        }
        this.billSuppliesExternally = [];
    }

    patchAttentions(attentions: PatchAttentionBody[]): Promise<Attention[]> {
        return new Promise((resolve, reject) => {
            this.attentionsFacade.patchAttentions(attentions).subscribe(
                (attention) => resolve(attention),
                (error) => reject(error),
            );
        });
    }

    isCrossState(at: Attention, facturable: boolean) {
        if (facturable && (at.state.id == AttentionStates.NO_REALIZADA || at.state.id == AttentionStates.DEBITADA)) {
            return true;
        } else {
            if (!facturable && (at.state.id == AttentionStates.NO_REALIZADA || at.state.id == AttentionStates.REALIZADA || at.state.id == AttentionStates.FACTURABLE)) {
                return true;
            } else {
                return false;
            }
        }
    }

    receiveEventUpdate(attention: Attention) {
        this.onEventClickedHandler({ calendarEvent: attention, editMode: 'DSP' });
    }

    receiveEventDelete(attention: Attention) {
        this.isDeleteModeAttentionFromGridAgreements = false;
        this.onClickDeleteAttention(attention);
    }

    receiveOptionSelected(option) {
        this.optionSelected = option.value;
    }

    createAttentionFromOUT(event) {
        // let today = new Date();
        this.createAttentionFromOutside = true
        moment.updateLocale("es", {
            week: {
                dow: 0, // First day of week is Monday
                doy: 7  // First week of year must contain 4 January (7 + 1 - 4)
            }
        });

        switch (event.view) {
            case CalendarView.Day:
                // this.onClickCreateAttention(event.date); // Selected day
                this.onClickCreateAttention(); // Selected day
                break;

            case CalendarView.Week:
                this.onClickCreateAttention(); // Selected day
                // if (isSameWeek(event.date, today)) {
                //     this.onClickCreateAttention(today);
                // } else {
                //     const newDate = new Date(moment(event.date).startOf('week').toString()) // First day the week
                //     this.onClickCreateAttention(newDate);
                // }
                break;

            case CalendarView.Month:
                this.onClickCreateAttention();
                // if (isSameMonth(event.date, today)) {
                //     this.onClickCreateAttention();
                // } else {
                //     this.onClickCreateAttention();
                // }
                break;
            default:    // Nothing
                break;
        }
    }

    receiveStateAttentionsSelected(states: number[]) {
        this.stateAttentionSelected = states;
    }

    calculateProvisionFeeId(provisionFee: ProvisionFeeWithPractice) {
        return provisionFee.id;
    }

    calculateProvisionFeeName(provisionFee: ProvisionFeeWithPractice) {
        let result = '';

        if (!!provisionFee.frequency && !!provisionFee.frequency.amount)
            result = `${provisionFee.practice.name} - ${provisionFee.frequency.amount} por ${provisionFee.frequency.unit} - `
        else
            result = `${provisionFee.practice.name} - `

        if (!!provisionFee.arrangementId) {
            result = result + "Valor convenido"
        } else {
            result = result + `$${provisionFee.fee}`
        }
        return result + ' - ' + (!!provisionFee.fromDate && !!provisionFee.toDate ? `${moment(provisionFee.fromDate).format('DD/MM/YYYY')} a ${moment(provisionFee.toDate).format('DD/MM/YYYY')}` : `${moment(provisionFee.fromDate).format('DD/MM/YYYY')}`);
    }

    onSelectedProvisionFee(event: ProvisionFeeWithPractice) {

        this.onCleanAttentions();
        this.resetValuesDialog(true, true);

        this.provisionFeeSelected = event;
        console.log("ProvisionFEE selected: ", this.provisionFeeSelected);

        // this.view = CalendarView.Month;
        // this.CalendarView
        this.setButtonsToCalendarNavigate(this.view, this.generalService.getDateToSetCalendar());

        if (this.calendarCaseService.practiceCountedByHours(event.practice)) {
            this.scheduleForm.get(this.DATE_FROM).setValidators([CustomValidators.required('Fecha requerida')])
        } else {
            this.scheduleForm.get(this.DATE_FROM).setValidators([]);
        }
        this.mapCaseAgreements(event);
        this.events = this.attentionsToEvents(this.attentions);
        this.mapMonthEvents();
    }

    ngOnDestroy(): void {

        if(!!this._screenToJump){
            this._screenToJump.unsubscribe()
            this.breadcrumbService.setScreenToJump(null)
        }

        console.log('schedule-case ngOnDestroy');

        if (!!this._canExecute)
            this._canExecute.unsubscribe();

        if (!!this._allProvisionsCase) {
            this._allProvisionsCase.unsubscribe();
        }

        this._historyModeDate ? this._historyModeDate.unsubscribe() : null;
        this._filtersOnProvisions ? this._filtersOnProvisions.unsubscribe() : null;
        this._provisionsDisplayedOnScreen ? this._provisionsDisplayedOnScreen.unsubscribe() : null;
        this._provisionsForCase ? this._provisionsForCase.unsubscribe() : null;
    }

    mustShowSpinner() {
        if (this.isLoadingGetAttentions || this.isLoadingGettingEvolutions) {
            return true;
        }
        return false;
    }

    setDatePicker(event) {
        console.log('setDatePicker')
        if (!!event.value) {
            this.deleteDatesUntil(moment(event.value).toDate());

            for (let [key, value] of this.dayChipsSelected) {

                if (value === true) {
                    // Same logic as "selectDayChip" method, but without the toggle function
                    let numberDay = this.getDayChipNumber({ key, value });

                    const daysOfDayChip: moment.Moment[] = this.dateService.recurDaysOfWeek(this.dateFromForm, this.dateEndRepeatForm, [numberDay]);

                    this.updateDatesToPostSelected(daysOfDayChip, value);

                    this.sortAscArrayDates(this.datesToPostSelected);

                    this.updateDateFromForm();

                    // this.calendarToPost.updateTodaysDate();
                }
            }
        } else {
            this.dateEndRepeatForm = moment(this.dateFromForm).add(6, 'months').toDate();
        }
    }

    validateTimeValid() {
        let timeString = this.scheduleForm.get(this.TIME_FROM).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.scheduleForm.get(this.TIME_FROM).setValue(formattedTimeString)
                if (this.scheduleForm.get(this.TIME_TO).dirty) {
                    this.changeHourFinish();
                } else if (this.scheduleForm.get(this.HOUR_DURATION).dirty || this.scheduleForm.get(this.MINUTE_DURATION).dirty) {
                    this.durationChanged();
                }
            }
        }
    }

    changeHourFinish() {
        let timeString = this.scheduleForm.get(this.TIME_TO).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        let timeTo;
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.scheduleForm.get(this.TIME_TO).setValue(formattedTimeString);

                let timeFrom = moment(this.scheduleForm.get(this.TIME_FROM).value, 'HH:mm')
                // Same day
                if (parseInt(timeString) > parseInt(this.timeFromForm.replace(':', ''))) {
                    timeTo = moment(this.scheduleForm.get(this.TIME_TO).value, 'HH:mm')
                    // Next day
                } else {
                    timeTo = moment(timeFrom).add(1, 'day')
                    timeTo = moment(timeTo).set({ hour: timeString.substring(0, 2), minute: timeString.substring(2,) })
                }

                let timeDifference = moment.duration(timeTo.diff(timeFrom))
                this.scheduleForm.get(this.HOUR_DURATION).setValue(timeDifference.asHours() >> 0);
                this.scheduleForm.get(this.MINUTE_DURATION).setValue(timeDifference.minutes());
            }
        }
        this.scheduleForm.get(this.HOUR_DURATION).markAsPristine();
        this.scheduleForm.get(this.MINUTE_DURATION).markAsPristine();
        this.validateIfNextDay();
        this.setMinutesToLimit();
    }

    durationChanged() {
        let hours = this.hourDurationForm;
        let minutes: any = hours != 24 ? this.minuteDurationForm : 0;
        let hourFinish = moment(this.scheduleForm.get(this.TIME_FROM).value, 'HH:mm').add({ hours: hours, minutes: minutes })
        if (hours != null && (minutes != null && minutes != "")) {
            this.scheduleForm.get(this.TIME_TO).setValue(hourFinish.format('HH:mm'));
            this.scheduleForm.get(this.TIME_TO).markAsPristine();
        } else if (hours != null && (minutes == null || minutes == "")) {
            this.minuteDurationForm = 0;
            this.scheduleForm.get(this.TIME_TO).setValue(hourFinish.format('HH:mm'));
            this.scheduleForm.get(this.TIME_TO).markAsPristine();
        }
        this.validateIfNextDay();
        this.setMinutesToLimit();
    }

    calcHours(hourDurationForm: number, minuteDurationForm: number): number {
        return parseFloat((minuteDurationForm / 60).toFixed(2)) + hourDurationForm
    }

    validateIfNextDay() {
        this.isNextDay = moment(this.timeToForm, 'HH:mm').diff(moment(this.timeFromForm, 'HH:mm')) <= 0;
    }

    // Limits is 24 HH and 00 mm
    setMinutesToLimit() {
        if (this.hourDurationForm >= 24) {
            this.minuteDurationForm = 0;
            this.scheduleForm.get(this.MINUTE_DURATION).disable();
        } else {
            this.scheduleForm.get(this.MINUTE_DURATION).enable();
        }
    }

    onSelectedAttentionRepeat(event) {
        this.repeatAttention = event;
        this.defaultValueRepeatAttention = this.repeatAttention
    }

    onSelectedStateAttention(event) {
        this.defaultValueStateAttention = event;
    }

    repeatAttentions(attentions, N) {
        return attentions.flatMap(att => Array.from({ length: N }, () => att));
    }

    assignColorsToAgreements() {
        let agreements = Array.from(new Set(this.attentions?.flatMap(at => at.agreementId)))
        let colorIndex = this.colorsForAgreements.size != undefined ? this.colorsForAgreements.size : 0
        agreements?.forEach(agr => {
            if (!this.colorsForAgreements.has(agr)) {
                let color;
                if (colorIndex < colors.length) {
                    color = colors[colorIndex]
                    colorIndex++
                } else {
                    let randomColor = this.generalService.getRandomColor()
                    color = { primary: randomColor, secondary: randomColor }
                }
                this.colorsForAgreements.set(agr, color)
            }
        })
    }

    composeAttentionsToAgreements() {
        this.agreements = this.agreements?.map(ag => {
            ag.attentions = this.attentions.filter(at => at.agreementId == ag.id && this.filterAttentions.range.contains(moment(at.fromDate))).sort((a, b) => moment(a.fromDate).isAfter(b.fromDate) ? 1 : -1);
            return ag
        })
    }

    getAttentionDayText(att: Attention) {
        return moment(att.fromDate).format('ddd DD MMM');
    }

    getAttentionHourSpan(att: Attention) {
        return moment(att.fromDate).format('HH:mm') + ' - ' + moment(att.toDate).format('HH:mm')
    }

    get practiceCountedByHours() {
        return this.provisionFeeSelected.practice.restriction.requiredTime
    }

    attentionsToEvents(attentions: Attention[]): CalendarEvent[] {

        console.log('attentionsToEvents');

        const events: CalendarEvent[] = attentions.filter(at => at.provisionFeeId == this.provisionFeeSelected?.id).map(attention => {

            const event: CalendarEvent = {
                id: attention.id,
                color: this.colorsForAgreements.get(attention.agreementId),
                title: `${this.entitiesFacade.getProvidersFullName(attention?.provider)} - $${attention?.providerFee}`,
                start: new Date(attention.fromDate),
                cssClass: 'color-white',
                end: !!attention.toDate ? new Date(attention.toDate) : moment(attention.fromDate).add(1, 'hours').toDate(),
                draggable: false,
                allDay: false,
                resizable: { beforeStart: false, afterEnd: false },
                meta: {
                    attention,
                    referenceText: `${attention.practice.name} • ${attention.patient.surname} | ${this.entitiesFacade.getProvidersFullName(attention?.provider)}`,
                }
            };
            return event;
        });
        return events;
    }

    mapMonthEvents() {
        this.monthEvents = this.events.map((ev) => {
            ev.meta.isNotOneDayEvent = moment(ev.meta.attention.toDate).isAfter(ev.start, 'day');
            return ev
        })
    }

    onClickSeeMoreAttentions(_event: any, day: CalendarMonthViewDay) {
        _event.preventDefault();
        _event.stopPropagation();
        this.extraDayAttentions = day.events;
        let template = this.extraAttentionsTemplate;
        this.dayOpened = moment(day.date);

        const dialogWidth = 300;  // Ancho del diálogo
        const dialogHeight = 400; // Altura del diálogo

        // Calcula las posiciones deseadas basadas en el evento de clic
        let left = _event.clientX - dialogWidth / 2;
        let top = _event.clientY - dialogHeight;

        // Obtén los límites de la ventana del navegador
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Ajusta la posición si el diálogo se sale del lado izquierdo o derecho de la pantalla
        if (left < 0) {
            left = 0;
        } else if (left + dialogWidth > viewportWidth) {
            left = viewportWidth - dialogWidth;
        }

        // Ajusta la posición si el diálogo se sale de la parte superior o inferior de la pantalla
        if (top < 0) {
            top = 0;
        } else if (top + dialogHeight > viewportHeight) {
            top = viewportHeight - dialogHeight;
        }

        // Abre el diálogo con la posición ajustada
        this._dialogRefAttByDay = this.dialog.open(DialogComponent, {
            disableClose: false,
            minWidth: `${dialogWidth}px`,
            maxHeight: `${dialogHeight}px`,
            data: { template },
            position: { left: `${left}px`, top: `${top}px` }
        });
    }


    selectView(option) {
        this.view = option.value
        this.getRangeToFilterAttentions();
        this.setButtonsToCalendarNavigate(this.view);
        // this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar
        this.updateScheduleView();
    }

    applyDateSelectionPolicy(body: any) {
        body?.forEach(day => {
            if (!!this.dateRange) {
                if (moment(day.date).isValid()
                    && !(moment(day.date).isBetween(this.dateRange.historyFromDate, this.dateRange.historyToDate, undefined, '[]'))) {
                    day.inMonth = false; // Day disabled if out of range
                }
            }
        });
    }

    clickonCalendarPreviousNextView() {

        console.log("this.viewDate: ", this.viewDate);
        console.log("this.viewDateX: ", this.viewDateX);

        this.getRangeToFilterAttentions();
        this.setButtonsToCalendarNavigate(this.view, this.filterAttentions.date);
        // this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar
        this.updateScheduleView();
        this.composeAttentionsToAgreements();
    }

    disableDay(date) {
        let range = new momentRange.DateRange(this.minDate, this.maxDate);
        date = moment(date)
        return !range.contains(date)
    }

    deleteAttentionsBatch(agreement: Agreement) {

        this.provisionAgreements.push(agreement); // One agreement selected

        this._dialogRefWizardDelete = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: '30%',
            maxHeight: '90vh',
            data: { template: this.deleteWizardAttentions, title: 'Eliminar' }
        });
    }

    clickCancelDeleteWizard() {
        this._dialogRefWizardDelete.close();
    }

    deleteAttentionFromAgreementsGrid(attention: Attention) {
        this.isDeleteModeAttentionFromGridAgreements = true;
        this.onClickDeleteAttention(attention);
    }

    editAttentionFromAgreementsGrid(attention: Attention) {
        this.onEventClickedHandler({ calendarEvent: attention, editMode: 'UPD' });
    }

    closeDialogAttentionEdit() {
        this._dialogRefEditAttention.close();
    }

    /**
     *
     * @returns Max date (in the future) for provisionFee's attentions
     */
    selectLastDate(): Date {
        const lastDate = this.attentions.filter(att => att.provisionFeeId == this.attentionToEdit.provisionFeeId).reduce((maxDate: Date, att: Attention) => {
            const attentionMoment = moment(att.fromDate);
            const maxFechaMoment = moment(maxDate);

            return attentionMoment.isAfter(maxFechaMoment) ? att.fromDate : maxDate;
        }, new Date(0));

        return lastDate;
    }

    // Click on attention for edit
    eventClicked(_event, event?: CalendarEvent) {
        if (event != null) {
            this.onEventClickedHandler({ calendarEvent: event.meta.attention, editMode: 'DSP' });
        } else {
            this.onEventClickedHandler({ calendarEvent: _event.meta.attention, editMode: 'DSP' });
        }
    }

    eventClickedX(event) {
        console.log(event);
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        if (action === 'Edit') {
            //   this.onEventClicked.emit(event.meta.attention);
            console.log("Edit event")
        } else if (action === 'Deleted') {
            // this.onEventDelete.emit(event.meta.attention);
            console.log("Delete event")
        }
    }

    overlapAdviceText(attention: Attention) {
        let overlapedAttention: AttentionOverlap;
        overlapedAttention = this.attentionsWithOverlap.find(att => att.id == attention.id && att.hasOverlap ? att : null)
        const text = this.attentionsService.overlapText(overlapedAttention);
        return text;
    }

    updateScheduleView() {
        this.viewDateX = this.viewDate;

        const actualMonth = moment(this.viewDate).month() + 1;
        const previousMonth = moment(this.previousViewDate).month() + 1;

        if (actualMonth != previousMonth) {
            this.previousViewDate = this.viewDate;
        }
    }

    getRangeToFilterAttentions() {

        switch (this.view) {
            case CalendarView.Month:
                this.filterAttentions = {
                    date: moment(this.viewDate).startOf('M').toDate(),
                    range: new momentRange.DateRange(moment(this.viewDate), moment(moment(this.viewDate).endOf('M').toDate()))
                }
                break;
            case CalendarView.Week:
                this.filterAttentions = {
                    date: this.viewDate,
                    range: new momentRange.DateRange(moment(this.viewDate), moment(moment(this.viewDate).endOf('W').toDate()))
                }
                break;

            case CalendarView.Day:
                this.filterAttentions = {
                    date: this.viewDate,
                    range: new momentRange.DateRange(moment(this.viewDate), moment(this.viewDate).endOf('D').toDate())
                }
                break;
            default:
                break;
        }
    }

    copyAttentionTo(attention: Attention) {
        if (!!this.datesToPostSelected && this.datesToPostSelected.length > 0) {
            const hours = moment.duration(moment(attention.toDate).diff(attention.fromDate)).hours();
            const minutes = moment.duration(moment(attention.toDate).diff(attention.fromDate)).minutes();
            this.onCreateAttentionV2(true, 'CPY', attention.fromDate, hours, minutes, attention.state.id)
        }
    }

    setButtonsToCalendarNavigate(view: CalendarView, date?: Date) {
        // Buttons are set with intersection between practice and first/last attention day loaded
        const rangeAtt = new momentRange.DateRange(this.rangeToNavigate.historyFromDate, moment(moment(this.rangeToNavigate.historyToDate).format('YYYY-MM-DD')).toDate());
        const rangePractice = new momentRange.DateRange(this.provisionFeeSelected.fromDate, this.provisionFeeSelected.toDate);

        console.log("Range to navigate: ", rangePractice.intersect(rangeAtt));

        const intersect = rangePractice.intersect(rangeAtt);

        this.buttonsNewDateScheduleNavigator = this.generalService.controlCalendarView(intersect.start.toDate(), intersect.end.toDate(), view, date); // Navigate by attentions prev/next
        this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar;
        this.updateScheduleView();
        this.getRangeToFilterAttentions();
    }

    // Set to Agendar calendar
    setViewSelectedCalendarCase(event) {
        this.viewSelectedCalendarCase = event
    }

    replaceAgreementAttentionFromAgreementsGrid(attention: Attention) {
        this.onEventClickedHandler({ calendarEvent: attention, editMode: 'RPA' });
    }
}
