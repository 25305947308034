import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { UtilsModule } from 'src/app/shared/components/utils/utils.module';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { FilterComponent } from './components/filter/filter.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FormsModule } from 'src/app/shared/components/forms/forms.module';
import { AttentionsListComponent } from './components/attentions-list/attentions-list.component';
import { ConsolidationComponent } from './pages/consolidation/consolidation.component';
import { ScheduleDialogTemplateComponent } from './components/schedule-dialog-template/schedule-dialog-template.component';
import { ProvisionalControlComponent } from './pages/provisional-control/provisional-control.component';
import { CaseDetailComponent } from './components/case-detail/case-detail.component';
import { CardTableModule } from 'src/app/shared/components/card-table/card-table.module';
import { PatientSelectorComponent } from './components/patient-selector/patient-selector.component';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CustomCalendarModule } from 'src/app/shared/components/calendar/calendar.module';


@NgModule({
    declarations: [
        ScheduleComponent,
        CalendarComponent,
        AttentionsListComponent,
        ProvisionalControlComponent,
        FilterComponent,
        AttentionsListComponent,
        ConsolidationComponent,
        ScheduleDialogTemplateComponent,
        ProvisionalControlComponent,
        CaseDetailComponent,
        PatientSelectorComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        UtilsModule,
        ScheduleRoutingModule,
        CardTableModule,
        PipesModule,
        FormsModule,
        CustomCalendarModule
    ],
    exports: [CalendarComponent],
})
export class ScheduleModule { }
