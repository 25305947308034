import { Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class MyDateAdapter extends MomentDateAdapter {

    constructor(@Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
        console.log('MyDateAdapter is being used');
    }

    override getFirstDayOfWeek(): number {
        return 1; // Set first day MONDAY
    }

    override format(date: moment.Moment, displayFormat: string): string {
        console.log('displayFormat:', displayFormat);
        if (!date.isValid()) {
            return '';
        }
        if (typeof displayFormat !== 'string') {
            // Handling the case where displayFormat is not a string
            console.error('displayFormat is not a string:', displayFormat);
            return date.format('DD/MM/YYYY'); // Default format as a fallback
        }
        return date.format(displayFormat || 'DD/MM/YYYY');
    }

    override parse(value: any, parseFormat: any): moment.Moment | null {
        const currentYear = moment().year();
        const currentMonth = (moment().month() + 1).toString().padStart(2, '0'); // month() devuelve 0-11

        if (typeof value === 'string') {
            let day, month, formattedValue;

            // Caso de entrada de solo día (1 o 2 dígitos)
            if (value.length === 1 || value.length === 2) {
                day = value.padStart(2, '0'); // Asegura que el día tenga dos dígitos
                formattedValue = `${day}/${currentMonth}/${currentYear}`;
                return moment(formattedValue, 'DD/MM/YYYY', true);
            }

            // Caso de entrada de día y mes (4 dígitos)
            if (value.length === 4) {
                day = value.slice(0, 2);
                month = value.slice(2, 4);
                formattedValue = `${day}/${month}/${currentYear}`;
                return moment(formattedValue, 'DD/MM/YYYY', true);
            }
        }

        return super.parse(value, parseFormat || 'DD/MM/YYYY');
    }
}
