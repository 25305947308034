import { Component, OnInit, Input, EventEmitter, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import { Tag } from 'src/app/shared/models/tags';

@Component({
    selector: 'app-multi-select-input',
    templateUrl: './multi-select-input.component.html',
    styleUrls: ['./multi-select-input.component.scss']
})
export class MultiSelectInputComponent extends FormElementComponent implements OnInit {
    @Input() elements!: Array<any>;
    @Input() defaultValue!: string;
    @Input() spinner = false;
    // Input provided that indicates if the values of the mat-options will be computed as strings or numbers
    @Input() forceNumber!: string;
    // Functions provided by the parent that tells the component how to calculate the id and name of the options
    @Input() calculateId: (element: { id: string }) => string = (element: any) => { return element ? element.id : null; };
    @Input() calculateName: (element: { name: string }) => string = (element: any) => { return element ? element.name : null; };
    @Input() multiple!: boolean; // Undefined by default

    // Emit an event every time the select changes
    @Output() public selected: EventEmitter<any> = new EventEmitter();
    @Input() valuesSelected!: Array<{ id: string, name: string } | Tag>;

    constructor() {
        super();
    }

    // Static function that returns the appropriate function according to the "calculate" parameter
    static proxyCalculate(calculate: any): (element: any) => string {
        if (typeof calculate === 'string') {
            return element => {
                return element ? element[String(calculate)] : null;
            };
        } else {
            return calculate;
        }
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Id
    proxyCalculateId(): (element: any) => string {
        return MultiSelectInputComponent.proxyCalculate(this.calculateId);
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Name
    proxyCalculateName(): (element: any) => string {
        return MultiSelectInputComponent.proxyCalculate(this.calculateName);
    }

    override ngOnInit() {
        if (this.forceNumber) {
            this.form.get(this.name)?.valueChanges.subscribe(value => {
                if (typeof value === 'string') {
                    this.form.get(this.name)?.setValue(Number(value));
                }
            });
        }

        // Si valuesSelected está definido, establece el valor en el FormControl
        if (this.valuesSelected) {
            const initialValues = this.valuesSelected.map(value => value.id);
            this.form.get(this.name)?.setValue(initialValues);
            this.form.get(this.name)?.updateValueAndValidity();
        }
    }

    calculateSelected(id: string): string {
        let elementSelected = null;
        if (this.elements) {
            elementSelected = this.elements.find(
                element =>
                    this.proxyCalculateId()(element) ===
                    (this.forceNumber ? Number(id) : id)
            );
        }
        return elementSelected;
    }

    change(event: any) {
        console.log('Valor seleccionado:', event.value);

        // Actualizamos valuesSelected con los elementos que coinciden con los valores seleccionados
        this.valuesSelected = this.elements.filter(element => event.value.includes(element.id));
        console.log('Valores seleccionados después del cambio:', this.valuesSelected);

        // Actualizamos el FormControl
        this.form.get(this.name)?.setValue(event.value);
        this.form.get(this.name)?.updateValueAndValidity();
        console.log('Valor del FormControl:', this.form.get(this.name)?.value);

        // Emitimos los valores seleccionados al componente padre
        this.selected.emit(this.valuesSelected);
    }


    removeElement(element: any) {
        this.valuesSelected = this.valuesSelected.filter(value => value.id != element.id);

        // Actualizamos el FormControl para que refleje los cambios
        const updatedValues = this.valuesSelected.map(value => value.id);
        this.form.get(this.name)?.setValue(updatedValues);
        this.form.get(this.name)?.updateValueAndValidity();

        // Emitimos los valores seleccionados actualizados
        this.selected.emit(this.valuesSelected);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['valuesSelected']) {
            const updatedValues = this.valuesSelected?.map(value => value.id);
            this.form.get(this.name)?.setValue(updatedValues);
            this.form.get(this.name)?.updateValueAndValidity();
        }
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
