<div class="flex-column">
    <div class="flex-column bg-primary-lighter p-1">
        <span class="text-primary-darker-h3 ml-1 mb-0">{{this.data.title}}</span>
    </div>
    <div class="flex-column p-24">
        <form *ngIf="form && !this.loadingReasons else loadingSpinner" class="flex-100" [formGroup]="form"
            class="flex-column">
            <div class="flex-45-gt-sm flex-column">
                <!-- TODO: Cambiar por searching -->
                <!-- Select reasons -->
                <app-select-input class="flex-100" [name]="this.REASON" [form]="this.form"
                    [placeholder]="this.reasonPlaceHolder" [elements]="elementsReasons"
                    [calculateId]="this.calculateIdReason" [calculateName]="this.calculateNameReason">
                </app-select-input>
                <app-text-area-input class="flex-100" [name]="this.OBSERVATIONS" [form]="this.form"
                    [placeholder]="'Observaciones'" [maxLength]="120">
                </app-text-area-input>
                <app-date-picker *ngIf="this.data.action !== this.ACTION.REOPEN" [name]="this.CHANGING_STATE_DATE"
                    [form]="this.form" [placeholder]="this.datePickerPlaceHolder">
                </app-date-picker>
            </div>
            <div class="mts-2 flex-row align-space-around-start">
                <button mat-stroked-button [color]="'primary'" (click)="onClickCloseDialog()">
                    CANCELAR
                </button>
                <button mat-flat-button [color]="'primary'" [disabled]="!this.form.valid"
                    (click)="onConfirmAction(this.confirmQuestion,this.confirmButtonText,this.successMessage)">
                    {{this.confirmButtonText}}
                </button>
            </div>
        </form>

        <ng-template #loadingSpinner>
            <div class="flex-100 align-center-center">
                <mat-spinner class="mts-4" color="primary" diameter="40">
                </mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
