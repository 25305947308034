<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg" [svgIcon]="leftIconSvg"></mat-icon>
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            <b>{{ label }}</b>
        </mat-label>
        <mat-hint>
            {{ this.hint }}
        </mat-hint>
        <mat-select [(value)]="selectedValue" [formControlName]="name" id="{{ name }}" placeholder="{{ placeholder }}" [disabled]="disabled" (selectionChange)="change($event)">
            <ngx-mat-select-search [placeholderLabel]="placeholderSearching" [searching]="searchingSpinner" [noEntriesFoundLabel]="placeholderNoValue" [formControlName]="nameSearching"></ngx-mat-select-search>
            <ng-container *ngIf="!!defaultOptions">
                <ng-container *ngFor="let option of defaultOptions">
                    <mat-option class="text-primary" value="{{ option.id }}">
                        <mat-icon *ngIf="selectedValue !== option.id">add</mat-icon>{{ option.name }}</mat-option>
                </ng-container>
            </ng-container>

            <mat-option *ngIf="this.noOptionText"><b>-- {{ noOptionText }} --</b></mat-option>
            <mat-option [disabled]="element && element.disabled" *ngFor="let element of filteredValues" value="{{ proxyCalculateId()(element) }}" [matTooltip]="proxyCalculateName()(element)">
                {{ proxyCalculateName()(element) }}
            </mat-option>
            <mat-option *ngIf="!filteredValues" disabled>
                Comience su búsqueda...
            </mat-option>
            <mat-option *ngIf="filteredValues && filteredValues.length < 1" disabled>
                Sin resultados
            </mat-option>
            <!-- Para que cuando no se encuentre la busqueda quede seleccionado el elemento previo, sino se resetea
         el select, pero no el form, y queda inconsistente -->
            <mat-option *ngIf="filteredValues && filteredValues.length < 1" value="{{ proxyCalculateId()(this.calculateSelected(this.form.get(this.name).value)) }}" hidden>
                {{ proxyCalculateName()(this.calculateSelected(this.form.get(this.name).value)) }}
            </mat-option>
        </mat-select>
        <button tabIndex="-1" [disabled]="!this.form.get(this.name).value || this.form.get(this.name).disabled" type="button" mat-button *ngIf="this.clearable" matSuffix mat-icon-button aria-label="Clear" (click)="this.clearSelection($event)">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf=" this.form.get(this.name).errors && (this.form.get(this.name).dirty || this.form.get(this.name).touched) ">
            <b>
                {{ objectValues(this.form.get(this.name).errors)[0] }}
            </b>
        </mat-error>
    </mat-form-field>
</div>
