import { Inject, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';


@Component({
    selector: 'app-dialog-image-detail',
    templateUrl: './dialog-image-detail.component.html',
    styleUrls: ['./dialog-image-detail.component.scss']
})
export class DialogImageDetailComponent implements OnInit {

    photo64: any;
    rotationAmount = 0;

    constructor(
        public fileUtilitiesService: FileUtilitiesService,
        private _dialogRef: MatDialogRef<DialogImageDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cd: ChangeDetectorRef,

    ) {
        this.photo64 = data.image;
    }

    ngOnInit(): void {
    }

    onCloseClick() {
        this._dialogRef.close();
    }

    rotate(direction) {
        this.rotationAmount += direction == 'left' ? -90 : 90;
    }

}
