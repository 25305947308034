<form class="flex-100" [formGroup]="filtersForm" style="width:350px;">
    <div class="flex-row p-2 my-1" style="border-bottom:1px solid lightgrey; font-size: smal;">
        <span class="flex-80" style="line-height: inherit;">Filtros
        </span>
        <mat-icon class="flex-1-1-auto" [color]="'primary'" style="line-height: 0.8" (click)="setFilterVisible()">
            {{ filterVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
        </mat-icon>
    </div>
    @if(this.loadingFilters){
        <mat-progress-bar [mode]="'indeterminate'" [value]="100"></mat-progress-bar>
    }
    <div class="flex-column" [@detailExpand]="filterVisible ? 'expanded' : 'collapsed'">
        <div class="flex-column gap-0-5em" style="border-bottom:2px solid lightgrey;">
            <app-checkbox-input class="subtitle-3 text-primary-darker" [form]="filtersForm"
                [label]="'Incluir domicilio de prestadores'" [name]="this.INCLUDE_PROVIDERS"
                [disabled]="!this.enableFilters">
            </app-checkbox-input>
        </div>
            <app-checkbox-input class="subtitle-3 text-primary-darker" [form]="filtersForm"
                [label]="'Incluir pacientes activos'" [name]="this.INCLUDE_ACTIVE_PATIENTS"
                [disabled]="!this.enableFilters">
            </app-checkbox-input>
            <app-checkbox-input class="subtitle-3 text-primary-darker" [form]="filtersForm"
                [label]="'Incluir pacientes inactivos'" [name]="this.INCLUDE_INACTIVE_PATIENTS"
                [disabled]="!this.enableFilters"style="margin-bottom: 10px !important;">
            </app-checkbox-input>
            <div>
                <span class="flex subtitle-3 text-primary-darker mbs-1">Fecha inicio de acuerdo:</span>
                <app-select-input [elements]="fromDateList" [name]="this.FROMDATE" [form]="this.filtersForm"
                    [placeholder]="this.fromDateList[2].description" [calculateId]="calculateIdFromDate"
                    [calculateName]="calculateNameFromDate" [defaultValue]="this.fromDateList[2].value"
                    (selected)="onFilterFromDate($event)" [disabled]="!enableFilters">
                </app-select-input>
            </div>

    </div>
</form>
