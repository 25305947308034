<div class="flex-column">
    <ng-container *ngIf="this.data.modifyEvolution; else modifyEvolution">
        <div class="flex-column bg-primary-lighter p-1">
            <span class="text-primary-darker-h3 ml-1 mb-0">Gestionar evolución</span>
        </div>
    </ng-container>
    <ng-template #modifyEvolution>
        <div class="flex-column bg-primary-lighter p-1">
            <span class="text-primary-darker-h3 ml-1 mb-0">Finalizar tarea</span>
        </div>
    </ng-template>
    <div class="flex-column p-24">
        <span *ngIf="!this.data.modifyEvolution" class="text-secondary">Atención
            #{{ this.data.task.evolution.attentionId }}</span>
        <div class="flex-100 separator separator-center mts-1">
            <ng-container *ngIf="this.data.modifyEvolution; else modifyEvolution2">
                <span class="mx-2 text-primary-darker">
                    Evolución a gestionar
                </span>
            </ng-container>
            <ng-template #modifyEvolution2>
                <span class="mx-2 text-primary-darker">
                    Visibilidad de la evolución
                </span>
            </ng-template>
        </div>
        <!-- Paso #1 -> Marque quien puede visualizar la evolución -->
        <div class="flex-row align-start-center">
            <mat-icon class="mr-1 text-primary align-start-center">assignment_ind</mat-icon>
            <span class="text-secondary-darker">{{
                this.entitiesFacade.getProvidersFullName(this.data.task.evolution.provider) }}</span>
            <span class="text-secondary-darker ml-1">
                - {{ this.data.task.evolution.category.description }}</span>
            <ng-container *ngIf="!!this.data.task?.evolution?.media?.mediaUrls">
                <ng-container *ngFor="let mediaUrl of this.data.task.evolution.media.mediaUrls">
                    <button mat-icon-button (click)="openDialogImage(mediaUrl)" *ngIf="!!mediaUrl">
                        <mat-icon [color]="'primary'">photo</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
        </div>
        <div class="multi_lines_text mls-5 text-secondary" style="max-height: 100px; overflow: auto;">
            {{ this.data.task.evolution.text }}
        </div>
        <div *ngIf="!data.task.evolution.practice || data.task.evolution.practice.id.toString() != this.PRACTICE_QUALITY_CONTROL_ID.toString()"
            class="mts-1 flex-row flex-wrap align-center-center gap-2rem">
            <button mat-button (click)="financierVisibility = !financierVisibility" class="align-center-center">
                <mat-icon class="text-primary mat-icon-medium" *ngIf="this.financierVisibility">visibility</mat-icon>
                <mat-icon class="text-secondary mat-icon-medium"
                    *ngIf="!this.financierVisibility">visibility_off</mat-icon>
                {{ this.data.task.evolution.financier.name }}
            </button>
            <button mat-button (click)="providersVisibility = !providersVisibility">
                <mat-icon class="text-primary mat-icon-medium" *ngIf="this.providersVisibility">visibility</mat-icon>
                <mat-icon class="text-secondary mat-icon-medium"
                    *ngIf="!this.providersVisibility">visibility_off</mat-icon>
                Prestadores
            </button>
        </div>
        <!-- FIN Paso #1 -->
        <!-- Paso #2 -> Indique en qué estado queda la atención (Sólo evoluciones no normales) -->
        <div class="flex-column"
            *ngIf="this.data.task.evolution.category.id !== EVOLUTIONS_TYPE.NORMAL || this.data.modifyEvolution">
            <div class="flex-100 separator separator-center mts-3">
                <ng-container *ngIf="this.data.modifyEvolution; else modifyEvolution3">
                    <span class="mx-2 text-primary-darker">
                        Nueva evolución
                    </span>
                </ng-container>
                <ng-template #modifyEvolution3>
                    <span class="mx-2 text-primary-darker">
                        Estado final de la atención
                    </span>
                </ng-template>
            </div>

            <div class="flex-column mts-1">
                <form [formGroup]="evolutionForm" class="flex-row flex-wrap align-start-center">
                    <app-text-area-input class="flex-100" [hint]="this.evolutionTextHint"
                        [form]="this.evolutionForm" [name]="this.EVOLUTION_TEXT"
                        [placeholder]="this.evolutionTextPlaceholder">
                    </app-text-area-input>
                </form>
                <div class="mts-0 flex-row align-center-center gap-2rem">
                    <button mat-button (click)="financierVisibilityEvolution = !financierVisibilityEvolution">
                        <mat-icon class="text-primary mat-icon-medium"
                            *ngIf="this.financierVisibilityEvolution">visibility
                        </mat-icon>
                        <mat-icon class="text-secondary mat-icon-medium" *ngIf="!this.financierVisibilityEvolution">
                            visibility_off
                        </mat-icon>
                        {{ this.data.task.evolution.financier.name }}
                    </button>
                    <button mat-button (click)="providersVisibility = !providersVisibility">
                        <mat-icon class="text-primary mat-icon-medium"
                            *ngIf="this.providersVisibility">visibility</mat-icon>
                        <mat-icon class="text-secondary mat-icon-medium"
                            *ngIf="!this.providersVisibility">visibility_off</mat-icon>
                        Prestadores
                    </button>
                </div>
                <div class="mts-5 flex-row align-space-around-center">
                    <button mat-stroked-button [color]="'primary'" (click)="onCancelClick()">CANCELAR</button>

                    <button mat-flat-button [color]="'primary'" [disabled]="this.loadingConfirm || !evolutionForm.valid"
                        (click)="onConfirmClick()">
                        <span *ngIf="!this.loadingConfirm; else loadingConfirmIcon">
                            CONFIRMAR
                        </span>
                        <ng-template #loadingConfirmIcon>
                            <mat-icon>
                                <mat-spinner color="accent" diameter="20">
                                </mat-spinner>
                            </mat-icon>
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
