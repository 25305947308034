import { Injectable } from '@angular/core';
import { FormsQPS } from '../core/services/forms.service';
import { Pagination } from '../shared/models/pagination';
import { from } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequisitionsState } from '../core/states/requisitions.state';
import { PatchRequisitionBody, PostRequisitionBody, RequisitionService, RequisitionsQPS, RequisitionsIndicatorsQPS } from '../core/services/requisition.service';
import { Requisition } from 'src/app/shared/models/requisition';
import { User } from '../shared/models/user';
import { RequisitionIndicator } from '../shared/models/requisition';
import { log } from 'console';

export interface MetaDataRequisitions extends RequisitionsQPS, FormsQPS, Pagination {
    user?: User,
    fromIndicator?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class RequisitionsFacade {

    constructor(
        private requisitionState: RequisitionsState,
        private requisitionService: RequisitionService
    ) { }

    // LOAD
    loadRequisitions(qps?: RequisitionsQPS) {

        this.requisitionState.setLoadingGetRequisitions(true);
        this.requisitionState.setLoadingGettingPagination(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.requisitionService.getRequisitions(qps).pipe(
                tap(body => {
                    this.requisitionState.setMetaDataRequisitions({ ...qps, ...body.pagination }); // Save qps
                }),
                tap((body) => console.log('request finished. Order: ', body, 'Pagination :', body.pagination)),
                finalize(() => {
                    this.requisitionState.setLoadingGetRequisitions(false);
                    this.requisitionState.setLoadingGettingPagination(false);
                })
            ).subscribe({
                next: response => {
                    resolve(response.data);
                    this.requisitionState.setRequisitions(response.data)
                },
                error: e => reject(e)
            })
        })
        return from(promise);
    }

    // ADD - CREATE
    addRequisition(requisition: PostRequisitionBody): Observable<Requisition> {
        this.requisitionState.setLoadingCreatingRequisition(true);
        const promise: Promise<Requisition> = new Promise((res, rej) => {
            this.requisitionService.postRequisition(requisition).pipe(
                finalize(() => this.requisitionState.setLoadingCreatingRequisition(false))
            ).subscribe({
                next:
                    // Add al store
                    requisition => {
                        this.requisitionState.addRequisition(requisition);
                        this.requisitionState.addRequisitionIndicators(requisition);
                        res(requisition)
                    },
                error: e => rej(e)
            })
        })
        return from(promise);
    }

    // UPDATE - PATCH
    updateRequisition(requisition: PatchRequisitionBody): Observable<Requisition> {
        this.requisitionState.setLoadingUpdatingRequisition(true);
        const promise: Promise<Requisition> = new Promise((res, rej) => {
            this.requisitionService.patchRequisition(requisition).pipe(
                finalize(() => this.requisitionState.setLoadingUpdatingRequisition(false))
            ).subscribe({
                next: response => {
                    this.requisitionState.updateRequisition(response, response.id);
                    this.requisitionState.updateRequisitionIndicator(response, requisition.id);
                    res(response);
                },
                error: e => rej(e)
            })
        })
        return from(promise);
    }

    loadRequisitionsIndicators(qps: RequisitionsIndicatorsQPS) {

        this.requisitionState.setLoadingGetRequisitionsIndicators(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.requisitionService.getRequisitionsIndicators(qps).pipe(
                finalize(() => this.requisitionState.setLoadingGetRequisitionsIndicators(false)),
            ).subscribe({
                next: response => {
                    resolve(response.data);
                    this.requisitionState.setRequisitionsToIndicators(response.data);
                },
                error: e => {
                    reject(e)
                },
            })
        })
        return from(promise);
    }

    // STATES FROM STORE
    // Get requisitions
    getRequisitions$(): Observable<Requisition[]> {
        return this.requisitionState.getRequisitions$();
    }

    setRequisitions(req: Requisition[]) {
        this.requisitionState.setRequisitions(req);
    }


    // Forms
    isLoadingGetRequisitions$(): Observable<boolean> {
        return this.requisitionState.isLoadingGetRequisitions$();
    }

    isLoadingCreatingRequisition$(): Observable<boolean> {
        return this.requisitionState.isLoadingCreatingRequisition$();
    }

    isLoadingUpdatingRequisition$(): Observable<boolean> {
        return this.requisitionState.isLoadingUpdatingRequisition$();
    }

    // Metadata
    getMetaDataRequisitions$(): Observable<MetaDataRequisitions> {
        return this.requisitionState.getMetaDataRequisitions$();
    }

    setMetaDataRequisitions(rqp: MetaDataRequisitions) {
        this.requisitionState.setMetaDataRequisitions(rqp);
    }

    // Pagination
    getPagination$(): Observable<Pagination> {
        return this.requisitionState.getPagination$();
    }


    // Indicators
    isLoadingGetRequisitionsIndicators$(): Observable<boolean> {
        return this.requisitionState.isLoadingGetRequisitionsIndicators$();
    }

    getRequisitionsToIndicators$(): Observable<RequisitionIndicator[]> {
        return this.requisitionState.getRequisitionsToIndicators$();
    }
}
