import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { RestUtilitiesService } from './rest-utilities.service';
import { Observable } from 'rxjs';
import { HttpBaseResponse } from '../../shared/models/httpBaseResponse';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { map } from 'rxjs/operators';
import { Tag } from 'src/app/shared/models/tags';

export interface TagsQPS {
  name_like?: any;
  size?: number;
  page?: number;
  active?: boolean;
   
}

export interface TagsCountQPS {
}

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getTags(qps?: TagsQPS): Observable<HttpBaseResponse<Tag[]>> {

      console.log("getTags");

      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
      return this.http
         .get<any>(`${environment.apiFinancierServer}${APIS.TAGS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getTagsCount(qps?: TagsCountQPS): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiFinancierServer}${APIS.TAGS}/count`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data.count;
            }),
         );
   }

   postTags( tag: Tag ): Observable<Tag> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiFinancierServer}${APIS.TAGS}`, JSON.stringify( tag ), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchTag(tagUpdated: Tag ) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiFinancierServer}${APIS.TAG}`,
            JSON.stringify( tagUpdated ),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

}
