<!-- [formGroup]="this.form" -->
<form class="flex-column full-width">
    <!-- Campo de búsqueda de direcciones -->

    <div class="flex-column full-width">
        <span class="subtitle-3 text-primary-darker mbs-1">
            Domicilio
        </span>
        <!-- <app-text-input #addressinput [name]="this.ADDRESS_STREET" [form]="this.form">
        </app-text-input> -->
        <mat-form-field appearance="outline" class="flex-100">
            <!-- <mat-label>Ingrese el nombre, apellido, DNI del paciente o CIF</mat-label>
            <mat-hint>Al buscar por CIF o DNI el mismo debe ser exacto</mat-hint> -->
            <input matInput class="flex-90" placeholder="Búsqueda rápida de domicilio" #addressinput (keyup.enter)="onEnterPress()" [disabled]="this.disabled()"
                (click)="selectAllText()" />
            <!-- <button mat-icon-button matSuffix class="flex-10" style="justify-content: center;" *ngIf="addressinputRef?.nativeElement?.value" (click)="clearAddress()">
                <mat-icon>clear</mat-icon>
            </button> -->
        </mat-form-field>
    </div>

    <!-- Province -->
    <!-- <div class="flex-column">
        <span class="subtitle-3 text-primary-darker mbs-1">
            Provincia *
        </span> -->
    <!-- <app-select-searching-input [name]="this.ADDRESS_STATE" [nameSearching]="this.ADDRESS_STATE_SEARCHING"
            [form]="this.form"
            [calculateName]="this.calculateNameProvince" [calculateId]="this.calculateIdProvince"
            (selected)="this.selectProvince($event)" [elements]="this.provincesOnSelect"
            [defaultValue]="this.actualProvince?.id.toString()"
            [placeholderSearching]="'Ingrese provincia...'">
        </app-select-searching-input> -->
    <!-- </div> -->
    <!-- <div class="flex-row align-stretch-center gap-1em"> -->
    <!-- Location -->
    <!-- <div class="flex-column flex-100">
            <span class="subtitle-3 text-primary-darker mbs-1">
                Localidad *
            </span> -->
    <!-- <app-select-searching-input [name]="this.ADDRESS_LOCATION" [form]="this.form"
                [nameSearching]="this.ADDRESS_LOCATION_SEARCHING" [calculateName]="this.calculateNameLocation"
                [calculateId]="this.calculateIdLocation" [defaultValue]="this.actualLocation?.id.toString()"
                [elements]="this.locations" (selected)="this.selectLocation($event)">
            </app-select-searching-input> -->
    <!-- </div> -->
    <!-- ZipCode -->
    <!-- <div class="flex-column flex-20">
            <span class="subtitle-3 text-primary-darker mbs-1">
                CP
            </span> -->
    <!-- <app-text-input [name]="this.ADDRESS_ZIPCODE" [form]="this.form">
            </app-text-input> -->
    <!-- </div>
    </div> -->
    <!-- Street + Number -->
    <!-- <div class="flex-column full-width">
        <span class="subtitle-3 text-primary-darker mbs-1">
            Domicilio
        </span> -->
    <!-- <app-text-input [name]="this.ADDRESS_STREET" [form]="this.form">
        </app-text-input> -->
    <!-- </div> -->
</form>
