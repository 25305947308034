<div class="flex-column">
    <div class="flex-row align-space-between-center pxs-5">
        <span class="headline-1 pls-4 mbs-5 border-left text-primary-darker-title">
            DASHBOARD
        </span>
        <div class="flex-row align-space-between-center flex-30">
            <app-select-searching-input style="height: 50px !important;" class="flex-45" [name]="this.OPERATOR"
                [nameSearching]="this.OPERATOR_SEARCHING" [form]="this.filterForm" [placeholder]="'Operador'"
                [elements]="(this.operators)" [defaultValue]="this.operator" [calculateId]="this.calculateIdOperator"
                [calculateName]="this.calculateNameOperator" [placeholderNoValue]="'Sin resultados'"
                [placeholderSearching]="'Buscar...'" [noOptionText]="'-- Todos --'">
            </app-select-searching-input>
            <button id="btnOpenCreateAdmission" mat-flat-button [color]="'primary'"
                class="big-button big-button-add-admission" (click)="this.openCreateAdmission()"
                [disabled]="(!this.authenticationService.isFrontDesk() && !this.authenticationService.isAdmin())">
                <span class="flex-100" style="font-size: small;">CREAR ADMISIÓN</span>
            </button>
        </div>
    </div>
    <div class="flex-row align-space-between-none gap-0-7em">
        <!-- Cards -->
        <div class="flex-row align-space-between-none flex-98 pxs-5" style="place-content: stretch space-between;">
            <!-- Indicators - LEFT  -->
            <mat-card class="shadow-lg mat-elevation-z5 flex-30">
                <div class="grid-container" style="height: 100%;">
                    <div class="c1 border-bottom text-secondary align-start-center my-1">
                        <div class="p-4" style="margin: 0.5rem;">
                            <span>ADMISIONES</span>
                        </div>
                    </div>
                    <div class="c2 border-bottom border-right clickable-indicator" (click)="this.stateSelected = 'Todos'">
                        <div class="flex-column align-center-center ">
                            <span class="mat-display-2 label-1 number-font big-number" style="margin: 0;">{{ requisitionsNews }}</span>
                            <span class="text-primary">SOLICITUDES</span>
                        </div>
                    </div>
                    <div class="c3">
                        <div class="flex-row align-center-center">
                            <span class="label-1 number-font small-number mb-0">{{ requisitionsUpdate }}</span>
                            <span class="text-primary ml-2" style="font-size: 12px;">ACTUALIZACIONES</span>
                        </div>
                    </div>
                    <!-- Label & number Nº2 -->
                    <div class="c4">
                        <div (click)="stateSelected = requisitionStates.WITHOUT_WARNING" class="clickable-indicator">
                            <span class="number-font small-number label-1 number-indicator-2">{{ requisitionsWithoutWarning }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2" >SIN AVISO AL F</span>
                        </div>
                        <div (click)="stateSelected = requisitionStates.WAITING_FOR_INFO" class="clickable-indicator">
                            <span class="number-font small-number label-2 number-indicator-2">{{ requisitionsWaitingForInfo }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">ESPERANDO INFO</span>
                        </div>
                        <div (click)="stateSelected = requisitionStates.SEARCHING_RESOURCES" class="clickable-indicator">
                            <span class="number-font small-number label-3 number-indicator-2">{{ requisitionsSearchingResources }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">BUSCANDO REC</span>
                        </div>
                        <div (click)="stateSelected = requisitionStates.CONFIRMED" class="clickable-indicator">
                            <span class="number-font small-number label-4 number-indicator-2">{{ requisitionsConfirmed }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">CONFIRMADO</span>
                        </div>
                        <div (click)="stateSelected = requisitionStates.BUDGETED" class="clickable-indicator">
                            <span class="number-font small-number label-5 number-indicator-2">{{ requisitionsBudgeted }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">PRESUPUESTADO</span>
                        </div>
                    </div>
                </div>
            </mat-card>
            <!-- Indicators - CENTER  -->
            <mat-card class="shadow-lg mat-elevation-z5 flex-30">
                <div class="grid-container" style="height: 100%;">
                    <div class="c1 flex border-bottom text-secondary align-start-center">
                        <div class="p-4" style="margin: 0.5rem;">
                            <span>SOLICITUDES</span>
                        </div>
                    </div>
                    <div class="c22 border-right">
                        <div class="flex-column align-center-center mbs-5">
                            <span class="mat-display-2 label-1 number-font big-number" style="margin: 0;">{{ requisitionsWithoutWarning }}</span>
                            <span class="text-primary">SIN AVISO AL F</span>
                        </div>
                    </div>
                    <div class="c33 border-right">
                        <div class="flex-row align-start-center">
                        </div>
                    </div>
                    <!-- Label & number Nº2 -->
                    <div class="c44">
                        <div>
                            <span class="number-font small-number label-1 number-indicator-2">{{ requisitionsComplexityA }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD A</span>
                        </div>
                        <div>
                            <span class="number-font small-number label-1 number-indicator-2">{{ requisitionsComplexityB }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD B</span>
                        </div>
                        <div>
                            <span class="number-font small-number label-1 number-indicator-2">{{ requisitionsComplexityC }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD C</span>
                        </div>
                    </div>
                </div>
            </mat-card>
            <!-- Indicators - RIGHT  -->
            <mat-card class="shadow-lg mat-elevation-z5 flex-30">
                <div class="grid-container" style="height: 100%;">
                    <div class="c1 border-bottom">
                        <div class="p-4 flex-row align-end-center" style="padding: 0.5rem;">
                            <form [formGroup]="filterFormCardRequisitionsAuthorized" class="flex-row align-end-center">
                                <app-select-input style="height:40px !important;" [name]="this.FRECUENCY_AUTHORIZED"
                                    [form]="this.filterFormCardRequisitionsAuthorized"
                                    [elements]="this.frecuencyPeriod" [defaultValue]="frecuencyPeriod_default"
                                    (selected)="frecuencySelected($event)">
                                </app-select-input>
                            </form>
                        </div>
                    </div>
                    <div class="c222 border-right">
                        <div class="flex-column align-center-center mbs-2">
                            <span class="mat-display-2 label-2 number-font big-number" style="margin: 0;">{{ requisitionsAuthorized }}</span>
                            <span class="label-2">AUTORIZADAS</span>
                        </div>
                    </div>
                    <div class="c333 border-right">
                        <div class="flex-row align-center-center">
                            <span class="text-primary number-font small-number number-indicator-2">{{ totals }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">TOTALES</span>
                        </div>
                    </div>
                    <!-- Label & number Nº2 -->
                    <div class="c444">
                        <div>
                            <span class="number-font small-number label-2 number-indicator-2">{{ requisitionsAuthorizedComplexityA }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD A</span>
                        </div>
                        <div>
                            <span class="number-font small-number label-2 number-indicator-2">{{ requisitionsAuthorizedComplexityB }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD B</span>
                        </div>
                        <div>
                            <span class="number-font small-number label-2 number-indicator-2">{{ requisitionsAuthorizedComplexityC }}</span>
                            <span class="text-primary-darker subtitle-5 label-indicator-2">COMPLEJIDAD C</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="mb-1"></div>
    <!-- Admissions table list -->
    <div class="pls-5">
        <app-admissions-list [requisitions]="requisitions" [loading]="loading" [columns]="columnsToShow"
            [operator]="operator" [indicatorSelected]="stateSelected"></app-admissions-list>
    </div>
</div>
