import { BehaviorSubject } from 'rxjs';

export interface IUpdate<Data> {
    data: Data,
    dataId?: string | number,
    storeRefAttribute: BehaviorSubject<any>,
}

export interface IUpdateBatch<Data>{
    data: any[],
    storeRefAttribute: BehaviorSubject<any>
}
export interface IAdd<Data> {
    data: Data,
    storeRefAttribute: BehaviorSubject<any>,
}
export interface IDelete<Data> {
    dataId?: string | number,
    storeRefAttribute: BehaviorSubject<any>,
}

export type Store = {
    [propName: string]: BehaviorSubject<any>
}
export class BaseState {
    protected store: Store;

    constructor() {
    }

    cleanStore: () => void = () => {
        const properties = Object.entries(this.store);
        setTimeout(() => {
            console.log('cleanStore');
            properties.forEach(([propertie, _]) => {
                this.store[propertie].next(null);
            })
        }, 500);
    };

    update<Data>(obj: IUpdate<Data>) {
        const propertyStore = obj.storeRefAttribute.getValue();
        if (Array.isArray(propertyStore)) {
            let indexData = propertyStore.findIndex(value => value.id == obj.dataId);
            if (indexData != -1) {
                propertyStore[indexData] = { ...propertyStore[indexData], ...obj.data };
                obj.storeRefAttribute.next(propertyStore);
                console.info('Updating store (update): ', propertyStore);
            } else {
                console.error('Updating store (update): the resource to update was not found');
            }
        } else {
            obj.storeRefAttribute.next({ ...propertyStore, ...obj.data });
            console.info('Updating store (update): ', { ...propertyStore, ...obj.data });
        }
    }

    add<Data>(obj: IAdd<Data>) {
        const propertyStore = obj.storeRefAttribute.getValue();
        if (Array.isArray(propertyStore)) {
            propertyStore.push(obj.data);
            obj.storeRefAttribute.next(propertyStore);
        }
        // !ELSE?
    }

    delete<Data>(obj : IDelete<Data>){
        let propertyStore = obj.storeRefAttribute.getValue();
        if (Array.isArray(propertyStore)) {
            let indexData = propertyStore.findIndex(value => value.id == obj.dataId);
            if (indexData != -1) {
                propertyStore = [ ...propertyStore.slice(0, indexData), ...propertyStore.slice(indexData + 1, propertyStore.length) ];
                console.info('Updating store (delete): ', propertyStore);
                obj.storeRefAttribute.next(propertyStore);
            } else {
                console.error('Updating store (delete): the resource to delete was not found');
            }
        }
    }

    updateBatch<Data>(obj: IUpdateBatch<Data>){
        const propertyStore = obj.storeRefAttribute.getValue().map(a => {return{...a}});
        if (Array.isArray(propertyStore)) {
            for(let data of obj.data){
                let indexData = propertyStore.findIndex(val => val.id == data.id);
                if(indexData != -1){
                    propertyStore[indexData] = { ...propertyStore[indexData], ...data };
                }
            }
            obj.storeRefAttribute.next(propertyStore);
        } else {
            obj.storeRefAttribute.next({ ...propertyStore, ...obj.data });
            console.info('Updating store (update): ', { ...propertyStore, ...obj.data });
        }
    }
}
