import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../core/services/general.service';
import { ROUTES } from '../../../../core/enums/routes';
import { Requisition } from '../../../../shared/models/requisition';
import { PostPatientBody, PostCaseBody, CasesService } from '../../../../core/services/cases.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { RequisitionsFacade } from 'src/app/abstraction/requisitions.facade';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { PostEvolutionBody } from '../../../../core/services/evolutions.service';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { EntitiesFacade } from '../../../../abstraction/entities.facade';
import { PostProvisionBody } from 'src/app/core/services/provisions.service';
import { Arrangement } from 'src/app/shared/models/arrangement';
import { PostRequisitionBody } from 'src/app/core/services/requisition.service';

@Component({
    selector: 'app-admission-creation',
    templateUrl: './admission-creation.component.html',
    styleUrls: ['./admission-creation.component.scss']
})
export class AdmissionCreationComponent implements OnInit {

    step: number = 1;
    labelButtonNext: string = 'CONFIRMAR DATOS';
    btn_next_disabled: boolean = false;

    admission: PostRequisitionBody;
    postPatientBody: PostPatientBody;
    postCaseBody: PostCaseBody;
    postProvisionBodyPractices: PostProvisionBody[] = [];
    postProvisionBodySupplies: PostProvisionBody[] = [];

    formPatienIsValid: boolean;
    formCaseIsValid: boolean;
    formProvisionsIsValid: boolean;
    formSuppliesIsValid: boolean;
    formCaseManageCaseIsValid: boolean;

    isLoadingCreatingRequisition$: Observable<boolean>;
    postRequisition: PostRequisitionBody = <PostRequisitionBody>{};

    arrangementSelected: Arrangement;

    constructor(private generalService: GeneralService,
                private requisitionsFacade: RequisitionsFacade,
                public router: Router,
                private nomenclatorFacade: NomenclatorFacade,
                private casesFacade: CasesFacade,
                private entitiesFacade: EntitiesFacade,
                private casesService : CasesService
    ) {
    }

    ngOnInit(): void {
        this.isLoadingCreatingRequisition$ = this.requisitionsFacade.isLoadingCreatingRequisition$();
        this.entitiesFacade.loadFinanciers( {active:true} ); // For case - STEP 1
        this.casesFacade.loadTaxZones({ all:true }); // For case - STEP 1
        this.nomenclatorFacade.loadPractices({ active: true }); // For provisions and supplies - STEP 2
        this.controlStep();
    }

    assistantPrevious() {
        this.step--;
        this.controlStep();
    }

    assistantNext() {
        if (this.step==3 && !this.btn_next_disabled) { // Step 3 and button enabled -> click create requisition
            this.createRequisition()
            this.btn_next_disabled = true;
        } else {
            this.step = this.step != 3 ? this.step + 1 : this.step;
            this.btn_next_disabled = true;
            this.controlStep();
        }
    }

    controlStep() {
        switch (this.step) {
            case 1:

                this.labelButtonNext = 'CONFIRMAR DATOS'
                // this.generalService.setGoBack({ route: this.generalService.checkRouteGoToAdmissions( this.router.url ), title: 'Dashboard' });

                this.btn_next_disabled = !(this.formPatienIsValid && this.formCaseIsValid);

                break;

            case 2:
                this.labelButtonNext = 'CONFIRMAR'
                this.btn_next_disabled = !((this.formProvisionsIsValid || this.formSuppliesIsValid) && (this.postProvisionBodyPractices?.length > 0 || this.postProvisionBodySupplies?.length > 0));
                break;

            case 3:

                this.labelButtonNext = 'CREAR ADMISION'
                this.btn_next_disabled = !this.formCaseManageCaseIsValid;

                // Create Requisition object
                if (!this.btn_next_disabled) {

                    this.postCaseBody.patient = this.postPatientBody;
                    this.postCaseBody.provisions = [ ...this.postProvisionBodyPractices, ...this.postProvisionBodySupplies];
                    this.postRequisition.casee = this.postCaseBody;
                    this.postRequisition.type = 'solicitud';

                    console.log("Requisition assembled: ", this.postRequisition);
                }

                break;

            default:
                // Nothing
                break;
        }
    }

    // Data patient
    patientData( patientData: PostPatientBody ) {
        console.log("Datos del paciente: ", patientData);
        this.postPatientBody = patientData;
    }

    // Data case
    caseData( caseData: PostCaseBody ) {
        console.log("Datos del caso: ", caseData);
        this.postCaseBody = caseData;
    }

    arrangementsData( arrangementData : Arrangement){
        console.log("Datos de arrangement: ", arrangementData);
        this.arrangementSelected = arrangementData;
    }

    // Orders - Data provisions
    provisionsData( provisionsData: PostProvisionBody[] ) {
        console.log("Datos de las provisions/prestaciones: ", provisionsData);
        this.postProvisionBodyPractices = provisionsData
    }

    // Orders - Data supplies
    suppliesData( suppliesData: PostProvisionBody[] ) {
        console.log("Datos de los insumos: ", suppliesData);
        this.postProvisionBodySupplies = suppliesData;
    }

    // Data case management
    caseManagementData( caseManagementData ) {
        console.log("Datos de la administración del caso: ", caseManagementData);
        delete caseManagementData.operatorSearching;
        this.postCaseBody = { ...this.postCaseBody, ...caseManagementData};
    }

    formPatientDataValid( event:boolean ) {
        console.log("FORM paciente: ", event);
        this.formPatienIsValid = event;
        this.controlStep();
    }

    formCaseDataValid( event:boolean ) {
        console.log("FORM caso: ", event);
        this.formCaseIsValid = event;
        this.controlStep();
    }

    formProvisionsDataValid( event:boolean ) {
        this.formProvisionsIsValid = event;
        this.controlStep();
    }

    formSuppliesDataValid( event:boolean ) {
        this.formSuppliesIsValid = event;
        this.controlStep();
    }

    formCaseManagementCaseValid( event:boolean ) {
        this.formCaseManageCaseIsValid = event;
        this.controlStep();
    }

    createRequisition() {

        if (!!this.postRequisition.casee.observation){
            const evolution : PostEvolutionBody = {
                date: new Date(),
                text: this.postRequisition.casee.observation,
                type: 20,
                visibleFinancier: false,
                visibleProvider: false,
                mediaUrls: []
            }
            this.postRequisition.evolution = evolution;
        }

        const { observation, ...caseRequisition } = this.postRequisition.casee; // Delete observation
        this.postRequisition.casee = caseRequisition;

        if (!!this.arrangementSelected){
            delete this.arrangementSelected.arrangementId;
            this.postRequisition.casee.arrangementId = this.arrangementSelected.id;
            this.postRequisition.casee.arrangement = this.arrangementSelected;
            this.postRequisition.casee.provisions.forEach(prov => {
                prov = { ...prov, arrangement: {
                        id: this.arrangementSelected.id,
                        name: this.arrangementSelected.name
                    }
                }
            })
        }
        console.log('Requisition: ', this.postRequisition);

        (this.requisitionsFacade.addRequisition( this.postRequisition ) as Observable<Requisition>).subscribe( requisition => {
            this.router.navigate([ROUTES.ADMISSIONS_LIST]);

          const swalWithCustomizeButtons = Swal.mixin({
            customClass: {
            confirmButton: 'btnSwalConfirm',
            cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
          })

          swalWithCustomizeButtons.fire({
            title: 'Caso creado correctamente',
            icon: 'success',
          });
        });
    }
}
