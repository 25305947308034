<!-- max-height: 80vh -->
<div class="flex-100 flex-column" style="overflow: hidden;">
    <div class="flex-column flex">
        <div class="flex-column header-filters">
            <div class="flex-row align-center-center">
                <!-- Select Listado / Calendario -->
                <div class="flex-20 ml-2">
                    <mat-form-field class="flex-100" style="height: 40px !important;" appearance="outline">
                        <mat-select id="cmb_schedule" [(value)]="this.optionSelected" (selectionChange)="this.selectOption($event)" class="subtitle-2">
                            <mat-option value="listado">
                                Listado
                            </mat-option>
                            <mat-option value="calendario">
                                Calendario
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Select Mes / Semana / Día -->
                <div class="flex-13 mx-3">
                    <mat-form-field class="flex-100" style="height: 40px !important;" appearance="outline">
                        <mat-select id="cmb_period_view" [(value)]="this.view" (selectionChange)="this.selectView($event)" class="subtitle-2">
                            <mat-option value="{{CalendarView.Month}}">
                                Mes
                            </mat-option>
                            <mat-option value="{{CalendarView.Week}}">
                                Semana
                            </mat-option>
                            <mat-option value="{{CalendarView.Day}}">
                                Día
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex">
                    <!-- Arrow previous -->
                    <button id="btn_previous" mat-icon-button id="arrowLeftButton" mwlCalendarPreviousView [view]="view" (click)="clickonCalendarPreviousNextView()"
                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" [color]="'primary'" [disabled]="buttonsNewDateScheduleNavigator?.buttonLeftDisabled">
                        <mat-icon [ngClass]="{'text-secondary-darker':!buttonsNewDateScheduleNavigator?.buttonLeftDisabled, 'text-secondary-lighter':buttonsNewDateScheduleNavigator?.buttonLeftDisabled }">keyboard_arrow_left</mat-icon>
                    </button>
                    <!-- Info -->
                    <div class="" style="align-self: center;">
                        <span class="subtitle-2 text-secondary-darker">
                            {{ (viewDate | calendarDate:(view + 'ViewTitle'):'es-AR': 1)  | titlecase }}
                        </span>
                    </div>
                    <!-- Arrow next -->
                    <button id="btn_next" mat-icon-button id="arrowRightButton" mwlCalendarNextView [view]="view" (click)="clickonCalendarPreviousNextView()"
                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" [color]="'primary'" [disabled]="buttonsNewDateScheduleNavigator?.buttonRightDisabled">
                        <mat-icon [ngClass]="{'text-secondary-darker':!buttonsNewDateScheduleNavigator?.buttonRightDisabled, 'text-secondary-lighter':buttonsNewDateScheduleNavigator?.buttonRightDisabled }">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
                <!-- Button right Agendar -->
                <div class="flex-row align-end-center gap-1em">
                    <button id="btn_add_attention" mat-icon-button [matTooltip]="'Agendar'" [color]="'primary'" (click)="createAttention()">
                        <mat-icon class="mat-icon-22x22" [svgIcon]="'Add-attention-enabled-primary-22x22'"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <ng-template #customTemplate let-day="day" let-locale="locale">
            <!-- cal-cell-day -->
            <div *ngIf="day.events" class="flex-100 align-space-between-center flex-column">
                <div class="cal-cell-top flex-row align-center-center">
                    <span class="flex-52 align-end-center cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                    <div class="flex-48 align-end-center">
                        @if(day.badgeTotal > 0) { <span class="cal-day-badge bg-primary-lighter text-primary" (click)="badgeClicked(day)">{{ day.badgeTotal }}</span> }
                    </div>
                </div>
                <div class="flex-column flex-100 align-start-center gap-3px">
                    <ng-container *ngFor="let event of day.events; let i = index">
                        <div *ngIf="i < 3 || (i === 3 && day.events.length == 4 )" (click)="eventClicked($event, event)" class="flex-row event-label border-radius"
                            [style.background-color]="event?.color?.primary">
                            <div class="flex-row align-space-between-center" #targetElement>
                                <div class="flex-row align-start-center">
                                    <!-- Attention continious before day -->
                                    <mat-icon class="text-white xxs" *ngIf="event.meta.isNotOneDayEvent && calendarCaseService.eventStartsBeforeDaySelected(event, day.date)">arrow_back</mat-icon>
                                     <!-- Provider name -->
                                    <span [matTooltip]="event?.title" class="text-white mls-1 text-ellipsis title-label" style="width: 50px;">{{event?.title}}</span>
                                    <!-- Icon watch -->
                                    <mat-icon style="width: 16px; max-width: 16px !important;" svgIcon="Watch-16x16" class="text-white mat-icon-small" [matTooltip]="calendarCaseService.getHoursTooltip(event)" *ngIf="calendarCaseService.practiceCountedByHours(event?.meta?.attention?.practice)"></mat-icon>
                                    <!-- Attention continious after day -->
                                    <mat-icon class="text-white xxs mr-5" *ngIf="event.meta.isNotOneDayEvent && calendarCaseService.eventEndsAfterDaySelected(event, day.date)">arrow_forward</mat-icon>
                                </div>
                                <div>
                                    <!-- Attention state -->
                                    <span [matTooltip]="event?.meta?.attention?.state?.name" style="width: 16px; max-width: 16px;"
                                        [ngStyle]="{'background-color': calendarCaseService.getColor(event?.meta?.attention?.state?.id)}"
                                        class="flex state-initial text-white border-radius align-center-center">
                                        {{event?.meta?.attention?.state?.name | firstLetterWordsIntoString}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Icon when more attentions -->
                    <div *ngIf="day.events.length >  4" (click)="onClickSeeMoreAttentions($event, day)" class="event-label align-start-center flex-row">
                        <span class="more-attentions-label event-label pls-1 text-secondary subtitle-2" style="width: 40px;">{{ day.events.length - 3}} más</span>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Calendar -->
        <div [ngSwitch]="view" [hidden]="this.loading">
            <!-- Month view -->
            <mwl-calendar-month-view
                *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate"
                [events]="monthEvents"
                [refresh]="refresh"
                [locale]="'es-AR'"
                [cellTemplate]="customTemplate"
                (dayClicked)="dayClicked($event.day)"
                [weekStartsOn] = "1">
            </mwl-calendar-month-view>
            <!-- Week view -->
            <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDateX"
                [events]="events"
                [refresh]="refresh"
                (beforeViewRender)="applyDateSelectionPolicy($event)"
                [weekStartsOn] = "1">
            </mwl-calendar-week-view>
            <!-- Day view -->
            <mwl-calendar-day-view
                *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDateX"
                [events]="events"
                [refresh]="refresh"
                (beforeViewRender)="applyDateSelectionPolicy($event)">
            </mwl-calendar-day-view>
        </div>
        <div *ngIf="this.loading" class="flex-100 pys-2 align-center-start">
            <mat-spinner class="mys-4" color="primary" diameter="40">
            </mat-spinner>
        </div>
    </div>
    <!-- BOTTOM BAR -->
    <div class="flex-column">
        <div class="flex-row align-start-center gap-1em py-2 pl-1 mat-footer-cell" style="width: 100%; border-radius: 0 0 6px 6px;">
            <div class="flex-row flex-30 align-space-between-center">
                <span class="subtitle-3 text-secondary-darker align-center-center" *ngIf="this.optionSelected != OPTIONS.ORDER">Unidades</span>
                <mat-slide-toggle [color]="'primary'" *ngIf="this.optionSelected != OPTIONS.ORDER" (change)="this.onChangeToggle($event)" ></mat-slide-toggle>
                <span class="subtitle-3 text-secondary-darker align-center-center" *ngIf="this.optionSelected != OPTIONS.ORDER">Valor venta</span>
            </div>
            <span class="subtitle-3 text-secondary-darker margin-left-counters" *ngIf="this.optionSelected != OPTIONS.ORDER">{{this.optionSelected === OPTIONS.SUPPLY ? 'TOTAL DE UNIDADES' : 'HORAS'}}:</span>
            <span class="mls-1 subtitle-3 text-primary" *ngIf="this.optionSelected != OPTIONS.ORDER" >{{ this.totalHours }}</span>
            <span class="subtitle-3 text-secondary-darker margin-left-counters" *ngIf="this.optionSelected == OPTIONS.ORDER" >COSTO TOTAL:</span>
            <span class="mls-1 subtitle-3 text-primary" *ngIf="this.optionSelected == OPTIONS.ORDER" >${{ this.totalCost }}</span>
            <span class="subtitle-3 text-secondary-darker margin-left-counters">TOTAL {{this.optionSelected === OPTIONS.SUPPLY ? 'DE INSUMOS' : this.optionSelected === OPTIONS.ORDER ? 'DE PEDIDOS' : ''}}:</span>
            <span class="mls-1 subtitle-3 text-primary">{{ this.totalAttentions }}</span>
        </div>
        <ng-container *ngIf="this.viewType == 'units';else priceViewTemplate">
            <div class="flex-row align-space-between-start">
                <div class="flex-row wrap align-start-none mts-2 pl-1 flex-50">
                    <mat-chip-listbox>
                        <div *ngFor="let totalByState of totalsByAttentionsState; let i=index">
                            <div *ngIf="totalByState!=0" class="flex-row align-start-center gap-2em">
                                <mat-chip style="white-space: nowrap; cursor: pointer;" class="mat-chip-min-width-40px"
                                    [ngStyle]="{'color':this.calendarCaseService.getColor(i), 'background-color':this.getBackgroundColor(i), 'border':this.getBorderStyle(i)}" (click)="this.selectState(i)">
                                    <span class="mat-caption" matTooltip="{{ attentionStates[i].split('_').join(' ') }}">
                                        {{ ( attentionStates[i].split('_').join(' ') | firstLetterWordsIntoString ) }}
                                    </span>
                                </mat-chip>
                                <span class="subtitle-3 text-secondary-darker mrs-5">{{ totalByState }}</span>
                            </div>
                        </div>
                    </mat-chip-listbox>
                </div>
                <div class="flex-50 mts-3">
                    <span class="ml-5 text-secondary txt" (click)="removeFilters()" style="white-space: nowrap; cursor: pointer;">Limpiar selección</span>
                </div>
            </div>
        </ng-container>
        <ng-template #priceViewTemplate>
            <div class="flex-row align-space-between-start">
                <div class="flex-row wrap align-start-none mts-2">
                    <mat-chip-listbox>
                        <div *ngFor="let totalByState of totalPricesByAttentionsState; let i=index">
                            <div *ngIf="showImport(totalByState, i)" class="flex-row align-start-center gap-2em">
                                <mat-chip style="white-space: nowrap; cursor: pointer;" class="mat-chip-min-width-40px"
                                    [ngStyle]="{'color':this.calendarCaseService.getColor(i), 'background-color':this.getBackgroundColor(i), 'border':this.getBorderStyle(i)}" (click)="this.selectState(i)">
                                    <span class="mat-caption" matTooltip="{{ attentionStates[i].split('_').join(' ') }}">
                                        {{ ( attentionStates[i].split('_').join(' ') | firstLetterWordsIntoString ) }}
                                    </span>
                                </mat-chip>
                                <span class="mls-1 subtitle-3 text-secondary-darker mrs-5">{{ (totalByState) ? (totalByState | currency: 'ARS':'symbol':'1.2') : (0 | currency: 'ARS':'symbol':'1.2') }}</span>
                            </div>
                        </div>
                    </mat-chip-listbox>
                </div>
                <div class="flex-25 mts-3">
                    <span class="ml-5 text-secondary txt" (click)="removeFilters()" style="white-space: nowrap; cursor: pointer;">Limpiar selección</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<!-- Pop-up template if there are more than 3 attentions -->
<ng-template #extraAttentionsTemplate>
    <ng-container>
        <div class="flex-column align-center-center mb-5">
            <span class="text-secondary-darker headline-2">{{this.dayOpened.format('dddd') | uppercase}}</span>
            <span class="text-secondary-darker headline-2">{{this.dayOpened.format('DD')}}</span>
        </div>
    </ng-container>
    <ng-container >
        <div *ngFor="let event of this.extraDayAttentions" class="align-center-center extra-attentions-event-label border-radius pls-1"
             [style.background-color]="event?.color?.primary"
             (click)="handleEvent('Clicked', event)">
             <div class="flex-row align-space-between-center" style="cursor: pointer;" (click)="eventClicked($event, event)">
                <div class="flex-row align-start-center">
                    <mat-icon class="text-white extra-small mr-2" *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventStartsBeforeDaySelected(event, this.dayOpened)">arrow_back</mat-icon>
                    <span class="text-white text-ellipsis"  [matTooltip]="event?.title" >{{event?.title}}</span>
                    <mat-icon class="text-white extra-small mr-2" *ngIf="event.meta.isNotOneDayEvent && this.calendarCaseService.eventEndsAfterDaySelected(event, this.dayOpened)">arrow_forward</mat-icon>
                    <mat-icon class="text-white extra-small mr-2" [matTooltip]="calendarCaseService.getHoursTooltip(event)" *ngIf="calendarCaseService.practiceCountedByHours(event?.meta?.attention?.practice)"> query_builder </mat-icon>
                </div>
                <!-- Attention state -->
                <span class="state-initial text-white border-radius align-center-center" style="width: 16px !important; height:16px !important; display: flex;" [ngStyle]="{'background-color': calendarCaseService.getColor(event?.meta?.attention?.state?.id)}" [matTooltip]="event?.meta?.attention?.state?.name">{{event?.meta?.attention?.state?.name | firstLetterWordsIntoString}}</span>
            </div>
        </div>
  </ng-container>
</ng-template>
