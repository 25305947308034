<div class="flex-column align-center-center">
    <!-- Spinner -->
    <ng-container *ngIf="( loadingDeleteBatchAttentions$ | async )">
        <app-spinner></app-spinner>
    </ng-container>

    <form [formGroup]="formOptionToSelect" >
        <mat-radio-group class="flex-column align-start-none gap-1em" formControlName="radioButton" (change)="this.radioButtonSelected( $event )">
            <mat-radio-button [value]="'allAttentionsSelected'"><span style="font-weight: bolder;" class="text-primary-darker">Todas las atenciones</span></mat-radio-button>
            <mat-radio-button [value]="'customizeAttenttionsSelected'"><span style="font-weight: bolder;" class="text-primary-darker">Personalizar</span></mat-radio-button>
        </mat-radio-group>
    </form>

    <div class="mt-6"></div>

    <ng-container *ngIf="this.customizeAttenttionsSelectedToDelete">
        <form [formGroup]="formSelectCustomized">
            <div class="flex-column align-center-none">
                <div class="flex-column align-center-stretch full-width">
                    <!-- Desde -->
                    <div class="flex-row align-space-between-center">
                        <span style="padding-bottom: 1.5em" class="subtitle-3 text-primary-darker">Desde</span>
                        <app-date-picker class="flex-50" [name]="this.FROM_DATE" [form]="formSelectCustomized">
                        </app-date-picker>
                    </div>
                    <!-- Hasta  -->
                    <div class="flex-row align-space-between-center">
                        <span style="padding-bottom: 1.5em" class="subtitle-3 text-primary-darker">Hasta</span>
                        <app-date-picker class="flex-50" [name]="this.TO_DATE" [form]="formSelectCustomized">
                        </app-date-picker>
                    </div>
                </div>

                <div class="mt-2"></div>

                <div class="flex-column align-center-start gap-1em">
                    <span class="subtitle-3 text-primary-darker">Los días</span>
                    <div class="flex-row align-start-center gap-1-5em">
                        <!-- Days -->
                        <div class="flex-auto align-center-start">
                            <mat-chip-listbox multiple>
                                <mat-chip *ngFor="let day of dayChipsSelected | keyvalue: asIsOrder; let i = index"
                                    selectable="true" selected="isDayChipSelected(day.key)" (click)="selectDayChip(day)" [ngClass]="{'bg-accent' : isDayChipSelected(day.key), 'bg-accent-100' : !isDayChipSelected(day.key)}"
                                    style="min-width: 40px !important;">
                                    <span class="mat-small">{{day.key}}</span>
                                </mat-chip>
                            </mat-chip-listbox>
                        </div>
                        <!-- Check -->
                        <span class="subtitle-3 text-primary-darker">
                            Todos
                        </span>
                        <div>
                            <app-checkbox-input class="mt-3 subtitle-3 text-primary-darker"
                                (change)="onChangeCheck( $event )"
                                [form]="this.formSelectCustomized"
                                [name]="this.ALLDAYS">
                            </app-checkbox-input>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>

    <div class="my-3">
        <div style="max-width: 30vw;">
            <span class="text-secondary subtitle-2">*Las atenciones en estado REALIZADA Y FACTURABLE CON EVOLUCIÓN O EN CURSO se deberán pasar a estado debitable desde el menú de facturación.</span>
        </div>
    </div>

    <!-- Button -->
    <div class="flex-row align-space-around-center gap-2em">
        <button mat-stroked-button [color]="'primary'" (click)="closeWizard()">
            <div class="flex-row align-start-center gap-5px">
                <span>CANCELAR</span>
            </div>
        </button>

        <button mat-flat-button [color]="'primary'" (click)="confirmDeleteAttentionsBatch()" [disabled]="!((this.optionSelected === 'allAttentionsSelected') || (this.formSelectCustomized.valid && this.isAtLeastOneDaySelected() && this.optionSelected != 'allAttentionsSelected'))">
            <div class="flex-row align-start-center gap-5px">
                <mat-icon *ngIf="'true'" [svgIcon]="'ArrowCircleRight'"></mat-icon>
                <span>SIGUIENTE</span>
            </div>
        </button>
    </div>
</div>
