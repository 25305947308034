import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order, ProvisionalControlOrder } from 'src/app/shared/models/order';
import { CasesFilterParameters, MetaDataOrders, CaseDateFilters} from '../../abstraction/cases.facade';
import { Case } from '../../shared/models/case';
import { Pagination } from '../../shared/models/pagination';
import { BaseState } from './base.state';
import { VatCategory } from 'src/app/shared/models/vatCategory';
import { Patient } from 'src/app/shared/models/patient';
import { CaseArrangement } from 'src/app/shared/models/caseArrangement';
import { CompanyType } from '../../shared/models/companyType';
import { TaxZone } from '../services/cases.service';
import { CasesIndicatorsValues, OrderIndicatorValues, ProvisionFeesIndicatorValues } from '../../shared/models/provisionalControlIndicators';
import { Arrangement } from '../../shared/models/arrangement';
import { ArrangementItem } from '../../shared/models/arrangementItem';
import { Item } from 'src/app/shared/models/item';
import * as momentRange from 'moment-range';
import * as moment from 'moment'
import { ProvisionalControlProvisionFee } from 'src/app/shared/models/provisionFee';
import { UpdatingCase } from 'src/app/shared/models/submodels/updatingCase';

@Injectable({
  providedIn: 'root',
})
export class CasesState extends BaseState {

  override store = {
    cases$: new BehaviorSubject<Case[]>(null),
    case$: new BehaviorSubject<Case>(null),
    activeCases$: new BehaviorSubject<number>(null),
    loadingGettingActiveCases$: new BehaviorSubject<boolean>(null),
    casesWithoutAttentions$: new BehaviorSubject<number>(null),
    loadingGettingCasesWithoutPendingAttentions$: new BehaviorSubject<boolean>(null),
    casesWithoutPendingAttentions$: new BehaviorSubject<number>(null),
    loadingGettingCasesWithoutAttentions$: new BehaviorSubject<boolean>(null),
    loadingCreatingCase$: new BehaviorSubject<boolean>(false),
    loadingGettingCases$: new BehaviorSubject<boolean>(false),
    loadingGettingCase$: new BehaviorSubject<boolean>(true),
    errorCreatingCase$: new BehaviorSubject<boolean>(false),
    loadingUpdatingCase$: new BehaviorSubject<boolean>(false),
    errorUpdatingCase$: new BehaviorSubject<boolean>(false),
    metaDataCases$: new BehaviorSubject<CasesFilterParameters>(null),
    pagination$: new BehaviorSubject<Pagination>(null),
    loadingGettingPagination$: new BehaviorSubject<boolean>(false),
    isLoadingGettingVatCategories$: new BehaviorSubject<boolean>(false),
    vatCategories$: new BehaviorSubject<VatCategory[]>(null),
    isLoadingGetCaseArrangements$: new BehaviorSubject<boolean>(false),
    caseArrangements$: new BehaviorSubject<CaseArrangement[]>(null),
    isLoadingAddCaseArrangement$: new BehaviorSubject<boolean>(false),
    errorAddingCaseArrangement$: new BehaviorSubject<boolean>(false),

    // HISTORY MODE
    isShowCase$: new BehaviorSubject<boolean>(false),
    dateHistoryModeShow: new BehaviorSubject<Date>(null),

    // ORDERS
    orders$: new BehaviorSubject<Order[]>(null),
    loadingGettingOrders$: new BehaviorSubject<boolean>(false),
    metaDataOrders$: new BehaviorSubject<MetaDataOrders>(null),
    loadingCreatingOrder$: new BehaviorSubject<boolean>(false),
    errorCreatingOrder$: new BehaviorSubject<boolean>(false),
    loadingUpdatingOrder$: new BehaviorSubject<boolean>(false),
    errorUpdatingOrder$: new BehaviorSubject<boolean>(false),
    ordersDisplayedOnScreen$: new BehaviorSubject<Order[]>(null),
    loadingOrdersIndicators$: new BehaviorSubject<boolean>(false),

    // PATIENTS
    patients$: new BehaviorSubject<Patient[]>(null),
    loadingGettingPatients$: new BehaviorSubject<boolean>(false),

    // ACTIVE PATIENT
    loadingUpdatingPatientCaseAndEntities$: new BehaviorSubject<boolean>(false),

    // COMPANIES TYPE
    loadingGetCompaniesType$: new BehaviorSubject<boolean>(false),
    companiesType$: new BehaviorSubject<CompanyType[]>(null),

    // TAX ZONES
    loadingGetTaxZones$: new BehaviorSubject<boolean>(false),
    taxZones$: new BehaviorSubject<TaxZone[]>(null),

    // Indicators for ProvisionalControl
    provisionalControlIndicatorValues$: new BehaviorSubject<CasesIndicatorsValues>(null),
    loadingProvisionalControlIndicators$: new BehaviorSubject<boolean>(false),
    provisionFeesIndicators$: new BehaviorSubject<ProvisionFeesIndicatorValues>(null),
    loadingProvisionFeesIndicators$: new BehaviorSubject<boolean>(false),
    provisionalControlProvisionFees$: new BehaviorSubject<ProvisionalControlProvisionFee[]>(null),
    orderIndicators$: new BehaviorSubject<OrderIndicatorValues>(null),
    provisionalControlOrders$: new BehaviorSubject<ProvisionalControlOrder[]>(null),
    loadingGettingProvisionalControlProvisionFees$: new BehaviorSubject<boolean>(false),
    loadingGettingProvisionalControlOrders$: new BehaviorSubject<boolean>(false),

    // FILTERS
    caseDateFilters$: new BehaviorSubject<CaseDateFilters>(null),

    //EVOLUTIONS PDF
    loadingEvolutionsPdf$: new BehaviorSubject<boolean>(false),
  }

  constructor(
    ) {
      super()
    }

    isLoadingGettingPagination$() {
      return this.store.loadingGettingPagination$.asObservable();
    }

    setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
      this.store.loadingGettingPagination$.next(isLoadingGettingPagination);
    }
    isLoadingCreatingCase$() {
      return this.store.loadingCreatingCase$.asObservable();
    }

    setLoadingCreatingCase(isLoadingCreatingCase: boolean) {
      this.store.loadingCreatingCase$.next(isLoadingCreatingCase);
    }

    isLoadingGettingCases$() {
      return this.store.loadingGettingCases$.asObservable();
    }

    isLoadingGettingActiveCases$() {
      return this.store.loadingGettingActiveCases$.asObservable();
    }

    setLoadingGettingActiveCases(boolean: boolean) {
      this.store.loadingGettingActiveCases$.next(boolean);
    }

    setLoadingGettingCasesWithoutAttentions(boolean: boolean) {
      this.store.loadingGettingCasesWithoutAttentions$.next(boolean);
    }

    isLoadingGettingCasesWithoutAttentions$() {
      return this.store.loadingGettingCasesWithoutAttentions$.asObservable();
    }


    setLoadingGettingCases(isLoadingGettingCases: boolean) {
      this.store.loadingGettingCases$.next(isLoadingGettingCases);
    }

    isLoadingGettingProvisionalControlProvisionFees(){
      return this.store.loadingGettingProvisionalControlProvisionFees$.asObservable();
    }

    setLoadingGettingProvisionalControlProvisionFees(isLoading: boolean){
      this.store.loadingGettingProvisionalControlProvisionFees$.next(isLoading);
    }

    isLoadingGettingProvisionalControlOrders(){
      return this.store.loadingGettingProvisionalControlOrders$.asObservable();
    }

    setLoadingGettingProvisionalControlOrders(isLoading: boolean){
      this.store.loadingGettingProvisionalControlOrders$.next(isLoading);
    }

    isErrorCreatingCase$() {
      return this.store.errorCreatingCase$.asObservable();
    }

    setErrorCreatingCase(isErrorCreatingCase: boolean) {
      this.store.errorCreatingCase$.next(isErrorCreatingCase);
    }

    addCase(acase: Case) {
      const cases: Case[] = this.store.cases$.getValue() || [];
      this.store.cases$.next([...cases, acase]);
    }

    getCases$(): Observable<Case[]> {
      return this.store.cases$.asObservable();
    }

    setCases(cases: Case[]) {
      this.store.cases$.next(cases);
    }

    getActiveCases$(): Observable<number> {
      return this.store.activeCases$.asObservable();
    }

    setActiveCases(number: number) {
      this.store.activeCases$.next(number);
    }

    getCasesWithoutAttentions$(): Observable<number> {
      return this.store.casesWithoutAttentions$.asObservable();
    }

    setCasesWithoutAttentions(number: number) {
      this.store.casesWithoutAttentions$.next(number);
    }
    getMetaDataCases$(): Observable<CasesFilterParameters> {
      return this.store.metaDataCases$.asObservable();
    }

    setMetaDataCases(metaDataCases: CasesFilterParameters) {
      this.store.metaDataCases$.next(metaDataCases);
    }

    updateMetaDataCases(metaDataCases: CasesFilterParameters) {
      const md = this.store.metaDataCases$.getValue();
      console.log('se esta emitiendo: ', { ...md, ...metaDataCases });

      this.store.metaDataCases$.next({ ...md, ...metaDataCases });
    }

    // CASE
    isLoadingGettingCase$() {
      return this.store.loadingGettingCase$.asObservable();
    }

    setLoadingGettingCase(isLoadingGettingCase: boolean) {
      this.store.loadingGettingCase$.next(isLoadingGettingCase);
    }

    getCase$(): Observable<Case> {
      return this.store.case$.asObservable();
    }

    setCase(casee: Case) {
      this.store.case$.next(casee);
    }

    isLoadingUpdatingCase$() {
      return this.store.loadingUpdatingCase$.asObservable();
    }

    setLoadingUpdatingCase(isLoadingUpdatingCase: boolean) {
      this.store.loadingUpdatingCase$.next(isLoadingUpdatingCase);
    }


    isErrorUpdatingCase$() {
      return this.store.errorUpdatingCase$.asObservable();
    }

    setErrorUpdatingCase(isErrorUpdatingCase: boolean) {
      this.store.errorUpdatingCase$.next(isErrorUpdatingCase);
    }

    updateCase(newCase: UpdatingCase, caseId: number) {
      let casee: Case = this.store.case$.getValue();
      if (casee.id == caseId) {
        casee = { ...casee, ...newCase }
        console.log('Se actualizó este caso', casee);
        this.store.case$.next(casee);
      }
    }

    // Indicators

    isLoadingGettingCasesWithoutPendingAttentions$() {
      return this.store.loadingGettingCasesWithoutPendingAttentions$.asObservable();
    }

    setLoadingGettingCasesWithoutPendingAttentions(boolean: boolean) {
      this.store.loadingGettingCasesWithoutPendingAttentions$.next(boolean);
    }

    getCasesWithoutPendingAttentions$(): Observable<number> {
      return this.store.casesWithoutPendingAttentions$.asObservable();
    }

    setCasesWithoutPendingAttentions(countCases: number) {
      this.store.casesWithoutPendingAttentions$.next(countCases);
    }


    // Pagination

    getPagination$(): Observable<Pagination> {
      return this.store.pagination$.asObservable();
    }

    setPagination(pagination: Pagination) {
      this.store.pagination$.next(pagination);
    }

    // Orders

    isLoadingGettingOrders$() {
      return this.store.loadingGettingOrders$.asObservable();
    }

    setLoadingGettingOrders(loading: boolean) {
      this.store.loadingGettingOrders$.next(loading);
    }

    getMetaDataOrders$(): Observable<MetaDataOrders> {
      return this.store.metaDataOrders$.asObservable();
    }

    setMetaDataOrders(metaDataOrders: MetaDataOrders) {
      this.store.metaDataOrders$.next(metaDataOrders);
    }

    getOrders$(): Observable<Order[]> {
      return this.store.orders$.asObservable();
    }

    setOrders(Orders: Order[]) {
      this.store.orders$.next(Orders);
    }

    isLoadingCreatingOrder$() {
      return this.store.loadingCreatingOrder$.asObservable();
    }

    setLoadingCreatingOrder(isLoadingCreatingOrder: boolean) {
      this.store.loadingCreatingOrder$.next(isLoadingCreatingOrder);

    }

    isErrorCreatingOrder$() {
      return this.store.errorCreatingOrder$.asObservable();
    }

    setErrorCreatingOrder(isErrorCreatingOrder: boolean) {
      this.store.errorCreatingOrder$.next(isErrorCreatingOrder);
    }

    addOrder(newEntidad: Order) {
      this.add<Order>({ data: newEntidad, storeRefAttribute: this.store.orders$ })
    }

    isLoadingUpdatingOrder$() {
      return this.store.loadingUpdatingOrder$.asObservable();
    }

    setLoadingUpdatingOrder(loadingUpdatingOrder: boolean) {
      this.store.loadingUpdatingOrder$.next(loadingUpdatingOrder);
    }

    isErrorUpdatingOrder$() {
      return this.store.errorUpdatingOrder$.asObservable();
    }

    setErrorUpdatingOrder(isErrorUpdatingOrder: boolean) {
      this.store.errorUpdatingOrder$.next(isErrorUpdatingOrder);
    }


    updateOrder(entidadUpdate: Partial<Order>, entidadId: number) {
      this.update<Partial<Order>>({ data: entidadUpdate, dataId: entidadId, storeRefAttribute: this.store.orders$ })

    }

    deleteOrderItems(items: Item[], orders: Order[]){
      this.setLoadingUpdatingOrder(true)
      if(!!orders && !!items){
        let ordersUpdated: Order[] = [];
        orders.map(or => {return {...or}}).forEach(or => {
          // Saves the order items in a variable
          let orderItems = [...or.items];
          // Finds the items to delete in the order
          let itemsToDelete = items.filter(it => {return orderItems.includes(it)});
          if(itemsToDelete.length > 0){
            //For each item, deletes it of the order items array
            itemsToDelete.forEach( it => {
              orderItems.splice(orderItems.indexOf(it), 1)
            })
          }
          // Sets the updated items array to the order
          or.items = orderItems;
          // Pushes the order with the updated items into the array that will be uploaded to the state
          ordersUpdated.push(or);
        })
        this.setOrders(ordersUpdated.filter(or => or.items.length > 0));
      } else {
        this.setOrders([]);
      }
      this.setLoadingUpdatingOrder(false)
    }

    /* VAT CATEGORIES */
    isLoadingGettingVatCategories$() {
      return this.store.isLoadingGettingVatCategories$.asObservable();
    }

    setLoadingGettingVatCategories(isLoadingGettingVatCategories: boolean) {
      this.store.isLoadingGettingVatCategories$.next(isLoadingGettingVatCategories);
    }

    setVatCategories(categories: VatCategory[]) {
      this.store.vatCategories$.next(categories);
    }

    getVatCategories$(): Observable<VatCategory[]> {
      return this.store.vatCategories$.asObservable();
    }

    // PATIENTS
    isLoadingGettingPatients$() {
      return this.store.loadingGettingPatients$.asObservable();
    }

    setLoadingGettingPatients(isLoadingGettingPatients: boolean) {
      this.store.loadingGettingPatients$.next(isLoadingGettingPatients);
    }

    getPatients$(): Observable<Patient[]> {
      return this.store.patients$.asObservable();
    }

    setPatients(patients: Patient[]) {
      this.store.patients$.next(patients);
    }

    // ACTIVE PATIENT => case's patientActive === entities's patientActive
    setLoadingUpdatingPatientCaseAndEntities( isLoadingUpdatingPatientCaseAndEntities: boolean ) {
      this.store.loadingUpdatingPatientCaseAndEntities$.next( isLoadingUpdatingPatientCaseAndEntities );
    }

    isLoadingUpdatingPatientCaseAndEntities$() {
      return this.store.loadingUpdatingPatientCaseAndEntities$.asObservable();
    }

    setPatient( patient: Patient ) {
      let casee: Case = this.store.case$.getValue();
      casee.patient = { ...patient };
      casee.patientId = patient.patientId;
      this.store.case$.next(casee);
    }

    // CASE ARRRANGEMENTS
    isLoadingGetCaseArrangements$() {
      return this.store.isLoadingGetCaseArrangements$.asObservable();
    }

    setLoadingGetCaseArrangements(isLoadingGetCaseArrangements: boolean) {
      this.store.isLoadingGetCaseArrangements$.next(isLoadingGetCaseArrangements);
    }

    setCaseArrangements(arrangement: CaseArrangement[]) {
      this.store.caseArrangements$.next(arrangement);
    }

    getCaseArrangements$(): Observable<CaseArrangement[]> {
      return this.store.caseArrangements$.asObservable();
    }

    getCaseArrangement( arrangementId: number ): Arrangement {
      return this.store.caseArrangements$.getValue().find( arr => arr.id == arrangementId )
    }

    getCaseArrangementPracticesByPractice( arrangementId: number , practiceId: number ): ArrangementItem[] {
      return this.store.caseArrangements$.getValue().find( arr => arr.id == arrangementId ).practiceArrangements.filter( pr => pr.practiceId == practiceId );
    }

    isLoadingAddCaseArrangement$() {
      return this.store.isLoadingAddCaseArrangement$.asObservable();
    }

    setLoadingAddCaseArrangement(isLoadingAddCaseArrangements: boolean) {
      this.store.isLoadingAddCaseArrangement$.next(isLoadingAddCaseArrangements);
    }

    addCaseArrangement(arrangement: CaseArrangement) {
      const arrangements: CaseArrangement[] = this.store.caseArrangements$.getValue();
      this.store.caseArrangements$.next([...arrangements, arrangement]);
    }

    deleteCaseArrangement( arrangementId: number ) {
      this.delete<Partial<CaseArrangement[]>>({ dataId: arrangementId, storeRefAttribute: this.store.caseArrangements$ });
    }

    setErrorAddingCaseArrangement(isErrorAddingCaseArrangement: boolean) {
      this.store.errorAddingCaseArrangement$.next(isErrorAddingCaseArrangement);
    }

    // COMPANIES TYPE
    setLoadingGetCompaniesType(isLoadingGetCompaniesType: boolean) {
      this.store.loadingGetCompaniesType$.next(isLoadingGetCompaniesType);
    }

    getLoadingGetCompaniesType() {
      return this.store.loadingGetCompaniesType$.asObservable();
    }

    setCompaniesType(companiesType: CompanyType[]) {
      this.store.companiesType$.next(companiesType);
    }

    getCompaniesType() {
      return this.store.companiesType$.asObservable();
    }

    // TAX ZONES
    setLoadingGettingTaxZones(isLoadingGetTaxZones: boolean) {
      this.store.loadingGetTaxZones$.next(isLoadingGetTaxZones);
    }

    setTaxZones(taxZones: TaxZone[]) {
      this.store.taxZones$.next(taxZones);
    }

    getTaxZones$(): Observable<TaxZone[]> {
      return this.store.taxZones$.asObservable();
    }

    // History MODE
    isShowCase$() {
      return this.store.isShowCase$.asObservable();
    }

    setShowCase( isShowCase: boolean ) {
      this.store.isShowCase$.next( isShowCase );
    }

    getCaseDateFilters$(): Observable<CaseDateFilters> {
      return this.store.caseDateFilters$.asObservable();
    }

    getCaseDateFilters(): CaseDateFilters {
      return this.store.caseDateFilters$.value;
    }

    setCaseDateFilters( caseDateFilters:CaseDateFilters ) {
      return this.store.caseDateFilters$.next( caseDateFilters );
    }

    getProvisionalControlIndicatorValues$(): Observable<CasesIndicatorsValues> {
      return this.store.provisionalControlIndicatorValues$.asObservable();
    }


    setProvisionalControlIndicatorValues( provisionalControlIndicatorValues: CasesIndicatorsValues ) {
      this.store.provisionalControlIndicatorValues$.next( provisionalControlIndicatorValues )
    }

    getProvisionFeesIndicators$(){
      return this.store.provisionFeesIndicators$.asObservable();
    }

    setProvisionFeesIndicators(provisionFeesIndicators){
      this.store.provisionFeesIndicators$.next(provisionFeesIndicators)
    }

    setLoadingProvisionFeesIndicators( loading: boolean ){
      this.store.loadingProvisionFeesIndicators$.next( loading )
    }

    isLoadingProvisionFeesIndicators$(): Observable<boolean> {
      return this.store.loadingProvisionFeesIndicators$.asObservable();
    }

    setLoadingOrdersIndicators( loading: boolean ){
      this.store.loadingOrdersIndicators$.next( loading );
    }

    isLoadingOrdersIndicators$(): Observable<boolean> {
      return this.store.loadingOrdersIndicators$.asObservable();
    }

    getProvisionalControlProvisionFees$(){
      return this.store.provisionalControlProvisionFees$.asObservable();
    }

    setProvisionalControlProvisionFees$(provisionFees){
      this.store.provisionalControlProvisionFees$.next(provisionFees);
    }

    getOrderIndicators$(){
      return this.store.orderIndicators$.asObservable();
    }

    setOrderIndicators$(orders){
      this.store.orderIndicators$.next(orders);
    }

    getProvisionalControlOrders$(){
      return this.store.provisionalControlOrders$.asObservable();
    }

    setProvisionalControlOrders$(orders){
      this.store.provisionalControlOrders$.next(orders)
    }

    getLoadingProvisionalControlIndicators$(): Observable<boolean> {
      return this.store.loadingProvisionalControlIndicators$.asObservable();
    }

    setLoadingProvisionalControlIndicators( loadingProvisionalControlIndicators: boolean ) {
      this.store.loadingProvisionalControlIndicators$.next( loadingProvisionalControlIndicators )
    }

    filterOrdersToDisplayOnScreen(orders: Order[]){
      this.setLoadingGettingOrders(true);
      if(!!orders){
        let dateFilters: CaseDateFilters = this.getCaseDateFilters();
        let range = new momentRange.DateRange(
          dateFilters.historyFromDate,
          dateFilters.historyToDate,
          );
          let ordersToDisplay = orders.filter(or => range.contains(moment(or.orderDate)))
          this.setOrdersToDisplayOnScreen(ordersToDisplay);
        } else {
          this.setOrdersToDisplayOnScreen([]);
        }
        this.setLoadingGettingOrders(false);
      }

    setOrdersToDisplayOnScreen(orders: Order[]){
      this.store.ordersDisplayedOnScreen$.next(orders)
    }

    getOrdersDisplayedOnScreen$(){
      return this.store.ordersDisplayedOnScreen$.asObservable();
    }

    getLoadingEvolutionsPdf$(): Observable<boolean>{
        return this.store.loadingEvolutionsPdf$.asObservable();
    }

    setLoadingEvolutionsPdf(value:boolean){
        return this.store.loadingEvolutionsPdf$.next(value)
    }
}
