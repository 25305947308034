import { Patient } from './patient';
import { Operator } from './operator';
import { Financier } from './financier';
import { CaseState } from './caseState';
import { Diagnosis } from './diagnosis';
import { CaseParametrization } from './CaseParametrization';
import { Authorization } from './authorization';
import { CaseDerivation } from './caseDerivation';
import { VatCategory } from './vatCategory';
import { Arrangement } from './arrangement';
import { CompanyType } from './companyType';
import { TaxZone } from 'src/app/core/services/cases.service';
import { CaseTag, Tag } from './tags';
import { Complexity } from './complexity';

export enum CASE_AUTHORIZATIONS_STATES {
   EXPIRED,
   VALID,
   CLOSE_TO_EXPIRED,
   UNAUTHORIZED
}
export interface Case {
   id: number;
   pendingAttentions?: number
   financierInternalCode: string,
   financierOperator?: string,
   legacyId?: number,
   caseDerivation?: CaseDerivation,
   diagnoses: Diagnosis[],
   states: CaseState[],
   validity: Date,
   hasSchedule?: boolean,
   attentionsWithoutScheduleByCase?: number,
   financier: Financier,
   financierId?: number,
   fromDate: Date,
   toDate: Date,
   patient: Patient,
   patientId: number,
   operator?: Operator,
   //provisions: Provision[],
   active: boolean,
   caseParametrization?: CaseParametrization,
   attentionsOldAndOmitted?: number,
   closestAuthorization?: Authorization,
   closestAuthorizationState?: CASE_AUTHORIZATIONS_STATES,
   vatCategory?: VatCategory,
   arrangement?: Arrangement,
   allergies?:string,
   operatorId?:number,
   indicator?:string,
   financierEmail?:string,
   emailDate?: Date,
   companyType?: CompanyType,
   taxZone?: TaxZone,
   caseTags?: CaseTag[],
   complexities: Complexity,
   complexityId?: number,
   startDate?: Date,
   requested?: boolean,
   affiliationNumber?: string
   companyTypeId?: number,
   enabledFinancier?: boolean,
   enabledProvider?: boolean,
   lastScheduled?: Date,
   tags?: Tag[],
   taxZoneId?: number,
   vatCategoryId?: number,
   validityFinancier?: Date,
}
