import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { Attention } from 'src/app/shared/models/attention';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
const colors: { primary: string, secondary: string }[] = [
  {
    primary: '#1e90ff',
    secondary: '#1e90ff',
  },
  {
    primary: '#ad2121',
    secondary: '#ad2121',
  },
  {
    primary: '#e3bc08',
    secondary: '#e3bc08',
  },
  {
    primary: ' #1ed6ae',
    secondary: '#1ed6ae',
  },
  {
    primary: '#aa42f5',
    secondary: '#aa42f5',
  },
  {
    primary: '#a6ad21',
    secondary: '#a6ad21',
  },
  {
    primary: '#3fe308',
    secondary: '#3fe308',
  },
  {
    primary: '#1e93d6',
    secondary: '#1e93d6',
  },
]

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() attentions: Attention[];
  @Input() loading: boolean;
  @Output() onEventDelete = new EventEmitter<CalendarEvent>();
  @Output() onDayClicked = new EventEmitter<Date>();
  @Output() onEventClicked = new EventEmitter<CalendarEvent>();
  @Output() onChangeMonth = new EventEmitter<Date>();

  public readonly TEXT_DATE_FROM = 'Mostrar fecha Realizada';
  public readonly TEXT_DATE_SCHEDULED = 'Mostrar fecha Agendada';

  events: CalendarEvent[];

  isChangedDate: boolean = false;
  eventStart: Date;
  dateToShow: string =  this.TEXT_DATE_SCHEDULED;
  @Output() option = new EventEmitter<string>();

  constructor(private generalService: GeneralService, public entitiesFacade: EntitiesFacade, private attentionService: AttentionsService, private casesFacade: CasesFacade) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log('Changes ',changes);

    if (changes['attentions'] && changes['attentions'].currentValue) {
      this.events = this.attentionsToEvents(this.attentions);
    }
  }

  attentionsToEvents(attentions: Attention[]): CalendarEvent[] {
    // Usado para la paleta de colores
    let agreementsUsed = [];
    let indexAgreement;
    // let countAttentionOldAndOmmited : number = 0;
    // let isAttentionOldAndOmmited : boolean = false;
    const events: CalendarEvent[] = attentions.map(attention => {
      indexAgreement = agreementsUsed.findIndex(agreementId => agreementId === attention.agreementId)
      // Si aún no se registró al prestador
      if (indexAgreement == -1) {
        indexAgreement = agreementsUsed.push(attention.agreementId) - 1;
        if ((colors.length - 1) < indexAgreement) {
          const color = this.generalService.getRandomColor();
          colors.push({ primary: color, secondary: color })
        }
      }
      // isAttentionOldAndOmmited = this.attentionService.isOldAndOmmited(attention);
      // if(isAttentionOldAndOmmited){
      //   countAttentionOldAndOmmited += 1;
      // }
      this.eventStart = this.isChangedDate ? new Date(attention.fromDate) : new Date(attention.fromScheduled) ;
      // console.log('EventStart Inicial', this.eventStart);

      const event: CalendarEvent = {

        id: attention.id,
        color: { ...colors[indexAgreement], primary: this.attentionService.isOldAndOmmited(attention) ? 'white' : colors[indexAgreement].primary },
        title: `${attention.practice.name} • ${attention.patient.surname} | ${this.entitiesFacade.getProvidersFullName(attention?.provider)}| Comienza: ` + moment(this.eventStart).format('DD/MM HH:mm') + (attention.toDate != null ? '| Termina: ' + moment(new Date(attention.toDate)).format('DD/MM HH:mm') : ''),
        start: this.eventStart,
        end: new Date(attention.toDate),
        draggable: false,
        meta: {
          attention,
          referenceText: `${attention.practice.name} • ${attention.patient.surname} | ${this.entitiesFacade.getProvidersFullName(attention?.provider)}`
        }
      };
      return event;
    });
    //this.case.attentionsOldAndOmitted = countAttentionOldAndOmmited;
    //this.casesFacade.updateCase$(casee);
    return events;
  }

  onToggleChange($event: MatSlideToggleChange) {
    this.isChangedDate = $event.checked;
    console.log('isChangedDate ', this.isChangedDate);
    this.dateToShow = this.isChangedDate ? this.TEXT_DATE_FROM : this.TEXT_DATE_SCHEDULED;
    console.log('dateToShow ', this.dateToShow);

    if(this.attentions) {
      this.events = this.attentionsToEvents(this.attentions);
    }
  }

  selectOption(option){
    this.option.emit(option);
  }

  onDayClickedHandler(date: any) {
    // this.openDialog(date);
    this.onDayClicked.emit(date);
  }

  onEventClickedHandler(event: any) {
    this.onEventClicked.emit(event.meta.attention)
    // this.openDialog(null, event.meta);
  }

  onEventDeleteHanlder(event: any) {
    this.onEventDelete.emit(event.meta.attention)
  }

  onReceiveChangeMonth(date: any) {
    this.onChangeMonth.emit(date);
  }
}
