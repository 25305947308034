<div class="flex-column">
    <span class="headline-1 pls-4 mbs-5 border-left text-primary-darker-title">
        {{ 'Gestión del caso' | uppercase }}
    </span>
        <mat-card  class="flex-100 pt-1">
            <div class="flex-column ml-4 flex-90">

                <form [formGroup]="this.formCaseManagement">

                    <span class="subtitle-3 text-primary-darker-h3">
                        Operador *
                    </span>
                    <app-operator-select [name]="this.OPERATOR" [form]="this.formCaseManagement"
                        [placeholder]="'Seleccione un operador'" [elements]="this.operators"
                        [defaultValue]="" [calculateId]="this.calculateIdOperator"
                        [calculateName]="this.calculateNameOperator" [placeholderSearching]="'Buscar operador...'"
                        [placeholderNoValue]="'No hay resultados'" [nameSearching]="this.OPERATOR_SEARCHING">
                    </app-operator-select>

                    <span class="subtitle-3 text-primary-darker-h3">
                        Agregar nota
                    </span>
                    <app-text-area-input class="notes" [name]="this.NOTE" [form]="this.formCaseManagement"
                        [placeholder]="'Escriba aqui'">
                    </app-text-area-input>
                </form>
            </div>
        </mat-card>
</div>
