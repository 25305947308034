import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { CasesService, ReasonsQPS } from 'src/app/core/services/cases.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Case } from 'src/app/shared/models/case';
import { CaseTransitionReason } from 'src/app/shared/models/caseTransitionReason';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-changing-state-case',
  templateUrl: './dialog-changing-state-case.component.html',
  styleUrls: ['./dialog-changing-state-case.component.scss'],
})
export class DialogChangingStateCaseComponent implements OnInit {
  // Called on parent's invocation
  static readonly ACTION = {
    REOPEN: 1,
    CLOSE: 2,
    // TODO: REJECT type  
    // REJECT: 3,  
  };
  ACTION
  form: FormGroup;
  loadingPatch = false;
  public readonly REASON = 'reason';
  public readonly OBSERVATIONS = 'observations';
  public readonly CHANGING_STATE_DATE = 'closeDate';
  reasonPlaceHolder: string;
  datePickerPlaceHolder: string;
  confirmButtonText: string;
  confirmQuestion: string;
  successMessage: string;
  loadingReasons: boolean;
  elementsReasons: CaseTransitionReason[];

  get changingStateDate() {
    return this.form.get(this.CHANGING_STATE_DATE).value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, case: Case, action: 1 | 2 | 3 },
    public dialogRef: MatDialog,
    private casesService: CasesService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.loadingReasons = true;
    this.ACTION = DialogChangingStateCaseComponent.ACTION;
    switch (this.data.action) {
      case this.ACTION.CLOSE:
        this.setClose();
        break;
      case this.ACTION.REOPEN:
        this.setReopen();
        break;
      default:
        console.error('Action type inexistent');
        break;
    }
  }
  setClose() {
    this.reasonPlaceHolder = 'Seleccione el motivo del cierre';
    this.datePickerPlaceHolder = 'Fecha de cierre del caso';
    this.confirmButtonText = 'CERRAR CASO';
    this.confirmQuestion = '¿Seguro desea cerrar el caso?';
    this.successMessage = 'Caso cerrado correctamente';
    let qps: ReasonsQPS = {
      closed: 1,
    }
    this.casesService.getTransitionReasons(qps).pipe(
      tap(Reasons => this.elementsReasons = Reasons),
      tap(Reasons => this.form = this.createForm()),
      tap(closingReasons => this.loadingReasons = false)
    ).subscribe()
  }

  setReopen() {
    this.reasonPlaceHolder = 'Seleccione el motivo de reapertura ';
    this.datePickerPlaceHolder = 'Fecha de reapertura del caso';
    this.confirmButtonText = 'REABRIR CASO';
    this.confirmQuestion = '¿Seguro desea reabrir el caso?';
    this.successMessage = 'Caso Reabierto correctamente';
    let qps: ReasonsQPS = {
      closed: 0,
    }
    this.casesService.getTransitionReasons(qps).pipe(
      tap(reasons => this.elementsReasons = reasons),
      tap(reasons => {
        this.form = this.createForm();
        this.form.get(this.CHANGING_STATE_DATE).setValue(moment().subtract(5, 'seconds').toDate())
      }),
      tap(closingReasons => this.loadingReasons = false)
    ).subscribe()
  }

  createForm() {
    return this.formBuilder.group({
      [this.REASON]: ['', [CustomValidators.required('Motivo requerido')]],
      [this.OBSERVATIONS]: ['', [CustomValidators.required('Motivo requerido')]],
      [this.CHANGING_STATE_DATE]: ['', [CustomValidators.dateLessOrEqualThan('La fecha debe ser menor o igual a hoy', new Date())]],
    })
  }

  calculateNameReason(reason: CaseTransitionReason) {
    return reason ? reason.text : null;
  }

  calculateIdReason(reason: CaseTransitionReason) {
    return reason ? reason.id : null;
  }

  onConfirmAction(title, confirmButtonText, successMessage) {

    const swalWithCustomizeButtons = Swal.mixin({
      customClass: {
      confirmButton: 'btnSwalConfirm',
      cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    console.log(this.form);
    let stateChangeDate = this.setCloseDate();
    swalWithCustomizeButtons.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText: 'CANCELAR',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => {

        this.loadingPatch = true;
        return this.casesService.postCaseState({
          stateChangeDate: stateChangeDate,
          observations: this.form.get(this.OBSERVATIONS).value,
          reason: {
            id: this.form.get(this.REASON).value
          }
        }, this.data.case.id)
          .toPromise()
          .then((e) => {
            console.log('entro afueraaa', e);
            return swalWithCustomizeButtons.fire({
              title: successMessage,
              icon: 'success'
            })
          })
          .then(_ => this.onClickCloseDialog())
          .then(_ => window.location.reload())
          .finally(() => this.loadingPatch = false)
          .catch(e => {
            // TODO: 428 -> force ->
            if (!(e instanceof HttpErrorResponse) || e.status != 428) {
              this.onClickCloseDialog()
              console.log('entro en error return', e);
              return e;
            }
            return swalWithCustomizeButtons.fire({
              title: 'Atención: ' + e.error.error.message,
              text: '¿Igualmente desea cerrar el caso?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'CONFIRMAR',
              cancelButtonText: 'CANCELAR',
              showLoaderOnConfirm: true,
              reverseButtons: true,
              preConfirm: () => {
                return this.casesService.postCaseState({
                  stateChangeDate: stateChangeDate,
                  observations: this.form.get(this.OBSERVATIONS).value,
                  reason: {
                    id: this.form.get(this.REASON).value
                  },
                }, this.data.case.id, true).toPromise()
                  .then((e) => {
                    console.log(e);
                    return swalWithCustomizeButtons.fire({
                      title: successMessage,
                      icon: 'success'
                    })
                  })
                  .then(_ => this.onClickCloseDialog())
                  .then(_ => window.location.reload())
                  .catch(() => {
                    this.onClickCloseDialog()
                  })
                  .finally(() => this.loadingPatch = false)
              }
            })
          })
      }
    })
  }

  onClickCloseDialog() {
    this.dialogRef.closeAll();
  }

  setCloseDate() : Date{
    let date = moment(this.form.get(this.CHANGING_STATE_DATE).value);
    if(this.data.action == this.ACTION.CLOSE){
      let actualDateWithMinAndHs = moment();
      date.add(actualDateWithMinAndHs.hours(),'h')
      date.add(actualDateWithMinAndHs.minutes(),'m')
      date.add(actualDateWithMinAndHs.seconds(),'s')
    }
    return date.toDate();
  }
}