import { Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import {
    FinanciersService,
    FinanciersQPS,
    PostFinancierBody,
    PatchFinancierBody,
} from '../core/services/financiers.service';
import {
    OperatorsService,
    OperatorsQPS,
    PostOperatorBody,
    PatchOperatorBody,
} from '../core/services/operators.service';
import {
    PatientsService,
    HealthInsuranceQPS,
    PostHealthInsuranceBody,
    PatchHealthInsuranceBody,
    PatientsQPS,
    LocationsQPS,
    ProvinceQPS,
} from '../core/services/patients.service';
import { EntitiesState } from '../core/states/entities.state';
import { Financier } from '../shared/models/financier';
import { Patient } from '../shared/models/patient';
import { Operator } from '../shared/models/operator';
import { Provider } from '../shared/models/provider';
import {
    ProvidersService,
    ProvidersQPS,
    PostProviderBody,
    PatchProviderBody,
    NationsQPS,
} from '../core/services/providers.service';
import { TypeDocument } from '../shared/models/typeDocument';
import { Location } from '../shared/models/location';
import { HealthInsurance } from '../shared/models/healthInsurance';
import { Pagination } from '../shared/models/pagination';
import { tap, finalize, map, shareReplay } from 'rxjs/operators';
import { Nationality } from '../shared/models/nationality';
import { Province } from '../shared/models/province';
import { PatientEntities } from '../shared/models/patientEnttities';
import { GeneralService } from '../core/services/general.service';
import { Specialty } from '../shared/models/specialty';

export interface MetaDataHealthInsurance extends HealthInsuranceQPS, Pagination { }
export interface MetaDataProviders extends ProvidersQPS, Pagination { }
export interface MetaDataOperators extends OperatorsQPS, Pagination { }

@Injectable({
    providedIn: 'root',
})
export class EntitiesFacade {
    private providers$: Observable<Provider[]>;
    private loadingGettingProviders$ = new BehaviorSubject<boolean>(false);

    private genders: { id: string; name: string }[] = [
        {
            id: 'M',
            name: 'Masculino',
        },
        {
            id: 'F',
            name: 'Femenino',
        },
        {
            id: '',
            name: 'Sin especificar',
        },
    ];

    constructor(
        private patientsService: PatientsService,
        private providersService: ProvidersService,
        private operatorsService: OperatorsService,
        private financiersService: FinanciersService,
        private entitiesState: EntitiesState,
        private generalService: GeneralService,
    ) {
        // Practices cache
        this.setLoadingGettingProviders(true);
        this.providers$ = this.providersService.getProviders().pipe(
            map((res) => res.data),
            tap(() => this.setLoadingGettingProviders(false)),
            shareReplay(1),
        );
    }

    // Providers cache
    getProvidersFiltered$(value: string): Observable<Provider[]> {
        return this.providers$.pipe(
            map((providers) =>
                providers.filter((provider) => {
                    const name = this.generalService.normalizeString(provider.name.toLowerCase().trim());
                    value = this.generalService.normalizeString(value.toLowerCase().trim());
                    return name.indexOf(value) != -1;
                }),
            ),
        );
    }

    private setLoadingGettingProviders(isLoadingGettingProviders: boolean) {
        this.loadingGettingProviders$.next(isLoadingGettingProviders);
    }

    isLoadingGettingTypeDocuments$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingTypeDocuments$();
    }

    isLoadingGettingPatients$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingPatients$();
    }

    getPatients$(): Observable<Patient[]> {
        return this.entitiesState.getPatients$();
    }

    getGenders$(): Observable<{ id: string; name: string }[]> {
        return of(this.genders);
    }

    getTypeDocuments$(): Observable<TypeDocument[]> {
        return this.entitiesState.getTypeDocuments$();
    }

    // this method make de request and update the state
    loadTypeDocuments(): Observable<TypeDocument[]> {
        this.entitiesState.setLoadingGettingTypeDocuments(true);
        const promise: Promise<TypeDocument[]> = new Promise((res, rej) => {
            this.patientsService.getTypeDocuments().subscribe({
                next: (typeDocuments) => {
                    this.entitiesState.setTypeDocuments(typeDocuments);
                    res(typeDocuments);
                },
                error: (e) => {
                    rej(e);

                },
                complete:
                    () => this.entitiesState.setLoadingGettingTypeDocuments(false)
            });
        });
        return from(promise);
    }

    // **HEALTH INSURANCE**

    isLoadingGettingHealthInsurance$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingHealthInsurance$();
    }
    isLoadingUpdatingHealthInsurance$(): Observable<boolean> {
        return this.entitiesState.isLoadingUpdatingHealthInsurance$();
    }
    isLoadingCreatingHealthInsurance$(): Observable<boolean> {
        return this.entitiesState.isLoadingCreatingHealthInsurance$();
    }

    getHelathInsurance$(): Observable<HealthInsurance[]> {
        return this.entitiesState.getHealthInsurance$();
    }

    getHelathInsuranceCount$(): Observable<number> {
        return this.entitiesState.getHealthInsuranceCount$();
    }

    getMetaDataHealthInsurances$(): Observable<MetaDataHealthInsurance> {
        return this.entitiesState.getMetaDataHealthInsurance$();
    }

    // this method make de request and update the state
    loadHealthInsurance(hfp: HealthInsuranceQPS) {
        this.entitiesState.setLoadingGettingHealthInsurance(true);
        console.log('Loading request GET healthInsurances ', hfp);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.patientsService
                .getHealthInsurance(hfp)
                .pipe(
                    tap((body) => this.entitiesState.setHealthInsurance(body.data)),
                    tap((body) => this.entitiesState.setPagination(body.pagination)),
                    tap(() => this.entitiesState.setLoadingGettingHealthInsurance(false)),
                    tap(() => this.entitiesState.setLoadingGettingPagination(false)),
                    tap((body) => {
                        this.entitiesState.setMetaDataHealthInsurance({ ...hfp, ...body.pagination });
                    }),
                    tap((body) =>
                        console.log(
                            'request finished. Health Insurance: ',
                            body.data,
                            'Pagination :',
                            body.pagination,
                        ),
                    ),
                )
                .subscribe({
                    next: response => res(response.data),
                    error: e => { rej(e); }
                });
        });
        return from(promise);
    }

    loadHealthInsuranceCount() {
        this.entitiesState.setLoadingGettingHealthInsuranceCount(true);
        console.log('Loading request GET healthInsurances COUNT ');
        const promise: Promise<any> = new Promise((res, rej) => {
            this.patientsService
                .getHealthInsuranceCount()
                .pipe(
                    tap((data) => this.entitiesState.setHealthInsuranceCount(data.count)),
                    tap(() => this.entitiesState.setLoadingGettingHealthInsuranceCount(false)),
                    tap((data) => console.log('request finished. Health Insurance Count: ', data.count)),
                )
                .subscribe({
                    next: () => { },
                    error: e => { rej(e); }
                });
        });
        return from(promise);
    }

    addHealthInsurance(hi: PostHealthInsuranceBody): Observable<HealthInsurance> {
        this.entitiesState.setLoadingCreatingHealthInsurance(true);
        // Mapper from CreationalProvision to PostProvisionBody must be here
        console.log(hi);
        const promise: Promise<HealthInsurance> = new Promise((res, rej) => {
            this.patientsService
                .postHealthInsurance(hi)
                .pipe(finalize(() => this.entitiesState.setLoadingCreatingHealthInsurance(false)))
                .subscribe({
                    next: newHealthInsurance => {
                        this.entitiesState.addHealthInsurance(newHealthInsurance);
                        // Update count store
                        this.loadHealthInsuranceCount();
                        res(newHealthInsurance);
                    },
                    error: e => {
                        this.entitiesState.setErrorCreatingHealthInsurance(true);
                        rej(e)
                    },
                });
        });
        return from(promise);
    }

    updateHealthInsurance(hi: PatchHealthInsuranceBody, hiId: number): Observable<HealthInsurance> {
        this.entitiesState.setLoadingUpdatingHealthInsurance(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<HealthInsurance> = new Promise((res, rej) => {
            this.patientsService
                .patchHealthInsurance(hi, hiId)
                .pipe(finalize(() => this.entitiesState.setLoadingUpdatingHealthInsurance(false)))
                .subscribe({
                    next: newHealthInsurance => {
                        this.entitiesState.updateHealthInsurance(newHealthInsurance, hiId);
                        res(newHealthInsurance);
                    },
                    error: e => {
                        this.entitiesState.setErrorUpdatingHealthInsurance(true);
                        rej(e);
                    },
                })
        });
        return from(promise);
    }

    //**FINANCIERS */

    isLoadingGettingFinanciers$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingFinanciers$();
    }
    isLoadingUpdatingFinanciers$(): Observable<boolean> {
        return this.entitiesState.isLoadingUpdatingFinancier$();
    }
    isLoadingCreatingFinanciers$(): Observable<boolean> {
        return this.entitiesState.isLoadingCreatingFinancier$();
    }

    getFinancierCount$(): Observable<number> {
        return this.entitiesState.getFinancierCount$();
    }

    getMetaDataFinanciers$(): Observable<FinanciersQPS> {
        return this.entitiesState.getMetaDataFinancier$();
    }

    getFinanciers$(): Observable<Financier[]> {
        return this.entitiesState.getFinanciers$();
    }

    // this method make de request and update the state
    loadFinanciers(ffp?: FinanciersQPS): Observable<Financier[]> {
        this.entitiesState.setLoadingGettingFinanciers(true);
        console.log('Loading request GET financiers ', ffp);
        // directly mapped from FinanciersFilterParameters to FinanciersQPS, because they are the same properties (could differ)
        const promise: Promise<Financier[]> = new Promise((res, rej) => {
            this.financiersService
                .getFinanciers(ffp)
                .pipe(
                    tap((body) => this.entitiesState.setFinanciers(body.data)),
                    tap((body) => this.entitiesState.setPagination(body.pagination)),
                    tap(() => this.entitiesState.setLoadingGettingFinanciers(false)),
                    tap(() => this.entitiesState.setLoadingGettingPagination(false)),
                    tap((body) => {
                        this.entitiesState.setMetaDataFinancier({ ...ffp, ...body.pagination });
                    }),
                    tap((body) =>
                        console.log(
                            'request finished. Financiers: ',
                            body.data,
                            'Pagination :',
                            body.pagination,
                        ),
                    ),
                )
                .subscribe({
                    next: (response) => response.data,
                    error: (e) => { rej(e); }
                });
        });
        return from(promise);
    }

    loadFinancierCount() {
        this.entitiesState.setLoadingGettingFinancierCount(true);
        console.log('Loading request GET financiers COUNT ');
        const promise: Promise<any> = new Promise((res, rej) => {
            this.financiersService
                .getFinancierCount()
                .pipe(
                    tap((data) => this.entitiesState.setFinancierCount(data.count)),
                    tap(() => this.entitiesState.setLoadingGettingFinancierCount(false)),
                    tap((data) => console.log('request finished. Financiers Count: ', data.count)),
                )
                .subscribe({
                    next: (res) => res.data,
                    error: (e) => { rej(e); }
                });
        });
        return from(promise);
    }

    addFinancier(financier: PostFinancierBody): Observable<Financier> {
        this.entitiesState.setLoadingCreatingFinancier(true);
        // Mapper from CreationalProvision to PostProvisionBody must be here
        console.log(financier);
        const promise: Promise<Financier> = new Promise((res, rej) => {
            this.financiersService
                .postFinancier(financier)
                .pipe(finalize(() => this.entitiesState.setLoadingCreatingFinancier(false)))
                .subscribe({
                    next: newFinancier => {
                        this.entitiesState.addFinancier(newFinancier);
                        //Actualizo el store del count
                        this.loadFinancierCount();
                        res(newFinancier);
                    },
                    error: e => {
                        this.entitiesState.setErrorCreatingFinancier(true);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    updateFinancier(financier: PatchFinancierBody, financierId: number): Observable<Financier> {
        this.entitiesState.setLoadingUpdatingFinancier(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Financier> = new Promise((res, rej) => {
            this.financiersService
                .patchFinancier(financier, financierId)
                .pipe(finalize(() => this.entitiesState.setLoadingUpdatingFinancier(false)))
                .subscribe({
                    next: newFinancier => {
                        this.entitiesState.updateFinancier(newFinancier, financierId);
                        res(newFinancier);
                    },
                    error: e => {
                        this.entitiesState.setErrorUpdatingFinancier(true);
                        rej(e);
                    }
                });
        });
        return from(promise);
    }

    //**PROVIDERS */

    isLoadingGettingProviders$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingProviders$();
    }
    isLoadingUpdatingProviders$(): Observable<boolean> {
        return this.entitiesState.isLoadingUpdatingProvider$();
    }
    isLoadingCreatingProviders$(): Observable<boolean> {
        return this.entitiesState.isLoadingCreatingProvider$();
    }

    getProvidersCount$(): Observable<number> {
        return this.entitiesState.getProvidersCount$();
    }

    getMetaDataProviders$(): Observable<MetaDataProviders> {
        return this.entitiesState.getMetaDataProviders$();
    }

    getProviders$(): Observable<Provider[]> {
        return this.entitiesState.getProviders$();
    }

   // This method make the request and update the state
   loadProviders(qps?: ProvidersQPS) {
      this.entitiesState.setLoadingGettingProviders(true);
      const promise: Promise<any> = new Promise((res, rej) => {
         this.providersService
            .getProviders(qps)
            .pipe(
               tap((body) => this.entitiesState.setProviders(body.data)),
               finalize(() => this.entitiesState.setLoadingGettingPagination(false)),
               finalize(() => this.entitiesState.setLoadingGettingProviders(false)),
               tap((body) => {
                  this.entitiesState.setMetaDataProviders({ ...qps, ...body.pagination });
               }),
               tap((body) =>
                  console.log(
                     'request finished. Provider: ',
                     body.data,
                     'Pagination :',
                     body.pagination,
                  ),
               ),
            )
            .subscribe(
               (response) => res(response.data),
               (e) => {
                  rej(e);
               },
            );
      });
      return from(promise);
   }

   loadProvidersbySpecialty(qps: ProvidersQPS) {
    this.entitiesState.setLoadingGettingProviders(true);
    const promise: Promise<any> = new Promise((res, rej) => {
       this.providersService
          .getProvidersBySpecialty(qps)
          .pipe(
             tap((body) => this.entitiesState.setProviders(body.data)),
             finalize(() => this.entitiesState.setLoadingGettingPagination(false)),
             finalize(() => this.entitiesState.setLoadingGettingProviders(false)),
             tap((body) => {
                this.entitiesState.setMetaDataProviders({ ...qps, ...body.pagination });
             }),
             tap((body) =>
                console.log(
                   'request finished. Provider: ',
                   body.data,
                   'Pagination :',
                   body.pagination,
                ),
             ),
          )
          .subscribe(
             (response) => res(response.data),
             (e) => {
                rej(e);
             },
          );
    });
    return from(promise);
 }

    addProvider(postBody: PostProviderBody): Observable<Provider> {
        this.entitiesState.setLoadingCreatingProvider(true);
        const promise: Promise<Provider> = new Promise((res, rej) => {
            this.providersService
                .postProvider(postBody)
                .pipe(finalize(() => this.entitiesState.setLoadingCreatingProvider(false)))
                .subscribe({
                    next: newProvider => {
                        this.entitiesState.addProvider(newProvider);
                        // Actualizo el store del count
                        this.loadProvidersCount();
                        res(newProvider);
                    },
                    error: e => {
                        this.entitiesState.setErrorCreatingProvider(true);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    updateProvider(patchBody: PatchProviderBody, entidadId: number): Observable<Provider> {
        this.entitiesState.setLoadingUpdatingProvider(true);
        const promise: Promise<Provider> = new Promise((res, rej) => {
            this.providersService
                .patchProvider(patchBody, entidadId)
                .pipe(finalize(() => this.entitiesState.setLoadingUpdatingProvider(false)))
                .subscribe({
                    next: newProvider => {
                        this.entitiesState.updateProvider(newProvider, entidadId);
                        res(newProvider);
                    },
                    error: e => {
                        this.entitiesState.setErrorUpdatingProvider(true);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    loadProvidersCount() {
        this.entitiesState.setLoadingGettingProvidersCount(true);
        console.log('Loading request GET Providers COUNT ');
        const promise: Promise<any> = new Promise((res, rej) => {
            this.providersService
                .getProvidersCount()
                .pipe(
                    tap((data) => this.entitiesState.setProvidersCount(data.count)),
                    tap(() => this.entitiesState.setLoadingGettingProvidersCount(false)),
                    tap((data) => console.log('request finished. Providers Count: ', data.count)),
                )
                .subscribe({
                    next: (response) => res(response.data),
                    error: (e) => { rej(e); }
                })
        });
        return from(promise);
    }

    getProvidersFullName(provider: Provider): string {
        return `${provider?.surname ? provider?.surname + ' ' : ''}${provider?.name}`;
    }

    getProviderSpecialties(specialties: Specialty[]): string {
        return (!!specialties) ? specialties.map(s => s.name).join(', ') : "";
    }

    //**OPERATORS */

    isLoadingGettingOperators$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingOperators$();
    }
    isLoadingUpdatingOperators$(): Observable<boolean> {
        return this.entitiesState.isLoadingUpdatingOperator$();
    }
    isLoadingCreatingOperators$(): Observable<boolean> {
        return this.entitiesState.isLoadingCreatingOperator$();
    }

    getOperators$(): Observable<Operator[]> {
        return this.entitiesState.getOperators$();
    }

    getOperatorsCount$(): Observable<number> {
        return this.entitiesState.getOperatorsCount$();
    }

    getMetaDataOperators$(): Observable<MetaDataOperators> {
        return this.entitiesState.getMetaDataOperators$();
    }

    // // this method make de request and update the state
    // loadOperators(ofp?: OperatorsQPS): Promise<Operator[]> {
    //    this.entitiesState.setLoadingGettingOperators(true);
    //    console.log('Loading request GET operators ', ofp);
    //    const promise: Promise<Operator[]> = new Promise((res, rej) => {
    //       this.operatorsService.getOperators(ofp).subscribe(
    //          (operators) => { this.entitiesState.setOperators(operators); res(operators) },
    //          (e) => { rej(e) },
    //          () => this.entitiesState.setLoadingGettingOperators(false))
    //    });
    //    return promise;
    // }

    // this method make the request and update the state
    loadOperators(qps?: OperatorsQPS) {
        this.entitiesState.setLoadingGettingOperators(true);
        console.log('Loading request GET  Operator ', qps);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.operatorsService
                .getOperators(qps)
                .pipe(
                    tap((body) => this.entitiesState.setOperators(body.data)),
                    tap(() => this.entitiesState.setLoadingGettingOperators(false)),
                    tap(() => this.entitiesState.setLoadingGettingPagination(false)),
                    tap((body) => {
                        this.entitiesState.setMetaDataOperators({ ...qps, ...body.pagination });
                    }),
                    tap((body) =>
                        console.log(
                            'request finished. Operator: ',
                            body.data,
                            'Pagination :',
                            body.pagination,
                        ),
                    ),
                )
                .subscribe({
                    next: (response) => res(response.data),
                    error: (e) => { rej(e); },
                });
        });
        return from(promise);
    }

    addOperator(postBody: PostOperatorBody): Observable<Operator> {
        this.entitiesState.setLoadingCreatingOperator(true);
        const promise: Promise<Operator> = new Promise((res, rej) => {
            this.operatorsService
                .postOperator(postBody)
                .pipe(finalize(() => this.entitiesState.setLoadingCreatingOperator(false)))
                .subscribe({
                    next: newOperator => {
                        this.entitiesState.addOperator(newOperator);
                        //Actualizo el store del count
                        this.loadOperatorsCount();
                        res(newOperator);
                    },
                    error: e => {
                        this.entitiesState.setErrorCreatingOperator(true);
                        rej(e);
                    }
                });
        });
        return from(promise);
    }

    updateOperator(patchBody: PatchOperatorBody, entidadId: number): Observable<Operator> {
        this.entitiesState.setLoadingUpdatingOperator(true);
        const promise: Promise<Operator> = new Promise((res, rej) => {
            this.operatorsService
                .patchOperator(patchBody, entidadId)
                .pipe(finalize(() => this.entitiesState.setLoadingUpdatingOperator(false)))
                .subscribe({
                    next: newOperator => {
                        this.entitiesState.updateOperator(newOperator, entidadId);
                        res(newOperator);
                    },

                    error: e => {
                        this.entitiesState.setErrorUpdatingOperator(true);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    loadOperatorsCount() {
        this.entitiesState.setLoadingGettingOperatorsCount(true);
        console.log('Loading request GET Operators COUNT ');
        const promise: Promise<any> = new Promise((res, rej) => {
            this.operatorsService
                .getOperatorsCount()
                .pipe(
                    tap((data) => this.entitiesState.setOperatorsCount(data.count)),
                    tap(() => this.entitiesState.setLoadingGettingOperatorsCount(false)),
                    tap((data) => console.log('request finished. Operators Count: ', data.count)),
                )
                .subscribe({
                    next: response => res(response.data),
                    error: e => { rej(e); },
                });
        });
        return from(promise);
    }

    // this method make de request and update the state
    loadPatients(pfp?: PatientsQPS): Observable<Patient[]> {
        this.entitiesState.setLoadingGettingPatients(true);
        console.log('Loading request GET patients ', pfp);
        const promise: Promise<Patient[]> = new Promise((res, rej) => {
            this.patientsService.getPatients(pfp).subscribe({
                next: patients => {
                    this.entitiesState.setPatients(patients);
                    res(patients);
                },
                error: e => {
                    rej(e);
                },
                complete: () => this.entitiesState.setLoadingGettingPatients(false),
            });
        });
        return from(promise);
    }

    // loadProviders(pfp?: ProvidersQPS): Observable<Provider[]> {
    //    this.entitiesState.setLoadingGettingProviders(true);
    //    console.log('Loading request GET patients ', pfp);
    //    const promise: Promise<Provider[]> = new Promise((res, rej) => {
    //       this.providersService.getProviders(pfp).subscribe(
    //          (providers) => { this.entitiesState.setProviders(providers); res(providers) },
    //          (e) => { rej(e) },
    //          () => this.entitiesState.setLoadingGettingProviders(false))
    //    });
    //    return from(promise);
    // }

    // this method make de request and update the state
    loadLocations(lfp?: LocationsQPS): Observable<Location[]> {
        this.entitiesState.setLoadingGettingLocations(true);
        const promise: Promise<Location[]> = new Promise((res, rej) => {
            this.patientsService.getLocations(lfp).subscribe({
                next: locations => {
                    this.entitiesState.setLocations(locations);
                    res(locations);
                },
                error: e => { rej(e); },
                complete: () => this.entitiesState.setLoadingGettingLocations(false),
            });
        });
        return from(promise);
    }

    loadNations(qps?: NationsQPS): Observable<Nationality[]> {
        this.entitiesState.setLoadingGettingNations(true);
        const promise: Promise<Nationality[]> = new Promise((res, rej) => {
            this.providersService.getNations(qps).subscribe({
                next: nations => {
                    this.entitiesState.setNations(nations);
                    res(nations);
                },
                error: e => {
                    rej(e);
                },
                complete: () => this.entitiesState.setLoadingGettingNations(false),
            });
        });
        return from(promise);
    }

    loadProvinces(sfp?: ProvinceQPS): Observable<Province[]> {
        this.entitiesState.setLoadingGettingProvinces(true);
        const promise: Promise<Province[]> = new Promise((res, rej) => {
            this.patientsService.getProvinces(sfp).subscribe({
                next: provinces => {
                    this.entitiesState.setProvinces(provinces);
                    res(provinces);
                },

                error: e => { rej(e); },
                complete: () => this.entitiesState.setLoadingGettingProvinces(false),
            });
        });
        return from(promise);
    }

    getProvinces$(): Observable<Province[]> {
        return this.entitiesState.getProvinces$();
    }

    getPagination$(): Observable<Pagination> {
        return this.entitiesState.getPagination$();
    }

    isLoadingGettingPagination$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingPagination$();
    }

    // ACTIVE PATIENT
    loadPatient(patientId?: number): Observable<PatientEntities> {
        this.entitiesState.setLoadingGettingPatient(true);

        console.log('Loading request GET active patient');
        const promise: Promise<PatientEntities> = new Promise((res, rej) => {
            this.patientsService
                .getPatient(patientId)
                .pipe(finalize(() => this.entitiesState.setLoadingGettingPatient(false)))
                .subscribe({
                    next: patient => res(patient),
                    error: e => { rej(e); }
                })
        });
        return from(promise);
    }

    isLoadingGettingPatient$(): Observable<boolean> {
        return this.entitiesState.isLoadingGettingPatient$();
    }
}
