import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Pagination } from '../shared/models/pagination';
import { ArrangementsState } from '../core/states/arrangements.state';
import {
    ArrangementsService,
    ArrangementsQPS,
    ArrangementItemsQPS,
    PostArrangement,
    PatchArrangement,
    PostPracticeArrangement,
    PracticesArrangementQPS,
    PracticeArrangement,
    PatchPracticeArrangement,
} from '../core/services/arrangements.service';
import { Arrangement } from '../shared/models/arrangement';
import { HttpBaseResponse } from '../shared/models/httpBaseResponse';

export interface MetaDataArrangements extends ArrangementsQPS, Pagination { }

@Injectable({
    providedIn: 'root',
})
export class ArrangementsFacade {
    constructor(
        private arrangementsState: ArrangementsState,
        private arrangementsService: ArrangementsService,
    ) { }

    // LOAD
    loadArrangements(qps?: ArrangementsQPS) {
        this.arrangementsState.setLoadingGetArrangements(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.arrangementsService
                .getArrangements(qps)
                .pipe(
                    finalize(() => {
                        this.arrangementsState.setLoadingGetArrangements(false);
                        this.arrangementsState.setLoadingGettingPagination(false)
                    })
                )
                .subscribe({
                    next: (response) => {
                        this.arrangementsState.setArrangements(response.data);
                        this.arrangementsState.setMetaDataArrangements({ ...qps, ...response.pagination });
                        resolve(response.data)
                    },
                    error: (e) => {
                        this.arrangementsState.setLoadingGetArrangements(false);
                        this.arrangementsState.setLoadingGettingPagination(false);
                        reject(e);
                    }
                });
        });
        return from(promise);
    }

    loadArrangementItems(arrangementId: number, qps?: ArrangementItemsQPS) {
        this.arrangementsState.setLoadingGetArrangementItems(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.arrangementsService
                .getArrangementItems(arrangementId, qps)
                .pipe(
                    tap((body) => this.arrangementsState.setArrangementItems(body.data)),
                    tap(() => this.arrangementsState.setLoadingGetArrangementItems(false)),
                )
                .subscribe({
                    next: response => res(response.data),
                    error: e => {
                        this.arrangementsState.setLoadingGetArrangementItems(false);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    // LOAD - Load practices from arrangement
    loadPracticesArrangement(arrangementId: number, qps: PracticesArrangementQPS) {
        this.arrangementsState.setloadingGettingPracticesArrangement(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.arrangementsService
                .getPracticesArrangement(arrangementId, qps)
                .pipe(
                    tap((body) => this.arrangementsState.setPracticesArrangement(body)),
                    finalize(() => this.arrangementsState.setloadingGettingPracticesArrangement(false)),
                )
                .subscribe({
                    next: response => res(response),
                    error: e => {
                        this.arrangementsState.setLoadingPracticesArrangement(false);
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    // ADD - CREATE - Add practice to arrangement
    addPracticeArrangement(
        practiceArrangement: PostPracticeArrangement,
    ): any | Observable<PostPracticeArrangement> {
        this.arrangementsState.setLoadingCreatingPracticeArrangement(true);

        const promise: Promise<PostPracticeArrangement> = new Promise((res, rej) => {
            this.arrangementsService
                .postPracticeArrangement(practiceArrangement)
                .pipe(
                    finalize(() => this.arrangementsState.setLoadingCreatingPracticeArrangement(false)),
                )
                .subscribe({
                    next:
                        // Add al store
                        practiceArrangement => {
                            this.arrangementsState.addPracticeArrangement(practiceArrangement);
                            res(practiceArrangement);
                        },
                    error: e => {
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    // ADD - CREATE
    addArrangement(arrangement: PostArrangement): any | Observable<Arrangement> {
        this.arrangementsState.setLoadingCreatingArrangement(true);

        const promise: Promise<Arrangement> = new Promise((res, rej) => {
            this.arrangementsService
                .postArrangement(arrangement)
                .pipe(finalize(() => this.arrangementsState.setLoadingCreatingArrangement(false)))
                .subscribe({
                    next:
                        // Add al store
                        arrangement => {
                            this.arrangementsState.addArrangement(arrangement);
                            res(arrangement);
                        },
                    error: e => {
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    // UPDATE - PATCH
    updateArrangement(arrangement: PatchArrangement): Observable<HttpBaseResponse<Arrangement>> {
        this.arrangementsState.setLoadingUpdatinArrangement(true);

        const promise: Promise<any> = new Promise((res, rej) => {
            this.arrangementsService.patchArrangement(arrangement)
                .pipe(
                    finalize(() => this.arrangementsState.setLoadingUpdatinArrangement(false)))
                .subscribe({
                    next: body => {
                        this.arrangementsState.updateArrangement(body.data, arrangement.id);
                        res(body);
                    },
                    error: e => {
                        rej(e);
                    },
                });
        });
        return from(promise);
    }

    // COUNT
    loadArrangementsCount(qps: ArrangementsQPS) {
        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.arrangementsService
                .getArrangementsCount(qps)
                .pipe(tap(body => this.arrangementsState.setArrangementsCount(body.data)))
                .subscribe({
                    next: response => resolve(response),
                    error: e => {
                        reject(e);
                    },
                });
        });
        return from(promise);
    }

    // UPDATE - PATCH
    updatePracticeArrangement(
        practiceArrangement: PatchPracticeArrangement,
    ): Observable<PracticeArrangement> {
        this.arrangementsState.setLoadingUpdatingPracticeArrangement(true);

        const promise: Promise<PracticeArrangement> = new Promise((res, rej) => {
            this.arrangementsService
                .patchPracticeArrangement(practiceArrangement)
                .pipe(
                    finalize(() => this.arrangementsState.setLoadingUpdatingPracticeArrangement(false)),
                )
                .subscribe({
                    next: pa => {
                        this.arrangementsState.updatePracticeArrangement(pa, pa.id);
                        res(pa);
                    },
                    error: e => rej(e),
                });
        });
        return from(promise);
    }

    // STATES FROM STORE
    // Arrangements
    isLoadingGetArrangements$(): Observable<boolean> {
        return this.arrangementsState.isLoadingGetArrangements$();
    }

    isLoadingCreatingArrangement$(): Observable<boolean> {
        return this.arrangementsState.isLoadingCreatingArrangement$();
    }

    isLoadingUpdatingArrangement$(): Observable<boolean> {
        return this.arrangementsState.isLoadingUpdatingArrangement$();
    }

    // Practices Arrangement
    isLoadingGettingPracticesArrangement$(): Observable<boolean> {
        return this.arrangementsState.isLoadingGettingPracticesArrangement$();
    }

    isLoadingCreatingPracticeArrangement$(): Observable<boolean> {
        return this.arrangementsState.isLoadingCreatingPracticeArrangement$();
    }

    isLoadingUpdatingPracticeArrangement$(): Observable<boolean> {
        return this.arrangementsState.isLoadingUpdatingPracticeArrangement$();
    }

    // Metadata
    getMetaDataArrangements$(): Observable<MetaDataArrangements> {
        return this.arrangementsState.getMetaDataArrangements$();
    }

    getArrangements$(): Observable<Arrangement[]> {
        return this.arrangementsState.getArrangements$();
    }

    // Count
    getArrangementsCount$(): Observable<number> {
        return this.arrangementsState.getArrangementsCount$();
    }

    // Items
    isLoadingGetArrangementItems$(): Observable<boolean> {
        return this.arrangementsState.isLoadingGetArrangementItems$();
    }

    getPracticesArrangement$(): Observable<PracticeArrangement[]> {
        return this.arrangementsState.getPracticesArrangement$();
    }
}
