import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdmissionDashboardComponent } from './pages/dashboard/admission-dashboard.component';
import { AdmissionCreationComponent } from './pages/admission-creation/admission-creation.component';


const routes: Routes = [
   {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
   },
   {
      path: 'dashboard',
      component: AdmissionDashboardComponent,
      data: { breadcrumb: 'Dashboard' }
   },
   {
      path: 'crear',
      component: AdmissionCreationComponent,
      data: { breadcrumb: 'Crear' }
   },
   {
      path: 'listado',
      component: AdmissionDashboardComponent,
      data: { breadcrumb: 'Listado' }
   },
   {
      path: 'archivadas',
      component: AdmissionDashboardComponent,
      data: { breadcrumb: 'Archivadas' }
   },
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class AdmissionsRoutingModule { }
