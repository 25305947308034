import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Pagination } from '../../models/pagination';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input() title: string;
  @Input() elevated = false;
  @Input() showPaginator: boolean = true;
  @Input() metaData: Pagination;
  @Input() checkHasNextPage;
  @Output('page') onPage = new EventEmitter<PageEvent>();
  @Output() hasNextPage = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!!this.paginator) {
      setTimeout(() => {
        this.hasNextPage.emit(this.paginator.hasNextPage())
      }, 100)
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.setDataSourceAttributes();

  }

  onPaginatorEvent(ev: PageEvent) {
    this.onPage.emit(ev);
    this.hasNextPage.emit(this.paginator.hasNextPage())

  }

  setDataSourceAttributes() {
    if (!!this.paginator) {
      this.paginator._intl.itemsPerPageLabel = 'Registros por página';
      this.paginator._intl.firstPageLabel = 'Primera página';
      this.paginator._intl.lastPageLabel = 'Última página';
      this.paginator._intl.nextPageLabel = 'Página siguiente';
      this.paginator._intl.previousPageLabel = 'Página anterior';
      this.hasNextPage.emit(this.paginator.hasNextPage())
      // console.log(this.paginator.hasNextPage());

    }
  }

}
