<div class="flex-row">
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <input matInput [formControlName]="name" id="{{ this.name }}" name="{{ name }}" type="email"
            placeholder="{{ placeholder }}" [readonly]="readonly" [attr.disabled]="disabled ? '' : null" />
        <button tabIndex="-1" [disabled]="!this.form.get(this.name)?.value || this.form.get(this.name)?.disabled"
            type="button" mat-button *ngIf="this.clearable" matSuffix mat-icon-button aria-label="Clear"
            (click)="this.form.get(this.name)?.reset()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="
             this.form.get(this.name)?.errors &&
             (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
          ">
            {{ getError() }}
        </mat-error>
    </mat-form-field>
</div>
