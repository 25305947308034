<form class="flex-row align-center-start gap-7p mts-3" *ngIf="filterForm" [formGroup]="filterForm">
    <!-- FILTRO -->
    <!-- Select patients -->
    <app-select-searching-dynamic-input class="flex-1-auto-gt-md flex-50-gt-xs" [leftIconSvg]='"patient"'
        [leftIconClass]="'text-primary'" (selected)="this.onSelectPatient($event)" [name]="this.PATIENT"
        [nameSearching]="this.PATIENT_SEARCHING" [form]="this.filterForm"
        [placeholder]="'Ingrese el nombre/apellido del paciente'" [calculateId]="this.calculateIdPatient"
        [calculateName]="this.calculateNamePatient" [placeholderNoValue]="'Sin resultados'"
        [placeholderSearching]="'Buscar paciente...'" [httpGet]="this.getPatientsByNameAndSurname">
    </app-select-searching-dynamic-input>
    <!-- FIN FILTRO -->
</form>
