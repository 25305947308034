import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { APIS } from '../enums/apis';
import { Provider } from 'src/app/shared/models/provider';
import { RestUtilitiesService } from './rest-utilities.service';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { Specialty } from 'src/app/shared/models/specialty';
import { Address } from 'src/app/shared/models/address';
import { Nationality } from 'src/app/shared/models/nationality';
import { Location } from 'src/app/shared/models/location';

export interface ProviderLocation {
   id?: number;
   location: Location;
   latitude?: number;
   longitude?: number
 }

export interface ProvidersQPS {
   active?: boolean;
   caseId?: number,
   expand?: string[],
   fullName?: string;
   fullName_like?: string;
   isCorporate?: boolean,
   isFilteredByRegion?: boolean
   isSupply?: boolean;
   page?: number,
   size?: number,
   specialtyId?: number;
}

export interface NationsQPS {
   name_like?: string;
}

export interface PostProviderBody {
   name: string;
   cuit?: number;
   surname?: string;
   mobilePhoneNumber?: string;
   address?: Address;
   photo?: string;
   specialties?: Specialty[];
}

export interface PatchProviderBody {
   active?: boolean;
}


@Injectable({
   providedIn: 'root',
})
export class ProvidersService {
   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getProviders(qps?: ProvidersQPS): Observable<HttpBaseResponse<Provider[]>> {
      if (!qps) { qps = { active: true } }
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         /**
          * !Ojo que falta el .data
          */
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               response.body.data = this.adaptProviders(response.body.data);
               return response.body;
            }),
         );
   }

   getProvidersBySpecialty(qps?: ProvidersQPS): Observable<HttpBaseResponse<Provider[]>> {
    if (!qps) { qps = { active: true } }
    const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
    const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
    const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
    return this.http
       .get<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}`, {
          headers: queryHeaders,
          observe: 'response',
          params: queryParams,
       })
       /**
        * !Ojo que falta el .data
        */
       .pipe<any>(
          map<HttpResponse<any>, any>((response) => {
             response.body.data = this.adaptProviders(response.body.data);
             return response.body;
          }),
       );
 }

   private adaptProviders(providers: Provider[]) {
      return providers.map(
         provider => {
            provider.name = provider.name.replace(",", "");
            return provider;
         });
   }

   postProvider(entidad: PostProviderBody): Observable<Provider> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}`, JSON.stringify(entidad), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchProvider(entidad: PatchProviderBody, entidadId) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiProviderServer}${APIS.PROVIDERS}/${entidadId}`,
            JSON.stringify(entidad),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getProvidersCount(): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}/count`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }


   public getSignaturePhoto(providerId: number): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}/${providerId}/signature`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getMediaByProviderId(providerId: number): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiProviderServer}${APIS.PROVIDERS}/${providerId}/media`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getNations(qps: NationsQPS): Observable<Nationality[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiProviderServer}/user/nations`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

}
