export enum RequisitionStates{
    NONE = "",
    DRAFT = "Borrador",                               // 1
    WITHOUT_WARNING = "Sin Aviso",
    WAITING_FOR_INFO = "Esperando Info",
    SEARCHING_RESOURCES = "Buscando Recursos",
    CONFIRMED = "Confirmado",
    BUDGETED = "Presupuestado",
    PENDING = "Pendiente",
    ARCHIVED = "Archivado"                            // 8
}