import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Attention } from 'src/app/shared/models/attention';
import { BaseState } from './base.state';
import { MetaDataAttentions } from 'src/app/abstraction/attentions.facade';
import { Pagination } from 'src/app/shared/models/pagination';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import { Liquidation } from 'src/app/shared/models/liquidation';
import { LiquidationsQPS } from '../services/attentions.service';
import { StateLiquidation } from 'src/app/shared/models/stateLiquidation';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';

export interface MetaDataLiquidations extends LiquidationsQPS, Pagination { }
@Injectable({
   providedIn: 'root',
})
export class AttentionsState extends BaseState {

   override store = {
      metaDataAttentions$: new BehaviorSubject<MetaDataAttentions>(null),
      attentions$: new BehaviorSubject<Attention[]>(null),
      loadingGetAttentions$: new BehaviorSubject<boolean>(false),
      auditStatesAttention$: new BehaviorSubject<AuditStatesAttention>(null),
      loadingGetAuditStatesAttention$: new BehaviorSubject<boolean>(false),
      scheduledAttentions$: new BehaviorSubject<number>(null), // Indicator
      loadingGetScheduledAttentions$: new BehaviorSubject<boolean>(false), // Indicator flag
      pagination$: new BehaviorSubject<Pagination>(null),
      loadingGettingPagination$: new BehaviorSubject<boolean>(false),
      loadingPatchingAttention$: new BehaviorSubject<boolean>(false),
      loadingCreatingAttention$: new BehaviorSubject<boolean>(false),
      loadingRemovingAttention$: new BehaviorSubject<boolean>(false),
      loadingDeleteBatchAttentions$: new BehaviorSubject<boolean>(false),

      // LIQUIDATIONS
      loadingGetStatesLiquidation$: new BehaviorSubject<boolean>(false),
      statesLiquidation$: new BehaviorSubject<StateLiquidation[]>(null),

      //HEALTH INSURANCE
      liquidations$: new BehaviorSubject<Liquidation[]>(null),
      liquidationsCount$: new BehaviorSubject<number>(null),
      loadingGettingLiquidationsCount$: new BehaviorSubject<boolean>(false),
      loadingGettingLiquidations$: new BehaviorSubject<boolean>(false),
      metaDataLiquidations$: new BehaviorSubject<MetaDataLiquidations>(null),
      loadingCreatingLiquidation$: new BehaviorSubject<boolean>(false),
      errorCreatingLiquidation$: new BehaviorSubject<boolean>(false),
      loadingUpdatingLiquidation$: new BehaviorSubject<boolean>(false),
      errorUpdatingLiquidation$: new BehaviorSubject<boolean>(false),

      // CONSENT - CONSENTIMIENTO
      loadingUpdatingConsent$: new BehaviorSubject<boolean>(false),
      loadingGettingConsent$: new BehaviorSubject<boolean>(false),

      // CASE-INDICATOR
      attentionsCaseActive$ : new BehaviorSubject<Attention[]>(null),

   };

   constructor() {
      super();
   }

   isLoadingGetAttentions$() {
      return this.store.loadingGetAttentions$.asObservable();
   }

   setLoadingGetAttentions(isLoadingGetAttentions: boolean) {
      this.store.loadingGetAttentions$.next(isLoadingGetAttentions);
   }

   isLoadingGetAuditStatesAttention$() {
      return this.store.loadingGetAuditStatesAttention$.asObservable();
   }

   setLoadingGetAuditStatesAttention(isLoading: boolean) {
      this.store.loadingGetAuditStatesAttention$.next(isLoading);
   }

   isLoadingGetStatesLiquidation$() {
      return this.store.loadingGetStatesLiquidation$.asObservable();
   }

   setLoadingGetStatesLiquidation(isLoading: boolean) {
      this.store.loadingGetStatesLiquidation$.next(isLoading);
   }

   isLoadingGettingPagination$() {
      return this.store.loadingGettingPagination$.asObservable();
   }

   setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
      this.store.loadingGettingPagination$.next(isLoadingGettingPagination);
   }

   isLoadingPatchingAttention$() {
      return this.store.loadingPatchingAttention$.asObservable();
   }

   setLoadingPatchingAttention(isLoadingPatchingAttention: boolean) {
      this.store.loadingPatchingAttention$.next(isLoadingPatchingAttention);
   }

   isLoadingCreatingAttention$() {
      return this.store.loadingCreatingAttention$.asObservable();
   }

   setLoadingCreatingAttention(isLoadingCreatingAttention: boolean) {
      this.store.loadingCreatingAttention$.next(isLoadingCreatingAttention);
   }

   isLoadingRemovingAttention$() {
      return this.store.loadingRemovingAttention$.asObservable();
   }

   setLoadingRemovingAttention(isLoadingRemovingAttention: boolean) {
      this.store.loadingRemovingAttention$.next(isLoadingRemovingAttention);
   }

   getAttentions$(): Observable<Attention[]> {
      return this.store.attentions$.asObservable();
   }

   resetAttentions(){
      this.store.attentionsCaseActive$.next(null);
   }

   getAttentions(): Attention[] {
      return this.store.attentions$.getValue();
   }

  getAttentionsCaseActive(): Attention[] {
    return this.store.attentionsCaseActive$.getValue();
  }

   setAttentions(attentions: Attention[]) {
      this.store.attentions$.next( attentions );
   }

   getAttentionsCaseActive$(): Observable<Attention[]> {
      return this.store.attentionsCaseActive$.asObservable();
   }

   setAttentionsCaseActive(attentions: Attention[]) {
      this.store.attentionsCaseActive$.next( !!attentions ? [...attentions] : null );
   }

   updateAttentionsCaseActive( attentionsUpdate: Partial<Attention[]> ) {
      for (let a of attentionsUpdate) {
         this.update({ data: a, dataId: a.id, storeRefAttribute: this.store.attentionsCaseActive$ })
      }
   }

   getAuditStatesAttention$(): Observable<AuditStatesAttention> {
      return this.store.auditStatesAttention$.asObservable();
   }

   setAuditStatesAttention(states: AuditStatesAttention) {
      this.store.auditStatesAttention$.next(states);
   }

   getStatesLiquidation$(): Observable<StateLiquidation[]> {
      return this.store.statesLiquidation$.asObservable();
   }

   setStatesLiquidation(states: StateLiquidation[]) {
      this.store.statesLiquidation$.next(states);
   }

   getMetaDataAttentions$(): Observable<MetaDataAttentions> {
      return this.store.metaDataAttentions$.asObservable();
   }

   setMetaDataAttentions(metaDataAttentions: MetaDataAttentions) {
      this.store.metaDataAttentions$.next(metaDataAttentions);
   }

   updateMetaDataAttentions(metaDataAttentions: Partial<MetaDataAttentions>) {
      const md = this.store.metaDataAttentions$.getValue();
      this.store.metaDataAttentions$.next({ ...md, ...metaDataAttentions });
   }

   getPagination$(): Observable<Pagination> {
      return this.store.pagination$.asObservable();
   }

   setPagination(pagination: Pagination) {
      this.store.pagination$.next(pagination);
   }

   // **INDICATORS**

   isLoadingGetScheduledAttentions$() {
      return this.store.loadingGetScheduledAttentions$.asObservable();
   }

   setLoadingGetScheduledAttentions(boolean: boolean) {
      this.store.loadingGetAttentions$.next(boolean);
   }

   getScheduledAttentions$(): Observable<number> {
      return this.store.scheduledAttentions$.asObservable();
   }

   setScheduledAttentions(number: number) {
      this.store.scheduledAttentions$.next(number);
   }

   // ** LIQUIDATIONS **

   getLiquidations$(): Observable<Liquidation[]> {
      return this.store.liquidations$.asObservable();
   }

   setLiquidations(liquidations: Liquidation[]) {
      this.store.liquidations$.next(liquidations);
   }

   isLoadingGettingLiquidations$() {
      return this.store.loadingGettingLiquidations$.asObservable();
   }

   setLoadingGettingLiquidations(loading: boolean) {
      this.store.loadingGettingLiquidations$.next(loading);
   }

   isLoadingGettingLiquidationsCount$() {
      return this.store.loadingGettingLiquidationsCount$.asObservable();
   }

   setLoadingGettingLiquidationsCount(loading: boolean) {
      this.store.loadingGettingLiquidationsCount$.next(loading);
   }

   getMetaDataLiquidations$(): Observable<MetaDataLiquidations> {
      return this.store.metaDataLiquidations$.asObservable();
   }

   setMetaDataLiquidations(metaDataLiquidation: MetaDataLiquidations) {
      this.store.metaDataLiquidations$.next(metaDataLiquidation);
   }

   updateMetaLiquidation(metaDataLiquidation: LiquidationsQPS) {
      this.update<LiquidationsQPS>({ data: metaDataLiquidation, storeRefAttribute: this.store.metaDataLiquidations$ })
   }

   getLiquidation$(): Observable<Liquidation[]> {
      return this.store.liquidations$.asObservable();
   }

   setLiquidation(liquidation: Liquidation[]) {
      this.store.liquidations$.next(liquidation);
   }

   getLiquidationsCount$(): Observable<number> {
      return this.store.liquidationsCount$.asObservable();
   }

   setLiquidationsCount(liquidationCount: number) {
      this.store.liquidationsCount$.next(liquidationCount);
   }


   isLoadingCreatingLiquidation$() {
      return this.store.loadingCreatingLiquidation$.asObservable();
   }

   setLoadingCreatingLiquidation(isLoadingCreatingLiquidation: boolean) {
      this.store.loadingCreatingLiquidation$.next(isLoadingCreatingLiquidation);
   }

   isErrorCreatingLiquidation$() {
      return this.store.errorCreatingLiquidation$.asObservable();
   }

   setErrorCreatingLiquidation(isErrorCreatingCase: boolean) {
      this.store.errorCreatingLiquidation$.next(isErrorCreatingCase);
   }

   addLiquidation(newHi: Liquidation) {
      this.add<Liquidation>({ data: newHi, storeRefAttribute: this.store.liquidations$ })
   }

   isLoadingUpdatingLiquidation$() {
      return this.store.loadingUpdatingLiquidation$.asObservable();
   }

   setLoadingUpdatingLiquidation(loadingUpdatingLiquidation: boolean) {
      this.store.loadingUpdatingLiquidation$.next(loadingUpdatingLiquidation);
   }

   isErrorUpdatingLiquidation$() {
      return this.store.errorUpdatingLiquidation$.asObservable();
   }

   setErrorUpdatingLiquidation(isErrorUpdatingLiquidation: boolean) {
      this.store.errorUpdatingLiquidation$.next(isErrorUpdatingLiquidation);
   }

   updateLiquidation(liquidationUpdate: Partial<Liquidation>, liquidationId: number) {
      this.update<Partial<Liquidation>>({ data: liquidationUpdate, dataId: liquidationId, storeRefAttribute: this.store.liquidations$ })
   }

   updateAttention(attentionUpdate: Partial<Attention>, attentionId: number) {
      this.update<Partial<Attention>>({ data: attentionUpdate, dataId: attentionId, storeRefAttribute: this.store.attentions$ })
   }

   updateAttentionCaseActive(attentionUpdate: Partial<Attention>, attentionId: number) {
      this.update<Partial<Attention>>({ data: attentionUpdate, dataId: attentionId, storeRefAttribute: this.store.attentionsCaseActive$ })
   }

   updateAttentions(attentionsUpdate: Partial<Attention[]>) {
      for (let a of attentionsUpdate) {
         this.update({ data: a, dataId: a.id, storeRefAttribute: this.store.attentions$ })
      }
   }

   addAttention(attention: Partial<Attention>) {
      this.add<Partial<Attention>>({ data: attention, storeRefAttribute: this.store.attentions$ })
   }

   addAttentionCaseActive(attention: Partial<Attention>) {
      this.add<Partial<Attention>>({ data: attention, storeRefAttribute: this.store.attentionsCaseActive$ })
   }

   deleteAttention(attention : Attention) {
      this.delete<Partial<Attention[]>>({ dataId: attention.id, storeRefAttribute: this.store.attentions$});
   }

   deleteAttentionById(id : number) {
      this.delete<Partial<Attention[]>>({ dataId: id, storeRefAttribute: this.store.attentions$});
   }

   deleteAttentionCaseActive(attention : Attention) {
      this.delete<Partial<Attention[]>>({ dataId: attention.id, storeRefAttribute: this.store.attentionsCaseActive$});
   }

   deleteAttentionsByAgreementId( agreementId: number ) {
      let newAtt: Attention[];
      // attentionsCaseActive$
      newAtt = this.store.attentionsCaseActive$.getValue().filter( att => att.agreementId != agreementId && att.state.id === AttentionStates.AGENDADA );
      this.store.attentionsCaseActive$.next( newAtt );
      // attentions$
      newAtt = this.store.attentions$.getValue().filter( att => att.agreementId != agreementId && att.state.id === AttentionStates.AGENDADA );
      this.store.attentions$.next( newAtt );
   }

   // Consent - Consentimiento
   setLoadingGettingConsent( loadingGettingConsent: boolean ) {
      this.store.loadingGettingConsent$.next( loadingGettingConsent );
   }

   isLoadingGettingConsent$() {
      return this.store.loadingGettingConsent$.asObservable();
   }

   setLoadingUpdatingConsent( loadingUpdatingConsent: boolean ) {
      this.store.loadingUpdatingConsent$.next( loadingUpdatingConsent );
   }

   isLoadingUpdatingConsent$() {
      return this.store.loadingUpdatingConsent$.asObservable();
   }

   // Delete batch attentions
   isLoadingDeleteBatchAttentions$() {
      return this.store.loadingDeleteBatchAttentions$.asObservable();
   }

  setLoadingDeleteBatchAttentions( loadingDeleteBatchAttentions: boolean ) {
    this.store.loadingDeleteBatchAttentions$.next( loadingDeleteBatchAttentions );
  }

  putAttentionCaseActive( attention: Attention ) {
    const attentionsCaseActive = this.store.attentionsCaseActive$.getValue();
    if (attentionsCaseActive.findIndex( att => att.id == attention.id ) != -1) {
      // Update
      this.updateAttentionCaseActive( attention, attention.id )
    } else {
      // Add
      this.addAttentionCaseActive( attention )
    }
  }

  putAttentionsCaseActive( attentions: Attention[] ) {
    for (let att of attentions ) {
      this.putAttentionCaseActive( att );
    }
  }

  putAttention( attention: Attention ) {
    const attentions = this.store.attentions$.getValue();
    if (attentions.findIndex( att => att.id == attention.id ) != -1) {
      // Update
      this.updateAttention( attention, attention.id )
    } else {
      // Add
      this.addAttention( attention )
    }
  }

  putAttentions( attentions: Attention[] ) {
    for (let att of attentions ) {
      this.putAttention( att );
    }
  }
}
