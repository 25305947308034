import { CommonModule } from '@angular/common';
import { Component, input, OnInit, effect, ElementRef, ViewChild, model } from '@angular/core';
import { FormsModule } from 'src/app/shared/components/forms/forms.module';
import { Province } from 'src/app/shared/models/province';
import { Location } from 'src/app/shared/models/location';
import { Subscription } from 'rxjs';
import { LocationPOI } from 'src/app/shared/models/maps/locationPOI';
import { MaterialModule } from 'src/app/material/material.module';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { MapsFacade } from 'src/app/abstraction/maps.facade';
import { MapsService } from 'src/app/shared/services/maps.service';

@Component({
    selector: 'app-finder-location-map',
    standalone: true,
    imports: [CommonModule, FormsModule, MaterialModule],
    templateUrl: './finder-location-map.component.html',
    styleUrl: './finder-location-map.component.scss'
})

export class LocationMapComponent implements OnInit {

    // ADDRESS FROM CASE
    inputPOI = input<LocationPOI>();
    // ADRESS FROM SIDEBAR
    center = model<MarkerGoogleMaps>();
    disabled = input<boolean>(false);

    @ViewChild('addressinput', { static: false }) addressinputRef: ElementRef<HTMLInputElement>;

    // form: FormGroup;
    provincesOnSelect: Province[]; _provinces: Subscription;
    locations: Location[]; _locations: Subscription;
    provinceSelected: Province;
    locationSelected: Location;
    actualLocation;
    actualProvince;



    constructor(
        private mapsFacade: MapsFacade,
        public mapsService: MapsService,
    ) {
        effect(() => {
            // console.log('effet -> finder-location-map');
            if (!!this.inputPOI()) {
                this.addressinputRef.nativeElement.value = this.inputPOI().address;
            }
        })
    }

    ngAfterViewInit(): void {
             if(this.mapsService.loadApi){
            this.initializeAutocomplete();

        }
    }

    ngOnInit(): void {
        // if(this.mapsService.loadApi){
        //     this.initializeAutocomplete();

        // }

    }


    private initializeAutocomplete(): void {
        const options = {
            componentRestrictions: { country: "ar" }, // Restricción a Argentina
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
        };

        // Pasamos las opciones al Autocomplete
        const autocomplete = new google.maps.places.Autocomplete(this.addressinputRef.nativeElement, options);

        autocomplete.addListener('place_changed', () => {
            this.mapsService.deleteMarkersByType(TypeMapMarker.GENERAL);
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                const markers = [];
                markers.push({ type: TypeMapMarker.GENERAL, lat: location.lat(), lng: location.lng() });
                this.mapsFacade.mergeMarkers(markers);
                this.center.set({ type: TypeMapMarker.GENERAL, lat: location.lat(), lng: location.lng() });
            } else {
                console.error('The place has no geometry data');
            }
        });
    }


    handleAddressSelection(lat: number, lng: number, place: google.maps.places.PlaceResult): void {
        console.log('Manejando selección de dirección:', place.formatted_address);
        // Lógica adicional para manejar la selección de la dirección
    }

    onEnterPress(): void {
        const inputValue = this.addressinputRef.nativeElement.value;
        console.log('Dirección ingresada:', inputValue);
        // Puedes llamar a una función para manejar el valor ingresado
        this.searchAddress(inputValue);
    }

    searchAddress(address: string): void {
        console.log('Buscando dirección:', address);
        // Implementa la lógica de búsqueda o consulta de API aquí
    }

    selectAllText(): void {
        setTimeout(() => {
            this.addressinputRef.nativeElement.select();
        }, 0);
    }
}
