<div class="flex-column">
    <app-card-table [elevated]="true" [metaData]="this.metaDataAttentions" [title]="'Consolidación de atenciones'"
        (page)="onPaginatorEvent($event)" (hasNextPage)="this.hasMorePages = $event">
        <div filterInputs class="mat-elevation-z2 mys-4 pxs-3 pbs-3">
            <mat-tab-group dynamicHeight [disableRipple]="false" mat-stretch-tabs="false" mat-align-tabs="center">
                <mat-tab label="Filtros">
                    <!-- Filters -->
                    <form class="flex-column mts-3" *ngIf="filterForm" [formGroup]="filterForm">
                        <div class="flex-row flex-wrap align-space-between-center mb-0 mxs-3">
                            <!-- Select providers -->
                            <div class="flex-column pr-2 flex-30">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Prestador</b>
                                </span>
                                <app-select-searching-dynamic-input class="flex-auto" [name]="this.PROVIDER"
                                    [nameSearching]="this.PROVIDER_SEARCHING" [form]="this.filterForm"
                                    [placeholder]="'Seleccionar'" [calculateId]="this.calculateIdProvider"
                                    [calculateName]="this.calculateNameProvider" [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar prestador...'"
                                    [httpGet]="this.getProvidersByFullName">
                                </app-select-searching-dynamic-input>
                            </div>
                            <!-- Select patients -->
                            <div class="flex-column pr-2 flex-30">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Paciente</b>
                                </span>
                                <app-select-searching-dynamic-input class="flex-auto" [name]="this.PATIENT"
                                    [nameSearching]="this.PATIENT_SEARCHING" [form]="this.filterForm"
                                    [placeholder]="'Seleccionar'" [calculateId]="this.calculateIdPatient"
                                    [calculateName]="this.calculateNamePatient" [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar paciente...'"
                                    [httpGet]="this.getPatientsByNameAndSurname"
                                    [defaultValue]="!!this.patientOnSelect ? this.actualPatient[0] : null"
                                    [elements]="!!this.patientOnSelect ? this.patientOnSelect[0] : null">
                                </app-select-searching-dynamic-input>
                            </div>
                            <!-- Select financiers  -->
                            <div class="flex-column pr-2 flex-30">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Financiador</b>
                                </span>
                                <app-select-searching-dynamic-input class="flex-auto" [name]="this.FINANCIER"
                                    [nameSearching]="this.FINANCIER_SEARCHING" [form]="this.filterForm"
                                    [placeholder]="'Seleccionar'" [calculateId]="this.calculateIdFinancier"
                                    [calculateName]="this.calculateNameFinancier"
                                    [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar financiador...'"
                                    [httpGet]="this.getFinanciersByName"
                                    [defaultValue]="!!this.financierOnSelect ? this.actualFinancier[0] : null"
                                    [elements]="!!this.financierOnSelect ? this.financierOnSelect[0] : null">
                                </app-select-searching-dynamic-input>
                            </div>
                        </div>
                        <div class="flex-row flex-wrap align-center-center gap-1rem">
                            <!-- Select fromDate -->
                            <div class="flex-column pr-2">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Desde</b>
                                </span>
                                <div class="flex-row align-start-center">
                                    <app-date-picker [name]="this.FROM_DATE" [form]="this.filterForm"
                                        [placeholder]="'Fecha desde'">
                                    </app-date-picker>
                                </div>
                            </div>
                            <!-- Select ToDate -->
                            <div class="flex-column pr-2">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Hasta</b>
                                </span>
                                <div class="flex-row align-start-center">
                                    <app-date-picker [name]="this.TO_DATE" [form]="this.filterForm"
                                        [placeholder]="'Fecha hasta'">
                                    </app-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row align-space-around-center">
                            <app-checkbox-input [form]="filterForm" [label]="'Informadas'"
                                [name]="this.INFORMED_STATE_ATTENTION">
                            </app-checkbox-input>
                            <app-checkbox-input [form]="filterForm" [label]="'No Informadas'"
                                [name]="this.NOT_INFORMED_STATE_ATTENTION">
                            </app-checkbox-input>
                            <app-checkbox-input [form]="filterForm" [label]="'Facturables'"
                                [name]="this.BILLABLE_STATE_ATTENTION">
                            </app-checkbox-input>
                            <app-checkbox-input [form]="filterForm" [label]="'Debitadas'"
                                [name]="this.DEBITED_STATE_ATTENTION">
                            </app-checkbox-input>
                            <app-checkbox-input [form]="filterForm" [label]="'En Proceso'"
                                [name]="this.IN_PROCESS_STATE_ATTENTION">
                            </app-checkbox-input>
                        </div>
                    </form>
                    <!-- Buttons -->
                    <div *ngIf="this.filterForm" class="mts-3 flex-row align-center">
                        <button *ngIf="this.patientForm || this.financierForm || this.providerForm || this.toDateForm || this.fromDateForm ||
                            this.informedAttentionForm || this.notInformedAttentionForm ||this.billableAttentionForm ||this.debitedAttentionForm || this.inProcessAttentionForm"
                            mat-stroked-button [color]="'primary'" (click)="resetValuesDialog()" class="mx-3"
                            id="Consolidations_btnRemoveFilters">
                            BORRAR FILTROS
                        </button>
                        <button (click)="onFilterAttentions()" mat-flat-button [color]="'primary'" type="submit" id="Consolidations_btnSearch">BUSCAR</button>
                    </div>
                </mat-tab>
                <mat-tab label="Liquidaciones">
                    <form class="flex-column mts-4" *ngIf="filterLiquidationForm" [formGroup]="filterLiquidationForm"
                        (submit)="this.onSearchAttentionsByLiquidation()">
                        <div class="flex-row align-space-evenly-center gap-1rem">
                            <!-- Select financiers -->
                            <div class="flex-column pr-2 flex-30">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Financiador</b>
                                </span>
                                <app-select-searching-dynamic-input class="flex-auto" [name]="this.FINANCIER"
                                    (selected)="this.onSelectFinancierLiquidation($event)"
                                    [nameSearching]="this.FINANCIER_SEARCHING" [form]="this.filterLiquidationForm"
                                    [placeholder]="'Seleccionar'" [calculateId]="this.calculateIdFinancier"
                                    [calculateName]="this.calculateNameFinancier"
                                    [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar financiador...'"
                                    [httpGet]="this.getFinanciersByName">
                                </app-select-searching-dynamic-input>
                            </div>
                            <!-- Select liquidation -->
                            <div class="flex-column pr-2 flex-30">
                                <span class="subtitle-4 text-primary-darker">
                                    <b>Liquidación</b>
                                </span>
                                <app-select-searching-input #selectLiquidations class="flex-auto"
                                    [disabled]="!this.filterLiquidationForm.get(this.FINANCIER).value"
                                    [name]="this.LIQUIDATION" [nameSearching]="this.LIQUIDATION_SEARCHING"
                                    [form]="this.filterLiquidationForm" [placeholder]="'Buscar liquidación'"
                                    [calculateId]="this.calculateIdFinancier"
                                    [calculateName]="this.calculateNameFinancier"
                                    [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar liquidación...'" [elements]="this.liquidations">
                                </app-select-searching-input>
                            </div>
                        </div>
                        <div class="flex-row align-center-center gap-5 mts-2">
                            <button id="btn_consolidationComponentBuscar" mat-flat-button type="submit" color="primary"
                                [disabled]="!this.filterLiquidationForm.valid">BUSCAR</button>
                            <button mat-flat-button type="button" color="primary"
                                [disabled]="!this.filterLiquidationForm.valid || this.loadingPdf"
                                (click)="onGeneratePdf()">
                                <span *ngIf="!this.loadingPdf; else loadingIcon">GENERAR PDF</span>
                            </button>
                            <ng-template #loadingIcon>
                                <mat-icon><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
                            </ng-template>
                        </div>
                    </form>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div table class="mat-elevation-z2">
            @if(isLoadingGetAttentions) {
                <app-spinner></app-spinner>
            }
            <div class="mts-2 flex mat-stretch-tabs" *ngIf="this.attentions">
                <div class="flex-column align-center-none table-container full-width">
                    <app-attentions-list
                        [title]="this.metaDataAttentions && !!this.metaDataAttentions.liquidationId ? (this.attentions.length == 0 ? 'Atenciones filtradas por liquidación' : 'Atenciones de la liquidación: ' + this.attentions[0]?.liquidation?.name):'Atenciones por filtro manual'"
                        [ruleClickable]="this.attentionsService.isInformable" class="flex-100"
                        (onClickButton1)="this.onClickInformAttentions($event)" [textButton1]="'INFORMAR'"
                        [colorButton1]="'primary'" [infoTextButton1]="''"
                        [attentions]="this.filterDeliveryPractice()"
                        [textNoAttentions]="'No existen atenciones a consolidar para este mes según los filtros establecidos'"
                        [loading]="this.isLoadingGetAttentions" [showMonth]="false" [paginatorOn]='false'
                        [scrollOn]='false' [paginatorServerSide]="true" [clearSelection]="this.clearSelection"
                        [noSort]='true' [showReload]="false"
                        class="full-width">
                    </app-attentions-list>
                    @if(hasMorePages) {
                        <div class="flex-row mts-3" class="align-center-center full-width flex">
                            <app-warning-icon style="display: flex; width: 50px;"></app-warning-icon>
                            <span class="text-secondary-darker">Aún tiene páginas por recorrer</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    </app-card-table>
</div>

<ng-template #reportTemplate>
    <!-- Spinner -->
    <div class="spinner-hover" *ngIf="this.informingAttentions">
        <mat-spinner color="primary" diameter="40"></mat-spinner>
    </div>
    <div class="text-secondary align-start-center">
        <mat-icon class="mat-icon-small">info</mat-icon>
        Seleccione la liquidación que se asociará a las atenciones
    </div>
    <form class="flex-column mts-4" *ngIf="formReport" [formGroup]="formReport" (submit)="this.informAttentions()">
        <!-- Select liquidation -->
        <div class="flex-column">
            <span class="subtitle-3 text-primary-darker mbs-0">
                Liquidación *
            </span>
            <app-select-searching-input #selectReport class="flex-auto" [name]="this.LIQUIDATION"
                [nameSearching]="this.LIQUIDATION_SEARCHING" [form]="this.formReport"
                [placeholder]="'Buscar liquidación'" [calculateId]="this.calculateIdFinancier"
                [calculateName]="this.calculateNameFinancier" [placeholderNoValue]="'Sin resultados'"
                [placeholderSearching]="'Buscar liquidación...'" [elements]="this.liquidations">
            </app-select-searching-input>
        </div>
        <!-- Buttons -->
        <div class="flex-row align-center-center gap-5 mts-2">
            <button mat-stroked-button type="button" color="primary" (click)="this._dialogRef.close()">CANCELAR</button>
            <button mat-flat-button type="submit" color="primary"
                [disabled]="!this.formReport.valid || this.informingAttentions">INFORMAR</button>
        </div>
    </form>
</ng-template>


<ng-template #loadingRequest>
    <!-- <div class="flex-100 mts-5 center-center">
        <app-spinner *ngIf="!this.error; else errorDiv" [minHeight]="200"></app-spinner>
    </div> -->
</ng-template>

<ng-template #loadingRequestNotInformed>
    <div class="flex-100 mts-5 center-center">
        <app-spinner *ngIf="!this.errorNotInformed; else errorDiv" class="flex-100 start"></app-spinner>
    </div>
</ng-template>

<ng-template #errorDiv>
    <div class="flex-100 center-center">
        <app-spinner-error></app-spinner-error>
        <span>
            Ha ocurrido un error inesperado, inténtelo nuevamente
        </span>
    </div>
</ng-template>
