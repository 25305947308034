<div class="flex-column">
    <ng-container *ngIf="(this.loadingCase || this.isloadingUpdate) && !!this.isCaseInputParam">
        <div class="flex-row align-center-center full-height spinner-hover">
            <mat-spinner diameter="60"></mat-spinner>
         </div>
    </ng-container>
    <span *ngIf="!this.isCaseInputParam" class="headline-1 pls-4 mbs-3 border-left text-primary-darker-title">
        {{ 'Datos del caso' | uppercase }}
    </span>
    <div [ngClass]="{ 'ml-1': !this.isCaseInputParam }" *ngIf="!this.loadingCase">
        <!-- [ngClass.lt-md]="['mts-3', 'mat-elevation-z4']"  -->
        <mat-card
            class="card-shadow mts-4 mx-1 data-management-container mat-elevation-z2 flex-100 p-1">
            <div class="flex-column">
                <form [formGroup]="this.formCase">
                    <div class="flex-column">
                        <div *ngIf="!this.caseIN" class="flex-row mbs-3">
                            <span class="text-primary-darker-h3 pl-2">
                                Gestión del caso
                            </span>
                        </div>

                        <div class="flex-column px-2">
                            <div class="flex-row flex-100 space-between-center">
                                <!-- Financier -->
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Financiador *
                                    </span>
                                    <app-select-searching-input
                                        [name]="this.FINANCIER" [nameSearching]="this.FINANCIER_SEARCHING"
                                        [form]="this.formCase" [placeholder]="'Seleccione el financiador'"
                                        [elements]="this.financiers" [placeholderNoValue]="'Sin resultados'"
                                        [placeholderSearching]="'Buscar financiador...'"
                                        (selected)="onSelectFinancier($event)"
                                        [calculateId]="this.calculateIdFinancier" [calculateName]="this.calculateNameFinancier"
                                        [defaultValue]="this.financierIdToEdit"
                                        [disabled]="!!this.caseIN ? true : false">
                                    </app-select-searching-input>
                                </div>

                                <!-- Gestor -->
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Gestor del caso
                                    </span>
                                    <app-text-input [name]="this.FINANCIER_OPERATOR"
                                        [form]="this.formCase">
                                    </app-text-input>
                                </div>
                            </div>

                            <div class="flex-row flex-100 space-between-center">
                                <!-- Date email -->
                                <div class="flex-row space-between-center flex-45">
                                    <div class="flex-column flex-45">
                                        <span class="subtitle-3 text-primary-darker mbs-1">
                                            Fecha de mail
                                        </span>
                                        <app-date-picker [name]="this.EMAIL_DATE" [form]="this.formCase">
                                        </app-date-picker>
                                    </div>
                                    <div class="flex-column flex-45">
                                        <span class="subtitle-3 text-primary-darker mbs-1">
                                            Fecha de inicio de actividad
                                        </span>
                                        <app-date-picker [name]="this.START_DATE" [form]="this.formCase">
                                        </app-date-picker>
                                    </div>
                                </div>

                                <!-- Email -->
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Mail del financiador
                                    </span>
                                    <app-email-input [name]="this.EMAIL_FINANCIER" [form]="this.formCase"
                                        [placeholder]="'ejemplo@mail.com'" class="inputMail">
                                    </app-email-input>
                                </div>
                            </div>

                            <!-- Affiliaton -->
                            <div class="flex-row space-between-center">
                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Nº de afiliado
                                    </span>

                                    <app-text-input [name]="this.PATIENT_AFFILIATIONNUMBER" [form]="this.formCase" (keypress)="keyPressNumbers($event)"
                                        [placeholder]="''" [hint]="'Números del 0 al 9, - y /'">
                                    </app-text-input>
                                </div>

                                <div class="flex-column flex-45">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Código interno financiador (CIF)
                                    </span>
                                    <app-text-input [name]="this.FINANCIER_INTERNAL_CODE" [form]="this.formCase" [placeholder]="''" (keypress)="keyPressNumbers($event)" [hint]="'Números del 0 al 9, - y /'">
                                    </app-text-input>
                                </div>
                            </div>

                            <div class="flex-row flex-100  space-between-center mt-1">
                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Tipo de empresa
                                    </span>
                                    <app-select-input [name]="this.COMPANY_TYPE"
                                        [elements]="this.companyTypesList" [form]="this.formCase" (selected)="onSelectCompanyType($event)"
                                        [defaultValue]="!!this.caseIN?.companyType ? this.caseIN.companyType.id.toString() : ''"
                                        [noOptionText]="'Sin especificar'"
                                        [calculateName]="this.calculateNameCompanyType" [calculateId]="this.calculateIdCompanyType" [disabled]="isDisabledCompanyType">
                                    </app-select-input>
                                </div>
                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        IVA
                                    </span>
                                    <app-select-input [name]="this.VAT_CATEGORY" [defaultValue]="isDisabledVatCategory || !!this.caseIN?.vatCategory ? this.vatCatId : ''" [noOptionText]="'Sin especificar'"
                                        [elements]="this.vatCategories" [form]="this.formCase" [calculateName]="this.calculateNameVatCategory" [calculateId]="this.calculateIdVatCategory" [disabled]="isDisabledVatCategory">
                                    </app-select-input>
                                </div>
                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Zona impositiva
                                    </span>
                                    <app-select-searching-input class="mbs-1" [placeholder]="'Seleccionar'"
                                        [defaultValue]="!!this.caseIN?.taxZone ? this.taxZoneDefault.toString() : ''"
                                        [name]="this.TAX_ZONE" [nameSearching]="this.TAX_ZONE_SEARCHING"
                                        [form]="this.formCase"[calculateName]="this.calculateNameTaxZone"
                                        [calculateId]="this.calculateIdTaxZone"
                                        [elements]="this.taxZones"
                                        [placeholderSearching]="'Buscar zona impositiva...'">
                                    </app-select-searching-input>
                                </div>
                            </div>

                            <div class="flex-row space-between-center">

                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Tipo de caso
                                    </span>
                                    <app-multi-select-input [name]="this.TYPE_CASE"
                                        [elements]="this.tagList" [form]="this.formCase"
                                        [valuesSelected]="this.tagsDefault">
                                    </app-multi-select-input>
                                </div>

                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1 full-width">
                                        Complejidad de caso
                                    </span>
                                <div class="flex-row align-start-none flex-gap-small">
                                    <div class="flex-80">
                                        <app-select-input [name]="this.COMPLEXITY_CASE"
                                            [elements]="this.complexityList" [form]="this.formCase" (selected)="onSelectComplexity($event)"
                                            [defaultValue]="!!this.caseIN?.complexityId ? this.caseIN.complexityId.toString() : ''"
                                            [noOptionText]="'Sin especificar'">
                                        </app-select-input>
                                    </div>
                                        <mat-icon style="cursor: pointer;" [color]="'accent'" (mouseover)="complexitiesHelp()" (mouseout)="complexitiesHelp()" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">help_outline</mat-icon>
                                        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="-280" [cdkConnectedOverlayOffsetY]="275" [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="isOpen" cdkConnectedOverlayWidth="20%">
                                            <div *ngFor="let complexity of this.complexityList" class="complexity-item">
                                                <span style="font-weight: 600; font-size: small; border-bottom: 0.1px solid darkgray; ">{{complexity.name}}  :  </span>
                                                <br>
                                                <span style="font-size: smaller;">  {{complexity.description}}  </span>
                                            </div>
                                        </ng-template>
                                </div>
                                </div>
                                <div class="flex-column flex-30">
                                    <div class="flex-row flex">
                                    <span class="subtitle-3 text-primary-darker">
                                        Visibilidad de caso
                                    </span>
                                    </div>
                                    <div class="flex-row">
                                        <app-checkbox-input class="subtitle-3 text-primary-darker" [form]="this.formCase"
                                            [label]="'Financiador'"
                                            [name]="this.ENABLED_FINANCIER">
                                        </app-checkbox-input>
                                        <app-checkbox-input class="subtitle-3 text-primary-darker" [form]="this.formCase"
                                            [label]="'Grupo de prestadores'"
                                            [name]="this.ENABLED_PROVIDER">
                                        </app-checkbox-input>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row flex-100 mbs-3 ">
                                  <!-- Diagnóstico -->
                                <div class="flex-column flex-45">
                                    <span class="text-primary-darker-h3">
                                        Diagnóstico
                                    </span>
                                </div>
                            </div>
                                <div [formGroup]="this.formObservations">
                                    <div [formArrayName]="this.OBSERVATIONS">
                                        <div *ngFor="let observation of observations['controls']; let i=index">
                                            <div [formGroupName]="i">
                                                <div class="flex-row" style="gap: 4rem;">
                                                    <div class="flex-column flex-30">
                                                            <span class="subtitle-3 text-primary-darker mbs-1">
                                                                Seleccionar dx
                                                            </span>
                                                            <app-select-searching-dynamic-input
                                                                id="diagnosis-select"
                                                                [defaultValue]="this.actualDiagnoses[i]"
                                                                [elements]="this.diagnosesOnSelect[i]"
                                                                [name]="this.DIAGNOSIS" [nameSearching]="this.DIAGNOSIS_SEARCHING"
                                                                [form]="getObservationsFormGroup(i)"
                                                                [placeholder]="'Seleccione el diagnóstico'" [placeholderNoValue]="'Sin resultados'"
                                                                [placeholderSearching]="'Buscar diagnóstico...'"
                                                                [httpGet]="this.getDiagnosesByName"
                                                                [noOptionText]="'Sin diagnóstico'"
                                                                [calculateName]="this.calculateName"
                                                                [matTooltip]="!!this.diagnosesOnSelect[i] ? !!this.diagnosesOnSelect[i][0] ? this.diagnosesOnSelect[i][0].name : null : null">
                                                            </app-select-searching-dynamic-input>
                                                    </div>
                                                        <!-- Observación -->
                                                        <div class="flex-column flex-100">
                                                            <span class="subtitle-3 text-primary-darker mbs-1">
                                                                Observación
                                                            </span>
                                                            <app-text-input [clearable]="true"
                                                                [name]="this.OBSERVATION"
                                                                [form]="getObservationsFormGroup(i)">
                                                            </app-text-input>
                                                        </div>
                                                        </div>

                                                    <!-- BUTTONS -->
                                                   <div class="flex-row align-end-center">
                                                            <div class="flex-row">
                                                                <button mat-icon-button [color]="'primary'"
                                                                    (click)="this.removeObservation(i)">
                                                                    <mat-icon>delete_outlined</mat-icon>
                                                                </button>
                                                            </div>
                                                            <div class="flex-row">
                                                                <button mat-icon-button [color]="'primary'" matTooltip="Agregar"
                                                                    [disabled]="!(!!this.formObservations['controls']['observations']['controls'][i]['controls']['id']['value'])"
                                                                    [hidden]="!((i+1)==this.formObservations['controls']['observations']['controls'].length)"
                                                                    (click)="this.addObservation()">
                                                                    <mat-icon>add_circle_outlined</mat-icon>
                                                                </button>
                                                            </div>
                                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Buttons if input param case -->
                    <div class="mt-2"></div>
                    <div *ngIf="this.isCaseInputParam" class="flex-row align-space-around-center full-width">
                        <button mat-stroked-button [color]="'primary'" (click)="clickCancelEditCase()">
                            <span>CANCELAR</span>
                        </button>
                        <button mat-flat-button [color]="'primary'" [disabled]="!this.formCaseDataValid || !this.isValidFormObservations" (click)="clickConfirmEditCase()">
                            <span>CONFIRMAR</span>
                        </button>
                    </div>
                </form>
            </div>
        </mat-card>
    </div>
</div>
