import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { debounce, filter, map, skip, startWith, tap } from 'rxjs/operators';
import { AttentionsFacade, ExtraMetaDataAttentions, MetaDataAttentions, ATTENTIONS_COLORS } from 'src/app/abstraction/attentions.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { ROUTES } from 'src/app/core/enums/routes';
import { TasksStates } from 'src/app/core/enums/tasksStates';
import { AttentionsQPS, AttentionsService } from 'src/app/core/services/attentions.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Attention } from 'src/app/shared/models/attention';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import { Operator } from 'src/app/shared/models/operator';
import { DialogAuditStatesAttentionComponent } from '../../components/dialog-audit-states-attention/dialog-audit-states-attention.component';
import { DialogEvolutionComponent } from '../../components/dialog-evolution/dialog-evolution.component';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { Patient } from 'src/app/shared/models/patient';
import { ATTENTIONS_BACKGROUND_COLORS } from '../../../../abstraction/attentions.facade';

enum INDICATORS {
   NOT_FINISHED_BEFORE_TODAY = '1',
   INFORMABLE_ATTENTIONS = '2'
}
export interface SnapshotAttentions {
   storedFrom: any;
   attentions: Attention[],
   isMyAttentions: boolean,
   isExpanded: boolean,
   dataSourceAttentions: MatTableDataSource<Attention>
}
@Component({
   selector: 'app-attentions',
   templateUrl: './attentions.component.html',
   styleUrls: ['./attentions.component.scss'],
   // Need to remove view encapsulation so that the custom tooltip style defined in
   // `tooltip-custom-class-example.css` will not be scoped to this component's view.
   encapsulation: ViewEncapsulation.None,
})

export class AttentionsComponent implements OnInit, AfterViewInit {
   @Input() isExpanded;
   @Output() onExpand = new EventEmitter<boolean>();
   @Output() onShrink = new EventEmitter<boolean>();
   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
   @ViewChild('inputSearch') set input(input: ElementRef) {
      if (input && !this._input) { // initially setter gets called with undefined
         this.inputSearch = input;
         this.subscribeInputSearch();
      }
   }
   inputValue;
   private inputSearch: ElementRef;
   _input: Subscription;

   error = false;
   loadingResponse = false;
   TASKS_STATES = TasksStates;
   ATTENTIONS_STATES = AttentionStates;
   ATTENTIONS_COLORS = ATTENTIONS_COLORS;
   ATTENTIONS_BACKGROUND_COLORS = ATTENTIONS_BACKGROUND_COLORS;
   attentions: Attention[];
   filterForm: FormGroup;
   filtersShown = true;
   isMyAttentions;
   pageSize = 100;
   pageIndex = 1;
   // Form
   public readonly DATE_FROM = 'dateFrom';
   public readonly DATE_TO = 'dateTo';
   public readonly INDICATOR = 'indicator';
   public readonly ALL_CASES = 'allCases';
   public readonly OPERATOR = 'operatorId';
   public readonly OPERATOR_SEARCHING = 'operatorSearching';
   public readonly PATIENT = 'patientId';
   public readonly PATIENT_SEARCHING = 'patientSearching';
   public readonly FINANCIER = 'financierId';
   public readonly FINANCIER_SEARCHING = 'financierSearching';
   selectedIndicator;
   indicatorsOptions = [
       {
          id: INDICATORS.NOT_FINISHED_BEFORE_TODAY,
          name: 'Previas a hoy sin finalizar'
       },
       {
          id: INDICATORS.INFORMABLE_ATTENTIONS,
          name: 'Pendientes de informar'
       },

   ]
   operators: Operator[];
   _operators: Subscription;

   storedFrom;
   displayedColumns: string[] = [
      'alerts',
      // 'id',
      'practice',
      // 'caseId',
      'patient',
      'patientDocument',
      'provider',
      'financier',
      'operator',
      'state',
      'signature',
      'evolution',
      'alertPending',
   ];

   user;
   dataSourceAttentions: MatTableDataSource<Attention>;
   _attentions: Subscription;
   _mda: Subscription;
   auditStatesAttention$: Observable<AuditStatesAttention>;
   numberOfPages;
   numberOfRows
   _isLoadingGettingAttentions: Subscription;
   isLoadingGettingAttentions: boolean;
   metaDataAttentions: MetaDataAttentions;

   get dateFrom() {
      return this.filterForm.get(this.DATE_FROM);
   }
   get dateTo() {
      return this.filterForm.get(this.DATE_TO);
   }

   setDateFrom(date) {
      // return this.evolutionForm.get(this.EVOLUTION_TYPE);
      this.filterForm.controls[this.DATE_FROM].setValue(date);
   }
   setDateTo(date) {
      this.filterForm.controls[this.DATE_TO].setValue(date);
   }

   constructor(
      public dialog: MatDialog,
      private datePipe: DatePipe,
      private formBuilder: FormBuilder,
      public attentionService: AttentionsService,
      public generalService: GeneralService,
      private router: Router,
      private attentionsFacade: AttentionsFacade,
      public entitiesFacade: EntitiesFacade,
      private authService: AuthenticationService,
   ) {
      this.attentionsFacade.setAttentions( null ); // Reset to force load attentions
   }

   ngOnInit() {
      this._isLoadingGettingAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loading => this.isLoadingGettingAttentions = loading);

      this.isMyAttentions = true;
      this.user = this.authService.userValue

      this.generalService.sendMessage('Atenciones');
      this.filterForm = this.createFilterForm();
      this.entitiesFacade.loadOperators();

      this._operators = this.entitiesFacade
         .getOperators$()
         .subscribe(operators => this.operators = operators);

      this.dataSourceAttentions = new MatTableDataSource([]);
      this._mda = this.attentionsFacade.getMetaDataAttentions$().subscribe((mda) => {
         if (!!mda) {
            this.metaDataAttentions = mda;
            this.inputValue = this.metaDataAttentions?.searchPatient
               || this.metaDataAttentions?.searchProvider
               || this.metaDataAttentions?.searchState
               || this.metaDataAttentions?.searchPractice
               || '';
            this.numberOfPages = this.metaDataAttentions?.pages;
            this.numberOfRows = this.metaDataAttentions?.rows;

            // TODO: EZE VER
            if (this.metaDataAttentions?.statesIds?.includes(AttentionStates.AGENDADA)
               && this.metaDataAttentions?.statesIds?.includes(AttentionStates.EN_CURSO)) {
               this.selectedIndicator = INDICATORS.NOT_FINISHED_BEFORE_TODAY;
               this.from.disable();
            }
            else if ( this.metaDataAttentions?.statesIds?.includes(AttentionStates.FACTURABLE)
                && this.metaDataAttentions?.statesIds?.includes(AttentionStates.NO_INFORMADA)) {
                this.selectedIndicator = INDICATORS.INFORMABLE_ATTENTIONS;
                this.from.disable();
            } else {
               this.selectedIndicator = '0';
               this.from.enable();
            }

            let allCases;
            if (this.metaDataAttentions?.activeCase != null
               && this.metaDataAttentions?.activeCase != undefined) {
               allCases = false
            } else {
               allCases = true;
            }

            this.setDisplayColumns(this.metaDataAttentions);
         }
      })

      this._attentions = this.attentionsFacade.getAttentions$().subscribe(( attentions ) => {

         if (attentions == null && !this.isLoadingGettingAttentions) {

            let afp: AttentionsQPS = {
               fromDate: new Date(),
               toDate: new Date(),
               //operatorId: !!this.metaDataAttentions ? this.metaDataAttentions?.operatorId : this.user.id,
               page: 0,
               size: 100,
               notManageable: false,
               expand: "financier,operator,patient,practice,provider"
            }

            if(!!this.metaDataAttentions){
               if(!!this.metaDataAttentions.operatorId){
                  afp.operatorId = this.metaDataAttentions.operatorId;
               }
            } else {
               afp.operatorId = this.user?.id;
            }

            // If home indicator is clicked
            // It prevents attentions loading before component initializes and IOException
            if(!!this.metaDataAttentions && this.metaDataAttentions.fromIndicator){
               let auxAfp = this.metaDataAttentions;
               delete auxAfp.fromIndicator;
               afp = {...auxAfp, ...afp};
               afp.toDate = moment().subtract(1, 'days').toDate();
               delete afp.fromDate;
            }
            delete afp.searchPatient;
            delete afp.searchProvider;
            delete afp.searchPractice;
            delete afp.searchState;
            delete afp.searchCIF;
            const exma: ExtraMetaDataAttentions = { title: this.datePipe.transform(afp.fromDate, 'dd-MM-yyyy') }
            this.attentionsFacade.loadAttentions(afp, exma, false).subscribe()
         } else {
            this.dataSourceAttentions.data = attentions;
            setTimeout(() => {
               this.setDataSourceAttributes();
            })
         }
      })
   }

   onSelectIndicator(event) {
      if (event) {
         this.from.disable();
      } else {
         this.from.enable();
      }
   }

   ngOnDestroy(): void {
      this._attentions.unsubscribe();
      this._mda.unsubscribe();
      this._isLoadingGettingAttentions.unsubscribe();
      this._operators.unsubscribe();
      this._input.unsubscribe();
   }

   ngAfterViewInit(): void {
      console.log(this.paginator);
      setTimeout(() => {
         this.setDataSourceAttributes();
      }, 500)
   }

   subscribeInputSearch() {
      this._input = fromEvent(this.inputSearch.nativeElement, 'keyup').
         pipe(

            map((event: any) => event.target.value),
            startWith(''),
            tap((st) => {
               console.log(st.length == 0)
            }),
            filter((value: string) => (value.length != 1 && value.length != 2)),
            skip(1),
            debounce((string: string) => (string.length > 1) ? timer(500) : timer(1250)),
         ).subscribe((st) => {
            console.log(st);
            let afp: AttentionsQPS = this.metaDataAttentions;
            if (st.length == 0) {
               delete afp.searchPatient;
               /* delete afp.searchProvider;
               delete afp.searchPractice;
               delete afp.searchState; */
               delete afp.searchCIF;
               delete afp.searchDocument;
            } else {
               if (this.hasNumbers(st) && this.hasLetters(st)) {
                 // By Cif
                 afp.searchCIF = st;
                 delete afp.searchPatient;
                /*  delete afp.searchProvider;
                 delete afp.searchPractice;
                 delete afp.searchState; */
                 delete afp.searchDocument;
               } else if (this.hasNumbers && !this.hasLetters(st)) {
                 // By Document
                 afp.searchDocument = st;
                 afp.searchCIF = st;
                 delete afp.searchPatient;
                 /* delete afp.searchProvider;
                 delete afp.searchPractice;
                 delete afp.searchState; */

               } else {
                 // By Patient
                 afp.searchPatient = st;
                 afp.searchCIF = st;
                 delete afp.searchDocument;
                 /* afp.searchProvider;
                  afp.searchPractice;
                  afp.searchState; */

               }
            }
            afp.page = 0;
            const exma: ExtraMetaDataAttentions = !!afp.fromDate ? { title: this.datePipe.transform(afp.fromDate, 'dd-MM-yyyy') } : null;
            this.attentionsFacade.loadAttentions(afp, exma).subscribe()
         });
   }

   hasNumbers(st: string): boolean {
      let numbers = "0123456789";
      for (let i = 0; i < st.length; i++) {
         if (numbers.indexOf(st.charAt(i), 0) != -1) {
            return true;
         }
      }
      return false;
   }

   hasLetters(st: string): boolean{
      const numbers = '0123456789';
      for (let i = 0; i < st.length; i++) {
        if (numbers.indexOf(st.charAt(i), 0) == -1) {
          return true;
        }
      }
      return false;
    }

   setDisplayColumns(mda: MetaDataAttentions) {
      const fromDate = !!mda && !!mda.fromDate ? moment(mda.fromDate) : null;
      const toDate = !!mda && !!mda.toDate ? moment(mda.toDate) : null;
      if (!fromDate || !toDate) {
         this.displayedColumns.splice(1, 1, 'date');
      } else {
         if (!fromDate.isSame(toDate, 'day')) {
            this.displayedColumns.splice(1, 0, 'date');
         }
         // else {
         //    this.displayedColumns.splice(1, 1, 'date');
         // }
      }
      console.log('COLUMNASSSS: ', this.displayedColumns);
   }

   setDataSourceAttributes() {
      console.log('METADATA ', this.metaDataAttentions);

      if (!!this.formBuilder) {
         if (!!this.metaDataAttentions && !!this.metaDataAttentions.fromDate) {
            this.setDateFrom(this.metaDataAttentions.fromDate);
         }
         if (!!this.metaDataAttentions && !!this.metaDataAttentions.fromDate) {
            this.setDateTo(this.metaDataAttentions.toDate);
         }
         if (!!this.metaDataAttentions && !!this.metaDataAttentions.searchCIF) {
            this.inputSearch.nativeElement.value = this.metaDataAttentions.searchCIF;
         }
      }
      console.log(this.paginator);
      if (this.paginator != null && this.paginator != undefined) {
         console.log(this.metaDataAttentions?.page, this.metaDataAttentions?.size);

         this.paginator.pageIndex = this.metaDataAttentions?.page
         this.paginator.pageSize = this.metaDataAttentions?.size
         this.paginator.length = this.numberOfRows;
         this.paginator._intl.itemsPerPageLabel = 'Registros por página';
         this.paginator._intl.firstPageLabel = 'Primera página';
         this.paginator._intl.lastPageLabel = 'Última páginaa';
         this.paginator._intl.nextPageLabel = 'Página siguiente';
         this.paginator._intl.previousPageLabel = 'Página anterior';
      }
   }

   onPaginatorEvent() {
      let afp: AttentionsQPS = this.metaDataAttentions;
      afp.page = this.paginator.pageIndex;
      afp.size = this.paginator.pageSize;
      console.log('EVENTO DE PAGINATOR ... afp', afp)
      this.attentionsFacade.loadAttentions(afp).subscribe();
   }

   getDayName(date: Date) {
      if (!!date) {

         return this.generalService.getDayName(date);
      } else {
         return null;
      }
   }

   matTooltipText(attention: Attention): string {
      let string = '';
      !!attention.fromDate
         ? (string +=
            'Ingreso agendado: ' +
            this.datePipe.transform(attention.fromDate, 'yyyy-MM-dd HH:mm') +
            '\n')
         : null;
      !!attention.toDate
         ? (string +=
            'Egreso agendado: ' +
            this.datePipe.transform(attention.toDate, 'yyyy-MM-dd HH:mm') +
            '\n')
         : null;
      !!attention.checkIn
         ? (string +=
            'Check in: ' + this.datePipe.transform(attention.checkIn, 'yyyy-MM-dd HH:mm') + '\n')
         : null;
      !!attention.checkOut
         ? (string +=
            'Check out: ' + this.datePipe.transform(attention.checkOut, 'yyyy-MM-dd HH:mm') + '\n')
         : null;
      return string;
   }

   // APLICAR FILTRO A TABLA

   openEvolutionDialog(attention: Attention): void {
      const dialogRef = this.dialog.open(DialogEvolutionComponent, {
         width: '65%',
         maxHeight: '90vh',
         data: { attention },
      });

      dialogRef.afterClosed().subscribe((attention: Attention) => {
         // this.onFinishTask(result);
         if (!!attention && !!attention.state.id) {
            this.updateAttention(attention);
         }
      });
   }

   openAuditStatesAttentionDialog(auditStatesAttention: AuditStatesAttention): void {
      const dialogRef = this.dialog.open(DialogAuditStatesAttentionComponent, {
         width: '65%',
         data: { auditStatesAttention },
      });

      this._attentions.unsubscribe(); // Unsubscribe from onInit()

      dialogRef.afterClosed().subscribe(( a ) => {

         if ( a.reload ) { // There were changes!

            this._attentions = this.attentionsFacade.getAttentions$().subscribe(( attentions ) => {

                     this.dataSourceAttentions.data = attentions;
                     setTimeout(() => {
                        this.setDataSourceAttributes();
                     })

                     this.onFilter(); // Reload because dialog can do changes
            })
         }
      });
   }

   stopPropagation(e: Event) {
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      e.stopPropagation();
   }

   updateAttention(updatedAttention: Attention) {
      const attentionIndex = this.attentions.findIndex(
         (attention) => attention.id === updatedAttention.id,
      );
      this.attentions[attentionIndex].state = updatedAttention.state;
      this.dataSourceAttentions = new MatTableDataSource(this.attentions);
   }

   onClickStateAttention(attention) {
      console.log("onClickStateAttention:", attention);
      attention.isLoadingStates = true;
      this.attentionsFacade.loadAuditStatesAttention(attention.id).pipe(
         map( a => ({...a, lastStateId:attention.state.id }) ))
      .subscribe(a =>
            { this.openAuditStatesAttentionDialog(a); },
            () => attention.isLoadingStates = false,
            () => attention.isLoadingStates = false)
   }

   /*********
    * FORMS *
    ********/

   get from() {
      return this.filterForm?.get(this.DATE_FROM);
   }
   get to() {
      return this.filterForm?.get(this.DATE_TO);
   }
   get indicator() {
      return this.filterForm?.get(this.INDICATOR);
   }

   createFilterForm(): FormGroup {
      const to = new Date();
      const from = new Date(Date.now());
      from.setHours(0, 0, 0, 0);
      to.setDate(from.getDate() + 1);
      return this.formBuilder.group(
         {
            [this.DATE_FROM]: [
               from,
               [CustomValidators.required('Fecha requerida')]
            ],
            [this.DATE_TO]: [
               to,
               [CustomValidators.required('Fecha requerida')]
            ],
            [this.OPERATOR]: [],
            [this.OPERATOR_SEARCHING]: [''],
            [this.PATIENT]: [],
            [this.PATIENT_SEARCHING]: [''],
            [this.FINANCIER]: [],
            [this.FINANCIER_SEARCHING]: [''],
            [this.ALL_CASES]: [],
            [this.INDICATOR]: [],
         },
         // { validator: this.dateLessThan("dateFrom", "dateTo") }
      );
   }


   /************
    * HANDLERS *
    ************/


   onFilter() {

      console.log("onFilter");

      const allCases = this.filterForm.get(this.ALL_CASES)?.value;
      const operatorId = this.filterForm.get(this.OPERATOR)?.value;
      const patientId = this.filterForm.get(this.PATIENT)?.value;
      const financierId = this.filterForm.get(this.FINANCIER)?.value;
      const indicatorId = this.filterForm.get(this.INDICATOR)?.value;
      let afp: AttentionsQPS = this.metaDataAttentions;
      afp.fromDate = this.from.value;
      afp.fromDate = moment(afp.fromDate).subtract(3,"month").startOf("month").toDate();
      afp.toDate = this.from.value;
      afp.toDate = moment(afp.toDate).toDate();
      afp.page = 0;
      afp.expand = "financier,operator,patient,practice,provider";
      if (allCases) {
         delete afp.activeCase;
      } else {
         afp.activeCase = true;
      }
      if (!operatorId || operatorId == '0') {
         delete afp.operatorId
      } else {
         afp.operatorId = operatorId
      }

      if (!patientId || patientId == '0') {
         delete afp.patientsIds;
      } else {
         afp.patientsIds = [patientId];
      }

      if (!financierId || financierId == '0') {
         delete afp.financiersIds;
      } else {
         afp.financiersIds = [financierId];
      }


      if (!indicatorId) {
         delete afp.statesIds;
      } else {
         if (indicatorId == INDICATORS.NOT_FINISHED_BEFORE_TODAY) {
            afp.statesIds = [AttentionStates.AGENDADA, AttentionStates.EN_CURSO];
            delete afp.fromDate;

            afp.toDate = moment().subtract(1, 'days').toDate();
         }
         else if (indicatorId == INDICATORS.INFORMABLE_ATTENTIONS) {
            afp.statesIds = [AttentionStates.FACTURABLE, AttentionStates.NO_INFORMADA];
         }
      }
      const exma: ExtraMetaDataAttentions = !!afp.fromDate ? { title: this.datePipe.transform(afp.fromDate, 'dd-MM-yyyy') } : null;
      console.log("this.attentionsFacade.loadAttentions(afp, exma).subscribe()");
      this.attentionsFacade.loadAttentions(afp, exma).subscribe();
      if (!!afp.fromDate) {
        delete afp.fromDate
      }
   }



   onClickRow(attention: Attention) {
      this.generalService.setGoBack({ title: 'Atenciones ', route: ROUTES.ATTENTIONS })
      this.router.navigate([ROUTES.CASES, attention.caseId]);
   }

   onClickNextBeforeDay(nextDay: boolean) {
      const oldDay: Date = this.from.value;
      const newDay: Date = new Date(oldDay);
      const sum = nextDay ? 1 : -1;
      newDay.setDate(oldDay.getDate() + sum);
      this.filterForm.controls[this.DATE_FROM].setValue(newDay);
   }

   onChangeMyAttentionFilter(isMyAttentions: boolean) {
      // Hacer el get de las atenciones con all=true|false y con stored from ->
      // TODO: directamente llamar a onFilter() y que en ese metodo analice las variables a insertar.
      this.isMyAttentions = isMyAttentions;

      // Hacer el get de las atenciones con all=true|false y con stored from ->
      const operatorColumnIndex = this.displayedColumns.findIndex(
         (column) => column == 'operator',
      );
      if (operatorColumnIndex && isMyAttentions) {
         this.displayedColumns.splice(operatorColumnIndex, 1);
      } else if (operatorColumnIndex == -1 && !isMyAttentions) {
         // Insertar antes de la columna de estado
         const stateColumnIndex = this.displayedColumns.findIndex((column) => column == 'state');
         this.displayedColumns.splice(stateColumnIndex, 0, 'operator');
      }
      // TODO: directamente llamar a onFilter() y que en ese metodo analice las variables a insertar.
      let afp: AttentionsQPS = this.metaDataAttentions;
      afp.all = !isMyAttentions;
      afp.page = 0;
      afp.expand = "financier,operator,patient,practice,provider";
      this.attentionsFacade.loadAttentions(afp).subscribe();

   }

   calculatePatientId(patient: Patient) {
      return patient.id;
   }

}
