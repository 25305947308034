<div class="layout-column align-center">
  <div class="layout-row align-center" >
    <mat-slide-toggle
        color="primary"
        (change)="onToggleChange($event)">
    </mat-slide-toggle>
    <div class="flex-100 mys-3 mls-3" >
      <span class="text-secondary-darker">
          {{dateToShow}}
      </span>
    </div>
  </div>
  <!-- //TODO: DESCOMENTAR !!!! GENERA ERROR -->
  <app-generic-calendar
      (onEventClicked)="onEventClickedHandler($event)"
      (onDayClicked)="onDayClickedHandler($event)"
      (onEventDelete)="onEventDeleteHanlder($event)"
      [events]="events"
      (onChangeMonth)="onReceiveChangeMonth($event)"
      [loading]="loading"
      class="flex-100">
  </app-generic-calendar>
</div>
