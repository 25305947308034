<div class="flex-column flex-100 align-center-center">
    <div class="spinner-hover" *ngIf="(this.isWorking$ | async)">
        <mat-progress-spinner color="primary" diameter="40"></mat-progress-spinner>
    </div>
    <div *ngIf="!!data.title" class="flex-column p-1"
        [ngClass]="!!data.headerClass ? data.headerClass : 'bg-primary-lighter'">
        <!-- TITLE -->
        <span class="ml-1"
            [ngClass]="!!data.titleClass ? data.titleClass : 'text-primary-darker-h3 mbs-3'">{{data.title}}</span>
    </div>
    <div class="flex-column align-center-center pxs-5 pys-2 gap-1-5em">
        <div class="align-start-none">
            <mat-icon svgIcon="{{data.icon}}" style="height: 55px; width: 55px;"></mat-icon>
        </div>
        <span class="subtitle-2 active-case-text-margin" style="text-align: center;">
            {{ data.message }}
        </span>
    </div>

    <!-- Table -->
    <div class="table-container flex-column flex-80 align-center" *ngIf="!!this.data && this.data?.arrayTable.length > 0">
      <table mat-table [dataSource]="this.data.arrayTable" style="max-width: 80%; align-self: center;">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
        <tr mat-row *ngFor="let row; columns: columnsToDisplay;"></tr>
      </table>
    </div>

    <div class="flex-row align-space-evenly-center gap-3em m-5">
        <button mat-stroked-button [color]="'primary'" (click)="onNoClick()">
            {{data.closeText}}
        </button>
        <button mat-raised-button [color]="'primary'" (click)="confirm()" [disabled]="this.disableConfirm">
            <div class="flex-row align-center" *ngIf="!this.disableConfirm; else loadingIcon">
                {{data.confirmText}}
            </div>
            <ng-template #loadingIcon>
                <mat-icon>
                    <mat-spinner color="accent" diameter="20">
                    </mat-spinner>
                </mat-icon>
            </ng-template>
        </button>
    </div>
</div>
