import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnClickOutsideDirective } from './on-click-outside.directive';
import { RemoveFocusedDirective } from './remove-focused.directive';

@NgModule({
   declarations: [OnClickOutsideDirective, RemoveFocusedDirective],
   imports: [CommonModule],
   exports: [OnClickOutsideDirective],
})
export class DirectivesModule {}
