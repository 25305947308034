export const adomicilioData = {
  code: 'adomicilio',
  name: "A Domicilio",
  pathLogo: "assets/logos/adomicilio/logo.png",
  pathText: null,
  pathLogoText: "assets/logos/adomicilio/logotext.png",
  mail: null,
  phoneNumber: "0810-333-4002",
  address: {
    flat: '1° Piso',
    flatExtra: null,
    floor: null,
    location: "Mar del Plata",
    province: "Buenos Aires",
    street: "Avda. Constitución",
    streetNumber: 5515,
    zipCode: "7600"
  },
  links: {
    home: "https://adomicilio-sa.com.ar/",
    support: null,
    facebook: "https://www.facebook.com/ADomicilioSalud/",
    linkedin: "https://www.linkedin.com/in/adomiciliosalud/",
    twitter: "https://twitter.com/ADomicilioSA",
    instagram: null
  }
}
