import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { Attention } from 'src/app/shared/models/attention';
import { Evolution } from 'src/app/shared/models/evolution';
import { Media } from "src/app/shared/models/media";
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { DialogImageDetailComponent } from '../../../../shared/components/dialog-image-detail/dialog-image-detail.component';

interface AttentionComposedWithEvolutions extends Attention {
    evolutions?: Evolution[];
}
@Component({
    selector: 'app-evolutions-dialog',
    templateUrl: './evolutions-dialog.component.html',
    styleUrls: ['./evolutions-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class EvolutionsDialogComponent implements OnInit {
    EVOLUTIONS_TYPE = EvolutionsType;
    loadingConfirmEndTask;
    indexPanelOpened = -1;
    expanded: boolean = false;
    constructor(
        public fileUtilitiesService: FileUtilitiesService,
        public entitiesFacade: EntitiesFacade,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private _bottomSheetRef: MatBottomSheetRef<EvolutionsDialogComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { evolutions: Evolution[]; name: string; surname: string; attentions: AttentionComposedWithEvolutions[], expanded: boolean },
        private evolutionFacade: EvolutionsFacade,
    ) {
    }

    ngOnInit() {
        this.data.evolutions = this.data.evolutions.filter(ev => ev != undefined)
        this.mapAttentionEvolution();

        this.sortEvolutionsByAttentionDate();
    }

    sortEvolutionsByAttentionDate() {
        this.data.attentions.sort((a, b) => {
            if (a && a.fromDate) {
                const aFromDate = moment(a.fromDate);
                const bFromDate = moment(b.fromDate);
                return aFromDate.isSame(bFromDate) ? 0 : (aFromDate.isBefore(bFromDate) ? 1 : -1);
            }
            return -1;
        });
    }

    mapAttentionEvolution() {

        // Attentions with Evolutions
        this.data.attentions = this.data.attentions.map(attention => {
            const evolution: Evolution[] = this.data.evolutions.filter((evol) => evol.attentionId === attention.id)
            attention.evolutions = evolution;
            return attention;
        }).filter(att => !!att.evolutions && att.evolutions.length > 0);

        // Evolutions without Attentions
        this.data.evolutions?.filter(evolution => (!evolution.attentionId)).forEach(evol => {
            if (!this.data.attentions) {
                this.data.attentions = [];
            }
            let attention: AttentionComposedWithEvolutions = {
                fromDate: evol.date,
                caseId: evol.caseId,
                evolutions: [evol]
            }
            this.data.attentions.push(attention);
        });

    }

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
        event.preventDefault();
    }

    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    openDialogImage(_image): void {
        const dialogRef = this.dialog.open(DialogImageDetailComponent, {
            width: '350vw',
            data: { image: _image },
        });

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    mostrarOpened(event) {
        console.log(event);

    }

    getMediaByEvolutionId(evolutionId: number): Promise<Media> {
        return new Promise((resolve, reject) => {
            this.evolutionFacade.getMediaByEvolutionId(evolutionId).subscribe(
                (photo) => {
                    resolve(photo);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }
}
