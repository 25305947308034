import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { EvolutionsQPS, EvolutionsService } from 'src/app/core/services/evolutions.service';
import { Evolution } from '../shared/models/evolution';
import { EvolutionsState } from '../core/states/evolutions.state';
import { PostEvolutionBody } from '../core/services/evolutions.service';
import { CasesFacade } from './cases.facade';
import { GeneralService } from 'src/app/core/services/general.service';
import { AttentionsQPS } from '../core/services/attentions.service';
import { Attention } from '../shared/models/attention';

export interface AttentionComposedWithEvolutions extends Attention {
    evolutions?: Evolution[];
    provisionFeeIds?: number[]; // Attention simulated for authorization -> backend include all provFeeIds where evolutionId is founded
}


@Injectable({
    providedIn: 'root',
})
export class EvolutionsFacade {

    constructor(
        private evolutionsService: EvolutionsService,
        private evolutionsState: EvolutionsState,
        private casesFacade: CasesFacade,
        private generalService: GeneralService,
    ) { }

    // STATES
    // Side Bar Evolutions for Cases
    getEvolutionsSideBarShow$(): Observable<boolean> {
        return this.evolutionsState.getEvolutionsSideBarShow$();
    }


    setEvolutionsSideBarShow(show: boolean) {
        this.evolutionsState.setEvolutionsSideBarShow(show);
    }

    getEvolutionsTypeNote$(): Observable<Evolution[]> {
        return this.evolutionsState.getEvolutionsTypeNote$();
    }

    setEvolutionsTypeNote(evolutions: Evolution[]) {
        this.evolutionsState.setEvolutionsTypeNote(evolutions);
    }

    isLoadingGetEvolutions$(): Observable<boolean> {
        return this.evolutionsState.isLoadingGettingEvolutions();
    }


    isLoadingCreatingEvolution$(): Observable<boolean> {
        return this.evolutionsState.isLoadingCreatingEvolution();
    }


    isLoadingUpdatingEvolution$(): Observable<boolean> {
        return this.evolutionsState.isLoadingPatchingEvolution();
    }


    getEvolutionsByCase$(): Observable<Evolution[]> {
        return this.evolutionsState.getEvolutionsByCase$();
    }

    setEvolutionsByCase(evolutions: Evolution[]) {
        this.evolutionsState.setEvolutionsByCase(evolutions);
    }

    getAttentionsWithEvolutions$(): Observable<AttentionComposedWithEvolutions[]> {
        return this.evolutionsState.getAttentionsWithEvolutions$();
    }

    setAttentionsWithEvolutions(evolutions: AttentionComposedWithEvolutions[]) {
        this.evolutionsState.setAttentionsWithEvolutions(evolutions);
    }

    // PATCH
    patchEvolution(evolution: Evolution): Observable<Evolution> {
        this.evolutionsState.setLoadingPatchingEvolution(true);

        const promise: Promise<Evolution> = new Promise((res, rej) => {
            this.evolutionsService.patchEvolution(evolution).pipe(
                finalize(() => this.evolutionsState.setLoadingPatchingEvolution(false))
            ).subscribe({
                next: evo => {
                    this.evolutionsState.updateEvolutionByCase(evo, evo.id);
                    res(evo)
                },
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }


    // POST - ADD
    postEvolution(evolution: PostEvolutionBody, updateStore = true): any | Observable<Evolution> {
        this.evolutionsState.setLoadingCreatingEvolution(true);

        const promise: Promise<Evolution> = new Promise((res, rej) => {
            this.evolutionsService.postEvolution(evolution).pipe(
                finalize(() => this.evolutionsState.setLoadingCreatingEvolution(false))
            ).subscribe({
                next:
                    // Add al store
                    (evolution: Evolution) => {
                        if (!!updateStore) {
                            this.evolutionsState.addEvolutionByCase(evolution);
                            // TODO: Revisar para hacer enum de NOTA...
                            if (evolution.category.description === 'Nota') {
                                this.evolutionsState.addEvolutionTypeNote(evolution);
                            }
                        } else {
                            let caseDateFilters = this.casesFacade.getCaseDateFilter();
                            if (!caseDateFilters && !this.generalService.validateDateBetween(caseDateFilters.historyFromDate, caseDateFilters.historyToDate, evolution.date)) {
                                this.evolutionsState.addEvolutionByCase(evolution);
                            }
                        }
                        res(evolution);
                    },
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }


    // GET - LAOD
    loadEvolutionsByQPS(qps?: EvolutionsQPS, reloadState = true) {

        this.evolutionsState.setLoadingGettingEvolutions(true)

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.evolutionsService.getEvolutions(qps).pipe(
                tap(evolutions => console.log("Evoluciones cargadas para el caso: ", evolutions)),
                finalize(() => this.evolutionsState.setLoadingGettingEvolutions(false))
            ).subscribe({
                next: response => {
                    console.log("Evolutions by Case ", response);
                    if (reloadState) {
                        this.evolutionsState.setEvolutionsByCase(response);
                    }
                    resolve(response);
                },
                error: e => { reject(e); }
            })
        });
        return from(promise);
    }

          // GET - LAOD ( Para NOTAS )
          loadEvolutionsByNoteQPS( qps?: EvolutionsQPS, reloadState = true) {

            this.evolutionsState.setLoadingGettingEvolutions( true )

            const promise: Promise<any> = new Promise(( resolve, reject ) => {
            this.evolutionsService.getEvolutions( qps ).pipe(
               tap(evolutions => console.log("Evoluciones cargadas para el caso: ", evolutions)),
               finalize( () => this.evolutionsState.setLoadingGettingEvolutions( false ) )
            ).subscribe(
               response => {  console.log("Evolutions by Case ", response);
                              if (reloadState){
                                 this.evolutionsState.setEvolutionsTypeNote( response );
                              }
                              resolve( response );
                           },
               e => { reject( e ) ; }
            )
            });
            return from(promise);
         }

    loadEvolutionsById(id: number) {

        this.evolutionsState.setLoadingGettingEvolutions(true)

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.evolutionsService.getEvolutionById(id).pipe(
                tap(evolutions => console.log("Evoluciones cargadas para el caso: ", evolutions)),
                finalize(() => this.evolutionsState.setLoadingGettingEvolutions(false))
            ).subscribe({
                next: response => { resolve(response); },
                error: e => { reject(e); }
            })
        });
        return from(promise);
    }

    // GET - LAOD
    loadAttentionsWithEvolutionsByQPS(qps?: AttentionsQPS, categoriesIds?: number[], reloadState = true) {

        this.evolutionsState.setLoadingGettingEvolutions(true)

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.evolutionsService.getAttentionsWithEvolutions(qps, categoriesIds).pipe(
                tap(evolutions => console.log("Evoluciones cargadas para el caso: ", evolutions)),
                finalize(() => this.evolutionsState.setLoadingGettingEvolutions(false))
            ).subscribe({
                next: response => {
                    console.log("Evolutions by Case ", response);
                    if (reloadState) {
                        this.evolutionsState.setAttentionsWithEvolutions(response);
                    }
                    resolve(response);
                },
                error: e => { reject(e); }
            })
        });
        return from(promise);
    }


    // GET MEDIA
    getMediaByEvolutionId(evolutionId: number) {

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.evolutionsService.getMediaByEvolutionId(evolutionId).pipe(
                tap((evolutions) => {
                    console.log("getEvolutionIdMedia", evolutions);
                }),
                finalize(() => this.evolutionsState.setLoadingGettingEvolutions(false))
            ).subscribe({
                next: response => {
                    console.log("Evolution Id Media ", response);
                    resolve(response);
                },
                error: e => { reject(e); }
            })
        });
        return from(promise);
    }

    loadEvolutionsCountByQPS(qps?: EvolutionsQPS) {

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.evolutionsService.getCountEvolutions(qps).pipe(
            ).subscribe({
                next: response => {
                    console.log("Evolutions Count ", response);
                    resolve(response);
                },
                error: e => { reject(e); }
            })
        });
        return from(promise);
    }
}
