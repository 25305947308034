import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseState } from './base.state';
import { Pagination } from '../../shared/models/pagination';
import { Arrangement } from 'src/app/shared/models/arrangement';
import { MetaDataArrangements } from '../../abstraction/arrangements.facade';
import { PatchArrangement, PracticeArrangement, PatchPracticeArrangement, PostPracticeArrangement } from '../services/arrangements.service';
import { ArrangementItem } from '../../shared/models/arrangementItem';

@Injectable({
   providedIn: 'root',
})
export class ArrangementsState extends BaseState {

    override store = {

        // Arrangements
        arrangements$: new BehaviorSubject<Arrangement[]>(null),
        loadingGetArrangements$: new BehaviorSubject<boolean>(null),
        loadingCreatingArrangement$: new BehaviorSubject<boolean>(null),
        loadingUpdatingArrangement$: new BehaviorSubject<boolean>(null),

        // Items
        arrangementItems$: new BehaviorSubject<ArrangementItem[]>(null),
        loadingGetArrangementItems$: new BehaviorSubject<boolean>(false),

        // Pagination
        pagination$: new BehaviorSubject<Pagination>(null),
        loadingGettingPagination$: new BehaviorSubject<boolean>(false),

        // Metadata
        metaDataArrangements$: new BehaviorSubject<MetaDataArrangements>(null),

        // Count
        arrangementsCount$: new BehaviorSubject<number>(0),

        // Arragement's Practices
        practicesArrangement$: new BehaviorSubject<PracticeArrangement[]>(null),
        loadingGettingPracticesArrangement$: new BehaviorSubject<boolean>(false),
        loadingUpdatingPracticeArrangement$: new BehaviorSubject<boolean>(false),
        loadingCreatingPracticeArrangement$: new BehaviorSubject<boolean>(false)
   };

   constructor() {
      super();
   }

   // ARRANGEMENTS
   getArrangements$() {
      return this.store.arrangements$.asObservable();
   }

   setArrangements( arrangements: Arrangement[]) {
      this.store.arrangements$.next( arrangements );
   }

   // GET ARRANGEMENTS
   isLoadingGetArrangements$() {
    return this.store.loadingGetArrangements$.asObservable();
   }

   setLoadingGetArrangements( isLoadingGetArrangements: boolean ) {
      this.store.loadingGetArrangements$.next( isLoadingGetArrangements );
   }

   // ADD
   isLoadingCreatingArrangement$() {
    return this.store.loadingCreatingArrangement$.asObservable();
   }

   setLoadingCreatingArrangement( isLoadingCreatingArrangement: boolean ) {
      this.store.loadingCreatingArrangement$.next( isLoadingCreatingArrangement );
   }

   addArrangement( arrangement: Arrangement ) {
      this.add<Arrangement>({ data: arrangement, storeRefAttribute: this.store.arrangements$ })
   }

   // UPDATE
   setLoadingUpdatinArrangement( isLoadingUpdatingArrangement: boolean ) {
      this.store.loadingUpdatingArrangement$.next( isLoadingUpdatingArrangement );
   }

   updateArrangement( arrangement: Arrangement, arrangementId: number ) {
      this.update<Partial<Arrangement>>({ data: arrangement, dataId: arrangementId, storeRefAttribute: this.store.arrangements$ })
   }

   isLoadingUpdatingArrangement$() {
      return this.store.loadingUpdatingArrangement$.asObservable();
   }

   // Pagination
   getPagination$(): Observable<Pagination> {
      return this.store.pagination$.asObservable();
   }

   setPagination( pagination: Pagination ) {
      this.store.pagination$.next( pagination );
   }

   isLoadingGettingPagination$() {
      return this.store.loadingGettingPagination$.asObservable();
   }

   setLoadingGettingPagination( isLoadingGettingPagination: boolean ) {
      this.store.loadingGettingPagination$.next( isLoadingGettingPagination );
   }

   // Metadata
   getMetaDataArrangements$(): Observable<MetaDataArrangements> {
      return this.store.metaDataArrangements$.asObservable();
   }

   setMetaDataArrangements( mda: MetaDataArrangements ) {
      this.store.metaDataArrangements$.next( mda );
   }

   // Count
   getArrangementsCount$(): Observable<number> {
      return this.store.arrangementsCount$.asObservable();
   }

   setArrangementsCount( count: number ) {
      this.store.arrangementsCount$.next( count );
   }

   // ARRANGEMENTS ITEMS
   isLoadingGetArrangementItems$() {
      return this.store.loadingGetArrangementItems$.asObservable();
   }

   setLoadingGetArrangementItems(isLoading: boolean) {
      this.store.loadingGetArrangementItems$.next(isLoading);
   }

   setArrangementItems(arrangementItems: ArrangementItem[]) {
      this.store.arrangementItems$.next(arrangementItems);
   }

   getArrangementItems$(): Observable<ArrangementItem[]> {
      return this.store.arrangementItems$.asObservable();
   }

   setLoadingPracticesArrangement( isLoading: boolean ){

   }

   getPracticesArrangement$() {
      return this.store.practicesArrangement$.asObservable();
   }

   addPracticeArrangement( practiceArrangement: PostPracticeArrangement ) {
      this.add<PostPracticeArrangement>({ data: practiceArrangement, storeRefAttribute: this.store.practicesArrangement$ })
   }

   setPracticesArrangement ( practicesArrangement: PracticeArrangement[] ) {
      this.store.practicesArrangement$.next( practicesArrangement );
   }

   // UPDATE
   setLoadingUpdatingPracticeArrangement ( isLoadingUpdatingPracticeArrangement: boolean ) {
      this.store.loadingUpdatingPracticeArrangement$.next( isLoadingUpdatingPracticeArrangement );
   }

   updatePracticeArrangement( practiceArrangement: PatchPracticeArrangement, practiceArrangementId: number) {
      this.update<Partial<PracticeArrangement>>({ data: practiceArrangement, dataId: practiceArrangementId, storeRefAttribute: this.store.practicesArrangement$ })
   }

   setLoadingCreatingPracticeArrangement ( isLoadingCreatingPracticeArrangement: boolean ) {
      this.store.loadingCreatingPracticeArrangement$.next( isLoadingCreatingPracticeArrangement );
   }

   setloadingGettingPracticesArrangement( isloadingGettingPracticesArrangement: boolean ) {
      this.store.loadingGettingPracticesArrangement$.next( isloadingGettingPracticesArrangement );
   }

   isLoadingGettingPracticesArrangement$(): Observable<boolean> {
      return this.store.loadingGettingPracticesArrangement$.asObservable();
   }

   isLoadingCreatingPracticeArrangement$(): Observable<boolean> {
      return this.store.loadingCreatingPracticeArrangement$.asObservable();
   }

   isLoadingUpdatingPracticeArrangement$(): Observable<boolean> {
      return this.store.loadingUpdatingPracticeArrangement$.asObservable();
   }

}
