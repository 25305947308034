<mat-list role="list" class="flex flex-column trackingList">
    <mat-list-item class="flex-100 align-start-center" role="listitem" *ngFor="let tracking of this.elements">
        <span class="mat-body text-secondary flex-100 flex-row align-start-center p-2">
            <span> {{ tracking.key }} </span>
            <mat-icon mat-list-icon class="mat-icon-medium mxs-3" [style.width.px]="28" [style.height.px]="28"
                [svgIcon]="'arrow-right-pointed'">
            </mat-icon>
            <span class="text-secondary-darker">
                {{ tracking.value }}
            </span>
            <i class="text-secondary mls-2" *ngIf="!!tracking.details">
                {{ tracking.details }}
            </i>
        </span>
    </mat-list-item>
</mat-list>
