import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Financier } from 'src/app/shared/models/financier';

@Component({
  selector: 'app-financier-selector',
  templateUrl: './financier-selector.component.html',
  styleUrls: ['./financier-selector.component.scss']
})
export class FinancierSelectorComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() name: string;
  @Input() nameSearching: string;

  financiers$: Observable<Financier[]>;
  _financiers: Subscription;
  financiers: Financier[];

  constructor(
    private entitiesFacade: EntitiesFacade,
  ) { }

  ngOnInit(): void {
    this.entitiesFacade.loadFinanciers();
    this.financiers$ = this.entitiesFacade.getFinanciers$();
    this.subscribeToData();
  }

  subscribeToData() {
    this._financiers = this.financiers$
      .subscribe(financiers => this.financiers = financiers);
  }

  ngOnDestroy(): void {
    this._financiers.unsubscribe();
  }

  // calculatePatientDNI(patient: Patient) {
  //   return patient.documentNumber;
  // }

}
