import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthGuardUnlogged } from './core/guards/authUnlogged.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'app', // Redirige a la ruta 'app'
        pathMatch: 'full',
        data: { breadcrumb: 'Home' },
    },
    {
        path: 'app',
        canActivateChild: [AuthGuard], // Aplica la guardia de autenticación para todos los hijos de la ruta 'app'
        loadChildren: () =>
            import('./core/base-layout/base-layout.module').then(mod => mod.BaseLayoutModule),
            data: { breadcrumb: 'Home' },
    },
    {
        path: 'login',
        canLoad: [AuthGuardUnlogged], // Aplica la guardia de autenticación para usuarios no autenticados
        loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule),
        data: { breadcrumb: 'Login' },
    },
    {
        path: 'not-found',
        loadChildren: () =>
            import('./modules/not-found/not-found.module').then((mod) => mod.NotFoundModule),
    },
    {
        path: '**', // Captura todas las rutas no encontradas y las redirige a 'not-found'
        redirectTo: 'not-found'
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
