<form *ngIf="operatorSelectorForm" [formGroup]="operatorSelectorForm" class="flex-row">
    <app-select-searching-dynamic-input class="m-0 p-0 text-secondary-darker" [leftIcon]='"support_agent"'
        [defaultValue]="this.actualOperator.id.toString()" [elements]="this.operatorsOnSelect"
        (selected)="this.onUpdateOperator()" [name]="this.OPERATORS"
        [nameSearching]="this.OPERATORS_SEARCHING" [form]="this.operatorSelectorForm"
        [placeholder]="'Operador/a del caso'" [calculateId]="this.calculateId" [calculateName]="this.calculateName"
        [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar operador...'"
        [httpGet]="this.getOperatorsByNameAndSurname">
    </app-select-searching-dynamic-input>
</form>
