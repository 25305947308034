import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { skipWhile, tap, } from 'rxjs/operators';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { CaseDateFilters, CasesFacade, EvolutionPdfQPS } from 'src/app/abstraction/cases.facade';
import { ROUTES } from 'src/app/core/enums/routes';
import { AttentionsQPS, AttentionsService } from 'src/app/core/services/attentions.service';
import { CasesService, ReportQPS } from 'src/app/core/services/cases.service';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { GeneralService, GoBack } from 'src/app/core/services/general.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Agreement } from 'src/app/shared/models/agreement';
import { Attention } from 'src/app/shared/models/attention';
import { Case } from 'src/app/shared/models/case';
import { CaseState } from 'src/app/shared/models/caseState';
import { Evolution } from 'src/app/shared/models/evolution';
import { Patient } from 'src/app/shared/models/patient';
import { Provider } from 'src/app/shared/models/provider';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import Swal from 'sweetalert2';
import { DialogChangingStateCaseComponent } from '../../components/dialog-changing-state-case/dialog-changing-state-case.component';
import { EvolutionsComponent } from '../../components/evolutions/evolutions.component';
import { NomenclatorFacade } from 'src/app/abstraction/nomenclator.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Practice } from 'src/app/shared/models/practice';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { RequisitionType, RequisitionState } from '../../../../shared/models/requisition';
import { PatchRequisitionBody, RequisitionService } from '../../../../core/services/requisition.service';
import { StorageService } from '../../../../core/services/storage.service';
import { RequisitionStates } from 'src/app/core/enums/requisitionsStates';
import { MatRadioChange } from '@angular/material/radio';
import { RequisitionsFacade } from 'src/app/abstraction/requisitions.facade';
import { RequisitionReasons } from 'src/app/core/enums/requisitionsReasons';
import { RequisitionTypes } from 'src/app/core/enums/requisitionTypes';
import { UpdatingCase } from 'src/app/shared/models/submodels/updatingCase';
import { MapsFacade } from 'src/app/abstraction/maps.facade';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MatTabGroup } from '@angular/material/tabs';

// //// CASE COVER ///////////////////
interface AttentionComposedWithEvolutions extends Attention {
    evolutions?: Evolution[];
}

enum LogoOptions {
    NO_LOGO = 1,
    COMPANY_LOGO = 2,
    CUSTOM_LOGO = 3,
    ADOMICILIO_LOGO = 4,
}

enum OPTIONS_TO_EXPORT {
    PDF = 'pdf',
    ZIP = 'zip',
    CSV = 'csv',
}
// /////////////////////////////////////

@Component({
    selector: 'app-case',
    templateUrl: './case.component.html',
    styleUrls: ['./case.component.scss'],
})


export class CaseComponent implements OnInit {
    @ViewChild('generateOperationReportTemplate', { static: true }) generateOperationReportTemplate: TemplateRef<any>;
    loadingCase$: Observable<boolean>;
    loadingOrders$: Observable<boolean>;
    goBack$: Observable<GoBack>;
    _goBack: Subscription;
    _mda: Subscription;
    _case: Subscription;
    _focus: Subscription;
    goBack: GoBack;
    TITLE_CASES_DETAIL = 'Detalles del caso';
    case: Case;
    lastCaseState: CaseState;
    isProvisionsDetailExpanded = false;
    attentions: Attention[];
    attentionsCaseActive: Attention[];
    _attentions: Subscription;
    _attentionsCaseActive: Subscription;
    _loadAttentions: Subscription;
    filteredAttentions: Attention[];
    loadingCalendar: boolean = true;
    loadingInnerCalendar: boolean = false;
    agreementsFromPatient: Agreement[];
    evolutions: Evolution[];
    dataEvolutions;
    evolutionsComponent: EvolutionsComponent;
    refresh = false;
    _routing: Subscription;
    loadingPractices = false;
    // CASE COVER
    userSubscription: Subscription;
    user: User;
    onSubmitExportFunction;
    showOptions = false;
    practices = [];
    OPTIONS_TO_EXPORT = OPTIONS_TO_EXPORT;
    caseCoverPDF: AttentionComposedWithEvolutions[];
    // OPERATION REPORT
    formOperationReport: FormGroup;
    agreements: Agreement[];
    providers = new Set<Provider>([]);

    // Agreement que se muestra en el dialog
    activeAttention: Attention;
    datesToPostSelected: moment.Moment[] = [];
    elementsProvidersDialog: Provider[] = [];
    allPatientsDialog: Patient[] = [];
    allAgreementsDialog: Agreement[] = [];
    allAgreementsDialogList: Agreement[] = [];
    // Agreement que se selecciona al seleccionar en el select de nueva atencion! -> Se usa para saber que
    // restricciones tiene la carga (fecha de inicio/fin hora etc.)
    agreementSelected: Agreement;
    @ViewChild('calendar', { static: false }) calendarToPost;
    @ViewChild('scheduleDialogTemplate', { static: true }) scheduleDialogTemplate: TemplateRef<any>;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    loadingEditAttention = false;
    isRepeatSchedule = false;
    loadingGetAgreements = false;
    objectValues = Object.values;
    loadingCreateAttention = false;
    filterForm: FormGroup;
    filterProviderId: number;
    filterPracticeId: number;
    initialAgreementId: number = undefined;

    // Evolutions side bar
    evolutionsSideBarShow$: Observable<boolean>;

    @ViewChild('updateState', { static: true }) updateState: TemplateRef<any>;
    requisitionType: RequisitionType;
    requisitionState: RequisitionState;
    requisitionId: number;
    requisitionTransitionStates;
    changeStateSelected;
    formUpdateState;
    isLoadingReport: boolean = false;
    _isLoadingEvolutionsPdf: Subscription;
    pdfSpinner$: Observable<boolean>;

    public LOGO_OPTIONS = LogoOptions;
    // exportAsPDFConfig: ExportAsConfig = { FIXME: FER
    // 	type: 'pdf', // the type you want to download
    // 	elementIdOrContent: 'coverToPrint', // the id of html/table element
    // };

    // FORM //
    public readonly PROVIDER = 'provider';
    public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly PRACTICE = 'practice';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly PATIENT = 'patient';
    public readonly AGREEMENT = 'agreement';
    public readonly DATE_FROM = 'fromDate';
    public readonly DATE_TO = 'toDate';
    public readonly TIME_FROM = 'timeFrom';
    public readonly HOUR_DURATION = 'hourDuration';
    public readonly MINUTE_DURATION = 'minuteDuration';
    public readonly DATE_END_REPEAT = 'endDateRepeat';
    public readonly STATE_ATTENTION = 'stateAttention';

    public readonly ALL_PROVIDERS_ID = -1;
    public dayChipsSelected: Map<string, boolean> = new Map<string, boolean>();

    _dialogRef;

    // CASE COVER FORM
    public readonly LOGO_COVER = 'logo';
    public readonly LOAD_FILE_COVER = 'loadFile';
    public readonly DATE_FROM_COVER = 'fromDateCover';
    public readonly DATE_TO_COVER = 'toDateCover';

    filterCoverForm: FormGroup;
    elementsLogo = [
        {
            id: LogoOptions.NO_LOGO,
            name: 'Sin logo',
        },
        {
            id: LogoOptions.COMPANY_LOGO,
            name: 'Logo de la empresa',
        },
        {
            id: LogoOptions.CUSTOM_LOGO,
            name: 'Logo personalizado',
        }
    ];
    cont: number = 0;
    historyFromDate: Date;
    historyToDate: Date;
    get dateFromCoverForm() {
        return this.filterCoverForm.get(this.DATE_FROM_COVER).value;
    }
    get dateToCoverForm() {
        return this.filterCoverForm.get(this.DATE_TO_COVER).value;
    }
    get logoCoverForm() {
        return this.filterCoverForm.get(this.LOGO_COVER).value;
    }
    get loadFileCoverForm() {
        return this.filterCoverForm.get(this.LOAD_FILE_COVER).value;
    }
    set dateToCoverForm(date: Date) {
        date != null ? this.filterCoverForm.controls[this.DATE_TO_COVER].setValue(date)
            : this.filterCoverForm.controls[this.DATE_TO_COVER].reset()
    }
    set dateFromCoverForm(date: Date) {
        date != null ? this.filterCoverForm.controls[this.DATE_FROM_COVER].setValue(date)
            : this.filterCoverForm.controls[this.DATE_FROM_COVER].reset()
    }
    set optionLogoCoverForm(optionLogoId: number) {
        optionLogoId != null ? this.filterCoverForm.controls[this.LOGO_COVER].setValue(optionLogoId.toString())
            : this.filterCoverForm.controls[this.LOGO_COVER].reset()
    }

    get from() {
        return this.formOperationReport.get(this.DATE_FROM);
    }
    get to() {
        return this.formOperationReport.get(this.DATE_TO);
    }
    setDateTo(date) {
        this.formOperationReport.controls[this.DATE_TO].setValue(date);
    }

    isEnabledHistoryMode$: Observable<boolean>;
    dateHistoryMode$: Observable<Date>;

    provisions: Provision[];
    _historyModeDate: Subscription;
    _allProvisionsForCase: Subscription;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private casesService: CasesService,
        public attentionsService: AttentionsService,
        private router: Router,
        public generalService: GeneralService,
        private casesFacade: CasesFacade,
        private cd: ChangeDetectorRef,
        private attentionsFacade: AttentionsFacade,
        private fileUtilitiesService: FileUtilitiesService,
        private entitiesFacade: EntitiesFacade,
        // private exportAsService: ExportAsService,
        private nomenclatorFacade: NomenclatorFacade,
        private evolutionsFacade: EvolutionsFacade,
        private authService: AuthenticationService,
        private sanitizer: DomSanitizer,
        private provisionsFacade: ProvisionsFacade,
        public requisitionService: RequisitionService,
        private storageService: StorageService,
        private requisitionsFacade: RequisitionsFacade,
        private mapsFacade: MapsFacade,
        private breadcrumbService: BreadcrumbService
    ) {
        this.loadingCase$ = this.casesFacade.isLoadingGettingCase$();
        this.loadingOrders$ = this.casesFacade.isLoadingGettingOrders$();
    }

    swalWithCustomizeButtons: any;

    ngOnInit() {

        this._focus = this.breadcrumbService.getScreenToJump$().subscribe(value =>{
            if(value){
                this.tabGroup.selectedIndex = 1
            }
        })
        console.log("case.component ngOnInit");
        this.userSubscription = this.authService.getUser$().subscribe((user) => (this.user = user));

        this.casesFacade.setShowCase(true); // If true, show toogle history mode

        // All provisions
        this.provisionsFacade.getAllProvisionsCase$().subscribe(provisions => this.provisions = provisions);

        // If exist filteredDate (from provisional-control) then set selector
        let startDate: Date;
        let endDate: Date

        if (!!history.state.filteredDate) {
            startDate = moment(history.state.filteredDate).startOf('month').toDate();
            endDate = moment(history.state.filteredDate).endOf('month').toDate();
        } else {
            startDate = moment().startOf('month').toDate();
            endDate = moment().endOf('month').toDate()
        }
        this.casesFacade.setCaseDateFilters({ historyFromDate: startDate, historyToDate: endDate, touched: false })

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this.evolutionsSideBarShow$ = this.evolutionsFacade.getEvolutionsSideBarShow$();


        this.calculateNameProvider = this.calculateNameProvider.bind(this);
        this.generalService.sendMessage(this.TITLE_CASES_DETAIL);
        this._routing = this.route.params.subscribe(params => {
            const id = +params['id'];

            if (!!id) {
                let requisitionStorage = this.storageService.getObjectfromSessionStorage("requisition");
                if (!!requisitionStorage) {
                    if (requisitionStorage?.caseId == id) {
                        if (!!requisitionStorage?.requisitionType) {
                            this.requisitionType = requisitionStorage.requisitionType;
                        }

                        if (!!requisitionStorage?.requisitionState) {
                            this.requisitionState = requisitionStorage.requisitionState;
                        }

                        if (!!requisitionStorage?.requisitionId) {
                            this.requisitionId = requisitionStorage?.requisitionId;
                        }

                        if (!!requisitionStorage.requisitionTransitionStates) {
                            this.requisitionTransitionStates = requisitionStorage.requisitionTransitionStates
                        }
                    }
                }
            }

            firstValueFrom(this.nomenclatorFacade.loadPractices({ page: 0, size: 1100, active: true }))
                .then(_ => this.loadingPractices = false)
                .finally(() => this.loadingPractices = false);

            this.casesFacade.loadCase(Number(id), { withProvisions: false });
			this.evolutionsFacade.loadEvolutionsByNoteQPS({ caseId: Number(id), category:'NOTA' }, true);
            this.casesFacade.loadCaseArrangements(id);

            this._case = this.casesFacade.getCase$().pipe(
                tap((val) => console.log("CASE active: ", val)),
                skipWhile((val) => (val == null || val == undefined)),
                tap(acase => {
                    this.case = acase;
                    // Seteo el ultimo del array --> estan ordenados por id creciente
                    this.setLastCase();
                    const patientSurname = acase?.patient?.surname ? acase.patient.surname : '';
                    const patientName = acase?.patient?.name ? acase.patient.name : '';
                    const fullName = `${patientSurname} ${patientName}`;
                    const docNumber = acase?.patient?.documentNumber;
                    this.generalService.sendMessage(`${fullName} - DNI: ${docNumber}`);
                    this.loadAttentions();
                }),
            ).subscribe(() => {
                this.dataEvolutions = {
                    name: this.case.patient.name,
                    typeDocument: this.case.patient.typeDocument,
                    document: this.case.patient.documentNumber,
                    cif: this.case.financierInternalCode,
                    surname: this.case.patient.surname,
                };
            });
        });

        // Attentions Case Active
        this._attentionsCaseActive = this.attentionsFacade.getAttentionsCaseActive$().subscribe(attentions => {
            if (!!attentions) {
                console.log("this._attentionsCaseActive");
                this.attentionsCaseActive = attentions.map(a => { return { ...a } }) // Deep copy - solution to shallow copy
            }
        })

        // Attentions
        this._attentions = this.attentionsFacade.getAttentions$().subscribe(attentions => {
            if (!!attentions) {
                console.log("this._attentions");
                this.attentions = attentions.map(a => { return { ...a } }) // Deep copy - solution to shallow copy
            }
        })

        this.pdfSpinner$ = this.casesFacade.isLoadingGetEvolutionsPdf$();
    }

    loadAttentions() {

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(dateRange => {
            if (dateRange && this.case && this.provisions) {
                this.historyFromDate = dateRange.historyFromDate;
                this.historyToDate = dateRange.historyToDate;
                const range: CaseDateFilters = this.attentionsService.getPeriodToLoadAttentions(dateRange.historyFromDate, dateRange.historyToDate);
                this._loadAttentions = this.attentionsFacade.loadAttentionsGeneric({ casesIds: [this.case.id], fromDate: range.historyFromDate, toDate: range.historyToDate }, true)
                    .subscribe(attentions => this.attentionsCaseActive = attentions);
            }
        })
    }

    onClickGoBack() {

        console.log("onClickGoBack");

        if (this.goBack.route == ROUTES.ATTENTIONS.toString()) {
            this._mda = this.attentionsFacade.getMetaDataAttentions$().subscribe((mda) => {
                let afp: AttentionsQPS = mda;
            });
        }
        this.router.navigate([this.goBack.route]);
    }

    onTabChanged(e) {
        console.log(e);
        setTimeout(() => {
            this.refresh = !this.refresh;
            this.cd.detectChanges();
        }, 100);
    }

    setLastCase() {
        !!this.case && !!this.case.states ? this.lastCaseState = this.case.states[this.case.states.length - 1] : this.lastCaseState = null;
    }

    onClickCloseDialog() {
        this._dialogRef.close();
    }

    onClickCloseDialogClosingCase() {
        this._dialogRef.close();
    }

    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }

    calculateNamePractice(element: Practice) {
        return element ? element.name : null;
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    calculateNamePatient(element: Patient) {
        return element ? element.surname + ' ' + element.name : null;
    }

    calculateNameAgreement(element: Agreement): string {
        return element
            && element.practice
            && element.practice.name
            ? element.practice.name + ' • ' + (element.provision?.fee ? element.provision.fee : '')
            : 'Sin datos';
    }

    calculateNameState(element: StateAttention) {
        return element ? element.name : null;
    }

    calculateIdState(element: StateAttention) {
        return element ? element.id : null;
    }

    openDialogCalendar(title: string, component, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(component, {
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template: this.scheduleDialogTemplate, title },
        });
        this._dialogRef.afterClosed().subscribe(afterClosed);
    }

    openDialogChangingStateCase(
        title: string,
        acase: Case,
        component,
        style: { minWidth: string, maxHeight: string },
        action,
        afterClosed?
    ): void {
        this._dialogRef = this.dialog.open(component, {
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { title, case: acase, dialogRef: this._dialogRef, action },
        });
        this._dialogRef.afterClosed().subscribe();
    }

    onCloseCase() {
        this.openDialogChangingStateCase(
            'Cerrar caso',
            this.case,
            DialogChangingStateCaseComponent,
            { maxHeight: '95vh', minWidth: '45%' },
            DialogChangingStateCaseComponent.ACTION.CLOSE,
            (dataOnClose) => { console.log(dataOnClose); }
        );
    }

    onReopenCase() {
        this.openDialogChangingStateCase(
            'Reabrir caso',
            this.case,
            DialogChangingStateCaseComponent,
            { maxHeight: '95vh', minWidth: '45%' },
            DialogChangingStateCaseComponent.ACTION.REOPEN,
            (dataOnReopen) => { console.log(dataOnReopen); }
        );
    }

    onChangeEnabledFinancier() {
        this.swalWithCustomizeButtons.fire({
            title: 'Atención',
            text: `¿Está seguro que desea modificar la visibilidad del cliente a "${!this.case?.caseParametrization?.enabledFinancier ? 'Visible' : 'No visible'}" ?`,
            icon: 'question',
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: () => {
                let caseUpdate: UpdatingCase = {
                    caseParametrization: {
                        enabledFinancier: !this.case.caseParametrization.enabledFinancier
                    }
                };
                return this.casesFacade.updateCase(caseUpdate, this.case.id).toPromise()
                    .then((_) => {
                        // Probar passar a facturable y que se actualice el button de informar
                        this.swalWithCustomizeButtons.fire({
                            title: 'Visibilidad del cliente modificada correctamente',
                            icon: 'success',
                        });
                    })
                    .catch((err) => {
                        console.log('error', err);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });

    }

    onClickOperationReport() {
        this.formOperationReport = this.createOperationReportForm();
        this.openDialogGenerateOperationReport(
            'Exportar reporte de actuación',
            this.generateOperationReportTemplate,
            { maxHeight: '95vh', minWidth: '20%' },
            () => { }
        );
    }
    createOperationReportForm() {
        let to = new Date();
        return this.formBuilder.group(
            {
                [this.DATE_FROM]: [
                    '',
                    [CustomValidators.required('Fecha requerida')]
                ],
                [this.DATE_TO]: [
                    to,
                    [CustomValidators.required('Fecha requerida')]
                ],
            },
            {
                validators: [
                    CustomValidators.CVFromToDate('La fecha hasta debe ser superior a la desde', 'errorDate', this.DATE_FROM, this.DATE_TO),
                ]
            });
    }

    openDialogGenerateOperationReport(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    onClickExportOperationReport() {
        // DESCARGAR BASE64
        let reportQps: ReportQPS = {
            fromDate: moment(this.from.value, 'YYYY-MM-DD').toDate(),
            toDate: moment(this.to.value, 'YYYY-MM-DD').toDate()
        };
        console.log(reportQps);
        let fileName;
        if (!!this.case.patient && !!this.case.patient.name && !!this.case.patient.surname) {
            fileName = 'Reporte de actuación- '
                + this.case.patient.surname + ' '
                + this.case.patient.name
                + '(desde:' + moment(this.from.value).format('DD-MM-YYYY')
                + ' hasta:' + moment(this.to.value).format('DD-MM-YYYY') + ')';
        } else {
            fileName = 'Reporte de actuación(desde:'
                + moment(this.from.value).format('DD-MM-YYYY')
                + ' hasta:' + moment(this.to.value).format('DD-MM-YYYY') + ')';
        }

        this.isLoadingReport = true;

        this.casesService.getCaseOperationReport(this.case.id, reportQps).subscribe({
            next: data => {
                let blobb: Blob = this.fileUtilitiesService.getFileBlob2(data.mediaUrl);
                // this.exportAsService.downloadFromBlob(blobb, fileName); FIXME: FER
                this.isLoadingReport = false;
                this._dialogRef.close();
            },
            error: error => {
                this.swalWithCustomizeButtons.fire({
                    icon: 'error',
                    title: 'Atención',
                    text: "No se pudo crear el archivo, vuelva a intentar"
                })
                this.isLoadingReport = false;
            }
        });
    }

    onClickCloseDialogGenerateOperationReport() {
        this._dialogRef.close();
    }

    // Métodos para la carátula de caso. Se replica comportamiento de evolutions.component
    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    onClickOptionToExport(option: string) {
        this.filterCoverForm = this.createCoverForm();
        this.onSubmitExportFunction = this.onSubmitExport(option);
        switch (option) {
            case OPTIONS_TO_EXPORT.PDF:
                this.showOptions = true
                break;

            default:
                console.error('INCORRECT OPTION TO EXPORT');
                break;
        }

    }

    onSubmitExport(option: string) {
        console.log('Se ejecutó onSubmitExport');

        return () => {
            switch (option) {
                case OPTIONS_TO_EXPORT.PDF: {
                    this.exportAsPDF()
                    break;
                }

                default:
                    break;
            }
        }
    }

    exportAsPDF() {
        let evolutionsPdfQPS: EvolutionPdfQPS = {
            caseId: this.case.id,
            financierId: this.case.financier.id,
        }
        evolutionsPdfQPS.groupByPractice = false;
        evolutionsPdfQPS.groupByPracticeAndProvider = false;
        let logo = this.filterCoverForm.get(this.LOGO_COVER).value;
        switch (Number(logo)) {
            case this.LOGO_OPTIONS.NO_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = false;
                evolutionsPdfQPS.image = null;
                break;
            case this.LOGO_OPTIONS.CUSTOM_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = false;
                evolutionsPdfQPS.image = this.loadFileCoverForm;
                break;
            case this.LOGO_OPTIONS.COMPANY_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = true;
                evolutionsPdfQPS.image = null;
                break;
        }
        evolutionsPdfQPS.fromDate = moment(this.casesFacade.getCaseDateFilter().historyFromDate).format('YYYY-MM-DD');
        evolutionsPdfQPS.toDate = moment(this.casesFacade.getCaseDateFilter().historyToDate).format('YYYY-MM-DD');
        evolutionsPdfQPS.isHideFromFinancier = false;
        evolutionsPdfQPS.isHideFromProvider = false;

        evolutionsPdfQPS.onlyCover = true;

        this.casesFacade.loadgetEvolutionsPDF(evolutionsPdfQPS).subscribe((files => {
            console.log(files);
            for (let file of files) {
                this.downloadPdf(file, 'evoluciones_adomicilio_' + new Date().toISOString());
            }
        }))
    }

    downloadPdf(base64String, fileName) {
        const source = base64String;
        const link = document.createElement("a");
        link.href = source;
        link.download = `${fileName}.pdf`
        link.click();
    }

    createCoverForm(): FormGroup {
        return this.formBuilder.group({
            [this.LOGO_COVER]: ['', [CustomValidators.required('Estado requerido')]],
            [this.LOAD_FILE_COVER]: [''],
        }, {
            validators: [
                CustomValidators.requiredDependingOnControlValue('El logo es requerido', 'errorDepending', this.LOAD_FILE_COVER, this.LOGO_COVER, LogoOptions.CUSTOM_LOGO)
            ]
        });
    }

    calculateIdlogo(element) {
        return element ? element.id : null;
    }

    calculateNameLogo(element) {
        return element ? element.name : null;
    }

    private filterAndSortAttentionsToExport(): AttentionComposedWithEvolutions[] {
        const attentionsToExport = this.attentions.filter(attention => (!!attention.id));
        const attentions = attentionsToExport.filter(attention => new Date(attention.fromDate) >= new Date(this.dateFromCoverForm) && new Date(attention.fromDate) <= moment(this.dateToCoverForm).add(1, 'day').toDate())
        console.log('ATENCIONES FILTRADAS', attentions);

        this.practices = [];
        this.mapAttentionsPracticesToExport(attentions);
        return attentions;
    }

    setCountPractices(practiceId) {
        if (this.practices.length == 0) {
            this.practices.push({ id: practiceId, count: 1 });
        } else {
            let practiceIndex = this.practices.findIndex(practice => practice.id == practiceId);
            if (practiceIndex > -1) {
                this.practices[practiceIndex].count += 1;
            } else {
                this.practices.push({ id: practiceId, count: 1 });
            }
        }
    }

    mapAttentionsPracticesToExport(attentions) {
        attentions.filter(attention => !!attention.id).forEach(attention => {
            if (attention?.state?.id != 6) {
                if (attention?.practiceId) {
                    this.setCountPractices(attention.practiceId);
                }
            }
        });
        this.filterPractices();
    }

    filterPractices() {
        this.practices.forEach(practice => {
            this.attentions.filter(attention => !!attention.id).forEach(att => {
                if (att?.practice?.id == practice.id) {
                    practice['name'] = att.practice.name;
                }
            })
        });
    }
    // Hasta acá métodos para la carátula de caso

    // Show side bar right with evolutions
    showSideBarEvolutions() {
        this.evolutionsFacade.setEvolutionsSideBarShow(true);
    }

    // Requisitions actions
    changeState() {
        this.formUpdateState = this.createformUpdateState();

        this.openDialog(
            'Modificar estado',
            this.updateState,
            { maxHeight: '95vh', minWidth: '25%' },
            () => { }
        );
    }

    openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    createformUpdateState(): FormGroup {
        return this.formBuilder.group({
            radioButton: new FormControl("", [Validators.required])
        })
    }

    transitionStatesPermitted(transitions: RequisitionState[]) {
        return transitions.filter(t => t.description != RequisitionStates.ARCHIVED);
    }

    clickRadiobuttonChangeState(event: MatRadioChange) {
        this.changeStateSelected = event.value; // Save option number
    }

    onClickConfirmUpdateState() {
        const req: PatchRequisitionBody = {
            id: this.requisitionId,
            requisitionState: { id: this.changeStateSelected },
        }
        this.patchRequisition(req, 'Cambio de estado de solicitud realizado con éxito');
    }

    patchRequisition(requisition: PatchRequisitionBody, succesMsg: string) {
        this.requisitionsFacade.updateRequisition(requisition).subscribe(
            req => {
                this.swalWithCustomizeButtons.fire({
                    title: succesMsg,
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false
                }).then(() => {
                    this._dialogRef.close();
                    this.requisitionState = req?.requisitionResponseDto?.requisitionState;
                    let requisitionStorage = this.storageService.getObjectfromSessionStorage("requisition");
                    let objectToSave = { ...requisitionStorage, requisitionState: this.requisitionState };
                    this.storageService.removeValuesByKeyOnSessionStorage("requisition");
                    this.storageService.setObjectToSessionStorage("requisition", objectToSave);
                })
            }
        )
    }

    isSwitchToCaseEnabled(): Boolean {
        const idsEnabled = [
            Object.keys(RequisitionStates).indexOf('CONFIRMED'),
            Object.keys(RequisitionStates).indexOf('BUDGETED'),
            Object.keys(RequisitionStates).indexOf('PENDING'),
        ]
        return idsEnabled.includes(this.requisitionState?.id);
    }

    switchToCase() {

        const newState = Object.keys(RequisitionStates).indexOf('ARCHIVED');
        const req: PatchRequisitionBody = {
            id: this.requisitionId,
            requisitionState: { id: newState },
            requisitionReason: { id: Object.values(RequisitionReasons).indexOf(RequisitionReasons.AUTORIZADO) }
        }
        if (this.requisitionType?.description != RequisitionTypes.REQUEST && this.requisitionType.description != RequisitionTypes.PROVISIONAL_INCREMENT) {
            req.requisitionReason = { id: Object.values(RequisitionReasons).indexOf(RequisitionReasons.ACCION_REALIZADA) }
        }

        this.swalWithCustomizeButtons.fire({
            title: 'Esta acción no se puede revertir',
            icon: 'question',
            html: `¿Está seguro que desea pasar la solicitud <b>#${this.requisitionId}</b> a caso?`,
            confirmButtonText: 'ACEPTAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            reverseButtons: true,
            preConfirm: () => {
                return this.requisitionsFacade.updateRequisition(req).subscribe(requisition => {
                    if (req) {
                        this.swalWithCustomizeButtons.fire({
                            html: `La solicitud <b>#${this.requisitionId}</b> se convirtió en caso exitosamente`,
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false
                        });
                        this.switchToCaseActions(requisition)
                    }
                })
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    }

    switchToCaseActions(requisition) {
        let requisitionStorage = {
            caseId: requisition.caseRequisitionResponseDto.id,
            requisitionId: requisition?.requisitionResponseDto?.id,
            requisitionTransitionStates: requisition?.requisitionResponseDto.requisitionTransitionStates,
            requisitionType: requisition.requisitionResponseDto.requisitionType,
            requisitionState: requisition.requisitionResponseDto.requisitionState,
        };
        this.requisitionId = requisitionStorage.requisitionId;
        this.requisitionState = requisitionStorage.requisitionState;
        this.requisitionType = requisitionStorage.requisitionType;
        this.storageService.setObjectToSessionStorage("requisition", requisitionStorage);
        this.case = { ...this.case, requested: false };
        this.casesFacade.setCase(this.case);
    }

    ngOnDestroy(): void {
        this._attentions ? this._attentions.unsubscribe() : null;
        this.attentionsFacade.setAttentions(null);

        this._attentionsCaseActive ? this._attentionsCaseActive.unsubscribe() : null;
        this.attentionsFacade.setAttentionsCaseActive(null);

        this.provisionsFacade.setAllProvisionsCase(null); // Reset provisions state
        this.provisionsFacade.setProvisionsDisplayedOnScreen(null);
        this.casesFacade.setShowCase(false); // Control show toggle history mode
        this._routing ? this._routing.unsubscribe() : null;
        this._goBack ? this._goBack.unsubscribe() : null;
        this._mda ? this._mda.unsubscribe() : null;
        this.casesFacade.setCase(null);
        this._case ? this._case.unsubscribe() : null;
        this.evolutionsFacade.setEvolutionsSideBarShow(false);
        this.userSubscription ? this.userSubscription.unsubscribe() : null;
        this._loadAttentions ? this._loadAttentions.unsubscribe() : null;
        this._historyModeDate ? this._historyModeDate.unsubscribe() : null;

        this.casesFacade.setCaseArrangements(null);
        this._focus.unsubscribe();
        this.breadcrumbService.setScreenToJump(null)

        // this.mapsFacade.setMarkers([]);
    }
}
