import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseState } from './base.state';
import { Evolution } from '../../shared/models/evolution';
import { AttentionComposedWithEvolutions } from '../../abstraction/evolutions.facade';

@Injectable({
    providedIn: 'root',
})
export class EvolutionsState extends BaseState {


    override store = {

        // EVOLUTIONS by CASE
        evolutionsSideBarShow$: new BehaviorSubject<boolean>(false),
        evolutionsByCase$: new BehaviorSubject<Evolution[]>([]),
        evolutionsTypeNote$: new BehaviorSubject<Evolution[]>([]),
        isLoadingPatchingEvolution$: new BehaviorSubject<boolean>(false),
        isLoadingCreatingEvolution$: new BehaviorSubject<boolean>(false),
        isLoadingGettingEvolutions$: new BehaviorSubject<boolean>(false),
        attentionsWithEvolutions$: new BehaviorSubject<AttentionComposedWithEvolutions[]>(null),
    }


    constructor() {
        super()
    }


    // Side Bar for Evolutions inside Case
    getEvolutionsSideBarShow$(): Observable<boolean> {
        return this.store.evolutionsSideBarShow$.asObservable();
    }


    setEvolutionsSideBarShow(show: boolean) {
        this.store.evolutionsSideBarShow$.next(show);
    }

    // Evolutions type Nota

    getEvolutionsTypeNote$(): Observable<Evolution[]> {
        return this.store.evolutionsTypeNote$.asObservable();
    }


    setEvolutionsTypeNote(evolutions: Evolution[]) {
        this.store.evolutionsTypeNote$.next(evolutions);
    }

    getEvolutionsByCase$(): Observable<Evolution[]> {
        return this.store.evolutionsByCase$.asObservable();
    }


    setEvolutionsByCase(evolutions: Evolution[]) {
        this.store.evolutionsByCase$.next(evolutions);
    }

    getAttentionsWithEvolutions$(): Observable<AttentionComposedWithEvolutions[]> {
        return this.store.attentionsWithEvolutions$.asObservable();
    }


    setAttentionsWithEvolutions(evolutions: AttentionComposedWithEvolutions[]) {
        this.store.attentionsWithEvolutions$.next(evolutions);
    }


    // PATCH POST
    getLoadingPatchingEvolution() {
        return this.store.isLoadingPatchingEvolution$.asObservable();
    }

    setLoadingPatchingEvolution(isLoadingPatchingEvolution: boolean) {
        this.store.isLoadingPatchingEvolution$.next(isLoadingPatchingEvolution);
    }

    updateEvolutionByCase(evolution: Evolution, evolutionId: number) {
        this.update<Partial<Evolution>>({ data: evolution, dataId: evolutionId, storeRefAttribute: this.store.evolutionsByCase$ })
    }


    // CREATE
    getLoadingCreatingEvolution() {
        return this.store.isLoadingCreatingEvolution$.asObservable();
    }

    setLoadingCreatingEvolution(isLoadingCreatingEvolution: boolean) {
        this.store.isLoadingCreatingEvolution$.next(isLoadingCreatingEvolution);
    }

    addEvolutionByCase(evolution: Evolution) {
        this.add<Evolution>({ data: evolution, storeRefAttribute: this.store.evolutionsByCase$ })
    }

    // CREATE NOTA

    addEvolutionTypeNote(evolution: Evolution) {
        this.add<Evolution>({ data: evolution, storeRefAttribute: this.store.evolutionsTypeNote$ })
    }

    // LOADING EVOLUTIONS
    isLoadingGettingEvolutions() {
        return this.store.isLoadingGettingEvolutions$.asObservable();
    }

    setLoadingGettingEvolutions(isLoadingGettingEvolutions: boolean) {
        this.store.isLoadingGettingEvolutions$.next(isLoadingGettingEvolutions);
    }


    isLoadingPatchingEvolution() {
        return this.store.isLoadingPatchingEvolution$.asObservable();
    }


    isLoadingCreatingEvolution() {
        return this.store.isLoadingCreatingEvolution$.asObservable();
    }
}
