import { Component, OnInit, Input } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { Agreement } from 'src/app/shared/models/agreement';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionAgreementFilters } from 'src/app/shared/models/ProvisionAgreementFilters';

@Component({
    selector: 'app-supply-detail',
    templateUrl: './supply-detail.component.html',
    styleUrls: ['./supply-detail.component.scss']
})
export class SupplyDetailComponent implements OnInit {
    @Input() case: Case;
    @Input() isCollapsed: boolean = false;
    @Input() isBilling: boolean = false;

    provisionAgreements: Agreement[];
    provisionAgreementsFilters: ProvisionAgreementFilters;
    provisions: Provision[];

    constructor() { }

    ngOnInit(): void {
    }
}
