import { Injectable } from '@angular/core';
// import * as moment from 'moment';
// import * as momentRecur from 'moment-recur';
// import 'moment/min/locales';
// var moment = require('moment');
// require('moment-recur');
// // require('moment-timezone');
// // moment().tz("America/Argentina/Buenos_Aires").format();
// moment.locale('es')

import * as moment from 'moment';
import * as momentRecur from 'moment-recur'
import { AlertService } from './alert.service';
moment.locale('es')
// momentRecur.locale('es')

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private alertService: AlertService,
  ) { }

  /**
   *
   * @param startDate: Date que indica cuándo arranca la repetición
   * @param endDate: Date que indica cuándo finaliza la repetición
   * @param days: Array de numbers donde el valor indica el día de la semana. Domingo como 0 y Sábado como 6
   */
  public recurDaysOfWeekFromAndTo(fromDate, endDate, toDate, days: number[]): { fromDate: moment.Moment, toDate: moment.Moment }[] {
    let recurrence;
    recurrence = momentRecur().recur(moment(fromDate), moment(endDate)).every(days).dayOfWeek();
    const fromDates: moment.Moment[] = recurrence.all().map(adate => {
      const a = moment(adate)
      a.set({ hour: moment(fromDate).hour(), minute: moment(fromDate).minute() })
      return a;
    });
    // Guardar intervalo to - from
    // foreach de from -> push en to con from+intervalo
    const difference = moment(toDate).diff(moment(fromDate));
    const dates: { fromDate: moment.Moment, toDate: moment.Moment }[] = fromDates.map(fromDate => {
      const toDate = moment(fromDate).add(difference);
      return { fromDate, toDate };
    })
    return dates;
  }

  public recurDaysOfWeekFrom(fromDate, endDate, days: number[]): { fromDate: moment.Moment }[] {
    let recurrence;
    recurrence = momentRecur().recur(moment(fromDate), moment(endDate)).every(days).dayOfWeek();
    const fromDates: moment.Moment[] = recurrence.all().map(adate => {
      const a = moment(adate)
      a.set({ hour: moment(fromDate).hour(), minute: moment(fromDate).minute() })
      return a;
    });
    return fromDates.map(fromDate => { return { fromDate } });
  }

  public recurDaysOfWeek(fromDate, endDate, days: number[]): moment.Moment[] {
    let recurrence;
    recurrence = momentRecur().recur(moment(fromDate), moment(endDate)).every(days).dayOfWeek();
    const fromDates = recurrence.all().map((adate: moment.Moment) => {
      return moment(adate).utc(false).format();
    });
    return fromDates.map( f => moment(f.substring(0, 10))); // Workaround to create right moment istead of add +3 hours
  }

  // Format: Mmm
  public getMonthFirsLetterUpper(date: Date): string {
    let month = moment(date).format('MMM');
    return month = month.charAt(0).toUpperCase() + month.substring(1, month.length-1);
  }

  // Format: Ddd -> [Lun, Mar, Mié, Jue, Vie, Sáb, Dom]
  public getDayFirsLetterUpper(date: Date): string {
    let day = moment(date).format('ddd');
    return day = day.charAt(0).toUpperCase() + day.substring(1, day.length-1);
  }

  // Format: DD Mmm
  public getDayMonth(date: Date) {
    return `${moment(date).format('DD')} ${this.getMonthFirsLetterUpper(date)}`
  }

  // Format: DD Mmm-DD Mmm YYYY
  public getLabelRange(startDate: Date, endDate: Date): string {
    return moment(startDate).isSame(endDate, 'year')
      ? `${this.getDayMonth(startDate)} - ${this.getDayMonth(endDate)} ${moment(endDate).format('YYYY')}` // Same year
      : `${this.getDayMonth(startDate)} ${moment(startDate).format('YYYY')} - ${this.getDayMonth(endDate)} ${moment(endDate).format('YYYY')}` // NOT same year
  }

  // Format: Mmmm YYYY
  public getMonthYear(date: Date): string {
    let month = moment(date).format('MMMM');
    return `${month.charAt(0).toUpperCase()}${month.substring(1)} ${moment(date).format('YYYY')}`
  }

  // Are dates calendar month? - Las fechas son mes calendario?
  isCalendarMonth(start: Date, end: Date): boolean {
    const startOfMonth = moment(start).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(start).endOf('month').format('YYYY-MM-DD');

    return startOfMonth == moment(start).format('YYYY-MM-DD') && endOfMonth == moment(end).format('YYYY-MM-DD');
 }

  public getSameFormatDateToDateRange(start: Date, end: Date): string {
    if ( this.isCalendarMonth(start, end) )
      return this.getMonthYear(start)
    else
      return this.getLabelRange(start, end)
  }
}
