// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../services/http-cancel.service';
import { AuthenticationService } from 'src/app/core/authentication.service';

/**
 * Include here endpoints what won't never be canceled
 */
enum FINAL_SEGMENT_ENDPOINT_EXCLUDED_TO_INTERCEPTOR_CANCEL {
  LOGIN = 'login',        // https://www.../api/v1/login
  PRINCIPAL = 'user'      // https://www.../api/v1/user
}

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

  constructor(router: Router,
              private httpCancelService: HttpCancelService,
              private authenticationService: AuthenticationService) {

    router.events.subscribe(event => {

      // An event triggered at the end of the activation part of the Resolve phase of routing
      if (event instanceof ActivationEnd) {
          this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {

    const urlTempEndpoint = req.url ? req.url : '';
    // https://www.../api/v1/tenants/:tenantName - excluded with this.authenticationService.getValueTenant()
    const isUrlEndpointExcludedToCancel = Object.values<string>(FINAL_SEGMENT_ENDPOINT_EXCLUDED_TO_INTERCEPTOR_CANCEL).includes(urlTempEndpoint.split('/').pop()) || !this.authenticationService.getValueTenant(); // Workaround exclude only this paths (endpoint) or tenant === null/undefined
    return isUrlEndpointExcludedToCancel ? next.handle(req) : next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}