<div class="flex-column gap-5">
    <div class="flex-row space-between-center">
        <div>
            <mat-button-toggle-group *ngIf="!this.loading">
                <mat-button-toggle id="arrowLeftButton" mwlCalendarPreviousView [view]="view" (click)="onCalendarPreviusNextView()"
                    [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                    <mat-icon class="text-secondary-darker">keyboard_arrow_left</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle id="arrowRightButton" mwlCalendarNextView [view]="view" (click)="onCalendarPreviusNextView()"
                    [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                    <mat-icon class="text-secondary-darker">keyboard_arrow_right</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle mwlCalendarToday [(viewDate)]="viewDate" class="text-secondary-darker">Hoy
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group *ngIf="this.loading" disabled>
                <mat-button-toggle>
                    <mat-icon class="text-secondary">keyboard_arrow_left</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle>
                    <mat-icon class="text-secondary">keyboard_arrow_right</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle [ngClass]="{'autoTodayButton' : true}">Hoy
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div>
            <span
                id="autoTitleMonth"
                class="mat-title text-secondary-darker">{{ (viewDate | calendarDate:(view + 'ViewTitle'):'es-AR')  | titlecase }}</span>
        </div>
        <div>
            <mat-button-toggle-group value="{{view}}">
                <mat-button-toggle (click)="setView(CalendarView.Month)" value="{{CalendarView.Month}}"
                    class="text-secondary-darker">
                    Mes
                </mat-button-toggle>
                <mat-button-toggle (click)="setView(CalendarView.Week)" value="{{CalendarView.Week}}"
                    class="text-secondary-darker">
                    Semana
                </mat-button-toggle>
                <mat-button-toggle (click)="setView(CalendarView.Day)" value="{{CalendarView.Day}}"
                    class="text-secondary-darker">Día
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div [ngSwitch]="view" [hidden]="this.loading">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="this.monthEvents"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
            (eventClicked)="eventClicked($event.event)"
            (dayClicked)="dayClicked($event.day)"
            [cellTemplate]="customCellTemplate"
            (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="eventClicked($event.event)"
            (hourSegmentClicked)="hourSegmentClicked($event.date)" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="eventClicked($event.event)"
            (hourSegmentClicked)="hourSegmentClicked($event.date)" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
    </div>
    <div *ngIf="this.loading" class="flex-100 pys-2 center-start">
        <mat-spinner class="mys-4" color="primary" diameter="40">
        </mat-spinner>
    </div>
</div>
<div class="space-between-start mxs-1 mts-2">
    <div>
        <div class="flex-row start-center mys-1">
            <mat-icon class="text-secondary-darker" style="width: 15px; height:15px; font-size: 15px;">trip_origin</mat-icon>
            <span class="text-secondary-darker mls-2">Atenciones anteriores a la fecha sin finalizar (agendadas/en curso)</span>
        </div>
        <div class="flex-row start-center mys-1">
            <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'agendada'" class="text-secondary-darker">
            </mat-icon>
            <span class="text-secondary-darker mls-2">Atenciones Agendadas</span>
        </div>
        <div class="flex-row start-center mys-1">
            <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'en_curso'" class="text-secondary-darker">
            </mat-icon>
            <span class="text-secondary-darker mls-2">Atenciones En curso</span>
        </div>
        <div class="flex-row start-center mys-1">
            <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'realizada'" class="text-secondary-darker">
            </mat-icon>
            <span class="text-secondary-darker mls-2">Atenciones Realizadas</span>
        </div>
        <div class="flex-row start-center mys-1">
            <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'no_realizada'" class="text-secondary-darker">
            </mat-icon>
            <span class="text-secondary-darker mls-2">Atenciones No realizadas</span>
        </div>
    </div>
    <div>
        <div *ngFor="let colorReference of colorReferences" class="flex-row start-center mbs-1">
            <mat-icon [style.color]="colorReference.color?.secondary" style="width: 15px; height:15px; font-size: 15px;">
                lens
            </mat-icon>
            <span class="text-secondary-darker mls-2">{{!!colorReference.title?  colorReference.title : 'Sin datos de referencia'}}</span>
        </div>
    </div>
</div>
<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
        <span class="cal-day-badge bg-primary" (click)="badgeClicked(day)" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 0">
        <div [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event" *ngFor="let event of day.events" (click)="eventClicked(event)">
            <span *ngIf="event.color?.primary != 'white'; else attentionNotPerformed" [ngSwitch]="event.meta.attention.state.id">
                <span *ngSwitchCase="this.attentionStates.AGENDADA">
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [style.color]="event.color?.secondary" [svgIcon]="'agendada'">
                    </mat-icon>
                </span>
                <span *ngSwitchCase="this.attentionStates.EN_CURSO">
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'en_curso'" [style.color]="event.color?.secondary">
                    </mat-icon>
                </span>
                <span *ngSwitchCase="this.attentionStates.REALIZADA">
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'realizada'" [style.color]="event.color?.secondary">
                    </mat-icon>
                </span>
                <span *ngSwitchCase="this.attentionStates.NO_REALIZADA">
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'no_realizada'" [style.color]="event.color?.secondary">
                    </mat-icon>
                </span>
                <span *ngSwitchCase="this.attentionStates.DEBITADA">
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'no_realizada'" [style.color]="event.color?.secondary">
                    </mat-icon>
                </span>
                <span *ngSwitchDefault>
                    <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                    <mat-icon [style.width.px]="14" [style.height.px]="14" [svgIcon]="'realizada'" [style.color]="event.color?.secondary">
                    </mat-icon>
                </span>
            </span>
            <ng-template #attentionNotPerformed>
                <span *ngIf="event.meta.isNotOneDayEvent" class="icon-plus" [style.color]="event.color?.secondary">+</span>
                <div [style.backgroundColor]="event.color?.primary" [style.borderColor]="event.color?.secondary" style="border-style: solid;border-width: 2px;" (click)="eventClicked(event)" [ngClass]="event?.cssClass" [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event" [class.cal-draggable]="event.draggable" mwlDraggable dragActiveClass="cal-drag-active" [dropData]="{event: event, draggedFrom: day}" [dragAxis]="{x: event.draggable, y: event.draggable}" class="cal-event">
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>

<ng-template #customOpenDaysTemplate>
    <div class="cal-open-day-events" *ngIf="true">
        <div *ngFor="let event of events" [ngClass]="event?.cssClass" mwlDraggable [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active" [dropData]="{event: event}" [dragAxis]="{x: event.draggable, y: event.draggable}">
            <span class="cal-event" [style.backgroundColor]="event.color?.primary">
            </span>
            &ngsp;
            <mwl-calendar-event-title [event]="event" view="month" (mwlClick)="eventClicked(event)">
            </mwl-calendar-event-title>
            &ngsp;
            <mwl-calendar-event-actions [event]="event">
            </mwl-calendar-event-actions>
        </div>
    </div>
</ng-template>
