import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import * as moment from 'moment';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { Subscription, forkJoin, of } from 'rxjs';
import { Order } from 'src/app/shared/models/order';
import { Practice } from 'src/app/shared/models/practice';
import { Attention } from '../../../../shared/models/attention';
import { CasesIndicatorsValues } from 'src/app/shared/models/provisionalControlIndicators';
import { Provision } from '../../../../shared/models/provision';
import { GeneralService } from 'src/app/core/services/general.service';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';
import { AttentionsState } from 'src/app/core/states/attentions.state';
import { ProvisionsService } from 'src/app/core/services/provisions.service';
import { ProvisionsState } from 'src/app/core/states/provisions.state';
@Component({
    selector: 'app-case-indicators',
    templateUrl: './case-indicators.component.html',
    styleUrls: ['./case-indicators.component.scss']
})
export class CaseIndicatorsComponent implements OnInit {

    @Input() attentions: Attention[];

    validityProvisionIndicator: boolean;
    nextDueDate = moment(new Date());
    actualDate = moment(new Date());
    previousDay = this.actualDate.clone().subtract(1, 'days').endOf('day');
    caseDays: number;
    case: Case;
    _orders: Subscription;
    orders: Order[];
    practicesSupply = [];
    practices: Practice[];
    accumulatedFeesWithoutAttention: any[] = [];


    // COUNTERS
    counterProvisionsWithoutAttenttions: number = 0; colorProvisionsWithoutAttentions: string = 'green'; isCounterProvisionsWithoutAttenttions: boolean;
    counterAttentionsWithoutBilling: number = 0; colorAttentionsWithoutBilling: string = 'green'; isCounterAttentionsWithoutBilling: boolean;
    counterAttentionsOldAndOmitted: number = 0; colorAttentionsOldAndOmitted: string = 'green'; isCounterAttentionsOldAndOmitted: boolean;
    counterSuppliesWithoutOrder: number = 0; colorSuppliesWithoutOrder: string = 'green'; isCounterSuppliesWithoutOrder: boolean;
    counterBillingAttentions: number = 0; colorBillingAttentions: string = 'green'; isCounterBillingAttentions: boolean;
    counterSuppliesNotDelivered: number = 0; colorSuppliesNotDelivered: string = 'green'; isCounterSuppliesNotDelivered: boolean;
    counterSuppliesWithoutBilling: number = 0; colorSuppliesWithoutBilling: string = 'green'; isCounterSuppliesWithoutBilling: boolean;
    counterSuppliesNotInformed: number = 0; colorSuppliesNotInformed: string = 'green'; isCounterSuppliesNotInformed: boolean;


    _case: Subscription;
    _allAttentions: Subscription;
    _filteredAttentions: Subscription;
    _provisions: Subscription;
    provisions: Provision[];

    historyModeActivated: boolean = false;
    _historyModeActivated: Subscription;
    _historyModeDate: Subscription;
    historyModeDate: Date;

    _caseIndicatorValues: Subscription;
    caseIndicatorValues: CasesIndicatorsValues;

    attentionsINRange: Attention[];
    historyFromDate: Date;
    historyToDate: Date;

    btnsDisabled: boolean;
    private _btnsDiasabled: Subscription;
    public ViewManagementEntities = ViewManagementEntities;

    constructor(
        private casesFacade: CasesFacade,
        private generalService: GeneralService,
        private provisionsFacade: ProvisionsFacade,
        private breadcrumbService: BreadcrumbService,
        private attentionsState: AttentionsState,
        private provisionsService: ProvisionsService
    ) { }

    ngOnInit(): void {
        // this.provisionsService.markProvisions();

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.historyFromDate = rangeDate.historyFromDate;
                this.historyToDate = rangeDate.historyToDate;
            }
        })

        // Get case
        this._case = this.casesFacade.getCase$().subscribe(res => {
            this.case = !!res ? res : null;
        });

        // ForkJoin made to await for both endpoints to respond
        const observable = forkJoin({ provisions: of(this.provisionsFacade.getProvisionsDisplayedOnScreen$()), orders: of(this.casesFacade.getOrdersDisplayedOnScreen$()) });
        observable.subscribe({
            next: values => {
                values.provisions.subscribe(provisions => {
                    if (!!provisions) {
                        this.provisions = provisions;
                        this.calculateProvisionsWithoutAttentions();
                        this.calculateSuppliesWithoutOrder();
                    }
                })
                values.orders.subscribe(orders => {
                    if (!!orders) {
                        this.orders = orders;
                        if (!!this.provisions && this.provisions.length > 0) {
                            this.composeProvisionFeeWithOrders();
                            this.calculateSuppliesWithoutOrder();
                        }
                    }
                })
            }
        })
        this._btnsDiasabled = this.generalService.getDisabledBtnsPeriodSelector$().subscribe(btnsDiasabled => this.btnsDisabled = btnsDiasabled);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['attentions']) {
            this.attentionsINRange = changes['attentions'].currentValue?.filter(att => moment(att.fromDate).isBetween(this.historyFromDate, this.historyToDate, undefined, '[]'));
            this.calculateProvisionsWithoutAttentions();
            this.calculateAttentionsOldAndOmitted();
            this.calculateAttentionsWithoutBilling();
            this.calculateBillingAttentions();
            this.calculateSuppliesNotDelivered();
            this.calculateSuppliesWithoutBilling();
            this.calculateSuppliesNotInformed();
            // this.calculateSuppliesWithoutOrder();
        }
    }

//~~~~~ !!! IMPORTANT !!!~~~~~~~~//
///////// IF WE WANT TO MODIFY THESE FILTERS, WE ALSO NEED TO DO SO IN ATTENTIONS-LIST-CASE-COMPONENT TO GET THE SAME RESULTS./////////////////
//PRACTICES WITHOUT SCHEDULE PAINTED IN  PROVISIONS-DETAIL-COMPONENT

    // Calculate how many provisions without attentions

    calculateProvisionsWithoutAttentions() {
        if (!this.provisions || !this.attentionsINRange) {
            this.resetCounterProvisionsWithoutAttentions();
            return;
        }
        // Filtrar las provisiones excluyendo las que sean 'Entrega insumos' o `isSupply`
        const filteredProvisions = this.provisions.filter(pr => pr.practice?.name !== 'Entrega insumos' && !pr.isSupply);
        const attentionsById = new Map(this.attentionsINRange.map(att => [att.provisionFeeId, att]));
        // Acumulamos cantidad de fees sin atención y los fees
        const { accumulatedCount, accumulatedFeesWithoutAttention } = filteredProvisions.reduce(
            (acc, provision) => {
                const feesWithoutAttention = provision.provisionFees.filter(fee => !attentionsById.has(fee.id));
                acc.accumulatedCount += feesWithoutAttention.length;
                acc.accumulatedFeesWithoutAttention.push(...feesWithoutAttention);
                return acc;
            },
            { accumulatedCount: 0, accumulatedFeesWithoutAttention: [] }
        );
        // Setear valores de contadores y colores
        this.isCounterProvisionsWithoutAttenttions = true;
        this.counterProvisionsWithoutAttenttions = Math.max(accumulatedCount, 0);
        this.colorProvisionsWithoutAttentions = this.generalService.calculateColor(this.counterProvisionsWithoutAttenttions, filteredProvisions.length);
    }

    // Método para resetear el estado de las provisiones sin atenciones
    resetCounterProvisionsWithoutAttentions() {
        this.isCounterProvisionsWithoutAttenttions = false;
        this.counterProvisionsWithoutAttenttions = 0;
        this.colorProvisionsWithoutAttentions = null;
        this.accumulatedFeesWithoutAttention = [];
    }



    // Calculate attentions without end
    calculateAttentionsOldAndOmitted() {
        this.isCounterAttentionsOldAndOmitted = false;
        this.counterAttentionsOldAndOmitted = 0;
        this.counterAttentionsOldAndOmitted = this.attentionsINRange ?
            this.attentionsINRange.filter(attention => {
                const isFiltered = !attention.isSupply && moment(attention.fromDate).isSameOrBefore(this.previousDay) &&
                    [AttentionStates.AGENDADA, AttentionStates.EN_CURSO, AttentionStates.NO_REALIZADA].includes(attention.state.id);
                return isFiltered;
            }).length : 0;

        this.isCounterAttentionsOldAndOmitted = true;
        this.colorAttentionsOldAndOmitted = this.generalService.calculateColor(this.counterAttentionsOldAndOmitted, this.attentionsINRange?.length);

    }

    // Calculate attentions without billing
    calculateAttentionsWithoutBilling() {
        this.isCounterAttentionsWithoutBilling = false;
        this.counterAttentionsWithoutBilling = 0;
        const filteredAtt = [];
        if (!!this.attentionsINRange) {
            this.counterAttentionsWithoutBilling = this.attentionsINRange ?
                this.attentionsINRange.filter(attention => {
                    const isFiltered = !attention.notManageable && attention?.practice?.name != "Entrega insumos" &&
                    [AttentionStates.REALIZADA, AttentionStates.NO_REALIZADA, AttentionStates.CREACION].includes(attention.state.id);
                    if(isFiltered) {
                        filteredAtt.push(attention)
                    }
                    return isFiltered;
                }).length: 0;
        this.isCounterAttentionsWithoutBilling = true;
        this.colorAttentionsWithoutBilling = this.generalService.calculateColor(this.counterAttentionsWithoutBilling, this.attentionsINRange.length);
        // const type = 'ATTENTIONS_WITHOUT_BILLING';
        // filteredAtt.forEach(att => {
        //     att.paintedType = type
        // })
        this.attentionsState.updateAttentionsCaseActive(filteredAtt);

    }
}

// Calculate billing attentions
calculateBillingAttentions() {
    this.isCounterBillingAttentions = false;
    this.counterBillingAttentions = 0;
    if (!!this.attentionsINRange) {
        this.counterBillingAttentions = this.attentionsINRange ?
            this.attentionsINRange.filter(attention =>
                [AttentionStates.FACTURABLE].includes(attention.state.id)
                && !attention.notManageable && attention?.practice?.name != "Entrega insumos").length : 0
        this.isCounterBillingAttentions = true;
        this.colorBillingAttentions = this.generalService.calculateColor(this.counterBillingAttentions, this.attentionsINRange.length);
    }
}

//Calculate Supplies Without order
calculateSuppliesWithoutOrder() {
    this.isCounterSuppliesWithoutOrder = false;
    this.counterSuppliesWithoutOrder = 0;
    let supplies = this.provisions?.filter(prov => prov.isSupply && prov.practice?.name != 'Entrega insumos');
    let suppliesProvisionFees = supplies?.flatMap(supp => { return supp.provisionFees });
    if (suppliesProvisionFees?.length > 0) {
        suppliesProvisionFees.forEach(pF => {
            if (pF?.orders?.length === 0) {
                this.counterSuppliesWithoutOrder++;
            } else if (pF?.orders) {
                const orderSupply = pF.orders.find(order => (order.state.id !== 3))
                !orderSupply ? this.counterSuppliesWithoutOrder++ : null;
            }
        });
    }
    this.colorSuppliesWithoutOrder = this.counterSuppliesWithoutOrder == 0 ? '#21a35c' : 'red'
    this.isCounterSuppliesWithoutOrder = true;
}

//Calculate Supplies not delivered
calculateSuppliesNotDelivered() {
    this.isCounterSuppliesNotDelivered = false;
    this.counterSuppliesNotDelivered = 0;
    if (!!this.attentionsINRange) {
        this.counterSuppliesNotDelivered = this.attentionsINRange ?
            this.attentionsINRange.filter(attention =>
                [AttentionStates.AGENDADA].includes(attention.state.id)
                && attention?.isSupply && attention?.practice?.name == "Entrega insumos").length : 0
        this.isCounterSuppliesNotDelivered = true;
        this.colorSuppliesNotDelivered = this.generalService.calculateColor(this.counterSuppliesNotDelivered, this.attentionsINRange.length);
    }
}

//Calculate Supplies without billing
calculateSuppliesWithoutBilling() {
    this.isCounterSuppliesWithoutBilling = false;
    this.counterSuppliesWithoutBilling = 0;
    if (!!this.attentionsINRange) {
        this.counterSuppliesWithoutBilling = this.attentionsINRange ?
            this.attentionsINRange.filter(attention =>
                [AttentionStates.REALIZADA].includes(attention.state.id)
                && attention?.isSupply && attention?.practice?.name != "Entrega insumos").length : 0
        this.isCounterSuppliesWithoutBilling = true;
        this.colorSuppliesWithoutBilling = this.generalService.calculateColor(this.counterSuppliesWithoutBilling, this.attentionsINRange.length);
    }
}

//Calculate Supplies not informed
calculateSuppliesNotInformed() {
    this.isCounterSuppliesNotInformed = false;
    this.counterSuppliesNotInformed = 0;
    if (!!this.attentionsINRange) {
        this.counterSuppliesNotInformed = this.attentionsINRange ?
            this.attentionsINRange.filter(attention =>
                attention?.isSupply && attention?.practice?.name != "Entrega insumos" &&
                [AttentionStates.NO_INFORMADA, AttentionStates.FACTURABLE].includes(attention.state.id)
            ).length : 0
        this.isCounterSuppliesNotInformed = true;
        this.colorSuppliesNotInformed = this.generalService.calculateColor(this.counterSuppliesNotInformed, this.attentionsINRange.length);
    }
}


composeProvisionFeeWithOrders() {
    this.provisions = this.provisions?.map(prov => {
        prov.provisionFees = prov.provisionFees.map(pF => {
            let aux = [];
            if (this.orders != null && this.orders?.length > 0) {
                this.orders.forEach(or => {
                    let itemForProvFee = or?.items?.find(i => pF.id == i.provisionFeeId);
                    if (itemForProvFee != null) {
                        aux.push(or)
                    }
                })
            }
            pF.orders = aux;
            return pF
        })
        return prov
    })
}

goToScreen(event: ViewManagementEntities) {
    this.breadcrumbService.setScreenToJump(event)
}

ngOnDestroy(): void {
        !!this._case ? this._case.unsubscribe() : null;
!!this._historyModeDate ? this._historyModeDate.unsubscribe() : null;
!!this._btnsDiasabled ? this._btnsDiasabled.unsubscribe() : null;
    }
}
