import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent extends FormElementComponent implements OnInit {

  @Output() readonly TimepickerInfo = new EventEmitter<string>();

  constructor() {
    super();
  }

  onTimeSet(event:string){
    this.TimepickerInfo.emit(event);
  }

}
