<div class="flex-column">
    <mat-card [ngClass]="'card-shadow'">
        <mat-card-header class="mbs-4">
            <mat-card-title>Agenda</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mx-2 mb-3">
            <div class="flex-row start-start gap-6">
                <div class="flex-column gap-10 flex-25">
                    <div>
                        <!-- onClickCreateAttention() -->
                        <button (click)="''" mat-flat-button color="primary">
                            CREAR
                        </button>
                    </div>
                    <app-filter (onLoadScheduleWithDataFilter)="this.onLoadScheduleExtended($event)"
                        (onLoadSchedule)="this.onLoadSchedule($event,this.globalRequestsParams.fromDate,globalRequestsParams.toDate)"
                        [getAgreementsByProvider]="this.getAgreementsByProvider">
                    </app-filter>
                </div>

                <mat-tab-group class="flex-75 mat-stretch-tabs">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="text-secondary-darker mrs-3">calendar_today</mat-icon>
                            Calendario
                        </ng-template>
                        <!-- onClickDeleteAttention($event) -->
                        <!-- this.onEventClickedHandler($event) -->
                        <!-- this.onClickCreateAttention($event) -->
                        <app-calendar
                        (onChangeMonth)="this.onChangeMonth($event)"
                        class="flex-100 mts-5"
                            *ngIf="!loadingSchedule; else loadingRequest"
                            (onDayClicked)="''"
                            (onEventClicked)="''"
                            (onEventDelete)="''" [attentions]="this.attentions"
                            [loading]="this.loadingCalendar">
                        </app-calendar>


                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="text-secondary-darker mrs-3">list</mat-icon>
                            Listado
                        </ng-template>
                        <!-- this.onClickDeleteAttentions($event) -->
                        <app-attentions-list (onChangeMonth)="this.onChangeMonth($event)" class="flex-100"
                            *ngIf="!loadingSchedule; else loadingRequest"
                            [textNoAttentions]="'No existen atenciones para el mes indicado'"
                            (onClickButton1)="''" [loading]="this.loadingCalendar"
                            [colorButton1]="'primary'" [ruleClickable]="this.attentionsService.isDeletable"
                            [textButton1]="'ELIMINAR'" [attentions]="this.attentions">
                        </app-attentions-list>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #dateFormDiv>
    <div class="flex-row flex-wrap gap-10">
        <!-- Fecha INICIO -->
        <!-- <app-date-picker *ngIf="!!this.agreementSelected" class="flex-auto"
            [name]="this.DATE_FROM"
            [disabled]="this.activeAttention.state.id != ATTENTION_STATES.AGENDADA" [form]="this.scheduleForm"
            [placeholder]="'Inicio de la atención'">
        </app-date-picker> -->
        <!-- Fecha y hora FIN -->
        <!-- <app-date-picker
            *ngIf="!!this.agreementSelected && this.agreementSelected.provision.practice.restriction.requiredToDate"
            [disabled]="this.activeAttention.state.id != ATTENTION_STATES.AGENDADA" class="flex-auto"
             [name]="this.DATE_TO" [form]="this.scheduleForm"
            [placeholder]="'Finalización de la atención'">
        </app-date-picker> -->
        <!-- Fecha FIN No existe este caso -->
    </div>
</ng-template>

<ng-template #loadingRequest>
    <div class="flex-100 mts-5 align-center-center">
        <app-spinner *ngIf="!this.error; else errorDiv" class="flex-100 center-start"></app-spinner>
    </div>
</ng-template>

<ng-template #loadingConfirm>
    <mat-icon>
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
    </mat-icon>
</ng-template>

<ng-template #errorDiv>
    <div class="flex-100 align-center-center">
        <app-spinner-error></app-spinner-error>
        <span>
            Ha ocurrido un error inesperado, inténtelo nuevamente
        </span>
    </div>
</ng-template>
