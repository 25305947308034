import { Component, OnInit, EventEmitter, Output, ViewChild, ChangeDetectorRef, Input, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general.service';
import { TypeDocument } from 'src/app/shared/models/typeDocument';
import { Subscription } from 'rxjs';
import { Patient } from 'src/app/shared/models/patient';
import { Address } from 'src/app/shared/models/address';
import { AddressData, Location } from 'src/app/shared/models/location';
import { Province } from 'src/app/shared/models/province';
import { CasesService, PatchPatientBody } from '../../../../core/services/cases.service';
import { Case } from 'src/app/shared/models/case';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ROUTES } from 'src/app/core/enums/routes';
import { PostPatientBody } from '../../../../core/services/cases.service';
import Swal from 'sweetalert2';
import { PatientsQPS, PatientsService, ProvinceQPS } from '../../../../core/services/patients.service';
import { PatientContact } from '../../../../shared/models/patient';
import { RequisitionsFacade } from '../../../../abstraction/requisitions.facade';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import * as moment from 'moment';
import { PostRequisitionBody } from 'src/app/core/services/requisition.service';
import { RequisitionTypes } from '../../../../core/enums/requisitionTypes';
import { RequisitionStates } from 'src/app/core/enums/requisitionsStates';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { log } from 'console';

@Component({
    selector: 'app-patient-data',
    templateUrl: './patient-data.component.html',
    styleUrls: ['./patient-data.component.scss'],
})
export class PatientDataComponent implements OnInit {

    // Only Popup mode
    @Input() patientIN: Patient | PatchPatientBody | string;
    @Input() isloadingUpdate: boolean = false;
    @Input() parentComponent: string
    @Input() case: Case;
    @Input() onClickConfirm: boolean;

    @Output() onClickCancelEditPatient = new EventEmitter();
    @Output() onClickConfirmEditPatient = new EventEmitter();
    @Output() isValidatingDocumentNumber = new EventEmitter<boolean>();
    @Output() emitCase = new EventEmitter<Case>();
    @Output() confirmChangesEmitter = new EventEmitter<void>();


    loadingPatient: boolean = false;
    isPatientInputParam: boolean = false;
    //

    @Output() patientData = new EventEmitter<PostPatientBody>();
    @Output() formPatientDataValid = new EventEmitter<boolean>();
    formPatienValid: boolean = false;

    btn_addPhone_disabled: boolean = false;
    btn_addEmail_disabled: boolean = false;
    patientAdmission: PostPatientBody;

    public readonly PATIENT_SEARCH = 'patientSearch';
    public readonly PATIENT_SEARCHING = 'patientSearching';
    public readonly PATIENT_NAME = 'name';
    public readonly PATIENT_SURNAME = 'surname';
    public readonly PATIENT_TYPEDOCUMENT = 'typeDocument';
    public readonly PATIENT_DOCUMENTNUMBER = 'documentNumber';
    public readonly PATIENT_BIRTHDATE = 'birthDate';
    public readonly PATIENT_AGE = 'age';
    public readonly PATIENT_ADDRESS = 'address';
    public readonly PATIENT_ADDRESS_STREET = 'street';
    public readonly PATIENT_ADDRESS_STREETNUMBER = 'streetNumber';
    public readonly PATIENT_ADDRESS_FLOOR = 'floor';
    public readonly PATIENT_ADDRESS_FLAT = 'flat';
    public readonly PATIENT_ADDRESS_FLATEXTRA = 'flatExtra';
    public readonly PATIENT_ADDRESS_LOCATION = 'location';
    public readonly PATIENT_ADDRESS_LOCATION_SEARCHING = 'locationSearching';
    public readonly PATIENT_ADDRESS_STATE = 'state';
    public readonly PATIENT_ADDRESS_STATE_SEARCHING = 'stateSearching';
    public readonly PATIENT_ADDRESS_ZIPCODE = 'zipCode';
    public readonly PATIENT_GENDER = 'gender';
    public readonly PATIENT_HEALTHINSURANCE = 'healthInsuranceId';
    public readonly PATIENT_ADDRESS_LATITUDE = 'latitude';
    public readonly PATIENT_ADDRESS_LONGITUDE= 'longitude';

    // NEW CONTACT
    public readonly PATIENT_NEW_CONTACT_ID = 'id'
    public readonly PATIENT_NEW_CONTACT_PHONE = 'phoneNumber';
    public readonly PATIENT_NEW_CONTACT_EMAIL = 'email';
    public readonly PATIENT_NEW_CONTACT_REFERENCE = 'reference';
    public readonly PATIENT_NEW_CONTACT_MAINCONTACT = 'isMainContact';

    btn_addContact_disabled: boolean = true;

    formSelectTask: FormGroup;

    isLoadingGettingPatients$: Observable<boolean>;
    isLoadingGettingTypeDocuments$: Observable<boolean>;
    formPatient: FormGroup;

    typeDocuments: TypeDocument[];
    _typeDocuments: Subscription;
    visibleNewPatient: boolean = true;
    formAddress: FormGroup;
    formContacts: FormGroup;
    patientsList: Patient[];
    indexMainContact: number = 0;
    caseActive: Case;
    patientSelected: Patient;
    formPatientCaseActive: FormGroup;
    locations: Location[]; _locations: Subscription;
    closedCaseDate;
    closedCase: Case;

    public readonly PATIENT_NAME_CASE_ACTIVE = 'nameCaseActive';
    public readonly PATIENT_OPERATOR_CASE_ACTIVE = 'operatorCaseActive';
    public routes = ROUTES;

    @ViewChild('patientCaseActive', { static: true }) patientCaseActive: TemplateRef<any>;
    @ViewChild('selectTask', { static: true }) selectTask: TemplateRef<any>;

    _dialogRef;
    admissionPatientForm: FormGroup;
    provinceSelected: Province;
    locationSelected: Location;
    provincesOnSelect: Province[]; _provinces: Subscription;
    actualPatientLocation;
    actualPatientProvince;
    selectedTask: string;
    selectedProvince:string;
    selectedCity:string;
    private _selectedlocation: AddressData;

    get selectedLocation(){
        return this._selectedlocation
    }

   set selectedLocation(value:AddressData){
    this._selectedlocation = value;
    this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.setValue(value.street);
    this.formAddress.get(this.PATIENT_ADDRESS_STREETNUMBER)?.setValue(value.streetNumber);
    this.formAddress.get(this.PATIENT_ADDRESS_LATITUDE)?.setValue(value.latitude);
    this.formAddress.get(this.PATIENT_ADDRESS_LONGITUDE)?.setValue(value.longitude);
   }

    /*     casesFacade: CasesFacade; */

    get patientTypeDocument() {
        return this.formPatient.get(this.PATIENT_TYPEDOCUMENT).value;
    }
    get patientDocumentNumber() {
        return this.formPatient.get(this.PATIENT_DOCUMENTNUMBER);
    }
    get patientName() {
        return this.formPatient.get(this.PATIENT_NAME).value;
    }
    get patientGender() {
        return this.formPatient.get(this.PATIENT_GENDER).value;
    }
    get patientBirthdate() {
        return this.formPatient.get(this.PATIENT_BIRTHDATE).value;
    }
    get patientAge() {
        return this.formPatient.get(this.PATIENT_AGE).value;
    }
    set patientAge(value) {
        this.formPatient.controls[this.PATIENT_AGE].setValue(value);
    }
    get patientSurname() {
        return this.formPatient.get(this.PATIENT_SURNAME).value;
    }
    get patientStreet() {
        return this.formPatient.get(this.PATIENT_ADDRESS_STREET).value;
    }
    get patientStreetNumber() {
        return this.formPatient.get(this.PATIENT_ADDRESS_STREETNUMBER).value;
    }
    get patientFlat() {
        return this.formPatient.get(this.PATIENT_ADDRESS_FLAT).value;
    }
    get patientFlatExtra() {
        return this.formPatient.get(this.PATIENT_ADDRESS_FLATEXTRA).value;
    }
    get patientFloor() {
        return this.formPatient.get(this.PATIENT_ADDRESS_FLOOR).value;
    }
    get healthInsurance() {
        return this.formPatient.get(this.PATIENT_HEALTHINSURANCE).value;
    }
    get patientLocation() {
        return this.formPatient.get(this.PATIENT_ADDRESS_LOCATION).value;
    }
    get patientState() {
        return this.formAddress.get(this.PATIENT_ADDRESS_STATE).value;
    }
    get patientZipcode() {
        return this.formAddress.get(this.PATIENT_ADDRESS_ZIPCODE);
    }
    get contacts(): FormArray {
        return this.formContacts.get('contacts') as FormArray;
    }

    get lat(){
        return this.formAddress.get(this.PATIENT_ADDRESS_LATITUDE).value;
    }

    get lng(){
        return this.formAddress.get(this.PATIENT_ADDRESS_LONGITUDE).value;
    }

    set lat(value){
        this.formAddress.get(this.PATIENT_ADDRESS_LATITUDE).setValue(value)
    }

    set lng(value){
        this.formAddress.get(this.PATIENT_ADDRESS_LONGITUDE).setValue(value);
    }

    originalFormAddress: {};
    isValidFormPatient: boolean = false; isValidFormAddress: boolean = false; isValidFormContacts: boolean = false;
    isFormContactsTouched: boolean = false;
    RequisitionTypes: RequisitionTypes;

    constructor(
        private formBuilder: FormBuilder,
        private entitiesFacade: EntitiesFacade,
        public router: Router,
        private generalService: GeneralService,
        private casesService: CasesService,
        private casesFacade: CasesFacade,
        public dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private requisitionFacade: RequisitionsFacade,
        private patientsService: PatientsService
    ) {
        this.isLoadingGettingPatients$ = this.entitiesFacade.isLoadingGettingPatients$();
        this.isLoadingGettingTypeDocuments$ = this.entitiesFacade.isLoadingGettingTypeDocuments$();
    }

    ngOnInit(): void {

        this.patientAdmission = {};

        if (!!this.patientIN) {
            this.loadingPatient = true
            this.isPatientInputParam = true;
        }

        this.calculateNamePatient.bind(this);
        this._typeDocuments = this.entitiesFacade.getTypeDocuments$().subscribe(typeDocuments => {
            if (!!typeDocuments) {
                this.typeDocuments = typeDocuments;
            } else {
                this.entitiesFacade.loadTypeDocuments();
            }
        })
        this._provinces = this.entitiesFacade.getProvinces$().subscribe(provinces => {
            if (!!provinces) {
                this.provincesOnSelect = provinces;
            } else {
                this.entitiesFacade.loadProvinces();
            }
        })
        this.formPatient = this.createForm();
        this.formAddress = this.createAddressForm();
        this.originalFormAddress = this.formAddress.value; // After compare

        // PATIENT
        this.formPatient.valueChanges.subscribe(patient => {

            if (this.formPatient.status === 'VALID') {
                const { age, ...newPatient } = patient; // Delete age
                this.patientAdmission = { ...this.patientAdmission, ...newPatient, typeDocument: { id: this.formPatient.get('typeDocument').value }, documentNumber: this.formPatient.get('documentNumber').value };
                this.patientData.emit(this.patientAdmission);
                this.isValidFormPatient = true; // Form valid
            } else {
                this.isValidFormPatient = false; // Form invalid
            }
            this.emitValidsForms()
        })

        // ADDRESS
        this.formAddress.valueChanges.subscribe(_ => {

            if (this.formAddress.status === 'VALID') {
                this.patientAdmission.address = this.generalService.compareObjects(this.formAddress.value, this.originalFormAddress) ? {} : this.formAddress.value;
                const idLocation = this.locationSelected ? this.locationSelected?.id : this.patientSelected?.address?.location ? this.patientSelected?.address?.location.id : null;
                let newPatientAdmissionAddress: any = { ...this.patientAdmission.address, location: { id: idLocation } };
                const { state, zipCode, stateSearching, locationSearching, ...editnewPatientAdmissionAddress } = newPatientAdmissionAddress;
                this.patientAdmission.address = editnewPatientAdmissionAddress;
                this.patientData.emit(this.patientAdmission);
                this.isValidFormAddress = true; // Form valid
            } else {
                if (this.formAddress.status != 'DISABLED')
                    this.isValidFormAddress = false; // Form invalid
            }
            this.emitValidsForms()
        })

        // CONTACTS
        this.formContacts.valueChanges.subscribe(_ => {

            this.isFormContactsTouched = true;

            if (this.formContacts.status === 'VALID') {

                this.patientAdmission.contacts = this.contacts.value.filter(c => c.email || c.phoneNumber);
                this.patientData.emit(this.patientAdmission);
                this.isValidFormContacts = true; // Form valid
            } else {
                this.isValidFormContacts = false; // Form invalid
            }

            this.emitValidsForms()
            this.btn_addContact_disabled = this.isContactInvalid(); // At least phone or email
        })

        setTimeout(() => {
            if (this.isPatientInputParam)
                this.selected(this.patientIN);
        }, 1000);
    }

    emitValidsForms() {

        // if ( !this.isContactInvalid() ) {
        if (this.isValidFormPatient && this.isValidFormAddress) {

            if (!this.isFormContactsTouched || this.isValidFormContacts) {
                this.formPatienValid = true;
                this.formPatientDataValid.emit(true)
            } else {
                this.formPatienValid = false;
                this.formPatientDataValid.emit(false)
            }
        } else {
            this.formPatienValid = false;
            this.formPatientDataValid.emit(false)
        }
        // } else {
        //     this.formPatienValid = false;
        //     this.formPatientDataValid.emit(false)
        // }
    }

    isContactInvalid(): boolean {
        if (!!this.contacts.value && this.contacts.value.length > 0) {
            return this.contacts.value.some(p => !p.email && !p.phoneNumber)
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._typeDocuments.unsubscribe();

        if (!!this._locations) {
            this._locations.unsubscribe();
        }

        if (!!this._provinces) {
            this._provinces.unsubscribe();
        }

        this.changeDetector.detach();
    }

    loadPatientsByFullName = (value: string) => {
        const pfp: PatientsQPS = {
            active: true
        };
        if (this.generalService.stringIsNumber(value)) {
            pfp['document_number_like'] = value;
        } else {
            pfp['fullName_like'] = value;
        }
        return this.entitiesFacade.loadPatients(pfp);
    }

    createForm(patient?: Patient): FormGroup {
        let radioButton = 'S/E';
        this.admissionPatientForm = this.formBuilder.group({
            [this.PATIENT_SEARCH]: [''],
            [this.PATIENT_SEARCHING]: [''],
        })

        const form = this.formBuilder.group({
            [this.PATIENT_TYPEDOCUMENT]: ['',], // [CustomValidators.required('Tipo documento requerido')]
            [this.PATIENT_DOCUMENTNUMBER]: ['', [Validators.pattern('^[0-9]*$')]], // [CustomValidators.required('Nº de documento requerido')]
            [this.PATIENT_NAME]: [patient && patient.name ? patient.name : '',], // [CustomValidators.required('Nombre requerido')]
            [this.PATIENT_SURNAME]: [patient && patient.surname ? patient.surname : '',], // [CustomValidators.required('Apellido requerido')]
            [this.PATIENT_BIRTHDATE]: [patient && patient.birthDate ? patient.birthDate : ''],
            [this.PATIENT_AGE]: [patient && patient.birthDate ? this.calculateAge(patient?.birthDate) : ''],
            [this.PATIENT_GENDER]: new FormControl({ value: patient && patient.gender ? patient.gender : 'S/E', disabled: false }),
          });

        this.formContacts = this.formBuilder.group({
            contacts: this.formBuilder.array([]) // Definiendo el FormArray de contactos
        });

        form.disable();
        return form;
    }

    createAddressForm(address?: Address): FormGroup {
        const form = this.formBuilder.group({
            [this.PATIENT_ADDRESS_STREET]: [
                address && address.street ? address.street : '',], // [CustomValidators.required('Campo obligatorio')]
            [this.PATIENT_ADDRESS_STREETNUMBER]: [
                address && address.streetNumber ? address.streetNumber : '',], // [CustomValidators.required('Campo obligatorio')]
            [this.PATIENT_ADDRESS_FLOOR]: [
                address && address.floor ? address.floor : '',
            ],
            [this.PATIENT_ADDRESS_FLAT]: [
                address && address.flat ? address.flat : '',
            ],
            [this.PATIENT_ADDRESS_FLATEXTRA]: [address && address.flatExtra ? address.flatExtra : '', [CustomValidators.maxLength(25, 'El máximo de carácteres son 25')]],
            [this.PATIENT_ADDRESS_STATE]: ['', [CustomValidators.required('Campo obligatorio')]],
            [this.PATIENT_ADDRESS_STATE_SEARCHING]: [''],
            [this.PATIENT_ADDRESS_LOCATION]: ['', [CustomValidators.required('Campo obligatorio')]],
            [this.PATIENT_ADDRESS_LOCATION_SEARCHING]: [''],
            [this.PATIENT_ADDRESS_ZIPCODE]: [''],
            [this.PATIENT_ADDRESS_LATITUDE]: [''],
            [this.PATIENT_ADDRESS_LONGITUDE]: [''],
        });
        form.disable();
        return form;
    }
    resetAddressFields(exceptField?: string) {
        // Definir el objeto con todos los campos que deben resetearse a null
        const defaultValues = {
          [this.PATIENT_ADDRESS_LOCATION]: null,
          [this.PATIENT_ADDRESS_ZIPCODE]: null,
          [this.PATIENT_ADDRESS_FLOOR]: null,
          [this.PATIENT_ADDRESS_FLAT]: null,
          [this.PATIENT_ADDRESS_FLATEXTRA]: null
        };

        // Si hay un campo que no debe ser null, lo excluimos del reset
        if (exceptField && defaultValues.hasOwnProperty(exceptField)) {
          defaultValues[exceptField] = this.formAddress.get(exceptField)?.value;
        }

        // Si exceptField tiene valor, mantener también el valor de ZIPCODE
        if (exceptField) {
          defaultValues[this.PATIENT_ADDRESS_ZIPCODE] = this.formAddress.get(this.PATIENT_ADDRESS_ZIPCODE)?.value;
        }

        // Aplicar los valores
        this.formAddress.patchValue(defaultValues);
      }

      //GUARDAMOS CALLE Y NUMERO EN EL FORMADDRESS
      onSelectAddress(event:AddressData) {
        this.selectedLocation = event;
        const {latitude, longitude} = event
      }


    createNewContactForm(contact?: PatientContact): FormGroup {
        let mainContact;

        if (!!this.patientSelected && this.patientSelected.contacts.length != 0) {
            mainContact = !!contact ? contact.isMainContact ? true : false : false; // Select radioButton mainConctat = true
        } else if (this.contacts.length == 0) {
            mainContact = true; // Unique element then is main
        } else {
            mainContact = false;
        }

        return this.formBuilder.group({
            [this.PATIENT_NEW_CONTACT_ID]: [!!contact ? contact.id : ''],
            [this.PATIENT_NEW_CONTACT_PHONE]: [!!contact ? contact.phoneNumber : '',], // [CustomValidators.required('Teléfono requerido')]
            [this.PATIENT_NEW_CONTACT_EMAIL]: [!!contact ? contact.email : ''],
            [this.PATIENT_NEW_CONTACT_REFERENCE]: [!!contact ? contact.reference : ''],
            isMainContact: new FormControl({ value: mainContact, disabled: false }),
        });
    }

    addContact() {
        this.contacts.push(this.createNewContactForm());
        this.validMainContact();
    }

    removeContact(i: number) {

        if (this.contacts.length > 1 && this.contacts['controls'][i].value.isMainContact) {
            const swalWithCustomizeButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btnSwalConfirm',
                    cancelButton: 'btnSwalCancel'
                },
                buttonsStyling: true
            })
            swalWithCustomizeButtons.fire({
                title: 'Atención',
                text: 'No puede eliminar un contacto principal, marque otro como principal y reintente',
                icon: 'error'
            });
        } else {
            this.contacts.removeAt(i);
            this.validMainContact();
        }
    }

    validMainContact() {
        if (this.contacts.length == 1) { // Only one element, so it's main
            this.contacts.value[0].isMainContact = true;
            this.indexMainContact = 0;
        } else {
            this.indexMainContact = this.contacts.value.findIndex(e => e.isMainContact === true); // Update global index for mainContact
        }
    }

    changeMainContact() {
        this.contacts['controls'][this.indexMainContact]['controls'].isMainContact.value = false;
        this.contacts.value[this.indexMainContact].isMainContact = false;
        this.validMainContact();
    }

    selected(patient: Patient | string | PatchPatientBody) {
        // New Patient option selected
        if (this.isPatientInputParam) this.formPatient.reset();
        if (this.isPatientInputParam) this.contacts.clear();
        if (this.formPatient.disabled) {
            this.formPatient.enable();
            this.formAddress.enable();
        }

        this.formPatient.get(this.PATIENT_TYPEDOCUMENT).enable();
        this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).enable();


        if (typeof patient === 'string') {

            this.btn_addContact_disabled = false;
            this.addContact();

        } else {
            // Patient selected
            if (this.parentComponent === 'admission-creation') {
                this.formPatient.get(this.PATIENT_TYPEDOCUMENT).disable();
                this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).disable();
            }
            patient = patient as Patient;
            this.patientSelected = patient;

            if (!!this.patientSelected?.address && !!this.patientSelected?.address.location) {
                this.actualPatientProvince = this.patientSelected.address.location.province;
                this.actualPatientLocation = this.patientSelected.address.location;
                this.selectProvince(this.actualPatientProvince.id);
            }

            //this.formPatient.get(this.PATIENT_TYPEDOCUMENT).disable();
            //this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).disable();
            this.formPatient.patchValue({
                [this.PATIENT_TYPEDOCUMENT]: patient?.typeDocument?.id.toString(),
                [this.PATIENT_DOCUMENTNUMBER]: patient?.documentNumber != '0' ? patient?.documentNumber : '',
                [this.PATIENT_NAME]: patient?.name,
                [this.PATIENT_BIRTHDATE]: patient?.birthDate,
                [this.PATIENT_AGE]: this.calculateAge(patient?.birthDate),
                [this.PATIENT_SURNAME]: patient?.surname,
            });
            this.formPatient.controls['gender'].patchValue((!!patient && patient.gender != '') ? patient?.gender : 'S/E')
            this.formAddress.patchValue({
                [this.PATIENT_ADDRESS_FLAT]: patient?.address?.flat,
                [this.PATIENT_ADDRESS_STREET]: patient?.address?.street,
                [this.PATIENT_ADDRESS_STREETNUMBER]: patient?.address?.streetNumber,
                [this.PATIENT_ADDRESS_FLOOR]: patient?.address?.floor,
                [this.PATIENT_ADDRESS_FLATEXTRA]: patient?.address?.flatExtra,
                // TODO: Adapt location dynamic
                /* [this.PATIENT_ADDRESS_ZIPCODE]: patient?.address?.zipcode, */
            })

            // Load contacts
            if (!this.patientSelected.contacts) {
                this.patientSelected.contacts = [];
            }

            if (this.patientSelected.contacts.length != 0) {
                this.contacts.removeAt(0);
                this.loadManyContacts();
            } else {
                this.addContact();
                this.btn_addContact_disabled = true;
            }

            this.changeDetector.detectChanges();

            if (!this.isPatientInputParam) {

                this.casesService.getCases({ patientId: patient.id, active: true, page: 0, size: 10 }).subscribe((casesPages) => {
                    let cases = casesPages.data;
                    if (cases.length != 0) {
                        this.caseActive = cases[0];
                        this.formPatientCaseActive = this.createFormCaseActive(this.caseActive);

                        this.openDialog('', this.patientCaseActive, { maxHeight: '95vh', minWidth: '35%', maxWidth: '35%' }, () => {
                            // Hacer algo cuando se cierra o nada
                        }, "p-0");
                    }
                })
            }
        }
        this.loadingPatient = false;
    }

    loadManyContacts() {

        this.btn_addContact_disabled = false;

        this.patientSelected.contacts.forEach((contact, index) => {
            if (this.patientSelected.mainContactId == contact.id) {
                this.indexMainContact = !!index ? index : 0; // Save global index contact main
                contact = { ...contact, isMainContact: true }
            } else if (!this.patientSelected.mainContactId) {
                this.indexMainContact = contact.id;
                contact = { ...contact, isMainContact: true }
            }
            this.contacts.push(this.createNewContactForm(contact))
        });
    }

    calculateNamePatient(element: Patient) {
        return element ? element.surname + ' ' + element.name : null;
    }

    calculateIdPatient(element: Patient) {
        return element ? element.id : null;
    }

    calculateNameLocation(location: Location) {
        return !!location ? location.name : '';
    }

    calculateIdLocation(location: Location) {
        return !!location ? location.id : '';
    }

    calculateNameProvince(province: Province) {
        return province ? province.name : null;
    }

    calculateIdProvince(province: Province) {
        return province ? province.id : null;
    }

    loadProvincesByName = (value: string) => {
        const sfp: ProvinceQPS = {
            name_like: value
        };
        return this.entitiesFacade.loadProvinces(sfp);
    }

    selectProvince(id: number) {
        let province = this.provincesOnSelect.find(province => province.id == id);
        this.provinceSelected = province;
        this.formAddress.patchValue({
            [this.PATIENT_ADDRESS_LOCATION]: null
        })
        this._locations = this.entitiesFacade.loadLocations({ provinceId: province.id }).subscribe((locations: Location[]) => {
            if (!!locations) {
                this.locations = locations;
                if (!!this.actualPatientLocation) {
                    this.selectLocation(this.actualPatientLocation.id);
                }
            }
        })
        if (!!id && this.provincesOnSelect.length > 0) {
            this.selectedProvince = this.provinceSelected.name
        }
    }

    selectLocation(id: number) {
        this.locationSelected = this.locations.find(l => l.id == id);
        this.selectedCity = this.locationSelected.name;

        this.formAddress.get('zipCode').setValue(this.locationSelected.zipCode);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    openDialog(title: string, template, style: { minWidth: string, maxHeight: string, maxWidth: string }, afterClosed?, templateClass?: string): void {
        let data = { template, title, templateClass: null };
        if (!!templateClass) {
            data.templateClass = templateClass;
        }
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            maxWidth: style.maxWidth,
            panelClass: 'custom-modalbox',
            data: data
        })
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }


    createFormCaseActive(caseForPatient: Case) {
        return this.formBuilder.group({
            [this.PATIENT_NAME_CASE_ACTIVE]: [{ value: caseForPatient.id, disabled: true }],
            [this.PATIENT_OPERATOR_CASE_ACTIVE]: [{ value: caseForPatient.operator.surname + ', ' + caseForPatient.operator.name, disabled: true }]
        })
    }

    redirectToDashboard() {
        this.dialog.closeAll();
        this.router.navigate([ROUTES.ADMISSIONS]);
    }

    redirectToCase() {
        this._dialogRef.close();
        if (this.parentComponent == "admission-creation") {
            this.generalService.setGoBack({ route: ROUTES.ADMISSIONS_CREATE, title: 'Dashboard' });
        } else {
            this.generalService.setGoBack({ route: ROUTES.CASES, title: 'Casos' })
        }

        const url: string = ROUTES.CASES + '/' + (this.caseActive ? this.caseActive.id : this.closedCase.id);
        window.open(url, '_blank');
        if (this.parentComponent == "admission-creation") {
            this.returnToDashboard();
        } else {
            this.closeDialog();
        }
    }

    calculateAge(birthDate?: Date): string {

        if (birthDate === undefined) {
            birthDate = this.patientBirthdate
        }
        if (birthDate != null)
            return this.patientAge = `${moment().diff(birthDate, 'years')} años`
        else {
            this.patientAge = '';
            return '';
        }
    }

    clickCancelEditPatient() {
        this.onClickCancelEditPatient.emit();
    }

    clickConfirmEditPatient() {
        this.onClickConfirmEditPatient.emit();
        this.onClickConfirm = true;
    }

    addTask() {
        this.formSelectTask = this.createFormSelectTask();
        this.openDialog('Tipo de tarea', this.selectTask, { maxHeight: '95vh', minWidth: '35%', maxWidth: '80%' }, () => {
        })
    }

    createFormSelectTask(): FormGroup {
        return this.formBuilder.group({
            radioButton: new FormControl({ value: '', disabled: false }, CustomValidators.required('Debe seleccionar una opción'))
        })
    }

    clickCancelSelectTask() {
        this._dialogRef.close();
    }

    clickConfirmSelectTask() {
        let taskToCreate: PostRequisitionBody = {
            caseId: this.caseActive.id
        }
        let requisitionType = Object.values(RequisitionTypes).find(value => value == this.selectedTask);
        taskToCreate.requisitionTypeId = Object.values(RequisitionTypes).indexOf(requisitionType);
        this.requisitionFacade.addRequisition(taskToCreate).subscribe(requisition => {
            this.dialog.closeAll();
            this.router.navigate([ROUTES.ADMISSIONS]);

            const swalWithCustomizeButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btnSwalConfirm',
                    cancelButton: 'btnSwalCancel'
                },
                buttonsStyling: true
            })

            swalWithCustomizeButtons.fire({
                title: 'Tarea creada correctamente',
                icon: 'success',
            });
        });
        // TODO: llamar al endpoint
    }

    radioButtonSelected(event) {
        this.selectedTask = event.value
    }

    returnToDashboard() {
        this.dialog.closeAll();
        this.router.navigate([ROUTES.ADMISSIONS]);
    }

    isRequisition(casee: Case) {
        return casee.requested;
    }

    admissionDocumentNumberValidation(documentNumber: string) {
        let patientId
        this.patientsService.getPatients({ document_number: documentNumber }).subscribe(p => {
            if (!!p && !!p[0]) {
                patientId = p[0].id;
            }
            if (!!patientId) {
                this.casesService.getCases({ active: true, patientId: patientId, page: 0, size: 10 }).subscribe((casesPages) => {
                    let caseActive = casesPages.data
                    if (!!caseActive) {
                        this.patientSelected = caseActive[0].patient;
                        this.caseActive = caseActive[0];
                        this.formPatientCaseActive = this.createFormCaseActive(this.caseActive);

                        this.openDialog('', this.patientCaseActive, { maxHeight: '95vh', minWidth: '35%', maxWidth: '35%' }, () => {
                            // Hacer algo cuando se cierra o nada
                        }, "p-0");
                    }
                })
            }
        })
    }

    caseDocumentNumberValidation(documentNumber: string) {
        let patientId
        this.isValidatingDocumentNumber.emit(true);
        this.patientsService.getPatients({ document_number: documentNumber }).subscribe(p => {
            if (!!p && !!p[0]) {
                patientId = p[0].id
            }
            if (!!patientId) {
                this.casesService.getCases({ patientId: patientId, page: 0, size: 10 }).subscribe(casesPages => {
                    let cases = casesPages.data
                    let activeCases = cases?.filter(c => c.active == true);
                    let closedCases = cases?.filter(c => c.active == false);
                    let closedCaseId = closedCases[0]?.id;
                    if (!!cases && activeCases.length == 0 && closedCases.length != 0) {
                        this.casesService.getCase(closedCaseId, { withProvisions: false }).subscribe(c => {
                            this.caseActive = null
                            this.closedCase = c;
                            let lastState = this.closedCase?.states[this.closedCase?.states.length - 1];
                            this.closedCaseDate = moment(lastState?.stateChangeDate).format('DD/MM/YYYY');
                            this.isValidatingDocumentNumber.emit(false);
                            this.emitCase.emit(this.closedCase);
                            this.patientSelected = this.closedCase.patient;
                            if (this.onClickConfirm) {
                                this.formPatientCaseActive = this.createFormCaseActive(this.closedCase);
                                this.openDialog('', this.patientCaseActive, { maxHeight: '95vh', minWidth: '35%', maxWidth: '35%' }, () => {
                                }, "p-0");
                            }

                        })
                    } else if (!!cases && activeCases.length != 0 && activeCases[0].id != this.case.id) {
                        this.closedCase = null
                        this.patientSelected = activeCases[0].patient;
                        this.caseActive = activeCases[0];
                        this.formPatientCaseActive = this.createFormCaseActive(this.caseActive);
                        this.formPatienValid = false;
                        this.formPatientDataValid.emit(false);
                        this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).setValidators(CustomValidators.required('No se puede continuar con el dni seleccionado'))
                        this.emitCase.emit(this.caseActive);
                        this.openDialog('', this.patientCaseActive, { maxHeight: '95vh', minWidth: '35%', maxWidth: '35%' }, () => {
                            // Hacer algo cuando se cierra o nada
                        }, "p-0");
                        this.isValidatingDocumentNumber.emit(false)
                    } else {
                        this.noCaseFound();
                        this.formPatienValid = true;
                        this.formPatientDataValid.emit(true);
                    }
                })
            } else {
                this.noCaseFound();
            }
        })
    }

    validateDocumentNumber() {
        this.isValidatingDocumentNumber.emit(true)
        const documentNumber = this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).value;
        if (!!documentNumber && !/^[0-9]*$/.test(documentNumber)) {
            this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).setErrors({ pattern: 'El documento debe contener solo números.' });
            this.isValidatingDocumentNumber.emit(false);
            return;
        }
        if (this.parentComponent == "admission-creation" && !!documentNumber && documentNumber.length >= 7 && parseInt(documentNumber) > 1000000) {
            this.admissionDocumentNumberValidation(documentNumber);
        } else if (this.parentComponent == "patient-detail" && !!documentNumber && documentNumber.length >= 7 && parseInt(documentNumber) > 1000000 && this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).dirty) {
            this.caseDocumentNumberValidation(documentNumber);
        } else {
            this.noCaseFound()
        }
        this.formPatient.get(this.PATIENT_DOCUMENTNUMBER).setErrors(null);
    }

    noCaseFound() {
        this.closedCase = null;
        this.caseActive = null;
        this.emitCase.emit(null);
        this.isValidatingDocumentNumber.emit(false);
    }

    confirmChanges() {
        this.confirmChangesEmitter.emit()
    }

    getContactFormGroup(index: number): FormGroup {
        return this.contacts.at(index) as FormGroup;
    }
}
