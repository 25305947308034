<div *ngIf="!!this.case">
    <div style="min-width: 100%; display: flex; align-items: center; flex-wrap: nowrap; justify-content: flex-end;"
        class="gap-1em flex-row start-center">

        <!-- ////////////////////////// PRACTICES //////////////////// -->
        <div class="flex-row start-center" style="place-items: center; justify-content: flex-start;
            background-color: rgb(228, 226, 230); min-width: 45%;
            border-radius: 1rem; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.19), 0 1px 1px rgba(0, 0, 0, 0.23);">
            <!-- Vertical Tag -->
            <div style="display: flex; justify-content: center; width: auto; height: 100%;">
                <span
                    style="writing-mode: vertical-lr; transform: rotate(180deg); font-size: 12px; font-weight: 600">Prácticas</span>
            </div>
            <!-- Provisions without attentions -->
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterProvisionsWithoutAttenttions != 0"
                    (click)="counterProvisionsWithoutAttenttions !== 0 && goToScreen(ViewManagementEntities.PRACTICES_WITHOUT_SCHEDULE)"
                    style="min-width: auto;" class="flex-column align-center-center">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterProvisionsWithoutAttenttions && !this.btnsDisabled else loadingProvisions">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorProvisionsWithoutAttentions">
                                {{ isCounterProvisionsWithoutAttenttions && !btnsDisabled ?
                                this.counterProvisionsWithoutAttenttions : '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingProvisions>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Prácticas sin agenda</span>
                    </div>
                </div>
            </mat-card>
            <!-- Attentions old and omitted -->
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterAttentionsOldAndOmitted != 0" (click)="counterAttentionsOldAndOmitted !== 0 && goToScreen(ViewManagementEntities.ATTENTIONS_OLD_AND_OMITTED)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container *ngIf="this.isCounterAttentionsOldAndOmitted && !this.btnsDisabled else loadingOldAndEmittedAttentions">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorAttentionsOldAndOmitted">
                                {{ isCounterAttentionsOldAndOmitted && !btnsDisabled ?
                                this.counterAttentionsOldAndOmitted : '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingOldAndEmittedAttentions>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Atenciones sin finalizar</span>
                    </div>
                </div>
            </mat-card>
            <!-- Attentions without billing -->
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterAttentionsWithoutBilling != 0"   (click)="this.counterAttentionsWithoutBilling !== 0 && goToScreen(ViewManagementEntities.ATTENTIONS_WITHOUT_BILLING)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterAttentionsWithoutBilling && !this.btnsDisabled else loadingAttentionsWithoutBill">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorAttentionsWithoutBilling">
                                {{ isCounterAttentionsWithoutBilling && !btnsDisabled ?
                                this.counterAttentionsWithoutBilling : '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingAttentionsWithoutBill>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Atenciones sin facturar</span>
                    </div>
                </div>
            </mat-card>
            <!-- Billing Attentions -->
            <mat-card style="width: auto; height: 98%; border-radius: 0 1rem 1rem 0;"
                class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterBillingAttentions != 0"  (click)="this.counterBillingAttentions !== 0 && goToScreen(ViewManagementEntities.BILLING_ATTENTIONS)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterBillingAttentions && !this.btnsDisabled else loadingBillingAttentions">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorBillingAttentions">
                                {{ isCounterBillingAttentions && !btnsDisabled ? this.counterBillingAttentions : '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingBillingAttentions>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Atenciones sin informar</span>
                    </div>
                </div>
            </mat-card>
        </div>

        <!-- ////////////////////////// SUPPLIES //////////////////// -->
        <div class="flex-row start-center"
            style="place-items: center; justify-content: flex-start;
            min-width: 45%; background-color: rgb(228, 226, 230);
            border-radius: 1rem; box-shadow: 0 1px 1px rgba(109, 104, 104, 0.19), 0 1px 1px rgba(109, 104, 104, 0.19);">
            <!-- SUPPLY CARDS -->
            <!-- Provisions without attentions -->
            <div style="display: flex; justify-content: center; width: auto; height: 100%;">
                <!-- Texto centrado -->
                <span
                    style="writing-mode: vertical-lr; transform: rotate(180deg); font-size: 12px; font-weight: 600;">Insumos</span>
            </div>
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterSuppliesWithoutOrder != 0"  (click)="this.counterSuppliesWithoutOrder !== 0 && goToScreen(ViewManagementEntities.SUPPLIES_WITHOUT_ORDER)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterSuppliesWithoutOrder && !this.btnsDisabled else loadingSuppliesWithoutOrder">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorSuppliesWithoutOrder">
                                {{ isCounterSuppliesWithoutOrder && !btnsDisabled ? this.counterSuppliesWithoutOrder :
                                '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingSuppliesWithoutOrder>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Insumos no pedidos</span>
                    </div>
                </div>
            </mat-card>
            <!-- Attentions old and omitted -->
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterSuppliesNotDelivered != 0"  (click)="this.counterSuppliesNotDelivered !== 0 && goToScreen(ViewManagementEntities.SUPPLIES_NOT_DELIVERED)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterSuppliesNotDelivered && !this.btnsDisabled else loadingSuppliesNotDelivered">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorSuppliesNotDelivered">
                                {{ isCounterSuppliesNotDelivered && !btnsDisabled ? this.counterSuppliesNotDelivered :
                                '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingSuppliesNotDelivered>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Pedidos no entregados</span>
                    </div>
                </div>
            </mat-card>

            <!-- Attentions without billing -->
            <mat-card class="flex-column align-center-center">
                <div [class.cursor-pointer]="this.counterSuppliesWithoutBilling != 0"  (click)="this.counterSuppliesWithoutBilling !== 0 && goToScreen(ViewManagementEntities.SUPPLIES_NOT_BILLING)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterSuppliesWithoutBilling && !this.btnsDisabled else loadingSuppliesWithoutBilling">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorSuppliesWithoutBilling">
                                {{ isCounterSuppliesWithoutBilling && !btnsDisabled ? this.counterSuppliesWithoutBilling
                                : '-' }}
                            </span>
                        </ng-container>
                        <ng-template #loadingSuppliesWithoutBilling>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Insumos sin facturar</span>
                    </div>
                </div>
            </mat-card>
            <!-- supply not delivered -->
            <mat-card style="width: auto; height: 98%; border-radius: 0 1rem 1rem 0;"
                class="flex-column align-center-center">
                <div  [class.cursor-pointer]="this.counterSuppliesNotInformed != 0"  (click)="this.counterSuppliesNotInformed !== 0 && goToScreen(ViewManagementEntities.SUPPLIES_NOT_INFORMED)"
                    class="flex-column align-center-center cursor-pointer">
                    <div class="number-container">
                        <ng-container
                            *ngIf="this.isCounterSuppliesNotInformed && !this.btnsDisabled else loadingSuppliesNotInformed">
                            <span class="mat-display-1 number-font big-number"
                                [style.color]="this.colorSuppliesNotInformed">
                                {{ isCounterSuppliesNotInformed && !btnsDisabled ? this.counterSuppliesNotInformed : '-'
                                }}
                            </span>
                        </ng-container>
                        <ng-template #loadingSuppliesNotInformed>
                            <mat-spinner class="mr-3" color="primary" diameter="30"></mat-spinner>
                        </ng-template>
                    </div>
                    <div class="text-container flex-column align-center-center">
                        <span class="text-title">Insumos sin informar</span>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
