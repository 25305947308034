import {
   HttpEvent,
   HttpHandler,
   HttpInterceptor,
   HttpRequest,
   HttpResponse,
   HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { tap, catchError } from 'rxjs/operators';
import { Token } from 'src/app/shared/models/token';
import { AlertService } from '../services/alert.service';
import { HttpErrorHandlerService } from '../services/http-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   constructor(
      private authService: AuthenticationService,
      private alertService: AlertService,
      private handlerErrorService: HttpErrorHandlerService,
   ) {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Add authorization header with jwt token if available
      const currentUser = this.authService.userValue;
      if (currentUser && currentUser.token) {
         request = request.clone({
            setHeaders: {
               Authorization: `Bearer ${currentUser.token.accessToken}`,
               RefreshToken: `${currentUser.token.refreshToken}`,
            },
         });
      } else {
         // Didn't enter into the interceptor
      }
      return next.handle(request).pipe(
         tap((evt) => {
            if (evt instanceof HttpResponse) {
               const accessToken = evt.headers.get(environment.accessTokenHeaderName)
                  ? evt.headers.get(environment.accessTokenHeaderName)
                  : null;

               const refreshToken = evt.headers.get('RefreshToken')
                  ? evt.headers.get('RefreshToken')
                  : null;
               const token: Token = {
                  accessToken: accessToken,
                  refreshToken: refreshToken,
               };
               if (accessToken && refreshToken) {
                  this.authService.updateCurrentUser({ token: token });
               }
            }
         }),
         // HTTP Errors:
         catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
               try {
                  // Llamar al handle error service
                  const messageError = this.handlerErrorService.handleError(err);
                  if (messageError) {
                     this.alertService.openError(messageError);
                  } else {
                     // this.alertService.openError('Ha ocurrido un error');
                  }
               } catch (e: any) {
                  if (e.status != 428) {
                     this.alertService.openError('Ha ocurrido un error');
                  }
               }
            }
            return throwError(err);
         }),
      );
   }
}
