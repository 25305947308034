import { Component, OnInit, Input, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import { EventEmitter } from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { startWith, map, tap, filter, switchAll, debounce, finalize, delay } from 'rxjs/operators';
import { Subject, Observable, timer, of } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { StringFilterByPipe } from 'src/app/shared/pipes/string-filter-by-pipe.pipe';
//!RESOLVER: Se selecciona el primer valor de la lista al buscar por el filter y cuando lo selecciono no se llama al onchange
@Component({
    selector: 'app-select-searching-dynamic-input',
    templateUrl: './select-searching-dynamic-input.component.html',
    styleUrls: ['./select-searching-dynamic-input.component.scss']
})
export class SelectSearchingDynamicInputComponent extends FormElementComponent implements OnInit {
    selectedValue: any;
    selectedElement: any;
    @ViewChild('singleSelect', { static: true }) singleSelect!: MatSelect;
    @Input() elements!: Array<any>;
    @Input() noOptionText!: string;
    @Input() defaultOptions!: Array<{ id: number | string, name: string }>;
    @Input() httpGet!: (value: string) => Observable<any>;
    @Input() nameSearching!: string;
    @Input() placeholderNoValue!: string;
    @Input() placeholderSearching!: string;
    @Input() defaultValue!: string;
    @Input() clearable = false;
    // Input provided that indicates if the values of the mat-options will be computed as strings or numbers
    @Input() forceNumber!: string;
    // Functions provided by the parent that tells the component how to calculate the id and name of the options
    @Input() calculateId: (element:any) => any = (e) => e?.id;
    @Input() calculateName: (element:any) => any = (e) => e?.name;
    // Emit an event every time the select changes
    @Output() public selected: EventEmitter<any> = new EventEmitter();
    @Output() public selectedWithEntity: EventEmitter<any> = new EventEmitter();
    /** Subject that emits when the component has been destroyed. */
    /** list of banks filtered by search keyword */
    searchPipe = new StringFilterByPipe();
    searchingSpinner = false;
    storedValue: any;
    public filteredValues!: any;
    protected _onDestroy = new Subject<void>();
    get searchCtrl() {
        return this.form.get(this.nameSearching);
    }
    constructor() {
        super();
    }

    // Static function that returns the appropriate function according to the "calculate" parameter
    static proxyCalculate(calculate:string): (element:any) => string {
        if (typeof calculate === 'string') {
            return element => {
                return element ? element[String(calculate)] : null;
            };
        } else {
            return calculate;
        }
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Id
    proxyCalculateId(): (element:{id:string}) => string {
        return SelectInputComponent.proxyCalculate(this.calculateId);
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Name
    proxyCalculateName(): (element:{name:string}) => string {
        return SelectInputComponent.proxyCalculate(this.calculateName);
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        !!this.elements && this.elements.length > 0 ? this.filteredValues = this.elements : null;
    }

    override ngOnInit() {
        this.form.get(this.name)?.setValue(this.defaultValue);
        if (this.forceNumber) {
            // Subscribe at any change in the select form control and everytime a new option is
            // selected, convert the value to Number if it isn't
            this.form.get(this.name)?.valueChanges.subscribe(value => {
                if (typeof value === 'string') {
                    this.form.get(this.name)?.setValue(Number(value));
                }
            });
        }
        let temporalValue:any;
        !!this.elements && this.elements.length > 0 ? this.filteredValues = this.elements : null;

        this.searchCtrl?.valueChanges
            .pipe(
                startWith(''),
                filter((value: string) => !!value && value.length > 2),
                debounce(value => (value.length > 2) ? timer(500) : timer(2500)),
                tap(_ => this.searchingSpinner = true),
                // map(value => { temporalValue = value; return value == this.storedValue ? of(this.filteredValues) : this.httpGet(value) }),
                map(value => { return this.httpGet(value) }),
                tap(_ => { this.storedValue = temporalValue }),
                switchAll(),
                finalize(() => { this.searchingSpinner = false }),
            ).subscribe(values => {
                const id = this.form.get(this.name)?.value;
                this.searchingSpinner = false
                this.selectedElement = !!this.filteredValues ? this.filteredValues.find(
                    (element:any) =>
                        this.proxyCalculateId()(element) ==
                        (this.forceNumber ? Number(id) : id)
                ) : null;
                this.filteredValues = !!this.selectedElement ? [...values, this.selectedElement] : values;
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    resetElements() {
        this.elements = [];
        this.filteredValues = []
    }

    calculateSelected(id:string) {
        let elementSelected = null;
        if (this.elements) {
            elementSelected = this.elements.find(
                element =>
                    this.proxyCalculateId()(element) ==
                    (this.forceNumber ? Number(id) : id)
            );
        }
        return elementSelected;
    }

    change(event:any) {
        // Called everytime a new value is selected
        this.selected.emit(event.value);
        let elementSelected;
        if (this.filteredValues) {
            elementSelected = this.filteredValues.find(
                (element:any) =>
                    this.proxyCalculateId()(element) ==
                    (this.forceNumber ? Number(event.value) : event.value)
            );
        }
        this.selectedWithEntity.emit(elementSelected ? elementSelected : event.value);
    }

    clearSelection(event:any) {
        this.form.get(this.name)?.setValue(null);
        this.selectedValue = null;
        this.selectedElement = null;
        event.stopPropagation();
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
          return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
