import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { debounce, filter, map, skip, startWith } from 'rxjs/operators';
import { CasesFacade, CasesFilterParameters } from 'src/app/abstraction/cases.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { CasesService } from 'src/app/core/services/cases.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Case, CASE_AUTHORIZATIONS_STATES } from 'src/app/shared/models/case';
import { Financier } from 'src/app/shared/models/financier';
import { Operator } from 'src/app/shared/models/operator';
import { Provider } from 'src/app/shared/models/provider';
import { ROUTES } from './../../../../core/enums/routes';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import * as moment from 'moment';

enum INDICATORS {
  HAS_NOT_SCHEDULE = '1',
  HAS_NOT_PENDING_SCHEDULE = '2'
}

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
})
export class CasesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;

  openedCalendar: boolean;

  public operatorId: number;
  public filtroRecuperado? = Object;

  public readonly OPERATOR = 'operatorId';
  public readonly OPERATOR_SEARCHING = 'operatorSearching';
  public readonly PATIENT = 'patientId';
  public readonly PATIENT_SEARCHING = 'patientSearching';
  public readonly PROVIDER = 'providerId';
  public readonly PROVIDER_SEARCHING = 'providerSearching';
  public readonly FINANCIER = 'financierId';
  public readonly FINANCIER_SEARCHING = 'financierSearching';
  public readonly ALL_CASES = 'all';
  public readonly DERIVED = 'derived';
  public readonly WITHOUT_ARRANGEMENTS = 'withoutArrangements'
  public readonly INDICATOR = 'indicators';
  public readonly FROM_DATE = 'fromDate';

  inputValue;

  CASE_AUTHORIZATIONS_STATES = CASE_AUTHORIZATIONS_STATES;
  filterForm: FormGroup;
  _operators: Subscription;
  operators: Operator[];

  _financiers: Subscription;
  financiers: Financier[];

  _providers: Subscription;
  providers: Provider[];

  indicatorsOptions = [
    {
      id: INDICATORS.HAS_NOT_SCHEDULE,
      name: 'Sin agenda'
    },
    {
      id: INDICATORS.HAS_NOT_PENDING_SCHEDULE,
      name: 'Casos sin actividad agendada'
    }
  ];
  selectedIndicator; // Select default indicator value
  user;

  public readonly TITLE_CASES_LIST = 'Lista de casos';
  displayedColumnsMainData: string[] = [
    'active',
    'validity',
    'patient',
    'patientDocument',
    'financier',
    // 'financierInternalCode',
    // 'patient',
    // 'typeDni',
    // 'dni',
    'operator',
    'diagnosis',
    // 'active',
    // 'alert',
  ];
  displayedColumnsIndicators: string[];
  displayedColumnsIndicatorsExpand: string[] = [
    /* 'daysWithoutScheduled',
    'attentionsNotCompleted', */
    'expiredAuthorizations',
  ];
  displayedColumnsIndicatorsCollapse: string[] = [
    'dummy',
  ];
  dataSource;
  cases: Case[];
  isLoadingGettingCases: boolean;
  _isLoadingGettingCases: Subscription;
  cases$: Observable<Case[]>;
  _cases: Subscription;
  _pagination: Subscription;
  _mdc: Subscription;
  _sort: Subscription;
  _input: Subscription;
  numberOfPages: number;
  numberOfRows: number;
  metaDataCases: CasesFilterParameters;
  actualDirection: SortDirection;
  _casesLoaded: Subscription;

  constructor(
    private router: Router,
    public generalService: GeneralService,
    public casesService: CasesService,
    private casesFacade: CasesFacade,
    private formBuilder: FormBuilder,
    private entitiesFacade: EntitiesFacade,
    private authService: AuthenticationService,
    private objecttosessionstorage: StorageService,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    this.cases$ = this.casesFacade.getCases$();
  }

  ngOnInit() {

    this.generalService.sendMessage(this.TITLE_CASES_LIST);
    this.displayedColumnsIndicators = this.displayedColumnsIndicatorsExpand;
    this.dataSource = new MatTableDataSource();
    this.entitiesFacade.loadOperators();
    this.user = this.authService.userValue;
    this.filterForm = this.createFilterForm();
    this.entitiesFacade.loadFinanciers();
    // Set arrangement checkbox date on first day of the current month
    this.filterForm.controls[this.FROM_DATE].setValue(new Date(new Date().getFullYear(), new Date().getMonth(), 1))

    this._operators = this.entitiesFacade
      .getOperators$()
      .subscribe(operators => this.operators = operators);

    this._financiers = this.entitiesFacade
      .getFinanciers$()
      .subscribe( financiers => this.financiers = financiers)

    this.loadWithFilterDefault(); // Always reload with filter default o session (if exist)

    this.suscribeToData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (!!this.inputSearch) {
      this.inputSearch.nativeElement.value = null;
    }
  }

  ngOnDestroy(): void {
    this._cases.unsubscribe();
    this._pagination.unsubscribe();
    this._operators.unsubscribe();
    this._mdc.unsubscribe();

    if (!!this._input) {
      this._input.unsubscribe();
    }

    //this._providers.unsubscribe();
    this._isLoadingGettingCases.unsubscribe();
    if (!!this._casesLoaded){
      this._casesLoaded.unsubscribe();
    }
    this.casesFacade.setCases$(null);
  }


  createFilterForm(): FormGroup {
    return this.formBuilder.group({
      [this.OPERATOR]: [],
      [this.OPERATOR_SEARCHING]: [''],
      [this.PATIENT]: [],
      [this.PATIENT_SEARCHING]: [''],
      [this.FINANCIER]: [],
      [this.FINANCIER_SEARCHING]: [''],
      [this.ALL_CASES]: [],
      [this.DERIVED]: [],
      [this.INDICATOR]: [],
      [this.WITHOUT_ARRANGEMENTS]: [],
      [this.FROM_DATE]: []
      /*[this.PROVIDER]: [],
      [this.PROVIDER_SEARCHING]: [''],*/
    });
  }


  loadWithFilterDefault() {

    let cfp: CasesFilterParameters = {};

    if ( this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' )) {
      cfp = this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' );
    } else {
      cfp= {
        page: 0,
        size: 10,
        order: 'ASC',
        sort: 'fromDate',
        active: true,
        operatorId: this.user.id,
        requested: 0 // Only cases
      };
    }

    console.log("loadCases 1");
    this._casesLoaded = this.casesFacade.loadCases(cfp, true).subscribe();
  }


  suscribeToData() {

    console.log('suscribeToData');

    this._isLoadingGettingCases = this.casesFacade.isLoadingGettingCases$().subscribe(loading => this.isLoadingGettingCases = loading);
    this._pagination = this.casesFacade.getPagination$().subscribe((pagination) => {

      if (!!pagination) {
        this.numberOfPages = pagination.pages;
        this.numberOfRows = pagination.rows;
      }
    });

    this._mdc = this.casesFacade.getMetaDataCases$().subscribe((mdc) => {

      // If exist sessionStorage with filter key "filterListCases"
      if ( this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' )) {
        mdc = this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' );
      }

      this.metaDataCases = mdc;

      // Checkbox ALL_CASES
      let allCases;
      if (this.metaDataCases?.active != null && this.metaDataCases?.active != undefined) {
        allCases = false;
      } else {
        allCases = true;
      }

      // Checkbox DERIVE
      let derived;
      if (this.metaDataCases?.derived != null && this.metaDataCases?.derived != undefined) {
        derived = true;
      } else {
        derived = false;
      }

      // Checkbox WITHOUT_ARRANGEMENT
      let withoutArrangement;
      if (this.metaDataCases?.byArrangement != null && this.metaDataCases?.byArrangement != undefined) {
        withoutArrangement = true;
      } else {
        withoutArrangement = false;
      }

      this.filterForm.get(this.ALL_CASES).setValue(allCases);
      this.filterForm.get(this.DERIVED).setValue(derived);
      this.filterForm.get(this.WITHOUT_ARRANGEMENTS).setValue(withoutArrangement);

      if (this.metaDataCases?.hasSchedule != null && this.metaDataCases?.hasSchedule != undefined) {
        this.selectedIndicator = INDICATORS.HAS_NOT_SCHEDULE;
      } else if (this.metaDataCases?.hasPendingSchedule != null && this.metaDataCases?.hasPendingSchedule != undefined) {
        this.selectedIndicator = INDICATORS.HAS_NOT_PENDING_SCHEDULE;
      } else {
        this.selectedIndicator = '0';
      }
    });

    this._cases = this.cases$.subscribe(

      (cases: Case[]) => {

      if ((cases == null && !this.isLoadingGettingCases)) {

        this.loadWithFilterDefault()

      } else {

        this.cases = cases;
        this.dataSource.data = cases;

        setTimeout(() => {
          this.setDataSourceAttributes();
        }, 500);
      }
    });

    setTimeout(() => {

      // Quickfilter is saved too in session
      if ( this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' ) ) {
        const filter = this.objecttosessionstorage.getObjectfromSessionStorage ( 'filterListCases' );
        if (filter.searchPatient || filter.searchCif || filter.searchDocument) {
          this.inputSearch.nativeElement.value = filter.searchPatient || filter.searchCif || filter.searchDocument;
        }
      }

      this._input = fromEvent(this.inputSearch.nativeElement, 'keyup').
        pipe(
          map((event: any) => event.target.value),
          startWith(''),
          filter((value: string) => (value.length != 1 && value.length != 2)),
          skip(1),
          debounce((string: string) => (string.length > 1) ? timer(500) : timer(1250)),
        ).subscribe((st) => {

          let cfp: CasesFilterParameters = this.metaDataCases;

          console.log("Quickfilter cases");

          delete cfp.searchPatient;
          delete cfp.searchCif;
          delete cfp.searchDocument;

          if (st.length != 0){
            if (this.hasNumbers(st) && this.hasLetters(st)) {
              // By Cif
              cfp.searchCif = st;
            } else if (this.hasNumbers && !this.hasLetters(st)) {
              // By Document
              cfp.searchCif = st;
              cfp.searchDocument = st;
            } else {
              // By Patient
              cfp.searchPatient = st;
            }
          }
          cfp.page = 0;
          cfp.requested = 0;
          this.objecttosessionstorage.setObjectToSessionStorage ( 'filterListCases', cfp );
          this._casesLoaded = this.casesFacade.loadCases(cfp, true).subscribe();
        });
    }, 500);
  }


  hasNumbers(st: string): boolean {
    const numbers = '0123456789';
    for (let i = 0; i < st.length; i++) {
      if (numbers.indexOf(st.charAt(i), 0) != -1) {
        return true;
      }
    }
    return false;
  }


  hasLetters(st: string): boolean{
    const numbers = '0123456789';
    for (let i = 0; i < st.length; i++) {
      if (numbers.indexOf(st.charAt(i), 0) == -1) {
        return true;
      }
    }
    return false;
  }


  setDataSourceAttributes() {

    this.dataSource.sort = this.sort;

    if (this.paginator != null && this.paginator != undefined) {
      this.paginator.pageIndex = this.metaDataCases?.page;
      this.paginator.pageSize = this.metaDataCases?.size;
      this.paginator.length = this.numberOfRows;
      this.paginator._intl.itemsPerPageLabel = 'Registros por página';
      this.paginator._intl.firstPageLabel = 'Primera página';
      this.paginator._intl.lastPageLabel = 'Última página';
      this.paginator._intl.nextPageLabel = 'Página siguiente';
      this.paginator._intl.previousPageLabel = 'Página anterior';
    }
  }

  getDateFromForm(){
    return moment(this.filterForm.get(this.FROM_DATE).value).format("DD/MM/YYYY");
  }

  onPaginatorEvent() {

    let cfp: CasesFilterParameters = this.metaDataCases;
    cfp.size = this.paginator.pageSize;
    cfp.page = this.paginator.pageIndex;
    cfp.requested = 0;

    // Update new filter in session
    this.objecttosessionstorage.setObjectToSessionStorage ( 'filterListCases',  cfp );
    console.log("loadCases 3");
    this._casesLoaded = this.casesFacade.loadCases(cfp, true).subscribe();
  }

  onSortEvent(e) {
    let cfp: CasesFilterParameters = this.metaDataCases;
    cfp.page = 0;
    cfp.order = e.direction.toUpperCase();
    cfp.sort = this.sort.active;
    cfp.requested = 0;
    this._casesLoaded = this.casesFacade.loadCases(cfp, true).subscribe();
  }


  checkFilter () {
  }


  onFilter() {
    const allCases = this.filterForm.get(this.ALL_CASES)?.value;
    const operatorId = this.filterForm.get(this.OPERATOR)?.value;
    const patientId = this.filterForm.get(this.PATIENT)?.value;
    const financierId = this.filterForm.get(this.FINANCIER)?.value;
    const indicatorId = this.filterForm.get(this.INDICATOR)?.value;
    const derived = this.filterForm.get(this.DERIVED)?.value;
    const byArrangement = this.filterForm.get(this.WITHOUT_ARRANGEMENTS).value;
    //const providerId = this.filterForm.get(this.PROVIDER)?.value; // No se utiliza

    const qps: CasesFilterParameters = { ...this.metaDataCases };

    if (allCases) {
      delete qps.active;
    } else {
      qps.active = true;
    }

    if (derived) {
      qps.derived = true;
    } else {
      delete qps.derived;
    }
    // TODO: Gonza: agregar fecha para filtrar por presupuestado en el qps (incidencia AD-1230)
    if (byArrangement) {
      qps.byArrangement = true;
    } else {
      delete qps.byArrangement;
    }

    if (!operatorId || operatorId == '0') {
      delete qps.operatorId;
    } else {
      qps.operatorId = operatorId;
    }

    if (!financierId || financierId == '0') {
      delete qps.financierId;
    } else {
      qps.financierId = financierId;
    }

    if (!patientId || patientId == '0') {
      delete qps.patientsIds;
    } else {
      qps.patientsIds = [patientId];
    }

    if (!indicatorId) {
      delete qps.hasPendingSchedule;
      delete qps.hasSchedule;
    } else {
      if (indicatorId == INDICATORS.HAS_NOT_SCHEDULE) {
        delete qps.hasPendingSchedule;
        qps.hasSchedule = false;
      } else if (indicatorId == INDICATORS.HAS_NOT_PENDING_SCHEDULE) {
        delete qps.hasSchedule;
        qps.hasPendingSchedule = false;
      }
    }
    qps.page = 0;
    qps.requested = 0;
    // Save filter in sessionStorage
    this.objecttosessionstorage.setObjectToSessionStorage ( 'filterListCases', qps );

    console.log("loadCases 5");
    this._casesLoaded = this.casesFacade.loadCases(qps, true).subscribe();
  }


  onClickRow(row: Case) {
    this.generalService.setGoBack({ route: ROUTES.CASES, title: 'Casos' });
    this.router.navigate([ROUTES.CASES, row.id]);
  }


  updateCasesTable() {
    this.dataSource.data = this.cases;
  }

  spinnerFunction(value) {
    return value;
  }

  collapseExpandIndicators() {
    if (this.displayedColumnsIndicators == this.displayedColumnsIndicatorsCollapse) {
      this.displayedColumnsIndicators = this.displayedColumnsIndicatorsExpand;
    } else {
      this.displayedColumnsIndicators = this.displayedColumnsIndicatorsCollapse;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction} ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openCalendar() {
    this.openedCalendar = true;
  }

  isCalendarOpen( event: boolean ) {
      this.openedCalendar = !event
  }
}
