import { ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})

export class MessageDialogComponent<T> {

  @Output() ok = new EventEmitter<boolean>();

  isWorking$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  disableConfirm:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      icon: string,
      headerClass: string,
      titleClass: string,
      arrayTable: Array<T>,
      disableConfirm: boolean,
      closeFunction?: Function,
      closeText?: string,
      confirmText?: string,
      isWorking?: boolean
    },
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.data.closeText = !!this.data.closeText ? this.data.closeText : 'CANCELAR'
    this.data.confirmText = !!this.data.confirmText ? this.data.confirmText : 'ACEPTAR'
    this.data.arrayTable = this.data?.arrayTable?.length > 0 ? this.data.arrayTable : [];
    this.isWorking$.next(!!this.data.isWorking ? this.data.isWorking : false)

    // Create table dynamcically
    if(!!this.data.arrayTable && this.data.arrayTable.length > 0) {
      this.displayedColumns = Object.keys(this.data.arrayTable[0] as string);
      this.columnsToDisplay = Object.keys(this.data.arrayTable[0] as string);
    }

    this.cd.detectChanges();
  }

  onNoClick(): void {
    if(this.data.closeFunction != null){
      this.data.closeFunction();
    } else {
      this.dialogRef.close();
      this.ok.emit(false);
    }
  }

  confirm() {
    this.ok.emit(true);
    if(this.data.disableConfirm){
      this.disableConfirm = true;
    }
  }
}
