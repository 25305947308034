import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { endOfDay, isSameDay, isSameMonth, startOfDay } from 'date-fns';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { AttentionStates } from 'src/app/core/enums/attentionsStates'
import { Attention } from 'src/app/shared/models/attention';
import { GeneralService } from 'src/app/core/services/general.service';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ATTENTIONS_BACKGROUND_COLORS, ATTENTIONS_COLORS, AttentionsFacade } from '../../../../abstraction/attentions.facade';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { CaseDateFilters } from '../../../../abstraction/cases.facade';
import { ButtonsNewDateScheduleNavigator } from '../../../../core/services/general.service';
import { DateService } from 'src/app/core/services/date.service';
import { OPTIONS } from '../provision-scheme/provision-scheme.component';
import { SelectionModel } from '@angular/cdk/collections';
import * as momentRange from 'moment-range';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { CalendarCaseService } from 'src/app/core/services/calendar-case.service';

@Component({
    selector: 'app-calendar-case',
    templateUrl: './calendar-case.component.html',
    styleUrls: ['./calendar-case.component.scss']
})
export class CalendarCaseComponent implements OnInit {

    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    @ViewChildren('targetElement', { read: ElementRef }) targetElements!: QueryList<ElementRef>;

    @Input() events: CalendarEvent[];
    @Input() loading: boolean = false;
    @Input() attentions: Attention[];
    @Input() optionSelected;
    @Input() colorsForAgreements: Map<number, { primary: string, secondary: string }>;

    @Output() onDayClicked = new EventEmitter<Date>();
    @Output() onEventClicked = new EventEmitter<{ calendarEvent: CalendarEvent, editMode?: string }>();
    @Output() onEventDelete = new EventEmitter<CalendarEvent>();
    @Output() onChangeMonth = new EventEmitter<Date>();
    @Output() option = new EventEmitter<string>();
    @Output() onClickCreateAttention = new EventEmitter<any>(); // Emitter to create attention
    @Output() viewSelected = new EventEmitter<CalendarView>(); // Emitter to create attention

    @ViewChild("extraAttentionsTemplate") extraAttentionsTemplate: TemplateRef<any>

    _dialogRef;
    monthEvents: CalendarEvent[];
    attentionStates = AttentionStates;
    ATTENTIONS_COLORS = ATTENTIONS_COLORS;
    ATTENTIONS_BACKGROUND_COLORS = ATTENTIONS_BACKGROUND_COLORS;
    colorReferences;
    // Month - Week - Day
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    // Día actual seleccionado
    viewDate: Date = new Date();
    viewDateX: Date = new Date();
    lastEvent: CalendarEvent;
    previousViewDate = this.viewDate;
    modalData: {
        action: string;
        event: CalendarEvent;
    };

    public readonly TEXT_DATE_FROM = 'Mostrar fecha Realizada';
    public readonly TEXT_DATE_SCHEDULED = 'Mostrar fecha Agendada';

    isChangedDate: boolean = false;
    eventStart: Date;
    dateToShow: string = this.TEXT_DATE_SCHEDULED;
    // viewSelected: string = "mes";
    OPTIONS = OPTIONS
    totalsByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
    totalPricesByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
    statesSelected = new SelectionModel<number>(true, []);
    filteredAttentions: Attention[];

    actions: CalendarEventAction[] = [
        {
            label: `<span class="pxs-2 text-primary-darker">
                  Ver
              </span>`,
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edit', event);
            },
        },
        {
            label: `<span class="pxs-2 text-warn">
                  Eliminar
              </span>`,
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Deleted', event);
            },
        },
    ];

    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = false;

    // HISTORY MODE
    historyModeActivated: boolean;
    _historyModeActivated: Subscription;
    _historyModeDate: Subscription;
    historyModeDate: Date;
    _isLoadingGetAttentions: any;
    _isLoadingGettingEvolutions: any;
    isLoadingGetAttentions: any;
    isLoadingGettingEvolutions: any;

    rangeDate: CaseDateFilters;
    disabledButtonPrevious: boolean;
    disabledButtonNext: boolean;

    buttonsNewDateScheduleNavigator: ButtonsNewDateScheduleNavigator;
    totalHours: any;
    totalCost: any;
    totalAttentions: any;
    viewType: string = 'units';
    extraDayAttentions: CalendarEvent[];
    dayOpened: moment.Moment;

    constructor(private cd: ChangeDetectorRef,
        private generalService: GeneralService,
        public entitiesFacade: EntitiesFacade,
        public dialog: MatDialog,
        private casesFacade: CasesFacade,
        private attentionsFacade: AttentionsFacade,
        private evolutionsFacade: EvolutionsFacade,
        private dateService: DateService,
        public calendarCaseService: CalendarCaseService
    ) { }

    ngOnInit() {

        if (this.events) {

            this.colorReferences = Array.from(new Set(this.events.map(e => e.meta.attention.agreementId)))
                .map(ag => {
                    const events = this.events.find(e => e.meta.attention.agreementId == ag)
                    events.title = events.title
                    return {
                        title: events.meta.referenceText,
                        color: events.color
                    }
                })
        }

        // Spinner control
        this._isLoadingGetAttentions = this.attentionsFacade.isLoadingGetAttentions$().subscribe(loadingAttentions => this.isLoadingGetAttentions = loadingAttentions);
        this._isLoadingGettingEvolutions = this.evolutionsFacade.isLoadingGetEvolutions$().subscribe(loadingEvolutions => this.isLoadingGettingEvolutions = loadingEvolutions);

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.rangeDate = { ...rangeDate, historyToDate: moment(moment(rangeDate.historyToDate).format('YYYY-MM-DD')).toDate() };
                this.buttonsNewDateScheduleNavigator = this.generalService.controlCalendarView(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, this.view);
                this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar;
                this.updateScheduleView();
            }
        })
    }

    clickonCalendarPreviousNextView() {
        this.buttonsNewDateScheduleNavigator = this.generalService.controlCalendarView(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, this.view, this.viewDate)
        this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar
        this.updateScheduleView();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['attentions'] && changes['attentions'].currentValue) {
            this.filterAttentionsByState()
            this.events = this.attentionsToEvents(this.attentions);
        }

        if (changes['events'] && changes['events'].currentValue) {
            this.events = this.addActionsEvents(this.events);
        }

        if (this.events) {
            this.colorReferences = Array.from(new Set(this.events.map(e => e.meta.referenceText)))
                .map(referenceTxt => {
                    return {
                        title: referenceTxt,
                        color: this.events.find(e => e.meta.referenceText == referenceTxt).color
                    }
                })
            this.setViewDateWithLastEventDate();
            this.mapMonthEvents();
        }
        this.cd.detectChanges();
    }

    mapMonthEvents() {
        this.monthEvents = this.events.map((ev) => {
            ev.meta.isNotOneDayEvent = moment(ev.meta.attention.toDate).isAfter(ev.start, 'day');
            return ev
        })
    }

    private addActionsEvents(events: CalendarEvent[]): CalendarEvent[] {
        const eventsComposed: CalendarEvent[] = events.map(eventUncomposed => {
            const event: CalendarEvent = {
                ...eventUncomposed,
                actions: this.actions,
            };
            return event;
        });
        return eventsComposed;
    }

    setViewDateWithLastEventDate() {
        if (this.events != null && this.events != undefined && this.events.length > 0) {

            if (this.viewDate === undefined) {
                let auxDate = this.events[0].start;
                this.events.forEach((ev) => {
                    if (ev.start > auxDate) {
                        auxDate = ev.start;
                    }
                })
                this.viewDate = auxDate;
            }
        }
    }

    badgeClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        event.preventDefault();
        event.stopPropagation()
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    dayClicked(_event) {
        console.log('Day clicked');
        this.onDayClicked.emit(_event.date);
    }

    eventClicked(_event, event?: CalendarEvent) {
        console.log('Event clicked');
        if (event != null) {
            this.onEventClicked.emit({ calendarEvent: event.meta.attention, editMode: 'DSP' });
        } else {
            this.onEventClicked.emit({ calendarEvent: _event.meta.attention, editMode: 'DSP' });
        }
        _event.stopPropagation();
    }

    hourSegmentClicked(_event) {
        console.log('Hour segment clicked');
        this.onDayClicked.emit(_event);
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        console.log('HandleEvent clicked');
        if (action === 'Edit') {
            this.onEventClicked.emit(event.meta.attention);
        } else if (action === 'Deleted') {
            this.onEventDelete.emit(event.meta.attention);
        }
    }

    // TODO pasar al padre
    addEvent(): void {
        console.log('NewEvent clicked');
        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                draggable: false,
                resizable: {
                    beforeStart: false,
                    afterEnd: false,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        console.log('DeleteEvent clicked');
        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
        this.viewSelected.emit(this.view);
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    updateScheduleView() {
        this.viewDateX = this.viewDate;

        const actualMonth = moment(this.viewDate).month() + 1;
        const previousMonth = moment(this.previousViewDate).month() + 1;

        if (actualMonth != previousMonth) {
            this.previousViewDate = this.viewDate;
            this.onChangeMonth.emit(this.viewDate)
        }
        this.calculateTotalsByAttentionState();
    }

    attentionsToEvents(attentions: Attention[]): CalendarEvent[] {

        const events: CalendarEvent[] = attentions.map(attention => {

            this.eventStart = this.isChangedDate ? new Date(attention.fromScheduled) : new Date(attention.fromDate);

            const event: CalendarEvent = {
                id: attention.id,
                color: this.colorsForAgreements.get(attention.agreementId),
                title: `${this.entitiesFacade.getProvidersFullName(attention?.provider)} - $${attention?.providerFee}`,
                start: this.eventStart,
                end: new Date(attention.toDate),
                draggable: false,
                allDay: false,
                resizable: { beforeStart: false, afterEnd: false },
                meta: {
                    attention,
                    referenceText: `${attention.practice.name} • ${attention.patient.surname} | ${this.entitiesFacade.getProvidersFullName(attention?.provider)}`,
                }
            };
            return event;
        });
        return events;
    }

    onToggleChange($event: MatSlideToggleChange) {
        this.isChangedDate = $event.checked;
        this.dateToShow = this.isChangedDate ? this.TEXT_DATE_FROM : this.TEXT_DATE_SCHEDULED;

        if (this.attentions) {
            this.events = this.attentionsToEvents(this.attentions);
            this.mapMonthEvents();
        }
    }

    selectOption(option) {
        this.option.emit(option);
    }

    selectView(option) {
        this.setView(option.value);
        this.buttonsNewDateScheduleNavigator = this.generalService.controlCalendarView(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, this.view);
        this.viewDate = this.buttonsNewDateScheduleNavigator.dateToSetCalendar
        this.updateScheduleView();
    }

    createAttention() {
        const configCreateAttention = {
            view: this.view,
            date: this.viewDate
        }

        this.onClickCreateAttention.emit(configCreateAttention);
    }

    ngOnDestroy(): void {
        this._historyModeDate.unsubscribe();
    }

    mustShowSpinner() {
        if (this.isLoadingGetAttentions || this.isLoadingGettingEvolutions) {
            return true;
        }
        return false;
    }

    // Color disabled if day is out of date range
    applyDateSelectionPolicy(body: any) {
        body?.forEach(day => {
            if (!!this.rangeDate) {
                if (moment(day.date).isValid() && !(moment(day.date).isBetween(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, undefined, '[]'))) {
                    day.inMonth = false; // Day disabled if out of range
                }
            }
        });
    }

    onChangeToggle(event) {
        if (!event.checked) {
            this.viewType = "units";
        } else {
            this.viewType = "price";
        }
    }

    getBackgroundColor(element) {
        if (!this.statesSelected.selected.includes(element)) {
            return this.ATTENTIONS_BACKGROUND_COLORS[element];
        } else {
            return "white";
        }
    }

    getBorderStyle(element) {
        if (!this.statesSelected.selected.includes(element)) {
            return "none";
        } else {
            return "1px solid " + this.ATTENTIONS_BACKGROUND_COLORS[element];
        }
    }
    removeFilters() {
        this.statesSelected.clear();
        this.filterAttentionsByState();
    }

    selectState(id: number) {
        this.statesSelected.toggle(id);
        this.filterAttentionsByState();
    }

    showImport(totalByState: number, stateId: number) {
        let show: boolean = false;
        if (totalByState != 0 || this.totalsByAttentionsState[stateId] != 0) {
            show = true;
        }
        return show;
    }

    filterAttentionsByState() {
        if (!!this.statesSelected.selected) {
            this.filteredAttentions = this.attentions.filter(att => !this.statesSelected.selected.includes(att.state.id));
            this.events = this.attentionsToEvents(this.filteredAttentions);
            this.mapMonthEvents();
        }
        this.calculateTotalsByAttentionState();
    }

    calculateTotalsByAttentionState() {
        // Always get totals
        this.totalsByAttentionsState.fill(0);
        this.totalPricesByAttentionsState.fill(0);
        this.totalAttentions = 0;
        this.totalHours = 0;
        let attentionsFilteredByDate = []
        let range: momentRange.DateRange
        switch (this.view) {
            case (CalendarView.Month):
                range = new momentRange.DateRange(moment(this.rangeDate?.historyFromDate, "YYYYMMDD"), moment(this.rangeDate?.historyToDate, "YYYYMMDD"));
                break;
            case (CalendarView.Week):
                let startOfWeek = moment(this.viewDateX).startOf('isoWeek')
                let endOfWeek = moment(this.viewDateX).endOf('isoWeek')
                range = new momentRange.DateRange(startOfWeek, endOfWeek);
                break
            case (CalendarView.Day):
                range = new momentRange.DateRange(moment(this.viewDateX).startOf('day'), moment(this.viewDateX).endOf('day'));

        }
        attentionsFilteredByDate = this.attentions.filter(att => range.contains(moment(att.fromDate, "YYYYMMDD")));
        attentionsFilteredByDate.map(att => {
            this.totalsByAttentionsState[att.state.id] += 1;
            if (att.state.id == this.attentionStates.AGENDADA && this.calendarCaseService.practiceCountedByHours(att.practice)) {
                this.totalPricesByAttentionsState[att.state.id] += (att.toScheduled && att.fromScheduled) ? moment.duration(moment(att.toScheduled).diff(att.fromScheduled)).hours() * att.provisionFee : att.provisionFee // Attention by hours
            } else {
                this.totalPricesByAttentionsState[att.state.id] = this.calendarCaseService.practiceCountedByHours(att.practice) || (att.isSupply && att.practice?.name != "Entrega insumos") ?
                    this.totalPricesByAttentionsState[att.state.id] += (att.provisionFee * (att.quantity != null ? att.quantity : 1))
                    :
                    this.totalPricesByAttentionsState[att.state.id] += att.provisionFee;
                this.totalHours += !!att.quantity && !this.statesSelected?.selected?.includes(att?.state?.id) ? att?.quantity : 0;
            }

            if (!this.statesSelected.selected.includes(att.state.id)) {
                this.totalAttentions += 1;
            }

        });
    }

    onClickSeeMoreAttentions(_event: any, day: CalendarMonthViewDay) {
        _event.preventDefault();
        _event.stopPropagation();
        this.extraDayAttentions = day.events;
        let template = this.extraAttentionsTemplate;
        this.dayOpened = moment(day.date);

        const dialogWidth = 300;  // Ancho del diálogo
        const dialogHeight = 400; // Altura del diálogo

        // Calcula las posiciones deseadas basadas en el evento de clic
        let left = _event.clientX - dialogWidth / 2;
        let top = _event.clientY - dialogHeight;

        // Obtén los límites de la ventana del navegador
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Ajusta la posición si el diálogo se sale del lado izquierdo o derecho de la pantalla
        if (left < 0) {
            left = 0;
        } else if (left + dialogWidth > viewportWidth) {
            left = viewportWidth - dialogWidth;
        }

        // Ajusta la posición si el diálogo se sale de la parte superior o inferior de la pantalla
        if (top < 0) {
            top = 0;
        } else if (top + dialogHeight > viewportHeight) {
            top = viewportHeight - dialogHeight;
        }

        // Abre el diálogo con la posición ajustada
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            minWidth: `${dialogWidth}px`,
            maxHeight: `${dialogHeight}px`,
            data: { template },
            position: { left: `${left}px`, top: `${top}px` }
        });
    }

    applyMaxWidth() {
        const sourceElement = document.querySelector('mwl-calendar-month-cell');
        const sourceWidth = sourceElement?.clientWidth;
        this.targetElements.forEach(targetElement => {
            targetElement.nativeElement.style.width = `${sourceWidth}px`;
            targetElement.nativeElement.style.maxWidth = `${sourceWidth}px`;
        });
    }

    ngAfterViewInit() {
        this.applyMaxWidth();
    }


    ngAfterViewChecked() {
        this.applyMaxWidth();
    }
}
