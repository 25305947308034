import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Case } from 'src/app/shared/models/case';
import { Patient } from 'src/app/shared/models/patient';
import Swal from 'sweetalert2';
import { EntitiesFacade } from '../../../../abstraction/entities.facade';
import { PatchPatientBody } from '../../../../core/services/cases.service';
import { Observable } from 'rxjs';
import { PatientDataComponent } from 'src/app/modules/admissions/components/patient-data/patient-data.component';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
   selector: 'app-patient-detail',
   templateUrl: './patient-detail.component.html',
   styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit {
   @Input() case: Case;

   patient: Patient;
   patchPatient: PatchPatientBody;
   isValidFormPatient: boolean=false;
   patientEdited: PatchPatientBody;
   documentNumberValidationPending: boolean = false;
   existentCase: Case;

   @ViewChild('diagnosesTemplate', { static: true }) diagnosesTableTemplate: TemplateRef<any>;
   @ViewChild('editPatient', { static: true }) editPatient: TemplateRef<any>;
   @ViewChild('PatientDataComponent') patientDataComponent: PatientDataComponent;

   displayedColumns = [
      'name',
      'observation',
   ];

   _dialogRef;
   swalWithCustomizeButtons:any;

   isLoadingGettingPatient$: Observable<boolean>;
   isLoadingUpdatingPatientCaseAndEntities$: Observable<boolean>;

   constructor(
      private authService: AuthenticationService,
      private casesFacade: CasesFacade,
      private dialog: MatDialog,
      private entitiesFacade: EntitiesFacade,
      public generalService: GeneralService
   ) { }
   ngOnInit() {
      console.log('Map: ', this.getLinkMap());

      this.isLoadingGettingPatient$ = this.entitiesFacade.isLoadingGettingPatient$();

      this.swalWithCustomizeButtons = Swal.mixin({
         customClass: {
          confirmButton: 'btnSwalConfirm',
          cancelButton: 'btnSwalCancel'
         },
         buttonsStyling: true
       })

      this.patient = this.case.patient;
   }

   getLinkMap(): string {

      let link = 'https://www.google.com/maps/search/';
      link += this.case.patient.address.street ? this.case.patient.address.street : '';
      link += ' ' + (
         this.case.patient.address.streetNumber
            ? this.case.patient.address.streetNumber
            : ''
      );
      link += (
         this.case.patient.address.location
            ? `, ${this.case.patient.address.location}`
            : ''
      );
      link += (
         this.case.patient.address.location
         && this.case.patient.address.province
            ? `, ${this.case.patient.address.province}`
            : ''
      );
      return link;
   }

   onClickCreateUser(patient: Patient) {
      this.swalWithCustomizeButtons.fire({
         title: 'Atención',
         text: `¿Seguro desea crear un usuario asociado al paciente ${patient?.surname || patient?.name ? patient?.surname + ' ' + patient.name : ''} ?`,
         icon: 'question',
         confirmButtonText: 'CONFIRMAR',
         cancelButtonText: 'CANCELAR',
         showLoaderOnConfirm: true,
         showCancelButton: true,
         reverseButtons: true,
         preConfirm: () => {
            return this.authService.postUserPatient({ id: this.case.patientId.toString() })
            .toPromise()
            .then((_) => {
               this.swalWithCustomizeButtons.fire({
                  title: 'Cuenta creada correctamente ',
                  text: 'Se le ha enviado un mail a la casilla de correo del usuario para que active la cuenta',
                  icon: 'success',
               });
            })
            .then(_ => this.casesFacade.loadCase(this.case.id))
            .catch((err) => {
               console.log('error', err);
            });
         },
         allowOutsideClick: () => !Swal.isLoading(),
      })
    }

    onClickResendEmailUser(patient: Patient) {
      this.swalWithCustomizeButtons.fire({
         title: 'Atención',
         text: `¿Seguro desea volver a activar la cuenta del usuario ${patient?.surname || patient?.name ? patient?.surname + ' ' + patient.name : ''} ?`,
         icon: 'question',
         confirmButtonText: 'CONFIRMAR',
         cancelButtonText: 'CANCELAR',
         showLoaderOnConfirm: true,
         showCancelButton: true,
         reverseButtons: true,
         preConfirm: () => {
            return this.authService.postUserPatient({ id: this.case.patientId.toString() })
            .toPromise()
            .then((_) => {
               this.swalWithCustomizeButtons.fire({
                  title: 'Cuenta creada correctamente ',
                  text: 'Se le ha enviado un mail a la casilla de correo del usuario para que active la cuenta',
                  icon: 'success',
               });
            })
            .then(_ => this.casesFacade.loadCase(this.case.id))
            .catch((err) => {
               console.log('error', err);
            });
         },
         allowOutsideClick: () => !Swal.isLoading(),
      })
   }
   openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
      this._dialogRef = this.dialog.open(DialogComponent, {
         disableClose: true,
         minWidth: style.minWidth,
         maxHeight: style.maxHeight,
         data: { template, title },
      })
      this._dialogRef.afterClosed().subscribe( res => {
         if (!!res) {
            console.log("RES: ", res);
            afterClosed();
         }
      });
   }

   onClickCloseDialog() {
      this._dialogRef.close();
   }

   showDiagnosesList() {
      this.openDialog(
         'Diagnósticos',
         this.diagnosesTableTemplate,
         {maxHeight: '95vh', minWidth: '40%' },
         () => { }
      );
   }


   onClickDeactivateUser(patient: Patient) {
      this.swalWithCustomizeButtons.fire({
         title: 'Atención',
         text: `¿Seguro desea desactivar la cuenta del usuario ${patient?.surname || patient?.name ? patient?.surname + ' ' + patient.name : ''} ?`,
         icon: 'question',
         confirmButtonText: 'CONFIRMAR',
         cancelButtonText: 'CANCELAR',
         showLoaderOnConfirm: true,
         showCancelButton: true,
         reverseButtons: true,
         preConfirm: () => {
            return this.authService.patchUser({ active: false }, patient.user.id.toString())
            .toPromise()
            .then((_) => {
               this.swalWithCustomizeButtons.fire({
                  title: 'Cuenta desactivada correctamente ',
                  icon: 'success',
               });
            })
            .then(_ => this.casesFacade.loadCase(this.case.id))
            .catch((err) => {
               console.log('error', err);
            });
         },
         allowOutsideClick: () => !Swal.isLoading(),
      })
   }

   onClickActivateUser(patient: Patient) {
      this.swalWithCustomizeButtons.fire({
         title: 'Atención',
         text: `¿Seguro desea volver a activar la cuenta del usuario ${patient?.surname || patient?.name ? patient?.surname + ' ' + patient.name : ''} ?`,
         icon: 'question',
         confirmButtonText: 'CONFIRMAR',
         cancelButtonText: 'CANCELAR',
         showLoaderOnConfirm: true,
         showCancelButton: true,
         reverseButtons: true,
         preConfirm: () => {
            return this.authService.patchUser({ active: true }, patient.user.id.toString())
            .toPromise()
            .then((_) => {
               this.swalWithCustomizeButtons.fire({
                  title: 'Cuenta activada correctamente ',
                  icon: 'success',
               });
            })
            .then(_ => this.casesFacade.loadCase(this.case.id))
            .catch((err) => {
               console.log('error', err);
            });
         },
         allowOutsideClick: () => !Swal.isLoading(),
      })
   }

   onClickEditPatient() {
      // Patient from Entities
      this.entitiesFacade.loadPatient( this.case.patientId ).subscribe( p => {
         if (!!p) {
            this.patchPatient = p;
            this._dialogRef = this.openDialog( 'Datos del paciente', this.editPatient, {maxHeight: '90vh', minWidth: '80%' });
         }
      })
   }

   // POP-UP
   patientData( event ) {
      console.log("Patient data from pop-up: ", event);
      this.patientEdited = event;
   }

   formPatientDataValid( event ) {
      console.log("Patient is valid: ", event);
      this.isValidFormPatient = event;
    }

    confirmChangesEmitter(event){
      this.confirmChanges()
    }

    isValidatingDocumentNumber(event){
      this.documentNumberValidationPending = event
    }

    isExistentCase(event){
      this.existentCase = event
    }


   clickCancelEditPatient() {
      this.dialog.closeAll(); // Workaround close dialog
   }

   clickConfirmEditPatient() {
      console.log("Confirm edit patient from pop-up: ");
      const validation = new Promise((res, rej) => {
         this.patientDataComponent?.validateDocumentNumber();
         if(!this.documentNumberValidationPending){
            res("Validación finalizada");
         } else {
            rej("Error en la validación")
         }
      }).then(() => {
            if(!this.existentCase?.active || this.existentCase == null || this.existentCase == undefined){
               this.confirmChanges()
         }
      })
   }

   confirmChanges(){
      this.isLoadingUpdatingPatientCaseAndEntities$ = this.casesFacade.isLoadingUpdatingPatientCaseAndEntities$();
      if (this.isValidFormPatient) {
         if("documentNumber" in this.patientEdited && this.patientEdited.documentNumber == ""){
            this.patientEdited.documentNumber = "0";
         }
         this.casesFacade.updatePatientCaseAndEntities( this.case.patient.id, this.patientEdited ).subscribe( p => {
            this.dialog.closeAll();
         });
      }
   }
}
