import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { RestUtilitiesService } from './rest-utilities.service';
import { map } from 'rxjs/operators';
import { Practice } from 'src/app/shared/models/practice';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { Specialty } from 'src/app/shared/models/specialty';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { PracticeRestriction } from 'src/app/shared/models/practiceRestriction';
import { Complexity } from '../../shared/models/complexity';
import { Provision } from '../../shared/models/provision';

export interface ProvisionFeesQPS {
   practiceId?: number;
   financierId?: number;
}

export interface PostPracticeBody {
   code: string,
   name: string,
   specialtyId: number,
   medicalHierarchyId: number,
   restrictionId: number
}

export interface PatchPracticeBody {
   code?: string,
   name?: string,
   specialtyId?: number,
   medicalHierarchyId?: number,
   restrictionId?: number,
   active?: boolean
}

export interface SpecialtiesQPS {
   name?: string;
   name_like?: string;
   isSupply?: boolean;
}

export interface PracticesQPS {
   name_like?: string;
   active?: boolean;
   page?: number;
   size?: number;
   isSupply?: boolean;
}
export interface PracticesCountQPS {
   isSupply?: boolean;
}
@Injectable({
   providedIn: 'root'
})
export class NomenclatorService {

   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getPractices(qps?: PracticesQPS): Observable<HttpBaseResponse<Practice[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiNomenclatorService}${APIS.PRACTICES}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getPracticesCount(qps?: PracticesCountQPS): Observable<HttpBaseResponse<any>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiNomenclatorService}${APIS.PRACTICES}/count`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getPracticeRestrictions(): Observable<HttpBaseResponse<PracticeRestriction[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get(`${environment.apiNomenclatorService}${APIS.PRACTICES_RESTRICTIONS}`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getSpecialties(qps?: SpecialtiesQPS): Observable<Specialty[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
      return this.http
         .get<any>(`${environment.apiNomenclatorService}${APIS.SPECIALTIES}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getProvisionFees(qps?: ProvisionFeesQPS): Observable<ProvisionFee[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
      return this.http
         .get<any>(`${environment.apiNomenclatorService}${APIS.PROVISION_FEES}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postPractice(practice: PostPracticeBody): Observable<Practice> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiNomenclatorService}${APIS.PRACTICES}`, JSON.stringify(practice), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchPractice(practice: PatchPracticeBody, practiceId: number) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiNomenclatorService}${APIS.PRACTICES}/${practiceId}`,
            JSON.stringify(practice),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }


   getComplexities(): Observable<HttpBaseResponse<Complexity[]>> {

      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiNomenclatorService}${APIS.COMPLEXITIES}`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }   
}