import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { CasesStates } from 'src/app/core/enums/casesStates';
import { DatePipe } from '@angular/common';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { PostAddCaseArrangementBody } from 'src/app/core/services/cases.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { CaseArrangement } from 'src/app/shared/models/caseArrangement';
import Swal from 'sweetalert2';
import { ConsentsStates } from 'src/app/core/enums/consentsStates';
import { ArrangementsFacade } from 'src/app/abstraction/arrangements.facade';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { Consent } from 'src/app/shared/models/consent';
import { ROUTES } from 'src/app/core/enums/routes';
import { UpdatingCase } from 'src/app/shared/models/submodels/updatingCase';

// Translate state to spanish text
enum CONSENTSTATES_SPANISH {
   'No solicitado' = 1,       // No solicitado  1
	'Pendiente',               // Pendiente      2
   'Firmado',                 // Firmado        3
}

// Label for action button
enum CONSENTSTATES_BTN_ACTIVE {
   'SOLICITAR' = 1,           // 1
   'REFRESCAR',               // 2
   'VOLVER A SOLICITAR',      // 3
}

@Component({
   selector: 'app-case-main-data',
   templateUrl: './case-main-data.component.html',
   styleUrls: ['./case-main-data.component.scss']
})
export class CaseMainDataComponent implements OnInit {
   public readonly NO_ARRANGEMENT_ID = '-1';
   public readonly ARRANGEMENT_NAME = 'Arrangement_name';
   public readonly ARRANGEMENT = 'arrangementId';
   public readonly ARRANGEMENT_SEARCHING = 'arrangementSearching';
   public readonly routes = ROUTES

   @Input() case: Case;
   @Input() disabledSwitchToCase: boolean;
   @Output() onCloseCase = new EventEmitter;
   @Output() onReopenCase = new EventEmitter;
   @Output() onChangeEnabledFinancier = new EventEmitter;
   @Output() onGenerateOperationReport = new EventEmitter;
   @Output() onChangeState = new EventEmitter;
   @Output() onSwitchToCase = new EventEmitter;

   trackingElements: { key: string, value: string }[];

   @ViewChild('arrangementsListTemplate', { static: true }) arrangementsListTemplate: TemplateRef<any>;
   @ViewChild('editCase', { static: true }) editCase: TemplateRef<any>;
   @ViewChild('diagnosesTemplate', { static: true }) diagnosesTableTemplate: TemplateRef<any>;

   _dialogRef;
   displayedColumns: string[] = ['name', 'delete'];
   displayedColumnsDiagnoses: string[] = [
      'name',
      'observation',
   ];
   formAddArrangement: FormGroup;
   isLoadingGetArrangements$: Observable<boolean>;
   isLoadingGetCaseArrangements$: Observable<boolean>;
   isLoadingAddCaseArrangement$: Observable<boolean>;
   caseArrangements: CaseArrangement[];
   listCaseArrangementsString: string = '';
   _caseArrangements: Subscription;
   arrangements = [];

   // Consents
   consentsStates = ConsentsStates;
   consentStateId:number;
   CONSENTSTATES_SPANISH = CONSENTSTATES_SPANISH;
   CONSENTSTATES_BTN_ACTIVE = CONSENTSTATES_BTN_ACTIVE;
   _isLoadingGettingConsent: Subscription;
   isLoadingGettingConsent: boolean = true;
   _isLoadingUpdatingConsent: Subscription;
   isLoadingUpdatingConsent: boolean = false;

   caseEdited;
   isValidFormCase: boolean=false;
   patchCase: UpdatingCase | Case;
   isValidFormPatient: boolean=false;

   isLoadingUpdatingCase$: Observable<boolean>;

   caseId: number;
   isLoadingGetArrangements: any;

   constructor (
      private datePipe: DatePipe,
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      private casesFacade: CasesFacade,
      private arrangementsFacade: ArrangementsFacade,
      private attentionsFacade: AttentionsFacade,
   ) { }

   ngOnInit(): void {
      this.trackingElements = this.generateTrackingElements(this.case);

      this.caseId = this.case.id;

      this.suscribeToData();

      // Consent
      this._isLoadingGettingConsent = this.attentionsFacade.isLoadingGettingConsent$().subscribe( isLoadingGettingConsent => this.isLoadingGettingConsent = isLoadingGettingConsent )
      this._isLoadingUpdatingConsent = this.attentionsFacade.isLoadingUpdatingConsent$().subscribe( isLoadingUpdatingConsent => this.isLoadingUpdatingConsent = isLoadingUpdatingConsent )

      this.getConsentState();
   }

   ngOnDestroy(){
      if (!!this._caseArrangements){
         this._caseArrangements.unsubscribe();
      }
   }

   suscribeToData(){
      this.casesFacade.isLoadingGetCaseArrangements$().subscribe(loading => this.isLoadingGetArrangements = loading);
      this.isLoadingAddCaseArrangement$ = this.casesFacade.isLoadingAddCaseArrangement$();

      this.getCaseArrangements();
   }

   getCaseArrangements() {

      this._caseArrangements = this.casesFacade.getCaseArrangements$().subscribe( caseArrangements => {
         if ( caseArrangements ){
            this.caseArrangements = caseArrangements;
            this.listCaseArrangementsString = this.caseArrangements.map( ar => ar.name).join(', ');
            this.arrangements = this.arrangements.filter(arr => !this.caseArrangements.map(cArr => cArr.id).includes(arr.id))
         }
      })
   }

   onClickCloseCase() {
      this.onCloseCase.emit();
   }

   onClickReopenCase() {
      this.onReopenCase.emit();
   }

   onClickChangeEnabledFinancier() {
      this.onChangeEnabledFinancier.emit();

   }

   onClickOperationReport() {
      this.onGenerateOperationReport.emit();

   }

   onClickChangeState(){
      this.onChangeState.emit();
   }

   onClickSwitchToCase(){
      this.onSwitchToCase.emit();
   }

   generateTrackingElements(casee: Case): { key: string, value: string }[] {
      let elements = [];
      if (!!casee.states && casee.states.length > 0) {
         elements = elements.concat(casee.states.map(state => {
            const key = this.datePipe.transform(state.stateChangeDate, 'yyyy-MM-dd HH:mm') + ' hs';
            let action;
            switch (state.reason.type) {
               case CasesStates.CLOSE:
                  action = 'Cierre del caso';
                  break;
               case CasesStates.REOPEN:
                  action = 'Reapertura del caso';
                  break;
               case CasesStates.REJECTED:
                  action = 'Rechazo del caso';
                  break;
               case CasesStates.OPEN:
                  action = 'Apertura del caso';
                  break;
               default:
                  action = '';
                  break;
            }
            const reason = state.reason.text;
            const observations = state.observations;
            const value = `${action} · ${reason} · ${observations}`;
            return { key, value };
         }));
      }
      return elements;
   }

   openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
      this._dialogRef = this.dialog.open(DialogComponent, {
         disableClose: true,
         minWidth: style.minWidth,
         maxHeight: style.maxHeight,
         data: { template, title },
      });
      if (!!afterClosed)
         this._dialogRef.afterClosed().subscribe(() => afterClosed());
   }

   onClickCloseDialog() {
      this._dialogRef.close();
   }

   openArrangementsListTemplate() {
      // Load arrangement list - carga lista de convenios
      this.isLoadingGetArrangements$ = this.arrangementsFacade.isLoadingGetArrangements$();

      const financierId = this.case.financier.id;
      const caseId = this.case.id;

      // tslint:disable-next-line: deprecation
      this.arrangementsFacade.loadArrangements({ financierId, isNullFinancierId: true, active : true }).subscribe(arrangements => {
         if ( arrangements ) {
            this.arrangements = arrangements.filter(arr => !this.caseArrangements?.map(cArr => cArr.id)?.includes(arr.id));
            this.formAddArrangement = this.createAddArrangementForm();
         }
      });

      this.openDialog('Convenios asignados al caso', this.arrangementsListTemplate, { maxHeight: '95vh', minWidth: '40%' }, () => {
         // TODO: needs something?
      });
   }

   createAddArrangementForm() {
      const form = this.formBuilder.group({
         [this.ARRANGEMENT]: ['', [CustomValidators.required('Convenio requerido')]],
         [this.ARRANGEMENT_SEARCHING]: [''],
      });
      return form;
   }

   onClickAddArrangement() {
      delete this.formAddArrangement.value[this.ARRANGEMENT_SEARCHING];
      const caseId: number = this.case.id;
      const body: PostAddCaseArrangementBody = {
         arrangementId: this.formAddArrangement.value[this.ARRANGEMENT],
      };
      this.casesFacade.addCaseArrangement(body, caseId).subscribe(() => {

            const swalWithCustomizeButtons = Swal.mixin({
               customClass: {
                  confirmButton: 'btnSwalConfirm',
                  cancelButton: 'btnSwalCancel'
               },
               buttonsStyling: true
             })
            swalWithCustomizeButtons.fire({
               text: 'Se ha agregado un nuevo convenio al caso',
               icon: 'success'
            });
         });
   }

   onSelectArrangement(arrangementId, form: FormGroup) {
      console.log('selecciono arrangement: ', arrangementId);
   }


   getConsentState() {
      this.attentionsFacade.loadConsent( this.case.id ).subscribe(
         consent => {
            this.consentStateId = consent.consentState;
         })
   }


   clickConsentState() {

      if (this.consentStateId === this.consentsStates.PENDING) {
         this.getConsentState(); // Only refresh
      } else {

         let consent: Consent = {
            caseId: this.case.id,
         }

         this.attentionsFacade.setConsent( consent ).subscribe(() => {
               // Update consent success, but the post doesn't back consent state -> so, call get again
               this.getConsentState();
         });
      }
   }

   onClickDeleteArrangement( caseArrangement: CaseArrangement ) {
      this.casesFacade.deleteCaseArrangement( caseArrangement ).subscribe(() => {

         const swalWithCustomizeButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btnSwalConfirm',
            },
            buttonsStyling: true
          })
         swalWithCustomizeButtons.fire({
            text: 'Se quitó el convenio del caso',
            icon: 'success'
         });
      });
   }

   ngDestroy(): void {
      this._isLoadingGettingConsent.unsubscribe();
      this._isLoadingUpdatingConsent.unsubscribe();
   }

   // POP-UP Edit Case
   onClickEditCase() {
      // Case
      this.patchCase = this.case;
      this._dialogRef = this.openDialog( 'Datos del caso', this.editCase, { maxHeight: '90vh', minWidth: '80%' });
   }

   caseData( event ) {
      console.log("Case data from pop-up: ", event);
      this.caseEdited = event;
   }

   formCaseDataValid( event ) {
      console.log("Case is valid: ", event);
      this.isValidFormCase = event;
    }

   clickCancelEditCase() {
      this.dialog.closeAll(); // Workaround close dialog
   }

   clickConfirmEditCase() {
      this.isLoadingUpdatingCase$ = this.casesFacade.isLoadingUpdatingCase$();

      if (this.isValidFormCase) {

         console.log("CASE: ", this.caseEdited)
         const casePatch: UpdatingCase = {
                                             ...this.caseEdited,
                                             companyType: { id: this.caseEdited.companyTypeId },
                                             taxZone: { id: this.caseEdited.taxZoneId },
                                             caseParametrization: {
                                                enabledFinancier: this.caseEdited.enabledFinancier,
                                                enabledProvider: this.caseEdited.enabledProvider
                                             }
                                       }

         delete casePatch.companyTypeId;
         delete casePatch.taxZoneId;
         delete casePatch.enabledFinancier;
         delete casePatch.enabledProvider;
         delete casePatch.financierId;

         this.casesFacade.updateCase( casePatch, this.case.id ).subscribe( p => {
            this.dialog.closeAll();
         });
      }
   }

   showDiagnosesList() {
      this.openDialog(
         'Diagnósticos',
         this.diagnosesTableTemplate,
         {maxHeight: '95vh', minWidth: '40%' },
         () => { }
      );
   }
}
