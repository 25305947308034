<div class="flex-column">
    <div class="mts-4 mbs-3 ml-3">
        <div class="flex-row align-space-between-center">
            <div class="flex-row gap-15px">
                <button mat-raised-button *ngIf="isProvision(); else activeNotSelected" class="provision-scheme-buttons"
                    matRipple id="lblProvisionsSelected" [color]="'primary'" matBadge="{{this.provisionsFilteredCount}}"
                    [matBadgeHidden]="this.provisionsFilteredCount==0">
                    Prestaciones
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <ng-template #activeNotSelected>
                    <button mat-stroked-button class="provision-scheme-buttons" matRipple
                        (click)="onSelectOption('PROVISION')" id="lblProvisionsNotSelected" [color]="'primary'"
                        matBadge="{{this.provisionsFilteredCount}}" [matBadgeHidden]="this.provisionsFilteredCount==0">
                        Prestaciones
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                </ng-template>
                <button mat-raised-button
                    *ngIf="(!this.isCollapsedView && this.isSupply()) || (this.isCollapsedView && this.isSupply() && viewColumnsBillAndDebit)"
                    class="provision-scheme-buttons" matRipple id="lblSuppliesSelected" [color]="'primary'"
                    matBadge="{{this.provisionsSuppliesFilteredCount}}"
                    [matBadgeHidden]="this.provisionsSuppliesFilteredCount==0">
                    Insumos
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button mat-stroked-button class="provision-scheme-buttons" matRipple (click)="onSelectOption('SUPPLY')"
                    id="lblSuppliesNotSelected"
                    *ngIf="(!this.isCollapsedView && !this.isSupply()) || (this.isCollapsedView && !this.isSupply() && viewColumnsBillAndDebit)"
                    [color]="'primary'" matBadge="{{this.provisionsSuppliesFilteredCount}}"
                    [matBadgeHidden]="this.provisionsSuppliesFilteredCount==0">
                    Insumos
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <!-- && this.isCollapsedView -->
                <button mat-raised-button *ngIf="!viewColumnsBillAndDebit && this.isCollapsedView && this.isOrder()"
                    class="provision-scheme-buttons" matRipple id="lblOrdersSelected" [color]="'primary'"
                    matBadge="{{this.ordersFilteredCount}}" [matBadgeHidden]="this.ordersFilteredCount==0">
                    Pedidos
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button mat-stroked-button class="provision-scheme-buttons" matRipple (click)="onSelectOption('ORDER')"
                    id="lblOrdersNotSelected"
                    *ngIf="!viewColumnsBillAndDebit && this.isCollapsedView && !this.isOrder()" [color]="'primary'"
                    matBadge="{{this.ordersFilteredCount}}" [matBadgeHidden]="this.ordersFilteredCount==0">
                    Pedidos
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <span class="mt-2 text-secondary txt" (click)="removeFilters()"
                    style="white-space: nowrap; cursor: pointer;">Limpiar selección</span>
            </div>
            <!-- Actions right -->
            <div class="flex-row" *ngIf="!this.case.requested">
                <button mat-icon-button [color]="'primary'" (click)="this.onClickCalendar()" matTooltip="Agenda"
                    id="schedule_case">
                    <mat-icon
                        [svgIcon]="!this.visibleCalendarPanel ? 'calendar-enabled-primary' : 'calendar-disabled-primary'"></mat-icon>
                </button>
                <button mat-icon-button [color]="'primary'" (click)="this.onClickEvolutions()" matTooltip="Evoluciones"
                    id="evolution_case">
                    <mat-icon
                        [svgIcon]="!this.visibleEvolution ? 'ListPlus-enabled-primary' : 'ListPlus-disabled-primary'"></mat-icon>
                </button>
                <button mat-icon-button [color]="'primary'" (click)="this.onClickBilling()" matTooltip="Facturar"
                    id="bill_case">
                    <mat-icon
                        [svgIcon]="!this.viewColumnsBillAndDebit ? 'billing-enabled-primary' : 'billing-disabled-primary'"></mat-icon>
                </button>
                <ng-container *ngIf="isAdmin">
                    <button id="btn_link_to_consolidation" mat-icon-button [matTooltip]="'Link a consolidación'"
                        (click)="linkToConsolidation()" [color]="'primary'" [disabled]="!isAdmin">
                        <mat-icon [svgIcon]="'Union-enabled-primary'"></mat-icon>
                    </button>
                </ng-container>
                <button mat-icon-button [color]="'primary'"
                    [disabled]="!this.visibleCalendarPanel && !this.visibleEvolution && !this.viewColumnsBillAndDebit"
                    (click)="this.closePanel()" matTooltip="Ocultar" id="hide_case">
                    <mat-icon [svgIcon]="'exit-icon'" [color]="'primary'"
                        *ngIf="this.visibleCalendarPanel || this.visibleEvolution || this.viewColumnsBillAndDebit"></mat-icon>
                    <mat-icon [svgIcon]="'exit-disabled-icon'" [color]="'primary'"
                        *ngIf="!this.visibleCalendarPanel && !this.visibleEvolution && !this.viewColumnsBillAndDebit"></mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-100">
        <as-split [unit]="'percent'" [restrictMove]="'false'">
            <as-split-area [size]="30">
                <mat-card>
                    <ng-container *ngIf="isProvision()">
                        <app-provisions-detail [isExpanded]="!this.isCollapsedView" [case]="this.case" [supply]="false"
                            [isBilling]="this.viewColumnsBillAndDebit" [expandedElement]="this.expandedProvision"
                            [allProvisionsWithoutFilter]="this.allProvisionsWithoutFilter">
                        </app-provisions-detail>
                    </ng-container>
                    <ng-container *ngIf="isSupply()">
                        <div class="flex-column gap-20px">
                            <app-supply-list [isCollapsed]="this.isCollapsedView" [case]="this.case"
                                *ngIf="!this.isCollapsedView || (this.isCollapsedView && this.viewColumnsBillAndDebit)"
                                [isBilling]="this.viewColumnsBillAndDebit"
                                [provisionsFeeIdsToModifySupplies]="this.provisionsFeeIdsToModifySupplies"
                                (billSupplyAttentions)="billModifiedAttentions($event)"
                                [allProvisionsWithoutFilter]="this.allProvisionsWithoutFilter">
                            </app-supply-list>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isOrder() || isSupply()">
                        <div class="mts-4 mxs-3 flex-column gap-20px">
                            <app-order-list [case]="this.case" [isCollapsed]="this.isCollapsedView"
                                *ngIf="!this.isCollapsedView || (this.isCollapsedView && !this.viewColumnsBillAndDebit)"
                                [allProvisionsWithoutFilter]="this.allProvisionsWithoutFilter">
                            </app-order-list>
                        </div>
                    </ng-container>
                </mat-card>
            </as-split-area>
            <as-split-area [size]="70" *ngIf="this.visibleCalendarPanel || this.viewColumnsBillAndDebit">
                <mat-card class="mts-5 mbs-3 mx-1">
                    <app-schedule-case class="flex-100" [case]="this.case" [attentions]="this.attentionsCaseActive"
                        [viewColumnsBillAndDebit]="this.viewColumnsBillAndDebit" [selectedOption]="this.selectedOption"
                        [optionViewSelected]="calculateView()" [provisionsFilteredCount]="this.provisionsFilteredCount"
                        [provisionsSuppliesFilteredCount]="this.provisionsSuppliesFilteredCount"
                        [ordersFilteredCount]="this.ordersFilteredCount"
                        (agreementNewAttention)="this.receiveAgreementOfNewAttention($event)"
                        (refreshDataFiltered)="this.refreshViewFiltered()"
                        [provisionAgreements]="!!this.provisionAgreementsFilters?.agreements ? this.provisionAgreementsFilters.agreements : []"
                        (openModifySuppliesProvisionFee)="this.openModifyProvisionFeeSupplies($event)"
                        [billSuppliesExternally]="billModifiedSupplies"
                        [allProvisionsWithoutFilter]="this.allProvisionsWithoutFilter">
                    </app-schedule-case>
                </mat-card>
            </as-split-area>
            <as-split-area [size]="70" *ngIf="visibleEvolution">
                <mat-card class="mts-5 mbs-3 mx-3">
                    <app-evolutions [case]="this.case" [data]="this.data"></app-evolutions>
                </mat-card>
            </as-split-area>
        </as-split>
    </div>
</div>
