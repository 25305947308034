<div class="evolutions-side-bar flex-column">
    <!-- HEADER - Button close -->
    <div style="top: 0; width: 100%; position: relative;">
        <mat-toolbar class="mat-toolbar-right flex-row align-space-between-center">
            <div class="flex-row align-start-center text-primary">
                <mat-icon style="margin-right: 1rem;">text_snippet</mat-icon>
                <span class="mt-2" style="font-size: 18px;">Notas</span>
            </div>
            <button id="evolutionsSideBarComponent_btnCloseSideBar" mat-icon-button (click)="this.close()" [color]="'primary'">
                <mat-icon class="mls-2">highlight_off</mat-icon>
            </button>
        </mat-toolbar>
    </div>

    <!-- Notes -->
    <div class="example-loading-shade" *ngIf="(this.isLoadingGettingEvolutions || this.isLoadingUpdatingEvolution)">
        <app-spinner class="spinner"></app-spinner>
    </div>
    <div style="width: 100%; overflow-y: scroll;" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <div style="width: 100%; overflow-y: scroll;">
            <div *ngFor="let evolution of dataEvolutions" class="mb-5">
                <div [ngClass]="this.user.id === evolution.operatorId ? 'myself' : 'other'" style="display: flex;">
                    <!-- CARD -->
                    <div style="max-width: 75%; min-width: 200px;">
                        <!-- USER & FECHA -->
                        <div class="flex-row align-space-between-center" [ngClass]="this.user.id === evolution.operatorId ? 'start-none' : 'none-none'">
                            <p class="flex-50 subtitle-3 text-primary-darker" style="margin-bottom: 0px !important; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;">{{ evolution.operator.name }}</p>
                            <p class="mx-2" style="margin-bottom: 0px !important;">-</p>
                            <p class="flex-45 subtitle-3 text-primary-darker" style="margin-bottom: 0px !important;" [ngStyle]="{'text-align': this.user.id === evolution.operatorId ? 'end' : 'start' }">{{ evolution.date | date:'dd/MM/yyyy HH:mm' }}</p>
                        </div>
                        <!-- TEXTO -->
                        <mat-card [ngClass]="this.user.id === evolution.operatorId ? 'bg-primary' : 'bg-primary-100'" style="border-radius: 10px;">
                            <mat-card-content [ngStyle]="{'color': this.user.id === evolution.operatorId ? 'white' : 'black' }" style="overflow-wrap: anywhere;" [innerHTML]="evolution.text | safeHtml"> </mat-card-content>
                        </mat-card>
                        <!-- TEXTO SI HAY ATENCIÓN -->
                        <div *ngIf="evolution.attentionId" class="flex-column"  [ngClass]="this.user.id === evolution.operatorId ? 'start-none' : 'none-none'">
                            <div>
                                <p class="body-3" style="margin-bottom: 0px !important; max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"> Atención del día:  {{ evolution.activityDate | date:'dd/MM/yyyy HH:mm'}}</p>
                            </div>
                            <div>
                                <p class="body-3" style="margin-bottom: 0px !important;" [ngStyle]="{'text-align': this.user.id === evolution.operatorId ? 'start' : 'start' }">Prestador: {{ evolution.provider.name }} {{ evolution.provider.surname }}</p>
                            </div>
                        </div>
                        <!-- ACCIONES -->
                        <div class="flex-row align-center-start">
                            <!-- Financier -->
                            <button mat-button class="flex align-center-center nowrap" matTooltip="{{this.case.financier.name}}" (click)="this.onclickEyeFinancier( evolution )" id="evolutionsSideBarComponent_btnFinancierVisibility" class="mat-font-small-px"
                            [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'">
                                <mat-icon [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'" class="mat-icon-small-px" *ngIf="evolution.visibleFinancier">visibility</mat-icon>
                                <mat-icon [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'" class="mat-icon-small-px" *ngIf="!evolution.visibleFinancier">visibility_off</mat-icon>
                                <span style="display: block; max-width: 90px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ this.case.financier.name }}</span>
                            </button>
                            <!-- Provider -->
                            <button mat-button class="flex align-center-center nowrap" matTooltip="Prestadores" (click)="this.onclickEyeProvider( evolution )" id="evolutionsSideBarComponent_btnProviderVisibility" class="mat-font-small-px"
                                    [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'">
                                <mat-icon [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'" class="mat-icon-small-px" *ngIf="evolution.visibleProvider">visibility</mat-icon>
                                <mat-icon [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'" class="mat-icon-small-px" *ngIf="!evolution.visibleProvider">visibility_off</mat-icon>
                                <span>Prestadores</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FOOTER - Input Note -->
    <div style="bottom: 0; width: 100%;">
        <mat-divider></mat-divider>
        <ng-container *ngIf="this.isLoadingCreatingEvolution">
            <app-spinner></app-spinner>
        </ng-container>
        <ng-container *ngIf="!this.isLoadingCreatingEvolution">
            <form [formGroup]="this.addNote" (submit)="addEvolution()" class="px-2">
                <div class="flex-row align-space-between-center" style="height: 100%;">
                    <app-text-area-input [name]="this.NOTE" [form]="this.addNote" [placeholder]="'Ingrese nueva nota'" [resize]="false" class="flex-90" [height]="60"></app-text-area-input>
                    <button [disabled]="!this.addNote.valid" id="evolutionsSideBarComponent_btnAddEvolution" type="submit" matTooltip="Presione para crear la nota" style="align-self: center;" mat-icon-button class="flex-10">
                        <mat-icon class="text-primary">send</mat-icon>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
