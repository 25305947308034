import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { RestUtilitiesService } from './rest-utilities.service';
import { Operator } from 'src/app/shared/models/operator';
import { Financier } from 'src/app/shared/models/financier';
import { Pagination } from 'src/app/shared/models/pagination';
import { Media } from "src/app/shared/models/media";
import { Address } from 'src/app/shared/models/address';
import { MediaFinancier } from 'src/app/shared/models/mediaFinancier';


export interface FinanciersQPS {
   name_like?: string;
   page?: number,
   size?: number,
   active?: boolean,
   expand?: string[]
}

export interface FinanciersWithPages {
   data: Financier[],
   pagination: Pagination;
}

export interface PostFinancierBody {
   name: string;
   address?: Address;
   cuit: number;
   email?: string;
   phoneNumber?: string;
   mediaUrl?: string;
}

export interface PatchFinancierBody {
   name?: string;
   cuit?: number;
   active?: boolean;
   address?: Address;
   email?: string;
   phoneNumber?: string;
   mediaUrl?: string;
   user?: boolean;
}


@Injectable({
   providedIn: 'root',
})
export class FinanciersService {
   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getFinanciers(qps?: FinanciersQPS): Observable<FinanciersWithPages> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
      return this.http
         .get<any>(`${environment.apiFinancierServer}${APIS.FINANCIERS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getFinancierCount(): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiFinancierServer}${APIS.FINANCIERS}/count`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postFinancier(financier: PostFinancierBody): Observable<Financier> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiFinancierServer}${APIS.FINANCIERS}`, JSON.stringify(financier), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchFinancier(financier: PatchFinancierBody, financierId) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiFinancierServer}${APIS.FINANCIERS}/${financierId}`,
            JSON.stringify(financier),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getMediaByFinancierId(financierId: number): Observable<MediaFinancier> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiFinancierServer}${APIS.FINANCIERS}/${financierId}/media`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<MediaFinancier>(
            map<HttpResponse<any>, MediaFinancier>((response) => {
               return response.body.data;
            }),
         );
   }
}
