<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg"></mat-icon>
    <div class="flex-row" [formGroup]="form" class="flex-100">
        <!-- <mat-form-field class="flex-100" [formGroup]="form"> -->
        <div class="flex-100 align-start-center">
            <mat-checkbox (change)="this.onChange($event)"
                [formControlName]="name"
                [disabled]="this.disabled"
                [labelPosition]="labelPosition">
                {{placeholder || label}}
            </mat-checkbox>
            <mat-error *ngIf="
                               this.form.get(this.name)?.errors &&
                               (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
                            ">
                <b>{{ getError() }}</b>
            </mat-error>
        </div>
        <!-- </mat-form-field> -->
    </div>
</div>
