import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetaDataPractices } from 'src/app/abstraction/nomenclator.facade';
import { Practice } from 'src/app/shared/models/practice';
import { PracticeRestriction } from 'src/app/shared/models/practiceRestriction';
import { PriceList } from 'src/app/shared/models/priceList';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { Specialty } from 'src/app/shared/models/specialty';
import { PatchPracticeBody } from '../services/nomenclator.service';
import { BaseState } from './base.state';
import { Complexity } from '../../shared/models/complexity';



@Injectable({
   providedIn: 'root',
})
export class NomenclatorState extends BaseState {

   override store = {
      practices$: new BehaviorSubject<Practice[]>(null),
      priceList$: new BehaviorSubject<PriceList[]>(null),
      practicesCount$: new BehaviorSubject<number>(null),
      practiceRestrictions$: new BehaviorSubject<PracticeRestriction[]>(null),
      metaDataPractices$: new BehaviorSubject<MetaDataPractices>(null),
      loadingGetPractices$: new BehaviorSubject<boolean>(false),
      loadingGetPracticeRestrictions$: new BehaviorSubject<boolean>(false),
      loadingCreatingPractice$: new BehaviorSubject<boolean>(false),
      loadingUpdatingPractice$: new BehaviorSubject<boolean>(false),
      loadingGetPagination$: new BehaviorSubject<boolean>(false),
      provisionFees$: new BehaviorSubject<ProvisionFee[]>(null),
      loadingGetProvisionFees$: new BehaviorSubject<boolean>(false),
      loadingGetSpecialties$: new BehaviorSubject<boolean>(false),
      specialties$: new BehaviorSubject<Specialty[]>(null),

      // Complexities
      loadingGetComplexities$: new BehaviorSubject<boolean>(false),
      complexities$: new BehaviorSubject<Complexity[]>(null),
   };

   constructor() {
      super();
   }

   isLoadingGetPractices$() {
      return this.store.loadingGetPractices$.asObservable();
   }

   setLoadingGetPractices(isLoadingGetPractices: boolean) {
      this.store.loadingGetPractices$.next(isLoadingGetPractices);
   }

   isLoadingGetPagination$() {
      return this.store.loadingGetPagination$.asObservable();
   }

   setLoadingGetPagination(isLoadingGettingPagination: boolean) {
      this.store.loadingGetPagination$.next(isLoadingGettingPagination);
   }

   isLoadingCreatingPractice$() {
      return this.store.loadingCreatingPractice$.asObservable();
   }

   setLoadingCreatingPractice(isLoading: boolean) {
      this.store.loadingCreatingPractice$.next(isLoading);
   }

   isLoadingUpdatingPractice$() {
      return this.store.loadingUpdatingPractice$.asObservable();
   }

   setLoadingUpdatingPractice(isLoading: boolean) {
      this.store.loadingUpdatingPractice$.next(isLoading);
   }

   getPractices$(): Observable<Practice[]> {
      return this.store.practices$.asObservable();
   }

   getPracticesCount$(): Observable<number> {
      return this.store.practicesCount$.asObservable();
   }

   setPracticesCount(count: number) {
      this.store.practicesCount$.next(count);
   }

   getMetaDataPractices$(): Observable<MetaDataPractices> {
      return this.store.metaDataPractices$.asObservable();
   }

   setMetaDataPractices(md: MetaDataPractices) {
      this.store.metaDataPractices$.next(md);
   }

   addPractice(practice: Practice) {
      this.add<Practice>({ data: practice, storeRefAttribute: this.store.practices$ })
   }

   updatePractice(practice: PatchPracticeBody, practiceId: number) {
      this.update<Partial<Practice>>({ data: practice, dataId: practiceId, storeRefAttribute: this.store.practices$ })
   }

   setPractices(practices: Practice[]) {
      this.store.practices$.next(practices);
   }

   isLoadingGetPracticeRestrictions$() {
      return this.store.loadingGetPracticeRestrictions$.asObservable();
   }

   setLoadingGetPracticeRestrictions(isLoading: boolean) {
      this.store.loadingGetPracticeRestrictions$.next(isLoading);
   }

   setPracticeRestrictions(practiceRestrictions: PracticeRestriction[]) {
      this.store.practiceRestrictions$.next(practiceRestrictions);
   }

   getPracticeRestrictions$(): Observable<PracticeRestriction[]> {
      return this.store.practiceRestrictions$.asObservable();
   }

   isLoadingGetProvisionFees() {
      return this.store.loadingGetProvisionFees$.asObservable();
   }

   setLoadingProvisionFees(isLoadingGetProvisionFees: boolean) {
      this.store.loadingGetProvisionFees$.next(isLoadingGetProvisionFees);
   }

   getProvisionFees(): Observable<ProvisionFee[]> {
      return this.store.provisionFees$.asObservable();
   }

   setProvisionFees(provisionFees: ProvisionFee[]) {
      this.store.provisionFees$.next(provisionFees);
   }

   isLoadingGetSpecialties$() {
      return this.store.loadingGetSpecialties$.asObservable();
   }

   setLoadingGetSpecialties(isLoadingGetSpecialties: boolean) {
      this.store.loadingGetSpecialties$.next(isLoadingGetSpecialties);
   }

   getSpecialties$(): Observable<Specialty[]> {
      return this.store.specialties$.asObservable();
   }

   setSpecialties(specialties: Specialty[]) {
      this.store.specialties$.next(specialties);
   }

   isSupplyPractice(practiceId: number): boolean {
      return this.store.practices$.getValue().find(p => p.id == practiceId)?.specialty?.isSupply;
   }

   // Complexities
   setLoadingGetComplexities(isLoadingGetComplexities: boolean) {
      this.store.loadingGetComplexities$.next(isLoadingGetComplexities);
   }

   setComplexities(complexities: Complexity[]) {
      this.store.complexities$.next(complexities);
   }
}
