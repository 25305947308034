import { Component, OnInit, Input, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormElementComponent } from '../../../../shared/components/forms/form-element/form-element.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Operator, OperatorTag } from '../../../../shared/models/operator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { StringFilterByPipe } from 'src/app/shared/pipes/string-filter-by-pipe.pipe';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-operator-select',
    templateUrl: './operator-select.component.html',
    styleUrls: ['./operator-select.component.scss']
})
export class OperatorSelectComponent extends FormElementComponent implements OnInit {
    @Input() elements: Array<any>;
    @Input() defaultValue;
    @Input() noOptionText;
    @Input() spinner = false;
    @Input() nameSearching: string;
    @Input() placeholderNoValue: string;
    @Input() placeholderSearching: string;
    // Input provided that indicates if the values of the mat-options will be computed as strings or numbers
    @Input() forceNumber: string;
    // Functions provided by the parent that tells the component how to calculate the id and name of the options
    @Input() calculateId: (element) => any = (e) => e.id;
    @Input() calculateName: (element) => any = (e) => e.name + " " + e.surname;
    // Emit an event every time the select changes
    @Output() public selected: EventEmitter<any> = new EventEmitter();
    @ViewChild('operatorInfo') operatorInfo;
    _dialogRef;
    formOperator: FormGroup;
    operatorForWindow;
    operatorSelected;
    searchingSpinner = false;

    ADMISSIONS_STATES_COLORS: any[] = [
        // ALTO
        {
          color: '#FF6969',
          background_color: '#FFE9E9'
        },
        // MEDIO
        {
          color: '#FFB800',
          background_color: '#FFF2DF'
        },
        // BAJO
        {
          color: '#21A35C',
          background_color: '#DFF7EA'
        },
    ]
    public filteredValues: Observable<any>;
    searchPipe = new StringFilterByPipe();
    get searchCtrl() {
        return this.form.get(this.nameSearching);
    }

    constructor(
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private cd: ChangeDetectorRef
    ) {
    super();
    }

    // Static function that returns the appropriate function according to the "calculate" parameter
    static proxyCalculate(calculate): (element) => string {
        if (typeof calculate === 'string') {
            return element => {
                return element ? element[String(calculate)] : null;
            };
        } else {
            return calculate;
        }
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Id
    proxyCalculateId(): (element) => string {
        return OperatorSelectComponent.proxyCalculate(this.calculateId);
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Name
    proxyCalculateName(): (element) => string {
        return OperatorSelectComponent.proxyCalculate(this.calculateName);
    }

    proxyCalculateTag(element : OperatorTag) : string {
        return !!element ? element.tag?.name : null;
    }

    override ngOnInit() {
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue);
        }
        if (this.forceNumber) {
            // Subscribe at any change in the select form control and everytime a new option is
            // selected, convert the value to Number if it isn't
            this.form.get(this.name)?.valueChanges.subscribe(value => {
                if (typeof value === 'string') {
                    this.form.get(this.name)?.setValue(Number(value));
                }
            });
        }
        this.filteredValues = (this.searchCtrl?.valueChanges || of())
            .pipe(
                tap(_ => this.searchingSpinner = true),
                startWith(''),
                map(country => country ? this.searchPipe.transform(this.elements, country, this.proxyCalculateName()) : this.elements?.slice()),
                tap(_ => this.searchingSpinner = false)
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue);
        }
        this.filteredValues = (this.searchCtrl?.valueChanges || of() )
            .pipe(
                tap(_ => this.searchingSpinner = true),
                startWith(''),
                map(country => country ? this.searchPipe.transform(this.elements, country, this.proxyCalculateName()) : this.elements?.slice()),
                tap(_ => this.searchingSpinner = false)
            );
    }

    calculateSelected(id) {
        let elementSelected = null;
        if (this.elements) {
            elementSelected = this.elements.find(
                element =>
                    this.proxyCalculateId()(element) ===
                    (this.forceNumber ? Number(id) : id)
            );
        }
        return elementSelected;
    }

    change(event:any) {
        // Called everytime a new value is selected
        this.operatorSelected = this.elements.find(e => e.id == event.value);
        this.selected.emit(event.value.name);
    }

    closeDialog(){
        this._dialogRef.close();
    }

    createDialog(operator:Operator){
        //this.operatorSelected = operator;
        this.operatorForWindow = operator;
        this.openDialog('', this.operatorInfo, { maxHeight: '95vh', minWidth: '30%' }, () => {
        //hacer algo cuando se cierra o nada
        });
    }

    openDialog(title: string, template:NgTemplateOutlet, style: { minWidth: string, maxHeight: string }, afterClosed?:any): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            panelClass: 'custom-modalbox',
            data: { template, title },
        })
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    createToolTipText(tags:any){
        let lines : string[] = tags.map((tag:any) => tag.tag.name);
        let toolTip : string = this.transform(lines);
        return toolTip;
    }

    transform(lines: string[]): string {

        let list: string = '';

        lines.forEach(line => {
          list += '• ' + line + '\n';
        });
        return list;
      }
}
