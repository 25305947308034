import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { concatMap, finalize, tap } from "rxjs/operators";
import { AgreementsBySpecialtyQPS, BudgetBody, CreationalAgreement, PatchProvisionBody, PatchProvisionFeeBody, PostAuthorizationBody, PostProvisionBody, PostProvisionFeeBody, PostSuppliesBody, ProvisionQPS, ProvisionsService, QuoteBody } from "../core/services/provisions.service";
import { ProvisionsState } from "../core/states/provisions.state";
import { Agreement } from "../shared/models/agreement";
import { Authorization } from "../shared/models/authorization";
import { Provision } from "../shared/models/provision";
import { ProvisionAgreementFilters } from "../shared/models/ProvisionAgreementFilters";
import { ProvisionFee } from "../shared/models/provisionFee";
import { AttentionsState } from '../core/states/attentions.state';
import { Provider } from "../shared/models/provider";

export interface UpdatingAgreement {

    providerFee?: number;
    fromDate?: Date;
    toDate?: Date;
    edit?: boolean;
}

export const AUTHORIZATION_COLORS = {
    'AUTORIZADO': "#609E50",
    'PRÓXIMO A VENCER': "#EA750B",
    'VENCIDO': "#D02700",
    'NO AUTORIZADO': "#7A0060"
}

export const AUTHORIZATION_BACKGROUND_COLORS = {
    'AUTORIZADO': "#EAFEE5",
    'PRÓXIMO A VENCER': "#FFF4EB",
    'VENCIDO': "#FFE9E9",
    'NO AUTORIZADO': "#FFD9F7"
}

@Injectable({
    providedIn: 'root',
})
export class ProvisionsFacade {

    constructor(
        private provisionsService: ProvisionsService,
        private provisionsState: ProvisionsState,
        private attentionState: AttentionsState,
    ) { }

    // GETTERS/SETTERS
    setFiltersOnProvisions(filters: ProvisionAgreementFilters) {
        console.log('setFiltersOnProvisions: ', filters)
        this.provisionsState.setFiltersOnProvisions(filters);
    }

    getFiltersOnProvisions$() {
        return this.provisionsState.getFiltersOnProvisions$();
    }

    getFiltersOnProvisions(): ProvisionAgreementFilters {
        return this.provisionsState.getFiltersOnProvisions();
    }

    isLoadingGetProvisions$() {
        return this.provisionsState.isLoadingGetProvisions$();
    }

    isLoadingCreatingAgreement$(): Observable<boolean> {
        return this.provisionsState.isLoadingCreatingAgreement$();
    }

    isLoadingUpdatingAgreement$(): Observable<boolean> {
        return this.provisionsState.isLoadingUpdatingAgreement$();
    }

    isLoadingUpdatingProvision$(): Observable<boolean> {
        return this.provisionsState.isLoadingUpdatingProvision$();
    }

    isLoadingCreatingProvision$(): Observable<boolean> {
        return this.provisionsState.isLoadingCreatingProvision$();
    }

    setAllProvisionsCase(provisions: Provision[]) {
        this.provisionsState.setAllProvisionsCase(provisions);
    }

    getAllProvisionsCase$() {
        return this.provisionsState.getAllProvisionsCase$();
    }

    getProvisionsDisplayedOnScreen$() {
        return this.provisionsState.getProvisionsDisplayedOnScreen$();
    }

    setProvisionsDisplayedOnScreen(provisionsDisplayedOnScreen?: Provision[]) {
        this.provisionsState.setProvisionsDisplayedOnScreen(provisionsDisplayedOnScreen);
    }

    isLoadingGetAuthorizations$(): Observable<boolean> {
        return this.provisionsState.isLoadingGetAuthorizations$();
    }

    isLoadingGetAgreementsBySpecialtyId$(): Observable<boolean> {
        return this.provisionsState.isLoadingGetAgreementsBySpecialtyId();
    }

    // LOAD - GET

    loadProvisionsForCase(caseId: number, provisionQPS?: ProvisionQPS) {
        const promise: Promise<Provision[]> = new Promise((res, rej) => {

            this.provisionsState.setLoadingGetProvisions(true);

            this.provisionsService.getProvisions(caseId, provisionQPS).pipe(
                finalize(() => this.provisionsState.setLoadingGetProvisions(false))
            )
                .subscribe({
                    next: provisions => {
                        this.provisionsState.setProvisionsDisplayedOnScreen(provisions.filter(prov => !!prov.provisionFees));
                        res(provisions.filter(prov => !!prov.provisionFees));
                    },
                    error: e => { rej(e) },
                })
        });
        return from(promise);
    }

    filterProvisionsOnDateFilters(provisions: Provision[]) {
        this.provisionsState.filterProvisionsToDisplayedOnScreen(provisions);
    }

    loadAllProvisionsForCase(caseId: number) {
        this.provisionsState.setLoadingGetProvisions(true);
        const promise: Promise<Provision[]> = new Promise((res, rej) => {

            this.provisionsService.getProvisions(caseId)
                .pipe(
                    finalize(() => this.provisionsState.setLoadingGetProvisions(false))
                )
                .subscribe({
                    next: provisions => {
                        const provisionsFiltered = provisions.filter(prov => !!prov.provisionFees); // Provision without provisionFees
                        this.provisionsState.setAllProvisionsCase(provisionsFiltered);
                        res(provisionsFiltered);
                    },
                    error: e => { rej(e) }
                })
        });
        return from(promise);
    }



    loadProvision(caseId: number, provisionId: number): Observable<Provision> {
        console.log('Loading request GET provison');
        const promise: Promise<Provision> = new Promise((res, rej) => {
            this.provisionsService.getProvision(caseId, provisionId)
                .pipe(
                    tap(prov => this.provisionsState.updateProvision(prov))
                )
                .subscribe({
                    next: provision => { console.log("Provision reloaded: ", provision); res(provision) },
                    error: (e) => { rej(e) },
                })
        });
        return from(promise);
    }

    loadAuthorizations(caseId: number, provisionId: number): Observable<Authorization[]> {

        const promise: Promise<Authorization[]> = new Promise((res, rej) => {

            this.provisionsState.setLoadingGetAuthorizations(true);

            this.provisionsService.getAuthorizations(caseId, provisionId).pipe(
                finalize(() => this.provisionsState.setLoadingGetAuthorizations(false))
            )
                .subscribe({
                    next: authorizations => { console.log("Authorizations for provisionId loaded: ", authorizations); res(authorizations); },
                    error: e => { rej(e) },
                })
        });
        return from(promise);
    }

    // INSERT

    addProvision(provision: PostProvisionBody, caseId: number): Observable<Provision> {
        this.provisionsState.setLoadingCreatingProvision(true);
        // Mapper from CreationalProvision to PostProvisionBody must be here
        const promise: Promise<Provision> = new Promise((res, rej) => {
            this.provisionsService.postProvision(provision, caseId).pipe(
                finalize(() => this.provisionsState.setLoadingCreatingProvision(false))
            ).subscribe({
                next: newProvision => { this.provisionsState.addProvision(newProvision, caseId); res(newProvision); },
                error: e => { this.provisionsState.setErrorCreatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    addProvisionFee(provisionFee: PostProvisionFeeBody, caseId: number, provisionId: number): Observable<ProvisionFee> {

        this.provisionsState.setLoadingCreatingProvision(true);

        const promise: Promise<ProvisionFee> = new Promise((res, rej) => {
            this.provisionsService.postProvisionFee(provisionFee).pipe(
                finalize(() => this.provisionsState.setLoadingCreatingProvision(false))
            ).subscribe({
                next: newProvisionFee => {
                    this.provisionsState.addProvisionFee(newProvisionFee, provisionId);
                    res(newProvisionFee);
                },
                error: e => { this.provisionsState.setErrorCreatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    addAuthorization(authorization: PostAuthorizationBody, provisionId: number, caseId: number): Observable<Authorization> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        let newAuthorization: Authorization;
        const promise: Promise<Authorization> = new Promise((res, rej) => {
            this.provisionsService.postAuthorization(authorization, caseId, provisionId).pipe(
                tap(_newAuthorization => newAuthorization = _newAuthorization),
                concatMap(_ => this.provisionsService.getProvision(caseId, provisionId)),
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: newProvision => {
                    this.provisionsState.setProvision(newProvision);
                    res(newAuthorization);
                },
                error: e => { rej(e); },
            });
        });
        return from(promise);
    }

    addAuthorizationToProvisions(authorization: PostAuthorizationBody, provisionFees: ProvisionFee[], mediaURLs: string[]): Observable<Provision[]> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        const promise: Promise<Provision[]> = new Promise((res, rej) => {

            const authToProvisions = { authorization: authorization, provisionFeeIds: provisionFees.map(prov => prov.id), mediaUrls: mediaURLs } // Compose

            console.log(authToProvisions);
            console.log(JSON.stringify(authToProvisions));

            this.provisionsService.postAuthorizationToProvisions(authToProvisions).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: provisionsResponse => {
                    provisionsResponse.forEach(prov => {
                        this.provisionsState.addAuthorizationToProvisionFees(prov.provisionFees, prov.id);
                    })
                    res(provisionsResponse);
                },
                error: e => { rej(e); },
            });
        });
        return from(promise);
    }

    addAgreement(agreement: CreationalAgreement, provisionId: number, caseId: number): any | Observable<Agreement> {
        this.provisionsState.setLoadingCreatingAgreement(true);
        // Mapper from CreationalCase to PostCaseBody must be here
        const promise: Promise<Agreement> = new Promise((res, rej) => {
            this.provisionsService.postAgreement(agreement, provisionId, caseId).pipe(
                finalize(() => this.provisionsState.setLoadingCreatingAgreement(false))
            ).subscribe({
                next: agreement => {
                    this.provisionsState.addAgreement(agreement, provisionId);
                    res(agreement)
                },
                error: (e) => { rej(e) }
            });
        });
        return from(promise);
    }

    loadAgreementBySpecialtyId(qps:AgreementsBySpecialtyQPS): Observable<Agreement[]> {
                // this.provisionsState.getAgreementsBySpecialtyId()
        // Mapper from CreationalCase to PostCaseBody must be here
        this.provisionsState.setLoadingGetAgreementsBySpecialtyId(true);
        const promise: Promise<Agreement[]> = new Promise((res, rej) => {
            this.provisionsService.getAgreementsBySpecialtyId(qps).pipe(
                finalize(() => [this.provisionsState.setLoadingCreatingAgreement(false), this.provisionsState.setLoadingGetAgreementsBySpecialtyId(false)])
            ).subscribe({
                next: agreements => {
                    this.provisionsState.setAgreementsBySpecialtyId(agreements);
                    res(agreements)
                },
                error: (e) => { rej(e) }
            });
        });
        return from(promise);
    }

    // UPDATE
    updateProvisionFeePut(provisionFee: Partial<PostProvisionFeeBody>, caseId: number, provisionId: number, provisionFeeId: number): Observable<ProvisionFee> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        let newProvisionFee: ProvisionFee;
        // Mapper from CreationalProvision to PostProvisionBody must be here
        const promise: Promise<ProvisionFee> = new Promise((res, rej) => {
            this.provisionsService.putProvisionFee(provisionFee, caseId, provisionId, provisionFeeId).pipe(
                tap(_newProvisionFee => newProvisionFee = _newProvisionFee),
                concatMap(_ => this.provisionsService.getProvision(caseId, provisionId)),
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: newProvision => {
                    this.provisionsState.setProvision(newProvision);
                    res(newProvisionFee);
                },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    updateProvisionFeePatch(provisionFee: Partial<PostProvisionFeeBody>, caseId: number, provisionId: number, provisionFeeId: number): Observable<ProvisionFee> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        let newProvisionFee: ProvisionFee;
        // Mapper from CreationalProvision to PostProvisionBody must be here
        const promise: Promise<ProvisionFee> = new Promise((res, rej) => {
            this.provisionsService.patchProvisionFee(provisionFee, provisionFeeId).pipe(
                tap(_newProvisionFee => newProvisionFee = _newProvisionFee),
                concatMap(_ => this.provisionsService.getProvision(caseId, provisionId)),
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: newProvision => {
                    this.provisionsState.setProvision(newProvision);
                    res(newProvisionFee);
                },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    updateProvision(provision: PatchProvisionBody, caseId: number, provisionId: number): Observable<Provision> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Provision> = new Promise((res, rej) => {
            this.provisionsService.patchProvision(provision, caseId, provisionId).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: newProvision => { this.provisionsState.updateProvision(newProvision); res(newProvision); },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    updateAgreement(agreement: UpdatingAgreement, caseId: number, provisionId: number, agreementId: number): Observable<Agreement> {
        this.provisionsState.setLoadingUpdatingAgreement(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Agreement> = new Promise((res, rej) => {
            this.provisionsService.postAgreementToUpdate(agreement, caseId, provisionId, agreementId).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingAgreement(false))
            ).subscribe({
                next: newAgreement => {
                    if (!!agreement.edit) {
                        this.provisionsState.updateAgreement(newAgreement, provisionId, agreementId);
                    } else {
                        this.provisionsState.addAgreementAtPatch(newAgreement, provisionId, agreementId);
                    }
                    res(newAgreement);
                },
                error: e => { rej(e); },
            });
        });
        return from(promise);
    }

    // DELETE
    deleteProvision(caseId: number, provisionId: number): Observable<Provision> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Provision> = new Promise((res, rej) => {
            this.provisionsService.deleteProvision(caseId, provisionId).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: deletedProvision => { this.provisionsState.deleteProvision(caseId); res(deletedProvision); },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    deleteProvisionFee(caseId: number, provisionId: number, provisionFeeId: number): Observable<ProvisionFee> {

        this.provisionsState.setLoadingUpdatingProvision(true);

        const promise: Promise<ProvisionFee> = new Promise((res, rej) => {
            this.provisionsService.deleteProvisionFee(provisionFeeId).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: deletedProvisionFee => { this.provisionsState.deleteProvisionFee(provisionId, provisionFeeId); res(deletedProvisionFee); },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    deleteSupplies(provFees: ProvisionFee[]): Observable<ProvisionFee[]> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        const promise: Promise<ProvisionFee[]> = new Promise((res, rej) => {
            this.provisionsService.deleteSupplies(provFees).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: deletedProvFees => { provFees.forEach(provFee => this.provisionsState.deleteProvisionFee(provFee.provisionId, provFee.id)); res(provFees) },
                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); }
            })
        })
        return from(promise)
    }

    deleteProvisions(provisions: number[]): Observable<Provision[]> {

        this.provisionsState.setLoadingUpdatingProvision(true);

        const promise: Promise<Provision[]> = new Promise((res, rej) => {
            this.provisionsService.deleteProvisions(provisions).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false))
            ).subscribe({
                next: provisionsResponse => {
                    this.provisionsState.deleteProvisions(provisionsResponse);
                    res(provisionsResponse);
                },

                error: e => { this.provisionsState.setErrorUpdatingProvision(true); rej(e); },
            });
        });
        return from(promise);
    }

    deleteAgreement(caseId: number, provisionId: number, agreementId: number): Observable<Agreement> {
        const promise: Promise<Agreement> = new Promise((res, rej) => {
            this.provisionsService.deleteAgreement(caseId, provisionId, agreementId).pipe(
            ).subscribe({
                next: deletedAgreement => {
                    this.provisionsState.deleteAgreement(provisionId, agreementId);
                    this.attentionState.deleteAttentionsByAgreementId(agreementId); // Refresh attention states
                    res(deletedAgreement);
                },

                error: e => { rej(e); },
            });
        });
        return from(promise);
    }

    // Supplies
    addSupplyProvisions(provisions: PostSuppliesBody, caseId: number): Observable<Provision[]> {
        this.provisionsState.setLoadingCreatingProvision(true);
        const promise: Promise<Provision[]> = new Promise((res, rej) => {
            this.provisionsService.postSupplyProvisions(provisions, caseId).pipe(
                finalize(() => this.provisionsState.setLoadingCreatingProvision(false))
            ).subscribe({
                next: provs => {
                    provs.forEach(provision => {
                        this.provisionsState.addProvision(provision, caseId);
                    });
                    res(provs);
                },

                error: e => {
                    this.provisionsState.setErrorCreatingProvision(true);
                    rej(e)
                }
            });
        });
        return from(promise);
    }

    // Update supplies provisionFees
    updateProvisionFees(provisionFees: PatchProvisionFeeBody[], caseId: number, provisions): Observable<ProvisionFee[]> {
        this.provisionsState.setLoadingUpdatingProvision(true);
        const promise: Promise<ProvisionFee[]> = new Promise((res, rej) => {
            this.provisionsService.patchProvisionFees(provisionFees, caseId).pipe(
                finalize(() => this.provisionsState.setLoadingUpdatingProvision(false)),
                tap(res => this.provisionsState.updateProvisionFees(res.provisionFees, provisions, res.agreements)),
            ).subscribe({
                next: response => {
                    res(response.provisionFees);
                },
                error: e => {
                    this.provisionsState.setErrorUpdatingProvision(true);
                    rej(e);
                }
            });
        });
        return from(promise);
    }

    generateBudgetPDF(budgetBody: BudgetBody) {
        const promise = new Promise((res, rej) => {
            this.provisionsService.getBudgetPDF(budgetBody).subscribe({
                next: response => { res(response) },
                error: (e) => console.log(e)
            })
        })
        return from(promise)
    }

    generateQuotePDF(quoteBody: QuoteBody) {
        const promise = new Promise((res, rej) => {
            this.provisionsService.getSuppliesQuotePDF(quoteBody).subscribe({
                next: response => { res(response) },
                error: (e) => console.log(e)
            })
        })
        return from(promise)
    }

    // TODO: make colors visible for all
    assignColorsToAgreements() {
    }
}
