<div class="flex-row">
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <input [errorStateMatcher]="this.errorMatcher ? this.errorMatcher.errorStateMatcher : null" matInput
            [formControlName]="name" id="{{ name }}" name="{{ name }}" [type]="hide ? 'password' : 'text'"
            placeholder="{{ placeholder }}" [readonly]="readonly" [attr.disabled]="disabled ? '' : null"
            maxlength="{{ maxLength }}" />
        <button tabIndex="-1" type="button" mat-icon-button matSuffix (click)="hide = !hide"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon [color]="'primary'">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="
            this.form.get(this.name)?.errors &&
            (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
         ">
                {{ getError() }}
        </mat-error>
        <mat-error *ngIf="
            !this.form.get(this.name)?.errors &&
            this.form.errors &&
            this.errorMatcher &&
            this.errorMatcher.errorNameMatcher &&
            (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
         ">
            {{ this.form.errors[this.errorMatcher.errorNameMatcher] }}
        </mat-error>
    </mat-form-field>
</div>
