import { Component, OnInit, Inject } from '@angular/core';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { AuthenticationService } from '../../../../core/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../../shared/models/user';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertErrorComponent } from 'src/app/shared/components/utils/alert-error/alert-error.component';
import { AttentionsService, PatchAttentionBody } from 'src/app/core/services/attentions.service';
import { tap } from 'rxjs/operators';
import { Attention } from '../../../../shared/models/attention';
import { AttentionsFacade } from '../../../../abstraction/attentions.facade';


@Component({
  selector: 'app-dialog-audit-states-attention',
  templateUrl: './dialog-audit-states-attention.component.html',
  styleUrls: ['./dialog-audit-states-attention.component.scss']
})
export class DialogAuditStatesAttentionComponent implements OnInit {

  public revertState: boolean;
  public reloadAt: boolean = false;

  userSubscription: Subscription;
  user: User;

  attentions$: Observable<Attention[]>;
  _attentions: Subscription;

  auditAttentions$: Observable<AuditStatesAttention>;
  _auditAttentions: Subscription;

  trackingElements: {
    key: string,
    value: string,
    details: string
  }[] = [];

  constructor(
    private datePipe: DatePipe,
    private titlePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<DialogAuditStatesAttentionComponent>,
    private authService: AuthenticationService,
    private _alertError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { auditStatesAttention: AuditStatesAttention },
    public attentionsService: AttentionsService,
    private attentionsFacade: AttentionsFacade) { }


  ngOnInit(): void {

    this.mappingDatatoTrackingElements(this.data.auditStatesAttention.audit);

    console.log("TrackingElements: ", this.trackingElements);

    // Show button for revert state : BOOLEAN
    this.revertState = this.authService.isAdmin() && this.attentionsService.isLastStateidBillable(this.data.auditStatesAttention.lastStateId);
  }


  mappingDatatoTrackingElements(data: any ){

    console.log( 'Data for tracking detail: ', data );

    this.trackingElements = data.map(audit => {
      return {
        key: this.datePipe.transform(audit.date, 'yyyy-MM-dd HH:mm') + ' hs',
        value: `${audit.state.name}`,
        details: `${this.titlePipe.transform(!!audit.user.role ? audit.user.role + ':' : '')} ${this.titlePipe.transform(audit.user.surname || '')} ${this.titlePipe.transform(audit.user.name || '')}`
      }
    })
  }


  // getRoleName(role: ROLES) { // TODO: si en nuevas versiones no falla, eliminar [version package 1.16.11]
  //   const roleResult = Object.entries(ROLES).filter(obj => {
  //     console.log("TRASLAPPING: ", obj)
  //     return obj[1] == role ? true : false
  //   });
  //   return !!roleResult && roleResult.length > 0 ? roleResult[0][0] : ''
  // }


  onCancelClick() {
    this.dialogRef.close( { reload : this.reloadAt } ); // Return true if change state else false
  }


  revertStateBillableToPerformed() {
    console.log("Revertir estado!");

    // Definition Observervable
    this.auditAttentions$ = this.attentionsService.getAuditStatesAttention( this.data.auditStatesAttention.attentionId );

    const swalWithCustomizeButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btnSwalConfirm',
        cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    swalWithCustomizeButtons.fire({
      title: "Atención",
      text: "¿Desea confirmar la operación?",
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Escriba un motivo... ',
      inputValidator: (value) => {
          if (value.length < 10) {
              return 'El motivo debe tener más de 10 caracteres.';
          }
          return undefined
      },
      showCancelButton: true,
      confirmButtonText: 'CONFIRMAR',
      cancelButtonText: 'CANCELAR',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        // Patchattention for update state from id=5 (BILLABLE - FACTURABLE) to id=3 (PERFORMED - REALIZADA)
        console.log( 'Audit attentionId:', this.data.auditStatesAttention.attentionId );
        console.log( 'Audit state:', this.data.auditStatesAttention.audit );
        console.log( 'Audit caseId:', this.data.auditStatesAttention.caseId );

        const attentionPatch: PatchAttentionBody[] = [{
          "id": this.data.auditStatesAttention.attentionId,
          "caseId": this.data.auditStatesAttention.caseId,
          "state": {
            "id": 3
          },
          revertStateReason: result.value,
        }];

        this.attentions$ = this.attentionsFacade.patchAttentions( attentionPatch );
        this._attentions = this.attentions$.pipe(
          tap( _ => this._auditAttentions = this.auditAttentions$.pipe(tap( a => console.log("New audit attentions: ", a ))).subscribe( audits => {
                      this.mappingDatatoTrackingElements(audits.audit); // Map new records to trackingElements
                      }))
        ).subscribe( _ => {
                            swalWithCustomizeButtons.fire({
                              title: 'La operación fue realizada con éxito',
                              icon: 'success',
                              });
                              this.revertState = false;
                              this.reloadAttentions();
                            },
                    err => {
                        console.log('error', err);
                        this._alertError.openFromComponent(AlertErrorComponent, {
                          duration: 5000,
                          data: ( err.error.message )
                    })}
        )
      }
    })
  }


  reloadAttentions() {
    this.reloadAt = true;
  }


  ngOnDestroy(): void {
    this._auditAttentions ? this._auditAttentions.unsubscribe() : null;
    this._attentions ? this._attentions.unsubscribe() : null;
  }
}
