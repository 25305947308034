import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
    @Input() username: string = '';
    @Input() sideNavState: boolean = true;
    @Input() menuTriggerFor: any = null;
    @Input() tooltip: string = '';
    @Input() value: string = '';
    @Input() size: number = 30;
    @Input() textSizeRatio: number = 1;
    @Input() backgroundColor: string = '#ffffff';
    @Input() initialsSize: number = 0;
    @Input() customClass: string = 'avatar my-avatar mrs-3';

    get fontSize(): string {
        return `calc(${this.size}px / ${this.textSizeRatio})`;
      }

    get initials(): string {
        if (this.initialsSize > 0 && this.username) {
            const initials = this.username.split(' ').map(word => word.charAt(0).toUpperCase()).slice(0, this.initialsSize).join('');
            return initials;
        }
        return this.username.charAt(0).toUpperCase();
    }

}
