<div class="flex-column">
    <div class="flex-column bg-primary-lighter p-1">
        <span class="text-primary-darker-h3 ml-1 mbs-1">Evolucionar atención - {{this.data.attention.patient.surname + '
            ' + this.data.attention.patient.name}}</span>
        <span class="text-secondary ml-1">{{
            this.data.attention.practice?.name +
            ' - ' +
            (this.data.attention.fromScheduled | date: 'dd/MM/yyyy hh:mm') + 'hs' +
            ((!!this.data.attention?.toScheduled) ?' a ' : '') +
            (!!this.data.attention?.toScheduled ? (this.data.attention?.toScheduled | date: 'dd/MM/yyyy hh:mm')+ 'hs' :
            '' )
            }}</span>
    </div>
    <div class="flex-column p-24">
        <span class="text-primary-darker-h4 mbs-3">
            Datos de la evolución
        </span>
        <div class="flex-row flex-100">
            <div class="flex-column flex-25">
                <span class="subtitle-2">Tipo de evolución*</span>
                <app-select-input [name]="this.EVOLUTION_TYPE" [form]="this.evolutionForm"
                    [placeholder]="'Seleccionar...'" [elements]="this.evolutionCategories"
                    [calculateId]="this.calculateCategoryOfEvolutionId"
                    [calculateName]="this.calculateCategoryOfEvolutionName" (selected)="onSelectCategory($event)">
                </app-select-input>
            </div>
            <div class="flex-column ml-5 flex">
                <span class="subtitle-2">Visibilidad de la evolución</span>
                <div *ngIf="this.PRACTICE_QUALITY_CONTROL_ID.toString() != this.data.attention.practice.id.toString()"
                    class="flex-row wrap gap-5percent flex-100">
                    <mat-checkbox id="visibleFinancier" (change)="financierVisibility = !financierVisibility"
                        [checked]="this.financierVisibility">
                        {{ this.data.attention.financier.name }}
                    </mat-checkbox>
                    <mat-checkbox id="visibleProvider" (change)="providersVisibility = !providersVisibility"
                        [checked]="this.providersVisibility">
                        Prestadores
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div>
            <form *ngIf="this.evolutionForm" [formGroup]="evolutionForm">
                <div class="flex-row space-between-center">
                    <!-- Textarea -->
                    <div class="flex-45">
                        <app-text-area-input [clearable]="false" [form]="this.evolutionForm"
                            [name]="this.EVOLUTION_TEXT" [placeholder]="'Ingrese el texto de la evolución'"
                            (change)="onChangeDescription($event)">
                        </app-text-area-input>
                    </div>
                    <!-- Filepond -->
                    <div class="flex-45">
                        <file-pond #myPond [options]="pondOptions"
                            (oninit)="pondHandleInit()"
                            (onaddfile)="pondHandleAddFile($event)"
                            (onremovefile)="pondHandleRemoveFile($event)"
                            (onaddfilestart)="pondHandleProcessFile($event)">
                        </file-pond>
                    </div>
                </div>
            </form>
        </div>
        <!-- FIN Paso #1 -->
        <!-- INICIO Paso #2 -> Indique en qué estado queda la atención (Sólo evoluciones no normales) -->
        <div class="flex-column">
            <span class="text-primary-darker-h4 mbs-3">
                Estado final de la atención
            </span>
            <div class="flex-100">
                <form *ngIf="attentionStateForm && !!this.data.attention" [formGroup]="attentionStateForm" class="flex-row  flex-100">
                    <div class="flex-column flex-20">
                        <span class="subtitle-2">Fecha de realización</span>
                        <app-date-picker [name]="this.DATE_IN_REAL" [form]="this.attentionStateForm" class="mb-0"
                            (onDateChange)="setHourDifference()">
                        </app-date-picker>
                        <app-date-picker [name]="this.DATE_OUT_REAL" [form]="this.attentionStateForm"
                            *ngIf="this.data.attention?.practice?.restriction?.requiredToDate"
                            (onDateChange)="setHourDifference()">
                        </app-date-picker>
                    </div>
                    <div class="flex-column flex-30 ml-2">
                        <span class="subtitle-2">Hora de realización</span>
                        <app-text-input [form]="this.attentionStateForm" [selectAllOnClick]="true"
                            (changes)="validateTimeValid()" [name]="this.REAL_TIME_FROM"
                            (keydown.enter)="$event.preventDefault()">
                        </app-text-input>
                        <!-- Hour OUT -->

                    </div>
                    <div class="flex-column flex-20 ml-2" *ngIf="this.durationNeeded">
                        <span class="subtitle-2">Duración*</span>
                        <app-number-input [form]="this.attentionStateForm"
                            [name]="this.QUANTITY"
                            (change)="this.durationChanged()"
                            [money]='false' [placeholder]="'Horas'" [minValue]="0">
                        </app-number-input>
                        <i style="color:#d99e14;" class="subtitle-3" *ngIf="this.showAlertMsg">La duración de la
                            atención ha sido modificada</i>
                    </div>
                    <div class="flex-column ml-2 flex-40" style="width: 40%;">
                        <span class="subtitle-2">Estado de la atención</span>
                        <app-select-input [name]="this.STATE_ATTENTION" [form]="this.attentionStateForm"
                            [placeholder]="'Seleccionar'"
                            [hint]="'Estado actual de la atención: '+this.data.attention.state.name"
                            [elements]="this.attentionStates" [calculateId]="this.calculateId"
                            [calculateName]="this.calculateName"
                            [disabled]="this.data.attention.state.id == ATTENTION_STATES.INFORMADA">
                        </app-select-input>
                    </div>
                </form>
            </div>
            <!-- FIN Paso #2-->
        </div>
        <div class="flex-row align-space-around-stretch mts-2">
            <button mat-stroked-button [color]="'primary'" [disabled]="this.loadingConfirm" (click)="onCancelClick()">
                CANCELAR
            </button>
            <button mat-flat-button [color]="'primary'"
                [disabled]="!this.evolutionForm.valid || !this.attentionStateForm.valid || this.loadingConfirm || this.loadingAddFile"
                (click)="onConfirmClick()">
                <span *ngIf="!this.loadingConfirm; else loadingConfirmIcon">
                    CONFIRMAR
                </span>
                <ng-template #loadingConfirmIcon>
                    <mat-icon>
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </ng-template>
            </button>
        </div>
    </div>
</div>
