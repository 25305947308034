import { Injectable } from '@angular/core';
import { Pagination } from '../shared/models/pagination';
import { from } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TagsState } from '../core/states/tags.state';
import { TagsService, TagsQPS } from '../core/services/tags.service';
import { Tag } from 'src/app/shared/models/tags';

export interface MetaDataTags extends TagsQPS, Pagination {
}

@Injectable({
    providedIn: 'root',
})
export class TagsFacade {

    constructor(
        private tagsState: TagsState,
        private tagsService: TagsService
    ) { }

    // LOAD
    loadTags(qps?: TagsQPS) {

        this.tagsState.setLoadingGetTags(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.tagsService.getTags(qps).pipe(
                tap(body => this.tagsState.setTags(body.data)),
                tap(() => this.tagsState.setLoadingGetTags(false)),
                tap(() => this.tagsState.setLoadingGettingPagination(false)),
                tap((body) => {
                    this.tagsState.setMetaDataTags({ ...qps, ...body.pagination });
                }),
                tap((body) => console.log('request finished. Order: ', body.data, 'Pagination :', body.pagination)),
            ).subscribe({
                next: response => resolve(response.data),

                error: e => {
                    this.tagsState.setLoadingGetTags(false);
                    this.tagsState.setLoadingGettingPagination(false);
                    reject(e)
                }
            })
        })
        return from(promise);
    }

    // COUNT
    loadTagsCount(qps: TagsQPS) {

        const promise: Promise<any> = new Promise((res, rej) => {

            this.tagsService.getTagsCount(qps).pipe(
                tap(data => console.log("Data tags: " + data)),
                tap(data => this.tagsState.setTagsCount(data)),
            ).subscribe({
                next: response => res(response),
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }

    // ADD - CREATE
    addTag(tag: Tag): any | Observable<Tag> {
        this.tagsState.setLoadingCreatingTag(true);

        const promise: Promise<Tag> = new Promise((res, rej) => {
            this.tagsService.postTags(tag).pipe(
                finalize(() => this.tagsState.setLoadingCreatingTag(false))
            ).subscribe({
                next: tag => { this.tagsState.addTag(tag); res(tag) },
                error: (e) => rej(e)
            })
        })
        return from(promise);
    }

    // UPDATE - PATCH
    updateTag(tag: Tag): Observable<Tag> {
        this.tagsState.setLoadingUpdatingTag(true);

        const promise: Promise<Tag> = new Promise((res, rej) => {
            this.tagsService.patchTag(tag).pipe(
                finalize(() => this.tagsState.setLoadingUpdatingTag(false))
            ).subscribe({
                next: tag => { this.tagsState.updateTag(tag, tag.id); res(tag) },
                error: e => rej(e)
            })
        })
        return from(promise);
    }

    // STATES FROM STORE
    // Forms
    isLoadingGetTags$(): Observable<boolean> {
        return this.tagsState.isLoadingGetTags$();
    }

    isLoadingCreatingTags$(): Observable<boolean> {
        return this.tagsState.isLoadingGetTags$();
    }

    isLoadingUpdatingTags$(): Observable<boolean> {
        return this.tagsState.isLoadingGetTags$();
    }


    // Metadata
    getMetaDataTags$(): Observable<MetaDataTags> {
        return this.tagsState.getMetaDataTags$();
    }


    getTags$(): Observable<Tag[]> {
        return this.tagsState.getTags$();
    }

    // Count
    getTagsCount$(): Observable<number> {
        return this.tagsState.getTagsCount$();
    }

}
