import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import mime from 'mime';
import { FormElementComponent } from '../form-element/form-element.component';
import { FilePondComponent } from 'ngx-filepond';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent extends FormElementComponent implements OnInit {
    @ViewChild('myPond') myPond: FilePondComponent;
    photoEvolutionB64!: string;

    constructor() {
        super();
    }

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Arrastre el archivo o haga click <u>aquí</u>',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg',
        acceptedFileTypes: ['image/jpeg', 'image/jpg'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 150
    };

    pondHandleAddFile(event: any) {
        if (!event.error) {
            console.log(event);

            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);
            const photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
            this.form.controls[this.name].setValue(photoEvolutionB64)
        } else {
            this.photoEvolutionB64 = '';
        }
    }

    pondHandleRemoveFile(event: any) {
        this.photoEvolutionB64 = '';
        this.form.controls[this.name].reset();
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
