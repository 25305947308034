import { Component, OnInit, ElementRef, Input, AfterViewInit } from '@angular/core';
import { mixinColor, ThemePalette, CanColor } from '@angular/material/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';

const BASE_SIZE = 100;
const BASE_STROKE_WIDTH = 10;
export class SpinnerContainerBase {
    constructor(public _elementRef: ElementRef) { }
}
export const _SpinnerContainerMixinBase = mixinColor(SpinnerContainerBase, 'primary');

@Component({
    selector: 'app-progress-spinner-with-text',
    templateUrl: './progress-spinner-with-text.component.html',
    styleUrls: ['./progress-spinner-with-text.component.scss'],
    host: {
        'class': 'spinner-container',
        '[style.width.px]': 'diameter',
        '[style.height.px]': 'diameter',
        '[style.line-height.px]': 'diameter'
    }
})
export class ProgressSpinnerWithTextComponent extends _SpinnerContainerMixinBase implements AfterViewInit, CanColor {

    constructor(public override _elementRef: ElementRef) {
        super(_elementRef);
    }

    @Input() override color: ThemePalette = 'primary';
    @Input() valueText: any;
    @Input() fontSize = '14px';
    @Input() displayWith: (number: any) => string | number = (v) => v;

    private _diameter: number = BASE_SIZE;
    private _strokeWidth: number = BASE_STROKE_WIDTH;
    private _value: number = 0;
    private _spinnerBackgroundElement!: HTMLElement;

    @Input()
    get diameter(): number {
        return this._diameter;
    }
    set diameter(size: number) {
        this._diameter = coerceNumberProperty(size);
    }

    @Input()
    get strokeWidth() { return this._strokeWidth; }
    set strokeWidth(newValue: number) {
        if (newValue) {
            this._strokeWidth = Math.min(this.diameter / 2, coerceNumberProperty(newValue));
            if (this._spinnerBackgroundElement) {
                this._spinnerBackgroundElement.style.borderWidth = this.strokeWidth + 'px';
            }
        }
    }

    @Input()
    get value(): number { return this._value; }
    set value(newValue: number) {
        this._value = Math.max(0, Math.min(100, coerceNumberProperty(newValue)));
    }

    ngAfterViewInit() {
        if (!this.valueText) {
            this.valueText = this.value;
        }
        this._spinnerBackgroundElement = this._elementRef.nativeElement.querySelector('.spinner-background');
        this._spinnerBackgroundElement.style.borderWidth = this.strokeWidth + 'px';
    }
}
