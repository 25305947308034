import { CommonModule } from '@angular/common';
import { Component, effect, input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { AlertService } from 'src/app/core/services/alert.service';
import { MaterialModule } from 'src/app/material/material.module';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { Specialty } from 'src/app/shared/models/specialty';
import { MapsService } from 'src/app/shared/services/maps.service';

@Component({
    selector: 'app-map',
    standalone: true,
    imports: [GoogleMapsModule, MaterialModule, CommonModule],
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss',
})

export class MapComponent implements OnInit {

    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
    @ViewChild('templatePatient', { static: true }) templatePatient: TemplateRef<any>;
    @ViewChild('templateProvider', { static: true }) templateProvider: TemplateRef<any>;

    // INPUT PARAMS
    title = input<string>();
    specialtyName = input<string>();
    specialtyId = input<number>();
    center = input<MarkerGoogleMaps>();
    inputMarkersSignal = input<MarkerGoogleMaps[]>([]);

    markersSignal: MarkerGoogleMaps[];
    width = input<number>();
    height = input<number>();
    notCase: boolean = true;
    specialtySelected = input<Specialty>();

    styles: google.maps.MapTypeStyle[] = [
        {
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        }
    ];

    options: google.maps.MapOptions = {
        mapId: 'initMap',
        zoom: 8,
        controlSize: 24,
        center: { lat: -34.6037, lng: -58.3816 }, // Coordenadas de Buenos Aires, Argentina        // zoomControlOptions: {
        //     position: google.maps.ControlPosition.LEFT_TOP
        // },
        styles: this.styles
    };

    cardInfoEnabled: TypeMapMarker[] = [
        TypeMapMarker.PATIENT_ACTIVE,
        TypeMapMarker.PATIENT_INACTIVE,
        TypeMapMarker.PROVIDER
    ]
    infoContent = 'Hola infoContent';
    markerContent: { [key: string]: SVGElement } = {};
    selectedMarker: MarkerGoogleMaps;

    constructor(
        public mapsService: MapsService,
        public alertService: AlertService
    ) {
        effect(() => {
            const markers = this.inputMarkersSignal();
            if (markers && Array.isArray(markers)) {
                const markersClean = markers.filter(item => item != null);
                this.markersSignal = [...markersClean];
            } else {
                this.markersSignal = [];
            }

            const c = this.center();
            if (c && (c.type === 'GENERAL' || c.type === 'CASE')) {
                this.updateMapOptions(c);
            }
        });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        console.log('ngAfterViewInit()');
        this.updateMapOptions()
    }

    private updateMapOptions(value?): void {
        if (value && this.map && this.notCase) {
            // if (value.type = 'CASE') { this.notCase = false }
            const userLocation = {
                lat: value?.lat,
                lng: value?.lng
            };
            const options = {
                mapId: 'initMap',
                zoom: 13,
                controlSize: 24,
                center: userLocation,
                styles: this.styles
            }
            // this.map.googleMap.setOptions(options);
            this.map.googleMap.setCenter(userLocation);
            // this.animateZoom(13)

            // this.map.googleMap.setZoom(16); // Ajustar el nivel de zoom si es necesario
        } else if (navigator.geolocation && this.notCase) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    if (this.map && this.map.googleMap) {
                        this.map.googleMap.setCenter(userLocation);
                        this.animateZoom(13)
                        // this.onCenter = userLocation
                    }
                },
                error => {
                    console.error('Error getting user location', error);
                    // Si hay un error o no se concede el permiso, el mapa permanecerá centrado en Argentina
                }
            );
        }
    }

    animateZoom(targetZoom: number) {
        if (this.map) {
            const currentZoom = this.map.googleMap.getZoom();
            if (currentZoom !== targetZoom) {
                const zoomInterval = setInterval(() => {
                    const newZoom = currentZoom < targetZoom ? this.map!.getZoom()! + 1 : this.map!.getZoom()! - 1;
                    this.map.googleMap.setZoom(newZoom);

                    if (newZoom === targetZoom) {
                        clearInterval(zoomInterval);
                    }
                }, 150); // Cambia el zoom cada 150 ms para una transición suave
            }
        }
    }

    copyToClipboard(value) {
        navigator.clipboard.writeText(value)
            .then(() => {
                this.alertService.openInfo('Texto copiado al portapapeles');
                console.log('Texto copiado al portapapeles');
            })
            .catch(err => {
                console.error('Error al copiar:', err);
            });
    }



    openInfo(markerElem: MapAdvancedMarker, markerData: MarkerGoogleMaps) {
        if (this.cardInfoEnabled.includes(markerData.type)) {
            this.selectedMarker = markerData;
            this.info.open(markerElem);
        }
    }

    getInfoWindowTemplate(marker: MarkerGoogleMaps): TemplateRef<any> {
        switch (marker.type) {
            case TypeMapMarker.PATIENT_ACTIVE:
                return this.templatePatient;

            case TypeMapMarker.PATIENT_INACTIVE:
                return this.templatePatient;

            case TypeMapMarker.PROVIDER:
                return this.templateProvider;

            default:
                break;
        }
    }
}
