<div class="flex-column align-start-none full-width gap-1em">
    <div class="flex-column align-start-none gap-1em">
        <div class="flex-row gap-1em">
            <span class="subtitle-3 text-primary-darker">Paciente:</span>
            <span class="subtitle-4">{{ this.fullName() }}</span>
        </div>
        <div class="flex-row gap-1em">
            <span class="subtitle-3 text-primary-darker">Práctica:</span>
            <span class="subtitle-4">{{ !!this.pratice() ? this.pratice().name : 'Sin especificar' }}</span>
        </div>
        <div class="flex-row gap-1em">
            <span class="subtitle-3 text-primary-darker">Especialidad:</span>
            <span class="subtitle-4">{{ !!this.specialty() ? this.specialty() : 'Sin especificar' }}</span>
        </div>
    </div>
</div>
