import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Case } from 'src/app/shared/models/case';
import { VatCategory } from 'src/app/shared/models/vatCategory';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { CasesService } from 'src/app/core/services/cases.service';

@Component({
  selector: 'app-vat-category-selector',
  templateUrl: './vat-category-selector.component.html',
  styleUrls: ['./vat-category-selector.component.scss']
})
export class VatCategorySelectorComponent implements OnInit {

  public readonly VAT_CATEGORIES = 'vatCategories';
  public readonly VAT_CATEGORIES_SEARCHING = 'vatCategoriesSearching';

  vatCategorySelectorForm: FormGroup;

  @Input() case: Case;

  isLoadingGettingVatCategories$: Observable<boolean>;
  _vatCategories: Subscription;
  vatCategories: VatCategory[];

  vatCategoriesOnSelect: VatCategory[];
  actualVatCategory: VatCategory;

  constructor(
    private formBuilder: FormBuilder,
    private casesFacade: CasesFacade,
    private casesService: CasesService,
  ) { 
    this.isLoadingGettingVatCategories$ = this.casesFacade.isLoadingGettingVatCategories$();
  }


  ngOnInit(): void {

    this.vatCategorySelectorForm = this.createVatCategoryForm();

    this.casesFacade.loadVatCategories();
    this._vatCategories = this.casesFacade.getVatCategories$().subscribe(
      (vatCategories) => {
        this.vatCategories = vatCategories
    });

    if(!!this.case.vatCategory) {
      this.vatCategoriesOnSelect = [this.case.vatCategory];
      this.actualVatCategory = this.case.vatCategory;
      console.log("Actual VAT: ",this.actualVatCategory);
    }

  }

  calculateName(element: VatCategory) {
    return element ? element.description : null;
  }

  calculateId(element: VatCategory) {
    return element ? element.id : null;
  }

  createVatCategoryForm(): FormGroup {
    return this.formBuilder.group({
      [this.VAT_CATEGORIES]: ['', [CustomValidators.required('Estado requerido')]],
      [this.VAT_CATEGORIES_SEARCHING]: [''], 
    })
  }

  onUpdateVatCategory() {

    const swalWithCustomizeButtons = Swal.mixin({
      customClass: {
      confirmButton: 'btnSwalConfirm',
      cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    swalWithCustomizeButtons.fire({
      title: '¿Confirma el cambio de categoria de IVA?',
      icon: 'warning',
      confirmButtonText: 'CONFIRMAR',
      cancelButtonText: 'CANCELAR',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      reverseButtons: true,
      preConfirm: () => {
        //Revisar Body
        const body = {
          vatCategoryId: this.vatCategorySelectorForm.get(this.VAT_CATEGORIES)?.value
        };
        return this.casesFacade.updateCase(body, this.case.id).toPromise().then(() => {
          return swalWithCustomizeButtons.fire({
            text: 'Categoria de IVA actualizada correctamente',
            icon: 'success'
          })
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  getVatCategoriesByName = () => this.casesService.getVatCategories().pipe(map(body => body));

}
