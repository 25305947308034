import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends FormElementComponent implements OnInit {
    @Input() maxLength!: number;
    @Input() clearable = false;
    @Output() enter = new EventEmitter();
    @Output() changes = new EventEmitter();
    @ViewChild('textInput') textInput!: ElementRef;
    @Input() selectAllOnClick: boolean = false;

    constructor() {
        super();
    }

    selectAll() {
        if (this.selectAllOnClick) {
            const input = this.textInput.nativeElement;
            input.select();
        }
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }

    hasError(errorType: string): boolean {
        const control = this.form.get(this.name);
        return control && control.hasError(errorType) && (control.dirty || control.touched);
    }
}
