import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Financier } from 'src/app/shared/models/financier';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Observable, Subscription, fromEvent, timer } from 'rxjs';
import { Case, CASE_AUTHORIZATIONS_STATES } from 'src/app/shared/models/case';
import { CasesFilterParameters, CasesFacade } from 'src/app/abstraction/cases.facade';
import { SortDirection, MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GeneralService } from 'src/app/core/services/general.service';
import { CasesService } from 'src/app/core/services/cases.service';
import { MatTableDataSource } from '@angular/material/table';
import { map, startWith, filter, debounce, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/core/enums/routes';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import * as moment from 'moment';
import { Operator } from 'src/app/shared/models/operator';
import { CompanyType } from 'src/app/shared/models/companyType';
import { DatePipe } from '@angular/common';
import { ProvisionFeesIndicatorsQPS, ProvisionalControlIndicators, ProvisionalControlIndicatorsQPS } from 'src/app/shared/models/provisionalControlIndicators';
import { ProvisionalControlIndicatorsStates, ProvisionalControlTableOptions, ProvisionalControlOrderStates, ProvisionalControlProvisionFeeStates, CasesStates } from 'src/app/core/enums/provisionalControlIndicatorsStates';
import { CasesIndicatorsValues } from '../../../../shared/models/provisionalControlIndicators';
import { AuthenticationService } from '../../../../core/authentication.service';
import { StorageService } from '../../../../core/services/storage.service';
import { ProvisionalControlOrder } from 'src/app/shared/models/order';
import { ProvisionFee, ProvisionalControlProvisionFee } from 'src/app/shared/models/provisionFee';
import { AUTHORIZATION_BACKGROUND_COLORS, AUTHORIZATION_COLORS } from 'src/app/abstraction/provisions.facade';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-provisional-control',
    templateUrl: './provisional-control.component.html',
    styleUrls: ['./provisional-control.component.scss'],
})
export class ProvisionalControlComponent implements OnInit {
    @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild('datePicker') datePicker!: any;

    openedCalendar!: boolean;

    private inputSearch!: ElementRef;
    financierSelected!: Financier;
    isDisabledCompanyType: boolean = true;

    @ViewChild('inputSearch') set input(input: ElementRef) {
        if (input && !this._input) { // initially setter gets called with undefined
            this.inputSearch = input;
            this.subscribeInputSearch();
        }
    }

    displayedColumns: string[] = [
        'active',
        'patient',
        'patientDocument',
        'financier',
        'companyType',
        'operator',
        'complexity',
        'caseType'
    ];

    displayedColumnsOrders: string[] = [
        'orderCaseActive',
        'orderPatient',
        'orderPatientDocument',
        'orderFinancier',
        'orderCompanyType',
        'orderOperator',
        'orderId',
    ];

    displayedColumnsProvisionFees: string[] = [
        'practiceCaseActive',
        'practicePatient',
        'practiceName',
        'vigency',
        'practiceOperator',
        'practiceFinancier',
        'practiceCompanyType',
        'provisionFeeAuthorization'
    ];
    public provisionalControlIndicatorsStates = ProvisionalControlIndicatorsStates;
    public provisionalControlOrderStates = ProvisionalControlOrderStates;
    public provisionalControlProvisionFeeStates = ProvisionalControlProvisionFeeStates;
    public readonly FROM_DATE = 'fromDate'
    // public readonly ALL_CASES = 'all';
    // public readonly REJECTED = 'rejected'
    // public readonly DERIVED = 'derived';
    // public readonly WITHOUT_ARRANGEMENTS = 'withoutArrangements';
    CASE_AUTHORIZATIONS_STATES = CASE_AUTHORIZATIONS_STATES;
    ATTENTION_STATES = AttentionStates;
    AUTHORIZATION_COLORS = AUTHORIZATION_COLORS;
    AUTHORIZATION_BACKGROUND_COLORS = AUTHORIZATION_BACKGROUND_COLORS;
    ProvisionalControlTableOptions = ProvisionalControlTableOptions;
    dataSource;
    cases: Case[];
    isLoadingGettingCases: boolean;
    _isLoadingGettingCases: Subscription;
    isLoadingGettingProvisionFees: boolean;
    _isLoadingGettingProvisionFees: Subscription;
    isLoadingGettingOrders: boolean;
    _isLoadingGettingOrders: Subscription;
    cases$: Observable<Case[]>;
    _cases: Subscription;
    _pagination: Subscription;
    _mdc: Subscription;
    _sort: Subscription;
    _input: Subscription;
    numberOfPages: number;
    numberOfRows: number;
    metaDataCases: CasesFilterParameters;
    actualDirection: SortDirection;
    _casesLoaded: Subscription;

    isLoadingGettingFinanciers: boolean;
    financiers$: Observable<Financier[]>;
    _financiers: Subscription;
    _isLoadingGettingFinanciers: Subscription;
    financiers: Financier[];

    _isLoadingGettingOperators: Subscription;
    isLoadingGettingOperators: boolean;
    _operators: Subscription;
    operators: Operator[];

    _isLoadingProvisionalControlIndicators: Subscription;
    isLoadingProvisionalControlIndicators: boolean;
    _provisionalControlIndicators: Subscription;
    provisionalControlIndicatorsValues: CasesIndicatorsValues;
    _isLoadingProvisionFeesIndicators: Subscription;
    isLoadingProvisionFeesIndicators: boolean;
    _provisionFeesIndicators: Subscription;
    _provisionFees: Subscription;
    provisionFees: ProvisionalControlProvisionFee[];
    _isLoadingOrderIndicators: Subscription;
    isLoadingOrderIndicators: boolean;
    _ordersIndicators: Subscription;
    _orders: Subscription;
    orders: ProvisionalControlOrder[];

    counterTotalIndicator: number;
    //counterNotScheduledIndicator: number;
    counterOperableIndicator: number = 0;
    counterInformableIndicator: number = 0;
    counterInformedIndicator: number = 0;
    //counterWithoutPendingScheduleIndicator: number;

    counterProvisionFeesTotals: number;
    counterProvisionFeesWithoutSchedule: number;
    counterProvisionFeesAuthorized: number;
    counterProvisionFeesNotAuthorized: number;
    counterProvisionFeesBudgeted: number;

    counterTotalOrders: number;
    counterCasesWithNotOrderedSupplies: number;
    counterOrdersWithNotAuthorizedSupplies: number;
    counterOrdersNotDelivered: number;

    colorTotalIndicator: string;
    colorNotScheduledIndicator: string;
    colorOperableIndicator: string;
    colorInformableIndicator: string;

    companyTypesList: CompanyType[];

    caseIndicators: { name: string, counter: number, indicatorState: string, color: string }[] = [];

    environment = environment;

    globalVersion: string = 'v2';

    filterForm: FormGroup;
    appliedFilters: boolean = false;
    public readonly FINANCIER = 'financierId';
    public readonly FINANCIER_SEARCHING = 'financierSearching';
    public readonly COMPANY_TYPE = 'companyTypeId';
    public readonly COMPANY_TYPE_SEARCHING = 'companyTypeSearching';
    public readonly OPERATOR = 'operatorId';
    public readonly OPERATOR_SEARCHING = 'operatorSearching';
    public readonly DATE_TO = 'dateTo';
    public readonly BILLABLE_STATE_ATTENTION = 'billable';
    public readonly DEBITED_STATE_ATTENTION = 'debited';
    public readonly PATIENT = 'patientId';
    public readonly PATIENT_SEARCHING = 'patientSearching';
    public readonly PROVISIONAL_STATE = 'provisionalState';
    public readonly PROVISIONAL_OPTIONS = 'provisionalOptions';
    public readonly CASES_STATES = 'casesStates'
    public readonly ORDER_STATES = 'orderStates';
    public readonly PROVISIONFEE_STATES = 'provisionFeeStates'

    filteredProvisionalControlIndicators: ProvisionalControlIndicators[];
    provisionalControlIndicators: ProvisionalControlIndicators[] = [
        {
            id: 0,
            name: 'Todos',
            state: ProvisionalControlIndicatorsStates.TODOS
        },
        {
            id: 1,
            name: 'Operables',
            state: ProvisionalControlIndicatorsStates.OPERABLES
        },
        {
            id: 2,
            name: 'Informables',
            state: ProvisionalControlIndicatorsStates.INFORMABLES
        },
        {
            id: 3,
            name: 'Con insumos no pedidos',
            state: ProvisionalControlIndicatorsStates.CON_INSUMOS_NO_PEDIDOS
        },
        {
            id: 4,
            name: 'Informados',
            state: ProvisionalControlIndicatorsStates.INFORMADOS
        }
    ]

    orderStates = [
        {
            id: 0,
            name: 'Todos',
            state: ProvisionalControlOrderStates.ALL
        },
        {
            id: 1,
            name: 'Con insumos no autorizados',
            state: ProvisionalControlOrderStates.WITH_NOT_AUTHORIZED_SUPPLIES
        },
        {
            id: 2,
            name: 'No entregados',
            state: ProvisionalControlOrderStates.NOT_DELIVERED
        },
    ]

    provisionFeeStates = [
        {
            id: 0,
            name: 'Todos',
            state: ProvisionalControlProvisionFeeStates.ALL
        },
        {
            id: 1,
            name: 'No autorizadas',
            state: ProvisionalControlProvisionFeeStates.NOT_AUTHORIZED
        },
        {
            id: 2,
            name: 'Sin agenda',
            state: ProvisionalControlProvisionFeeStates.WITHOUT_SCHEDULE
        },
        {
            id: 3,
            name: 'Presupuestadas',
            state: ProvisionalControlProvisionFeeStates.BUDGETED
        },

    ]

    tableOptions = [
        {
            id: ProvisionalControlTableOptions.CASES,
            name: 'Casos'
        },
        {
            id: ProvisionalControlTableOptions.ORDERS,
            name: 'Pedidos'
        },
        {
            id: ProvisionalControlTableOptions.PROVISIONFEES,
            name: 'Prácticas'
        },
    ]

    casesStates = [
        {
            id: CasesStates.ALL,
            name: 'Todos'
        },
        {
            id: CasesStates.OPEN,
            name: 'Abiertos'
        },
        {
            id: CasesStates.CLOSED,
            name: 'Cerrados'
        },
        {
            id: CasesStates.REJECTED,
            name: 'Rechazados'
        }
    ]
    indicatorStateSelected: ProvisionalControlIndicators;
    user;

    auxInitialFilters: CasesFilterParameters;
    initialFilters: CasesFilterParameters = {
        page: 0,
        size: 100,
        order: 'ASC',
        sort: 'surname',
        requested: 0,
        closeLastTrimester: true,
        casesState: CasesStates.ALL
    }

    get financier() {
        return this.filterForm?.get(this.FINANCIER).value;
    }
    set financier(financierSelected) {
        this.filterForm?.get(this.FINANCIER).setValue(financierSelected.id);
    }
    get companyType() {
        return this.filterForm?.get(this.COMPANY_TYPE).value;
    }
    get dateTo() {
        return this.filterForm?.get(this.DATE_TO).value;
    }
    get operator() {
        return this.filterForm?.get(this.OPERATOR).value;
    }
    get provisionFeeStateSelected() {
        return this.filterForm?.get(this.PROVISIONFEE_STATES).value;
    }
    get optionSelected() {
        return parseInt(this.filterForm?.get(this.PROVISIONAL_OPTIONS).value);
    }
    get orderStateSelected() {
        return this.filterForm?.get(this.ORDER_STATES).value;
    }
    set optionSelected(option) {
        this.filterForm.get(this.PROVISIONAL_OPTIONS).setValue(option)
    }
    set orderStateSelected(state) {
        this.filterForm.get(this.ORDER_STATES).setValue(state);
    }
    set provisionFeeStateSelected(state) {
        this.filterForm.get(this.PROVISIONFEE_STATES).setValue(state);
    }
    get caseState() {
        return this.filterForm?.get(this.CASES_STATES).value;
    }
    set caseState(state) {
        this.filterForm.get(this.CASES_STATES).setValue(state)
    }
    set financierForm(financierId: number) {
        financierId != null
            ? this.filterForm.controls[this.FINANCIER].setValue(financierId.toString())
            : this.filterForm.controls[this.FINANCIER].reset();
    }
    set dateToForm(date: Date) {
        date != null
            ? this.filterForm.controls[this.DATE_TO].setValue(date)
            : this.filterForm.controls[this.DATE_TO].reset();
    }
    set billableAttentionForm(checked: boolean) {
        this.filterForm.controls[this.BILLABLE_STATE_ATTENTION].setValue(checked);
    }
    set debitedAttentionForm(checked: boolean) {
        this.filterForm.controls[this.DEBITED_STATE_ATTENTION].setValue(checked);
    }
    set operator(operatorId) {
        this.filterForm?.get(this.OPERATOR).setValue(operatorId);
    }

    calculateNameFinancier(financier: Financier) {
        return financier ? financier.name : null;
    }

    calculateIdFinancier(financier: Financier) {
        return financier ? financier.id : null;
    }

    calculateNameCompanyType(companyType: CompanyType) {
        return companyType ? companyType.description : null;
    }

    calculateIdCompanyType(companyType: CompanyType) {
        return companyType ? companyType.id : null;
    }

    calculateNameOperator(operator: Operator) {
        return operator ? operator.name : null;
    }

    calculateIdOperator(operator: Operator) {
        return operator ? operator.id : null;
    }

    calculateNameIndicatorState(indicator: ProvisionalControlIndicators) {
        return indicator ? indicator.name : null;
    }

    calculateIdIndicatorState(indicator: ProvisionalControlIndicators) {
        return indicator ? indicator.id : null;
    }

    calculateNameTableOptions(option) {
        return option != null ? option.name : null
    }

    calculateIdTableOptions(option) {
        return option != null ? option.id : null
    }

    calculateNameOrderState(state) {
        return state != null ? state.name : null
    }

    calculateIdOrderState(state) {
        return state != null ? state.id : null
    }

    calculateNameCaseState(state) {
        return state != null ? state.name : null
    }

    calculateIdCaseState(state) {
        return state != null ? state.id : null
    }

    titleComponent: string;

    constructor(
        public casesService: CasesService,
        public generalService: GeneralService,
        private formBuilder: FormBuilder,
        private entitiesFacade: EntitiesFacade,
        private casesFacade: CasesFacade,
        private router: Router,
        private datePipe: DatePipe,
        private authService: AuthenticationService,
        private storageService: StorageService,
    ) {
    }

    ngOnInit(): void {
        this.filteredProvisionalControlIndicators = this.provisionalControlIndicators.filter(indicator => indicator.id !== 5); // No listed in combo "Abiertos"
        this.user = this.authService.userValue;
        this.auxInitialFilters = { ...this.initialFilters };
        this.auxInitialFilters.operatorId = this.user.id;
        this.filterForm = this.createFilterForm();
        this.dataSource = new MatTableDataSource();
        this.generalService.sendMessage('');
        this.subscribeToData();
        this.indicatorStateSelected = this.provisionalControlIndicators[0];

        // if (!("indicator" in this.metaDataCases) && !("hasPendingSchedule" in this.metaDataCases)) {
        //     this.indicatorStateSelected = this.provisionalControlIndicators[0]; // TODOS

        // }

        this.titleComponent = this.router.url === '/app/casos/listado' ? 'Listado de casos' : 'Control prestacional'
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this._pagination.unsubscribe();
        this._cases.unsubscribe();
        this._financiers.unsubscribe();
        this._isLoadingGettingCases.unsubscribe();
        this._isLoadingGettingFinanciers.unsubscribe();
        this._mdc.unsubscribe();
        this._operators.unsubscribe();
        this._provisionalControlIndicators.unsubscribe();
        this._isLoadingOrderIndicators.unsubscribe();
        this._isLoadingProvisionFeesIndicators.unsubscribe();
        if (!!this._casesLoaded) {
            this._casesLoaded.unsubscribe();
        }
        this.casesFacade.setCases$(null);
        this.casesFacade.setProvisionalControlIndicatorValues(null);
        this.casesFacade.setProvisionFeesIndicators(null);
        this.casesFacade.setOrderIndicators(null);
        this._provisionFeesIndicators.unsubscribe();
        this._ordersIndicators.unsubscribe();
        this._orders.unsubscribe();
        this._provisionFees.unsubscribe();
        this._isLoadingGettingProvisionFees.unsubscribe();
        this._isLoadingGettingOrders.unsubscribe();
    }

    createFilterForm(): FormGroup {
        return this.formBuilder.group({
            [this.FINANCIER]: ['', []],
            [this.FINANCIER_SEARCHING]: [''],
            [this.COMPANY_TYPE]: ['', []],
            [this.COMPANY_TYPE_SEARCHING]: [''],
            [this.OPERATOR]: ['', []],
            [this.OPERATOR_SEARCHING]: [''],
            [this.DATE_TO]: ['', []],
            [this.PATIENT]: [],
            [this.PATIENT_SEARCHING]: [''],
            [this.PROVISIONAL_STATE]: [''],
            [this.PROVISIONAL_OPTIONS]: [this.tableOptions[ProvisionalControlTableOptions.CASES].id],
            [this.CASES_STATES]: [this.authService.isAdmin() ? CasesStates.ALL : CasesStates.OPEN],
            [this.ORDER_STATES]: [],
            [this.PROVISIONFEE_STATES]: [],
            // [this.ALL_CASES]: [],
            // [this.REJECTED]: [],
            // [this.DERIVED]: [],
            // [this.WITHOUT_ARRANGEMENTS]: [],
            [this.FROM_DATE]: [new Date(new Date().getFullYear(), new Date().getMonth(), 1)]
        });
    }


    // onFilter(reloadIndicators: boolean, version?: string) {
    //     // Casos con financierId, attentionDateTo y attentionStates: 3,4
    //     this.globalVersion = version;
    //     this.appliedFilters = true;
    //     const patientId = this.filterForm.get(this.PATIENT)?.value;
    //     const companyType = this.filterForm.get(this.COMPANY_TYPE)?.value;
    //     // const allCases = this.filterForm.get(this.ALL_CASES)?.value;
    //     // const derived = this.filterForm.get(this.DERIVED)?.value;
    //     // const byArrangement = this.filterForm.get(this.WITHOUT_ARRANGEMENTS).value;
    //     // const rejected = this.filterForm.get(this.REJECTED)?.value;
    //     // const arrangementFromDate = this.filterForm.get(this.FROM_DATE)?.value;
    //     let fp: CasesFilterParameters = this.metaDataCases;
    //     delete fp?.active;
    //     delete fp?.financierId;
    //     delete fp?.toDateProvisionFee;
    //     delete fp?.operatorId;
    //     delete fp?.companyTypeId;
    //     delete fp?.closeLastTrimester;
    //     delete fp?.byArrangementDate
    //     delete fp?.byIndicator;
    //     delete fp?.tableOption;
    //     delete fp?.hasPendingSchedule;
    //     fp.page = 0;
    //     fp.size = (this.metaDataCases && this.metaDataCases.size) || 10;
    //     fp.sort = (this.metaDataCases && this.metaDataCases.sort);
    //     fp.requested = 0;
    //     fp.rejected = 0;

    //     if (!!this.financier) {
    //         fp.financierId = this.financier;
    //     }

    //     if (!!companyType) {
    //         fp.companyTypeId = companyType;
    //     } else {
    //         delete fp.companyTypeId
    //     }

    //     if (!!this.operator) {
    //         fp.operatorId = this.operator;
    //     }

    //     if (!!this.dateTo) {
    //         fp.toDateProvisionFee = moment(this.dateTo, 'YYYY-MM-DD').toDate();
    //     }

    //     if (!patientId || patientId == '0') {
    //         delete fp.patientsIds;
    //     } else {
    //         fp.patientsIds = [patientId];
    //     }

    //     if (!!this.indicatorStateSelected && !!this.indicatorStateSelected.state && this.indicatorStateSelected.state != ProvisionalControlIndicatorsStates.TODOS) {
    //         fp.byIndicator = true;
    //         fp.indicator = this.indicatorStateSelected.state;
    //         delete fp.hasSchedule
    //     } else {
    //         delete fp.hasPendingSchedule
    //         delete fp.indicator;
    //     }

    //     this.storageService.setObjectToSessionStorage('filterListProvisionalControl', fp);

    //     if (!!reloadIndicators) {
    //         let qps: ProvisionalControlIndicatorsQPS = {
    //             requested: 0,
    //         };
    //         if (!!this.financier) {
    //             qps.financierId = this.financier;
    //         }
    //         if (!!this.dateTo) {
    //             qps.toDateProvisionFee = moment(this.dateTo).toDate();
    //         }
    //         if (!!this.operator) {
    //             qps.operatorId = this.operator;
    //         }
    //         if (!!this.companyType) {
    //             qps.companyTypeId = this.companyType;
    //         }
    //         if (!!fp.hasPendingSchedule) {
    //             qps.hasPendingSchedule = fp.hasPendingSchedule
    //         }
    //         if (!!fp.byArrangement) {
    //             qps.byArrangement = fp.byArrangement
    //         } else {
    //             delete qps.byArrangement
    //         }
    //         if (!!fp.derived) {
    //             qps.derived = fp.derived
    //         }
    //         if (!!fp.byArrangementDate) {
    //             qps.byArrangementDate = moment(fp.byArrangementDate, 'YYYY-MM-DD').toDate();
    //         }

    //         this.casesFacade.loadProvisionalControlIndicators({ ...qps, closeLastTrimester: true, rejected: 0 }, this.globalVersion);
    //         this.casesFacade.loadProvisionFeesIndicators({ ...qps, active: true }, this.globalVersion);
    //         this.casesFacade.loadOrdersIndicators({ ...qps, active: true }, this.globalVersion);
    //     }

    //     switch (this.optionSelected) {
    //         case ProvisionalControlTableOptions.CASES:
    //             switch (this.caseState) {
    //                 case CasesStates.ALL:
    //                     if (this.indicatorStateSelected.state != ProvisionalControlIndicatorsStates.OPERABLE)
    //                         delete fp.rejected;
    //                     delete fp.active;
    //                     fp.closeLastTrimester = true;
    //                     break;
    //                 case CasesStates.ACTIVE:
    //                     fp.rejected = 0;
    //                     delete fp.closeLastTrimester;
    //                     fp.active = true;
    //                     break;
    //                 case CasesStates.CLOSED:
    //                     fp.rejected = 0;
    //                     fp.active = false;
    //                     fp.closeLastTrimester = true;
    //                     break;
    //                 case CasesStates.REJECTED:
    //                     fp.rejected = 1;
    //                     fp.active = false;
    //                     fp.closeLastTrimester = true;
    //             }
    //             this.loadCases(fp);
    //             break;
    //         case ProvisionalControlTableOptions.ORDERS:
    //             this.loadOrders(this.orderStateSelected);
    //             break;
    //         case ProvisionalControlTableOptions.PROVISIONFEES:
    //             this.loadProvisionFees(this.provisionFeeStateSelected);
    //             break;
    //     }
    // }

    onFilterV2FromButton(reloadIndicators: boolean, version?: string) {
        this.caseState = CasesStates.ALL;
        const actualFilter = this.storageService.getObjectfromSessionStorage('filterListProvisionalControl');
        // const { indicator, byIndicator, ...newFilter } = actualFilter;
        const { indicator, ...newFilter } = actualFilter;
        this.indicatorStateSelected = this.provisionalControlIndicators[0]; // TODOS
        this.storageService.setObjectToSessionStorage('filterListProvisionalControl', { ...newFilter, casesState: this.caseState });
        this.onFilterV2(reloadIndicators, version);
    }

    onFilterV2(reloadIndicators: boolean, version?: string) {
        // Casos con financierId, attentionDateTo y attentionStates: 3,4
        this.globalVersion = version;
        this.appliedFilters = true;
        const patientId = this.filterForm.get(this.PATIENT)?.value;
        const companyType = this.filterForm.get(this.COMPANY_TYPE)?.value;

        // Exception for REJECTED
        if (this.caseState === CasesStates.REJECTED) {
            this.indicatorStateSelected = this.provisionalControlIndicators[0];
            this.filterForm.get(this.PROVISIONAL_STATE).disable();
        } else {
            this.filterForm.get(this.PROVISIONAL_STATE).enable();
        }

        let fp: CasesFilterParameters = this.metaDataCases;
        delete fp?.active;
        delete fp?.financierId;
        delete fp?.toDateProvisionFee;
        delete fp?.operatorId;
        delete fp?.companyTypeId;
        delete fp?.closeLastTrimester;
        delete fp?.byArrangementDate
        delete fp?.tableOption;
        delete fp?.hasPendingSchedule;
        delete fp?.casesState

        fp.page = 0;
        fp.size = (this.metaDataCases && this.metaDataCases.size) || 10;
        fp.sort = (this.metaDataCases && this.metaDataCases.sort) || 'surname';

        if (!!this.financier) {
            fp.financierId = this.financier;
        }

        if (!!companyType) {
            fp.companyTypeId = companyType;
        } else {
            delete fp.companyTypeId
        }

        if (!!this.operator) {
            fp.operatorId = this.operator;
        }

        if (!!this.dateTo) {
            fp.toDateProvisionFee = moment(this.dateTo, 'YYYY-MM-DD').toDate();
        }

        if (!patientId || patientId == '0') {
            delete fp.patientsIds;
        } else {
            fp.patientsIds = [patientId];
        }

        // fp.indicator = this.indicatorStateSelected.name; // Set indicator
        fp.casesState = this.caseState; // Always set

        // if (!!this.indicatorStateSelected && !!this.indicatorStateSelected.state && this.indicatorStateSelected.state != ProvisionalControlIndicatorsStates.TODOS) {
        // fp.byIndicator = true;
        // fp.indicator = this.indicatorStateSelected.name;
        // delete fp.hasSchedule
        // } else {
        //     delete fp.byIndicator;
        //     delete fp.hasPendingSchedule
        //     delete fp.indicator;
        // }

        // this.storageService.setObjectToSessionStorage('filterListProvisionalControl', fp);

        if (!!reloadIndicators) {
            let qps: ProvisionalControlIndicatorsQPS = {
                requested: 0,
            };
            if (!!this.financier) {
                qps.financierId = this.financier;
            }
            if (!!this.dateTo) {
                qps.toDateProvisionFee = moment(this.dateTo).toDate();
            }
            if (!!this.operator) {
                qps.operatorId = this.operator;
            }
            if (!!this.companyType) {
                qps.companyTypeId = this.companyType;
            }
            if (!!fp.hasPendingSchedule) {
                qps.hasPendingSchedule = fp.hasPendingSchedule
            }
            if (!!fp.byArrangement) {
                qps.byArrangement = fp.byArrangement
            } else {
                delete qps.byArrangement
            }
            if (!!fp.derived) {
                qps.derived = fp.derived
            }
            if (!!fp.byArrangementDate) {
                qps.byArrangementDate = moment(fp.byArrangementDate, 'YYYY-MM-DD').toDate();
            }

            // 2 IN 1
            this.casesFacade.loadProvisionalControlIndicators({ ...qps, closeLastTrimester: true, rejected: 0 }, this.globalVersion);
            //
            this.casesFacade.loadProvisionFeesIndicators({ ...qps, active: true }, this.globalVersion);
            this.casesFacade.loadOrdersIndicators({ ...qps, active: true }, this.globalVersion);
        }

        switch (this.optionSelected) {
            case ProvisionalControlTableOptions.CASES:
                switch (this.caseState) {
                    case CasesStates.ALL:
                        delete fp.active;
                        delete fp.rejected;
                        fp.closeLastTrimester = true; // For ALL (active + 3 months closed) -> closeLastTrimestre = true
                        fp.indicator = this.indicatorStateSelected.name
                        break;
                    case CasesStates.OPEN:
                        fp.rejected = 0;
                        fp.active = true;
                        fp.indicator = this.indicatorStateSelected.name
                        break;
                    case CasesStates.CLOSED:
                        fp.rejected = 0;
                        fp.active = false;
                        fp.closeLastTrimester = true;
                        fp.indicator = this.indicatorStateSelected.name
                        break;
                    case CasesStates.REJECTED:
                        fp.rejected = 1;
                        fp.active = false;
                        fp.closeLastTrimester = true;
                        fp.indicator = this.indicatorStateSelected.name
                }
                this.loadCases(fp);
                break;
            case ProvisionalControlTableOptions.ORDERS:
                this.loadOrders(this.orderStateSelected);
                break;
            case ProvisionalControlTableOptions.PROVISIONFEES:
                this.loadProvisionFees(this.provisionFeeStateSelected);
                break;
        }
    }

    loadCases(qps: CasesFilterParameters) {
        // this.storageService.setObjectToSessionStorage('filterListProvisionalControl', { ...qps, casesState: this.caseState });
        this.storageService.setObjectToSessionStorage('filterListProvisionalControl', qps);
        if (this.globalVersion === 'v2') {
            this.casesFacade.loadCasesFromProvisionalControlIndicators({ ...qps, compose: true }, this.globalVersion);
        } else {
            this._casesLoaded = this.casesFacade.loadCases(qps, false, this.globalVersion).subscribe();
        }
    }

    subscribeToData() {
        // Loadings
        this._isLoadingGettingCases = this.casesFacade.isLoadingGettingCases$().subscribe(loading => this.isLoadingGettingCases = loading);
        this._isLoadingGettingFinanciers = this.entitiesFacade.isLoadingGettingFinanciers$().subscribe(loading => this.isLoadingGettingFinanciers = loading);
        this._isLoadingGettingOperators = this.entitiesFacade.isLoadingGettingOperators$().subscribe(loading => this.isLoadingGettingOperators = loading);
        this._isLoadingProvisionalControlIndicators = this.casesFacade.isLoadingProvisionalControlIndicators$().subscribe(loading => this.isLoadingProvisionalControlIndicators = loading);
        this._isLoadingProvisionFeesIndicators = this.casesFacade.isLoadingProvisionFeesIndicators$().subscribe(loading => this.isLoadingProvisionFeesIndicators = loading);
        this._isLoadingOrderIndicators = this.casesFacade.isLoadingOrdersIndicators$().subscribe(loading => this.isLoadingOrderIndicators = loading)

        this._mdc = this.casesFacade.getMetaDataCases$().subscribe((mdc) => {
            if (this.storageService.getObjectfromSessionStorage('filterListProvisionalControl')) {
                mdc = this.storageService.getObjectfromSessionStorage('filterListProvisionalControl');
            }

            if (!!mdc) {
                this.metaDataCases = mdc;
            } else {
                this.metaDataCases = this.auxInitialFilters;
                this.metaDataCases.operatorId = this.user.id;
            }

            if (!!mdc?.toDateProvisionFee) {
                this.metaDataCases.toDateProvisionFee = moment(this.metaDataCases.toDateProvisionFee, 'YYYY-MM-DD').toDate();
                this.filterForm.get(this.DATE_TO).setValue(this.metaDataCases.toDateProvisionFee);
            }

            if (!!mdc?.financierId) {
                this.companyTypesList = [];
                this.financierSelected = this.financiers?.find(f => f.id == mdc.financierId);
                this.companyTypesList = this.financierSelected?.companyTypes ? this.financierSelected.companyTypes : null;
                this.isDisabledCompanyType = this.companyTypesList ? false : true;
            }

            if (!!mdc?.operatorId) {
                this.operator = mdc.operatorId;
            }

            if (!!mdc?.indicator) {
                this.indicatorStateSelected = this.provisionalControlIndicators.find(ind => ind.state == mdc.indicator);
            }

            if (!!mdc && !!mdc?.sort && !!this.sort) {
                const sortState: Sort = { active: mdc.sort, direction: mdc.order === 'ASC' ? 'asc' : 'desc' }
                this.sort.active = sortState.active;
                this.sort.direction = sortState.direction;
            }

            if (!!mdc.tableOption) {
                switch (mdc.tableOption) {
                    case "PROVISIONFEES":
                        this.loadProvisionFees(mdc.indicator);
                        break;
                    case "ORDERS":
                        this.loadOrders(mdc.indicator)
                        break;
                    case "CASES":
                        this.onClickCardIndicator(mdc.casesState);
                }
            }

            if (!!mdc?.casesState) {
                this.caseState = mdc?.casesState;
            }
        });

        this._pagination = this.casesFacade.getPagination$().subscribe((pagination) => {
            if (!!pagination) {
                this.numberOfPages = pagination.pages;
                this.numberOfRows = pagination.rows
                if (!!this.paginator) {
                    this.paginator.length = this.numberOfRows;
                }
            }
        });

        this._cases = this.casesFacade.getCases$().subscribe((cases: Case[]) => {
            if (cases == null && !this.isLoadingGettingCases && (this.metaDataCases?.tableOption == null || this.metaDataCases?.tableOption == "CASES")) {

                this.loadWithFilterDefault()

            } else if (cases != null || (cases == null && !this.isLoadingGettingCases)) {

                this.cases = cases;
                this.dataSource.data = cases;
                this.setDataSourceAttributes();
            }
        })

        this._provisionalControlIndicators = this.casesFacade.getProvisionalControlIndicatorValues$().subscribe((values) => {
            if (!values && !this.isLoadingProvisionalControlIndicators) {
                this.casesFacade.loadProvisionalControlIndicators({ closeLastTrimester: true, operatorId: this.user.id, requested: 0, rejected: 0 }, this.globalVersion);
            } else {
                this.counterOperableIndicator = (!!values?.operable) ? values.operable : 0;
                this.counterInformableIndicator = (!!values?.informable) ? values.informable : 0;
                this.counterTotalIndicator = (!!values?.totalCases) ? values.totalCases : 0;
                this.counterCasesWithNotOrderedSupplies = (!!values?.withNotOrderedSupplies) ? values.withNotOrderedSupplies : 0;
                this.counterInformedIndicator = (!!values?.informed) ? values.informed : 0;
                this.updateIndicators();
            }
        })

        this._provisionFeesIndicators = this.casesFacade.getProvisionFeesIndicators$().subscribe(indicators => {
            if (indicators != null) {
                this.counterProvisionFeesTotals = indicators.total;
                this.counterProvisionFeesAuthorized = indicators.authorized;
                this.counterProvisionFeesWithoutSchedule = indicators.withoutSchedule;
                this.counterProvisionFeesNotAuthorized = indicators.notAuthorized;
                this.counterProvisionFeesBudgeted = indicators.budgeted;
            } else {
                // FIXME: maybe it's not neccesary
                // this.casesFacade.loadProvisionFeesIndicators(this.metaDataCases)
            }
        })

        this._isLoadingGettingProvisionFees = this.casesFacade.isLoadingGettingProvisionalControlProvisionFees$().subscribe(isLoading => {
            this.isLoadingGettingProvisionFees = isLoading;
        })

        this._isLoadingGettingOrders = this.casesFacade.isLoadingGettingProvisionalControlOrders$().subscribe(isLoading => {
            this.isLoadingGettingOrders = isLoading;
        })

        this._ordersIndicators = this.casesFacade.getOrderIndicators$().subscribe(indicators => {
            if (indicators != null) {
                this.counterOrdersNotDelivered = indicators.notDeliveredOrders;
                this.counterOrdersWithNotAuthorizedSupplies = indicators.ordersWithNotAuthorizedSupplies;
                this.counterTotalOrders = indicators.totalOrders;
            } else {
                this.casesFacade.loadOrdersIndicators(this.metaDataCases);
            }
        })


        this._financiers = this.entitiesFacade.getFinanciers$().subscribe(financiers => {
            if (financiers == null && !this.isLoadingGettingFinanciers) {
                console.log('financiadores en null:');
                this.entitiesFacade.loadFinanciers();
            } else {

                console.log('financiadores ac:', financiers);
                this.financiers = financiers;

            }
            this._operators = this.entitiesFacade.loadOperators({ active: true }).subscribe((operators: Operator[]) => {
                this.operators = operators;
            })

            this._orders = this.casesFacade.getProvisionalControlOrders$().subscribe(orders => {
                if (!!orders) {
                    this.orders = orders.map(or => {
                        or.casee.financier = this.financiers?.find(financier => financier.id == or.casee.financierId)
                        or.casee.operator = this.operators?.find(op => op.id == or.casee.operatorId);
                        return or;
                    });
                }
            })
        });

        this._provisionFees = this.casesFacade.getProvisionalControlProvisionFees$().subscribe(provFees => {
            if (!!provFees) {
                this.provisionFees = provFees.map(pf => {
                    pf.casee.financier = this.financiers?.find(financier => financier.id == pf.casee.financierId);
                    pf.casee.operator = this.operators?.find(op => op.id == pf.casee.operatorId);
                    return pf
                })
            }
        })

    }

    loadWithFilterDefault() {
        let cfp: CasesFilterParameters = {};

        if (this.verifyUserTypeAdmin()) {
            delete this.auxInitialFilters.active
            this.auxInitialFilters.closeLastTrimester = true
        }

        if (this.storageService.getObjectfromSessionStorage('filterListProvisionalControl')) {
            cfp = this.storageService.getObjectfromSessionStorage('filterListProvisionalControl');
        } else {
            this.storageService.setObjectToSessionStorage('filterListProvisionalControl', this.auxInitialFilters)
            cfp = this.auxInitialFilters;
        }

        if (!!cfp?.toDateProvisionFee) {
            cfp.toDateProvisionFee = moment(cfp.toDateProvisionFee, 'YYYY-MM-DD').toDate();
        }

        if (!!cfp?.byArrangementDate) {
            cfp.byArrangementDate = moment(cfp.byArrangementDate, 'YYYY-MM-DD').toDate();
        }

        if (!!cfp?.tableOption) {
            delete cfp.tableOption
        }

        this.refreshIndicators(cfp);

        if (this.globalVersion === 'v2') {
            this.casesFacade.loadCasesFromProvisionalControlIndicators({ ...cfp, compose: true }, this.globalVersion);
        } else {
            this._casesLoaded = this.casesFacade.loadCases(cfp, false, this.globalVersion).subscribe();
        }
    }

    refreshIndicators(cfp: CasesFilterParameters) {
        let qps: ProvisionalControlIndicatorsQPS = {
            requested: 0,
        };
        if (!!cfp.derived) {
            qps.derived = cfp.derived;
        }
        if (!!cfp.byArrangement) {
            qps.byArrangement = cfp.byArrangement;
            qps.byArrangementDate = moment(cfp.byArrangementDate, "YYYY-MM-DD").toDate();
        }
        if (!!cfp.financierId) {
            qps.financierId = cfp.financierId;
        }
        if (!!cfp.toDateProvisionFee) {
            qps.toDateProvisionFee = moment(cfp.toDateProvisionFee).toDate();
        }
        if (!!cfp.operatorId) {
            qps.operatorId = cfp.operatorId;
        }
        if (!!cfp.companyTypeId) {
            qps.companyTypeId = cfp.companyTypeId;
        }
        if ("rejected" in cfp) {
            qps.rejected = cfp.rejected;
        }
        this.casesFacade.loadProvisionalControlIndicators({ ...qps, closeLastTrimester: true, rejected: 0 }, this.globalVersion);
        this.casesFacade.loadProvisionFeesIndicators({ ...qps, active: true });
        this.casesFacade.loadOrdersIndicators({ ...qps, active: true })
    }

    hasNumbers(st: string): boolean {
        let numbers = "0123456789";
        for (let i = 0; i < st.length; i++) {
            if (numbers.indexOf(st.charAt(i), 0) != -1) {
                return true;
            }
        }
        return false;
    }

    hasLetters(st: string): boolean {
        const numbers = '0123456789';
        for (let i = 0; i < st.length; i++) {
            if (numbers.indexOf(st.charAt(i), 0) == -1) {
                return true;
            }
        }
        return false;
    }

    getActualFinancierName(financiers: Financier[]) {
        return financiers.find(financier => financier.id == this.metaDataCases.financierId).name
    }


    setDataSourceAttributes() {
        this.dataSource.sort = this.sort;
        if (this.paginator != null && this.paginator != undefined) {
            this.paginator.pageIndex = this.metaDataCases.page;
            this.paginator.pageSize = this.metaDataCases.size;
            this.paginator.length = this.numberOfRows;
            this.paginator._intl.itemsPerPageLabel = 'Registros por página';
            this.paginator._intl.firstPageLabel = 'Primera página';
            this.paginator._intl.lastPageLabel = 'Última página';
            this.paginator._intl.nextPageLabel = 'Página siguiente';
            this.paginator._intl.previousPageLabel = 'Página anterior';
        }
    }

    onPaginatorEvent() {
        switch (this.optionSelected) {
            case ProvisionalControlTableOptions.CASES:
                let cfp: CasesFilterParameters = this.metaDataCases;
                cfp.size = this.paginator.pageSize;
                cfp.page = this.paginator.pageIndex;
                // Update new filter in session
                this.storageService.setObjectToSessionStorage('filterListProvisionalControl', cfp);

                if (this.globalVersion === 'v2') {
                    this.casesFacade.loadCasesFromProvisionalControlIndicators({ ...cfp, compose: true }, this.globalVersion);
                } else {
                    this._casesLoaded = this.casesFacade.loadCases({ ...cfp, closeLastTrimester: true }, false, this.globalVersion).subscribe();
                }

                break;
            case ProvisionalControlTableOptions.ORDERS:
                this.loadOrders(this.orderStateSelected, false);
                break;
            case ProvisionalControlTableOptions.PROVISIONFEES:
                this.loadProvisionFees(this.provisionFeeStateSelected, false);
                break;
        }
    }

    onSortEvent(e) {
        let cfp: CasesFilterParameters = this.metaDataCases;
        // cfp.page = 0;
        delete cfp?.tableOption;
        cfp.order = e.direction.toUpperCase();
        cfp.sort = this.sort.active;
        this.storageService.setObjectToSessionStorage('filterListProvisionalControl', cfp);
        if (this.globalVersion === 'v2') {
            this.casesFacade.loadCasesFromProvisionalControlIndicators({ ...cfp, compose: true }, this.globalVersion);
        } else {
            this._casesLoaded = this.casesFacade.loadCases(cfp, false, this.globalVersion).subscribe();
        }
    }

    onClickRow(row) {
        // If set date then set date to history mode
        const caseId = this.optionSelected == ProvisionalControlTableOptions.CASES ? row?.id : row.casee?.id;
        const state = moment(this.filterForm.get('dateTo').value).isValid() ? { state: { filteredDate: moment(this.filterForm.get('dateTo').value).toDate() } } : {}
        if (this.verifyUserTypeAdmin()) {
            this.generalService.setGoBack({ route: ROUTES.PROVISIONS_CONTROL, title: 'Control prestacional' });
            this.router.navigate([ROUTES.PROVISIONS_CONTROL, caseId], state);
        } else {
            this.generalService.setGoBack({ route: ROUTES.CASES, title: 'Listado de casos' });
            this.router.navigate([ROUTES.CASES, caseId], state);
        }
    }


    subscribeInputSearch() {
        // Quickfilter is saved too in session
        if (this.storageService.getObjectfromSessionStorage('filterListProvisionalControl')) {
            const filter = this.storageService.getObjectfromSessionStorage('filterListProvisionalControl');
            if (filter.searchPatient || filter.searchCif || filter.searchDocument) {
                this.inputSearch.nativeElement.value = filter.searchPatient || filter.searchCif || filter.searchDocument;
            }
        }

        this._input = fromEvent(this.inputSearch.nativeElement, 'keyup').
            pipe(
                map((event: any) => event.target.value),
                startWith(''),
                filter((value: string) => (value.length != 1 && value.length != 2)),
                skip(1),
                debounce((string: string) => (string.length > 1) ? timer(500) : timer(1250)),
            ).subscribe((st) => {
                let cfp: CasesFilterParameters = this.metaDataCases;

                console.log("Quickfilter cases");

                delete cfp.searchPatient;
                delete cfp.searchCif;
                delete cfp.searchDocument;

                if (st.length != 0) {
                    if (this.hasNumbers(st) && this.hasLetters(st)) {
                        // By Cif
                        cfp.searchCif = st;
                    } else if (this.hasNumbers && !this.hasLetters(st)) {
                        // By Document
                        cfp.searchCif = st;
                        cfp.searchDocument = st;
                    } else {
                        // By Patient
                        cfp.searchPatient = st;
                    }
                }
                cfp.page = 0;
                this.metaDataCases = cfp;
                switch (this.optionSelected) {
                    case ProvisionalControlTableOptions.CASES:
                        this.onFilterV2(false, this.globalVersion);
                        break;

                    case ProvisionalControlTableOptions.ORDERS:
                        this.loadOrders(this.orderStateSelected);
                        break;

                    case ProvisionalControlTableOptions.PROVISIONFEES:
                        this.loadProvisionFees(this.provisionFeeStateSelected);
                        break;
                }
            });
    }

    spinnerFunction(value) {
        return value;
    }

    getAuthorizationState(caseAuthorizationState: CASE_AUTHORIZATIONS_STATES, casee: Case) {
        switch (caseAuthorizationState) {
            case CASE_AUTHORIZATIONS_STATES.VALID:
                return this.datePipe.transform(casee.closestAuthorization.toDate, 'dd/MM/yyyy');
            case CASE_AUTHORIZATIONS_STATES.CLOSE_TO_EXPIRED:
                return this.datePipe.transform(casee.closestAuthorization.toDate, 'dd/MM/yyyy');
            case CASE_AUTHORIZATIONS_STATES.EXPIRED:
                return this.datePipe.transform(casee.closestAuthorization.toDate, 'dd/MM/yyyy');
            case CASE_AUTHORIZATIONS_STATES.UNAUTHORIZED:
                return "NO AUTORIZADO";
        }
    }

    getColorAuthorizationState(caseAuthorizationState: CASE_AUTHORIZATIONS_STATES) {
        switch (caseAuthorizationState) {
            case CASE_AUTHORIZATIONS_STATES.VALID:
                return "#21A35C";
            case CASE_AUTHORIZATIONS_STATES.CLOSE_TO_EXPIRED:
                return "#FF7A00";
            case CASE_AUTHORIZATIONS_STATES.EXPIRED:
                return "#FF5F3C";
            case CASE_AUTHORIZATIONS_STATES.UNAUTHORIZED:
                return "#FFB800";
        }
    }

    getBackgroundColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_BACKGROUND_COLORS[state]
    }

    onClickCardIndicator(state: string) {
        if (this.optionSelected != ProvisionalControlTableOptions.CASES) {
            this.optionSelected = ProvisionalControlTableOptions.CASES;
        }

        // Click on cardindicator
        if (this.provisionalControlIndicators.find(indicator => indicator.name === state) != null) {
            this.onSelectIndicatorFilter(state);
        } else {
            // If option isn't in indicator then it's caseState
            this.caseState = this.casesStates.find(cs => cs.name === state).id;
            this.onFilterV2(false, this.globalVersion);
        }
    }

    onSelectIndicatorFilter(event) {
        this.indicatorStateSelected = this.provisionalControlIndicators.find(ind => ind.id == event || ind.state == event);
        if (this.optionSelected != ProvisionalControlTableOptions.CASES) {
            this.optionSelected = ProvisionalControlTableOptions.CASES;
        }
        switch (this.indicatorStateSelected.state) {
            case this.provisionalControlIndicatorsStates.TODOS:
                // Nothing TO-DO
                break;
            case this.provisionalControlIndicatorsStates.OPERABLES:
                // Nothing TO-DO
                break;
            case this.provisionalControlIndicatorsStates.INFORMABLES:
                // Nothing TO-DO
                break;
            case this.provisionalControlIndicatorsStates.CON_INSUMOS_NO_PEDIDOS:
                // Nothing TO-DO
                break;
            case this.provisionalControlIndicatorsStates.INFORMADOS:
                // Nothing TO-DO
                break
            default:
                console.log("Switch error");
                break
        }
        this.onFilterV2(false, this.globalVersion);
    }

    onSelectFinancier(id: number) {
        this.resetFinancierSelection();
        this.companyTypesList = [];
        this.financierSelected = this.financiers.find(f => f.id == id);
        this.companyTypesList = this.financierSelected?.companyTypes ? this.financierSelected.companyTypes : null;
        this.isDisabledCompanyType = this.companyTypesList ? false : true;
        if (!this.financierSelected) {
            this.filterForm.get(this.FINANCIER).reset();
        }
    }

    resetFinancierSelection() {
        this.filterForm.get(this.FINANCIER_SEARCHING).reset();
        this.filterForm.get(this.COMPANY_TYPE).reset();
    }

    resetFilters() {
        this.indicatorStateSelected = this.provisionalControlIndicators.find(ind => ind.state == ProvisionalControlIndicatorsStates.TODOS);
        this.filterForm.get(this.FINANCIER).reset()
        this.filterForm.get(this.FINANCIER_SEARCHING).reset();
        this.filterForm.get(this.COMPANY_TYPE).reset();
        this.filterForm.get(this.COMPANY_TYPE_SEARCHING).reset();
        this.filterForm.get(this.DATE_TO).reset();
        // this.filterForm.get(this.OPERATOR).reset();
        this.operator = null;
        this.filterForm.get(this.OPERATOR_SEARCHING).reset();
        this.filterForm.get(this.PATIENT).reset();
        this.filterForm.get(this.PATIENT_SEARCHING).reset();
        this.filterForm.get(this.PROVISIONAL_STATE).reset();

        // Reset to init
        this.storageService.setObjectToSessionStorage('filterListProvisionalControl', this.initialFilters);

        this.onFilterV2(true, this.globalVersion);
    }
    verifyUserTypeAdmin() {
        return this.authService.isAdmin();
    }

    getDateFromForm() {
        return moment(this.filterForm.get(this.FROM_DATE).value).format("DD/MM/YYYY");
    }

    openCalendar() {
        this.openedCalendar = true;
    }

    isCalendarOpen(event: boolean) {
        this.openedCalendar = !event
    }

    loadProvisionFees(indicator?: string, restartPaginator: boolean = true) {
        let qps: ProvisionFeesIndicatorsQPS = {
            active: true,
            requested: 0,
            rejected: 0
        }

        if (restartPaginator && !!this.paginator) {
            this.paginator.pageIndex = 0;
        }

        if (!!this.operator) {
            qps.operatorId = this.operator;
        }

        if (!!this.financier) {
            qps.financierId = this.financier;
        }

        if (this.companyType != null) {
            qps.companyTypeId = this.companyType;
        }

        if (!!this.dateTo) {
            qps.toDateProvisionFee = moment(this.dateTo).toDate();
        }

        if (!!this.metaDataCases.searchCif) {
            qps.searchCif = this.metaDataCases.searchCif;
        }

        if (!!this.metaDataCases.searchDocument) {
            qps.searchDocument = this.metaDataCases.searchDocument;
        }

        if (!!this.metaDataCases.searchPatient) {
            qps.searchPatient = this.metaDataCases.searchPatient;
        }

        if (!!indicator) {
            let indicatorSelected = this.provisionFeeStates.find(st => st.state == indicator || st.id.toString() == indicator);
            if (indicatorSelected.state != ProvisionalControlProvisionFeeStates.ALL) {
                qps.indicator = indicatorSelected.state;
            }
            this.provisionFeeStateSelected = indicatorSelected.id.toString();
        }

        if (this.paginator != null && this.paginator != undefined) {
            qps.page = this.paginator.pageIndex;
            qps.size = this.paginator.pageSize
        } else {
            qps.page = 0;
            qps.size = 10;
        }

        if (this.optionSelected != ProvisionalControlTableOptions.PROVISIONFEES) {
            this.optionSelected = ProvisionalControlTableOptions.PROVISIONFEES;
        }

        this.casesFacade.loadProvisionalControlProvisionFees(qps);
    }

    loadOrders(indicator?: string, restartPaginator: boolean = true): void {
        let qps: CasesFilterParameters = {
            active: true,
            requested: 0,
            rejected: 0,
            order: 'ASC',
            sort: 'surname',
        }
        if (restartPaginator && !!this.paginator) {
            this.paginator.pageIndex = 0;
        }
        if (!!this.operator) {
            qps.operatorId = this.operator;
        }
        if (!!this.financier) {
            qps.financierId = this.financier;
        }
        if (this.companyType != null) {
            qps.companyTypeId = this.companyType;
        }
        if (!!this.dateTo) {
            qps.toDateProvisionFee = this.dateTo;
        }

        if (!!this.metaDataCases.searchCif) {
            qps.searchCif = this.metaDataCases.searchCif;
        }

        if (!!this.metaDataCases.searchDocument) {
            qps.searchDocument = this.metaDataCases.searchDocument;
        }

        if (!!this.metaDataCases.searchPatient) {
            qps.searchPatient = this.metaDataCases.searchPatient;
        }

        if (this.optionSelected != ProvisionalControlTableOptions.ORDERS) {
            this.optionSelected = ProvisionalControlTableOptions.ORDERS;
        }

        if (!!indicator) {
            let indicatorSelected = this.orderStates.find(st => st.state == indicator || st.id.toString() == indicator);
            if (indicatorSelected.state != ProvisionalControlOrderStates.ALL) {
                qps.indicator = indicatorSelected.state;
            }
            this.orderStateSelected = indicatorSelected.id.toString();
        }
        if (this.paginator != null && this.paginator != undefined) {
            qps.page = this.paginator.pageIndex;
            qps.size = this.paginator.pageSize
        } else {
            qps.page = 0;
            qps.size = 10;
        }
        this.casesFacade.loadProvisionalControlOrders(qps);
    }

    onSelectTableOption() {
        switch (this.optionSelected) {
            case ProvisionalControlTableOptions.CASES:
                this.onSelectIndicatorFilter(0)
                break;
            case ProvisionalControlTableOptions.ORDERS:
                this.loadOrders(ProvisionalControlOrderStates.ALL)
                break;
            case ProvisionalControlTableOptions.PROVISIONFEES:
                this.loadProvisionFees()
                break;
            default:
                break;
        }
    }

    getCaseTypesList(arr, separator: string) {
        let caseTypes = []
        if (!!arr && arr.length > 0) {
            caseTypes = arr.map(item => item.name)
            return caseTypes.join(`${separator}`)
        }
        return "-"
    }

    updateIndicators() {
        this.caseIndicators = [{
            name: 'OPERABLES',
            counter: this.counterOperableIndicator,
            indicatorState: this.provisionalControlIndicatorsStates.OPERABLES,
            color: 'label-1'
        },
        {
            name: 'INFORMABLES',
            counter: this.counterInformableIndicator,
            indicatorState: this.provisionalControlIndicatorsStates.INFORMABLES,
            color: 'label-2'
        }]
    }

    getPeriod(): string {
        // Convertir la fecha de Date a moment

        const fechaActual = this.dateTo ? this.dateTo : moment();
        const fechaMoment = moment(fechaActual);

        // Restar 3 meses y ajustar al primer día de ese mes
        const fechaInicio = fechaMoment.clone().subtract(3, 'months').startOf('month');

        // Formatear ambas fechas
        const fechaFormateadaInicio = fechaInicio.format('DD/MM/YYYY');
        const fechaFormateadaFin = fechaMoment.format('DD/MM/YYYY');

        // Retornar el período en formato "01/05/2024 al 15/08/2024"
        if (!!this.dateTo) {
            return `${fechaFormateadaInicio} al ${fechaFormateadaFin}`;
        } else {
            return `${fechaFormateadaInicio} en adelante`;
        }
    }

    handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
