import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ProvisionsUnit } from '../../../../core/enums/provisionsUnit';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { Practice } from '../../../../shared/models/practice';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { PostProvisionBody } from '../../../../core/services/provisions.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-admissions-supplies',
    templateUrl: './admissions-supplies.component.html',
    styleUrls: ['./admissions-supplies.component.scss']
})
export class AdmissionsSuppliesComponent implements OnInit {

    public readonly PRACTICE = 'practiceId';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly AMOUNT = 'amount';
    public readonly UNIT = 'unit';

    btn_addSupply_disabled: boolean = true;
    practices: Practice[];

    @Output() suppliesData = new EventEmitter<PostProvisionBody[]>();
    @Output() formSuppliesDataValid = new EventEmitter<boolean>();

    provisionsUnit = [
        {
           id: ProvisionsUnit.UNIDAD,
           name: 'Unidad',
        },
        {
           id: ProvisionsUnit.BOLSA_BOLSON,
           name: 'Bolsa/Bolsón',
        },
        {
           id: ProvisionsUnit.PAQUETE,
           name: 'Paquete',
        },
        {
           id: ProvisionsUnit.CAJA,
           name: 'Caja',
        },
     ];

    public get supplies(): FormArray {
        return this.suppliesForm.get("supplies") as FormArray
    }

    suppliesForm: FormGroup;
    _practices: Subscription;

    constructor(private fb: FormBuilder,
                private nomenclatorFacade: NomenclatorFacade) {

        this.suppliesForm = this.fb.group({
            supplies: this.fb.array([])
        });

    }

    ngOnInit(): void {

        this._practices = this.nomenclatorFacade.getPractices$().subscribe( practices => {
            this.practices = practices?.filter((practice) => practice.specialty?.isSupply && practice.name != 'Entrega insumos');
        })

        this.addSupply();

        // SUPPLIES
        this.suppliesForm.statusChanges.subscribe(formSupplies => {
            if (this.btn_addSupply_disabled = formSupplies === 'INVALID') {
                this.formSuppliesDataValid.emit(false);
            } else {
                this.formSuppliesDataValid.emit(true);
            }
        })

        this.suppliesForm.valueChanges.subscribe(supplies => {
            if (this.suppliesForm.status === 'VALID') {

                this.suppliesData.emit( this.suppliesForm.value.supplies.map(({ practiceSearching, ...newSupplies }) => {
                    const frequency = {
                        amount:newSupplies['amount'],
                        unit:newSupplies['unit']
                    }
                    let practice = this.practices.find(pr => pr.id  == newSupplies['practiceId']);
                    let fixedPrice:boolean = !!practice && practice.code?.startsWith('ME');
                    newSupplies = { ...newSupplies, frequency:frequency, fixedPrice: fixedPrice }; // Add frecuency and kairos
                    const { amount, unit, ...editNewSupply } = newSupplies;
                    return editNewSupply
                }));
                this.formSuppliesDataValid.emit(true);
            } else {
                this.formSuppliesDataValid.emit(false);
                this.suppliesData.emit( [] );
            }
        })
    }

    newSupply(): FormGroup {
        return this.fb.group({
            [this.PRACTICE]: ['', [CustomValidators.required('Insumo requerido')]],
            [this.PRACTICE_SEARCHING]: [''],
            [this.AMOUNT]: ['', ], // [CustomValidators.required('Cantidad requerida')]
            [this.UNIT]: ['', ], // [CustomValidators.required('Unidad requerida')]
        })
    }

    addSupply() {
        this.supplies.push(this.newSupply());
    }

    removeSupply(i: number) {
        if (this.supplies['controls'].length == 1) {
            // this.supplies['controls'][i].reset();
            this.supplies.removeAt(i);
            this.addSupply();
        } else {
            this.supplies.removeAt(i);
        }
    }

    calculateNameCodeUnit(element) {
        return element ? element.name + ' #' + element.code : null;
    }

    calculateNameUnit(element) {
        return element ? element.name : null;
     }

     calculateIdUnit(element) {
        return element ? element.id : null;
     }

     onSelectPractice(supplyId: number) {
        let practice  = this.practices.find(pr => pr.id == supplyId);
        if(!!practice && practice.code.startsWith('ME')){
            this.suppliesForm
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._practices.unsubscribe();
    }

    getSuppliesFormGroup(index: number): FormGroup {
        return this.supplies.at(index) as FormGroup;
    }
}
