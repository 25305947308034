import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MarkerGoogleMaps } from '../models/maps/markerGoogleMaps';
import { MapsSate } from 'src/app/core/states/maps.state';
import { catchError, filter, firstValueFrom, forkJoin, map, Observable } from 'rxjs';
import { IconMapMarker } from 'src/app/core/enums/iconMapMarker';
import { GeneralService } from 'src/app/core/services/general.service';
import { Patient } from '../models/patient';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { Provider } from '../models/provider';
import { LocationPOI } from '../models/maps/locationPOI';
import { AlertService } from 'src/app/core/services/alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class MapsService {

    constructor(
        private http: HttpClient,
        private mapsState: MapsSate,
        private generalService: GeneralService,
        private alertService: AlertService,
    ) {
        //this.preLoadMapIcons();
    }

    private svgMapIcons: { [key: string]: any } = {};
    private apiLoaded = false;

    public async loadApi(): Promise<void> {
        if (this.apiLoaded) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                this.apiLoaded = true;
                resolve();
            };
            script.onerror = (error) => {
                console.error('Error al cargar Google Maps API:', error);
                reject(error);
            };
            document.head.appendChild(script);
        });
    }

    private geoCodeCache: Map<string, { lat: number, lng: number }> = new Map();

    public async geoCodeAddress(address: string): Promise<{ lat: number, lng: number }> {
        if (this.geoCodeCache.has(address)) {
            return this.geoCodeCache.get(address)!;
        }

        try {
            const coords = await new Promise<{ lat: number, lng: number }>((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ 'address': address }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const location = results[0].geometry.location;
                        resolve({ lat: location.lat(), lng: location.lng() });
                    } else {
                        reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                    }
                });
            });

            this.geoCodeCache.set(address, coords);
            return coords;
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     * @param locations
     */
    public async setGeoLocationsToMarkers(locations: MarkerGoogleMaps[]): Promise<void> {
        const geocodedMarkers: Promise<MarkerGoogleMaps>[] = locations.map(async (location) => {
            if (location?.address) {
                try {
                    const coords = await this.geoCodeAddress(location.address);
                    return {
                        ...location,
                        lat: coords.lat,
                        lng: coords.lng,
                        svgIcon: this.getMarkerIcon(location)
                    };
                } catch (error) {
                    console.error(`Error geocoding address ${location.address}: ${error}`);
                    return null; // Fail location
                }
            }
            return null; // Null if address empty
        });
        // Wait for all promises
        const markers = (await Promise.all(geocodedMarkers)).filter(marker => marker !== null);
        // Set markers
        this.mapsState.setMarkers(markers);
    }

    public async mergeGeoLocationsToMarkers(locations: MarkerGoogleMaps[]): Promise<void> {
        const geocodedMarkers: Promise<MarkerGoogleMaps>[] = locations.map(async (location) => {
            if (location?.address) {
                try {
                    const coords = await this.geoCodeAddress(location.address);
                    return {
                        ...location,
                        lat: coords.lat,
                        lng: coords.lng,
                        svgIcon: this.getMarkerIcon(location)
                    };
                } catch (error) {
                    console.error(`Error geocoding address ${location.address}: ${error}`);
                    return null; // Fail location
                }
            }
            return null; // Null if address empty
        });
        // Wait for all promises
        const markers = (await Promise.all(geocodedMarkers)).filter(marker => marker !== null);

        // Set markers
        this.mapsState.mergeMarkers(markers);
    }

    public async deleteMarkersByType(type: TypeMapMarker) {
        const getMarkers = this.mapsState.getMarkers();
        const filteredMarkers = getMarkers?.filter(marker => marker.type != type)
        this.mapsState.setMarkers(filteredMarkers);
    }

    public async deleteMarkersByTypes(types: TypeMapMarker[]): Promise<void> {
        const getMarkers = this.mapsState.getMarkers();
        const filteredMarkers = getMarkers?.filter(marker => !types.includes(marker.type));
        if (filteredMarkers.length == 0) {
            this.mapsState.setMarkers(null);
        } else {
            this.mapsState.setMarkers(filteredMarkers);
        }
    }

    /**
     *
     * @param location
     */
    public addGeoLocationToMarkers(location: MarkerGoogleMaps) {
        if (!!location?.address) {
            this.geoCodeAddress(location.address).then(coords => {
                this.mapsState.addMarker({ ...location, lat: coords.lat, lng: coords.lng, svgIcon: this.getMarkerIcon(location) });
            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     *
     * @param location MarkerGoogleMaps
     * @returns lat & lng
     */
    public async getGeoLocation(location: MarkerGoogleMaps): Promise<MarkerGoogleMaps | null> {
        if (location?.address) {
            try {
                const coords = await this.geoCodeAddress(location.address);
                return { ...location, lat: coords.lat, lng: coords.lng };
            } catch (error) {
                console.error(`Error geocoding address ${location.address}: ${error}`);
                return null;
            }
        }
        return null;
    }

    // public addMarkers(location: MarkerGoogleMaps[]): void {
    //     console.log('addMarkers()');

    // }

    // public addMarker(location: MarkerGoogleMaps): void {
    //     console.log('addMarker()');
    //     this.setIconToMarker(location);
    //     this.mapsState.addMarker(location);
    // }

    // private getMarkerIcon(marker: MarkerGoogleMaps) {
    //     let svgIcon = this.getSvgForMap(marker.icon);

    //     if (!svgIcon) {
    //         console.error(`SVG doesn't loaded: ${marker.icon}`);
    //         return null;
    //     }

    //     svgIcon.style.width = !!marker.svgIconSize ? marker.svgIconSize : '50px';  // Adjust size
    //     svgIcon.style.height = !!marker.svgIconSize ? marker.svgIconSize : '50px';  // Adjust size
    //     return svgIcon;
    // }

    public getMarkers$(): Observable<MarkerGoogleMaps[]> {
        return this.mapsState.getMarkers$();
    }

    public getMarkers(): MarkerGoogleMaps[] {
        return this.mapsState.getMarkers();
    }

    // private preLoadMapIcons() {
    //     for (const icon of Object.values(IconMapMarker)) {
    //         this.generalService.getSvgMapIcon(icon).subscribe(svg => {
    //             this.svgMapIcons[icon] = svg;
    //         });
    //     }
    // }

    // private getSvgForMap(icon: IconMapMarker): any {
    //     const parser = new DOMParser();
    //     try {
    //         // const svgDocument = parser.parseFromString(this.svgMapIcons[icon], "image/svg+xml").documentElement;
    //         const svgDocument = this.generalService.getSvgMapIcon(icon);

    //         // Check if element is an SVGElement
    //         if (svgDocument instanceof SVGElement) {
    //             return svgDocument;
    //         } else {
    //             console.error("SVG doesn't loaded", svgDocument);
    //             this.alertService.openError('Error en la carga de prestadores, recargue la web');
    //             return null;
    //         }
    //     } catch(error) {
    //         console.error("SVG doesn't loaded", error);
    //         this.alertService.openError('Error en la carga de prestadores, recargue la web');
    //         return null;
    //     }
    // }

    private getMarkerIcon(marker: MarkerGoogleMaps): SVGElement | null {
        const svgIcon = this.getSvgMapIcon(marker.icon);
        if (!svgIcon) {
            console.error(`No se pudo obtener el ícono SVG para ${marker.icon}`);
            return null;
        }

        // Clonar el SVG antes de usarlo, para evitar reutilizar el mismo nodo
        const clonedSvgIcon = svgIcon.cloneNode(true) as SVGElement;
        clonedSvgIcon.style.width = marker.svgIconSize || '50px';
        clonedSvgIcon.style.height = marker.svgIconSize || '50px';
        return clonedSvgIcon;
    }

    public castPatientFromCaseToMarkerGoogleMaps(patient: Patient, type?: TypeMapMarker.PATIENT_ACTIVE | TypeMapMarker.PATIENT_INACTIVE | TypeMapMarker.GENERAL | TypeMapMarker.CASE): MarkerGoogleMaps {
        return {
            type,
            name: `${patient.surname}, ${patient.name}`,
            phone: patient.phoneNumber,
            address: `${patient.address.street} ${patient.address?.streetNumber}, ${patient.address?.location}, ${patient.address?.province}`,
            email: patient.email,
            lat: !!patient.address.latitude ? patient.address.latitude : null,
            lng: !!patient.address.longitude ? patient.address.longitude : null,
            icon: IconMapMarker.PATIENT,
            svgIcon: this.getMarkerIcon({type, svgIconSize:'50px', icon:IconMapMarker.PATIENT}),
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            // svgIconSize?: string,
        }
    }

    public castPatientToMarkerGoogleMaps(patient: Patient, type?: TypeMapMarker.PATIENT_ACTIVE | TypeMapMarker.PATIENT_INACTIVE | TypeMapMarker.GENERAL): MarkerGoogleMaps {
        return {
            type,
            name: `${patient.surname}, ${patient.name}`,
            phone: patient.phoneNumber,
            address: `${patient.address.street} ${patient.address?.streetNumber}, ${patient.address?.location?.name}, ${patient.address?.location?.province?.name}`,
            email: patient.email,
            lat: !!patient.address.latitude ? patient.address.latitude : null,
            lng: !!patient.address.longitude ? patient.address.longitude : null,
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            icon: IconMapMarker.PATIENT,
            svgIcon: this.getMarkerIcon({type, svgIconSize:'50px', icon:IconMapMarker.PATIENT}),
            // svgIconSize?: string,
        }
    }

    //Convert cuit to dni
    private getDni(value: number): number {
        const string = value.toString();
        const length = string.length;
        const res = string.slice(2, length - 1);
        return parseInt(res);
    }

    public castProviderToMarkerGoogleMaps(provider: Provider): MarkerGoogleMaps {
        const type = TypeMapMarker.PROVIDER;
        return {
            type: TypeMapMarker.PROVIDER,
            name: `${provider.surname}, ${provider.name}`,
            phone: provider?.mobilePhoneNumber ? provider.mobilePhoneNumber : provider?.phoneNumber ? provider?.phoneNumber : null,
            address: `${provider.address?.street} ${provider.address?.streetNumber}, ${provider.address?.location?.name}, ${provider.address?.location?.province?.name}`,
            email: provider.email,
            dni: this.getDni(provider.cuit),
            specialties: provider.specialties,
            lat: !!provider.address.latitude ? provider.address.latitude : null,
            lng: !!provider.address.longitude ? provider.address.longitude : null,
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            icon: IconMapMarker.PROVIDER,
            svgIcon: this.getMarkerIcon({type, svgIconSize:'50px', icon:IconMapMarker.PROVIDER}),
            // svgIconSize?: string,
        }
    }

    public castAddressPatientToAddressMap(patient: Patient): LocationPOI | null {

        let street = patient.address.street ? `${patient.address.street} ` : '';
        let streetNumber = patient.address.streetNumber ? `${patient.address.streetNumber} ` : '';
        let location = patient.address.location ? `,${patient.address.location} ` : '';
        let province = patient.address.province ? `,${patient.address.province} ` : '';

        const addressString = `${street}${streetNumber}${location}${province}`;

        return { address: addressString };
    }


    public castAddressProviderToAddressMap(provider: Provider): LocationPOI | null {
        return { address: this.castProviderToMarkerGoogleMaps(provider).address }
    }

    isApiLoaded(): boolean {
        return this.apiLoaded
    }

    // Precargar todos los íconos
    public preloadMapIcons(): Observable<void> {
        const iconUrls = [
          { key: 'providerMap', url: 'assets/icons/providerMap.svg' },
          { key: 'patientMap', url: 'assets/icons/patientMap.svg' },
          // Agrega más íconos según sea necesario
        ];

        const iconRequests = iconUrls?.map(icon =>
          this.http.get(icon.url, { responseType: 'text' }).pipe(
            map((svgString: string) => {
              if (!svgString.trim()) {
                console.warn(`No se pudo cargar el SVG para ${icon.key}`);
                return null; // Retorna null si el SVG no se carga correctamente
              }
              const parser = new DOMParser();
              const svgElement = parser.parseFromString(svgString, 'image/svg+xml').documentElement;
              this.svgMapIcons[icon.key] = svgElement;
              return svgElement;
            }),
            catchError(error => {
              console.error(`Error al cargar el SVG para ${icon.key}:`, error);
              return null;
            })
          )
        );

        return forkJoin(iconRequests).pipe(
          filter(elements => elements.every(element => element !== null)),
          map(() => {
            console.log('SVGs loaded successfully');
            return void 0;
          })
        );
      }


    // Obtener un ícono precargado por su clave
    public getSvgMapIcon(iconKey: string): SVGElement | null {
        return this.svgMapIcons[iconKey] || null;
    }

    // private getMarkerIcon(marker: MarkerGoogleMaps): SVGElement | null {
    //     const svgIcon = this.getSvgIcon(marker.icon);
    //     if (!svgIcon) {
    //         console.error(`No se pudo obtener el ícono SVG para ${marker.icon}`);
    //         return null;
    //     }

    //     // Clonar el SVG antes de usarlo, para evitar reutilizar el mismo nodo
    //     const clonedSvgIcon = svgIcon.cloneNode(true) as SVGElement;
    //     clonedSvgIcon.style.width = marker.svgIconSize || '50px';
    //     clonedSvgIcon.style.height = marker.svgIconSize || '50px';
    //     return clonedSvgIcon;
    // }

    // public getSvgMapIcon(svgName: string): Observable<string> {
    //     const url = `assets/icons/${svgName}.svg`;
    //     return this.http.get(url, { responseType: 'text' }).pipe(
    //         map(svgString => {
    //             return svgString;
    //         })
    //     );
    // }
}
