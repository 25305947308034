import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { RestUtilitiesService } from './rest-utilities.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIS } from '../enums/apis';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { Arrangement } from '../../shared/models/arrangement';
import { ArrangementItem } from 'src/app/shared/models/arrangementItem';
import { Practice } from '../../shared/models/practice';

export interface ArrangementsQPS {
   id?: number;
   name?: string;
   financierId?: number;
   caseId?: number;
   isNullFinancierId?: boolean;
   size?: number;
   page?: number;
   active?: boolean;
}

export interface ArrangementItemsQPS {
   practiceId?: number;
}

export interface PostArrangement {
   name: string;
   financierId: number;
   arrangementId?: number;
   margin?: number;
   roundFee?: number;
}

export interface PatchArrangement {
   id: number;
   name?: string;
   active?: boolean;
   practiceArrangements?: Partial<PatchPracticeArrangement>[];
   margin?: number;
   roundFee?: boolean;
}

export interface PostPracticeArrangement {
   practiceId: number;
   fee: number;
   profitMargin?: number;
   code: string;
   fromDate: Date;
   arrangementId: number;
}

export interface PatchPracticeArrangement {
   id: number;
   code: string,
   practice: Practice,
   fromDate: Date;
   toDate: Date,
   arrangement: Arrangement,
   fee?: number;
   practiceId?: number;
   profitMargin?: number,
   active?: boolean;
   name?: string;
}
export interface PracticeArrangement {
   id?: number;
   active?: boolean;
   code?: string;
   practiceId?: number;
   fromDate?: Date;
   name?: string;
   fee?: number;
   profitMargin?: number;
   arrangementId?: number;
   arrangementName?: string;
}

export interface PracticesArrangementQPS {
   fromDate: Date;
   active: boolean;
}

@Injectable({
   providedIn: 'root',
})
export class ArrangementsService {
   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) {}

   getArrangements(qps?: ArrangementsQPS): Observable<HttpBaseResponse<Arrangement[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string; value: string }[] = this.restUtilitiesService.formatQPs(
         qps,
      );
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);

      return this.http
         .get<any>(`${environment.apiCaseService}${APIS.ARRANGEMENTS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   // WATCH - This method is for one Case
   getArrangementItems(
      arrangementId: number,
      qps?: ArrangementItemsQPS,
   ): Observable<HttpBaseResponse<ArrangementItem[]>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string; value: string }[] = this.restUtilitiesService.formatQPs(
         qps,
      );
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiCaseService}${APIS.ARRANGEMENTS}/${arrangementId}/items`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   // WATCH - This method is for ABM arrangement
   getPracticesArrangement(
      arrangementId: number,
      qps: PracticesArrangementQPS,
   ): Observable<PracticeArrangement[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string; value: string }[] = this.restUtilitiesService.formatQPs(
         qps,
      );
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiCaseService}${APIS.ARRANGEMENTS}/${arrangementId}/practices`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   postPracticeArrangement(
      practiceArrangement: PostPracticeArrangement,
   ): Observable<PostPracticeArrangement> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(
            `${environment.apiCaseService}${APIS.ARRANGEMENTS}/${practiceArrangement.arrangementId}/practices`,
            JSON.stringify(practiceArrangement),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postArrangement(arrangement: PostArrangement): Observable<Arrangement> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(
            `${environment.apiCaseService}${APIS.ARRANGEMENTS}`,
            JSON.stringify(arrangement),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchArrangement(category: PatchArrangement): Observable<HttpBaseResponse<Arrangement>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiCaseService}${APIS.ARRANGEMENTS}`,
            JSON.stringify(category),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getArrangementsCount(qps?: ArrangementsQPS): Observable<HttpBaseResponse<any>> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string; value: string }[] = this.restUtilitiesService.formatQPs(
         qps,
      );
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);

      return this.http
         .get<any>(`${environment.apiCaseService}${APIS.ARRANGEMENTS}/count`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   patchPracticeArrangement(practiceArrangement: PatchPracticeArrangement) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiCaseService}${APIS.ARRANGEMENTS}/${practiceArrangement.id}/practices`,
            JSON.stringify(practiceArrangement),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }
}
