import { Component, OnInit, Input, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import { EventEmitter } from '@angular/core';
import { SelectInputComponent } from '../select-input/select-input.component';
import { takeUntil, take, startWith, map, tap } from 'rxjs/operators';
import { Subject, ReplaySubject, Observable, of } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { StringFilterByPipe } from 'src/app/shared/pipes/string-filter-by-pipe.pipe';
//!RESOLVER: Se selecciona el primer valor de la lista al buscar por el filter y cuando lo selecciono no se llama al onchange
@Component({
    selector: 'app-select-searching-input',
    templateUrl: './select-searching-input.component.html',
    styleUrls: ['./select-searching-input.component.scss']
})
export class SelectSearchingInputComponent extends FormElementComponent
    implements OnInit {
    @ViewChild('singleSelect', { static: true }) singleSelect!: MatSelect;
    @Input() elements!: Array<any>;
    @Input() noOptionText!: string;
    @Input() nameSearching!: string;
    @Input() placeholderNoValue!: string;
    @Input() placeholderSearching!: string;
    @Input() defaultValue!: any;
    // Input provided that indicates if the values of the mat-options will be computed as strings or numbers
    @Input() forceNumber!: string;
    // Functions provided by the parent that tells the component how to calculate the id and name of the options
    @Input() calculateId: (element:any) => any = (e) => e.id;
    @Input() calculateName: (element:any) => any = (e) => e.name;
    // Emit an event every time the select changes
    @Output() public selected: EventEmitter<any> = new EventEmitter();
    /** Subject that emits when the component has been destroyed. */
    /** list of banks filtered by search keyword */
    searchPipe = new StringFilterByPipe();
    searchingSpinner = false;
    public filteredValues!: Observable<any>;
    protected _onDestroy = new Subject<void>();
    get searchCtrl() {
        return this.form.get(this.nameSearching);
    }
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    // Static function that returns the appropriate function according to the "calculate" parameter
    static proxyCalculate(calculate:any): (element:any) => string {
        if (typeof calculate === 'string') {
            return element => {
                return element ? element[String(calculate)] : null;
            };
        } else {
            return calculate;
        }
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Id
    proxyCalculateId(): (element:string) => string {
        return SelectInputComponent.proxyCalculate(this.calculateId);
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Name
    proxyCalculateName(): (element:string) => string {
        return SelectInputComponent.proxyCalculate(this.calculateName);
    }

    override ngOnInit() {
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue.toString());
        }
        if (this.forceNumber) {
            // Subscribe at any change in the select form control and everytime a new option is
            // selected, convert the value to Number if it isn't
            this.form.get(this.name)?.valueChanges.subscribe(value => {
                if (typeof value === 'string') {
                    this.form.get(this.name)?.setValue(Number(value));
                }
            });
        }
        this.filteredValues = (this.searchCtrl?.valueChanges || of())
            .pipe(
                tap(_ => this.searchingSpinner = true),
                startWith(''),
                map(country => country ? this.searchPipe.transform(this.elements, country, this.proxyCalculateName()) : this.elements?.slice()),
                tap(_ => this.searchingSpinner = false)
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue.toString());
        }
        this.filteredValues = (this.searchCtrl?.valueChanges || of())
            .pipe(
                tap(_ => this.searchingSpinner = true),
                startWith(''),
                map(country => country ? this.searchPipe.transform(this.elements, country, this.proxyCalculateName()) : this.elements?.slice()),
                tap(_ => this.searchingSpinner = false)
            );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    calculateSelected(id: any) {
        let elementSelected = null;
        if (this.elements) {
            elementSelected = this.elements.find(
                element =>
                    this.proxyCalculateId()(element) ===
                    (this.forceNumber ? Number(id) : id)
            );
        }
        return elementSelected;
    }

    change(event: any) {
        // Called everytime a new value is selected
        this.selected.emit(event.value);
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
          return this.objectValues(control.errors)[0];
        }
        return null;
      }
}
