<div class="flex-100" *ngIf="!!this.case">
    <mat-card class="mat-elevation-z1 card-shadow flex-column">
        <div class="flex-row align-start-center">
            <div class="flex-7 align-center-center">
                <mat-icon [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                    class="mat-icon-extraLarge">person_pin</mat-icon>
            </div>
            <div class="flex align-center-start flex-column">
                <span class="mat-h2 mb-0 text-secondary-darker">
                    {{ this.case.patient.surname }}, {{ this.case.patient.name }}
                </span>
                <span class="text-secondary">{{ this.case.patient.typeDocument ? this.case.patient.typeDocument :
                    'DNI'}}
                    {{ this.case.patient.documentNumber ? this.case.patient.documentNumber : 'Sin datos' }}</span>
            </div>
        </div>
        <mat-card-content class="flex-row flex-wrap text-secondary-darker mts-4 align-space-around" style="gap: 8px;">

            <a [href]="this.getLinkMap()" style="text-decoration: none;" class="text-primary align-start-center"
                target="blank">
                <mat-icon class="mat-icon-medium mrs-1" matTooltip="Buscar en el mapa">where_to_vote</mat-icon>
                <span matTooltip="Buscar en el mapa">{{ this.case.patient.address.street + ' ' +
                    this.case.patient.address.streetNumber }}
                    {{ this.case?.patient?.address?.location?.name | titlecase }}
                    {{this.case?.patient?.address?.location?.province }}
                </span>
            </a>
            <div class="align-start-center">
                <mat-icon class="mat-icon-medium mrs-1">phone_iphone</mat-icon>
                <span>{{ this.case.patient.phoneNumber ? this.case.patient.phoneNumber : 'Sin datos' }}</span>
            </div>
            <div class="align-start-center">
                <mat-icon class="mat-icon-medium mrs-1">local_hospital</mat-icon>
                <span>{{
                    !!this.case.diagnoses
                    ? !!this.case.diagnoses[0]
                    ? this.case.diagnoses[0].name
                    : !!this.case.diagnoses[1]
                    ? this.case.diagnoses[1].name
                    : 'Sin diagnóstico'
                    : 'Sin diagnóstico'
                    }}</span>
            </div>

        </mat-card-content>
    </mat-card>
</div>
