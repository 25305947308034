<div class="flex-column" style="min-height: 100%; height: 100%;">
    <mat-toolbar class="mat-toolbar-right flex-row align-space-between-center">
        <div class="flex-row align-start-center text-primary">
            <span class="mt-2" style="font-size: 18px;">{{this.data.title}}</span>
        </div>
    </mat-toolbar>
    <div class="p-24" style="height: 100%">
        <ng-container [ngTemplateOutlet]="data.template"></ng-container>
    </div>
</div>

<!-- TODO: button close if necessary -->
<!-- <button id="more_Info_SideBarComponent_btnCloseSideBar" mat-icon-button (click)="''" [color]="'primary'">
    <mat-icon class="mls-2">highlight_off</mat-icon>
</button> -->
