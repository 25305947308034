import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, effect, input } from '@angular/core';
import { MaterialModule } from 'src/app/material/material.module';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddressData } from '../../../models/location'
import { Address } from 'src/app/shared/models/address';
import { log } from 'console';

@Component({
    selector: 'app-address-input',
    standalone: true,
    imports: [CommonModule, MaterialModule, ReactiveFormsModule],
    templateUrl: './address-input.component.html',
    styleUrl: './address-input.component.scss'
})
export class AddressInputComponent extends FormElementComponent implements OnInit {
    city = input<string>();
    province = input<string>();
    address = input<Address | AddressData>();
    @Input() maxLength!: number;
    @Input() clearable = false;
    @Input() selectAllOnClick: boolean = false;
    @Output() enter = new EventEmitter();
    @Output() changes = new EventEmitter();
    @Output() selected = new EventEmitter<AddressData>();
    @ViewChild('textInput') textInput!: ElementRef;
    @ViewChild('addressinput', { static: false }) addressinputRef: ElementRef<HTMLInputElement>;
    formAddress: FormGroup;
    public readonly PATIENT_ADDRESS_STREET = 'street';
    private autocomplete!: google.maps.places.Autocomplete;
    private _isAddressValid: boolean = true;
    private _enabledForm: boolean = false;
    private _selectedAddress: AddressData;
    private selectedValidAddress: string | null = null;

    set enabledForm(value: boolean) {
        this._enabledForm = value;
        if (this.formAddress) {
            if (value === true) {
                this.formAddress.enable();
            } else {
                this.formAddress.disable();
            }
        }
    }

    get enabledForm() {
        return this._enabledForm
    }

    set isAddressValid(value: boolean) {
        this._isAddressValid = value
        const control = this.formAddress.get(this.PATIENT_ADDRESS_STREET);
        if (value) {
            control?.setErrors(null);
        } else {
            control?.setErrors({ invalid: true });
        }
    }
    get isAddressValid() {
        return this._isAddressValid
    }
    set selectedAddress(value: AddressData) {
        this._selectedAddress = value;
        this.selected.emit(value);
    }
    get selectedAddress() {
        return this._selectedAddress;
    }
    get patientStreet() {
        return this.formAddress.get(this.PATIENT_ADDRESS_STREET).value;
    }

    constructor(private fb: FormBuilder) {
        super();

        effect(() => {

            const cityValue = this.city();
            const provinceValue = this.province();

            //APLICAMOS RESTRICCIONES A LA BÚSQUEDA
            if (cityValue && provinceValue) {
                this.setCityBounds(cityValue, provinceValue);
            }
            //HABILITAMOS EL FORM SOLO SI LA CIUDAD A SIDO SELECCIONADA (SOLO ADMICIONES)
            if (!!this.city()) {
                this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.setValue('');
                this.enabledForm = true;
                if (!!this.selectedAddress) {
                }
            }

            if (!!this.province) {
                // console.log('provincia a cambiado');
                this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.setValue('');
                // this.enabledForm = true;
            }

            if (this.address()) {
                this.enabledForm = true;
                const fullAddress = `${this.address().street} ${this.address().streetNumber}`;
                this.formAddress.get(this.PATIENT_ADDRESS_STREET).patchValue(fullAddress);
            }
        });
    }

    override ngOnInit(): void {

        this.formAddress = this.fb.group({
            [this.PATIENT_ADDRESS_STREET]: [
                { value: '', disabled: !this.enabledForm },
                Validators.required
            ]
        });

        // Suscribirse a los cambios en el input de dirección para validarlo
        this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.valueChanges.subscribe((value) => {
            const control = this.formAddress.get(this.PATIENT_ADDRESS_STREET);
            if (control?.dirty) {
                // Si el campo está sucio y el valor no coincide con el valor original seleccionado, marca el error
                if (value !== this.selectedValidAddress) {
                    control.setErrors({ invalidAddress: true });
                } else {
                    control.setErrors(null);
                }
            }
        });
    }

    ngAfterViewInit(): void {

        // Inicializamos el Autocomplete
        this.initializeAutocomplete();
        //Si recibimos por parametro ciudad y provincia para filtrar resultados del Autocomplete
        if (this.city() && this.province()) {
            this.setCityBounds(this.city(), this.province());
        }

        //Manejamos el resultado de la búsqueda
        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            if (place.address_components) {
                const components = this.getAddressComponents(place.address_components);
                this.selectedAddress = {
                    ...this.selectedAddress,
                    ...components,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                  };
                // Concatenar calle y número de calle
                try {
                    const fullAddress = `${components.street} ${components.streetNumber}`;
                    this.selectedValidAddress = fullAddress; // Guarda la dirección válida seleccionada
                    this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.setValue(fullAddress);
                    this.formAddress.get(this.PATIENT_ADDRESS_STREET)?.setErrors(null); // Limpia errores si la dirección es válida
                } catch (err) {
                    console.log(err);
                    this.isAddressValid = false;
                }
                this.isAddressValid = true;
            } else {
                this.isAddressValid = false;
                this.selectedAddress = null;
                this.formAddress.get('validation')?.setValue(null);
                this.formAddress.get('validation')?.setErrors({ required: true }); // Marca error si no hay dirección seleccionada
            }
        });
    }


    selectAll() {
        if (this.selectAllOnClick) {
            const input = this.textInput.nativeElement;
            input.select();
        }
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }

    hasError(errorType: string): boolean {
        const control = this.form.get(this.name);
        return control && control.hasError(errorType) && (control.dirty || control.touched);
    }

    // Inicializamos el Autocomplete
    private initializeAutocomplete(): void {
        const options = {
            componentRestrictions: { country: "ar" }, // Restricción a Argentina
            fields: ["address_components", "geometry"],
            strictBounds: false,
        };

        this.autocomplete = new google.maps.places.Autocomplete(this.addressinputRef.nativeElement, options);
    }

    // Función para mapear los componentes de la dirección
    private getAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]): AddressData {
        let street = '';
        let streetNumber = '';
        let city = '';
        let province = '';
        let country = '';

        addressComponents.forEach(component => {
            const componentType = component.types[0];

            switch (componentType) {
                case 'route':
                    street = component.long_name; // Calle
                    break;
                case 'street_number':
                    streetNumber = component.long_name; // Número de la calle
                    break;
                case 'locality':
                    city = component.long_name.replace('Ciudad de ', "").trim(); //Elimina Ciudad de y guarda el resto.
                    break;
                case 'administrative_area_level_1':
                    province = component.long_name.replace('Provincia de ', '').trim(); // Elimina 'Provincia de' y guarda el resto
                    break;
                case 'country':
                    country = component.long_name; // País
                    break;
            }
        });

        return {
            street,
            streetNumber,
            city,
            province,
            country
        };
    }

    // Función para obtener las coordenadas de una ciudad y provincia
    private async setCityBounds(city: string, province: string): Promise<void> {
        const geocoder = new google.maps.Geocoder();
        const address = `${city}, ${province}, Argentina`;

        try {
            const { results } = await geocoder.geocode({ address });
            if (results && results.length > 0) {
                const location = results[0].geometry.location;
                // Calcula un área más amplia alrededor del punto central de la ciudad
                const bounds = new google.maps.LatLngBounds();

                // Expande los límites para incluir un área alrededor de la ciudad
                const offset = 0.05; // Este valor define el tamaño de la caja en latitud y longitud. Ajusta según el tamaño de la ciudad.

                bounds.extend(new google.maps.LatLng(location.lat() - offset, location.lng() - offset));
                bounds.extend(new google.maps.LatLng(location.lat() + offset, location.lng() + offset));

                // Ajusta los límites del Autocomplete con los límites calculados
                this.autocomplete.setBounds(bounds);
                this.autocomplete.setOptions({ strictBounds: true });  // Mantén los límites estrictos
            } else {
                console.error('No se encontraron resultados para la dirección:', address);
            }
        } catch (error) {
            console.error('Error al geocodificar la dirección:', error);
        }
    }
}
