import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter word of string and take it
  * Usage:
 *  value | firstLetterWordsIntoString
 * Example:
 *  // value = "HOLA mundo"
 *  {{ value | firstLetterWordsIntoString }}
 *  Format to: HM
*/
@Pipe({
    name: "firstLetterWordsIntoString"
})

export class FirstLetterWordsIntoStringPipe implements PipeTransform {
    transform(fullString: string): any {
        return fullString
            .split(" ")
            .map(n => n[0].toUpperCase())
            .join("");
    }
}