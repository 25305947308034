@if (!!width() && height() ){
<google-map [height]="'100vh'" [width]='width()' #map [options]="options">
    @for (marker of markersSignal; track marker) {
    <!-- With svgIcon -->
    @if(!!marker?.icon) {
    <map-advanced-marker #markerElem="mapAdvancedMarker" [title]="!!marker?.title ? marker.title : null "
        [position]="!!marker.lat ? { lat: marker?.lat, lng: marker?.lng } : null" [content]="marker.svgIcon"
        (mapClick)="openInfo(markerElem, marker)">
    </map-advanced-marker>
    } @else {
    <!-- Without svgIcon, icon default -->
    <map-advanced-marker #markerElem="mapAdvancedMarker" [title]="!!marker?.title ? marker.title : null "
    [position]="!!marker.lat ? { lat: marker?.lat, lng: marker?.lng } : null" (mapClick)="openInfo(markerElem, marker)">
    </map-advanced-marker>
    }
    }
    <!-- En el componente del mapa -->
    <map-info-window>
        <ng-container *ngIf="selectedMarker">
            <ng-container
                *ngTemplateOutlet="getInfoWindowTemplate(selectedMarker); context: { $implicit: selectedMarker }"></ng-container>
        </ng-container>
    </map-info-window>
</google-map>
}

@if((mapsService.getMarkers$() | async)) {
<!-- This if force automatic refresh -->
}
<!-- Provider -->
<ng-template #templateProvider let-marker>
    <div class="card-marker">
        <div style=" border-bottom:1px solid lightgrey; text-align: center; width: max-content; width: 100% !important;">
            <!-- NAME -->
            <span style="font-weight: 500; color: darkblue; ">{{!!marker?.name ? marker.name : 'Sin nombre'}}</span>
        </div>
        <div class="flex flex-column align-center-center card-width text-center pts-1">
            <!-- DNI -->
            <span><b>DNI {{marker?.dni}}</b></span>
            <!-- SPECIALTY -->
            <span class="pts-1" style="color: darkgrey;font-weight: 500;">{{specialtyName()}}</span>
            <!-- MEDICAL LICENSE -->
            <ng-container *ngFor="let specialty of marker?.specialties">
                <div class="flex-row align-center-center" *ngFor="let medicalLicense of specialty.medicalLicenses">
                    @if(specialty.id == specialtyId()) {
                        <span>• {{medicalLicense?.type?.name}} Nº {{medicalLicense?.value}}</span>
                    <button (click)="copyToClipboard(medicalLicense?.value)"
                        style="background-color: transparent; border: none; cursor: pointer;">
                        <mat-icon class="mat-font-large-large-px lh">content_copy</mat-icon>
                    </button>
                    }
                </div>
            </ng-container>
            <!-- VERIFY LINK -->
            <a style="color: darkblue" href="https://sisa.msal.gov.ar/sisa/#sisa" target="_blank">
                VERIFICAR MATRÍCULA (SISA)
            </a>
            <!-- ADDRESS -->
            <span class="pts-1">
                <b>{{!!marker?.address ? marker.address : 'Sin dirección'}}</b>
            </span>
            <!-- PHONE NUMBER -->
            <div class="flex-row align-center-center gap-0-7em pts-1">
                <mat-icon [style.width.px]="14" [style.height.px]="14" svgIcon="whatsapp"></mat-icon>
                <div>{{!!marker?.phone ? marker.phone : 'Sin teléfono'}}</div>
            </div>
            <!-- EMAIL -->
            <div class="flex-row align-center-center gap-0-7em pts-1">
                <mat-icon class="flex-10" style="font-size:large !important;overflow: visible;">email</mat-icon>
                <div class="flex-row flex-80 text-ellipsis">{{!!marker?.email ? marker.email : 'Sin email'}}</div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</ng-template>

<!-- Patient -->
<ng-template #templatePatient let-marker>
    <div class="flex-column align-center-center flex-gap-small">
        <div class="flex-row p-2"
            style="border-bottom:1px solid lightgrey; justify-content:center !important; width: 85%;">
            <span style="font-weight: 500; color: darkblue;">{{!!marker?.name ? marker.name : 'Sin nombre'}}</span>
        </div>
        <div class="flex flex-column align-center-center p-2 gap-0-5em">
            <span class="text-primary-darker subtitle-3">{{!!marker?.address ? marker.address : 'Sin dirección'}}</span>
            <span>{{!!marker?.phone ? marker.phone : 'Sin teléfono'}}</span>
            <div class="flex flex-column mt-2">
                <span>{{!!marker?.email ? marker.email : 'Sin email'}}</span>
            </div>
        </div>


        <div class="flex-row align-space-between-center flex-gap-1rem">
            <!-- <button mat-stroked-button [color]="'primary'" type="button" (click)="''" class="buttom-map-size" [disabled]="">
                CERRAR INFORMACIÓN
            </button>
            <button mat-flat-button [color]="'primary'" type="submit" class="buttom-map-size" [disabled]="">
                <span>ASIGNAR CASO</span>
            </button> -->
        </div>
    </div>
</ng-template>
