import { Component } from '@angular/core';
import { LottieComponent, AnimationOptions, provideLottieOptions, AnimationLoader } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

@Component({
    selector: 'app-warning-icon',
    templateUrl: './warning-icon.component.html',
    styleUrls: ['./warning-icon.component.scss'],
    standalone: true,
    providers: [
        provideLottieOptions({
            player: () => import('lottie-web'),
          }),
          AnimationLoader
    ],
    imports: [LottieComponent]
})
export class WarningIconComponent {

    options: AnimationOptions = {
        path: '/assets/animations/warningicon.json',
    };

    animationCreated(animationItem: AnimationItem): void {
        console.log(animationItem);
    }
}
