//Flow 1 --> "Used with the paintedTypes hash of generalService to mark attentions, practices, orders, and supplies."
// Flow 2 --> "Used to manage focus of the case indicators view"
export enum ViewManagementEntities {
    PRACTICES_WITHOUT_SCHEDULE = 'PRACTICES_WITHOUT_SCHEDULE',
    ATTENTIONS_OLD_AND_OMITTED = 'ATTENTIONS_OLD_AND_OMITTED',
    ATTENTIONS_WITHOUT_BILLING = 'ATTENTIONS_WITHOUT_BILLING',
    BILLING_ATTENTIONS = 'BILLING_ATTENTIONS',
    SUPPLIES_WITHOUT_ORDER = 'SUPPLIES_WITHOUT_ORDER',
    SUPPLIES_NOT_DELIVERED = 'SUPPLIES _NOT_DELIVERED',
    SUPPLIES_NOT_BILLING = 'SUPPLIES_NOT_BILLING',
    SUPPLIES_NOT_INFORMED = 'SUPPLIES_NOT_INFORMED'
}
