import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttentionsComponent } from './pages/attentions/attentions.component';


const routes: Routes = [
   {
      path: '',
      redirectTo: 'listado',
      pathMatch: 'full',
      data: { breadcrumb: 'Listado' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
   {
      path: 'listado',
      component: AttentionsComponent,
      data: { breadcrumb: 'Listado' }
      // Protect all other child routes at one time instead of adding the AuthGuard to each route individually.
      // canActivateChild: [AuthGuard],
   },
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule],
})
export class AttentionsRoutingModule { }
