<div class="flex-row wrap">
    <mat-card class="card-shadow flex-100 border-radius-0-md">
        <mat-card-header class="pl-0 ml-0 flex-row align-space-between-start">
            <mat-card-title>
                Listado de casos
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex-column align-center-none">
            <form *ngIf="this.operators && this.filterForm" class="mts-3 card-shadow pts-2 pxs-3"
                (submit)="onFilter()" [formGroup]="filterForm" class="flex-column align-start-center">
                <div class="flex-row wrap gap-40px">
                    <mat-icon [color]="'primary'" class="mt-2">filter_alt</mat-icon>
                    <!-- Operator Searching -->
                    <app-select-searching-input [icon]="'support_agent'" [noOptionText]="'-- Todos --'"
                        [defaultValue]="this.metaDataCases?.operatorId ? this.metaDataCases?.operatorId:'0'"
                        [name]="this.OPERATOR" [nameSearching]="this.OPERATOR_SEARCHING" [form]="this.filterForm"
                        [label]="'Filtro por operador'" [elements]="this.operators" [placeholderNoValue]="'Sin resultados'"
                        [placeholderSearching]="'Buscar operador...'">
                    </app-select-searching-input>
                    <!-- Patient Searching -->
                    <!-- <app-patient-selector
                        [name]="this.PATIENT" [nameSearching]="this.PATIENT_SEARCHING"
                        [form]="this.filterForm">
                    </app-patient-selector> -->
                    <!-- Indicator Searching -->
                    <app-select-input [icon]="'trending_up'" [noOptionText]="'-- Sin filtrar --'" [name]="this.INDICATOR"
                        [form]="this.filterForm" [label]="'Filtro por indicador'" [elements]="this.indicatorsOptions"
                        [defaultValue]="this.selectedIndicator">
                    </app-select-input>
                    <!-- Financier Searching  -->
                    <!-- <app-financier-selector
                        [name]="this.FINANCIER" [nameSearching]="this.FINANCIER_SEARCHING"
                        [form]="this.filterForm">
                    </app-financier-selector> -->
                    <!-- Financier Searching -->
                    <app-select-searching-input [icon]="'local_hospital'" [noOptionText]="'-- Todos --'" style="min-width: 215px;"
                        [defaultValue]="this.metaDataCases?.financierId ? this.metaDataCases?.financierId:'0'"
                        [name]="this.FINANCIER" [nameSearching]="this.FINANCIER_SEARCHING" [form]="this.filterForm"
                        [label]="'Filtro por financiador'" [elements]="this.financiers" [placeholderNoValue]="'Sin resultados'"
                        [placeholderSearching]="'Buscar fianciador...'">
                    </app-select-searching-input>
                </div>
                <div class="flex-row wrap align-center-start gap-40px">
                    <app-checkbox-input class="mt-1" [form]="filterForm" [label]="'Incluir casos cerrados'" [name]="this.ALL_CASES">
                    </app-checkbox-input>
                    <app-checkbox-input class="mt-1" [form]="filterForm" [label]="'Sólo casos derivados'" [name]="this.DERIVED">
                    </app-checkbox-input>
                    <app-checkbox-input class="mt-2" [form]="filterForm" [label]="'Sólo casos sin convenio'" [name]="this.WITHOUT_ARRANGEMENTS">
                    </app-checkbox-input>
                    <!-- TODO: descomentar para la proxima puesta en producción -->
                    <div class="flex-row">
                        <app-checkbox-input class="mt-2" [form]="filterForm" [label]="'Sólo casos presupuestados desde'"
                            [name]="this.WITHOUT_ARRANGEMENTS">
                        </app-checkbox-input>
                        <a mat-button class="text-primary underline mt-1 pl-1" (click)="openCalendar()">{{getDateFromForm()}}</a>
                        <app-date-picker class="width-1 opacity-0" [name]="this.FROM_DATE" [form]="this.filterForm" [readonly]="true" [openCalendar]="openedCalendar"
                            (isCalendarOpen)="isCalendarOpen($event)"></app-date-picker>
                    </div>
                </div>
                <div class="flex-row align-center gap-5p ms-2">
                    <button id="btn_casesComponentBuscar" mat-flat-button [color]="'primary'" type="submit">BUSCAR</button>
                </div>
            </form>
            <div class="example-loading-shade" *ngIf="isLoadingGettingCases">
                <app-spinner></app-spinner>
            </div>
            <div class="table-container mts-3 mat-elevation-z3 cases-container-sm" class="flex-column">
                <div class="flex-row align-center-center mxs-4 mts-2 flex-100">
                    <mat-form-field appearance="outline" class="flex-100">
                        <mat-icon matPrefix class="text-primary-lighter">search</mat-icon>
                        <mat-label>Ingrese el nombre, apellido, DNI del paciente o CIF</mat-label>
                        <mat-hint>Al buscar por CIF o DNI el mismo debe ser exacto</mat-hint>
                        <input matInput placeholder="Búsqueda rápida" #inputSearch />
                    </mat-form-field>
                </div>
                <!-- Patient Table -->
                <!-- Deleted because order by observable matSortDirection="asc" -->
                <table *ngIf="this.dataSource?.data?.length > 0; else noCasesTemplate" mat-table class="mat-elevation-z0 flex-gt-sm-100" [dataSource]="dataSource"
                    class="cases-table-md" matSort matSortActive="patient" (matSortChange)="onSortEvent($event)" matSortDisableClear>
                    <!-- financierInternalCode Column -->
                    <ng-container matColumnDef="financierInternalCode" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cod. Int. Financiador</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                                {{ !!case.financierInternalCode ? case.financierInternalCode : (case.patient?.documentNumber ? case.patient.documentNumber : 'No disponible') }}
                            </div>
                        </td>
                    </ng-container>
                    <!-- financier Column -->
                    <ng-container matColumnDef="financier" sticky>
                        <th mat-header-cell *matHeaderCellDef>Financiador / CIF</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                                <div class="flex-column">
                                    <span [matTooltip]="!!case.financier && !!case.financier.name ? case.financier.name : 'Sin datos'">
                                        {{ !!case.financier && !!case.financier.name ? this.generalService.getSummarizeString(case.financier.name,20) : 'Sin datos' }}
                                    </span>
                                    <span *ngIf="!!case.financierInternalCode || case.patient?.documentNumber"
                                        matTooltip="Código interno de financiador" class="text-secondary">
                                        #{{ !!case.financierInternalCode ? case.financierInternalCode : case.patient?.documentNumber}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- operator.name Column -->
                    <ng-container matColumnDef="operator">
                        <th mat-header-cell *matHeaderCellDef>Operador</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                               {{ case.operator?.name }}
                            </div>
                        </td>
                    </ng-container>
                    <!-- patient.surname Column -->
                    <ng-container matColumnDef="patient">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="surname" sortActionDescription="Paciente">Paciente</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                                <span>
                                    {{ case?.patient?.surname || case?.patient?.name ? case?.patient?.surname + ' ' + case?.patient?.name : 'Sin datos' }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <!-- patiten.documentNumber Column -->
                    <ng-container matColumnDef="patientDocument">
                        <th mat-header-cell *matHeaderCellDef>DNI Paciente</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                               <span>
                                    {{ case?.patient?.documentNumber ? case?.patient?.documentNumber : 'Sin datos' }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <!-- typeDni Column -->
                    <ng-container matColumnDef="typeDni">
                        <th mat-header-cell *matHeaderCellDef>Tipo</th>
                        <td mat-cell *matCellDef="let case">{{ case.patient.typeDocument }}</td>
                    </ng-container>
                    <!-- DNI Column -->
                    <ng-container matColumnDef="dni">
                        <th mat-header-cell *matHeaderCellDef>Documento</th>
                        <td mat-cell *matCellDef="let case">{{ case.patient.documentNumber }}</td>
                    </ng-container>
                    <!-- Diagnosis Column -->
                    <ng-container matColumnDef="diagnosis">
                        <th mat-header-cell *matHeaderCellDef>Diagnóstico</th>
                        <td mat-cell *matCellDef="let case" [matTooltip]="casesService.getDiagnosisString(case)">
                            <div class="inner-cell">
                                {{ this.generalService.getSummarizeString(casesService.getDiagnosisString(case), 30) }}
                            </div>
                        </td>
                    </ng-container>
                    <!-- Derived To Column -->
                    <ng-container matColumnDef="derived">
                        <th mat-header-cell *matHeaderCellDef>Derivado</th>
                        <td mat-cell *matCellDef="let acase">
                            {{ acase?.caseDerivation?.derivedTo?.name }}
                        </td>
                    </ng-container>
                    <!-- To Column -->
                    <ng-container matColumnDef="validity">
                        <th mat-header-cell *matHeaderCellDef matTooltip="Mes/Año de la transacción en el sistema externo">
                            Referencia
                        </th>
                        <td mat-cell *matCellDef="let case" matTooltip="Mes/Año de la transacción en el sistema externo">
                            <div class="inner-cell">
                                {{ case.fromDate ? (case.fromDate | date: 'MM/yyyy': 'utc') : 'Sin definir' }}
                            </div>
                        </td>
                    </ng-container>
                    <!-- Active Column -->
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell active-column">
                                <div class="flex-column">
                                    <mat-icon class="text-warn" *ngIf="case.isDerivedTo" [matTooltip]="'Caso derivado a: ' + case.caseDerivation?.derivedTo?.name">
                                        redo
                                    </mat-icon>
                                    <mat-icon class="text-primary" *ngIf="case.isDerived" [matTooltip]="'Caso derivado de: ' + case.caseDerivation?.derivedFrom?.name">
                                        undo
                                    </mat-icon>
                                    <mat-icon [ngClass]="{'text-primary': !this.case.legacyId,'text-secondary': !!this.case.legacyId}" *ngIf="case.active" matTooltip="Caso activo">
                                        check_circle
                                    </mat-icon>
                                    <mat-icon class="text-warn mbs-1" *ngIf="!case.active" matTooltip="Caso inactivo">
                                        highlight_off
                                    </mat-icon>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- DaysWithoutScheduled Column -->
                    <ng-container matColumnDef="daysWithoutScheduled">
                        <th mat-header-cell *matHeaderCellDef class="vertical-left-line">Días sin agenda
                        </th>
                        <td mat-cell *matCellDef="let case" class="vertical-left-line">
                            <div class="flex-row align-start-center gap-8px">
                                <b class="text-secondary-darker">
                                    {{case.attentionsWithoutScheduleByCase}}
                                </b>
                                <mat-progress-bar mode="buffer" [color]="case.attentionsWithoutScheduleByCase < 15 ? 'primary':'warn'" [value]="case.attentionsWithoutScheduleByCase == 0 ? 0:(case.attentionsWithoutScheduleByCase < 15 ? 35:75)">
                                </mat-progress-bar>
                            </div>
                        </td>
                    </ng-container>
                    <!-- ExpiredAuthorizations Column -->
                    <ng-container matColumnDef="expiredAuthorizations">
                        <th mat-header-cell *matHeaderCellDef>Autorizaciones</th>
                        <td mat-cell *matCellDef="let case">
                            <div class="inner-cell">
                                <div [ngSwitch]="case.closestAuthorizationState">
                                    <div *ngSwitchCase="CASE_AUTHORIZATIONS_STATES.EXPIRED">
                                        <mat-chip-listbox *ngIf="!case?.isLoadingStates">
                                            <mat-chip style="white-space: nowrap;cursor: pointer; color: #FF5F3C; background-color: #FFE9E9;" selected>
                                                <span class="mat-small">
                                                    {{case.closestAuthorization.toDate | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                    <div *ngSwitchCase="CASE_AUTHORIZATIONS_STATES.UNAUTHORIZED">
                                        <mat-chip-listbox *ngIf="!case?.isLoadingStates">
                                            <mat-chip style="white-space: nowrap;cursor: pointer; color:#FFB800; background-color: #FFF2DF;" selected>
                                                <span class="mat-small">NO AUTORIZADO</span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                    <div *ngSwitchCase="CASE_AUTHORIZATIONS_STATES.CLOSE_TO_EXPIRED">
                                        <mat-chip-listbox *ngIf="!case?.isLoadingStates">
                                            <mat-chip style="white-space: nowrap;cursor: pointer; color: #FF7A00; background-color: #FFEBD9;" selected>
                                                <span class="mat-small">
                                                    {{case.closestAuthorization.toDate | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                    <div *ngSwitchCase="CASE_AUTHORIZATIONS_STATES.VALID">
                                        <mat-chip-listbox *ngIf="!case?.isLoadingStates">
                                            <mat-chip style="white-space: nowrap;cursor: pointer; color: #21A35C; background-color: #DFF7EA;" selected>
                                                <span class="mat-small">
                                                    {{case.closestAuthorization.toDate | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                    <div *ngSwitchDefault>-</div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- AttentionsNotCompleted Column -->
                    <ng-container matColumnDef="attentionsNotCompleted">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="flex-column">
                                <span>Atenciones previas</span>
                                <span>a hoy sin finalizar</span>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let case">
                            <app-avatar
                                [textSizeRatio]="2"
                                backgroundColor="#0"
                                [initialsSize]="0"
                                [value]="'10'"
                                [size]="30">
                            </app-avatar>
                            <div class="flex-row">
                                <app-progress-spinner-with-text *ngIf="!!case?.pendingAttentions" [color]="case?.pendingAttentions  * 6 <= 25 ? 'primary' :
                                (case?.pendingAttentions  * 6 <= 50 ? 'accent': 'warn')" [diameter]="37" [strokeWidth]="4"
                                    [value]="case?.pendingAttentions  * 6" [valueText]="case.pendingAttentions"
                                    [displayWith]="spinnerFunction">
                                </app-progress-spinner-with-text>
                                <mat-icon matTooltip="El caso tiene agenda cargada" *ngIf="!case?.pendingAttentions"
                                    class="text-secondary mat-icon-small">
                                    horizontal_rule
                                </mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Alert Column -->
                    <ng-container matColumnDef="alert">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let case">
                            <span *ngIf="case.notification">
                                <mat-icon [color]="'warn'" matTooltip="El caso posee novedades">warning</mat-icon>
                            </span>
                        </td>
                    </ng-container>
                    <!-- Dummy Column -->
                    <ng-container matColumnDef="dummy">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let case"></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMainData.concat(displayedColumnsIndicators); sticky: true">
                    </tr>
                    <tr mat-row matTooltip="Ver detalles del caso" [matTooltipShowDelay]="2000" *matRowDef="let row; columns: displayedColumnsMainData.concat(displayedColumnsIndicators)"
                        class="bg-on-hover" (click)="onClickRow(row)">
                    </tr>
                </table>
                <mat-paginator class="flex-100" pageSize="10" [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="onPaginatorEvent()" showFirstLastButtons firstPageLabel="Primera página"
                    lastPageLabel="Última página" nextPageLabel="Página siguiente" previousPageLabel="Página anterior">
                </mat-paginator>
            </div>
            <ng-template #noCasesTemplate>
                <span class="flex-100 align-center-start text-secondary mts-4" *ngIf="this.dataSource?.data?.length == 0">
                    No se encontraron casos con los filtros establecidos
                </span>
            </ng-template>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loadingCases>
    <app-spinner class="flex-100 align-center-start">
    </app-spinner>
</ng-template>
