import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-alert-error',
    templateUrl: './alert-error.component.html',
    styleUrls: ['./alert-error.component.scss'],
})

export class AlertErrorComponent {

    message: string;
    icon: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: any,
    ) {
        this.message = data.message;
        this.icon = data.icon;
    }
}
