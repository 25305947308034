<form class="flex-row center-start gap-7pct mts-3" *ngIf="corporateProvidersForm" [formGroup]="corporateProvidersForm"
    (submit)="onClickDelegate()">
    <app-select-input [name]="this.PROVIDERS" [form]="this.corporateProvidersForm"
        [placeholder]="!!this.case['caseDerivations'] && this.case['caseDerivations'].length > 0 ? 'Derivado a:' : 'Proveedor:'"
        [elements]="corporates" [calculateId]="this.calculateId" [calculateName]="this.calculateName"
        [disabled]="!!this.case['caseDerivations'] && this.case['caseDerivations'].length > 0">
    </app-select-input>
    <button *ngIf="!(this.case['caseDerivations']?.length > 0)" mat-flat-button type="submit" color="primary"
        [disabled]="!corporateProvidersForm.valid">
        DELEGAR
    </button>
</form>
