import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AttentionsService, AttentionsQPS, LiquidationsQPS, PostLiquidationBody, PatchLiquidationBody, PatchAttentionBody, AttentionsDeleteBatchQPS } from '../core/services/attentions.service';
import { AttentionsState, MetaDataLiquidations } from '../core/states/attentions.state';
import { Pagination } from '../shared/models/pagination';
import { AuditStatesAttention } from '../shared/models/auditStateAttention';
import { AttentionStates } from '../core/enums/attentionsStates';
import { Liquidation } from '../shared/models/liquidation';
import { StateLiquidation } from '../shared/models/stateLiquidation';
import { Consent } from '../shared/models/consent';
import { Attention } from 'src/app/shared/models/attention';
import { GeneralService } from '../core/services/general.service';

export interface ExtraMetaDataAttentions {
    title?: string,
}
export interface MetaDataAttentions extends ExtraMetaDataAttentions, AttentionsQPS, Pagination {
    fromIndicator?: boolean;
}

export interface ProvisionFeesFilterParameters {
    practiceId?: number;
    financierId?: number;
}

export const ATTENTIONS_COLORS = {
    [AttentionStates.AGENDADA]: '#609E50',
    [AttentionStates.REALIZADA]: '#093BBB',
    [AttentionStates.EN_CURSO]: '#FF7A00',
    [AttentionStates.NO_REALIZADA]: '#D02700',
    [AttentionStates.INFORMADA]: '#1994B1',
    [AttentionStates.FACTURABLE]: '#37474F',
    [AttentionStates.EN_PROCESO]: '#d7b1f2',
    [AttentionStates.NO_INFORMADA]: '#D02700',
    [AttentionStates.DEBITADA]: '#D02700',
}

export const ATTENTIONS_BACKGROUND_COLORS = {
    [AttentionStates.AGENDADA]: '#EAFEE5',
    [AttentionStates.REALIZADA]: '#D6DEF4',
    [AttentionStates.EN_CURSO]: '#FFEBD9',
    [AttentionStates.NO_REALIZADA]: '#FFE9E9',
    [AttentionStates.INFORMADA]: '#D9F1F6',
    [AttentionStates.FACTURABLE]: '#A1DFCC',
    [AttentionStates.EN_PROCESO]: '', // Whitout use?
    [AttentionStates.NO_INFORMADA]: '#FFE9E9',
    [AttentionStates.DEBITADA]: '#C6CCCE',
}
@Injectable({
    providedIn: 'root',
})
export class AttentionsFacade {

    constructor(private attentionsState: AttentionsState,
        private attentionsService: AttentionsService,
        private generalService: GeneralService) { }


    // **ATTENTIONS**
    isLoadingGetAttentions$(): Observable<boolean> {
        return this.attentionsState.isLoadingGetAttentions$();
    }
    isLoadingGettingPagination$(): Observable<boolean> {
        return this.attentionsState.isLoadingGettingPagination$();
    }

    isLoadingGetAuditStatesttention$(): Observable<boolean> {
        return this.attentionsState.isLoadingGetAuditStatesAttention$();
    }

    getAttentions$(): Observable<Attention[]> {
        return this.attentionsState.getAttentions$();
    }

    resetAttentions() {
        this.attentionsState.resetAttentions();
    }

    getAttentionsCaseActive$(): Observable<Attention[]> {
        return this.attentionsState.getAttentionsCaseActive$();
    }

    getAuditStatesAttentions$(): Observable<AuditStatesAttention> {
        return this.attentionsState.getAuditStatesAttention$();
    }


    // Martin
    getStatesLiquidations$(): Observable<StateLiquidation[]> {
        return this.attentionsState.getStatesLiquidation$();
    }

    getAttentionsFilteredByAgreements(agreementsIds: number[]): Attention[] {
        return this.attentionsState.getAttentions().filter(
            (attention: Attention) => {
                return agreementsIds.find(
                    agreementId => (agreementId == attention.agreementId && !attention.notManageable)
                ) != undefined ? true : false;
            }
        );
    }

    getSuppliesFilteredByAgreements(agreementsIds: number[]): Attention[] {
        return this.attentionsState.getAttentions().filter(
            (supply: Attention) => {
                console.log("Atencion SUPPLY: ", supply);
                return agreementsIds.find(id => (id == supply.agreementId && supply.notManageable)) != undefined ? true : false;
            }
        );
    }

    getMetaDataAttentions$(): Observable<MetaDataAttentions> {
        return this.attentionsState.getMetaDataAttentions$();
    }

    //this method make de request and update the state
    loadAttentions(afp?: AttentionsQPS, emda?: ExtraMetaDataAttentions, updateAttentionsCaseActive: boolean = true) {

        this.attentionsState.setLoadingGetAttentions(true);
        return this.attentionsService.getAttentions(afp)
            .pipe(
                tap(body => {
                    let attentions = body.data;
                    if (!!attentions) {
                        attentions = attentions.map(attention => {
                            if (attention.practice.restriction.name == 'Visita' && [AttentionStates.REALIZADA, AttentionStates.NO_INFORMADA, AttentionStates.FACTURABLE, AttentionStates.EN_PROCESO, AttentionStates.INFORMADA, AttentionStates.DEBITADA].includes(attention?.state?.id)) {
                                attention.quantity = 1;
                            }
                            return attention;
                        })
                    }
                    this.attentionsState.setAttentions(attentions)
                    if (updateAttentionsCaseActive) {
                        this.attentionsState.setAttentionsCaseActive(attentions);
                    }
                }),
                tap(() => this.attentionsState.setLoadingGetAttentions(false)),
                tap(() => this.attentionsState.setLoadingGettingPagination(false)),
                tap((body) => {
                    this.attentionsState.setMetaDataAttentions({ ...afp, ...body?.pagination, ...emda });
                }),
                tap((body) => console.log('request finished. Attentions: ', body.data, 'Pagination :', body.pagination))
            );
    }

    loadAuditStatesAttention(attentionId: number) {
        this.attentionsState.setLoadingGetAuditStatesAttention(true);
        const promise: Promise<AuditStatesAttention> = new Promise((res, rej) => {
            this.attentionsService.getAuditStatesAttention(attentionId).pipe(
                finalize(() => this.attentionsState.setLoadingGetAuditStatesAttention(false))
            ).subscribe({
                next: (states) => { this.attentionsState.setAuditStatesAttention(states); res(states) },
                error: (e) => { rej(e) }
            })
        })
        return from(promise);
    }

    loadStatesLiquidation() {
        this.attentionsState.setLoadingGetStatesLiquidation(true);
        const promise: Promise<StateLiquidation[]> = new Promise((res, rej) => {
            this.attentionsService.getStatesLiquidation().pipe(
                finalize(() => this.attentionsState.setLoadingGetStatesLiquidation(false))
            ).subscribe({
                next: (states) => { this.attentionsState.setStatesLiquidation(states); res(states) },
                error: (e) => { rej(e) }
            })
        })
        return from(promise);
    }

    // ** LIQUIDATIONS **
    isLoadingGettingLiquidations$(): Observable<boolean> {
        return this.attentionsState.isLoadingGettingLiquidations$();
    }
    isLoadingUpdatingLiquidation$(): Observable<boolean> {
        return this.attentionsState.isLoadingUpdatingLiquidation$();
    }
    isLoadingCreatingLiquidation$(): Observable<boolean> {
        return this.attentionsState.isLoadingCreatingLiquidation$();
    }

    getLiquidations$(): Observable<Liquidation[]> {
        return this.attentionsState.getLiquidations$()
    }

    getLiquidationsCount$(): Observable<number> {
        return this.attentionsState.getLiquidationsCount$();
    }

    getMetaDataLiquidations$(): Observable<MetaDataLiquidations> {
        return this.attentionsState.getMetaDataLiquidations$();
    }

    // this method make de request and update the state
    loadLiquidations(liquidationsQPS: LiquidationsQPS) {
        this.attentionsState.setLoadingGettingLiquidations(true);
        console.log('Loading request GET Liquidations ', liquidationsQPS);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.attentionsService.getLiquidations(liquidationsQPS).pipe(
                tap(body => this.attentionsState.setLiquidations(body.data)),
                tap(body => this.attentionsState.setPagination(body.pagination)),
                tap(() => this.attentionsState.setLoadingGettingLiquidations(false)),
                tap(() => this.attentionsState.setLoadingGettingPagination(false)),
                tap((body) => {
                    this.attentionsState.setMetaDataLiquidations({ ...liquidationsQPS, ...body.pagination });
                }),
                tap((body) => console.log('request finished. Liquidation: ', body.data, 'Pagination :', body.pagination)),
            ).subscribe({
                next: (response) => res(response.data),
                error: (e) => { rej(e) },
            })
        })
        return from(promise);
    }

    loadLiquidationsCount() {
        this.attentionsState.setLoadingGettingLiquidationsCount(true);
        console.log('Loading request GET Liquidations COUNT ');
        const promise: Promise<any> = new Promise((res, rej) => {
            this.attentionsService.getLiquidationsCount().pipe(
                tap(data => this.attentionsState.setLiquidationsCount(data)),
                tap(() => this.attentionsState.setLoadingGettingLiquidationsCount(false)),
                tap((data) => console.log('request finished. Health Insurance Count: ', data)),
            ).subscribe({
                next: () => {},
                error: (e) => { rej(e) },
            })
        })
        return from(promise);
    }

    addLiquidation(liquidationBody: PostLiquidationBody): Observable<Liquidation> {
        this.attentionsState.setLoadingCreatingLiquidation(true);
        // Mapper from CreationalProvision to PostProvisionBody must be here
        console.log(liquidationBody);
        const promise: Promise<Liquidation> = new Promise((res, rej) => {
            this.attentionsService.postLiquidation(liquidationBody).pipe(
                finalize(() => this.attentionsState.setLoadingCreatingLiquidation(false))
            ).subscribe({
                next: newLiquidation => {
                    this.attentionsState.addLiquidation(newLiquidation);
                    //Update store count
                    this.loadLiquidationsCount();
                    res(newLiquidation);
                },
                error: (e) => { this.attentionsState.setErrorCreatingLiquidation(true); rej(e) },
            })
        })
        return from(promise);
    }

    updateLiquidation(liquidationBody: PatchLiquidationBody, liquidationId: number): Observable<Liquidation> {
        this.attentionsState.setLoadingUpdatingLiquidation(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Liquidation> = new Promise((res, rej) => {
            this.attentionsService.patchLiquidation(liquidationBody, liquidationId).pipe(
                finalize(() => this.attentionsState.setLoadingUpdatingLiquidation(false))
            ).subscribe({
                next: (newLiquidation) => { this.attentionsState.updateLiquidation(newLiquidation, liquidationId); res(newLiquidation) },
                error: (e) => { this.attentionsState.setErrorUpdatingLiquidation(true); rej(e) },
            })
        })
        return from(promise);
    }

    // **INDICATORS**

    getScheduledAttentions$(): Observable<number> {
        return this.attentionsState.getScheduledAttentions$();
    }

    // this method make de request and update the state
    loadScheduledAttentions(qps: AttentionsQPS): Observable<number> {
        this.attentionsState.setLoadingGetScheduledAttentions(true);
        // directly mapped from CasesFilterParameters to CasesQPS, because they are the same properties (could differ)
        const promise: Promise<number> = new Promise((res, rej) => {
            this.attentionsService.getScheduledAttentions(qps).pipe(
                finalize(() => this.attentionsState.setLoadingGetScheduledAttentions(false))
            ).subscribe({
                next: (count) => { this.attentionsState.setScheduledAttentions(count); res(count) },
                error: (e) => { rej(e) },
            })
        })
        return from(promise);
    }

    isLoadingGettingScheduledAttentions$(): Observable<boolean> {
        return this.attentionsState.isLoadingGetScheduledAttentions$();
    }

    // Update state & execute next() on store.attentions$
    patchAttention(att: Attention): Observable<Attention> {
        this.attentionsState.setLoadingPatchingAttention(true);
        const promise: Promise<Attention> = new Promise((resolve, reject) => {

            this.attentionsService.patchAttention(att).pipe(
                finalize(() => this.attentionsState.setLoadingPatchingAttention(false))
            ).subscribe({
                next: res => {
                    this.attentionsState.updateAttention(res, res.id);
                    this.attentionsState.updateAttentionCaseActive(res, res.id);
                    resolve(res);
                },
                error: (error) => reject(error)
            })
        })
        return from(promise);
    }

    patchAttentionByAgreement(att: Attention): Observable<Attention> {
        this.attentionsState.setLoadingPatchingAttention(true);
        const promise: Promise<Attention> = new Promise((resolve, reject) => {

            this.attentionsService.patchAttentionByAgreement(att).pipe(
                finalize(() => this.attentionsState.setLoadingPatchingAttention(false))
            ).subscribe({
                next: res => {
                    if (res?.attentionsResponse) {
                        res.attentionsResponse.forEach(att => {
                            this.attentionsState.updateAttention(att, att.id);
                            this.attentionsState.updateAttentionCaseActive(att, att.id);
                        });
                    }
                    resolve(res);
                },
                error: error => reject(error)
            })
        })
        return from(promise);
    }

    patchAttentions(att: PatchAttentionBody[], updateAttentionsCaseActive: boolean = true): Observable<Attention[]> {
        this.attentionsState.setLoadingPatchingAttention(true);
        const promise: Promise<Attention[]> = new Promise((resolve, reject) => {

            this.attentionsService.patchAttentions(att).pipe(
                finalize(() => this.attentionsState.setLoadingPatchingAttention(false))
            ).subscribe({
                next: res => {
                    this.attentionsState.updateAttentions(res);
                    if (updateAttentionsCaseActive) {
                        this.attentionsState.updateAttentionsCaseActive(res);
                    }
                    resolve(res);
                },
                error: err => {
                    if (err.status === 418) {
                        this.attentionsState.updateAttentions(err.error.data);
                    }
                    reject(err);
                }}
            )
        })
        return from(promise);
    }

    postAttentions(attentions: { fromDate: Date, toDate: Date, stateId: number, agreementId: number, provisionFeeId?: number }[]) {
        this.attentionsState.setLoadingCreatingAttention(true);
        const promise: Promise<Attention> = new Promise((resolve, reject) => {

            this.attentionsService.postAttention(attentions).pipe(
                finalize(() => this.attentionsState.setLoadingCreatingAttention(false))
            ).subscribe({
                next: res => {
                    res.forEach(att => {
                        this.attentionsState.addAttention(att);
                        this.attentionsState.addAttentionCaseActive(att);
                    });
                    resolve(res)
                },
                error: error => reject(error)
            })
        })
        return from(promise);
    }

    postAttentionsV2(attentions: { fromDate: Date, toDate: Date, stateId: number, agreementId: number, provisionFeeId?: number }[]) {
        this.attentionsState.setLoadingCreatingAttention(true);
        const promise: Promise<Attention> = new Promise((resolve, reject) => {

            this.attentionsService.postAttentionV2(attentions).pipe(
                finalize(() => this.attentionsState.setLoadingCreatingAttention(false))
            ).subscribe({
                next: res => {
                    res.forEach(att => {
                        this.attentionsState.addAttention(att);
                        this.attentionsState.addAttentionCaseActive(att);
                    });
                    resolve(res)
                },
                error: error => reject(error)
            })
        })
        return from(promise);
    }

    loadAttentionsGeneric(qps: AttentionsQPS, updateAttentionsCaseActive: boolean = true, putAttentions: boolean = false) {

        console.log("loadAttentionsGeneric");

        this.attentionsState.setLoadingGetAttentions(true);
        this.generalService.setDisabledBtnsPeriodSelector(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.attentionsService.getAttentionsGeneric(qps).pipe(
                tap(body => {

                    if (!putAttentions) {
                        this.attentionsState.setAttentions(body);

                        if (updateAttentionsCaseActive) {
                            this.attentionsState.setAttentionsCaseActive(body);
                        }
                    }

                    if (putAttentions) {
                        this.attentionsState.putAttentions(body);

                        if (updateAttentionsCaseActive) {
                            this.attentionsState.putAttentionsCaseActive(body);
                        }
                    }
                }),
                finalize(() => {
                    this.attentionsState.setLoadingGetAttentions(false);
                    this.generalService.setDisabledBtnsPeriodSelector(false);
                }),
            ).subscribe({
                next: response => resolve(response),
                error: e => {
                    this.attentionsState.setLoadingGetAttentions(false);
                    reject(e)
                },
            })
        })
        return from(promise);
    }

    // Consent - Consentimiento - LOAD
    loadConsent(caseId: number) {

        this.attentionsState.setLoadingGettingConsent(true);

        const promise: Promise<any> = new Promise((res, rej) => {
            this.attentionsService.getConsent(caseId).pipe(
                tap(() => this.attentionsState.setLoadingGettingConsent(false)),
            ).subscribe({
                next: (consent) => { res(consent) },
                error: (e) => rej(e),
            });
        });
        return from(promise);
    }

    // Consent - Consentimiento - SET
    setConsent(consent: Consent): Observable<Consent> {

        this.attentionsState.setLoadingUpdatingConsent(true);

        const promise: Promise<Consent> = new Promise((res, rej) => {
            this.attentionsService.postConsent(consent).pipe(
                finalize(() => this.attentionsState.setLoadingUpdatingConsent(false))
            ).subscribe({
                next: (consent) => res(consent),
                error: (e) => { rej(e) },
            })
        })
        return from(promise);
    }

    // Consent - state
    isLoadingGettingConsent$(): Observable<boolean> {
        return this.attentionsState.isLoadingGettingConsent$();
    }

    // Consent - state
    isLoadingUpdatingConsent$(): Observable<boolean> {
        return this.attentionsState.isLoadingUpdatingConsent$();
    }

    // UPDATE - PATCH
    deleteAttention(attention: Attention) {
        this.attentionsState.setLoadingRemovingAttention(true);

        const promise: Promise<void> = new Promise((res, rej) => {
            this.attentionsService.deleteAttention(attention).pipe(
                finalize(() => this.attentionsState.setLoadingRemovingAttention(false))
            ).subscribe({
                next: attentionResponse => {
                    res(attentionResponse);
                    this.attentionsState.deleteAttention(attention)
                    this.attentionsState.deleteAttentionCaseActive(attention)
                },
                error: e => rej(e)
            })
        })
        return from(promise);
    }

    setAttentionsCaseActive(attentions: Attention[]) {
        this.attentionsState.setAttentionsCaseActive(attentions);
    }

    setAttentions(attentions: Attention[]) {
        this.attentionsState.setAttentions(attentions);
    }

    deleteAttentionsBatch(qps: AttentionsDeleteBatchQPS): Observable<any> {
        this.attentionsState.setLoadingDeleteBatchAttentions(true);

        const promise: Promise<any> = new Promise((res, rej) => {
            this.attentionsService.deleteAttentionsBatch(qps).pipe(
                finalize(() => this.attentionsState.setLoadingDeleteBatchAttentions(false))
            ).subscribe({
                next: (response) => res(response),
                error: (error) => rej(error)
            })
        })
        return from(promise);
    }

    isLoadingDeleteBatchAttentions$(): Observable<boolean> {
        return this.attentionsState.isLoadingDeleteBatchAttentions$();
    }

    getAttentions(): Attention[] {
        return this.attentionsState.getAttentions();
    }

    getAttentionsCaseActive(): Attention[] {
        return this.attentionsState.getAttentionsCaseActive();
    }
}
