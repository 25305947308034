import { Injectable } from '@angular/core';
import { BaseState } from './base.state';
import { BehaviorSubject } from 'rxjs';
import { Pagination } from '../../shared/models/pagination';
import { Observable } from 'rxjs';
import { Requisition } from 'src/app/shared/models/requisition';
import { MetaDataRequisitions } from 'src/app/abstraction/requisitions.facade';
import { RequisitionsQPS } from '../services/requisition.service';
import { RequisitionIndicator } from '../../shared/models/requisition';

@Injectable({
    providedIn: 'root',
})
export class RequisitionsState extends BaseState {


    override store = {

        // Categories of Solutions
        requisitions$: new BehaviorSubject<Requisition[]>(null),
        loadingGetRequisitions$: new BehaviorSubject<boolean>(null),
        loadingCreatingRequisitions$: new BehaviorSubject<boolean>(null),
        loadingUpdatingRequisitions$: new BehaviorSubject<boolean>(null),

        // Pagination
        pagination$: new BehaviorSubject<Pagination>(null),
        loadingGettingPagination$: new BehaviorSubject<boolean>(false),

        // Metadata
        metaDataRequisitions$: new BehaviorSubject<RequisitionsQPS>(null),

        // Count
        requisitionsCount$: new BehaviorSubject<number>(null),

        // Indicators
        loadingGetIndicatorsRequisitions$: new BehaviorSubject<boolean>(null),
        requisitionsToIndicators$: new BehaviorSubject<RequisitionIndicator[]>(null),
    };

    // Categories of Evolutions
    getRequisitions$() {
        return this.store.requisitions$.asObservable();
    }

    setRequisitions(requisitions: Requisition[]) {
        this.store.requisitions$.next(requisitions);
    }

    isLoadingGetRequisitions$() {
        return this.store.loadingGetRequisitions$.asObservable();
    }

    setLoadingGetRequisitions(isLoadingGetRequisitions: boolean) {
        this.store.loadingGetRequisitions$.next(isLoadingGetRequisitions);
    }

    // ADD
    isLoadingCreatingRequisition$() {
        return this.store.loadingCreatingRequisitions$.asObservable();
    }

    setLoadingCreatingRequisition(isLoadingCreatingRequisitions: boolean) {
        this.store.loadingCreatingRequisitions$.next(isLoadingCreatingRequisitions);
    }

    addRequisition(requisition: Requisition) {
        this.add<Requisition>({ data: requisition, storeRefAttribute: this.store.requisitions$ })
    }

    // UPDATE
    setLoadingUpdatingRequisition(isLoadingUpdatingRequisitions: boolean) {
        this.store.loadingUpdatingRequisitions$.next(isLoadingUpdatingRequisitions);
    }

    updateRequisition(requisition: Requisition, requisitionId: number) {
        this.update<Partial<Requisition>>({ data: requisition, dataId: requisitionId, storeRefAttribute: this.store.requisitions$ })
    }

    isLoadingUpdatingRequisition$() {
        return this.store.loadingUpdatingRequisitions$.asObservable();
    }

    // Pagination
    getPagination$(): Observable<Pagination> {
        return this.store.pagination$.asObservable();
    }

    setPagination(pagination: Pagination) {
        this.store.pagination$.next(pagination);
    }


    isLoadingGettingPagination$() {
        return this.store.loadingGettingPagination$.asObservable();
    }

    setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
        this.store.loadingGettingPagination$.next(isLoadingGettingPagination);
    }

    // Metadata
    getMetaDataRequisitions$(): Observable<MetaDataRequisitions> {
        return this.store.metaDataRequisitions$.asObservable();
    }

    setMetaDataRequisitions( md: MetaDataRequisitions ) {
        this.store.metaDataRequisitions$.next( md );
    }

    // Count
    getRequisitionsCount$(): Observable<number> {
        return this.store.requisitionsCount$.asObservable();
    }

    setRequisitionsCount(count: number) {
        this.store.requisitionsCount$.next(count);
    }

    // Indicators
    isLoadingGetRequisitionsIndicators$() {
        return this.store.loadingGetIndicatorsRequisitions$.asObservable();
    }

    setLoadingGetRequisitionsIndicators(isLoadingGetIndicatorsRequisitions: boolean) {
        this.store.loadingGetIndicatorsRequisitions$.next( isLoadingGetIndicatorsRequisitions );
    }

    setRequisitionsToIndicators(requisitions: RequisitionIndicator[]) {
        this.store.requisitionsToIndicators$.next( requisitions );
    }

    getRequisitionsToIndicators$(): Observable<RequisitionIndicator[]> {
        return this.store.requisitionsToIndicators$.asObservable();
    }

    addRequisitionIndicators(requisition: Requisition) {
        this.add<RequisitionIndicator>({ data: this.castRequisitionToRequisitionIndicator( requisition ), storeRefAttribute: this.store.requisitionsToIndicators$ })
    }

    updateRequisitionIndicator(requisition: Requisition, requisitionId: number) {

        this.update<Partial<RequisitionIndicator>>({ data: this.castRequisitionToRequisitionIndicator( requisition ), dataId: requisitionId, storeRefAttribute: this.store.requisitionsToIndicators$ })
    }

    castRequisitionToRequisitionIndicator( requisition: Requisition ): RequisitionIndicator {
        return {
            id: requisition.id,
            requisitionType: requisition.requisitionResponseDto.requisitionType,
            requisitionState: requisition.requisitionResponseDto.requisitionState,
            requisitionReason: requisition.requisitionResponseDto.requisitionReason,
            caseId: requisition.caseRequisitionResponseDto.id,
            fromDate: requisition.caseRequisitionResponseDto.fromDate,
            complexities: requisition.caseRequisitionResponseDto.complexities
        }
    }
}
