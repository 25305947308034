import { Component, ViewChild, OnInit, ChangeDetectorRef, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProvidersService } from 'src/app/core/services/providers.service';
import { Provider } from 'src/app/shared/models/provider';
import { CasesService } from 'src/app/core/services/cases.service';
import { Patient } from 'src/app/shared/models/patient';
import { MatDialog } from '@angular/material/dialog';
import { Agreement } from 'src/app/shared/models/agreement';
import { Attention } from 'src/app/shared/models/attention';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { DateService } from 'src/app/core/services/date.service';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import * as moment from 'moment';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { GeneralService } from 'src/app/core/services/general.service';
import { LOCALE_ID, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AgreementsQPS, ProvisionsService } from '../../../../core/services/provisions.service'
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

/**
 * Schedule
 * allAgreementsDialog: elementos del select
 * allPatientsDialog: elementos del select
 * allProvidersDialog: elementos del select
 * allAgreementsDialogList: total de agreements provenientes de la request
 */

// Array de dates estandar, en moment, sin horas ni hasta.
// Por cada dia seleccionado, lo voy agregando a la lista de dates, por cada uno deseleccionado, lo saco
// ** Si es el primero, se setea el fromDate con ese valor, sino se busca si es el mas antiguo, si lo es se setea, sino nada
// Por cada dia del patrón seleccionado:
// llamo a una funcion que me de los dias que correspondan agregar -> los dias que no esten, los agrego a la lista de array
// Por cada dia del patron deseleccionado:
// llamo a una funcion que me de los dias que correspondan quitar -> recorro array de dias y los quito.

// onCreateAttention =>

// si es desde hasta con hora => tomo todos los días del array, les pego la hora de inicio.
//por cada uno de ellos, hago un moment.add horas de duración de la atencion, y creo un array unico con fromDate toDate.
// si es con desde sin hora => tomo todos los dias del array, genero el arraytopost con hasta en null y hago el post

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],

})
export class ScheduleComponent implements OnInit {
    // Momentaneo, para tener los datos del filtro aplicado
    dataFiltered: {
        providersSelected,
        patientsSelected,
        agreementsSelected,
        allPatients,
        allAgreements,
        allAgreementsList
    };
    // Primer Provider de la lista de chips, seteado al momento de crear atencion
    // (se utiliza para precargar form de crear agenda)
    temporalProvider;
    @ViewChild('scheduleDialogTemplate', { static: true }) scheduleDialogTemplate: TemplateRef<any>;
    @ViewChild('calendar', { static: false }) calendarToPost;
    datesToPostSelected: moment.Moment[] = [];
    TITLE = 'Agenda';
    objectValues = Object.values;
    _dialogRef
    globalRequestsParams: {
        fromDate: Date,
        toDate: Date,
        windowFromDate: number,
        windowToDate: number
    } = {
            fromDate: null,
            toDate: null,
            windowFromDate: null,
            windowToDate: null
        }
    error = false;
    loadingSchedule = false;
    loadingCalendar = false;
    loadingCreateAttention = false;
    loadingEditAttention = false;
    loadingGetAgreements = false;
    allPatientsDialog: Patient[] = [];
    allAgreementsDialog: Agreement[] = [];
    allAgreementsDialogList: Agreement[] = [];
    // Atenciones cargadas en la agenda
    attentions: Attention[];
    // Agreements a partir de los cuales se cargó la agenda
    agreementsSelected: Agreement[];
    public dayChipsSelected: Map<string, boolean> = new Map<string, boolean>();
    ATTENTION_STATES = AttentionStates;
    // Agreement que se muestra en el dialog
    activeAttention: Attention;
    // Agreement que se selecciona al seleccionar en el select de nueva atencion! -> Se usa para saber que
    // restricciones tiene la carga (fecha de inicio/fin hora etc.)
    agreementSelected: Agreement;
    isRepeatSchedule = false;
    // FORM //
    public readonly PROVIDER = 'provider';
    public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly PATIENT = 'patient';
    public readonly PATIENT_SEARCHING = 'patientSearching';
    public readonly AGREEMENT = 'agreement';
    public readonly DATE_FROM = 'fromDate';
    public readonly DATE_TO = 'toDate';
    public readonly TIME_FROM = 'timeFrom';
    // public readonly TIME_DURATION = 'timeDuration';
    public readonly HOUR_DURATION = 'hourDuration';
    public readonly MINUTE_DURATION = 'minuteDuration';
    public readonly DATE_END_REPEAT = 'endDateRepeat';
    public readonly STATE_ATTENTION = 'stateAttention';
    scheduleForm: FormGroup;
    attentionStates: StateAttention[] = [
        {
            id: AttentionStates.AGENDADA,
            name: 'Agendada',
        },
        {
            id: AttentionStates.EN_CURSO,
            name: 'En curso',
        },
        {
            id: AttentionStates.NO_REALIZADA,
            name: 'No realizada',
        },
        {
            id: AttentionStates.REALIZADA,
            name: 'Realizada',
        },
        {
            id: AttentionStates.FACTURABLE,
            name: 'Facturable',
        },
        {
            id: AttentionStates.DEBITADA,
            name: 'Debitada',
        },
        {
            id: AttentionStates.EN_PROCESO,
            name: 'En proceso',
        },
        {
            id: AttentionStates.INFORMADA,
            name: 'Informada',
        },
        {
            id: AttentionStates.NO_INFORMADA,
            name: 'No informada',
        },
    ];
    attentionStatesPost: StateAttention[] = [
        {
            id: AttentionStates.AGENDADA,
            name: 'Agendada',
        },
        {
            id: AttentionStates.FACTURABLE,
            name: 'Facturable',
        },
    ];

    get providerForm() {
        return this.scheduleForm.get(this.PROVIDER).value;
    }
    get patientForm() {
        return this.scheduleForm.get(this.PATIENT).value;
    }
    get agreementForm() {
        return this.scheduleForm.get(this.AGREEMENT).value;
    }
    get dateFromForm() {
        return this.scheduleForm.get(this.DATE_FROM).value;
    }
    get dateToForm() {
        return this.scheduleForm.get(this.DATE_TO).value;
    }
    get timeFromForm() {
        return this.scheduleForm.get(this.TIME_FROM).value;
    }
    // get timeDurationForm() {
    //   return this.scheduleForm.get(this.TIME_DURATION).value;
    // }
    get hourDurationForm() {
        return this.scheduleForm.get(this.HOUR_DURATION).value;
    }
    get minuteDurationForm() {
        return this.scheduleForm.get(this.MINUTE_DURATION).value;
    }
    get dateEndRepeatForm() {
        return this.scheduleForm.get(this.DATE_END_REPEAT).value;
    }
    get stateAttentionForm() {
        return this.scheduleForm.get(this.STATE_ATTENTION).value;
    }

    set providerForm(providerId: number) {
        providerId != null ? this.scheduleForm.controls[this.PROVIDER].setValue(providerId.toString())
            : this.scheduleForm.controls[this.PROVIDER].reset()
    }
    set patientForm(patientId: number) {
        patientId != null ? this.scheduleForm.controls[this.PATIENT].setValue(patientId.toString())
            : this.scheduleForm.controls[this.PATIENT].reset()

    }
    set agreementForm(agreementId: number) {
        agreementId != null ? this.scheduleForm.controls[this.AGREEMENT].setValue(agreementId.toString())
            : this.scheduleForm.controls[this.AGREEMENT].reset()
    }
    set dateFromForm(date: Date) {
        date != null ? this.scheduleForm.controls[this.DATE_FROM].setValue(date)
            : this.scheduleForm.controls[this.DATE_FROM].reset()
    }
    set dateToForm(date: Date) {
        date != null ? this.scheduleForm.controls[this.DATE_TO].setValue(date)
            : this.scheduleForm.controls[this.DATE_TO].reset()
    }
    set timeFromForm(hourAndMinute: string) {
        hourAndMinute != null ? this.scheduleForm.controls[this.TIME_FROM].setValue(hourAndMinute)
            : this.scheduleForm.controls[this.TIME_FROM].reset()
    }
    // set timeDurationForm(hourAndMinute: string) {
    //   hourAndMinute != null ? this.scheduleForm.controls[this.TIME_DURATION].setValue(hourAndMinute)
    //     : this.scheduleForm.controls[this.TIME_DURATION].reset()
    // }
    set hourDurationForm(hours: number) {
        hours != null ? this.scheduleForm.controls[this.HOUR_DURATION].setValue(hours)
            : this.scheduleForm.controls[this.HOUR_DURATION].reset()
    }
    set minuteDurationForm(minutes: number) {
        minutes != null ? this.scheduleForm.controls[this.MINUTE_DURATION].setValue(minutes)
            : this.scheduleForm.controls[this.MINUTE_DURATION].reset()
    }
    set dateEndRepeatForm(date: Date) {
        date != null ? this.scheduleForm.controls[this.DATE_END_REPEAT].setValue(date)
            : this.scheduleForm.controls[this.DATE_END_REPEAT].reset()
    }
    set stateAttentionForm(stateId: number) {
        stateId != null ? this.scheduleForm.controls[this.STATE_ATTENTION].setValue(stateId.toString())
            : this.scheduleForm.controls[this.STATE_ATTENTION].reset()
    }

    swalWithCustomizeButtons: any;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        public attentionsService: AttentionsService,
        public cd: ChangeDetectorRef,
        private providersService: ProvidersService,
        private casesService: CasesService,
        private dateService: DateService,
        private generalService: GeneralService,
        private entitiesFacade: EntitiesFacade,
        @Inject(LOCALE_ID) public locale: string,
        private provisionsService: ProvisionsService,
    ) {
    }

    ngOnInit() {

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this.generalService.sendMessage(this.TITLE);
        this.setDayChipsSelected();
        this.setGlobalRequestParams();
        this.calculateNameProvider = this.calculateNameProvider.bind(this);
    }

    setDayChipsSelected() {
        this.dayChipsSelected.set('D', false);
        this.dayChipsSelected.set('L', false);
        this.dayChipsSelected.set('M', false);
        this.dayChipsSelected.set('X', false);
        this.dayChipsSelected.set('J', false);
        this.dayChipsSelected.set('V', false);
        this.dayChipsSelected.set('S', false);
    }

    // Retorna si el día del mat-calendar está en el array de los dates seleccionados (datesToPostSelected)
    // Es llamado desde el html, en el mat-calendar
    isSelectedDate = (date: moment.Moment) => {
        return this.datesToPostSelected.find(x => x.isSame(date, 'day')) ? "selected" : null;
    };

    // !Mutable
    // Ordena un array de moments, de manera ascendente (mas vieja primero)
    sortAscArrayDates(array: moment.Moment[]) {
        array.sort(function (a, b) {
            const a2 = a.toDate();
            const b2 = b.toDate();
            return a < b ? -1 : a > b ? 1 : 0;
        });
    }

    setGlobalRequestParams(date?: Date) {
        // 2 y 2 representa una ventana de 4 meses, con 2 para atras, el actual y uno para adelante
        this.globalRequestsParams.windowFromDate = 2
        this.globalRequestsParams.windowToDate = 2
        if (!date) {
            date = new Date();
        }
        this.globalRequestsParams.fromDate = moment(date).subtract(this.globalRequestsParams.windowFromDate, 'months').date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
        this.globalRequestsParams.toDate = moment(date).add(this.globalRequestsParams.windowToDate, 'months').date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    calculateNamePatient(element: Patient) {
        return element ? element.surname + ' ' + element.name : null;
    }

    calculateNameAgreement(element: Agreement) {
        var datePipe = new DatePipe('es-Ar');
        const provisionFee = element?.provision?.fee ? ' • $' + element.provision.fee : '';

        return element ? element.provision.practice.name + provisionFee + (!!element.toDate ? ' • ' + datePipe.transform(element.toDate, 'dd/MM/yyyy') : '') : null;
    }

    calculateNameState(element: StateAttention) {
        return element ? element.name : null;
    }

    calculateIdState(element: StateAttention) {
        return element ? element.id : null;
    }

    composeAttentions(attentions: Attention[], agreements: Agreement[]): Attention[] {
        const attentionsComposed = attentions.map(attention => {
            const agreement = agreements.find(agreement => agreement.id === attention.agreementId);
            if (agreement != null) {
                attention.patient = agreement.provision.casee.patient;
                attention.provider = agreement.provider;
            }
            return attention;
        });
        return attentionsComposed;
    }

    setAgreementToForm() {
        this.allPatientsDialog = [this.activeAttention.patient];
        this.allAgreementsDialog = this.agreementsSelected;
        this.providerForm = this.activeAttention.provider.id;
        this.patientForm = this.activeAttention.patient.id;
        this.agreementForm = this.activeAttention.agreementId;
        this.dateFromForm = this.activeAttention.fromDate;
        this.dateToForm = this.activeAttention.toDate;
        this.stateAttentionForm = this.activeAttention.state.id;
        this.cd.markForCheck();
    }

    openDialog(title: string): void {

        this._dialogRef = this.dialog.open(DialogComponent, {
            minWidth: '90%',
            maxHeight: '95vh',
            data: { template: this.scheduleDialogTemplate, title },
        });

        this._dialogRef.afterClosed().subscribe((agreement: Agreement) => {
            this.agreementSelected = null;
            this.onCleanAttentions();
            this.scheduleForm.reset();
            this.isRepeatSchedule = false
        });
    }

    resetValuesDialog(resetProvider = false) {
        this.allAgreementsDialog = [];
        this.allPatientsDialog = [];
        this.patientForm = null;
        this.agreementForm = null;
        this.agreementSelected = null;
        if (resetProvider) {
            this.providerForm = null;

        }
    }

    // Retorna en número el dia pasado como argumento (key: "L", "M"... value: true/false)
    getDayChipNumber(day: { key, value }): number {
        let numberDay;
        [...this.dayChipsSelected.entries()].forEach((element, i) => {
            const key = element[0];
            if (key == day.key) {
                numberDay = i;
                return false;
            }
        });
        return numberDay;
    }

    // Metodo que se llama cuando se selecciona o se deselecciona un day chip
    selectDayChip(day: { key, value }) {
        //Actualizo la vista de los chips
        this.dayChipsSelected.set(day.key, !this.dayChipsSelected.get(day.key))
        //Actualizo el value del dia que me vino (por la accion anterior)
        day.value = this.dayChipsSelected.get(day.key);
        //Obtengo el dia en numero (antes estaba como L,M,X,J...)
        let numberDay = this.getDayChipNumber(day);
        // Obtengo el array de dias de ese día (chip) específico que se selecciono/deselecciono
        const daysOfDayChip: moment.Moment[] = this.dateService.recurDaysOfWeek(this.dateFromForm, this.dateEndRepeatForm, [numberDay]);
        // Inserto los días en el array de dias base (si el day chip fue seleccionado, es decir day.value == true)
        // o elimino los días en el array de dias base (si el day chip fue deseleccionado, es decir day.value == false)
        this.updateDatesToPostSelected(daysOfDayChip, day.value);
        //Si ese dia fue seleccionado
        // Ordeno el array
        this.sortAscArrayDates(this.datesToPostSelected)
        // Actualizo el dateFormFrom si es necesario
        this.updateDateFromForm();
        this.calendarToPost.updateTodaysDate();
    }

    // Metodo llamado para actualizar el array de dias (agregar/eliminar dias segun los que ya están y los nuevos)
    updateDatesToPostSelected(newDays: moment.Moment[], insert: boolean) {
        //Si se deben agregar los dias
        if (insert) {
            // Los dias que no estén los agrego
            newDays.forEach(newDay => {
                const indexNewDayOnOriginalArray = this.datesToPostSelected.findIndex(adate => adate.isSame(newDay, 'day'))
                if (indexNewDayOnOriginalArray == -1) {
                    this.datesToPostSelected.push(newDay);
                }
            })
        }
        //Si se deben quitar los dias
        else {
            // Recorro y borro los días que sean iguales
            newDays.forEach(dayToDelete => {
                const indexDayToDeleteOnOriginalArray = this.datesToPostSelected.findIndex(adate => adate.isSame(dayToDelete, 'day'))
                if (indexDayToDeleteOnOriginalArray != -1) {
                    this.datesToPostSelected.splice(indexDayToDeleteOnOriginalArray, 1);
                }
            })
        }
    }

    // Actualiza el valor del formulario "DateFromForm" en caso de que corresponda
    updateDateFromForm() {
        //Si no hay fechas en el array o la fecha mas antigua del array es distinta a la del form modifico el form
        if (!this.datesToPostSelected[0] || new Date(this.datesToPostSelected[0].toDate()).getTime() != new Date(this.dateFromForm).getTime()) {
            this.dateFromForm = !!this.datesToPostSelected[0] ? this.datesToPostSelected[0].toDate() : null;
        }
    }

    asIsOrder(a, b) {
        return 1;
    }

    isDayChipSelected(day) {
        return this.dayChipsSelected.get(day);
    }

    /***********
     * HANDLERS *
     ************/
    onLoadScheduleExtended(data) {
        this.dataFiltered = data;
    }

    onLoadSchedule(agreements: Agreement[], fromDate?: Date, toDate?: Date, loadSchedule: boolean = true, loadCalendar = true, resetDates = true, resetAttentions = false) {
        // Esta bandera se activa cuando se hace click sobre Ver Agenda
        if (loadSchedule) {
            this.loadingSchedule = true;
        }
        if (loadCalendar) {
            this.loadingCalendar = true;
        }
        // Esta bandera se activa cuando se hace click sobre Ver Agenda
        if (resetDates) {
            this.setGlobalRequestParams();
        }
        this.error = false;
        this.agreementsSelected = agreements;
        const agreementsId = agreements.map(agreement => agreement.id)
        this.getAttentionsByAgreements(agreementsId, fromDate, toDate)
            .then(attentions => this.composeAttentions(attentions, agreements))
            // .then(attentionsComposed => this.attentions = attentionsComposed)
            .then(attentionsComposed => this.attentions = (loadSchedule || resetAttentions) ? attentionsComposed : this.mergeAndCleanAttentionsWindow(attentionsComposed))
            .then(_ => (this.loadingSchedule = false, this.loadingCalendar = false))
            .catch(_ => (this.loadingSchedule = false, this.loadingCalendar = false, this.error = true))
    }
    mergeAndCleanAttentionsWindow(newAttentions: Attention[]) {
        const totalAttentions = this.attentions ? this.attentions.concat(newAttentions) : newAttentions;
        const attentionsFiltered = totalAttentions.filter(attention => (new Date(attention.fromDate) >= new Date(this.globalRequestsParams.fromDate) && new Date(attention.fromDate) <= new Date(this.globalRequestsParams.toDate)));
        return attentionsFiltered

    }

    /**
     *
     * @param date Mes actual
     */
    onChangeMonth(date: Date) {
        const isDateLessThanFrom = this.isMonthOutOfWindowFrom(date);
        const isDateGreaterThanTo = this.isMonthOutOfWindowTo(date);
        if (isDateLessThanFrom || isDateGreaterThanTo) {
            let fromDateRequest, toDateRequest;
            if (isDateLessThanFrom) {
                fromDateRequest = moment(date).subtract(this.globalRequestsParams.windowFromDate, 'months').date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
                toDateRequest = moment(date).add(1, 'months').date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
            } else {
                fromDateRequest = moment(date).date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
                toDateRequest = moment(date).add(this.globalRequestsParams.windowToDate, 'months').date(1).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
            }
            this.setGlobalRequestParams(date);
            this.onLoadSchedule(this.agreementsSelected, fromDateRequest, toDateRequest, false, true, false);
        }
    }

    // Handler llamado cuando se selecciona manualmente un dia del mat-calendar
    onSelectDate(date: moment.Moment, calendar: any) {
        date = date.set({ hour: 0, minute: 0, second: 0 })
        // Elimino / Agrego item al array de fechas
        const index = this.datesToPostSelected.findIndex(x => x.isSame(date, 'day'));
        if (index < 0) this.datesToPostSelected.push(date);
        else this.datesToPostSelected.splice(index, 1);
        // Ordeno el array
        this.sortAscArrayDates(this.datesToPostSelected);
        // Actualizo el dateFromForm si es necesario
        this.updateDateFromForm();
        calendar ? calendar.updateTodaysDate() : null;
    }

    private isMonthOutOfWindowFrom(date: Date) {
        return !!this.agreementsSelected && this.agreementsSelected.length > 0 && date < this.globalRequestsParams.fromDate
    }

    private isMonthOutOfWindowTo(date: Date) {
        return !!this.agreementsSelected && this.agreementsSelected.length > 0 && date >= this.globalRequestsParams.toDate
    }

    deleteDatesUntil(dateUntil) {
        dateUntil = moment(dateUntil);
        this.datesToPostSelected = this.datesToPostSelected.filter(adate => adate.isSameOrBefore(dateUntil));
        this.calendarToPost && this.calendarToPost.updateTodaysDate();
    }

    onChangeCheckRepeatSchedule(event) {
        this.isRepeatSchedule = event.checked;
        // event.checked ? this.dateEndRepeatForm = moment(this.dateFromForm).add('months', 1).date(0).toDate() : this.dateEndRepeatForm = null;
        event.checked ? this.dateEndRepeatForm = moment(this.dateFromForm).add('months', 1).date(0).toDate() : (this.setDayChipsSelected());
    }

    onSelectProvider(providerId: number): Promise<any> {
        this.loadingGetAgreements = true;
        return new Promise((resolve, reject) => {
            this.resetValuesDialog();

            this.getAgreementsByProvider(providerId)
                .then(agreementsRequest => { this.allAgreementsDialogList = agreementsRequest })
                .then(_ => {
                    this.allAgreementsDialogList.forEach(
                        agreement => {
                            if (!this.allPatientsDialog.find(patient => patient.id === agreement.provision.casee.patient.id)) {
                                this.allPatientsDialog.push(agreement.provision.casee.patient);
                            }
                        })
                })
                .then(_ => resolve(_))
                .catch(err => reject(err))
                .finally(() => this.loadingGetAgreements = false)
        })
        // }
    }

    onSelectPatient(patientId: number) {
        this.allAgreementsDialog = this.allAgreementsDialogList.filter(agreement => agreement.provision.casee.patient.id == patientId)
    }

    onCleanAttentions() {
        this.datesToPostSelected = [];
        this.setDayChipsSelected();
        // Ordeno el array
        this.sortAscArrayDates(this.datesToPostSelected);
        // Actualizo el dateFromForm si es necesario
        this.updateDateFromForm();
        this.calendarToPost ? this.calendarToPost.updateTodaysDate() : null;
    }

    onSelectAgreement(agreementId: number) {
        this.agreementSelected = this.allAgreementsDialog.find(agreement => agreement.id == agreementId);
        if (!this.activeAttention) {
            setTimeout(() => {
                this.stateAttentionForm = this.ATTENTION_STATES.AGENDADA;
                if (this.agreementSelected.provision.practice.restriction.requiredToDate) {
                    // this.dateToForm = moment(this.dateFromForm).add(1, 'hours').toDate();
                    // this.timeDurationForm = '08:00'
                    this.hourDurationForm = 2;
                    this.minuteDurationForm = 0;
                }
            }, 0)
        }
    }

    onClickCloseDialog() {
        this._dialogRef.close();
    }

    /************
    * SERVICES *
    ************/

    /**
     * TODO: Validar modelo de providers
     */
    getProvidersByFullName = (value: string) => this.entitiesFacade.loadProviders({ fullName_like: value, active: true })

    getAgreementsByProvider = (providerId): Promise<Agreement[]> => {
        return new Promise((resolve, reject) => {
            let qps: AgreementsQPS = {
                providersIds: providerId,
                active: true,
            }
            this.provisionsService.getAgreementsByProvider(qps).subscribe(
                (agreements) => {
                    resolve(agreements);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    getAttentionsByAgreements(agreementsId: number[], fromDate?: Date, toDate?: Date): Promise<Attention[]> {
        return new Promise((resolve, reject) => {
            this.attentionsService.getAttentionsByAgreements(agreementsId, fromDate, toDate, false).subscribe(
                (attentions) => {
                    resolve(attentions);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }
}
