<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg">
    </mat-icon>
    <mat-form-field class="flex-100" appearance="outline" [formGroup]="form">
        <!-- <mat-label class="align-start-center" *ngIf="this.label || this.icon || this.iconSvg">
          <mat-icon class="mrs-2" *ngIf="this.icon">{{ icon }}</mat-icon>
          <mat-icon class="mrs-2" *ngIf="this.iconSvg" [svgIcon]="iconSvg"></mat-icon>
          {{ label }}
       </mat-label> -->
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <mat-hint>{{ this.hint }}</mat-hint>
        <mat-select disableOptionCentering [formControlName]="name" id="{{ name }}" placeholder="{{ placeholder }}"
            [disabled]="disabled ? disabled : false" (selectionChange)="change($event)">
            <ngx-mat-select-search [placeholderLabel]="placeholderSearching" [searching]="searchingSpinner"
                [noEntriesFoundLabel]="placeholderNoValue" [formControlName]="nameSearching"></ngx-mat-select-search>
            <!-- <mat-option value="{{ proxyCalculateId()(element) }}" *ngFor="let element of elements">
             {{ proxyCalculateName()(element) }}
          </mat-option> -->
            <mat-option *ngIf="this.noOptionText">{{noOptionText}}</mat-option>
            <mat-option *ngFor="let element of filteredValues | async" value="{{ proxyCalculateId()(element) }}"
                [matTooltip]="proxyCalculateName()(element)">
                {{ proxyCalculateName()(element) }}
            </mat-option>
        </mat-select>
        @if (this.form.get(this.name)?.errors && (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)) {
            <mat-error>{{ getError() }}</mat-error>
        }
    </mat-form-field>
</div>
