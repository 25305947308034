<div class="flex-column" style="border-radius: 20px !important;">
    <!-- HEADER -->
    <div class="flex-column" *ngIf="!!this.data.title" [ngClass]="!!this.data.headerClass ? this.data.headerClass : 'bg-primary-lighter'"
        style="position: sticky; top: 0; z-index:10">
        <!-- TITLE -->
        <div class="flex-row align-space-between-center"  style="margin: 4px !important;">
            <span class="ml-1" [ngClass]="!!this.data.titleClass ? this.data.titleClass : 'text-primary-darker-h3'">
                {{this.data.title}}
            </span>
            <button mat-icon-button (click)="close()" [color]="'primary'">
                <mat-icon>highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <div [ngClass]="!!this.data.templateClass ? this.data.templateClass : 'p-24'">
        <ng-container [ngTemplateOutlet]="data.template"></ng-container>
    </div>
</div>
