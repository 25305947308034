import { Component, OnInit, Input, ViewChild, TemplateRef, OnDestroy, SimpleChanges, ElementRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Order } from 'src/app/shared/models/order';
import { MetaDataOrders, CasesFacade } from 'src/app/abstraction/cases.facade';
import { OrdersQPS, PatchOrderBody, CasesService, DeliveryNotePDFBody } from 'src/app/core/services/cases.service';
import { Case } from 'src/app/shared/models/case';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { OrdersStates } from 'src/app/core/enums/ordersStates';
import { GeneralService } from 'src/app/core/services/general.service';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Item } from 'src/app/shared/models/item';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Provider } from 'src/app/shared/models/provider';
import Swal from 'sweetalert2';
import mime from 'mime';
import { NomenclatorFacade } from 'src/app/abstraction/nomenclator.facade';
import { Practice } from 'src/app/shared/models/practice';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AttentionsFacade } from '../../../../abstraction/attentions.facade';
import { ProvisionAgreementFilters } from 'src/app/shared/models/ProvisionAgreementFilters';
import { ProvisionsFacade, AUTHORIZATION_COLORS, AUTHORIZATION_BACKGROUND_COLORS } from 'src/app/abstraction/provisions.facade';
import * as moment from 'moment';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { EvolutionsDialogComponent } from '../evolutions-dialog/evolutions-dialog.component';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Attention } from 'src/app/shared/models/attention';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { EvolutionsQPS } from 'src/app/core/services/evolutions.service';
import { Provision } from 'src/app/shared/models/provision';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { Evolution } from 'src/app/shared/models/evolution';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class OrderListComponent implements OnInit, OnDestroy {

    @Input() case: Case;
    @Input() isCollapsed: boolean = false;
    @Input() allProvisionsWithoutFilter: Provision[];

    @ViewChild('myPond', { static: false }) myPond: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('modifyOrderTemplate', { static: true }) modifyOrderTemplate: TemplateRef<any>;
    @ViewChild('popUpMessage', { static: true }) popUpMessage: TemplateRef<any>;
    @ViewChild('supplyOrdersFocus') matTable: ElementRef;

    selection = new SelectionModel<Order>(true, []);
    ORDERS_STATES = OrdersStates;
    orders$: Observable<Order[]>
    _orders: Subscription;
    orders: Order[];
    _mdo: Subscription;
    isLoadingGettingOrders: boolean;
    _isLoadingGettingOrders: Subscription;
    isLoadingUpdatingOrder: boolean;
    _isLoadingUpdatingOrder: Subscription;
    isLoadingCreatingOrder: boolean;
    _isLoadingCreatingOrder: Subscription;
    isLoadingUpdatingOrder$: Observable<boolean>;
    isLoadingUpdatingProvision$: Observable<boolean>;
    isLoadingCreatingProvision$: Observable<boolean>;
    isLoadingGettingAttentions$: Observable<boolean>;
    _provisionsDisplayedOnScreen: Subscription;
    _attentions: Subscription;
    attentions: Attention[];
    provisionFees: ProvisionFee[] = []
    metaDataOrders: MetaDataOrders;
    dataSource;
    dataSourceSupplies;
    _dialogRef;
    _historyModeDate: Subscription;
    _jumpToScreen:Subscription;
    historyFromDate: Date;
    historyToDate: Date;
    actualOrder: Order;
    deliveringOrder: boolean = false;
    editingOrder: boolean = false
    formModifyOrder: FormGroup;
    providersOnSelect: Provider[];
    actualOrderProvider;
    photoInvoice = null;
    fileChanged;
    expandedElement: any;
    practices: Practice[];
    loadingAddFile: boolean = false;
    toDay: Date = moment(new Date(), 'YYYY-MM-DD').toDate();
    AUTHORIZATION_COLORS = AUTHORIZATION_COLORS;
    message: string;
    txtConfirmBtn: string;
    txtCancelBtn: string;
    showConfirmBtn: boolean;
    closeAll: boolean = false;
    arrayTable = [];
    _viewManagementEntities= ViewManagementEntities;
    AUTHORIZATION_BACKGROUND_COLORS = AUTHORIZATION_BACKGROUND_COLORS;
    displayedColumnsCollapsed: string[] = [
        'arrow',
        'checkbox',
        'id',
        'provider',
        'quantity'
    ]
    displayedColumns: string[] = [
        'arrow',
        'checkbox',
        'id',
        'provider',
        'quantity',
        'orderDate',
        'deliveryDate',
        'state',
        'updateOrder',
        //'delete'
    ];

    // 'supplyDelivery',
    displayedColumnsSuppliesDeliveringOrder: string[] = [
        'supplyRequested',
        'quantityRequested',
        'priceRequested',
        'quantityDelivery',
        'priceDelivery',
        'marginDelivery',
        'feeDelivery',
        'fixedPriceDelivery'
    ];

    displayedColumnsSupplies: string[] = [
        'supplyRequested',
        'quantityRequested',
        'priceRequested'
    ];
    displayedColumnsPopUp: string[] = ['supplyName', 'quantity', 'provider'];
    public readonly ORDER_ITEMS = 'items';
    public readonly ORDER_PROVIDER = 'providerId';
    public readonly ORDER_PROVIDER_SEARCHING = 'providerSearching'
    public readonly ORDER_DATE = 'orderDate';
    public readonly ORDER_DATE_DELIVERY = 'deliveryDate';
    public readonly ORDER_OBSERVATIONS = 'observations';
    public readonly ORDER_CONFIRMED = 'state';
    public readonly ITEM_QUANTITY = 'quantityOrdered';
    public readonly ITEM_QUANTITY_DELIVERY = 'quantityDelivered';
    public readonly ITEM_PRICE_DELIVERY = 'priceDelivered';
    public readonly ITEM_ID = 'id';
    public readonly ITEM_PRICE = 'priceOrdered';
    public readonly ITEM_PRACTICE_SEARCHING = 'practiceItemSearching';
    public readonly ITEM_PRACTICE_DELIVERY = 'practiceItemDelivered';
    public readonly ITEM_PRACTICE = 'practice';
    public readonly ITEM_UNIT = 'unit';
    public readonly ITEM_KAIROS = 'fixedPrice'; // Kairos
    public readonly ITEM_VISIBLE_CHECK_KAIROS = 'visibleCheckKairos';
    public readonly ITEM_FEE = 'fee'; // provisionFee
    public readonly ITEM_MARGIN = 'profitMargin'; // Margin
    public readonly IS_BILLABLE = 'isBillable'; // Checkbox
    public readonly DELIVERY_NOTE = 'deliveryNote'; // Checkbox
    evolutions$: Observable<any>;
    _evolutions: Subscription;

    isBillable: boolean = false;
    deliveryNote: boolean = false

    get confirmed() {
        return this.formModifyOrder.get(this.ORDER_CONFIRMED).value;
    }
    get itemsFormArray(): FormArray {
        return this.formModifyOrder.get(this.ORDER_ITEMS) as FormArray;
    }

    constructor(
        private casesFacade: CasesFacade,
        private datePipe: DatePipe,
        public generalService: GeneralService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        public entitiesFacade: EntitiesFacade,
        private casesService: CasesService,
        private nomenclatorFacade: NomenclatorFacade,
        private attentionsFacade: AttentionsFacade,
        private provisionsFacade: ProvisionsFacade,
        private evolutionsFacade: EvolutionsFacade,
        private _bottomSheet: MatBottomSheet,
        private fileUtilitiesService: FileUtilitiesService,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.isLoadingUpdatingOrder$ = this.casesFacade.isLoadingUpdatingOrder$();
        this.isLoadingUpdatingProvision$ = this.provisionsFacade.isLoadingUpdatingProvision$();
        this.isLoadingCreatingProvision$ = this.provisionsFacade.isLoadingCreatingProvision$();
        this.isLoadingGettingAttentions$ = this.attentionsFacade.isLoadingGetAttentions$();
    }

    swalWithCustomizeButtons: any;
    _filtersOnProvisions: Subscription;
    provisionAgreementsInput: ProvisionAgreementFilters;

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        this.dataSourceSupplies = new MatTableDataSource();
        this.calculateNameProvider = this.calculateNameProvider.bind(this);
        this.subscribeToData();

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this._orders = this.casesFacade.getOrdersDisplayedOnScreen$().subscribe((orders: Order[]) => {
            if (!!orders) {
                this.orders = orders
                orders.forEach(order=>{
                    if(order.state.id == 2){
                        order.paintedType = ViewManagementEntities.SUPPLIES_NOT_DELIVERED
                    }else{
                        order.paintedType = null
                    }
                })
                this.dataSource.data = orders.sort((a, b) => {
                    let aId = a.id;
                    let bId = b.id;
                    return aId > bId ? - 1 : 1;
                });
                this.setOrderComposeWithProvisionFee();
            }
        })
    }

    ngAfterViewInit(){
        this._jumpToScreen = this.breadcrumbService.getScreenToJump$().subscribe(event => {
            if (event == ViewManagementEntities.SUPPLIES_NOT_DELIVERED) {
                                    this.setFocusOnOrderSupplyTable()
                        }})
    }

    setFocusOnOrderSupplyTable(){
        if(this.matTable){
            try{
                this.matTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.matTable.nativeElement.focus();
            }catch(err){
                console.log(err);

            }

            }
        }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['allProvisionsWithoutFilter']) {
            this.allProvisionsWithoutFilter = changes['allProvisionsWithoutFilter'].currentValue;
        }
    }

    ngOnDestroy() {
        this._jumpToScreen.unsubscribe();
        this.breadcrumbService.setScreenToJump(null)
        this._isLoadingCreatingOrder.unsubscribe()
        this._isLoadingGettingOrders.unsubscribe()
        this._isLoadingUpdatingOrder.unsubscribe()
        this._mdo.unsubscribe()
        this._orders.unsubscribe()
        this._filtersOnProvisions.unsubscribe();
        this._historyModeDate.unsubscribe();
    }

    subscribeToData() {
        this.orders = [];
        // this._allProvisionsWithoutFilter = this.provisionsFacade.getAllProvisionsCase$().subscribe(provisions => {
        //   this.allProvisionsWithoutFilter = provisions;
        // })
        this._isLoadingGettingOrders = this.casesFacade.isLoadingGettingOrders$().subscribe(loading => this.isLoadingGettingOrders = loading);
        this._isLoadingUpdatingOrder = this.casesFacade.isLoadingUpdatingOrder$().subscribe(loading => this.isLoadingUpdatingOrder = loading);
        this._isLoadingCreatingOrder = this.casesFacade.isLoadingCreatingOrder$().subscribe(loading => this.isLoadingCreatingOrder = loading);
        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.historyFromDate = rangeDate.historyFromDate;
                this.historyToDate = rangeDate.historyToDate;
            }
        })
        this._mdo = this.casesFacade.getMetaDataOrders$().subscribe((mdo) => {
            this.metaDataOrders = mdo;
        })

        this._provisionsDisplayedOnScreen = this.provisionsFacade.getProvisionsDisplayedOnScreen$().subscribe(provs => {
            this.provisionFees = provs.filter(prov => prov.isSupply).flatMap(prov => prov.provisionFees);
            this.setOrderComposeWithProvisionFee();
        })



        this.nomenclatorFacade.getPractices$().subscribe((practices) => {
            if (!!practices) {
                this.practices = practices.filter((practice) => practice.specialty?.isSupply);
            }
        });

        this._filtersOnProvisions = this.provisionsFacade.getFiltersOnProvisions$().subscribe(filters => {

            if (!!filters) {
                this.provisionAgreementsInput = filters;

                if (!!this.provisionAgreementsInput.orders && this.provisionAgreementsInput.orders.length == 0) {
                    this.selection.clear();
                } else {
                    this.provisionAgreementsInput.orders?.forEach(sel => {
                        this.selectOrder(sel, true);
                    })
                }
            }
        })
        this._attentions = this.attentionsFacade.getAttentions$().subscribe(att => {
            this.attentions = att;
        })
    }

    setOrderComposeWithProvisionFee() {
        this.orders?.forEach(or => or?.items?.forEach(it => {
            let provFeeForItem = this.provisionFees.find(provFee => provFee.id == it.provisionFeeId);
            if (provFeeForItem != null) {
                it.provisionFee = provFeeForItem;
            }
        }))
    }

    setDataSourceAttributes() {
        if (this.paginator != null && this.paginator != undefined) {
            this.paginator.pageIndex = this.metaDataOrders.page;
            this.paginator.pageSize = this.metaDataOrders.size;
            this.paginator.length = this.metaDataOrders.rows;
            this.paginator._intl.itemsPerPageLabel = 'Registros por página';
            this.paginator._intl.firstPageLabel = 'Primera página';
            this.paginator._intl.lastPageLabel = 'Última página';
            this.paginator._intl.nextPageLabel = 'Página siguiente';
            this.paginator._intl.previousPageLabel = 'Página anterior';
        }
    }

    onPaginatorEvent() {
        let ordersQPS: OrdersQPS = this.metaDataOrders;
        ordersQPS.size = this.paginator.pageSize;
        ordersQPS.page = this.paginator.pageIndex;
        ordersQPS.fromDate = this.historyFromDate;
        ordersQPS.toDate = this.historyToDate;
        this.casesFacade.loadOrders(ordersQPS, this.case.id);
    }

    matTooltipText(order: Order): string {
        let string = '';
        !!order.orderDate
            ? (string +=
                'Fecha de entrega esperada: ' +
                this.datePipe.transform(order.orderDate, 'yyyy-MM-dd HH:mm') +
                '\n')
            : null;
        !!order.deliveryDate
            ? (string +=
                'Fecha de entrega realizada: ' +
                this.datePipe.transform(order.deliveryDate, 'yyyy-MM-dd HH:mm') +
                '\n')
            : null;
        return string;
    }

    setOrderColor(orderStateId: number): any {
        switch (orderStateId) {
            case this.ORDERS_STATES.CANCELADO:
                return { color: '#D02700', background_color: '#FFE9E9' }
            case this.ORDERS_STATES.EN_CURSO:
                return { color: '#EA750B', background_color: '#FFF4EB' }
            case this.ORDERS_STATES.CREADO:
                return { color: '#609E50', background_color: '#EAFEE5' }
            case this.ORDERS_STATES.ENTREGADO:
                return { color: '#093BBB', background_color: '#D6DEF4' }
            default:
                return { color: '#FFB800', background_color: '#FFF2DF' }
        }
    }

    validateIsAbleToModifyOrder(order: Order) {
        let attentionsForItems = order.items.flatMap(it => this.attentions.filter(att => att.provisionFeeId == it.provisionFeeId));
        let attentionsUnableToEdit = attentionsForItems.filter(att => att.state.id == AttentionStates.INFORMADA || att.state.id == AttentionStates.NO_INFORMADA);
        this.arrayTable = order.items.map(it => {
            if (attentionsUnableToEdit.some(att => att.provisionFeeId == it.provisionFeeId)) {
                return {
                    supplyName: it.practice?.name,
                    quantity: it.quantityDelivered,
                    provider: `${order.provider.surname} ${order.provider.name}`
                }
            }
            return undefined
        })
        return attentionsUnableToEdit.length == 0
    }

    openModifyOrderDialog(order?: Order, edition?: boolean) {
        this.isBillable = false;
        this.providersOnSelect = null;
        this.actualOrder = order;
        this.deliveringOrder = (order.state.id == this.ORDERS_STATES.EN_CURSO || order.state.id == this.ORDERS_STATES.ENTREGADO);
        this.editingOrder = edition;
        this.formModifyOrder = this.createModifyOrderForm(order);
        this.deliveryNote = false;

        if (!!this.actualOrder?.provider) {
            this.providersOnSelect = [this.actualOrder.provider];
            this.actualOrderProvider = this.actualOrder.provider.id.toString();
        }

        let text: string;

        this.deliveringOrder && this.editingOrder && (this.actualOrder.state.id != this.ORDERS_STATES.ENTREGADO)
            ? text = 'Cargar entrega'
            : this.deliveringOrder && this.editingOrder && (this.actualOrder.state.id === this.ORDERS_STATES.ENTREGADO)
                ? text = 'Editar entrega'
                : text = 'Modificar Pedido';

        if (!this.editingOrder) {
            text = 'Ver pedido'
        }

        // text += ` - ${this.entitiesFacade.getProvidersFullName(order?.provider)}`;
        this.dataSourceSupplies.data = this.itemsFormArray['controls'];
        if ((this.editingOrder && this.validateIsAbleToModifyOrder(order)) || !this.editingOrder) {
            setTimeout(() => {
                this.openDialog(text, this.modifyOrderTemplate, { maxHeight: '95vh', minWidth: this.deliveringOrder ? '70%' : '45%' }, () => {
                    this.deliveringOrder = false;
                    this.actualOrderProvider = null;
                });
            }, 200);
        } else {
            this.message = 'Los siguientes insumos ya están informados. No se puede modificar el estado del pedido.'
            this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
        }
    }

    createModifyOrderForm(order: Order) {

        let form: FormGroup = this.formBuilder.group({
            [this.ORDER_PROVIDER]: [order.provider.id.toString(), [CustomValidators.required('Proveedor requerido')]],
            [this.ORDER_PROVIDER_SEARCHING]: [''],
            [this.ORDER_DATE]: [!!order.orderDate ? order.orderDate : '', [CustomValidators.required('Fecha de entrega requerida')]],
            [this.ORDER_DATE_DELIVERY]: [!!order.deliveryDate ? order.deliveryDate : (!!order.orderDate ? order.orderDate : '')],
            [this.ORDER_OBSERVATIONS]: [!!order.observations ? order.observations : ''],
            [this.ORDER_CONFIRMED]: [order.state.id == this.ORDERS_STATES.EN_CURSO],
            [this.ORDER_ITEMS]: this.formBuilder.array(this.createItemsFormArray(order.items)),
            [this.IS_BILLABLE]: [''],
            [this.DELIVERY_NOTE]: [''],
        })
        if (this.deliveringOrder) {
            form.get(this.ORDER_PROVIDER).disable();
            form.get(this.ORDER_DATE).disable();
            form.get(this.ORDER_CONFIRMED).disable();
            form.get(this.ORDER_DATE_DELIVERY).setValidators(CustomValidators.required('Fecha de entrega requerida'));
        }
        if (!this.editingOrder) {
            // If menu option selected is "Ver detalle", it disables all fields
            form.get(this.ORDER_OBSERVATIONS).disable();
            form.get(this.ORDER_DATE_DELIVERY).disable();
            form.get(this.ORDER_ITEMS).disable();
        }
        return form;
    }

    openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        })
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    onClickCloseDialog() {
        this._dialogRef.close();
        this.dialog.closeAll();
        this.deliveringOrder = false;
        this.actualOrderProvider = null;
    }

    createItemsFormArray(items: Item[]) {
        let itemsFormArray;
        itemsFormArray = items.map((item) => {
            if (!!item && !!item.id && !!item.practice?.id && !!item.practice.name) {
                let itemForm;
                itemForm = this.createItemForm(item);
                return itemForm;
            }
        })
        return itemsFormArray;
    }

    createItemForm(item: Item): FormGroup {
        let form = this.formBuilder.group({
            [this.ITEM_ID]: [item.id],
            [this.ITEM_QUANTITY]: [item.quantityOrdered, CustomValidators.required('Cantidad requerida')],
            [this.ITEM_PRICE]: [item.priceOrdered, CustomValidators.required('Precio requerido')],
            [this.ITEM_QUANTITY_DELIVERY]: [!!item.quantityDelivered ? item.quantityDelivered : (item.quantityOrdered ? item.quantityOrdered : '')],
            [this.ITEM_PRICE_DELIVERY]: [item.priceDelivered != null ? item.priceDelivered : (item.priceOrdered != null ? item.priceOrdered : '')],
            [this.ITEM_UNIT]: [item.unit],
            [this.ITEM_PRACTICE_DELIVERY]: [item.practice.id.toString()],
            [this.ITEM_PRACTICE_SEARCHING]: [''],
            [this.ITEM_PRACTICE]: [item.practice],

            // [this.ITEM_MARGIN]: [ {value: item.provisionFee.profitMargin, disabled: item.provisionFee.fixedPrice || this.editingOrder } ],
            [this.ITEM_MARGIN]: [{ value: item.provisionFee.profitMargin, disabled: (item.provisionFee.fixedPrice ? item.provisionFee.fixedPrice : false) || this.actualOrder?.agreement?.providerFee == 0 }],
            [this.ITEM_FEE]: [{ value: item.provisionFee.fee, disabled: item.provisionFee.fixedPrice }],
            [this.ITEM_KAIROS]: [{ value: !!item ? item.provisionFee.fixedPrice : false, disabled: false }],

            [this.ITEM_VISIBLE_CHECK_KAIROS]: [{ value: !!item ? item.provisionFee.fixedPrice : false, disabled: false }],

        })
        if (this.deliveringOrder) {
            form.get(this.ITEM_PRACTICE).disable();
            form.get(this.ITEM_QUANTITY).disable();
            form.get(this.ITEM_PRICE).disable();
            form.get(this.ITEM_PRICE_DELIVERY).setValidators([CustomValidators.minValue(0, 'Precio de entrega requerido')]);
            form.get(this.ITEM_QUANTITY_DELIVERY).setValidators([CustomValidators.minValue(0, 'Cantidad entregada requerida')]);
            form.get(this.ITEM_FEE).setValidators([CustomValidators.minValue(0, 'Requerido')]);
        }
        if (this.actualOrder.state.id == this.ORDERS_STATES.ENTREGADO) {
            form.get(this.ITEM_PRACTICE_DELIVERY).disable();
        }
        if (!this.editingOrder) {
            form.get(this.ITEM_PRICE_DELIVERY).disable();
            form.get(this.ITEM_QUANTITY_DELIVERY).disable();
            form.get(this.ITEM_PRACTICE_DELIVERY).disable();
        } else {
            if (this.actualOrder.state.id === this.ORDERS_STATES.ENTREGADO) {
                form.get(this.ITEM_MARGIN).disable();
                form.get(this.ITEM_FEE).disable();
                form.get(this.ITEM_KAIROS).disable();
            }
        }
        return form;
    }

    loadProvidersByFullName = (value: string) => this.entitiesFacade.loadProviders({ fullName_like: value, active: true, isSupply: true })

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    onClickConfirmDelivery() {
        if (this.deliveringOrder && this.isBillable) {
            console.log(this.formModifyOrder.value)
            let itemsWithCostOrFee0 = this.formModifyOrder.value.items.filter(i => i.priceDelivered == 0 || i.fee == 0);
            if (itemsWithCostOrFee0.length > 0) {
                this.message = "Los siguientes insumos tienen costo o valor venta 0, ¿Desea continuar?"
                this.showConfirmBtn = true;
                this.txtCancelBtn = 'Cancelar';
                this.txtConfirmBtn = 'Continuar';
                this.closeAll = false
                this.arrayTable = itemsWithCostOrFee0.map(i => {
                    let practiceForItem = this.actualOrder.items.find(it => it.id == i.id).practice?.name
                    return {
                        supplyName: practiceForItem,
                        quantity: i.quantityDelivered,
                        provider: `${this.actualOrder?.provider?.name} ${this.actualOrder?.provider?.surname}`
                    }
                })
                this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
            } else {
                this.onClickUpdateOrder();
            }
        } else {
            this.onClickUpdateOrder();
        }
        // this.orders.forEach(order=>{
        //     if(order.state.id == OrdersStates.ENTREGADO){
        //         order.paintedType = null
        //     }
        // })
        this.dataSource.data = this.orders
    }

    onClickUpdateOrder() {
        //TODO: próxima revisión eliminar si no hay errores
        // for (const key in this.formModifyOrder.controls) {
        //     if (Object.prototype.hasOwnProperty.call(this.formModifyOrder.controls, key)) {
        //         const control = this.formModifyOrder.controls[key];
        //         if (!control.touched && key != this.ORDER_DATE_DELIVERY) {
        //             delete this.formModifyOrder.value[key];
        //         }
        //     }
        // }
        if (this.deliveringOrder) {
            if (!this.fileChanged) {
                delete this.formModifyOrder.value.invoicePhoto
            } else {
                if (!!this.photoInvoice) {
                    this.formModifyOrder.value.invoicePhoto = this.photoInvoice;
                } else {
                    delete this.formModifyOrder.value.invoicePhoto;
                }
            }
            this.formModifyOrder.value.state = { id: this.ORDERS_STATES.ENTREGADO };
            this.formModifyOrder.value.items.map(item => {
                delete item.unit;
                delete item.practiceItemSearching;
                delete item.practiceItemDelivered;
                delete item.visibleCheckKairos;

                // Sets profit margin from rawValue because the field is disabled
                let rawValueItem = this.formModifyOrder?.getRawValue()?.items?.find(it => it.id == item.id);
                if (rawValueItem != null && rawValueItem.profitMargin != null) {
                    item.profitMargin = rawValueItem.profitMargin;
                }
                return item;
            });
            this.isBillable ? this.isBillable : delete this.formModifyOrder.value.isBillable; // If true -> create attentions with state FACTURABLE
        } else {
            if (!!this.formModifyOrder.value?.items) {
                this.formModifyOrder.value.items.map(item => {
                    item.practiceId = item.practice.id;
                    delete item.practice;
                    delete item.practiceItemDelivered;
                    delete item.practiceItemSearching;
                    delete item.priceDelivered;
                    delete item.quantityDelivered;
                    delete item.profitMargin;
                    delete item.fee;
                    delete item.fixedPrice;
                    delete item.visibleCheckKairos;
                    return item
                });
            }
            this.formModifyOrder.value.providerId = parseInt(this.formModifyOrder.value.providerId);
            this.formModifyOrder.value.state ? this.formModifyOrder.value.state = { id: this.ORDERS_STATES.EN_CURSO } : delete this.formModifyOrder.value.state;
        }
        delete this.formModifyOrder.value.deliveryNote
        let formValue: PatchOrderBody = this.formModifyOrder.value
        for (const property in formValue) {
            if (!(!!formValue[property]) && property != this.ORDER_OBSERVATIONS) {
                delete formValue[property]
            }
        }

        if (this.editingOrder) {
            if (this.actualOrder.state.id == this.ORDERS_STATES.ENTREGADO) {
                delete formValue.state
                formValue = { ...formValue, edition: true }
            }
        }

        this.casesFacade.updateOrder(formValue, this.actualOrder.id, this.case.id).subscribe((order) => {
            this.swalWithCustomizeButtons.fire({
                text: 'El pedido se modificó correctamente',
                icon: 'success'
            }).then(() => {
                this._dialogRef.close();
                this.dialog.closeAll();
                this.actualOrderProvider = null;
                this.deliveringOrder = false;
                if (this.deliveryNote) {
                    this.generateDeliveryNotePDF(this.actualOrder)
                }

                // Load all provisions for case
                this.provisionsFacade.loadAllProvisionsForCase(this.case.id) // TODO: if possible only load provisionFees
            })
                .catch((e) => {
                    this._dialogRef.close();
                    this.deliveringOrder = false;
                    this.actualOrderProvider = null;
                });
        }, (err) => {
            this.deliveringOrder = false;
            this.actualOrderProvider = null;
            this._dialogRef.close();
        });
    }

    onClickDeleteOrder(order: Order) {
        let orderToDelete: PatchOrderBody = {
            state: { id: 3 }
        };
        let text: string;
        let text2: string;
        this.swalWithCustomizeButtons.fire({
            title: 'Atención',
            text: `¿Está seguro que desea cancelar el pedido?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: () => {
                return this.casesFacade.updateOrder(orderToDelete, order.id, this.case.id).toPromise()
                    .then((_) => {
                        this.swalWithCustomizeButtons.fire({
                            title: 'Pedido cancelado correctamente',
                            icon: 'success',
                        });
                    })
                    .catch((err) => {
                        console.log('error', err);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    }

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Arrastre el archivo o haga click <u>aquí</u>',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        imagePreviewMaxHeight: 150,
        files: [this.photoInvoice],
    };

    pondHandleInit() {
        this.photoInvoice = null;
        this.fileChanged = false;
        this.casesService.getInvoicePhotoByOrderId(this.actualOrder.id, this.case.id).subscribe({
            next: media => {
                if (!!media && !!media.mediaUrl) {
                    this.photoInvoice = media.mediaUrl;
                    this.myPond.addFile(this.photoInvoice);
                } else {
                    this.photoInvoice = null;
                }
            },
            error: er => {
                console.log('Error al cargar foto o no se econtro');
                this.photoInvoice = null;
            }
        })
    }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            //Si es null quiere decir que no hay ninguna precargada, si no lo es quiere decir que ya hay foto
            if (this.photoInvoice == null) {
                const b64 = event.file.getFileEncodeBase64String();
                const extension = event.file.fileExtension;
                const mimeType = mime.getType(extension);;
                this.photoInvoice = `data:${mimeType};base64,${b64}`;
                this.fileChanged = true;
            }
        }
        else {
            this.photoInvoice = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoInvoice = null;
        this.fileChanged = true;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }
    calculateNamePractice(element: Practice) {
        return element ? element.name : null;
    }

    show(ev) {
        console.log(ev);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.filter(selected => this.dataSource.data.some(prov => prov.id == selected.id)).length;
        const totalProvisions = this.dataSource.data.length;
        return numSelected === totalProvisions;
    }

    masterToggle() {

        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            if (!!this.dataSource.data && this.dataSource.data.length > 0) {
                this.dataSource.data.forEach(row => this.selectOrder(row, true));
            }
        }
        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    selectOrder(row: Order, masterToggle: boolean = false) {
        if (!masterToggle || (masterToggle && !this.selection.selected.map(sel => sel.id).includes(row.id))) {
            if (this.selection.selected.map(sel => sel.id).includes(row.id)) {
                this.selection.deselect(this.selection.selected.find(sel => sel.id == row.id));
            } else {
                if (!this.selection.selected.map(sel => sel.id).includes(row.id)) {
                    this.selection.select(row);
                }
            }
        }
        if (!masterToggle) {
            this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
        }
    }

    getItemsCount(order: Order) {
        let count: number = order.items.length;
        let phrase: string = count.toString();
        if (count == 1) {
            phrase += " insumo";
        } else {
            phrase += " insumos";
        }
        return phrase;
    }

    updateFilter() {
        if (!!this.provisionAgreementsInput) {
            delete this.provisionAgreementsInput.orders;
        }
        return { ...this.provisionAgreementsInput, orders: [...this.selection.selected] }
    }

    dateDiff(day: Date): number {
        if (!!day) return moment(day).diff(this.toDay, 'days');
        else return -1; // Vencido / hay un error en la fecha
    }


    getColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_COLORS[state]
    }

    getBackgroundColorAuthorizationState(provisionFee: ProvisionFee): string {
        if (!!provisionFee) {
            let state = this.generalService.getAuthorizationState(provisionFee);
            return this.AUTHORIZATION_BACKGROUND_COLORS[state];
        }
        return '';
    }

    onClickAuthorization(provisionFee: ProvisionFee) {
        if (this.generalService.getAuthorizationState(provisionFee) !== "NO AUTORIZADO") {
            this.showAuthEvolution(provisionFee.authorization);
        }
    }
    showAuthEvolution(auth) {
        // Find evolution
        this.evolutionsFacade.loadEvolutionsById(auth.evolutionId).subscribe(evol => {
            this._bottomSheet.open(EvolutionsDialogComponent, {
                panelClass: 'bottomSheetPanelClass',
                data: {
                    evolutions: [evol],
                    attentions: [],
                    name: this.case.patient.name,
                    surname: this.case.patient.surname,
                    expanded: true
                },
            });
        })
    }

    onChangeCheckIsBillable(isChecked: boolean) {
        this.isBillable = isChecked;
    }
    onClickCancel() {
        this._dialogRef.close();
        if (this.closeAll) {
            this.dialog.closeAll();
        }
    }

    revertState(order: Order) {

        this.openMessageDialogComponent(`¿Está seguro que desea revertir el estado del pedido ${order.id}?`);
        this._dialogRef.componentInstance.ok.subscribe(result => {
            if (result) {
                // Creates popUp with text area
                if (this.validateIsAbleToModifyOrder(order)) {
                    this.swalWithCustomizeButtons.fire({
                        icon: 'warning',
                        title: 'Advertencia de flujo anormal',
                        text: 'Por favor, escriba un motivo',
                        input: 'textarea',
                        inputPlaceholder: 'Escriba un motivo... ',
                        inputValidator: (value) => {
                            if (value.length < 10) {
                                return 'El motivo debe tener más de 10 caracteres.';
                            } else {
                                return ''
                            }
                        },
                        confirmButtonText: 'CONFIRMAR',
                        cancelButtonText: 'CANCELAR',
                        showLoaderOnConfirm: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: () => !Swal.isLoading(),
                    }).then(result => {
                        if (result.isConfirmed) {
                            // If the confirm button is pressed, creates the patch body and calls the endpoint
                            let patchOrderBody: PatchOrderBody = {
                                stateReversed: true,
                                reasonForRevertingOrderStatus: result.value
                            }

                            this.casesFacade.updateOrder(patchOrderBody, order.id, this.case.id).subscribe({
                                next: order => {
                                    this.swalWithCustomizeButtons.fire({
                                        text: 'El pedido se modificó correctamente',
                                        icon: 'success'
                                    }).then(() => {
                                        this._dialogRef.close();
                                    })
                                        .catch((e) => {
                                            this._dialogRef.close();
                                        });
                                },
                                error: err => {
                                    this._dialogRef.close();
                                }
                            })
                        } else {
                            this._dialogRef.close();
                        }
                    });
                } else {
                    this.message = 'Los siguientes insumos ya están informados. No se puede modificar el estado del pedido.'
                    this.showConfirmBtn = false;
                    this.closeAll = false;
                    this.txtCancelBtn = 'OK'
                    this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
                }

            }
        })
    }

    openMessageDialogComponent(message) {
        this._dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            maxHeight: '20%',
            maxWidth: '50vh',
            data: {
                message: message,
                icon: 'WarningCircle',
                disableConfirm: true,
            }
        });
    }

    showDeliveryNote(order: Order) {
        let attentionForOrder: Attention = this.attentions.find(att => att?.agreementId == order?.agreement?.id)
        if (!!attentionForOrder) {
            this.getEvolutionsByAttention(attentionForOrder.id)
                .then((evolutions) => {
                    let deliveryNotes: Evolution[] = evolutions.filter(ev => ev.hasMedia);
                    let deliveryNote: Evolution = deliveryNotes[deliveryNotes.length - 1];
                    attentionForOrder.evolutions = [deliveryNote];
                    this.openBottomSheet([deliveryNote], [attentionForOrder]);
                })
        }
    }

    getEvolutionsByAttention(attentionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            let qps: EvolutionsQPS = { attentionId };
            this.evolutions$ = this.evolutionsFacade.loadEvolutionsByQPS(qps, false)
            this._evolutions = this.evolutions$.subscribe({
                next: evolutions => {
                    resolve(evolutions);
                },
                error: err => {
                    reject(err);
                },
            });
        });
    }

    openBottomSheet(evolutions, attentions) {

        this._bottomSheet.open(EvolutionsDialogComponent, {
            panelClass: 'bottomSheetPanelClass',
            data: {
                evolutions,
                name: attentions[0].patient.name,
                surname: attentions[0].patient.surname,
                attentions: attentions
            },
        });
    }

    onChangeCheckdeliveryNote(isChecked: boolean) {
        this.deliveryNote = isChecked;
        console.log(this.deliveryNote)
    }

    /**
     *
     * @returns If true then exists some item with fixedPrice (Kairos)
     */
    existsCheckItemsWithKairos(): boolean {
        let control: boolean = false;
        this.itemsFormArray.controls.forEach((element, index) => {
            if (element.get('visibleCheckKairos').value && element.get('fixedPrice').value)
                control = true;
        })
        return control;
    }

    onChangeCheckKairos(i: number, isSelected: boolean) {
        if (isSelected) {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).reset();
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).disable();
            this.itemsFormArray.at(i).get(this.ITEM_FEE).reset();
            this.itemsFormArray.at(i).get(this.ITEM_FEE).disable();
        } else {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).enable();
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).setValidators(CustomValidators.required('Requerido'));
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).updateValueAndValidity();
            this.itemsFormArray.at(i).get(this.ITEM_FEE).enable();
            this.itemsFormArray.at(i).get(this.ITEM_FEE).setValidators(CustomValidators.required('Requerido'));
            this.itemsFormArray.at(i).get(this.ITEM_FEE).updateValueAndValidity();
        }
    }

    calculateTotalValue(i: number) {
        let margin = this.itemsFormArray.at(i).get(this.ITEM_MARGIN).value;
        let cost = this.itemsFormArray.at(i).get(this.ITEM_PRICE_DELIVERY).value;
        if (cost != null && cost != 0) {
            this.itemsFormArray.at(i).get(this.ITEM_FEE).setValue(cost + (cost * margin / 100));
            this.itemsFormArray.at(i).get(this.ITEM_FEE).markAsPristine();
        } else {
            this.itemsFormArray.at(i).get(this.ITEM_FEE).reset();
            this.itemsFormArray.at(i).get(this.ITEM_FEE).disable();
        }
    }
    calculateMargin(i: number) {
        let cost = this.itemsFormArray.at(i).get(this.ITEM_PRICE_DELIVERY).value;
        let fee = this.itemsFormArray.at(i).get(this.ITEM_FEE).value;
        if (cost != 0 && cost != null) {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).setValue((((fee - cost) / cost) * 100).toFixed(2));
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).markAsPristine();
        } else {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).reset();
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).disable();
        }
    }

    onChangeCost(i) {
        let fee = this.itemsFormArray.at(i).get(this.ITEM_FEE).value;
        let margin = this.itemsFormArray.at(i).get(this.ITEM_MARGIN).value;
        let cost = this.itemsFormArray.at(i).get(this.ITEM_PRICE_DELIVERY).value;
        if (cost == 0) {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).reset();
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).disable();
        } else {
            this.itemsFormArray.at(i).get(this.ITEM_MARGIN).enable();
        }
        if ((margin != 0 && margin != null && (fee == 0 || fee == null)) || this.itemsFormArray.at(i).get(this.ITEM_MARGIN).dirty) {
            // If cost is changed and there isn't fee or profit margin is the last touched field, it calculates fee
            this.calculateTotalValue(i)
        } else if ((fee != 0 && fee != null && (margin == 0 || margin == null)) || this.itemsFormArray.at(i).get(this.ITEM_FEE).dirty) {
            // If there isn't margin or fee is the last touched field, it calculates margin
            this.calculateMargin(i)
        } else if ((this.itemsFormArray.at(i).get(this.ITEM_FEE).pristine && this.itemsFormArray.at(i).get(this.ITEM_MARGIN).pristine) && (fee != 0 && fee != null)) {
            // If there is margin and fee and both of them are untouched, it calculates margin
            this.calculateMargin(i)
        }
    }

    generateDeliveryNotePDF(order: Order) {
        let provisionFees = order.items.map(i => i.provisionFee).map(provisionFee => {
            let provFee: ProvisionFee = { ...provisionFee }
            delete provFee.orders
            delete provFee.agreement
            delete provFee.invoiced
            delete provFee.debited
            delete provFee.delivered
            delete provFee.total
            delete provFee.totalAmount
            delete provFee.totalDebited
            delete provFee.totalDelivered
            delete provFee.totalInvoiced
            delete provFee.totalRealized
            delete provFee.totalValue
            delete provFee.userId
            delete provFee.provider
            delete provFee.authorization
            return { ...provFee }
        });
        let orderDeliveryNotePDFBody: DeliveryNotePDFBody = {
            provisionFees: provisionFees,
            patientId: this.case.patient.id,
        }
        this.casesFacade.generateOrderDeliveryNotePDF(orderDeliveryNotePDFBody).subscribe(res => {
            this.fileUtilitiesService.downloadFile(res)
        })
    }

    getItemsFormArray(index: number): FormGroup {
        return this.itemsFormArray.at(index) as FormGroup;
    }
}
