import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarComponent } from './avatar.component';

@NgModule({
  declarations: [AvatarComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports: [AvatarComponent]
})
export class AvatarModule { }
