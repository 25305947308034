<mat-form-field class="custom-textarea"
    style="display: flex; flex-direction: column; box-sizing: border-box !important;" [formGroup]="form"
    appearance="outline">
    <mat-label *ngIf="this.label">
        <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
        <b>{{ label }}</b>
    </mat-label>
    <mat-hint>
        {{ this.hint }}
    </mat-hint>
    <textarea style=" flex-grow: 1;  width: 100%; box-sizing: border-box !important; height: 105px !important;" matInput
        [formControlName]="name" id="{{ name }}" name="{{ name }}" type="text" placeholder="{{ placeholder }}"
        [readonly]="readonly" [attr.disabled]="disabled ? '' : null" maxlength="{{ maxLength }}">
    </textarea>
    <button tabIndex="-1" [disabled]="!this.form.get(this.name)?.value || this.form.get(this.name)?.disabled"
        type="button" mat-button *ngIf="this.clearable" matSuffix mat-icon-button aria-label="Clear"
        (click)="this.form.get(this.name)?.reset()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="
          this.form.get(this.name)?.errors &&
          (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)
       ">
        <b>
            {{ getError() }}
        </b>
    </mat-error>

</mat-form-field>
