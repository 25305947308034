<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg"></mat-icon>
    <mat-form-field class="flex-100" appearance="outline">
        <mat-label>
            {{this.label}}
        </mat-label>
        <input [(ngModel)]="inputFilterValue" matInput [placeholder]="this.placeholder" #inputSearch />
    </mat-form-field>
</div>
