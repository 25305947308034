<div class="flex-column">
    <ng-container *ngIf="!this.isLoading; else loading">
        <ng-container *ngIf="!this.error; else errorDiv">
            <router-outlet></router-outlet>
        </ng-container>
    </ng-container>

    <ng-template #loading>
        <div class="loading-container center-content">
            <div class="center-content">
                <div class="center-content">
                    <img class="logo-large" src="assets/logos/tervey/logotext.png">
                    <div class="row-gap-10 center-content">
                        <span class="text-secondary">Powered by</span>
                        <img class="logo-medium" src="assets/logos/simfinix/text.png">
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #errorDiv>
        <div class="loading-container align-space-between-stretch">
            <div class="center-content">
                <img class="logo-large" src="assets/logos/tervey/logotext.png">
                <div class="row-gap-10 center-content mb-5">
                    <span class="text-secondary">Powered by</span>
                    <img class="logo-medium" src="assets/logos/simfinix/text.png">
                </div>
                <div class="row-gap-10 center-content mt-5">
                    <span class="text-secondary-lighter">
                        Espacio de trabajo inválido
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
</div>
