
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';
import { MaterialModule } from 'src/app/material/material.module';

// Components

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MaterialModule
  ],
  exports: [DialogComponent],
  declarations: [DialogComponent],
})
export class DialogModule { }
