import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { Evolution } from '../../../../shared/models/evolution';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { Case } from '../../../../shared/models/case';
import { User } from '../../../../shared/models/user';
import { AuthenticationService } from '../../../../core/authentication.service';
import { PostEvolutionBody } from '../../../../core/services/evolutions.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';


@Component({
    selector: 'app-evolutions-side-bar',
    templateUrl: './evolutions-side-bar.component.html',
    styleUrls: ['./evolutions-side-bar.component.scss']
})
export class EvolutionsSideBarComponent implements OnInit {

    addNote: FormGroup;
    public readonly NOTE = 'note';

    @ViewChild('scrollMe') private myScrollContainer: ElementRef;

    _case: Subscription; case: Case;
    _evolutionsTypeNote: Subscription;
    public dataEvolutions: Evolution[];
    _user: Subscription; user: User;
    inputNote: string;

    _isLoadingGettingEvolutions: Subscription;
    _isLoadingCreatingEvolution: Subscription;
    _isLoadingUpdatingEvolution: Subscription;
    isLoadingGettingEvolutions: boolean;
    isLoadingCreatingEvolution: boolean;
    isLoadingUpdatingEvolution: boolean;

    constructor(private evolutionsFacade: EvolutionsFacade,
        private casesFacade: CasesFacade,
        private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
        private formBuilder: FormBuilder) { }


    ngOnInit(): void {

        this._isLoadingGettingEvolutions = this.evolutionsFacade.isLoadingGetEvolutions$().subscribe(loading => {
            this.isLoadingGettingEvolutions = loading;
        });

        this._isLoadingCreatingEvolution = this.evolutionsFacade.isLoadingCreatingEvolution$().subscribe(loading => {
            this.isLoadingCreatingEvolution = loading;
        });

        this._isLoadingUpdatingEvolution = this.evolutionsFacade.isLoadingUpdatingEvolution$().subscribe(loading => this.isLoadingUpdatingEvolution = loading);

        // User
        this._user = this.authenticationService.getUser$().subscribe(u => this.user = u)

        // Get case
        this._case = this.casesFacade.getCase$().subscribe(res => {
            this.case = res;

            console.log("Case seleccionado ", this.case);


            if (!!this.case) {
                // Evolutions from state
                this._evolutionsTypeNote = this.evolutionsFacade.getEvolutionsTypeNote$()
                    .subscribe((evo: Evolution[]) => {
                        console.log("CONTROL 222: ", evo)
                        this.dataEvolutions = evo;
                        this.sortEvolutionsByDate();
                        this.cd.markForCheck();
                    })
            }
        });
        this.scrollToBottom();

        this.addNote = this.formBuilder.group({
            [this.NOTE]: ['', [CustomValidators.required('Ingrese un texto')]]
        })
    }

    sortEvolutionsByDate() {
        this.dataEvolutions?.sort((a, b) => {
            if (a && a.date) {
                const aFromDate = moment(a.date);
                const bFromDate = moment(b.date);
                return aFromDate.isSame(bFromDate) ? 0 : (aFromDate.isBefore(bFromDate) ? 1 : -1);
            }
            return -1;
        }).reverse();
    }

    onclickEyeProvider(evo: Evolution) {

        evo.visibleProvider = !evo.visibleProvider;
        this.evolutionsFacade.patchEvolution(evo).toPromise()
            .then((_) => {
                // Nothing
            })
            .catch((err) => {
                console.log('error', err);
            });
    }


    onclickEyeFinancier(evo: Evolution) {

        evo.visibleFinancier = !evo.visibleFinancier;
        this.evolutionsFacade.patchEvolution(evo).toPromise()
            .then((_) => {
                // Nothing
            })
            .catch((err) => {
                console.log('error', err);
            });
    }


    close() {
        this.evolutionsFacade.setEvolutionsSideBarShow(false);
    }


    addEvolution() {

        const newEvolution: PostEvolutionBody = {
            date: new Date(),
            caseId: this.case.id,
            type: 20,
            text: this.addNote.get(this.NOTE).value,
            mediaUrls: []
        }

        this.evolutionsFacade.postEvolution(newEvolution).subscribe((evo) => {
            // Nothing to do
            this.addNote.reset();
            this.scrollToBottom();
        });
    }

    ngAfterContentInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) { }
    }

    ngOnDestroy(): void {

        console.log("DESTROY evolutions-side-bar-components");

        if (!!this._case) {
            this._case.unsubscribe();
        }

        if (!!this._evolutionsTypeNote) {
            this._evolutionsTypeNote.unsubscribe();
        }

        if (!!this._user) {
            this._user.unsubscribe();
        }
    }
}
