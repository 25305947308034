import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { RestUtilitiesService } from './rest-utilities.service';
import { Requisition, RequisitionReason, RequisitionIndicator, RequisitionState } from '../../shared/models/requisition';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { APIS } from '../enums/apis';
import { User } from '../../shared/models/user';
import { PostCaseBody } from './cases.service';
import { PostEvolutionBody } from './evolutions.service';
import { Pagination } from '../../shared/models/pagination';


export interface RequisitionsQPS extends Pagination {
  searchDocument?: string;
  searchCif?: string;
  searchPatient?: string;
	requisitionsStateId?: number[],
	operatorId?: number,
	user?: User,
	order?: string,
	sort?: string,
	requisitionsTypeId?: number[],
	requisitionReasons?: number[],
}

export interface PostRequisitionBody {
    casee?: PostCaseBody,
    type?: string,
    evolution?: PostEvolutionBody,
    operatorId?: number,
    caseId?: number,
	requisitionTypeId ?: number,
	requisitionStateId ?: number
 }

 export interface PatchRequisitionBody {
	id: number,
	requisitionState: RequisitionState,
	requisitionReason?: RequisitionReason,
	observation?: string
 }

export interface RequisitionsIndicatorsQPS {
	fromDate: Date,
	operatorId?: number
}


@Injectable({
  providedIn: 'root'
})
export class RequisitionService {

  constructor(
    private http: HttpClient, 
    private restUtilitiesService: RestUtilitiesService
  ) { }


	getRequisitions(qps?: RequisitionsQPS): Observable<HttpBaseResponse<Requisition[]>> {

		console.log("getRequisitions");

		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.REQUISITIONS}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body;
				}),
			);
	}

	postRequisition(requisition: PostRequisitionBody): Observable<Requisition> {
	const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
	return this.http
		.post<any>(`${environment.apiCaseService}${APIS.REQUISITIONS}`, JSON.stringify(requisition), {
		headers: queryHeaders,
		observe: 'response',
		})
		.pipe<any>(
		map<HttpResponse<any>, any>((response) => {
				return response.body;
		}),
		);
	}

	patchRequisition(requisition: PatchRequisitionBody ): Observable<Requisition> {
		const { id, ...restWithoutId } = requisition;
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.REQUISITIONS}/${id}`,
				JSON.stringify( restWithoutId ),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body;
				}),
			);
	}

	getRequisitionsIndicators( qps: RequisitionsIndicatorsQPS ): Observable<HttpBaseResponse<RequisitionIndicator[]>> {

		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;

		return this.http
			.get<any>(`${environment.apiCaseService}${ APIS.REQUISITIONS_INDICATORS }`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body;
				}),
			);
	}

	getColorRequisitionState( stateId: number ): string {
		switch (stateId) {
			case 1:
				return 'requisition-state-borrador'
				break;
			case 2:
				return 'requisition-state-sin-aviso'
				break;
			case 3:
				return 'requisition-state-esperando-info'
				break;
			case 4:
				return 'requisition-state-buscando-rec'
				break;
			case 5:
				return 'requisition-state-confirmado'
				break;
			case 6:
				return 'requisition-state-presupuestado'
				break;
			case 7:
				return 'requisition-state-pendiente'
				break;
			case 8:
				return 'requisition-state-archivado'
				break;
			default:
				return ''
				break;
		}
	}

	getColorRequisitionType( stateId: number ): string {
		switch (stateId) {
			case 1:
				return 'requisition-type-borrador'
			case 2:
				return 'requisition-type-sin-aviso'
			case 3:
				return 'requisition-type-esperando-info'
			case 4:
				return 'requisition-type-buscando-rec'
			case 5:
				return 'requisition-type-confirmado'
			default:
				return '';
		}
	}

	getColorRequisitionReason( reasonId: number ): string {
		switch (reasonId) {
			case 1:
				return 'requisition-state-borrador'
				break;
			case 2:
				return 'requisition-state-sin-aviso'
				break;
			case 3:
				return 'requisition-state-esperando-info'
				break;
			case 4:
				return 'requisition-state-buscando-rec'
				break;
			case 5:
				return 'requisition-state-confirmado'
				break;
			case 6:
				return 'requisition-state-presupuestado'
				break;
			case 7:
				return 'requisition-state-pendiente'
				break;
			case 8:
				return 'requisition-state-archivado'
				break;
			default:
				return ''
				break;
		}	
	}
}
