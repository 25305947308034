<div class="flex-column">
    <span class="headline-1 pls-4 mbs-5 border-left text-primary-darker-title">
        {{ 'Convenio' | uppercase }}
    </span>
    <div>
        <mat-card>
            <form [formGroup]="arrangementForm">
                <div class="flex-column flex-30 p-1">
                    <span class="subtitle-3 text-primary-darker mbs-1">
                        Convenio
                    </span>
                    <app-select-searching-input (selected)="this.onSelectArrangement($event)"
                    [name]="this.ARRANGEMENT" [nameSearching]="this.ARRANGEMENT_SEARCHING"
                    [form]="this.arrangementForm" [placeholder]="'Seleccionar convenio'"
                    [elements]="this.activeArrangements" [calculateId]="this.calculateIdArrangement"
                    [calculateName]="this.calculateNameArrangement"
                    [placeholderNoValue]="'Sin resultados'"
                    [placeholderSearching]="'Buscar convenio...'"
                    [noOptionText]="'Sin convenio'">
                </app-select-searching-input>
                </div>
            </form>
        </mat-card>
    </div>

    <span class="headline-1 pls-4 mbs-5 border-left text-primary-darker-title mt-5">
        {{ 'Pedido de prestaciones' | uppercase }}
    </span>
    <div>
        <mat-card>
            <form [formGroup]="practicesForm">
                <div formArrayName="practiceS">
                    <div *ngFor="let practice of practiceS.controls; let i=index">
                        <div [formGroupName]="i" class="flex-column">
                            <div class="flex-row flex-100 gap-1-5em align-stretch-center p-1">
                                <!-- Practice -->
                                <div class="flex-column flex-22">
                                    <span class="subtitle-3 text-primary-darker mbs-1">
                                        Práctica *
                                    </span>
                                    <app-select-searching-input (selected)="this.onSelectPractice($event)"
                                        [name]="this.PRACTICE" [nameSearching]="this.PRACTICE_SEARCHING"
                                        [form]="getPracticeSFormGroup(i)" [placeholder]="'Seleccione la práctica'"
                                        [elements]="this.practicesFiltered" [calculateId]="this.calculateIdPractice"
                                        [calculateName]="this.calculateNamePractice"
                                        [placeholderNoValue]="'Sin resultados'"
                                        [placeholderSearching]="'Buscar practica...'">
                                    </app-select-searching-input>
                                </div>

                                <!-- Frecuency DAT -->
                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-1 flex-100">
                                        Frecuencia
                                    </span>
                                    <div class="flex-row space-between-center flex-100">
                                        <app-number-input class="flex-25"
                                            [name]="this.AMOUNT" [minValue]="0"
                                            [form]="getPracticeSFormGroup(i)" [placeholder]="''">
                                        </app-number-input>

                                        <span style="padding-bottom: 25px;">por</span>

                                        <!-- Frecuency PERIOD -->

                                        <app-select-input [noOptionText]="'Sin especificar'" [name]="this.UNIT"
                                            [elements]="this.provisionsFrequencyUnit" [form]="getPracticeSFormGroup(i)"
                                            [defaultValue]="'Sin especificar'">
                                        </app-select-input>
                                    </div>
                                </div>

                                <!-- Radiobutton -->
                                <div class="flex-column flex-25 mbs-3">
                                    <mat-radio-group formControlName="radioButton"
                                        (change)="clickRadiobutton(i, $event)" name="radioButton"
                                        class="flex-row flex-100 space-between-center"
                                        style="height: fit-content; margin-bottom: -8px;">
                                        <mat-radio-button [value]="'duration'" class="flex-50">
                                            <span class="subtitle-3 text-primary-darker mbs-1">
                                                Duración
                                            </span>
                                        </mat-radio-button>
                                        <mat-radio-button class="subtitle-3 text-primary-darker flex-50"
                                            [value]="'date'">
                                            <span class="subtitle-3 text-primary-darker mbs-1">
                                                Fecha
                                            </span>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <!-- Duración -->
                                    <ng-container
                                        *ngIf="this.practiceS['controls'][i]['controls'].radioButton.value === 'duration'">
                                        <div class="flex-row flex-100 flex-gap-small">
                                            <app-number-input [name]="this.DURATION_AMOUNT"
                                                [form]="getPracticeSFormGroup(i)" class="flex-50">
                                            </app-number-input>

                                            <app-select-input [icon]="'trending_up'" [noOptionText]="'Sin especificar'"
                                                [name]="this.DURATION_UNIT" [label]="''"
                                                [elements]="this.provisionsFrequencyUnit"
                                                [form]="getPracticeSFormGroup(i)" [defaultValue]="" class="flex-50">
                                            </app-select-input>
                                        </div>
                                    </ng-container>
                                    <!-- Fecha -->
                                    <ng-container
                                        *ngIf="this.practiceS['controls'][i]['controls'].radioButton.value === 'date'">
                                        <div class="flex-row flex-gap-small">
                                            <app-date-picker [name]="this.FROM_DATE" [form]="getPracticeSFormGroup(i)"
                                                [placeholder]="'Desde'" class="flex-50">
                                            </app-date-picker>
                                            <app-date-picker [name]="this.TO_DATE" [form]="getPracticeSFormGroup(i)"
                                                [placeholder]="'Hasta'" class="flex-50">
                                            </app-date-picker>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Cant -->
                                <div class="flex-column flex-7">
                                    <span class="subtitle-3 text-primary-darker">
                                        Cantidad
                                    </span>
                                    <app-number-input [name]="this.TOTAL_AMOUNT" [form]="getPracticeSFormGroup(i)"
                                        [minValue]="0" class="mt-4">
                                    </app-number-input>
                                </div>

                                <!-- ACTIONS -->
                                <div class="flex-row flex-7 start-none mbs-1 space-between-center flex-gap-medium ml-0">
                                    <!-- Remove practice -->
                                    <div class="flex-column flex-40">
                                        <button id="" matTooltip="Borrar" mat-icon-button [color]="'primary'"
                                            type="button" (click)="this.removePractice(i)">
                                            <mat-icon class="text-primary" outlined>delete_outline</mat-icon>
                                        </button>
                                    </div>
                                    <!-- Add practice -->
                                    <div class="flex-column flex-40">
                                        <button id="" matTooltip="Agregar" mat-icon-button [color]="'primary'"
                                            type="button" *ngIf="true" (click)="this.addPractice()"
                                            [disabled]="btn_addPractice_disabled"
                                            [hidden]="(i+1)==practiceS['controls'].length ? false : true">
                                            <mat-icon>add_circle_outline</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>
