import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdmissionsRoutingModule } from './admissions-routing.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AdmissionsListComponent } from './components/admissions-list/admissions-list.component';
import { CardTableModule } from 'src/app/shared/components/card-table/card-table.module';
import { MaterialModule } from '../../material/material.module';
import { UtilsModule } from '../../shared/components/utils/utils.module';
import { FormsModule } from '../../shared/components/forms/forms.module';
import { AdmissionCreationComponent } from './pages/admission-creation/admission-creation.component';
import { PatientDataComponent } from './components/patient-data/patient-data.component';
import { AdmissionDashboardComponent } from './pages/dashboard/admission-dashboard.component';
import { AdmissionsSuppliesComponent } from './components/admissions-supplies/admissions-supplies.component';
import { AdmissionsProvisionsComponent } from './components/admissions-provisions/admissions-provisions.component';
import { CaseDataComponent } from './components/case-data/case-data.component';
import { CaseManagementDataComponent } from './pages/case-management-data/case-management-data.component';
import { OperatorSelectComponent } from './components/operator-select/operator-select.component';
import {OverlayModule} from '@angular/cdk/overlay';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddressInputComponent } from 'src/app/shared/components/forms/address-input/address-input.component';

@NgModule({
  declarations: [
    AdmissionDashboardComponent,
    AdmissionsListComponent,
    AdmissionCreationComponent,
    PatientDataComponent,
    AdmissionsSuppliesComponent,
    AdmissionsProvisionsComponent,
    CaseDataComponent,
    CaseManagementDataComponent,
    OperatorSelectComponent,
  ],
  imports: [
    CommonModule,
    AdmissionsRoutingModule,
    CardTableModule,
    MaterialModule,
    UtilsModule,
    FormsModule,
    OverlayModule,
    NgxMatSelectSearchModule,
    PipesModule,
    AddressInputComponent
  ],
  exports: [
    AdmissionDashboardComponent,
    AdmissionsListComponent,
    PatientDataComponent,
    CaseDataComponent
  ]
})

export class AdmissionsModule { }
