<div class="flex-column flex-100">
    <mat-card *ngIf="!!this.case" class="flex-100 p-1-5">
        <span class="subtitle-card-1">Datos del paciente</span>
        <div class="flex-row p-1 flex-gap-xlarge">
            <!-- Patient name & DNI -->
            <!-- Icon -->
            <div class="flex-70 flex-row align-start-center my-2 gap-2em mx-3 my-4">
                <mat-icon [ngClass]="{'text-primary-darker': this.case.active,'text-secondary': !this.case.active}"
                    class="flex-row mat-icon-extraLarge text-secondary" [svgIcon]="'user'">
                </mat-icon>
                <!-- Patient -->
                <div class="flex-column flex-1 gap-0.5em">
                    <span id="patientFullname" class="mat-h2 mb-0 subtitle-card-2">
                        {{ this.generalService.getFullName( this.case.patient.name, this.case.patient.surname )}}
                    </span>
                    <span id="patientDocument" class="text-secondary">
                        {{ this.case.patient.typeDocument ? this.case.patient.typeDocument : 'DNI '}}
                        {{ this.case.patient.documentNumber != '0' ? this.case.patient.documentNumber : 'Sin datos'
                        }}
                    </span>
                </div>
            </div>


            <!-- Buttons email activation and edit patient -->
            <div class="flex-row gap-2em flex-30">
                <!-- Edit patient -->
                <button matTooltip="Modificar datos del paciente" (click)="onClickEditPatient()" mat-icon-button
                    [disabled]="!this.case.active">
                    <mat-icon
                        [svgIcon]="this.case.active && !(this.isLoadingGettingPatient$ | async) ? 'PencilSimple-enabled-primary-lighter' : 'PencilSimple-disabled'"></mat-icon>
                </button>
                <!-- Email activation -->
                <div class="flex-column">
                    <!-- Entidad sin usuario  -->
                    <button *ngIf="!this.patient?.user; else activeUser"
                        matTooltip="Usuario sin cuenta. Presione para crearle una nueva cuenta"
                        (click)="onClickCreateUser( this.patient )" mat-icon-button>
                        <mat-icon class="text-primary">person_add</mat-icon>
                    </button>

                    <!-- Usuario activo -->
                    <ng-template #activeUser>
                        <button *ngIf="this.patient.user?.active; else requestedUser"
                            matTooltip="Usuario activo. Presione para desactivarlo"
                            (click)="onClickDeactivateUser(this.patient)" mat-icon-button>
                            <mat-icon class="text-primary">verified_user</mat-icon>
                        </button>
                    </ng-template>

                    <!-- Usuario con solicitud de activación aún no creado -> Reenviar mail -->
                    <ng-template #requestedUser>
                        <button
                            *ngIf="!!this.patient.user?.lastRequestedTimeStamp && !this.patient.user?.createdTimeStamp; else userDisabled"
                            matTooltip="Mail de activación enviado. Presione para reenviar el mail"
                            (click)="onClickResendEmailUser(this.patient)" mat-icon-button>
                            <mat-icon class="text-primary">send</mat-icon>
                        </button>
                    </ng-template>

                    <!-- Usuario deshabilitado -->
                    <ng-template #userDisabled>
                        <button matTooltip="Usuario deshabilitado. Presione para activar su cuenta"
                            (click)="onClickActivateUser(this.patient)" mat-icon-button>
                            <mat-icon class="text-primary">person_off</mat-icon>
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>

        <!-- Patient data -->
        <mat-card-content class="flex-row space-between-none text-secondary-darker">

            <div class="flex-column gap-1em pls-1">
                <!-- Birth Date -->
                <div class="flex-row align-start-center">
                    <mat-icon class="mat-icon-medium mrs-1">cake</mat-icon>
                    <span id="patientBirthDate">{{ this.case.patient.birthDate ? ( this.case.patient.birthDate |
                        date:'longDate':'es_AR' ) : 'Sin datos' }}</span>
                </div>

                <!-- Allergies -->
                <div class="flex-row align-start-center" *ngIf="this.case.allergies">
                    <mat-icon class="mat-icon-medium mrs-1">filter_vintage</mat-icon>
                    <span id="patientAllergies">{{this.case.allergies}}</span>
                </div>

                <!-- Email -->
                <div class="flex-row align-start-center">
                    <mat-icon class="mat-icon-medium mrs-1">email</mat-icon>
                    <span id="patientEmail">{{ this.case.patient.email ? this.case.patient.email : 'Sin datos' }}</span>
                </div>

                <a [href]="this.getLinkMap()" style="text-decoration: none;" class="text-primary" target="blank">
                    <mat-icon class="mat-icon-medium mrs-1" matTooltip="Buscar en el mapa">where_to_vote</mat-icon>
                    <span matTooltip="Buscar en el mapa" id="patientAddress">
                        {{ !!this.case.patient.address.street ? this.case.patient.address.street : 'Sin domicilio' }} {{
                        this.case.patient.address.streetNumber ? this.case.patient.address.streetNumber : '' }}
                        {{ this.case.patient.address?.floor ? this.case.patient.address.floor : ''}}
                        {{ this.case.patient.address?.flat ? this.case.patient.address.flat : ''}},
                        {{ this.case?.patient?.address?.location }},
                        {{ this.case?.patient?.address?.province }}
                    </span>
                </a>
                <!-- Phone -->
                <div class="flex-row align-start-center">
                    <mat-icon class="mat-icon-medium mrs-1">phone_iphone</mat-icon>
                    <span id="patientPhoneNumber">{{ this.case.patient.phoneNumber ? this.case.patient.phoneNumber :
                        'Sin datos'
                        }}</span>
                </div>
                <!-- Health Insurance -->
                <div class="align-start-center">
                    <mat-icon class="mat-icon-medium mrs-1">health_and_safety</mat-icon>
                    <span id="healthInsurance">
                        {{ this.case.patient.healthInsurance ? this.case.patient.healthInsurance.name : 'Sin datos' }} -
                        {{ this.case.patient.affiliationNumber ? '# ' + this.case.patient.affiliationNumber : '' }}
                    </span>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #diagnosesTemplate>
    <div class="flex-column">

        <table mat-table [dataSource]="this.case.diagnoses" class="flex-100">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> id. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Diagnóstico </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Observations Column -->
            <ng-container matColumnDef="observation">
                <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <div class="flex-column mts-3 flex-100 flex-row-center">
            <button mat-button [color]="'warn'" type="button" (click)="onClickCloseDialog()">
                <span>
                    Cerrar
                </span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #editPatient>
    <ng-container *ngIf="!(this.isLoadingGettingPatient$ | async) else loadingPatient;">
        <app-patient-data [patientIN]="this.patchPatient" [parentComponent]="'patient-detail'" [case]="case"
            [isloadingUpdate]="( this.isLoadingUpdatingPatientCaseAndEntities$ | async )"
            (formPatientDataValid)="formPatientDataValid($event)"
            (isValidatingDocumentNumber)="isValidatingDocumentNumber($event)" (patientData)="patientData($event)"
            (emitCase)="isExistentCase($event)" (confirmChangesEmitter)="confirmChangesEmitter($event)"
            (onClickCancelEditPatient)="clickCancelEditPatient()"
            (onClickConfirmEditPatient)="clickConfirmEditPatient()">
        </app-patient-data>
    </ng-container>

    <!-- Spinner -->
    <ng-template #loadingPatient>
        <div class="flex-row align-center-center" style="height: 100%;">
            <mat-spinner diameter="60"></mat-spinner>
        </div>
    </ng-template>
</ng-template>
