import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { endOfDay, isSameDay, isSameMonth, startOfDay } from 'date-fns';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';

@Component({
  selector: 'app-generic-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() events: CalendarEvent[];
  @Input() loading: boolean = false;
  @Output() onDayClicked = new EventEmitter<Date>();
  @Output() onEventClicked = new EventEmitter<CalendarEvent>();
  @Output() onEventDelete = new EventEmitter<CalendarEvent>();
  @Output() onChangeMonth = new EventEmitter<Date>();
  monthEvents: CalendarEvent[];
  attentionStates = AttentionStates;
  colorReferences;
  // Month - Week - Day
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  // Día actual seleccionado
  viewDate: Date = new Date();
  lastEvent: CalendarEvent;
  previousViewDate = this.viewDate;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: `<span class="pxs-2 text-primary-darker">
                  Ver
              </span>`,
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edit', event);
      },
    },
    {
      label: `<span class="pxs-2 text-warn">
                  Eliminar
              </span>`,
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        // this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.events) {

      this.colorReferences = Array.from(new Set(this.events.map(e => e.meta.attention.agreementId)))
        .map(ag => {
          const events = this.events.find(e => e.meta.attention.agreementId == ag)
          events.title = events.title
          return {
            title: events.meta.referenceText,
            color: events.color
          }
        })
      this.setViewDateWithLastEventDate();
      this.monthEvents = this.events.map((ev) => {
        ev.meta.isNotOneDayEvent = moment(ev.meta.attention.toDate).isAfter(ev.start, 'day');
        return ev
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['events'] && changes['events'].currentValue) {
      this.events = this.addActionsEvents(this.events);
    }

    if (this.events) {
      this.colorReferences = Array.from(new Set(this.events.map(e => e.meta.referenceText)))
        .map(referenceTxt => {
          return {
            title: referenceTxt,
            color: this.events.find(e => e.meta.referenceText == referenceTxt).color
          }
        })
      this.setViewDateWithLastEventDate();
      this.monthEvents = this.events.map((ev) => {
        ev.meta.isNotOneDayEvent = moment(ev.meta.attention.toDate).isAfter(ev.start, 'day');
        return ev
      })
    }
    this.cd.detectChanges();
  }

  private addActionsEvents(events: CalendarEvent[]): CalendarEvent[] {
    const eventsComposed: CalendarEvent[] = events.map(eventUncomposed => {
      const event: CalendarEvent = {
        ...eventUncomposed,
        actions: this.actions,
      };
      return event;
    });
    return eventsComposed;
  }

  setViewDateWithLastEventDate() {
    if (this.events != null && this.events != undefined && this.events.length > 0) {

      if(this.viewDate === undefined) {
        let auxDate = this.events[0].start;
        this.events.forEach((ev) => {
          if (ev.start > auxDate) {
            auxDate = ev.start;
          }
        })
        this.viewDate = auxDate;
      }

    }
  }

  badgeClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    event.preventDefault();
    event.stopPropagation()
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  dayClicked(_event) {

    // event.preventDefault();
    this.onDayClicked.emit(_event.date);
  }

  eventClicked(_event) {
    event.preventDefault();
    event.stopPropagation();
    this.onEventClicked.emit(_event);
  }

  hourSegmentClicked(_event) { this.onDayClicked.emit(_event); }


  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {

    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Edit') {
      this.onEventClicked.emit(event);
    } else if (action === 'Deleted') {
      this.onEventDelete.emit(event);
    }
  }

  // TODO pasar al padre
  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        // color: colors.red,
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onCalendarPreviusNextView() {
    const actualMonth = moment(this.viewDate).month();
    const previousMonth = moment(this.previousViewDate).month();
    if (actualMonth != previousMonth) {
      this.previousViewDate = this.viewDate;
      this.onChangeMonth.emit(this.viewDate)
    }
  }
}
