import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Financier } from 'src/app/shared/models/financier';
import { Operator } from 'src/app/shared/models/operator';
import { Patient } from 'src/app/shared/models/patient';
import { Provider } from 'src/app/shared/models/provider';
import { BaseState } from './base.state';
import { TypeDocument } from 'src/app/shared/models/typeDocument';
import { Location } from 'src/app/shared/models/location';
import { HealthInsurance } from 'src/app/shared/models/healthInsurance';
import { Pagination } from 'src/app/shared/models/pagination';
import { HealthInsuranceQPS } from '../services/patients.service';
import { FinanciersQPS } from '../services/financiers.service';
import { MetaDataHealthInsurance, MetaDataOperators } from 'src/app/abstraction/entities.facade';
import { MetaDataProviders } from 'src/app/abstraction/entities.facade';
import { Nationality } from 'src/app/shared/models/nationality';
import { Province } from '../../shared/models/province';

@Injectable({
   providedIn: 'root',
})
export class EntitiesState extends BaseState {

    protected override store = {
      operators$: new BehaviorSubject<Operator[]>(null),
      providers$: new BehaviorSubject<Provider[]>(null),
      typeDocuments$: new BehaviorSubject<TypeDocument[]>(null),
      locations$: new BehaviorSubject<Location[]>(null),
      nations$: new BehaviorSubject<Nationality[]>(null),
      provinces$: new BehaviorSubject<Province[]>(null),
      loadingGettingTypeDocuments$: new BehaviorSubject<boolean>(false),
      loadingGettingLocations$: new BehaviorSubject<boolean>(false),
      loadingGettingNations$: new BehaviorSubject<boolean>(false),
      loadingGettingProvinces$: new BehaviorSubject<boolean>(false),

      //HEALTH INSURANCE
      healthInsurance$: new BehaviorSubject<HealthInsurance[]>(null),
      healthInsuranceCount$: new BehaviorSubject<number>(null),
      loadingGettingHealthInsuranceCount$: new BehaviorSubject<boolean>(false),
      loadingGettingHealthInsurance$: new BehaviorSubject<boolean>(false),
      metaDataHealthInsurance$: new BehaviorSubject<MetaDataHealthInsurance>(null),
      loadingCreatingHealthInsurance$: new BehaviorSubject<boolean>(false),
      errorCreatingHealthInsurance$: new BehaviorSubject<boolean>(false),
      loadingUpdatingHealthInsurance$: new BehaviorSubject<boolean>(false),
      errorUpdatingHealthInsurance$: new BehaviorSubject<boolean>(false),

      //FINANCIERS
      financiers$: new BehaviorSubject<Financier[]>(null),
      financierCount$: new BehaviorSubject<number>(null),
      loadingGettingFinancierCount$: new BehaviorSubject<boolean>(false),
      loadingGettingFinanciers$: new BehaviorSubject<boolean>(false),
      metaDataFinancier$: new BehaviorSubject<FinanciersQPS>(null),
      loadingCreatingFinancier$: new BehaviorSubject<boolean>(false),
      errorCreatingFinancier$: new BehaviorSubject<boolean>(false),
      loadingUpdatingFinancier$: new BehaviorSubject<boolean>(false),
      errorUpdatingFinancier$: new BehaviorSubject<boolean>(false),

      //PROVIDERS
      //Providers$: new BehaviorSubject<Provider[]>(null),
      loadingGettingProviders$: new BehaviorSubject<boolean>(false),
      metaDataProviders$: new BehaviorSubject<MetaDataProviders>(null),
      loadingCreatingProvider$: new BehaviorSubject<boolean>(false),
      errorCreatingProvider$: new BehaviorSubject<boolean>(false),
      loadingUpdatingProvider$: new BehaviorSubject<boolean>(false),
      errorUpdatingProvider$: new BehaviorSubject<boolean>(false),
      providersCount$: new BehaviorSubject<number>(null),
      loadingGettingProvidersCount$: new BehaviorSubject<boolean>(false),

      //OPERATORS
      //Providers$: new BehaviorSubject<Provider[]>(null),
      loadingGettingOperators$: new BehaviorSubject<boolean>(false),
      metaDataOperators$: new BehaviorSubject<MetaDataOperators>(null),
      loadingCreatingOperator$: new BehaviorSubject<boolean>(false),
      errorCreatingOperator$: new BehaviorSubject<boolean>(false),
      loadingUpdatingOperator$: new BehaviorSubject<boolean>(false),
      errorUpdatingOperator$: new BehaviorSubject<boolean>(false),
      operatorsCount$: new BehaviorSubject<number>(null),
      loadingGettingOperatorsCount$: new BehaviorSubject<boolean>(false),

      pagination$: new BehaviorSubject<Pagination>(null),
      loadingGettingPagination$: new BehaviorSubject<boolean>(false),

      // PATIENTS
      patients$: new BehaviorSubject<Patient[]>(null),
      loadingGettingPatients$: new BehaviorSubject<boolean>(false),

      // PATIENT ACTIVE
      loadingGettingPatient$: new BehaviorSubject<boolean>(false),
   };

   constructor() {
      super();
   }

   isLoadingGettingPagination$() {
      return this.store.loadingGettingPagination$.asObservable();
   }

   setLoadingGettingPagination(isLoadingGettingPagination: boolean) {
      this.store.loadingGettingPagination$.next(isLoadingGettingPagination);
   }



   getLocations$(): Observable<Location[]> {
      return this.store.locations$.asObservable();
   }

   setLocations(locations: Location[]) {
      this.store.locations$.next(locations);
   }

   isLoadingGettingLocations$() {
      return this.store.loadingGettingLocations$.asObservable();
   }

   setLoadingGettingLocations(loading: boolean) {
      this.store.loadingGettingLocations$.next(loading);
   }

   getNations$(): Observable<Nationality[]> {
      return this.store.nations$.asObservable();
   }

   setNations(nations: Nationality[]) {
      this.store.nations$.next(nations);
   }

   isLoadingGettingNations$() {
      return this.store.loadingGettingNations$.asObservable();
   }

   setLoadingGettingNations(loading: boolean) {
      this.store.loadingGettingNations$.next(loading);
   }

   getProvinces$(): Observable<Province[]> {
      return this.store.provinces$.asObservable();
   }

   setProvinces(provinces: Province[]) {
      this.store.provinces$.next(provinces);
   }

   isLoadingGettingProvinces$() {
      return this.store.loadingGettingProvinces$.asObservable();
   }

   setLoadingGettingProvinces(loading: boolean) {
      this.store.loadingGettingProvinces$.next(loading);
   }

   isLoadingGettingTypeDocuments$() {
      return this.store.loadingGettingTypeDocuments$.asObservable();
   }

   setLoadingGettingTypeDocuments(loading: boolean) {
      this.store.loadingGettingTypeDocuments$.next(loading);
   }

   getTypeDocuments$(): Observable<TypeDocument[]> {
      return this.store.typeDocuments$.asObservable();
   }

   setTypeDocuments(typeDocuments: TypeDocument[]) {
      this.store.typeDocuments$.next(typeDocuments);
   }

   //Health Insurance

   isLoadingGettingHealthInsurance$() {
      return this.store.loadingGettingHealthInsurance$.asObservable();
   }

   setLoadingGettingHealthInsurance(loading: boolean) {
      this.store.loadingGettingHealthInsurance$.next(loading);
   }

   isLoadingGettingHealthInsuranceCount$() {
      return this.store.loadingGettingHealthInsuranceCount$.asObservable();
   }

   setLoadingGettingHealthInsuranceCount(loading: boolean) {
      this.store.loadingGettingHealthInsuranceCount$.next(loading);
   }

   getMetaDataHealthInsurance$(): Observable<MetaDataHealthInsurance> {
      return this.store.metaDataHealthInsurance$.asObservable();
   }

   setMetaDataHealthInsurance(metaDataHealthInsurance: MetaDataHealthInsurance) {
      this.store.metaDataHealthInsurance$.next(metaDataHealthInsurance);
   }

   updateMetaHealthInsurance(metaDataHealthInsurance: HealthInsuranceQPS) {
      // const md = this.store.metaDataHealthInsurance$.getValue();
      // console.log('se esta emitiedno: ', { ...md, ...metaDataHealthInsurance });

      // this.store.metaDataHealthInsurance$.next({ ...md, ...metaDataHealthInsurance });

      this.update<HealthInsuranceQPS>({ data: metaDataHealthInsurance, storeRefAttribute: this.store.metaDataHealthInsurance$ })
   }

   getHealthInsurance$(): Observable<HealthInsurance[]> {
      return this.store.healthInsurance$.asObservable();
   }

   setHealthInsurance(healthInsurance: HealthInsurance[]) {
      this.store.healthInsurance$.next(healthInsurance);
   }

   getHealthInsuranceCount$(): Observable<number> {
      return this.store.healthInsuranceCount$.asObservable();
   }

   setHealthInsuranceCount(healthInsuranceCount: number) {
      this.store.healthInsuranceCount$.next(healthInsuranceCount);
   }


   isLoadingCreatingHealthInsurance$() {
      return this.store.loadingCreatingHealthInsurance$.asObservable();
   }

   setLoadingCreatingHealthInsurance(isLoadingCreatingHealthInsurance: boolean) {
      this.store.loadingCreatingHealthInsurance$.next(isLoadingCreatingHealthInsurance);
   }

   isErrorCreatingHealthInsurance$() {
      return this.store.errorCreatingHealthInsurance$.asObservable();
   }

   setErrorCreatingHealthInsurance(isErrorCreatingCase: boolean) {
      this.store.errorCreatingHealthInsurance$.next(isErrorCreatingCase);
   }

   addHealthInsurance(newHi: HealthInsurance) {
      // const hi = this.store.healthInsurance$.getValue();
      // hi.push(newHi);
      // console.log('Antes', hi);

      // this.store.healthInsurance$.next(hi);
      // const hi2 = this.store.healthInsurance$.getValue();
      // console.log('despues', hi2);
      this.add<HealthInsurance>({ data: newHi, storeRefAttribute: this.store.healthInsurance$ })
   }

   isLoadingUpdatingHealthInsurance$() {
      return this.store.loadingUpdatingHealthInsurance$.asObservable();
   }

   setLoadingUpdatingHealthInsurance(loadingUpdatingHealthInsurance: boolean) {
      this.store.loadingUpdatingHealthInsurance$.next(loadingUpdatingHealthInsurance);
   }

   isErrorUpdatingHealthInsurance$() {
      return this.store.errorUpdatingHealthInsurance$.asObservable();
   }

   setErrorUpdatingHealthInsurance(isErrorUpdatingHealthInsurance: boolean) {
      this.store.errorUpdatingHealthInsurance$.next(isErrorUpdatingHealthInsurance);
   }



   updateHealthInsurance(hiUpdate: Partial<HealthInsurance>, hiId: number) {
      // let hi = this.store.healthInsurance$.getValue();
      // let hiIndex = hi.findIndex(ahi => ahi.id === hiId);
      // if (hiIndex != -1) {
      //    hi[hiIndex] = { ...hi[hiIndex], ...hiUpdate }
      //    console.log('Se actualizoaron las obras sociales ', hi);
      //    this.store.healthInsurance$.next(hi);
      // } else {
      //    console.log('No se encontro la provision a actualizar');
      // }
      this.update<Partial<HealthInsurance>>({ data: hiUpdate, dataId: hiId, storeRefAttribute: this.store.healthInsurance$ })

   }

   // PATIENTS
   isLoadingGettingPatients$() {
      return this.store.loadingGettingPatients$.asObservable();
   }

   setLoadingGettingPatients(isLoadingGettingPatients: boolean) {
      this.store.loadingGettingPatients$.next(isLoadingGettingPatients);
   }

   getPatients$(): Observable<Patient[]> {
      return this.store.patients$.asObservable();
   }

   setPatients(patients: Patient[]) {
      this.store.patients$.next(patients);
   }

   // ACTIVE PATIENT => case's patientActive === entities's patientActive
   isLoadingGettingPatient$() {
      return this.store.loadingGettingPatient$.asObservable();
   }

   setLoadingGettingPatient( isLoadingGettingPatient: boolean ) {
      this.store.loadingGettingPatient$.next(isLoadingGettingPatient);
   }

   // Financiers
   isLoadingGettingFinanciers$() {
      return this.store.loadingGettingFinanciers$.asObservable();
   }

   setLoadingGettingFinanciers(isLoadingGettingFinanciers: boolean) {
      this.store.loadingGettingFinanciers$.next(isLoadingGettingFinanciers);
   }

   getFinanciers$(): Observable<Financier[]> {
      return this.store.financiers$.asObservable();
   }

   setFinanciers(financiers: Financier[]) {
      this.store.financiers$.next(financiers);
   }

   isLoadingGettingFinancierCount$() {
      return this.store.loadingGettingFinancierCount$.asObservable();
   }

   setLoadingGettingFinancierCount(loading: boolean) {
      this.store.loadingGettingFinancierCount$.next(loading);
   }

   getMetaDataFinancier$(): Observable<FinanciersQPS> {
      return this.store.metaDataFinancier$.asObservable();
   }

   setMetaDataFinancier(metaDataFinancier: FinanciersQPS) {
      this.store.metaDataFinancier$.next(metaDataFinancier);
   }

   updateMetaFinancier(metaDataFinancier: FinanciersQPS) {
      this.update<FinanciersQPS>({ data: metaDataFinancier, storeRefAttribute: this.store.metaDataFinancier$ })
      // const md = this.store.metaDataFinancier$.getValue();
      // console.log('se esta emitiedno: ', { ...md, ...metaDataFinancier });

      // this.store.metaDataFinancier$.next({ ...md, ...metaDataFinancier });
   }

   getFinancierCount$(): Observable<number> {
      return this.store.financierCount$.asObservable();
   }

   setFinancierCount(financierCount: number) {
      this.store.financierCount$.next(financierCount);
   }


   isLoadingCreatingFinancier$() {
      return this.store.loadingCreatingFinancier$.asObservable();
   }

   setLoadingCreatingFinancier(isLoadingCreatingFinancier: boolean) {
      this.store.loadingCreatingFinancier$.next(isLoadingCreatingFinancier);
   }

   isErrorCreatingFinancier$() {
      return this.store.errorCreatingFinancier$.asObservable();
   }

   setErrorCreatingFinancier(isErrorCreatingFinancier: boolean) {
      this.store.errorCreatingFinancier$.next(isErrorCreatingFinancier);
   }

   addFinancier(newFinancier: Financier) {
      // const financiers = this.store.financiers$.getValue();
      // financiers.push(newFinancier);
      // console.log('Antes', financiers);

      // this.store.financiers$.next(financiers);
      // const financiers2 = this.store.financiers$.getValue();
      // console.log('despues', financiers2);
      this.add<HealthInsurance>({ data: newFinancier, storeRefAttribute: this.store.financiers$ })

   }

   isLoadingUpdatingFinancier$() {
      return this.store.loadingUpdatingFinancier$.asObservable();
   }

   setLoadingUpdatingFinancier(loadingUpdatingFinancier: boolean) {
      this.store.loadingUpdatingFinancier$.next(loadingUpdatingFinancier);
   }

   isErrorUpdatingFinancier$() {
      return this.store.errorUpdatingFinancier$.asObservable();
   }

   setErrorUpdatingFinancier(isErrorUpdatingFinancier: boolean) {
      this.store.errorUpdatingFinancier$.next(isErrorUpdatingFinancier);
   }

   updateFinancier(financierUpdate: Partial<Financier>, financierId: number) {
      // let financiers = this.store.financiers$.getValue();
      // let financierIndex = financiers.findIndex(fin => fin.id === financierId);
      // if (financierIndex != -1) {
      //    financiers[financierIndex] = { ...financiers[financierIndex], ...financierUpdate }
      //    console.log('Se actualizoaron los clientes ', financiers);
      //    this.store.financiers$.next(financiers);
      // } else {
      //    console.log('No se encontro el cliente a actualizar');
      // }

      this.update<Partial<Financier>>({ data: financierUpdate, dataId: financierId, storeRefAttribute: this.store.financiers$})

   }

   // Providers
   isLoadingGettingProvidersCount$() {
      return this.store.loadingGettingProvidersCount$.asObservable();
   }

   setLoadingGettingProvidersCount(loading: boolean) {
      this.store.loadingGettingProvidersCount$.next(loading);
   }

   getProvidersCount$(): Observable<number> {
      return this.store.providersCount$.asObservable();
   }

   setProvidersCount(providersCount: number) {
      this.store.providersCount$.next(providersCount);
   }

   isLoadingGettingProviders$() {
      return this.store.loadingGettingProviders$.asObservable();
   }

   setLoadingGettingProviders(loading: boolean) {
      this.store.loadingGettingProviders$.next(loading);
   }

   getMetaDataProviders$(): Observable<MetaDataProviders> {
      return this.store.metaDataProviders$.asObservable();
   }

   setMetaDataProviders(metaDataProviders: MetaDataProviders) {
      this.store.metaDataProviders$.next(metaDataProviders);
   }

   getProviders$(): Observable<Provider[]> {
      return this.store.providers$.asObservable();
   }

   setProviders(providers: Provider[]) {
      this.store.providers$.next(providers);
   }

   isLoadingCreatingProvider$() {
      return this.store.loadingCreatingProvider$.asObservable();
   }

   setLoadingCreatingProvider(isLoadingCreatingProvider: boolean) {
     this.store.loadingCreatingProvider$.next(isLoadingCreatingProvider);

}

   isErrorCreatingProvider$() {
      return this.store.errorCreatingProvider$.asObservable();
   }

   setErrorCreatingProvider(isErrorCreatingProvider: boolean) {
      this.store.errorCreatingProvider$.next(isErrorCreatingProvider);
   }

   addProvider(newEntidad: Provider) {
      this.add<Provider>({ data: newEntidad, storeRefAttribute: this.store.providers$ })
   }

   isLoadingUpdatingProvider$() {
      return this.store.loadingUpdatingProvider$.asObservable();
   }

   setLoadingUpdatingProvider(loadingUpdatingProvider: boolean) {
      this.store.loadingUpdatingProvider$.next(loadingUpdatingProvider);
   }

   isErrorUpdatingProvider$() {
      return this.store.errorUpdatingProvider$.asObservable();
   }

   setErrorUpdatingProvider(isErrorUpdatingProvider: boolean) {
      this.store.errorUpdatingProvider$.next(isErrorUpdatingProvider);
   }


   updateProvider(entidadUpdate: Partial<Provider>, entidadId: number) {
      this.update<Partial<Provider>>({ data: entidadUpdate, dataId: entidadId, storeRefAttribute: this.store.providers$ })

   }

   // Operators
   isLoadingGettingOperatorsCount$() {
      return this.store.loadingGettingOperatorsCount$.asObservable();
   }

   setLoadingGettingOperatorsCount(loading: boolean) {
      this.store.loadingGettingOperatorsCount$.next(loading);
   }

   getOperatorsCount$(): Observable<number> {
      return this.store.operatorsCount$.asObservable();
   }

   setOperatorsCount(OperatorsCount: number) {
      this.store.operatorsCount$.next(OperatorsCount);
   }

   isLoadingGettingOperators$() {
      return this.store.loadingGettingOperators$.asObservable();
   }

   setLoadingGettingOperators(loading: boolean) {
      this.store.loadingGettingOperators$.next(loading);
   }

   getMetaDataOperators$(): Observable<MetaDataOperators> {
      return this.store.metaDataOperators$.asObservable();
   }

   setMetaDataOperators(metaDataOperators: MetaDataOperators) {
      this.store.metaDataOperators$.next(metaDataOperators);
   }

   getOperators$(): Observable<Operator[]> {
      return this.store.operators$.asObservable();
   }

   setOperators(Operators: Operator[]) {
      this.store.operators$.next(Operators);
   }

   isLoadingCreatingOperator$() {
      return this.store.loadingCreatingOperator$.asObservable();
   }

   setLoadingCreatingOperator(isLoadingCreatingOperator: boolean) {
     this.store.loadingCreatingOperator$.next(isLoadingCreatingOperator);

}

   isErrorCreatingOperator$() {
      return this.store.errorCreatingOperator$.asObservable();
   }

   setErrorCreatingOperator(isErrorCreatingOperator: boolean) {
      this.store.errorCreatingOperator$.next(isErrorCreatingOperator);
   }

   addOperator(newEntidad: Operator) {
      this.add<Operator>({ data: newEntidad, storeRefAttribute: this.store.operators$ })
   }

   isLoadingUpdatingOperator$() {
      return this.store.loadingUpdatingOperator$.asObservable();
   }

   setLoadingUpdatingOperator(loadingUpdatingOperator: boolean) {
      this.store.loadingUpdatingOperator$.next(loadingUpdatingOperator);
   }

   isErrorUpdatingOperator$() {
      return this.store.errorUpdatingOperator$.asObservable();
   }

   setErrorUpdatingOperator(isErrorUpdatingOperator: boolean) {
      this.store.errorUpdatingOperator$.next(isErrorUpdatingOperator);
   }


   updateOperator(entidadUpdate: Partial<Operator>, entidadId: number) {
      this.update<Partial<Operator>>({ data: entidadUpdate, dataId: entidadId, storeRefAttribute: this.store.operators$ })

   }

   // Pagination
   getPagination$(): Observable<Pagination> {
      return this.store.pagination$.asObservable();
   }

   setPagination(pagination: Pagination) {
      this.store.pagination$.next(pagination);
   }

}
