import { THEMES } from 'src/app/core/enums/themes';
import { adomicilioData } from './tenantsData/adomicilio';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const version_1 = '/api/v1';
const version_2 = '/api/v2';
const ipServer = 'https://api.dev.simfinix.com';
const ipJsonServer = 'http://localhost';
const portAuthService = ':443';
const portCaseService = ':443';
const portNomenclatorService = ':443';
const portScheduleService = ':443';
const portRecordService = ':443';
const portProviderService = ':443';
const portPatientService = ':443';
const portOperatorService = ':443';
const portFinancierService = ':443';
const portJsonServer = ':3004';

export const environment = {

   production: false,

   apiAuthService: ipServer + portAuthService + version_1,
   apiCaseService: ipServer + portCaseService + version_1,
   apiNomenclatorService: ipServer + portNomenclatorService + version_1,
   apiRecordService: ipServer + portRecordService + version_2,
   apiAttentions: ipServer + portScheduleService + version_1,
   apiProviderServer: ipServer + portProviderService + version_1,
   apiPatientServer: ipServer + portPatientService + version_1,
   apiOperatorServer: ipServer + portOperatorService + version_1,
   apiNomenclatorServer: ipServer + portNomenclatorService + version_1,
   apiFinancierServer: ipServer + portFinancierService + version_1,
   accessTokenHeaderName: 'Authorization',
   actualTheme: THEMES.ADOMICILIO,
   tenantData: adomicilioData,
   googleMapsApiKey: 'AIzaSyDTyqNHCG_S0_gsgZGJG5H6EYOXeKbGvJc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
