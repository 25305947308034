import { Component, ViewChild, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { Subscription, fromEvent, timer } from 'rxjs';
import { filter, map, startWith, skip, debounce } from 'rxjs/operators';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
    selector: 'app-text-searching-input',
    templateUrl: './text-searching-input.component.html',
    styleUrls: ['./text-searching-input.component.scss']
})
export class TextSearchingInputComponent extends FormElementComponent {
    @ViewChild('inputSearch', { static: false }) inputSearch!: ElementRef;
    @Input() override placeholder!: string;
    @Input() override label!: string;
    @Input() inputFilterValue = '';
    @Output() onSearch = new EventEmitter<string>();
    _input!: Subscription;

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        this.listenInput();
    }

    ngOnDestroy(): void {
        this._input.unsubscribe();
    }

    listenInput() {
        setTimeout(() => {
            this._input = fromEvent<KeyboardEvent>(this.inputSearch.nativeElement, 'keyup').
                pipe(
                    filter((e: KeyboardEvent) => !e.key.includes('Arrow')),
                    map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
                    startWith(''),
                    filter((value: string) => (value.length != 1 && value.length != 2)),
                    skip(1),
                    debounce((value: string) => (value.length > 1) ? timer(500) : timer(1250))
                ).subscribe((st: string) => {
                    console.log(st);
                    this.onSearch.emit(st);
                });
        }, 500)
    }
}
