<div class="flex-column align-start-center">
    <!-- Button close -->
    <div mat-dialog-actions class="full-width" style="justify-content: end;">
        <button mat-icon-button (click)="onCloseClick()">
            <mat-icon [color]="'primary'">highlight_off</mat-icon>
        </button>
    </div>
    <!-- File -->
    <div class="flex-100 flex-row align-center-center" style="">
        <!-- alt="Foto de la evolución" -->
        <img [ngStyle]="{'transform': 'rotate('+this.rotationAmount+'deg)'}"
            [src]="this.photo64" width="90%"/>
    </div>
    <!-- Rotate buttons -->
    <div class="align-center-start">
        <button mat-icon-button (click)="this.rotate('left')">
            <mat-icon [color]="'primary'">rotate_left</mat-icon>
        </button>
        <button mat-icon-button (click)="this.rotate('right')">
            <mat-icon [color]="'primary'">rotate_right</mat-icon>
        </button>
    </div>
    <!-- Button Descargar -->
    <div class="align-center-center mbs-5">
        <button mat-stroked-button [color]="'primary'" (click)="fileUtilitiesService.downloadFile(this.photo64)">
            <span>Descargar</span>
        </button>
    </div>
</div>
