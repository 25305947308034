import { Injectable } from '@angular/core';
import { MapsSate } from "../core/states/maps.state";
import { MarkerGoogleMaps } from "../shared/models/maps/markerGoogleMaps";

@Injectable({
	providedIn: 'root',
})
export class MapsFacade {

    constructor(
        private mapsState: MapsSate
    ) { }

    addMarker(marker: MarkerGoogleMaps) {
        this.mapsState.addMarker(marker);
    }

    setMarkers(markers: MarkerGoogleMaps[]) {
        this.mapsState.setMarkers(markers);
    }

    mergeMarkers(markers: MarkerGoogleMaps[]){
        this.mapsState.mergeMarkers(markers)
    }

    resetMarkers(){
        this.mapsState.resetMarkers();
    }
}
