import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { APIS } from '../enums/apis';
import { Provider } from 'src/app/shared/models/provider';
import { RestUtilitiesService } from './rest-utilities.service';
import { Patient } from 'src/app/shared/models/patient';
import { Address } from 'src/app/shared/models/address';
import { TypeDocument } from 'src/app/shared/models/typeDocument';
import { Location } from 'src/app/shared/models/location';
import { HealthInsurance } from 'src/app/shared/models/healthInsurance';
import { Pagination } from 'src/app/shared/models/pagination';
import { Province } from 'src/app/shared/models/province';
import { PatchPatientBody } from './cases.service';
import { PatientEntities } from '../../shared/models/patientEnttities';

export interface PatientsQPS {
   fullName_like?: string;
   active?: boolean;
   document_number_like?: string;
   document_number?: string;
}
export interface HealthInsurancesWithPages {
   data: HealthInsurance[],
   pagination: Pagination;
}
export interface LocationsQPS {
   name_like?: string;
   provinceId?: number;
}

export interface ProvinceQPS{
   name_like?: string;
}

export interface PostHealthInsuranceBody {
   name: string;
}

export interface PatchHealthInsuranceBody {
   name?: string;
   active?: boolean;
}

export interface HealthInsuranceQPS {
   name_like?: string;
   page?: number,
   size?: number,
   active?: boolean
}
@Injectable({
   providedIn: 'root',
})
export class PatientsService {
   constructor(private http: HttpClient, private restUtilitiesService: RestUtilitiesService) { }

   getPatients(qps?: PatientsQPS): Observable<Patient[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiPatientServer}${APIS.PATIENTS}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         /**
          * !Ojo que falta el .data
          */
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
               // return response.body;
            }),
         );
   }

   getPatient( patientId: number ): Observable<PatientEntities> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs( patientId );
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiPatientServer}${APIS.PATIENTS}/${patientId}`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>(( response ) => {
               return response.body.data;
            }),
         );
   }   

   getHealthInsurance(qps?: HealthInsuranceQPS): Observable<HealthInsurancesWithPages> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiPatientServer}/user/health-insurance`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body;
            }),
         );
   }

   getHealthInsuranceCount(): Observable<any> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiPatientServer}/user/health-insurance/count`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   postHealthInsurance(hi: PostHealthInsuranceBody): Observable<HealthInsurance> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .post<any>(`${environment.apiPatientServer}/user/health-insurance`, JSON.stringify(hi), {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   patchHealthInsurance(hi: PatchHealthInsuranceBody, hiId) {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .patch<any>(
            `${environment.apiPatientServer}/user/health-insurance/${hiId}`,
            JSON.stringify(hi),
            {
               headers: queryHeaders,
               observe: 'response',
            },
         )
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getTypeDocuments(): Observable<TypeDocument[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      return this.http
         .get<any>(`${environment.apiPatientServer}${APIS.PATIENTS}/document-types`, {
            headers: queryHeaders,
            observe: 'response',
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getLocations(qps: LocationsQPS): Observable<Location[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiPatientServer}/user/locations`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   getProvinces(qps: ProvinceQPS): Observable<Province[]> {
      const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
      const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
      const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
      return this.http
         .get<any>(`${environment.apiPatientServer}/user/provinces`, {
            headers: queryHeaders,
            observe: 'response',
            params: queryParams,
         })
         .pipe<any>(
            map<HttpResponse<any>, any>((response) => {
               return response.body.data;
            }),
         );
   }

   public getAddress(address: Address) {
      return {
         with: (...props) => {
            return props.reduce((addressStr: string, prop: string) => {
               return !!address[prop] ? addressStr += address[prop] + ' ' : addressStr;
            }, '')
         },
         withAll: () => {
            let addressReturn: string = '';
            addressReturn += address.street ? address.street + ' ' : '';
            addressReturn += (address.streetNumber ? address.streetNumber + ' ' : '');
            addressReturn += (address.flat ? address.flat + ' ' : '');
            addressReturn += (address.floor ? address.floor + ' ' : '');
            addressReturn += (address.location && address.location.name ? address.location.name : '');
            addressReturn += (address.location && address.location.province && address.location.province.name ? ', ' + address.location.province.name : '');
            return addressReturn;
         }
      }
   }
}
