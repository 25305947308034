/**
 * * Enum de las APIs de la aplicación para comunicación con el BackEnd
 */
export enum APIS {
   LOGIN = '/login',
   LOGIN_V2 = '/login/v2',
   LOGOUT = '/logout',
   USER = '/user',
   USERS = '/users',
   TENANTS = '/tenants',
   TASKS = '/user/tasks',
   EVOLUTIONS = '/user/evolutions',
   EVOLUTIONS_COUNT = '/user/evolutions/count',
   CATEGORIES_OF_EVOLUTIONS = '/user/evolutions/category',
   FINANCIERS = '/user/financiers',
   ATTENTIONS = '/user/attentions',
   ATTENTIONS_V2 = '/user/attentions/v2',
   CASES = '/user/cases',
   AGREEMENTS = '/user/agreements',
   AGREEMENTS_BY_SPECIALTY = '/user/agreements/agreements-by-specialty',
   ARRANGEMENTS = '/user/arrangements',
   PROVIDERS = '/user/providers',
   PATIENTS = '/user/patients',
   OPERATORS = '/user/operators',
   LIQUIDATIONS = '/user/liquidations',
   DIAGNOSES = '/user/diagnoses',
   REASONS = '/reasons',
   PRACTICES = '/user/practices',
   PRACTICES_RESTRICTIONS = '/user/restrictions',
   SPECIALTIES = '/user/specialties',
   PROVISIONS = '/user/provisions',
   PROVISION_FEES = '/user/provision-fees',
   VAT_CATEGORIES = '/user/vat-category',
   FORMS = '/user/forms',
   FORM = '/user/form',
   TAGS = '/user/tags',
   TAG = '/user/tag',
   CONSENT = '/user/consent',
   REQUISITIONS = '/user/requisitions',
   REQUISITIONS_INDICATORS = '/user/requisitions/indicators',
   COMPLEXITIES = '/user/complexities',
   COMPANIES_TYPE = '/user/company-type',
   TAX_ZONES = '/user/tax-zone',
   CASE_PATIENTS = '/user/case-patients',
   CASE_INDICATORS = '/user/cases/indicators',
}
