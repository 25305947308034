import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { tap, finalize, shareReplay, map } from 'rxjs/operators';
import { NomenclatorState } from '../core/states/nomenclator.state';
import { Practice } from '../shared/models/practice';
import { NomenclatorService, SpecialtiesQPS, PostPracticeBody, PatchPracticeBody, PracticesQPS, PracticesCountQPS } from '../core/services/nomenclator.service'
import { ProvisionFee } from '../shared/models/provisionFee';
import { Specialty } from '../shared/models/specialty';
import { Pagination } from '../shared/models/pagination';
import { PracticeRestriction } from '../shared/models/practiceRestriction';
import { GeneralService } from '../core/services/general.service';

export interface ProvisionFeesFilterParameters {
    practiceId?: number;
    financierId?: number;
}

export interface MetaDataPractices extends PracticesQPS, Pagination {

}
@Injectable({
    providedIn: 'root',
})
export class NomenclatorFacade {

    private practices$: Observable<Practice[]>;
    private loadingGettingPractices$ = new BehaviorSubject<boolean>(false);

    constructor(
        private nomenclatorState: NomenclatorState,
        private nomenclatorService: NomenclatorService,
        private generalService: GeneralService
    ) {
        // Practices cache
        this.setLoadingGettingPractices(true);
        this.practices$ = this.nomenclatorService.getPractices()
            .pipe(
                map(res => res.data),
                tap(() => this.setLoadingGettingPractices(false)),
                shareReplay(1)
            );
    }

    // Practices cache
    getPracticesFiltered$(value: string): Observable<Practice[]> {
        return this.practices$
            .pipe(
                map(practices => practices.filter(practice => {
                    const name = this.generalService.normalizeString(practice.name.toLowerCase().trim());
                    value = this.generalService.normalizeString(value.toLowerCase().trim());
                    return name.indexOf(value) != -1;
                }))
            );
    }

    // ** PRACTICE RESTRICTIONS **
    private setLoadingGettingPractices(isLoadingGettingPractices: boolean) {
        this.loadingGettingPractices$.next(isLoadingGettingPractices);
    }

    isLoadingGetPracticeRestrictions$(): Observable<boolean> {
        return this.nomenclatorState.isLoadingGetPracticeRestrictions$();
    }

    getPracticeRestrictions$(): Observable<PracticeRestriction[]> {
        return this.nomenclatorState.getPracticeRestrictions$();
    }

    // this method make the request and update the state
    loadPracticeRestrictions() {
        this.nomenclatorState.setLoadingGetPracticeRestrictions(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.nomenclatorService.getPracticeRestrictions().pipe(
                tap(body => this.nomenclatorState.setPracticeRestrictions(body.data)),
                tap(() => this.nomenclatorState.setLoadingGetPracticeRestrictions(false)),
                tap((body) => console.log('request finished. Practice Restrictions: ', body.data)),
            ).subscribe({
                next: response => res(response.data),
                error: e => {
                    this.nomenclatorState.setLoadingGetPractices(false);
                    this.nomenclatorState.setLoadingGetPagination(false);
                    rej(e)
                },
            })
        })
        return from(promise);
    }

    // **PRACTICES**
    isLoadingGetPractices$(): Observable<boolean> {
        return this.nomenclatorState.isLoadingGetPractices$();
    }

    isLoadingCreatingPractice$(): Observable<boolean> {
        return this.nomenclatorState.isLoadingCreatingPractice$();
    }

    isLoadingUpdatingPractice$(): Observable<boolean> {
        return this.nomenclatorState.isLoadingUpdatingPractice$();
    }

    getMetaDataPractices$(): Observable<MetaDataPractices> {
        return this.nomenclatorState.getMetaDataPractices$();
    }

    getPractices$(): Observable<Practice[]> {
        return this.nomenclatorState.getPractices$();
    }

    addPractice(practice: PostPracticeBody): any | Observable<Practice> {
        this.nomenclatorState.setLoadingCreatingPractice(true);
        // Mapper from CreationalCase to PostCaseBody must be here
        const promise: Promise<Practice> = new Promise((res, rej) => {
            this.nomenclatorService.postPractice(practice).pipe(
                finalize(() => this.nomenclatorState.setLoadingCreatingPractice(false))
            ).subscribe({
                next: practice => { this.nomenclatorState.addPractice(practice); res(practice) },
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }

    updatePractice(practice: PatchPracticeBody, practiceId: number): Observable<Practice> {
        this.nomenclatorState.setLoadingUpdatingPractice(true);
        // Mapper from UpdatingProvision to UpdatingProvisionBody must be here
        const promise: Promise<Practice> = new Promise((res, rej) => {
            this.nomenclatorService.patchPractice(practice, practiceId).pipe(
                finalize(() => this.nomenclatorState.setLoadingUpdatingPractice(false))
            ).subscribe({
                next: newProvision => { this.nomenclatorState.updatePractice(practice, practiceId); res(newProvision) },
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }

    // this method make the request and update the state
    loadPractices(qps?: PracticesQPS) {
        this.nomenclatorState.setLoadingGetPractices(true);
        const promise: Promise<any> = new Promise((res, rej) => {
            this.nomenclatorService.getPractices(qps).pipe(
                tap((body) => {
                    this.nomenclatorState.setMetaDataPractices({ ...qps, ...body.pagination });
                }),
                tap((body) => console.log('request loadPractices finished. Order: ', body.data, 'Pagination :', body.pagination)),
                finalize(() => {
                    this.nomenclatorState.setLoadingGetPagination(false);
                    this.nomenclatorState.setLoadingGetPractices(false);
                }),
            ).subscribe({
                next: response => {
                    res(response.data);
                    this.nomenclatorState.setPractices(response.data);
                },
                error: e => {
                    rej(e)
                },
            })
        })
        return from(promise);
    }

    getPracticesCount$(): Observable<number> {
        return this.nomenclatorState.getPracticesCount$();
    }

    loadPracticesCount(qps: PracticesCountQPS) {
        const promise: Promise<any> = new Promise((res, rej) => {
            this.nomenclatorService.getPracticesCount(qps).pipe(
                tap(body => this.nomenclatorState.setPracticesCount(body.data.count)),
            ).subscribe({
                next: response => res(response.data.count),
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }

   //**SPECIALTIES */
   loadSpecialties(qps?: SpecialtiesQPS): Observable<Specialty[]> {
      this.nomenclatorState.setLoadingGetSpecialties(true);
      const promise: Promise<Specialty[]> = new Promise((res, rej) => {
         this.nomenclatorService.getSpecialties(qps).subscribe(
            (specialties) => { this.nomenclatorState.setSpecialties(specialties); res(specialties) },
            (e) => { rej(e) },
            () => this.nomenclatorState.setLoadingGetSpecialties(false))
      });
      return from(promise);
   }

    getSpecialties$(): Observable<Specialty[]> {
        return this.nomenclatorState.getSpecialties$();
    }

    // **PROVISION FEE**
    isLoadingGetProvisionFees$(): Observable<boolean> {
        return this.nomenclatorState.isLoadingGetPractices$();
    }


    getProvisionFees(): Observable<ProvisionFee[]> {
        return this.nomenclatorState.getProvisionFees();
    }

    // this method make de request and update the state
    loadProvisionFees(pffp?: ProvisionFeesFilterParameters): Observable<ProvisionFee[]> {
        this.nomenclatorState.setLoadingProvisionFees(true);
        console.log('Loading request GET provison fees ');
        const promise: Promise<ProvisionFee[]> = new Promise((res, rej) => {
            this.nomenclatorService.getProvisionFees(pffp).subscribe({
                next: provisionFees => { this.nomenclatorState.setProvisionFees(provisionFees); res(provisionFees) },
                error: e => { rej(e) },
                complete: () => this.nomenclatorState.setLoadingProvisionFees(false)
            })
        });
        return from(promise);
    }

    isSupplyPractice(practiceId: number): boolean {
        return this.nomenclatorState.isSupplyPractice(practiceId);
    }

    loadComplexities() {

        const promise: Promise<any> = new Promise((resolve, reject) => {

            this.nomenclatorState.setLoadingGetComplexities(true);

            this.nomenclatorService.getComplexities().pipe(
                tap(body => this.nomenclatorState.setComplexities(body.data)),
                tap(() => this.nomenclatorState.setLoadingGetComplexities(false)),
                tap((body) => console.log('Request finished. Complexities: ', body)),
            ).subscribe({
                next: response => resolve(response.data),
                error: e => {
                    this.nomenclatorState.setLoadingGetComplexities(false);
                    reject(e)
                },
            })
        })
        return from(promise);
    }
}
