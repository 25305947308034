<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg"></mat-icon>
    <mat-form-field class="full-width" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            {{ label }}
        </mat-label>
        <mat-hint>{{ this.hint }}</mat-hint>
        <input matInput [formControlName]="name" id="{{ name }}" name="{{ name }}" type="number" placeholder="{{ placeholder }}" [readonly]="readonly"
            [attr.disabled]="disabled ? '' : null" min="{{ minValue }}" max="{{ maxValue }}" />
        @if(this.form.get(this.name)?.errors && (this.form.get(this.name)?.dirty || this.form.get(this.name)?.touched)) {
            <mat-error><b>{{ getError() }}</b></mat-error>
        }
        @if(this.money) {
            <span matPrefix>$&nbsp;</span>
        }
        @if(this.prefix) {
            <span [ngClass]="prefixClass" matPrefix>{{this.prefix}}</span>
        }
        @if(this.suffix) {
            <span [ngClass]="suffixClass" matSuffix>{{this.suffix}}</span>
        }
    </mat-form-field>
    @if(this.warnIcon) {
        <mat-icon matTooltip="this.warnToolTip" class="mat-icon-small" color="accent">{{ warnIcon }}</mat-icon>
    }
</div>
