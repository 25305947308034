import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Agreement } from "src/app/shared/models/agreement";
import { Authorization } from "src/app/shared/models/authorization";
import { Practice } from "src/app/shared/models/practice";
import { Provision } from "src/app/shared/models/provision";
import { ProvisionFee } from "src/app/shared/models/provisionFee";
import { environment } from "src/environments/environment";
import { APIS } from "../enums/apis";
import { RestUtilitiesService } from "./rest-utilities.service";
import { MedicalSupply } from "src/app/shared/models/medicalSupply";
import { Attention } from "src/app/shared/models/attention";

export interface ProvisionQPS {
	currentDate?: string,
	fromDate?: string,
	toDate?: string,
}

export interface PostProvisionBody {
	authorization: Authorization,
	fee: number,
	frequency: {
		amount: number,
		unit: string
	},
	practiceId: number,
	durationAmount?: number,
	durationUnit?: string,
	fromDate?: Date,
	toDate?: Date,
	totalAmount?: number,
	practice?: Practice,
	arrangementId?: number,
	arrangement?: {
		id: number,
		name: string
	}
}

export interface PostSuppliesBody {
  supplies: MedicalSupply[],
	fromDate: Date,
	toDate?: Date,
	authorization?: Authorization,
}

export interface PostProvisionFeeBody {
	authorization: Authorization,
	fee: number,
	frequency: {
		amount: number,
		unit: string
	},
	practiceId: number,
	fromDate: Date,
	toDate: Date,
	idToRefresh?: number
}

export interface PatchProvisionFeeBody {
  id: number,
  fee?: number,
	frequency?: {
		amount: number,
		unit: string
	},
	fromDate?: Date,
	toDate?: Date,
	profitMargin?: number,
	authorization?: Authorization,
  fixedPrice?: boolean,
  providerFee?: number,
  providerId?: number,
}

export interface PatchProvisionBody {
	active?: boolean,
	frequency?: {
		amount: number,
		unit: string
	},
	fromDate?: Date,
	toDate?: Date,
	fee?: number,
	profitMargin?: number,
	authorization?: Authorization,
	totalAmount?: number,
}

export interface PostAuthorizationBody {
	authorizationNumber: string,
	fromDate?: Date,
	toDate: Date,
	observations?: string
}
export interface PostAgreementBody {
	providerId: number,
	providerFee: number
}

export interface AgreementsQPS {
	active?: boolean;
	providersIds?: number[];
	patientsIds?: number[];
	fromDate?: Date;
	toDate?: Date;
}

export interface AgreementsBySpecialtyQPS {
    specialtyId: number;
    includePatientActive?: boolean;
    includePatientInactive?: boolean;
    fromDate?:Date
}

export interface UpdatingAgreement {

	providerFee?: number;
}

export interface CreationalAgreement {
	providerId: number;
	providerFee: number;
}

export interface CreationalProvision {
	authorization: Authorization;
	fee: number;
	frequency: {
		amount: number,
		unit: string
	};
	practiceId: number;
}

export interface DocumentBody {
	provisionFees: ProvisionFee[],
	patientId: number,
	isPdf?: boolean
}

export interface BudgetBody extends DocumentBody{
	financierId: number,
	caseId: number,
	isPractice?: boolean,
}

export interface QuoteBody extends DocumentBody{}


@Injectable({
	providedIn: 'root',
})
export class ProvisionsService {

	constructor(
		private http: HttpClient,
		private restUtilitiesService: RestUtilitiesService,
	) { }

	getProvisions(caseId: number, provisionQPS?: ProvisionQPS): Observable<Provision[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(provisionQPS);
		const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);

		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	getProvision(caseId: number, provisionId: number): Observable<Provision> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}`, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postProvision(aprovision: PostProvisionBody, caseId: number): Observable<Provision> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		console.log(aprovision);

		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions`, aprovision, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	putProvisionFee(provisionFee: Partial<PostProvisionFeeBody>, caseId: number, provisionId: number, provisionFeeId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.put<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/provision-fees/${provisionFeeId}`,
				JSON.stringify(provisionFee),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	patchProvisionFee(provisionFee: Partial<PostProvisionFeeBody>, provisionFeeId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.CASES}/provision-fees/${provisionFeeId}`,
				JSON.stringify(provisionFee),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postProvisionFee(provisionFee: PostProvisionFeeBody): Observable<ProvisionFee> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.USER}/provisionFees`, JSON.stringify(provisionFee), {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	deleteProvision(caseId: number, provisionId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.delete<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}`,
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postAuthorization(authorization: PostAuthorizationBody, caseId: number, provisionId: number): Observable<Authorization> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/authorizations`, JSON.stringify(authorization), {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postAuthorizationToProvisions(authToProvisions: any): Observable<Provision[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		// console.log("AUTH: ", authToProvisions);
		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.CASES}/provisions/authorizations`, authToProvisions, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postAgreement(agreement: PostAgreementBody, provisionId: number, caseId: number): Observable<Agreement> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/agreements`, JSON.stringify(agreement), {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	getAgreementsByProvider(qps: AgreementsQPS): Observable<Agreement[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.AGREEMENTS}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any[]>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	getAgreementsByPatient(idPatient: number): Observable<Agreement[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams = new HttpParams().append('patientsIds', idPatient.toString());
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.AGREEMENTS}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any[]>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

    getAgreementsBySpecialtyId(qps:AgreementsBySpecialtyQPS): Observable<Agreement[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.AGREEMENTS_BY_SPECIALTY}`, {
				headers: queryHeaders,
				observe: 'response',
				params: queryParams,
			})
			.pipe<any[]>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	patchProvision(provision: PatchProvisionBody, caseId: number, provisionId) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}`,
				JSON.stringify(provision),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	patchAgreement(agreement: UpdatingAgreement, caseId: number, provisionId: number, agreementId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/agreements/${agreementId}`,
				JSON.stringify(agreement),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	postAgreementToUpdate(agreement: UpdatingAgreement, caseId: number, provisionId: number, agreementId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/agreements/${agreementId}`,
				JSON.stringify(agreement),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	getAuthorizations(caseId: number, provisionId: number): Observable<Authorization[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.get<any>(`${environment.apiCaseService}${APIS.PROVISIONS}/${provisionId}/authorizations`, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body;
				}),
			);
	}

	deleteProvisions(provisions: number[]) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(`${environment.apiCaseService}${APIS.CASES}/provisions`, provisions.join(),
				{
					headers: queryHeaders,
					observe: 'response'
				}
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	deleteAgreement(caseId: number, provisionId: number, agreementId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.delete<any>(
				// TODO: Cambiar
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/provisions/${provisionId}/agreements/${agreementId}`,
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

	deleteProvisionFee(provisionFeeId: number) {

		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');

		return this.http
			.delete<any>(
				`${environment.apiCaseService}${APIS.CASES}/provision-fees/${provisionFeeId}`,
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
			);
	}

  // Supplies
  postSupplyProvisions(provisions: PostSuppliesBody, caseId: number): Observable<Provision[]> {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');

		return this.http
			.post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/supplies`, provisions, {
				headers: queryHeaders,
				observe: 'response',
			})
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data.provisions;
				}),
			);
	}

	patchProvisionFees(provisionFees: PatchProvisionFeeBody[], caseId: number) {
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http
			.patch<any>(
				`${environment.apiCaseService}${APIS.CASES}/${caseId}/supplies`,
				JSON.stringify(provisionFees),
				{
					headers: queryHeaders,
					observe: 'response',
				},
			)
			.pipe<any>(
				map<HttpResponse<any>, any>((response) => {
					return response.body.data;
				}),
    );
	}

	deleteSupplies(provFees: ProvisionFee[]){
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		let queryParam = provFees.map(provFee => provFee.id).join(',')
		return this.http.delete<any>(`${environment.apiCaseService}${APIS.CASES}/provisions/supplies/${queryParam}`,
		{
			headers: queryHeaders,
			observe: 'response',
		},)
				.pipe<any>(
					map<HttpResponse<any>,any>((response) => {
						return response.body
					})
				)
	}

	getBudgetPDF(budgetBody: BudgetBody){
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http.post(`${environment.apiCaseService}${APIS.CASES}/supplies/PDF-budget/`,
		budgetBody,
		{
			headers: queryHeaders,
			observe: 'response',
		},).pipe<any>(
			map<HttpResponse<any>, any>((response) => {
				return response.body.data;
			}),
		);
	}

	getSuppliesQuotePDF(quoteBody: QuoteBody){
		const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
		return this.http.post(`${environment.apiCaseService}${APIS.CASES}/supplies/supplies-quote-PDF/`,
		quoteBody,
		{
			headers: queryHeaders,
			observe: 'response',
		},).pipe<any>(
			map<HttpResponse<any>, any>((response) => {
				return response.body.data;
			}),
		);
	}
}
