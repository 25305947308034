<div class="flex-column align-end-center">
    <div class="flex-center-stretch pt-4" style="position: sticky; top: 0; background-color: white; z-index: 2;">
        <h2 class="text-secondary-darker">
            Evoluciones del paciente
            <span class="text-primary-darker">
                {{ this.data.surname + ' ' + this.data.name }}
            </span>
        </h2>
    </div>
    <div class="my-4 mxs-1" *ngIf="!!this.data">
        <mat-accordion>
            <mat-expansion-panel [style.background-color]="i === indexPanelOpened ? '#FBFBFB' : 'white'"
                [ngClass]="{'mat-elevation-z6': i === indexPanelOpened, 'mat-elevation-z2': i !== indexPanelOpened}"
                (afterExpand)="indexPanelOpened = i" (closed)="indexPanelOpened = -1" [expanded]="this.data.expanded"
                *ngFor="let attention of this.data.attentions; let i = index">
                <mat-expansion-panel-header>
                    <mat-panel-description>
                        <span>
                            Fecha de la atención: <b>{{ attention?.fromDate ? (attention.fromDate | date: 'dd/MM/yyyy' )
                                : 'Sin datos - '}}</b>
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="text-secondary-darker" *ngFor="let evolution of attention.evolutions">
                    <div class="mys-3 separator separator-center">
                        <span class="mx-2 text-secondary-darker">
                            Fecha de la evolución: <b>{{ evolution.date | date: 'dd/MM/yyyy' }}</b>
                        </span>
                    </div>
                    <div class="flex-row align-start-center">
                        <mat-icon class="mr-1 text-primary">assignment_ind</mat-icon>
                        <h4 class="mys-0 text-primary-darker">
                            {{
                            !!evolution.provider && !!this.entitiesFacade.getProvidersFullName(evolution.provider)
                            ? this.entitiesFacade.getProvidersFullName(evolution.provider)
                            : !evolution.attentionId ? 'Evolución' : 'Sin nombre'
                            }}&nbsp;-&nbsp;
                        </h4>
                        <span [ngSwitch]="evolution.category.id">
                            <span class="text-warn" *ngSwitchCase="EVOLUTIONS_TYPE.ABSENT_PATIENT">
                                &nbsp;Paciente ausente
                            </span>
                            <span *ngSwitchCase="EVOLUTIONS_TYPE.NORMAL">
                                &nbsp;Evolución médica
                            </span>
                            <span class="text-warn" *ngSwitchCase="EVOLUTIONS_TYPE.NOT_GOING">
                                &nbsp;Prestador ausente
                            </span>
                            <span class="text-warn" *ngSwitchCase="EVOLUTIONS_TYPE.CRITICAL">
                                &nbsp;Evolución médica crítica
                            </span>
                            <span class="text-warn" *ngSwitchCase="EVOLUTIONS_TYPE.DISCHARGE">
                                &nbsp;Alta médica
                            </span>
                            <span *ngSwitchDefault> {{evolution?.category?.description}}
                            </span>
                        </span>
                    </div>
                    <div class="mls-5 text-secondary">
                        <div class="multi_lines_text" [innerHTML]="evolution.text | safeHtml" >
                        </div>
                        <div *ngIf="evolution.hasMedia" class="mts-1 align-center">
                            <span class="mx-2 text-primary-darker">
                                Archivo cargado
                            </span>
                        </div>
                        <div *ngIf="evolution.hasMedia" class="align-center-center">
                            <ng-container *ngIf="evolution.hasMedia;else loadingPhoto">
                                <ng-container *ngFor="let mediaUrl of evolution?.mediaUrls">
                                    <div class="flex-column align-center-center" *ngIf="fileUtilitiesService.isPhoto(mediaUrl)">
                                        <img *ngIf="fileUtilitiesService.isPhoto(mediaUrl)" class="mts-2 cursor-pointer"
                                            alt="Foto de la evolución" style="max-width: 130px; max-height: 130px;"
                                            [src]="this.sanitizeUrl(mediaUrl)" (click)="openDialogImage(mediaUrl)" />
                                        <button mat-stroked-button [color]="'primary'" style="border:0px !important;"
                                            (click)="fileUtilitiesService.downloadFile(mediaUrl)">
                                            <span style="text-decoration: underline;">Descargar</span>
                                        </button>
                                    </div>
                                    <button mat-icon-button *ngIf="fileUtilitiesService.isPdf(mediaUrl)"
                                        (click)="fileUtilitiesService.downloadFile(mediaUrl)" matTooltip="Descargar">
                                        <mat-icon mat-list-icon [style.width.px]="30" [style.height.px]="30"
                                            [svgIcon]="'pdf'">
                                        </mat-icon>
                                    </button>
                                </ng-container>

                            </ng-container>
                            <ng-template #loadingPhoto>
                                <mat-spinner class="mts-4" color="primary" diameter="40">
                                </mat-spinner>
                            </ng-template>
                        </div>

                    </div>
                    <div class="flex-row align-center">
                        <button mat-button [disabled]="true" class="align-center-center" id="modalVisibleFinancier">
                            <mat-icon class="text-primary mat-icon-medium" *ngIf="evolution.visibleFinancier">visibility
                            </mat-icon>
                            <mat-icon class="text-secondary mat-icon-medium" *ngIf="!evolution.visibleFinancier">
                                visibility_off
                            </mat-icon>
                            Financiador
                        </button>
                        <button mat-button [disabled]="true" id="modalVisibleProvider">
                            <mat-icon class="text-primary mat-icon-medium" *ngIf="evolution.visibleProvider">visibility
                            </mat-icon>
                            <mat-icon class="text-secondary mat-icon-medium" *ngIf="!evolution.visibleProvider">
                                visibility_off
                            </mat-icon>
                            Prestadores
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>
            <div class="align-center-stretch" *ngIf="this.data.evolutions.length == 0">
                <h3 class="text-secondary-darker">Aún no se cargaron evoluciones</h3>
            </div>
        </mat-accordion>
    </div>
</div>
