import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { Provider } from 'src/app/shared/models/provider';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import { Attention } from 'src/app/shared/models/attention';
import { Agreement } from 'src/app/shared/models/agreement';
import { Patient } from 'src/app/shared/models/patient';
import { PracticeRestriction } from 'src/app/shared/models/practiceRestriction';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { MatDialog } from '@angular/material/dialog';
import { AttentionsService } from 'src/app/core/services/attentions.service';

enum DialogMode {
  CREATE = 1,
  EDIT = 2
}
@Component({
  selector: 'app-schedule-dialog-template',
  templateUrl: './schedule-dialog-template.component.html',
  styleUrls: ['./schedule-dialog-template.component.scss']
})

export class ScheduleDialogTemplateComponent implements OnInit {
  @Input() formConfig: {
    provider?: {
      providerSelected: number,
      providersElements: Provider[],
      order: number,
      readonly: boolean,
    },
    patient?: {
      patientSelected: number,
      patientsElements: Patient[],
      order: number,
      readonly: boolean,
    },
    agreement?: {
      agreementSelected?: number,
      agreementsElements?: Agreement[],
      order: number,
      readonly: boolean,
    },
    state?: {
      stateSelected?: number,
      statesElements?: StateAttention[],
      readonly: boolean,
    },
    date: {
      fromDate?: Date;
      toDate?: Date;

    },
    restrictions?: PracticeRestriction
  };
  // Tipo de dialog: de tipo edición de atención o de tipo creación
  @Input() mode?: DialogMode;
  // Attention que se muestra en el dialog
  activeAttention: Attention;
  // Array de fechas (Moment) para efectuar posteriormente un POST
  datesToPostSelected: moment.Moment[] = [];
  // Agreement que se selecciona al seleccionar en el select de nueva atencion! -> Se usa para saber que
  // restricciones tiene la carga (fecha de inicio/fin hora etc.) 
  agreementSelected: Agreement;
  @ViewChild('calendar', { static: false }) calendarToPost;
  @ViewChild('scheduleDialogTemplate', { static: true }) scheduleDialogTemplate: TemplateRef<any>;
  loadingCalendar: boolean = true;
  loadingInnerCalendar: boolean = false;
  elementsProvidersDialog: Provider[] = [];

  loadingEditAttention = false;
  scheduleForm: FormGroup;
  isRepeatSchedule = false;
  loadingGetAgreements = false;
  objectValues = Object.values;
  loadingCreateAttention = false;
  // FORM //
  public readonly PROVIDER = 'provider';
  public readonly PROVIDER_SEARCHING = 'providerSearching';
  public readonly PATIENT = 'patient';
  public readonly AGREEMENT = 'agreement';
  public readonly DATE_FROM = 'fromDate';
  public readonly DATE_TO = 'toDate';
  public readonly TIME_FROM = 'timeFrom';
  // public readonly TIME_DURATION = 'timeDuration';
  public readonly HOUR_DURATION = 'hourDuration';
  public readonly MINUTE_DURATION = 'minuteDuration';
  public readonly DATE_END_REPEAT = 'endDateRepeat';
  public readonly STATE_ATTENTION = 'stateAttention';
  public dayChipsSelected: Map<string, boolean> = new Map<string, boolean>();
  ATTENTION_STATES = AttentionStates;
  _dialogRef

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private attentionsService: AttentionsService,
  ) { }

  ngOnInit() {

  }

  // get providerForm() {
  //   return this.scheduleForm.get(this.PROVIDER).value;
  // }
  // get patientForm() {
  //   return this.scheduleForm.get(this.PATIENT).value;
  // }
  // get agreementForm() {
  //   return this.scheduleForm.get(this.AGREEMENT).value;
  // }
  // get dateFromForm() {
  //   return this.scheduleForm.get(this.DATE_FROM).value;
  // }
  // get dateToForm() {
  //   return this.scheduleForm.get(this.DATE_TO).value;
  // }
  // get timeFromForm() {
  //   return this.scheduleForm.get(this.TIME_FROM).value;
  // }
  // // get timeDurationForm() {
  // //   return this.scheduleForm.get(this.TIME_DURATION).value;
  // // }
  // get hourDurationForm() {
  //   return this.scheduleForm.get(this.HOUR_DURATION).value;
  // }
  // get minuteDurationForm() {
  //   return this.scheduleForm.get(this.MINUTE_DURATION).value;
  // }
  // get dateEndRepeatForm() {
  //   return this.scheduleForm.get(this.DATE_END_REPEAT).value;
  // }
  // get stateAttentionForm() {
  //   return this.scheduleForm.get(this.STATE_ATTENTION).value;
  // }

  // set providerForm(providerId: number) {
  //   providerId != null ? this.scheduleForm.controls[this.PROVIDER].setValue(providerId.toString())
  //     : this.scheduleForm.controls[this.PROVIDER].reset()
  // }
  // set patientForm(patientId: number) {
  //   patientId != null ? this.scheduleForm.controls[this.PATIENT].setValue(patientId.toString())
  //     : this.scheduleForm.controls[this.PATIENT].reset()

  // }
  // set agreementForm(agreementId: number) {
  //   agreementId != null ? this.scheduleForm.controls[this.AGREEMENT].setValue(agreementId.toString())
  //     : this.scheduleForm.controls[this.AGREEMENT].reset()
  // }
  // set dateFromForm(date: Date) {
  //   date != null ? this.scheduleForm.controls[this.DATE_FROM].setValue(date)
  //     : this.scheduleForm.controls[this.DATE_FROM].reset()
  // }
  // set dateToForm(date: Date) {
  //   date != null ? this.scheduleForm.controls[this.DATE_TO].setValue(date)
  //     : this.scheduleForm.controls[this.DATE_TO].reset()
  // }
  // set timeFromForm(hourAndMinute: string) {
  //   hourAndMinute != null ? this.scheduleForm.controls[this.TIME_FROM].setValue(hourAndMinute)
  //     : this.scheduleForm.controls[this.TIME_FROM].reset()
  // }
  // // set timeDurationForm(hourAndMinute: string) {
  // //   hourAndMinute != null ? this.scheduleForm.controls[this.TIME_DURATION].setValue(hourAndMinute)
  // //     : this.scheduleForm.controls[this.TIME_DURATION].reset()
  // // }
  // set hourDurationForm(hours: number) {
  //   hours != null ? this.scheduleForm.controls[this.HOUR_DURATION].setValue(hours)
  //     : this.scheduleForm.controls[this.HOUR_DURATION].reset()
  // }
  // set minuteDurationForm(minutes: number) {
  //   minutes != null ? this.scheduleForm.controls[this.MINUTE_DURATION].setValue(minutes)
  //     : this.scheduleForm.controls[this.MINUTE_DURATION].reset()
  // }
  // set dateEndRepeatForm(date: Date) {
  //   date != null ? this.scheduleForm.controls[this.DATE_END_REPEAT].setValue(date)
  //     : this.scheduleForm.controls[this.DATE_END_REPEAT].reset()
  // }
  // set stateAttentionForm(stateId: number) {
  //   stateId != null ? this.scheduleForm.controls[this.STATE_ATTENTION].setValue(stateId.toString())
  //     : this.scheduleForm.controls[this.STATE_ATTENTION].reset()
  // }

  // setDayChipsSelected() {
  //   this.dayChipsSelected.set('D', false);
  //   this.dayChipsSelected.set('L', false);
  //   this.dayChipsSelected.set('M', false);
  //   this.dayChipsSelected.set('X', false);
  //   this.dayChipsSelected.set('J', false);
  //   this.dayChipsSelected.set('V', false);
  //   this.dayChipsSelected.set('S', false);
  // }

  // // Retorna si el día del mat-calendar está en el array de los dates seleccionados (datesToPostSelected)
  // // Es llamado desde el html, en el mat-calendar
  // isSelectedDate = (date: moment.Moment) => {
  //   return this.datesToPostSelected.find(x => x.isSame(date, 'day')) ? "selected" : null;
  // };
  // // !Mutable
  // // Ordena un array de moments, de manera ascendente (mas vieja primero)
  // sortAscArrayDates(array: moment.Moment[]) {
  //   array.sort(function (a, b) {
  //     const a2 = a.toDate();
  //     const b2 = b.toDate();
  //     return a < b ? -1 : a > b ? 1 : 0;
  //   });
  // }

  // onChangeCheckRepeatSchedule(event) {
  //   this.isRepeatSchedule = event.checked;
  //   // event.checked ? this.dateEndRepeatForm = moment(this.dateFromForm).add('months', 1).date(0).toDate() : this.dateEndRepeatForm = null;
  //   event.checked ? this.dateEndRepeatForm = moment(this.dateFromForm).add('months', 1).date(0).toDate() : (this.setDayChipsSelected());
  // }

  // asIsOrder(a, b) {
  //   return 1;
  // }

  // isDayChipSelected(day) {
  //   return this.dayChipsSelected.get(day);
  // }

  // deleteDatesUntil(dateUntil) {
  //   console.log(dateUntil);
  //   dateUntil = moment(dateUntil);
  //   this.datesToPostSelected = this.datesToPostSelected.filter(adate => adate.isSameOrBefore(dateUntil));
  //   this.calendarToPost.updateTodaysDate();
  // }

  // createScheduleForm(): FormGroup {
  //   return this.formBuilder.group({
  //     [this.PROVIDER]: ['', [CustomValidators.required('Prestador requerido')]],
  //     [this.PROVIDER_SEARCHING]: [''],
  //     [this.PATIENT]: ['', [CustomValidators.required('Paciente requerido')]],
  //     [this.AGREEMENT]: ['', [CustomValidators.required('Acuerdo prestacional requerido')]],
  //     [this.DATE_FROM]: ['', [CustomValidators.required('Fecha requerida')]],
  //     [this.DATE_TO]: ['',],
  //     [this.TIME_FROM]: ['',],
  //     // [this.TIME_DURATION]: ['',],
  //     [this.HOUR_DURATION]: ['',],
  //     [this.MINUTE_DURATION]: ['',],
  //     [this.DATE_END_REPEAT]: [''],
  //     [this.STATE_ATTENTION]: ['', [CustomValidators.required('Estado requerido')]]
  //   }, {
  //     validators: [
  //       CustomValidators.CVFromToDate('La fecha de finalización debe ser superior a la de inicio', 'errCV', this.DATE_FROM, this.DATE_TO),
  //       CustomValidators.CVFromToDate('La fecha de finalización de la repetición debe ser mayor o igual a la fecha de finalización de la atención', 'errCVEnd', this.DATE_TO, this.DATE_END_REPEAT),
  //       CustomValidators.CVFromToDate('La fecha de finalización de la repetición debe ser mayor o igual a la fecha de inicio de la atención', 'errCVEnd', this.DATE_FROM, this.DATE_END_REPEAT)
  //     ]
  //   });
  // }

  // openDialog(title: string): void {

  //   this._dialogRef = this.dialog.open(DialogCalendarComponent, {
  //     minWidth: '70%',
  //     maxHeight: '95vh',
  //     data: { template: this.scheduleDialogTemplate, title },
  //   });

  //   this._dialogRef.afterClosed().subscribe((agreement: Agreement) => {
  //     //   this.agreementSelected = null;
  //     console.log('se cerro');
  //     this.onCleanAttentions();
  //     this.scheduleForm.reset();
  //     this.isRepeatSchedule = false
  //   });
  // }

  // onCleanAttentions() {
  //   this.datesToPostSelected = [];
  //   this.setDayChipsSelected();
  //   // Ordeno el array
  //   this.sortAscArrayDates(this.datesToPostSelected);
  //   // Actualizo el dateFromForm si es necesario 
  //   this.updateDateFromForm();
  //   console.log(this.scheduleForm);

  //   this.calendarToPost ? this.calendarToPost.updateTodaysDate() : null;
  // }

  // // Actualiza el valor del formulario "DateFromForm" en caso de que corresponda
  // updateDateFromForm() {
  //   //Si no hay fechas en el array o la fecha mas antigua del array es distinta a la del form modifico el form
  //   if (!this.datesToPostSelected[0] || new Date(this.datesToPostSelected[0].toDate()).getTime() != new Date(this.dateFromForm).getTime()) {
  //     this.dateFromForm = !!this.datesToPostSelected[0] ? this.datesToPostSelected[0].toDate() : null;
  //   }
  // }

  // onEventClickedHandler(attention: Attention) {
  //   this.activeAttention = attention;
  //   this.scheduleForm = this.createScheduleForm();
  //   // this.providerForm = agreement.provider.id;
  //   this.elementsProvidersDialog = [attention.provider];
  //   this.openDialog('Editar atención');
  //   setTimeout(() => {
  //     this.setAgreementToForm();
  //     this.onSelectAgreement(attention.agreementId);
  //   }, 0)
  // }

  // onSelectAgreement(agreementId: number) {
  //   this.agreementSelected = this.allAgreementsDialog.find(agreement => agreement.id == agreementId);
  //   if (!this.activeAttention) {
  //     setTimeout(() => {
  //       this.stateAttentionForm = this.ATTENTION_STATES.AGENDADA;
  //       if (this.agreementSelected.provision.practice.restriction.requiredToDate) {
  //         // this.dateToForm = moment(this.dateFromForm).add(1, 'hours').toDate();
  //         // this.timeDurationForm = '08:00'
  //         this.hourDurationForm = 2;
  //         this.minuteDurationForm = 0;
  //       }
  //     }, 0)
  //   }
  // }

  // setAgreementToForm() {
  //   this.allPatientsDialog = [this.activeAttention.patient];
  //   // this.allAgreementsDialog = this.agreementsSelected;
  //   console.log(this.case.provisions.flatMap(provision => provision.agreements));
  //   this.allAgreementsDialog = this.case.provisions.flatMap(provision => provision.agreements);
  //   this.providerForm = this.activeAttention.provider.id;
  //   this.patientForm = this.activeAttention.patient.id;
  //   this.agreementForm = this.activeAttention.agreementId;
  //   this.dateFromForm = this.activeAttention.fromDate;
  //   this.dateToForm = this.activeAttention.toDate;
  //   this.stateAttentionForm = this.activeAttention.state.id;
  //   console.log(this.scheduleForm);
  //   console.log(this.allAgreementsDialog);

  //   // this.cd.markForCheck();
  // }

  // onClickDeleteAttention(attention?: Attention) {
  //   if (attention) {
  //     this.activeAttention = attention;
  //   } else {
  //     attention = this.activeAttention;
  //   }

  //   Swal.fire({
  //     title: '¿Seguro desea eliminar la atención?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     reverseButtons: true,
  //     confirmButtonColor: '#d33',
  //     confirmButtonText: 'Eliminar',
  //     cancelButtonText: 'Cancelar',
  //     showLoaderOnConfirm: true,

  //     preConfirm: () => {
  //       return this.deleteAttention(attention)
  //         .then(attention => {
  //           console.log(attention);
  //           this.loadSchedule(this.agreementsFromPatient, this.globalRequestsParams.fromDate, this.globalRequestsParams.toDate, true)
  //           Swal.fire({
  //             title: `Atención eliminada correctamente`,
  //             icon: 'success'
  //           }).then(_ => this.onClickCloseDialog())
  //         })
  //         .catch(err => {
  //           console.log('error', err);
  //           this.onClickCloseDialog()
  //         })
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   })
  // }

  // deleteAttention(attention: Attention): Promise<Attention> {
  //   return new Promise((resolve, reject) => {
  //     this.attentionsService.deleteAttention(attention).subscribe(
  //       (attention) => resolve(attention),
  //       (error) => reject(error),
  //     );
  //   });
  // }

  // onClickCloseDialog() {
  //   this._dialogRef.close();
  //   this.scheduleForm.reset();
  // }

  // onEditAttention() {
  //   console.log('oneditattentions');
  //   this.loadingEditAttention = true;
  //   const attention: Attention = { id: this.activeAttention.id, fromDate: this.dateFromForm };
  //   !!this.dateToForm ? attention['toDate'] = this.dateToForm : null;
  //   this.attentionsService.patchAttention(attention).toPromise()
  //     .then(_ => this._dialogRef.close())
  //     .then(_ => {
  //       this.loadSchedule(this.agreementsFromPatient, this.globalRequestsParams.fromDate, this.globalRequestsParams.toDate, true)
  //       Swal.fire({
  //         title: 'Atención modificada con éxito',
  //         icon: 'success',
  //       })
  //     })
  //     .finally(() => this.loadingEditAttention = false)
  // }

  // loadSchedule(agreements: Agreement[], fromDate?: Date, toDate?: Date, loadingCalendar = false, loadingInnerCalendar = false) {
  //   if (loadingCalendar) { this.loadingCalendar = true };
  //   if (loadingInnerCalendar) { this.loadingInnerCalendar = true };
  //   of(agreements).pipe(
  //     map((agreementsInner: Agreement[]) => agreementsInner.map(agreement => agreement.id)),
  //     flatMap((agreementsIds: number[]) => this.attentionsService.getAttentionsByAgreements(agreementsIds, fromDate, toDate))
  //   ).subscribe((attentions: Attention[]) => { this.attentions = this.composeAttentions(attentions, this.agreementsFromPatient); this.loadingCalendar = false; this.loadingInnerCalendar = false }, () => { this.loadingCalendar = false; this.loadingInnerCalendar = false });
  // }

  // composeAttentions(attentions: Attention[], agreements: Agreement[]): Attention[] {
  //   const attentionsComposed = attentions.map(attention => {
  //     const agreement = agreements.find(agreement => agreement.id === attention.agreementId);
  //     if (agreement != null) {
  //       // attention.patient = agreement.provision.casee.patient;
  //       attention.patient = this.case.patient;
  //       attention.provider = agreement.provider;
  //     }
  //     return attention;
  //   });
  //   return attentionsComposed;
  // }
}