import { Component, OnInit, Input, ViewChild, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, Renderer2, TemplateRef, ElementRef, input, effect } from '@angular/core';
import * as moment from 'moment';
import { Attention } from 'src/app/shared/models/attention';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ROUTES } from 'src/app/core/enums/routes';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { AttentionsService } from 'src/app/core/services/attentions.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EvolutionsDialogComponent } from 'src/app/modules/cases/components/evolutions-dialog/evolutions-dialog.component';
import { EvolutionsQPS } from 'src/app/core/services/evolutions.service';
import { DialogEvolutionComponent } from 'src/app/modules/attentions/components/dialog-evolution/dialog-evolution.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AttentionsFacade, ATTENTIONS_BACKGROUND_COLORS, ATTENTIONS_COLORS } from 'src/app/abstraction/attentions.facade';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { AuditStatesAttention } from 'src/app/shared/models/auditStateAttention';
import { DialogAuditStatesAttentionComponent } from 'src/app/modules/attentions/components/dialog-audit-states-attention/dialog-audit-states-attention.component';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Provider } from 'src/app/shared/models/provider';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { Evolution } from 'src/app/shared/models/evolution';
import { AttentionToProcess } from '../../../../shared/models/attentionToProcess';
import { MatRadioButton } from '@angular/material/radio';
import { CaseDateFilters, CasesFacade } from '../../../../abstraction/cases.facade';
import { CalendarView } from 'angular-calendar';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { AlertService } from '../../../../core/services/alert.service';
import { Agreement } from 'src/app/shared/models/agreement';
import { GeneralService, ButtonsNewDateScheduleNavigator } from '../../../../core/services/general.service';
import { Case } from 'src/app/shared/models/case';
import { DateService } from '../../../../core/services/date.service';
import { Practice } from 'src/app/shared/models/practice';
import { OPTIONS } from '../provision-scheme/provision-scheme.component';
import { AttentionOverlap } from 'src/app/shared/models/attentionOverlap';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ViewManagementEntities } from 'src/app/core/enums/ViewManagementEntities';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';

@Component({
    selector: 'app-attentions-list-case',
    templateUrl: './attentions-list-case.component.html',
    styleUrls: ['./attentions-list-case.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AttentionsListCaseComponent implements OnInit {

    @Input() attentions: Attention[]; // It's filteredAttentions
    @Input() attentionsWithOverlap: AttentionOverlap[];
    @Input() clearSelection: boolean;
    @Input() title: string;
    @Input() loading: boolean = false;
    @Input() showMonth: boolean = true;
    @Input() showTotalQuantity: boolean = false;
    @Input() ruleClickable: (attention: Attention) => boolean;
    @Input() ruleEnabledButton3: (attention: Attention) => boolean;
    @Input() columnsToShow: string[];
    @Input() showGoToCase: boolean = true;
    @Input() textButton1: string;
    @Input() iconButton1: string;
    @Input() colorButton1: string = 'primary';
    @Input() colorIconButton1: string;
    @Input() infoTextButton1: string;
    @Input() colorButton2: string = 'primary';
    @Input() colorIconButton2: string;
    @Input() iconButton2: string;
    @Input() textButton2: string;
    @Input() textButton3: string;
    @Input() iconButton3: string;
    @Input() colorButton3: string = 'primary';
    @Input() colorIconButton3: string;
    @Input() textButton4: string;
    @Input() iconButton4: string;
    @Input() colorButton4: string = 'primary';
    @Input() colorIconButton4: string;
    @Input() textNoAttentions: string;
    @Input() scrollOn: boolean = true;
    @Input() paginatorOn: boolean = false;
    @Input() pageSize = 25;
    @Input() providers: Provider[];
    @Input() showProviderPracticeFilter: boolean = false;
    @Input() quickSearchActive: boolean = false;
    @Input() paginatorServerSide: boolean = false;
    @Input() keepSelection: boolean = false;
    @Input() evolutionList: boolean = false;
    @Input() noSort: boolean = false;
    @Input() showReload: boolean = false;
    @Input() supplyList: Attention[];

    @Input() descriptionStateShort: boolean = false;
    @Input() bottomTotals: boolean = false;
    @Input() optionSelected;
    @Input() processSuccessOnAttentions: boolean = false;

    @Input("provisionsFilteredCount") provisionsFilteredCount: number;
    @Input("provisionsSuppliesFilteredCount") provisionsSuppliesFilteredCount: number;
    @Input("ordersFilteredCount") ordersFilteredCount: number;

    // For delete batch
    @Input() provisionFees: ProvisionFee[];
    @Input("provisionAgreements") provisionAgreements: Agreement[];

    @Input() statesAttentionsSelectedInput = [];
    z
    @Input("selectedOption") selectedOption: OPTIONS;
    @Input("openDeleteBatch") openDeleteBatch: boolean;
    focusToElement = input<string>();

    @Output() onClickButton1 = new EventEmitter<Attention[]>();
    @Output() onClickButton2 = new EventEmitter<Attention[]>();
    @Output() onClickButton3 = new EventEmitter<Attention[]>();
    @Output() onClickButton4 = new EventEmitter<Attention[]>();
    // @Output() onChangeMonth = new EventEmitter<Date>();
    @Output() onClickReload = new EventEmitter();
    @Output() onUpdateClicked = new EventEmitter<Attention>();
    @Output() onDeleteClicked = new EventEmitter<Attention>();
    @Output() statesAttentionSelectedEmitter = new EventEmitter<number[]>();

    @Output() onClickCreateAttention = new EventEmitter<any>(); // Emitter to create attention
    @Output() option = new EventEmitter<string>();

    @ViewChild('scheduleFocus') matTable: ElementRef;

    lastAttentionId: number;
    lastAttentionStateId: number;
    attWithEvo: boolean;
    // Utilizado para mostrar el ng-template del tooltip de solapamiento
    /* isOpen: boolean;
     currentText: String;
     @ViewChild('originOverlay') originOverlay: CdkOverlayOrigin;*/

    view: CalendarView;

    attenttionsToBillDebit: AttentionToProcess[] = [];
    array = [];
    removed = [];
    attentionToEdit: Attention;

    currentCheckedValue = null;
    selectedAllState = "";

    // BOTTOM TOTALS
    totalsByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
    totalPricesByAttentionsState = new Array(Object.values(AttentionStates).length / 2).fill(0); // Create array with state totals with zero
    public enumStates = AttentionStates;
    totalAttentions: number = 0;
    totalHours: number = 0;

    checkBoxActive: boolean = true;
    routes = ROUTES;
    ATTENTIONS_STATES = AttentionStates;
    ATTENTIONS_COLORS = ATTENTIONS_COLORS;
    ATTENTIONS_BACKGROUND_COLORS = ATTENTIONS_BACKGROUND_COLORS;
    expandedElement: any;
    public actualDate = moment();
    ATTENTION_STATES = AttentionStates;
    public actualDateAttentions: Attention[];
    displayedColumns: string[] = [
        'arrow',
        'checkbox',
        'fromDate',
        'practice',
        'state',
        'actionsMenu'
    ];

    displayedColumnsSupply: string[] = [
        'checkbox',
        'fromDate',
        'supply',
        'quantity',
        'actionsMenu',
        'unit',
        'price',
        'provider',
        'state',
    ];

    permittedStates: AttentionStates[] = [
        this.ATTENTION_STATES.AGENDADA,
        this.ATTENTION_STATES.REALIZADA,
        this.ATTENTION_STATES.NO_REALIZADA,
        this.ATTENTION_STATES.FACTURABLE
    ]

    // Collections
    selectionToBill = new SelectionModel<Attention>(true, []);
    selectionToDebit = new SelectionModel<Attention>(true, []);

    dataSourceAttentions: MatTableDataSource<Attention>;
    dataSourceSupplies: MatTableDataSource<Attention>;
    selection = new SelectionModel<Attention>(true, []);
    statesSelected = new SelectionModel<number>(true, []);
    statesForShowBadgeWhenDiffHours = [
        AttentionStates.REALIZADA,
        AttentionStates.FACTURABLE
    ]

    @ViewChild('attentionsPaginator') paginator: MatPaginator;
    @ViewChild('suppliesPaginator') paginatorSupply: MatPaginator;
    @ViewChild('deleteWizardAttentions', { static: true }) deleteWizardAttentions: TemplateRef<any>;

    _dialogDeleteAttention;
    _dialogRef;

    supplySelection = new SelectionModel<Attention>(true, []);
    pageIndex = 0;
    EVOLUTIONS_TYPE = EvolutionsType;
    loadingEvolutions = false;
    filterForm: FormGroup;
    viewType: string = "units";
    public readonly PROVIDER = 'provider';
    public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly options: OPTIONS
    public readonly ORDER = OPTIONS.ORDER
    public readonly PROVISION = OPTIONS.PROVISION
    public readonly SUPPLY = OPTIONS.SUPPLY

    attetions$: Observable<Attention[]>;
    _attentions: Subscription;
    _evolutions: Subscription; evolutions$: Observable<Evolution[]>
    _case: Subscription;
    // _screenToJump:Subscription;

    showButtonBill: boolean = false;

    totalBill: number = 0;
    totalDebit: number = 0;

    // HISTORY MODE
    historyModeActivated: boolean;
    _historyModeActivated: Subscription;
    _historyModeDate: Subscription;
    historyModeDate: Date;

    _getOrders: Subscription;
    _getOrdersInvoicePhoto: Subscription;

    case: Case;

    rangeDate: CaseDateFilters;
    buttonsNewDateScheduleNavigator: ButtonsNewDateScheduleNavigator;
    dateLabel: string;
    _readyToFocus = false;
    public _viewManagementEntities = ViewManagementEntities;

    get readyToFocus() {
        return this._readyToFocus
    }
    set readyToFocus(value: boolean) {
        this._readyToFocus = value

    }

    get totalCost() {
        return this.actualDateAttentions?.filter(att => att?.practice?.name == 'Entrega insumos')?.map(att => att?.providerFee)?.reduce((a, b) => a + b);
    }

    constructor(
        public dialog: MatDialog,
        public entitiesFacade: EntitiesFacade,
        private attentionsFacade: AttentionsFacade,
        public cd: ChangeDetectorRef,
        private datePipe: DatePipe,
        public attentionService: AttentionsService,
        private _bottomSheet: MatBottomSheet,
        public fileUtilitiesService: FileUtilitiesService,
        private sanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private evolutionsFacade: EvolutionsFacade,
        private ren: Renderer2,
        private casesFacade: CasesFacade,
        private alertService: AlertService,
        private generalService: GeneralService,
        private dateService: DateService,
        // public breadcrumbService:BreadcrumbService
    ) {
        effect(() => {
            if (this.focusToElement() == ViewManagementEntities.ATTENTIONS_OLD_AND_OMITTED) {
                this.setScheduleTableFocus()
            }
            if (this.focusToElement() == ViewManagementEntities.ATTENTIONS_WITHOUT_BILLING || this.focusToElement() == ViewManagementEntities.SUPPLIES_NOT_BILLING) {
                this.setScheduleTableFocus()
            }
        }

        )
    }

    isChecked(row: any): boolean {
        const found = this.selection.selected.find(el => el.id === row.id);
        if (found) {
            return true;
        }
        return false;
    }

    isCheckedSupply(row: any): boolean {
        const found = this.supplySelection.selected.find(el => el.id === row.id);
        if (found) {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        this.view = CalendarView.Month;

        this._case = this.casesFacade.getCase$().subscribe(acase => {
            if (!!acase) {
                this.case = acase
            }
        })

        if (this.showProviderPracticeFilter) {
            this.calculateNameProvider = this.calculateNameProvider.bind(this);
            this.filterForm = this.createFilterForm();
        }
        this.selection.isSelected = this.isChecked.bind(this);
        this.supplySelection.isSelected = this.isCheckedSupply.bind(this);

        setTimeout(() => {
            if (!!this.paginatorSupply) {
                this.dataSourceSupplies.paginator = this.paginatorSupply;
            }
        });

        // this.onChangeMonth.emit(moment(this.actualDate).toDate());
        this.displayedColumns = !!this.columnsToShow ? this.displayedColumns = this.columnsToShow : this.displayedColumns;
        this.setFilterPredicate();

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.rangeDate = rangeDate;
                this.buttonsNewDateScheduleNavigator = this.generalService.controlCalendarView(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, this.view);
                this.actualDate = moment(this.buttonsNewDateScheduleNavigator.dateToSetCalendar);
                this.dateLabel = this.dateService.getSameFormatDateToDateRange(rangeDate.historyFromDate, rangeDate.historyToDate)
                this.setActualDateAttentions();
            }
        })

    }

    setScheduleTableFocus() {
        if (this.matTable) {
            try {
                this.matTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.matTable.nativeElement.focus();
            } catch (err) {
                console.log(err);

            }
        }
    }

    isLastRow(row: any): boolean {
        return this.dataSourceAttentions && this.dataSourceAttentions.data[this.dataSourceAttentions.data.length - 1] === row;
    }


    calculateTotalsByAttentionState() {
        // Always get totals
        // Exclude visit attentions
        this.totalsByAttentionsState.fill(0);
        this.totalPricesByAttentionsState.fill(0);
        this.totalAttentions = 0;
        this.totalHours = 0;
        this.actualDateAttentions.map(att => {
            this.totalsByAttentionsState[att.state.id] += 1;
            if (att.state.id == this.ATTENTIONS_STATES.AGENDADA && this.practiceCountedByHours(att.practice)) {
                this.totalPricesByAttentionsState[att.state.id] += (att.toScheduled && att.fromScheduled) ? moment.duration(moment(att.toScheduled).diff(att.fromScheduled)).hours() * att.provisionFee : att.provisionFee // Attention by hours
            } else {
                this.totalPricesByAttentionsState[att.state.id] = this.practiceCountedByHours(att.practice) || (att.isSupply && att.practice?.name != "Entrega insumos") ?
                    this.totalPricesByAttentionsState[att.state.id] += (att.provisionFee * (att.quantity != null ? att.quantity : 1))
                    :
                    this.totalPricesByAttentionsState[att.state.id] += att.provisionFee;
                this.totalHours += !!att.quantity && (att.practice?.restriction?.requiredTime || att.practice?.restriction?.requiredToDate) && !this.statesSelected?.selected?.includes(att?.state?.id) ? att?.quantity : 0;
            }

            if (!this.statesSelected.selected.includes(att.state.id)) {
                this.totalAttentions += 1;
            }

        });
    }

    ngOnChanges(changes: SimpleChanges): void {


        this.dataSourceSupplies = new MatTableDataSource<Attention>(this.supplyList); // TODO: maybe needs same control that this.dataSourceAttentions when it exists

        if (!!changes['attentions'] && !!changes['attentions'].currentValue) {
            if (!this.dataSourceAttentions) {
                this.dataSourceAttentions = new MatTableDataSource([]); // Only if not exists else the filter is lost
            }
            this.setActualDateAttentions();
        }

        if (!!changes['clearSelection'] && changes['clearSelection'].currentValue) {
            this.selection.clear();
        }

        this.displayedColumns = !!this.columnsToShow ? this.displayedColumns = this.columnsToShow : this.displayedColumns;

        if (!!changes['columnsToShow']) {
            this.showButtonBill = changes['columnsToShow'].currentValue.findIndex(col => col == 'bill-debit') != -1; // Control, if exist columnsToShow -> validate if column 'bill' is included
            if (this.showButtonBill) {
                this.resetBillDebit();
            }
        }

        if (!!changes['processSuccessOnAttentions']) {
            if (this.processSuccessOnAttentions == undefined) {
                this.processSuccessOnAttentions = false;
            }
            this.resetBillDebit();
        }

        if (!!changes['openDeleteBatch']) {
            this.deleteAttentions()
        }
    }

    ngOnDestroy(): void {

        // if(!!this._screenToJump){
        //     this._screenToJump.unsubscribe()
        // }

        if (!!this._evolutions)
            this._evolutions.unsubscribe();
        this._historyModeDate.unsubscribe();

        if (!!this._getOrders) {
            this._getOrders.unsubscribe();
        }
        if (!!this._getOrdersInvoicePhoto) {
            this._getOrdersInvoicePhoto.unsubscribe();
        }
    }

    createFilterForm(): FormGroup {
        return this.formBuilder.group({
            [this.PROVIDER]: [''],
            [this.PROVIDER_SEARCHING]: [''],
        });
    }

    // THIS PREDICATE FILTER WITH PATIENT NAME AND SURNAME/PROVIDER NAME AND SURNAME/ FINANCIER NAME
    setFilterPredicate() {
        if (!!this.dataSourceAttentions) {
            this.dataSourceAttentions.filterPredicate = (data: Attention, filter) => {
                const patientNameAndSurname =
                    data.patient.name.toLowerCase() + ' ' + data.patient.surname.toLowerCase();
                const providerNameAndSurname =
                    data.provider.name.toLowerCase() + ' ' + data.provider.surname.toLowerCase();
                const financierName =
                    data.financier.name.toLowerCase();
                return (
                    patientNameAndSurname.indexOf(filter) != -1 ||
                    providerNameAndSurname.indexOf(filter) != -1 ||
                    financierName.indexOf(filter) != -1
                );
            };
        }
    }

    onClickEvolutions(attention: Attention) {
        this.loadingEvolutions = true;
        this.getEvolutionsByAttention(attention.id)
            .then((evolutions) => { this.openBottomSheet(evolutions, [attention]); })
            .then(_ => this.loadingEvolutions = false)
            .catch(err => this.loadingEvolutions = false);
    }


    onClickStateAttention(attention) {
        attention.isLoadingStates = true;
        this.attentionsFacade.loadAuditStatesAttention(attention.id).pipe(

            map(a => ({ ...a, lastStateId: attention.state.id })), // Add id lastState
            map(a => ({ ...a, caseId: attention.caseId })), // Add id caseId
            tap(a => console.log("Este es el tracking de la attention", a)))

            .subscribe(a => { this.openAuditStatesAttentionDialog(a); },
                () => attention.isLoadingStates = false,
                () => attention.isLoadingStates = false);
    }

    openAuditStatesAttentionDialog(auditStatesAttention: AuditStatesAttention): void {
        const dialogRef = this.dialog.open(DialogAuditStatesAttentionComponent, {
            width: '65%',
            disableClose: true, // Don't close with outside click
            data: { auditStatesAttention },
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.onClickReload.emit(true);
        });
    }

    getEvolutionsByAttention(attentionId): Promise<any> {
        return new Promise((resolve, reject) => {
            let qps: EvolutionsQPS = { attentionId };
            this.evolutions$ = this.evolutionsFacade.loadEvolutionsByQPS(qps, false)
            this._evolutions = this.evolutions$.subscribe(
                (evolutions) => {
                    resolve(evolutions);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    getTotalQuantity() {
        return this.attentions.filter(
            attention => [
                this.ATTENTIONS_STATES.REALIZADA,
                this.ATTENTIONS_STATES.NO_INFORMADA,
                this.ATTENTIONS_STATES.FACTURABLE,
                this.ATTENTIONS_STATES.EN_PROCESO,
                this.ATTENTIONS_STATES.INFORMADA
            ]
                .includes(attention.state.id))
            .map(attention => attention.quantity)
            .reduce((acc, value) => acc + value, 0);
    }

    getTotalQuantityDebited() {
        return this.attentions
            .filter(attention => attention.state.id == this.ATTENTIONS_STATES.DEBITADA)
            .map(attention => attention.quantity)
            .reduce((acc, value) => acc + value, 0);
    }

    getTotalPrice() {
        return this.supplyList.filter(
            supply => [
                this.ATTENTIONS_STATES.REALIZADA,
                this.ATTENTIONS_STATES.NO_INFORMADA,
                this.ATTENTIONS_STATES.FACTURABLE,
                this.ATTENTIONS_STATES.EN_PROCESO,
                this.ATTENTIONS_STATES.INFORMADA,
            ]
                .includes(supply.state.id))
            .map(supply => supply.providerFee) // TIENE QUE SER PROVISION FEE!!!
            // .map(supply => supply.provisionFee)
            .reduce((acc, value) => acc + value, 0);
    }

    getTotalPriceDebited() {
        return this.supplyList
            .filter(supply => this.ATTENTIONS_STATES.DEBITADA == supply.state.id)
            .map(supply => supply.provisionFee)
            .reduce((acc, value) => acc + value, 0);
    }

    openBottomSheet(evolutions, attentions) {

        this._bottomSheet.open(EvolutionsDialogComponent, {
            panelClass: 'bottomSheetPanelClass',
            data: {
                evolutions,
                name: attentions[0].patient.name,
                surname: attentions[0].patient.surname,
                attentions: attentions
            },
        });
    }

    setActualDateAttentions() {

        if (!this.paginatorServerSide) {
            this.selection.clear();
        }
        if (this.showMonth) {
            this.actualDateAttentions = this.attentions?.filter(attention =>
                moment(attention.fromDate).month() == moment(this.actualDate).month()
                && moment(attention.fromDate).year() == moment(this.actualDate).year()
            );
        } else {
            if (!!this.rangeDate) {
                this.actualDateAttentions = this.attentions.filter(att => moment(att.fromDate).isBetween(this.rangeDate.historyFromDate, this.rangeDate.historyToDate, undefined, '[]'))
            } else {
                this.actualDateAttentions = this.attentions;
            }
        }
        if (!this.noSort) {
            if (!!this.actualDateAttentions) {
                this.actualDateAttentions.sort((attA, attB) => {
                    if (!!attA.provider && !!attB.provider) {
                        const dateA = attA.fromDate;
                        const dateB = attB.fromDate;
                        const providerA = this.entitiesFacade.getProvidersFullName(attA.provider);
                        const providerB = this.entitiesFacade.getProvidersFullName(attB.provider);
                        return (dateA < dateB ? -1 : dateA > dateB ? 1 : 0) || (providerA < providerB ? -1 : providerA > providerB ? 1 : 0);
                    }
                    return undefined
                });
            }
        }
        //"If we want to modify these filters, we also need to do so in Case Indicators to get the same results."
        //PATCH ATTENTIONS DATASOURCE -->  CASE INDICATORS PAINTED
        if (!!this.actualDateAttentions) {
            this.actualDateAttentions.forEach(attention => {
                switch (true) {
                    case attention.state.id === AttentionStates.REALIZADA && attention.practice.name !== "Entrega insumos" && attention.isSupply:
                        attention.paintedType = ViewManagementEntities.SUPPLIES_NOT_BILLING;
                        break;
                    case attention.state.id === AttentionStates.REALIZADA && attention.practice.name !== "Entrega insumos" && !attention.isSupply:
                        attention.paintedType = ViewManagementEntities.ATTENTIONS_WITHOUT_BILLING;
                        break;
                    case (attention.state.id === AttentionStates.AGENDADA || attention.state.id === AttentionStates.EN_CURSO) && attention.practice.name !== "Entrega insumos" && !attention.isSupply && moment(attention.fromDate).isBefore(moment().startOf('day')):
                        attention.paintedType = ViewManagementEntities.ATTENTIONS_OLD_AND_OMITTED;
                        break;
                    case attention.state.id === AttentionStates.FACTURABLE:
                        attention.paintedType = ViewManagementEntities.BILLING_ATTENTIONS;
                        break;
                    default:
                        //none
                        break;
                }
            });
        }
        this.dataSourceAttentions.data = this.actualDateAttentions;
        this.filterAttentionsByState(); // Filter and get totals -> always
        this.setDateText()
        setTimeout(() => {
            if (this.paginatorOn) {
                this.dataSourceAttentions.paginator = this.paginator;
            }
            this.setFilterPredicate();
        });
    }

    getNameDate(): string {
        return this.actualDate.format('MMMM YYYY');
    }

    onLocalClickButton4() {
        this.onClickButton4.emit(this.attenttionsToBillDebit) // Emit to schedule-case -> execute onClicProcessBillAndDebit()
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const actualPageAttentions = this.dataSourceAttentions._pageData(this.dataSourceAttentions.filteredData);
        const numSelectedOnActualPage = this.selection.selected
            .filter(selected => actualPageAttentions.some(attention => attention.id == selected.id)).length;
        const numSelectablesOnActualPage = this.dataSourceAttentions
            ._pageData(this.dataSourceAttentions.filteredData)
            .filter(attention => this.ruleClickable(attention)).length;
        return numSelectedOnActualPage === numSelectablesOnActualPage;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            if (!!this.dataSourceAttentions.filteredData && this.dataSourceAttentions.filteredData.length > 0) {
                this.dataSourceAttentions
                    ._pageData(this.dataSourceAttentions.filteredData)
                    .filter(attention => this.ruleClickable(attention))
                    .forEach(row => this.selection.select(row));
            }
        }
    }

    isAllSupplySelected() {
        return this.supplySelection.selected.length == this.supplyList.length;
    }

    setDateText() {
        // Set text to "fromDate" column
        this.dataSourceAttentions.data = this.actualDateAttentions.filter(att => !this.statesSelected?.selected?.includes(att.state.id)).map((att, index, attentions) => {
            if (index == 0 || moment(att.fromDate).format('YYYY-MM-DD') != moment(attentions[index - 1].fromDate).format('YYYY-MM-DD')) {
                return { ...att, textDate: `${this.dateService.getDayFirsLetterUpper(att.fromDate)} ${this.dateService.getDayMonth(att.fromDate)}` }
            } else {
                return { ...att }
            }
        })
        this.dataSourceAttentions.data = this.calculateHoursForEachDay(this.dataSourceAttentions.data)
    }

    masterToggleSupply() {
        if (this.isAllSupplySelected()) {
            this.supplySelection.clear();
        } else {
            this.supplyList
                .filter(supply => this.ruleClickable(supply))
                .forEach(row => this.supplySelection.select(row));
        }
    }

    isEnabledButton3() {
        let response = false;
        this.dataSourceAttentions.data.forEach(attention => {
            if (this.ruleEnabledButton3(attention)) {
                response = true;
                return false;
            }
        });
        return response;
    }

    matTooltipText(attention: Attention): string {
        let string = '';
        !!attention.fromDate
            ? (string +=
                'Ingreso agendado: ' +
                this.datePipe.transform(attention.fromDate, 'yyyy-MM-dd HH:mm') +
                '\n')
            : null;
        !!attention.toDate
            ? (string +=
                'Egreso agendado: ' +
                this.datePipe.transform(attention.toDate, 'yyyy-MM-dd HH:mm') +
                '\n')
            : null;
        !!attention.checkIn
            ? (string +=
                'Check in: ' + this.datePipe.transform(attention.checkIn, 'yyyy-MM-dd HH:mm') + '\n')
            : null;
        !!attention.checkOut
            ? (string +=
                'Check out: ' + this.datePipe.transform(attention.checkOut, 'yyyy-MM-dd HH:mm') + '\n')
            : null;
        return string;
    }

    openDialog(attention: Attention): void {

        const dialogRef = this.dialog.open(DialogEvolutionComponent, { maxHeight: '95vh', minWidth: '60%', data: { attention } });

        dialogRef.afterClosed().subscribe((attention: Attention) => {
            this.lastAttentionStateId = this.lastAttentionStateId === attention.state.id ? -1 : attention.state.id;
        });
    }

    stopPropagation(e: Event) {
        e.cancelBubble = true;
        if (e.stopPropagation) { e.stopPropagation(); }
        e.stopPropagation();
    }

    applyFilter(filterValue: any) {
        this.selection.clear();
        // filterValue.length > 0 ? this.checkBoxActive = false : this.checkBoxActive = true;
        this.dataSourceAttentions.filter = filterValue.trim().toLowerCase();
    }

    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    onSelectedProvider(providerId: number) {
        this.dataSourceAttentions.data = this.attentions.filter(attention => !!attention.provider && attention.provider.id == providerId);
    }

    // OnDestroy() {
    //     this._screenToJump.unsubscribe();
    // 	if (!!this._attentions) {
    // 		this._attentions.unsubscribe();
    // 	}

    // 	if (!!this._case) {
    // 		this._case.unsubscribe()
    // 	}
    // }

    checkState(attention: AttentionToProcess, el?: MatRadioButton, setBillorDebit?: string) {

        let attentionDeleted: boolean = false;

        // Uncheck radiobutton if exist in array and it's the same radiobutton fired
        setTimeout(() => {

            const atte = this.attenttionsToBillDebit.find(att => att.id === attention.id);

            if (!!atte) {

                this.currentCheckedValue = atte.bill ? 'toBill' : atte.debit ? 'toDebit' : '';

                if (this.currentCheckedValue && this.currentCheckedValue === el.value) {

                    if (!!el) {
                        el.checked = false;
                        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
                        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
                    }
                    this.currentCheckedValue = null;
                    this.attenttionsToBillDebit.splice(this.attenttionsToBillDebit.findIndex(a => a.id === atte.id), 1);
                    attentionDeleted = true;
                }
            }
        })

        // It's needed because the radiobutton can't be unchecked before
        setTimeout(() => {

            if (!attentionDeleted) {

                let att;
                let value;

                if (!!el) {
                    value = el.value
                } else {
                    value = setBillorDebit;
                }

                if (value == 'toBill') {
                    att = { ...attention, bill: true, debit: false };
                }

                if (value == 'toDebit') {
                    att = { ...attention, bill: false, debit: true };
                }

                let index = this.attenttionsToBillDebit.findIndex(f => f.id === attention.id);
                if (index != -1) {
                    this.attenttionsToBillDebit.splice(index, 1)
                }
                this.attenttionsToBillDebit.push(att)
                console.log("Attentions selected to bill or debit: ", this.attenttionsToBillDebit);

                this.calculateTotalBillorDebit();
            }
            // TOTALS
            this.calculateTotalBillorDebit();
        }, 200)
    }

    calculateTotalBillorDebit() {
        this.totalBill = this.attenttionsToBillDebit.filter(att => att.bill).length;
        this.totalDebit = this.attenttionsToBillDebit.filter(att => att.debit).length;

    }

    onClickUpdate(attention: Attention) {
        this.onUpdateClicked.emit({ ...attention });
    }

    onClickDelete(attention: Attention) {
        this.onDeleteClicked.emit(attention)
    }

    calculateRowspan(attention) {
        return this.dataSourceAttentions.data.filter(att => att.fromDate == attention.fromDate).length;
    }

    selectOption(option) {
        this.option.emit(option);
    }

    resetBillDebit() {

        console.log("resetBillDebit");
        this.attenttionsToBillDebit = []; // Used for mode bill or debit
        this.totalBill = 0;
        this.totalDebit = 0;
    }

    // onClickToday() {
    //   this.actualDate = moment();
    //   this.setActualDateAttentions();
    //   this.onChangeMonth.emit(moment(this.actualDate).toDate());
    //   this.resetBillDebit();
    //   this.statesSelected.clear();
    //   this.statesAttentionSelectedEmitter.emit(this.statesSelected.selected);
    // }

    onChangeToggle(event) {
        if (!event.checked) {
            this.viewType = "units";
        } else {
            this.viewType = "price";
        }
    }

    selectState(id: number) {
        this.statesSelected.toggle(id);
        this.filterAttentionsByState();
        this.setDateText();
    }

    removeFilters() {
        this.statesSelected.clear();
        this.setActualDateAttentions();
    }

    filterAttentionsByState() {
        if (!!this.statesSelected.selected) {
            this.dataSourceAttentions.data = this.actualDateAttentions.filter(att => !this.statesSelected.selected.includes(att.state.id));
            this.attenttionsToBillDebit = this.attenttionsToBillDebit.filter(att => !this.statesSelected.selected.includes(att.state.id));
        }
        this.calculateTotalBillorDebit();
        this.calculateTotalsByAttentionState();
        this.statesAttentionSelectedEmitter.emit(!!this.statesSelected.selected ? this.statesSelected.selected : []);
    }

    showImport(totalByState: number, stateId: number) {
        let show: boolean = false;
        if (totalByState != 0 || this.totalsByAttentionsState[stateId] != 0) {
            show = true;
        }
        return show;
    }

    createAttention() {

        const configCreateAttention = {
            view: CalendarView.Month,
            date: this.actualDate.toDate()
        }

        this.onClickCreateAttention.emit(configCreateAttention);
    }

    getColor(element) {
        return this.ATTENTIONS_COLORS[element];
    }

    getBackgroundColor(element) {
        if (!this.statesSelected.selected.includes(element)) {
            return this.ATTENTIONS_BACKGROUND_COLORS[element];
        } else {
            return "white";
        }
    }

    getBorderStyle(element) {
        if (!this.statesSelected.selected.includes(element)) {
            return "none";
        } else {
            return "1px solid " + this.ATTENTIONS_BACKGROUND_COLORS[element];
        }
    }

    selectAll(action: string) {

        // First time is checked use this.actualDateAttentions / WATCH: boolean el.checked is true or false negative
        if (this.selectedAllState === "") {
            this.attenttionsToBillDebit = this.actualDateAttentions.map(a => ({ ...a, bill: false, debit: false }));
        }

        // Exclude bill or debit as appropriate - Quitar bill o debit según corresponda
        this.attenttionsToBillDebit = this.attenttionsToBillDebit.filter(a => this.isValidToActionkAttentionState(a, action === 'toBill' ? this.enumStates.FACTURABLE : action === 'toDebit' ? this.enumStates.DEBITADA : null) && !this.statesSelected.selected.includes(a.state.id))

        if (action === "toBill" && this.selectedAllState === "") {

            this.attenttionsToBillDebit = this.attenttionsToBillDebit.map(att => ({ ...att, bill: true }));

            this.attenttionsToBillDebit.map(att =>
                this.checkState(att, null, action)
            )

            this.selectedAllState = "touchedBill";

        } else if (action === "toBill" && this.selectedAllState === "touchedBill") {
            this.attenttionsToBillDebit = this.attenttionsToBillDebit.map(att => ({ ...att, bill: false }));
            this.attenttionsToBillDebit.map(att =>
                this.checkState(att, null, "")
            );
            this.selectedAllState = "";

        } else if (action === "toDebit" && this.selectedAllState === "") {
            this.attenttionsToBillDebit = this.attenttionsToBillDebit.map(att => ({ ...att, debit: true }));
            this.attenttionsToBillDebit.map(att =>
                this.checkState(att, null, action)
            )

            this.selectedAllState = "touchedDebit";
        } else if (action === "toDebit" && this.selectedAllState === "touchedDebit") {
            this.attenttionsToBillDebit = this.attenttionsToBillDebit.map(att => ({ ...att, debit: false }));
            this.attenttionsToBillDebit.map(att =>
                this.checkState(att, null, "")
            );
            this.selectedAllState = "";
        } else if ((action === "toBill" && this.selectedAllState === "touchedDebit") || (action === "toDebit" && this.selectedAllState === "touchedBill")) {
            this.selectedAllState = ""
            this.selectAll(action)
        }

        this.calculateTotalBillorDebit();
        setTimeout(() => {
            this.resetAttenttionsToBillDebit();
        }, 200)

    }

    resetAttenttionsToBillDebit() {
        if (this.totalBill === 0 && this.totalDebit === 0) {
            this.attenttionsToBillDebit = [];
        };
    };

    validateCheckedBill(att: Attention) {
        return !!this.attenttionsToBillDebit.find(a => a?.id === att.id && a.bill) ? true : false;
    }

    validateCheckedDebit(att: Attention) {
        return !!this.attenttionsToBillDebit.find(a => a?.id === att.id && a.debit) ? true : false;
    }

    isValidToActionkAttentionState(att: Attention, exclude: AttentionStates): boolean {
        return att.state.id != this.enumStates.INFORMADA && att.state.id != this.enumStates.AGENDADA && att.state.id != exclude;
    }

    deleteAttentions() {

        switch (true) {
            case this.provisionsSuppliesFilteredCount > 0:
                this.alertService.openError('Para continuar debe quitar los insumos seleccionados')
                break;

            case this.ordersFilteredCount > 0:
                this.alertService.openError('Para continuar debe quitar los pedidos seleccionados')
                break

            case this.provisionAgreements.length == 0:
                this.alertService.openError('Debe seleccionar al menos un acuerdo prestacional');
                break

            case this.provisionsFilteredCount > 0:
                this._dialogRef = this.dialog.open(DialogComponent, {
                    disableClose: true,
                    minWidth: '30%',
                    maxHeight: '90vh',
                    data: { template: this.deleteWizardAttentions, title: 'Eliminar' }
                });
                break

            default:
                this.alertService.openInfo('Debe seleccionar al menos una prestación')
                break;
        }
    }

    clickCancelDeleteWizard() {
        this._dialogRef.close();
    }

    practiceCountedByHours(practice: Practice) {
        return (!!practice?.restriction?.requiredTime || !!practice?.restriction?.requiredToDate);
    }

    validateHourDifference(attention: Attention) {
        if (this.practiceCountedByHours(attention.practice)) {
            return moment(attention.toDate).diff(attention.fromDate, "hours") != attention.quantity;
        }
    }

    calculateHoursForEachDay(attentions: Attention[]): Attention[] {
        let hoursByDay: { [key: string]: number } = {};

        // Calculates the hours worked per day and stores them in an object
        attentions.forEach(attention => {
            const start = moment(attention.fromDate);
            const end = moment(attention.toDate);
            const startDateKey = start.format('YYYY-MM-DD');
            const endDateKey = end.format('YYYY-MM-DD');

            if (this.practiceCountedByHours(attention.practice)) {
                // If the attention ends on the same day
                // if (startDateKey === endDateKey) {
                const hours = end.diff(start, 'hours', true);
                hoursByDay[startDateKey] = (hoursByDay[startDateKey] || 0) + hours;
                /* } else {
                  // If ends the next day
                  const endOfStartDay = start.clone().endOf('day');
                  const startOfEndDay = end.clone().startOf('day');

                  const hoursDay1 = endOfStartDay.diff(start, 'hours', true);
                  const hoursDay2 = end.diff(startOfEndDay, 'hours', true);

                  hoursByDay[startDateKey] = (hoursByDay[startDateKey] || 0) + hoursDay1;
                  hoursByDay[endDateKey] = (hoursByDay[endDateKey] || 0) + hoursDay2;
                } */
            }
        });

        // Assigns the total sum of hours to the first attention of each day
        return attentions.map((attention, index, arr) => {
            const start = moment(attention.fromDate);
            const dayKey = start.format('YYYY-MM-DD');
            if (index === 0 || moment(arr[index - 1].fromDate).format('YYYY-MM-DD') !== dayKey) {
                return { ...attention, totalHours: Math.round(hoursByDay[dayKey]) };
            }
            return { ...attention };
        });
    }

    overlapAdviceText(attention) {
        let overlapedAttention: AttentionOverlap;
        overlapedAttention = this.attentionsWithOverlap.find(att => att.id == attention.id && att.hasOverlap ? att : null)
        const text = this.attentionService.overlapText(overlapedAttention);
        /* this.currentText = text; // Almacenar los datos actuales
        this.isOpen = true;*/
        return text;
    }
    // TODO: mejorar tooltip Solapamiento
    /* cdkOverlayExist(text) {
       this.isOpen = !!text? true : false;
     }

     onMouseOver(text: any) {
       this.currentText = text; // Almacenar los datos actuales
       this.isOpen
       // Aquí puedes llamar a cualquier otra función que necesites
     }

     onMouseOut() {
       // Limpiar los datos actuales o realizar otras acciones al salir del mouse
       this.currentText = null;
     }*/

}
