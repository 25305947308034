import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ROUTES } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { NomenclatorFacade } from 'src/app/abstraction/nomenclator.facade';
import { ProvisionsUnit } from 'src/app/core/enums/provisionsUnit';
import { SUPPLY_SPECIALTY_ID } from 'src/app/core/enums/supplySpecialtyId';
import { PostOrderBody } from 'src/app/core/services/cases.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Authorization } from 'src/app/shared/models/authorization';
import { Case } from 'src/app/shared/models/case';
import { Practice } from 'src/app/shared/models/practice';
import { Provision } from 'src/app/shared/models/provision';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import Swal from 'sweetalert2';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Agreement } from '../../../../shared/models/agreement';
import { ProvisionAgreementFilters } from 'src/app/shared/models/ProvisionAgreementFilters';
import { ProvisionsFacade, AUTHORIZATION_COLORS, AUTHORIZATION_BACKGROUND_COLORS } from 'src/app/abstraction/provisions.facade';
import { Order } from 'src/app/shared/models/order';
import { Attention } from 'src/app/shared/models/attention';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import mime from 'mime';
import { MedicalSupply } from 'src/app/shared/models/medicalSupply';
import { BudgetBody, PatchProvisionFeeBody, QuoteBody } from 'src/app/core/services/provisions.service';
import { OrdersStates } from 'src/app/core/enums/ordersStates';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GeneralService } from 'src/app/core/services/general.service';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { EvolutionsDialogComponent } from '../evolutions-dialog/evolutions-dialog.component';
import { EvolutionsFacade } from 'src/app/abstraction/evolutions.facade';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as momentRange from 'moment-range';
import { MatMenuTrigger } from '@angular/material/menu';
import { Provider } from 'src/app/shared/models/provider';
import { FilePondComponent } from 'ngx-filepond';

enum OPTION_SUPPLY {
    ACTIVE,
    INACTIVE
}

interface _Provision extends Provision {
    actualProvisionFee?: ProvisionFee;
    nextProvisionFee?: ProvisionFee;
}

interface _NewDates {
    newFromDate: Date,
    newToDate: Date
}

@Component({
    selector: 'app-supply-list',
    templateUrl: './supply-list.component.html',
    styleUrls: ['./supply-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', opacity: '0' })),
            state('expanded', style({ height: '*', opacity: '100' })),
            transition('expanded <=> collapsed', animate('250ms ease-in')), //cubic-bezier(0.4, 0.0, 0.2, 1)
            transition('expanded <=> void', animate('250ms ease-in')),
        ]),
    ],
})
export class SupplyListComponent implements OnInit {
    OPTION_SUPPLY = OPTION_SUPPLY;
    ORDERS_STATES = OrdersStates;
    selectedOptionSupply = 'active';
    expandedDisposables: boolean = true;
    expandedMedicines: boolean = true;
    expandedEquipments: boolean = true;
    expandedOthers: boolean = true;
    toDay: Date = moment(new Date(), 'YYYY-MM-DD').toDate();
    buttonsObserver: IntersectionObserver;
    resizeObserver: ResizeObserver;
    kairosModifiedExternally: boolean = false;

    @ViewChild('scrollElem') private scrollElem: ElementRef;

    // File authorization loader
    @ViewChild('myPond') myPond: FilePondComponent;

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Adjunte aquí o arrastre la autorización',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 100
    };

    photoEvolutionB64: string = null;
    loadingAddFile: boolean = false;

    @Input() case: Case;
    @Input() isCollapsed: boolean = false;
    @Input() isBilling;
    @Input() provisionsFeeIdsToModifySupplies: number[];
    @Input() allProvisionsWithoutFilter: Provision[];

    @Output() billSupplyAttentions = new EventEmitter<number[]>();

    @ViewChild('addSupplyTemplate', { static: true }) addSupplyTemplate: TemplateRef<any>;
    @ViewChild('addProvisionFeeTemplate', { static: true }) addProvisionFeeTemplate: TemplateRef<any>;

    @ViewChild('updateProvisionFeeTemplate', { static: true })
    updateProvisionFeeTemplate: TemplateRef<any>;

    @ViewChild('updateAuthorizationTemplate', { static: true })
    updateAuthorizationTemplate: TemplateRef<any>;

    @ViewChild('addOrderTemplate', { static: true }) addOrderTemplate: TemplateRef<any>;
    @ViewChild('popUpMessage', { static: true }) popUpMessage: TemplateRef<any>;
    @ViewChild('exportBudget', { read: MatMenuTrigger }) showBudgetMenu: MatMenuTrigger;
    @ViewChild('exportQuote', { read: MatMenuTrigger }) showQuoteMenu: MatMenuTrigger;


    formSupplies: FormGroup;
    dates: FormGroup;
    public readonly SUPPLIES = 'supplies';

    suppliesOnSelect = [[]];
    actualSupplies = [null];
    providersOnSelect = [[]];
    actualProviders = [null];
    index: number = 0;
    message: string;

    arrayTable = [];
    displayedColumns: string[] = ['supplyName', 'quantity', 'provider'];
    txtButtonCancel = '';
    showButtonACEPTAR = false;
    showFabButton: boolean = false
    selectedSupplies: ProvisionFee[];
    showSpinner: boolean = false;
    AUTHORIZATION_COLORS = AUTHORIZATION_COLORS;
    AUTHORIZATION_BACKGROUND_COLORS = AUTHORIZATION_BACKGROUND_COLORS;
    reactiveWidth: number;
    _observer;

    get supplies() {
        return this.formSupplies?.get('supplies') as FormArray;
    }

    // Array data
    public readonly PRACTICE = 'practiceId';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly PROVIDER = 'providerId';
    public readonly PROVIDER_SEARCHING = 'providerSearching';
    public readonly ONE_PROVIDER = 'oneProviderId';
    public readonly ONE_PROVIDER_SEARCHING = 'oneProviderSearching';
    public readonly AMOUNT = 'amount';
    public readonly UNIT = 'unit';
    public readonly PROVIDER_FEE = 'providerFee';
    public readonly PROFIT_MARGIN = 'profitMargin';
    public readonly FEE = 'fee';
    public readonly KAIROS = 'fixedPrice';
    // Provision fromDate - toDate
    public readonly FROM_DATE = 'fromDate';
    public readonly TO_DATE = 'toDate';
    // Auth
    public readonly IS_ADDING_AUTHORIZATION = 'is_adding_authorization';
    public readonly AUTHORIZATION_NUMBER = 'authorizationNumber';
    public readonly AUTHORIZATION_OBSERVATIONS = 'observations';
    public readonly AUTHORIZATION_FROM_DATE = 'fromDate';
    public readonly AUTHORIZATION_TO_DATE = 'toDate';
    // Checkboxes
    public readonly EXPORT_QUOTE = 'exportQuote'; // Exportar cotización
    public readonly EXPORT_BUDGET = 'exportBudget'; // Exportar presupuesto
    // From and to date by supply
    public readonly DATES = 'dates'
    public readonly FROM_DATE_BY_SUPPLY = 'fromDateBySupply' // From date supply
    public readonly TO_DATE_BY_SUPPLY = 'toDateBySupply' // To date supply
    public readonly SUPPLY_ORDER_DATE = 'supplyOrderDate' // Order date

    public readonly ITEM_PRICE = 'priceOrdered';
    // public readonly NO_ARRANGEMENT_ID = '-1';
    public readonly IS_MANUAL_VALUE = 'is_manual_value';
    // public readonly ARRANGEMENT = 'arrangementId';
    public readonly PROVISION_ID = 'provisionId';
    public readonly PROVISION_FEE_ID = 'provisionFeeId';
    // public readonly AGREEMENT_ID = 'agreementId';
    // public readonly ARRANGEMENT_SEARCHING = 'arrangementSearching';
    public readonly AUTHORIZATION = 'authorization';
    public readonly MARGIN_ADDED = 'profitMarginAdded';
    // // public readonly ARRANGEMENT_MARGIN = 'arrangementMargin';
    // // public readonly ARRANGEMENT_FEE = 'arrangementFee';
    public readonly FRECUENCY = 'frequency';
    public readonly PROVISION_FREQUENCY_UNIT_DEFAULT = ProvisionsUnit.UNIDAD;
    public readonly UPDATE_FEE = 'fee';
    public readonly ORDER_ITEMS = 'items';
    public readonly ORDER_PROVIDER = 'providerId';
    public readonly ORDER_PROVIDER_SEARCHING = 'providerSearching';
    public readonly ORDER_DATE = 'orderDate';
    public readonly ORDER_OBSERVATIONS = 'observations';
    public readonly ITEM_QUANTITY = 'quantityOrdered';
    public readonly ITEM_PROVISIONFEEID = 'provisionFeeId';
    public readonly ITEM_PRACTICE = 'practice';
    public readonly ITEM_UNIT = 'unit';
    public readonly ITEM_PROVISION = 'provision';

    providerOrderOnSelect = [[]];
    actualProviderOrderSelected = [null];
    provisionAgreementsInput: ProvisionAgreementFilters;
    supplySpecialtyId = SUPPLY_SPECIALTY_ID;
    routes = ROUTES;
    // public readonly MANUAL_OPTIONS = 'manualOptions';
    selection = new SelectionModel<Provision>(true, []);
    selectionAgreements = new SelectionModel<Agreement>(true, []);
    selectionProvFees = new SelectionModel<ProvisionFee>(true, []);
    formAddProvision: FormGroup;
    formAddProvisionFee: FormGroup;
    formAddOrder: FormGroup;
    formUpdateProvisionFee: FormGroup;
    formUpdateAgreement: FormGroup;
    formFrequency: FormGroup;
    formAuthorization: FormGroup;
    practices: Practice[];
    provisionToUpdate: Provision;
    dataSource;
    dataSourceSupplies;
    loadMultipleAuthorizations: boolean = false;
    isLoadingUpdatingProvision$: Observable<boolean>;
    isLoadingGettingProvision$: Observable<boolean>;
    isLoadingCreatingOrder$: Observable<boolean>;
    isLoadingCreatingProvision$: Observable<boolean>;
    isLoadingGettingAttentions$: Observable<boolean>
    _loadingGetCase: Subscription;
    loadingGetCase: boolean = false;
    _historyModeDate: Subscription;
    _attentions: Subscription;
    case$: Observable<Case>;
    _case: Subscription;
    _practices: Subscription;
    _combineLatest: Subscription;
    _getAllProvisionsCase: Subscription;
    _filtersOnProvisions: Subscription;
    _orders: Subscription;
    _allOrdersWithoutFilter: Subscription;
    _provisionsDisplayedOnScreen: Subscription;
    historyFromDate: Date;
    historyToDate: Date;
    orders: Order[];
    allOrdersWithoutFilter: Order[];
    attentionsInRange: Attention[];
    agreements: Agreement[];
    totalSupplies: number = 0;
    totalValue: number = 0;
    totalCost: number = 0;
    fullDelivered: number = 0;
    fullDebited: number = 0;
    fullInvoiced: number = 0;
    fullInformed: number = 0;
    presentSupplyIndex: Number[] = [];
    isOpen: boolean = false;
    indexOpen: number;
    disposables: Provision[];
    medicines: Provision[];
    equipments: Provision[];
    others: Provision[];
    columnsToDisplayCollapsed: string[] = ['provision', 'checkProvisionFee', 'frequency', 'fee'];
    headerColumns: string[] = [
        'provision',
        'checkProvisionFee',
        'frequency',
        'provider',
        'cost',
        'total-cost',
        'profitMargin',
        'fee',
        'total-fee',
        'ordered',
        'states',
        'authorization',
        'vigency',
        //'actions',
    ];
    supplyStates: string[] = ['delivered', 'not-delivered', 'invoiced', 'debited', 'informed'];
    columnsToDisplay: string[] = [
        //'arrow',
        //'checkbox',
        'provision',
        'checkProvisionFee',
        'frequency',
        'provider',
        'cost',
        'total-cost',
        'profitMargin',
        'fee',
        'total-fee',
        'ordered',
        'delivered',
        'not-delivered',
        'invoiced',
        'debited',
        'informed',
        'authorization',
        'vigency',
        //'actions'
    ];
    displayedColumnsSupplies: string[] = ['supplyRequested', 'quantityRequested', 'priceRequested'];
    _dialogRef;
    objectValues = Object.values;
    provisionsUnit = [
        {
            id: ProvisionsUnit.UNIDAD,
            name: 'Unidad',
        },
        {
            id: ProvisionsUnit.BOLSA_BOLSON,
            name: 'Bolsa/Bolsón',
        },
        {
            id: ProvisionsUnit.PAQUETE,
            name: 'Paquete',
        },
        {
            id: ProvisionsUnit.CAJA,
            name: 'Caja',
        },
    ];

    // Action -> ADD, UPD, etc
    globalAction: string = '';
    //
    txtMsgAmount = ''
    txtMsgUnit = ''
    txtMsgProviderFee = '';
    txtMsgSupply = '';
    oneProviderSelected: Provider;
    suppliesSelected: Practice[] = [];

    get fromDate() {
        return !!this.formSupplies && this.formSupplies.get(this.FROM_DATE) == undefined
            ? this.formSupplies.get(this.FROM_DATE).value
            : null;
    }

    set frequencyUnitForm(value: string) {
        !!value
            ? this.formFrequency.controls[this.UNIT].setValue(value)
            : this.formFrequency.controls[this.UNIT].reset();
    }

    get itemsFormArray(): FormArray {
        return this.formAddOrder.get(this.ORDER_ITEMS) as FormArray;
    }

    swalWithCustomizeButtons: any;
    _provisionsForCase: Subscription;
    provisions: Provision[];
    provisionsDisplayedOnScreen: Provision[];

    provisionsOnScreen_: Subscription;

    scrollTop: number = null;

    arrayIsSameProviderSelected: ProvisionFee[];
    arrayIsSomeSupplyINOrder: ProvisionFee[];
    arrayIsSomeSupplyWithoutToDate: ProvisionFee[];

    suppliesWithSomeOrderActive: number = 0;
    suppliesWithToDateNull: number = 0;
    suppliesWithProviderNull: number = 0;
    suppliesWithoutFeeOrProviderFee: number = 0;
    suppliesWithOrderInAnotherTimePeriod: number = 0;
    provisionFeesWithBackGroundColor = new Set();

    constructor(
        private formBuilder: FormBuilder,
        public entitiesFacade: EntitiesFacade,
        private casesFacade: CasesFacade,
        public dialog: MatDialog,
        private nomenclatorFacade: NomenclatorFacade,
        private provisionsFacade: ProvisionsFacade,
        private attentionsFacade: AttentionsFacade,
        private cdr: ChangeDetectorRef,
        public generalService: GeneralService,
        public fileUtilitiesService: FileUtilitiesService,
        private evolutionsFacade: EvolutionsFacade,
        private _bottomSheet: MatBottomSheet,
    ) {
        this.isLoadingUpdatingProvision$ = this.provisionsFacade.isLoadingUpdatingProvision$();
        this.isLoadingGettingProvision$ = this.provisionsFacade.isLoadingGetProvisions$();
        this.isLoadingCreatingProvision$ = this.provisionsFacade.isLoadingCreatingProvision$();
        this.isLoadingCreatingOrder$ = this.casesFacade.isLoadingCreatingOrder$();
        this.isLoadingGettingAttentions$ = this.attentionsFacade.isLoadingGetAttentions$()
    }

    isChecked(row: any): boolean {
        console.log("Is Checked", row);

        const found = this.selectionProvFees.selected.find((el) => el.id === row.id);
        if (found) {
            return true;
        }
        return false;
    }

    ngOnInit() {

        this.swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel',
            },
            buttonsStyling: true,
        });

        this.calculateNameProvider = this.calculateNameProvider.bind(this);
        this.selection.isSelected = this.isChecked.bind(this);
        this.selectionProvFees.isSelected = this.isChecked.bind(this);
        this.dataSource = new MatTableDataSource();
        this.dataSourceSupplies = new MatTableDataSource();

        this._practices = this.nomenclatorFacade.getPractices$().subscribe((practices) => {
            if (!!practices) {
                this.practices = practices.filter(
                    (practice) => practice.specialty?.isSupply && practice.name != 'Entrega insumos',
                );
            }
        });

        this._getAllProvisionsCase = this.provisionsFacade.getAllProvisionsCase$().subscribe(provisions => {
            console.log("Subscribe", provisions);
            if (!!provisions) {
                this.provisions = provisions;
            }
        })

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe((rangeDate) => {
            if (!!rangeDate) {
                this.historyFromDate = moment(rangeDate.historyFromDate).startOf('d').toDate(); // This component filters by date, NOT datetime
                this.historyToDate = moment(rangeDate.historyToDate).startOf('d').toDate(); // This component filters by date, NOT datetime
            }
        });

        this._allOrdersWithoutFilter = this.casesFacade.getOrders$().subscribe(or => {
            if (!!or) {
                this.allOrdersWithoutFilter = or;
            }
        })

        this._orders = this.casesFacade.getOrdersDisplayedOnScreen$().subscribe(orders => {
            if (!!orders) {
                this.orders = orders;
            }
        });

        this.provisionsOnScreen_ = this.provisionsFacade.getProvisionsDisplayedOnScreen$().subscribe(provisionsOnScreen => {

            if (!!provisionsOnScreen && provisionsOnScreen.length > 0) {
                this.provisionsDisplayedOnScreen = provisionsOnScreen;
                //TODO: Eliminar despues de la release de insumos
                //this.agreements = this.provisions?.flatMap((prov) => prov.agreements).filter((ag) => ag != null);
                this.composeProvisionFeeWithOrders(); // Compose with orders
                this.selectionProvFees.clear();
                this.provisionsFacade.setFiltersOnProvisions(this.updateFilter())
                // Sorts provision by practice name
                this.dataSource.data = provisionsOnScreen?.filter((prov) => prov.isSupply && prov?.practice?.name != 'Entrega insumos')
                    .sort((a, b) => {
                        if (a.practice.name > b.practice.name) {
                            return 1;
                        } else if (b.practice.name > a.practice.name) {
                            return -1;
                        }
                        return 0;
                    });
                console.log("Datasource: ", this.dataSource.data);
                this.dataSource.data.forEach((prov) =>
                    prov.provisionFees.forEach((provFee) => {
                        let agreementForProvFee = prov?.agreements?.find((ag) => ag.provisionFeeId == provFee.id);
                        provFee.practice = prov.practice;
                        if (agreementForProvFee != null) {
                            provFee.agreement = agreementForProvFee;
                        }
                    }),
                );
                this.groupByCategories();
                this.getTotalsFooter();
            } else {
                this.dataSource.data = [];
            }
        })

        this._attentions = this.attentionsFacade.getAttentions$().subscribe((attentions) => {
            if (!!attentions) {
                this.attentionsInRange = attentions?.filter(
                    (att) =>
                        moment(att.fromDate).isBetween(
                            this.historyFromDate,
                            this.historyToDate,
                            undefined,
                            '[]',
                        ) && att.isSupply,
                );
                this.getTotalsFooter();
            }
        });

        this._filtersOnProvisions = this.provisionsFacade
            .getFiltersOnProvisions$()
            .subscribe((filters) => {
                if (!!filters) {
                    this.provisionAgreementsInput = filters;

                    if (!!this.provisionAgreementsInput?.provisionsSupplies &&
                        this.provisionAgreementsInput?.provisionsSupplies?.length == 0
                    ) {
                        this.selection.clear();
                        this.selectionAgreements.clear();
                    } else {
                        this.provisionAgreementsInput?.provisionsSupplies?.forEach((filter) => {
                            this.selection.select(filter);
                            filter.agreements?.forEach((agr) => {
                                if (
                                    this.provisionAgreementsInput?.provisionAgreementsSupplies?.includes(agr)
                                ) {
                                    this.selectionAgreements.select(agr);
                                }
                            });
                        });
                    }
                }
            });

        if (!!this.isBilling) {
            this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
        }
    }

    getProvisionsFeeByIdAndFixedPrice(pFIds: number[]): ProvisionFee[] {
        let provisionsFee: ProvisionFee[] = [];
        let provFees = this.provisionsDisplayedOnScreen?.flatMap(prov => prov.provisionFees).filter(pr => pr?.fixedPrice || pr?.agreement?.providerFee == 0 || pr.fee == 0); // Only profisionsFee with fixedPrice = true - Kairos - watched in schedule.case -> getProvisionFeeIdsSupplies() too
        pFIds.forEach(item => {
            provisionsFee.push(provFees.find(pF => pF.id == item));
        })
        return provisionsFee;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['allProvisionsWithoutFilter']) {
            this.provisions = changes['allProvisionsWithoutFilter'].currentValue;
        }

        if (changes['provisionsFeeIdsToModifySupplies'] && changes['provisionsFeeIdsToModifySupplies'].currentValue?.length > 0) { // There are provisionFees with Kairos
            this.kairosModifiedExternally = true;
            this.openAddSupplyDialog('FPR', this.getProvisionsFeeByIdAndFixedPrice(changes['provisionsFeeIdsToModifySupplies'].currentValue));
        }
    }

    ngAfterViewInit() {
        this.setShowFabButton();
        this.updateWidth();
        // ResizeObserver observes the size changes of an element
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                this.updateWidth();
            }
        })
        const elementToObserve = document.getElementById('nameColumn');
        const supplyListTable = document.getElementById('supplyListTable')
        this.resizeObserver.observe(elementToObserve);
        this.resizeObserver.observe(supplyListTable);
    }


    setShowFabButton() {
        this.buttonsObserver = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.showFabButton = false;
                } else {
                    this.showFabButton = true
                }
            });
        }, {
            threshold: 0
        });
        const headerButtons = document.querySelector('#headerButtons')
        this.buttonsObserver.observe(headerButtons);
    }


    // Compose provisionFee with orders in array
    composeProvisionFeeWithOrders() {
        this.provisionsDisplayedOnScreen?.map(prov => {
            return prov.provisionFees?.map(pF => {
                let aux = [];
                if (this.orders != null && this.orders?.length > 0) {
                    for (let order of this.orders) {
                        if (order.items.find(i => i.provisionFeeId == pF.id)) {
                            aux.push(order);
                        }
                    }
                }
                pF.orders = aux;
            })
        })
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.scrollElem.nativeElement.scroll({
                top: this.scrollElem.nativeElement.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
        }, 0);
    }

    // Response supplies only
    getSuppliesByName = (value: string) => this.nomenclatorFacade.getPracticesFiltered$(value).pipe(
        map((practices) =>
            practices.filter(
                (practice) => practice?.specialty?.isSupply && practice.name != 'Entrega insumos' && practice.active,
            ),
        ));

    loadProvidersByFullName = (value: string) =>
        this.entitiesFacade.loadProviders({ fullName_like: value, active: true, isSupply: true }); // FIXME: dejar de utilizar
    // Response providers
    // getProvidersByName = (value: string) => this.entitiesFacade.getProvidersFiltered$(value).pipe(
    //   map( providers => providers.filter( prov => prov.specialties.some( spec => spec.isSupply)))
    // ); // TODO: cuando se pueda corregir el endpoint del get de providers para que devuelva isSupply y asi usa la cache
    getProvidersByName = (value: string) =>
        this.entitiesFacade.loadProviders({ fullName_like: value, active: true, isSupply: true });

    ngOnDestroy(): void {

        this._combineLatest ? this._combineLatest.unsubscribe() : null;
        this._practices ? this._practices.unsubscribe() : null;
        this._filtersOnProvisions ? this._filtersOnProvisions.unsubscribe() : null;
        if (!!this._case) {
            this._case.unsubscribe();
        }
        if (!!this._loadingGetCase) {
            this._loadingGetCase.unsubscribe();
        }
        if (!!this._orders) {
            this._orders.unsubscribe();
        }
        if (!!this._attentions) {
            this._attentions.unsubscribe();
        }
        if (!!this._historyModeDate) {
            this._historyModeDate.unsubscribe();
        }
        if (!!this._provisionsDisplayedOnScreen) {
            this._provisionsDisplayedOnScreen.unsubscribe();
        }
        this._getAllProvisionsCase ? this._getAllProvisionsCase.unsubscribe() : null;
        this.resizeObserver ? this.resizeObserver.disconnect() : null;
        this.buttonsObserver ? this.buttonsObserver.disconnect() : null;
        !!this._allOrdersWithoutFilter ? this._allOrdersWithoutFilter.unsubscribe() : null;
    }

    isAllSelected() {
        const numSelected = this.selectionProvFees.selected.length;
        const provFees = this.dataSource.data.flatMap((prov) =>
            prov.provisionFees.map((provFee) => provFee),
        ).length;
        return numSelected === provFees;
    }

    calculateIdProvider(element: Provider) {
        return element ? element.id : null;
    }

    calculateNameProvider(element: Provider) {
        return element ? this.entitiesFacade.getProvidersFullName(element) : null;
    }

    isProvisionFeeWithActiveOrders(provFee: ProvisionFee): boolean {
        return provFee.orders.filter(order => order.state.id != this.ORDERS_STATES.CANCELADO).length > 0 ? true : false;
    }

    getProvisionFeesWithOrder(provFees: ProvisionFee[]): ProvisionFee[] {
        return provFees.filter(pF => pF.orders?.length);
    }

    getProvisionFeesWithActiveOrder(provFees: ProvisionFee[]): ProvisionFee[] {
        return provFees.filter(pF => this.isProvisionFeeWithActiveOrders(pF));
    }

    getProvisionFeesWithAgreement(provFees: ProvisionFee[]): ProvisionFee[] {
        return provFees.filter(pF => pF.agreement?.id > 0);
    }

    getProvisionFeesWithAuthorization(provFees: ProvisionFee[]): ProvisionFee[] {
        return provFees.filter(pF => pF.authorization);
    }

    getProvisionFeesDynamicTableWithActiveOrders(provisionFeesSelected: ProvisionFee[]): any[] {
        let _array = [];
        provisionFeesSelected.forEach(pFee => {
            // Dynamic table
            pFee.orders.forEach(order => {
                if (order.state.id != this.ORDERS_STATES.CANCELADO)
                    _array.push({
                        // Example row -> Jeringas | 3 paquetes | Lezamas Josefina
                        supplyName: pFee.practice.name,
                        quantity: pFee.frequency?.amount ? `${pFee.frequency?.amount} ${pFee.frequency?.unit}` : '-',
                        provider: `${order.provider.surname} ${order.provider.name}`
                    })
            })
        })
        return _array;
    }

    getProvisionFeesDynamicTableWithAgreement(provisionFeesSelected: ProvisionFee[]): any[] {
        let _array = [];
        provisionFeesSelected.forEach(pFee => {
            // Dynamic table
            _array.push({
                // Example row -> Jeringas | 3 paquetes | Lezamas Josefina
                // or
                // Example row -> Jeringas | 3 paquetes | 99875552/33 -> if globalAction is authorization
                supplyName: pFee.practice.name,
                quantity: pFee.frequency?.amount ? `${pFee.frequency?.amount} ${pFee.frequency?.unit}` : '-',
                provider: this.globalAction != 'AUT' ? `${pFee.agreement.provider.surname} ${pFee.agreement.provider.name}` : pFee.authorization.authorizationNumber
            })
        })
        return _array;
    }

    openLoadPrice(selectedSupplies?: ProvisionFee[]) {
        this.selectedSupplies = [];
        this.globalAction = 'PRI';
        if (this.getProvisionFeesWithActiveOrder(selectedSupplies).length > 0) {
            this.message = 'Los siguientes insumos son parte de un pedido y no se pueden modificar';
            this.arrayTable = this.getProvisionFeesDynamicTableWithActiveOrders(this.getProvisionFeesWithActiveOrder(selectedSupplies))
            this.txtButtonCancel = 'OK';
            this.showButtonACEPTAR = false;
            this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
        } else {
            if (this.getProvisionFeesWithAgreement(selectedSupplies).length > 0) {
                this.message = 'Los siguientes insumos ya tienen una cotización cargada y si continúa con el proceso se reemplazarán';
                this.arrayTable = this.getProvisionFeesDynamicTableWithAgreement(this.getProvisionFeesWithAgreement(selectedSupplies))
                this.txtButtonCancel = 'CANCELAR';
                this.showButtonACEPTAR = true;
                this.selectedSupplies = selectedSupplies;
                this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
            } else {
                this.openAddSupplyDialog('PRI', selectedSupplies);
            }
        }
    }

    openAddAuthorization(selectedSupplies: ProvisionFee[]) {
        this.globalAction = 'AUT';
        this.arrayTable = [];
        this.arrayTable = this.getProvisionFeesDynamicTableWithAgreement(this.getProvisionFeesWithAuthorization(selectedSupplies));
        if (this.arrayTable.length > 0) {
            this.message = 'Los siguientes insumos tienen una autorización vigente. Si continúa con el proceso se reemplazarán. ¿Desea continuar?';
            this.txtButtonCancel = 'CANCELAR';
            this.showButtonACEPTAR = true;
            this.selectedSupplies = selectedSupplies;
            this.openDialog('Atención', this.popUpMessage, { minWidth: '15%', maxHeight: '70vh' }, () => { });
        } else {
            this.openAddSupplyDialog(this.globalAction, selectedSupplies);
        }
    }

    openUpdateSupplies() {
        if (this.isSomeSupplyWithoutToDate()) {
            this.message = 'Existe al menos un insumo sin fecha de finalización, corrija y vuelva a intentar';
            this.arrayTable = this.selectionProvFees.selected.filter(sup => sup.toDate == null).map(sup => {
                return {
                    supplyName: sup?.practice?.name,
                    quantity: sup?.frequency?.amount,
                    provider: !!sup?.agreement ? `${sup?.agreement?.provider?.surname} ${sup?.agreement?.provider?.name}` : 'Sin proveedor'
                }
            });
            this.txtButtonCancel = 'OK';
            this.showButtonACEPTAR = false;
            this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
        } else {
            this.openAddSupplyDialog('UPD', this.selectionProvFees.selected)
        }
    }

    openAddSupplyDialog(action: string, selectedSupplies?: ProvisionFee[]) {

        // Restarts present supply validator
        this.presentSupplyIndex = [];

        // Form auth
        this.photoEvolutionB64 = null;
        this.formAuthorization = this.createAuthorizationGroup();
        // Global action
        this.globalAction = action;

        this.txtMsgAmount = 'Cantidad *'
        this.txtMsgUnit = 'Unidad *'
        this.txtMsgSupply = 'Insumos *'
        this.txtMsgProviderFee = 'costo unitario'

        // Form supplies
        this.formSupplies = this.formBuilder.group({
            [this.ONE_PROVIDER]: [''],
            [this.ONE_PROVIDER_SEARCHING]: [''],
            [this.SUPPLIES]: this.formBuilder.array(this.createSuppliesFormArray(selectedSupplies)), // Array form
            [this.FROM_DATE]: [this.historyFromDate],
            [this.TO_DATE]: [this.historyToDate],
            // [this.EXPORT_QUOTE]: [''],   TODO: Descomentar cuando se desarrolle back
            // [this.EXPORT_BUDGET]: [''],  TODO: Descomentar cuando se desarrolle back
            [this.IS_ADDING_AUTHORIZATION]: [this.globalAction == 'AUT' ? true : false], // Checkbox authorization
            [this.AUTHORIZATION]: this.formAuthorization
        });

        let title: string = '';
        this.checkInputsRules(); // Check always rules based on this.globalAction

        console.log('globalAction: ', this.globalAction);
        // Particular settings by this.globalAction
        switch (this.globalAction) {
            case 'ADD':
                title = 'Crear insumo/s'
                this.formSupplies.get(this.FROM_DATE).setValidators(CustomValidators.required('Requerida'))
                this.formSupplies.get(this.TO_DATE).setValidators(CustomValidators.required('Requerida'))
                break;

            case 'UPD':
                title = 'Renovar insumo/s'
                break;

            case 'PRI':
                this._dialogRef ? this._dialogRef.close() : null;
                title = 'Cargar cotización'
                this.txtMsgProviderFee += ' *' // Add * to header
                this.providerSelected(null);
                break;

            case 'MOD':
                this._dialogRef ? this._dialogRef.close() : null;
                this.selectedSupplies = selectedSupplies;
                title = 'Modificar insumo/s'
                this.formSupplies.markAllAsTouched(); // Force show erros
                break;

            // TODO: not used here - maybe could be necesary in the future
            // Force load Kairos (price)
            case 'FPR':
                title = 'Asignación de valores';
                this.txtMsgAmount = 'Cantidad';
                this.txtMsgUnit = 'Unidad';
                this.txtMsgSupply = 'Insumos';
                break;

            // Only load authorization
            case 'AUT':
                title = 'Autorizar';
                this.formSupplies.get(this.IS_ADDING_AUTHORIZATION).disable();
                this.formAuthorization.get(this.AUTHORIZATION_FROM_DATE).setValidators(CustomValidators.required('Requerida'));
                this.formAuthorization.get(this.AUTHORIZATION_TO_DATE).setValidators(CustomValidators.required('Requerida'));
                this.txtMsgAmount = 'Cantidad';
                this.txtMsgUnit = 'Unidad';
                this.txtMsgSupply = 'Insumos';
                this.supplies.disable();
                break;

            default:
                break;
        }
        this.openDialog(title, this.addSupplyTemplate, { maxHeight: '95vh', minWidth: '100%' }, () => { });
    }


    getColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_COLORS[state]
    }

    getBackgroundColorAuthorizationState(provisionFee: ProvisionFee) {
        let state = this.generalService.getAuthorizationState(provisionFee);
        return this.AUTHORIZATION_BACKGROUND_COLORS[state];
    }

    onClickCloseDialog() {
        this.dialog.closeAll();
    }

    openDialog(
        title: string,
        template,
        style: { minWidth: string; maxHeight: string },
        afterClosed?,
    ): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    createAuthorizationGroup(auth?: Authorization) {
        return this.formBuilder.group({
            [this.AUTHORIZATION_NUMBER]: [auth?.authorizationNumber],
            [this.AUTHORIZATION_OBSERVATIONS]: [''],
            [this.AUTHORIZATION_FROM_DATE]: [!!this.historyFromDate ? this.historyFromDate : ''],
            [this.AUTHORIZATION_TO_DATE]: [!!this.historyToDate ? this.historyToDate : ''],
        });
    }

    calculateNameUnit(element) {
        return element ? element.name : null;
    }

    calculateIdUnit(element) {
        return element ? element.id : null;
    }

    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }

    calculateNamePractice(element: Practice) {
        return element ? element.name + ' #' + element.code : null;
    }

    /**
    *
    * @param supplyForm form inputs from DOM
    * @returns form inputs converted to provision for supplies patch
    */
    setSupplyFormToSupplyPatchRequest(supplyForm): PatchProvisionFeeBody {
        let supplyToPatch: PatchProvisionFeeBody = {
            id: supplyForm.provisionFeeId,
            fee: supplyForm.fee,
            profitMargin: supplyForm.profitMargin,

            frequency: {
                amount: supplyForm.amount,
                unit: supplyForm.unit,
            },
            providerFee: supplyForm.providerFee,

            fixedPrice: this.globalAction != 'FPR' ? supplyForm.fixedPrice : false,
            fromDate: supplyForm.fromDateBySupply,
            toDate: supplyForm.toDateBySupply,
            providerId: this.globalAction === 'PRI' ? this.oneProviderSelected.id : parseInt(supplyForm.providerId),
        }
        //if (this.globalAction=='MOD') delete supplyToPatch.frequency; TODO: revisar condición cuando se valide cantidad en insumo vs cantidad en orden
        return supplyToPatch
    }

    /**
    *
    * @param supplyForm form inputs from DOM
    * @returns form inputs converted to provision for supplies post
    */
    setSupplyFormToSupplyPostRequest(supplyForm): MedicalSupply {
        let medicalSupply: MedicalSupply;
        medicalSupply = {
            provision: {
                practiceId: supplyForm.practiceId,
                fee: supplyForm.fee,
                profitMargin: supplyForm.profitMargin,
                frequency: {
                    amount: supplyForm.amount,
                    unit: supplyForm.unit,
                },
                fixedPrice: supplyForm.fixedPrice,
                fromDate: this.globalAction === 'ADD' ? this.formSupplies.get('fromDate').value : supplyForm.fromDateBySupply,
                toDate: this.globalAction === 'ADD' ? moment(moment(this.formSupplies.get('toDate').value).format('YYYYMMDD')).toDate() : moment(moment(supplyForm.toDateBySupply).format('YYYYMMDD')).toDate()
            },
        };

        if (!!supplyForm.providerId) {
            medicalSupply = {
                ...medicalSupply,
                agreement: {
                    providerId: supplyForm.providerId,
                    providerFee: supplyForm.providerFee,
                },
            };
        }
        return medicalSupply;
    }

    onClickCreateProvision() {
        let formValues = this.formSupplies.getRawValue(); // Useful if it's need take disable values


        // Auth
        if (!formValues[this.IS_ADDING_AUTHORIZATION]) {
            delete formValues[this.AUTHORIZATION];
        } else {
            if (!!this.photoEvolutionB64) {
                formValues.authorization.mediaUrls = [this.photoEvolutionB64];
            }
            if (formValues[this.AUTHORIZATION][this.AUTHORIZATION_OBSERVATIONS] == '') {
                formValues[this.AUTHORIZATION][this.AUTHORIZATION_OBSERVATIONS] = 'Sin observaciones.'
            }
        }

        this.recalculateValues()

        let supplies = [...formValues.supplies];

        delete formValues[this.IS_ADDING_AUTHORIZATION]; // Always
        delete formValues[this.SUPPLIES];
        delete formValues[this.FROM_DATE];
        delete formValues[this.TO_DATE];

        switch (this.globalAction) {
            case 'ADD':
            case 'UPD':

                delete formValues[this.ONE_PROVIDER];
                delete formValues[this.ONE_PROVIDER_SEARCHING];

                let provisions: MedicalSupply[] = [];
                supplies.forEach((supply) => {
                    provisions.push(this.setSupplyFormToSupplyPostRequest(supply));
                });

                formValues = { ...formValues, medicalSupplies: provisions };

                this.provisionsFacade.addSupplyProvisions(formValues, this.case.id).subscribe(() => {
                    this.swalWithCustomizeButtons
                        .fire({
                            text: 'Insumo/s agregado/s exitosamente',
                            icon: 'success',
                        })
                        .then(() => this._dialogRef.close());
                    this.selectionProvFees.clear();
                    this.provisionsFacade.setFiltersOnProvisions(this.updateFilter())
                    this.rowsWithErrorsForCreateOrder();
                });
                break;

            // MOD and PRI almost same logic
            case 'MOD':
            case 'PRI':
            case 'FPR':
            case 'AUT':

                delete formValues[this.ONE_PROVIDER];
                delete formValues[this.ONE_PROVIDER_SEARCHING];

                let provFees: PatchProvisionFeeBody[] = [];
                supplies.forEach((supply) => {
                    provFees.push(this.setSupplyFormToSupplyPatchRequest(supply));
                });

                formValues = { ...formValues, provisionFees: [...provFees], update: this.globalAction === 'PRI' ? false : true };

                this.provisionsFacade.updateProvisionFees(formValues, this.case.id, this.provisions).subscribe(() => {
                    this.swalWithCustomizeButtons
                        .fire({
                            text: 'Insumo/s modificado/s exitosamente',
                            icon: 'success',
                        })
                        .then(() => {
                            this._dialogRef.close()
                            let provFeeIds = formValues.provisionFees.map(provFee => provFee.id)
                            this.billSupplyAttentions.emit(provFeeIds) // Emits to provisionScheme to bill attentions externally
                        });
                    this.selectionProvFees.clear();
                    this.provisionsFacade.setFiltersOnProvisions(this.updateFilter())
                    this.rowsWithErrorsForCreateOrder();
                });
                break;

            default:
                break;
        }
    }

    recalculateValues() {
        for (let i = 0; i < this.supplies['controls'].length; i++) {
            this.onChangeCost(i)
        }
    }

    openAddOrderDialog() {

        this.formAddOrder = this.createOrderForm(this.selectionProvFees.selected);
        this.dataSourceSupplies.data = this.itemsFormArray['controls'];

        this.actualProviderOrderSelected[0] = this.selectionProvFees.selected[0].agreement.provider.id.toString();
        this.providerOrderOnSelect[0] = [this.selectionProvFees.selected[0].agreement.provider]

        this.openDialog(
            'Crear pedido ',
            this.addOrderTemplate,
            { maxHeight: '95vh', minWidth: '60%' },
            () => { },
        );
    }

    getProvisionFeePractice(provFee: ProvisionFee) {
        return this.dataSource?.data?.find((prov) => prov.id === provFee.provisionId).practice;
    }

    createOrderForm(provisionFees: ProvisionFee[]) {
        return this.formBuilder.group({
            [this.ORDER_PROVIDER]: ['', [CustomValidators.required('Requerido')]],
            [this.ORDER_PROVIDER_SEARCHING]: [''],
            [this.ORDER_DATE]: ['', [CustomValidators.required('Requerida')]],
            [this.ORDER_OBSERVATIONS]: [''],
            [this.ORDER_ITEMS]: this.formBuilder.array(this.createItemsFormArray(provisionFees)),
        });
    }

    createItemsFormArray(provisionFees: ProvisionFee[]) {
        let itemsFormArray;
        itemsFormArray = provisionFees.map((provFee) => {
            if (!!provFee && !!provFee.id) {
                let itemForm;
                itemForm = this.createItemForm(provFee);
                return itemForm;
            }
        });
        return itemsFormArray;
    }

    createItemForm(provFee: ProvisionFee): FormGroup {
        let form = this.formBuilder.group({
            [this.ITEM_QUANTITY]: [provFee?.frequency?.amount, CustomValidators.required('Requerida')],
            [this.ITEM_PRICE]: [provFee?.agreement?.providerFee, CustomValidators.minValue(0, 'Requerido')],
            [this.ITEM_PROVISIONFEEID]: [provFee.id],
            [this.ITEM_UNIT]: [provFee?.frequency?.unit],
            [this.ITEM_PRACTICE]: [this.getProvisionFeePractice(provFee)],
            [this.ITEM_PROVISION]: [this.getProvisionFromProvisionFee(provFee.provisionId)],
        });
        return form;
    }

    getProvisionFromProvisionFee(provisionId) {
        return this.dataSource.data.find((prov) => prov.id === provisionId);
    }

    onClickCreateOrder() {
        delete this.formAddOrder.value.providerSearching;
        this.formAddOrder.value.items.map((item) => {
            item.provisionId = item.provision.id;
            delete item.provision;
            return item;
        });
        this.formAddOrder.value.providerId = parseInt(this.formAddOrder.value.providerId);
        this.formAddOrder.value.orderDate = moment(this.formAddOrder.value.orderDate).set('hour', 8);
        let formValue: PostOrderBody = this.formAddOrder.value;

        for (const property in formValue) {
            if (!!!formValue[property]) {
                delete formValue[property];
            }
        }

        this.casesFacade.addOrder(formValue, this.case.id).subscribe(
            (order) => {
                this.swalWithCustomizeButtons
                    .fire({
                        text: 'El pedido se creó correctamente',
                        icon: 'success',
                    })
                    .then(() => {
                        this._dialogRef.close();
                        this.selection.clear();
                        this.selectionAgreements.clear();
                        this.selectionProvFees.clear();
                        this.rowsWithErrorsForCreateOrder();
                        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
                    })
                    .catch((e) => {
                        this._dialogRef.close();
                        this.selection.clear();
                    });
            },
            (err) => {
                this._dialogRef.close();
                this.selection.clear();
            },
        );
    }

    onClickChangeActiveProvision(provision: Provision) {
        const active = !provision.active;
        this.provisionsFacade
            .updateProvision({ active }, this.case.id, provision.id)
            .subscribe(() => {
                this.swalWithCustomizeButtons
                    .fire({
                        text: 'El item seleccionado pasó a Insumos inactivos',
                        title: 'Insumo modificado correctamente',
                        icon: 'success',
                    })
                    .then(() => this._dialogRef.close());
            });
    }

    onSelectOptionSupply(option: string) {
        this.selectedOptionSupply = option;
    }

    dateDiff(day: Date): number {
        if (!!day) return moment(day).diff(this.toDay, 'days');
        else return -1; // Vencido / hay un error en la fecha
    }

    deliveredQuantity(provision) {
        let quantity: number = 0;
        if (provision.agreements) {
            provision.agreements.forEach((agr) => {
                quantity += agr.delivered;
            });
        }
        return quantity;
    }

    getTotalSupplies() {
        this.totalSupplies = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource?.data?.forEach((prov) =>
                prov?.provisionFees?.forEach((provFee) => {
                    this.totalSupplies += 1;
                }),
            );
        } else {
            this.totalSupplies = 0;
        }
    }

    getFullInvoiced() {
        this.fullInvoiced = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource?.data?.forEach((data) =>
                data?.provisionFees?.forEach((provFee) => {
                    this.fullInvoiced += this.getInvoiced(provFee) * provFee.fee;
                }),
            );
        } else {
            this.fullInvoiced = 0;
        }
        this.fullInvoiced = parseFloat(this.fullInvoiced.toFixed(2))
    }

    getFullDebited() {
        this.fullDebited = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource?.data?.forEach((data) =>
                data?.provisionFees?.forEach((provFee) => {
                    this.fullDebited += this.getDebited(provFee) * provFee.fee;
                }),
            );
        } else {
            this.fullDebited = 0;
        }
        this.fullDebited = parseFloat(this.fullDebited.toFixed(2))
    }

    getFullInformed() {
        this.fullInformed = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach((data) =>
                data?.provisionFees?.forEach((provFee) => {
                    this.fullInformed += this.getInformed(provFee) * provFee.fee;
                }),
            );
        } else {
            this.fullInformed = 0;
        }
        this.fullInformed = parseFloat(this.fullInformed.toFixed(2));
    }

    getFullDelivered() {
        this.fullDelivered = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach((data) =>
                data?.provisionFees?.forEach((provFee) => {
                    this.fullDelivered += this.getDelivered(provFee) * provFee.fee;
                }),
            );
        } else {
            this.fullDelivered = 0;
        }
        this.fullDelivered = parseFloat(this.fullDelivered.toFixed(2));
    }

    getTotalValue() {
        this.totalValue = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach((data) => {
                data?.provisionFees?.forEach((provFee) => {
                    if (provFee.fee != null && provFee.frequency.amount != null) {
                        this.totalValue += provFee?.fee * provFee?.frequency?.amount;
                    } else if (provFee.fee == null && (provFee.agreement?.providerFee != null && provFee.profitMargin != null) && provFee.frequency.amount != null) {
                        this.totalValue += (provFee.agreement?.providerFee + (provFee.agreement?.providerFee * (provFee.profitMargin / 100))) * provFee.frequency.amount;
                    } else {
                        this.totalValue += 0;
                    }
                });
            });
        } else {
            this.totalValue = 0;
        }
        this.totalValue = parseFloat(this.totalValue.toFixed(2))
    }

    getTotalCost() {
        this.totalCost = 0;
        if (this.dataSource.data.length > 0) {
            this.dataSource.data.forEach((data) => {
                data?.provisionFees?.forEach((provFee) => {
                    if (!!provFee.agreement?.providerFee) {
                        this.totalCost += provFee.agreement.providerFee * provFee.frequency?.amount;
                    }
                });
            });
        } else {
            this.totalCost = 0;
        }
        this.totalCost = parseFloat(this.totalCost.toFixed(2))
    }

    getDelivered(provFee: ProvisionFee) {
        let deliveredAttentions = this.attentionsInRange?.filter(
            (att) => att.state?.id === AttentionStates.REALIZADA && att.provisionFeeId == provFee.id,
        );
        let totalDelivered: number = 0;
        if (deliveredAttentions != null && deliveredAttentions.length != 0) {
            deliveredAttentions.forEach((att) => {
                totalDelivered += att.quantity;
            });
            return totalDelivered;
        }
        return 0;
    }

    getNotDelivered(provFee: ProvisionFee) {
        let items = this.orders?.filter(or => or.state.id != OrdersStates.CANCELADO).flatMap((order) =>
            order.items.filter((it) => it.provisionFeeId == provFee.id),
        );
        let quantityDelivered: number = 0;
        if (items != null && items.length != 0) {
            items?.forEach((it) => {
                quantityDelivered += it.quantityDelivered;
            });
            return this.getOrderedQuantity(provFee) - quantityDelivered;
        }
        return 0;
    }

    getInvoiced(provFee: ProvisionFee) {
        let deliveredAttentions = this.attentionsInRange?.filter(
            (att) => att.state.id === AttentionStates.FACTURABLE && att.provisionFeeId == provFee.id,
        );
        let totalInvoiced: number = 0;
        if (deliveredAttentions != null && deliveredAttentions.length != 0) {
            deliveredAttentions.forEach((att) => {
                totalInvoiced += att.quantity;
            });
            return totalInvoiced;
        }
        return 0;
    }

    getDebited(provFee: ProvisionFee) {
        let deliveredAttentions = this.attentionsInRange?.filter(
            (att) => att.state.id === AttentionStates.DEBITADA && att.provisionFeeId == provFee.id,
        );
        let totalDebited: number = 0;
        if (deliveredAttentions != null && deliveredAttentions.length != 0) {
            deliveredAttentions.forEach((att) => {
                totalDebited += att.quantity;
            });
            return totalDebited;
        }
        return 0;
    }

    getInformed(provFee: ProvisionFee) {
        let deliveredAttentions = this.attentionsInRange?.filter(
            (att) => att.state.id === AttentionStates.INFORMADA && att.provisionFeeId == provFee.id,
        );
        let totalInformed: number = 0;
        if (deliveredAttentions != null && deliveredAttentions.length != 0) {
            deliveredAttentions.forEach((att) => {
                totalInformed += att.quantity;
            });
            return totalInformed;
        }
        return 0;
    }

    getTotalsFooter() {
        this.getTotalSupplies();
        this.getTotalValue();
        this.getTotalCost();
        this.getFullDebited();
        this.getFullInvoiced();
        this.getFullInformed();
        this.getFullDelivered();
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectionProvFees.clear();
            this.selectionAgreements.clear();
            this.rowsWithErrorsForCreateOrder();
        } else {
            if (!!this.dataSource.data && this.dataSource.data.length > 0) {
                this.dataSource.data.forEach((row) =>
                    row?.provisionFees?.forEach((provFee) => this.selectPractice(provFee, true)),
                );
            }
        }

        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    onChangeChild(data, agreement) {
        this.selectionAgreements.toggle(agreement);
        if (this.selection.selected.find((provision) => provision.id == data.id)) {
            if (
                !(
                    data.agreements?.length > 1 &&
                    data.agreements?.find((agr) =>
                        this.selectionAgreements.selected
                            .map((agreement) => agreement.id)
                            .includes(agr.id),
                    )
                )
            ) {
                this.selection.toggle(this.selection.selected.find((sel) => sel.id == data.id));
            }
        } else {
            this.selection.toggle(data);
        }

        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    selectPractice(row, masterToggle: boolean = false) {

        let isAgreementsIndeterminated = this.isAgreementsIndeterminated(row);

        if (!masterToggle || (masterToggle && !this.selectionProvFees.selected.map((sel) => sel.id).includes(row.id))) {

            if (this.selectionProvFees.selected.map((sel) => sel.id).includes(row.id) && !isAgreementsIndeterminated) {

                this.selectionProvFees.deselect(this.selectionProvFees.selected.find((sel) => sel.id == row.id));
                if (this.provisionFeesWithBackGroundColor.has(row.id)) {
                    this.provisionFeesWithBackGroundColor.delete(row.id);
                }
            } else {

                if (!this.selectionProvFees.selected.map((sel) => sel.id).includes(row.id)) {
                    this.selectionProvFees.select(row);
                    /* this.rowsWithErrorsForCreateOrder(); */
                }
            }
        }
        if (row.agreements) {
            if (
                !masterToggle &&
                !isAgreementsIndeterminated &&
                !this.isEmptySelectionForPractice(row)
            ) {
                row.agreements.forEach((agr) => {
                    this.selectionAgreements.deselect(agr);
                    /* this.rowsWithErrorsForCreateOrder(); */
                });
            } else {
                let agreementsNotSelected = row.agreements.filter(
                    (agr) => !this.selectionAgreements.selected.includes(agr),
                );
                agreementsNotSelected.forEach((agr) => {
                    this.selectionAgreements.select(agr);
                    /* this.rowsWithErrorsForCreateOrder(); */
                });
            }
        }

        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    isSameProviderSelected(): boolean {
        const provIds = [...new Set(this.selectionProvFees.selected.filter(pF => !!pF.agreement).flatMap(pF => pF.agreement.provider ? pF.agreement.provider.id : -1))];
        // if provIds.length = 0 then provider empty
        return provIds.length === 0 ? true : provIds.length === 1 && provIds[0] != -1 ? true : false;
    }

    isAgreementsIndeterminated(row: Provision) {
        let indeterminate = false;
        let agreementsSelected = row.agreements?.filter((agr) =>
            this.selectionAgreements.selected.map((sel) => sel.id).includes(agr.id),
        );
        if (
            this.isPracticeSelected(row) &&
            row.agreements?.length != agreementsSelected?.length &&
            agreementsSelected?.length != 0
        ) {
            indeterminate = true;
        }
        return indeterminate;
    }

    isEmptySelectionForPractice(row) {
        let isEmpty: boolean = true;
        row.agreements.forEach((agr) => {
            if (this.selectionAgreements.selected.map((sel) => sel.id).includes(agr.id)) {
                isEmpty = false;
            }
        });
        return isEmpty;
    }

    isPracticeSelected(row) {
        console.log("ROWWWW", row);

        return this.selectionProvFees.selected.map((sel) => sel.id).includes(row.id);
    }

    getTotalValueForProvision(fee: number, amount: number) {
        return fee * amount;
    }

    updateFilter() {
        if (!!this.provisionAgreementsInput) {
            delete this.provisionAgreementsInput.provisionsSupplies;
            delete this.provisionAgreementsInput.provisionAgreementsSupplies;
        }
        return {
            ...this.provisionAgreementsInput,
            provisionsSupplies: [...this.selection.selected],
            provisionAgreementsSupplies: [...this.selectionAgreements.selected],
            provisionFees: [...this.selectionProvFees.selected],
        };
    }

    getOrderedQuantity(provFee: ProvisionFee) {
        let items = this.orders?.filter(or => or.state.id != OrdersStates.CANCELADO)?.flatMap((order) => order.items.filter((it) => it.provisionFeeId == provFee.id));
        let quantityOrdered: number = 0;

        if (items != null && items.length != 0) {
            items?.forEach((it) => {
                quantityOrdered += it.quantityOrdered;
            });
            return quantityOrdered;
        }
        return 0
    }

    newSupply(supply?: ProvisionFee): FormGroup {

        let newDates: _NewDates;

        //"Renovar" - "Update" - UPD
        if (this.globalAction === 'UPD' && !!supply) {
            newDates = this.setFromToDateBySupply(supply);
        }

        // "Cargar cotización" - "Load price" - PRI
        // "Modificar" - "Modify" - MOD
        if ((this.globalAction === 'PRI' || this.globalAction === 'MOD' || this.globalAction === 'FPR' || this.globalAction === 'AUT') && !!supply) {
            newDates = {
                newFromDate: supply.fromDate,
                newToDate: supply.toDate
            }
        }

        return this.formBuilder.group({
            [this.PROVISION_FEE_ID]: [!!supply ? supply.id : null],

            [this.PRACTICE]: [!!supply ? supply.practice.id : '', CustomValidators.required('Requerido')], // Supply -> disabled control on the html
            [this.PRACTICE_SEARCHING]: [''],
            [this.PROVIDER]: [!!supply ? supply.agreement?.provider?.id : ''], // Provider
            [this.PROVIDER_SEARCHING]: [''],

            [this.AMOUNT]: [!!supply ? supply.frequency?.amount : '', CustomValidators.required('Requerida')],
            [this.UNIT]: [!!supply ? supply.frequency?.unit : '', CustomValidators.required('Requerida')],

            // Disabled if fixedPrice
            [this.PROVIDER_FEE]: [!!supply ? supply.agreement?.providerFee : ''], // Costo
            [this.PROFIT_MARGIN]: [!!supply ? supply.profitMargin : ''], // Margin
            [this.FEE]: [!!supply ? supply.fee : ''], // Fee - Valor venta unitario

            [this.KAIROS]: [{ value: !!supply ? supply.fixedPrice : false, disabled: false }], // Kairos

            [this.FROM_DATE_BY_SUPPLY]: [!!supply ? newDates?.newFromDate : ''], // From-date by item
            [this.TO_DATE_BY_SUPPLY]: [!!supply ? newDates?.newToDate : ''], // To-date by item

            [this.SUPPLY_ORDER_DATE]: [!!supply ? this.getOrderDate(supply) : ''], // If has order, save
        });
    }

    calculateNameSupply(supply: Practice) {
        return !!supply ? supply.name : '';
    }

    calculateIdSupply(supply: Practice) {
        return !!supply ? supply.id : '';
    }

    createSuppliesFormArray(supplies?: ProvisionFee[]) {

        this.index = 0;
        let suppliesFormArray;
        this.suppliesOnSelect = [[]];
        this.actualSupplies = [null];
        this.providersOnSelect = [[]];
        this.actualProviders = [null];

        if (!!supplies && supplies.length > 0) {
            suppliesFormArray = supplies.map(supply => {
                let supplyForm: FormGroup;
                this.suppliesOnSelect[this.index] = [supply?.practice];
                this.actualSupplies[this.index] = supply?.practice?.id.toString();
                this.providersOnSelect[this.index] = [supply?.agreement?.provider]; // Maybe provider doesn't exist
                this.actualProviders[this.index] = supply?.agreement?.provider?.id.toString();

                this.index++;

                if (!!supply) {
                    supplyForm = this.newSupply(supply);
                } else {
                    supplyForm = this.newSupply();
                }
                return supplyForm;
            });
        } else {
            suppliesFormArray = [this.newSupply()];
        }
        return suppliesFormArray;
    }

    addSupply() {
        this.supplies.push(this.newSupply());
        // Add rules
        const position = this.supplies.length - 1;
        this.rulesInputPROVIDER_FEE(position);
        this.rulesFromToDateBYSupply(position);
    }

    removeSupply(i: number) {
        if (this.supplies['controls'].length == 1) {
            this.supplies['controls'][i].reset();
            this.supplies['controls'][i].enable();
        } else {
            this.supplies.removeAt(i);
        }
        this.actualSupplies.splice(i, 1);
        this.suppliesOnSelect.splice(i, 1);
    }

    pondHandleInit() { }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);;
            this.photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
        } else {
            this.photoEvolutionB64 = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoEvolutionB64 = null;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    onChangeCheckAut(event) {
        this.formAuthorization.get('authorizationNumber').clearValidators();
        this.formAuthorization.get('authorizationNumber').setErrors(null);
        if (event) {
            // Add validator
            this.formAuthorization.get('authorizationNumber').setValidators([CustomValidators.required('Requerido')]);
        }
        this.formAuthorization.updateValueAndValidity();
    }

    onClickDateFrom(event: MatDatepickerInputEvent<Date>, i?: number) {
        const auxDate = i >= 0 ? 'toDateBySupply' : 'toDate';
        if (moment(event.value).isValid()) {
            let fromDateSelected = moment(event.value).toDate();
            let toDateSelected = null;
            switch (auxDate) {
                case 'toDate':
                    if (moment(this.formSupplies.get(auxDate).value).isValid()) {
                        toDateSelected = moment(this.formSupplies.get(auxDate).value).endOf('day');
                    }
                    break;
                case 'toDateBySupply':
                    if (moment(this.supplies.at(i).get(auxDate).value).isValid()) {
                        toDateSelected = moment(this.supplies.at(i).get(auxDate).value).endOf('day');
                    }
                    break;

                default:
                    break;
            }
            i >= 0 ? this.supplies.at(i).get(auxDate).setErrors(null) : this.formSupplies.get(auxDate).setErrors(null);
            this.setDatesCustomValidators(fromDateSelected, toDateSelected, i);
        }
    }

    onClickDateTo(event: MatDatepickerInputEvent<Date>, i?: number) {
        const auxDateF = i >= 0 ? 'fromDateBySupply' : 'fromDate';
        const auxDateT = i >= 0 ? 'toDateBySupply' : 'toDate';
        if (!!event.value) {
            if (moment(event.value).isValid()) {
                let toDateSelected: Date = moment(event.value).endOf('day').toDate();
                let fromDateSelected = null;
                switch (auxDateF) {
                    case 'fromDate':
                        if (!!this.formSupplies.get(auxDateF).valid) {
                            fromDateSelected = moment(this.formSupplies.get(auxDateF).value).startOf('day').toDate();
                        }
                        this.formSupplies.get(auxDateF).setErrors(null);
                        break;
                    case 'fromDateBySupply':
                        if (!!this.supplies.at(i).get(auxDateF).valid) {
                            fromDateSelected = moment(this.supplies.at(i).get(auxDateF).value).startOf('day').toDate();
                        }
                        this.supplies.at(i).get(auxDateF).setErrors(null);
                        break;

                    default:
                        break;
                }
                this.setDatesCustomValidators(fromDateSelected, toDateSelected, i);
            }
        } else {
            switch (auxDateF) {
                case 'fromDate':
                    // this.formSupplies.get(auxDateT).clearValidators();
                    this.formSupplies.get(auxDateF).setErrors(null);
                    if (this.formSupplies.get(auxDateT).touched) {
                        this.formSupplies.get(auxDateT).setValue(null);
                    }
                    break;
                case 'fromDateBySupply':
                    // this.supplies.at(i).get(auxDateT).clearValidators();
                    this.supplies.at(i).get(auxDateF).setErrors(null);
                    if (this.supplies.at(i).get(auxDateT).touched) {
                        this.supplies.at(i).get(auxDateT).setValue(null);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    setDatesCustomValidators(fromDateSelected: Date, toDateSelected: Date, i?: number) {
        this.addCustomValidatorsfromDate(toDateSelected, i);
        this.addCustomValidatorsToDate(fromDateSelected, i);
    }

    addCustomValidatorsfromDate(date: Date, i?: number) {
        const auxDateF = i >= 0 ? 'fromDateBySupply' : 'fromDate';
        const form: FormGroup = this.globalAction !== 'AUT' ? this.formSupplies : this.formAuthorization; // Select form, dates has the same name 'fromDate' and 'toDate'
        if (moment(date).isValid()) {
            switch (auxDateF) {
                case 'fromDate':
                    form.get(auxDateF).setErrors(null);
                    form.get(auxDateF).clearValidators();
                    form.get(auxDateF).setValidators(Validators.compose([
                        CustomValidators.required('Requerida'),
                        CustomValidators.dateLowerEqualThan(date)
                    ]));
                    form.get(auxDateF).updateValueAndValidity();
                    break;

                case 'fromDateBySupply':
                    switch (this.globalAction) {
                        case 'UPD':
                            this.supplies.at(i).get(auxDateF).setErrors(null);
                            this.supplies.at(i).get(auxDateF).clearValidators();
                            this.supplies.at(i).get(auxDateF).setValidators(Validators.compose([
                                CustomValidators.required('Requerida'),
                                CustomValidators.dateLowerEqualThan(date),
                                !!this.selectionProvFees.selected[i] ? CustomValidators.dateGreaterThan(this.selectionProvFees.selected[i].toDate) : null
                            ]))
                            this.supplies.at(i).get(auxDateF).updateValueAndValidity();
                            break;

                        case 'MOD':
                            this.supplies.at(i).get(auxDateF).setErrors(null);
                            this.supplies.at(i).get(auxDateF).clearValidators();
                            this.supplies.at(i).get(auxDateF).setValidators(Validators.compose([
                                CustomValidators.required('Requerida'),
                                CustomValidators.dateLowerEqualThan(date)
                            ]))
                            if (this.supplies.at(i).get(this.SUPPLY_ORDER_DATE).value) {
                                this.supplies.at(i).get(auxDateF).setValidators(CustomValidators.dateLowerEqualThan(moment(moment(this.supplies.at(i).get(this.SUPPLY_ORDER_DATE).value).format('YYYY-MM-DD')).toDate()))
                            }
                            this.supplies.at(i).get(auxDateF).updateValueAndValidity();
                            break;

                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        i >= 0 ? this.supplies.at(i).get(auxDateF).updateValueAndValidity() : form.get(auxDateF).updateValueAndValidity();
    }

    addCustomValidatorsToDate(date: Date, i?: number) {
        const auxDateT = i >= 0 ? 'toDateBySupply' : 'toDate';
        const form: FormGroup = this.globalAction !== 'AUT' ? this.formSupplies : this.formAuthorization; // Select form, dates has the same name 'fromDate' and 'toDate'
        if (moment(date).isValid()) {
            switch (auxDateT) {
                case 'toDate':
                    form.get(auxDateT).setErrors(null);
                    form.get(auxDateT).clearValidators();
                    form.get(auxDateT).setValidators(Validators.compose([
                        CustomValidators.required('Requerida'),
                        CustomValidators.dateGreaterEqualThan(date)
                    ]));
                    form.get(auxDateT).updateValueAndValidity();
                    break;

                case 'toDateBySupply':
                    switch (this.globalAction) {
                        case 'UPD':
                            this.supplies.at(i).get(auxDateT).setErrors(null);
                            this.supplies.at(i).get(auxDateT).clearValidators();
                            this.supplies.at(i).get(auxDateT).setValidators(Validators.compose([
                                CustomValidators.required('Requerida'),
                                CustomValidators.dateGreaterEqualThan(date),
                                this.selectionProvFees.selected[i] ? CustomValidators.dateGreaterThan(this.selectionProvFees.selected[i].toDate) : null
                            ]));
                            this.supplies.at(i).get(auxDateT).updateValueAndValidity()
                            break;

                        case 'MOD':
                            this.supplies.at(i).get(auxDateT).setErrors(null);
                            this.supplies.at(i).get(auxDateT).clearValidators();
                            this.supplies.at(i).get(auxDateT).setValidators(Validators.compose([
                                CustomValidators.required('Requerida'),
                                CustomValidators.dateGreaterEqualThan(date),
                            ]));
                            if (this.supplies.at(i).get(this.SUPPLY_ORDER_DATE).value) {
                                this.supplies.at(i).get(auxDateT).setValidators(CustomValidators.dateGreaterEqualThan(moment(moment(this.supplies.at(i).get(this.SUPPLY_ORDER_DATE).value).format('YYYY-MM-DD')).toDate()))
                            }
                            this.supplies.at(i).get(auxDateT).updateValueAndValidity()
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
        }
        i >= 0 ? this.supplies.updateValueAndValidity() : form.updateValueAndValidity();
    }

    onChangeCheckKairos(i: number, isSelected: boolean) {
        if (isSelected) {
            this.supplies.at(i).get(this.PROFIT_MARGIN).reset();
            this.supplies.at(i).get(this.PROFIT_MARGIN).disable();
            this.supplies.at(i).get(this.FEE).reset();
            if (this.globalAction != 'FPR')
                this.supplies.at(i).get(this.FEE).disable();
            // Add FEE mandatory
            this.supplies.at(i).get(this.FEE).setValidators(CustomValidators.required('Valor requerido'));
            this.supplies.at(i).get(this.FEE).updateValueAndValidity();
        } else {
            this.supplies.at(i).get(this.PROFIT_MARGIN).enable();
            this.supplies.at(i).get(this.FEE).enable();
            if (this.globalAction == 'ADD' || this.globalAction == 'PRI') {
                this.supplies.at(i).get(this.FEE).setValidators(null)
                this.supplies.at(i).get(this.FEE).updateValueAndValidity();
            }
        }
    }

    providerSelected(provider: Provider, i?: number) {
        if (i != null && i != undefined) {
            this.rulesInputPROVIDER_FEE(i);
        } else {
            // WATCH!: this.globalAction=='PRI'
            this.oneProviderSelected = provider ? provider : null;
            for (let index = 0; index < this.supplies.length; index++) {
                this.rulesInputPROVIDER_FEE(index);
            }
        }
        this.formSupplies.updateValueAndValidity();
    }

    supplySelected(supply: Practice | string, i: number) {
        this.supplies.at(i).updateValueAndValidity();
        if (this.isSupplyPresent(supply)) {
            this.presentSupplyIndex = [...this.presentSupplyIndex, i];
        } else if (!this.isSupplyPresent(supply) && this.presentSupplyIndex.includes(i)) {
            let indexToRemove = this.presentSupplyIndex.findIndex(el => el == i);
            this.presentSupplyIndex.splice(indexToRemove, 1);
        }
        if (typeof (supply) != 'string') {
            if (this.suppliesSelected.length == 0) {
                this.suppliesSelected.push(supply);
            } else {
                this.suppliesSelected.splice(i, 1, supply);
            }
        }
        if (this.getIsMedicine(i)) {
            // If it is a medicine, kairos is true by default
            this.supplies.at(i).get(this.KAIROS).setValue(true);
            this.onChangeCheckKairos(i, true);
        } else {
            // If it is not a medicine, kairos is always false
            this.supplies.at(i).get(this.KAIROS).setValue(false);
            this.onChangeCheckKairos(i, false);
        }
    }

    isSupplyPresent(supply: Practice | string) {
        return this.provisionsDisplayedOnScreen?.filter(prov => (typeof (supply) != 'string' ? prov.practiceId == supply.id : prov.practice.name == supply) && prov.provisionFees.length > 0).length > 0
    }

    supplyExistHelp(i: number) {
        this.isOpen = !this.isOpen;
        this.indexOpen = i
    }

    checkInputsRules() {
        const length = this.globalAction === 'ADD' ? 1 : this.supplies.length;
        for (let index = 0; index < length; index++) {
            this.rulesInputPROVIDER_FEE(index);
            this.rulesFromToDateBYSupply(index);
            this.onChangeCheckKairos(index, this.supplies.at(index).get('fixedPrice').value);
            this.rulesInputAMOUNT_and_UNIT(index);
        }
    }

    rulesInputAMOUNT_and_UNIT(i: number) {
        switch (this.globalAction) {
            case 'MOD':
                // Supply has order
                if (this.supplies.at(i).get(this.SUPPLY_ORDER_DATE).value) {
                    this.supplies.at(i).get('amount').disable();
                    this.supplies.at(i).get('unit').disable();
                }
                break;

            default:
                break;
        }
    }

    rulesFromToDateBYSupply(i: number) {
        switch (this.globalAction) {
            case 'UPD':
                this.supplies.at(i).get(this.FROM_DATE_BY_SUPPLY).setValidators(CustomValidators.required('Requerida'));
                this.supplies.at(i).get(this.TO_DATE_BY_SUPPLY).setValidators(CustomValidators.required('Requerida'));
                break;

            case 'MOD':
                // Now rule is -> 1 ProvFee -> 1 Agreement -> 1 Order
                this.setDatesCustomValidators(this.supplies.at(i).get(this.FROM_DATE_BY_SUPPLY).value, this.supplies.at(i).get(this.TO_DATE_BY_SUPPLY).value, i)
                break;

            default:
                break;
        }
    }

    rulesInputPROVIDER_FEE(i: number) {
        let control: AbstractControl = this.globalAction === 'PRI' ? this.formSupplies.get('oneProviderId') : this.supplies.at(i).get('providerId');
        if (control.value > 0) {
            this.supplies.at(i).get(this.PROVIDER_FEE).enable()
        } else {
            this.supplies.at(i).get(this.PROVIDER_FEE).reset();
            this.supplies.at(i).get(this.PROVIDER_FEE).disable();
        }

        switch (this.globalAction) {
            case 'PRI':
                this.supplies.at(i).get(this.PROVIDER_FEE).setValidators(CustomValidators.minValue(0, 'Requerido'));
                let provisionFee = this.selectionProvFees.selected[i];
                if (provisionFee?.agreement?.providerFee != null && provisionFee?.agreement?.providerFee != undefined) {
                    this.supplies.at(i).get(this.PROVIDER_FEE).setValue(provisionFee.agreement.providerFee);
                }
                break;

            case 'MOD':
                if (this.supplyHasSomeOrderActive(this.selectionProvFees.selected[i])) {
                    this.supplies.at(i).get(this.PROVIDER_FEE).disable(); // Readonly
                }
                break;

            case 'FPR':
                this.supplies.at(i).get(this.PROVIDER_FEE).enable();
                break;

            default:
                break;
        }
    }

    setFromToDateBySupply(supply: ProvisionFee): _NewDates {

        const fD: Date = !!supply.toDate ? supply.fromDate : moment().toDate();
        const tD: Date = !!supply.toDate ? supply.toDate : moment().toDate();

        const startOfMonth = moment(fD).startOf('month').toDate();
        const endOfMonth = moment(fD).endOf('month').toDate();

        if (moment(startOfMonth).format('YYYY-MM-DD') == moment(fD).format('YYYY-MM-DD') && moment(endOfMonth).format('YYYY-MM-DD') == moment(tD).format('YYYY-MM-DD')) {

            const startOfNextMonth = moment(startOfMonth).add(1, 'M').toDate();
            const endOfNextMonth = moment(startOfNextMonth).endOf('month').toDate();

            return {
                newFromDate: startOfNextMonth,
                newToDate: endOfNextMonth
            }
        } else {
            let diff = moment.duration(moment(tD).diff(fD))
            diff = diff.add(1, 'day');
            return {
                newFromDate: moment(tD).add(1, 'day').toDate(),
                newToDate: moment(tD).add(diff).toDate()
            }
        }
    }

    onClickFromDateBySupply(i: number, event: MatDatepickerInputEvent<Date>) {
        this.onClickDateFrom(event, i)
    }

    onClickToDateBySupply(i: number, event: MatDatepickerInputEvent<Date>) {
        this.onClickDateTo(event, i)
    }

    getColspan(prov) {
        if (prov?.provisionFees?.length > 0) {
            //If its a normal provision, name column needs a single column space
            return 1
        }
        // If its a category name provision, it needs the width of the entire table (17 columns)
        return 17
    }

    groupByCategories() {
        let data = [...this.dataSource.data];
        this.dataSource.data = [];
        // Declare regex to test the practice codes and then classify the provisions
        let disposableRegex = /^IN/;
        let medicinesRegex = /^ME/;
        let equipmentsRegex = /^EQ/;

        // Sorts the provisions by their practice codes
        const group = data.sort((a, b) => {
            let aPracticeCode = a.practice.code;
            let bPracticeCode = b.practice.code;
            if (disposableRegex.test(aPracticeCode) && !disposableRegex.test(bPracticeCode)) {
                return -1
            } else if (disposableRegex.test(bPracticeCode) && !disposableRegex.test(aPracticeCode)) {
                return 1
            } else if (medicinesRegex.test(aPracticeCode) && !medicinesRegex.test(bPracticeCode)) {
                return -1
            } else if (!medicinesRegex.test(aPracticeCode) && medicinesRegex.test(bPracticeCode)) {
                return 1
            } else if (equipmentsRegex.test(aPracticeCode) && !equipmentsRegex.test(bPracticeCode)) {
                return -1
            } else if (!equipmentsRegex.test(aPracticeCode) && equipmentsRegex.test(bPracticeCode)) {
                return 1
            } else {
                return 0
            }
        })

        // Makes mock-up provisions to show the categories. These will be pushed into the datasource array.
        let disposablesProvision = {
            practice: {
                name: 'Descartables'
            },
            provisionFees: []
        }

        let medicinesProvision = {
            practice: {
                name: 'Medicamentos'
            },
            provisionFees: []
        }

        let equipmentsProvision = {
            practice: {
                name: 'Equipamientos'
            },
            provisionFees: []
        }

        let othersProvision = {
            practice: {
                name: 'Otros'
            },
            provisionFees: []
        }

        // Looks for first provision of each type and pushes the category mock-up provision above it.
        // If doesn't exist any provision for that category, pushes the mock-up provisions in order
        this.disposables = group.filter(prov => !!prov.practice.code && disposableRegex.test(prov.practice.code))
        let firstDisposable = this.disposables[0]
        !!firstDisposable ? group.splice(group.indexOf(firstDisposable), 0, disposablesProvision) : group.unshift(disposablesProvision)

        this.others = group.filter(prov => !!prov.practice.code && !equipmentsRegex.test(prov.practice.code) && !medicinesRegex.test(prov.practice.code) && !disposableRegex.test(prov.practice.code))
        let others = this.others[0]
        !!others ? group.splice(group.indexOf(others), 0, othersProvision) : group.push(othersProvision)

        this.equipments = group.filter(prov => !!prov.practice.code && equipmentsRegex.test(prov?.practice?.code))
        let firstEquipment = this.equipments[0]
        !!firstEquipment ? group.splice(group.indexOf(firstEquipment), 0, equipmentsProvision) : group.splice(group.indexOf(othersProvision), 0, equipmentsProvision)

        this.medicines = group.filter(prov => !!prov.practice.code && medicinesRegex.test(prov?.practice?.code))
        let firstMedicine = this.medicines[0];
        !!firstMedicine ? group.splice(group.indexOf(firstMedicine), 0, medicinesProvision) : group.splice(group.indexOf(equipmentsProvision), 0, medicinesProvision)

        this.dataSource.data = group;
    }

    toggleExpandedElement(row: Provision) {
        // Toggles the correct variable for the category clicked
        if (row.practice.name == 'Descartables') {
            this.expandedDisposables = !this.expandedDisposables
        } else if (row.practice.name == 'Medicamentos') {
            this.expandedMedicines = !this.expandedMedicines
        } else if (row.practice.name == 'Equipamientos') {
            this.expandedEquipments = !this.expandedEquipments
        } else {
            this.expandedOthers = !this.expandedOthers
        }
        this.updateWidth();
    }

    getIsExpanded(row: Provision) {
        // Returns the correct category for every row to check if it is collapsed or expanded.
        let disposableRegex = /^IN/;
        let medicinesRegex = /^ME/;
        let equipmentsRegex = /^EQ/;

        if (disposableRegex.test(row.practice?.code) || row.practice.name == 'Descartables') {
            return this.expandedDisposables
        } else if (medicinesRegex.test(row.practice?.code) || row.practice.name == 'Medicamentos') {
            return this.expandedMedicines
        } else if (equipmentsRegex.test(row.practice?.code) || row.practice.name == 'Equipamientos') {
            return this.expandedEquipments
        } else {
            return this.expandedOthers
        }
    }

    onClickCancel() {
        this._dialogRef.close();
        this.dialog.closeAll();
    }

    onClickConfirm() {
        switch (this.globalAction) {
            case 'PRI':
                this.openAddSupplyDialog('PRI', this.selectedSupplies);
                break;

            case 'AUT':
                this._dialogRef.close();
                this.openAddSupplyDialog('AUT', this.selectedSupplies);
                break;

            default:
                break;
        }
    }

    calculateTotalValue(i: number) {
        let margin = this.supplies.at(i).get(this.PROFIT_MARGIN).value;
        let cost = this.supplies.at(i).get(this.PROVIDER_FEE).value;
        if (cost != null && cost != 0 && margin != null) {
            // this.supplies.at(i).get(this.FEE).setValue(cost + (cost * margin / 100).toFixed(2));
            const result = (Math.ceil((cost + (cost * margin / 100)) * 100) / 100).toFixed(2);
            this.supplies.at(i).get(this.FEE).setValue(result);
            this.supplies.at(i).get(this.FEE).markAsPristine();
        } else {
            this.supplies.at(i).get(this.FEE).reset();
            if (cost == null) {
                this.supplies.at(i).get(this.FEE).disable();
            }
        }
    }

    calculateMargin(i: number) {
        let cost = this.supplies.at(i).get(this.PROVIDER_FEE).value;
        let fee = this.supplies.at(i).get(this.FEE).value;
        if (cost != 0 && cost != null && fee != null) {
            this.supplies.at(i).get(this.PROFIT_MARGIN).setValue((((fee - cost) / cost) * 100).toFixed(2));
            this.supplies.at(i).get(this.PROFIT_MARGIN).markAsPristine();
        } else {
            this.supplies.at(i).get(this.PROFIT_MARGIN).reset();
            this.supplies.at(i).get(this.PROFIT_MARGIN).disable();
        }
    }

    onChangeCost(i) {
        if (!this.supplies.at(i).get(this.KAIROS).value) {
            this.supplies.at(i).get(this.PROFIT_MARGIN).enable();
            this.supplies.at(i).get(this.FEE).enable();
        }
        let cost = this.supplies.at(i).get(this.PROVIDER_FEE).value;
        let fee = this.supplies.at(i).get(this.FEE).value;
        let margin = this.supplies.at(i).get(this.PROFIT_MARGIN).value;
        if (cost == 0) {
            this.supplies.at(i).get(this.PROFIT_MARGIN).reset();
            this.supplies.at(i).get(this.PROFIT_MARGIN).disable();
        }
        if ((margin != 0 && margin != null && (fee == 0 || fee == null)) || this.supplies.at(i).get(this.PROFIT_MARGIN).dirty) {
            // If cost is changed and there isn't fee or profit margin is the last touched field, it calculates fee
            this.calculateTotalValue(i)
        } else if ((fee != 0 && fee != null && (margin == 0 || margin == null)) || this.supplies.at(i).get(this.FEE).dirty) {
            // If there isn't margin or fee is the last touched field, it calculates margin
            this.calculateMargin(i)
        } else if ((this.supplies.at(i).get(this.FEE).pristine && this.supplies.at(i).get(this.PROFIT_MARGIN).pristine) && (fee != 0 && fee != null)) {
            // If there is margin and fee and both of them are untouched, it calculates margin
            this.calculateMargin(i)
        }
    }

    deleteSupply(provFees: ProvisionFee[]) {
        this.openMessageDialogComponent('Se eliminará el o los insumos seleccionados. Esta acción no se puede revertir.');
        this._dialogRef.componentInstance.ok.subscribe(result => {
            if (result) {
                let provFeesWithOrders = provFees.filter(provFee => provFee.orders?.length > 0 && !!provFee.orders.find(or => or.state.id != this.ORDERS_STATES.CANCELADO));
                if (provFeesWithOrders.length == 0) {
                    this.confirmDeleteSupplies(provFees)
                } else {
                    this.message = 'Los siguientes insumos son parte de un pedido y no se pueden eliminar';
                    this.arrayTable = this.getProvisionFeesDynamicTableWithActiveOrders(this.getProvisionFeesWithActiveOrder(provFeesWithOrders))
                    this.txtButtonCancel = 'OK';
                    this.showButtonACEPTAR = false;
                    this.openDialog('Atención', this.popUpMessage, { minWidth: '30%', maxHeight: '70vh' }, () => { })
                }
            }
        })
    }

    openMessageDialogComponent(message) {
        this._dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            maxHeight: '20%',
            maxWidth: '50vh',
            data: {
                message: message,
                icon: 'WarningCircle',
                disableConfirm: true,
            }
        });
    }

    confirmDeleteSupplies(provFees: ProvisionFee[]) {
        let itemsToDelete = provFees.flatMap(provFee => this.orders.flatMap(or => or.items.filter(it => it.provisionFeeId == provFee.id)));
        let successMsg = () => {
            this.swalWithCustomizeButtons.fire({
                text: 'Se eliminó el o los insumos seleccionados',
                icon: 'success'
            }).then(() => {
                this._dialogRef.close();
                if (itemsToDelete != null && itemsToDelete.length > 0) {
                    this.casesFacade.deleteOrderItems(itemsToDelete, this.orders);
                }
            })
        }
        this.provisionsFacade.deleteSupplies(provFees).subscribe(
            res => {
                successMsg()
                this.selectionProvFees.clear()
                this.provisionsFacade.setFiltersOnProvisions(this.updateFilter())
            }
        )
    }

    /**
     *
     * @param i item number filtered state <> CANCELADO
     * @returns orderId
     */
    getIdOrder(i: number): number {
        return this.selectedSupplies ? (this.selectedSupplies[i]?.orders?.find(order => order.state.id != this.ORDERS_STATES.CANCELADO))?.id : -1;
    }

    getOrderDate(supply: ProvisionFee): Date {
        const order: Partial<Order>[] = supply?.orders?.filter(or => or.state.id != this.ORDERS_STATES.CANCELADO)
        if (order?.length > 0) {
            return order[0].orderDate; // Only exist one order date
        } else {
            return null;
        }
    }

    isSomeSupplyINOrder(): boolean {
        this.arrayIsSomeSupplyINOrder
        this.suppliesWithSomeOrderActive = 0;
        this.suppliesWithSomeOrderActive = this.selectionProvFees.selected?.filter(supply => this.supplyHasSomeOrderActive(supply)).length
        return this.selectionProvFees.selected?.some(supply => this.supplyHasSomeOrderActive(supply));
    }

    supplyHasSomeOrderActive(supply: ProvisionFee): boolean {
        return supply ? supply.orders?.filter(or => or.state.id != this.ORDERS_STATES.CANCELADO).length > 0 : false;
    }

    isSomeSupplyWithoutToDate() {
        this.suppliesWithToDateNull = 0;
        this.suppliesWithToDateNull = this.selectionProvFees.selected?.filter(sup => sup.toDate == null).length;
        return this.selectionProvFees.selected?.some(supply => supply.toDate == null)
    }

    isSomeSupplyWithoutProvider() {
        this.suppliesWithProviderNull = 0;
        this.suppliesWithProviderNull = this.selectionProvFees.selected?.filter(sup => !sup.agreement).length;
        return this.selectionProvFees.selected?.some(supply => !supply.agreement);
    }

    disabledProvider(i: number): boolean {
        if (this.globalAction === 'FPR') return true;
        return this.selectedSupplies && this.globalAction != 'ADD' ? this.supplyHasSomeOrderActive(this.selectedSupplies[i]) : false;
    }

    getTotalsByCategory(category: string) {
        let totalDisposables = 0;
        let totalMedicines = 0;
        let totalEquipments = 0;
        let totalOthers = 0;

        if (category == 'Descartables') {
            if (this.disposables.length > 0) {
                this.disposables.forEach(sup => {
                    totalDisposables += sup.provisionFees.length;
                })
            }
            return totalDisposables
        }

        if (category == 'Medicamentos') {
            if (this.medicines.length > 0) {
                this.medicines.forEach(sup => {
                    totalMedicines += sup.provisionFees.length;
                })
            }
            return totalMedicines
        }

        if (category == 'Equipamientos') {
            if (this.equipments.length > 0) {
                this.equipments.forEach(sup => {
                    totalEquipments += sup.provisionFees.length
                })
            }
            return totalEquipments
        }

        if (category == 'Otros') {
            if (this.others.length > 0) {
                this.others.forEach(sup => {
                    totalOthers += sup.provisionFees.length
                })
            }
            return totalOthers
        }
        return 0;
    }

    unCheckCompleteCategory(provisions: Provision[]) {
        provisions.forEach(prov => prov.provisionFees.forEach(provFee => {
            if (this.selectionProvFees.selected.includes(provFee)) {
                this.selectionProvFees.deselect(provFee)
                if (this.provisionFeesWithBackGroundColor.has(provFee.id)) {
                    this.provisionFeesWithBackGroundColor.delete(provFee.id);
                }
            }
        }))
    }

    checkCompleteCategory(provisions: Provision[]) {
        provisions.forEach(prov => {
            prov.provisionFees.forEach(provFee => {
                this.selectionProvFees.select(provFee)
            })
        })
    }

    checkAllByCategory(event: MatCheckboxChange, category: string) {
        if (event.checked) {
            switch (category) {
                case 'Descartables':
                    this.checkCompleteCategory(this.disposables);
                    break;
                case 'Medicamentos':
                    this.checkCompleteCategory(this.medicines);
                    break;
                case 'Equipamientos':
                    this.checkCompleteCategory(this.equipments);
                    break;
                case 'Otros':
                    this.checkCompleteCategory(this.others);
                    break;
            }
        } else {
            switch (category) {
                case 'Descartables':
                    this.unCheckCompleteCategory(this.disposables);
                    break;
                case 'Medicamentos':
                    this.unCheckCompleteCategory(this.medicines);
                    break;
                case 'Equipamientos':
                    this.unCheckCompleteCategory(this.equipments);
                    break;
                case 'Otros':
                    this.unCheckCompleteCategory(this.others);
                    break;
            }
        }
        this.provisionsFacade.setFiltersOnProvisions(this.updateFilter());
    }

    isAllCheckedByCategory(category: string): boolean {
        switch (category) {
            case 'Descartables':
                let provFeesDisposables = this.disposables.flatMap(prov => { return [...prov.provisionFees] })
                return provFeesDisposables.every(provFee => {
                    return this.selectionProvFees.selected.includes(provFee)
                }) && provFeesDisposables.length > 0;
            case 'Medicamentos':
                let provFeesMedicines = this.medicines.flatMap(prov => { return [...prov.provisionFees] })
                return provFeesMedicines.every(provFee => {
                    return this.selectionProvFees.selected.includes(provFee)
                }) && provFeesMedicines.length > 0;
            case 'Equipamientos':
                let provFeesEquipments = this.equipments.flatMap(prov => { return [...prov.provisionFees] })
                return provFeesEquipments.every(provFee => {
                    return this.selectionProvFees.selected.includes(provFee)
                }) && provFeesEquipments.length > 0;
            case 'Otros':
                let provFeesOthers = this.others.flatMap(prov => { return [...prov.provisionFees] })
                return provFeesOthers.every(provFee => {
                    return this.selectionProvFees.selected.includes(provFee)
                }) && provFeesOthers.length > 0;
            default:
                return undefined
        }
    }
    updateWidth() {
        this.cdr.detectChanges();
        this.reactiveWidth = document?.getElementById('nameColumn')?.getBoundingClientRect().width; //getBoundingClientRect allows to get the exact width of the element
        this.cdr.markForCheck();
    }

    generateBudgetPDF(supplies, isPdf: boolean) {
        let suppliesForBudget = supplies.map(sup => {
            let practiceForSupply = this.provisionsDisplayedOnScreen.find(prov => prov.id == sup.provisionId).practice
            let provFee = { ...sup, practice: practiceForSupply }
            delete provFee.orders
            delete provFee.agreement
            delete provFee.invoiced
            delete provFee.debited
            delete provFee.delivered
            delete provFee.total
            delete provFee.totalAmount
            delete provFee.totalDebited
            delete provFee.totalDelivered
            delete provFee.totalInvoiced
            delete provFee.totalRealized
            delete provFee.totalValue
            delete provFee.userId
            delete provFee.provider
            delete provFee.authorization
            return provFee
        })
        let budgetBody: BudgetBody = {
            provisionFees: suppliesForBudget,
            patientId: this.case?.patient?.id,
            financierId: this.case?.financier?.id,
            caseId: this.case?.id,
            isPdf: isPdf
        }
        this.provisionsFacade.generateBudgetPDF(budgetBody).subscribe(res => this.fileUtilitiesService.downloadFile(res))
    }

    getIsMedicine(index: number) {
        return this.globalAction != 'ADD' ? this.selectionProvFees?.selected[index]?.practice.code?.startsWith('ME') : this.suppliesSelected[index]?.code?.startsWith('ME')
    }

    onClickAuthorization(provisionFee: ProvisionFee) {
        if (this.generalService.getAuthorizationState(provisionFee) !== "NO AUTORIZADO") {
            this.showAuthEvolution(provisionFee.authorization);
        }
    }
    showAuthEvolution(auth) {
        // Find evolution
        this.evolutionsFacade.loadEvolutionsById(auth.evolutionId).subscribe(evol => {
            this._bottomSheet.open(EvolutionsDialogComponent, {
                panelClass: 'bottomSheetPanelClass',
                data: {
                    evolutions: [evol],
                    attentions: [],
                    name: this.case.patient.name,
                    surname: this.case.patient.surname,
                    expanded: true
                },
            });
        })
    }


    generateQuotePDF(supplies, isPdf) {
        let suppliesForQuote = supplies.map(sup => {
            let practiceForSupply = this.provisionsDisplayedOnScreen.find(prov => prov.id == sup.provisionId).practice
            let provFee = { ...sup, practice: practiceForSupply }
            delete provFee.orders
            delete provFee.agreement
            delete provFee.invoiced
            delete provFee.debited
            delete provFee.delivered
            delete provFee.total
            delete provFee.totalAmount
            delete provFee.totalDebited
            delete provFee.totalDelivered
            delete provFee.totalInvoiced
            delete provFee.totalRealized
            delete provFee.totalValue
            delete provFee.userId
            delete provFee.provider
            delete provFee.authorization
            return provFee
        })

        let quoteBody: QuoteBody = {
            provisionFees: suppliesForQuote,
            patientId: this.case?.patient?.id,
            isPdf: isPdf
        }

        this.provisionsFacade.generateQuotePDF(quoteBody).subscribe(res => this.fileUtilitiesService.downloadFile(res))
    }

    findProvisionFeeINDatasource(id: number): ProvisionFee {
        return this.dataSource.data.flatMap(prov => prov.provisionFees).find(pF => pF.id === id)
    }

    supplyHasToDateNull(supply: ProvisionFee): boolean {
        return supply.toDate == null;
    }

    supplyHasProviderNull(supply: ProvisionFee): boolean {
        return !(supply.agreement);
    }

    addRowWithBackGroundColor(supply: ProvisionFee) {
        this.provisionFeesWithBackGroundColor.add(supply.id);
    }

    isRowWithBackGroundColor(id: number): boolean {
        return this.provisionFeesWithBackGroundColor.has(id);
    }

    shouldShowButton(index: number): boolean {
        const isLastSupply = (index + 1) === this.formSupplies['controls']['supplies']['controls'].length;
        const action = this.globalAction;
        return isLastSupply && (action !== 'PRI' && action !== 'FPR' && action !== 'AUT') && action !== 'MOD';
    }

    // Paints row if error -> backgroundcolor is red
    rowsWithErrorsForCreateOrder() {
        let supplies = this.dataSource.data.map(prov => { return { ...prov } }).flatMap(prov => prov.provisionFees)
        if (supplies.length > 0 && this.selectionProvFees.selected.length > 0) {
            supplies.forEach(supply => {
                if ((this.selectionProvFees.selected.some(pF => pF.id == supply.id))) {
                    if ((!this.isSameProviderSelected()
                        || this.supplyHasSomeOrderActive(supply)
                        || this.supplyHasToDateNull(supply)
                        || this.supplyHasProviderNull(supply)
                        || this.supplyWithoutFeeOrProviderFee(supply)
                        || this.supplyWithOrderInAnotherTimePeriod(supply))) {
                        this.addRowWithBackGroundColor(supply);
                    }
                } else {
                    if (this.provisionFeesWithBackGroundColor.has(supply.id)) {
                        this.provisionFeesWithBackGroundColor.delete(supply.id);
                    }
                }

            });
        } else {
            this.provisionFeesWithBackGroundColor.clear();
        }
    }

    supplyWithoutFeeOrProviderFee(supply: ProvisionFee): boolean {
        return (supply.fee == null || supply.agreement?.providerFee == null) && !supply.fixedPrice;
    }

    supplyWithOrderInAnotherTimePeriod(supply: ProvisionFee): boolean {
        let ordersForSupply = this.allOrdersWithoutFilter.filter(or => or.items.some(it => it.provisionFeeId == supply.id));
        let rangeDate = new momentRange.DateRange(this.historyFromDate, this.historyToDate);
        return ordersForSupply.some(or => or.state.id != this.ORDERS_STATES.CANCELADO && !rangeDate.contains(or.orderDate));
    }

    isSomeSupplyWithoutFeeOrProviderFee() {
        this.suppliesWithoutFeeOrProviderFee = 0;
        this.suppliesWithoutFeeOrProviderFee = this.selectionProvFees.selected?.filter(supply => (supply.fee == null && !supply.fixedPrice) || supply.agreement?.providerFee == null).length;
        return this.selectionProvFees.selected?.some(supply => (supply.fee == null && !supply.fixedPrice) || supply.agreement?.providerFee == null)
    }

    isSomeSupplyWithOrderInAnotherTimePeriod() {
        this.suppliesWithOrderInAnotherTimePeriod = 0;
        let dateRange = new momentRange.DateRange(this.historyFromDate, this.historyToDate);
        let ordersInAnotherTimePeriodProvFeeIds = this.allOrdersWithoutFilter.filter(or => or.state.id != this.ORDERS_STATES.CANCELADO && !dateRange.contains(moment(or.orderDate))).flatMap(or => or.items.map(i => i.provisionFeeId));
        this.selectionProvFees.selected.forEach(pF => {
            if (ordersInAnotherTimePeriodProvFeeIds.includes(pF.id)) {
                this.suppliesWithOrderInAnotherTimePeriod += 1;
            }
        })
        return this.suppliesWithOrderInAnotherTimePeriod > 0
    }

    createOrderButtonDisabled() {
        return !this.case.active
            || this.selectedOptionSupply == 'inactive'
            || this.selectionProvFees?.selected?.length == 0
            || !this.isSameProviderSelected()
            || this.isSomeSupplyINOrder()
            || this.isSomeSupplyWithoutToDate()
            || this.isSomeSupplyWithoutProvider()
            || this.isSomeSupplyWithoutFeeOrProviderFee()
            || this.isSomeSupplyWithOrderInAnotherTimePeriod()
    }

    isSomeSupplyWithoutUnit() {
        return this.selectionProvFees.selected.some(provFee => provFee?.frequency?.unit == null || provFee?.frequency?.amount == null)
    }

    getSuppliesFormGroup(index: number): FormGroup {
        return this.supplies.at(index) as FormGroup;
    }

    getItemsFormArrayFormGroup(index: number): FormGroup {
        return this.itemsFormArray.at(index) as FormGroup;
    }

    trackByProvisionFee(index: number, item: any): any {
        return item.id;
      }
}
