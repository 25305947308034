<mat-card class="flex-100">
    <mat-card-header class="pl-3 ml-0 align-space-between-center">
        <mat-card-title class="headline-1-8 mb-0">{{this.title}}</mat-card-title>
        <div class="flex-row gap-15px">
            <ng-content select="[controlButtons]"></ng-content>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="flex-column">
            <ng-content select="[filterInputs]"></ng-content>
            <div class="flex-column pxs-2" [ngClass]="{'mat-elevation-z1':this.elevated, 'pxs-3':this.elevated}">
                <ng-content select="[table]"></ng-content>
                <mat-paginator *ngIf="showPaginator" [pageIndex]="this.metaData?.page" [pageSize]="this.metaData?.size"
                    [length]="this.metaData?.rows" class="flex-100" pageSize="10" [pageSizeOptions]="[5, 10, 25, 100]"
                    showFirstLastButtons (page)="onPaginatorEvent($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
