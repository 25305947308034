import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Practice } from '../../../../shared/models/practice';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { ProvisionsFrequencyUnit } from '../../../../core/enums/provisionsFrequencyUnit';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { Subscription } from 'rxjs';
import { Arrangement } from 'src/app/shared/models/arrangement';
import { ArrangementsFacade } from 'src/app/abstraction/arrangements.facade';
import { AlertService } from '../../../../core/services/alert.service';
import { PostProvisionBody } from 'src/app/core/services/provisions.service';

@Component({
    selector: 'app-admissions-provisions',
    templateUrl: './admissions-provisions.component.html',
    styleUrls: ['./admissions-provisions.component.scss']
})
export class AdmissionsProvisionsComponent implements OnInit {

    public readonly PRACTICE = 'practiceId';
    public readonly PRACTICE_SEARCHING = 'practiceSearching';
    public readonly AMOUNT = 'amount';
    public readonly UNIT = 'unit';
    public readonly DURATION_AMOUNT = 'durationAmount';
    public readonly DURATION_UNIT = 'durationUnit';
    public readonly FROM_DATE = 'fromDateProv';
    public readonly TO_DATE = 'toDateProv';
    public readonly TOTAL_AMOUNT = 'totalAmount';
    public readonly ARRANGEMENT = 'arrangement';
    public readonly ARRANGEMENT_SEARCHING = 'arrangementSeaarching';

    @Output() provisionsData = new EventEmitter<PostProvisionBody[]>();
    @Output() formProvisionsDataValid = new EventEmitter<boolean>();

    @Output() arrangementsData = new EventEmitter<Arrangement>();

    btn_addPractice_disabled: boolean = false;

    practices: Practice[];
    practicesFiltered: Practice[];
    arrangements: Arrangement[];
    activeArrangements: Arrangement[];

    provisionsFrequencyUnit = [
        {
            id: ProvisionsFrequencyUnit.DIA,
            name: 'Dia',
        },
        {
            id: ProvisionsFrequencyUnit.SEMANA,
            name: 'Semana',
        },
        // {
        //     id: ProvisionsFrequencyUnit.QUINCENA,
        //     name: 'Quincena',
        // },
        {
            id: ProvisionsFrequencyUnit.MES,
            name: 'Mes',
        },
        // {
        //     id: ProvisionsFrequencyUnit.ANIO,
        //     name: 'Año',
        // },
    ];

    arrangementForm: FormGroup;
    _arrangements: Subscription;

    practicesForm: FormGroup;
    _practices: Subscription;
    arrangementSelected: Arrangement;

    public get practiceS(): FormArray {
        return this.practicesForm.get("practiceS") as FormArray
    }

    constructor(
        private fb: FormBuilder,
        private nomenclatorFacade: NomenclatorFacade,
        private arrangementsFacade: ArrangementsFacade,
        private alertService: AlertService,
    ) { }

    ngOnInit(): void {

        this._practices = this.nomenclatorFacade.getPractices$().subscribe( practices => {
            this.practices = practices?.filter((practice) => !practice.specialty?.isSupply);
            this.practicesFiltered = this.practices;
        })

        this._arrangements = this.arrangementsFacade.getArrangements$().subscribe( arrangements => {
            this.arrangements = arrangements;
            this.activeArrangements = arrangements?.filter(arr => !!arr.active);
        })

        this.arrangementForm = this.createArrangementForm();

        this.practicesForm = this.fb.group({
            practiceS: this.fb.array([])
        });

        // PRACTICES
        this.practicesForm.statusChanges.subscribe(formPractices => {
            this.btn_addPractice_disabled = formPractices === 'INVALID' ? true : false;
        })

        this.practicesForm.valueChanges.subscribe(practices => {

            if (this.practicesForm.status === 'VALID') {
                this.provisionsData.emit( this.practicesForm.value.practiceS.map( ({practiceSearching, radioButton, ...newPractice }) => {
                    const frequency = {
                                        amount:newPractice['amount'],
                                        unit:newPractice['unit']
                                    }
                    newPractice = { ...newPractice, frequency:frequency }; // Add frecuency
                    const { amount, unit, ...editNewPractice } = newPractice;
                    return editNewPractice
                }))
                this.formProvisionsDataValid.emit( true );
            } else {
                this.provisionsData.emit( [] );
                this.formProvisionsDataValid.emit( false );
            }
        })
        this.addPractice();
    }

    createArrangementForm(){
        return this.fb.group({
            [this.ARRANGEMENT]: [],
            [this.ARRANGEMENT_SEARCHING]: [],
        })
    }

    newPractice(): FormGroup {
        return this.fb.group({
            [this.PRACTICE]: ['', [CustomValidators.required('Práctica requerida')]],
            [this.PRACTICE_SEARCHING]: [''],
            [this.AMOUNT]: ['', ], // [ CustomValidators.required('Frecuencia requerida') ]
            [this.UNIT]: ['', ], // [ CustomValidators.required('Periodo requerido') ]
            radioButton: new FormControl({ value: 'duration', disabled: false }, ), // [CustomValidators.required('Seleccione Duración o Fecha')]
            [this.DURATION_AMOUNT]: ['', ],    // By default // [CustomValidators.required('Cantidad requerida')]
            [this.DURATION_UNIT]: ['', ],       // By default // [CustomValidators.required('Periodo requerido')]
            [this.FROM_DATE]: [''],
            [this.TO_DATE]: [''],
            [this.TOTAL_AMOUNT]: ['', ], // [CustomValidators.required('Cantidad requerida')]
        })
    }

    addPractice() {
        this.practiceS.push(this.newPractice());
    }

    removePractice(i: number) {

        if (this.practiceS['controls'].length == 1) {
            // this.practiceS['controls'][i].reset();
            // this.practiceS['controls'][i]['controls'].radioButton.value = 'duration';
            this.practiceS.removeAt(i); // Else radiobutton fail
            this.practiceS.push(this.newPractice());
        } else {
            this.practiceS.removeAt(i);
        }
    }

    calculateIdPractice(element: Practice) {
        return element ? element.id : null;
    }

    // It's necessary arrow function
    calculateNamePractice = (element: Practice) => {
      if(!!this.arrangementSelected) {
        let practices = this.arrangementSelected?.practiceArrangements?.filter(pr => pr.practiceId === element.id);
        let practice = practices[practices?.length - 1]

        if(!!practice?.code) {
            return element ? element.name + ' #' + practice.code : null;
        }
        return element ? element.name + ' #' + element.code : null;
      }
      return element ? element.name + ' #' + element.code : null;
    }

    calculateIdArrangement(element: Arrangement) {
        return element ? element.id : null;
    }
    calculateNameArrangement(element: Arrangement) {
        return element ? element.name : null;
    }

    onSelectPractice(practiceId: number) {
        // this.formAddProvision.get(this.FEE).reset();
        // console.log(practiceId);
        // console.log(this.formAddProvision.value);
        // console.log(this.formAddProvision.get(this.FEE).value);
        // this.tryGetArrangementItem(this.formAddProvision);
    }

    clickRadiobutton(i: number, event) {
        switch (event.value) {
            case 'duration':
                    // this.practiceS['controls'][i].get('durationAmount').setValidators([CustomValidators.required('Cantidad requerida')]);
                    // this.practiceS['controls'][i].get('durationUnit').setValidators([CustomValidators.required('Periodo requerido')]);
                    // this.practiceS['controls'][i].get('fromDate').setValidators(null);
                    // this.practiceS['controls'][i].get('toDate').setValidators(null);
                break;

            case 'date':
                    // this.practiceS['controls'][i].get('fromDate').setValidators([CustomValidators.required('Fecha requerida')]);
                    // this.practiceS['controls'][i].get('toDate').setValidators([CustomValidators.required('Fecha requerida')]);
                    // this.practiceS['controls'][i].get('durationAmount').setValidators(null);
                    // this.practiceS['controls'][i].get('durationUnit').setValidators(null);
                break;

            default:
                // Nothing
                break;
        }
        // this.practiceS['controls'][i].get('fromDate').updateValueAndValidity();
        // this.practiceS['controls'][i].get('toDate').updateValueAndValidity();
        // this.practiceS['controls'][i].get('durationAmount').updateValueAndValidity();
        // this.practiceS['controls'][i].get('durationUnit').updateValueAndValidity();
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._practices.unsubscribe();
    }

    onSelectArrangement(event){
        if (!!event){
            if (!this.arrangementSelected || (!!this.arrangementSelected && this.validateChangeArrangement(event))){
                let arrangement = this.arrangements.find(arr => arr.id == event);
                if (!!arrangement.arrangementId && (!arrangement.practiceArrangements || arrangement.practiceArrangements.length == 0)){
                    let arrangementRelated = this.arrangements.find(arr => arr.id == arrangement.arrangementId);
                    if (!!arrangementRelated){
                        const practicesIds = Array.from(new Set(arrangementRelated.practiceArrangements.filter( arrDetail => arrDetail.active ).map(arrDetail => arrDetail.practiceId))) // Filter practices active=1
                        this.practicesFiltered = this.practices.filter(practice => practicesIds.includes(practice.id));
                    }
                } else {
                    const practicesIds = Array.from(new Set(arrangement.practiceArrangements.filter( arrDetail => arrDetail.active ).map( arrDetail => arrDetail.practiceId ))) // Filter practices active=1
                    this.practicesFiltered = this.practices.filter(practice => practicesIds.includes(practice.id));
                }
                this.arrangementSelected = arrangement;
                this.arrangementsData.emit(this.arrangementSelected);
            } else {
                this.alertService.openInfo("El convenio no incluye una o más de las prácticas seleccionadas. Corrija el campo de prácticas y vuelva a intentar");
                this.arrangementForm.get(this.ARRANGEMENT).setValue(this.arrangementSelected.id.toString());
            }
        } else {
            this.practicesFiltered = this.practices;
            this.arrangementSelected = undefined;
            this.arrangementsData.emit(this.arrangementSelected);
        }
    }

    validateChangeArrangement(event){
        const practicesSelected = this.practicesForm.value.practiceS.map(control => control.practiceId).filter(pid => !!pid && pid != '');
        const arrangement = this.arrangements.find(arr => arr.id == event);
        let letChangeArrangement = true;
        practicesSelected?.forEach(ps => {
            if (!arrangement.practiceArrangements?.find(pa => pa.practiceId == ps)){
                letChangeArrangement = false;
            }
        })
        return letChangeArrangement;
    }

    getPracticeSFormGroup(index: number): FormGroup {
        return this.practiceS.at(index) as FormGroup;
    }
}
