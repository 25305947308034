import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import mime from 'mime';
import { AttentionStates } from 'src/app/core/enums/attentionsStates';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Attention } from 'src/app/shared/models/attention';
import { StateAttention } from 'src/app/shared/models/stateAttention';
import { AttentionsFacade } from 'src/app/abstraction/attentions.facade';
import { Subscription } from 'rxjs';
import { CategoryOfEvolution } from '../../../../shared/models/categoryOfEvolution';
import { CategoriesofEvolutionsFacade } from '../../../../abstraction/categories-of-evolutions.facade';
import { CategoriesOfEvolutionsQPS } from '../../../../core/services/categories-of-evolutions.service';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { CaseDateFilters, CasesFacade } from 'src/app/abstraction/cases.facade';
import * as moment from 'moment';
import { FilePondComponent } from 'ngx-filepond';

@Component({
    selector: 'app-dialog-evolution',
    templateUrl: './dialog-evolution.component.html',
    styleUrls: ['./dialog-evolution.component.scss'],
})
export class DialogEvolutionComponent implements OnInit {
    @ViewChild('myPond') myPond: FilePondComponent;

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Arrastre el archivo o haga click <u>aquí</u>',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 150
    };

    isNextDay: boolean = false;
    _input: Subscription;
    PRACTICE_QUALITY_CONTROL_ID = '10000047';
    _mda: Subscription;
    loadingConfirm = false;
    financierVisibility;
    providersVisibility;
    ATTENTION_STATES = AttentionStates;
    EVOLUTIONS_TYPE = EvolutionsType;
    photoEvolutionB64: string;
    public readonly NO_MODIFICAR_ESTADO_ID = 0;
    attention: Attention;
    loadingAddFile: boolean = false;
    attentionStates: StateAttention[] = [
        {
            id: this.NO_MODIFICAR_ESTADO_ID,
            name: 'No modificar estado',
        },
        {
            id: AttentionStates.AGENDADA,
            name: 'Agendada',
        },
        {
            id: AttentionStates.EN_CURSO,
            name: 'En curso',
        },
        {
            id: AttentionStates.NO_REALIZADA,
            name: 'No realizada',
        },
        {
            id: AttentionStates.REALIZADA,
            name: 'Realizada',
        },
    ];
    private defaultTextCharged: string;
    private changeTextDescription: boolean;
    durationNeeded: boolean = this.data.attention.practice.restriction.requiredTime === true || this.data.attention.practice.restriction.requiredToDate === true;
    _historyModeDate: Subscription;
    historyModeDate: Date;
    rangeDate: CaseDateFilters;
    showAlertMsg: boolean = false;

    /**
     * ATTENTION FORM
     */
    public readonly STATE_ATTENTION = 'state_attention';
    public readonly DATE_IN_REAL = 'dateInReal';
    public readonly DATE_OUT_REAL = 'dateOutReal';
    public readonly REAL_TIME_FROM = 'realTimeFrom';
    public readonly REAL_TIME_TO = 'realTimeTo';
    public readonly QUANTITY = 'quantity';
    attentionStateForm: FormGroup;
    evolutionCategories: CategoryOfEvolution[];

    get stateAttention() {
        return this.attentionStateForm.get(this.STATE_ATTENTION);
    }

    /**
     * EVOLUTION FORM
     */
    public readonly EVOLUTION_TEXT = 'evolution_text';
    public readonly EVOLUTION_TYPE = 'evolution_type';
    evolutionForm: FormGroup;

    get quantity() {
        return this.attentionStateForm.get(this.QUANTITY).value;
    }

    set quantity(hours: number) {
        hours != null ? this.attentionStateForm?.controls[this.QUANTITY].setValue(hours)
            : this.attentionStateForm?.controls[this.QUANTITY].reset();
    }

    get evolutionText() {
        return this.evolutionForm.get(this.EVOLUTION_TEXT);
    }

    get evolutionType() {
        return this.evolutionForm.get(this.EVOLUTION_TYPE);
    }

    get realTimeFrom() {
        return this.attentionStateForm.get(this.REAL_TIME_FROM).value;
    }

    set realTimeFrom(hourAndMinute: string) {
        hourAndMinute != null ? this.attentionStateForm?.controls[this.REAL_TIME_FROM].setValue(hourAndMinute)
            : this.attentionStateForm?.controls[this.REAL_TIME_FROM].reset();
    }

    get realTimeTo() {
        return this.attentionStateForm.get(this.REAL_TIME_TO).value;
    }

    set realTimeTo(hourAndMinute: string) {
        hourAndMinute != null ? this.attentionStateForm?.controls[this.REAL_TIME_TO].setValue(hourAndMinute)
            : this.attentionStateForm?.controls[this.REAL_TIME_TO].reset();
    }

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<DialogEvolutionComponent>,
        private attentionsFacade: AttentionsFacade,
        @Inject(MAT_DIALOG_DATA) public data: { attention: Attention },
        private categoryOfEvolutionFacade: CategoriesofEvolutionsFacade,
        private evolutionFacade: EvolutionsFacade,
        private casesFacade: CasesFacade,
    ) { }

    ngOnInit() {

        this.financierVisibility = false;
        this.providersVisibility = false;
        const qps: CategoriesOfEvolutionsQPS = {
            active: 1
        }
        this.categoryOfEvolutionFacade.loadCategoriesOfEvolutions(qps).subscribe(
            categories => this.evolutionCategories = categories
        );
        this.attentionStateForm = this.createAttentionStateForm();

        this.evolutionForm = this.createEvolutionForm();
        this.attentionStates = this.attentionStates.filter(state => state.id !== this.data.attention.state.id);

        this._historyModeDate = this.casesFacade.getCaseDateFilters$().subscribe(rangeDate => {
            if (!!rangeDate) {
                this.rangeDate = rangeDate;
            }
        })
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        if (!!this.data.attention.state && this.data.attention.state.id == AttentionStates.INFORMADA) {
            this.stateAttention.setValidators(null);
            this.attentionStateForm.get(this.STATE_ATTENTION).setValue(this.NO_MODIFICAR_ESTADO_ID);
        }

        if (this.durationNeeded) {
            this.attentionStateForm.get(this.QUANTITY).setValidators([CustomValidators.required('Duración requerida')])
            this.attentionStateForm.get(this.QUANTITY).disable();
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (!!this._mda) {
            this._mda.unsubscribe();
        }

        this._historyModeDate ? this._historyModeDate.unsubscribe() : null;
    }

    pondHandleInit() {
        console.log('Inicia la carga del archivo');
    }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);
            this.photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
        } else {
            this.photoEvolutionB64 = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoEvolutionB64 = null;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    createAttentionStateForm(): FormGroup {
        let fromDate = this.data?.attention?.fromDate;
        let toDate = this.data?.attention?.toDate;
        let fromTime;
        let toTime;
        if (!!fromDate) {
            // fromDate = new Date(fromDate);
            // fromTime = `${fromDate.getHours()}:${fromDate.getMinutes()}`;
            fromTime = moment(this.data?.attention?.fromDate).format('HH:mm'); // Hour in
        }

        if (!!toDate) {
            // toDate = new Date(toDate);
            // toTime = `${toDate.getHours()}:${toDate.getMinutes()}`;
            toTime = moment(this.data?.attention?.toDate).format('HH:mm'); // Hour out
        }
        return this.formBuilder.group({
            [this.STATE_ATTENTION]: ['', [CustomValidators.required('Estado requerido')]],
            [this.DATE_IN_REAL]: [this.data.attention.fromDate],
            [this.DATE_OUT_REAL]: [this.data.attention.toDate],
            [this.REAL_TIME_FROM]: [fromTime],
            [this.REAL_TIME_TO]: [toTime],
            [this.QUANTITY]: [this.calculateHourDifference(this.data.attention.fromDate, this.data.attention.toDate)],
            // [this.QUANTITY]: [],
        });
    }


    createEvolutionForm(): FormGroup {
        return this.formBuilder.group({
            [this.EVOLUTION_TEXT]: ['', [CustomValidators.required('Texto requerido'), CustomValidators.minLength(10, 'La evolución debe tener al menos 10 caracteres')]],
            [this.EVOLUTION_TYPE]: ['', [CustomValidators.required('Tipo requerido')]],
        });
    }

    calculateId(element: StateAttention) {
        return element ? element.id : null;
    }

    calculateName(element: StateAttention) {
        return element ? element.name : null;
    }

    calculateCategoryOfEvolutionId(element: CategoryOfEvolution) {
        return element ? element.id : null;
    }

    calculateCategoryOfEvolutionName(element: CategoryOfEvolution) {
        return element ? element.description : null;
    }

    onCancelClick(): void {
        this.dialogRef.close();

    }

    onConfirmClick(): void {

        // POST a la evolucion -> texto tipo foto visualizaciones
        // PATCH a la atencion -> estado de la atencion
        this.loadingConfirm = true;
        const postEvolutionObject = {
            attentionId: this.data.attention.id,
            caseId: this.data.attention.caseId,
            text: this.evolutionText.value,
            type: this.evolutionType.value,
            date: new Date(),
            mediaUrls: [this.photoEvolutionB64],
            visibleFinancier: this.financierVisibility,
            visibleProvider: this.providersVisibility,
        };

        let dateInReal: Date = this.attentionStateForm.get(this.DATE_IN_REAL).value;
        if (!!dateInReal && !!this.attentionStateForm.get(this.REAL_TIME_FROM).value) {
            const minAndHour = this.attentionStateForm.get(this.REAL_TIME_FROM).value.split(':');
            dateInReal = new Date(dateInReal);
            dateInReal.setHours(minAndHour[0]);
            dateInReal.setMinutes(minAndHour[1]);
        }

        let dateOutReal: Date;
        if (this.data.attention?.practice?.restriction?.requiredToDate) {
            // Entro cuando es Guardia
            dateOutReal = this.attentionStateForm.get(this.DATE_OUT_REAL).value;
            if (!!dateOutReal && !!this.attentionStateForm.get(this.REAL_TIME_TO).value) {
                const minAndHour = this.attentionStateForm.get(this.REAL_TIME_TO).value.split(':');
                dateOutReal = new Date(dateOutReal);
                dateOutReal.setHours(minAndHour[0]);
                dateOutReal.setMinutes(minAndHour[1]);
            }
        } else {
            // Entro cuando es Visita
            dateOutReal = new Date(dateInReal);
            dateOutReal.setHours(dateOutReal.getHours() + 1);
            this.attentionStateForm.get(this.QUANTITY).setValue(this.calculateHourDifference(dateInReal, dateOutReal))
        }

        const patchAttentionObject = {
            id: this.data.attention.id,
        };
        patchAttentionObject['dateInReal'] = dateInReal;
        patchAttentionObject['dateOutReal'] = dateOutReal;
        patchAttentionObject['quantity'] = this.attentionStateForm.get(this.QUANTITY).value;
        patchAttentionObject['fromEvolution'] = true;
        if (this.stateAttention.value != this.NO_MODIFICAR_ESTADO_ID) {
            patchAttentionObject['state'] = {
                id: this.stateAttention.value,
            };
        }

        this.postEvolution(postEvolutionObject, patchAttentionObject)
            .then((evolution) => this.patchAttention(patchAttentionObject))
            .then((attention) => this.dialogRef.close(attention))
            .then(_ => this.loadingConfirm = false)
            .catch(_ => this.loadingConfirm = false);
    }

    postEvolution(evolution, patchAttentionObject): Promise<any> {
        return this.evolutionFacade.postEvolution(evolution).toPromise();
    }

    patchAttention(attention: Attention): Promise<Attention> {
        return this.attentionsFacade.patchAttention(attention).toPromise();
    }

    onSelectCategory(id: number) {
        let categorySelected: CategoryOfEvolution = this.evolutionCategories.find(category => category.id == id);
        if (categorySelected) {
            this.financierVisibility = (categorySelected.visibleFinancier && categorySelected.visibleFinancier == 1) ? true : false;
            this.providersVisibility = (categorySelected.visibleProvider && categorySelected.visibleProvider == 1) ? true : false;
            if (!this.changeTextDescription) {
                if (categorySelected.defaultText && this.defaultTextCharged !== categorySelected.defaultText) {
                    this.evolutionText.setValue(categorySelected.defaultText);
                    this.defaultTextCharged = categorySelected.defaultText;
                } else {
                    this.evolutionText.setValue("");
                }
            }
        }
    }

    onSetTimeIn(event) {
        this.attentionStateForm.get(this.REAL_TIME_FROM).setValue(event);
        this.setHourDifference();
    }

    // onSetTimeOut(event) {
    //     this.attentionStateForm.get(this.REAL_TIME_TO).setValue(event);
    //     this.setHourDifference();
    // }

    setHourDifference() {
        let dateIn = this.concatDateAndHours(this.attentionStateForm.get(this.DATE_IN_REAL).value, this.attentionStateForm.get(this.REAL_TIME_FROM).value);
        let dateOut = this.concatDateAndHours(this.attentionStateForm.get(this.DATE_OUT_REAL).value, this.attentionStateForm.get(this.REAL_TIME_TO).value);
        if (this.durationNeeded) {
            this.attentionStateForm.get(this.QUANTITY).setValue(this.calculateHourDifference(dateIn, dateOut));
            if (this.data.attention.state.id === this.ATTENTION_STATES.REALIZADA && this.data.attention.quantity != this.attentionStateForm.get(this.QUANTITY).value) {
                this.showAlertMsg = true;
            } else {
                this.showAlertMsg = false;
            }
        }
    }

    concatDateAndHours(date, hours) {
        let formattedDate = moment(date);
        let formattedHours = moment(hours, "HH:mm");
        return formattedDate.set({ hour: formattedHours.hour(), minute: formattedHours.minutes() });
    }

    calculateHourDifference(date1, date2) {
        const formattedDate1 = moment(date1);
        const formattedDate2 = moment(date2);
        return Math.ceil(formattedDate2.diff(formattedDate1, 'minutes') / 60);
    }
    onChangeDescription(event) {
        let categorySelected: CategoryOfEvolution = this.evolutionCategories.find(category => category.id == this.evolutionType.value);
        if (categorySelected && event.target.value != '') {
            this.changeTextDescription = (categorySelected.defaultText != event.target.value);
        } else {
            if (event.target.value == '') {
                this.changeTextDescription = false;
            } else {
                this.changeTextDescription = (!categorySelected);
            }
        }
    }

    // Fx() to validates hours
    durationChanged() {
        let hours = this.quantity;
        // let minutes: any = hours != 24 ? this.minuteDurationForm : 0;
        let hourFinish = moment(this.attentionStateForm.get(this.REAL_TIME_FROM).value, 'HH:mm').add({ hours: hours })
        // if (hours != null && (minutes != null && minutes != "")) {
        if ( hours != null ) {
            this.attentionStateForm.get(this.REAL_TIME_TO).setValue(hourFinish.format('HH:mm'));
            this.attentionStateForm.get(this.REAL_TIME_TO).markAsPristine();
            this.attentionStateForm.get(this.QUANTITY).markAsPristine();
        }
        this.validateIfNextDay();
    }

    validateIfNextDay() {
        this.isNextDay = moment(this.realTimeTo, 'HH:mm').diff(moment(this.realTimeFrom, 'HH:mm')) <= 0;
    }

    validateTimeValid() {
        let timeString = this.attentionStateForm.get(this.REAL_TIME_FROM).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.attentionStateForm.get(this.REAL_TIME_FROM).setValue(formattedTimeString)

                if (this.attentionStateForm.get(this.REAL_TIME_TO).dirty) {
                    this.changeHourFinish();
                } else if (this.attentionStateForm.get(this.QUANTITY).dirty ) {
                    this.durationChanged();
                } else {
                    this.changeHourFinish();
                }
            }
        }
    }

    changeHourFinish() {
        let timeString = this.attentionStateForm.get(this.REAL_TIME_TO).value
        timeString = timeString.padEnd(4, '0')
        timeString = timeString.replace(/\D/g, '')
        let timeTo;
        if (timeString.length == 4) {
            if (parseInt(timeString) <= 2359) {
                let formattedTimeString = timeString.substring(0, 2) + ':' + timeString.substring(2,)
                this.attentionStateForm.get(this.REAL_TIME_TO).setValue(formattedTimeString);

                let timeFrom = moment(this.attentionStateForm.get(this.REAL_TIME_FROM).value, 'HH:mm')
                // Same day
                if (parseInt(timeString) > parseInt(this.realTimeFrom.replace(':', ''))) {
                    timeTo = moment(this.attentionStateForm.get(this.REAL_TIME_TO).value, 'HH:mm')
                    // Next day
                } else {
                    timeTo = moment(timeFrom).add(1, 'day')
                    timeTo = moment(timeTo).set({ hour: timeString.substring(0, 2), minute: timeString.substring(2,) })
                }

                let timeDifference = moment.duration(timeTo.diff(timeFrom))
                this.attentionStateForm.get(this.QUANTITY).setValue(Math.ceil(timeDifference.asHours()));
            }
        }
        this.attentionStateForm.get(this.QUANTITY).markAsPristine();
        this.validateIfNextDay();
    }
}
