import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpecialtiesProvidersPoisComponent } from './pages/specialties-providers-pois/specialties-providers-pois.component';

const routes: Routes = [
    {
        path: 'buscar-prestadores',
        component: SpecialtiesProvidersPoisComponent,
        data: { breadcrumb: 'Buscar Prestadores' }
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProvidersRoutingModule { }


