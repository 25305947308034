<div class="flex-column">
    <span class="headline-1 pls-4 mbs-5 border-left text-primary-darker-title">
        {{ 'Pedido de insumos' | uppercase }}
    </span>
    <div>
        <mat-card>
            <form [formGroup]="suppliesForm">
                <div formArrayName="supplies" class="p-1">
                    <div *ngFor="let supply of supplies.controls; let i=index">
                        <div [formGroupName]="i" class="flex-column">
                            <div class="flex-row flex-wrap flex-gap-small align-start-end">
                                <!-- Supply -->
                                <div class="flex-column flex-30">
                                    <span class="subtitle-3 text-primary-darker mbs-0">
                                        Insumos *
                                    </span>
                                    <app-select-searching-input (selected)="this.onSelectPractice($event)"
                                        [name]="this.PRACTICE" [nameSearching]="this.PRACTICE_SEARCHING"
                                        [form]="getSuppliesFormGroup(i)" [placeholder]="'Seleccione el insumo'"
                                        [elements]="this.practices" [calculateId]="this.calculateIdUnit"
                                        [calculateName]="this.calculateNameCodeUnit"
                                        [placeholderNoValue]="'Sin resultados'"
                                        [placeholderSearching]="'Buscar insumo...'">
                                    </app-select-searching-input>
                                </div>

                                <!-- Cant -->
                                <div class="flex-column flex-15 ml-2">
                                    <span class="subtitle-3 text-primary-darker mbs-0">
                                        Cantidad
                                    </span>
                                    <!-- AMOUNT input -->
                                    <app-number-input [name]="this.AMOUNT" [form]="getSuppliesFormGroup(i)"
                                        [money]='false' [minValue]="0" [placeholder]="'Cantidad'">
                                    </app-number-input>
                                </div>

                                <div class="flex-column flex-10 ml-2">
                                    <span class="subtitle-3 text-primary-darker mbs-0">
                                        Unidad
                                    </span>
                                    <app-select-input class="mrs-5" [name]="this.UNIT" [form]="getSuppliesFormGroup(i)"
                                        [placeholder]="" [elements]="this.provisionsUnit"
                                        [calculateId]="this.calculateIdUnit" [calculateName]="this.calculateNameUnit">
                                    </app-select-input>
                                </div>

                                <!-- ACTIONS -->
                                <div class="flex-row start-none flex-10 mbs-3">
                                    <!-- Remove supply -->
                                    <div class="flex-50">
                                        <button id="" matTooltip="Borrar" mat-icon-button [color]="'primary'"
                                            type="button" (click)="this.removeSupply(i)">
                                            <mat-icon class="text-primary" outlined>delete_outline</mat-icon>
                                        </button>
                                    </div>
                                    <!-- Add supply -->
                                    <div class="flex-50">
                                        <button id="" matTooltip="Agregar" mat-icon-button [color]="'primary'"
                                            type="button" *ngIf="true" [disabled]="btn_addSupply_disabled"
                                            (click)="this.addSupply()"
                                            [hidden]="(i+1)==supplies['controls'].length ? false : true">
                                            <mat-icon>add_circle_outline</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>
