import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertErrorComponent } from './alert-error/alert-error.component';
import { SpinnerErrorComponent } from './spinner-error/spinner-error.component';
import { WarningIconComponent } from './warning-icon/warning-icon.component';
import { ProgressSpinnerWithTextComponent } from './progress-spinner-with-text/progress-spinner-with-text.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from './spinner/spinner.component';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
    declarations: [
        SpinnerComponent,
        AlertErrorComponent,
        ProgressSpinnerWithTextComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        LottieComponent,
        SpinnerErrorComponent,
        WarningIconComponent
    ],
    exports: [
        SpinnerComponent,
        AlertErrorComponent,
        ProgressSpinnerWithTextComponent,
        SpinnerErrorComponent,
        WarningIconComponent,
    ],
})
export class UtilsModule { }
