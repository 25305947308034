<div class="mbs-2 flex-column flex-100" *ngIf="!!this.case;">
    <ng-container *ngIf="this.dataSource">
        <button mat-mini-fab matTooltip="Agregar insumos" *ngIf="this.showFabButton && !this.isCollapsed"
            (click)="this.openAddSupplyDialog( 'ADD' )" color="primary" aria-label="Agregar insumos"
            style="position: fixed; right: 32px; bottom: 85px;">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-mini-fab *ngIf="this.showFabButton && !this.isCollapsed" matTooltip="Acciones" color="primary"
            aria-label="Acciones" style="position: fixed; right: 32px; bottom: 40px;">
            <mat-icon [matMenuTriggerFor]="menuActions"
                [matMenuTriggerData]="{ selectedSupplies:this.selectionProvFees }">menu</mat-icon>
        </button>
        <div id="headerButtons" class="header-buttons flex-100 flex-row align-end-center">
            <button mat-icon-button matTooltip="Agregar insumo" [disabled]="!this.case.active" [color]="'primary'"
                (click)="this.openAddSupplyDialog( 'ADD' )" matTooltip="Agregar insumo"
                *ngIf="!this.isCollapsed; else addSupplyButton">
                <mat-icon>add_circle_outlined</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Acciones">
                <mat-icon class="cursor-pointer text-secondary" [matMenuTriggerFor]="menuActions"
                    [matMenuTriggerData]="{ selectedSupplies:this.selectionProvFees }"
                    [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"></mat-icon>
            </button>
            <ng-template #addSupplyButton>
                <button mat-icon-button matTooltip="Agregar insumo" [disabled]="!this.case.active"
                    (click)="this.openAddSupplyDialog( 'ADD' )" [color]="'primary'">
                    <mat-icon>add_circle_outlined</mat-icon>
                </button>
            </ng-template>
        </div>
        <table id="supplyListTable" class="full-width"
            style="border-radius: 10px; border-left: none; border-right: none;" mat-table [dataSource]="this.dataSource"
            multiTemplateDataRows *ngIf="!this.loadingGetCase; else loading">
            <!-- arrow Column -->
            <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"></th>
                <td mat-cell *matCellDef="let data" class="arrow-column">
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
                </th>
                <td mat-cell *matCellDef="let row"
                    style="vertical-align: top !important; border-right: none !important;">
                    <div class="inner-cell" *ngFor="let provFee of row.provisionFees; trackBy: trackByProvisionFee">
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- name Column -->
            <ng-container matColumnDef="provision">
                <th mat-header-cell *matHeaderCellDef id="nameColumn"
                    style="min-width: 70px; text-align: center !important; justify-content: center !important;"
                    [attr.rowspan]="2">
                    Insumos
                </th>
                <!-- style="max-width:15vw;" -->
                <td mat-cell *matCellDef="let data" [attr.colspan]="this.getColspan(data)">
                    @if(data?.provisionFees?.length > 0) {
                    <div *ngFor="let provFee of data.provisionFees"
                        [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}">
                        <span *ngIf="this.getIsExpanded(data)" class="subtitle-3 text-primary-darker text-ellipsis"
                            [matTooltip]="data.practice?.name">
                            {{ !!data.practice?.name ? data.practice.name : 'Sin datos' }}
                        </span>
                    </div>
                    }
                    <!-- Group and provisionFees == 0 -->
                    <div *ngIf="data?.provisionFees?.length == 0"
                        class="inner-cell category-header flex-row align-space-between-center"
                        style="border-bottom: 1px solid #fff !important; cursor:pointer">
                        <!-- Supply name -->
                        <div class="flex-row align-start-center" style="height: 100%">
                            <div [style.width.px]="this.reactiveWidth" style="height: 100%"
                                class="flex-row align-start-center" (click)="toggleExpandedElement(data)">
                                <span class="subtitle-3 text-primary-darker text-ellipsis mls-2"
                                    style="width: max-content">{{ data.practice.name }}</span>
                                <span class="subtitle-3 text-primary-darker mls-1">({{
                                    this.getTotalsByCategory(data.practice.name) }})</span>
                            </div>
                            <!-- Checkbox for group -->
                            <div class="flex-row align-center-center"> <!-- style="width: 30px;" -->
                                <mat-checkbox (change)="this.checkAllByCategory($event, data.practice.name)"
                                    [checked]="this.isAllCheckedByCategory(data.practice.name)"></mat-checkbox>
                            </div>
                        </div>
                        <!-- Expand arrow -->
                        <div style="height: 100%" (click)="toggleExpandedElement(data)"
                            class="flex-row align-end-center flex-100">
                            <mat-icon class="text-primary">{{ this.getIsExpanded(data) ? 'keyboard_arrow_down' :
                                'keyboard_arrow_left' }}</mat-icon>
                        </div>
                    </div>
                </td>
                <!-- Footer Totals -->
                <td mat-footer-cell *matFooterCellDef
                    [attr.colspan]="isCollapsed ? columnsToDisplayCollapsed.length : columnsToDisplay.length">
                    <div class="flex-row align-space-around-center gap-2rem" style="border:none !important; width: 100%"
                        *ngIf="!this.isCollapsed">
                        <div>
                            <span class="subtitle-5 text-primary-darker"
                                style="font-weight: 500 !important;">INSUMOS:</span>
                            <span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">{{this.totalSupplies}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">COSTO
                                TOTAL:</span>
                            <span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.totalCost}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">VENTA
                                TOTAL:</span>
                            <span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.totalValue}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">TOTAL
                                ENTREGADO:</span><span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.fullDelivered}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">TOTAL
                                DEBITABLE:</span><span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.fullDebited}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">TOTAL
                                FACTURABLE:</span>
                            <span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.fullInvoiced}}</span>
                        </div>
                        <div>
                            <span class="subtitle-5 text-primary-darker" style="font-weight: 500 !important;">TOTAL
                                INFORMADO:</span>
                            <span class="mls-1 subtitle-5 text-primary"
                                style="font-weight: 600 !important;">${{this.fullInformed}}</span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Checkbox Column for rows-->
            <ng-container matColumnDef="checkProvisionFee">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selectionProvFees.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row"
                    [ngStyle]="{'width': row?.provisionFees?.length > 0 ? '42px' : '0px'}">
                    <div *ngFor="let data of row.provisionFees; trackBy: trackByProvisionFee let last=last"
                        [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(data.id)}"
                        [ngStyle]="{'width': row?.provisionFees?.length > 0 ? '42px' : '0px'}" class="">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectPractice(data) : null"
                            [checked]="this.isPracticeSelected(data) && !isAgreementsIndeterminated(row)"
                            [indeterminate]="isAgreementsIndeterminated(row)" *ngIf="this.getIsExpanded(row)">
                        </mat-checkbox>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- authorization Column -->
            <ng-container matColumnDef="authorization">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Autoriz.</th>
                <td mat-cell *matCellDef="let data">
                    <div *ngFor="let provFee of data.provisionFees"
                        [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}">
                        <mat-chip-listbox *ngIf="this.getIsExpanded(data)">
                            <mat-chip style="white-space: nowrap;cursor: pointer;"
                                [ngStyle]="{ 'background-color': this.getBackgroundColorAuthorizationState(provFee), 'color': this.getColorAuthorizationState(provFee) }"
                                matTooltip="{{ provFee?.authorization
                                                ? '#' + provFee?.authorization?.authorizationNumber + ' - ' + this.generalService.getAuthorizationState(provFee)
                                                : (this.generalService.getAuthorizationState(provFee) | uppercase) }}"
                                (click)="this.onClickAuthorization(provFee)">
                                <span class="mat-small">
                                    {{this.generalService.getAuthorizationState(provFee) | firstLetterWordsIntoString}}
                                </span>
                            </mat-chip>
                        </mat-chip-listbox>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- frequency Column -->
            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Cantidad</th>
                <td mat-cell *matCellDef="let provision">
                    <div *ngFor="let provFee of provision.provisionFees"
                        [ngClass]="{'inner-cell': getIsExpanded(provision), 'collapsed-cell': !getIsExpanded(provision), 'warn-cell': isRowWithBackGroundColor(provFee.id)}">
                        <div
                            [ngClass]="{'without-unit': provFee?.frequency?.unit == null || provFee.frequency?.amount == null}">
                            <span *ngIf="this.getIsExpanded(provision)">
                                {{!!provFee.frequency && provFee?.frequency?.amount != null && provFee.frequency?.unit
                                != null
                                && provFee.frequency?.unit != '' ? provFee?.frequency?.amount + " " +
                                provFee.frequency?.unit +
                                "/s" : '-'}}
                            </span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="profitMargin">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Margen</th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <ng-container *ngIf="!!provFee?.profitMargin && this.getIsExpanded(data) ; else noMargin">
                            <div class="flex-column">
                                <span>
                                    {{ provFee.profitMargin | number:'1.2-2'}}%
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #noMargin>
                        <div *ngIf="this.getIsExpanded(data)">
                            <span class="text-secondary">
                                -
                            </span>
                        </div>
                    </ng-template>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="states">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="5">
                    Estados
                </th>
                <td mat-cell style="display:none;" *matCellDef></td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Delivered Quantity Column -->
            <ng-container matColumnDef="delivered">
                <th mat-header-cell *matHeaderCellDef class="mrs-3 state-header"
                    style="border-radius: 10px 0px 0px 0px; border-right: none !important;">
                    <span>Entr.</span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="this.getIsExpanded(data)">
                            {{this.getDelivered(provFee)}}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Not delivered column-->
            <ng-container matColumnDef="not-delivered">
                <th mat-header-cell *matHeaderCellDef class="mrs-3 state-header" style="border-right: none !important;">
                    <span>No en.</span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="this.getIsExpanded(data)">
                            {{this.getNotDelivered(provFee)}}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Tolal Cost Column -->
            <ng-container matColumnDef="total-cost">
                <th mat-header-cell *matHeaderCellDef class="mrs-3" [attr.rowspan]="2">
                    <div class="flex-column center-center">
                        <span>Costo</span>
                        <span>total</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees; trackBy: trackByProvisionFee">
                        <span
                            *ngIf="provFee?.agreement?.providerFee != null && !!provFee?.frequency?.amount && this.getIsExpanded(data)">
                            ${{(provFee.agreement.providerFee * provFee.frequency.amount).toFixed(2)}}
                        </span>
                        <i *ngIf="provFee?.agreement?.providerFee == null && this.getIsExpanded(data)"
                            class="text-secondary">
                            Sin datos
                        </i>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Invoiced Cost Column -->
            <ng-container matColumnDef="invoiced">
                <th mat-header-cell *matHeaderCellDef class="state-header" style="border-right: none !important;">
                    <span>Fact.</span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="this.getIsExpanded(data)">
                            {{this.getInvoiced(provFee)}}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!--Informed Column-->
            <ng-container matColumnDef="informed">
                <th mat-header-cell *matHeaderCellDef class="state-header" style="border-radius: 0px 10px 0px 0px;">
                    <span>Informado</span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <div class="flex-column" *ngIf="this.getIsExpanded(data)">
                            <span>
                                {{this.getInformed(provFee)}}
                            </span>
                            <span>
                                {{this.getInformed(provFee) * provFee.fee | currency:'ARS':'symbol'}}
                            </span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Debited Cost Column -->
            <ng-container matColumnDef="debited">
                <th mat-header-cell *matHeaderCellDef class="state-header" style="border-right: none !important;">
                    <span>Deb.</span>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="this.getIsExpanded(data)">
                            {{this.getDebited(provFee)}}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Cost Column -->
            <ng-container matColumnDef="cost">
                <th mat-header-cell *matHeaderCellDef class="mrs-3" [attr.rowspan]="2">
                    <div class="flex-column">
                        <span>Costo</span>
                        <span>unitario</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="provFee?.agreement?.providerFee != null && this.getIsExpanded(data)">
                            ${{(provFee?.agreement?.providerFee).toFixed(2)}}
                        </span>
                        <i class="text-secondary"
                            *ngIf="provFee?.agreement?.providerFee == null && this.getIsExpanded(data)">
                            Sin datos
                        </i>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- total Column -->
            <ng-container matColumnDef="fee">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
                    <div class="flex-column center-center">
                        <span>Venta</span>
                        <span>unitario</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <div *ngIf="this.getIsExpanded(data)">
                            <span *ngIf="provFee?.fee != null">
                                ${{(provFee.fee).toFixed(2)}}
                            </span>
                            <span
                                *ngIf="provFee?.fee == null && !!provFee?.agreement?.providerFee && !!provFee.profitMargin">
                                ${{provFee?.agreement?.providerFee + (provFee?.agreement?.providerFee *
                                (provFee.profitMargin /
                                100))}}
                            </span>
                            <span
                                *ngIf="provFee?.fee == null && (provFee?.agreement?.providerFee == null || provFee?.profitMargin == null) && !provFee.fixedPrice">
                                -
                            </span>
                            <span *ngIf="provFee.fixedPrice">
                                Kairos
                            </span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="total-fee">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
                    <div class="flex-column center-center">
                        <span>Venta</span>
                        <span>total</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let data">
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of data.provisionFees">
                        <span
                            *ngIf="this.getIsExpanded(data) && provFee.fee != null && provFee.frequency.amount != null">
                            ${{(provFee.fee * provFee.frequency?.amount).toFixed(2)}}
                        </span>
                        <span
                            *ngIf="this.getIsExpanded(data) && provFee.fee == null && provFee.agreement?.providerFee != null && provFee.profitMargin != null">
                            ${{(provFee.agreement?.providerFee + (provFee.agreement?.providerFee * (provFee.profitMargin
                            /
                            100))) * provFee.frequency.amount}}
                        </span>
                        <span
                            *ngIf="this.getIsExpanded(data) && (provFee.fee == null && (provFee.agreement?.providerFee == null || provFee.profitMargin == null) || provFee?.frequency?.amount == null)">
                            -
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Ordered -->
            <ng-container matColumnDef="ordered">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
                    Pedido
                </th>
                <td mat-cell *matCellDef="let data" >
                    <div [ngClass]="{'inner-cell': getIsExpanded(data), 'collapsed-cell': !getIsExpanded(data), 'warn-cell': isRowWithBackGroundColor(provFee.id),
                   'marked-top': getOrderedQuantity(provFee) == 0} "
                        *ngFor="let provFee of data.provisionFees">
                        <span *ngIf="this.getIsExpanded(data)">
                            {{this.getOrderedQuantity(provFee)}}
                        </span>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actionsNextProvision">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let provision"></td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let provision">
                    <div class="inner-cell">
                        <div class="flex-row">
                            <button [disabled]="!this.case.active" class="mat-small"
                                (click)="onClickChangeActiveProvision(provision); $event.stopPropagation(); $event.preventDefault();"
                                mat-icon-button>
                                <mat-icon class="mat-icon-small"
                                    [matTooltip]="this.selectedOptionSupply == 'active' ? 'Desactivar insumo':'Activar insumo'"
                                    [class]="this.case.active && this.selectedOptionSupply == 'active' ? 'text-primary':'text-primary'">
                                    {{this.selectedOptionSupply == 'active' ? 'visibility_off':'visibility'}}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="vigency">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Vigencia</th>
                <td mat-cell *matCellDef="let provision">
                    <div [ngClass]="{'inner-cell': getIsExpanded(provision), 'collapsed-cell': !getIsExpanded(provision), 'warn-cell': isRowWithBackGroundColor(provFee.id)}"
                        *ngFor="let provFee of provision.provisionFees">
                        <div class="flex-column">
                            <span [ngStyle]="{'opacity': this.getIsExpanded(provision) ? '100' : '0'}">
                                {{!!provFee.fromDate ? (provFee.fromDate | date: 'dd/MM/yyyy') : ''}}
                            </span>
                            <span [ngStyle]="{'opacity': this.getIsExpanded(provision) ? '100' : '0'}">
                                {{!!provFee.toDate ? (provFee.toDate | date: 'dd/MM/yyyy') : ''}}
                            </span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Proveedor</th>
                <td mat-cell *matCellDef="let provision">
                    <div *ngFor="let provFee of provision.provisionFees"
                        [ngClass]="{'inner-cell': getIsExpanded(provision), 'collapsed-cell': !getIsExpanded(provision), 'warn-cell': isRowWithBackGroundColor(provFee.id)}">
                        <div class="flex-column">
                            <span *ngIf="this.getIsExpanded(provision)">
                                {{ !!provFee.agreement?.provider?.name ? provFee.agreement.provider.name : '-'}}
                            </span>
                            <span *ngIf="this.getIsExpanded(provision) && !!provFee.agreement?.provider?.surname">
                                {{provFee.agreement.provider.surname}}
                            </span>
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Disclaimer column -->
            <ng-container matColumnDef="disclaimer">
                <td mat-footer-cell *matFooterCellDef colspan="17"
                    style="background-color: #FFF !important; border: none !important;">
                    <div class="flex-row align-space-around-start mys-3" *ngIf="!this.isCollapsed"
                        style="border:none !important;">
                        <div class="flex-column align-center-center">
                            <button [disabled]="this.createOrderButtonDisabled()" mat-flat-button [color]="'primary'"
                                (click)="this.openAddOrderDialog()"
                                matTooltip="Crear pedido con los insumos seleccionados">
                                CREAR PEDIDO
                            </button>
                            <div class="flex-column mts-3"
                                *ngIf="this.selectionProvFees?.selected?.length > 0 && this.createOrderButtonDisabled()">
                                <mat-error class="mls-3">No puede crear el pedido.</mat-error>
                                <mat-error *ngIf="!this.isSameProviderSelected()" class="mls-3">Los insumos
                                    seleccionados tienen distinto proveedor.</mat-error>
                                <mat-error *ngIf="this.isSomeSupplyINOrder()" class="mls-3">Existe/n
                                    {{this.suppliesWithSomeOrderActive}} insumo/s con pedido.</mat-error>
                                <mat-error *ngIf="this.isSomeSupplyWithoutProvider()" class="mls-3">Existe/n
                                    {{this.suppliesWithProviderNull}} insumo/s con proveedor vacío.</mat-error>
                                <mat-error *ngIf="this.isSomeSupplyWithoutToDate()" class="mls-3">Existe/n
                                    {{this.suppliesWithToDateNull}} insumo/s sin fecha de finalización.</mat-error>
                                <mat-error *ngIf="this.isSomeSupplyWithoutFeeOrProviderFee()" class="mls-3">Existe/n
                                    {{this.suppliesWithoutFeeOrProviderFee}} insumo/s sin costo o valor
                                    venta.</mat-error>
                                <mat-error *ngIf="this.isSomeSupplyWithOrderInAnotherTimePeriod()"
                                    class="mls-3">Existe/n {{this.suppliesWithOrderInAnotherTimePeriod}} insumo/s con
                                    pedido en otro mes.</mat-error>
                                <span class="text-primary mls-3" (click)="rowsWithErrorsForCreateOrder()"
                                    style="cursor:pointer;">Resaltar insumo/s</span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="progressBar">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="17" style="border-right: none !important;">
                    <mat-progress-bar [mode]="'indeterminate'" progressBarId="attentionsLoading"
                        *ngIf="(isLoadingCreatingOrder$ | async) || (isLoadingCreatingProvision$ | async) || (isLoadingUpdatingProvision$ | async) || (isLoadingGettingAttentions$ | async) || (isLoadingGettingProvision$ | async)"></mat-progress-bar>
                    <mat-progress-bar [mode]="'determinate'" [value]="100" progressBarId="attentionsLoading"
                        *ngIf="!(isLoadingCreatingOrder$ | async) && !(isLoadingCreatingProvision$ | async) && !(isLoadingUpdatingProvision$ | async) && !(isLoadingGettingAttentions$ | async) && !(isLoadingGettingProvision$ | async)"></mat-progress-bar>
                </th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="(!this.isCollapsed) ? headerColumns : this.columnsToDisplayCollapsed">
            </tr>
            <tr mat-header-row *matHeaderRowDef="!this.isCollapsed ? supplyStates : null"></tr>
            <tr mat-header-row *matHeaderRowDef="['progressBar']" style="height: 4px !important;"></tr>
            <tr mat-row
                *matRowDef="let row; columns: (!this.isCollapsed) ? columnsToDisplay : this.columnsToDisplayCollapsed"
                class="parentShowOnHover example-element-row"
                [ngClass]="this.getIsExpanded(row) ? 'expanded-row' : 'collapsed-row'"></tr>
            <tr mat-footer-row *matFooterRowDef="!this.isCollapsed ? columnsToDisplay : this.columnsToDisplayCollapsed">
            </tr>
            <tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr>
        </table>
    </ng-container>
    <ng-template #noSuppliesTemplate>
        <span class="flex-row center-start text-secondary"> El caso no tiene insumos cargados</span>
    </ng-template>
</div>

<ng-template #loading>
    <div class="flex-center-center">
        <mat-spinner diameter="20"></mat-spinner>
    </div>
</ng-template>

<ng-template #addSupplyTemplate>
    <div class="flex-column start-none">
        <div *ngIf="globalAction=='ADD' || globalAction=='UPD' || globalAction=='PRI' || globalAction=='MOD'"
            class="flex-row start-center">
            <mat-icon class="mbs-2" svgIcon="WarningCircle" style="height: 24px; width: 24px;"></mat-icon>
            <span *ngIf="globalAction=='ADD'" class="mls-1 mbs-2">Crea uno o varios insumos agrupados en una única
                autorización.</span>
            <span *ngIf="globalAction=='UPD'" class="mls-1 mbs-2">Renueva uno o varios insumos agrupados en una única
                autorización.</span>
            <span *ngIf="globalAction=='PRI' || globalAction=='MOD'" class="mls-1 mbs-2">La modificación de uno o varios
                insumos reemplazará el insumo actual.</span>
        </div>
        <form [formGroup]="this.formSupplies" class="flex-column" (submit)="onClickCreateProvision()">

            <!-- One provider, used to load price / cargar cotización -->
            <div *ngIf="this.globalAction=='PRI'" class="flex-row start-center">
                <app-select-searching-dynamic-input class="flex-50" [name]="this.ONE_PROVIDER"
                    [nameSearching]="this.ONE_PROVIDER_SEARCHING" [form]="this.formSupplies"
                    [httpGet]="this.getProvidersByName" [calculateId]="this.calculateIdProvider"
                    [calculateName]="this.calculateNameProvider" [placeholder]="'Seleccionar proveedor'"
                    [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar proveedor...'"
                    [noOptionText]="'Sin proveedor'" (selectedWithEntity)="this.providerSelected($event)">
                </app-select-searching-dynamic-input>
                <div class="flex-50"></div>
            </div>

            <!-- HEADER -->
            <div class="flex-row space-between-center bg-primary-lighter practices-arrangement-header">
                <div class="flex-14 text-align-center align-center-center"
                    [ngStyle]="{'min-width': this.globalAction==='MOD' ? '14%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        {{txtMsgSupply}}
                    </span>
                </div>
                <div class="flex-14 text-align-center align-center-center" *ngIf="this.globalAction!='PRI'"
                    [ngStyle]="{'min-width': this.globalAction==='MOD' ? '14%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        Proveedor
                    </span>
                </div>
                <div class="flex-6 text-align-center align-center-center"
                    [ngStyle]="{'min-width': this.globalAction==='UPD' ? '7%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        {{txtMsgAmount}}
                    </span>
                </div>
                <div class="flex-7 text-align-center align-center-center">
                    <span class="subtitle-3 text-primary-darker">
                        {{txtMsgUnit}}
                    </span>
                </div>
                <div class="flex-10 text-align-center align-center-center flex-column"
                    [ngStyle]="{'min-width': this.globalAction==='MOD' ? '10%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        Valor
                    </span>
                    <span class="subtitle-3 text-primary-darker">
                        {{txtMsgProviderFee}}
                    </span>
                </div>
                <div class="flex-7 text-align-center align-center-center"
                    [ngStyle]="{'min-width': this.globalAction==='MOD' ? '7%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        Margen
                    </span>
                </div>
                <div class="flex-10 text-align-center align-center-center flex-column"
                    [ngStyle]="{'min-width': this.globalAction==='MOD' ? '10%' : ''}">
                    <span class="subtitle-3 text-primary-darker">
                        Valor
                    </span>
                    <span class="subtitle-3 text-primary-darker">
                        venta unitario
                    </span>
                </div>
                <div *ngIf="globalAction!='FPR'" class="flex-3 text-align-center align-center-center">
                    <span class="subtitle-3 text-primary-darker">
                        Kairos
                    </span>
                </div>
                <div *ngIf="globalAction!='ADD' && globalAction!='FPR'"
                    class="flex-11 text-align-center align-center-center">
                    <span class="subtitle-3 text-primary-darker">
                        Desde
                    </span>
                </div>
                <div *ngIf="globalAction!='ADD' && globalAction!='FPR'"
                    class="flex-11 text-align-center align-center-center">
                    <span class="subtitle-3 text-primary-darker">
                        Hasta
                    </span>
                </div>
                <div class="flex-6">
                </div>
            </div>

            <!-- Supplies -->
            <div [formArrayName]="this.SUPPLIES"
                style="max-height: 250px; min-height: 80px; overflow-y: auto; overflow-x: hidden;" #scrollElem>
                <div *ngFor="let supply of supplies['controls']; let i=index">
                    <div [formGroupName]="i">
                        <div class="flex-column"
                            [ngStyle]="{'height': (this.supplies.at(i).invalid && (this.supplies.at(i).dirty || this.supplies.at(i).touched)) ? '80px' : 'max-content'}">
                            <!-- SUPLLY INPUTS -->
                            <div class="flex-row flex-100 align-space-between-start"
                                [ngStyle]="{'height': this.presentSupplyIndex.includes(i) ? '4.3rem !important' : '40px !important'}">
                                <!-- Supply -->
                                <div class="flex-column flex-14">
                                    <app-select-searching-dynamic-input style="height: 40px;" class="full-width"
                                        [disabled]="!!this.actualSupplies[i]" [defaultValue]="this.actualSupplies[i]"
                                        [elements]="this.suppliesOnSelect[i]" [name]="this.PRACTICE"
                                        [nameSearching]="this.PRACTICE_SEARCHING" [form]="getSuppliesFormGroup(i)"
                                        [httpGet]="this.getSuppliesByName" [calculateId]="this.calculateIdSupply"
                                        [calculateName]="this.calculateNameSupply"
                                        (selectedWithEntity)="this.supplySelected($event, i)"
                                        [placeholder]="'Seleccionar insumo'" [placeholderNoValue]="'Sin resultados'"
                                        [placeholderSearching]="'Buscar insumo...'" [noOptionText]="'Sin insumo'"
                                        [matTooltip]="!!this.suppliesOnSelect[i] ? !!this.suppliesOnSelect[i][0] ? this.suppliesOnSelect[i][0].name : null : null">
                                    </app-select-searching-dynamic-input>

                                    <!-- Alert msg if supply exist -->
                                    <div *ngIf="this.presentSupplyIndex.includes(i)"
                                        class="flex-row start-center gap-0_2rem full-width">
                                        <span class="subtitle-4 text-secondary-darker">Ya existe este insumo. Se
                                            duplicará.</span>
                                        <mat-icon style="cursor: pointer; z-index: 10;" [color]="'accent'"
                                            (mouseover)="supplyExistHelp(i)" (mouseout)="supplyExistHelp(i)"
                                            cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">help_outline
                                        </mat-icon>
                                        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="30"
                                            [cdkConnectedOverlayOffsetY]="-30"
                                            [cdkConnectedOverlayOrigin]="originOverlay"
                                            [cdkConnectedOverlayOpen]="isOpen && indexOpen == i"
                                            [cdkConnectedOverlayWidth]="200">
                                            <div class="flecha-lado-izquierdo-arriba">
                                                <div class="bg-secondary-lighter mat-font-verysmall-px pxs-2 pys-1">
                                                    <div class="flex-column gap-0_1em">
                                                        <span>La duplicación de insumos permite tener el mismo insumo
                                                            con dos
                                                            condiciones distintas
                                                            en el mismo periodo de tiempo.</span>
                                                        <span>Si desea modificar o actualizar el insumo, diríjase al
                                                            menú de la
                                                            tabla.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>

                                    <!-- Supply has order -->
                                    <div *ngIf="this.getIdOrder(i) > 0 && this.globalAction == 'MOD'"
                                        class="flex-row start-center mls-3 gap-0_2rem">
                                        <span class="subtitle-4 text-secondary-darker">Pedido
                                            {{this.getIdOrder(i)}}</span>
                                        <mat-icon style="cursor: pointer; z-index: 10;" [color]="'accent'"
                                            (mouseover)="supplyExistHelp(i)" (mouseout)="supplyExistHelp(i)"
                                            cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">help_outline
                                        </mat-icon>
                                        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOffsetX]="30"
                                            [cdkConnectedOverlayOffsetY]="-25"
                                            [cdkConnectedOverlayOrigin]="originOverlay"
                                            [cdkConnectedOverlayOpen]="isOpen && indexOpen == i"
                                            [cdkConnectedOverlayWidth]="600">
                                            <div class="flecha-lado-izquierdo-arriba">
                                                <div class="bg-secondary-lighter mat-font-verysmall-px pxs-2 pys-1">
                                                    <div class="flex-column gap-0_1em">
                                                        <span class="text-warn">El proveedor y costo no pueden
                                                            modificarse porque pertenecen a un pedido.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- Provider -->
                                <app-select-searching-dynamic-input class="flex-14" *ngIf="this.globalAction!='PRI'"
                                    [disabled]="this.disabledProvider(i)" [defaultValue]="this.actualProviders[i]"
                                    [elements]="this.providersOnSelect[i]" [name]="this.PROVIDER"
                                    [nameSearching]="this.PROVIDER_SEARCHING" [form]="getSuppliesFormGroup(i)"
                                    [httpGet]="this.getProvidersByName" [calculateId]="this.calculateIdProvider"
                                    [calculateName]="this.calculateNameProvider"
                                    (selectedWithEntity)="this.providerSelected($event, i)"
                                    [placeholder]="'Seleccionar proveedor'" [placeholderNoValue]="'Sin resultados'"
                                    [placeholderSearching]="'Buscar proveedor...'" [noOptionText]="'Sin proveedor'"
                                    [matTooltip]="!!this.providersOnSelect[i] ? !!this.providersOnSelect[i][0] ? this.providersOnSelect[i][0].name : null : null"
                                    style="height:40px">
                                </app-select-searching-dynamic-input>

                                <!-- AMOUNT / Cantidad -->
                                <app-number-input class="flex-6" [name]="this.AMOUNT" [form]="getSuppliesFormGroup(i)"
                                    [money]='false' [placeholder]="''" [minValue]="0"
                                    [readonly]="this.globalAction=='PRI' || this.globalAction=='FPR'"
                                    (keydown.enter)="$event.preventDefault()" style="height: 40px;">
                                </app-number-input>

                                <!-- UNIT -->
                                <app-select-input class="flex-7" [name]="this.UNIT" [form]="getSuppliesFormGroup(i)"
                                    [placeholder]="''" [elements]="this.provisionsUnit"
                                    [calculateId]="this.calculateIdUnit" [calculateName]="this.calculateNameUnit"
                                    [disabled]="this.globalAction=='PRI' || this.globalAction=='FPR'"
                                    (keydown.enter)="$event.preventDefault()" style="height: 40px;">
                                </app-select-input>

                                <!-- COST -->
                                <app-number-input class="flex-10" [name]="this.PROVIDER_FEE"
                                    [form]="getSuppliesFormGroup(i)" [money]="true" [minValue]="0"
                                    (change)="this.onChangeCost(i)" (keydown.enter)="$event.preventDefault()"
                                    style="height: 40px;">
                                </app-number-input>

                                <!-- Margin -->
                                <app-number-input class="flex-7" [name]="this.PROFIT_MARGIN"
                                    [form]="getSuppliesFormGroup(i)" [readonly]="this.globalAction=='FPR'"
                                    [money]="false" [placeholder]="''" [suffix]="'%'"
                                    (change)="this.calculateTotalValue(i)" (keydown.enter)="$event.preventDefault()"
                                    style="height: 40px;">
                                </app-number-input>

                                <!-- Fee / Valor unitario -->
                                <app-number-input class="flex-10" [name]="this.FEE" [form]="getSuppliesFormGroup(i)"
                                    [money]='true' [minValue]="0" (change)="this.calculateMargin(i)"
                                    (keydown.enter)="$event.preventDefault()" style="height: 40px;">
                                </app-number-input>

                                <!-- Kairos -->
                                <div class="flex-3" *ngIf="this.globalAction!='FPR'"
                                    [ngStyle]="{'min-width': (this.globalAction==='MOD' || this.globalAction==='AUT') ? '3%' : ''}">
                                    <app-checkbox-input *ngIf="this.getIsMedicine(i)" class="mb-1"
                                        [form]="getSuppliesFormGroup(i)" [name]="this.KAIROS"
                                        [disabled]="this.globalAction=='FPR'" (change)="onChangeCheckKairos(i, $event)"
                                        (keydown.enter)="$event.preventDefault()" [hidden]="this.globalAction=='FPR'"
                                        style="height: 40px;">
                                    </app-checkbox-input>
                                </div>

                                <!-- From date by item -->
                                <app-date-picker class="flex-11" *ngIf="globalAction!='ADD' && globalAction!='FPR'"
                                    [name]="this.FROM_DATE_BY_SUPPLY" [form]="getSuppliesFormGroup(i)"
                                    [readonly]="this.globalAction=='PRI'" [disabled]="this.globalAction=='PRI'"
                                    (onDateChange)="onClickFromDateBySupply(i, $event)"
                                    (keydown.enter)="$event.preventDefault()" style="height: 40px;">
                                </app-date-picker>

                                <!-- To date by item -->
                                <app-date-picker class="flex-11" *ngIf="globalAction!='ADD' && globalAction!='FPR'"
                                    [name]="this.TO_DATE_BY_SUPPLY" [form]="getSuppliesFormGroup(i)"
                                    [readonly]="this.globalAction=='PRI'" [disabled]="this.globalAction=='PRI'"
                                    (onDateChange)="onClickToDateBySupply(i, $event)"
                                    (keydown.enter)="$event.preventDefault()" style="height: 40px;">
                                </app-date-picker>

                                <!-- BUTTONS -->
                                <div class="flex-row flex-6">
                                    <button mat-icon-button [color]="'primary'"
                                        (click)="this.removeSupply(i); $event.preventDefault()"
                                        [hidden]="this.globalAction==='FPR' || this.globalAction==='AUT'" class="flex-50 align-center-start px-0"
                                        style="width: 25px;">
                                        <mat-icon>delete_outlined</mat-icon>
                                    </button>
                                    @if (shouldShowButton(i)) {
                                        <button mat-icon-button [color]="'primary'" matTooltip="Agregar"
                                            (click)="this.addSupply(); this.scrollToBottom(); $event.preventDefault()"
                                            [disabled]="!(!!this.formSupplies['controls']['supplies']['controls'][i].valid)"
                                            class="flex-50 align-center-start px-0" style="width: 20px;">
                                            <mat-icon>add_circle_outlined</mat-icon>
                                        </button>
                                    } @else {
                                        <div class="flex-50"></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div style="width: 95%" class="flex-row end-center mts-2 mbs-2">
                    <span>Insumos: {{this.supplies['controls'].length}}</span>
                </div>
            </div>
            <!-- Validity / Vigencia -->
            <div *ngIf="globalAction=='ADD'" class="flex-row start-center flex-50 gap-1em">
                <div class="flex-column">
                    <span class="subtitle-3 text-primary-darker">Desde *</span>
                    <app-date-picker [name]="this.FROM_DATE" [form]="this.formSupplies"
                        (onDateChange)="onClickDateFrom($event)"></app-date-picker>
                </div>
                <div class="flex-column">
                    <span class="subtitle-3 text-primary-darker">Hasta *</span>
                    <app-date-picker [name]="this.TO_DATE" [form]="this.formSupplies"
                        (onDateChange)="onClickDateTo($event)"></app-date-picker>
                </div>
            </div>
            <span *ngIf="globalAction=='ADD'" class="mts-1 mbs-2">Fecha a partir de la cual se puede cargar
                agenda</span>
            <div *ngIf="this.globalAction!='PRI' && this.globalAction!='FPR' && this.globalAction != 'MOD'"
                class="flex-100 separator separator-center mts-1">
                <b class="mx-2 text-primary-darker">
                    Autorizar
                </b>
            </div>
            <!-- Checkbox -->
            <app-checkbox-input
                *ngIf="this.globalAction!='PRI' && this.globalAction!='FPR' && this.globalAction != 'MOD'"
                class="flex-1-1-auto mbs-2" [labelPosition]="'after'" [form]="this.formSupplies"
                [label]="'Agregar autorización'" [name]="this.IS_ADDING_AUTHORIZATION"
                (change)="onChangeCheckAut($event)">
            </app-checkbox-input>
            <!-- AUTHORIZATION -->
            <div *ngIf="this.formSupplies.get(this.IS_ADDING_AUTHORIZATION).value" [formGroupName]="this.AUTHORIZATION"
                class="flex-column flex-100">
                <!-- First row -->
                <div class="flex-row align-space-between-center">
                    <!-- Auth number -->
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Número de autorización *</span>
                        <app-text-input [name]="this.AUTHORIZATION_NUMBER" [form]="this.formAuthorization"
                            (keydown.enter)="$event.preventDefault()">
                        </app-text-input>
                    </div>
                    <!-- Dates -->
                    <div class="flex-column flex-45">
                        <div *ngIf="globalAction==='AUT'" class="flex-row space-between-center">
                            <div class="flex-column">
                                <span class="subtitle-3 text-primary-darker">Desde *</span>
                                <app-date-picker [name]="this.AUTHORIZATION_FROM_DATE" [form]="this.formAuthorization"
                                    (onDateChange)="onClickDateFrom($event)"></app-date-picker>
                            </div>
                            <div class="flex-column">
                                <span class="subtitle-3 text-primary-darker">Hasta *</span>
                                <app-date-picker [name]="this.AUTHORIZATION_TO_DATE" [form]="this.formAuthorization"
                                    (onDateChange)="onClickDateTo($event)"></app-date-picker>
                            </div>
                        </div>
                        <mat-error *ngIf="globalAction==='AUT'">
                            Las fechas ingresadas reemplazarán la vigencia de los insumos seleccionados
                        </mat-error>
                    </div>
                </div>
                <!-- Second row -->
                <div class="flex-row space-between-center">
                    <div class="flex-column flex-45">
                        <span class="subtitle-3 text-primary-darker">Observaciones de la autorización</span>
                        <app-text-area-input class="" [hint]="'Opcional'" [name]="this.AUTHORIZATION_OBSERVATIONS"
                            [form]="this.formAuthorization" [maxLength]="120">
                        </app-text-area-input>
                    </div>
                    <div class="flex-column flex-45">
                        <div style="height: 25px;">
                            @if(this.photoEvolutionB64 == null) {
                            <span class="subtitle-3" style="color:red">Archivo requerido</span>
                            }
                        </div>
                        <!-- class="filepond--panel-root" -->
                        <file-pond #myPond
                            [options]="pondOptions"
                            (oninit)="pondHandleInit()"
                            (onaddfile)="pondHandleAddFile($event)"
                            (onremovefile)="pondHandleRemoveFile($event)"
                            (onaddfilestart)="pondHandleProcessFile($event)"
                            id="Case_fileSelector">
                        </file-pond>
                    </div>
                </div>
            </div>

            <mat-error class="mls-3" *ngIf=" this.formAuthorization.errors &&
                   (this.formAuthorization.dirty || this.formAuthorization.touched)" class="flex-row start-center">
                <mat-icon class="text-warn mrs-2">error_outline</mat-icon>
                {{ objectValues(this.formAuthorization.errors)[0] }}
            </mat-error>
            <!-- Bottom actions -->
            <div class="flex-row align-space-around-center full-width">
                <button [disabled]="(this.isLoadingCreatingProvision$ | async) || (isLoadingUpdatingProvision$ | async)"
                    mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()">
                    CANCELAR
                </button>

                <button mat-flat-button [color]="'primary'" type="submit" [disabled]="(isLoadingUpdatingProvision$ | async) || (isLoadingCreatingProvision$ | async)
                       || !this.formSupplies.valid
                       || (this.formSupplies.get(this.IS_ADDING_AUTHORIZATION).value && this.photoEvolutionB64 == null)
                       || (this.globalAction=='PRI' && !(this.formSupplies.get('oneProviderId').value > 0))">
                    <span
                        *ngIf="!(isLoadingUpdatingProvision$ | async) && !(isLoadingCreatingProvision$ | async) else loadingIcon">
                        GUARDAR
                    </span>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #loadingIcon>
    <mat-icon>
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
    </mat-icon>
</ng-template>

<ng-template #addOrderTemplate>
    <div class="flex-column">
        <form [formGroup]="this.formAddOrder" *ngIf="this.formAddOrder" (submit)="onClickCreateOrder()" class="flex-column flex-100">
            <div class="flex-row align-space-between-center full-width">
                <!-- Select providers -->
                <div class="flex-column flex-45">
                    <span class="subtitle-3 text-primary-darker">
                        Proveedor
                    </span>
                    <app-select-searching-dynamic-input [name]="this.ORDER_PROVIDER"
                        [defaultValue]="this.actualProviderOrderSelected[0]" [elements]="this.providerOrderOnSelect[0]"
                        [nameSearching]="this.ORDER_PROVIDER_SEARCHING" [form]="this.formAddOrder"
                        [placeholder]="'Seleccionar'" [calculateId]="this.calculateIdProvider"
                        [calculateName]="this.calculateNameProvider" [placeholderNoValue]="'Sin resultados'"
                        [placeholderSearching]="'Buscar proveedor...'" [httpGet]="this.loadProvidersByFullName"
                        [disabled]="true">
                    </app-select-searching-dynamic-input>
                </div>
                <!-- Date delivery -->
                <div class="flex-column flex-45">
                    <span class="subtitle-3 text-primary-darker">
                        Fecha de entrega
                    </span>
                    <app-date-picker [startDate]="this.historyFromDate" [name]="this.ORDER_DATE"
                        [form]="this.formAddOrder" class=""></app-date-picker>
                </div>
            </div>

            <table mat-table [dataSource]="dataSourceSupplies">
                <!-- supplyRequested Column -->
                <ng-container matColumnDef="supplyRequested">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 12px;
                    border-right: 1px solid #FFF">Insumo</th>
                    <td mat-cell *matCellDef="let order; let i = index;">
                        <span class="mbs-3">
                            {{ this.itemsFormArray['controls'][i].get(this.ITEM_PRACTICE).value.name }}
                        </span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- quantityRequested Column -->
                <ng-container matColumnDef="quantityRequested">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <td mat-cell *matCellDef="let order, let i = index;" style="padding-inline: 5%;">
                        <app-number-input
                            [name]="this.ITEM_QUANTITY" [form]="getItemsFormArrayFormGroup(i)" [money]='false'
                            [suffix]="this.itemsFormArray['controls'][i].get(this.ITEM_UNIT).value+'/s'"
                            [suffixClass]="'text-secondary'" [readonly]="true"> <!-- padding-right: 2% !important; -->
                        </app-number-input>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- priceRequested Column -->
                <ng-container matColumnDef="priceRequested">
                    <th mat-header-cell *matHeaderCellDef>Costo</th>
                    <td mat-cell *matCellDef="let order, let i = index;" class="flex align-center-center">
                        <app-number-input [name]="this.ITEM_PRICE" [form]="getItemsFormArrayFormGroup(i)" [money]='true'
                           [readonly]="true"  class="flex-70">
                        </app-number-input>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSupplies"></tr>
                <tr mat-row class="bg-on-hover parentShowOnHover" *matRowDef="let row; columns: displayedColumnsSupplies;"></tr>
            </table>
            <mat-divider class="divider-form mts-2"></mat-divider>
            <!-- Text-area -->
            <app-text-area-input class="flex-100" [hint]="'Opcional'" [name]="this.ORDER_OBSERVATIONS"
                [form]="this.formAddOrder" [placeholder]="'Observaciones del pedido'" [maxLength]="120">
            </app-text-area-input>
            <!-- Bottom buttons -->
            <div class="flex-row align-space-around-center flex-100 mts-3">
                <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                    [disabled]="(isLoadingCreatingOrder$ | async)">
                    CANCELAR
                </button>
                <button mat-flat-button [color]="'primary'" type="submit"
                    [disabled]="(isLoadingCreatingOrder$ | async) || !this.formAddOrder.valid">
                    <span *ngIf="!(isLoadingCreatingOrder$ | async) else loadingIcon">CREAR PEDIDO</span>
                    <ng-template #loadingIcon>
                        <mat-icon>
                            <mat-spinner color="accent" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </ng-template>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<mat-menu #menuActions="matMenu">
    <ng-template matMenuContent let-selectedSupplies="selectedSupplies">
        <!-- No actions -->
        <button *ngIf="selectedSupplies.selected.length == 0" mat-menu-item (click)="''">Sin acciones</button>
        <!-- Update -->
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            (click)="this.openUpdateSupplies()">
            Renovar
        </button>
        <!-- Modify -->
        <button *ngIf="selectedSupplies.selected.length > 0" mat-menu-item
            (click)="this.openAddSupplyDialog( 'MOD', selectedSupplies.selected )">Modificar
        </button>
        <!-- Load price -->
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            (click)="this.openLoadPrice( selectedSupplies.selected )">
            Cargar cotización
        </button>
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            (click)="this.openAddAuthorization( selectedSupplies.selected )">
            Autorizar
        </button>
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            (click)="this.deleteSupply( selectedSupplies.selected )">
            Eliminar
        </button>
        <!--Budget PDF-->
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            [mat-menu-trigger-for]="exportBudget" (mouseenter)="showBudgetMenu?.openMenu()"
            (mouseleave)="showBudgetMenu?.closeMenu()">
            Crear presupuesto
        </button>
        <mat-menu #exportBudget="matMenu" [overlapTrigger]="false">
            <button mat-menu-item (click)="this.generateBudgetPDF( selectedSupplies.selected, true )">Exportar
                como.pdf</button>
            <button mat-menu-item (click)="this.generateBudgetPDF( selectedSupplies.selected, false )">Exportar
                como.doc</button>
        </mat-menu>
        <!--Quote PDF-->
        <button *ngIf="selectedSupplies.selected.length > 0 && !isSomeSupplyWithoutUnit()" mat-menu-item
            [mat-menu-trigger-for]="exportQuote" (mouseenter)="showQuoteMenu?.openMenu()"
            (mouseleave)="showQuoteMenu?.closeMenu()">Crear cotización
        </button>
        <mat-menu #exportQuote="matMenu" [overlapTrigger]="false">
            <button mat-menu-item (click)="this.generateQuotePDF( selectedSupplies.selected, true )">Exportar
                como.pdf</button>
            <button mat-menu-item (click)="this.generateQuotePDF( selectedSupplies.selected, false )">Exportar
                como.doc</button>
        </mat-menu>
    </ng-template>
</mat-menu>

<ng-template #popUpMessage>
    <div class="flex-column center-none">
        <!-- Header -->
        <div class="flex-column align-center-center">
            <div class="flex-row mbs-2">
                <mat-icon svgIcon="WarningCircle" style="height: 55px; width: 55px;"></mat-icon>
            </div>
            <span class="subtitle-2 active-case-text-margin" style="text-align: center; max-width: 50em;">
                {{ this.message }}
            </span>
        </div>

        <!-- Table -->
        @if(this.arrayTable?.length > 0) {
            <div class="mt-2" class="flex-column align-center-none full-width">
                <section class="table-container" tabindex="0">
                    <table mat-table [dataSource]="this.arrayTable">
                        <!-- Supplyname -->
                        <ng-container matColumnDef="supplyName">
                            <td mat-cell *matCellDef="let item">
                                <span class="pls-5"
                                    style="-webkit-box-decoration-break: clone; box-decoration-break: clone;">
                                    {{ item.supplyName }}
                                </span>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <!-- Quantity -->
                        <ng-container matColumnDef="quantity">
                            <td mat-cell *matCellDef="let item">
                                {{ item.quantity }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <!-- Provider -->
                        <ng-container matColumnDef="provider">
                            <td mat-cell *matCellDef="let item">
                                {{ item.provider }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </section>
            </div>
        }

        <!-- Bottom buttons -->
        <div class="flex-row align-space-around-center m-1 border-data full-width">
            <button mat-stroked-button [color]="'primary'" (click)="onClickCancel()">
                {{ txtButtonCancel }}
            </button>
            <button *ngIf="showButtonACEPTAR" mat-raised-button [color]="'primary'" (click)="onClickConfirm()">
                <div class="flex-row center-center">
                    ACEPTAR
                </div>
            </button>
        </div>
    </div>
</ng-template>
