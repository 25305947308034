import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringFilterByPipe } from './string-filter-by-pipe.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { FirstLetterWordsIntoStringPipe } from './first-letter-words-into-string';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [StringFilterByPipe, CapitalizeFirstPipe, FirstLetterWordsIntoStringPipe, SafeHtmlPipe],
  imports: [
    CommonModule
  ],
  exports: [StringFilterByPipe, CapitalizeFirstPipe, FirstLetterWordsIntoStringPipe, SafeHtmlPipe]
})
export class PipesModule { }
