<div class="flex-100 flex-column" style="overflow: hidden;">
    <div *ngIf="!!dataSourceAttentions && this.quickSearchActive"
        class="flex-row align-center-center mxs-5 mys-2 flex-100">
        <mat-form-field class="flex-80">
            <input matInput (keyup)="applyFilter($event)" placeholder="Búsqueda rápida" />
        </mat-form-field>
    </div>

    <div class="header-filters mb-2">
        <!-- <div class="flex-column align-none-none"> -->
        <div class="flex-row align-center-center">
            <mat-form-field class="flex-100 pls-3" style="height: 40px !important;" appearance="outline">
                <mat-select id="cmb_schedule" [(value)]="this.optionSelected"
                    (selectionChange)="this.selectOption($event)" class="subtitle-2">
                    <mat-option value="listado">
                        Listado
                    </mat-option>
                    <mat-option value="calendario">
                        Calendario
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex-30"></div>
            <div class="flex-37">
                <span class="subtitle-2 text-secondary-bolder">
                    {{ dateLabel }}
                </span>
            </div>
            <div class="flex-row align-end-center gap-1em">
                <button id="btn_add_attention" mat-icon-button matTooltip="Eliminación de atenciones"
                    (click)="deleteAttentions()">
                    <mat-icon svgIcon="Trash-enabled-primary"></mat-icon>
                </button>
                <button id="btn_add_attention" mat-icon-button matTooltip="Agendar" color="primary"
                    (click)="createAttention()">
                    <mat-icon class="mat-icon-22x22" svgIcon="Add-attention-enabled-primary-22x22"></mat-icon>
                </button>
            </div>
        </div>
        <!-- </div> -->
    </div>

    <div class="flex-column"
        *ngIf="(dataSourceAttentions && dataSourceAttentions.data && (dataSourceAttentions.data.length > 0 || this.actualDateAttentions?.length > 0)) || (dataSourceSupplies && dataSourceSupplies.data && dataSourceSupplies.data.length > 0 && this.actualDateAttentions?.length > 0); else noAttentions">
        <!-- Attentions Table -->
        <div style="overflow-y:auto;" [ngClass]="{'scrollOff': !this.scrollOn}">
            <table multiTemplateDataRows class="mat-elevation-z0 mt-0 flex-100" mat-table
                [dataSource]="dataSourceAttentions">
                <!-- Master Head 1 -->
                <ng-container matColumnDef="title">
                    <th #scheduleFocus mat-header-cell *matHeaderCellDef colspan="25"
                        style="text-align: center; background-color: #FFF !important; border: 1px solid #441d99 !important;">
                        <span *ngIf="this.selectedOption == PROVISION">Atenciones Médicas</span>
                        <span *ngIf="this.selectedOption == ORDER || this.selectedOption == SUPPLY">Insumos</span>
                    </th>
                </ng-container>

                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *ngIf="this.checkBoxActive" style="margin-right: 5px"
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div>
                            <mat-checkbox *ngIf="this.ruleClickable(row); else noClickeable"
                                (click)="$event.stopPropagation()" (change)="$event ?  selection.toggle(row) : null"
                                [checked]="this.selection.isSelected(row)">
                            </mat-checkbox>
                            <ng-template #noClickeable>
                                <span></span>
                            </ng-template>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- arrow Column -->
                <ng-container matColumnDef="arrow">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data" class="pr-0" [ngClass]="{'border-top': !!data.textDate }">
                        <mat-icon class="text-secondary">{{ data == expandedElement ? 'keyboard_arrow_up' :
                            'keyboard_arrow_down' }}</mat-icon>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- dateFrom Column -->
                <ng-container matColumnDef="fromDate">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data" style="border-right: 0px !important;"
                        [ngClass]="{'border-top': !!data.textDate }">
                        <div class="inner-cell">
                            <div class="flex-100 flex-row align-start-center">
                                <div class="flex-15 flex align--start pls-2">
                                    <mat-icon *ngIf="(!!this.overlapAdviceText(data))" style="cursor: pointer;"
                                        svgIcon="WarningCircle" class="mat-icon-small"
                                        matTooltip="{{this.overlapAdviceText(data)}}"></mat-icon>
                                </div>
                                <div class="flex-1-1-auto flex-column" style="justify-content: start;"
                                    style="margin-left: auto; margin-right: auto;">
                                    <span class="subtitle-3 text-secondary-bolder" style="margin-left: 8px;">{{
                                        !!data.textDate ? data.textDate : '' }}</span>
                                    <span *ngIf="data.totalHours != 0">{{!!data.totalHours ? data.totalHours + 'hs' :
                                        ''}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- timeRange Column -->
                <ng-container matColumnDef="timeRange">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="flex-column align-center-center" *ngIf="this.selectedOption === PROVISION">
                            <span>Horario</span>
                            <span>agendado</span>
                        </div>
                        <div class="flex-column align-center-center" *ngIf="this.selectedOption === SUPPLY">
                            <span>Hs</span>
                            <span>de entrega</span>
                        </div>
                        <div class="flex-column align-center-center" *ngIf="this.selectedOption === ORDER">
                            <span>Proveedor</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let data" [ngClass]="{'border-top': !!data.textDate }">
                        <div class="inner-cell">
                            <div class="flex-column" *ngIf="this.selectedOption != ORDER">
                                <span>
                                    {{ this.practiceCountedByHours(data.practice) ? (data && data.fromScheduled ?
                                    (data.fromDate | date: 'HH:mm') : '') : 'Visita' }}
                                    {{ this.practiceCountedByHours(data.practice) ? (data && data.toScheduled ? ' - ' +
                                    (data.toDate | date: 'HH:mm') : '') : '' }}
                                </span>
                            </div>
                            <div class="flex-column" *ngIf="this.selectedOption === ORDER">
                                <span>{{data?.provider?.name ? data?.provider?.name : '-'}}</span>
                                <span>{{data?.provider?.surname ? data.provider.surname : ''}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- authorization ok -->
                <ng-container matColumnDef="isAuthorized">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data" [ngClass]="{'border-top': !!data.textDate }">
                        <mat-icon matTooltip="Autorizado" [style.width.px]="15" [style.height.px]="13"
                            *ngIf="data.isAuthorized && data.practice.name != 'Entrega insumos'"
                            svgIcon="ellipseGreen"></mat-icon>
                        <mat-icon matTooltip="No autorizado" [style.width.px]="15" [style.height.px]="13"
                            *ngIf="!data.isAuthorized && data.practice.name != 'Entrega insumos'"
                            svgIcon="ellipseRed"></mat-icon>
                    </td>
                </ng-container>

                <!-- hours quantity Column -->
                <ng-container matColumnDef="hours">
                    <th mat-header-cell *matHeaderCellDef style="max-width: 90px;">
                        <div class="flex-column align-center-center" *ngIf="this.selectedOption === PROVISION">
                            <span>Hs</span>
                            <span>declaradas</span>
                        </div>
                        <div class="flex-column align-center-center" *ngIf="this.selectedOption === ORDER">
                            <span>Costo</span>
                            <span>Del pedido</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let data" [ngClass]="{'border-top': !!data.textDate }">
                        <div class="inner-cell">
                            <div class="flex-row" *ngIf="this.selectedOption === PROVISION">
                                <span
                                    [ngClass]="{'hours-difference': this.validateHourDifference(data) && this.practiceCountedByHours(data.practice) && !statesForShowBadgeWhenDiffHours.includes(data.state.id) && !!data.quantity}"
                                    matBadge="!" matBadgeSize="small" matBadgeColor="warn"
                                    [matBadgeHidden]="!this.validateHourDifference(data) || !this.practiceCountedByHours(data.practice) || !statesForShowBadgeWhenDiffHours.includes(data.state.id)"
                                    matTooltip="El rango agendado no coincide con las horas declaradas"
                                    [matTooltipDisabled]="!validateHourDifference(data) || !practiceCountedByHours(data.practice) || !statesForShowBadgeWhenDiffHours.includes(data.state.id)">
                                    {{ data && data?.practice?.restriction.id === 1 && data.quantity
                                        ? '1'
                                        : data && data.quantity
                                            ? (((data.quantity < 10) ? ('0' + data.quantity + ':00' )
                                            : (data.quantity + ':00' )) + ' hs' ) : '-'
                                    }}
                                </span>
                            </div>
                            <div *ngIf="this.selectedOption === ORDER">
                                <span>${{data?.providerFee}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <td mat-cell *matCellDef="let data" [ngClass]="{'border-top': !!data.textDate }">
                        <div class="inner-cell">
                            <div class="flex-column">
                                {{!!data.quantity ? data.quantity + ' ' + data.unit : '-'}}
                            </div>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column">
                            <b>{{this.getTotalQuantity()}}</b>
                            <span>{{this.getTotalQuantityDebited()}}</span>
                        </div>
                    </td>
                </ng-container>

                <!-- state Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let attention" [matTooltip]="this.matTooltipText(attention)"
                        matTooltipClass="multiline-tooltip"
                        style="padding-right: 0px !important; max-width: 80px !important; "
                        [ngClass]="{'marked-top': attention.paintedType ==_viewManagementEntities.ATTENTIONS_OLD_AND_OMITTED && focusToElement()== _viewManagementEntities.ATTENTIONS_OLD_AND_OMITTED,
                    'border-top': !!attention.textDate}">
                        <div class="inner-cell inner-cell-auth">
                            <mat-chip-listbox *ngIf="!attention?.isLoadingStates">
                                <mat-chip style="white-space: nowrap; cursor: pointer;"
                                    [ngClass]="{ 'mat-chip-min-width-50px': this.descriptionStateShort }"
                                    [ngStyle]="{'color':this.ATTENTIONS_COLORS[attention.state.id], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[attention.state.id]}"
                                    selected [disabled]="attention?.isLoadingStates"
                                    (click)="this.stopPropagation($event); !attention?.isLoadingStates ? this.onClickStateAttention(attention):null">
                                    <span class="mat-caption" matTooltip="{{ attention.state.name | uppercase }}">{{
                                        this.descriptionStateShort ? ( attention.state.name | firstLetterWordsIntoString
                                        ) : ( attention.state.name | titlecase | uppercase ) }}</span>
                                </mat-chip>
                            </mat-chip-listbox>
                            <div class="align-center-center">
                                <mat-spinner diameter="20" *ngIf="attention?.isLoadingStates"></mat-spinner>
                            </div>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let attention">{{ attention.id }}</td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- practice Column -->
                <ng-container matColumnDef="practice">
                    <th mat-header-cell *matHeaderCellDef style="max-width:110px">
                        <span *ngIf="this.selectedOption !== SUPPLY">Práctica</span>
                        <span *ngIf="this.selectedOption === SUPPLY">Insumos</span>
                    </th>
                    <td mat-cell *matCellDef="let attention" [ngClass]="{'border-top': !!attention.textDate}"
                        style="max-width: 110px;">
                        <div class="inner-cell" [matTooltip]="!!attention.practice ? attention.practice.name : ''">
                            <span class="text-ellipsis">
                                {{ !!attention.practice?.name ? attention.practice.name : 'Sin datos'}}
                                </span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- provider Column -->
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef style="max-width:110px">
                        <span *ngIf="this.selectedOption !== SUPPLY">Prestador</span>
                        <span *ngIf="this.selectedOption === SUPPLY">Proveedor</span>
                    </th>
                    <td mat-cell *matCellDef="let attention" [ngClass]="{'border-top': !!attention.textDate}"
                        style="max-width: 110px;">
                        <div class="inner-cell"
                            [matTooltip]="!!attention.provider ? attention.provider.surname + ', ' + attention.provider.name : ''">
                            <span class="text-ellipsis">{{ !!attention.provider ? attention.provider.surname + ', ' +
                                attention.provider.name : ''}}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Actions Menu -->
                <ng-container matColumnDef="actionsMenu">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let attention" style="padding-right: 0px !important;"
                        [ngClass]="{'border-top': !!attention.textDate}">
                        <div class="inner-cell">
                            <button mat-icon-button (click)="stopPropagation($event);" matTooltip="Acciones">
                                <mat-icon class="cursor-pointer text-secondary" [matMenuTriggerFor]="menuActions"
                                    [matMenuTriggerData]="{ attention:attention }"
                                    svgIcon="DotsThreeVertical-enabled-primary-lighter"></mat-icon>
                            </button>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Actions Bill/Facturar o Debit/Debitar -->
                <ng-container matColumnDef="bill-debit">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-radio-group (click)="$event.stopPropagation()">
                            <div class="flex-row">
                                <div class="flex-50 flex-column align-center-center">
                                    <span style="padding: 0px 5px;">Facturar</span>
                                    <mat-icon style="cursor: pointer;" svgIcon="SelectAll-enabled-primary-lighter"
                                        matTooltip="Selecciona todas las atenciones para Facturar"
                                        (click)="this.selectAll('toBill')"></mat-icon>
                                </div>
                                <div class="flex-50 flex-column align-center-center">
                                    <span>Debitar</span>
                                    <mat-icon style="cursor: pointer;" svgIcon="SelectAll-enabled-primary-lighter"
                                        matTooltip="Selecciona todas las atenciones para Debitar"
                                        (click)="this.selectAll('toDebit')"></mat-icon>
                                </div>
                            </div>
                        </mat-radio-group>
                    </th>
                    <td mat-cell *matCellDef="let attention" [ngClass]="{'border-top': !!attention.textDate }">
                        <div class="inner-cell">
                            <mat-radio-group style="width: 100%;" (click)="$event.stopPropagation()"
                                [disabled]="attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA">
                                <div  class="flex-row">
                                    <div class="flex-50 flex-column align-center-center"
                                    [ngClass]="{'marked-top': attention.paintedType ==_viewManagementEntities.ATTENTIONS_WITHOUT_BILLING || attention.paintedType ==   _viewManagementEntities.SUPPLIES_NOT_BILLING
                                    && focusToElement() ==  _viewManagementEntities.ATTENTIONS_WITHOUT_BILLING || focusToElement() ==  _viewManagementEntities.SUPPLIES_NOT_BILLING}">
                                        <mat-radio-button #buttonB class="subtitle-3 text-primary-darker"
                                            [checked]="validateCheckedBill(attention)" [value]="'toBill'"
                                            (click)="!(attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA) && !(attention.state.id==enumStates.FACTURABLE) && checkState(attention, buttonB)"
                                            [disabled]="attention.state.id==enumStates.FACTURABLE"></mat-radio-button>
                                    </div>
                                    <div class="flex-50 flex-column align-center-center">
                                        <mat-radio-button #buttonD class="subtitle-3 text-primary-darker"
                                            [checked]="validateCheckedDebit(attention)" [value]="'toDebit'"
                                            (click)="!(attention.state.id==enumStates.INFORMADA || attention.state.id==enumStates.AGENDADA) && !(attention.state.id==enumStates.DEBITADA) && checkState(attention, buttonD)"
                                            [disabled]="attention.state.id==enumStates.DEBITADA"></mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container>
                    <tr mat-header-row *matHeaderRowDef="['title']"></tr>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="bg-on-hover example-element-row"  [id]="isLastRow(row) ? 'scheduleFocus' : null">
                </tr>
                <ng-container *ngIf="this.showTotalQuantity">
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </ng-container>
            </table>
        </div>

        <mat-paginator #attentionsPaginator *ngIf="this.dataSourceAttentions?.data?.length > 0 && this.paginatorOn"
            class="flex-100" [pageSize]="this.pageSize" [pageIndex]="this.pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="!this.keepSelection ? this.selection.clear() : null"
            showFirstLastButtons>
        </mat-paginator>

        <!-- Supply Table -->
        <div *ngIf="this.supplyList" style="max-height: 85vh; overflow:auto;"
            [ngClass]="{'scrollOff': this.scrollOn==false}">
            <table multiTemplateDataRows class="flex-100 mat-elevation-z0 mt-0" mat-table
                [dataSource]="this.dataSourceSupplies.data">
                <!-- Master Head 1 -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef colspan="25" style="text-align: center;">
                        <span>Insumosx</span>
                    </th>
                </ng-container>

                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *ngIf="this.checkBoxActive" style="margin-right: 5px"
                            (change)="$event ? masterToggleSupply() : null"
                            [checked]="supplySelection.hasValue() && isAllSupplySelected()"
                            [indeterminate]="supplySelection.hasValue() && !isAllSupplySelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div>
                            <mat-checkbox *ngIf="this.ruleClickable(row); else noClickeable"
                                (click)="$event.stopPropagation()"
                                (change)="$event ?  supplySelection.toggle(row) : null"
                                [checked]="this.supplySelection.isSelected(row)">
                            </mat-checkbox>
                            <ng-template #noClickeable>
                                <span></span>
                            </ng-template>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- dateFrom Column -->
                <ng-container matColumnDef="fromDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            <a>{{ data.fromDate | date: "dd/MM/yyyy" }}</a>
                            <span class="text-secondary" *ngIf="data?.liquidation?.id"
                                [matTooltip]="data?.liquidation?.name">#{{data?.liquidation?.id}}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Cant.</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.quantity ? data.quantity : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- unit Column -->
                <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef>Unidad</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.unit ? data.unit : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column mys-1">
                            <b>Total</b>
                            <span class="mat-caption">Total debitada</span>
                        </div>
                    </td>
                </ng-container>

                <!-- price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Precio</th>
                    <td mat-cell *matCellDef="let data">
                        <div class="flex-column">
                            {{!!data.providerFee ? (data.providerFee | currency: 'ARS':'symbol':'1.2') : '-'}}
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <div class="flex-column">
                            <b>{{(this.getTotalPrice() | currency: 'ARS':'symbol':'1.2')}}</b>
                            <span>{{(this.getTotalPriceDebited() | currency: 'ARS':'symbol':'1.2')}}</span>
                        </div>
                    </td>
                </ng-container>

                <!-- provider Column -->
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>Proveedor</th>
                    <td mat-cell *matCellDef="let data">
                        <span *ngIf="!!this.entitiesFacade.getProvidersFullName(data?.provider)"
                            matTooltip="{{this.entitiesFacade.getProvidersFullName(data?.provider)}}">
                            {{ (this.entitiesFacade.getProvidersFullName(data?.provider)).toString().length > 15 ?
                            ((this.entitiesFacade.getProvidersFullName(data?.provider)) | slice: 0:13) + '...' :
                            this.entitiesFacade.getProvidersFullName(data?.provider) }}
                        </span>
                        <div *ngIf="!this.entitiesFacade.getProvidersFullName(data?.provider)">Sin datos</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- state Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let attention" [matTooltip]="this.matTooltipText(attention)"
                        matTooltipClass="multiline-tooltip" [ngClass]="{'border-top': !!attention.textDate }">
                        <mat-chip-listbox *ngIf="!attention?.isLoadingStates">
                            <mat-chip style="white-space: nowrap; cursor: pointer;"
                                [ngStyle]="{'color':this.ATTENTIONS_COLORS[attention.state.id], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[attention.state.id]}"
                                selected [disabled]="attention?.isLoadingStates"
                                (click)="this.stopPropagation($event); !attention?.isLoadingStates ? this.onClickStateAttention(attention):null">
                                <span class="mat-small">{{ attention.state.name | titlecase | uppercase }}</span>
                            </mat-chip>
                        </mat-chip-listbox>
                        <div class="align-center-center">
                            <mat-spinner diameter="20" *ngIf="attention?.isLoadingStates"></mat-spinner>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let attention">{{ attention.id }}</td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- practice Column -->
                <ng-container matColumnDef="supply">
                    <th mat-header-cell *matHeaderCellDef>Insumo</th>
                    <td mat-cell *matCellDef="let attention">{{ !!attention.practice?.name ? attention.practice.name :
                        'Sin datos'}}</td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container>
                    <tr mat-header-row *matHeaderRowDef="['title']"></tr>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsSupply"></tr>
                <tr mat-row *matRowDef=" let row; columns: displayedColumnsSupply"
                    class="bg-on-hover example-element-row"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsSupply"></tr>
            </table>
        </div>

        <mat-paginator #suppliesPaginator *ngIf="this.supplyList?.length > 0 && this.paginatorOn" class="flex-100"
            [pageSize]="this.pageSize" [pageIndex]="this.pageIndex" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="!this.keepSelection ? this.selection.clear() : null" showFirstLastButtons>
        </mat-paginator>

        <!-- Bottom buttons -->
        <div class="flex-row align-space-between-center" style="width: 100%">
            <!-- TOTALS -->
            <div *ngIf="this.bottomTotals" class="flex-column flex-100 align-space-between-stretch">
                <div class="flex-row align-start-center flex-gap-1em py-2 pl-1 pr-2 mat-footer-cell"
                    style="border-radius: 0 0 6px 6px;">
                    <div class="flex-row align-space-between-center" style="gap:1.0rem">
                        @if(this.selectedOption != ORDER) {
                            <span class="subtitle-3 text-secondary-darker align-center-center">Unidades</span>
                            <mat-slide-toggle color="primary" (change)="this.onChangeToggle($event)"></mat-slide-toggle>
                            <span class="subtitle-3 text-secondary-darker align-center-center">Valor venta</span>
                        }
                    </div>
                    <span class="subtitle-3 text-secondary-darker margin-left-counters"
                        *ngIf="this.selectedOption != ORDER">{{this.selectedOption === SUPPLY ? 'TOTAL DE UNIDADES' :
                        'HORAS'}}:</span>
                    <span class="mls-1 subtitle-3 text-primary" *ngIf="this.selectedOption != ORDER">{{ this.totalHours
                        }}</span>
                    <span class="subtitle-3 text-secondary-darker margin-left-counters"
                        *ngIf="this.selectedOption == ORDER">COSTO TOTAL:</span>
                    <span class="mls-1 subtitle-3 text-primary" *ngIf="this.selectedOption == ORDER">${{ this.totalCost
                        }}</span>
                    <span class="subtitle-3 text-secondary-darker margin-left-counters">TOTAL {{this.selectedOption ===
                        SUPPLY ? 'DE INSUMOS' : this.selectedOption === ORDER ? 'DE PEDIDOS' : ''}}:</span>
                    <span class="mls-1 subtitle-3 text-primary">{{ this.totalAttentions }}</span>
                </div>
                <ng-container *ngIf="this.viewType == 'units'; else priceViewTemplate">
                    <div class="flex-row align-space-between-center pl-1">
                        <div class="flex-row wrap align-start-none mts-2 flex-50">
                            <mat-chip-listbox>
                                <div *ngFor="let totalByState of totalsByAttentionsState; let i=index">
                                    <div *ngIf="totalByState!=0" class="flex-row align-start-center flex-gap-2em">
                                        <mat-chip style="white-space: nowrap; cursor: pointer;"
                                            [ngClass]="{ 'mat-chip-min-width-50px': this.descriptionStateShort }"
                                            [ngStyle]="{'color':this.getColor(i), 'background-color':this.getBackgroundColor(i), 'border':this.getBorderStyle(i)}"
                                            (click)="this.selectState(i)">
                                            <span class="mat-caption"
                                                matTooltip="{{ enumStates[i].split('_').join(' ') }}">{{
                                                this.descriptionStateShort ? ( enumStates[i].split('_').join(' ') |
                                                firstLetterWordsIntoString ) : ( enumStates[i] | titlecase | uppercase )
                                                }}</span>
                                        </mat-chip>
                                        <span class="subtitle-3 text-secondary-darker mrs-5">{{ totalByState }}</span>
                                    </div>
                                </div>
                            </mat-chip-listbox>
                        </div>
                        <div class="flex-50 mts-3">
                            <span class="ml-5 text-secondary txt" (click)="removeFilters()"
                                style="white-space: nowrap; cursor: pointer;">Limpiar selección</span>
                        </div>
                    </div>
                    <div *ngIf="this.textButton4" class="flex-row align-space-between-center mts-2"
                        style="background-color: #fafafa; min-width: 100%;">
                        <div class="flex-row align-start-stretch flex-gap-1em flex-75">
                            <span class="subtitle-3 text-secondary ml-5">TOTAL:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.attenttionsToBillDebit.length
                                }}</span>
                            <span class="subtitle-3 text-secondary ml-5">FACTURAR:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker"> {{ this.totalBill }}</span>
                            <span class="subtitle-3 text-secondary ml-5">DEBITAR:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.totalDebit }}</span>
                        </div>

                        <a class="flex-25 text-primary mls-4" mat-button
                            (click)="this.attenttionsToBillDebit.length > 0 ? onLocalClickButton4() : null"
                            [disabled]="this.attenttionsToBillDebit.length == 0"
                            [ngClass]="{ btnProcessDisabled: this.attenttionsToBillDebit.length == 0 }"
                            style="font-weight: bolder !important;">
                            CONFIRMAR
                        </a>


                    </div>
                </ng-container>
                <ng-template #priceViewTemplate>
                    <div class="flex-row align-space-between-center pl-1">
                        <div class="flex-row wrap align-start-none mts-2">
                            <mat-chip-listbox>
                                <div *ngFor="let totalByState of totalPricesByAttentionsState; let i=index">
                                    <div *ngIf="showImport(totalByState, i)"
                                        class="flex-row align-start-center flex-gap-2em">
                                        <mat-chip-option style="white-space: nowrap; cursor: pointer;"
                                            [ngClass]="{ 'mat-chip-min-width-50px': this.descriptionStateShort }"
                                            [ngStyle]="{'color':this.getColor(i), 'background-color':this.getBackgroundColor(i), 'border':this.getBorderStyle(i)}"
                                            (click)="this.selectState(i)">
                                            <span class="mat-caption"
                                                matTooltip="{{ enumStates[i].split('_').join(' ') }}">{{
                                                this.descriptionStateShort ? ( enumStates[i].split('_').join(' ') |
                                                firstLetterWordsIntoString ) : ( enumStates[i] | titlecase | uppercase )
                                                }}</span>
                                        </mat-chip-option>
                                        <span class="mls-1 subtitle-3 text-secondary-darker mrs-5">{{ (totalByState) ?
                                            (totalByState | currency: 'ARS':'symbol':'1.2') : (0 | currency:
                                            'ARS':'symbol':'1.2') }}</span>
                                    </div>
                                </div>
                            </mat-chip-listbox>
                        </div>
                        <div class="flex-25 mts-3">
                            <span class="ml-5 text-secondary txt" (click)="removeFilters()"
                                style="white-space: nowrap; cursor: pointer;">Limpiar selección</span>
                        </div>
                    </div>
                    <div *ngIf="this.textButton4" class="flex-row align-space-between-center mts-2"
                        style="background-color: #fafafa; min-width: 100%;">
                        <div class="flex-row align-stretch-stretch flex-gap-1em flex-75">
                            <span class="subtitle-3 text-secondary ml-5">TOTAL:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.attenttionsToBillDebit.length
                                }}</span>
                            <span class="subtitle-3 text-secondary ml-5">FACTURAR:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker"> {{ this.totalBill }}</span>
                            <span class="subtitle-3 text-secondary ml-5">DEBITAR:</span>
                            <span class="mls-1 subtitle-3 text-secondary-darker">{{ this.totalDebit }}</span>
                        </div>

                        <a class="flex-25 text-primary mls-4" mat-button
                            (click)="this.attenttionsToBillDebit.length > 0 ? onLocalClickButton4() : null"
                            [disabled]="this.attenttionsToBillDebit.length == 0"
                            [ngClass]="{ btnProcessDisabled: this.attenttionsToBillDebit.length == 0 }"
                            style="font-weight: bolder !important;">
                            CONFIRMAR
                        </a>

                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #noAttentions>
    <div *ngIf="!loading" class="text-secondary-darker mts-4 align-center-center" style="align-self: center;">
        <div
            *ngIf="(!!this.dataSourceAttentions && !!this.dataSourceSupplies || (!!this.dataSourceAttentions?.data && this.dataSourceAttentions?.data?.length == 0 && this.actualDateAttentions?.length == 0) && (!!this.dataSourceSupplies?.data && this.dataSourceSupplies?.data?.length == 0 && this.actualDateAttentions?.length == 0))">
            <app-spinner-error [msgError]="this.textNoAttentions"></app-spinner-error>
        </div>
        <div *ngIf="!this.dataSourceAttentions">
            <app-spinner-error
                [msgError]="'Para ver la lista de atenciones, se debe efectuar un filtro de acuerdos previamente'"></app-spinner-error>
        </div>
    </div>
</ng-template>

<ng-template #loadingSpinner>
    <div class="flex-100 pys-2 align-center-start">
        <mat-spinner class="mys-4" color="primary" diameter="40"></mat-spinner>
    </div>
</ng-template>

<mat-menu #menuActions="matMenu">
    <ng-template matMenuContent let-attention="attention">
        <!-- Evolucionar -->
        <button mat-menu-item
            [disabled]="!(this.attentionService.isEvolvable(attention) && attention?.practice?.name != 'Entrega insumos')"
            (click)="openDialog(attention)">Evolucionar</button>
        <!-- Ver remito -->
        <button mat-menu-item [disabled]="!attention.hasEvolutions"
            (click)="onClickEvolutions(attention)">{{attention?.practice?.name == 'Entrega insumos' ? 'Ver remito' :
            'Ver evoluciones'}}</button>
        <!-- Editar -->
        <button mat-menu-item [disabled]="!this.attentionService.isEditable(attention)"
            (click)="onClickUpdate(attention)">Editar</button>
        <!-- Eliminar -->
        <button mat-menu-item [disabled]="!this.attentionService.isDeletable(attention)"
            (click)="onClickDelete(attention)">Eliminar</button>
    </ng-template>
</mat-menu>

<ng-template #deleteWizardAttentions>
    <app-attentions-delete-wizard
        [caseId]="this.case.id"
        [provisionAgreements]="this.provisionAgreements"
        [provisionFees]="this.provisionFees"
        (onClickCancelWizard)="clickCancelDeleteWizard()"></app-attentions-delete-wizard>
</ng-template>
