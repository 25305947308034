import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { CasesFacade } from 'src/app/abstraction/cases.facade';
import { OperatorsQPS, OperatorsService } from 'src/app/core/services/operators.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Case } from 'src/app/shared/models/case';
import { Operator } from 'src/app/shared/models/operator';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-operators-selector',
  templateUrl: './operators-selector.component.html',
  styleUrls: ['./operators-selector.component.scss']
})
export class OperatorsSelectorComponent implements OnInit {

  public readonly OPERATORS = "operators";
  public readonly OPERATORS_SEARCHING = 'operatorsSearching';

  operatorSelectorForm: FormGroup;

  @Input() case: Case;

  operators: Operator[];

  operatorsOnSelect: Operator[];
  actualOperator: Operator;


  constructor(private formBuilder: FormBuilder, private entitiesFacade: EntitiesFacade, private casesFacade: CasesFacade, private operatorsService: OperatorsService) { }

  ngOnInit(): void {
    console.log(this.case);
    this.operatorSelectorForm = this.createCorporateProvidersForm();
    let providerQPS: OperatorsQPS = {
      active: true
    }
    this.entitiesFacade.loadOperators(providerQPS).subscribe();
    this.entitiesFacade.getOperators$().subscribe((operators: Operator[]) => {
      this.operators = operators;
    });
    if (!!this.case.operator) {
      this.operatorsOnSelect = [this.case.operator];
      this.actualOperator = this.case.operator;
    }
  }

  calculateName(element: Operator) {
    return element ? element.name : null;
  }

  calculateId(element: Operator) {
    return element ? element.id : null;
  }

  createCorporateProvidersForm(): FormGroup {
    return this.formBuilder.group({
      [this.OPERATORS]: ['', [CustomValidators.required('Estado requerido')]],
      [this.OPERATORS_SEARCHING]: [''],
    });
  }

  onUpdateOperator() {
    
    const swalWithCustomizeButtons = Swal.mixin({
      customClass: {
      confirmButton: 'btnSwalConfirm',
      cancelButton: 'btnSwalCancel'
      },
      buttonsStyling: true
    })

    swalWithCustomizeButtons.fire({
      title: `Confirma el cambio de operador`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'CONFIRMAR',
      cancelButtonText: 'CANCELAR',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => {
        const body = { operatorId: this.operatorSelectorForm.get(this.OPERATORS)?.value };
        return this.casesFacade.updateCase(body, this.case.id).toPromise().then(() => {
          return swalWithCustomizeButtons.fire({
            text: 'Caso delegado correctamente',
            icon: 'success'
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  getOperatorsByNameAndSurname = (value: string) => this.operatorsService.getOperators({ fullName_like: value, active: true }).pipe(map(body => body.data));
}