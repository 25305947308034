import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { Patient } from 'src/app/shared/models/patient';
import { PatientsService } from 'src/app/core/services/patients.service';

@Component({
  selector: 'app-cases-filter',
  templateUrl: './cases-filter.component.html',
  styleUrls: ['./cases-filter.component.scss']
})
export class CasesFilterComponent implements OnInit {
  @Output('onSelectPatient') onSelectPatientEvent = new EventEmitter<number>();
  filterForm: FormGroup;

  public readonly PATIENT = 'patient';
  public readonly PATIENT_SEARCHING = 'patientSearching';

  get patientForm() {
    return this.filterForm.get(this.PATIENT).value;
  }
  set patientForm(patientId: number) {
    patientId != null ? this.filterForm.controls[this.PATIENT].setValue(patientId.toString())
      : this.filterForm.controls[this.PATIENT].reset()

  }

  constructor(private formBuilder: FormBuilder, private patientsService: PatientsService) { }

  ngOnInit(): void {
    this.filterForm = this.createFilterForm();
  }

  calculateIdPatient(element: Patient) {
    return element ? element.id : null;
  }

  calculateNamePatient(element: Patient) {
    return element ? element.surname + ' ' + element.name : null;
  }

  createFilterForm(): FormGroup {
    return this.formBuilder.group({
      [this.PATIENT]: ['', [CustomValidators.required('Paciente requerido')]],
      [this.PATIENT_SEARCHING]: [''],
    });
  }

  onSelectPatient(patientId: number) {
    this.onSelectPatientEvent.emit(patientId);
  }

  getPatientsByNameAndSurname = (value: string) => this.patientsService.getPatients({ fullName_like: value, active: true })


}
