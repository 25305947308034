<mat-card class="flex flex-row gap-1em" style="
        width:auto;
        max-height: 85vh;
        border-radius: 1rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.19), 0 1px 1px rgba(0, 0, 0, 0.23)
    ">

        <mat-tab-group class="flex-2" mat-stretch-tabs="false" mat-align-tabs="start"
            (selectedTabChange)="onTabChanged($event);" [disableRipple]="false" animationDuration="0ms"
            [selectedIndex]="0">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>tune</mat-icon>
                </ng-template>
                <!-- FILTERS -->
                <form class="flex-100" [formGroup]="providersForm" (submit)="onClickConfirmProvidersForm()"
                    style="width:350px;">
                    <div class="flex-column p-2">
                        <div class="flex-row p-2 " style="border-bottom:1px solid lightgrey; font-size: small">
                            <span class="flex-80" style="line-height: inherit;">Información general</span>
                            <mat-icon class="flex-1-1-auto" [color]="'primary'" style="line-height: 0.8"
                                (click)="setDataVisible()">
                                {{ dataVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                            </mat-icon>

                        </div>
                        <mat-progress-bar *ngIf="( this.entitiesFacade.isLoadingGettingProviders$() | async)"
                            [mode]="'indeterminate'" progressBarId="agreementsLoading"></mat-progress-bar>
                        <div class="p-2" [@detailExpand]="dataVisible ? 'expanded' : 'collapsed'">
                            <ng-container *ngIf="flow == 'app-provisions-detail'; else otherFlow">
                                <!-- Contenido para app-provision-detail -->
                                <div>
                                    <app-patient-data [patient]="this.patient()" [pratice]="this.practice" [specialty]="
                                        this.specialtyName"></app-patient-data>
                                </div>
                                <div class="mt-4">
                                    <app-finder-location-map [inputPOI]="poi" [(center)]="center" [disabled]="true"></app-finder-location-map>
                                </div>
                            </ng-container>
                            <ng-template #otherFlow>
                                <!-- Contenido para otros casos -->
                                <div>
                                    <app-finder-location-map [inputPOI]="poi"
                                        [(center)]="center"></app-finder-location-map>
                                </div>
                                <div class="flex-column flex-gap-small">
                                    <span class="subtitle-3 text-primary-darker">
                                        Especialidad
                                    </span>

                                    <app-select-searching-dynamic-input [name]="this.SPECIALTIES_ID"
                                        [nameSearching]="this.SPECIALTIES_SEARCHING" [form]="providersForm"
                                        [placeholder]="'Búsqueda por especialidad'"
                                        [placeholderNoValue]="'Sin resultados'"
                                        [placeholderSearching]="'Buscando especialidad...'"
                                        [httpGet]="this.getSpecialtiesByName"
                                        (selectedWithEntity)="this.onSelectSpecialty($event)"
                                        [calculateName]="this.calculateNameSpecialty"
                                        [calculateId]="this.calculateIdSpecialty" [defaultOptions]="" [defaultValue]=""
                                        [clearable]="true">
                                    </app-select-searching-dynamic-input>
                                </div>
                            </ng-template>
                        </div>

                        <app-filter-by-agreements [enableFilters]="enableFilters" [screenCalling]="this.screenCalling()"
                            (patientActiveFilter)="onChangePatientActiveFilter($event)"
                            (patientInactiveFilter)="onChangePatientInactiveFilter($event)"
                            (providerFilter)="onChangeProviderFilter($event)"
                            (fromDateToQps)="onChangeFromDateFilter($event)">
                        </app-filter-by-agreements>
                    </div>
                </form>

            </mat-tab>

            <mat-tab disabled="true">
                <ng-template mat-tab-label>
                    <mat-icon>star</mat-icon>
                </ng-template>
            </mat-tab>

        </mat-tab-group>


    <div class="flex" #mapDiv>
        @if(this.mapsService.isApiLoaded()){
            <app-map class="flex" style="width: 100vh;" [inputMarkersSignal]="localMarkers" [width]="mapWidth" [height]="mapHeight"
            [specialtyId]="specialtySelected" [specialtyName]="specialtyName" [center]="center"></app-map>
        }
    </div>

</mat-card>
