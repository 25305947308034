import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ROUTES } from 'src/app/core/enums/routes';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import mime from 'mime';
import { Operator } from 'src/app/shared/models/operator';
import { Subscription } from 'rxjs';
import { EntitiesFacade } from '../../../../abstraction/entities.facade';
import { PostCaseBody } from '../../../../core/services/cases.service';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { DialogImageDetailComponent } from '../../../../shared/components/dialog-image-detail/dialog-image-detail.component';
import { FilePondComponent } from 'ngx-filepond';

@Component({
    selector: 'app-case-management-data',
    templateUrl: './case-management-data.component.html',
    styleUrls: ['./case-management-data.component.scss']
})
export class CaseManagementDataComponent implements OnInit {

    @ViewChild('myPond') myPond: FilePondComponent;

    photoEvolutionB64: string;
    loadingAddFile: boolean = false;
    formCaseManagement: FormGroup;
    public readonly OPERATOR = 'operatorId';
    public readonly OPERATOR_SEARCHING = 'operatorSearching';
    public readonly NOTE = 'observation';
    operators : Operator[]; _operators: Subscription;

    @Output() caseManagementData = new EventEmitter<PostCaseBody>();
    @Output() formCaseManagementDataValid = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private entitiesFacade: EntitiesFacade,
    ) { }

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Adjunte <u>aquí</u> o arrastre el pedido del financiador',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 150
    };

    ngOnInit(): void {
        this.formCaseManagement = this.createForm();
        this.suscribeToData();

        // MANAGEMENT DATA
        this.formCaseManagement.valueChanges.subscribe(managementData => {

            if (this.formCaseManagement.status === 'VALID') {
                this.caseManagementData.emit(this.formCaseManagement.value);
                this.formCaseManagementDataValid.emit(true);
            } else {
                this.formCaseManagementDataValid.emit(false);
            }
        })
    }

    ngOnDestroy(){
        if (!!this._operators){
            this._operators.unsubscribe();
        }
    }

    suscribeToData(){
        this._operators = this.entitiesFacade.loadOperators({active: true}).subscribe((operators : Operator[]) => {
            this.operators = operators;
        })
    }

    goBack() {
        this.router.navigate([ROUTES.ADMISSIONS_ORDERS]);
    }

    onClickConfirm() {

    }

    createForm(): FormGroup {
        const form = this.formBuilder.group({
            [this.OPERATOR]: ['', [CustomValidators.required('Operador requerido')]],
            [this.OPERATOR_SEARCHING]: [''],
            [this.NOTE]: ['']
        });
        return form;
    }

    sanitizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    openDialogImage(_image): void {
        const dialogRef = this.dialog.open(DialogImageDetailComponent, {
            width: '350vw',
            data: { image: _image },
        });

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    pondHandleInit() {
        console.log('Inicio la carga');
    }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);;
            this.photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
        } else {
            this.photoEvolutionB64 = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoEvolutionB64 = null;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    calculateIdOperator = (operator : Operator) => {
        return (operator) ? operator.id : null;
    }

    calculateNameOperator = (operator : Operator) => {
        return (operator) ? operator.name + ' ' + operator.surname : null;
    }
}
