import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent extends FormElementComponent
    implements OnInit {
    @Input() elements!: Array<any>;
    @Input() defaultValue: any;
    @Input() noOptionText: any;
    @Input() spinner = false;
    // Input provided that indicates if the values of the mat-options will be computed as strings or numbers
    @Input() forceNumber!: any;
    // Functions provided by the parent that tells the component how to calculate the id and name of the options
    @Input() calculateId: (element:any) => any = (e) => e.id;
    @Input() calculateName: (element:any) => any = (e) => e.name;
    // Emit an event every time the select changes
    @Output() public selected: EventEmitter<any> = new EventEmitter();
    @Output() public entitySelected: EventEmitter<any> = new EventEmitter();

    constructor() {
        super();
    }

    // Static function that returns the appropriate function according to the "calculate" parameter
    static proxyCalculate(calculate:any): (element:any) => string {
        if (typeof calculate === 'string') {
            return element => {
                return element ? element[String(calculate)] : null;
            };
        } else {
            return calculate;
        }
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Id
    proxyCalculateId(): (element:any) => string {
        return SelectInputComponent.proxyCalculate(this.calculateId);
    }

    // Proxy that calls the proxyCalculate function with a function or string that tells this component how to calculate the Name
    proxyCalculateName(): (element:string) => string {
        return SelectInputComponent.proxyCalculate(this.calculateName);
    }

    override ngOnInit() {
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (!!this.defaultValue) {
            this.form.get(this.name)?.setValue(this.defaultValue);
        }
    }

    calculateSelected(id:string) {
        let elementSelected = null;
        if (this.elements) {
            elementSelected = this.elements.find(
                element =>
                    this.proxyCalculateId()(element) ===
                    (this.forceNumber ? Number(id) : id)
            );
        }
        return elementSelected;
    }

    change(event:any) {
        // Called everytime a new value is selected
        this.selected.emit( event.value ); // Emit id
        this.entitySelected.emit( this.calculateSelected(event.value) ); // Emit entity too
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
          return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
